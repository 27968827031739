import _ from 'lodash'
import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { getAuth } from 'store/auth/authReducer';
import { AgencyModel, PlayerRepresentationStatus } from 'api/player-v2/models';
import AgencyFinderService from 'api/player-v2/agency-finder.service';
import { getCancelTokenSource } from 'axios-config'

class State {
    keyword: string
    isLoading: boolean
    agencies: AgencyModel[]
    wasLoaded: boolean
}

const defaultState: State = {
    keyword: '',
    isLoading: false,
    agencies: [],
    wasLoaded: false
}

const stateController = new StateController<State>("PLAYERV2/AGENCY-FINDER/SEARCH", defaultState);

class Actions {
    public static dispose() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ ...defaultState }))
        }
    }

    public static createDebounce = () => _.debounce((dispatch, keyword: string) => dispatch(Actions.loadAgencies(keyword)), 1000)

    public static cancelToken = null
    public static fetchDebounced = Actions.createDebounce()

    public static onKeywordChange(keyword: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ keyword }))

            if (keyword.length > 0) {
                if(keyword.length > 2){ // start searching after 3 letters typed
                    Actions.fetchDebounced(dispatch, keyword)
                }
            } else {
                dispatch(Actions.onKeywordClear())
            }
        }
    }

    public static setKeyword(keyword: string) {
        return dispatch => {
            dispatch(stateController.setState({ 
                keyword
             }))
        }
    }

    public static onKeywordClear() {
        return (dispatch, getState: () => AppState) => {
            const { playerId } = getAuth(getState());
            dispatch(stateController.setState({ 
                keyword: '', 
                isLoading: false,
                agencies: [],
                wasLoaded: false
            }))
            dispatch(userActivityInsert({
                PageName: 'Agent Finder [Countries]',
                Message: `Clicked Clear Search Bar`,
                PlayerId: playerId,
            }))
        }
    }

    public static loadAgencies(keyword: string) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));
            try {
                if (Actions.cancelToken)
                    Actions.cancelToken.cancel()

                Actions.cancelToken = getCancelTokenSource();

                var data = await AgencyFinderService.getAgencySuggestions(keyword, Actions.cancelToken.token);
                dispatch(stateController.setState({ agencies: data, wasLoaded: true }));

                const { playerId } = getAuth(getState());
                dispatch(userActivityInsert({
                    PageName: 'Agent Finder [Countries]',
                    Message: `Search Agency: ${keyword}`,
                    PlayerId: playerId,
                }))
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        }
    }

    public static reloadAgencyItem(agencyId: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => {
                const agencyItem = prevState.agencies.find(x => x.agencyId === agencyId);
                agencyItem.playerRepresentationStatus = PlayerRepresentationStatus.Sent;
                return { 
                    ...prevState,
                    agencies: [...prevState.agencies]
                }
            }));
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState): State => state.playerV2.agencyFinder.search;
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};



