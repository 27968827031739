import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer'
import VirtualSummitService from 'api/virtual-summit/virtual-summit.service';
import { userPaths } from 'routes/paths';


export class User {
    firstName: string;
    lastName: string;
    club: string;
    title: string;
    email: string;
    phoneCodeAreaId: number;
    mobilePhone: string;
}
class ConfirmState {
    isProcessing: boolean;
}

const defaultState: ConfirmState = {
    isProcessing: false
}

const stateController = new StateController<ConfirmState>(
    "VIRTUAL_SUMMIT/CONFIRM_UNAUTHORIZED",
    defaultState
);

class Actions {
    public static setUserData = (data: User, eventId: number) => {
        return async (dispatch, getState: () => AppState) => {
           
            dispatch(stateController.setState({ isProcessing: true }));
            await VirtualSummitService.registerUser(data, null, eventId);
            dispatch(stateController.setState({ isProcessing: false }));
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    ConfirmState as State,
    Actions as Actions,
    stateController as Controller
};



