import React from 'react'
import styled from 'styled-components'

import Panel from 'components/Panel';
import { EventTitle } from '../past-event-title'
import { PastEventStats } from '../past-event-stats'
import { PastEventClubLogos } from '../past-event-club-logos'
import VirtualDealDayImage from 'media/images/events/2024-january-virtual/virtual-deal-day-thumb.jpg'

const VirtualDealDayJanuary2024 = () => {
    return (
        <div className="row">
            <div className="col-md-8 col-12">
                <Panel className="content-wrapper">
                    <EventTitle
                        number="4"
                        bgImageNumber={8}
                        th="th"
                        title1="TransferRoom"
                        title2="Winter Virtual Deal Day"
                        date="15th January 2024, Online"
                        vs={true}
                    />
                    <div className="photo-container row" style={{ padding: '0' }}>
                        <img style={{ width: '100%', borderRadius: '10px' }} src={VirtualDealDayImage} alt="" />
                    </div>
                </Panel>
            </div>
            <div className="col-md-4 col-12 row no-gutters">
                <div className="col-md-12 col-sm-6 col-12">
                    <PastEventStats
                        participants="525"
                        countries="54"
                        leagues="92"
                        agents="70"
                    />
                </div>
                <div className="col-md-12 col-sm-6 col-12">
                    <PastEventClubLogos
                        attendees={previousEventAttendees}
                        countlimit={42}
                    />
                </div>
            </div>
        </div>
    )
}



export const previousEventAttendees = [
    { id: 2, name: "AS Roma", logo: "logo_1835_AS_Roma.png" },
    { id: 3, name: "Juventus", logo: "logo_1832_Juventus_FC.png" },
    { id: 7, name: "Udinese Calcio", logo: "logo_1844_Udinese_Calcio.png" },
    { id: 46, name: "Liverpool", logo: "logo_1123_Liverpool_FC.png" },
    { id: 47, name: "Tottenham", logo: "logo_1122_Tottenham_Hotspur.png" },
    { id: 48, name: "Man City", logo: "1625_Manchester_City.png" },
    { id: 49, name: "Arsenal", logo: "logo_1121_Arsenal_FC.png" },
    { id: 50, name: "Chelsea", logo: "logo_Chelsea_FC.png" },
    { id: 60, name: "Newcastle", logo: "logo_Newcastle_United.png" },
    { id: 63, name: "Everton", logo: "logo_1124_Everton_FC.png" },
    { id: 70, name: "Brighton", logo: "logo_Brighton_And_Hove_Albion_FC.png" },
    { id: 773, name: "Getafe", logo: "logo_1728_Getafe_CF.png" },
    { id: 976, name: "Monaco", logo: "19830_AS_Monaco.png" },
    { id: 2226, name: "Sevilla FC", logo: "680_Sevilla_FC.png" },
    { id: 4059, name: "OGC Nice", logo: "logo_1965_OGC_Nice.png" },
    { id: 4230, name: "Stade Reims", logo: "3802_Stade_Reims.png" },
    { id: 5282, name: "PSV Eindhoven", logo: "11_PSV_Eindhoven.png" },
    { id: 6249, name: "B. Leverkusen", logo: "logo_1260_Bayer_04_Leverkusen.png" },
    { id: 8239, name: "Brentford", logo: "logo_1153_Brentford_FC.png" },
    { id: 12294, name: "Olympique Marseille", logo: "logo_1964_Olympique_Marseille.png" },
    { id: 12598, name: "VfB Stuttgart", logo: "logo_1273_VfB_Stuttgart.png" },
    { id: 13058, name: "Feyenoord", logo: "logo_1533_Feyenoord_Rotterdam.png" },
    { id: 16046, name: "Olympique Lyon", logo: "3766_Olympique_Lyon.png" },
    { id: 29455, name: "Nottm Forest", logo: "logo_1152_Nottingham_Forest.png" },
    { id: 29747, name: "Ajax", logo: "9_Ajax_Amsterdam.png" },
    { id: 32429, name: "E. Frankfurt", logo: "logo_1268_Eintracht_Frankfurt.png" },
    { id: 33927, name: "UD Almeria", logo: "708_UD_Almería.png" },
    { id: 38553, name: "Aston Villa", logo: "logo_1138_Aston_Villa.png" },
    { id: 39339, name: "Villarreal", logo: "logo_1714_Villarreal_CF.png" },
    { id: 42798, name: "Cavalry FC", logo: "64336_Cavalry_FC.png" },
    { id: 66, name: "Leicester", logo: "logo_Leicester_City.png" },
    { id: 31367, name: "Sheff Utd", logo: "logo_1159_Sheffield_United.png" },
    { id: 39176, name: "Leeds", logo: "logo_1150_Leeds_United.png" },
    { id: 28212, name: "R. Strasbourg", logo: "logo_1981_RC_Strasbourg_Alsace.png" },
    { id: 24711, name: "Urawa Reds", logo: "logo_2407_Urawa_Red_Diamonds.png" },
    { id: 29065, name: "Sport. Cristal", logo: "15532_Club_Sporting_Cristal.png" },
    { id: 416, name: "Al-Fateh", logo: "16483_Al-Fateh_SC.png" },
    { id: 28879, name: "Athletic", logo: "678_Athletic_Bilbao.png" },
    { id: 10422, name: "Vancouver", logo: "15015_Vancouver_Whitecaps_FC.png" },
    { id: 30849, name: "Atlanta", logo: "logo_2103_Atlanta_United_FC.png" },
    { id: 25037, name: "Montevideo City Torque", logo: "23497_Montevideo_City_Torque.png" },
    { id: 35839, name: "Palmeiras", logo: "5646_Sociedade_Esportiva_Palmeiras.png" },
]

export default VirtualDealDayJanuary2024