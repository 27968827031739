import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer'
import VirtualSummitService from 'api/virtual-summit/virtual-summit.service';
import { userPaths } from 'routes/paths';
import { Actions as StepActions, RegistrationState } from './confirm.controller';
import { Actions as Step2Actions, AttendeeOption } from './step-2.controller';
import { Actions as MiscEventController } from 'app/events/redux/misc.controller'
import { AgencyInvitationModel } from 'api/virtual-summit/models/summit-invitationl';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';
import { getEventInfo } from 'app/events/components/authorized-event-page/events-map/eventsMap';

export const MIN_COUNT_INVITED_AGENT = 3;

class InviteAgentsState {
    agencies: Array<AgencyInvitationModel>;
    selectedAgencies: Array<number>;
    isLoading: boolean;
    disabledAgencies: Array<number>;
}

const defaultState: InviteAgentsState = {
    agencies: null,
    selectedAgencies: null,
    isLoading: false,
    disabledAgencies: []
}

const stateController = new StateController<InviteAgentsState>(
    "VIRTUAL_SUMMIT/INVITE_AGENTS",
    defaultState
);

class Actions {
    public static getData() {
        return async (dispatch, getState: () => AppState) => {

            const state = getState()
            const { eventId } = state.auth;
            let nextStep = RegistrationState.Done;

            let subState = state.virtualSummit.confirmPage;
            dispatch(stateController.setState({ isLoading: true }));
            var res = await VirtualSummitService.getSummitAgencies();
            if(res.agencies.length < MIN_COUNT_INVITED_AGENT) {
                dispatch(StepActions.setStep(nextStep));
                dispatch(stateController.setState({ isLoading: false }));
                return
            }

            dispatch(stateController.setState({ isLoading: false, agencies: res.agencies, selectedAgencies: res.agencies.map(x => x.agencyId) }));
        }
    }

    public static getDataStep() {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let subState = state.virtualSummit.confirmPage;
            dispatch(stateController.setState({ isLoading: true }));
            var res = await VirtualSummitService.getSummitAgencies();

            dispatch(stateController.setState({
                isLoading: false,
                agencies: res.agencies,
                selectedAgencies: res.agencies.map(x => x.agencyId)
            }));
        }
    }

    public static sendAgentInvites() {
        return async (dispatch, getState: () => AppState) => {
            const state = getState();
            let subState = state.virtualSummit.inviteAgents;
            const { eventId } = state.auth;
            let nextStep = RegistrationState.Done;

            dispatch(stateController.setState({ isLoading: true }));
            var res = await VirtualSummitService.sendSummitAgentInvitations(subState.selectedAgencies);
            dispatch(stateController.setState({ isLoading: false }));

            dispatch(StepActions.setStep(nextStep));

            if(subState.selectedAgencies && subState.selectedAgencies[0]) {
                const agencies = subState.agencies
                .filter(i => subState.selectedAgencies.includes(i.agencyId))
                .map(i => i.agencyName)
                .join(', ')
    
                dispatch(userActivityInsert({
                    PageName: 'Event [Registration]',
                    Message: `Invited Agencies: ${agencies}`,
                    PageType: PageType.Events,
                    EventId: eventId
                }))
            }
        }
    }

    public static sendAgentInvitesStep(callback: () => void) {
        return async (dispatch, getState: () => AppState) => {
            const state = getState();
            let subState = state.virtualSummit.inviteAgents;
            const { eventId } = state.auth;
            dispatch(stateController.setState({ isLoading: true }));
            var res = await VirtualSummitService.sendSummitAgentInvitations(subState.selectedAgencies);
            dispatch(stateController.setState({ isLoading: false }));

            if(subState.selectedAgencies && subState.selectedAgencies[0]) {
                const agencies = subState.agencies
                .filter(i => subState.selectedAgencies.includes(i.agencyId))
                .map(i => i.agencyName)
                .join(', ')
    
                dispatch(userActivityInsert({
                    PageName: 'Event [Details]',
                    Message: `Invited Agencies: ${agencies}`,
                    PageType: PageType.Events,
                    EventId: eventId
                }))
            }
            callback();
        }
    }

    public static toggleSelected = (id: number) => {
        return async (dispatch, getState: () => AppState) => {
            let subState = getState().virtualSummit.inviteAgents;
            if (subState.selectedAgencies.indexOf(id) == -1) {
                dispatch(stateController.setState({ selectedAgencies: [...subState.selectedAgencies, id] }))
            }
            else {
                dispatch(stateController.setState({ selectedAgencies: subState.selectedAgencies.filter(i => i != id) }))
            }
        }
    }

    public static deselectAll = () => {
        return async (dispatch, getState: () => AppState) => {
            let subState = getState().virtualSummit.inviteAgents;
            dispatch(stateController.setState({ selectedAgencies: [] }))
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    InviteAgentsState as State,
    Actions as Actions,
    stateController as Controller
};



