//TODO: Remove it after as reat authentication work
import { AppState } from 'root.reducer';
import {
    AUTH_CHECK_EMAIL,
    AUTH_LOGIN,
    ROUTE_CHANGED,
    AUTH_RESTORE,
    AUTH_REFRESH,
    AUTH_LOGIN_SCREEN_DISPOSE,
    AUTH_SET_LANGUAGE,
    AUTH_RESET_LOGIN_ERROR,
    AUTH_LOGOUT_GLOBAL_DISPOSE,
    AUTH_SET_UP_AGENCY_FEATURE,
    AUTH_DEACTIVATE_AGENCY_FEATURE,
    REFRESH_SET_AGENCY,
    AUTH_CHANGE_STEP,
    AUTH_ISBLOCKED_CHANGE_STEP,
    AUTH_ISBLOCKED_LOADING,
    AUTH_SET_ERRORS,
    AUTH_GENERATE_CHECK_EMAIL_TOKEN,
    AUTH_META,
    AUTH_HIDE_TERMS_AND_CONDITIONS, AUTH_LOGIN_SCREEN_CLEANUP
} from '../actionTypes';
import AuthState, { AuthStep } from './auth-state';
import { UserTypeEnum, CoachAccessSubscriptionEnum } from "constants/enums";
import { getIsRestrictedEUUKFreemium } from 'utils/restrictions';

interface AuthReducerState extends AuthState {
    checkEmailToken: any;
}

let INITIAL_STATE: AuthReducerState = {
    // Auth flow state
    currentStep: AuthStep.Email,
    triedToLogIn: false,
    loading: false,
    isSuccessful: null,
    serverError: {
        emailError: false,
        authError: false,
        authErrorMessage: ''
    },

    // User properties
    userId: null,
    userEmail: '',
    userFirstName: '',
    userFullName: '',
    userLastName: '',
    userPositionName: '',
    phone: '',
    phoneCodeAreaId: null,
    clubId: null,
    clubName: '',
    clubLogo: '',
    squadId: null,
    agencyId: null,
    agencyName: '',
    agencyLogo: '',
    agencyBackgroundImage: '',
    agentId: null,
    squadIds: [],
    squadIdWithApiIds: [],
    areaId: null,
    areaName: '',
    competitionId: null,
    competitionName: '',
    userTypeId: 0,
    aspNetUserId: '',
    currency: null,
    languageId: null,
    languageCode: '',
    // userIdFromUrlWorkaround: null,
    defaultSeason: new Date().getFullYear(),
    adminImpersonateId: null,
    playerId: null,
    eventId: null,
    userEventIds: [],

    clubPermission: null,
    agencyPermission: null,

    // User permitions
    allowCreateNewMessage: false,
    allowedAdminPages: [],
    mySquadPageVisible: false,
    playerSearchPageVisible: false,
    shortListPageVisible: false,
    playerAdsPageVisible: false,
    clubAdsPageVisible: false,
    messagesPageVisible: false,
    friendlyMatchesPageVisible: false,
    eventsPageVisible: false,
    plusPitchPageVisible: false,
    loanInsightsPageVisible: false,
    homePageVisible: false,
    loanInsights: false,
    isAgentsVisible: false,
    isAgencySubscribed: false,
    adminPersonalizeId: null,
    homePageHighPriorityRecommendationMakePlayersAvailable: false,
    homePageHighPriorityRecommendationVisible: false,
    homePagePerformancePlayerAvailabilityVisible: false,
    homePagePerformanceIndicatorVisible: false,
    homePageWellDialogAddUserVisible: false,
    homePageWellDialogFriendlyMatchVisible: false,
    homePageWellDialogPlayerAvailabilityVisible: false,
    homePageWellDialogPlayerPitchVisible: false,
    homePagePerformanceFriendlyAdsAmountVisible: false,
    homePagePerformancePlayerPitchesAmountVisible: false,
    homePagePlayerTrendingVisible: false,
    isAgentsFreeTrialActive: false,
    isLeague: false,
    isFaPlayerPointsVisible: false,
    isFreemiumSubscription: false,
    isMasterAgent: false,
    isSpecialAgencyUser: false,
    isSubscriptionEntryPackage: false,
    pitchTestInterestTimeframe: null,
    financialInsightsPageVisible: false,
    pitchInsightsSubscription: null,
    metaInfo: null,
    checkEmailToken: null,
    isBlocked: false,
    isResetPasswordOnNextLogon: false,
    termsAgreed: false,
    finishedRegistration: false,
    showEventRegistrationModal: false,
    isEvent: false,

    active: false,
    clubOldApiId: null,
    debug: '',
    errorMessage: null,
    userTypeName: '',
    logoImageFullPath: '',
    oldUserId: null,
    shortClubName: '',
    subscriptionPlan: null,
    showTermsAndConditionsModal: false,
    isNewPlayerForReferralBanner: false,
    coachId: null,
    isUnblockAccountLoading: false,
    isJustReleasedPlayersPageVisible: false,
};

export default (auth = INITIAL_STATE, action): AuthReducerState => {
    switch (action.type) {

        case AUTH_CHANGE_STEP: {
            return {
                ...auth,
                isBlocked: false,
                currentStep: action.payload
            }
        }
        case AUTH_LOGIN_SCREEN_CLEANUP: {
            return {
                ...INITIAL_STATE
            }
        }
        case AUTH_ISBLOCKED_CHANGE_STEP: {
            return {
                ...auth,
                isBlocked: true,
                currentStep: action.payload
            }
        }
        case AUTH_ISBLOCKED_LOADING: {
            return {
                ...auth,
                isUnblockAccountLoading: action.payload
            }
        }
        case AUTH_SET_LANGUAGE: {
            return { ...auth, languageCode: action.payload.code, languageId: action.payload.id }
        }
        case REFRESH_SET_AGENCY.SUCCESS: {
            return { ...auth, isAgentsFreeTrialActive: true }
        }

        case AUTH_SET_ERRORS: {
            return {
                ...auth,
                serverError: action.payload
            }
        }
        case AUTH_RESET_LOGIN_ERROR: {
            return {
                ...auth,
                serverError: {
                    emailError: false,
                    authError: false,
                    authErrorMessage: ''
                }
            }
        }

        //  GENERATE CHECK EMAIL TOKEN
        case AUTH_GENERATE_CHECK_EMAIL_TOKEN.REQUEST: {
            return {
                ...auth,
                loading: true
            }
        }
        case AUTH_GENERATE_CHECK_EMAIL_TOKEN.SUCCESS: {
            return {
                ...auth,
                loading: false,
                checkEmailToken: action.payload.data.value
            }
        }

        //  VALIDATE EMAIL
        case AUTH_CHECK_EMAIL.REQUEST: {
            return {
                ...auth,
                loading: true
            }
        }
        case AUTH_CHECK_EMAIL.SUCCESS: {
            return {
                ...auth,
                loading: false,
            }
        }

        case AUTH_LOGIN.REQUEST: {
            return {
                ...auth,
                loading: true,
            };
        }
        case AUTH_LOGIN.SUCCESS:
        case AUTH_REFRESH.SUCCESS:
        case AUTH_META.SUCCESS: {

            const newAuth: AuthState = action.payload.data

            return newAuth.isSuccessful
                ? {
                    ...auth,
                    ...newAuth,
                    serverError: {
                        ...INITIAL_STATE.serverError,
                        authError: !!newAuth.errorMessage,
                        authErrorMessage: newAuth.errorMessage
                    },
                }
                : {
                    ...auth,
                    loading: false,
                    isBlocked: newAuth.isBlocked,
                    serverError: {
                        ...INITIAL_STATE.serverError,
                        authError: true,
                        authErrorMessage: newAuth.errorMessage
                    }
                };
        }
        case AUTH_LOGIN.ERROR: {
            return {
                ...auth,
                serverError: {
                    ...INITIAL_STATE.serverError,
                    authError: true,
                    authErrorMessage: action.payload.data.errorMessage || ''
                },
            };
        }
        case AUTH_LOGIN_SCREEN_DISPOSE: {
            return {
                ...auth,
                triedToLogIn: false,
                loading: false,
                serverError: {
                    ...INITIAL_STATE.serverError
                },
            };
        }
        case AUTH_LOGOUT_GLOBAL_DISPOSE: {
            return INITIAL_STATE;
        }
        case AUTH_RESTORE: {
            const localAuth = action.payload || {};
            return localAuth.userId ? { ...localAuth } : auth;
        }
        case AUTH_SET_UP_AGENCY_FEATURE: {
            return {
                ...auth,
                isAgentsFreeTrialActive: true,
            };
        }
        case AUTH_DEACTIVATE_AGENCY_FEATURE: {
            return {
                ...auth,
                isAgentsFreeTrialActive: false,
            };
        }
        case AUTH_HIDE_TERMS_AND_CONDITIONS: {
            return {
                ...auth,
                showTermsAndConditionsModal: false,
            };
        }
        case ROUTE_CHANGED:
        default:
            return auth;
    }
};

export const getAuth = (state: AppState) => state.auth;
export const getAuthCurrentStep = state => state.auth.currentStep;
export const getAuthLoading = state => getAuth(state).loading;

export const getAuthServerEmailError = state => getAuth(state).serverError.emailError;
export const getAuthServerEmailErrorMessage = state => (getAuth(state).serverError as any).emailErrorMessage;
export const getAuthServerError = state => getAuth(state).serverError.authError;
export const getAuthServerErrorMessage = state => getAuth(state).serverError.authErrorMessage;

export const getAuthUserId = state => getAuth(state).userId;
export const getAuthUserEmail = state => getAuth(state).userEmail;
export const getDefaultSeason = state => getAuth(state).defaultSeason;
export const getAuthAspId = state => getAuth(state).aspNetUserId;
export const getAuthSquadId = state => getAuth(state).squadId;
export const getAuthClubId = state => getAuth(state).clubId;
export const getAuthPlayerId = state => getAuth(state).playerId;

export const hasAgentFeature = state => getAuth(state).isAgentsFreeTrialActive;
export const isAgencySubscribed = state => getAuth(state).isAgencySubscribed;

export const getAuthCurrency = state => getAuth(state).currency;
export const isPlayerUser = state => getAuth(state).userTypeId === UserTypeEnum.Player;
export const isAgencyUser = state => getAuth(state).userTypeId === UserTypeEnum.Agency;
export const isHeadCoachUser = state => getAuth(state).userTypeId === UserTypeEnum.Staff;
export const isLowerTierAgency = state => getAuth(state).agencyPermission.isLowerTier;
export const getCoachAccess = state => getAuth(state).agencyPermission.coachAccess;
export const isFullCoachAccess = state => getAuth(state).agencyPermission.coachAccess === CoachAccessSubscriptionEnum.Full ;
export const isTransparencyPermitted = state => getAuth(state).agencyPermission.transparency;
export const isCoachAccess = state => getAuth(state).agencyPermission.coachAccess === CoachAccessSubscriptionEnum.Database ||
    getAuth(state).agencyPermission.coachAccess === CoachAccessSubscriptionEnum.Full;
export const isPitchCoachAccess = state => getAuth(state).agencyPermission.coachAccess === CoachAccessSubscriptionEnum.Full;
export const isRestrictedEUUKFreemium = state => getIsRestrictedEUUKFreemium(getAuth(state).subscriptionPlan);
export const isPlayerMarketPermissionEnabled = state => getAuth(state).agencyPermission.agencyPlayerMarket;

export const isClubUser = state =>
    [UserTypeEnum.MasterUser, UserTypeEnum.AdditionalUser, UserTypeEnum.FriendlyMatchSupervisor, UserTypeEnum.SummitParticipant].includes(getAuth(state).userTypeId);
export const isLoggedInUser = state => !!getAuth(state).userId;
