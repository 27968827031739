import axios from 'axios-config'
import { getResetPasswordLinkData } from 'api/admin/reset-password-link/models'


export class AdminResetPasswordLinkService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    }

    public static async getResetPasswordLinkAsync(email: string, linkType: number): Promise<getResetPasswordLinkData>  {
        const { data } = await axios.post(
            `api/AdminConfirmationLink/GetConfirmationLink/`,
            {
                Email: email,
                LinkType: linkType,
            },
            {...AdminResetPasswordLinkService.axiosConfig}
        )
        return data
    }
}