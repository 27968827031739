import { StateController } from 'utils/action-declaration' 
import { AppState } from 'root.reducer';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import {PageType, UserTypeEnum} from 'constants/enums';
import Service from 'api/virtual-summit/virtual-summit.service'
import { DeclinedEventRegistrationType } from 'api/virtual-summit/models/declined-event-registration';
import { getAuth } from 'store/auth/authReducer';
import { userPaths } from 'routes/paths';
import historyAccessor from 'history-accessor';
import { Actions as SpeakerCardActions } from 'pages/landing-page/redux/speaker-card.controller';
import moment from 'moment';
import { MADRID_SUMMIT_DEAL_DAY_16TH_JUNE_2022_ID } from 'app/events/components/authorized-event-page/events-map/constants';

const LOCAL_STORAGE_KEY = "eventRegistrationModal";

export class EventRegistrationModalState {
    isOpen: boolean;
    step: number;
    showBackButton: boolean;
    isDeclineInProgress: boolean;
}

interface ERM_LocalStorageInfo {
    shownAt: number;
}

const defaultState: EventRegistrationModalState = {
    isOpen: false,
    step: 1,
    showBackButton: true,
    isDeclineInProgress: false
}

const stateController = new StateController<EventRegistrationModalState>(
    "EVENT_REGISTRATION_MODAL",
    defaultState
);

class Actions {
    public static init() {
        return (dispatch, getState: () => AppState) => {
            const auth = getAuth(getState());
            const { showEventRegistrationModal, eventId } = auth;

            let isModalCanBeShown = showEventRegistrationModal && eventId == MADRID_SUMMIT_DEAL_DAY_16TH_JUNE_2022_ID;
            if (isModalCanBeShown) {
                let localStorageInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || {};
                const info: ERM_LocalStorageInfo = localStorageInfo[auth.userId] || {};
                if(info.shownAt)
                {
                    isModalCanBeShown = moment(info.shownAt).add(1, 'days').diff(Date.now()) < 0;
                }

                if(isModalCanBeShown){
                    info.shownAt = Date.now();
                    localStorageInfo[auth.userId] = info;

                    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(localStorageInfo));
                }
            }

            dispatch(stateController.setState((prevState) => ({ 
                ...prevState, 
                isOpen: isModalCanBeShown
            })));

            if(isModalCanBeShown) {
                dispatch(userActivityInsert({
                    Message: 'Opened',
                    PageName: 'Event Registration Modal',
                    PageType: PageType.Events,
                    EventId: auth.eventId
                }))
            }
        }
    }

    public static close() {
        return (dispatch, getState: () => AppState) => {
            const auth = getAuth(getState());

            dispatch(stateController.setState((prevState) => ({ 
                ...prevState, 
                isOpen: false 
            })));

            dispatch(userActivityInsert({
                Message: 'Closed',
                PageName: 'Event Registration Modal',
                PageType: PageType.Events,
                EventId: auth.eventId
            }))
        }
    }

    public static goToNoThanksStep() {
        return (dispatch, getState: () => AppState) => {
            const auth = getAuth(getState());

            dispatch(userActivityInsert({
                Message: 'No Thanks',
                PageName: 'Event Registration Modal',
                PageType: PageType.Events,
                EventId: auth.eventId
            }));

            dispatch(stateController.setState((prevState) => ({ 
                ...prevState, 
                step: 2 
            })));
        }
    }

    public static goBack() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => ({ 
                ...prevState, 
                step: 1
            })));
        }
    }

    public static register() {
        return (dispatch, getState: () => AppState) => {
            const auth = getAuth(getState());

            dispatch(stateController.setState((prevState) => ({ 
                ...prevState, 
                isOpen: false 
            })));
            dispatch(userActivityInsert({ 
                Message: 'Opened Registration', 
                PageName: 'Event Registration Modal',
                PageType: PageType.Events,
                EventId: auth.eventId
            }))
            historyAccessor.push(userPaths.eventRegistration)
        }
    }

    public static declineEventRegistration(declinedEventRegistrationType: DeclinedEventRegistrationType, otherReason: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const auth = getAuth(getState());

                dispatch(stateController.setState((prevState) => ({ 
                    ...prevState, 
                    isDeclineInProgress: true
                })));

                const payload = {
                    declinedEventRegistrationType,
                    otherReason
                };
                await Service.declineEventRegistration(payload);

                dispatch(SpeakerCardActions.hide());
            
                dispatch(userActivityInsert({ 
                    Message: 'Event Registration Declined', 
                    PageName: 'Event Registration Modal',
                    PageType: PageType.Events,
                    EventId: auth.eventId
                }))

                dispatch(stateController.setState((prevState) => ({ 
                    ...prevState, 
                    isOpen: false 
                })));

            } catch (e) {
                console.error(e);
            } finally {
                dispatch(stateController.setState((prevState) => ({ 
                    ...prevState, 
                    isDeclineInProgress: false 
                })));
            }
        };
    }
    public static sendUserActivity() {
        return async (dispatch, getState: () => AppState) => {
            const isAgentPage = getState().auth.userTypeId === UserTypeEnum.Agency;
            dispatch(userActivityInsert({
                PageName: 'Search [Event banner]',
                Message: isAgentPage ? 'Register Interest' : 'Register Now',
                EventId: getState().auth.eventId,
                PageType: PageType.Search,
            }))
        }
    }
    public static dismissSpeakerCard(isAgentPage: boolean) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const { eventId } = getAuth(getState());
                dispatch(stateController.setState((prevState) => ({ 
                    ...prevState, 
                    isOpen: true,
                    step: 2,
                    showBackButton: false
                })));

                dispatch(userActivityInsert({
                    PageName: isAgentPage ? "Agency Home [Event card Speaker]" : "Home [Event card Speaker]",
                    Message: "Not Attending",
                    PageType: isAgentPage ? PageType.AgencyHome : PageType.Home,
                    EventId: eventId
                }))
            } catch (e) {
                console.error(e);
            } finally {
            }
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    EventRegistrationModalState as State,
    Actions as Actions,
    stateController as Controller
};


