import { USER_PREFERENCE_FETCH, AGENCY_LANDING_MAKE_AS_VIEWED } from 'store/actionTypes';
import createApiThunk from 'services/createApiThunk';
import { setLocaleToState, updateLocaleByLangId } from 'services/localization'

export function loadUserPreferenceInfo() {
    return async (dispatch, getState) => {

        const request = {
            type: USER_PREFERENCE_FETCH,
            method: 'get',
            url: `Permission/UserPreference`,
            success: USER_PREFERENCE_FETCH.SUCCESS,
        };
        const data = await dispatch(createApiThunk(request));
    
        if (data && data.languageId) {
            dispatch(updateLocaleByLangId(data.languageId));
            dispatch(setLocaleToState(data.languageId));
        }
    }
}

export function hideNewIndicator() {
    return async (dispatch, getState) => {
        dispatch({type: AGENCY_LANDING_MAKE_AS_VIEWED})
    }
}
