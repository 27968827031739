import axios from 'axios-config';


export class Permissions {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async sendPermissionRequest(squadId: number, userId: number, buttonName: string, pageName: string) {
        const { data } = await axios.post(
            `api/Permission/SendPermissionRequest/${squadId}/${userId}/${buttonName}/${pageName}`,
            {},
            this.axiosConfig
        );
        return data;
    }
}
