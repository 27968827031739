import { translate } from 'services/localization';
import { SuggestionModel } from '../models/suggestion.model';
import { SuggestionType } from 'api/player/player-search';
import { PlayerComparisonModel } from '../models/player-comparison.model';
import { ComparedPlayerModel } from '../models/compared-player.model';

export function formatPlayerSuggestion(player: SuggestionModel) {
    if (player && player.suggestionType === SuggestionType.League) {
        return player.competitionName;
    }
    if (!player || !player.englishShortName) {
        return null;
    }
    const res = [
        `${player.englishShortName}${player.firstPositionCode ? ', ' : ''}`,
        player.firstPositionCode ? translate(`positionAbbreviations.${player.firstPositionCode}`) : '',
        player.secondPositionCode ? `(${translate(`positionAbbreviations.${player.secondPositionCode}`)})` : '',
    ].join(' ');
    return res;
}

export function formatPlayer(player: ComparedPlayerModel) {
    if (!player || !player.englishShortName) {
        return null;
    }
    const res = [
        `${player.englishShortName}${player.firstPositionCode ? ', ' : ''}`,
        player.firstPositionCode ? translate(`positionAbbreviations.${player.firstPositionCode}`) : ''
    ].join(' ');
    return res;
}

export function formatAverage(player: { firstPositionCode: string }, comparison: PlayerComparisonModel) {
    if (!comparison.average) return '';
    return [
        translate('playerComparison.formatPlayer.avg'),
        player.firstPositionCode ? ', ' : '',
        player.firstPositionCode ? translate(`positionAbbreviations.${player.firstPositionCode}`) : '',
        competitionIsAvailable() ? ', ' : '',
        competitionIsAvailable()
            ? comparison.average.season.competitionName
            : '',
    ].join('');

    function competitionIsAvailable() {
        return !!comparison && comparison.average && comparison.average.season;
    }
}
