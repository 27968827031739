import React from 'react'
import historyAccessor from "history-accessor"
import { useEffect } from "react"

class LiveChatController {
    private static countHide = 0;
    private static classNameSelector = '#hubspot-messages-iframe-container'

    public static hide = (): Promise<any> => {
        const hideWhenAppear = (resolve: (value?: any) => void) => {
            let count = 0
            let x = setInterval(() => {
                let chatComponent: HTMLElement = document.querySelector(LiveChatController.classNameSelector)
                count = count + 1
                if (chatComponent !== null) {
                    chatComponent.style.transform = 'translate(100%, 0%)';
                    clearInterval(x)
                    resolve()
                } else if (count > 30) { // If there is no component in progect during (20ms * 500ms = 10) seconds, break and resolve
                    clearInterval(x)
                    resolve()
                }
                LiveChatController.countHide++;
            }, 500)
        }
        return new Promise(resolve => hideWhenAppear(resolve))
    }
    public static show = () => {
        let chatComponent: HTMLElement = document.querySelector(LiveChatController.classNameSelector)
        
        if (LiveChatController.countHide > 0) {
            LiveChatController.countHide = 0;
        }
        
        if (chatComponent !== null && LiveChatController.countHide === 0) {
            chatComponent.style.transform = 'translate(0%, 0%)';
        }
    }

    public static deleteFromDOM = () => {
        document.querySelector(LiveChatController.classNameSelector).remove()
    }

    public static isVisible = () => {
        const chatComponent = document.querySelector(LiveChatController.classNameSelector)
        if (!chatComponent) return false

        const transform = window.getComputedStyle(document.querySelector(LiveChatController.classNameSelector)).transform
        var matrix = new WebKitCSSMatrix(transform);
        const value = matrix.m41

        if (value > 0) {
            return false
        } else {
            return true
        }
    }

    public static openConversation = () => {
        const status = (window as any).HubSpotConversations.widget.status()
        if (status.loaded && !status.pending) {
            (window as any).HubSpotConversations.widget.open();
        }

    }
}



export const LiveChatCoordinator = () => {

    const setVisibility = () => {

        // If chat is not declared yet
        if (!(window as any).HubSpotConversations) return

        // Handle routing rules
        const isAdminRoute = historyAccessor.location.pathname.startsWith('/admin');
        if (isAdminRoute) {
            (window as any).HubSpotConversations.widget.remove();
        }
    }

    useEffect(() => {
        setVisibility()
    }, [historyAccessor.location.pathname, (window as any).HubSpotConversations])

    return (<div></div>)
}

export default LiveChatController