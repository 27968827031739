import _ from 'lodash'
import { copyToClipboard as copy } from 'utils/copy-to-clipboard';
import { AppState } from "root.reducer";
import { StateController } from "utils/action-declaration";
import { isValid } from "utils/validation";
import { notificationCreate } from 'app/notifications/notifications.actions'
import { PlayerOnboardingService } from 'api/admin/onboard-player/player-onboarding.service'
import { AdminPlayerOnboardRequest, PlayerDataModel, PlayerSuggestionItem, ReferralModel } from 'api/admin/onboard-player/models';
import { getCancelTokenSource } from 'axios-config'

class State {
    isModalOpen: boolean;
    keyword: string;
    isProcessing: boolean;
    playerData: PlayerDataModel;
    suggestionItems: PlayerSuggestionItem[];
    isDataReceived: boolean;
    isSaving: boolean;
    isPhoneInvalid: boolean;
    isPhoneCodeInvalid: boolean;
    isFormValid: boolean;
    isEmailExist: boolean;

    referralData: ReferralModel;
}

const defaultState: State = {
    isModalOpen: false,
    keyword: '',
    isProcessing: false,
    suggestionItems: [],
    isDataReceived: false,
    playerData: {
        playerId: null,
        isAlreadyRegistered: false,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        phoneCodeAreaId: null,
        onboardingLink: '',
        isYoungPlayer: false,
    },
    isSaving: false,
    isFormValid: false,
    isPhoneInvalid: false,
    isPhoneCodeInvalid: false,
    isEmailExist: false,

    referralData: {
        link: '',
        isLinkValid: true,
        showValidationResult: false,
        referralPlayer: {
            playerId: null,
            playerName: '',
            squadId: null,
            squadName: '',
            isSuccess: false,
        },
        processing: false,
    }
};

const stateController = new StateController<State>(
    "ADMIN_V2/ONBOARD_PLAYER_MODAL",
    defaultState
);

class Actions {
    public static cancelToken = null

    public static openModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isModalOpen: true }));
        };
    }

    public static closeModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.dispose());
        };
    }

    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState({ ...defaultState }));
        };
    }


    public static onEmailChange(email: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                playerData: {
                    ...prevState.playerData,
                    email
                },
                isEmailExist: false
            })));

            dispatch(Actions.validateForm())
        };
    }

    public static onPlayerUserDataChange(userData: { [K in keyof PlayerDataModel]?: PlayerDataModel[K] }) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                playerData: {
                    ...prevState.playerData,
                    ...userData,
                },
            })));

            dispatch(Actions.validateForm())
        };
    }

    public static validateForm() {
        return async (dispatch, getState: () => AppState) => {
            const { playerData } = Selectors.getRoot(getState());
            const {
                playerId,
                email,
                phoneCodeAreaId,
                phone,
                isYoungPlayer
            } = playerData;

            const playerSelected = !!playerId && !isYoungPlayer;
            const emailValid = isValid.email(email);
            const phoneValid = !!phone;
            const phoneCodeAreaIdValid = !!phoneCodeAreaId;
            const phoneInfoValid = phoneValid && phoneCodeAreaIdValid || !phoneValid && !phoneCodeAreaIdValid;

            let isFormValid =
                playerSelected &&
                emailValid &&
                phoneInfoValid;

            dispatch(stateController.setState({
                isFormValid: isFormValid,
                isPhoneInvalid: phoneCodeAreaIdValid && !phoneValid,
                isPhoneCodeInvalid: phoneValid && !phoneCodeAreaIdValid
            }));
        };
    }

    public static onKeywordChange(value: string) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ keyword: value }))
            if (value) {
                Actions.loadSuggestionsDebounceFunc(dispatch, value)
            } else {
                dispatch(stateController.setState({ suggestionItems: [] }))
            }
        }
    }

    private static loadSuggestionsDebounceFunc = _.debounce((dispatch, keyword) => dispatch(Actions.loadPlayerSuggestions(keyword)), 1000)

    public static copyToClipboard() {
        return (dispatch, getState: () => AppState) => {
            const { playerData } = Selectors.getRoot(getState());
            copy(playerData.onboardingLink)
            dispatch(notificationCreate({ message: 'Copied to clipboard', level: 'info' }))
        }
    }

    public static loadPlayerSuggestions(keyword: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isProcessing: true }))

                if (Actions.cancelToken)
                    Actions.cancelToken.cancel()

                Actions.cancelToken = getCancelTokenSource();

                const data = await PlayerOnboardingService.getPlayerOnboardingSuggestions(keyword, Actions.cancelToken.token)
                dispatch(stateController.setState({ suggestionItems: data }))
            } finally {
                dispatch(stateController.setState({ isProcessing: false }))
            }
        }
    }

    public static loadPlayerData(player: any) {
        return async (dispatch, getState: () => AppState) => {
            const data = await PlayerOnboardingService.getPlayerUserData(player.id);
            dispatch(stateController.setState({ playerData: data, isDataReceived: true }))
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                referralData: {
                    ...prevState.referralData,
                    link: '',
                    isLinkValid: true,
                    showValidationResult: false,
                    referralPlayer: {
                        playerId: null,
                        playerName: '',
                        squadId: null,
                        squadName: '',
                        isSuccess: false,
                    },
                }
            })))
        }
    }

    public static onboardPlayer() {
        return async (dispatch, getState: () => AppState) => {
            const { playerData, referralData } = Selectors.getRoot(getState());

            try {
                dispatch(stateController.setState({ isSaving: true }))
                const args: AdminPlayerOnboardRequest = {
                    playerId: playerData.playerId,
                    email: playerData.email,
                    phone: playerData.phone,
                    phoneCodeAreaId: playerData.phoneCodeAreaId,
                    refPlayerId: referralData?.referralPlayer?.playerId
                };
                const data = await PlayerOnboardingService.onboardPlayer(args);

                const { onboardingUrl, isEmailExist, isSuccess } = data;
                if (isSuccess) {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        playerData: {
                            ...prevState.playerData,
                            onboardingLink: onboardingUrl,
                            isAlreadyRegistered: true
                        }
                    })))
                } else {
                    dispatch(stateController.setState({ isEmailExist }))
                }
            } finally {
                dispatch(stateController.setState({ isSaving: false }))
            }
        }
    }

    public static onReferralLinkChange(value: string) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                referralData: {
                    ...prevState.referralData,
                    link: value,
                    isLinkValid: true,
                    showValidationResult: false,
                }
            })))
        }
    }

    public static validateLink() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                referralData: {
                    ...prevState.referralData,
                    processing: true,
                }
            })))
            try {
                const link = Selectors.getRoot(getState()).referralData.link;

                if (link.length > 0) {
                    const player = await PlayerOnboardingService.getPlayerByLink(link);
                    if (player.isSuccess) {
                        dispatch(stateController.setState(prevState => ({
                            ...prevState,
                            referralData: {
                                ...prevState.referralData,
                                referralPlayer: player,
                            }
                        })))
                    } else {
                        dispatch(stateController.setState(prevState => ({
                            ...prevState,
                            referralData: {
                                ...prevState.referralData,
                                isLinkValid: false,
                            }
                        })))
                    }

                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        referralData: {
                            ...prevState.referralData,
                            showValidationResult: true,
                        }
                    })))
                }
            } catch (error) {
                console.error(error)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    referralData: {
                        ...prevState.referralData,
                        processing: false,
                    }
                })))
            }
        }

    }
}

class Selectors {
    public static getRoot = (state: AppState): State => state.admin.onboardPlayerModal;
    public static getItemsForAutocomplete = (state: AppState) => {
        const { suggestionItems } = Selectors.getRoot(state);

        if (!suggestionItems)
            return [];

        return suggestionItems.map(x => ({
            value: x.id,
            player: x
        }));
    }
    public static getPlayerValidationResult = (state: AppState) => {
        const isPlayerSelected = Selectors.getRoot(state).playerData.playerId !== null
        const isYongPlayer = Selectors.getRoot(state).playerData.isYoungPlayer;
        
        return isYongPlayer && isPlayerSelected ? true : false
    }    
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State,
    Actions,
    Selectors,
    stateController as Controller,
};
