import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import AgencyCustomPitchService from 'api/agency/custom-pitch/custom-pitch.service'
import { ListResponse, CustomPitchSent } from 'api/agency/custom-pitch/models'
import { createSelector } from 'reselect';
import * as CreatePitchController from './create-pitch.controller';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';
import { SORT_PLAYER_BY, SORT_OPEN_BY, SORT_CLOSED_BY, sortPlayerParams, sortParamsOpened, sortParamsClosed } from '../../tabs/custom-pitch/constants';

export enum CustomPitchMode {
    PitchView = 0,
    Creating = 1,
}

export class Indicators {
    totalViews: number;
    totalShares: number;
    interests: number;
    viewsLast7Days: number;
    sharesLast7Days: number;
    interestsLast7Days: number;
}

class AgencyCustomPitchState {
    isLoading: boolean;
    sentPitches: Array<CustomPitchSent>;
    mode: CustomPitchMode;
    indicators: Indicators;
    selectPlayerId?: number;
    selectedActivePitchSquadIds: Array<number>;
    playerGridSortBy: string;
    activePitchGridSortBy: string;
    closedPitchGridSortBy: string;
    changeStatusDropdownOpenedPitchSquadId?: number;
}

const defaultState: AgencyCustomPitchState = {
    isLoading: false,
    selectPlayerId: null,
    sentPitches: [],
    mode: CustomPitchMode.PitchView,
    indicators: {
        totalViews: 0,
        totalShares: 0,
        interests: 0,
        viewsLast7Days: 0,
        sharesLast7Days: 0,
        interestsLast7Days: 0,
    },
    selectedActivePitchSquadIds: [],
    playerGridSortBy: SORT_PLAYER_BY.VIEWS,
    activePitchGridSortBy: SORT_OPEN_BY.VIEWS,
    closedPitchGridSortBy: SORT_CLOSED_BY.LATEST_CLOSED,
    changeStatusDropdownOpenedPitchSquadId: null,
}

const stateController = new StateController<AgencyCustomPitchState>("AGENCY/CUSTOM_PITCH/GRID", defaultState);

class Actions {
    
    public static initSource() {
        return async (dispatch) => {
            await dispatch(Actions.getCustomPitch());
            dispatch(userActivityInsert({
                PageName: 'Custom Pitch',
                Message: 'Opened Page',
                PageType: PageType.Pitch,
            }))
        }
    }

    public static selectPitch(id: number) {
        return (dispatch, getState: () => AppState) => {
            const { selectedActivePitchSquadIds } = Selectors.getRoot(getState());

            if(selectedActivePitchSquadIds.includes(id)){
                dispatch(stateController.setState({
                    selectedActivePitchSquadIds: selectedActivePitchSquadIds.filter(i => i !== id)
                }))
            } else {
                dispatch(stateController.setState({
                    selectedActivePitchSquadIds: [...selectedActivePitchSquadIds, id]
                }))

                const { pitchedPlayer } = Selectors.getActiveSelectedPlayer(getState());
                const playerItems = Selectors.getActiveSelectedPlayerItems(getState());
                const { squad } = playerItems.find(x => x.customPitchSentSquadId === id);

                dispatch(userActivityInsert({
                    PageName: 'Custom Pitch [Players pitches]',
                    Message: `Selected active pitch '${squad.name}'`,
                    PlayerId: pitchedPlayer.id,
                    ClubId: squad.id,
                    PageType: PageType.Pitch,
                }))
            }
        }
    }

    public static selectAllPitch() {
        return (dispatch, getState: () => AppState) => {
            const { sentPitches, selectedActivePitchSquadIds } = Selectors.getRoot(getState());

            const ids = sentPitches
            .map(i => i.sentSquads)
            .flat()
            .map(i => i.customPitchSentSquadId);

            if(ids.length === selectedActivePitchSquadIds.length) {
                dispatch(stateController.setState({
                    selectedActivePitchSquadIds: []
                }))
            } else {
                dispatch(stateController.setState({
                    selectedActivePitchSquadIds: ids
                }))

                const player = Selectors.getActiveSelectedPlayer(getState());
                dispatch(userActivityInsert({
                    PageName: 'Custom Pitch [Players pitches]',
                    Message: 'Selected All Active Pitches',
                    PlayerId: player.pitchedPlayer.id,
                    PageType: PageType.Pitch,
                }))
            }
        }
    }

    public static toggleState(mode: CustomPitchMode) {
        return (dispatch) => {
            dispatch(stateController.setState({ mode }));
        }
    }

    public static openCreatingPage() {
        return (dispatch) => {
            dispatch(Actions.toggleState(CustomPitchMode.Creating));
            dispatch(userActivityInsert({
                PageName: 'Custom Pitch',
                Message: 'Create Custom Pitch',
                PageType: PageType.Pitch,
            }))
        }
    }

    public static openCreatingPageForPlayer(playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            await dispatch(CreatePitchController.Actions.getPlayers());
            dispatch(Actions.toggleState(CustomPitchMode.Creating));
            dispatch(CreatePitchController.Actions.selectPlayer(playerId));

            const selectedPlayer = CreatePitchController.Selectors.getSelectedPlayer(getState());
            dispatch(userActivityInsert({
                PageName: 'Custom Pitch',
                Message: `Create New Pitch '${selectedPlayer.name}'`,
                PlayerId: playerId,
                PageType: PageType.Pitch,
            }))
        }
    }

    public static closeCreatingPage() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.toggleState(CustomPitchMode.PitchView));
            dispatch(CreatePitchController.Actions.dispose());

            const { playerId, recipientsSquadId } = CreatePitchController.Selectors.getSession(getState());
            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Creation]',
                Message: 'Cancelled Custom Pitch',
                PlayerId: playerId,
                ClubId: recipientsSquadId,
                PageType: PageType.Pitch,
            }))
        }
    }

    public static getCustomPitch() {
        return async (dispatch) => {
            dispatch(stateController.setState({ isLoading: true }))
            const data: ListResponse = await AgencyCustomPitchService.list();
            const { counters } = data;
            dispatch(stateController.setState({
                sentPitches: data.sentPitches,
                indicators: {
                    totalViews: counters.views,
                    totalShares: counters.shares,
                    interests: counters.interests,
                    viewsLast7Days: counters.viewsLast7Days,
                    sharesLast7Days: counters.sharesLast7Days,
                    interestsLast7Days: counters.interestsLast7Days,
                },
                isLoading: false
            }))
        }
    }

    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState({ ...defaultState }))
        }
    }

    public static manageActivePitches(selectPlayerId: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.setPlayer(selectPlayerId))

            const player = Selectors.getActiveSelectedPlayer(getState());
            dispatch(userActivityInsert({
                PageName: 'Custom Pitch',
                Message: `Manage Active Pitches (${player.pitchedPlayer.englishShortName})`,
                PlayerId: selectPlayerId,
                PageType: PageType.Pitch,
            }))
        }
    }

    public static viewClosedPitches(selectPlayerId: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.setPlayer(selectPlayerId))
            const player = Selectors.getClosedSelectedPlayer(getState());

            dispatch(userActivityInsert({
                PageName: 'Custom Pitch',
                Message: `View Closed Pitches (${player.pitchedPlayer.englishShortName})`,
                PlayerId: selectPlayerId,
                PageType: PageType.Pitch,
            }))
        }
    }

    public static selectPlayer(selectPlayerId: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.setPlayer(selectPlayerId))

            const player = Selectors.getSelectedPlayer(getState())[0];
            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Players pitches]',
                Message: `Selected Player (${player.pitchedPlayer.englishShortName})`,
                PlayerId: selectPlayerId,
                PageType: PageType.Pitch,
            }))
        }
    }

    private static setPlayer(selectPlayerId: number) {
        return (dispatch) => {
            dispatch(stateController.setState({ selectPlayerId }))
        }
    }

    public static goToPlayers() {
        return (dispatch) => {
            dispatch(Actions.setPlayer(null));
        }
    }

    public static setPlayerGridSorting(sortBy: string){
        return (dispatch) => {
            dispatch(stateController.setState({ playerGridSortBy: sortBy }))

            const { title } = sortPlayerParams.find(x => x.id === sortBy);
            dispatch(userActivityInsert({
                PageName: 'Custom Pitch',
                Message: `Sorted active picthes by ${title}`,
                PageType: PageType.Pitch,
            }))
        }
    }

    public static setActivePitchGridSorting(sortBy: string){
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ activePitchGridSortBy: sortBy }))

            const { pitchedPlayer } = Selectors.getActiveSelectedPlayer(getState());
            const { title } = sortParamsOpened.find(x => x.id === sortBy);
            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Players pitches]',
                Message: `Sorted active pitches by ${title}`,
                PlayerId: pitchedPlayer.id,
                PageType: PageType.Pitch
            }))
        }
    }

    public static setClosedPitchGridSorting(sortBy: string){
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ closedPitchGridSortBy: sortBy }))

            const { pitchedPlayer } = Selectors.getClosedSelectedPlayer(getState());
            const { title } = sortParamsClosed.find(x => x.id === sortBy);
            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Players pitches]',
                Message: `Sorted Closed Pitches by ${title}`,
                PlayerId: pitchedPlayer.id,
                PageType: PageType.Pitch
            }))
        }
    }

    public static openPlayerProfile(playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            window.open(`/profile/${playerId}`, '_blank')
            dispatch(userActivityInsert({
                PageName: 'Custom Pitch',
                Message: 'Opened Player Profile',
                PlayerId: playerId,
                PageType: PageType.Pitch
            }));
        }
    }

    public static toggleChangeStatusDropdown(pitchSquadId: number) {
        return async (dispatch, getState: () => AppState) => {
            const { changeStatusDropdownOpenedPitchSquadId } = Selectors.getRoot(getState());
            const isDropdownOpen = changeStatusDropdownOpenedPitchSquadId === null;
            dispatch(stateController.setState({ 
                changeStatusDropdownOpenedPitchSquadId: isDropdownOpen ? pitchSquadId : null 
            }))

            if(isDropdownOpen){
                const { pitchedPlayer, sentSquads } = Selectors.getClosedSelectedPlayer(getState());
                const { squad } = sentSquads.find(x => x.customPitchSentSquadId === pitchSquadId);
                dispatch(userActivityInsert({
                    PageName: 'Custom Pitch',
                    Message: 'Opened Pitch Close Reason',
                    ClubId: squad.id,
                    PlayerId: pitchedPlayer.id,
                    PageType: PageType.Pitch
                }));
            }
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.agency.customPitch.grid
    public static getCreatingRoot = (state: AppState) => state.agency.customPitch.createPitch
    public static isLoading = (state: AppState): boolean => Selectors.getRoot(state).isLoading || Selectors.getCreatingRoot(state).isLoadingPlayers
    public static getMode = (state: AppState): CustomPitchMode => Selectors.getRoot(state).mode
    public static getCustomPitches = (state: AppState): Array<CustomPitchSent> => Selectors.getRoot(state).sentPitches
    public static getIndicators = (state: AppState): Indicators => Selectors.getRoot(state).indicators
    public static getSelectedPlayerId = (state: AppState): number => Selectors.getRoot(state).selectPlayerId
    public static getSelectedActivePitchSquadIds = (state: AppState): Array<number> => Selectors.getRoot(state).selectedActivePitchSquadIds

    public static getSelectedPlayer = createSelector([Selectors.getCustomPitches, Selectors.getSelectedPlayerId],
        (items, id) => items.filter(i => i.pitchedPlayer.id == id))

    public static getActiveSelectedPlayer = createSelector([Selectors.getSelectedPlayer],
        (items) => items.find(i => i.openedInfo))

    public static getPitchedPlayers = createSelector([Selectors.getCustomPitches],
        (items) => items
            .map(x => x.pitchedPlayer)
            .filter((player, index, self)  => self.map(x => x.id).indexOf(player.id) === index))

    public static getActiveSelectedPlayerItems = createSelector([Selectors.getActiveSelectedPlayer],
            (playerItem) => playerItem?.sentSquads.filter(i => i.openedInfoSquad))

    public static getClosedSelectedPlayer = createSelector([Selectors.getSelectedPlayer],
        (items) => items.find(i => i.closedInfo))
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    AgencyCustomPitchState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};
