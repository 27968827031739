import { StateController } from 'utils/action-declaration' 
import { AppState } from 'root.reducer';
import PitchService from "api/agency/agent/pitch/pitch.service";
import { PitchAccessCriteriaModel } from 'api/agency/agent/pitch/pitch-access-criteria-models';
import { AgencyPlayerAd } from 'api/agency/agent/pitch/pitch-models';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';
import { PitchAccessCriteriaArgs, StrategyModalActions } from 'pages/agency/authorized-agent/home-page/redux/home-page-players.controller'
import { PlayerVerificationExtended } from 'api/agency/agent/landing/agency-dashboard';

export class PitchAccessCriteriaModalState {
    isOpen: boolean;
    isLoading: boolean;
    clubAd: AgencyPlayerAd;
    pitchAccessCriterias: Array<PitchAccessCriteriaModel>;
    selectedPlayerId?: number;
}

const defaultState: PitchAccessCriteriaModalState = {
    isOpen: false,
    isLoading: true,
    clubAd: null,
    pitchAccessCriterias: []
}

const stateController = new StateController<PitchAccessCriteriaModalState>(
    "PITCH_ACCESS_CRITERIA_MODAL",
    defaultState
);

class Actions {

    public static open(clubAd: AgencyPlayerAd, selectedPlayerId: number){
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ 
                isOpen: true, 
                clubAd: clubAd,
                selectedPlayerId: selectedPlayerId
             }));
            const playerIds = clubAd.notPitchablePlayers.map(x => x.id);
            await dispatch(Actions.load([clubAd.playerAd.id], playerIds));

            dispatch(userActivityInsert({
                Message: 'Opened Pitch Access Criteria',
                PageName: 'Pitch [No Access]',
                PlayerId: selectedPlayerId,
                ClubId: clubAd.playerAd.createdBySquad.id,
                PageType: PageType.Pitch
            }))
        }
    }

    public static load(adIds: Array<number>, playerIds: Array<number>) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }))
            try {
                const data = await PitchService.getPitchAccessCriteria(adIds, playerIds);
                dispatch(stateController.setState({ pitchAccessCriterias: data.items }))
            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState({ isLoading: false }))
            }
        }
    }

    public static close(){
        return (dispatch, getState: () => AppState) => {
            const substate = getState().agency.agent.pitchAccessCriteriaModal;
            const { clubAd } = substate;

            dispatch(stateController.setState({ 
                isOpen: false, 
            }));

            dispatch(userActivityInsert({
                Message: 'Closed Pitch Access Criteria',
                PageName: 'Pitch [No Access]',
                PlayerId: substate.selectedPlayerId,
                ClubId: clubAd.playerAd.createdBySquad.id,
                PageType: PageType.Pitch
            }))
        }
    }

    public static selectPlayer(playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            const substate = getState().agency.agent.pitchAccessCriteriaModal;
            const { clubAd } = substate;
            
            dispatch(stateController.setState({ 
                selectedPlayerId: playerId
            }));

            dispatch(userActivityInsert({
                Message: 'Selected a Player in Pitch Access Criteria',
                PageName: 'Pitch [No Access]',
                PlayerId: playerId,
                ClubId: clubAd.playerAd.createdBySquad.id,
                PageType: PageType.Pitch
            }))
            
        }
    }

    public static openStrategyModal(player: PlayerVerificationExtended, adIds: number[], playerIds: number[], actionName: string) {
        return async (dispatch, getState: () => AppState) => {

            const substate = getState().agency.agent.pitchAccessCriteriaModal;
            const { clubAd } = substate;

            dispatch(userActivityInsert({
                Message: actionName,
                PageName: 'Pitch [No Access]',
                PlayerId: player.playerId,
                ClubId: clubAd.playerAd.createdBySquad.id,
                PageType: PageType.Pitch
            }))

            const args: PitchAccessCriteriaArgs = {
                adIds: adIds,
                playerIds: playerIds
            }
            dispatch(StrategyModalActions.openStrategyModal(player, args))
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    PitchAccessCriteriaModalState as State,
    Actions as Actions,
    stateController as Controller
};


