import { StateController } from 'utils/action-declaration'
import { AppState } from 'root.reducer'
import { ShortlistService } from 'api/shortlist/shortlist-service'

class ShortListState {
    ids: Array<number>;
    processingIds: Array<number>;
}

const defaultState: ShortListState = {
    ids: [],
    processingIds: [],
}

const stateController = new StateController<ShortListState>(
    'CROSSAPP_SHORTLIST',
    defaultState
)

class Actions {
    public static loadShortlist() {
        return (dispatch, getState: () => AppState) => new Promise(async (resolve, reject) => {
            const isAgencyUser = !!getState().auth.agencyId
            try {
                const data = isAgencyUser ? await ShortlistService.loadAgencyShortListedPlayers() : await ShortlistService.loadShortListedPlayers()
                dispatch(stateController.setState({ ids: data }))
                resolve(data)
            } catch(e) {
                console.error(e)
                reject(e)
            }
        })
    }
    public static toggleShortlist(playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            const isAgencyUser = !!getState().auth.agencyId
            const substate = getState().shortlisted
            const isPlayerInShortlist = substate.ids.includes(playerId)
            try {
                dispatch(stateController.setState({ processingIds: [ ...substate.processingIds, playerId ]}))
                if (isPlayerInShortlist) {
                    isAgencyUser ? await ShortlistService.agencyAddToShortlist(playerId) : await ShortlistService.addToShortlist(playerId)
                } else {
                    isAgencyUser ? await ShortlistService.agencyRemoveFromShortlist(playerId)  : await ShortlistService.removeFromShortlist(playerId)
                }
            } catch(e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState({ processingIds: getState().shortlisted.processingIds.filter(item => item !== playerId) }))
            }
        } 
    }
    
}

class Selectors {
    public static selectProcessingIds = (state: AppState) => state.shortlisted.processingIds
}   

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    ShortListState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};