import * as types from 'store/actionTypes';
import PasswordValidation from 'utils/validate-password';

const initialState = {
    user: {},
    isTokenValid: false,
    isReady: false,
    isConfirmed: false,
    isProcessing: false,
    data: undefined,
};

export default (confirmAccount = initialState, action) => {
    switch (action.type) {
        case types.CHECK_CONFIRM_ACCOUNT_TOKEN.REQUEST: {
            return {
                ...confirmAccount,
                isProcessing: true,
            };
        }
        case types.CHECK_CONFIRM_ACCOUNT_TOKEN.SUCCESS: {
            return {
                ...confirmAccount,
                user: action.payload.data.output,
                isTokenValid: !!action.payload.data.output,
                isReady: true,
                isProcessing: false,
            };
        }
        case types.CONFIRM_ACCOUNT.REQUEST: {
            return {
                ...confirmAccount,
                isProcessing: true,
            };
        }
        case types.USER_CLEAR_CONFIRM_REQUEST_SESSION: {
            return {
                ...confirmAccount,
                data: undefined,
            };
        }
        case types.CONFIRM_ACCOUNT.SUCCESS: {
            return {
                ...confirmAccount,
                isConfirmed:
                    action.payload.data.data.isSuccess && action.payload.data.isValid,
                isTokenValid: action.payload.data.data.isSuccess
                    ? action.payload.data.isValid
                    : confirmAccount.isTokenValid,
                data: action.payload.data.data,
                isProcessing: false,
            };
        }
        case types.VALIDATE_CONFIRM_PASSWORD: {
            const validation = PasswordValidation.validate(action.payload);
            return {
                ...confirmAccount,
                data: {
                    rules: validation.rules,
                    isSuccess: validation.isSuccess,
                },
            };
        }
        default:
            return confirmAccount;
    }
};

export const getUser = state => {
    return state.confirmAccount.user;
};

export const getEmail = state => {
    return state.confirmAccount.email;
};

export const isTokenValid = state => {
    return state.confirmAccount.isTokenValid;
};

export const isReady = state => {
    return state.confirmAccount.isReady;
};

export const isConfirmed = state => {
    return state.confirmAccount.isConfirmed;
};

export const isProcessing = state => {
    return state.confirmAccount.isProcessing;
};

export const getData = state => {
    return state.confirmAccount.data;
};
