import { getRequests } from 'store/requests/requestsReducer';
import { asyncActionType } from 'store/actionTypes';
import { createAction } from 'redux-actions';

export const spendingPowerTabs = {
    transfers: 'TRANSFERS',
    loans: 'LOANS',
};

const initialState = {
    data: null,
    selectedTab: spendingPowerTabs.transfers,
    selectedLastYears: 1,
};

export default (spendingPower = initialState, action) => {
    switch (action.type) {
        case SPENDING_POWER_FETCH.SUCCESS: {
            return { ...spendingPower, data: action.payload.data };
        }
        case SPENDING_POWER_FETCH.REQUEST:
            return { ...spendingPower };
        case SPENDING_POWER_SET_TAB:
            return { ...spendingPower, selectedTab: action.payload }
        case SPENDING_POWER_SET_SEASON:
            return { ...spendingPower, selectedLastYears: action.payload }

        default:
            return spendingPower;
    }
};


export const SPENDING_POWER_FETCH = asyncActionType('app/financial-insights/SPENDING_POWER_FETCH');

export const SPENDING_POWER_SET_TAB = 'app/financial-insights/SPENDING_POWER_SET_TAB';
export const SPENDING_POWER_SET_SEASON = 'app/financial-insights/SPENDING_POWER_SET_SEASON';

export const setTab = createAction(SPENDING_POWER_SET_TAB);
export const setSeason = createAction(SPENDING_POWER_SET_SEASON);

export const getSpendingPowerLoadingStatus = state =>
    getRequests(state).some(r => r.type === SPENDING_POWER_FETCH);


