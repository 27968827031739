import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import { SearchByKey } from 'api/search/model/search-request'
import getSections from 'services/filterSections';
import SearchService from 'api/search/search.clubside.service';
import { CustomLeagueList } from 'api/search/model/search-filter-criteria';

import Range from 'api/core/range';
import { SearchItemType } from 'api/search/model/suggestion-item'
import * as GridActions from './grid.controller'
import * as Suggestions from './autosuggest.controller'
import { IdName } from 'api/core/id-name';
import { FilterActivityService } from './filter.activity.service'
import { SuggestionActivityService } from './autosuggest.activity.service'
import { ClubActivityService } from './player-activity.service'
import * as storedFilter from 'pages/PlayerSearch-v2/redux/stored-filter'
import { LatestFilterSet, SearchSuggestionSubset, FiltersSet, LatestFilter, FeaturedAgency } from 'api/search/model/suggestion-subsets';
import { GridToggleState } from './grid.controller';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';
import { currencyById } from 'constants/currency';

const sections = getSections();
class SortItem {
    id: SearchByKey;
    title: string;
}
export class Structure {
    age: Range<number>;
    height: Range<number>;
    contractExpiryMonthes: Range<number>;
    leaguesList: Array<IdName>;
    leaguesLists?: Array<CustomLeagueList>;
    nationalityList: Array<IdName>;
    gbePass: boolean;
    showOnlyFreeAgentPlayers: boolean;
    positionList: Array<string>;
    transferFee: Range<number>;
    anuallGrossSalaryYearly: Range<number>;
    loanFee: Range<number>;
    minutesPlayed: Range<number>;
    rating: Range<number>;
}
export class SearchMoreFilter {
    gbePass: boolean;
    minHeight: number;
    contractExpiryMonthesMax: number;
    leaguesList: Array<number>;
    leaguesLists?: Array<CustomLeagueList>;
    nationalityList: Array<number>;
    age: Range<number>;
    minutesPlayed: Range<number>;
    minRating: number;
}
export class TransferTypeFilter {
    showOnlyFreeAgentPlayers: boolean;
    showOnlyAvailablePlayers: boolean;
    showOnlyPlayersWithSuspendedContract: boolean;
    showOnlyPlayersOnReleaseList: boolean;
    positionList: Array<string>;
    transferFee: Range<number>;
    anuallGrossSalaryYearly: Range<number>;
    loanFee: Range<number>;
}
export class OnlyAgentsAndClubsFilter {
    showOnlyClubs: boolean;
    showOnlyTrustedAgents: boolean;
}

export enum SearchMode {
    Default = 0,
    Filtered = 1,
}

// Basic sortList sets
export const searchSortList: Array<SortItem> = [
    { id: SearchByKey.Availability, title: 'Availability' },
    { id: SearchByKey.RatingHightToLow, title: 'Rating: High to Low' },
    { id: SearchByKey.RatingLowToHigh, title: 'Rating: Low to High' },
    { id: SearchByKey.XtvHightToLow, title: 'xTV: High to Low' },
    { id: SearchByKey.XtvLowToHigh, title: 'xTV: Low to High' },
    { id: SearchByKey.AgeHigtToLow, title: 'Age: High to Low', },
    { id: SearchByKey.AgeLowHigh, title: 'Age: Low to High' },
    { id: SearchByKey.ContractExpiry, title: 'Contract Expiry' },
];
export const shortListSortList: Array<SortItem> = [
    { id: SearchByKey.Availability, title: 'Availability' },
    { id: SearchByKey.RatingHightToLow, title: 'Rating: High to Low' },
    { id: SearchByKey.RatingLowToHigh, title: 'Rating: Low to High' },
    { id: SearchByKey.XtvHightToLow, title: 'xTV: High to Low' },
    { id: SearchByKey.XtvLowToHigh, title: 'xTV: Low to High' },
    { id: SearchByKey.AgeHigtToLow, title: 'Age: High to Low', },
    { id: SearchByKey.AgeLowHigh, title: 'Age: Low to High' },
    { id: SearchByKey.ContractExpiry, title: 'Contract Expiry' },
    { id: SearchByKey.DateWhenAddedToShortList, title: 'Date when added' }
]
export const similaritySortList: Array<SortItem> = [
    { id: SearchByKey.Similarity, title: 'Similarity' },
    { id: SearchByKey.RatingHightToLow, title: 'Rating: High to Low' },
    { id: SearchByKey.RatingLowToHigh, title: 'Rating: Low to High' },
    { id: SearchByKey.XtvHightToLow, title: 'xTV: High to Low' },
    { id: SearchByKey.XtvLowToHigh, title: 'xTV: Low to High' },
    { id: SearchByKey.AgeHigtToLow, title: 'Age: High to Low', },
    { id: SearchByKey.AgeLowHigh, title: 'Age: Low to High' },
    { id: SearchByKey.ContractExpiry, title: 'Contract Expiry' },
];
export const clubAgentsSortList: Array<SortItem> = [
    { id: SearchByKey.ByRelevant, title: 'By Relevant' },
    { id: SearchByKey.XtvHightToLow, title: 'xTV: High to Low' },
    { id: SearchByKey.XtvLowToHigh, title: 'xTV: Low to High' },
];
// Additional specific Sort items
export const byRelevantClubOrAgency = { id: SearchByKey.ByRelevant, title: 'By Relevant' }

const getStructure = () => {
    return {
        age: {
            min: sections.age.default.minAge,
            max: sections.age.default.maxAge,
        },
        height: {
            min: 150,
            max: 210,
        },
        contractExpiryMonthes: {
            min: sections.contractExpiry.default.contractExpiryMin,
            max: sections.contractExpiry.default.contractExpiryMax,
        },
        leaguesList: [],
        leaguesLists: [],
        nationalityList: [],
        gbePass: sections.faPlayerPoints.default.faPointsPassResult,
        showOnlyFreeAgentPlayers: false,
        positionList: [],
        transferFee: {
            min: sections.transferFee.default.minTransferFee,
            max: sections.transferFee.default.maxTransferFee,
        },
        anuallGrossSalaryYearly: {
            min: sections.transferFee.default.minTransferFee,
            max: sections.transferFee.default.maxTransferFee,
        },
        loanFee: {
            min: sections.loanFee.default.minLoanFee,
            max: sections.loanFee.default.maxLoanFee,
        },
        minutesPlayed: {
            min: 0,
            max: null,
        },
        rating: {
            min: 0,
            max: 100,
        }
    }
}

export const getClubAgentsFilterCriteria = () => ({
    showOnlyClubs: false,
    showOnlyTrustedAgents: false
})

export const getTransferTypeFilterCriteria = (): TransferTypeFilter => {
    return {
        positionList: [],
        transferFee: null,
        anuallGrossSalaryYearly: null,
        loanFee: null,
        showOnlyFreeAgentPlayers: false,
        showOnlyAvailablePlayers: false,
        showOnlyPlayersWithSuspendedContract: false,
        showOnlyPlayersOnReleaseList: false
    };
};

const getMoreFilterCriteria = (): SearchMoreFilter => {
    return {
        gbePass: sections.faPlayerPoints.default.faPointsPassResult,
        minHeight: null,
        contractExpiryMonthesMax: null,
        leaguesList: [],
        leaguesLists: [],
        nationalityList: [],
        age: {
            min: sections.age.default.minAge,
            max: sections.age.default.maxAge,
        },
        minutesPlayed: {
            min: 0,
            max: null,
        },
        minRating: null
    };
};

export class SearchState {
    searchMode: SearchMode;
    searchSubset: SearchSuggestionSubset;
    searchSubsets: Array<SearchSuggestionSubset>;
    latestSearches: Array<LatestFilter>;
    featuredAgency: FeaturedAgency;

    structure: Structure;
    clubAgentsFilter: OnlyAgentsAndClubsFilter;
    transferTypeFilter: TransferTypeFilter;
    moreFilter: SearchMoreFilter;

    sortBy?: SearchByKey;
    structureLoading: boolean;
    suggestionsLoading: boolean;
    mpMaxUpdatedFromServer: boolean;

    transferTypeFilterModal: any;
    clubAgentsFilterModal: any;
    moreFilterModal: any;
    filterPanel: any;
}

const defaultState: SearchState = {
    searchMode: SearchMode.Default,
    searchSubset: null,
    searchSubsets: [],
    latestSearches: [],
    featuredAgency: null,

    structure: getStructure(),
    clubAgentsFilter: getClubAgentsFilterCriteria(),
    transferTypeFilter: getTransferTypeFilterCriteria(),
    moreFilter: getMoreFilterCriteria(),

    sortBy: SearchByKey.XtvHightToLow,
    structureLoading: false,
    suggestionsLoading: false,
    mpMaxUpdatedFromServer: false,

    transferTypeFilterModal: null,
    clubAgentsFilterModal: null,
    moreFilterModal: null,
    filterPanel: null,
};

const stateController = new StateController<SearchState>(
    'SEARCH_SCREEN/FILTER',
    defaultState,
);

class Actions {
    public static loadCriteria = () => {
        return async (dispatch) => {
            try {
                dispatch(stateController.setState({ structureLoading: true }))
                const criteria = await SearchService.getCriteria();
                dispatch(stateController.setState((draftState: SearchState) => ({
                    ...draftState,
                    structure: {
                        ...draftState.structure,
                        minutesPlayed: { ...draftState.structure.minutesPlayed, max: criteria.maxMinutesPlayed },
                        leaguesList: criteria.availableLeagues,
                        nationalityList: criteria.availableNationalitites,
                        transferFee: { ...draftState.structure.transferFee, max: criteria.maxTransferFee },
                        leaguesLists: criteria.customLeagueList,
                    },
                    moreFilter: {
                        ...draftState.moreFilter,
                        minutesPlayed: draftState.mpMaxUpdatedFromServer ?
                            draftState.moreFilter.minutesPlayed :
                            { ...draftState.moreFilter.minutesPlayed, max: criteria.maxMinutesPlayed }
                    },
                    mpMaxUpdatedFromServer: true,
                    structureLoading: false,
                })))
            } catch (error) {
                dispatch(stateController.setState({ structureLoading: false }))
            }
        }
    }

    public static toogleClubAgentsFilter(clubAgentsFilterModal?: boolean) {
        return dispatch => {
            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                clubAgentsFilterModal: clubAgentsFilterModal ? draftState.clubAgentsFilter : null,
            })))
        }
    }

    public static setClubsAgentsFilterAction(clubAgentsFilter: OnlyAgentsAndClubsFilter, withActivity: boolean = true) {
        return (dispatch, getState: () => AppState) => {
            const oldClubAgentsFilter = { ...Selectors.getClubAgentsFilter(getState()) }
            dispatch(stateController.setState({ clubAgentsFilter: clubAgentsFilter }));

            const wasFiltersChanged = (filterOptions: OnlyAgentsAndClubsFilter, previousFilterSnapshot: OnlyAgentsAndClubsFilter) => {
                return JSON.stringify(filterOptions) !== JSON.stringify(previousFilterSnapshot);
            }
            if (wasFiltersChanged(clubAgentsFilter, oldClubAgentsFilter)) {
                dispatch(Actions.initSorting());
                dispatch(GridActions.Actions.resetPage());
            }

            dispatch(Suggestions.Actions.setDefaultTab())
            dispatch(Suggestions.Actions.fetchSuggestionsSilentAndNoRefresh())
            dispatch(GridActions.Actions.refresh(
                () => { if (withActivity) dispatch(FilterActivityService.filterClubAgents(clubAgentsFilter)) }
            ));
        }
    }

    public static setClubsAgentsFilter(clubAgentsFilter: OnlyAgentsAndClubsFilter) {
        return (dispatch) => {
            dispatch(Actions.setClubsAgentsFilterAction(clubAgentsFilter, true))

            dispatch(Actions.updateSearchModeStateDependingOnFiltersState())
            dispatch(Actions.saveLatestFilterSet())
        }
    }

    public static setDefaultClubsAgentsFilter(clubAgentsFilter: OnlyAgentsAndClubsFilter) {
        return (dispatch) => {
            dispatch(Actions.setClubsAgentsFilterAction(clubAgentsFilter, false))

            dispatch(Actions.updateSearchModeStateDependingOnFiltersState())
            dispatch(Actions.saveLatestFilterSet())
        }
    }

    public static clearClubsAgentsFilter() {
        return (dispatch, getState: () => AppState) => {
            const oldclubAgentsFilter = { ...Selectors.getClubAgentsFilter(getState()) }
            dispatch(stateController.setState({ clubAgentsFilter: { showOnlyTrustedAgents: false, showOnlyClubs: false }, }))

            if (oldclubAgentsFilter.showOnlyClubs || oldclubAgentsFilter.showOnlyTrustedAgents) {
                dispatch(Actions.initSorting());
                dispatch(GridActions.Actions.resetPage());
            }
            dispatch(Suggestions.Actions.setDefaultTab())
        }
    }

    public static clearClubsAgentsFilterAsync() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.clearClubsAgentsFilter())
            dispatch(Suggestions.Actions.fetchSuggestionsSilentAndNoRefresh());
            dispatch(GridActions.Actions.refresh());

            dispatch(Actions.updateSearchModeStateDependingOnFiltersState())
            dispatch(Actions.saveLatestFilterSet())
        }
    }

    public static onClubSelect(name: string, clubId: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.resetFilter(true, false));
            dispatch(GridActions.Actions.resetPage());
            dispatch(Suggestions.Actions.setKeywordWithGridRefresh(name))
            dispatch(Suggestions.Actions.fetchSuggestionsSilentAndNoRefresh());
            dispatch(ClubActivityService.openClubPlayers(clubId))
        }
    }

    public static onSuggestClubSelected(clubId: number) {
        return (dispatch, getState: () => AppState) => {
            const club = Suggestions.Selectors.getSuggestionItem(getState())(clubId, SearchItemType.Club)

            dispatch(Actions.resetFilter())
            dispatch(Suggestions.Actions.setKeywordWithGridRefresh(club.title));
            dispatch(Suggestions.Actions.fetchSuggestionsSilentAndNoRefresh());
            dispatch(SuggestionActivityService.selectClub(clubId, club.title))
            dispatch(Actions.saveLatestFilterSet());
        }
    }

    public static toggleTransferTypeFilterModal(transferTypeFilterModal: boolean) {
        return (dispatch, getState: () => AppState) => {
            const filter = Selectors.getTranferTypeFilter(getState())
            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                transferTypeFilterModal: transferTypeFilterModal ? { ...filter } : null,
            })))
        }
    }

    public static setTranferTypeFilter(filter: TransferTypeFilter) {
        return (dispatch, getState: () => AppState) => {
            const subState = Selectors.getRoot(getState())
            const gridState = GridActions.Selectors.getGridState(getState())


            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                transferTypeFilter: { ...draftState.transferTypeFilter, ...filter },
            })));
            dispatch(GridActions.Actions.resetPage());
            dispatch(GridActions.Actions.refresh(() => {
                dispatch(FilterActivityService.filterTransferTypeAndPosition(filter, gridState))
            }));

            dispatch(Actions.updateSearchModeStateDependingOnFiltersState())
            dispatch(Actions.saveLatestFilterSet())
        }
    }

    public static setDefaultTranferTypeFilter(filter: TransferTypeFilter) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                transferTypeFilter: { ...draftState.transferTypeFilter, ...filter },
            })));
            dispatch(GridActions.Actions.resetPage());
            dispatch(GridActions.Actions.refresh());

            dispatch(Actions.updateSearchModeStateDependingOnFiltersState())
            dispatch(Actions.saveLatestFilterSet())
        }
    }

    public static clearTranferTypeFilter() {
        return dispatch => {
            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                transferTypeFilter: { ...getTransferTypeFilterCriteria() },
            })));
        }
    }

    public static clearTranferTypeFilterAsync() {
        return dispatch => {
            dispatch(Actions.clearTranferTypeFilter());
            dispatch(GridActions.Actions.resetPage());
            dispatch(GridActions.Actions.refresh());

            dispatch(Actions.updateSearchModeStateDependingOnFiltersState())
            dispatch(Actions.saveLatestFilterSet())
        }
    }

    public static toggleMoreFilterModal(moreFilter: boolean) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                moreFilterModal: moreFilter ? { ...draftState.moreFilter } : null,
            })));
        }
    }

    public static setMoreFilter(filter: SearchMoreFilter) {
        return (dispatch, getState: () => AppState) => {
            const structure = Selectors.getStructure(getState())
            const gridState = GridActions.Selectors.getGridState(getState())

            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                moreFilter: { ...draftState.moreFilter, ...filter },
            })))
            dispatch(GridActions.Actions.resetPage());
            dispatch(GridActions.Actions.refresh(
                () => dispatch(FilterActivityService.filterMore(filter, structure, gridState))
            ));

            dispatch(Actions.updateSearchModeStateDependingOnFiltersState())
            dispatch(Actions.saveLatestFilterSet())
        }
    }

    public static setDefaultMoreFilter(filter: SearchMoreFilter) {
        return (dispatch, getState: () => AppState) => {

            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                moreFilter: { ...draftState.moreFilter, ...filter }
            })))
            dispatch(GridActions.Actions.resetPage());
            dispatch(GridActions.Actions.refresh());

            dispatch(Actions.updateSearchModeStateDependingOnFiltersState())
            dispatch(Actions.saveLatestFilterSet())
        }
    }

    public static clearMoreFilter() {
        return dispatch => {
            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                moreFilter: { ...getMoreFilterCriteria(), minutesPlayed: draftState.structure.minutesPlayed },
            })));
        }
    }

    public static clearMoreFilterAsync() {
        return dispatch => {
            dispatch(Actions.clearMoreFilter());
            dispatch(GridActions.Actions.resetPage());
            dispatch(GridActions.Actions.refresh());

            dispatch(Actions.updateSearchModeStateDependingOnFiltersState())
            dispatch(Actions.saveLatestFilterSet())
        }
    }

    public static toggleAllFilterModal(open: boolean) {
        return (dispatch, getState: () => AppState) => {
            const transferTypeFilter = Selectors.getTranferTypeFilter(getState());
            const clubAgentsFilter = Selectors.getClubAgentsFilter(getState());
            const moreFilter = Selectors.getMoreFilter(getState());

            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                filterPanel: open ? { transferTypeFilter, clubAgentsFilter, moreFilter } : null,
            })))
        }
    }

    public static applyFilter() {
        return (dispatch, getState: () => AppState) => {
            const structure = Selectors.getStructure(getState())
            const gridState = GridActions.Selectors.getGridState(getState())
            const filterPanel = Selectors.getFilterPanel(getState());

            dispatch(stateController.setState({
                clubAgentsFilter: filterPanel.clubAgentsFilter,
                transferTypeFilter: filterPanel.transferTypeFilter,
                moreFilter: filterPanel.moreFilter
            }));
            dispatch(GridActions.Actions.resetPage());

            const action = () => {
                if (!filterPanel.clubAgentsFilter.showOnlyClubs && !filterPanel.clubAgentsFilter.showOnlyTrustedAgents) {
                    dispatch(FilterActivityService.filterMore(filterPanel.moreFilter, structure, gridState))
                    dispatch(FilterActivityService.filterTransferTypeAndPosition(filterPanel.transferTypeFilter, gridState))
                }

                dispatch(FilterActivityService.filterClubAgents(filterPanel.clubAgentsFilter))
            }

            dispatch(GridActions.Actions.refresh(action));
            dispatch(Actions.toggleAllFilterModal(false))

            dispatch(Actions.updateSearchModeStateDependingOnFiltersState())
            dispatch(Actions.saveLatestFilterSet())
        }
    }

    public static setFilterPanel(data: any) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ filterPanel: { ...data } }));
        }
    }

    public static resetFilter(withClearSubset = true, withUpdateSearchMode = true) {
        return (dispatch, getState: () => AppState) => {

            if (withClearSubset) {
                dispatch(Actions.clearSubsetFilter())
            }

            dispatch(Actions.clearClubsAgentsFilter());
            dispatch(Actions.clearMoreFilter());
            dispatch(Actions.clearTranferTypeFilter());
            dispatch(GridActions.Actions.resetPage());
            dispatch(Actions.initSorting())

            if(withUpdateSearchMode)
                dispatch(Actions.updateSearchModeStateDependingOnFiltersState())
        }
    }



    public static init(gridState: GridActions.GridToggleState) {
        return (dispatch, getState: () => AppState) => {
            const activeTabWasChanged = GridActions.Selectors.getGridState(getState()) !== gridState
            if (activeTabWasChanged) dispatch(Actions.disposeFilters())

            dispatch(GridActions.Actions.toggleGridState(gridState))
            // dispatch(Actions.restoredFilter(gridState)) // disable filter storage
            dispatch(GridActions.Actions.resetPage())
            dispatch(Suggestions.Actions.onKeywordClearWithoutRefresh())
            dispatch(GridActions.Actions.load(gridState))
        }
    }

    // Method is not in use due to new functionality (Latest Search and DB storage functionality has been replaced the LocalStorage approach)
    public static restoredFilter(gridState: GridActions.GridToggleState) {
        return (dispatch, getState: () => AppState) => {
            const filter: any = storedFilter.restoredFilter();
            const state = getState()
            const sortBy = Selectors.getSortBy(state)
            const structure = Selectors.getStructure(state)
            if (filter && gridState === GridActions.GridToggleState.Search) {
                const playerFilter = filter.playerFilter || {}

                let playerMinutesMin = playerFilter.minutesPlayed ? playerFilter.minutesPlayed.min : structure.minutesPlayed.min;
                let playerMinutesMax = playerFilter.minutesPlayed ? playerFilter.minutesPlayed.max : structure.minutesPlayed.max;


                let mpMaxUpdatedFromServer = true;
                if (playerMinutesMax == null && structure.minutesPlayed) {
                    playerMinutesMax = structure.minutesPlayed.max;
                    mpMaxUpdatedFromServer = false;
                }

                dispatch(stateController.setState({
                    mpMaxUpdatedFromServer,
                    moreFilter: {
                        ...getMoreFilterCriteria(),
                        gbePass: playerFilter.gbePass,
                        minHeight: playerFilter.minHeight,
                        contractExpiryMonthesMax: playerFilter.contractExpiryMonthesMax,
                        leaguesList: playerFilter.leaguesList,
                        leaguesLists: playerFilter.leaguesLists,
                        nationalityList: playerFilter.nationalityList,
                        age: playerFilter.age,
                        minutesPlayed: {
                            min: playerMinutesMin,
                            max: playerMinutesMax,
                        },
                        minRating: playerFilter.minRating,
                    },
                    transferTypeFilter: {
                        ...getTransferTypeFilterCriteria(),
                        showOnlyFreeAgentPlayers: playerFilter.showOnlyFreeAgentPlayers,
                        showOnlyAvailablePlayers: playerFilter.showOnlyAvailablePlayers,
                        showOnlyPlayersWithSuspendedContract: playerFilter.showOnlyPlayersWithSuspendedContract,
                        showOnlyPlayersOnReleaseList: playerFilter.showOnlyPlayersOnReleaseList,
                        positionList: playerFilter.positionList,
                        transferFee: playerFilter.transferFee,
                        anuallGrossSalaryYearly: playerFilter.anuallGrossSalaryYearly,
                        loanFee: playerFilter.loanFee,
                    },
                    sortBy: filter.sortByKey || sortBy,
                    clubAgentsFilter: filter.clubsAndAgentsFilter,
                }));
                dispatch(Suggestions.Actions.setKeyword(filter.keyword))
            } else {
                dispatch(Actions.resetFilter())
                dispatch(Actions.initSorting())
            }
        }
    }

    public static resetFilterAsync(withClearSubset: boolean = false) {
        return dispatch => {
            dispatch(Actions.resetFilter(withClearSubset));
            dispatch(GridActions.Actions.resetPage());
            dispatch(GridActions.Actions.refresh());
        }
    }

    public static applySorting(sortBy: SearchByKey, column: string) {
        return (dispatch, getState: () => AppState) => {
            const gridState = GridActions.Selectors.getGridState(getState())

            dispatch(stateController.setState({ sortBy }));
            dispatch(GridActions.Actions.resetPage());
            dispatch(GridActions.Actions.refresh(
                () => dispatch(FilterActivityService.sortBy(column, gridState))
            ));
        }
    }

    public static initSorting() {
        return (dispatch, getState: () => AppState) => {
            const state = getState();

            const tabKey = GridActions.Selectors.getGridState(state);
            let sortBy;

            if (tabKey === GridActions.GridToggleState.Similarity) {
                sortBy = SearchByKey.Similarity
            } else if (Selectors.isActiveClubsAgentsFilter(state)) {
                sortBy = SearchByKey.ByRelevant
            } else if (Selectors.isAvailable(state)) {
                sortBy = SearchByKey.Availability
            } else {
                sortBy = SearchByKey.XtvHightToLow
            }

            dispatch(stateController.setState({ sortBy }));
        }
    }

    public static deleteCompetitionList(listId: number) {
        return dispatch => {
            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                moreFilter: {
                    ...draftState.moreFilter,
                    leaguesLists: draftState.moreFilter.leaguesLists.filter(item => item.id !== listId)
                },
                structure: {
                    ...draftState.structure,
                    leaguesLists: draftState.structure.leaguesLists.filter(item => item.id !== listId)
                },
            })))
        }
    }

    public static addLeagueList(list: CustomLeagueList) {
        return (dispatch, getState: () => AppState) => {
            const structure = Selectors.getStructure(getState())
            const currentList = structure.leaguesLists.find(item => item.id === list.id);

            if (currentList) {
                dispatch(stateController.setState({
                    structure: {
                        ...structure,
                        leaguesLists: structure.leaguesLists
                            .map((item) => item.id === list.id ? { ...list } : item)
                    },
                }))
            } else {
                dispatch(stateController.setState({
                    structure: {
                        ...structure,
                        leaguesLists: [list, ...structure.leaguesLists]
                    },
                }))
            }
        }
    }

    public static loadSearchSuggestionSubsets() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ suggestionsLoading: true }))
            const { filterSuggestions, latestFilters, featuredAgency } = await SearchService.getSearchSuggestionSubsets()
            dispatch(stateController.setState({ suggestionsLoading: false, searchSubsets: filterSuggestions, latestSearches: latestFilters, featuredAgency }))
        }
    }

    public static selectSubset(subset: SearchSuggestionSubset) {
        return (dispatch, getState: () => AppState) => {
            const sortBy = Selectors.isAvailable(getState())
                ? SearchByKey.Availability
                : SearchByKey.XtvHightToLow;

            dispatch(stateController.setState({
                searchSubset: subset,
                sortBy: sortBy
            }))
            dispatch(Actions.setSearchModeToFiltered())
            dispatch(GridActions.Actions.resetPage())
            dispatch(GridActions.Actions.refresh())

            dispatch(userActivityInsert({
                PageName: 'Search [Suggestion]',
                Message: `Selected: ${subset.name}`,
                PageType: PageType.Search
            }))
        }
    }
    public static selectLatestSearch(subset: LatestFilter) {
        return (dispatch, getState: () => AppState) => {
            const latestFilterSet = subset.latestFilterSet
            const structure = getState().newPlayerSearch.filter.structure
            // if custom leagues lists where changed after  was saved
            const leaguesListWithExistingCustomLeagues = latestFilterSet.moreFilter.leaguesLists.filter(item => {
                return structure.leaguesLists.some(el => el.id === item.id)
            })
            const filters: FiltersSet = {
                clubAgentsFilter: latestFilterSet.clubAgentsFilter,
                transferTypeFilter: latestFilterSet.transferTypeFilter,
                moreFilter: {
                    ...latestFilterSet.moreFilter,
                    leaguesLists: leaguesListWithExistingCustomLeagues
                }
            }
            const keyword = latestFilterSet.keyword
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                ...filters
            })))
            dispatch(Suggestions.Actions.setKeyword(keyword || ''))


            dispatch(Actions.setSearchModeToFiltered())
            dispatch(GridActions.Actions.resetPage())
            dispatch(GridActions.Actions.refresh())

            const currencyCode = currencyById[getState().auth.currency.id]
            const filtersMessage = FilterActivityService.getLatestSearchMessage(filters, structure, currencyCode)
            dispatch(userActivityInsert({
                PageName: 'Search [Latest Search]',
                Message: `Selected Latest Search - ${filtersMessage}`,
                PageType: PageType.Search
            }))

        }
    }
    public static clearSubsetFilter() {
        return dispatch => {
            dispatch(stateController.setState({
                searchSubset: null,
                sortBy: SearchByKey.XtvHightToLow
            }))
        }
    }
    public static setSearchModeToDefault() {
        return (dispatch, getState: () => AppState) => {
            // first of all need to refetch Latest Filter and Search Suggestion Subsets from database
            const isFiltersInActiveState = Selectors.isFiltersInActiveState(getState())
            const isKeywordNotEmpty = !!Suggestions.Selectors.getRoot(getState()).keyword
            if (isFiltersInActiveState || isKeywordNotEmpty) {
                dispatch(Actions.loadSearchSuggestionSubsets())
            }

            dispatch(stateController.setState({ searchMode: SearchMode.Default }))
            dispatch(Suggestions.Actions.onKeywordClearWithoutRefresh()) // clear keywortd as well
            dispatch(Actions.resetFilterAsync(true)) // dispose all filters and fetch search without filters
            dispatch(Actions.initSorting());

            dispatch(userActivityInsert({
                PageName: 'Search',
                Message: 'Moved Back to Search',
                PageType: PageType.Search
            }))
        }
    }
    public static setSearchModeToFiltered() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ searchMode: SearchMode.Filtered }))
            dispatch(GridActions.Actions.toggleGridState(GridToggleState.Search)) // we need to set to default Search Tab before refresh
        }
    }
    public static updateSearchModeStateDependingOnFiltersState() {
        return (dispatch, getState: () => AppState) => {
            // Ignore if not Search Tab selected
            const notSearchTabSelected = GridActions.Selectors.getGridState(getState()) !== GridToggleState.Search
            if (notSearchTabSelected) return

            const isFiltersInActiveState = Selectors.isFiltersInActiveState(getState())
            const isKeywordNotEmpty = !!Suggestions.Selectors.getRoot(getState()).keyword
            const isSearchSubsetSelected = Selectors.isSearchSubsetSelected(getState())
            const isNeededToSwitchToFilteredState = isFiltersInActiveState || isSearchSubsetSelected || isKeywordNotEmpty
            const isNeededToSwitchToDefaultState = !isFiltersInActiveState && !isSearchSubsetSelected && !isKeywordNotEmpty
            const isSearchModeInFilteredState = Selectors.isSearchModeInFilteredState(getState())

            if (isNeededToSwitchToFilteredState && !isSearchModeInFilteredState) {
                dispatch(Actions.setSearchModeToFiltered())
            } else if (isNeededToSwitchToDefaultState && isSearchModeInFilteredState) {
                dispatch(Actions.setSearchModeToDefault())
            }
        }
    }
    public static saveLatestFilterSet(keywordOwerride?: string) {
        return async (dispatch, getState: () => AppState) => {
            const isSuggestedSearchApplied = Selectors.getRoot(getState()).searchSubset
            if (isSuggestedSearchApplied) return // Ignore saving if Suggested Search player set was applied

            let latestFilterSet = Selectors.getLatestFilterSet(getState());
            if (keywordOwerride !== undefined && keywordOwerride !== null) {
                latestFilterSet = { ...latestFilterSet, keyword: keywordOwerride }
            }
            await SearchService.saveLatestSearch(latestFilterSet)
        }
    }

    public static disposeFilters() {
        return (dispatch, getState: () => AppState) => {
            const substate = getState().newPlayerSearch.filter
            const initialFilters = Selectors.getInitialFiltersSet(getState())
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                clubAgentsFilter: initialFilters.clubAgentsFilter,
                transferTypeFilter: initialFilters.transferTypeFilter,
                moreFilter: {
                    ...initialFilters.moreFilter,
                    minutesPlayed: { min: 0, max: substate.structure.minutesPlayed.max }
                }
            })))
        }
    }

}

class Selectors {
    public static getRoot = (state: AppState) => state.newPlayerSearch.filter;
    public static getClubAgentsFilterModal = (state: AppState) => Selectors.getRoot(state).clubAgentsFilterModal;
    public static getClubAgentsFilter = (state: AppState) => Selectors.getRoot(state).clubAgentsFilter;
    public static isActiveClubsAgentsFilter = (state: AppState) => Selectors.getClubAgentsFilter(state).showOnlyClubs
        || Selectors.getClubAgentsFilter(state).showOnlyTrustedAgents;
    public static getTransferTypeFilterModal = (state: AppState) => Selectors.getRoot(state).transferTypeFilterModal;
    public static getTranferTypeFilter = (state: AppState) => Selectors.getRoot(state).transferTypeFilter;
    public static isActiveTransferTypeAndPositionFilter = (state: AppState) => JSON.stringify(Selectors.getTranferTypeFilter(state)) !== JSON.stringify(getTransferTypeFilterCriteria())
    public static isActiveTransferTypeFilter = (state: AppState) => JSON.stringify({ ...Selectors.getTranferTypeFilter(state), positionList: [] }) !== JSON.stringify(getTransferTypeFilterCriteria())
    public static getMoreFilterModal = (state: AppState) => Selectors.getRoot(state).moreFilterModal;
    public static getMoreFilter = (state: AppState) => Selectors.getRoot(state).moreFilter;
    public static isActiveMoreFilter = (state: AppState) => JSON.stringify(Selectors.getMoreFilter(state)) !== JSON.stringify({ ...getMoreFilterCriteria(), minutesPlayed: Selectors.getStructure(state).minutesPlayed })
    public static getFilterPanel = (state: AppState) => Selectors.getRoot(state).filterPanel;
    public static getStructure = (state: AppState) => Selectors.getRoot(state).structure;
    public static getSortBy = (state: AppState) => Selectors.getRoot(state).sortBy;
    public static isAvailable = (state: AppState) => !state.auth.clubPermission.availabilityNo || state.auth.isSubscriptionEntryPackage
    public static isStructureLoading = (state: AppState) => Selectors.getRoot(state).structureLoading
    public static isSuggestionsLoading = (state: AppState) => Selectors.getRoot(state).suggestionsLoading
    public static isFiltersInActiveState = (state: AppState) => {
        return Selectors.isActiveClubsAgentsFilter(state) || Selectors.isActiveTransferTypeAndPositionFilter(state) || Selectors.isActiveMoreFilter(state)
    }
    public static isSearchSubsetSelected = (state: AppState) => !!Selectors.getRoot(state).searchSubset
    public static isSearchModeInFilteredState = (state: AppState) => Selectors.getRoot(state).searchMode === SearchMode.Filtered
    public static getLatestFilterSet = (state: AppState): LatestFilterSet => {
        const playerSearchSubstate = state.newPlayerSearch
        return {
            keyword: playerSearchSubstate.search.keyword,
            clubAgentsFilter: playerSearchSubstate.filter.clubAgentsFilter,
            transferTypeFilter: playerSearchSubstate.filter.transferTypeFilter,
            moreFilter: playerSearchSubstate.filter.moreFilter
        }
    }
    public static getInitialFiltersSet = (state: AppState): FiltersSet => {
        return {
            clubAgentsFilter: getClubAgentsFilterCriteria(),
            transferTypeFilter: getTransferTypeFilterCriteria(),
            moreFilter: getMoreFilterCriteria()
        }
    }
}



const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    SearchState as State,
    Actions,
    Selectors,
    stateController as Controller,
};