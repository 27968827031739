import compose from 'lodash/fp/compose';
import {
    PLAYERSEARCH_FILTER_STRUCTURE_FETCH,
    COMPETITION_LIST_CREATE,
    COMPETITION_LIST_DELETE,
    COMPETITION_LIST_UPDATE,
    AUTH_LOGOUT_GLOBAL_DISPOSE,
    PLAYERSEARCH_SCREEN_DISPOSE,
    PLAYERS_FETCH
} from 'store/actionTypes';
import { arrayToObject } from '../../../services/utils';

const INITIAL_STATE = {
    byId: {},
    allIds: [],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AUTH_LOGOUT_GLOBAL_DISPOSE: {
            return INITIAL_STATE;
        }

        case PLAYERSEARCH_SCREEN_DISPOSE: {
         
            return INITIAL_STATE;
        }

        case PLAYERSEARCH_FILTER_STRUCTURE_FETCH.SUCCESS: {
            const { competitionLists } = action.payload.data;
            return {
                ...state,
                byId: arrayToObject(competitionLists),
                allIds: competitionLists.map(list => list.id),
            };
        }

        case PLAYERS_FETCH.SUCCESS: {
            // cancelled request
            if(!action.payload.data) {
                return {
                    ...state
                };
            }
            if(!action.payload.data.groupedSquads) {
                return {
                    ...state
                };
            }
            const { competitionLists } = action.payload.data.groupedSquads;
            if(!competitionLists) {
                return {
                    ...state
                };
            }
            return {
                ...state,
                byId: arrayToObject(competitionLists),
                allIds: competitionLists.map(list => list.id),
            };
        }

        case COMPETITION_LIST_CREATE.SUCCESS: {
            const savedLeagueList = action.payload.data;
            return {
                ...state,
                byId: { ...state.byId, [savedLeagueList.id]: savedLeagueList },
                allIds: state.allIds.concat(savedLeagueList.id),
            };
        }
        case COMPETITION_LIST_UPDATE.SUCCESS: {
            const updatedLeagueList = action.payload.data;
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [updatedLeagueList.id]: updatedLeagueList,
                },
            };
        }
        case COMPETITION_LIST_DELETE.SUCCESS: {
            const ok = action.payload.data;
            const { deletedListId } = action.payload.request;
            const { [deletedListId]: val, ...newById } = state.byId;
            if (!ok) {
                return state;
            }
            return {
                ...state,
                byId: newById,
                allIds: state.allIds.filter(id => id !== deletedListId),
            };
        }
        default:
            return state;
    }
};

const path = state => state.entities.competitionLists;

export const getCompetitionLists = compose(
    lists => lists.allIds.map(id => lists.byId[id]),
    path,
);

export const getLeagueList = compose(
    ads => id => ads.byId[id],
    path,
);
