import { StateController } from "utils/action-declaration";
import { AppState } from "root.reducer";

class SharedModalState {
    isOpen: boolean;
}

const defaultState: SharedModalState = {
    isOpen: false,
}

const stateController = new StateController<SharedModalState>(
    "SHARED_VIDEO_MODAL",
    defaultState
);

class Actions {
    public static onOpen() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isOpen: true }))
        }
    }
    public static onClose() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isOpen: false}))
        }
    }

}

class Selectors {
    public static getRoot = (state: AppState) => state.videoModal.isOpen
}

const reducer = stateController.getReducer();


export {
    reducer as Reducer,
    SharedModalState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};