import { StateController } from 'utils/action-declaration';
import { AdminAllChannelsSearchRequest, GetChatsResponseItem } from 'api/messaging/models/admin-all-channels-search-request'
import { MessagingService } from 'api/messaging/messaging.service'
import { AdminSearchEndpoint } from 'api/messaging/models/admin-suggestion-endpoint'
import _ from 'lodash';
import { AppState } from 'root.reducer'
import { ReceiverType } from 'api/messaging/models/get-channel-response';
import { MessageItem } from 'api/messaging/models/message';

class AdminMessagingState {
    suggestions: Array<AdminSearchEndpoint>;
    channels: Array<GetChatsResponseItem>
    messages: Array<MessageItem>

    channelId: string;

    firstParticipantKeyword: string;
    firstParticipantId: number;
    firstParticipantType: ReceiverType;

    secondParticipantKeyword: string;
    secondParticipantId: number;
    secondParticipantType: ReceiverType;

    currentPage: number;
    recordsPerPage: number;
    totalRecords: number;

    isLoading: boolean;
    isSuggestionsLoading: boolean;
}

const defaultState: AdminMessagingState = {
    suggestions: [],
    channels: [],
    messages: [],

    channelId: '',

    firstParticipantKeyword: '',
    firstParticipantId: null,
    firstParticipantType: null,

    secondParticipantKeyword: '',
    secondParticipantId: null,
    secondParticipantType: null,

    currentPage: 1,
    recordsPerPage: 10,
    totalRecords: 0,

    isLoading: false,
    isSuggestionsLoading: false,
}

const stateController = new StateController<AdminMessagingState>(
    'ADMIN_MESSAGING',
    defaultState
)

class Actions {

    public static onFirstKeywordChange = (keyword: string) => async (dispatch) => {
        dispatch(stateController.setState({ firstParticipantKeyword: keyword }))
        if (keyword === '') {
            await dispatch(stateController.setState({
                firstParticipantKeyword: '',
                firstParticipantId: null,
                firstParticipantType: null,
                currentPage: 1,
                recordsPerPage: 10,
                totalRecords: 0,
            }))
            dispatch(Actions.loadAllChannels())
        }
    }
    public static onSecondKeywordChange = (keyword: string) => async (dispatch) => {
        dispatch(stateController.setState({ secondParticipantKeyword: keyword }))
        if (keyword === '') {
            await dispatch(stateController.setState({
                secondParticipantKeyword: '',
                secondParticipantId: null,
                secondParticipantType: null,
                currentPage: 1,
                recordsPerPage: 10,
                totalRecords: 0,
            }))
            dispatch(Actions.loadAllChannels())
        }
    }
    public static onChannelIdChange = (channelId: string) => (dispatch) => {
        dispatch(stateController.setState({ channelId: channelId }))
        if (channelId === '') {
            dispatch(Actions.disposeState())
            dispatch(Actions.initChannelsAndMessages())
        }
    }

    public static loadFirstParticipantSuggestions() {
        return (dispatch, getState: () => AppState) => {
            const keyword = getState().adminMessaging.firstParticipantKeyword
            if (keyword !== '') {
                dispatch(Actions.loadSuggestions(keyword))
            }
        }
    }
    public static loadFirstSuggestionsDebounceFunc = _.debounce((dispatch) => dispatch(Actions.loadFirstParticipantSuggestions()), 1000)
    public static loadFirstSuggestionsDebounced = () => dispatch => Actions.loadFirstSuggestionsDebounceFunc(dispatch)


    public static loadSecondParticipantSuggestions() {
        return (dispatch, getState: () => AppState) => {
            const keyword = getState().adminMessaging.secondParticipantKeyword
            if (keyword !== '') {
                dispatch(Actions.loadSuggestions(keyword))
            }
        }
    }
    public static loadSecondSuggestionsDebounceFunc = _.debounce((dispatch) => dispatch(Actions.loadSecondParticipantSuggestions()), 1000)
    public static loadSecondSuggestionsDebounced = () => dispatch => Actions.loadSecondSuggestionsDebounceFunc(dispatch)


    public static loadSuggestions(keyword: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const encodedKeyword = encodeURI(keyword)
                dispatch(stateController.setState({ isSuggestionsLoading: true }))
                const suggestions = await MessagingService.getAdminChannelsAutosuggest(encodedKeyword)
                dispatch(stateController.setState({ suggestions: suggestions }))
            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState({ isSuggestionsLoading: false }))
            }
        }
    }


    public static disposeSuggestions = () => dispatch => dispatch(stateController.setState({ suggestions: [] }))

    public static selectFirstParticipantSuggestion(suggestionId: number, receiverType: ReceiverType) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ firstParticipantId: suggestionId, firstParticipantType: receiverType }))
            await dispatch(Actions.loadAllChannels())

            dispatch(Actions.initMessagesOnChennelsFetched())
        }
    }
    public static selectSecondParticipantSuggestion(suggestionId: number, receiverType: ReceiverType) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ secondParticipantId: suggestionId, secondParticipantType: receiverType }))
            await dispatch(Actions.loadAllChannels())

            dispatch(Actions.initMessagesOnChennelsFetched())
        }
    }

    public static initMessagesOnChennelsFetched() {
        return (dispatch, getState: () => AppState) => {
            const channels = getState().adminMessaging.channels
            if (channels.length > 0) {
                const firstChannelId = getState().adminMessaging.channels[0].id
                dispatch(Actions.loadMessages(firstChannelId))
            }
        }
    }

    public static loadAllChannels() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const substate = getState().adminMessaging
                const filterParams: AdminAllChannelsSearchRequest = {
                    keyword: '',
                    firstParticipantId: substate.firstParticipantId,
                    firstParticipantType: substate.firstParticipantType,
                    secondParticipantId: substate.secondParticipantId,
                    secondParticipantType: substate.secondParticipantType,
                    page: substate.currentPage,
                    recordsPerPage: substate.recordsPerPage
                }
                dispatch(stateController.setState({ isLoading: true }))
                const data = await MessagingService.getAdminAllChannels(filterParams)
                
                dispatch(stateController.setState({ channels: data.items, totalRecords: data.total }))
            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState({ isLoading: false }))
            }
        }
    }

    public static initChannelsAndMessages() {
        return async dispatch => {
            await dispatch(Actions.loadAllChannels())
            dispatch(Actions.initMessagesOnChennelsFetched())
        }
    }

    public static loadMessages(channelId: string) {
        return async (dispatch, getState: () => void) => {
            try {
                dispatch(stateController.setState({ isLoading: true }))
                const data = await MessagingService.getAdminMessages(channelId)
                dispatch(stateController.setState({ messages: data.messages }))
            } catch(e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState({ isLoading: false }))
            }
        }
    }

    public static onPageChange(page, pageSize) {
        return async dispatch => {
            await dispatch(stateController.setState({
                currentPage: page,
                recordsPerPage: pageSize
            }))
            dispatch(Actions.loadAllChannels())
        }
    }

    public static disposeState = () => dispatch => dispatch(stateController.setState(defaultState))

}

class Selectors {

}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    AdminMessagingState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};