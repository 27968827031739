import { map, filter } from 'rxjs/operators';
import { getAuthAspId, getAuth } from '../authReducer';

const noActionWithAuthMessage = action =>
    `"${action.type}" ` +
    'action should have "meta.actionWithAuth" property ' +
    'initialized with reference to action to be called ' +
    'with "aspId" argument.';

const fetchAuthEpic = (action$, state$) => {
    return action$.pipe(
        filter(action => action.type.indexOf('--AUTH') > -1),
        map(action => {
            if (!action.meta || !action.meta.actionWithAuth) {
                throw new TypeError(noActionWithAuthMessage(action));
            }
            return action.meta.actionWithAuth({
                aspId: getAuthAspId(state$.value),
                payload: action.payload,
                auth: getAuth(state$.value),
            });
        }),
    );
};

export default fetchAuthEpic;
