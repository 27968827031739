import axios from 'axios-config';
import { CancelToken } from 'axios';
import { SuggestionResponse } from "./model/suggestion-response";
import { AgencySearchFilterCriteria, SearchFilterCriteria, SearchFilterDynamicCriteria } from "./model/search-filter-criteria";
import { SearchCoachRequest, SearchRequest } from "./model/search-request";
import { SearchResult } from "./model/search-result";
import { SearchPlayerItemForAgency } from './model/search-player-item-for-agency';
import { SearchCoachItemForClub } from "./model/search-coach-item-for-club";


export default class AgenciesSearchService {
    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async getSuggestions(
        keyword: string,
        showOnlyAgencies: boolean,
        includeOwnSquad: boolean,
        showOnlyShortlist: boolean,
        playerSet: number[],
        cancelToken: CancelToken
    ): Promise<SuggestionResponse> {
        const { data } = await axios.post(
            `api/v2/AgencySearch/Suggestions`,
            {
                keyword,
                showOnlyAgencies,
                includeOwnSquad,
                showOnlyShortlist,
                playerSet
            },
            { ...this.axiosConfig, cancelToken: cancelToken }
        )
        return data
    }

    public static async getAgenciesCriteria(cancelToken: CancelToken): Promise<AgencySearchFilterCriteria> {
        const { data } = await axios.get(
            `api/v2/AgencySearch/Criteria`,
            { ...this.axiosConfig, cancelToken: cancelToken }
        )
        return data;
    }

    public static async getAgenciesDynamicCriteria(): Promise<SearchFilterDynamicCriteria> {
        const { data } = await axios.get(
            `api/v2/AgencySearch/DynamicCriteria`,
            { ...this.axiosConfig }
        )
        return data;
    }

    public static async agenciesSearchPlayers(request: SearchRequest, cancelToken: CancelToken): Promise<SearchResult<SearchPlayerItemForAgency>> {

        const { data } = await axios.post(
            `api/v2/AgencySearch/Players`,
            request,
            { ...this.axiosConfig, cancelToken: cancelToken }
        );
        return data
    }

    public static async agenciesSearchCoaches(request: SearchCoachRequest, cancelToken: CancelToken): Promise<SearchResult<SearchCoachItemForClub>> {
        const { data } = await axios.post(
            `api/v2/AgencySearch/Coaches`,
            request,
            { ...this.axiosConfig, cancelToken: cancelToken }
        )
        return data
    }
}
