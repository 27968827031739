import React from 'react'
import Panel from 'components/Panel';
import { EventTitle } from '../past-event-title';
import { PastEventStats } from '../past-event-stats';
import { PastEventClubLogos } from '../past-event-club-logos';
import PastEventImage from 'media/images/events/2024-august-madrid/summit-madrid-2024-august-past.jpg';

const MadridSummitAugust2024 = () => {
    return (
        <div className="row">
            <div className="col-md-8 col-12">
                <Panel className="content-wrapper">
                    <EventTitle
                        number="4"
                        bgImageNumber={4}
                        th="th"
                        title1="TransferRoom"
                        title2="Deal Day"
                        date="5th-6th August 2024, Madrid, Spain + Online"
                        vs={true}
                    />
                    <div className="photo-container row" style={{ padding: '0' }}>
                        <img style={{ width: '100%', borderRadius: '10px' }} src={PastEventImage} alt="" />
                    </div>
                </Panel>
            </div>
            <div className="col-md-4 col-12 row no-gutters">
                <div className="col-md-12 col-sm-6 col-12">
                    <PastEventStats
                        participants="519"
                        countries="52"
                        leagues="87"
                        agents="64"
                    />
                </div>
                <div className="col-md-12 col-sm-6 col-12">
                    <PastEventClubLogos
                        attendees={previousEventAttendees}
                        countlimit={42}
                    />
                </div>
            </div>
        </div>
    )
}

export const previousEventAttendees = [
    { id: 2, name: "AS Roma", logo: "logo_1835_AS_Roma.png" },
    { id: 3, name: "Juventus FC", logo: "logo_1832_Juventus_FC.png" },
    { id: 46, name: "Liverpool FC", logo: "logo_1123_Liverpool_FC.png" },
    { id: 47, name: "Tottenham Hotspur", logo: "logo_1122_Tottenham_Hotspur.png" },
    { id: 48, name: "Manchester City", logo: "1625_Manchester_City.png" },
    { id: 49, name: "Arsenal FC", logo: "logo_1121_Arsenal_FC.png" },
    { id: 50, name: "Chelsea FC", logo: "logo_Chelsea_FC.png" },
    { id: 60, name: "Newcastle United", logo: "logo_Newcastle_United.png" },
    { id: 62, name: "Southampton FC", logo: "1619_Southampton_FC.png" },
    { id: 63, name: "Everton FC", logo: "logo_1124_Everton_FC.png" },
    { id: 64, name: "Crystal Palace", logo: "logo_1128_Crystal_Palace.png" },
    { id: 66, name: "Leicester City", logo: "logo_Leicester_City.png" },
    { id: 67, name: "West Ham United", logo: "1633_West_Ham_United.png" },
    { id: 70, name: "Brighton & Hove Albion", logo: "logo_Brighton_And_Hove_Albion_FC.png" },
    { id: 71, name: "AFC Bournemouth", logo: "logo_1134_AFC_Bournemouth.png" },
    { id: 976, name: "AS Monaco", logo: "19830_AS_Monaco.png" },
    { id: 2226, name: "Sevilla FC", logo: "680_Sevilla_FC.png" },
    { id: 4230, name: "Stade de Reims", logo: "3802_Stade_Reims.png" },
    { id: 5282, name: "PSV Eindhoven", logo: "11_PSV_Eindhoven.png" },
    { id: 6207, name: "Molde FK", logo: "7098_Molde_FK.png" },
    { id: 12294, name: "Olympique de Marseille", logo: "logo_1964_Olympique_Marseille.png" },
    { id: 12470, name: "VfL Wolfsburg", logo: "logo_VfL_Wolfsburg.png" },
    { id: 12598, name: "VfB Stuttgart", logo: "logo_1273_VfB_Stuttgart.png" },
    { id: 12686, name: "FC Nordsjaelland", logo: "7458_Nordsjelland.png" },
    { id: 13058, name: "Feyenoord Rotterdam", logo: "logo_1533_Feyenoord_Rotterdam.png" },
    { id: 16046, name: "Olympique Lyonnais", logo: "3766_Olympique_Lyon.png" },
    { id: 16247, name: "Club Athletico Paranaense", logo: "5651_Club_Athletico_Paranaense.png" },
    { id: 19206, name: "Santos FC", logo: "5655_Santos_FC.png" },
    { id: 22514, name: "CF America", logo: "15405_América.png" },
    { id: 26699, name: "Galatasaray", logo: "logo_2003_Galatasaray_SK.png" },
    { id: 28212, name: "RC Strasbourg Alsace", logo: "logo_1981_RC_Strasbourg_Alsace.png" },
    { id: 28879, name: "Athletic Bilbao", logo: "678_Athletic_Bilbao.png" },
    { id: 29455, name: "Nottingham Forest", logo: "logo_1152_Nottingham_Forest.png" },
    { id: 29747, name: "Ajax Amsterdam", logo: "9_Ajax_Amsterdam.png" },
    { id: 32429, name: "Eintracht Frankfurt", logo: "logo_1268_Eintracht_Frankfurt.png" },
    { id: 34079, name: "Celtic FC", logo: "logo_Celtic_FC.png" },
    { id: 35839, name: "Sociedade Esportiva Palmeiras", logo: "5646_Sociedade_Esportiva_Palmeiras.png" },
    { id: 39176, name: "Leeds United", logo: "logo_1150_Leeds_United.png" },
    { id: 39789, name: "Como 1907", logo: "logo_39789_Como_1907.png" },
    { id: 39818, name: "Al-Qadsiah FC", logo: "39818_AlQadsiah.png" },
    { id: 50113, name: "Inter Miami CF", logo: "-69261_Inter_Miami_CF.png" },
    { id: 200001, name: "Mazatlan FC", logo: "-82696_Mazatlán_FC.png" }
];

export default MadridSummitAugust2024