const requestsReducer = (requests = [], action) => {
    if (action.type.endsWith('--SUCCESS')) {
        return requests.filter(r => r.type.SUCCESS !== action.type);
    }
    if (action.type.endsWith('--ERROR')) {
        return requests.filter(r => r.type.ERROR !== action.type);
    }
    if (action.type.endsWith('--REQUEST')) {
        return [...requests, action.payload];
    }
    return requests;
};

export default requestsReducer;

export const getRequests = state => state.requests; 
