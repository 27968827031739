import axios from 'axios-config';

import {
    CreateAgencyPlusPitchRequest,
    GetAvailableSquadsRequest,
    CompetitionWithActiveSquadsAndRecommendersModel,
    PlusPitchSend,
    PlusPitchPermissionResponse,
    SendMessageToClubRequest,
    InitInfo,
} from './models'



export default class AgencyPlusPitchService {


    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async create(request: CreateAgencyPlusPitchRequest): Promise<any> {
        const { data } = await axios.post(
            `api/AgencyPlusPitch/Create`,
            request,
            this.axiosConfig
        );
        return data;
    }

    public static async sendMessage(request: SendMessageToClubRequest): Promise<any> {
        const { data } = await axios.post(
            `api/AgencyPlusPitch/SendMessageToClub`,
            request,
            this.axiosConfig
        );
        return data;
    }

    public static async getSquads(request: GetAvailableSquadsRequest): Promise<CompetitionWithActiveSquadsAndRecommendersModel> {
        const { data } = await axios.post(
            `api/AgencyPlusPitch/AvailableSquads`,
            request,
            this.axiosConfig
        );
        return data;
    }

    public static async testInterest(interests: { pitchId: number, pitchSquadIds: Array<number> }): Promise<any> {
        const { data } = await axios.post(
            `api/AgencyPlusPitch/TestInterest`,
            interests,
            this.axiosConfig
        );
        return data;
    }

    public static async getAll(): Promise<Array<PlusPitchSend>> {
        const { data } = await axios.get(
            `api/AgencyPlusPitch/GetAll`,
            this.axiosConfig
        );

        return data;
    }

    public static async getPermissions(agencyId: number): Promise<Array<PlusPitchPermissionResponse>> {
        const { data } = await axios.post(
            `api/AgencyPlusPitch/GetPermissions`,
            [agencyId],
            this.axiosConfig
        );

        return data;
    }

    public static async getInfo(): Promise<InitInfo> {
        const { data } = await axios.post(
            `api/AgencyPlusPitch/InitInfo`,
            {},
            this.axiosConfig
        );

        return data;
    }



}