export const eventClubs = [
  {
    squadId: 2,
    squadName: "AS Roma",
    shortSquadName: "AS Roma",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1835_AS_Roma.png"
  },
  {
    squadId: 3,
    squadName: "Juventus FC",
    shortSquadName: "Juventus",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1832_Juventus_FC.png"
  },
  {
    squadId: 4,
    squadName: "Parma Calcio 1913",
    shortSquadName: "Parma",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3160_Parma_Calcio_1913.png"
  },
  {
    squadId: 6,
    squadName: "SS Lazio",
    shortSquadName: "Lazio",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1837_SS_Lazio.png"
  },
  {
    squadId: 7,
    squadName: "Udinese Calcio",
    shortSquadName: "Udinese Calcio",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1844_Udinese_Calcio.png"
  },
  {
    squadId: 8,
    squadName: "UC Sampdoria",
    shortSquadName: "Sampdoria",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1842_UC_Sampdoria.png"
  },
  {
    squadId: 14,
    squadName: "FC Empoli",
    shortSquadName: "FC Empoli",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3178_FC_Empoli.png"
  },
  {
    squadId: 15,
    squadName: "Torino FC",
    shortSquadName: "Torino",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1839_Torino_FC.png"
  },
  {
    squadId: 16,
    squadName: "SSC Napoli",
    shortSquadName: "SSC Napoli",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_SSC_Napoli.png"
  },
  {
    squadId: 17,
    squadName: "Genoa CFC",
    shortSquadName: "Genoa",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1843_Genoa_CFC.png"
  },
  {
    squadId: 20,
    squadName: "US Sassuolo",
    shortSquadName: "Sassuolo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3315_US_Sassuolo.png"
  },
  {
    squadId: 45,
    squadName: "Manchester United",
    shortSquadName: "Man Utd",
    squadLogo: null
  },
  {
    squadId: 46,
    squadName: "Liverpool FC",
    shortSquadName: "Liverpool",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1123_Liverpool_FC.png"
  },
  {
    squadId: 48,
    squadName: "Manchester City",
    shortSquadName: "Man City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1625_Manchester_City.png"
  },
  {
    squadId: 49,
    squadName: "Arsenal FC",
    shortSquadName: "Arsenal",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1121_Arsenal_FC.png"
  },
  {
    squadId: 50,
    squadName: "Chelsea FC",
    shortSquadName: "Chelsea",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Chelsea_FC.png"
  },
  {
    squadId: 60,
    squadName: "Newcastle United",
    shortSquadName: "Newcastle",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Newcastle_United.png"
  },
  {
    squadId: 62,
    squadName: "Southampton FC",
    shortSquadName: "Southampton",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1619_Southampton_FC.png"
  },
  {
    squadId: 63,
    squadName: "Everton FC",
    shortSquadName: "Everton",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1124_Everton_FC.png"
  },
  {
    squadId: 64,
    squadName: "Crystal Palace",
    shortSquadName: "Crystal Palace",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1128_Crystal_Palace.png"
  },
  {
    squadId: 65,
    squadName: "Wolverhampton Wanderers",
    shortSquadName: "Wolves",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Wolverhampton_Wanderers.png"
  },
  {
    squadId: 66,
    squadName: "Leicester City",
    shortSquadName: "Leicester",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Leicester_City.png"
  },
  {
    squadId: 67,
    squadName: "West Ham United",
    shortSquadName: "West Ham",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1633_West_Ham_United.png"
  },
  {
    squadId: 68,
    squadName: "Cardiff City",
    shortSquadName: "Cardiff",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1151_Cardiff_City.png"
  },
  {
    squadId: 69,
    squadName: "Watford FC",
    shortSquadName: "Watford",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1644_Watford_FC.png"
  },
  {
    squadId: 70,
    squadName: "Brighton & Hove Albion",
    shortSquadName: "Brighton",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Brighton_And_Hove_Albion_FC.png"
  },
  {
    squadId: 71,
    squadName: "AFC Bournemouth",
    shortSquadName: "Bournemouth",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1134_AFC_Bournemouth.png"
  },
  {
    squadId: 72,
    squadName: "Huddersfield Town",
    shortSquadName: "Huddersfield",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1673_Huddersfield_Town.png"
  },
  {
    squadId: 174,
    squadName: "CA Talleres",
    shortSquadName: "CA Talleres",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2588_Club_Atletico_Talleres.png"
  },
  {
    squadId: 329,
    squadName: "Stromsgodset IF",
    shortSquadName: "Stromsgodset",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7111_Strømsgodset_IF.png"
  },
  {
    squadId: 416,
    squadName: "Al-Fateh SC",
    shortSquadName: "Al-Fateh",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/16483_Al-Fateh_SC.png"
  },
  {
    squadId: 489,
    squadName: "Helmond Sport",
    shortSquadName: "Helmond Sport",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Helmond_Sport.png"
  },
  {
    squadId: 496,
    squadName: "HNK Rijeka",
    shortSquadName: "HNK Rijeka",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2164_HNK_Rijeka.png"
  },
  {
    squadId: 513,
    squadName: "Rodez AF",
    shortSquadName: "Rodez AF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3867_Rodez_AF.png"
  },
  {
    squadId: 542,
    squadName: "FK Atyrau",
    shortSquadName: "Atyrau",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_542_FK_Atyrau.png"
  },
  {
    squadId: 606,
    squadName: "Quevilly - Rouen Metropole",
    shortSquadName: "QRM",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3865_Quevilly_-_Rouen_Métropole.png"
  },
  {
    squadId: 773,
    squadName: "Getafe CF",
    shortSquadName: "Getafe",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1728_Getafe_CF.png"
  },
  {
    squadId: 862,
    squadName: "CD O'Higgins",
    shortSquadName: "O'Higgins",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12302_CD_O'Higgins.png"
  },
  {
    squadId: 976,
    squadName: "AS Monaco",
    shortSquadName: "Monaco",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/19830_AS_Monaco.png"
  },
  {
    squadId: 1003,
    squadName: "Hull City",
    shortSquadName: "Hull City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1672_Hull_City.png"
  },
  {
    squadId: 1142,
    squadName: "AZ Alkmaar",
    shortSquadName: "AZ Alkmaar",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/10_AZ_Alkmaar.png"
  },
  {
    squadId: 1224,
    squadName: "Botafogo FC",
    shortSquadName: "NULL",
    squadLogo: null
  },
  {
    squadId: 1421,
    squadName: "Gremio Foot-Ball Porto Alegrense",
    shortSquadName: "Gremio",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2605_Gremio_Foot-Ball_Porto_Alegrense.png"
  },
  {
    squadId: 1494,
    squadName: "NEC Nijmegen",
    shortSquadName: "NEC Nijmegen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/22_NEC_Nijmegen.png"
  },
  {
    squadId: 1535,
    squadName: "FC Zurich",
    shortSquadName: "FC Zurich",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/6412_FC_Zürich.png"
  },
  {
    squadId: 1559,
    squadName: "FC Cincinnati",
    shortSquadName: "Cincinnati",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/37892_Cincinnati.png"
  },
  {
    squadId: 1740,
    squadName: "Amiens SC",
    shortSquadName: "Amiens SC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3789_Amiens_SC.png"
  },
  {
    squadId: 1930,
    squadName: "FC Viktoria Koln",
    shortSquadName: "Viktoria Koln",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1388_FC_Viktoria_Koln.png"
  },
  {
    squadId: 1949,
    squadName: "Independiente Medellin",
    shortSquadName: "Indep. Medellin",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12399_Independiente_Medellín.png"
  },
  {
    squadId: 2031,
    squadName: "1.FC Union Berlin",
    shortSquadName: "Union Berlin",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1277_1.FC_Union_Berlin.png"
  },
  {
    squadId: 2086,
    squadName: "FC Erzgebirge Aue",
    shortSquadName: "Erzgebirge Aue",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Erzgebirge_Aue.png"
  },
  {
    squadId: 2226,
    squadName: "Sevilla FC",
    shortSquadName: "Sevilla FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/680_Sevilla_FC.png"
  },
  {
    squadId: 2332,
    squadName: "Cercle Brugge",
    shortSquadName: "Cercle Brugge",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5070_Cercle_Brugge.png"
  },
  {
    squadId: 2391,
    squadName: "Roda JC Kerkrade",
    shortSquadName: "Roda JC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Roda_JC_Kerkrade.png"
  },
  {
    squadId: 2507,
    squadName: "SC Cambuur Leeuwarden",
    shortSquadName: "SC Cambuur",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/40_SC_Cambuur-Leeuwarden.png"
  },
  {
    squadId: 2956,
    squadName: "KAA Gent",
    shortSquadName: "KAA Gent",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1571_KAA_Gent.png"
  },
  {
    squadId: 2994,
    squadName: "1.FC Saarbrucken",
    shortSquadName: "Saarbrucken",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2476_1.FC_Saarbrücken.png"
  },
  {
    squadId: 3028,
    squadName: "Palermo FC",
    shortSquadName: "Palermo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3171_Palermo_FC.png"
  },
  {
    squadId: 3177,
    squadName: "FK Spartak Subotica",
    shortSquadName: "Spartak",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1669_FK_Spartak_Subotica.png"
  },
  {
    squadId: 3695,
    squadName: "SK Slavia Prague",
    shortSquadName: "Slavia Prague",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/11242_SK_Slavia_Prague.png"
  },
  {
    squadId: 3704,
    squadName: "FC Hegelmann",
    shortSquadName: "Hegelmann",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/31322_FC_Hegelmann.svg"
  },
  {
    squadId: 3740,
    squadName: "KV Kortrijk",
    shortSquadName: "KV Kortrijk",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_KV_Kortrijk.png"
  },
  {
    squadId: 3774,
    squadName: "Club Necaxa",
    shortSquadName: "Necaxa",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2283_Club_Necaxa.png"
  },
  {
    squadId: 3795,
    squadName: "Shonan Bellmare",
    shortSquadName: "Shonan Bellmare",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2422_Shonan_Bellmare.png"
  },
  {
    squadId: 3895,
    squadName: "Sumgayit PFC",
    shortSquadName: "Sumgayit",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12451_Sumgayit_PFC.png"
  },
  {
    squadId: 4059,
    squadName: "OGC Nice",
    shortSquadName: "OGC Nice",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1965_OGC_Nice.png"
  },
  {
    squadId: 4230,
    squadName: "Stade de Reims",
    shortSquadName: "Stade Reims",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3802_Stade_Reims.png"
  },
  {
    squadId: 4234,
    squadName: "Queens Park Rangers",
    shortSquadName: "QPR",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1149_Queens_Park_Rangers.png"
  },
  {
    squadId: 5065,
    squadName: "RWD Molenbeek",
    shortSquadName: "RWDM",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5622_RWD_Molenbeek.png"
  },
  {
    squadId: 5072,
    squadName: "SC Paderborn 07",
    shortSquadName: "SC Paderborn",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1303_SC_Paderborn_07.png"
  },
  {
    squadId: 5109,
    squadName: "PEC Zwolle",
    shortSquadName: "PEC Zwolle",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1543_PEC_Zwolle.png"
  },
  {
    squadId: 5121,
    squadName: "Minnesota United FC",
    shortSquadName: "Minnesota",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2122_Minnesota_United_FC.png"
  },
  {
    squadId: 5282,
    squadName: "PSV Eindhoven",
    shortSquadName: "PSV Eindhoven",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/11_PSV_Eindhoven.png"
  },
  {
    squadId: 5306,
    squadName: "Rakow Czestochowa",
    shortSquadName: "Rakow",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1622_Rakow_Czestochowa.png"
  },
  {
    squadId: 5325,
    squadName: "IF Gnistan",
    shortSquadName: "IF Gnistan",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_5325_IF_Gnistan.png"
  },
  {
    squadId: 5359,
    squadName: "Caracas FC",
    shortSquadName: "Caracas FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15680_Caracas_FC.png"
  },
  {
    squadId: 5592,
    squadName: "SD Aucas",
    shortSquadName: "SD Aucas",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_5592_SD_Aucas.png"
  },
  {
    squadId: 5842,
    squadName: "Hobro IK",
    shortSquadName: "Hobro IK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Hobro_IK.png"
  },
  {
    squadId: 5869,
    squadName: "Viking FK",
    shortSquadName: "Viking",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7100_Viking_Stavanger.png"
  },
  {
    squadId: 5924,
    squadName: "Everton de Vina del Mar",
    shortSquadName: "CD Everton",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12293_CD_Everton.png"
  },
  {
    squadId: 6051,
    squadName: "Real Valladolid CF",
    shortSquadName: "Real Valladolid",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/690_Real_Valladolid_CF.png"
  },
  {
    squadId: 6059,
    squadName: "CD Leganes",
    shortSquadName: "CD Leganes",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/712_CD_Leganés.png"
  },
  {
    squadId: 6098,
    squadName: "Kalmar FF",
    shortSquadName: "Kalmar FF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Kalmar_FF.png"
  },
  {
    squadId: 6249,
    squadName: "Bayer 04 Leverkusen",
    shortSquadName: "B. Leverkusen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1260_Bayer_04_Leverkusen.png"
  },
  {
    squadId: 6306,
    squadName: "Hibernian FC",
    shortSquadName: "Hibernian FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Hibernian_FC.png"
  },
  {
    squadId: 6313,
    squadName: "ESTAC Troyes",
    shortSquadName: "Troyes",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3795_ESTAC_Troyes.png"
  },
  {
    squadId: 6571,
    squadName: "US Salernitana 1919",
    shortSquadName: "Salernitana",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1865_US_Salernitana_1919.png"
  },
  {
    squadId: 7374,
    squadName: "Lommel SK",
    shortSquadName: "Lommel SK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5122_Lommel_SK.png"
  },
  {
    squadId: 7390,
    squadName: "Club Atletico Lanus",
    shortSquadName: "Lanus",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12063_Club_Atlético_Lanús.png"
  },
  {
    squadId: 7400,
    squadName: "Botafogo de Futebol e Regatas",
    shortSquadName: "Botafogo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_7400_Botafogo_de_Futebol_e_Regatas.png"
  },
  {
    squadId: 7527,
    squadName: "FC Vaduz",
    shortSquadName: "FC Vaduz",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2354_FC_Vaduz.png"
  },
  {
    squadId: 7783,
    squadName: "Pyramids FC",
    shortSquadName: "Pyramids FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/27533_Pyramids_Al_Ahram_FC.png"
  },
  {
    squadId: 7819,
    squadName: "Sarpsborg 08 FF",
    shortSquadName: "Sarpsborg 08",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1472_Sarpsborg_08_FF.png"
  },
  {
    squadId: 7845,
    squadName: "CF Pachuca",
    shortSquadName: "Pachuca",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15413_CF_Pachuca.png"
  },
  {
    squadId: 8064,
    squadName: "Kawasaki Frontale",
    shortSquadName: "Kawasaki Front.",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2410_Kawasaki_Frontale.png"
  },
  {
    squadId: 8110,
    squadName: "Hertha BSC",
    shortSquadName: "Hertha BSC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2457_Hertha_BSC.png"
  },
  {
    squadId: 8239,
    squadName: "Brentford FC",
    shortSquadName: "Brentford",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1153_Brentford_FC.png"
  },
  {
    squadId: 8624,
    squadName: "Sutton United",
    shortSquadName: "Sutton Utd",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1214_Sutton_United.png"
  },
  {
    squadId: 8665,
    squadName: "New York City FC",
    shortSquadName: "New York City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2109_New_York_City_FC.png"
  },
  {
    squadId: 8837,
    squadName: "FC Sheriff Tiraspol",
    shortSquadName: "FC Sheriff",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/11056_FC_Sheriff_Tiraspol.png"
  },
  {
    squadId: 8839,
    squadName: "Ferroviaria",
    shortSquadName: "Ferroviaria",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_8839_Associacao_Ferroviaria_de_Esportes_(SP).png"
  },
  {
    squadId: 8934,
    squadName: "FC Sion",
    shortSquadName: "FC Sion",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/6421_FC_Sion.png"
  },
  {
    squadId: 9029,
    squadName: "FC Ingolstadt 04",
    shortSquadName: "FC Ingolstadt",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2579_FC_Ingolstadt_04.png"
  },
  {
    squadId: 9150,
    squadName: "Venezia FC",
    shortSquadName: "Venezia",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3191_Venezia_FC.svg"
  },
  {
    squadId: 9276,
    squadName: "CF Monterrey",
    shortSquadName: "Monterrey",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2267_CF_Monterrey.png"
  },
  {
    squadId: 9451,
    squadName: "Hamilton Academical FC",
    shortSquadName: "Hamilton Acad.",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Hamilton_Academical.png"
  },
  {
    squadId: 9732,
    squadName: "FK Javor-Matis Ivanjica",
    shortSquadName: "Javor-Matis",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1670_FK_Javor-Matis_Ivanjica.png"
  },
  {
    squadId: 9954,
    squadName: "Sparta Rotterdam",
    shortSquadName: "Sparta R.",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Sparta_Rotterdam.png"
  },
  {
    squadId: 9984,
    squadName: "CA Velez Sarsfield",
    shortSquadName: "Velez Sarsfield",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12073_Club_Atlético_Vélez_Sarsfield.png"
  },
  {
    squadId: 10026,
    squadName: "Los Angeles Galaxy",
    shortSquadName: "Los Angeles",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7847_Los_Angeles_Galaxy.png"
  },
  {
    squadId: 10065,
    squadName: "Partick Thistle FC",
    shortSquadName: "Partick Thistle",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2195_Partick_Thistle_FC.png"
  },
  {
    squadId: 10088,
    squadName: "RB Leipzig",
    shortSquadName: "RB Leipzig",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1261_RB_Leipzig.png"
  },
  {
    squadId: 10158,
    squadName: "Toulouse FC",
    shortSquadName: "Toulouse FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3780_FC_Toulouse.png"
  },
  {
    squadId: 10223,
    squadName: "CD Castellon",
    shortSquadName: "Castellon",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_3039_CD_Castellon.png"
  },
  {
    squadId: 10231,
    squadName: "FC Schaffhausen",
    shortSquadName: "FC Schaffhausen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2359_FC_Schaffhausen.png"
  },
  {
    squadId: 10317,
    squadName: "New York Red Bulls",
    shortSquadName: "New York",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2112_New_York_Red_Bulls.png"
  },
  {
    squadId: 10422,
    squadName: "Vancouver Whitecaps FC",
    shortSquadName: "Vancouver",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15015_Vancouver_Whitecaps_FC.png"
  },
  {
    squadId: 10541,
    squadName: "USL Dunkerque",
    shortSquadName: "USL Dunkerque",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3904_USL_Dunkerque.png"
  },
  {
    squadId: 10774,
    squadName: "IFK Varnamo",
    shortSquadName: "Varnamo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/6769_IFK_Värnamo.png"
  },
  {
    squadId: 11209,
    squadName: "Bristol City",
    shortSquadName: "Bristol City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1655_Bristol_City.png"
  },
  {
    squadId: 11256,
    squadName: "Club Guarani",
    shortSquadName: "Guarani",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_11256_Club_Guarani.png"
  },
  {
    squadId: 11585,
    squadName: "Tromso IL",
    shortSquadName: "Tromso",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7089_Tromsø_IL.png"
  },
  {
    squadId: 11592,
    squadName: "Venados FC Yucatan",
    shortSquadName: "Venados FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15428_Venados_FC_Yucatán.png"
  },
  {
    squadId: 11717,
    squadName: "Fehervar FC",
    shortSquadName: "Fehervar",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/10260_Fehérvár_FC.png"
  },
  {
    squadId: 12027,
    squadName: "CA Boston River",
    shortSquadName: "Boston River",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2875_Club_Atletico_Boston_River.png"
  },
  {
    squadId: 12139,
    squadName: "Budapest Honved FC",
    shortSquadName: "Honved",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/10194_Budapest_Honvéd_FC.png"
  },
  {
    squadId: 12173,
    squadName: "Barnsley FC",
    shortSquadName: "Barnsley FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1158_Barnsley_FC.png"
  },
  {
    squadId: 12294,
    squadName: "Olympique de Marseille",
    shortSquadName: "Olympique Marseille",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1964_Olympique_Marseille.png"
  },
  {
    squadId: 12470,
    squadName: "VfL Wolfsburg",
    shortSquadName: "VfL Wolfsburg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_VfL_Wolfsburg.png"
  },
  {
    squadId: 12598,
    squadName: "VfB Stuttgart",
    shortSquadName: "VfB Stuttgart",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1273_VfB_Stuttgart.png"
  },
  {
    squadId: 12612,
    squadName: "Hammarby IF",
    shortSquadName: "Hammarby",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/6710_Hammarby_IF.png"
  },
  {
    squadId: 12686,
    squadName: "FC Nordsjaelland",
    shortSquadName: "Nordsjaelland",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7458_Nordsjelland.png"
  },
  {
    squadId: 13008,
    squadName: "Paris FC",
    shortSquadName: "Paris FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3846_Paris_FC.png"
  },
  {
    squadId: 13058,
    squadName: "Feyenoord Rotterdam",
    shortSquadName: "Feyenoord",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1533_Feyenoord_Rotterdam.png"
  },
  {
    squadId: 13109,
    squadName: "Sint-Truidense VV",
    shortSquadName: "Sint-Truiden",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1582_Sint-Truidense_VV.png"
  },
  {
    squadId: 13234,
    squadName: "Vissel Kobe",
    shortSquadName: "Vissel Kobe",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2409_Vissel_Kobe.png"
  },
  {
    squadId: 13297,
    squadName: "GD Estoril Praia",
    shortSquadName: "Estoril Praia",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1680_GD_Estoril_Praia.png"
  },
  {
    squadId: 13371,
    squadName: "NK Tabor Sezana",
    shortSquadName: "Tabor Sezana",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/11971_MND_Tabor_Sežana.png"
  },
  {
    squadId: 13404,
    squadName: "Houston Dynamo FC",
    shortSquadName: "Houston",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7944_Houston_Dynamo_FC.png"
  },
  {
    squadId: 13510,
    squadName: "FC Utrecht",
    shortSquadName: "FC Utrecht",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/17_FC_Utrecht.png"
  },
  {
    squadId: 13576,
    squadName: "Sonderjyske Fodbold",
    shortSquadName: "Sonderjyske",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7499_Sønderjyske_Fodbold.png"
  },
  {
    squadId: 13679,
    squadName: "FC Lausanne-Sport",
    shortSquadName: "Lausanne-Sport",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/6434_FC_Lausanne-Sport.png"
  },
  {
    squadId: 13756,
    squadName: "Debreceni VSC",
    shortSquadName: "Debrecen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1634_Debreceni_VSC.png"
  },
  {
    squadId: 14079,
    squadName: "Fortaleza Esporte Clube",
    shortSquadName: "Fortaleza",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5663_Fortaleza_Esporte_Clube.png"
  },
  {
    squadId: 14181,
    squadName: "Chattanooga FC",
    shortSquadName: "Chattanooga FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/8116_Chattanooga_Football_Club.png"
  },
  {
    squadId: 14291,
    squadName: "FK Sarajevo",
    shortSquadName: "FK Sarajevo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/9918_FK_Sarajevo.png"
  },
  {
    squadId: 14636,
    squadName: "Shelbourne FC",
    shortSquadName: "Shelbourne",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_14636_Shelbourne_FC.png"
  },
  {
    squadId: 14649,
    squadName: "SK Beveren",
    shortSquadName: "SK Beveren",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5104_SK_Beveren.png"
  },
  {
    squadId: 14681,
    squadName: "Panetolikos GFS",
    shortSquadName: "Panetolikos",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2028_Panetolikos_GFS.png"
  },
  {
    squadId: 14781,
    squadName: "MVV Maastricht",
    shortSquadName: "MVV Maastricht",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_MVV_Maastricht.png"
  },
  {
    squadId: 14930,
    squadName: "Celaya FC",
    shortSquadName: "Celaya",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15426_Celaya_FC.png"
  },
  {
    squadId: 15024,
    squadName: "Yverdon Sport FC",
    shortSquadName: "Yverdon Sport",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/6422_Yverdon_Sport_FC.png"
  },
  {
    squadId: 15052,
    squadName: "Sogndal IL",
    shortSquadName: "Sogndal",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7101_Sogndal_IL.png"
  },
  {
    squadId: 15130,
    squadName: "AIK",
    shortSquadName: "AIK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1436_AIK_Solna.png"
  },
  {
    squadId: 15241,
    squadName: "Magallanes CF",
    shortSquadName: "Magallanes",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12321_Club_Deportivo_Magallanes.png"
  },
  {
    squadId: 15268,
    squadName: "Widzew Lodz",
    shortSquadName: "Widzew Lodz",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/13515_Widzew_Lodz.png"
  },
  {
    squadId: 15469,
    squadName: "Club Nacional",
    shortSquadName: "Nacional",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15634_Club_Nacional.png"
  },
  {
    squadId: 15517,
    squadName: "Oud-Heverlee Leuven",
    shortSquadName: "OH Leuven",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5121_Oud-Heverlee_Leuven.png"
  },
  {
    squadId: 15549,
    squadName: "FC Basel 1893",
    shortSquadName: "FC Basel",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1932_FC_Basel_1893.png"
  },
  {
    squadId: 15581,
    squadName: "Lech Poznan",
    shortSquadName: "Lech Poznan",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1595_Lech_Poznan.png"
  },
  {
    squadId: 15715,
    squadName: "Diosgyori VTK",
    shortSquadName: "Diosgyor",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_15715_Diosgyori_VTK.png"
  },
  {
    squadId: 15741,
    squadName: "US Triestina",
    shortSquadName: "Triestina",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1905_US_Triestina.png"
  },
  {
    squadId: 15840,
    squadName: "Warta Poznan",
    shortSquadName: "Warta Poznan",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/13545_Warta_Poznan.png"
  },
  {
    squadId: 15862,
    squadName: "Universitario de Deportes",
    shortSquadName: "Universitario",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_15862_Universitario_de_Deportes.png"
  },
  {
    squadId: 16011,
    squadName: "Stevenage FC",
    shortSquadName: "Stevenage",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1705_Stevenage_FC.png"
  },
  {
    squadId: 16046,
    squadName: "Olympique Lyonnais",
    shortSquadName: "Olympique Lyon",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3766_Olympique_Lyon.png"
  },
  {
    squadId: 16173,
    squadName: "SCR Altach",
    shortSquadName: "SCR Altach",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1946_SC_Rheindorf_Altach.png"
  },
  {
    squadId: 16232,
    squadName: "FC Girondins Bordeaux",
    shortSquadName: "G. Bordeaux",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3772_FC_Girondins_Bordeaux.png"
  },
  {
    squadId: 16247,
    squadName: "Club Athletico Paranaense",
    shortSquadName: "Athletico-PR",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5651_Club_Athletico_Paranaense.png"
  },
  {
    squadId: 16335,
    squadName: "Beerschot V.A.",
    shortSquadName: "Beerschot V.A.",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5280_K._Beerschot_V.A..png"
  },
  {
    squadId: 16437,
    squadName: "Excelsior Rotterdam",
    shortSquadName: "Excelsior",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/18608_SBV_Excelsior_Rotterdam.png"
  },
  {
    squadId: 16727,
    squadName: "Fortaleza CEIF",
    shortSquadName: "Fortaleza CEIF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12427_Fortaleza_CEIF_FC.png"
  },
  {
    squadId: 16832,
    squadName: "Boldklubben af 1893",
    shortSquadName: "B.93",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_16832_Boldklubben_af_1893.png"
  },
  {
    squadId: 17005,
    squadName: "Samsunspor",
    shortSquadName: "Samsunspor",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/4495_Samsunspor.png"
  },
  {
    squadId: 17017,
    squadName: "Philadelphia Union",
    shortSquadName: "Philadelphia",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2115_Philadelphia_Union.png"
  },
  {
    squadId: 17043,
    squadName: "FC Tokyo",
    shortSquadName: "FC Tokyo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2416_FC_Tokyo.png"
  },
  {
    squadId: 17288,
    squadName: "Riga FC",
    shortSquadName: "Riga",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2924_Riga_FC.png"
  },
  {
    squadId: 17331,
    squadName: "TOP Oss",
    shortSquadName: "TOP Oss",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1565_TOP_Oss.png"
  },
  {
    squadId: 17417,
    squadName: "BSC Young Boys",
    shortSquadName: "BSC Young Boys",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1934_BSC_Young_Boys.png"
  },
  {
    squadId: 17454,
    squadName: "Avai FC",
    shortSquadName: "Avai FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5666_Avaí_Futebol_Clube_(SC).png"
  },
  {
    squadId: 17668,
    squadName: "FC Famalicao",
    shortSquadName: "Famalicao",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Famalicao.png"
  },
  {
    squadId: 17761,
    squadName: "Kashima Antlers",
    shortSquadName: "Kashima Antlers",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2411_Kashima_Antlers.png"
  },
  {
    squadId: 17770,
    squadName: "Adelaide United",
    shortSquadName: "Adelaide United",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2482_Adelaide_United.png"
  },
  {
    squadId: 17825,
    squadName: "Hillerod Fodbold",
    shortSquadName: "Hillerod F",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7699_Hilleröd_Fodbold.png"
  },
  {
    squadId: 18028,
    squadName: "FC Lorient",
    shortSquadName: "FC Lorient",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3788_FC_Lorient.png"
  },
  {
    squadId: 18100,
    squadName: "Cracovia",
    shortSquadName: "Cracovia",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1598_Cracovia_Krakow.png"
  },
  {
    squadId: 18179,
    squadName: "SC Verl",
    shortSquadName: "SC Verl",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1395_SC_Verl.png"
  },
  {
    squadId: 18516,
    squadName: "SV Wehen Wiesbaden",
    shortSquadName: "Wehen Wiesbaden",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1294_SV_Wehen_Wiesbaden.png"
  },
  {
    squadId: 18582,
    squadName: "Panathinaikos FC",
    shortSquadName: "Panathinaikos",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2022_Panathinaikos_Athens.png"
  },
  {
    squadId: 18651,
    squadName: "Rosenborg BK",
    shortSquadName: "Rosenborg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1464_Rosenborg_BK.png"
  },
  {
    squadId: 18697,
    squadName: "FC Augsburg",
    shortSquadName: "FC Augsburg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1274_FC_Augsburg.png"
  },
  {
    squadId: 18894,
    squadName: "Brondby IF",
    shortSquadName: "Brondby IF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1407_Brondby_IF.png"
  },
  {
    squadId: 18960,
    squadName: "FC Porto",
    shortSquadName: "FC Porto",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1672_FC_Porto.png"
  },
  {
    squadId: 19055,
    squadName: "Shimizu S-Pulse",
    shortSquadName: "Shimizu S-Pulse",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2418_Shimizu_S-Pulse.png"
  },
  {
    squadId: 19151,
    squadName: "Clermont Foot 63",
    shortSquadName: "Clermont Foot",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3797_Clermont_Foot_63.png"
  },
  {
    squadId: 19616,
    squadName: "Aalesunds FK",
    shortSquadName: "Aalesund",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7106_Aalesunds_FK.png"
  },
  {
    squadId: 19631,
    squadName: "FK Vojvodina Novi Sad",
    shortSquadName: "Vojvodina",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1660_FK_Vojvodina_Novi_Sad.png"
  },
  {
    squadId: 19775,
    squadName: "HB Koge",
    shortSquadName: "HB Koge",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_HB_Koge.png"
  },
  {
    squadId: 20016,
    squadName: "Motherwell FC",
    shortSquadName: "Motherwell FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Motherwell_FC.png"
  },
  {
    squadId: 20050,
    squadName: "Giresunspor",
    shortSquadName: "Giresunspor",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_20050_Giresunspor.png"
  },
  {
    squadId: 20133,
    squadName: "Chicago Fire FC",
    shortSquadName: "Chicago",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7848_Chicago_Fire_FC.png"
  },
  {
    squadId: 20144,
    squadName: "Plymouth Argyle",
    shortSquadName: "Plymouth",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1649_Plymouth_Argyle.png"
  },
  {
    squadId: 20454,
    squadName: "Legia Warszawa",
    shortSquadName: "Legia Warszawa",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1594_Legia_Warszawa.png"
  },
  {
    squadId: 20482,
    squadName: "FC Noah Yerevan",
    shortSquadName: "FC Noah",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_20482_FC_Noah_Yerevan.png"
  },
  {
    squadId: 20630,
    squadName: "Gillingham FC",
    shortSquadName: "Gillingham FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1648_Gillingham_FC.png"
  },
  {
    squadId: 20830,
    squadName: "SV Sandhausen",
    shortSquadName: "SV Sandhausen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_SV_Sandhausen.png"
  },
  {
    squadId: 20887,
    squadName: "Millwall FC",
    shortSquadName: "Millwall",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1160_Millwall_FC.png"
  },
  {
    squadId: 21139,
    squadName: "Olympiacos Piraeus",
    shortSquadName: "Olympiacos",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12509_Olympiacos_Piraeus.png"
  },
  {
    squadId: 21425,
    squadName: "FC St. Gallen 1879",
    shortSquadName: "FC St. Gallen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1935_FC_St._Gallen_1879.png"
  },
  {
    squadId: 21491,
    squadName: "FBC Melgar",
    shortSquadName: "FBC Melgar",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15534_FBC_Melgar.png"
  },
  {
    squadId: 21706,
    squadName: "FC Hansa Rostock",
    shortSquadName: "Hansa Rostock",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Hansa_Rostock.png"
  },
  {
    squadId: 21992,
    squadName: "Maritimo SC",
    shortSquadName: "NULL",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/9799_Maritimo_SC.png"
  },
  {
    squadId: 22011,
    squadName: "Puebla FC",
    shortSquadName: "Puebla FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15414_Puebla_FC.png"
  },
  {
    squadId: 22191,
    squadName: "Gamba Osaka",
    shortSquadName: "Gamba Osaka",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2413_Gamba_Osaka.png"
  },
  {
    squadId: 22198,
    squadName: "TSV 1860 Munich",
    shortSquadName: "1860 Munich",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2461_TSV_1860_Munich.png"
  },
  {
    squadId: 22240,
    squadName: "PAOK Thessaloniki",
    shortSquadName: "PAOK Salonika",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2021_PAOK_Thessaloniki.png"
  },
  {
    squadId: 22292,
    squadName: "FK Zeljeznicar Sarajevo",
    shortSquadName: "Zeljeznicar",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_22292_FK_Zeljeznicar_Sarajevo.png"
  },
  {
    squadId: 22392,
    squadName: "Maccabi Tel Aviv",
    shortSquadName: "M. Tel Aviv",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/13270_Maccabi_Tel_Aviv.png"
  },
  {
    squadId: 22514,
    squadName: "CF America",
    shortSquadName: "America",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15405_América.png"
  },
  {
    squadId: 22719,
    squadName: "Clube de Regatas Vasco da Gama",
    shortSquadName: "Vasco da Gama",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5657_Club_de_Regatas_Vasco_da_Gama.png"
  },
  {
    squadId: 22720,
    squadName: "Korona Kielce",
    shortSquadName: "Korona Kielce",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1605_Korona_Kielce.png"
  },
  {
    squadId: 22788,
    squadName: "Red Bull Bragantino",
    shortSquadName: "RB Bragantino",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5685_Red_Bull_Bragantino.png"
  },
  {
    squadId: 22827,
    squadName: "HNK Hajduk Split",
    shortSquadName: "Hajduk Split",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2165_HNK_Hajduk_Split.png"
  },
  {
    squadId: 22866,
    squadName: "KMSK Deinze",
    shortSquadName: "KMSK Deinze",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2368_KMSK_Deinze.png"
  },
  {
    squadId: 22922,
    squadName: "FK Zeleziarne Podbrezova",
    shortSquadName: "Podbrezova",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/14379_FK_Zeleziarne_Podbrezova.png"
  },
  {
    squadId: 22992,
    squadName: "Pisa Sporting Club",
    shortSquadName: "Pisa",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3207_AC_Pisa_1909.png"
  },
  {
    squadId: 23117,
    squadName: "FC Versailles 78",
    shortSquadName: "FC Versailles",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/4213_FC_Versailles_78.png"
  },
  {
    squadId: 23168,
    squadName: "1.FC Koln",
    shortSquadName: "1.FC Koln",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1266_1._FC_Koln.png"
  },
  {
    squadId: 23506,
    squadName: "Atlas Guadalajara",
    shortSquadName: "Atlas",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15407_Atlas.png"
  },
  {
    squadId: 23588,
    squadName: "SC Heerenveen",
    shortSquadName: "Heerenveen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1537_SC_Heerenveen.png"
  },
  {
    squadId: 23647,
    squadName: "Atletico de San Luis",
    shortSquadName: "San Luis",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/25866_Atlético_de_San_Luis.png"
  },
  {
    squadId: 23873,
    squadName: "Botev Plovdiv",
    shortSquadName: "Botev Plovdiv",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2039_Botev_Plovdiv.png"
  },
  {
    squadId: 24099,
    squadName: "Valenciennes FC",
    shortSquadName: "Valenciennes FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3814_Valenciennes_FC.png"
  },
  {
    squadId: 24198,
    squadName: "Esporte Clube Bahia",
    shortSquadName: "EC Bahia",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5677_Esporte_Clube_Bahia.png"
  },
  {
    squadId: 24387,
    squadName: "Luton Town",
    shortSquadName: "Luton",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Luton_Town.png"
  },
  {
    squadId: 24432,
    squadName: "Pafos FC",
    shortSquadName: "Pafos FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/34760_Pafos_FC.png"
  },
  {
    squadId: 24448,
    squadName: "Aarhus GF",
    shortSquadName: "Aarhus GF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1410_Aarhus_GF.png"
  },
  {
    squadId: 24449,
    squadName: "Viborg FF",
    shortSquadName: "Viborg FF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Viborg_FF.png"
  },
  {
    squadId: 24695,
    squadName: "Union Saint-Gilloise",
    shortSquadName: "Union SG",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1590_Royale_Union_Saint_Gilloise.png"
  },
  {
    squadId: 24701,
    squadName: "CF Atlante",
    shortSquadName: "Atlante",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15406_CF_Atlante.png"
  },
  {
    squadId: 24711,
    squadName: "Urawa Red Diamonds",
    shortSquadName: "Urawa Reds",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2407_Urawa_Red_Diamonds.png"
  },
  {
    squadId: 24806,
    squadName: "Deportivo de La Coruna",
    shortSquadName: "Dep. La Coruna",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1721_Deportivo_de_La_Coruna.png"
  },
  {
    squadId: 24812,
    squadName: "Club Bolivar",
    shortSquadName: "Club Bolivar",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/10474_Bolívar_La_Paz.png"
  },
  {
    squadId: 24973,
    squadName: "Queretaro FC",
    shortSquadName: "Queretaro FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15421_Querétaro_FC.png"
  },
  {
    squadId: 25090,
    squadName: "Cimarrones de Sonora",
    shortSquadName: "Cimarrones",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/26089_Cimarrones_de_Sonora.png"
  },
  {
    squadId: 25285,
    squadName: "Levante UD",
    shortSquadName: "Levante",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Levante_UD.png"
  },
  {
    squadId: 25436,
    squadName: "Nashville SC",
    shortSquadName: "Nashville",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/30149_Nashville.png"
  },
  {
    squadId: 25519,
    squadName: "Rapid Vienna",
    shortSquadName: "Rapid Vienna",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1943_Rapid_Vienna.png"
  },
  {
    squadId: 25629,
    squadName: "RSC Anderlecht",
    shortSquadName: "RSC Anderlecht",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_RSC_Anderlecht.png"
  },
  {
    squadId: 25671,
    squadName: "AC Reggiana 1919",
    shortSquadName: "Reggiana",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3170_AC_Reggiana_1919.png"
  },
  {
    squadId: 25911,
    squadName: "Royal Antwerp FC",
    shortSquadName: "Royal Antwerp",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1583_Royal_Antwerp_FC.png"
  },
  {
    squadId: 26054,
    squadName: "CFR Cluj",
    shortSquadName: "CFR Cluj",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2341_CFR_Cluj.png"
  },
  {
    squadId: 26056,
    squadName: "AeK Athens",
    shortSquadName: "AeK Athens",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12511_AEK_Athens.png"
  },
  {
    squadId: 26202,
    squadName: "Ludogorets Razgrad",
    shortSquadName: "Ludogorets",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/9311_Ludogorets.png"
  },
  {
    squadId: 26232,
    squadName: "FC Schalke 04",
    shortSquadName: "FC Schalke 04",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Schalke_04.png"
  },
  {
    squadId: 26353,
    squadName: "SV Darmstadt 98",
    shortSquadName: "Darmstadt 98",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2520_SV_Darmstadt_98.png"
  },
  {
    squadId: 26457,
    squadName: "Stabaek Fotball",
    shortSquadName: "Stabaek",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1473_Stabaek_IF.png"
  },
  {
    squadId: 26540,
    squadName: "Orlando City SC",
    shortSquadName: "Orlando",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/8068_Orlando_City_SC.png"
  },
  {
    squadId: 26635,
    squadName: "Swift Hesperange",
    shortSquadName: "Hesperange",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_26635_Swift_Hesperange.png"
  },
  {
    squadId: 26667,
    squadName: "Coritiba Foot Ball Club",
    shortSquadName: "Coritiba FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2625_Coritiba_Foot_Ball_Club.png"
  },
  {
    squadId: 26699,
    squadName: "Galatasaray",
    shortSquadName: "Galatasaray",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2003_Galatasaray_SK.png"
  },
  {
    squadId: 27176,
    squadName: "KV Mechelen",
    shortSquadName: "KV Mechelen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Yellow-Red_KV_Mechelen.png"
  },
  {
    squadId: 27251,
    squadName: "CR Flamengo",
    shortSquadName: "Flamengo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5654_Clube_de_Regatas_do_Flamengo.png"
  },
  {
    squadId: 27570,
    squadName: "Puskas Akademia FC",
    shortSquadName: "Puskas AFC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1630_Puskas_Akademia_FC.png"
  },
  {
    squadId: 27650,
    squadName: "FC Juarez",
    shortSquadName: "FC Juarez",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/34402_FC_Juárez.png"
  },
  {
    squadId: 27833,
    squadName: "America Futebol Clube (MG)",
    shortSquadName: "America-MG",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5673_América_Futebol_Clube_(MG).png"
  },
  {
    squadId: 28108,
    squadName: "FC Wil 1900",
    shortSquadName: "FC Wil 1900",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2361_FC_Wil_1900.png"
  },
  {
    squadId: 28130,
    squadName: "IF Brommapojkarna",
    shortSquadName: "Brommapojkarna",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_IF_Brommapojkarna.png"
  },
  {
    squadId: 28158,
    squadName: "Aalborg BK",
    shortSquadName: "Aalborg BK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Aalborg_BK.png"
  },
  {
    squadId: 28212,
    squadName: "RC Strasbourg Alsace",
    shortSquadName: "R. Strasbourg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1981_RC_Strasbourg_Alsace.png"
  },
  {
    squadId: 28242,
    squadName: "Kryvbas Kryvyi Rig",
    shortSquadName: "Kryvbas",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_28242_Kryvbas_Kryvyi_Rig.png"
  },
  {
    squadId: 28434,
    squadName: "FCSB",
    shortSquadName: "FCSB",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2340_FCSB.png"
  },
  {
    squadId: 28998,
    squadName: "Real Betis Balompie",
    shortSquadName: "Real Betis",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/684_Real_Betis_Balompié.png"
  },
  {
    squadId: 29036,
    squadName: "SC Telstar",
    shortSquadName: "Telstar",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_SC_Telstar.png"
  },
  {
    squadId: 29065,
    squadName: "Club Sporting Cristal",
    shortSquadName: "Sport. Cristal",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15532_Club_Sporting_Cristal.png"
  },
  {
    squadId: 29285,
    squadName: "BK Hacken",
    shortSquadName: "Hacken",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/6736_BK_Häcken.png"
  },
  {
    squadId: 29455,
    squadName: "Nottingham Forest",
    shortSquadName: "Nottm Forest",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1152_Nottingham_Forest.png"
  },
  {
    squadId: 29469,
    squadName: "GNK Dinamo Zagreb",
    shortSquadName: "Dinamo Zagreb",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2163_GNK_Dinamo_Zagreb.png"
  },
  {
    squadId: 29580,
    squadName: "NK Bravo",
    shortSquadName: "NK Bravo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/35651_AŠK_Bravo_Publikum.png"
  },
  {
    squadId: 29704,
    squadName: "NAC Breda",
    shortSquadName: "NAC Breda",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_NAC_Breda.png"
  },
  {
    squadId: 29747,
    squadName: "Ajax Amsterdam",
    shortSquadName: "Ajax",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/9_Ajax_Amsterdam.png"
  },
  {
    squadId: 29766,
    squadName: "Gornik Zabrze",
    shortSquadName: "Gornik Zabrze",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1609_Gornik_Zabrze.png"
  },
  {
    squadId: 30012,
    squadName: "Crewe Alexandra",
    shortSquadName: "Crewe Alexandra",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1202_Crewe_Alexandra.png"
  },
  {
    squadId: 30168,
    squadName: "Deportivo Saprissa",
    shortSquadName: "Saprissa",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_30168_Deportivo_Saprissa.png"
  },
  {
    squadId: 30351,
    squadName: "AC Ajaccio",
    shortSquadName: "AC Ajaccio",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3781_AC_Ajaccio.png"
  },
  {
    squadId: 30352,
    squadName: "FC Vizela",
    shortSquadName: "Vizela",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/9683_FC_Vizela.png"
  },
  {
    squadId: 30372,
    squadName: "Envigado FC",
    shortSquadName: "Envigado",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12405_Envigado_FC.png"
  },
  {
    squadId: 30411,
    squadName: "FK TSC Backa Topola",
    shortSquadName: "FK TSC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_30411_FK_TSC_Backa_Topola.png"
  },
  {
    squadId: 30475,
    squadName: "Malmo FF",
    shortSquadName: "Malmo FF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1432_Malmo_FF.png"
  },
  {
    squadId: 30586,
    squadName: "AC Sparta Prague",
    shortSquadName: "Sparta Prague",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/11241_AC_Sparta_Prague.png"
  },
  {
    squadId: 30723,
    squadName: "Borussia Dortmund",
    shortSquadName: "Bor. Dortmund",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1259_Borussia_Dortmund.png"
  },
  {
    squadId: 30736,
    squadName: "FC Sochaux-Montbeliard",
    shortSquadName: "FC Sochaux",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3768_FC_Sochaux-Montbéliard.png"
  },
  {
    squadId: 30849,
    squadName: "Atlanta United FC",
    shortSquadName: "Atlanta",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2103_Atlanta_United_FC.png"
  },
  {
    squadId: 31172,
    squadName: "Montpellier HSC",
    shortSquadName: "Montpellier",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3787_Montpellier.png"
  },
  {
    squadId: 31205,
    squadName: "SG Dynamo Dresden",
    shortSquadName: "Dynamo Dresden",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2478_SG_Dynamo_Dresden.png"
  },
  {
    squadId: 31306,
    squadName: "Kolding IF",
    shortSquadName: "Kolding IF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7622_Kolding_IF.png"
  },
  {
    squadId: 31328,
    squadName: "Sabah FK",
    shortSquadName: "Sabah FK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/61081_Sabah_FK.png"
  },
  {
    squadId: 31484,
    squadName: "Deportivo Guadalajara",
    shortSquadName: "Chivas",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15409_Deportivo_Guadalajara.png"
  },
  {
    squadId: 31536,
    squadName: "Aris Limassol",
    shortSquadName: "Aris Limassol",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_31536_Aris_Limassol.png"
  },
  {
    squadId: 31588,
    squadName: "Rangers FC",
    shortSquadName: "Rangers",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2188_Rangers_FC.png"
  },
  {
    squadId: 32429,
    squadName: "Eintracht Frankfurt",
    shortSquadName: "E. Frankfurt",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1268_Eintracht_Frankfurt.png"
  },
  {
    squadId: 32489,
    squadName: "Stockport County",
    shortSquadName: "Stockport",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1667_Stockport_County.jpg"
  },
  {
    squadId: 32515,
    squadName: "IK Sirius",
    shortSquadName: "Sirius",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1446_IK_Sirius.png"
  },
  {
    squadId: 32566,
    squadName: "Vaasan Palloseura",
    shortSquadName: "VPS",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1503_Vaasan_Palloseura.png"
  },
  {
    squadId: 32586,
    squadName: "Omonia Nicosia",
    shortSquadName: "Omonia Nikosia",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2175_Omonia_Nicosia.png"
  },
  {
    squadId: 32628,
    squadName: "Ascoli Calcio",
    shortSquadName: "Ascoli",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1869_Ascoli_Picchio.png"
  },
  {
    squadId: 32704,
    squadName: "UNAM Pumas",
    shortSquadName: "UNAM Pumas",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15420_UNAM_Pumas.png"
  },
  {
    squadId: 32865,
    squadName: "Portland Timbers",
    shortSquadName: "Portland",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/8113_Portland_Timbers.png"
  },
  {
    squadId: 33026,
    squadName: "FC Rapid Bucharest",
    shortSquadName: "FC Rapid",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/11566_FC_Rapid_1923.png"
  },
  {
    squadId: 33438,
    squadName: "FC Thun",
    shortSquadName: "FC Thun",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1941_FC_Thun.png"
  },
  {
    squadId: 33615,
    squadName: "Zalaegerszegi TE FC",
    shortSquadName: "Zalaegerszeg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_33615_Zalaegerszegi_TE_FC.png"
  },
  {
    squadId: 33648,
    squadName: "Atletico Nacional",
    shortSquadName: "Atl. Nacional",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12391_Atlético_Nacional.png"
  },
  {
    squadId: 33794,
    squadName: "Sport Club Internacional",
    shortSquadName: "Internacional",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2611_Sport_Club_Internacional.png"
  },
  {
    squadId: 33797,
    squadName: "Spezia Calcio",
    shortSquadName: "Spezia Calcio",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3205_Spezia_Calcio.webp"
  },
  {
    squadId: 33813,
    squadName: "Karlsruher SC",
    shortSquadName: "Karlsruher SC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Karlsruher_SC.png"
  },
  {
    squadId: 34616,
    squadName: "Vejle Boldklub",
    shortSquadName: "Vejle BK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1422_Vejle_Boldklub.png"
  },
  {
    squadId: 34633,
    squadName: "CSKA-Sofia",
    shortSquadName: "CSKA-Sofia",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2037_CSKA_Sofia.png"
  },
  {
    squadId: 34776,
    squadName: "Goztepe",
    shortSquadName: "Goztepe",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2012_Goztepe.png"
  },
  {
    squadId: 35103,
    squadName: "Standard Liege",
    shortSquadName: "Standard Liege",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1574_Standard_Liege.png"
  },
  {
    squadId: 35181,
    squadName: "SpVgg Unterhaching",
    shortSquadName: "Unterhaching",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2471_SpVgg_Unterhaching.png"
  },
  {
    squadId: 35187,
    squadName: "Virtus Entella",
    shortSquadName: "Virtus Entella",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3404_Virtus_Entella.png"
  },
  {
    squadId: 35255,
    squadName: "CA Sarmiento (Junin)",
    shortSquadName: "Sarmiento Junin",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12095_CA_Sarmiento_(Junin).png"
  },
  {
    squadId: 35303,
    squadName: "Stal Mielec",
    shortSquadName: "Stal Mielec",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1614_Stal_Mielec.png"
  },
  {
    squadId: 35764,
    squadName: "SV 07 Elversberg",
    shortSquadName: "NULL",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1371_SV_07_Elversberg.png"
  },
  {
    squadId: 35839,
    squadName: "Sociedade Esportiva Palmeiras",
    shortSquadName: "Palmeiras",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5646_Sociedade_Esportiva_Palmeiras.png"
  },
  {
    squadId: 36192,
    squadName: "Tigres UANL",
    shortSquadName: "Tigres UANL",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15417_Tigres_UANL.png"
  },
  {
    squadId: 36226,
    squadName: "Go Ahead Eagles",
    shortSquadName: "Go Ahead Eagles",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1553_Go_Ahead_Eagles_Deventer.png"
  },
  {
    squadId: 36491,
    squadName: "FK Vozdovac",
    shortSquadName: "Vozdovac",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1662_FK_Vozdovac.png"
  },
  {
    squadId: 36837,
    squadName: "AJ Auxerre",
    shortSquadName: "AJ Auxerre",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3769_AJ_Auxerre.png"
  },
  {
    squadId: 36927,
    squadName: "Dinamo Tbilisi",
    shortSquadName: "Dinamo Tbilisi",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15814_Dinamo_Tbilisi.png"
  },
  {
    squadId: 37066,
    squadName: "SSV Ulm 1846",
    shortSquadName: "SSV Ulm 1846",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2508_SSV_Ulm_1846.png"
  },
  {
    squadId: 37333,
    squadName: "NK Istra 1961",
    shortSquadName: "NK Istra",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2169_NK_Istra_1961.png"
  },
  {
    squadId: 37475,
    squadName: "Mamelodi Sundowns FC",
    shortSquadName: "Sundowns",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/16344_Mamelodi_Sundowns_FC.png"
  },
  {
    squadId: 37659,
    squadName: "Kongsvinger IL",
    shortSquadName: "Kongsvinger",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Kongsvinger_IL.png"
  },
  {
    squadId: 38328,
    squadName: "D.C. United",
    shortSquadName: "D.C. United",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2118_D.C._United.png"
  },
  {
    squadId: 38564,
    squadName: "FC Volendam",
    shortSquadName: "FC Volendam",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Volendam.png"
  },
  {
    squadId: 38623,
    squadName: "Pogon Szczecin",
    shortSquadName: "Pogon Szczecin",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1599_Pogon_Szczecin.png"
  },
  {
    squadId: 38864,
    squadName: "Slask Wroclaw",
    shortSquadName: "Slask Wroclaw",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_WKS_Slask_Wroclaw.png"
  },
  {
    squadId: 39176,
    squadName: "Leeds United",
    shortSquadName: "Leeds",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1150_Leeds_United.png"
  },
  {
    squadId: 39191,
    squadName: "LKS Lodz",
    shortSquadName: "LKS Lodz",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/13528_Łódzki_KS.png"
  },
  {
    squadId: 39292,
    squadName: "Liverpool FC Montevideo",
    shortSquadName: "Liverpool FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15632_Liverpool_FC_Montevideo.png"
  },
  {
    squadId: 39339,
    squadName: "Villarreal CF",
    shortSquadName: "Villarreal",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1714_Villarreal_CF.png"
  },
  {
    squadId: 39599,
    squadName: "FC Midtjylland",
    shortSquadName: "FC Midtjylland",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Midtjylland.png"
  },
  {
    squadId: 39668,
    squadName: "SKN St. Polten",
    shortSquadName: "SKN St. Polten",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/8756_SKN_St._Pölten.png"
  },
  {
    squadId: 39789,
    squadName: "Como 1907",
    shortSquadName: "Como",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_39789_Como_1907.png"
  },
  {
    squadId: 39818,
    squadName: "Al-Qadsiah FC",
    shortSquadName: "Al-Qadsiah",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_39818_Al_Qadisiyah_Al_Khubar.png"
  },
  {
    squadId: 40356,
    squadName: "SV Werder Bremen",
    shortSquadName: "Werder Bremen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1269_SV_Werder_Bremen.png"
  },
  {
    squadId: 40409,
    squadName: "Oxford United",
    shortSquadName: "Oxford United",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1683_Oxford_United.png"
  },
  {
    squadId: 40498,
    squadName: "Swansea City",
    shortSquadName: "Swansea",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1131_Swansea_City.png"
  },
  {
    squadId: 40508,
    squadName: "LASK",
    shortSquadName: "LASK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/8754_LASK.png"
  },
  {
    squadId: 40635,
    squadName: "Ross County FC",
    shortSquadName: "Ross County",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2197_Ross_County_FC.png"
  },
  {
    squadId: 40692,
    squadName: "Columbus Crew",
    shortSquadName: "Columbus",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7855_Columbus_Crew.png"
  },
  {
    squadId: 40848,
    squadName: "Gil Vicente FC",
    shortSquadName: "Gil Vicente",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/9700_Gil_Vicente_FC.png"
  },
  {
    squadId: 41304,
    squadName: "Cape Town City FC",
    shortSquadName: "Cape Town City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/16369_Cape_Town_City_FC.png"
  },
  {
    squadId: 41360,
    squadName: "EA Guingamp",
    shortSquadName: "Guingamp",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3785_EA_Guingamp.png"
  },
  {
    squadId: 41565,
    squadName: "GD Chaves",
    shortSquadName: "Chaves",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_GD_Chaves.png"
  },
  {
    squadId: 41620,
    squadName: "Qarabag FK",
    shortSquadName: "Qarabag FK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12444_Qarabağ.png"
  },
  {
    squadId: 41684,
    squadName: "Twente Enschede FC",
    shortSquadName: "Twente FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1540_FC_Twente_Enschede.png"
  },
  {
    squadId: 41692,
    squadName: "Seattle Sounders FC",
    shortSquadName: "Seattle",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/8067_Seattle_Sounders_FC.png"
  },
  {
    squadId: 41761,
    squadName: "Leyton Orient",
    shortSquadName: "Leyton Orient",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Leyton_Orient.png"
  },
  {
    squadId: 41792,
    squadName: "Las Vegas Lights FC",
    shortSquadName: "Las Vegas",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/62275_Las_Vegas_Lights_FC.png"
  },
  {
    squadId: 41795,
    squadName: "FK Partizani",
    shortSquadName: "FK Partizani",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/8675_FK_Partizani.png"
  },
  {
    squadId: 42063,
    squadName: "Ujpest FC",
    shortSquadName: "Ujpest FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1633_Ujpest_FC.png"
  },
  {
    squadId: 42813,
    squadName: "Loudoun United FC",
    shortSquadName: "Loudoun United",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/63759_Loudoun_United_FC.png"
  },
  {
    squadId: 50113,
    squadName: "Inter Miami CF",
    shortSquadName: "Miami",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-69261_Inter_Miami_CF.png"
  },
  {
    squadId: 200001,
    squadName: "Mazatlan FC",
    shortSquadName: "Mazatlan FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-82696_Mazatlán_FC.png"
  },
  {
    squadId: 200004,
    squadName: "Charlotte FC",
    shortSquadName: "Charlotte",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-78435_Charlotte_FC.png"
  },
  {
    squadId: 200109,
    squadName: "Cancun FC",
    shortSquadName: "Cancun FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/83082_Cancún_FC.png"
  },
  {
    squadId: 200113,
    squadName: "1.FC Phonix Lubeck",
    shortSquadName: "Phonix Lubeck",
    squadLogo: null
  },
  {
    squadId: 220000,
    squadName: "United Soccer League",
    shortSquadName: "NULL",
    squadLogo: null
  },
  {
    squadId: 222008,
    squadName: "FC Alverca",
    shortSquadName: "Alverca",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_222008_FC_Alverca.png"
  },
  {
    squadId: 225010,
    squadName: "CD Real Cartagena",
    shortSquadName: "Real Cartagena",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-13046_CD_Real_Cartagena.png"
  },
  {
    squadId: 225324,
    squadName: "Vancouver FC",
    shortSquadName: "Vancouver FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-94560_Vancouver_FC.jpg"
  },
  {
    squadId: 225557,
    squadName: "Clube Atletico Mineiro",
    shortSquadName: "Atletico-MG",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-330_Clube_Atlético_Mineiro.png"
  },
  {
    squadId: 225678,
    squadName: "San Diego FC",
    shortSquadName: "San Diego FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/114977_San_Diego_FC.png"
  },
  {
    squadId: 225749,
    squadName: "Gulf United FC",
    shortSquadName: "NULL",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-103824_Gulf_United_FC.jpg"
  },
// {
    //   squadId: 225180,
    //   squadName: "" "",
    //   shortSquadName: "NULL",
    //   squadLogo: null
    // },
]
