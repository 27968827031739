import { StateController } from 'utils/action-declaration';
import AgencySettingsService from 'api/agency/agent/agent-profile/agent-profile.service'
import { AgentProfileModel, SaveUserAgentProfileRequest } from 'api/agency/agent/agent-profile/agent-profile'
import { AppState } from 'root.reducer';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';

class State {
    isLoading: boolean
    profile: AgentProfileModel
    passwordChangedModalData: any
}

const defaultState: State = {
    isLoading: false,
    profile: {
        userPositionName: '',
        email: '',
        phone: '',
        phoneCodeAreaId: -1,
        agencyName: '',
        firstName: '',
        lastName: '',
        agencyCountry: '',
        agencyCity: '',
        agencyId: null,
        agentId: null,
        userId: null,
        emailPreferences: null,
        isAgencyTrusted: false
    },
    passwordChangedModalData: null
}

const stateController = new StateController<State>(
    "AGENCY/EDIT_PROFILE",
    defaultState
);

class Actions {

    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState))
        }
    }

    public static load() {
        return async (dispatch) => {

            try {
                dispatch(stateController.setState({ isLoading: true }))

                const data: AgentProfileModel = await AgencySettingsService.getProfile()

                dispatch(stateController.setState({
                    isLoading: false,
                    profile: data,
                }))
            } catch (error) {
                dispatch(stateController.setState({ isLoading: false }))
            }
        }
    }

    public static updatePofile(data: SaveUserAgentProfileRequest) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isLoading: true }))

                const profile = Selectors.getProfile(getState())

                await AgencySettingsService.saveProfile({ ...data, emailPreferences: profile.emailPreferences })

                await dispatch(Actions.load())

                dispatch(userActivityInsert({
                    PageName: 'Edit Profile',
                    Message: `Save changes: ${data.firstName} ${data.lastName}, ${data.userPosition}`,
                    PageType: PageType.EditProfile
                }))

                dispatch(stateController.setState({
                    isLoading: false,
                }))
            } catch (error) {
                dispatch(stateController.setState({
                    isLoading: false,
                }))
            }

        }
    }

    public static resetProfile() {
        return async (dispatch) => {
            dispatch(userActivityInsert({
                PageName: 'Edit Profile',
                Message: `Reset`,
                PageType: PageType.EditProfile
            }))
        }
    }

    public static addAgentUserUA() {
        return async (dispatch) => {
            dispatch(userActivityInsert({
                PageName: 'Edit Profile [Users]',
                Message: 'Opened Upgrade Request',
            }))
        }
    }
}

class Selectors {
    public static getAgencyProfile = (state: AppState): State => state.agency.agent.agencySettings.profile;
    public static getProfile = (state: AppState): AgentProfileModel => Selectors.getAgencyProfile(state).profile;
    public static getProfileId = (state: AppState): number => Selectors.getProfile(state).userId;
    public static isLoading = (state: AppState): boolean => Selectors.getAgencyProfile(state).isLoading;
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    stateController as Controller,
    Selectors as Selectors,
};
