

export const madridTestimonials = [
    {
        fullName: 'Dougie Freedman',
        position: 'Sporting Director',
        club: 'Crystal Palace',
        img: require('media/images/events/madrid/Dougie_Freedman.jpg'),
        text: 'It’s a way of strengthening relationships. It’s speed-training, it’s 15 minutes, the buzzer goes, and you get down to business very quickly.',
    },
    {
        fullName: 'Dan Ashworth',
        position: 'Technical Director',
        club: 'Brighton & Hove Albion',
        img: require('media/images/events/madrid/DAN-ASHWORTH-BRIGHTON.jpg'),
        text: 'I think we can use our time much more efficiently... You get a 10-minute slot. That’s it. Then, bang, on to the next one.',
    },
    {
        fullName: 'Frank Arnesen',
        position: 'Technical Director',
        club: 'Feyenoord',
        img: require('media/images/events/madrid/frank_arnesen.jpg'),
        text: 'TransferRoom is reliable, it’s a closed network of clubs and you are speaking directly to people.',
    },
    {
        fullName: 'Johannes Jahns',
        position: 'Sports Coordinator',
        club: 'FC Red Bull Salzburg',
        img: require('media/images/events/madrid/johannes_jahn.jpg'),
        text: 'The Summits are a great way of direct, effective and transparent communication between clubs. It saves time and creates opportunities.',
    },
    {
        fullName: 'Piotr Rutkowski',
        position: 'President',
        club: 'Lech Poznan',
        img: require('media/images/events/madrid/rutkowski.jpg'),
        text: 'TransferRoom gives us quick and correct information. That gives a lot of value in a world where information is often false.',
    },
    {
        fullName: 'Stewart Mairs',
        position: '',
        club: 'DC United',
        img: require('media/images/events/madrid/stewart_mairs_dc_united.jpg'),
        text: 'TransferRoom consistently delivers high level events that keep us up to date with market trends and player movements.',
    },
    {
        fullName: 'Andreas Georgson',
        position: 'Sporting Director',
        club: 'Malmo',
        img: require('media/images/events/madrid/andreas_georgson_malmo.jpg'),
        text: 'The TransferRoom Summits are an innovative way of making football communication processes more effective and economically smart.',
    },
]