import createApiThunk from 'services/createApiThunk';
import { PLUS_PITCHES_FETCH, PLUS_PITCH_REACTIONS_FETCH, TEST_INTEREST, TEST_INTEREST_BATCH } from './indexReducer';
import { TEST_INTEREST_MODAL } from 'constants/modals';
import { MODAL_OPEN, MODAL_CLOSE } from 'store/actionTypes';
import { ActionType, PageType } from 'constants/enums';

export const plusPitchesFetch = () => (dispatch, getState) => {
    const aspId = getState().auth.aspNetUserId;
    const request = {
        type: PLUS_PITCHES_FETCH,
        method: 'get',
        url: `PlusPitch/GetMyPitchesWithOffset/${aspId}/${-(new Date().getTimezoneOffset())}`,
        payload: {},
    };
    return dispatch(createApiThunk(request));
};

export const plusPitchesReactionsFetch = (pitchId) => (dispatch, getState) => {
    const aspId = getState().auth.aspNetUserId;
    const request = {
        type: PLUS_PITCH_REACTIONS_FETCH,
        method: 'get',
        url: `PlusPitch/GetReactions/${aspId}/${pitchId}`,
        payload: { pitchId: pitchId },
    };
    return dispatch(createApiThunk(request));
};

export const testInterest = (pitchId, pitchSquadId) => (dispatch, getState) => {
    
    const plusPitchData = getState().plusPitch.plusPitchData.byId[pitchId]
    const playerId = plusPitchData.player.id
    const clubId = plusPitchData.squads.find(item => item.id === pitchSquadId).squad.id
    const trackingObject = {
        Message: 'Tested Interest',
        PageName: 'Plus Pitch [Drop-down]',
        PlayerId: playerId,
        ClubId: clubId,
        ActionType: ActionType.TestedInterest,
        PageType: PageType.Pitch
    }

    const request = {
        type: TEST_INTEREST,
        method: 'get',
        url: `PlusPitch/TestInterest/${pitchSquadId}`,
        payload: { pitchId: pitchId, pitchSquadId: pitchSquadId },
    };

    return dispatch(createApiThunk(request, trackingObject));
}

export const testInterestBatch = (pitchId, pitchSquadIds) => (dispatch, getState) => {
    
    const request = {
        type: TEST_INTEREST_BATCH,
        method: 'post',
        url: `PlusPitch/TestInterestBatch`,
        payload: { pitchId: pitchId, pitchSquadIds: pitchSquadIds },
    };

    const data = getState().plusPitch.plusPitchData.byId[pitchId]
    let selectedSquadNames = [];
    pitchSquadIds.forEach(id => {
        let selected = data.squads.filter(k => k.id === id)[0];
        if (selected != null) {
            selectedSquadNames.push(selected.squad.name);
        }
    });
    const trackingObject = {
        Message: `Tested Interest: ${selectedSquadNames.join()}`,
        PageName: 'Plus Pitch [Test list]',
        PlayerId: data.player.id,
        PageType: PageType.Pitch
    }

    dispatch(createApiThunk(request, trackingObject));
}

export const openTestInterestModal = () => {
    return dispatch => {
        let command = { type: MODAL_OPEN, payload: { id: TEST_INTEREST_MODAL, content: {} } };
        dispatch(command);
    };
};

export const closeTestInterestModal = () => {
    return dispatch => {
        let command = { type: MODAL_CLOSE, payload: TEST_INTEREST_MODAL };
        dispatch(command);
    };
};