import { PageType } from 'constants/enums';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PaginationClickState } from 'pages/PlayerSearch-v2/redux/grid.controller';

export class GridActivityService {
    private static page: string = 'Search';

    private static buildCommand(container: string, action: string, playerId?: number, clubId?: number, agencyId?: number) {
        return {
            Message: action,
            PageType: PageType.Search,
            PlayerId: playerId,
            ClubId: clubId,
            AgencyId: agencyId,
            PageName: `${this.page}${container ? ` [${container}]` : ''}`
        }
    }

    public static openSearch() {
        return dispatch => {
            dispatch(userActivityInsert(
                this.buildCommand(null, `Opened Player Search`)
            ))
        }
    }

    public static openShortList() {
        return dispatch => {
            dispatch(userActivityInsert(
                this.buildCommand('Shortlist', `Opened Shortlist`)
            ))
        }
    }

    public static selectSimilarPlayer(playerId: number, clubId?: number, agencyId?: number) {
        return dispatch => {
            const command = this.buildCommand('Similar to', `Selected Player to Compare`, playerId, clubId, agencyId)

            dispatch(userActivityInsert(command))
        }
    }

    public static setPageSize(size: number) {
        return dispatch => {
            dispatch(userActivityInsert(
                this.buildCommand(null, `Selected ${size} rows`)
            ))
        }
    }

    public static setPage(page: number, paginationState: PaginationClickState) {
        return dispatch => {
            if (paginationState === PaginationClickState.Number) {
                dispatch(userActivityInsert({
                    PageName: 'Search [Pagination]',
                    Message: `Moved to Page: ${page}`,
                }))
            } else if (paginationState === PaginationClickState.Left) {
                dispatch(userActivityInsert({
                    PageName: 'Search [Pagination]',
                    Message: `Moved to Previous Page: ${page}`,
                }));
            } else if (paginationState === PaginationClickState.Right) {
                dispatch(userActivityInsert({
                    PageName: 'Search [Pagination]',
                    Message: `Moved to Next Page: ${page}`,
                }));
            }
        }
    }
}
