import React from 'react'
import styled from 'styled-components'

class Props {
    participants: string;
    countries: string;
    leagues: string;
    agents?: string;
}

export const PastEventStats = ({ participants, countries, leagues, agents = "" }: Props) => (
    <Section>
        <li>
            <b>{participants}</b>
            <span>Participants</span>
        </li>
        <li>
            <b>{countries}</b>
            <span>Countries</span>
        </li>
        <li>
            <b>{leagues}</b>
            <span>Leagues</span>
        </li>
        {agents && (
            <li>
                <b>+{agents}</b>
                <span>Trusted Agents</span>
            </li>
        )}
    </Section>
);

const Section = styled.ul`
    display: block;
    list-style: none;
    padding: 0;
    margin: 0 0 20px 0;
    display: table;
    li {
        display: table-row;
        font-size: 21px;
        font-weight: 500;
        text-transform: uppercase;
        line-height: 1.1;
        > * {
            display: table-cell;
            vertical-align: middle;
            padding: 2px 5px;
            font-style: normal;
        }
        b {
            font-size: 30px;
            font-weight: bold;
            color: #0E9655;
            text-align: right;
            width: 1%;
        }
    }
`