import * as types from 'store/actionTypes';

const initialState = {
    positions: [],
};

export default (s = initialState, action) => {
    switch (action.type) {
        case types.USER_REGISTRATION_POSITIONS_GET.SUCCESS:
            return {
                ...s,
                positions: action.payload.data,
            };
        default:
            return s;
    }
};

export const getUserPositions = state => {
    return state.userregistration.positions;
};
