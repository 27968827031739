import history from 'history-accessor'
import { AppState } from 'root.reducer'
import { userPaths } from 'routes/paths'
import { hasAgentFeature } from 'store/auth/authReducer'
import { StateController } from 'utils/action-declaration'
import { setupAgencyFeature } from 'store/auth/auth.actions'
import { PlayerAdService } from 'api/player-ad/player-ad-service'
import { CreatePlayerAdRequestV2 } from 'api/player-ad/models/create-player-ad'
import * as PlayerAdsController from 'pages/player-ads/redux/common.controller'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions'
import { getPositionCodeById, getPositionIdByCode } from 'utils/position-helper'
import AgencyOnboardingService from 'api/agency/club/onboarding/onboarding.servise'
import { PlayerAdTypeEnumModel } from 'api/agency/club/onboarding/onboarding-models'
import { ageRule, heightRule } from 'pages/player-ads/create-new-ad-modal/redux/create-new-ad.controller'
import { getOpenReonboardingPopupActionType } from '../reonboarding-popup/utils'
import { Selectors as ReonboardingPopupSelectors } from 'pages/club/reonboarding-popup/reonboarding-popup.controller'
import { ReonboardingService } from 'api/club/reonboarding/reonboarding.service';
import { Actions as ReonboardingFlowActions } from 'pages/club/reonboarding-flow/reonboarding.controller';
import { PlayerAdInitialInfoResponse } from 'api/player-ad/models/player-ad'
import { getPreferredPlayerAreas} from 'pages/player-ads/create-new-ad-modal/helpers/preferred-player-area';
import { getPreferredPlayingStyles} from 'pages/player-ads/create-new-ad-modal/helpers/preferred-player-playing-style';
import { IdName } from "api/core/id-name";
import { PlayerAdWithCountersModel } from 'api/player-ad-v2/models/player-ad-with-counters';
import PlayerAdV2Service from 'api/player-ad-v2/player-ad-v2.service';
import { getCancelTokenSource } from 'axios-config'

export type CreateAdModel = {
    toLoan: boolean
    toBuy: boolean
    loan?: number
    buy?: number
    grossSalary?: number
    age: { min: number, max: number }
    height: number
    isLeftFooted: boolean
    isAgencyRecommended: boolean;

    preferredPlayerArea: IdName;
    preferredPlayingStyle: IdName;
    isAnonymiseRequirement: boolean;
}

export enum RecruitPlayersSteps {
    Step1 = 1,
    Step2 = 2,
    CongratulationsStep = 3,
}

class State {
    positions: { [key: string]: CreateAdModel }
    originalPositions: string[]
    adsProcessing: boolean
    currentStep: number
    totalClubsNotified: number
    currentPositionIndex: number
    isHeaderHide: boolean;
    isTransferWindowReonboarding: boolean;

    playerAdInitialInfo: PlayerAdInitialInfoResponse;

    playerAds: Array<PlayerAdWithCountersModel>;
    isLoading: boolean
}

const defaultState: State = {
    positions: {},
    originalPositions: [],
    adsProcessing: false,
    currentStep: 1,
    totalClubsNotified: 0,
    currentPositionIndex: 0,
    isHeaderHide: false,
    isTransferWindowReonboarding: false,

    playerAdInitialInfo: null,

    playerAds: [],
    isLoading: false,
}

const stateController = new StateController<State>(
    'CLUB/RECRUIT-PLAYERS',
    defaultState
)

class Actions {
    public static token = null;

    public static init(isTransferWindowReonboarding: boolean) {
        return async (dispatch, getState: () => AppState) => {

            await dispatch(Actions.loadAllAds())
            dispatch(stateController.setState({ isTransferWindowReonboarding: isTransferWindowReonboarding }))            
        }
    }
    
    public static loadAllAds() {
        return async (dispatch) => {
            dispatch(stateController.setState({ isLoading: true }));

            Actions.token = getCancelTokenSource();

            let myAdsItems = await PlayerAdV2Service.getAllAdsWithCounters(Actions.token.token);

            if (myAdsItems) {
                dispatch(stateController.setState({ playerAds: myAdsItems }));
            }
            
            dispatch(stateController.setState({ isLoading: false}));
        }
    }
    
    public static dispose() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ ...defaultState, positions: {} }))
        }
    }

    public static exitToHomepage() {
        return async (dispatch, getState: () => AppState) => {
            const { isTransferWindowReonboarding } = Selectors.getRoot(getState());
            const pageName = Selectors.getPageName(getState());

            dispatch(userActivityInsert({
                PageName: pageName,
                Message: isTransferWindowReonboarding ? 'Dismiss': 'Exit to Homepage',
            }))
            history.push(userPaths.home);
        }
    }

    public static remindMeLater() {
        return async (dispatch, getState: () => AppState) => {
            const pageName = Selectors.getPageName(getState());
            try {
                const promise = ReonboardingService.remindMeLater();
                history.push(userPaths.home);

                await promise;

                dispatch(userActivityInsert({
                    PageName: pageName,
                    Message: 'Not Now',
                }));
            } catch (e) {
                console.error(e)
            }
        }
    }
    
    public static nextStep() {
        return (dispatch, getState: () => AppState) => {
            const step = getState().club.recruitPlayers.currentStep

            if (step === 1) {
                const positions = getState().club.recruitPlayers.positions
                
                let positionsText = ''
                if (Object.keys(positions).length > 0) {
                    positionsText = Object.keys(positions).reduce((result, item) => {
                        return `${result}, ${item}`
                    })
                }
                if (positionsText == ',') {
                    positionsText = ''
                }

                for (let key in positions) {
                    if (positions.hasOwnProperty(key)) {
                        let ad = positions[key];
                        ad.isAgencyRecommended = getState().auth.isAgentsVisible
                    }
                }

                dispatch(userActivityInsert({
                    Message: 'Continue',
                    PageName: 'Re-Onboarding [Create Player Ads - Select Position]',
                }))
            }

            dispatch(stateController.setState({ currentStep: step + 1 }))
            dispatch(Actions.disposeScrollingEffect())
        }
    }
    public static prevStep() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ currentStep: getState().club.recruitPlayers.currentStep - 1 }))
            dispatch(Actions.disposeScrollingEffect())
        }
    }

    public static onVerticalPageScroll(value: boolean) {
        return (dispatch) => {
            dispatch(stateController.setState({ isHeaderHide: value }))
        }   
    }
    
    public static disposeScrollingEffect() {
        return (dispatch) => {
            dispatch(stateController.setState({ isHeaderHide: false }))
        }   
    }

    public static loadPlayerAdInitialInfo() {
        return async (dispatch) => {
            const initialInfo = await PlayerAdService.getPlayerAdInitialInfo();
            
            if (!initialInfo) {
                return null
            }

            dispatch(stateController.setState({ playerAdInitialInfo: initialInfo}))
        }
    }
}

class Step1Actions {

    public static backToReonboardingPopup() {
        return async (dispatch, getState: () => AppState) => {
            const appState = getState();
            const { isManual } = ReonboardingPopupSelectors.getRoot(appState);
            dispatch({ type: getOpenReonboardingPopupActionType(isManual) })
            dispatch(userActivityInsert({
                Message: 'Cancel and Exit',
                PageName: 'Re-Onboarding [Create Player Ads - Select Position]',
            }))
            history.push(userPaths.home)
        }
    }
    public static changePositionsSelection(positionCode: string) {
        return (dispatch, getState: () => AppState) => {
            const state = getState()
            const originalPositions = state.club.recruitPlayers.originalPositions
            let defaultAdState: CreateAdModel = { 
                toLoan: false, 
                toBuy: false, 
                age: null, 
                height: null, 
                isAgencyRecommended: true, 
                isLeftFooted: false, 
                preferredPlayerArea: null, 
                preferredPlayingStyle: null, 
                isAnonymiseRequirement: false 
            }
            
            defaultAdState.isAgencyRecommended = state.auth.isAgentsVisible && (hasAgentFeature(state) || state.auth.isFreemiumSubscription)

            const positions = state.club.recruitPlayers.positions

            if (Object.keys(positions).includes(positionCode)) {
                delete positions[positionCode]
            } else {
                positions[positionCode] = defaultAdState
            }

            dispatch(stateController.setState({
                positions: { ...positions },
                originalPositions: [...Object.keys(positions)]
            }))

            if (originalPositions.includes(positionCode)) {
                dispatch(userActivityInsert({
                    Message: `Deselected Position: ${positionCode}`,
                    PageName: 'Re-Onboarding [Create Player Ads - Select Position]',
                }))
            } else {
                dispatch(userActivityInsert({
                    Message: `Selected Position: ${positionCode}`,
                    PageName: 'Re-Onboarding [Create Player Ads - Select Position]',
                }))
            }
        }
    }
}

class Step2Actions {

    public static onAdPropertiesChange(position: string, toBuy: boolean, toLoan: boolean, loan?: number, buy?: number, grossSalary?: number, isAgencyRecommended?: boolean) {
        return (dispatch, getState: () => AppState) => {
            const positions = getState().club.recruitPlayers.positions
            const changedPositions = { ...positions }
            const currentEntity = changedPositions[position]

            changedPositions[position] = { ...currentEntity, toBuy: toBuy, toLoan: toLoan, loan: loan, buy: buy, grossSalary: grossSalary, isAgencyRecommended}

            dispatch(stateController.setState({ positions: changedPositions }))
            dispatch(Step2Actions.validateAnonymiseRequirement(position, toBuy, buy, grossSalary))
        }
    }

    public static validateAnonymiseRequirement(position: string, toBuy: boolean, buy?: number, grossSalary?: number) {
        return (dispatch, getState: () => AppState) => {
            if (!toBuy) {
                dispatch(Step2Actions.onAnonymiseRequirementChange(position, false))
            }
                        
            if (!!buy || !!grossSalary){                
                const criteria = Selectors.getPlayerAdAnonymisationCriteria(getState())          
                                
                if ((buy < criteria.transferFeeThreshold.price && grossSalary < criteria.grossSalaryThreshold.price) || 
                    (!buy && grossSalary < criteria.grossSalaryThreshold.price) ||
                    (!grossSalary && buy < criteria.transferFeeThreshold.price)
                ) {
                    dispatch(Step2Actions.onAnonymiseRequirementChange(position, false))
                }
            } 
        }
    }

    public static onAgePropertyChanged(position: string, min: number, max: number) {
        return (dispatch, getState: () => AppState) => {
            const positions = getState().club.recruitPlayers.positions
            const changedPositions = { ...positions }
            const currentEntity = changedPositions[position]
            const upd = (min == ageRule.min && max == ageRule.max) ? null : { min: min, max: max }

            changedPositions[position] = { ...currentEntity, age: upd }

            dispatch(stateController.setState({ positions: changedPositions }))
        }
    }
    public static onHeightPropertyChanged(position: string, val: number) {
        return (dispatch, getState: () => AppState) => {
            const positions = getState().club.recruitPlayers.positions
            const changedPositions = { ...positions }
            const currentEntity = changedPositions[position]

            changedPositions[position] = { ...currentEntity, height: val == heightRule.min ? null : val }

            dispatch(stateController.setState({ positions: changedPositions }))
        }
    }
    public static onBackButton() {
        return (dispatch, getState: () => AppState) => {
            const currentIndex = getState().club.recruitPlayers.currentPositionIndex
            if (currentIndex === 0) {
                dispatch(Actions.prevStep())
            } else {
                dispatch(Step2Actions.goToPrevPosition())
            }
            
            dispatch(userActivityInsert({
                Message: 'Back',
                PageName: 'Re-Onboarding [Create Player Ads - Set Transfer Terms]',
            }))
        }
    }
    public static goToPrevPosition() {
        return (dispatch, getState: () => AppState) => {
            const currentPositionIndex = getState().club.recruitPlayers.currentPositionIndex

            dispatch(stateController.setState({ currentPositionIndex: currentPositionIndex - 1 }))
        }
    }
    public static onLeftFootedChange(position: string, val: boolean) {
        return (dispatch, getState: () => AppState) => {
            const positions = getState().club.recruitPlayers.positions
            const changedPositions = { ...positions }
            const currentEntity = changedPositions[position]

            changedPositions[position] = { ...currentEntity, isLeftFooted: val }

            dispatch(stateController.setState({ positions: changedPositions }))
        }
    }

    public static onAnonymiseRequirementChange(position: string, value: boolean) {
        return (dispatch, getState: () => AppState) => {
            
            const positions = getState().club.recruitPlayers.positions
            let changedPositions = { ...positions };

            let currentEntity = changedPositions[position];

            changedPositions[position] = { ...currentEntity, isAnonymiseRequirement: value };

            dispatch(stateController.setState({ positions: changedPositions }))
        }
    }

    public static onPreferredPlayerAreaChanged(position: string, area: IdName) {
        return (dispatch, getState: () => AppState) => {
            const positions = getState().club.recruitPlayers.positions
            const changedPositions = { ...positions }
            const currentEntity = changedPositions[position]

            changedPositions[position] = { ...currentEntity, preferredPlayerArea: area };

            dispatch(stateController.setState({ positions: changedPositions }))
        }
    }

    public static onPreferredPlayingStyleChanged(position: string, playingStyle: IdName) {
        return (dispatch, getState: () => AppState) => {
            let positions = getState().club.recruitPlayers.positions;
            let changedPositions = { ...positions };

            let currentEntity = changedPositions[position];

            changedPositions[position] = { ...currentEntity, preferredPlayingStyle: playingStyle };

            dispatch(stateController.setState({ positions: changedPositions }))
        }
    }
    
    public static goToNextPosition() {
        return async (dispatch, getState: () => AppState) => {
            const state = getState()
            const currencyId = state.auth.currency.id
            const subState = state.club.recruitPlayers
            const currentPositionIndex = subState.currentPositionIndex
            const currentPositionCode = Object.keys(subState.positions)[subState.currentPositionIndex]

            if (state.auth.isAgentsVisible && !hasAgentFeature(state) && subState.positions[currentPositionCode].isAgencyRecommended) {
                dispatch(stateController.setState({ adsProcessing: true }))
                for (let key in subState.positions) {
                    if (subState.positions.hasOwnProperty(key)) {
                        let ad = subState.positions[key]
                        ad.isAgencyRecommended = true
                    }
                }
                dispatch(stateController.setState({ positions: { ...subState.positions } }))

                await AgencyOnboardingService.setUpAgencyFeatures()
                
                dispatch(setupAgencyFeature())
                dispatch(stateController.setState({ adsProcessing: false }))
            }
            
            if (currentPositionIndex === Object.keys(subState.positions).length - 1) {
                const request: Array<CreatePlayerAdRequestV2> = new Array<CreatePlayerAdRequestV2>()
                for (var key of Object.keys(subState.positions)) {
                    const current = subState.positions[key]
                    if (current.toBuy) {
                        request.push({
                            toAge: current.age ? current.age.max : null, 
                            fromAge: current.age ? current.age.min : null, 
                            minHeight: current.height,
                            type: PlayerAdTypeEnumModel.Buy, 
                            amount: current.buy, positionId: getPositionIdByCode(key), 
                            currencyId: currencyId, grossSalary: current.grossSalary,
                            isAgencyRecommendation: current.isAgencyRecommended,
                            isLeftFooted: current.isLeftFooted,
                            preferredNationalityAreaId: current.preferredPlayerArea?.id, 
                            playingStyleId: current.preferredPlayingStyle?.id, 
                            isAnonymised: current.isAnonymiseRequirement,
                        })
                    }
                    if (current.toLoan) {
                        request.push({
                            toAge: current.age ? current.age.max : null, 
                            fromAge: current.age ? current.age.min : null, 
                            minHeight: current.height,
                            type: PlayerAdTypeEnumModel.Loan, 
                            amount: current.loan, positionId: getPositionIdByCode(key), 
                            currencyId: currencyId,
                            isAgencyRecommendation: current.isAgencyRecommended,
                            isLeftFooted: current.isLeftFooted,
                            preferredNationalityAreaId: current.preferredPlayerArea?.id, 
                            playingStyleId: current.preferredPlayingStyle?.id,
                            isAnonymised: false,
                        })
                    }
                }

                dispatch(stateController.setState({ adsProcessing: true }))

                try {
                    if (request.length > 0) {
                        const playerAds = await PlayerAdService.createPlayerAds(request)
                        dispatch(stateController.setState({ totalClubsNotified: playerAds.totalClubsNotified }))
                    }

                    const positions = request

                    positions.map((item, index) => {
                        let positionValueText = ''
                        let costValue = ''
                        let age = ''
                        let height = ''
                        let leftFooted = ''
                        let nationality = ''
                        let playingStyle = ''

                        if (item.type === 1) {
                            positionValueText += 'To Buy';
                            costValue = `, ${item.amount};${item.grossSalary}/yr`
                        }
                        if (item.type === 2) {
                            if (positionValueText.length > 0) {
                                positionValueText += ', '
                            }
                            costValue = `, ${item.amount}/mo`
                            positionValueText += 'To Loan';
                        }
                        if ((item.fromAge && item.fromAge !== 16) || (item.toAge && item.toAge !== 40)) {
                            age = `, Age ${item.fromAge}-${item.toAge}`
                        }
                        if (item.minHeight && item.minHeight !== 150) {
                            height = `, Min. ${item.minHeight}`
                        }
                        if (item.isLeftFooted) {
                            leftFooted = ' - LF'
                        }
                        if (item.preferredNationalityAreaId) {
                            const position = getPositionCodeById(item.positionId)
                            const area = subState.positions[position].preferredPlayerArea.name
                            
                            nationality = `, ${area}`
                        }

                        if (item.playingStyleId) {
                            const position = getPositionCodeById(item.positionId)
                            const style = subState.positions[position].preferredPlayingStyle.name
                            
                            playingStyle = `, ${style}`
                        }

                        let finalText = `${getPositionCodeById(item.positionId)}${leftFooted} (${positionValueText}${costValue}${age}${height}${nationality}${playingStyle}${item.isAgencyRecommendation ? ', Invited Agents' : ''}${item.isAnonymised ? ',  Anonymised Requirement' : ''})`

                        dispatch(userActivityInsert({
                            Message: `Launched Player Ad: ${finalText}`,
                            PageName: 'Re-Onboarding [Create Player Ads - Set Transfer Terms]',
                        })) 
                    })

                    dispatch(PlayerAdsController.Actions.loadCounters())
                    dispatch(stateController.setState({ adsProcessing: false }))
                    dispatch(Actions.nextStep())
                    
                } catch (e) {
                    dispatch(stateController.setState({ adsProcessing: false }))
                    console.error(e)
                }
            }
            else {
                dispatch(stateController.setState({ currentPositionIndex: currentPositionIndex + 1 }))
                dispatch(userActivityInsert({
                    Message: 'Next Position',
                    PageName: 'Re-Onboarding [Create Player Ads - Set Transfer Terms]',
                }))
            }
        }
    }

    public static onFindOutMoreClick() {
        return (dispatch) => {
            dispatch(userActivityInsert({
                Message: `Clicked Playing Style`,
                PageName: 'Re-Onboarding [Create Player Ads - Set Transfer Terms]',
            }))
            window.open('https://blog.transferroom.com/transferrooms-playing-styles-explained', '_blank');
        }
    }

    public static onPlayingStyleTooltipHover() {
        return async (dispatch) => {
            dispatch(userActivityInsert({
                Message: `Viewed Playing Style Tooltip`,
                PageName: 'Re-Onboarding [Create Player Ads - Set Transfer Terms]',
            }))
        }
    }
}

class CongratulationsStepActions {

    public static goToReonboardingFlow() {
        return async (dispatch, getState: () => AppState) => {
            const { isTransferWindowReonboarding } = Selectors.getRoot(getState())

            dispatch(userActivityInsert({
                Message: 'Sell/Loan Players',
                PageName: 'Re-Onboarding [Create Player Ads - Congratulations]',
            }))

            dispatch(ReonboardingFlowActions.init(isTransferWindowReonboarding));
            history.push(userPaths.reonboardingFlow);
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState): State => state.club.recruitPlayers;
    public static getHeaderVisibility = (state: AppState): boolean => Selectors.getRoot(state)?.isHeaderHide;
    public static getPageName = (state: AppState): string => {
        const { currentStep } = Selectors.getRoot(state);
        let result;
        switch(currentStep){
            case RecruitPlayersSteps.Step1:
                result = 'Re-Onboarding [Create Player Ads - Select Position]';
                break;
            case RecruitPlayersSteps.Step2:
                result = 'Re-Onboarding [Create Player Ads - Set Transfer Terms]';
                break;
            case RecruitPlayersSteps.CongratulationsStep:
                result = 'Re-Onboarding [Create Player Ads - Congratulations]';
                break;
        }
        return result;
    }
    public static getPlayerAdInitialInfo = (state: AppState) => Selectors.getRoot(state).playerAdInitialInfo;
    public static getPlayerAdAnonymisationCriteria = (state: AppState) => Selectors.getPlayerAdInitialInfo(state).anonymisationCriteria;
    public static getCurrentPositionIndex = (state: AppState) => Selectors.getRoot(state).currentPositionIndex;
    public static getCurrentPositionId = (state: AppState) => {
        const positionIndex = Selectors.getCurrentPositionIndex(state)
        const positionCode = Selectors.getRoot(state).originalPositions[positionIndex]

        return getPositionIdByCode(positionCode)

    }
    public static getPreferredPlayerAreasList = (state: AppState) => {
        const info = Selectors.getPlayerAdInitialInfo(state)
        if (!info) {
            return null
        }
        const areas = getPreferredPlayerAreas(info)
    
        return areas
    };
    public static getPreferredPlayingStylesList = (state: AppState) => {
        const info = Selectors.getPlayerAdInitialInfo(state)
        const positionId = Selectors.getCurrentPositionId(state)

        if (!info) {
            return null
        }
        
        const styles = getPreferredPlayingStyles(info, positionId)

        return styles
    };
    public static getPlayerAds = (state: AppState) => Selectors.getRoot(state).playerAds     
    public static getPLayerAdsPositions = (state: AppState) => {
        const subState = Selectors.getPlayerAds(state)
        let playerAdsPositions = subState.map(x => x.ad.positionCode);        
        let uniquePlayerAdsPositions = playerAdsPositions.filter((element, index) => {
            return playerAdsPositions.indexOf(element) === index;
        });
        
        return uniquePlayerAdsPositions
    }
}

const reducer = stateController.getReducer()

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    Step1Actions as Step1Actions,
    Step2Actions as Step2Actions,
    CongratulationsStepActions as CongratulationsStepActions,
    stateController as Controller,
    Selectors as Selectors
}