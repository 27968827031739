import { StateController } from 'utils/action-declaration' 
import { AppState } from 'root.reducer';
import { getSquadUsersProfiles } from 'app/edit-profile/actions/edit-profile.action'
import UserProfileServices from 'api/user-profile/user-profile'
import { Position } from 'api/user-profile/models/position';
import { Profile } from 'api/user-profile/models/profile'
import { MODAL_OPEN, MODAL_CLOSE } from 'store/actionTypes';
import { ADD_USERS, USER_EDITOR_MODAL, REMOVE_USER_WARNING_MODAL } from 'constants/modals';
import * as Recommendation from './performance-data.controller'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions'
import { PageType } from 'constants/enums';

export class AddUserState {
    isLoading: boolean;
    positions: Array<Position> | null
    profiles: Array<Profile> | null
    isCreatingUser: boolean
}

const defaultState: AddUserState = {
    isLoading: false,
    isCreatingUser: false,
    positions: null,
    profiles: null
}

const stateController = new StateController<AddUserState>(
    "LANDING_PAGE/ADD_USERS_MODAL",
    defaultState
);

class Actions {

    public static getSquadUsersProfiles = () => {
        return async (dispatch, getState: () => AppState) => {
            const squadId = getState().auth.squadId
            dispatch(stateController.setState({ isLoading: true }))
            const data = await UserProfileServices.getSquadUsersProfiles(squadId)
            dispatch(stateController.setState({ 
                isLoading: false,
                positions: data.positions,
                profiles: data.profiles 
            }))
        }
    }

    public static openEditUserModal = (userId) => {
        return (dispatch, getState: () => AppState) => {

            const userData = getState().landingPage.addUsersFlow.profiles.find(item => item.id === userId)
            const positions = getState().landingPage.addUsersFlow.positions

            let command = {
                type: MODAL_OPEN,
                payload: { 
                    id: USER_EDITOR_MODAL, 
                    className: 'lp-edit-user-modal',
                    content: {
                        user: userData,
                        positions: positions,
                        pageName: 'Home [Add more users]',
                        pageType: PageType.Home,
                        onUpdateFinished: () => dispatch(Actions.getSquadUsersProfiles())
                    }
                },
            };
            dispatch(command);
        }
    }

    public static openAddUserModal = () => {
        return (dispatch, getState: () => AppState) => {

            const squadId = getState().auth.squadId

            const emptyUser = {
                id: 0,
                squadId: squadId,
                fullName: '',
                email: '',
                phone: '',
                // userPositionName: positions[0] ? positions[0].name : '',
                // userPositionId: positions[0] ? positions[0].id : 0,
                userPositionName: '',
                userPositionId: 16,
            };

            const positions = getState().landingPage.addUsersFlow.positions

            let command = {
                type: MODAL_OPEN,
                payload: { 
                    id: USER_EDITOR_MODAL, 
                    className: 'lp-edit-user-modal',
                    content: {
                        user: emptyUser,
                        positions: positions,
                        pageName: 'Home [Add more users]',
                        PageType: PageType.Home,
                        onCreateFinished: () =>{
                             dispatch(Actions.getSquadUsersProfiles())
                             dispatch(Recommendation.Actions.completedAddUsersRecommendation());
                        }
                    }
                },
            };

            dispatch(userActivityInsert({
                Message: 'Add Users',
                PageName: 'Home [Add more users]',
                PageType: PageType.Home
            }));

            dispatch(command);
        }
    }

    public static closeEditUserModal = () => {
        return dispatch => {
            let command = {
                type: MODAL_CLOSE,
                payload: USER_EDITOR_MODAL,
            };
            dispatch(command);
        }
    }


        
    public static openRemoveUserModal = (userId, userRequestId) => {
        return (dispatch) => {
            let command = {
                type: MODAL_OPEN,
                payload: { 
                    id: REMOVE_USER_WARNING_MODAL, 
                    content: {
                        onDelete: () => {                            
                            if(userRequestId > 0){
                                dispatch(Actions.removeUserRequest(userRequestId))
                            }
                            else {
                                dispatch(Actions.removeUser(userId))
                            }
                            
                            dispatch(Actions.closeRemoveUserModal())
                        },
                        onModalClose: () => dispatch(Actions.closeRemoveUserModal())
                    }
                },
            };
            dispatch(command);
        }
    }

    public static closeRemoveUserModal = () => {
        return dispatch => {
            let command = {
                type: MODAL_CLOSE,
                payload: REMOVE_USER_WARNING_MODAL,
            };
            dispatch(command);
        }
    }

    public static removeUser = (userId: number) => {
        return async (dispatch, getState: () => AppState) => {
            await UserProfileServices.deleteUserProfile(userId)
            dispatch(Actions.getSquadUsersProfiles())
        }
    }

    public static removeUserRequest = (userRequestId: number) => {
        return async (dispatch, getState: () => AppState) => {
            await UserProfileServices.deleteUserRequest(userRequestId)
            dispatch(Actions.getSquadUsersProfiles())
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    AddUserState as State,
    Actions as Actions,
    stateController as Controller
};


