import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import AgencyPlayerService from 'api/agency/player/shared/agency-player-service'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';

export class ModalData {
    requestSent: boolean;
    isRegular: boolean;
    modalType: RestrictedPlayerCreditsModalType;
    playerId?: number;
    squadId?: number;
}

export enum RestrictedPlayerCreditsModalType {
    CreditRequest = 1,
    AddPlayers = 2,
    UpgradePlayer = 3
}

class State {
    modalData: ModalData;
}

const defaultState: State = {
    modalData: null,
}

const stateController = new StateController<State>(
    "AGENCY/RESTRICTED_PLAYER_CREDITS_MODAL",
    defaultState
)

class Actions {

    public static openRestrictedPremiumCreditsModal(modalType: RestrictedPlayerCreditsModalType, playerId: number = null, squadId: number = null) {
        return (dispatch) => {
            dispatch(stateController.setState({
                modalData: {
                    requestSent: false,
                    isRegular: false,
                    modalType: modalType,
                    playerId: playerId,
                    squadId: squadId
                }
            }))
        }
    }

    public static openRestrictedRegularCreditsModal(modalType: RestrictedPlayerCreditsModalType) {
        return (dispatch) => {
            dispatch(stateController.setState({
                modalData: {
                    requestSent: false,
                    isRegular: true,
                    modalType: modalType
                }
            }))
        }
    }

    public static closeRestrictedCreditsModal() {
        return async (dispatch, getState: () => AppState) => {
            const { modalData } = getState().agency.agent.restrictedPlayerCreditsModal;

            dispatch(stateController.setState({ modalData: null }))

            dispatch(userActivityInsert({
                PageName: Actions.getPageName(modalData.modalType),
                Message: `Closed${modalData.isRegular ? '' : ' Priority'} Credits Request`,
                PlayerId: modalData.playerId,
                ClubId: modalData.squadId,
                PageType: Actions.getPageType(modalData.modalType)
            }))
        }
    }

    public static requestMoreCredits() {
        return async (dispatch, getState: () => AppState) => {
            const { modalData } = getState().agency.agent.restrictedPlayerCreditsModal;
            await AgencyPlayerService.requestPlayerCreditsEmail({
                isRegular: modalData.isRegular,
                isPriority: !modalData.isRegular
            });
            dispatch(stateController.setState({
                modalData: {
                    ...modalData,
                    requestSent: true
                }
            }))

            dispatch(userActivityInsert({
                PageName: Actions.getPageName(modalData.modalType),
                Message: `Sent${modalData.isRegular ? '' : ' Priority'} Credits Request`,
                PlayerId: modalData.playerId,
                ClubId: modalData.squadId,
                PageType: Actions.getPageType(modalData.modalType)
            }))
        }
    }

    private static getPageName(modalType: RestrictedPlayerCreditsModalType) {
        let pageName = '';
        switch (modalType) {
            case RestrictedPlayerCreditsModalType.CreditRequest:
                pageName = 'Agency Home [Agency Overview]';
                break;
            case RestrictedPlayerCreditsModalType.AddPlayers:
                pageName = 'Agency Home [Player Verification]'
                break;
            case RestrictedPlayerCreditsModalType.UpgradePlayer:
                pageName = 'Agency Players [Upgrade]';
                break;
        }
        return pageName;
    }

    private static getPageType(modalType: RestrictedPlayerCreditsModalType) {
        if (modalType === RestrictedPlayerCreditsModalType.UpgradePlayer) {
            return PageType.AgencyPlayers;
        }
        return PageType.AgencyHome;
    }
}

class Selectors {

}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    stateController as Controller
};