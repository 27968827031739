
export class AgencyAdFilterRequest {
    page: number;
    pageSize: number;
    isForSale: boolean;
    isForLoan: boolean;
    positionCodes: string[];
    showOnlyPitchableAds: boolean;
    keyword: string;
    playerIds: number[]
    isLeftFooted: boolean;
}
