import React, { Component } from 'react';
import Panel from '../../components/Panel';
import Button from 'components/Buttons/Button/Button';
import FontAwesome from 'react-fontawesome';
import { Modal, ModalBody } from 'reactstrap';

export default class ContactUs extends Component {
    render() {
        return (
            <>
                <Modal
                    isOpen={true}
                    key={'ContactUS'}
                    toggle={() => this.props.onClose()}
                >
                    <ModalBody>
                        <Panel compact className="tr-contact-us__modal">
                            <Panel.Header>
                                <div className="tr-modal__header">Contact Us</div>
                            </Panel.Header>
                            <Panel.Body className="tr-contact-us">
                                <p>
                                    <FontAwesome
                                        name="envelope"
                                        className="circle-fa"
                                    />{' '}
                                    <a href="mailto:support@transferroom.com">
                                        support@transferroom.com
                            </a>
                                </p>
                                {/* <p>
                                    <FontAwesome name="phone" className="circle-fa" />{' '}
                                    <a href="tel:0044 7414176702">0044 7414176702</a>
                                </p> */}
                                <div className="text-center">
                                    <Button
                                        type="primary"
                                        effect="outline"
                                        large
                                        onClick={() => {
                                            this.props.onClose()
                                        }}
                                        className="btn btn-default"
                                    >
                                        Close
                            </Button>
                                </div>
                            </Panel.Body>
                        </Panel>
                    </ModalBody>
                </Modal>
            </>
        );
    }
}
