import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import AgencyFinderService from 'api/player-v2/agency-finder.service'
import { getAuth } from 'store/auth/authReducer';
import userActivityInsert  from 'app/user-activity/actions/user-activity.actions';
import { playerPathsV2 } from 'routes/paths'
import historyAccessor from 'history-accessor'
import { AgencyModel, MarketAreaModel, PlayerMarkedInterestAreaSourceEnum } from 'api/player-v2/models';
import { Actions as CommonActions, AgencyFinderScreen } from './common.controller'

const DEFAULT_MARKETS_QTY = 10;
const TOP_MARKETS_QTY = 5;

class State {
    isMarketsLoading: boolean
    isTopMarketsLoading: boolean
    markets: MarketAreaModel[]
    topMarkets: MarketAreaModel[]
    selectedArea: MarketAreaModel
    isCountryAgenciesLoading: boolean
    countryAgencies: AgencyModel[]
}

const defaultState: State = {
    isMarketsLoading: false,
    isTopMarketsLoading: false,
    markets: [],
    topMarkets: [],
    selectedArea: null,
    isCountryAgenciesLoading: false,
    countryAgencies: [],
}

const stateController = new StateController<State>("PLAYERV2/AGENCY-FINDER/MARKETS", defaultState);

class Actions {
    public static dispose() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ ...defaultState }))
        }
    }

    public static loadInitData() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(Actions.loadMarkets(DEFAULT_MARKETS_QTY));
            dispatch(Actions.loadTopMarkets());
            
            const { playerId } = getAuth(getState())
            dispatch(userActivityInsert({
                PageName: 'Agent Finder [Countries]',
                Message: `Viewed Agent Finder Page`,
                PlayerId: playerId,
            }))
        }
    }

    public static loadAllMarkets() {
        return async (dispatch, getState: () => AppState) => {
            const { playerId } = getAuth(getState());
            dispatch(Actions.loadMarkets(0));
            dispatch(userActivityInsert({
                PageName: 'Agent Finder [Countries]',
                Message: `Clicked Load More`,
                PlayerId: playerId,
            }))
        }
    }

    public static openCountryAgencies(marketArea: MarketAreaModel) {
        return async (dispatch, getState: () => AppState) => {
            const { playerId } = getAuth(getState());
            dispatch(stateController.setState({ selectedArea: marketArea }));
            dispatch(CommonActions.setScreen(AgencyFinderScreen.CountryAgencies));
            AgencyFinderService.savePlayerInterestedMarkets([marketArea.areaId], PlayerMarkedInterestAreaSourceEnum.Country);
            dispatch(userActivityInsert({
                PageName: 'Agent Finder [Countries]',
                Message: `Selected Country: ${marketArea.areaName}`,
                PlayerId: playerId,
            }))
        }
    }
    public static sendUserActivity(message: string, agencyId?: number) {
        return (dispatch, getState: () => AppState) => {
            const { playerId } = getAuth(getState());

            dispatch(userActivityInsert({
                PageName: `Agent Finder [${getState().playerV2.agencyFinder.markets.selectedArea.areaName}]`,
                Message: message,
                PlayerId: playerId,
                AgencyId: agencyId ? agencyId : null,
            }))
        }
    }

    public static loadCountryAgencies(areaId: number){
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isCountryAgenciesLoading: true }));
            try {
                const data = await AgencyFinderService.getCountryAgencies(areaId);
                dispatch(stateController.setState({ countryAgencies: data }));

            } finally {
                dispatch(stateController.setState({ isCountryAgenciesLoading: false }));
                dispatch(Actions.sendUserActivity('Viewed Agency Finder Country Page'));
            }
        }
    }

    private static loadMarkets(qty: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isMarketsLoading: true }));
            try {
                var data = await AgencyFinderService.getMarkets(qty, TOP_MARKETS_QTY);
                dispatch(stateController.setState({ markets: data }));
            } finally {
                dispatch(stateController.setState({ isMarketsLoading: false }));
            }
        }
    }

    private static loadTopMarkets() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isTopMarketsLoading: true }));
            try {
                var data = await AgencyFinderService.getTopMarkets(TOP_MARKETS_QTY);
                dispatch(stateController.setState({ topMarkets: data }));
            } finally {
                dispatch(stateController.setState({ isTopMarketsLoading: false }));
            }
        }
    }

    public static goBackToDefault() {
        return (dispatch, getState: () => AppState) => {
            dispatch(CommonActions.clearSelection());
            dispatch(CommonActions.setScreen(AgencyFinderScreen.Default));
            dispatch(Actions.sendUserActivity("Clicked Back to Agent Finder"));
        }
    }

    public static onDoneClick() {
        return async (dispatch, getState: () => AppState) => {
            const { playerId } = getAuth(getState());
            const screen = getState().playerV2.agencyFinder.common.sendedFromScreen
            
            if (screen === AgencyFinderScreen.AgencyRecommendations) {
                dispatch(userActivityInsert({
                    PageName: 'Agent Finder [Country Agency Recommendations]',
                    Message: `Clicked Done`,
                    PlayerId: playerId,
                }))
            }
            if (screen === AgencyFinderScreen.CountryAgencies) {
                dispatch(Actions.sendUserActivity("Clicked Done"));
            }
            
            historyAccessor.push(playerPathsV2.myAgency)
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState): State => state.playerV2.agencyFinder.markets;
    public static isLoadMoreMarketsBtnVisible = (state: AppState) => Selectors.getRoot(state).markets.length <= DEFAULT_MARKETS_QTY;
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};



