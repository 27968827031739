import {StateController} from 'utils/action-declaration'
import AgencyHomePageService from "api/agency/agent/landing/agency-homepage.service";
import {PlayerIdInfo, PlayerRepresentationResponse} from "api/agency/agent/landing/representation-request.model";
import {AppState} from "root.reducer";
import {MessagingService} from 'api/messaging/messaging.service';
import {Actions as ModalController} from "components/modals/video/video-modal.controller";
import userActivityInsert from "../../../../../app/user-activity/actions/user-activity.actions";

export enum RepresentationState {
    SendRequest,
    Connected
}

class RepresentationRequestState {
    representationRequestList: Array<PlayerRepresentationResponse>;
    isLoadingRequest: boolean;
    videoInfo: {
        title: string;
        videos: string[];
    }
    conversationId: string;
    representationRequestState: RepresentationState;
}

const defaultState: RepresentationRequestState = {
    representationRequestList: [],
    isLoadingRequest: false,
    videoInfo: null,
    conversationId: '',
    representationRequestState: RepresentationState.SendRequest,
}

const stateController = new StateController<RepresentationRequestState>(
    "AGENCY/LANDING_PAGE/REPRESENTATION_REQUEST",
    defaultState
)

class Actions {
    public static loadRepresentationRequests() {
        return async (dispatch) => {
            const requestList = await AgencyHomePageService.getRepresentationRequests();
            dispatch(stateController.setState({representationRequestList: requestList}));
        }
    }
    public static openVideoModal(requestInfo: PlayerRepresentationResponse, message: string) {
        return (dispatch) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                videoInfo: {
                    title: requestInfo.playerName,
                    videos: requestInfo.videos,
                }
            })));
            dispatch(ModalController.onOpen());
            dispatch(Actions.sendUserActivity(message, requestInfo.playerId, requestInfo.squadShortInfo.id))
        }
    }

    public static declareInterest(requestInfo: PlayerIdInfo, isProfilePage = false) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    isLoadingRequest: true,
                })));
                const conversationId = await MessagingService.declareInterestPlayerRepresentationRequest(requestInfo.messageId);
                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    conversationId,
                })));

                dispatch(Actions.sendUserActivity('Declared Interest', requestInfo.playerId, requestInfo.clubId, isProfilePage))
            } catch(e) {
                console.error(e)
            } finally {
                const requestList = getState().agency.landingPage.representationRequest.representationRequestList;
                dispatch(stateController.setState({  representationRequestList: [...requestList.filter(item => item.messageId != requestInfo.messageId)] }))
                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    isLoadingRequest: false,
                    representationRequestState: RepresentationState.Connected,
                })))
            }
        }
    }
    public static rejectInterest(requestInfo: PlayerRepresentationResponse) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    isLoadingRequest: true,
                })))
                await MessagingService.rejectPlayerRepresentationRequest(requestInfo.messageId);
                dispatch(Actions.sendUserActivity('Dismissed', requestInfo.playerId, requestInfo.squadShortInfo.currentSquad?.id))
            } catch(e) {
                console.error(e)
            } finally {
                const requestList = getState().agency.landingPage.representationRequest.representationRequestList;
                dispatch(stateController.setState({  representationRequestList: [...requestList.filter(item => item.messageId != requestInfo.messageId)] }))
                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    isLoadingRequest: false,
                })))
            }
        }
    }
    public static sendUserActivity = (message: string, playerId: number, clubId: number, isProfilePage = false) => {
        const pageName = isProfilePage ? 'Player Profile [Representation Interest Request] ' : 'Agency Home [Representation Interest Request]';
        return (dispatch) => {
            dispatch(userActivityInsert({
                PageName: pageName,
                Message: message,
                PlayerId: playerId,
                ClubId: clubId,
            }));
        }
    };
}

class Selectors {
}

const reducer = stateController.getReducer()

export {
    reducer as Reducer,
    Actions as Actions,
    RepresentationRequestState as State,
    Selectors as Selectors,
    stateController as Controller
}