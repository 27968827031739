import * as types from 'store/actionTypes';

const initialState = {
    proposals: [],
    leagues: [],
    isSuccess: true,
};

export default (playerProposals = initialState, action) => {
    switch (action.type) {
        case types.ADMIN_PLAYER_PROPOSALS_FETCH.SUCCESS: {
            return {
                ...playerProposals,
                proposals: action.payload.data.output,
                totalRecords: action.payload.data.rowCount,
                currentPageNumber: action.payload.data.currentPageNumber,
            };
        }

        case types.ADMIN_COMPETITION_PROPOSALS_FETCH.SUCCESS: {
            return {
                ...playerProposals,
                leagues: action.payload.data,
            };
        }

        case types.ADMIN_PLAYER_PROPOSALS_SEND_EMAIL.SUCCESS: {
            return {
                ...playerProposals,
                isSuccess: action.payload.data,
            };
        }

        default:
            return playerProposals;
    }
};

export const getPlayerProposalsList = state => {
    return state.playerProposals.proposals;
};

export const getTotalRecordsCount = state => {
    return state.playerProposals.totalRecords;
};

export const getCurrentPageNumber = state => {
    return state.playerProposals.currentPageNumber;
};

export const getLeagues = state => {
    return state.playerProposals.leagues;
};
