import axios from 'axios-config';
import { UpgradeBannerReportListModel, SearchParameters } from "./models";

export default class upgradeBannerReportService {
  private static axiosConfig = {
    headers: { 'Content-Type': 'application/json' },
  }

  public static async getUpgradeBannerReport(searchParameters: SearchParameters): Promise<UpgradeBannerReportListModel> {
    const { data } = await axios.post(
      `api/AdminReports/GetUpgradeBannerReport`,
      searchParameters,
      this.axiosConfig
    );
    return data;
  }

}
