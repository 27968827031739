import { AppState } from 'root.reducer'
import { FilterOptions } from './models'
import { Contact, User } from './models'
import { StateController } from 'utils/action-declaration'
import { AdminContactsService } from 'api/admin/contacts/contacts.service'


class AdminContactsState {
    modalData: {
        isOpen: boolean,
    };
    items: Array<Contact>;
    loading: boolean;
    lastTimestamp: number;
    userSearching: boolean;
    processingItems: Array<number>;
    mapContactId: number;
    keyword: string;
    users: Array<User>;
    userId: number;
    filterOptions: FilterOptions;
    processingBindUserId: number;
}

const defaultFilterOptions = {
    userMapped: null,
    firstName: '',
    lastName: '',
    email: '',
    jobTitle: '',
    companyName: '',
    userName: '',
    userFirstName: '',
    userLastName: '',
    userSquad: ''
}

const defaultState: AdminContactsState = {
    modalData: {
        isOpen: false,
    },
    items: [],
    loading: false,
    lastTimestamp: 0,
    userSearching: false,
    processingItems: [],
    mapContactId: null,
    keyword: '',
    users: [],
    userId: null,
    filterOptions: defaultFilterOptions,
    processingBindUserId: null,
}

const stateController = new StateController<AdminContactsState>(
    'ADMIN/CONTACTS-TABLE',
    defaultState
)

class Actions {

    private static mergeArray<T extends { id: number }>(a1: Array<T>, a2: Array<T>): Array<T> {
        var o: { [key: number]: T } = {}
        for (let i = 0; i < a1.length; i++) {
            o[a1[i].id] = a1[i]
        }
        for (let i = 0; i < a2.length; i++) {
            o[a2[i].id] = a2[i]
        }
        const res = Object.values(o)
        return res
    }

    private static unbindUser(item: Contact) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processingItems: [...prevState.processingItems, item.id]
                })))
                await AdminContactsService.unmapUser(item)
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    mapContactId: null,
                    items: prevState.items.map((el: Contact) => {
                        if (el.id !== item.id) {
                            return el
                        } else {
                            return {
                                ...el,
                                userName: '',
                                userFirstName: '',
                                userLastName: '',
                                userSquadName: '',
                                userMapped: false
                            }
                        }
                    })
                })))
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processingItems: prevState.processingItems.filter(id => id !== item.id)
                })))
            }
        }
    }

    public static bindUser(user: User) {

        function getUserFirstAndLastName(name: string): [string, string] {
            const fisrtName = name.split(' ')[0]
            const separatorIndex = fisrtName.length
            const lastName = name.substring(separatorIndex)
            return [fisrtName, lastName]
        } 

        return async (dispatch, getState: () => AppState) => {
            const substate = getState().admin.contactsPage

            try {
                dispatch(stateController.setState({ processingBindUserId: user.id }));
                await AdminContactsService.selectedUser(substate.mapContactId, user.id);

                const [userFirstName, userLastName] = getUserFirstAndLastName(user.name)
                const newItems = substate.items.map((item: Contact) => {
                    if (item.id !== substate.mapContactId) {
                        return item
                    } else {
                        return {
                            ...item,
                            userId: user.id,
                            userName: user.userName,
                            userFirstName: userFirstName,
                            userLastName: userLastName,
                            userSquadName: user.saquadName,
                            userMapped: true
                        }
                    }
                })

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    mapContactId: null,
                    items: newItems,
                })))
                
                dispatch(Actions.closeModal());
            } finally {
                dispatch(stateController.setState({ processingBindUserId: null }));
            }
        }
    }

    public static disposeFilters() {
        return (dispatch) => {
            dispatch(stateController.setState(prevState => ({ ...prevState, filterOptions: defaultState.filterOptions })))
        }
    }
    public static load() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ loading: true }))
            const data = await AdminContactsService.getContactsData(getState().admin.contactsPage.lastTimestamp)
            if (data) {
                const mergedItems = Actions.mergeArray(getState().admin.contactsPage.items, data.output);
                const itemsWithKey = mergedItems.map(item => ({
                    ...item,
                    key: item.id,
                }))
                dispatch(stateController.setState({
                    loading: data.rowCount > data.output.length,
                    items: itemsWithKey,
                    lastTimestamp: data.output.length === 0 ? getState().admin.contactsPage.lastTimestamp : data.output[data.output.length - 1].lastModifiedStamp
                }))
                if (data.rowCount > data.output.length) {
                    dispatch(Actions.load())
                }
            }
            dispatch(stateController.setState({ loading: false }))
        }
    }
    public static toggleBindUserModal(checked: boolean, item: Contact) {
        return async (dispatch) => {
            const stateOfToggler = checked
            if (stateOfToggler) {
                dispatch(Actions.openModal())
                dispatch(stateController.setState({ mapContactId: item.id }))
            } else {
                dispatch(Actions.unbindUser(item))
            }
        }
    }

    public static onChangeUserMapped(value: boolean | null) {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterOptions: {
                    ...prevState.filterOptions,
                    userMapped: value
                }
            })))
        }
    }
    public static onChangeFirstName(value: string) {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterOptions: {
                    ...prevState.filterOptions,
                    firstName: value
                }
            })))
        }
    }
    public static onChangeLastName(value: string) {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterOptions: {
                    ...prevState.filterOptions,
                    lastName: value
                }
            })))
        }
    }
    public static onChangeEmail(value: string) {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterOptions: {
                    ...prevState.filterOptions,
                    email: value
                }
            })))
        }
    }
    public static onChangeJobTitle(value: string) {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterOptions: {
                    ...prevState.filterOptions,
                    jobTitle: value
                }
            })))
        }
    }
    public static onChangeCompanyName(value: string) {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterOptions: {
                    ...prevState.filterOptions,
                    companyName: value
                }
            })))
        }
    }
    public static onChangeUserName(value: string) {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterOptions: {
                    ...prevState.filterOptions,
                    userName: value
                }
            })))
        }
    }
    public static onChangeUserFirstName(value: string) {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterOptions: {
                    ...prevState.filterOptions,
                    userFirstName: value
                }
            })))
        }
    }
    public static onChangeUserLastName(value: string) {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterOptions: {
                    ...prevState.filterOptions,
                    userLastName: value
                }
            })))
        }
    }
    public static onChangeUserSquad(value: string) {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterOptions: {
                    ...prevState.filterOptions,
                    userSquad: value
                }
            })))
        }
    }

    public static openModal() {
        return dispatch => {
            dispatch(stateController.setState({ userSearching: false }));
            dispatch(stateController.setState({ modalData: { isOpen: true } }));
        }
    }

    public static closeModal() {
        return dispatch => {
            dispatch(stateController.setState({
                modalData: { isOpen: false },
                keyword: '',
                users: [],
                userSearching: false,
            }));
        }
    }

    public static onKeywordChange(value: string) {
        return async (dispatch) => {
            dispatch(stateController.setState({
                keyword: value,
            }));
        }
    }

    public static searchUser() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({
                userSearching: true,
            }));
            const data = await AdminContactsService.getUsersData(getState().admin.contactsPage.keyword, null);
            dispatch(stateController.setState({
                users: data.map(user => ({
                    key: user.id,
                    id: user.id,
                    userName: user.email,
                    name: user.name,
                    jobTitle: user.positionName,
                    saquadName: user.clubName,
                })),
            }));
            dispatch(stateController.setState({ userSearching: false }));
        }
    }

    

}

class Selectors {
    public static selectFilteredItems(state: AppState) {
        const {
            userMapped,
            firstName,
            lastName,
            email,
            jobTitle,
            companyName,
            userName,
            userFirstName,
            userLastName,
            userSquad
        } = state.admin.contactsPage.filterOptions
        return state.admin.contactsPage.items
            .filter(item => userMapped === null || item.userMapped === userMapped)
            .filter(item => (item.firstName && item.firstName.toLowerCase() || "").includes(firstName.toLowerCase()))
            .filter(item => (item.lastName && item.lastName.toLowerCase() || "").includes(lastName.toLowerCase()))
            .filter(item => (item.email && item.email.toLowerCase() || "").includes(email.toLowerCase()))
            .filter(item => (item.jobTitle && item.jobTitle.toLowerCase() || "").includes(jobTitle.toLowerCase()))
            .filter(item => (item.companyName && item.companyName.toLowerCase() || "").includes(companyName.toLowerCase()))
            .filter(item => (item.userName && item.userName.toLowerCase() || "").includes(userName.toLowerCase()))
            .filter(item => (item.userFirstName && item.userFirstName.toLowerCase() || "").includes(userFirstName.toLowerCase()))
            .filter(item => (item.userLastName && item.userLastName.toLowerCase() || "").includes(userLastName.toLowerCase()))
            .filter(item => (item.userSquadName && item.userSquadName.toLowerCase() || "").includes(userSquad.toLowerCase()))
    }
    public static isFiltersSetToDefault(state: AppState) {
        return JSON.stringify(defaultState.filterOptions) === JSON.stringify(state.admin.contactsPage.filterOptions)
    }
}

const reducer = stateController.getReducer()

export {
    reducer as Reducer,
    AdminContactsState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
}