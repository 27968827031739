import compose from 'lodash/fp/compose';
import {
    SHORTLIST_PLAYER_SELECTED,
    SHORTLIST_FETCH,
    SHORTLIST_FILTER_DATA_FETCH,
    SHORTLIST_FILTER_CHANGED,
    SHORTLIST_FILTER_CLEAR,
    SHORT_LIST_SCREEN_DISPOSE,
    SHORTLIST_FILTER_COLLAPSE_TOGGLE

} from 'store/actionTypes';

import getSections from 'services/filterSections';
const sections = getSections();

const initialCriteria = {
    keyword: '',
    squadId: undefined,
    ...sections.status.default,
    ...sections.age.default,
    ...sections.contractExpiry.default,
    ...sections.position.default,
    ...sections.league.default,
    ...sections.marketValue.default,
};

const initialState = {
    ids: [],
    selectedId: null,
    keyword: '',
    leaguesChangdTimestamp: new Date(),
    expandedSections: Object.values(sections).map(s => s.id),
    structure: {
        statuses: [],
        minAge: sections.age.default.minAge,
        maxAge: sections.age.default.maxAge,
        contractExpiryMin: sections.contractExpiry.default.contractExpiryMin,
        contractExpiryMax: sections.contractExpiry.default.contractExpiryMax,
        positions: sections.position.default.positions,
        minMarketValue: sections.marketValue.default.minMarketValue,
        maxMarketValue: sections.marketValue.default.maxMarketValue,
    },
    criteria: initialCriteria
};

export default (players = initialState, action) => {
    switch (action.type) {

        case SHORT_LIST_SCREEN_DISPOSE: {
            return initialState;
        }

        case SHORTLIST_FETCH.SUCCESS: {
            const { data } = action.payload;

            return {
                ...players,
                ids: data.output.map(player => player.id),
            };
        }

        case SHORTLIST_FILTER_COLLAPSE_TOGGLE: {
            const toggledSectionId = action.payload;
            const { expandedSections } = players;
            return {
                ...players,
                expandedSections: expandedSections.includes(toggledSectionId)
                    ? expandedSections.filter(s => s !== toggledSectionId)
                    : [...expandedSections, toggledSectionId],
            };
        }

        case SHORTLIST_FILTER_DATA_FETCH.SUCCESS: {
            const { data } = action.payload;

            return {
                ...players,
                structure: {...players.structure, ...data },
            };
        }

        case SHORTLIST_FILTER_CHANGED: {
            let change = action.payload || {};
            if (
                change.keyword !== undefined &&
                change.keyword !== players.criteria.keyword &&
                players.criteria.squadId
            ) {
                change = { ...change, squadId: initialCriteria.squadId };
            }
            const res = {
                ...players,
                criteria: { ...players.criteria, ...change },
                leaguesChangdTimestamp: new Date(),
            };
            return res;
        }

        case SHORTLIST_FILTER_CLEAR: {
            const section = action.payload;
            return {
                ...players,
                criteria: { ...players.criteria, ...section.default },
            };
        }

        case SHORTLIST_PLAYER_SELECTED: {
            const playerId = action.payload.id;
            return {
                ...players,
                selectedId: players.selectedId === playerId ? null : playerId,
            };
        }

        default:
            return players;
    }
};

const path = state => state.shortList;

export const getshortListFilterCriteria = compose(
    filter => ({
        ...filter.criteria,
        competitionsIds: filter.criteria.competitionLists.reduce(
            (res, list) => {
                return res.concat(list.competitionIds);
            },
            [],
        ),
    }),
    path,
);

