import * as types from 'store/actionTypes';

const initialState = {
    friendlyProposals: [],
    leagues: [],
    isSuccess: true,
};

export default (friendlyProposals = initialState, action) => {
    switch (action.type) {
        case types.ADMIN_FRIENDLY_PROPOSALS_FETCH.SUCCESS: {
            return {
                ...friendlyProposals,
                proposals: action.payload.data.output,
                totalRecords: action.payload.data.rowCount,
                currentPageNumber: action.payload.data.currentPageNumber,
            };
        }

        case types.ADMIN_FRIENDLY_PROPOSALS_SEND_EMAIL: {
            return {
                ...friendlyProposals,
                isSuccess: action.payload.data,
            };
        }

        default:
            return friendlyProposals;
    }
};

export const getFriendlyProposalsList = state => {
    return state.friendlyProposals.proposals;
};

export const getTotalRecordsCount = state => {
    return state.friendlyProposals.totalRecords;
};

export const getCurrentPageNumber = state => {
    return state.friendlyProposals.currentPageNumber;
};

export const getLeagues = state => {
    return state.friendlyProposals.leagues;
};