import {
    PITCH_INSIGHT_LOAD_GENERAL_INFO,
    PITCH_INSIGHT_LOAD_RESPONSES_MOST_PITCHED,
    PITCH_INSIGHT_SUCCESS_RATE_DATA,
    PITCH_INSIGHT_LOAD_LOG,
    PITCH_INSIGHT_TEST_INTEREST,
    PITCH_INSIGHT_LOAD_RECENT_ACTIVITY,
    PITCH_INSIGHT_LOAD_GENERAL_INFO_HIDDEN,
    PitchInsightPlans
} from '../reducers/pitch-insight.reducer';
import createApiThunk from 'services/createApiThunk';
import { getAuthSquadId, getAuthUserId } from 'store/auth/authReducer'
import { PITCH_INSIGHT_DETAILED_MODAL } from 'constants/modals';
import { MODAL_OPEN, MODAL_CLOSE } from 'store/actionTypes'
import { createAction } from 'redux-actions';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';

export const trackActivity = (action, prefix, playerId, clubId) => {
    return (dispatch, getState) => {
        
        dispatch(
            userActivityInsert({
                Message: action,
                PageName: `Pitch Insights ` + (prefix ? prefix : ''),
                PlayerId: playerId,
                ClubId: clubId ? clubId : getAuthSquadId(getState()),
                PageType: PageType.Pitch
            })
        )
    }
}

export const trackActivityWithoutDefaultClubId = (action, prefix, playerId, clubId, actionType) => {
    return (dispatch, getState) => {
        
        dispatch(
            userActivityInsert({
                Message: action,
                PageName: `Pitch Insights ` + (prefix ? prefix : ''),
                PlayerId: playerId,
                ClubId: clubId,
                ActionType: actionType,
                PageType: PageType.Pitch
            })
        )
    }
}

export const testInterest = (pitchId, trackingObj) => {
    return (dispatch, getState) => {
        const request = {
            type: PITCH_INSIGHT_TEST_INTEREST,
            method: 'post',
            payload: pitchId,
            url: `PlayerPitch/TestInterest/${pitchId}`,
        };

        return dispatch(createApiThunk(request, trackingObj));
    }
}

export const mapPlayerActivitiesToFlatView = (items) => {
    let flatMappedView = [];

    for (let player of items.pitchReactionDataList) {
        var parentItem = { playerId: player.playerId, playerName: player.playerName };

        for (var playerItem of player.pitchReactionDetailList) {
            flatMappedView.push({ ...parentItem, pitchInfo: playerItem })
        }

    }
    flatMappedView.sort((a, b) => new Date(b.pitchInfo.actionPerformedAt) -  new Date(a.pitchInfo.actionPerformedAt))
    return flatMappedView;
}

export const loadGeneralInfoStart =()=>{
    return async (dispatch, getState) => {
        let state = getState();

        const currentSquadId = getAuthSquadId(state);
        const currentUserId = getAuthUserId(state);

        const request = {
            type: PITCH_INSIGHT_LOAD_GENERAL_INFO,
            method: 'get',
            url: `PitchInsight/GetPitchInsightGeneralInfo/${currentSquadId}/${currentUserId}`,
        };
       await dispatch(createApiThunk(request));
    }
}

export const loadGeneralInfoStartHidden =()=>{
    return async (dispatch, getState) => {
        let state = getState();

        const currentSquadId = getAuthSquadId(state);
        const currentUserId = getAuthUserId(state);

        const request = {
            type: PITCH_INSIGHT_LOAD_GENERAL_INFO_HIDDEN,
            method: 'get',
            url: `PitchInsight/GetPitchInsightGeneralInfo/${currentSquadId}/${currentUserId}`,
        };
       await dispatch(createApiThunk(request));
    }
}


export const loadGeneralInfo = () => {
    return async (dispatch, getState) => {
        let state = getState();

        const currentSquadId = getAuthSquadId(state);
        const currentUserId = getAuthUserId(state);

        const request = {
            type: PITCH_INSIGHT_LOAD_GENERAL_INFO_HIDDEN,
            method: 'get',
            url: `PitchInsight/GetPitchInsightGeneralInfo/${currentSquadId}/${currentUserId}`,
        };
        let result = await dispatch(createApiThunk(request));

        const approvedPlansVipAndPremium = [PitchInsightPlans.Vip, PitchInsightPlans.Premium]
        if (approvedPlansVipAndPremium.indexOf(result.plan) !== -1) {

            dispatch(loadResponsesMostPitched());
            dispatch(loadSuccessRateInfo());
            dispatch(loadLog());
            dispatch(loadRecentACtivity());
        }
    }
}

export const loadResponsesMostPitched = () => {
    return (dispatch, getState) => {
        let state = getState();

        const currentSquadId = getAuthSquadId(state);
        const currentUserId = getAuthUserId(state);

        const request = {
            type: PITCH_INSIGHT_LOAD_RESPONSES_MOST_PITCHED,
            method: 'get',
            url: `PitchInsight/GetMostPitchedReactionData/${currentSquadId}/${currentUserId}`,
        };

        return dispatch(createApiThunk(request));
    }
}

export const turnOnDetailedView = (playerId) => {
    return dispatch => {
        dispatch(trackActivity('Clicked for Details', '[Most pitched]', playerId, -1));
        dispatch(createAction(MODAL_OPEN)({ id: PITCH_INSIGHT_DETAILED_MODAL, playerId: playerId}));
    }
}

export const closeDetailedView = () => {
    return dispatch => {
        let command = { type: MODAL_CLOSE, payload: PITCH_INSIGHT_DETAILED_MODAL };
        dispatch(command);
    };
};

export const loadRecentACtivity = () => {
    return (dispatch, getState) => {
        let state = getState();

        const currentSquadId = getAuthSquadId(state);
        const currentUserId = getAuthUserId(state);

        const request = {
            type: PITCH_INSIGHT_LOAD_RECENT_ACTIVITY,
            method: 'get',
            url: `PitchInsight/GetResponsesOnMostPitchedPlayers/${currentSquadId}/${currentUserId}`,
        };

        return dispatch(createApiThunk(request));
    }
}

export const loadSuccessRateInfo = () => {
    return (dispatch, getState) => {
        let state = getState();

        const currentSquadId = getAuthSquadId(state);
        const currentUserId = getAuthUserId(state);

        const request = {
            type: PITCH_INSIGHT_SUCCESS_RATE_DATA,
            method: 'get',
            url: `PitchInsight/GetPitchinsightSuccessRateData/${currentSquadId}/${currentUserId}`,
        };

        return dispatch(createApiThunk(request));
    }
}

export const loadLog = () => {
    return (dispatch, getState) => {
        let state = getState();

        const currentSquadId = getAuthSquadId(state);
        const currentUserId = getAuthUserId(state);

        const request = {
            type: PITCH_INSIGHT_LOAD_LOG,
            method: 'get',
            url: `PitchInsight/GetPitchLog/${currentSquadId}/${currentUserId}`,
        };

        return dispatch(createApiThunk(request));
    }
}