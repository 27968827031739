import axios from 'axios-config';
import { CoachOnboardingAccessResponse, CoachRegistrationRequest, CoachSignupResponse, ReconfirmAgentRequest } from './models';
import { AuthInfoModel } from 'store/auth/auth-state';

export class AgencyCoachOnboardingService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async getOnboardingAccess(token: string): Promise<CoachOnboardingAccessResponse> {
        const { data } = await axios.get(
            `api/v2/CoachOnboarding/GetOnboardingAccess/${token}`,
            this.axiosConfig
        );
        return data
    }
    
    public static async signup(request: CoachRegistrationRequest): Promise<CoachSignupResponse> {
        const { data } = await axios.post(
            `api/v2/CoachOnboarding/Signup`,
            request,
            this.axiosConfig
        );
        return data
    }

    public static async submitAgent(request: ReconfirmAgentRequest): Promise<AuthInfoModel> {
        const { data } = await axios.post(
            `api/v2/CoachOnboarding/ReconfirmAgent`,
            request,
            this.axiosConfig
        );
        return data
    }

    public static async sendConfirmEmail(): Promise<CoachSignupResponse> {
        const { data } = await axios.post(
            `api/v2/CoachOnboarding/SendConfirmEmail`,
            null,
            this.axiosConfig
        )
        return data
    }

    public static async confirmEmailVerification(uid: string): Promise<CoachSignupResponse> {
        const { data } = await axios.post(
            `api/v2/CoachOnboarding/ConfirmEmailVerify/${uid}`, null, this.axiosConfig
        )
        return data
    }
}