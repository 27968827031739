import axios from 'axios-config';
import { FilterOptions, GetUserActivityResponse } from './models'

export class AdminUserActivityService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async getUserActivityData(dataOptions: FilterOptions): Promise<GetUserActivityResponse> {
        const { data } = await axios.post(
            `api/AdminUserActivity/GetUserActivityNew`,
            dataOptions,
            this.axiosConfig
        );
        return data;
    }

    public static async getUserActivityCriteria () {
        const { data } = await axios.get(
            `api/AdminUserActivity/GetCriteria`,
            this.axiosConfig
        )
        return data;
    }
}