import * as types from 'store/actionTypes';

const initialState = {
    result: null,
};

export default (s = initialState, action) => {
    switch (action.type) {
        case types.USER_SET_UPDATE_PASSWORD_POST.SUCCESS:
            return {
                ...s,
                result: action.payload.data,
            };
        case types.USER_RETURN_TO_UPDATE_PASSWORD:
            return {
                ...s,
                result: null
            };
        default:
            return s;
    }
};

export const setNewPasswordResult = state => {
    return state.userupdatepassword.result;
};
