import { translate } from 'services/localization';

// function but not const is needed for localization function

export default function getSections() {
    return {
        transferType: {
            name: translate('clubAds.filterSection.transferType'),
            propName: 'transferType',
            id: 1,
            default: {
                isForSale: false,
                isForLoan: false,
            },
        },
         transferFee: {
            name: translate('clubAds.filterSection.transferFee'),
            propName: 'transferFee',
            id: 2,
            default: {
                transferFeeMin: 0,
                transferFeeMax: 500000000,
                transferFeeRangeSteps: [{ from: 0, to: 1000000, stepRange: 100000 }, { from: 1000000, to: Number.MAX_VALUE, stepRange: 500000 }]
            },
        },
        monthlyLoanFee: {
            name: translate('clubAds.filterSection.monthlyLoanFee'),
            propName: 'monthlyLoanFee',
            id: 3,
            default: {
                monthlyLoanFeeMin: 0,
                monthlyLoanFeeMax: 500000000,
                monthlyLoanFeeRangeSteps: [{ from: 0, to: 10000, stepRange: 1000 }, { from: 10000, to: 100000, stepRange: 5000 }, { from: 100000, to: Number.MAX_VALUE, stepRange: 10000 }]
            },
        },
        position: {
            name: translate('clubAds.filterSection.position'),
            propName: 'position',
            id: 4,
            default: {
                positions: [
                    {
                        key: 'GK',
                        value: translate('clubAds.filterSection.goalkeeper'),
                    },
                    {
                        key: 'LB',
                        value: translate('clubAds.filterSection.leftBack'),
                    },
                    {
                        key: 'RB',
                        value: translate('clubAds.filterSection.rightBack'),
                    },
                    {
                        key: 'CB',
                        value: translate('clubAds.filterSection.centreBack'),
                    },
                    {
                        key: 'DM',
                        value: translate(
                            'clubAds.filterSection.defensiveMidfielder',
                        ),
                    },
                    {
                        key: 'CM',
                        value: translate(
                            'clubAds.filterSection.centralMidfielder',
                        ),
                    },
                    {
                        key: 'AM',
                        value: translate(
                            'clubAds.filterSection.attackingMidfielder',
                        ),
                    },
                    {
                        key: 'W',
                        value: translate('clubAds.filterSection.winger'),
                    },
                    {
                        key: 'F',
                        value: translate('clubAds.filterSection.forward'),
                    },
                ],
            },
        },
        league: {
            name: translate('clubAds.filterSection.league'),
            propName: 'league',
            id: 5,
            default: { competitionsIds: [] },
        },
        status: {
            name: translate('clubAds.filterSection.status'),
            propName: 'status',
            id: 6,
            default: {
                statuses: [
                    {
                        key: 'Interest',
                        value: translate('clubAds.filterSection.interest'),
                    },
                    {
                        key: 'Saved',
                        value: translate('clubAds.filterSection.saved'),
                    },
                    {
                        key: 'Dismissed',
                        value: translate('clubAds.filterSection.dismissed'),
                    },
                    {
                        key: 'Others',
                        value: translate('clubAds.filterSection.others'),
                    },
                ],
            },
        },
        isLeftFooted: {
            name: 'Left Footed',
            propName: 'isLeftFooted',
            id: 7,
            default: {
                isLeftFooted: false,
            },
        },
    };
}

// TODO : investigate how to eliminate duplicating
// sections are used in filter-reducer

export const sections = {
    transferType: {
        name: translate('clubAds.filterSection.transferType'),
        propName: 'transferType',
        id: 1,
        default: {
            isForSale: false,
            isForLoan: false,
        },
    },
    transferFee: {
        name: translate('clubAds.filterSection.transferFee'),
        propName: 'transferFee',
        id: 2,
        default: {
            transferFeeMin: 0,
            transferFeeMax: 500000000,
        },
    },
    monthlyLoanFee: {
        name: translate('clubAds.filterSection.monthlyLoanFee'),
        propName: 'monthlyLoanFee',
        id: 3,
        default: {
            monthlyLoanFeeMin: 0,
            monthlyLoanFeeMax: 500000000,
        },
    },
    position: {
        name: translate('clubAds.filterSection.position'),
        propName: 'position',
        id: 4,
        default: {
            positions: [
                {
                    key: 'GK',
                    value: translate('clubAds.filterSection.goalkeeper'),
                },
                {
                    key: 'LB',
                    value: translate('clubAds.filterSection.leftBack'),
                },
                {
                    key: 'RB',
                    value: translate('clubAds.filterSection.rightBack'),
                },
                {
                    key: 'CB',
                    value: translate('clubAds.filterSection.centreBack'),
                },
                {
                    key: 'DM',
                    value: translate(
                        'clubAds.filterSection.defensiveMidfielder',
                    ),
                },
                {
                    key: 'CM',
                    value: translate('clubAds.filterSection.centralMidfielder'),
                },
                {
                    key: 'AM',
                    value: translate(
                        'clubAds.filterSection.attackingMidfielder',
                    ),
                },
                {
                    key: 'W',
                    value: translate('clubAds.filterSection.winger'),
                },
                {
                    key: 'F',
                    value: translate('clubAds.filterSection.forward'),
                },
            ],
        },
    },
    league: {
        name: translate('clubAds.filterSection.league'),
        propName: 'league',
        id: 5,
        default: { competitionsIds: [] },
    },
    status: {
        name: 'Status',
        propName: 'status',
        id: 6,
        default: {
            statuses: [
                {
                    key: 'Interest',
                    value: 'Interest',
                },
                {
                    key: 'Saved',
                    value: 'Saved',
                },
                {
                    key: 'Dismissed',
                    value: 'Dismissed',
                },
                {
                    key: 'Others',
                    value: 'Others',
                },
            ],
        },
    },
    isLeftFooted: {
        name: 'Left Footed',
        propName: 'isLeftFooted',
        id: 7,
        default: {
            isLeftFooted: false,
        },
    },
};
