import axios from 'axios-config';

export class ShortlistService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async addToShortlist(playerId: number): Promise<any> {
        const { data } = await axios.put(
            `api/ShortList/1/${playerId}`,
            this.axiosConfig
        );
        return;
    }

    public static async removeFromShortlist(playerId: number): Promise<any> {
        const { data } = await axios.delete(
            `api/ShortList/1/${playerId}`,
            this.axiosConfig
        );
        return;
    }

    public static async agencyAddToShortlist(playerId: number): Promise<any> {
        const { data } = await axios.post(
            `api/ShortList/agency/set/${playerId}`,
            this.axiosConfig
        );
        return;
    }

    public static async agencyRemoveFromShortlist(playerId: number): Promise<any> {
        const { data } = await axios.post(
            `api/ShortList/agency/remove/${playerId}`,
            this.axiosConfig
        );
        return;
    }

    public static async declareInterest(playerId: number, squadId: number, userId: number): Promise<any> {
        const { data } = await axios.post(
            `api/Players/DeclareInterestForPlayer/${playerId}/${userId}/${squadId}`,
            this.axiosConfig
        );
        return;
    }

    public static async loadShortListedPlayers(): Promise<Array<number>>{
        const { data } = await axios.get(
            `api/ShortList/Get/1`,
            this.axiosConfig
        );
        return data;
    }

    public static async loadAgencyShortListedPlayers(): Promise<Array<number>>{
        const { data } = await axios.get(
            `api/ShortList/agency/get`,
            this.axiosConfig
        );
        return data;
    }
}