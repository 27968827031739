import { PageType, ActionType, UserActivityType } from 'constants/enums';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions'
import { GridToggleState } from './grid.controller';

export class PlayerActivityService {
    private static page: string = 'Search';
    private static containers = {
        dropdown: 'Drop-down',
        recommended: 'Recommended',
        suspendedContract: 'Suspended',
        releaseList: 'Release list',
        recommendedAndSuspended: 'Recommended + Suspended',
        recommendedAndReleaseList: 'Recommended + Release list'
    }

    private static buildCommand(container: string, action: string, playerId?: number, clubId?: number, agencyId?: number, type?: ActionType, userActivityType?: number) {
        return {
            Message: action,
            PageType: PageType.Search,
            PlayerId: playerId,
            ClubId: clubId,
            AgencyId: agencyId,
            PageName: `${this.page}${container ? ` [${container}]` : ''}`,
            ActionType: type,
            UserActivityType: userActivityType
        }
    }

    public static getContainerName(isRecommended: boolean, isSuspended: boolean, isOnReleaseList: boolean, gridState?: GridToggleState) {
        let container = '';
        if (gridState === GridToggleState.Search && isRecommended && isOnReleaseList) {
            container = this.containers.recommendedAndReleaseList
        } else if (gridState === GridToggleState.Search && isRecommended && isSuspended) {
            container = this.containers.recommendedAndSuspended
        } else if (gridState === GridToggleState.Search && isRecommended && !isSuspended && !isOnReleaseList) {
            container = this.containers.recommended
        } else if (gridState === GridToggleState.Search && !isRecommended && isSuspended && !isOnReleaseList) {
            container = this.containers.suspendedContract
        } else if (gridState === GridToggleState.Search && !isRecommended && !isSuspended && isOnReleaseList) {
            container = this.containers.releaseList
        } else if (gridState) {
            container = PlayerActivityService.getStatePage(gridState)
        }
        return container
    }

    public static openPlayerProfile(playerId: number, recommended: boolean, isSuspendedContract: boolean, isOnReleaseList: boolean, clubId?: number, agencyId?: number, gridState?: GridToggleState) {
        return (dispatch) => {
            const container = this.getContainerName(recommended, isSuspendedContract, isOnReleaseList, gridState)
            const command = this.buildCommand(container, 'Opened Player Profile', playerId, clubId, agencyId)

            dispatch(userActivityInsert(command))
        }
    }

    public static toggleShortlist(playerId: number, isInShortList: boolean, recommended?: boolean, isSuspendedContract?: boolean, isOnReleaseList?: boolean, clubId?: number, agencyId?: number, gridState?: GridToggleState) {
        return (dispatch) => {
            const container = this.getContainerName(recommended, isSuspendedContract, isOnReleaseList, gridState)
            const message = isInShortList ? 'Added to Shortlist' : 'Removed from Shortlist'
            const actionType = isInShortList ? ActionType.AddToShortlist : null;
            const command = this.buildCommand(container, message, playerId, clubId, agencyId, actionType)

            dispatch(userActivityInsert(command))
        }
    }

    public static requestTransferAvailability(playerId: number, recommended?: boolean, suspended?: boolean, isOnReleaseList?: boolean, clubId?: number, gridState?: GridToggleState) {
        return (dispatch) => {
            const container = this.getDropDownContainer(gridState, recommended, suspended, isOnReleaseList)
            const command = this.buildCommand(container, 'Requested Transfer Availability', playerId, clubId, null, null, UserActivityType.Action)

            dispatch(userActivityInsert(command))
        }
    }

    public static requestLoanAvailability(playerId: number, recommended?: boolean, suspended?: boolean, isOnReleaseList?: boolean, clubId?: number, gridState?: GridToggleState) {
        return (dispatch) => {
            const container = this.getDropDownContainer(gridState, recommended, suspended, isOnReleaseList)
            const command = this.buildCommand(container, 'Requested Loan Availability', playerId, clubId, null, null, UserActivityType.Action)

            dispatch(userActivityInsert(command))
        }
    }

    public static requestAskingPrice(playerId: number, recommended?: boolean, suspended?: boolean, isOnReleaseList?: boolean, clubId?: number, gridState?: GridToggleState) {
        return (dispatch) => {
            const container = this.getDropDownContainer(gridState, recommended, suspended, isOnReleaseList)
            const command = this.buildCommand(container, 'Requested Asking Price', playerId, clubId, null, null, UserActivityType.Action)

            dispatch(userActivityInsert(command))
        }
    }

    public static requestLoanContribution(playerId: number, recommended?: boolean, suspended?: boolean, isOnReleaseList?: boolean, clubId?: number, gridState?: GridToggleState) {
        return (dispatch) => {
            const container = this.getDropDownContainer(gridState, recommended, suspended, isOnReleaseList)
            const command = this.buildCommand(container, 'Requested Loan Contribution', playerId, clubId, null, null, UserActivityType.Action)

            dispatch(userActivityInsert(command))
        }
    }

    public static declareInterestToClubMain(playerId: number, recommended?: boolean, suspended?: boolean, isOnReleaseList?: boolean, clubId?: number, gridState?: GridToggleState) {
        return (dispatch) => {
            const container = this.getContainerName(recommended, suspended, isOnReleaseList, gridState)
            const command = this.buildCommand(container, 'Declared Interest', playerId, clubId, null, ActionType.DeclaredInterest, UserActivityType.Action)
            dispatch(userActivityInsert(command))
        }
    }

    public static declareInterestToAgentMain(playerId: number, recommended?: boolean, suspended?: boolean, isOnReleaseList?: boolean, agencyId?: number, gridState?: GridToggleState) {
        return (dispatch) => {
            const container = this.getContainerName(recommended, suspended, isOnReleaseList, gridState)
            const command = this.buildCommand(container, 'Declared Interest', playerId, null, agencyId, ActionType.DeclaredInterest, UserActivityType.Action)
            dispatch(userActivityInsert(command))
        }
    }

    public static declareInterestToClub(playerId: number, recommended?: boolean, suspended?: boolean, isOnReleaseList?: boolean, clubId?: number, gridState?: GridToggleState) {
        return (dispatch) => {
            const container = this.getDropDownContainer(gridState, recommended, suspended, isOnReleaseList)
            const command = this.buildCommand(container, 'Declared Interest', playerId, clubId, null, ActionType.DeclaredInterest, UserActivityType.Action)

            dispatch(userActivityInsert(command))
        }
    }

    public static declareInterestToAgent(playerId: number, recommended?: boolean, suspended?: boolean, isOnReleaseList?: boolean, agencyId?: number, gridState?: GridToggleState) {
        return (dispatch) => {
            const container = this.getDropDownContainer(gridState, recommended, suspended, isOnReleaseList)
            const command = this.buildCommand(container, 'Declared Interest', playerId, null, agencyId, ActionType.DeclaredInterest, UserActivityType.Action)
            dispatch(userActivityInsert(command))
        }
    }

    public static openRecommenderTootip(playerId: number, clubId?: number, agencyId?: number, gridState?: GridToggleState) {
        return (dispatch) => {
            const state = PlayerActivityService.getStatePage(gridState)
            const command = this.buildCommand(state, 'Opened Recommender Tooltip', playerId, clubId, agencyId)
            dispatch(userActivityInsert(command))
        }
    }
    public static openSuspendedTootip(playerId: number, clubId?: number, agencyId?: number, gridState?: GridToggleState) {
        return (dispatch) => {
            const state = PlayerActivityService.getStatePage(gridState)
            const command = this.buildCommand(state, 'Opened Suspended Tooltip', playerId, clubId, agencyId)
            dispatch(userActivityInsert(command))
        }
    }
    public static openSuspendedReadmore(playerId: number, recommended: boolean, suspended: boolean, isInReleaseList?: boolean, clubId?: number, agencyId?: number, gridState?: GridToggleState) {
        return (dispatch) => {
            const container = PlayerActivityService.getContainerName(recommended, suspended, isInReleaseList, gridState)
            const command = this.buildCommand(container, 'Read More', playerId, clubId, agencyId)
            dispatch(userActivityInsert(command))
        }
    }

    public static openNewMessage(playerId: number, recommended: boolean, suspended: boolean, isOnReleaseList: boolean, clubId: number, agencyId: number, gridState: GridToggleState) {
        const container = this.getDropDownContainer(gridState, recommended, suspended, isOnReleaseList)
        return dispatch => dispatch(userActivityInsert(this.buildCommand(container, 'Opened New Message', playerId, clubId, agencyId)))
    }

    public static cancelNewMessage(playerId: number, recommended: boolean, suspended: boolean, isOnReleaseList: boolean, clubId: number, agencyId: number, gridState: GridToggleState) {
        const container = this.getDropDownContainer(gridState, recommended, suspended, isOnReleaseList)
        return dispatch => dispatch(userActivityInsert(this.buildCommand(container, 'Cancelled New Message', playerId, clubId, agencyId)))
    }

    public static sendNewMessage(playerId: number, recommended: boolean, suspended: boolean, isOnReleaseList: boolean, clubId: number, agencyId: number, gridState: GridToggleState) {
        const container = this.getDropDownContainer(gridState, recommended, suspended, isOnReleaseList)
        return dispatch => dispatch(userActivityInsert(this.buildCommand(container, 'Sent New Message', playerId, clubId, agencyId, ActionType.SentNewMessage, UserActivityType.Action)))
    }

    public static openDropDown(playerId: number, recommended: boolean, suspended: boolean, isOnReleaseList: boolean, clubId: number, agencyId: number, gridState: GridToggleState) {
        const container = this.getDropDownContainer(gridState, recommended, suspended, isOnReleaseList)
        return dispatch => dispatch(userActivityInsert(this.buildCommand(container, 'Opened Drop-down', playerId, clubId, agencyId)))
    }

    public static openReleaseListTootip(playerId: number, clubId?: number, agencyId?: number, gridState?: GridToggleState) {
        return (dispatch) => {
            const state = PlayerActivityService.getStatePage(gridState)
            const command = this.buildCommand(state, 'Opened Release List Tooltip', playerId, clubId, agencyId)
            dispatch(userActivityInsert(command))
        }
    }

    private static getDropDownContainer(gridState: GridToggleState, recommended: boolean, suspended: boolean, isOnReleaseList: boolean) {
        const pageName = PlayerActivityService.getStatePage(gridState)
        let container = '' 
        if (gridState === GridToggleState.Search && recommended && suspended) {
            container = this.containers.recommendedAndSuspended
        } else if (gridState === GridToggleState.Search && recommended && isOnReleaseList) {
            container = this.containers.recommendedAndReleaseList
        } else if (gridState === GridToggleState.Search && recommended && !suspended && !isOnReleaseList) {
            container = this.containers.recommended
        } else if (gridState === GridToggleState.Search && !recommended && suspended && !isOnReleaseList) {
            container = this.containers.suspendedContract
        } else if (gridState === GridToggleState.Search && !recommended && !suspended && isOnReleaseList) {
            container = this.containers.releaseList
        } else if (gridState === GridToggleState.Similarity) {
            container = ''
        }

        return `${pageName}${pageName && container ? ' ' : ''}${container}`
    }

    private static getStatePage(state: GridToggleState) {
        let action = ''
        if (state === GridToggleState.ShortList) { action = 'Shortlist' }
        else if (state === GridToggleState.Similarity) { action = 'Similar to' }
        return action;
    }

    public static openPositionRoleTootip(playerId: number, clubId?: number, agencyId?: number, gridState?: GridToggleState) {
        return (dispatch) => {
            const state = PlayerActivityService.getStatePage(gridState)
            const command = this.buildCommand(state, 'Opened Player Style Info', playerId, clubId, agencyId)
            dispatch(userActivityInsert(command))
        }
    }

    public static openRatingTootip(playerId: number, clubId?: number, agencyId?: number, gridState?: GridToggleState) {
        return (dispatch) => {
            const state = PlayerActivityService.getStatePage(gridState)
            const command = this.buildCommand(state, 'Opened Player Rating Info', playerId, clubId, agencyId)
            dispatch(userActivityInsert(command))
        }
    }
}

export class ClubActivityService {
    private static page: string = 'Search [Club card]';

    private static buildCommand = (action: string, clubId: number, type?: ActionType) =>
        ({ Message: action, PageType: PageType.Search, ClubId: clubId, PageName: ClubActivityService.page, ActionType: type })

    public static openClubPlayers(clubId: number) {
        return dispatch => dispatch(userActivityInsert(this.buildCommand('Opened Clubs Players', clubId)))
    }

    public static openDropDown(clubId: number) {
        return dispatch => dispatch(userActivityInsert(this.buildCommand('Opened Drop-down', clubId)))
    }

    public static openNewMessage(clubId: number) {
        return dispatch => dispatch(userActivityInsert(this.buildCommand('Opened New Message', clubId)))
    }

    public static cancelNewMessage(clubId: number) {
        return dispatch => dispatch(userActivityInsert(this.buildCommand('Cancelled New Message', clubId)))
    }

    public static sendNewMessage(clubId: number) {
        return dispatch => dispatch(userActivityInsert(this.buildCommand('Sent New Message', clubId, ActionType.SentNewMessage)))
    }
}

export class AgencyActivityService {
    private static page: string = 'Search [Agency card]';

    private static buildCommand = (action: string, agencyId: number, type?: ActionType) =>
        ({ Message: action, PageType: PageType.Search, AgencyId: agencyId, PageName: AgencyActivityService.page, ActionType: type })

    public static openAgencyProfile(agencyId: number) {
        return dispatch => dispatch(userActivityInsert(this.buildCommand('Opened Agency Profile', agencyId)))
    }

    public static openDropDown(agencyId: number) {
        return dispatch => dispatch(userActivityInsert(this.buildCommand('Opened Drop-down', agencyId)))
    }

    public static openNewMessage(agencyId: number) {
        return dispatch => dispatch(userActivityInsert(this.buildCommand('Opened New Message', agencyId)))
    }

    public static cancelNewMessage(agencyId: number) {
        return dispatch => dispatch(userActivityInsert(this.buildCommand('Cancelled New Message', agencyId)))
    }

    public static sendNewMessage(agencyId: number) {
        return dispatch => dispatch(userActivityInsert(this.buildCommand('Sent New Message', agencyId, ActionType.SentNewMessage)))
    }
}
