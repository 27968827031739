import axios from 'axios-config';
import { CancelToken } from 'axios';
import { SearchResult, SearchItemForClub } from "./model/search-result"
import { SearchRequest } from "./model/search-request";
import { SuggestionItem } from './model/suggestion-item'
import { SearchFilterCriteria } from './model/search-filter-criteria';
import { LatestFilterSet, SearchSuggestionsSubsetResult } from './model/suggestion-subsets';

export default class SearchService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async search(request: SearchRequest, cancelToken: CancelToken): Promise<SearchResult<SearchItemForClub>> {
        
        const { data } = await axios.post(
            `api/v2/Search`,
            request,
            { ...this.axiosConfig, cancelToken: cancelToken }
        )
        return data
    }

    public static async searchSimilarity(playerId: number, request: SearchRequest, cancelToken: CancelToken): Promise<SearchResult<SearchItemForClub>> {

        const { data } = await axios.post(
            `api/v2/Search/Similarity/${playerId}`,
            request,
            { ...this.axiosConfig, cancelToken: cancelToken }
        )
        return data
    }

    public static async getSuggestions(
        keyword: string,
        showOnlyClubs: boolean,
        showOnlyAgencies: boolean,
        includeOwnSquad: boolean,
        showOnlyShortlist: boolean,
        playerSet: number[],
        cancelToken: CancelToken
    ): Promise<Array<SuggestionItem>> {
        const { data } = await axios.post(
            `api/v2/Search/Suggestions`,
            {
                keyword,
                showOnlyClubs,
                showOnlyAgencies,
                includeOwnSquad,
                showOnlyShortlist,
                playerSet
            },
            { ...this.axiosConfig, cancelToken: cancelToken }
        )
        return data
    }

    public static async getCriteria(): Promise<SearchFilterCriteria> {

        const { data } = await axios.get(
            `api/v2/Search/Criteria`,
            { ...this.axiosConfig }
        )
        return data
    }

    public static async getSearchSuggestionSubsets(): Promise<SearchSuggestionsSubsetResult> {
        const { data } = await axios.get(
            `api/v2/Search/FilterSuggestions`,
            { ...this.axiosConfig }
        )
        return data
    }
    public static async saveLatestSearch(latestSearch: LatestFilterSet): Promise<any> {
        const { data } = await axios.post(
            `api/v2/Search/SaveLatestSearch`,
            { ...latestSearch },
            { ...this.axiosConfig }
        )
        return data
    }

    public static async getCardTextByPlayerId(playerId: number): Promise<any> {
        const { data } = await axios.get(
            `api/v2/Pitch/GetCardText/${playerId}`,
            { ...this.axiosConfig }
        )
        return data
    }
}