import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer'
import VirtualSummitService from 'api/virtual-summit/virtual-summit.service'
import { Player, Ad } from 'api/virtual-summit/models/preparation-transfer-out'
import { Actions as MainActions } from './b2match.controller'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';

class B2MatchTransfersOutState {
    isLoading: boolean;
    players: Array<Player>;
    ads: Array<Ad>;
    selectedIds: Array<number>;
    processing: boolean
}

const defaultState: B2MatchTransfersOutState = {
    isLoading: false,
    players: [],
    ads: [],
    selectedIds: [],
    processing: false
}

const stateController = new StateController<B2MatchTransfersOutState>(
    "VIRTUAL_SUMMIT/B2MATCH_TRANSFERS-OUT",
    defaultState
);

class Actions {
    public static getData = () => {

        return async (dispatch, getState: () => AppState) => {

            let state = getState();
            let { userId, eventId } = state.auth;
            let meetingId = state.events.virtualSummit.b2match.agendaId;

            dispatch(stateController.setState({ isLoading: true }));
            const data = await VirtualSummitService.getPreparationTransferOut(userId, meetingId);
            let onlyActiveAds = data.ads.filter(i => i.active);

            dispatch(stateController.setState({
                players: data.players,
                ads: onlyActiveAds,
                isLoading: false
            }));

            let { scheduledTime, meetingClubName } = getState().events.virtualSummit.b2match;
            dispatch(userActivityInsert({
                PageName: 'Event [Agenda Builder]',
                Message: `Opened Transfers Out set up (${meetingClubName} at ${scheduledTime})`,
                PageType: PageType.Events,
                EventId: eventId
            }));
        }
    }

    public static continue = () => {
        return async (dispatch, getState: () => AppState) => {

            let state = getState();
            let { userId, eventId } = state.auth;
            let meetingId = state.events.virtualSummit.b2match.agendaId;

            dispatch(stateController.setState({ processing: true }));

            await VirtualSummitService.SetTransferOutPlayers(userId, meetingId, state.events.virtualSummit.b2matchTransfersOut.selectedIds);

            dispatch(stateController.setState({ processing: false }));

            let { scheduledTime, meetingClubName } = getState().events.virtualSummit.b2match
            let { players, selectedIds } = getState().events.virtualSummit.b2matchTransfersOut

            let playersNames = []
            selectedIds.forEach(i => {
                let player = players.filter(item => item.id === i)[0]
                playersNames.push(player.name)
            })

            dispatch(userActivityInsert({
                PageName: 'Event [Agenda Builder]',
                Message: `Selected Players for Transfers Out: ${playersNames.join(', ')} (${meetingClubName} at ${scheduledTime})`,
                PageType: PageType.Events,
                EventId: eventId
            }))

            let b2matchObjectivities = getState().events.virtualSummit.b2matchObjectivities
            let canShowTransferIn = b2matchObjectivities.transferIn

            if (canShowTransferIn) {
                dispatch(MainActions.goNext());
            } else {
                await VirtualSummitService.CompleteStepper(userId, meetingId);
                dispatch(MainActions.showDoneStep());
            }
        }
    }


    public static onChange = (playerId: number) => {
        return (dispatch, getState: () => AppState) => {
            let selectedIds = getState().events.virtualSummit.b2matchTransfersOut.selectedIds
            let newSelection;
            if (selectedIds.includes(playerId)) {
                newSelection = selectedIds.filter(i => i !== playerId)
                dispatch(stateController.setState({
                    selectedIds: newSelection
                }))
            } else {
                newSelection = selectedIds.concat(playerId)
                dispatch(stateController.setState({
                    selectedIds: newSelection
                }))
            }
        }
    }

    public static setSelectedPlayers = (playerIds: Array<number>) => {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ selectedIds: playerIds }));
        }
    }

    public static dispose = () => {
        return async (dispatch, getState: () => AppState) => {
            let appState = getState();
            dispatch(stateController.setState(defaultState));
        }
    }
}

class Selectors {
    private static getPositionValue(position: string) {
        if (position === 'GK') return 0;
        if (position === 'LB') return 1;
        if (position === 'RB') return 2;
        if (position === 'CB') return 3;
        if (position === 'DM') return 4;
        if (position === 'CM') return 5;
        if (position === 'AM') return 6;
        if (position === 'W') return 7;
        if (position === 'F') return 8;
        return 100;
    }
    private static compare(a: Player, b: Player) {
        const aValue = Selectors.getPositionValue(a.position);
        const bValue = Selectors.getPositionValue(b.position);

        if (aValue < bValue) {
            return -1;
        }
        if (aValue > bValue) {
            return 1;
        }
        return 0;
    }
    public static selectPlayers(state: AppState) {
        return state.events.virtualSummit.b2matchTransfersOut.players
    }
    public static selectRecommendedPlayers(state: AppState) {
        let resp = this.selectPlayers(state).filter(i => i.isAvailable || i.isRecomended);
        resp.sort(Selectors.compare);
        return resp;
    }
    public static selectOtherPlayers(state: AppState) {
        let resp = this.selectPlayers(state).filter(i => !i.isAvailable && !i.isRecomended);
        resp.sort(Selectors.compare);
        return resp;
    }
    public static selectSelectedIds(state: AppState) {
        return state.events.virtualSummit.b2matchTransfersOut.selectedIds
    }
    public static selectAds(state: AppState) {
        let ads = state.events.virtualSummit.b2matchTransfersOut.ads.map(i => {
            let obj: Ad = {
                positionName: i.positionName,
                type: i.type,
                amount: i.amount,
                positionCode: i.positionCode,
                currencyCode: i.currencyCode,
                amountFormatted: i.amountFormatted,
                active: i.active
            }
            return obj
        })
        return ads
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    B2MatchTransfersOutState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};



