import { StateController } from 'utils/action-declaration';
import { SuperPitchService } from 'api/super-pitch/super-pitch'
import { AppState } from 'root.reducer';
import * as SuperPitchController from './common.controller'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions'
import { ActionType, PageType } from 'constants/enums';

class ReactionsState {
    isTestingInterest: Array<number>
}

const defaultState: ReactionsState = {
    isTestingInterest: []
}

const stateController = new StateController<ReactionsState>(
    "SUPER_PITCH/REACTIONS",
    defaultState
)

class Actions {
    public static testInterest = (pitchId, pitchSquadId, squad) => async (dispatch, getState: () => AppState) => {
        const squadId = getState().auth.squadId
        const userId = getState().auth.userId
        try {
            dispatch(stateController.setState(prevState => ({ ...prevState, isTestingInterest: [...prevState.isTestingInterest, pitchSquadId] })))
            await SuperPitchService.testInterest(squadId, userId, pitchId, pitchSquadId)
            dispatch(SuperPitchController.Actions.updateSuperPitchSquadData(pitchId, pitchSquadId))

            const playerId = getState().superPitch.common.pitches.byId[pitchId].playerInfo.id
            dispatch(userActivityInsert({
                Message: `Tested Interest`,
                PageName: 'Super Pitch [Drop-down]',
                PlayerId: playerId,
                ClubId: squad.id,
                ActionType: ActionType.TestedInterest,
                PageType: PageType.Pitch
            }));
        } catch (e) {
            console.error(e)
        } finally {
            dispatch(stateController.setState(prevState => ({ ...prevState, isTestingInterest: [...prevState.isTestingInterest.filter(i => i !== pitchSquadId)] })))
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    ReactionsState as State,
    Actions as Actions,
    stateController as Controller
};