// import createApiThunk from '../../../services/createApiThunk';
import { getAuth } from 'store/auth/authReducer';
import { v4 } from 'uuid';
import config from 'config'
import { ActionType, PageType } from 'constants/enums';
import CommonService from 'api/common/common.service';
import { MetaInfo } from 'store/auth/auth-state';
const { EventHubProducerClient } = require("@azure/event-hubs");

// let userActivityInsert___Timer = null;
// let userActivityInsert___Batch = [];

export type UserActivity = {
    Message: string,
    PageName: string,
    Action?: string,
    PlayerId?: number,
    UserId?: number,
    ClubId?: number,
    CurrentClubId?: number,
    AgencyId?: number,
    CoachId?: number,
    ExternalAgencyId?: number,
    Data?: any,
    Date?: string,
    UserActivityType?: any,
    AdminImpersonateId?: number,
    Device?: string,
    DeviceVersion?: any,
    IpAddress?: any,
    CurrentLocation?: any,
    CurrentLocationCountry?: string,
    Links?: any,
    ActionType?: ActionType,
    PageType?: PageType,
    EventId?: number,
}

export const insertAnonymousActivity = (userActivity: UserActivity, callback = null) => async (dispatch, getState) => {
    const { metaInfo } = getAuth(getState());
    // const { Message } = userActivity;
    await eventHubInsert(userActivity, null, null, null, metaInfo);

    // let contract = {
    //     UserId: -1,
    //     Message,
    //     PageUrl:
    //         userActivity.PageName === undefined
    //             ? window.location.pathname
    //             : userActivity.PageName,
    //     Device: 'WEB',
    //     Data: null,
    //     AgencyId:
    //         typeof userActivity.AgencyId === 'undefined' ||
    //             userActivity.AgencyId === null
    //             ? -1
    //             : userActivity.AgencyId,
    //     PlayerId: userActivity.PlayerId,
    // }

    // userActivityInsert___Batch = [];
    // const request = {
    //     type: USER_ACTIVITY_INSERT,
    //     method: 'post',
    //     //url: 'Common/InsertUserActivityObsolete',
    //     url: 'Common/InsertUserActivity',
    //     payload: contract,
    // };
    // await dispatch(createApiThunk(request));
};

export const userActivityInsertSingle = (userActivity: UserActivity) => async (dispatch, getState) => {
    const { userId, adminImpersonateId, userImpersonateId, metaInfo } = getAuth(getState());
    // const { Message } = userActivity;
    if (!userId) return null;

    await eventHubInsert(userActivity, userId, adminImpersonateId, userImpersonateId, metaInfo);

    // if (userActivityInsert___Timer) {
    //     window.clearTimeout(userActivityInsert___Timer);
    //     userActivityInsert___Timer = null;
    // }
    // var data = [{
    //     UserId: userId,
    //     ClubId:
    //         typeof userActivity.ClubId === 'undefined' ||
    //             userActivity.ClubId === null
    //             ? -1
    //             : userActivity.ClubId,
    //     CurrentClubId:
    //         typeof userActivity.CurrentClubId === 'undefined' ||
    //             userActivity.CurrentClubId === null
    //             ? -1
    //             : userActivity.CurrentClubId,
    //     AgencyId:
    //         typeof userActivity.AgencyId === 'undefined' ||
    //             userActivity.AgencyId === null
    //             ? -1
    //             : userActivity.AgencyId,
    //     Message,
    //     PageUrl:
    //         userActivity.PageName === undefined
    //             ? window.location.pathname
    //             : userActivity.PageName,
    //     Device: 'WEB',
    //     PlayerId: userActivity.PlayerId,
    //     Data: null,
    //     Links: userActivity.Links
    // }];
    // const request = {
    //     type: USER_ACTIVITY_INSERT,
    //     method: 'post',
    //     url: 'Common/InsertUserActivityBatch',
    //     payload: data,
    // };

    // await dispatch(createApiThunk(request));
};

export const userActivityInsertSequence = (userActivityInsertBatch: UserActivity[]) => async (dispatch) => {
    for (const userActivity of userActivityInsertBatch) {
        await dispatch(userActivityInsert(userActivity));
    }
}

const userActivityInsert = (userActivity: UserActivity) => async (dispatch, getState) => {
    const { userId, adminImpersonateId, userImpersonateId, metaInfo } = getAuth(getState());

    if (!userId) return null;

    await eventHubInsert(userActivity, userId, adminImpersonateId, userImpersonateId, metaInfo);

    // let maximumBatchCountReached = userActivityInsert___Batch.length > 10;

    // let processBatch = () => {
    //     if (userActivityInsert___Timer) {
    //         window.clearTimeout(userActivityInsert___Timer);
    //         userActivityInsert___Timer = null;
    //     }
    //     var data = [...userActivityInsert___Batch]//.reverse();
    //     userActivityInsert___Batch = [];
    //     const request = {
    //         type: USER_ACTIVITY_INSERT,
    //         method: 'post',
    //         url: 'Common/InsertUserActivityBatch',
    //         payload: data,
    //     };
    //     dispatch(createApiThunk(request));
    // };

    // if (userActivityInsert___Timer && !maximumBatchCountReached) {
    //     window.clearTimeout(userActivityInsert___Timer);
    // }

    // userActivityInsert___Batch.push({
    //     UserId: userId,
    //     ClubId:
    //         typeof userActivity.ClubId === 'undefined' ||
    //             userActivity.ClubId === null
    //             ? -1
    //             : userActivity.ClubId,
    //     CurrentClubId:
    //         typeof userActivity.CurrentClubId === 'undefined' ||
    //             userActivity.CurrentClubId === null
    //             ? -1
    //             : userActivity.CurrentClubId,
    //     AgencyId:
    //         typeof userActivity.AgencyId === 'undefined' ||
    //             userActivity.AgencyId === null
    //             ? -1
    //             : userActivity.AgencyId,
    //     Message,
    //     PageUrl:
    //         userActivity.PageName === undefined
    //             ? window.location.pathname
    //             : userActivity.PageName,
    //     Device: 'WEB',
    //     PlayerId: userActivity.PlayerId,
    //     Data: null,
    //     Links: userActivity.Links
    // });
    // if (!maximumBatchCountReached) {
    //     userActivityInsert___Timer = window.setTimeout(() => {
    //         processBatch();
    //     }, 5000);
    // } else {
    //     processBatch();
    // }
};

export const eventHubInsert = async (ua: UserActivity, userId: number, adminImpersonateId: number, userImpersonateId: number, meta: MetaInfo) => {
    if (config.eventHub == null) return;

    const now = new Date();
    let userActivityNew = {
        uid: v4(),
        action: ua.Message,
        pageUrl: ua.PageName,
        playerId: ua.PlayerId,
        userId: userId,
        parentSquadId: ua.ClubId,
        currentSquadId: ua.CurrentClubId,
        agencyId: ua.AgencyId,
        coachId: ua.CoachId,
        externalAgencyId: ua.ExternalAgencyId,
        data: ua.Data,
        date: now.toISOString(),
        userActivityType: ua.UserActivityType,
        adminImpersonateId: adminImpersonateId,
        userImpersonateId: userImpersonateId,
        device: meta ? meta.device : null,
        deviceVersion: meta ? meta.deviceVersion : null,
        ipAddress: meta ? meta.ipAddress : null,
        currentLocation: meta ? meta.location : null,
        currentLocationCountry: meta ? meta.locationCountry : null,
        links: ua.Links,
        actionType: ua.ActionType,
        pageType: ua.PageType,
        eventId: ua.EventId,
    };

    try {
        const producerClient = new EventHubProducerClient(config.eventHub.connectionString, config.eventHub.name);
        const eventDataBatch = await producerClient.createBatch();
        let wasAdded = eventDataBatch.tryAdd({ body: userActivityNew });

        if (wasAdded) { }
        else {
            (userActivityNew as any).frontEndString = "tryAdd returns false";
            let serialized = JSON.stringify(userActivityNew);
            await CommonService.insertUserActivityPoison(serialized);
        }
        await producerClient.sendBatch(eventDataBatch);
        await producerClient.close();
    }
    catch (err) {
        // alert("Error when creating UA. Please contact Vadym and don't close the tab.");
        //console.log("Error when creating & sending a batch of events: ", err);
        (userActivityNew as any).frontEndString = `Error occured. Message: ${err.message}. StackTrace: ${err.stack}`;
        let serialized = JSON.stringify(userActivityNew);
        await CommonService.insertUserActivityPoison(serialized);
    }
}

/*
const getActivityType = (message) => {
    let activityType = null;
    if (message.includes("Request") || message.includes("Declare") || message.includes("Message")) {
        activityType = 1;
    }
    return activityType;
}*/

export default userActivityInsert;
