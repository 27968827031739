import createApiThunk from '../../services/createApiThunk';
import {
    PLAYER_RANKING_FETCH_CONFIGURATION,
    PLAYER_RANKING_SET_SELECTED_SEASON,
    PLAYER_RANKING_SET_SELECTED_SANDBOX,
    PLAYER_RANKING_SET_SELECTED_POSITION,
    getPlayerRankingState,
    PLAYER_RANKING_FIRST_SET_FETCH,
    PLAYER_RANKING_SECOND_SET_FETCH,
    PLAYER_RANKING_SET_IS_IN_SHORT_LIST,
    PLAYER_RANKING_TOGGLE_NEXT_PAGE,
    PLAYER_RANKING_SHOW_FIRST_PAGE,
    SHOW_BATCH_COUNT,
    PLAYER_RANKING_DISPOSE,
} from './playerRanking.reducer';
import { createAction } from 'redux-actions';
import { getAuthSquadId, getAuthUserId } from 'store/auth/authReducer';
import userActivityInsert, {
    userActivityInsertSingle,
} from 'app/user-activity/actions/user-activity.actions';
import { playersDeclareInterestSave } from 'app/declare-interest/actions/declare-interest.actions';
import { Actions as PlayerProfileCommonController } from 'store/playerProfile/profile-common.controller'
import { ActionType, PageType, UserActivityType } from 'constants/enums';
import { translate } from 'services/localization';
import { isPlayerUser } from 'store/auth/authReducer';
export const declareInterestRanking = playerId => {
    return (dispatch, getState) => {
        dispatch(playersDeclareInterestSave(playerId));
        dispatch(
            PlayerProfileCommonController.shortlistToggle(playerId, false),
        );
        dispatch(insertPlayerRankingActivity(`Declared Interest`, playerId, ActionType.DeclaredInterest, UserActivityType.Action));
    };
};

export const loadConfiguration = playerId => {
    return async (dispatch, getState) => {
        const state = getState();
        const currentSquadId = getAuthSquadId(state);
        const currentUserId = getAuthUserId(state);

        const request = {
            type: PLAYER_RANKING_FETCH_CONFIGURATION,
            method: 'get',
            currentPlayerId: playerId,
            url: `PlayerRanking/GetConfiguration/${playerId}/${currentSquadId}/${currentUserId}`,
            success: PLAYER_RANKING_FETCH_CONFIGURATION.SUCCESS,
        };

        await dispatch(createApiThunk(request));
    };
};

export const getSquadIdByPlayer = (playerId, state) => {
    let rankingState = state.playerRanking;
    if (playerId === rankingState.currentPlayerId)
        return state.playerProfile.details.parentSquadId;
    let players = [
        ...rankingState.firstSet.items,
        ...rankingState.secondSet.items,
    ];
    let findPlayer = players.filter(item => item.player.playerId === playerId);
    if (findPlayer.length > 0) {
        let playerFound = findPlayer[0];
        if (playerFound.player.parentSquad)
            return playerFound.player.parentSquad.id;
    }
    return null;
};

export const gerRankingPlayerById = (playerId, state) => {
    let rankingState = state.playerRanking;
    let players = [
        ...rankingState.firstSet.items,
        ...rankingState.secondSet.items,
    ];
    let findPlayer = players.filter(item => item.player.playerId === playerId);
    if (findPlayer.length > 0) {
        let playerFound = findPlayer[0];
        if (playerFound.player)
            return playerFound.player;
    }
    return null;
};

export const insertPlayerRankingActivity = (action, playerId, actionType, userActivityType) => {
    return (dispatch, getState) => {
        const isPlayer = isPlayerUser(getState())
        dispatch(
            userActivityInsert({
                PageName: 'Player Profile [Ranking]',
                Message: action,
                ClubId: isPlayer ? null : getSquadIdByPlayer(playerId, getState()),
                PlayerId: playerId,
                ActionType: actionType,
                PageType: PageType.PlayerProfile,
                UserActivityType: userActivityType
            }),
        );
    };
};

export const openProfileTrack = playerId => {
    return (dispatch, getState) => {
        const isPlayer = isPlayerUser(getState())
        dispatch(
            userActivityInsertSingle({
                PageName: 'Player Profile [Ranking]',
                Message: 'Opened Player Profile',
                ClubId: isPlayer ? null : getSquadIdByPlayer(playerId, getState()),
                PlayerId: playerId,
                PageType: PageType.PlayerProfile
            }),
        );
    };
};

export const setSelectedSeason = (selectedSeasonKey,selectedSeasonTitle)  => {
    return (dispatch, getState) => {
        const action = createAction(PLAYER_RANKING_SET_SELECTED_SEASON);
        dispatch(action(selectedSeasonKey));
        const playerId = getState().playerRanking.currentPlayerId;

        dispatch(
            insertPlayerRankingActivity(
                `Select Season: "${selectedSeasonTitle}"`,
                playerId,
                null
            ),
        );
        dispatch(refreshBothSets());
    };
};

export const setSelectedComparisonSandbox = (
    selectedComparisonSandbox,
    name,
) => {
    return (dispatch, getState) => {
        let action = createAction(PLAYER_RANKING_SET_SELECTED_SANDBOX);
        dispatch(action(selectedComparisonSandbox));
        let playerId = getState().playerRanking.currentPlayerId;
        dispatch(insertPlayerRankingActivity(`Compare to: "${name}"`, playerId, ActionType.CompareTo));
        dispatch(refreshBothSets());
    };
};

export const togglePositionCode = positionCode => {
    return (dispatch, getState) => {
        const currentPositions = getState().playerRanking.selection
            .selectedPositions;
        const indexOfPosition = currentPositions.indexOf(positionCode);
        let newPositions = [];

        if (indexOfPosition === -1) {
            newPositions = [...currentPositions, positionCode];
        } else {
            newPositions = [
                ...currentPositions.filter(item => item !== positionCode),
            ];
        }

        const action = createAction(PLAYER_RANKING_SET_SELECTED_POSITION);
        const playerId = getState().playerRanking.currentPlayerId;

        dispatch(
            insertPlayerRankingActivity(
                `Selected position: "${positionCode}"`,
                playerId,
                null
            ),
        );
        dispatch(action(newPositions));
    };
};

export const shortListToggle = (playerId, isInShortList) => async (
    dispatch,
    getState,
) => {
    const currentSquadId = getAuthSquadId(getState());
    const method = isInShortList ? 'delete' : 'put';
    const toggleRequest = {
        type: PLAYER_RANKING_SET_IS_IN_SHORT_LIST,
        method,
        url: `ShortList/${currentSquadId}/${playerId}`,
        payload: {
            playerId,
            on: !isInShortList,
        },
    };
    const res = await dispatch(createApiThunk(toggleRequest));
    dispatch(insertPlayerRankingActivity(`Added to Shortlist`, playerId, ActionType.AddToShortlist));
    dispatch(PlayerProfileCommonController.shortlistInsert(playerId, isInShortList));
    return res;
};

export const disposeState = () => {
    return (dispatch, getState) => {
        dispatch(createAction(PLAYER_RANKING_DISPOSE)());
    };
};

const refreshBothSets = () => {
    return (dispatch, getState) => {
        const state = getState();
        const rankingState = getPlayerRankingState(state);
        const pathToPropId = entity => entity.avvarageStatistic.key;

        dispatch(loadSet(pathToPropId(rankingState.firstSet), PLAYER_RANKING_FIRST_SET_FETCH));
        dispatch(loadSet(pathToPropId(rankingState.secondSet), PLAYER_RANKING_SECOND_SET_FETCH));
    };
};

export const loadFirstSet = (statPropId) => {
    return (dispatch, getState) => {
        let playerId = getState().playerRanking.currentPlayerId;

        dispatch(
            insertPlayerRankingActivity(
                `Selected parametr (1 set): "${translate('playerRanking.statistics')[statPropId]}"`,
                playerId,
                null
            ),
        );
        dispatch(loadSet(statPropId, PLAYER_RANKING_FIRST_SET_FETCH));
    };
};

export const loadSecondSet = statPropId => {
    return (dispatch, getState) => {
        let playerId = getState().playerRanking.currentPlayerId;

        dispatch(
            insertPlayerRankingActivity(
                `Selected parametr (2 set): "${translate('playerRanking.statistics')[statPropId]}"`,
                playerId,
                null
            ),
        );
        dispatch(loadSet(statPropId, PLAYER_RANKING_SECOND_SET_FETCH));
    };
};

const loadSet = (statPropId, type) => {
    return async (dispatch, getState) => {
        const state = getState();
        let currentSquadId = getAuthSquadId(state);
        const rankingState = getPlayerRankingState(state);
        const request = {
            type: type,
            method: 'post',
            url: `PlayerRanking/Get`,
            success: type.SUCCESS,
            payload: {
                playerId: rankingState.currentPlayerId,
                statPropertyId: statPropId,
                selection: rankingState.selection,
                currentSquadId: currentSquadId,
            },
        };

        await dispatch(createApiThunk(request));
    };
};

export const getRankingList = (set, state) => {
    const rankingState = getPlayerRankingState(state);
    const currentProfilePlayerId = rankingState.currentPlayerId;

    const count = rankingState.displayPage * SHOW_BATCH_COUNT;
    const originalItems = rankingState[set + 'Set'];
    if (originalItems === null) return null;

    let tempItems = [...originalItems.items];
    const currentPlayer = tempItems.find(
        item => item.player.playerId === currentProfilePlayerId,
    );

    const selectedPositions = rankingState.selection.selectedPositions;
    if (selectedPositions.length > 0) {
        tempItems = [
            ...tempItems.filter(
                item =>
                    selectedPositions.indexOf(item.player.positionCode) !== -1,
            ),
        ];
    }

    const indexOfCurrentPlayer = tempItems
        .map(item => item.player.playerId)
        .indexOf(currentProfilePlayerId);

    if (indexOfCurrentPlayer === -1 && currentPlayer !== undefined) {
        tempItems = [...tempItems, currentPlayer].sort(
            (a, b) => {
                const valueA = parseFloat(a.statValue.replace(',', '.'))
                const valueB = parseFloat(b.statValue.replace(',', '.'))
        
                return valueB - valueA
            }
        );
    }

    tempItems = [
        ...tempItems.map((item, position) => ({
            ...item,
            position: position + 1,
        })),
    ];

    const currentPlayerUpdated = tempItems.find(
        item => item.player.playerId === currentProfilePlayerId,
    );
    const indexOfCurrentPlayerUpdated = tempItems
        .map(item => item.player.playerId)
        .indexOf(currentProfilePlayerId);

    const items = [...tempItems].splice(0, count);

    if (
        indexOfCurrentPlayerUpdated >= count &&
        currentPlayerUpdated !== undefined
    ) {
        items[items.length - 1] = currentPlayerUpdated;
    }
    return { ...originalItems, items: items };
};

export const toggleNextPage = () => {
    return async dispatch => {
        dispatch(createAction(PLAYER_RANKING_TOGGLE_NEXT_PAGE)());
    };
};
export const showFirstTen = () => {
    return async dispatch => {
        dispatch(createAction(PLAYER_RANKING_SHOW_FIRST_PAGE)());
    };
};
