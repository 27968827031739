import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer'
import { userPaths } from 'routes/paths';
import { CountryWithAgenciesModel, CreatePlayerAdRequest, PlayerAdModel, SquadAgencyPermissionTypeModelEnum, PlayerAdTypeEnumModel } from 'api/agency/club/onboarding/onboarding-models';
import Service from 'api/agency/club/onboarding/onboarding.servise';
import { getPositionIdByCode } from 'utils/position-helper'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import positionFullNames from 'constants/positionFullNames'
import { setupAgencyFeature } from 'store/auth/auth.actions'
import { PageType, ActionType } from 'constants/enums';
import PlayerAdV2Service from 'api/player-ad-v2/player-ad-v2.service';
import { getCancelTokenSource } from 'axios-config'
import { PlayerAdWithCountersModel } from 'api/player-ad-v2/models/player-ad-with-counters';

export const ageRule = { min: 16, max: 40 };
export const heightRule = { min: 150, max: 210 };

export class InviteAgentsCheckboxes {
    myPlayers: boolean;
    shortlistPlayers: boolean;
    myCountry: boolean;
    algorithm: boolean;
    top5: boolean;
    specific: boolean;
}
export class AgencyCreateAdModel {
    toLoan: boolean;
    toBuy: boolean;
    loan?: number;
    buy?: number;
    grossSalary?: number;
    age: { min: number, max: number }
    height: number;
}
export class InviteSpecificAgentState {
    allCountries: Array<CountryWithAgenciesModel>
    filtered: Array<CountryWithAgenciesModel>
    selectedAgencyIds: Array<number>
    searchString: string
    selectedTree: Array<CountryWithAgenciesModel>
    availableTree: Array<CountryWithAgenciesModel>
}

const defaultInviteAgentsCheckboxesState: InviteAgentsCheckboxes = { myPlayers: true, shortlistPlayers: true, myCountry: true, algorithm: true, top5: false, specific: false, }
const defaultInviteSpecificAgentState: InviteSpecificAgentState = { allCountries: [], filtered: [], selectedAgencyIds: [], searchString: "", availableTree: [], selectedTree: [] }
class OnboardingState {
    invitationGuid: string;
    currentStep: number;
    requesProccessing: boolean;
    termsAgreed: boolean;
    inviteAgents: InviteAgentsCheckboxes;
    inviteSpecificAgent: InviteSpecificAgentState;
    existingAds: Array<PlayerAdModel>;
    selectedExistingAds: Array<number>;
    positions: {
        [key: string]: AgencyCreateAdModel
    }
    allPositions: Array<string>
    currentPositionIndex: number;
    isLoading: boolean;

    playerAds: Array<PlayerAdWithCountersModel>;
    loadingPlayerAds: boolean
}

const defaultState: OnboardingState = {
    invitationGuid: null,
    currentStep: 1,
    requesProccessing: false,
    termsAgreed: false,
    inviteAgents: defaultInviteAgentsCheckboxesState,
    inviteSpecificAgent: defaultInviteSpecificAgentState,
    existingAds: [],
    selectedExistingAds: [],
    positions: {},
    allPositions: [],
    currentPositionIndex: 0,
    isLoading: false,

    playerAds: [],
    loadingPlayerAds: false,
}

const stateController = new StateController<OnboardingState>(
    "AGENCY/CLUB/ONBOARDING",
    defaultState
);

class Actions {
    public static token = null;

    public static getSpecificAgencies() {
        return async (dispatch, getState: () => AppState) => {
            let subState = getState().agency.club.onboarding;
            dispatch(stateController.setState({ requesProccessing: true }));
            let result = await Service.getCountriesWithAgencies();
            dispatch(stateController.setState({
                requesProccessing: false,
                inviteSpecificAgent: { ...subState.inviteSpecificAgent, allCountries: result, availableTree: result, filtered: result }
            }));
        }
    }

    public static getExistingAds() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ requesProccessing: true }));
            let result = await Service.getExistingAds();

            dispatch(stateController.setState({ requesProccessing: false, existingAds: result, selectedExistingAds: result.map(x => { return x.id }) }));
        }
    }

    public static toggleSelectedAd(id: number) {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().agency.club.onboarding;
            if (substate.selectedExistingAds.indexOf(id) == -1) {
                dispatch(stateController.setState({ selectedExistingAds: [...substate.selectedExistingAds, id] }))
            }
            else {
                dispatch(stateController.setState({ selectedExistingAds: substate.selectedExistingAds.filter(i => i != id) }))
            }
        }
    }

    public static setSearchString(search: string) {
        return async (dispatch, getState: () => AppState) => {
            let subState = getState().agency.club.onboarding;
            let filtered = subState.inviteSpecificAgent.allCountries.map(i => {
                return { ...i, name: i.name, agencies: i.agencies.filter(x => x.name.toUpperCase().includes(search.toUpperCase())) }
            });
            let newAvailableTree = filtered.map(i => {
                return { ...i, name: i.name, agencies: i.agencies.filter(x => !subState.inviteSpecificAgent.selectedAgencyIds.some(k => k == x.id)) }
            });
            newAvailableTree = newAvailableTree.filter(i => i.agencies.length > 0);

            dispatch(stateController.setState({
                inviteSpecificAgent: {
                    ...subState.inviteSpecificAgent, searchString: search, filtered: filtered, availableTree: newAvailableTree
                }
            }));
        }
    }

    public static nextStep() {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let substate = getState().agency.club.onboarding;

            let step = substate.currentStep + 1;
            if (substate.currentStep >= 7) step = substate.currentStep;
            
            if(substate.currentStep == 2 && state.agency.club.onboarding.existingAds.length <= 0){
                step++;
            }
            
            if (substate.currentStep == 4) {
                const positions = substate.positions
                let positionsText = Object.keys(positions).join(", ");

                if (positionsText) {
                    dispatch(userActivityInsert({
                        Message: `Selected Positions: ${positionsText}`,
                        PageName: 'Club Agents [Onboarding - Ad Creation]',
                        PageType: PageType.Agents
                    }));
                }
            }

            dispatch(stateController.setState({ currentStep: step }));
        }
    }

    public static setUpAgencyFeatures() {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().agency.club.onboarding;
            if (substate.currentStep === 1) {
                dispatch(stateController.setState({ isLoading: true }));

                await Service.setUpAgencyFeatures();

                dispatch(setupAgencyFeature())

                dispatch(userActivityInsert({
                    Message: 'Get Started',
                    PageName: 'Club Agents [Onboarding]',
                    PageType: PageType.Agents
                }))

                dispatch(stateController.setState({ currentStep: substate.currentStep + 1, isLoading: false }));
            }
        }
    }

    public static inviteAgents() {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().agency.club.onboarding;

            let permissionsArray: Array<SquadAgencyPermissionTypeModelEnum> = [];
            if (substate.inviteAgents.myPlayers) permissionsArray.push(SquadAgencyPermissionTypeModelEnum.AgentsRepMyPlayers);
            if (substate.inviteAgents.shortlistPlayers) permissionsArray.push(SquadAgencyPermissionTypeModelEnum.AgentsRepShortlist);
            if (substate.inviteAgents.myCountry) permissionsArray.push(SquadAgencyPermissionTypeModelEnum.AgentsRegInMyCountry);
            if (substate.inviteAgents.algorithm) permissionsArray.push(SquadAgencyPermissionTypeModelEnum.AgentsRecommended);
            if (substate.inviteAgents.top5) permissionsArray.push(SquadAgencyPermissionTypeModelEnum.AgentsTopFives);
            if (substate.inviteAgents.specific) permissionsArray.push(SquadAgencyPermissionTypeModelEnum.SpecificAgencies);


            if (substate.currentStep == 2 && substate.inviteAgents.specific) {
                dispatch(stateController.setState({ currentStep: substate.currentStep + 1 }));
            }
            if (substate.currentStep == 2 && !substate.inviteAgents.specific) {
                dispatch(stateController.setState({ requesProccessing: true }));
                let guid = await Service.createAgencyInvitation({ permissions: permissionsArray, specificAgenciesIds: [] })
                dispatch(stateController.setState({ requesProccessing: false, invitationGuid: guid, currentStep: substate.currentStep + 2 }));
            }
            if (substate.currentStep == 3 && substate.inviteAgents.specific) {
                dispatch(stateController.setState({ requesProccessing: true }));
                let guid = await Service.createAgencyInvitation({ permissions: permissionsArray, specificAgenciesIds: substate.inviteSpecificAgent.selectedAgencyIds })
                dispatch(stateController.setState({ requesProccessing: false, invitationGuid: guid, currentStep: substate.currentStep + 1 }));
            }
        }
    }

    public static shareAds() {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().agency.club.onboarding;

            dispatch(stateController.setState({ requesProccessing: true }));

            const activityText = []
            substate.selectedExistingAds.forEach((id) => {
                const item = substate.existingAds.find((ads) => ads.id == id)
                if (item) {
                    activityText.push(`\"${positionFullNames[item.positionCode]}, ${item.type === PlayerAdTypeEnumModel.Buy ? 'To Buy' : 'To Loan'}\"`)
                }
            })

            await Service.shareAds({ invitationGuid: substate.invitationGuid, adsIds: substate.selectedExistingAds })

            dispatch(userActivityInsert({
                Message: `Shared requirements: ${activityText.join(", ")}`,
                PageName: 'Club Agents [Onboarding - Share ad]',
                PageType: PageType.Agents
            }))
            dispatch(stateController.setState({ requesProccessing: false, currentStep: substate.currentStep + 1 }));
        }
    }

    public static prevStep() {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().agency.club.onboarding;

            let step = substate.currentStep - 1;
            if (substate.currentStep <= 1) step = substate.currentStep;

            dispatch(stateController.setState({ currentStep: step }));
        }
    }

    public static toggleTerms() {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().agency.club.onboarding;
            dispatch(stateController.setState({ termsAgreed: !substate.termsAgreed }));
        }
    }

    public static setIviteAgentsCheckboxes(checkboxes: InviteAgentsCheckboxes) {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().agency.club.onboarding;
            dispatch(stateController.setState({ inviteAgents: { ...checkboxes } }));
        }
    }
    public static addAgencies(agencyIds: Array<number>) {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().agency.club.onboarding;
            let newSelected = [...substate.inviteSpecificAgent.selectedAgencyIds, ...agencyIds]

            let newSelectedTree = substate.inviteSpecificAgent.allCountries.map(i => {
                return { ...i, name: i.name, agencies: i.agencies.filter(x => newSelected.some(k => k == x.id)) }
            });
            newSelectedTree = newSelectedTree.filter(i => i.agencies.length > 0);

            let newAvailableTree = substate.inviteSpecificAgent.filtered.map(i => {
                return { ...i, name: i.name, agencies: i.agencies.filter(x => !newSelected.some(k => k == x.id)) }
            });
            newAvailableTree = newAvailableTree.filter(i => i.agencies.length > 0);

            dispatch(stateController.setState({
                inviteSpecificAgent: {
                    ...substate.inviteSpecificAgent,
                    selectedAgencyIds: newSelected,
                    selectedTree: newSelectedTree,
                    availableTree: newAvailableTree
                }
            }));
        }
    }
    public static removeAgencies(agencyIds: Array<number>) {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().agency.club.onboarding;
            let newSelected = substate.inviteSpecificAgent.selectedAgencyIds.filter(i => !agencyIds.some(x => x == i));

            let newSelectedTree = substate.inviteSpecificAgent.allCountries.map(i => {
                return { ...i, name: i.name, agencies: i.agencies.filter(x => newSelected.some(k => k == x.id)) }
            });
            newSelectedTree = newSelectedTree.filter(i => i.agencies.length > 0);

            let newAvailableTree = substate.inviteSpecificAgent.filtered.map(i => {
                return { ...i, name: i.name, agencies: i.agencies.filter(x => !newSelected.some(k => k == x.id)) }
            });
            newAvailableTree = newAvailableTree.filter(i => i.agencies.length > 0);

            dispatch(stateController.setState({
                inviteSpecificAgent: {
                    ...substate.inviteSpecificAgent,
                    selectedAgencyIds: newSelected,
                    selectedTree: newSelectedTree,
                    availableTree: newAvailableTree
                }
            }));
        }
    }
    public static changePositionsSelection(positionCode: string) {
        let defaultAdState: AgencyCreateAdModel = { toLoan: false, toBuy: false, age: null, height: null }
        return (dispatch, getState: () => AppState) => {
            const positions = getState().agency.club.onboarding.positions;
            if (Object.keys(positions).includes(positionCode)) {
                delete positions[positionCode]
            } else {
                positions[positionCode] = defaultAdState
            }
            dispatch(stateController.setState({ positions: { ...positions }, allPositions: [...Object.keys(positions)] }))
        }
    }

    public static goToPrevPosition() {
        return (dispatch, getState: () => AppState) => {
            let substate = getState().agency.club.onboarding;
            let currentPositionIndex = substate.currentPositionIndex;

            if (currentPositionIndex == 0) dispatch(this.prevStep())
            else dispatch(stateController.setState({ currentPositionIndex: currentPositionIndex - 1 }))
        }
    }

    public static goToNextPosition(redirect: () => void) {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let currency = state.auth.currency.id;
            let subState = state.agency.club.onboarding;
            let currentPositionIndex = subState.currentPositionIndex;
            var currentPositionCode = Object.keys(subState.positions)[subState.currentPositionIndex];

            if (currentPositionIndex === Object.keys(subState.positions).length - 1) {
                let request: Array<CreatePlayerAdRequest> = new Array<CreatePlayerAdRequest>();
                for (var key of Object.keys(subState.positions)) {
                    let current = subState.positions[key];
                    if (current.toBuy) {
                        request.push({
                            toAge: current.age ? current.age.max : null, fromAge: current.age ? current.age.min : null, minHeight: current.height, type: 1,
                            amount: current.buy, positionId: getPositionIdByCode(key), currencyId: currency, grossSalary: current.grossSalary
                        })
                    }
                    if (current.toLoan) {
                        request.push({
                            toAge: current.age ? current.age.max : null, fromAge: current.age ? current.age.min : null, minHeight: current.height, type: 2,
                            amount: current.loan, positionId: getPositionIdByCode(key), currencyId: currency
                        })
                    }
                }

                dispatch(stateController.setState({ requesProccessing: true }))
                if (request.length > 0) {
                    await Service.createAds({ invitationGuid: subState.invitationGuid, playerAds: request });

                    const positions = Object.keys(subState.positions);
                    let positionsText = '';

                    positions.map((positionCode, index) => {

                        let positionValueText = '';

                        const item = subState.positions[positionCode]
                        if (item.toBuy) {
                            positionValueText += 'To buy';
                        }
                        if (item.toLoan) {
                            if (positionValueText.length > 0) {
                                positionValueText += ', '
                            }
                            positionValueText += 'To loan';
                        }

                        let finalText = `${positionCode}(${positionValueText})`;
                        positionsText += `${finalText}`;

                        const isLastPosition = Object.keys(positions).length - 1 === index
                        positionsText += isLastPosition ? '.' : ', '
                    });

                    if (positionsText) {
                        dispatch(userActivityInsert({
                            Message: `Launched Player Ad: ${positionsText}`,
                            PageName: 'Club Agents [Onboarding - Ad creation]',
                            PageType: PageType.Agents,
                            ActionType: ActionType.LaunchedPlayerAd,
                        }));
                    }

                }
                dispatch(stateController.setState({ requesProccessing: false }))

                redirect();

            }
            else {
                dispatch(stateController.setState({ currentPositionIndex: currentPositionIndex + 1 }))
            }
        }
    }

    public static onHeightPropertyChanged(position: string, val: number) {
        return (dispatch, getState: () => AppState) => {
            let positions = getState().agency.club.onboarding.positions;
            let changedPositions = { ...positions };
            let currentEntity = changedPositions[position];

            changedPositions[position] = { ...currentEntity, height: val == heightRule.min ? null : val };
            dispatch(stateController.setState({ positions: changedPositions }));
        }
    }

    public static onAgePropertyChanged(position: string, min: number, max: number) {
        return (dispatch, getState: () => AppState) => {
            let positions = getState().agency.club.onboarding.positions;
            let changedPositions = { ...positions };
            let currentEntity = changedPositions[position];

            let upd = (min == ageRule.min && max == ageRule.max) ? null : { min: min, max: max };
            changedPositions[position] = { ...currentEntity, age: upd };
            dispatch(stateController.setState({ positions: changedPositions }));
        }
    }
    public static onAdPropertiesChange(position: string, toBuy: boolean, toLoan: boolean, loan?: number, buy?: number, grossSalary?: number) {
        return (dispatch, getState: () => AppState) => {
            let positions = getState().agency.club.onboarding.positions;
            let changedPositions = { ...positions };
            let currentEntity = changedPositions[position];

            changedPositions[position] = { ...currentEntity, toBuy: toBuy, toLoan: toLoan, loan: loan, buy: buy, grossSalary: grossSalary };
            dispatch(stateController.setState({ positions: changedPositions }));

        }
    }

    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState));
        }
    }

    public static loadAllAds() {
        return async (dispatch) => {
            dispatch(stateController.setState({loadingPlayerAds: true}))
            Actions.token = getCancelTokenSource();

            let myAdsItems = await PlayerAdV2Service.getAllAdsWithCounters(Actions.token.token);

            if (myAdsItems) {
                dispatch(stateController.setState({ playerAds: myAdsItems }));
            }
            
            dispatch(stateController.setState({loadingPlayerAds: false}))
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState): OnboardingState => state.agency.club.onboarding;
    public static getPlayerAds = (state: AppState) => Selectors.getRoot(state).playerAds     
    public static getPLayerAdsPositions = (state: AppState) => {
        const subState = Selectors.getPlayerAds(state)
        let playerAdsPositions = subState.map(x => x.ad.positionCode);        
        let uniquePlayerAdsPositions = playerAdsPositions.filter((element, index) => {
            return playerAdsPositions.indexOf(element) === index;
        });
        
        return uniquePlayerAdsPositions
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    OnboardingState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};



