import * as redux from 'redux';
import * as SearchAutosuggestController from 'pages/PlayerSearch-v3/agency/redux/autosuggest.controller';
import * as FilterController from 'pages/PlayerSearch-v3/agency/redux/filter.controller';
import * as Grid from 'pages/PlayerSearch-v3/agency/redux/grid.controller';
import * as UpsellBanner from 'pages/PlayerSearch-v3/agency/redux/upsell-banner.controller';
import * as PitchOpportunityModal from 'pages/PlayerSearch-v3/agency/redux/pitch-opportunity-modal.controller';
import * as PlayerMarketModal from 'pages/PlayerSearch-v3/agency/redux/player-market.controller';
import * as CompetitionListController from './competition-list.controller';

export class AgencySearchState {
    search: SearchAutosuggestController.State;
    filter: FilterController.State;
    grid: Grid.State;
    upsellBanner: UpsellBanner.State;
    pitchOpportunityModal: PitchOpportunityModal.State;
    playerMarketModal: PlayerMarketModal.State;
    competitionList: CompetitionListController.State;
}

export function combineReducers() {
    const AgencySearchReducers = {
        search: SearchAutosuggestController.Reducer,
        filter: FilterController.Reducer,
        grid: Grid.Reducer,
        upsellBanner: UpsellBanner.Reducer,
        pitchOpportunityModal: PitchOpportunityModal.Reducer,
        playerMarketModal: PlayerMarketModal.Reducer,
        competitionList: CompetitionListController.Reducer
    }

    return redux.combineReducers(AgencySearchReducers);
}