import axios from 'axios-config'
import { EventModel, SearchParameters } from 'api/admin/events/models'

export default class EventsService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    }

    public static async getActiveEvents() {
        return await axios.get<EventModel[]>(`api/AdminEvents/GetActiveEvents`,
            this.axiosConfig
        )
    }

    public static async getParticipants(eventId: number, pageNumber: number, recordsPerPage: number, keyword: string) {
        return await axios.post(`api/v2/AdminEventController/GetEventData/${eventId}`,
            {
                pageNum: pageNumber,
                itemsCount: recordsPerPage,
                name: keyword,
            }
        )
    }

    public static async getAttendees(searchParameters: SearchParameters) {
        return await axios.post(`api/AdminEvents/GetEventAttendees/`,
        {
            eventId: searchParameters.eventId,
            isCustomAttendee: searchParameters.customAttendee,
            sortOrder: searchParameters.sortOrder,
            isSortOrderAscending: searchParameters.isSortOrderAscending,
            page: searchParameters.page,
            recordsPerPage: searchParameters.recordsPerPage
        },
            this.axiosConfig
        )
    }

    public static async getAttendeesTransfersIn(summitId: number) {
        return await axios.get(`api/AdminEvents/GetEventAttendeeTransfersIn/${summitId}`,
            this.axiosConfig
        )
    }

    public static async getAttendeesTransfersOut(summitId: number) {
        return await axios.get(`api/AdminEvents/GetEventAttendeeTransfersOut/${summitId}`,
            this.axiosConfig
        )
    }

    public static async getAttendeesClubInvites(summitId: number) {
        return await axios.get(`api/AdminEvents/GetEventAttendeeInvitedClubs/${summitId}`,
            this.axiosConfig
        )
    }

    public static async getEventsPractical (searchParameters) {
        return await axios.post(`api/AdminSummit/Attendees/Tables`,
            {
                sortOrder: searchParameters.sortOrder,
                isSortOrderAscending: searchParameters.isSortOrderAscending,
                page: searchParameters.page,
                recordsPerPage: searchParameters.recordsPerPage,
                eventId: searchParameters.eventId,
            },
            this.axiosConfig
        )
    }

    public static async registerSquad(squadId: number, eventId: number) {
        return await axios.post(`api/Event/RegisterSquad`,
        {
            squadId,
            eventId
        },
            this.axiosConfig
        )
    }

    public static async unregisterSquad(squadId: number, eventId: number) {
        return await axios.post(`api/Event/UnregisterSquad`,
        {
            squadId,
            eventId
        },
            this.axiosConfig
        )
    }

    public static async clearRegisteredSquads(eventId: number) {
        return await axios.post(`api/Event/ClearSquads`,
        {
            eventId
        },
            this.axiosConfig
        )
    }

    public static async deleteAttendee(summitId: number) {
        return await axios.get(`api/AdminEvents/DeleteEventAttendee/${summitId}`,
            this.axiosConfig
        )
    }

    public static async setEventsPractical (eventItem) {
        return await axios.put(`api/AdminSummit/Attendees/Tables/${34}`,
            eventItem,
            this.axiosConfig
        )
    }
}