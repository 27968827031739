import {StateController} from 'utils/action-declaration'
import {AgencyEventRequest, EventModel} from "api/admin/events/models";
import {AppState} from "root.reducer";
import {
    AgencyPermissions,
    AgencyPermissions as AgencyPermissionsService
} from "api/agency-permissions/permissions.service";
import EventsService from 'api/admin/events/events.service';

class MainState {
    isEventLoading: boolean;
    events: EventModel[];
    selectedEvent: {
        name: string;
        id: number;
    };
    isLoading: boolean;
    eventRequests: AgencyEventRequest[];
}

const defaultState = {
    isEventLoading: false,
    events: [],
    selectedEvent: {
        name: '',
        id: null,
    },
    isLoading: false,
    eventRequests: [],
}

const stateController = new StateController<MainState>(
    'ADMIN_V2/EVENTS_AGENCY_REQUEST',
    defaultState
)

class Actions {
    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState));
        }
    }
    public static loadActiveEvents() {
        return async (dispatch) => {
            try {
                dispatch(stateController.setState({ isEventLoading: true }));

                const events = (await EventsService.getActiveEvents()).data;
                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    events,
                    selectedEvent: {
                        name: events[0].name,
                        id: events[0].id,
                    }
                })));
                dispatch(Actions.loadEventsData());
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isEventLoading: false }));
            }
        }
    }
    public static loadEventsData = () => {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isLoading: true }));
                const eventRequests = await AgencyPermissionsService.getPendingAgencyEventRequests(getState().admin.eventAgencyRequest.selectedEvent.id);
                dispatch(stateController.setState((prevState) => {
                    return({...prevState,
                        eventRequests,
                    })
                }))
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        }
    }

    public static setSelectedEvent = (id: number, name: string) => {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => {
                return({
                    ...prevState,
                    selectedEvent: {
                        id,
                        name,
                    }
                })
            }));
        }
    }

    public static applyAgency = (agencyId: number, eventId: number, isApplied: boolean) => {
        return async (dispatch, getState: () => AppState) => {
            try {
                await AgencyPermissions.applyAgencyRequest(eventId, agencyId, isApplied);
                dispatch(Actions.loadEventsData());

            } catch (err) {
                console.error(err);
            }
        }
    }
}

class Selectors {

}

const reducer = stateController.getReducer()

export {
    MainState as State,
    reducer as Reducer,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
}