export const copyToClipboard = async (text: string) => {
  if (navigator.clipboard) {
    try {
      await navigator.clipboard.writeText(text);
      return true;
    } catch (err) {
      console.error('Failed to copy text: ', err);
      // Fall back to older method below
    }
  }

  // For browsers that don't support navigator.clipboard
  const textarea = document.createElement('textarea');
  textarea.value = text;
  textarea.setAttribute('readonly', '');
  textarea.style.position = 'absolute';
  textarea.style.left = '-9999px';
  document.body.appendChild(textarea);
  textarea.select();
  try {
    const success = document.execCommand('copy');
    if (success) {
      return true;
    }
  } catch (err) {
    console.error('Failed to copy text: ', err);
  } finally {
    document.body.removeChild(textarea);
  }

  // If all else fails, prompt the user to copy the text manually
  prompt('Copy link: ', text);
};
