import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import _ from 'lodash';
import { FriendlySuccessStoryService } from 'api/admin/friendly-success-story/friendly-success-story.service';
import { Items, DataValue, StoryModel } from "api/admin/friendly-success-story/models";
import { getAuthClubId, getAuthSquadId } from 'store/auth/authReducer';
import moment from 'moment';

export class FriendlySuccessStoryState {
    isLoading: boolean;
    items: Items[];
    clubName: string;
    isFriendlySuccessStoryModalOpen: boolean;
    editingItemId: number;
    proofTypeSource: DataValue[];
    modalFriendly: ModalFriendlyState;
}

export class ModalFriendlyState {
    date: Date | string;
    host: DataValue;
    visitor: DataValue;
    type: number;
    firstProof: number;
    secondProof: number;
    notes: string;
    adInfo: string;
    keywordHost: string;
    keywordVisitor: string;
    autosuggestionsOptionsHost: any[];
    autosuggestionsOptionsVisitor: any[];
    hostProcessing: boolean;
    visitorProcessing: boolean;
}

export enum AutosuggestionTypeEnum {
    Host = 0,
    Visitor = 1,
};


const defaultState: FriendlySuccessStoryState = {
    isLoading: false,
    items: [],
    clubName: '',
    isFriendlySuccessStoryModalOpen: false,
    editingItemId: null,
    proofTypeSource: null,
    modalFriendly: {
        date: '',
        host: null,
        visitor: null,
        type: null,
        firstProof: null,
        secondProof: null,
        notes: '',
        adInfo: '',
        keywordHost: '',
        keywordVisitor: '',
        autosuggestionsOptionsHost: [],
        autosuggestionsOptionsVisitor: [],
        hostProcessing: false,
        visitorProcessing: false,
    },
}

const stateController = new StateController<FriendlySuccessStoryState>(
    'ADMIN_V2/FRIENDLY-SUCCESS-STORY',
    defaultState
);

class Actions {

    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState))
        }
    }

    public static getAll(userId: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isLoading: true }));

                const data = await FriendlySuccessStoryService.getAll(userId);

                dispatch(stateController.setState({ items: data.items }));
                dispatch(stateController.setState({
                    proofTypeSource: [
                        { key: 0, value: '' },
                        ...data.proofTypeSource,
                    ]
                }));

            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        }
    }

    public static updateStory(userId: number, storyModel: StoryModel) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const editingItemId = getState().admin.friendlySuccessStory.editingItemId;

                dispatch(stateController.setState({ isLoading: true }));

                await FriendlySuccessStoryService.updateStory(userId, editingItemId, storyModel);
                await dispatch(Actions.getAll(userId))
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        }
    }

    public static createStory(userId: number, storyModel: StoryModel) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isLoading: true }));

                await FriendlySuccessStoryService.createStory(userId, storyModel);
                await dispatch(Actions.getAll(userId))
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        }
    }

    public static removeStory(userId: number, id: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isLoading: true }));

                await FriendlySuccessStoryService.deleteStory(userId, id);
                await dispatch(Actions.getAll(userId))
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        }
    }

    public static onChangeClubName(value: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ clubName: value }))
        }
    }

    public static friendlySuccessStoryModalToggle() {
        return (dispatch, getState: () => AppState) => {
            const substate = getState().admin.friendlySuccessStory;
            dispatch(stateController.setState({ isFriendlySuccessStoryModalOpen: !substate.isFriendlySuccessStoryModalOpen }));
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                modalFriendly: {
                    ...prevState.modalFriendly,
                    autosuggestionsOptionsHost: [],
                    autosuggestionsOptionsVisitor: [],
                }
            })));
        }
    }

    public static selectDate(value: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                modalFriendly: {
                    ...prevState.modalFriendly,
                    date: value,
                }
            })));
        }
    }

    public static selectType(value: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                modalFriendly: {
                    ...prevState.modalFriendly,
                    type: value,
                }
            })));
        }
    }

    public static selectFirstProof(value: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                modalFriendly: {
                    ...prevState.modalFriendly,
                    firstProof: value,
                }
            })));
        }
    }

    public static selectSecondProof(value: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                modalFriendly: {
                    ...prevState.modalFriendly,
                    secondProof: value,
                }
            })));
        }
    }

    public static setNotes(value: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                modalFriendly: {
                    ...prevState.modalFriendly,
                    notes: value,
                }
            })));
        }
    }

    public static setAdInfo(value: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                modalFriendly: {
                    ...prevState.modalFriendly,
                    adInfo: value,
                }
            })));
        }
    }

    public static setEditingItem(id: number) {
        return (dispatch, getState: () => AppState) => {
            const editingItem = getState().admin.friendlySuccessStory.items.find(item => item.id === id);

            dispatch(stateController.setState({ editingItemId: id }));

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                modalFriendly: {
                    ...prevState.modalFriendly,
                    date: id ? editingItem?.date : moment(),
                    host: { key: editingItem?.host.key, value: editingItem?.host.value, },
                    visitor: { key: editingItem?.visitor.key, value: editingItem?.visitor.value, },
                    keywordHost: editingItem?.host.value,
                    keywordVisitor: editingItem?.visitor.value,
                    type: editingItem?.type,
                    firstProof: editingItem?.firstProof,
                    secondProof: editingItem?.secondProof || null,
                    notes: editingItem?.notes || '',
                    adInfo: editingItem?.adInfo || '',
                }
            })));
        }
    }

    public static getClubAutosuggestionsHost(keyword: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const clubId = getAuthClubId(getState());
                const squadId = getAuthSquadId(getState());
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    modalFriendly: {
                        ...prevState.modalFriendly,
                        hostProcessing: true,
                    }
                })));

                const autosuggestionsOptionsHost = await FriendlySuccessStoryService.loadSquadSuggestions(keyword, clubId, squadId, []);
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    modalFriendly: {
                        ...prevState.modalFriendly,
                        autosuggestionsOptionsHost: autosuggestionsOptionsHost,
                    }
                })));
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    modalFriendly: {
                        ...prevState.modalFriendly,
                        hostProcessing: false,
                    }
                })));
            }
        }
    }

    public static getClubAutosuggestionsVisitor(keyword: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const clubId = getAuthClubId(getState());
                const squadId = getAuthSquadId(getState());
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    modalFriendly: {
                        ...prevState.modalFriendly,
                        visitorProcessing: true,
                    }
                })));

                const autosuggestionsOptionsVisitor = await FriendlySuccessStoryService.loadSquadSuggestions(keyword, clubId, squadId, []);
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    modalFriendly: {
                        ...prevState.modalFriendly,
                        autosuggestionsOptionsVisitor: autosuggestionsOptionsVisitor,
                    }
                })));
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    modalFriendly: {
                        ...prevState.modalFriendly,
                        visitorProcessing: false,
                    }
                })));
            }
        }
    }

    private static loadFirstClubAutosuggestionsDebounceFuncHost = _.debounce((dispatch, keyword) => dispatch(Actions.getClubAutosuggestionsHost(keyword)), 1000)

    private static loadFirstClubAutosuggestionsDebounceFuncVisitor = _.debounce((dispatch, keyword) => dispatch(Actions.getClubAutosuggestionsVisitor(keyword)), 1000)

    public static selectVisitorClub(clubName: string, squadId: number,) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                modalFriendly: {
                    ...prevState.modalFriendly,
                    visitor: { key: squadId, value: clubName, },
                }
            })));

            dispatch(Actions.onChangeVisitorKeyword(clubName));
        }
    };

    public static onChangeVisitorKeyword(value: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                modalFriendly: {
                    ...prevState.modalFriendly,
                    keywordVisitor: value,
                }
            })));

            if (value) {
                Actions.loadFirstClubAutosuggestionsDebounceFuncVisitor(dispatch, value);
            } else {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    modalFriendly: {
                        ...prevState.modalFriendly,
                        autosuggestionsOptionsVisitor: [],
                    }
                })));
            }
        }
    }

    public static selectHostClub(clubName: string, squadId: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                modalFriendly: {
                    ...prevState.modalFriendly,
                    host: { key: squadId, value: clubName, },
                }
            })));

            dispatch(Actions.onChangeHostKeyword(clubName));
        }
    };

    public static onChangeHostKeyword(value: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                modalFriendly: {
                    ...prevState.modalFriendly,
                    keywordHost: value,
                }
            })));

            if (value) {
                Actions.loadFirstClubAutosuggestionsDebounceFuncHost(dispatch, value);
            } else {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    modalFriendly: {
                        ...prevState.modalFriendly,
                        autosuggestionsOptionsHost: [],
                    }
                })));
            }
        }
    }

    public static onClearAutosuggestions(autosuggestionType: number) {
        return (dispatch) => {
            if (autosuggestionType === 0) {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    modalFriendly: {
                        ...prevState.modalFriendly,
                        host: null,
                    }
                })));
            } else {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    modalFriendly: {
                        ...prevState.modalFriendly,
                        visitor: null,
                    }
                })));
            }
        }
    }

}

class Selectors {
    public static selectState = (state: AppState) => state.admin.friendlySuccessStory;
    public static getFilteredFriendlyMatches(state: AppState) {
        const clubName = Selectors.selectState(state).clubName;
        return Selectors.selectState(state).items
            .filter(item => !clubName.length || (item.host.value.toLowerCase().includes(clubName.toLowerCase()) || item.visitor.value.toLowerCase().includes(clubName.toLowerCase())));
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    FriendlySuccessStoryState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};



