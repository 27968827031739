import React from 'react'
import Panel from 'components/Panel';
import { EventTitle } from '../past-event-title'
import { PastEventStats } from '../past-event-stats'
import { PastEventClubLogos } from '../past-event-club-logos'
import styled from 'styled-components'
import VictorOrta from 'media/images/events/2023-november-lisbon/victor-orta.png';
import PauloFonseca from 'media/images/events/2023-november-lisbon/paulo-fonseca.png';

const LisbonSummitNovember2023 = () => {
    return (
        <div className="row">
            <div className="col-md-8 col-12">
                <Panel className="content-wrapper">
                    <EventTitle
                        number="12"
                        bgImageNumber={4}
                        th="th"
                        title1="TransferRoom"
                        title2="Summit"
                        date="13th-15th NOV 2023, Lisbon + Online"
                        vs={true}
                    />
                    <SpeakersSection>
                        <h2>Speakers</h2>
                        <div className="speaker-card-container">
                            <div className="speaker-card">
                                <div className="speaker-image">
                                    <div style={{ backgroundImage: `url('${VictorOrta}')` }}></div>
                                </div>
                                <div className="speaker-info">
                                    <div className="speaker-info__name">Victor Orta</div>
                                    <div className="speaker-info__position">Sporting Director</div>
                                    <div className="speaker-info__club">Sevilla FC</div>
                                </div>
                            </div>
                            <div className="speaker-card">
                                <div className="speaker-image">
                                    <div style={{ backgroundImage: `url('${PauloFonseca}')` }}></div>
                                </div>
                                <div className="speaker-info">
                                    <div className="speaker-info__name">Paulo Fonseca</div>
                                    <div className="speaker-info__position">Head Coach</div>
                                    <div className="speaker-info__club">LOSC Lille</div>
                                </div>
                            </div>
                        </div>
                    </SpeakersSection>
                </Panel>
            </div>
            <div className="col-md-4 col-12 row no-gutters">
                <div className="col-md-12 col-sm-6 col-12">
                    <PastEventStats
                        participants="726"
                        countries="59"
                        leagues="92"
                        agents="121"
                    />
                </div>
                <div className="col-md-12 col-sm-6 col-12">
                    <PastEventClubLogos
                        attendees={previousEventAttendees}
                        countlimit={48}
                    />
                </div>
            </div>
        </div>
    )
}

const SpeakersSection = styled.div`
    padding: 15px 0px 45px 0px;
    @media (max-width: 768px) {
        padding: unset;   
    }
    h2 {
        font-size: 21px;
        line-height: 34px;
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .speaker-card-container {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

        .speaker-card {
            width: 250px;
            margin-bottom: 10px;

            .speaker-image {
                display: flex;
                justify-content: center;
        
                & > div{
                    width: 128px;
                    height: 128px;
                    border-radius: 100%;
                    background-position: center;
                    background-size: cover;
                }
            }
        
            .speaker-info {
                margin-top: 15px;
                font-size: 16px;
                line-height: 19px;
                text-align: center;
        
                &__name {
                    font-weight: bold;
                }
        
                &__position {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                &__club {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }

        }
    }
`;

export const previousEventAttendees = [
    { id: 1, name: "AC Milan", logo: "logo_1834_AC_Milan.png" },
    { id: 2, name: "AS Roma", logo: "logo_1835_AS_Roma.png" },
    { id: 3, name: "Juventus FC", logo: "logo_1832_Juventus_FC.png" },
    { id: 17, name: "Genoa CFC", logo: "logo_1843_Genoa_CFC.png" },
    { id: 45, name: "Manchester United", logo: null }, //"logo_1120_Manchester_United.png"
    { id: 46, name: "Liverpool FC", logo: "logo_1123_Liverpool_FC.png" },
    { id: 47, name: "Tottenham Hotspur", logo: "logo_1122_Tottenham_Hotspur.png" },
    { id: 48, name: "Manchester City", logo: "1625_Manchester_City.png" },
    { id: 49, name: "Arsenal FC", logo: "logo_1121_Arsenal_FC.png" },
    { id: 50, name: "Chelsea FC", logo: "logo_Chelsea_FC.png" },
    { id: 60, name: "Newcastle United", logo: "logo_Newcastle_United.png" },
    { id: 63, name: "Everton FC", logo: "logo_1124_Everton_FC.png" },
    { id: 64, name: "Crystal Palace", logo: "logo_1128_Crystal_Palace.png" },
    { id: 65, name: "Wolverhampton Wanderers", logo: "logo_Wolverhampton_Wanderers.png" },
    { id: 67, name: "West Ham United", logo: "1633_West_Ham_United.png" },
    { id: 70, name: "Brighton & Hove Albion", logo: "logo_Brighton_And_Hove_Albion_FC.png" },
    { id: 71, name: "AFC Bournemouth", logo: "logo_1134_AFC_Bournemouth.png" },
    { id: 416, name: "Al-Fateh SC", logo: "16483_Al-Fateh_SC.png" },
    { id: 976, name: "AS Monaco", logo: "19830_AS_Monaco.png" },
    { id: 1421, name: "Gremio Foot-Ball Porto Alegrense", logo: "logo_2605_Gremio_Foot-Ball_Porto_Alegrense.png" },
    { id: 2226, name: "Sevilla FC", logo: "680_Sevilla_FC.png" },
    { id: 3476, name: "Sporting CP", logo: "9608_Sporting_CP.png" },
    { id: 4059, name: "OGC Nice", logo: "logo_1965_OGC_Nice.png" },
    { id: 5282, name: "PSV Eindhoven", logo: "11_PSV_Eindhoven.png" },
    { id: 8239, name: "Brentford FC", logo: "logo_1153_Brentford_FC.png" },
    { id: 8886, name: "Jeonbuk Hyundai Motors", logo: "logo_2425_Jeonbuk_Hyundai_Motors_FC.png" },
    { id: 10026, name: "Los Angeles Galaxy", logo: "7847_Los_Angeles_Galaxy.png" },
    { id: 10088, name: "RB Leipzig", logo: "logo_1261_RB_Leipzig.png" },
    { id: 10422, name: "Vancouver Whitecaps FC", logo: "15015_Vancouver_Whitecaps_FC.png" },
    { id: 12598, name: "VfB Stuttgart", logo: "logo_1273_VfB_Stuttgart.png" },
    { id: 17788, name: "Paris Saint-Germain", logo: "3767_Paris_Saint-Germain.png" },
    { id: 18960, name: "FC Porto", logo: "logo_1672_FC_Porto.png" },
    { id: 23647, name: "Atletico de San Luis", logo: "25866_Atlético_de_San_Luis.png" },
    { id: 24711, name: "Urawa Red Diamonds", logo: "logo_2407_Urawa_Red_Diamonds.png" },
    { id: 25037, name: "Montevideo City Torque", logo: "23497_Montevideo_City_Torque.png" },
    { id: 26699, name: "Galatasaray", logo: "logo_2003_Galatasaray_SK.png" },
    { id: 27251, name: "CR Flamengo", logo: "5654_Clube_de_Regatas_do_Flamengo.png" },
    { id: 28879, name: "Athletic Bilbao", logo: "678_Athletic_Bilbao.png" },
    { id: 29065, name: "Club Sporting Cristal", logo: "15532_Club_Sporting_Cristal.png" },
    { id: 29455, name: "Nottingham Forest", logo: "logo_1152_Nottingham_Forest.png" },
    { id: 29747, name: "Ajax Amsterdam", logo: "9_Ajax_Amsterdam.png" },
    { id: 30723, name: "Borussia Dortmund", logo: "logo_1259_Borussia_Dortmund.png" },
    { id: 30849, name: "Atlanta United FC", logo: "logo_2103_Atlanta_United_FC.png" },
    { id: 30997, name: "Red Bull Salzburg", logo: "logo_1942_Red_Bull_Salzburg.png" },
    { id: 35839, name: "Sociedade Esportiva Palmeiras", logo: "5646_Sociedade_Esportiva_Palmeiras.png" },
    { id: 36192, name: "Tigres UANL", logo: "15417_Tigres_UANL.png" },
    { id: 37475, name: "Mamelodi Sundowns FC", logo: "16344_Mamelodi_Sundowns_FC.png" },
    { id: 38553, name: "Aston Villa", logo: "logo_1138_Aston_Villa.png" },
]

export default LisbonSummitNovember2023