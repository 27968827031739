import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import { PlayerPitchOpportunity, PlayerAdModel } from 'api/agency/agent/landing/agency-dashboard'
import AgencyHomePageService from 'api/agency/agent/landing/agency-homepage.service'
import PitchService from "api/agency/agent/pitch/pitch.service";
import {validateFee} from 'utils/number-utils';
import { GridActivityService } from './grid-activity.service'
import { PitchActivityService } from './pitch-opportunity.activity'

import * as Grid from './grid.controller'
import { DisplayStatus } from 'api/search/model/search-player-item-for-agency';

class PitchOpportunityState {
    playerId?: number;
    isLoading: boolean;
    playerOpportunities: PlayerPitchOpportunity;
    sentAdIds: Array<number>;
    processingNotInterestIds: Array<number>
    modal: PitchModalState;
}

export class PitchModalState {
    ad: PlayerAdModel;
    grossSalary: string;
    message: string;
    playerName: string;
    clubName: string;
    processing: boolean;
    playerId: number;
}

const defaultState: PitchOpportunityState = {
    playerId: null,
    isLoading: false,
    playerOpportunities: null,
    sentAdIds: [],
    processingNotInterestIds: [],
    modal: null
}

const stateController = new StateController<PitchOpportunityState>(
    'SEARCH/PITCH_OPPORTUNITY_MODAL',
    defaultState
)


class Actions {

    public static initSession = (playerId: number, isCustomUserActivity?: boolean) => {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ playerId: playerId, isLoading: true }));
            let opportunities = await AgencyHomePageService.getPitchOpportunitiesByPlayerId(playerId);
            const p = Grid.Actions.getPlayer(getState(), playerId)
            if (!isCustomUserActivity) {
                dispatch(GridActivityService.pitch(
                    playerId,
                    p.parentSquad ? p.parentSquad.id : null,
                    Grid.Selectors.getGridState(getState())
                ))
            }
            dispatch(stateController.setState({ playerOpportunities: opportunities, isLoading: false }));
        }
    }


    public static setFee(fee: any) {
        return (dispatch, getState: () => AppState) => {
            let subState = getState().agency.playerSearch.pitchOpportunityModal;
            const formatted = validateFee(fee);

            dispatch(stateController.setState({ modal: { ...subState.modal, grossSalary: formatted } }))
        }
    }
    public static setMessage(message: string) {
        return (dispatch, getState: () => AppState) => {
            let subState = getState().agency.playerSearch.pitchOpportunityModal;
            dispatch(stateController.setState({ modal: { ...subState.modal, message: message } }))
        }
    }

    public static finishPitch() {
        return async (dispatch, getState: () => AppState) => {
            let subState = getState().agency.playerSearch.pitchOpportunityModal;

            let ad = subState.modal.ad;
            const playerId = subState.playerOpportunities.playerId;
            const grossSalary = parseInt(subState.modal.grossSalary.replace(/\D/g, ''))

            dispatch(stateController.setState({ modal: { ...subState.modal, processing: true } }))

            await PitchService.pitch(
                ad.id,
                playerId,
                grossSalary,
                subState.modal.message
            );

            let sentAds = [...subState.sentAdIds, ad.id];

            const player = Grid.Actions.getPlayer(getState(), subState.playerId)
            const ads = subState.playerOpportunities.opportunityAds.find(x => x.id == ad.id)

            dispatch(PitchActivityService.sentPitch(
                player.id,
                subState.playerOpportunities.positionCode,
                ads,
                grossSalary,
                player.parentSquad ? player.parentSquad.id : null,
                Grid.Selectors.getGridState(getState())
            ))

            const opportunityAds = subState.playerOpportunities.opportunityAds.filter(x => !sentAds.includes(x.id))
            dispatch(
                Grid.Actions.updatePlayer(subState.playerId,
                    {
                        ...player,
                        agencyPlayerDisplayInfo:
                        {
                            ...player.agencyPlayerDisplayInfo,
                            pitchOpportunityCount: opportunityAds.length,
                            displayStatus: opportunityAds.length === 0
                                ? DisplayStatus.ButtonViewPlayerProfile
                                : player.agencyPlayerDisplayInfo.displayStatus,
                        }
                    })
            );

            dispatch(stateController.setState({ sentAdIds: sentAds, modal: null }));
        }
    }

    public static openPitchForm(adId: number) {
        return async (dispatch, getState: () => AppState) => {
            let subState = getState().agency.playerSearch.pitchOpportunityModal;

            let ad = subState.playerOpportunities.opportunityAds.find(x => x.id == adId);
            let reccomendedFee = subState.playerOpportunities.recomendedAdFee[ad.id];
            const player = Grid.Actions.getPlayer(getState(), subState.playerId)

            dispatch(stateController.setState({
                modal: {
                    ad: ad,
                    grossSalary: reccomendedFee.toString(),
                    message: "",
                    playerName: subState.playerOpportunities.playerName,
                    clubName: subState.playerOpportunities.playerSquad.name,
                    playerId: subState.playerId,
                    processing: false
                }
            }))

            dispatch(PitchActivityService.makePitch(
                subState.playerOpportunities.playerId,
                subState.playerOpportunities.positionCode,
                ad,
                subState.playerOpportunities.recomendedAdFee[ad.id],
                player.parentSquad ? player.parentSquad.id : null,
                Grid.Selectors.getGridState(getState())
            ))
        }

    }

    public static notInterest(adId: number) {
        return async (dispatch, getState: () => AppState) => {
            let subState = getState().agency.playerSearch.pitchOpportunityModal;

            dispatch(stateController.setState({ processingNotInterestIds: [...subState.processingNotInterestIds, adId] }))

            await PitchService.notInterestedv2(adId, subState.playerId);

            const player = Grid.Actions.getPlayer(getState(), subState.playerId)
            const ads = subState.playerOpportunities.opportunityAds.find(x => x.id == adId)

            dispatch(PitchActivityService.notInterest(
                player.id,
                subState.playerOpportunities.positionCode,
                ads,
                subState.playerOpportunities.recomendedAdFee[ads.id],
                player.parentSquad ? player.parentSquad.id : null,
                Grid.Selectors.getGridState(getState())
            ))

            let opportinity: PlayerPitchOpportunity = {
                ...subState.playerOpportunities,
                opportunityAds: [...subState.playerOpportunities.opportunityAds.filter(x => x.id != adId)]
            }

            dispatch(
                stateController.setState(
                    {
                        processingNotInterestIds: [...subState.processingNotInterestIds.filter(x => x != adId)],
                        playerOpportunities: opportinity
                    }
                )
            )

            const { sentAdIds } = subState;
            const opportunityAds = opportinity.opportunityAds.filter(x => !sentAdIds.includes(x.id));
            dispatch(
                Grid.Actions.updatePlayer(subState.playerId,
                    {
                        ...player,
                        agencyPlayerDisplayInfo: {
                            ...player.agencyPlayerDisplayInfo,
                            pitchOpportunityCount: opportunityAds.length,
                            displayStatus: opportunityAds.length === 0
                                ? DisplayStatus.ButtonViewPlayerProfile
                                : player.agencyPlayerDisplayInfo.displayStatus,
                        }
                    })
            );
        }
    }

    public static dispose = () => {
        return async (dispatch, getState: () => AppState) => {
            let subState = getState().agency.playerSearch.pitchOpportunityModal;
            const player = Grid.Actions.getPlayer(getState(), subState.playerId);

            dispatch(PitchActivityService.done(
                subState.playerOpportunities.playerId,
                player.parentSquad ? player.parentSquad.id : null,
                Grid.Selectors.getGridState(getState())
            ))
            dispatch(stateController.setState({ ...defaultState }));
        }
    }

    public static closePitchForm = () => {
        return async (dispatch, getState: () => AppState) => {
            let subState = getState().agency.playerSearch.pitchOpportunityModal;
            let ad = subState.playerOpportunities.opportunityAds.find(x => x.id == subState.modal.ad.id);
            const grossSalary = parseInt(subState.modal.grossSalary.replace(/\D/g, ''))
            const player = Grid.Actions.getPlayer(getState(), subState.playerId);

            if (player && ad) {
                dispatch(PitchActivityService.cancelledPitch(
                    subState.playerOpportunities.playerId,
                    subState.playerOpportunities.positionCode,
                    ad,
                    grossSalary,
                    player.parentSquad ? player.parentSquad.id : null,
                    Grid.Selectors.getGridState(getState())
                ))
            }

            dispatch(stateController.setState({ modal: null }));
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    PitchOpportunityState as State,
    Actions as Actions,
    stateController as Controller
};