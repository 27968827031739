import { StateController } from 'utils/action-declaration';
import {
    SUPER_PITCH_CREATE,
    SUPER_PITCH_FLOW_STEP1_SELECT_PLAYER,
    SUPER_PITCH_FLOW_STEP2_SET_PRICE,
    SUPER_PITCH_FLOW_STEP3_RECIPIENTS,
    SUPER_PITCH_FLOW_STEP4_MESSAGE,
    SUPER_PITCH_FLOW_STEP5_VIDEO,
    SUPER_PITCH_FLOW_STEP6_COMPARISON,
    SUPER_PITCH_FLOW_STEP7_RSVP,
    SUPER_PITCH_FLOW_PREVIEW
} from 'constants/modals';
import {
    MODAL_OPEN,
    MODAL_CLOSE
} from 'store/actionTypes';
import { AppState } from 'root.reducer'
import { SuperPitchService } from 'api/super-pitch/super-pitch'
import {
    getAuth,
} from 'store/auth/authReducer';
import { loadUserPreferenceInfo } from 'store/userPreference/userPreference.service';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import * as SuperPitchController from 'pages/Pitch/tab-super-pitch/store/common.controller';
import { Comparisonitem, ComparisonRequest, ComparisonChangeItem } from 'api/super-pitch/models/comparison'
import { createSelector } from 'reselect';
import { PageType } from 'constants/enums';
import { getClubUserPermissions } from 'store/userPreference/userPreference.reducer';
import { Selectors as MySquadFilterSelectors } from 'pages/my-squad-v2/redux/my-squad-filter.controller';
import { PlayerModel } from 'store/entities/players/player.model';

export class SetVideoModel {
    selectedVideo: number | null;
    isActive: boolean;
    additionalVideo: string;
    addVideoToProfile: boolean
}

class CreateSuperPitchState {
    step1: {
        player: any
    };
    step2: {
        forSale: number | null,
        sellOn: number | null,
        isSaleToBeDiscussed: boolean,
        forLoan: number | null,
        isLoanToBeDiscussed: boolean
    };
    step3: Array<number>;
    step4: {
        wasSaved: boolean;
        message: string;
        isActive: boolean
    };
    step5: {
        wasSaved: boolean,
        selectedVideo: number | null;
        isActive: boolean;
        playerVideos: Array<string>;
        additionalVideo: string;
        addVideoToProfile: boolean
    };
    step6: {
        wasInitiallyLoaded: boolean;
        wasSaved: boolean;
        isLoading: boolean;
        isActive: boolean;
        keys: {
            firstKey: string,
            secondKey: string,
            thirdKey: string,
        },
        characteristicSource: Array<{
            'key': string;
            'value': string
        }>,
        items: { [key: string]: Array<Comparisonitem> } | null;
        isPlayerSearching: boolean;
        playerSearchResult: Array<Comparisonitem> | [],

        changeItems: { [key: string]: boolean }
    }
    step7: {
        wasSaved: boolean;
        isActive: boolean;
        expirationDate: Date | null;
    };
    squads: Array<{}>;
    players: PlayerModel[];
    sessionCompleted: boolean;
    isLoading: boolean;
    isCreatePitchLikeThisLoading: Array<number>;
    isSaving: boolean;
}

const defaultState: CreateSuperPitchState = {
    step1: { player: null },
    step2: {
        forSale: null,
        sellOn: null,
        isSaleToBeDiscussed: false,
        forLoan: null,
        isLoanToBeDiscussed: false
    },
    step3: [],
    step4: {
        wasSaved: false,
        message: '',
        isActive: false
    },
    step5: {
        wasSaved: false,
        selectedVideo: null,
        isActive: false,
        playerVideos: [],
        additionalVideo: '',
        addVideoToProfile: false
    },
    step6: {
        wasInitiallyLoaded: false,
        wasSaved: false,
        isLoading: false,
        isActive: false,
        keys: {
            firstKey: '',
            secondKey: '',
            thirdKey: '',
        },
        characteristicSource: [],
        items: null,
        isPlayerSearching: false,
        playerSearchResult: [],
        changeItems: {}
    },
    step7: {
        wasSaved: false,
        isActive: false,
        expirationDate: null
    },
    squads: [],
    players: [],
    sessionCompleted: false,
    isLoading: false,
    isCreatePitchLikeThisLoading: [],
    isSaving: false,
}

const stateController = new StateController<CreateSuperPitchState>(
    "SUPER_PITCH/CREATE_FLOW",
    defaultState
)

class Actions {

    // General methods
    // --------------------------
    public static initDataForSuperPitchCretion = () => {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();

            dispatch(stateController.setState({ isLoading: true }))

            try {
                const playersRequest = MySquadFilterSelectors.getMySquadFetchRequest(state);
                const {
                    clubId,
                    squadId,
                    userId,
                    criteria
                } = playersRequest;

                const defaultPagination = {
                    currentPageNumber: 1,
                    pageSize: 10000,
                };
                const payload = { ...criteria, ...defaultPagination };
                const playersData = await SuperPitchService.loadMySquadPlayers(clubId, userId, squadId, payload);
                dispatch(stateController.setState({ players: playersData.output }))
                await dispatch(loadUserPreferenceInfo());
                const data = await SuperPitchService.activeSquadsFetch()

                dispatch(stateController.setState({ squads: data }))
                dispatch(stateController.setState({ isLoading: false }))
                return
            } catch (e) {
                console.error(e)
                dispatch(stateController.setState(defaultState))
            }
        };
    };



    public static initCreatePitchLikeThis = (pitchId) => {
        return async (dispatch, getState: () => AppState) => {
            const objective = getState().superPitch.common.pitches.byId[pitchId]
            const substate = getState().superPitch.createSuperPitch2

            try {
                dispatch(stateController.setState({
                    isCreatePitchLikeThisLoading: [...substate.isCreatePitchLikeThisLoading, pitchId]
                }))

                await dispatch(Actions.initDataForSuperPitchCretion())

                const playerId = objective.playerInfo.id
                dispatch(Actions.selectPlayer(playerId))

                if (objective.isComparisonKeysVisibleOnPitch) {
                    await dispatch(Actions.loadComparisonData())
                    await dispatch(Actions.loadComparisonDataOnSelect(objective.comparisonKeys, pitchId))
                    dispatch(Actions.setComparison(true))
                }

                const buyPrice = objective.buy === null ? {
                    forSale: null,
                    sellOn: null,
                    isSaleToBeDiscussed: false
                } : {
                        forSale: objective.buy.price === null ? null : objective.buy.price,
                        sellOn: objective.buy.sellOn === null ? null : objective.buy.sellOn,
                        isSaleToBeDiscussed: objective.buy.toBeDiscussed
                    }
                const loanPrice = objective.loan === null ? {
                    forLoan: null,
                    isLoanToBeDiscussed: false
                } : {
                        forLoan: objective.loan.price === null ? null : objective.loan.price,
                        isLoanToBeDiscussed: objective.loan.toBeDiscussed
                    }
                dispatch(Actions.setPrice(
                    buyPrice.forSale,
                    buyPrice.sellOn,
                    buyPrice.isSaleToBeDiscussed,
                    loanPrice.forLoan,
                    loanPrice.isLoanToBeDiscussed
                ))

                const recipients = objective.recipientsSquadIdList
                dispatch(Actions.selectClub(recipients))

                if (objective.isMessageVisibleOnPitch) {
                    const message = {
                        isActive: objective.isMessageVisibleOnPitch,
                        text: objective.message ? objective.message : ''
                    }
                    dispatch(Actions.setMessage({ isActive: message.isActive, message: message.text }))
                }

                if (objective.isVideoVisibleOnPitch) {
                    const selectedVideoUrl = objective.videoUrl
                    const playerProfileVideos = substate.step5.playerVideos
                    const selectedIndex = playerProfileVideos.indexOf(selectedVideoUrl) >= 0 ? playerProfileVideos.indexOf(selectedVideoUrl) : -1
                    const additionalVideo = playerProfileVideos.indexOf(selectedVideoUrl) < 0 ? selectedVideoUrl : ''
                    const isActive = objective.isVideoVisibleOnPitch

                    const video: SetVideoModel = {
                        addVideoToProfile: false,
                        isActive: isActive,
                        selectedVideo: selectedIndex,
                        additionalVideo: additionalVideo
                    }
                    dispatch(Actions.setVideo(video))
                }

                if (objective.isRsvpVisibleOnPitch) {
                    dispatch(Actions.setExpirationDate(objective.rsvp, true))
                }

                dispatch(Actions.openSuperPitchCreateModal(false))
                dispatch(stateController.setState({
                    isCreatePitchLikeThisLoading: substate.isCreatePitchLikeThisLoading.filter(item => item !== pitchId)
                }))

            } catch (e) {
                dispatch(stateController.setState({
                    isCreatePitchLikeThisLoading: substate.isCreatePitchLikeThisLoading.filter(item => item !== pitchId)
                }))
                dispatch(Actions.disposeSuperPitchCreate())
            }
        }
    }
    public static clearPitchForm = () => {
        return (dispatch, getState: () => AppState) => {
            const substate = getState().superPitch.createSuperPitch2
            dispatch(stateController.setState({
                ...substate,
                step1: defaultState.step1,
                step2: defaultState.step2,
                step3: defaultState.step3
            }))
        };
    };
    public static processNewPitchCreate = () => {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isSaving: true }))

            const state = getState()

            let createSuperPitchState = state.superPitch.createSuperPitch2;

            try {
                const buySettings = {
                    price: createSuperPitchState.step2.forSale,
                    sellOn: createSuperPitchState.step2.sellOn,
                    toBeDiscussed: createSuperPitchState.step2.isSaleToBeDiscussed
                }
                const loanSettings = {
                    price: createSuperPitchState.step2.forLoan,
                    toBeDiscussed: createSuperPitchState.step2.isLoanToBeDiscussed
                }
                const comparisonKeys: Array<string> | null = createSuperPitchState.step6.wasSaved ? Object.values(createSuperPitchState.step6.keys) : null
                const video = !!Selectors.getSelectedVideoUrl(state) ? Selectors.getSelectedVideoUrl(state) : null

                const squadId = state.auth.squadId
                const userId = state.auth.userId

                let customizationComparision: { [key: string]: Array<{ comparisonPlayerIds: Array<number> }> } = {};

                let step6 = state.superPitch.createSuperPitch2.step6;
                if (step6.changeItems[step6.keys.firstKey] == true) {
                    customizationComparision[step6.keys.firstKey] = { comparisonPlayerIds: step6.items[step6.keys.firstKey].map(x => x.playerId) } as any;
                }
                if (step6.changeItems[step6.keys.secondKey] == true) {
                    customizationComparision[step6.keys.secondKey] = { comparisonPlayerIds: step6.items[step6.keys.secondKey].map(x => x.playerId) } as any;
                }
                if (step6.changeItems[step6.keys.thirdKey] == true) {
                    customizationComparision[step6.keys.thirdKey] = { comparisonPlayerIds: step6.items[step6.keys.thirdKey].map(x => x.playerId) } as any;
                }

                await SuperPitchService.createSuperPitch(squadId, userId, {
                    playerId: createSuperPitchState.step1.player.id,
                    buy: buySettings,
                    loan: loanSettings,
                    recipientsSquadIdList: createSuperPitchState.step3,
                    message: createSuperPitchState.step4.message,
                    video: video,
                    addVideoToProfile: createSuperPitchState.step5.addVideoToProfile,
                    comparisonKeys: comparisonKeys,
                    rsvp: createSuperPitchState.step7.expirationDate,
                    isComparisonKeysVisibleOnPitch: createSuperPitchState.step6.isActive,
                    isVideoVisibleOnPitch: createSuperPitchState.step5.isActive,
                    isMessageVisibleOnPitch: createSuperPitchState.step4.isActive,
                    isRsvpVisibleOnPitch: createSuperPitchState.step7.isActive,
                    comparisonCustomization: customizationComparision
                })
                await dispatch(loadUserPreferenceInfo());
                await dispatch(SuperPitchController.Actions.superPitchesFetch());

                // If this function process creation of the last plus pitch
                if (getClubUserPermissions(state)?.allowedSuperPitchesCount === 0) {
                    dispatch(Actions.closeSuperPitchCreateModal())
                } else {
                    dispatch(stateController.setState({
                        sessionCompleted: true,
                        isSaving: false
                    }))
                }

                let recipients = [];

                for (let i = 0; i < createSuperPitchState.squads.length; i++) {
                    const league = createSuperPitchState.squads[i] as any;
                    for (let j = 0; j < league.squads.length; j++) {
                        const squad = league.squads[j];
                        if (createSuperPitchState.step3.indexOf(squad.id) > -1) {
                            recipients.push(squad.name);
                        }
                    }
                }
                const recipientsText = recipients.join(', ');

                const player = createSuperPitchState.step1.player as any;

                let currency = getAuth(state).currency.name;
                const priceData = {
                    salePrice: createSuperPitchState.step2.forSale,
                    sellOn: createSuperPitchState.step2.sellOn,
                    isSaleToBeDiscussed: createSuperPitchState.step2.isSaleToBeDiscussed,
                    loanPrice: createSuperPitchState.step2.forLoan,
                    isLoanToBeDiscussed: createSuperPitchState.step2.isLoanToBeDiscussed,
                }

                const isSalePriceWasCustomized = priceData.salePrice !== null || priceData.isSaleToBeDiscussed
                const salePrice = priceData.salePrice ? `Sell: ${priceData.salePrice} ${currency}` : 'Sale to be discussed'

                const sellOnPrice = priceData.sellOn !== null && priceData.sellOn > 0 ? `Sell on: ${priceData.sellOn}%` : ''

                const isLoanPriceWasCustomized = priceData.loanPrice !== null || priceData.isLoanToBeDiscussed
                const loanPrice = priceData.loanPrice !== null ? `Loan: ${priceData.loanPrice} ${currency}` : 'Loan to be discussed'

                dispatch(
                    userActivityInsert({
                        Message: `Launched Super Pitch: ${isSalePriceWasCustomized ? (salePrice + ',') : ''} ${sellOnPrice + ','} ${isLoanPriceWasCustomized ? (loanPrice + ',') : ''} ${recipientsText}`,
                        PageName: 'Super Pitch',
                        PlayerId: player.id,
                        CurrentClubId: player.currentSquadId,
                        PageType: PageType.Pitch
                    }),
                );
            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState({ isSaving: false }))
            }
        };
    };
    public static disposeSuperPitchCreate = () => (dispatch, getState) => {
        const substate = getState().superPitch.createSuperPitch2
        return dispatch(stateController.setState({
            ...substate,
            sessionCompleted: defaultState.sessionCompleted,
            step1: defaultState.step1,
            step2: defaultState.step2,
            step3: defaultState.step3,
            step4: defaultState.step4,
            step5: defaultState.step5,
            step6: defaultState.step6,
            step7: defaultState.step7,

        }))
    };
    public static openSuperPitchCreateModal = (initOnModalMount: boolean = true) => {
        return (dispatch, getState) => {
            let command = {
                type: MODAL_OPEN,
                payload: {
                    id: SUPER_PITCH_CREATE,
                    className: 'spn-modal',
                    customToggle: () => (dispatch, getState) => {
                        dispatch(Actions.closeSuperPitchCreateModal())
                    },
                    content: {
                        initOnModalMount: initOnModalMount
                    }
                },
            };
            dispatch(command);
        };
    };

    public static closeSuperPitchCreateModal = () => {
        return async dispatch => {
            let command = { type: MODAL_CLOSE, payload: SUPER_PITCH_CREATE, };
            return await dispatch(command);
        };
    };

    // STEP 1 - Select player
    // ----------------------
    public static openSelectPlayerModal = () => {
        return (dispatch, getState) => {
            let command = {
                type: MODAL_OPEN,
                payload: { id: SUPER_PITCH_FLOW_STEP1_SELECT_PLAYER },
            };
            dispatch(command);
        };
    };
    public static selectPlayer = (id: number) => {
        return async (dispatch, getState: () => AppState) => {
            const substate = getState().superPitch.createSuperPitch2
            const player = substate.players.find(x => x.id === id);
            await dispatch(stateController.setState({
                step1: { player: player },
                step5: {
                    ...substate.step5,
                    playerVideos: [...player.playerVideos.map(x => x.url)],
                    wasSaved: false,
                    selectedVideo: null,
                    isActive: false,
                    additionalVideo: '',
                    addVideoToProfile: false
                },
                step6: defaultState.step6,
                step7: defaultState.step7
            }))
            dispatch(Actions.closeSelectPlayer());
            return
        };
    };
    public static closeSelectPlayer = () => {
        return dispatch => {
            let command = {
                type: MODAL_CLOSE,
                payload: SUPER_PITCH_FLOW_STEP1_SELECT_PLAYER,
            };
            dispatch(command);
        };
    };

    // STEP 2 - Select price
    // --------------------------
    public static openSelectSetPriceModal = () => {
        return dispatch => {
            let command = {
                type: MODAL_OPEN,
                payload: { id: SUPER_PITCH_FLOW_STEP2_SET_PRICE },
            };
            dispatch(command);
        };
    };
    public static setPrice = (forSale, sellOn, isSaleToBeDiscussed, forLoan, isLoanToBeDiscussed) => {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({
                step2: {
                    forSale: forSale != null ? parseInt(forSale) : null,
                    sellOn: sellOn != null ? parseInt(sellOn) : null,
                    isSaleToBeDiscussed: isSaleToBeDiscussed,
                    forLoan: forLoan != null ? parseInt(forLoan) : null,
                    isLoanToBeDiscussed: isLoanToBeDiscussed,
                }
            }))
            dispatch(Actions.closeSetPrice());
        };
    };
    public static closeSetPrice = () => {
        return dispatch => {
            let command = {
                type: MODAL_CLOSE,
                payload: SUPER_PITCH_FLOW_STEP2_SET_PRICE,
            };
            dispatch(command);
        };
    };

    // STEP 3 - Select Recipients
    // --------------------------
    static ClulbSelectionLimit = 10;

    public static openRecipientsModal = () => {
        return dispatch => {
            let command = {
                type: MODAL_OPEN,
                payload: { id: SUPER_PITCH_FLOW_STEP3_RECIPIENTS },
            };

            dispatch(command);
        };
    };
    public static selectClub = (clubdIds: Array<number>) => {
        return (dispatch, getState) => {
            dispatch(stateController.setState({ step3: clubdIds, step6: defaultState.step6 }))
            dispatch(Actions.closeResipientsModal());
        };
    };
    public static closeResipientsModal = () => {
        return dispatch => {
            let command = {
                type: MODAL_CLOSE,
                payload: SUPER_PITCH_FLOW_STEP3_RECIPIENTS,
            };

            dispatch(command);
        };
    };

    // STEP 4 - Set Message
    // --------------------------
    public static openMessageModal = () => {
        return dispatch => {
            let command = {
                type: MODAL_OPEN,
                payload: { id: SUPER_PITCH_FLOW_STEP4_MESSAGE },
            };

            dispatch(command);
        };
    };
    public static closeMessageModal = () => {
        return dispatch => {
            let command = {
                type: MODAL_CLOSE,
                payload: SUPER_PITCH_FLOW_STEP4_MESSAGE,
            };

            dispatch(command);
        };
    };
    public static setMessage = ({ message, isActive }) => {
        return (dispatch, getState: () => AppState) => {

            dispatch(stateController.setState({
                step4: {
                    wasSaved: true,
                    message: message,
                    isActive: isActive
                }
            }))
        }
    }

    // STEP 5 - Set Video
    // --------------------------

    public static openVideoModal = () => {
        return dispatch => {
            let command = {
                type: MODAL_OPEN,
                payload: { id: SUPER_PITCH_FLOW_STEP5_VIDEO },
            };

            dispatch(command);
        };
    };
    public static closeVideoModal = () => {
        return dispatch => {
            let command = {
                type: MODAL_CLOSE,
                payload: SUPER_PITCH_FLOW_STEP5_VIDEO,
            };

            dispatch(command);
        };
    };


    public static setVideo = (setVideoState: SetVideoModel) => {
        return (dispatch, getState: () => AppState) => {
            const substate = getState().superPitch.createSuperPitch2
            dispatch(stateController.setState({
                step5: {
                    ...substate.step5,
                    ...setVideoState,
                    wasSaved: true
                }
            }))
        }
    }

    // STEP 6 - Set Comparison
    // --------------------------

    public static openComparisonModal = () => {
        return dispatch => {
            let command = {
                type: MODAL_OPEN,
                payload: {
                    id: SUPER_PITCH_FLOW_STEP6_COMPARISON,
                    className: 'spn-modal',
                },
            };

            dispatch(command);
        };
    };
    public static closeComparisonModal = () => {
        return dispatch => {
            let command = {
                type: MODAL_CLOSE,
                payload: SUPER_PITCH_FLOW_STEP6_COMPARISON,
            };

            dispatch(command);
        };
    };
    public static loadComparisonData = () => {
        return async (dispatch, getState: () => AppState) => {
            const userId = getState().auth.userId
            const squadId = getState().auth.squadId
            const playerId = getState().superPitch.createSuperPitch2.step1.player.id
            const substate = getState().superPitch.createSuperPitch2
            try {
                if (substate.step6.items ==null ) {

                    dispatch(stateController.setState({
                        step6: {
                            ...substate.step6,
                            isLoading: true,
                        }
                    }))
                    let payload = new ComparisonRequest();
                    payload.squadIds = getState().superPitch.createSuperPitch2.step3;

                    const data = await SuperPitchService.loadComparisonData(userId, squadId, playerId, payload)

                    dispatch(stateController.setState({
                        step6: {
                            ...substate.step6,
                            wasInitiallyLoaded: true,
                            items: data.comparisonLists,
                            keys: {
                                firstKey: data.firstKey,
                                secondKey: data.secondKey,
                                thirdKey: data.thirdKey,
                            },
                            characteristicSource: data.characteristicSource,
                            isLoading: false,
                        }
                    }))
                }

            } catch (e) {
                dispatch(stateController.setState({
                    step6: {
                        ...substate.step6,
                        isLoading: false,
                    }
                }))
            }

        }
    }

    public static loadComparisonDataOnSelect = (keys, superPitchId) => {
        return async (dispatch, getState: () => AppState) => {
            const playerId = getState().superPitch.createSuperPitch2.step1.player.id
            const substate = getState().superPitch.createSuperPitch2

            try {
                dispatch(stateController.setState({
                    step6: {
                        ...substate.step6,
                        isLoading: true,
                    }
                }))
                let payload = new ComparisonRequest();
                payload.keys = keys;
                payload.squadIds = getState().superPitch.createSuperPitch2.step3;
                payload.superPitchId = superPitchId;


                //  const data = await SuperPitchService.loadComparisonDataOnSelect(playerId, payload)

                dispatch(stateController.setState({
                    step6: {
                        ...substate.step6,
                        keys: {
                            firstKey: keys[0],
                            secondKey: keys[1],
                            thirdKey: keys[2]
                        },
                        //  items: data.items,
                        isLoading: false
                    }
                }))
            } catch (e) {
                console.error(e)
                dispatch(stateController.setState({
                    step6: {
                        ...substate.step6,
                        isLoading: false,
                    }
                }))
            }
        }
    }

    public static setComparison = (isActive) => {
        return (dispatch, getState: () => AppState) => {
            const substate = getState().superPitch.createSuperPitch2
            dispatch(stateController.setState({
                step6: {
                    ...substate.step6,
                    isActive: isActive,
                    wasSaved: true,
                }
            }))
        }
    }

    public static comparisonSearchSuggestedPlayer = (mainPlayerId: number, characteristicKey: string, searchKey: string) => {
        return async (dispatch, getState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    step6: {
                        ...prevState.step6,
                        isPlayerSearching: true
                    }
                })))
                const data = await SuperPitchService.comparisonSearchSuggestedPlayers(mainPlayerId, characteristicKey, searchKey)

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    step6: {
                        ...prevState.step6,
                        playerSearchResult: data
                    }
                })))

            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    step6: {
                        ...prevState.step6,
                        isPlayerSearching: false
                    }
                })))
            }
        }
    }

    public static onApplyComparisonPlayer = (previousPlayerId: number, nextPlayer: Comparisonitem, metricKey: string) => {
        return (dispatch, getState: () => AppState) => {
            let appState = getState();
            const substate = appState.superPitch.createSuperPitch2;

            let step6 = substate.step6;

            let items = step6.items[metricKey];
            let mappedItems = items.map(
                (x) => {
                    if (x.playerId == previousPlayerId) return nextPlayer;
                    else return x;
                }
            )
            let newDict: { [key: string]: Array<Comparisonitem> } = { ...step6.items };

            mappedItems.sort((x, y) => y.originalValue - x.originalValue);
            mappedItems.forEach((item, index) => {
                item.position = index + 1;
                item.positionFormatted = `0${item.position}`;
            })
            newDict[metricKey] = [...mappedItems];

            let changedPlayers = step6.changeItems;

            changedPlayers[metricKey] = true;

            dispatch(stateController.setState({
                step6: { ...step6, items: newDict, changeItems: changedPlayers }
            }))
        }
    }

    public static disposePlayerSearch = () => (dispatch, getState: AppState) => {
        dispatch(stateController.setState(prevState => ({
            ...prevState,
            step6: {
                ...prevState.step6,
                playerSearchResult: []
            }
        })))
    }

    // STEP 7 - Set Expiration Date
    // --------------------------
    public static openExpirationModal = () => {
        return (dispatch, getState) => {
            let command = {
                type: MODAL_OPEN,
                payload: { id: SUPER_PITCH_FLOW_STEP7_RSVP },
            };
            dispatch(command);
        };
    }
    public static closeExpirationModal = () => {
        return dispatch => {
            let command = {
                type: MODAL_CLOSE,
                payload: SUPER_PITCH_FLOW_STEP7_RSVP,
            };

            dispatch(command);
        };
    };
    public static setExpirationDate = (expirationDate, isActive) => {
        return (dispatch, getState: () => AppState) => {
            const substate = getState().superPitch.createSuperPitch2
            dispatch(stateController.setState({
                step7: {
                    wasSaved: true,
                    isActive: isActive,
                    expirationDate: expirationDate
                }
            }))
        }
    }

    // PREVIEW
    // --------------------------
    public static openPreviewModal = () => {
        return (dispatch, getState) => {
            let command = {
                type: MODAL_OPEN,
                payload: {
                    id: SUPER_PITCH_FLOW_PREVIEW,
                    className: 'spn-modal',
                },
            };
            dispatch(command);
        };
    }
    public static closePreviewModal = () => {
        return dispatch => {
            let command = {
                type: MODAL_CLOSE,
                payload: SUPER_PITCH_FLOW_PREVIEW,
            };

            dispatch(command);
        };
    };


}

class Selectors {

    private static path = (state: AppState) => state.superPitch.createSuperPitch2

    public static selectMainPlayerId = createSelector(
        Selectors.path,
        path => path.step1.player.id
    )

    public static selectCustomizationSearchResult = createSelector(
        Selectors.path,
        path => path.step6.playerSearchResult
    )

    public static stepsCompleteState = state => {
        let createState = state.superPitch.createSuperPitch2;
        let step1 = createState.step1.player != null;
        let step2 = createState.step2.forSale != null || createState.step2.forLoan != null || !!createState.step2.isLoanToBeDiscussed || !!createState.step2.isSaleToBeDiscussed;
        let step3 = createState.step3.length > 0;
        let step4 = createState.step4.message.length > 0 && createState.step4.isActive;
        let step5 = createState.step5.selectedVideo && createState.step5.isActive;
        return { step1, step2, step3, step4, step5 };
    };

    public static getLoadingStatus = state => {
        const substate = state.superPitch.createSuperPitch2
        return substate.isLoading
    }

    public static isSavingStatus = (state: AppState) => {
        const substate = state.superPitch.createSuperPitch2
        return substate.isSaving
    }

    public static selectProgressState = (state: AppState) => {
        const substate = state.superPitch.createSuperPitch2;
        const { forLoan, forSale, isLoanToBeDiscussed, isSaleToBeDiscussed } = substate.step2;

        const stepsState = {
            isPlayerSelected: !!substate.step1.player,
            isPriceSelected: forLoan != null && forLoan != undefined || forSale != null && forSale != undefined || !!isLoanToBeDiscussed || !!isSaleToBeDiscussed,
            isRecipientsSelected: substate.step3.length > 0,
            isMessageSelected: substate.step4.isActive && substate.step4.message.length > 0,
            isVideoSelected: substate.step5.selectedVideo !== null && substate.step5.isActive,
            isComparsonSelected: substate.step6.isActive && substate.step6.items !== null,
            isRSVPSelected: !!substate.step7.expirationDate && substate.step7.isActive
        }
        const activeSteps = Object.values(stepsState).filter(i => i === true).length
        const totalSteps = Object.keys(stepsState).length
        return Math.round((activeSteps / totalSteps) * 100)
    }

    public static getSelectedVideoUrl = (state: AppState) => {
        const substate = state.superPitch.createSuperPitch2

        if (!substate.step5.isActive && !substate.step5.additionalVideo) return null;

        const selectedVideoIndex = substate.step5.selectedVideo;
        const videos = substate.step5.playerVideos
        const additional = substate.step5.additionalVideo
        const selectedUrl = selectedVideoIndex === null ? null : selectedVideoIndex === -1 ? additional : videos[selectedVideoIndex]

        return selectedUrl
    }

}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    CreateSuperPitchState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};