import React from 'react'
import styled from 'styled-components'

type Props = {
    title: string
    date: string
}

const HprTitle = (props: Props) => {
    const { title, date } = props;
    return <StyledDiv>
        <div>
            {title}
        </div>
        <div>
            {date}
        </div>
    </StyledDiv>
}

const StyledDiv = styled.div`
`
export default HprTitle