import React, { Component } from 'react';
import FormInputRange from '../FormInputRange/FormInputRange';
import { Either } from 'types/either';
import _ from 'lodash'
import { formatWithCommas } from 'services/utils';

type Value = number | { min: number, max: number }
type EitherValue = Either<number, { min: number, max: number }>

class OwnProps {
    value: Value;
    range?: boolean;
    minValue?: number;
    maxValue?: number;
    hideLabel?: boolean;
    disabled?: boolean;
    allowSameValues?: boolean;
    withInputs?: boolean;
    onChange: (value: EitherValue) => void;
    renderLabel?: (current: EitherValue) => JSX.Element;
    display?: (value: any) => any;
    units?: ((value: any) => any) | string
    getNextStepRange?: (min: number, max: number) => { minBarrier: number, min: number, max: number };
    dataTestId?: string
    callbackAfterChange?: () => void;
    onChangeDebounceInput?: (value: { min: number; max: number }) => void;
}
class State {
    current: Value
    initial: Value
}

export default class RangeSelector extends Component<OwnProps, State> {

    static defaultProps = {
        units: '',
        display: val => val
    };

    state = {
        current: this.props.value,
        initial: this.props.value
    };

    currentMinValue = 0;
    currentMaxValue = null;

    static getDerivedStateFromProps(nextProps, state) {
        let res = null;

        if (nextProps.range && (nextProps.value.min !== state.initial.min || nextProps.value.max !== state.initial.max)) {
            res = { current: nextProps.value, initial: nextProps.value };
        } else if (!nextProps.range && (nextProps.value !== state.initial || nextProps.value !== state.initial)) {
            res = { current: nextProps.value, initial: nextProps.value }
        }

        if (nextProps.value !== state.initial) {
            res = { current: nextProps.value, initial: nextProps.value };
        }
        return res;
    }

    getUnits(val) {
        if (typeof this.props.units === 'function') {
            return this.props.units(val);
        }
        return this.props.units;
    }

    setCurrentValuesByStepRange(value) {

        if (this.currentMaxValue === null) {
            this.currentMaxValue = this.props.maxValue;
        }

        var nextStepRange = this.props.getNextStepRange(this.currentMinValue, this.currentMaxValue);

        if (value.min > nextStepRange.minBarrier && value.min !== this.currentMinValue) {
            let remainderOfDivisionMin = value.min % nextStepRange.min;

            this.currentMinValue = remainderOfDivisionMin === 0 ? value.min : (value.min - (remainderOfDivisionMin)) + nextStepRange.min;
        } else {

            if (this.currentMinValue <= value.min) {
                this.currentMinValue += nextStepRange.min;
            }

            if (this.currentMinValue > value.min) {
                this.currentMinValue -= nextStepRange.min;
            }
        }

        if (value.max > nextStepRange.minBarrier && value.max !== this.currentMaxValue) {
            let remainderOfDivisionMax = value.max % nextStepRange.max;

            this.currentMaxValue = remainderOfDivisionMax === 0 ? value.max : (value.max - (remainderOfDivisionMax)) + nextStepRange.max;

        } else {
            if (this.currentMaxValue > value.max) {
                this.currentMaxValue -= nextStepRange.max;
            }

            if (this.currentMaxValue < value.max) {
                this.currentMaxValue += nextStepRange.max;
            }
        }


        if (value.min <= 0) {
            this.currentMinValue = 0;
        }
    }

    resetRangeSettings() {
        this.currentMinValue = 0;
        this.currentMaxValue = null;
    }

    getCurrentValues(value) {

        if (this.props.getNextStepRange) {
            this.setCurrentValuesByStepRange(value);
        } else {
            this.currentMinValue = value.min;
            this.currentMaxValue = value.max;
        }

        return {
            min: this.currentMinValue,
            max: this.currentMaxValue
        }
    }

    onChangeThrottled = _.throttle((value) => this.props.onChange(value), 75);
    onChangeThrottledqwe = _.throttle((newValue) => this.props.onChange(newValue), 75);
    onChangeDebounce = _.debounce((value) => this.props.onChangeDebounceInput(value), 1000);

    onChangeLocal = (value, ignoreRangeSteps = false) => {
        if (typeof value === 'object') {
            this.setState({
                current: ignoreRangeSteps ? value : this.getCurrentValues(value),
            });
        } else {
            this.setState({
                current: value
            })
        }
    }

    valueValidation = (value) => {
        const maxValueLength = this.props.maxValue.toString().length;
        let stringWithoutCommas = value.replaceAll(/[^0-9]/g, '') || '0';
        if (+stringWithoutCommas > this.props.maxValue) {
            stringWithoutCommas = stringWithoutCommas.slice(0, maxValueLength - 1) || '0';
        }
        return stringWithoutCommas;
    }

    render() {
        const { renderLabel } = this.props;

        const rangeValue = this.state.current;

        if (typeof this.state.current === "object" && this.props.range
            && this.state.current.min === this.props.minValue
            && this.state.current.max === this.props.maxValue
        ) {
            this.resetRangeSettings();
        }

        const label = renderLabel ? (
            renderLabel(this.state.current as any)
        ) : typeof this.state.current === 'object' ? (
            <p className="tr-range-selector__status">
                {this.props.display(this.state.current.min)}
                {this.getUnits(this.state.current.min)}-
                {this.props.display(this.state.current.max)}
                {this.getUnits(this.state.current.max)}
            </p>
        ) : (
            <p className="tr-range-selector__status">
                {this.props.display(this.state.current)}
                {this.getUnits(this.state.current)}
            </p>
        );

        return (
            <div>
                {this.props.hideLabel ? null : label}
                <FormInputRange
                    range={this.props.range}
                    tooltipVisible={false}
                    minValue={this.props.minValue}
                    maxValue={this.props.maxValue}
                    value={rangeValue}
                    allowSameValues={this.props.allowSameValues}
                    onChange={value => {
                        this.onChangeLocal(value)
                        this.onChangeThrottled(value)
                    }}
                    callbackAfterChange={this.props.callbackAfterChange}
                    // onChangeComplete={(value) => {
                    //     this.props.onChange(value)
                    // }}
                    disabled={this.props.disabled}
                />
                {this.props.withInputs && this.props.range && (
                    <div className="tr-range-selector__inputs">
                        <input
                            value={formatWithCommas((rangeValue as any).min)}
                            placeholder="min"
                            onChange={(event) => {
                                const stringWithoutCommas= this.valueValidation(event.currentTarget.value);
                                const newValue = {
                                    min: parseInt(stringWithoutCommas), 
                                    max: ((this.state.current as any).max)
                                } as any
                                this.onChangeDebounce(newValue);
                                this.props.onChange(newValue)
                            }}
                            data-test-id={`${this.props.dataTestId ? this.props.dataTestId+'-min-input': ''}`}
                        />
                        <input 
                            value={formatWithCommas((rangeValue as any).max)}
                            placeholder="max"
                            onChange={(event) => {
                                const stringWithoutCommas= this.valueValidation(event.currentTarget.value);
                                const newValue = {
                                    min: ((this.state.current as any).min), 
                                    max: parseInt(stringWithoutCommas)
                                } as any
                                this.onChangeDebounce(newValue);
                                this.props.onChange(newValue)
                            }}
                            data-test-id={`${this.props.dataTestId ? this.props.dataTestId+'-max-input': ''}`}
                        />
                    </div>
                )}
            </div>
        );
    }
}