import { CancelToken } from 'axios';
import axios from 'axios-config';
import { PlusPitchModel } from './models/plus-pitch.model';

class GetPlusPitchesResponse {
    active: Array<PlusPitchModel>;
    tested: Array<PlusPitchModel>;
}

export default class PlusPitchService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async getSavedPlusPitches(cancelToken: CancelToken): Promise<Array<PlusPitchModel>> {
        const { data } = await axios.get(
            `api/V2/PlusPitchReceiver/GetAllSavedV2`,
            { ...this.axiosConfig, cancelToken: cancelToken }
        )
        return data
    }

    public static async getPlusPitches(cancelToken: CancelToken): Promise<GetPlusPitchesResponse> {
        const { data } = await axios.get(
            `api/v2/PlusPitchReceiver/GetAllV2`,
            { ...this.axiosConfig, cancelToken: cancelToken }
        )
        return data
    }

    public static async savePitch(id: number): Promise<any> {
        await axios.post(
            `api/v2/PlusPitchReceiver/Save`,
            id,
            this.axiosConfig
        )
    }

    public static async declareInterest(id: number): Promise<any> {
        await axios.post(
            `api/v2/PlusPitchReceiver/DeclareInterest`,
            id,
            this.axiosConfig
        )
    }

    public static async dismiss(id: number): Promise<any> {
        await axios.post(
            `api/v2/PlusPitchReceiver/Dismiss`,
            id,
            this.axiosConfig
        )
    }

    public static async deleteTestInterest(id: number) {
        await axios.get(
            `api/PlusPitch/DeleteTestInterest/`+id,
            this.axiosConfig
        )
    }
} 