import MiscEventService from 'api/virtual-summit/misc-event.service';
import { AgencyRegistrationRequest } from 'api/virtual-summit/models/agency-registration'
import VirtualSummitService from 'api/virtual-summit/virtual-summit.service'
import { notificationCreate } from 'app/notifications/notifications.actions';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';
import historyAccessor from 'history-accessor';
import { AppState } from 'root.reducer'
import { agencyPaths, anonymousPaths } from 'routes/paths';
import { StateController } from 'utils/action-declaration'
import { isValid } from 'utils/validation';
import { Actions as DateSelectorActions } from 'pages/virtual-summit/redux/confirm-page-steps/date-selector.controller';
import { getEventInfo } from 'app/events/components/authorized-event-page/events-map/eventsMap';
import { getAuth } from 'store/auth/authReducer';
import { USER_IS_SHOW_EXCLUSIVE_BANNER_FALSE } from 'store/actionTypes';

export enum RegistrationState {
    DateSelector = 0,
    InProgress = 1,
    Completed = 2
}

export class AttendeeRegistration {
    firstName?: string;
    lastName?: string;
    agency?: string;
    email?: string;
    phoneCodeAreaId?: string;
    phone?: string;
    title?: string;
}

class AuthorizedAgencyRegistration {
    registrationState: RegistrationState;

    isLoading: boolean;
    isProcessing: boolean;

    form: {
        firstName: string;
        lastName: string;
        agency: string;
        email: string;
        phoneCodeAreaId: string;
        phone: string;
        title: string;
    };

    validation: {
        isTitleValid: boolean;
        isPhoneCodeAreaIdValid: boolean;
        isPhoneValid: boolean;
    }
}

const defaultState: AuthorizedAgencyRegistration = {
    registrationState: RegistrationState.InProgress,

    isLoading: false,
    isProcessing: false,

    form: {
        firstName: '',
        lastName: '',
        agency: '',
        title: '',
        email: '',
        phoneCodeAreaId: null,
        phone: '',
    },

    validation: {
        isTitleValid: true,
        isPhoneCodeAreaIdValid: true,
        isPhoneValid: true,
    }
}

const stateController = new StateController<AuthorizedAgencyRegistration>(
    'AUTHORIZED_AGENCY_REGISTRATION',
    defaultState
)

class Actions {
    public static registerAgency = (data: AgencyRegistrationRequest) => {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isProcessing: true }));
                await VirtualSummitService.registerAgency(data);
                dispatch(stateController.setState({
                    isProcessing: false,
                    registrationState: RegistrationState.Completed
                }));
                dispatch({ type: USER_IS_SHOW_EXCLUSIVE_BANNER_FALSE });

                historyAccessor.push(agencyPaths.events);

            } catch (e) {
                dispatch(stateController.setState({ isProcessing: false }));
                dispatch(notificationCreate({
                    message: 'Something went wrong',
                    level: 'error',
                }))
            }
        }
    }

    public static loadInitialUserData() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const { eventId } = getAuth(getState());
                const { registration } = getEventInfo(eventId);
                const { hasDateSelectorStep } = registration;

                if(hasDateSelectorStep){
                    dispatch(stateController.setState({ registrationState: RegistrationState.DateSelector }));
                }

                dispatch(stateController.setState({ isLoading: true }))
                var { user, isUserRegistered, pendingAgencyRequest } = await MiscEventService.loadAgencyRegistrationState();
                if (isUserRegistered || pendingAgencyRequest) {
                    historyAccessor.push(agencyPaths.events)
                } else {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        form: {
                            firstName: user.firstName,
                            lastName: user.lastName,
                            agency: user.clubName,
                            email: user.email,
                            phoneCodeAreaId: user.phoneCodeAreaId,
                            phone: user.phone,
                            title: user.positionName
                        }
                    })))
                }
            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState({ isLoading: false }))
            }
        }
    }

    public static setForm(data: AttendeeRegistration) {
        return async dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                form: {
                    ...prevState.form,
                    ...data,
                }
            })))
        }
    }

    public static processRegistration() {
        return async (dispatch, getState: () => AppState) => {
            const state = getState();
            const form = state.virtualSummit.registrationAuthorizedAgency.form
            const { eventId } = state.auth;

            let isTitleValid = isValid.name(form.title);
            let isPhoneCodeAreaIdValid = isValid.areaId(parseInt(form.phoneCodeAreaId))
            let isPhoneValid = isValid.numbers(form.phone);

            if (!isTitleValid || !isPhoneCodeAreaIdValid || !isPhoneValid) {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    validation: {
                        isPhoneCodeAreaIdValid: isPhoneCodeAreaIdValid,
                        isPhoneValid: isPhoneValid,
                        isTitleValid: isTitleValid
                    }
                })));
            } else {

                const args = {
                    club: form.agency,
                    phoneCodeAreaId: form.phoneCodeAreaId,
                    mobilePhone: form.phone,
                    email: form.email,
                    firstName: form.firstName,
                    lastName: form.lastName,
                    title: form.title,

                    eventParticipationDays: []
                };

                const { registration } = getEventInfo(eventId);
                const { hasDateSelectorStep } = registration;

                if(hasDateSelectorStep){
                    dispatch(DateSelectorActions.setParticipationDays(args));
                }
                
                dispatch(Actions.registerAgency(args))
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    validation: {
                        isPhoneCodeAreaIdValid: true,
                        isPhoneValid: true,
                        isTitleValid: true
                    }
                })));

                dispatch(userActivityInsert({
                    Message: 'Confirmed Registration',
                    PageName: "Event [Registration]",
                    PageType: PageType.AgencyEvents,
                    EventId: eventId
                }))
            }
        }
    }

    public static setStep = (step: RegistrationState) => {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ registrationState: step }));
        }
    }
}

class Selectors {

}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    AuthorizedAgencyRegistration as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};