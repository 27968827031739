import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import AgencyCustomPitchService from 'api/agency/custom-pitch/custom-pitch.service';
import { CloseReason, CloseType, PitchSquadInterestType } from 'api/agency/custom-pitch/models';
import * as CustomPitchController from './pitch-grid.controller';
import * as PublishedPitchController from './published-pitch.controller';
import { copyToClipboard as copy } from 'utils/copy-to-clipboard';
import { notificationCreate } from 'app/notifications/notifications.actions';
import { closeReasonTextById, generateLink } from '../../helpers/custom-pitch';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';
import { anonymousPaths } from 'routes/paths';

class AgencyCustomPitchState {
    processingIds: Array<number>;
    closePitchModal: ClosePitchModalData;
    sharePitchModal: SharePitchModalData;
}

class ClosePitchModalData {
    pitchSquadIds: number[];
    isProcessing: boolean;
}

class SharePitchModalData {
    publishedUid: string;
    pitchSquadId: number;
    isProcessing: boolean;
}

const defaultState: AgencyCustomPitchState = {
    processingIds: [],
    closePitchModal: null,
    sharePitchModal: null,
}

const stateController = new StateController<AgencyCustomPitchState>("AGENCY/CUSTOM_PITCH/PITCH", defaultState);


class Actions {

    public static sendCloseWon(pitchSquadIds: number[]){
        return async (dispatch, getState: () => AppState) => {
            const request = { 
                pitchSquadIds, 
                type: CloseType.Won
            }
            await AgencyCustomPitchService.closePitchSquad(request);

            dispatch(CustomPitchController.Actions.initSource())

            const state = getState();
            const selectedPlayers = CustomPitchController.Selectors.getSelectedPlayer(state);
            const playerItems = selectedPlayers.map(x => x.sentSquads).flat();

            for(var pitchSquadId of pitchSquadIds){
                const { squad, closedInfoSquad } = playerItems.find(x => x.customPitchSentSquadId === pitchSquadId);

                dispatch(userActivityInsert({
                    PageName: 'Custom Pitch [Players pitches]',
                    Message: `Closed Won '${squad.name}'`,
                    PlayerId: selectedPlayers[0].pitchedPlayer.id,
                    ClubId: squad.id,
                    PageType: PageType.Pitch,
                }))

                if(closedInfoSquad) {
                    dispatch(userActivityInsert({
                        PageName: 'Custom Pitch [Players pitches]',
                        Message: 'Changed Close Reason to: Won',
                        PlayerId: selectedPlayers[0].pitchedPlayer.id,
                        ClubId: squad.id,
                        PageType: PageType.Pitch,
                    }))
                }
            }
        }
    }

    public static sendCloseLost(reason: CloseReason){
        return async (dispatch, getState: () => AppState) => {
            const substate = Selectors.getRoot(getState());
            const { pitchSquadIds } = substate.closePitchModal;

            dispatch(stateController.setState({ 
                closePitchModal: {
                    ...substate.closePitchModal,
                    isProcessing: true
                }
            }))

            const request = { 
                pitchSquadIds,
                type: CloseType.Lost,
                reason: reason
            }
            await AgencyCustomPitchService.closePitchSquad(request);

            dispatch(stateController.setState({ closePitchModal: null }))

            dispatch(CustomPitchController.Actions.initSource())

            const state = getState();
            const selectedPlayers = CustomPitchController.Selectors.getSelectedPlayer(state);
            const playerItems = selectedPlayers.map(x => x.sentSquads).flat();
            const reasonText = closeReasonTextById[reason];

            for(var pitchSquadId of pitchSquadIds){
                const { squad, closedInfoSquad } = playerItems.find(x => x.customPitchSentSquadId === pitchSquadId);

                dispatch(userActivityInsert({
                    PageName: 'Custom Pitch [Players pitches]',
                    Message: `Closed Lost '${squad.name}', Reason: '${reasonText}'`,
                    PlayerId: selectedPlayers[0].pitchedPlayer.id,
                    ClubId: squad.id,
                    PageType: PageType.Pitch,
                }))

                if(closedInfoSquad) {
                    dispatch(userActivityInsert({
                        PageName: 'Custom Pitch [Players pitches]',
                        Message: 'Changed Close Reason to: Lost',
                        PlayerId: selectedPlayers[0].pitchedPlayer.id,
                        ClubId: squad.id,
                        PageType: PageType.Pitch,
                    }))
                }
            }
        }
    }

    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState({ ...defaultState }))
        }
    }

    public static openClosePitchModal(pitchSquadIds: number[]) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ 
                closePitchModal: { 
                    pitchSquadIds, 
                    isProcessing: false 
                } 
            }))

            const state = getState();
            const selectedPlayers = CustomPitchController.Selectors.getSelectedPlayer(state);
            const playerItems = selectedPlayers.map(x => x.sentSquads).flat();

            for(var pitchSquadId of pitchSquadIds){
                const { squad } = playerItems.find(x => x.customPitchSentSquadId === pitchSquadId);

                dispatch(userActivityInsert({
                    PageName: 'Custom Pitch [Players pitches]',
                    Message: 'Opened Lost Reason Selection',
                    PlayerId: selectedPlayers[0].pitchedPlayer.id,
                    ClubId: squad.id,
                    PageType: PageType.Pitch
                }))
            }
        }
    }

    public static destroyClosePitchModal() {
        return (dispatch, getState: () => AppState) => {
            const state = getState();
            const { pitchSquadIds } = Selectors.getRoot(state).closePitchModal;

            dispatch(stateController.setState({ closePitchModal: null }))

            const selectedPlayers = CustomPitchController.Selectors.getSelectedPlayer(state);
            const playerItems = selectedPlayers.map(x => x.sentSquads).flat();
            const { squad } = playerItems.find(x => x.customPitchSentSquadId === pitchSquadIds[0]);

            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Players pitches]',
                Message: 'Cancelled Lost Reason Selection',
                PlayerId: selectedPlayers[0].pitchedPlayer.id,
                ClubId: squad.id,
                PageType: PageType.Pitch
            }))
        }
    }

    public static openSharePitchModal(publishedUid: string, pitchSquadId: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ 
                sharePitchModal: { 
                    publishedUid, 
                    pitchSquadId,
                    isProcessing: false
                } 
            }))

            const state = getState();
            const { pitchedPlayer } = CustomPitchController.Selectors.getActiveSelectedPlayer(state);
            const playerItems = CustomPitchController.Selectors.getActiveSelectedPlayerItems(state);
            const { squad } = playerItems.find(x => x.customPitchSentSquadId === pitchSquadId);

            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Players pitches]',
                Message: 'Opened Sharing',
                PlayerId: pitchedPlayer.id,
                ClubId: squad.id,
                PageType: PageType.Pitch,
            }))
        }
    }

    public static closeSharePitchModal() {
        return (dispatch) => {
            dispatch(stateController.setState({ sharePitchModal: null }))
        }
    }

    public static extendExpiryDate(pitchSquadId: number) {
        return async (dispatch, getState: () => AppState) => {
            const request = { 
                pitchSquadId
            };
            await AgencyCustomPitchService.extendExpiry(request);
            dispatch(CustomPitchController.Actions.initSource())

            const state = getState();
            const { pitchedPlayer } = CustomPitchController.Selectors.getActiveSelectedPlayer(state);
            const playerItems = CustomPitchController.Selectors.getActiveSelectedPlayerItems(state);
            const { squad } = playerItems.find(x => x.customPitchSentSquadId === pitchSquadId);

            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Players pitches]',
                Message: `Extended Active Pitch '${squad.name}'`,
                PlayerId: pitchedPlayer.id,
                ClubId: squad.id,
                PageType: PageType.Pitch,
            }))
        }
    }

    public static copyLink() {
        return async (dispatch, getState: () => AppState) => {
            const state = getState();
            const { sharePitchModal } = Selectors.getRoot(state);
            const { publishedUid, pitchSquadId } = sharePitchModal;

            try {
                dispatch(stateController.setState({ sharePitchModal: { ...sharePitchModal, isProcessing: true } }))
                const url = generateLink(publishedUid);
                copy(url);
                await dispatch(PublishedPitchController.Actions.logCustomPitchAction(pitchSquadId, PitchSquadInterestType.Share));
                
                const { pitchedPlayer } = CustomPitchController.Selectors.getActiveSelectedPlayer(state);
                const playerItems = CustomPitchController.Selectors.getActiveSelectedPlayerItems(state);
                const { squad } = playerItems.find(x => x.customPitchSentSquadId === pitchSquadId);
                dispatch(userActivityInsert({
                    PageName: 'Custom Pitch [Share]',
                    Message: 'Copied Link',
                    PlayerId: pitchedPlayer.id,
                    ClubId: squad.id,
                    PageType: PageType.Pitch
                }))

                dispatch(CustomPitchController.Actions.initSource())

                dispatch(notificationCreate({ message: 'The link is copied to the clipboard.', level: 'info' }))
            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState({ sharePitchModal: { ...sharePitchModal, isProcessing: false } }))
            }
        }
    }

    public static viewPublishedPitch(publishedUid: string) {
        return (dispatch, getState: () => AppState) => {
            window.open(anonymousPaths.customPitchPublished.replace(":id", publishedUid), '_blank')

            const state = getState();
            const { pitchedPlayer } = CustomPitchController.Selectors.getActiveSelectedPlayer(state);
            const playerItems = CustomPitchController.Selectors.getActiveSelectedPlayerItems(state);
            const { squad } = playerItems.find(x => x.openedInfoSquad.publishedUid === publishedUid);

            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Players pitches]',
                Message: 'Opened Published View',
                PlayerId: pitchedPlayer.id,
                ClubId: squad.id,
                PageType: PageType.Pitch
            }))
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState): AgencyCustomPitchState => state.agency.customPitch.pitch
    public static getProcessingIds = (state: AppState): Array<number> => Selectors.getRoot(state).processingIds
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    AgencyCustomPitchState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};



