import { AreaResponse } from './model';
import axios from 'axios-config';

export class AreaService {
    private static axiosConfig = {
        headers: {
            "Content-Type": "application/json",
        }
    }

    public static async getAreas(): Promise<AreaResponse> {
        const { data } = await axios.get(
            'api/v2/Area',
            this.axiosConfig
        )
        return data
    }

    public static async getCountries(): Promise<AreaResponse> {
        const { data } = await axios.get(
            'api/v2/Area/GetCountries',
            this.axiosConfig
        )
        return data
    }
}