import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import { ProfileSearchService } from 'api/agency/profile-search/profile-search.service';
import { AutosuggestItem } from 'api/agency/profile-search/profile-search.model'
import { Actions as CommonController } from './common.controller'
import { userPaths } from 'routes/paths';
import { Currency } from 'models/currency.model';
import _ from 'lodash';
import historyAccessor from 'history-accessor';
import config from 'config';

const mockedSuggestionItems = [
    { id: 1, name: "Zumdom" }, 
    { id: 2, name: "Ontoity" }, 
    { id: 3, name: "Dantexon" }, 
    { id: 4, name: "Strongtax Agency" }, 
    { id: 5, name: "Strongtax and Co." }, 
    { id: 6, name: "Medfan Company" }, 
    { id: 7, name: "Tanice" }, 
    { id: 8, name: "Taniceees" }, 
    { id: 9, name: "Tanicerr" }, 
]

export type AgencyItem = {
    id: number;
    name: string;
}

class SearchBarState {
    isProcessing: boolean;
    isSearching: boolean;
    keyword: string;
    suggestionItems: Array<AutosuggestItem>;
    selectedCurrencyId: Currency;
}

const defaultState: SearchBarState = {
    isProcessing: false,
    isSearching: false,
    keyword: '',
    suggestionItems: null,
    selectedCurrencyId: Currency.EUR
}

const stateController = new StateController<SearchBarState>(
    'AGENCY_PROFILE/SEARCH_BAR',
    defaultState
)

class Actions {
    public static loadSearchSuggestions() {
        return async (dispatch, getState: () => AppState) => {
            const keyword = getState().agencyProfile.searchBar.keyword
            if (keyword !== '') {
                dispatch(stateController.setState({ isSearching: true }))

                const suggestions = await ProfileSearchService.getSearchSuggestions(keyword)
                
                dispatch(stateController.setState({ suggestionItems: suggestions, isSearching: false }))
            }
        }
    }

    public static loadSuggestionsDebounced = _.debounce((dispatch) => dispatch(Actions.loadSearchSuggestions()), 1000)

    public static onKeywordChange(value: string) {
        return async (dispatch) => {
            dispatch(stateController.setState({ keyword: value }))
            if (value === '') {
                dispatch(Actions.disposeSuggestionItems())
            } else {
                Actions.loadSuggestionsDebounced(dispatch)
            }
        }
    }

    public static disposeSuggestionItems() {
        return dispatch => {
            dispatch(stateController.setState({ suggestionItems: null }))
        }
    }

    public static selectSearchSuggestion(id: number) {
        return (dispatch, getState: () => AppState) => {
            const selectedCurrency = getState().agencyProfile.searchBar.selectedCurrencyId
            const selectedItemTitle = getState().agencyProfile.searchBar.suggestionItems.find(item => id === item.agentId || id === item.agencyId).title
            dispatch(CommonController.init(id, selectedCurrency))
            // historyAccessor.replace(`/agency-profile/${id}`)
            // window.open(`${config.profileWebAppUrl}/agency-profile/${encodeURIComponent(selectedItemTitle)}`);
            historyAccessor.replace(`/agency-profile/${encodeURIComponent(selectedItemTitle)}`)
            // window.open(`/agency-profile/${encodeURIComponent(selectedItemTitle)}`, '_blank');
            
            const selectedItem = getState().agencyProfile.searchBar.suggestionItems.find(item => id === item.agentId || id === item.agencyId)
            dispatch(stateController.setState({ keyword: selectedItem.title, suggestionItems: null }))
        }
    }

    // public static initDefaultCurrency() {
    //     return (dispatch, getState: () => AppState) => {
    //         dispatch(stateController.setState({ selectedCurrencyId: getState().auth.currency.id}))
    //     }
    // }

    public static onSelectCurrency(currencyId: number) {
        return (dispatch) => {
            dispatch(stateController.setState({ selectedCurrencyId: currencyId }))
        }
    }
}

class Selectors {
    
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    SearchBarState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};