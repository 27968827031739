import { map, filter } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { ROUTE_CHANGED, AUTH_REFRESH } from '../../actionTypes';
import { getAuth } from '../authReducer';
import { logIn, refreshAuth } from '../auth.actions';
import { isAnonymousPath } from '../../../routes/paths';
import {updateLocale} from '../../../services/localization';

const refreshAuthEpic = (action$, state$) => {
    // noinspection JSCheckFunctionSignatures
    return action$.pipe(
        ofType(ROUTE_CHANGED),
        filter(() => !getAuth(state$.value).userId),
        map(action => {
            try {
                if (isAnonymousPath(action.payload.to.pathname)) {
                    return { type: 'ANONYMOUS_ROUTE_CHANGED' };
                }
                return logIn({});
            } catch (e) {
                console.log(e);
            }
        }),
    );
};

export const refreshAuthEpicUser = (action$, state$) => {
    // noinspection JSCheckFunctionSignatures
    return action$.pipe(
        ofType(ROUTE_CHANGED),
        filter(() => getAuth(state$.value).userId),
        map(action => {
            try {
                if (isAnonymousPath(action.payload.to.pathname)) {
                    return { type: 'ANONYMOUS_ROUTE_CHANGED' };
                }
                return refreshAuth(getAuth(state$.value).aspNetUserId);
            } catch (e) {
                console.log(e);
            }
        }),
    );
};

export const loginFromCookieEpic = (action$, state$) => {
    // noinspection JSCheckFunctionSignatures
    return action$.pipe(
        ofType(AUTH_REFRESH.SUCCESS),
        map(() => {
            const {languageCode} = state$.value.auth;
            state$.value.i18n.locale = languageCode;
            return updateLocale(languageCode);
        }),
        
    );
};

export default refreshAuthEpic;


