import { map } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { EXPIRED_ADS_UPDATE,EXPIRED_ADS_REMOVE } from 'store/actionTypes';
import { notificationCreate } from '../../notifications/notifications.actions';
import { translate } from '../../../services/localization';

export const expiredAdWasUpdatedEpic = (action$, state$) => {
    return action$.pipe(
        ofType(EXPIRED_ADS_UPDATE.SUCCESS),
        map(() => {
            return notificationCreate({
                message: translate("expiredAds.epic.successfully"),
                level: 'success',
            });
        }),
    );
};



export const expiredAdWasRemovedEpic = (action$, state$) => {
    return action$.pipe(
        ofType(EXPIRED_ADS_REMOVE.SUCCESS),
        map(() => {
            return notificationCreate({
                message: translate('expiredAds.epic.removedSuccesfully'),
                level: 'success',
            });
        }),
    );
};
