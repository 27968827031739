import React, { Component } from 'react'
import { connect } from 'react-redux'
import EventPageNotRegistered from './not-registered-state/events.entry'
import EventPageRegistered from './registered-state/events.entry'
import { translate } from 'services/localization';
import FeatureCoordinator from 'utils/featureCoordinator'
import { getAuth } from 'store/auth/authReducer';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { Actions as MiscEventController, Selectors as MiscEventSelectors } from 'app/events/redux/misc.controller'
import Spinner from 'components/Spinner/Spinner';
import { PageType } from 'constants/enums';

class EventsSwitch extends Component {

    componentDidMount() {

        const { squadId, userId, eventId } = this.props.auth;
        document.title = translate('menu.events');
        this.props.onLoad(squadId);
        this.props.loadUserSummitsData(userId)
        this.props.userActivityInsert({
            Message: 'Opened Event Page',
            PageName: 'Event Page',
            PageType: PageType.Events,
            EventId: eventId
        });

        // this is temporary, until we need to define red icon in Event menu
        // by user's visit to Event page
        FeatureCoordinator.setIsDisplayed(FeatureCoordinator.eventsNewFeature);
    }

    render() {
        const { isUserRegistered, isLoading } = this.props.upcommingEventData;
        const isEventFinished = true;

        if (isEventFinished) return <EventPageNotRegistered />

        if (isUserRegistered == null || isLoading) return <Spinner />;
        if (isUserRegistered) {
            return <EventPageRegistered />
        } else {
            return <EventPageNotRegistered />
        }
    }
}

const mapStateToProps = state => ({
    isLoading: MiscEventSelectors.selectIsLoading(state),
    upcommingEventData: state.events.misc.upcommingSummitData,
    isRegistrationShown: state.events.misc.isRegistrationShown,
    auth: getAuth(state),
});

const mapDispatchToProps = {
    onLoad: MiscEventController.getEventData,
    loadUserSummitsData: MiscEventController.getUserSummitsData,
    userActivityInsert,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsSwitch)
