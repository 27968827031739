import { StateController } from 'utils/action-declaration'
import { AlertList } from 'api/agency/agent/landing/agent-alert'
import AgentAlertService from 'api/agency/agent/landing/agent-alert.service'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions'
import { PageType } from 'constants/enums';

export class AlertState {
    isLoading: boolean
    alert: AlertList
    processing: boolean;
}

const defaultState: AlertState = {
    isLoading: false,
    processing: false,
    alert: {
        verifiedPlayersAlert: null
    }
}

const stateController = new StateController<AlertState>(
    "AGENCY/LANDING_PAGE/ALERT",
    defaultState
);

class Actions {
    public static loadAlert = () => {
        return async (dispatch) => {
            dispatch(stateController.setState({ isLoading: true }))
            const alert = await AgentAlertService.getAlert();
            dispatch(stateController.setState({ isLoading: false, alert: alert }))
        }
    }

    public static dismiss = () => {
        return async (dispatch) => {
            try {
                dispatch(stateController.setState({ processing: true }))
                await AgentAlertService.dimsiss();
                dispatch(stateController.setState({ alert: { verifiedPlayersAlert: null } }))
                dispatch(userActivityInsert({
                    PageName: 'Agency Home [Verified players]',
                    Message: 'Dismissed',
                    PageType: PageType.AgencyHome
                }))
            } catch(e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState({ processing: false }))
            }
        }
    }

}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    AlertState as State,
    Actions as Actions,
    stateController as Controller
};


