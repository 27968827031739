import React from 'react'
import styled from 'styled-components'

const HprContent = () => {
    return <StyledDiv>
        Register your interest to avoid disappointment.
    </StyledDiv>
}

const StyledDiv = styled.div`
    color: #D92D20;
    font-weight: 700;
`
export default HprContent