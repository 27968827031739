import React, { Component } from 'react';
import PaulMitchell from 'media/images/events/madrid/paul_mitchell.jpg';
import AsMonaco from 'media/images/events/madrid/as_monaco.png';
import Rasmus from 'media/images/events/madrid/Rasmus.png';
import styled from 'styled-components'

class State {
    isFirstSpeakerSectionOpen: boolean;
    isSecondSpeakerSectionOpen: boolean;
}

class Speakers extends Component<{}, State>{
    state = {
        isFirstSpeakerSectionOpen: false,
        isSecondSpeakerSectionOpen: false,
    }

    private onToggleFirstSpeaker () {
        this.setState(prevState => ({ ...prevState, isFirstSpeakerSectionOpen: !prevState.isFirstSpeakerSectionOpen }));
    }

    private onToggleSecondSpeaker () {
        this.setState(prevState => ({ ...prevState, isSecondSpeakerSectionOpen: !prevState.isSecondSpeakerSectionOpen }));
    }

    render () {
        const { isFirstSpeakerSectionOpen, isSecondSpeakerSectionOpen } = this.state;

        return (
            <StyledSection>
                <div className={'event-speakers'}>
                    <h2 style={{ marginTop: 0 }}>Speakers &amp; Talks</h2>
                    <div className="event-speakers__row">
                        <div className="event-speakers__header" onClick={() => this.onToggleFirstSpeaker()}>
                            <h3 className="event-speakers__title">Big Club Rebuild</h3>
                            <span className={`fas ${isFirstSpeakerSectionOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
                        </div>

                        {isFirstSpeakerSectionOpen && <div className="event-speakers__content">
                            <div className="event-speakers__image-container">
                                <div className="speaker-image">
                                    <div className="speaker-image__photo" style={{ backgroundImage: `url('${PaulMitchell}')` }}></div>
                                    <img className="speaker-image__logo first-logo" alt={'As Monaco'} src={AsMonaco} />
                                </div>
                            </div>
                            <div>
                                <div className="event-speakers__speaker-info">
                                    <div className="event-speakers__speaker-name">Paul Mitchell</div>
                                    <div>Sporting Director</div>
                                    <div>AS Monaco</div>
                                </div>

                                <p className="event-speakers__description">Paul Mitchell is the Sporting Director of AS Monaco. He's renowned for his relentless focus on recruiting and developing players to build a winning squad.</p>

                                <p className="event-speakers__description">In Madrid, Paul will discuss:</p>
                                <ul className="event-speakers__list">
                                    <li> <span className="fa fa-check" />Building a strategic roadmap for success</li>
                                    <li> <span className="fa fa-check" />The importance of internal and external communication</li>
                                    <li> <span className="fa fa-check" />Masterminding success through a winning team</li>
                                </ul>
                            </div>
                        </div>}

                        {!isFirstSpeakerSectionOpen && <div className="event-speakers__content">
                            <div className="event-speakers__card">
                                <div className="speaker-image">
                                    <div className="speaker-image__photo" style={{ backgroundImage: `url('${PaulMitchell}')` }}></div>
                                    <img className="speaker-image__logo first-logo" alt={'As Monaco'} src={AsMonaco} />
                                </div>
                                <div className="event-speakers__speaker-info">
                                    <div className="event-speakers__speaker-name">Paul Mitchell</div>
                                    <div>Sporting Director</div>
                                    <div>AS Monaco</div>
                                </div>
                            </div>
                        </div>}
                    </div>

                    <div className="event-speakers__row">
                        <div className="event-speakers__header" onClick={() => this.onToggleSecondSpeaker()}>
                            <h3 className="event-speakers__title">Winning on and off the pitch</h3>
                            <span className={`fas ${isSecondSpeakerSectionOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
                        </div>
                        {isSecondSpeakerSectionOpen && <div className="event-speakers__content">
                            <div className="event-speakers__image-container">
                                <div className="speaker-image">
                                    <div className="speaker-image__photo" style={{ backgroundImage: `url('${Rasmus}')` }}></div>
                                </div>
                            </div>
                            <div>
                                <div className="event-speakers__speaker-info">
                                    <div className="event-speakers__speaker-name">Rasmus Ankersen</div>
                                    <div>Co-founder and CEO</div>
                                    <div>Sport Republic</div>
                                </div>

                                <p className="event-speakers__description">Rasmus Ankersen is co-founder and CEO of Sport Republic, the new investment firm that hit the headlines in early 2022 when it acquired Southampton FC.</p>

                                <p className="event-speakers__description">In Madrid, Rasmus will discuss:</p>
                                <ul className="event-speakers__list">
                                    <li> <span className="fa fa-check" />Why your club may be next for Sport Republic</li>
                                    <li> <span className="fa fa-check" />How profitability and success can live together</li>
                                    <li> <span className="fa fa-check" />The secret to building high performance teams</li>
                                </ul>
                            </div>
                        </div>}
                        {!isSecondSpeakerSectionOpen && 
                            <div className="event-speakers__content">
                                <div className="event-speakers__card">
                                    <div className="speaker-image">
                                        <div className="speaker-image__photo" style={{ backgroundImage: `url('${Rasmus}')` }}></div>
                                    </div>
                                    <div className="event-speakers__speaker-info">
                                        <div className="event-speakers__speaker-name">Rasmus Ankersen</div>
                                        <div>Co-founder and CEO</div>
                                        <div>Sport Republic</div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                </div >
            </StyledSection>
        )
    }
}

const StyledSection = styled.div`
    .event-speakers {
        margin-top: 74px;
        margin: 0 auto;
        margin-bottom: 60px;
        padding-left: 5px;

        h2 {
            font-size: 40px;
            font-weight: bold;
            margin-top: 75px;
            margin-bottom: 60px;

            @media (max-width: 365px) {
                margin-top: 50px !important;
            }
        }

        &__header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;
            cursor: pointer;

            > span {
                padding-top: 2px;
                font-size: 26px;
                color: #219653;
            }
        }

        &__title {
            color: #219653;
            font-weight: bold;
            font-size: 26px;
        }

        &__row {
            border-radius: 15px;
            border: 1px solid #d5d5d5;
            padding: 24px;
            padding-right: 18px;

            margin-top: 30px;

            .download-link {
                font-size: 18px;
                color: #219653;
                align-items: baseline;
                font-weight: 500;
                margin-bottom: 10px;
                padding-left: 20px;
                display: flex;
                cursor: pointer;

                &__text {
                    margin-left: 10px;
                    text-decoration: underline;
                }

                .far {
                    font-size: 21px;
                }
            }
        }

        &__list {
            font-size: 18px;
            line-height: 22px;

            margin-top: 1rem;
            padding: 0px;
            li {
                margin-bottom: 7px;
                display: flex;
                font-weight: normal;
            }
            span {
                padding-top: 0;
                color: #219653;
                margin-right: 15px;
                font-size: 21px;
            }
        }

        &__content {
            display: flex;

            @media (max-width: 767px) {
                flex-direction: column;
            }
        }

        &__image-container {
            padding-right: 5px;

            @media (max-width: 767px) {
                padding-right: 0;
                align-self: center;
            }
        }
        
        .speaker-image {
            padding-right: 20px;
            width: 90px;
            height: 80px;

            max-width: 100%;
            position: relative;

            &__photo {
                width: 80px;
                height: 80px;
                border-radius: 100%;
                background-position: center;
                background-size: cover;
                max-width: none;
                box-shadow: none;
            }

            &__logo {
                width: 40%;
                height: auto;
                position: absolute;
                top: 60%;
                left: 55%;
                width: 30%;
        
                &.first-logo {
                    @media (max-width: 767px) {
                        top: 30%;
                        left: 70%;
                        transform: translate(0, -50%) scale(1.2) !important;;
                    }
                }
            }
            @media (max-width: 767px) {
                margin-top: 10px;
                margin-bottom: 20px;
            }
        }

        &__speaker-info {
            margin-top: 10px;
            margin-bottom: 24px;
            font-size: 16px;
            line-height: 19px;

            @media (max-width: 767px) {
                text-align: center;
            }
        }

        &__speaker-name {
            font-weight: bold;
        }

        &__description {
            font-size: 18px;
            line-height: 22px;
        }

        &__card {
            display: flex;
            width: 340px;

            .event-speakers__speaker-info {
                padding-left: 5px;
                
                
                @media (max-width: 767px) {
                    margin-top: 20px;
                    padding-left: 15px;
                    text-align: left;
                }
            }
        }

    }
`

export default Speakers