import axios from 'axios-config';
import { FriendlyMatchResponse, SearchParameters, FriendlyMatchFilters } from "./models";

export default class friendlyMatchesReportService {
  private static axiosConfig = {
    headers: { 'Content-Type': 'application/json' },
  }

  public static async getAllFriendlyMatches(searchParameters: SearchParameters): Promise<FriendlyMatchResponse> {
    const { data } = await axios.post(
      `api/v2/AdminFriendlyMatches/GetFriendlyMatches`,
      searchParameters,
      this.axiosConfig
    );
    return data;
  }

  public static async getFiltersValues(): Promise<FriendlyMatchFilters> {
    const { data } = await axios.get(
      `api/v2/AdminFriendlyMatches/GetAllFriendlyMatchesFilters`,
      this.axiosConfig
    );
    return data;
  }

  public static async assignOpponent(friendlyMatchId: number, opponentId: number, index: number): Promise<any> {
    const { data } = await axios.post(
      `api/AdminFriendlyMatches/AssignOpponent/${friendlyMatchId}/${opponentId}/${index}`,
      null,
      this.axiosConfig
    );
    return data;
  }
}
