import axios from 'axios-config';
import { AgentCoachInvitationCreateRequest, AgentCoachInvitationItemModel, CoachVerificationResponse, SendToVerificationRequest, SendToVerificationResponse, PendingEmailVerificationCoachModel } from './models';

export default class AgencyCoachOnboardingService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async addCoaches(items: Array<AgentCoachInvitationItemModel>) {
        const payload: AgentCoachInvitationCreateRequest = { items };
        await axios.post(`api/v2/AgentCoachInvitation/CreateRange`, payload, this.axiosConfig);
    }

    public static async getUnverifiedCoaches() {
        const { data } = await axios.get<CoachVerificationResponse>(`api/v2/AgentCoachInvitation/GetUnverifiedCoaches`, this.axiosConfig)
        return data;
    }

    public static async sendToVerify(request: SendToVerificationRequest) {
        const { data } = await axios.post<SendToVerificationResponse>(`api/v2/AgentCoachInvitation/SendToVerify`, request, this.axiosConfig)
        return data;
    }

    public static async sendVerificationEmail(request: PendingEmailVerificationCoachModel) {
        const { data } = await axios.post(`api/v2/AgencyCoach/SendToVerifyEmails`, { coaches: [request] }, this.axiosConfig)
        return data;
    }
}