import { StateController } from 'utils/action-declaration';
import validator from 'services/validator';
import { AppState } from 'root.reducer';
import historyAccessor from 'history-accessor'
import { RegistrationService } from 'api/registration/registration.service';
import { anonymousPaths } from 'routes/paths';
import { getAreaPhoneCodes } from 'app/phone-codes/actions/phone-codes.actions';

export enum RequestInvitationState {
    DefaultView = 1,
    RequestInvitationDone = 2,
    AgencyIsAlreadyExist = 3,
}

export type AgentRequestInvitationForm = {
    email: string;
    name: string;
    phoneCodeAreaId: number;
    phoneNumber: string;
    jobTitle: string;
    agencyName: string;
}

export type ValidationState = {
    isEmailValid: boolean,
    isNameValid: boolean;
    isPhoneCodeAreaIdValid: boolean;
    isPhoneNumberValid: boolean;
    isJobTitleValid: boolean;
    isAgencyNameValid: boolean;
    didTryToProcess: boolean,
    errorMessage: string | Array<string>;
    isFormValid: boolean;
};

class AgentRequestInvitation {
    isInitializing: boolean;
    isProcessing: boolean;
    currentState: RequestInvitationState;
    form: AgentRequestInvitationForm;
    validation: ValidationState;
    agencyIsAlreadyExist: boolean;
}

const defaultState: AgentRequestInvitation = {
    isInitializing: false,
    isProcessing: false,
    currentState: RequestInvitationState.DefaultView,
    form: {
        email: '',
        name: '',
        phoneCodeAreaId: null,
        phoneNumber: '',
        jobTitle: '',
        agencyName: '',
    },
    agencyIsAlreadyExist: false,
    validation: {
        isEmailValid: null,
        isNameValid: null,
        isPhoneCodeAreaIdValid: null,
        isPhoneNumberValid: null,
        isJobTitleValid: null,
        isAgencyNameValid: null,

        didTryToProcess: false,
        errorMessage: '',
        isFormValid: true,
    },
}

const stateController = new StateController<AgentRequestInvitation>(
    "REQUEST_AGENCY_INVITATION",
    defaultState
);

class Actions {

    private static validationRules = {
        email: {
            method: validator.isValidEmail,
            message: 'Insert user email!',
        },
        name: {
            method: validator.isNotEmpty,
            message: 'Insert your first name.',
        },
        jobTitle: {
            method: validator.isNotEmpty,
            message: 'Insert nyour job title'
        },
        agencyName: {
            method: validator.isNotEmpty,
            message: 'Insert agency name.',
        },
        phoneCode: {
            method: validator.isNotEmpty,
            message: 'Select area code'
        },
        phoneNumber: {
            method: validator.isNotEmpty,
            message: 'Insert your phone number'
        }
    };

    public static init() {
        return async dispatch => {
            try {
                dispatch(stateController.setState({ isInitializing: true }))
                await dispatch(getAreaPhoneCodes())
                dispatch(stateController.setState({ isInitializing: false }))
            } catch (e) {
                console.error(e)
            }
        }
    }


    public static setDefaultViewState() {
        return dispatch => {
            dispatch(stateController.setState({ currentState: RequestInvitationState.DefaultView }))
        }
    }

    public static setRequestInvitationDoneState() {
        return dispatch => {
            dispatch(stateController.setState({ currentState: RequestInvitationState.RequestInvitationDone }))
        }
    }

    public static setRequestInvitationAgencyIsAlreadyExistState() {
        return dispatch => {
            dispatch(stateController.setState({ currentState: RequestInvitationState.AgencyIsAlreadyExist }))
        }
    }


    public static onEmailChange(value: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState, form: { ...prevState.form, email: value }
            })))
            if (getState().requestAgencyInvitation.validation.didTryToProcess) {
                dispatch(Actions.validateForm())
            }
        }
    }
    public static onNameChange(value: string) {
        return (dispatch, getState: () => AppState) => {


            let state = getState().requestAgencyInvitation;
            let changed: AgentRequestInvitation = { ...state, form: { ...state.form, name: value } };


            dispatch(stateController.setState(changed));

            if (getState().requestAgencyInvitation.validation.didTryToProcess) {
                dispatch(Actions.validateForm())
            }
        }
    }
    public static onPhoneCodeChange(value: number) {
        return (dispatch, getState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState, form: { ...prevState.form, phoneCodeAreaId: value }
            })))

            if (getState().requestAgencyInvitation.validation.didTryToProcess) {
                dispatch(Actions.validateForm())
            }
        }
    }
    public static onPhoneNumberChange(value: string) {
        return (dispatch, getState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                form: {
                    ...prevState.form,
                    phoneNumber: value
                }
            })))

            if (getState().requestAgencyInvitation.validation.didTryToProcess) {
                dispatch(Actions.validateForm())
            }
        }
    }
    public static onJobTitleChange(value: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState, form: { ...prevState.form, jobTitle: value }
            })))
            if (getState().requestAgencyInvitation.validation.didTryToProcess) {
                dispatch(Actions.validateForm())
            }
        }
    }
    public static onAgencyNameChange(value: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState, form: { ...prevState.form, agencyName: value }
            })))
            if (getState().requestAgencyInvitation.validation.didTryToProcess) {
                dispatch(Actions.validateForm())
            }
        }
    }

    public static processRequestInvitation() {
        return async (dispatch, getState: () => AppState) => {
            await dispatch(Actions.validateForm())

            const isFormValid = getState().requestAgencyInvitation.validation.isFormValid

            if (isFormValid) {

                const {
                    name,
                    email,
                    phoneCodeAreaId,
                    phoneNumber,
                    agencyName,
                    jobTitle
                } = getState().requestAgencyInvitation.form

                const m = {
                    fullName: name,
                    email: email,
                    phoneCodeAreaId: phoneCodeAreaId,
                    phoneNumber: phoneNumber,
                    agencyName: agencyName,
                    customPosition: jobTitle
                };

                try {
                    dispatch(stateController.setState({ isProcessing: true }))
                    const result = await RegistrationService.registerAgency(m)
                    dispatch(stateController.setState({ isProcessing: false }))


                    if (result.value.emailIsRegistered) {
                        dispatch(Actions.disposeComponent())
                        historyAccessor.push(anonymousPaths.login);
                    } else if (result.value.agencyMatch) {
                        dispatch(Actions.setRequestInvitationAgencyIsAlreadyExistState())
                    } else {
                        dispatch(Actions.disposeComponent())
                        dispatch(Actions.setRequestInvitationDoneState())
                    }
                } catch (e) {
                    dispatch(stateController.setState({ isProcessing: false }))
                }
            }
        }
    }

    public static disposeComponent() {
        return dispatch => {
            dispatch(stateController.setState(defaultState))
        }
    }

    public static disposeError() {
        return async dispatch => {
            dispatch(stateController.setState(prevState => ({ ...prevState, validation: { errorMessage: '', didTryToProcess: false } })))
        }
    }

    public static prefill(email: string, firstName: string, lastName: string) {
        return dispatch => {
            if (email != null && firstName != null) {
                dispatch(Actions.setDefaultViewState());
                dispatch(Actions.onEmailChange(email));
                dispatch(Actions.onNameChange(firstName + ' ' + lastName));
            }
        }
    };

    public static resetForm() {
        return dispatch => {
            dispatch(Actions.setDefaultViewState());
            dispatch(Actions.disposeComponent());
        }
    }

    public static validateForm() {
        return async (dispatch, getState: () => AppState) => {
            const form = getState().requestAgencyInvitation.form
            const result = validator.validate({
                email: form.email,
                name: form.name,
                phoneCode: form.phoneCodeAreaId,
                phoneNumber: form.phoneNumber,
                agencyName: form.agencyName,
                jobTitle: form.jobTitle
            }, this.validationRules) as any
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                validation: {
                    ...prevState.validation,
                    isFormValid:
                        result.email.isValid
                        && result.name.isValid
                        && result.agencyName.isValid
                        && result.jobTitle.isValid
                        && result.phoneCode.isValid
                        && result.phoneNumber.isValid,
                    didTryToProcess: true,
                    errorMessage: result.email.message,

                    isEmailValid: result.email.isValid,
                    isNameValid: result.name.isValid,
                    isPhoneCodeAreaIdValid: result.phoneCode.isValid,
                    isPhoneNumberValid: result.phoneNumber.isValid,
                    isAgencyNameValid: result.agencyName.isValid,
                    isJobTitleValid: result.jobTitle.isValid
                }
            })))
        }
    }
}


const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    AgentRequestInvitation as State,
    Actions as Actions,
    stateController as Controller
};



