import axios from 'axios-config'
import { GetReferralLinkResponse } from './invite-players.models'

export class InvitePlayersPageService {
    private static axiosConfig = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    public static async getReferralLink(): Promise<GetReferralLinkResponse> {
        const { data } = await axios.get(
            `api/v2/PlayerReferral/GetReferralLink`,
            this.axiosConfig
        )
        return data
    }

    public static async setLinkShared(buttonType: string): Promise<any> {
        await axios.post(
            `api/v2/PlayerReferral/ClickShare/${buttonType}`,
            this.axiosConfig
        )
    }
}