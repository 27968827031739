import { AppState } from 'root.reducer'
import { StateController } from 'utils/action-declaration'
import AgencyResetPreconnectedPlayersService
    from "api/admin/reset-preconnected-players/reset-preconnected-players.service";
import {PlayersList} from "api/admin/reset-preconnected-players/models";
import {
    AgencySearchFilterSuggestionService
} from "api/admin/search-agency-suggestions/search-agency-suggestions.service";
import {AgencySuggestionsResponse} from "api/admin/search-agency-suggestions/models";
import {notificationCreate} from "../../../app/notifications/notifications.actions";
import React from "react";

class AdminPreconnectedPlayersState {
    isModalOpen: boolean;
    playersInfoList: PlayersList;
    isLoadingAgency: boolean;
    isLoadingPlayersInfo: boolean;
    suggestionItems: AgencySuggestionsResponse;
    agencyId: string;
}

const defaultState = {
    isModalOpen: false,
    playersInfoList: [],
    isLoadingAgency: false,
    isLoadingPlayersInfo: false,
    suggestionItems: [],
    agencyId: '',
}

const stateController = new StateController<AdminPreconnectedPlayersState>(
    'ADMIN_V2/RESET_PRECONNECTED_PLAYERS_MODAL',
    defaultState
)

class Actions {
    public static getPlayers(agencyId: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isLoadingPlayersInfo: true }));
                const playersInfoList = await AgencyResetPreconnectedPlayersService.loadPlayers(agencyId)

                dispatch(stateController.setState((prevState) => {
                    return({
                            ...prevState,
                            playersInfoList,
                            agencyId,
                        }
                    )
                }))
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({isLoadingPlayersInfo: false }))
            }
        }
    }

    public static onKeywordChange(value: string) {
        return async (dispatch, getState: () => AppState) => {
            if (value) {
                dispatch(Actions.getSuggestedAgencies(value));
            } else {
                dispatch(stateController.setState((prevState) => {
                    return ({
                        ...prevState,
                        suggestionItems: []
                    });
                }))
            }
        }
    }

    public static getSuggestedAgencies(keyword: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isLoadingAgency: true }))
                const data = await AgencySearchFilterSuggestionService.getAgencySearchFilterSuggestion(keyword);
                dispatch(stateController.setState({suggestionItems: data}))
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({isLoadingAgency: false }))
            }
        }
    }

    public static reconnectPlayers (playersList: React.Key[]) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const id = getState().admin.resetPreconnectedPlayersModal.agencyId;
                await AgencyResetPreconnectedPlayersService.resetPlayerConnections(playersList, id);
                dispatch(Actions.outputSuccessMessage());
                dispatch(Actions.getPlayers(id));
            } catch (err) {
                console.error(err);
            }
        }
    }

    public static outputSuccessMessage = () => {
        return dispatch => {
            dispatch(notificationCreate({
                message: 'Selected connections of players are reset',
                level: 'success',
            }));
        };
    };
    public static openModal() {
        return (dispatch) => {
            dispatch(stateController.setState({ isModalOpen: true }))
        }
    }

    public static closeModal() {
        return (dispatch) => {
            dispatch(stateController.setState({ isModalOpen: false }));
            dispatch(stateController.setState(defaultState));
        }
    }
}

class Selectors {
    public static selectState = (state: AppState) => state.admin.resetPreconnectedPlayersModal;
    public static getPlayersInfoList = (state: AppState) => {
        return Selectors.selectState(state).playersInfoList.map(item => {
            item['key'] = item.id;
            return item;
        })
    };
    public static getSuggestedItems = (state: AppState) => Selectors.selectState(state).suggestionItems;
    public static isModalOpen = (state: AppState) => Selectors.selectState(state).isModalOpen;
    public static isLoadingAgencies = (state: AppState) => Selectors.selectState(state).isLoadingAgency;
    public static isLoadingPlayersInfo = (state: AppState) => Selectors.selectState(state).isLoadingPlayersInfo;
}

const reducer = stateController.getReducer()

export {
    reducer as Reducer,
    AdminPreconnectedPlayersState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
}