import { getRequests } from 'store/requests/requestsReducer';
import { asyncActionType } from 'store/actionTypes';
import { cloneDeep } from 'lodash';

const initialState = {
    initialSorting: [],
    players: [],
    playersToShow: [],
    selectedPlayerId: null,
    sortBy: null
};

export default (playerValuations = initialState, action) => {
    switch (action.type) {
        case PLAYERS_VALUATIONS_FETCH.SUCCESS: {
            return {
                ...playerValuations, players: action.payload.data, playersToShow: action.payload.data.slice(0, 5), initialSorting: action.payload.data.map(i => i.player.id)
            };
        }
        case PLAYERS_VALUATIONS_FETCH.REQUEST:
            return { ...playerValuations };

        case PLAYERS_VALUATION_PLAYER_SELECTED:
            const playerId = action.payload.player.id;
            return {
                ...playerValuations,
                selectedPlayerId: playerValuations.selectedPlayerId === playerId ? null : playerId,
            };
        case PLAYERS_VALUATION_SHOW_MORE:
            return { ...playerValuations, playersToShow: playerValuations.players.slice(0, playerValuations.playersToShow.length + 5) }
        case PLAYERS_VALUATION_SHOW_FIRST:
            return { ...playerValuations, playersToShow: playerValuations.players.slice(0, 5) }

        case PLAYERS_VALUATION_SET_SORTING:
            let copyPlayers = cloneDeep(playerValuations.players);
            let sortedPlayers = null;
            if (action.payload.id === "value") {
                sortedPlayers = copyPlayers.sort((a, b) => {
                    let newA = parseFloat(a.player.marketValue.replace(/,/g, ''));
                    let newB = parseFloat(b.player.marketValue.replace(/,/g, ''));
                    if (action.payload.id !== null && action.payload.sortBy === "asc")
                        return newA - newB;
                    if (action.payload.id !== null && action.payload.sortBy === "desc")
                        return newB - newA;
                    return 0;
                });
            } else if (action.payload.id === "player") {
                sortedPlayers = copyPlayers.sort((a, b) => {
                    if (a.player.englishShortName < b.player.englishShortName) {
                        if (action.payload.id !== null && action.payload.sortBy === "asc")
                            return -1;
                        if (action.payload.id !== null && action.payload.sortBy === "desc")
                            return 1;
                    }
                    if (a.player.englishShortName > b.player.englishShortName) {
                        if (action.payload.id !== null && action.payload.sortBy === "asc")
                            return 1;
                        if (action.payload.id !== null && action.payload.sortBy === "desc")
                            return -1;
                    }
                    return 0;
                });

            } else if (action.payload.id === "position") {
                sortedPlayers = copyPlayers.sort((a, b) => {
                    if (a.player.firstPositionCode < b.player.firstPositionCode) {
                        if (action.payload.id !== null && action.payload.sortBy === "asc")
                            return -1;
                        if (action.payload.id !== null && action.payload.sortBy === "desc")
                            return 1;
                    }
                    if (a.player.firstPositionCode > b.player.firstPositionCode) {
                        if (action.payload.id !== null && action.payload.sortBy === "asc")
                            return 1;
                        if (action.payload.id !== null && action.payload.sortBy === "desc")
                            return -1;
                    }
                    return 0;
                });
            } else if (action.payload.id === "age") {
                sortedPlayers = copyPlayers.sort((a, b) => {
                    let newA = a.player.age;
                    let newB = b.player.age;
                    if (action.payload.id !== null && action.payload.sortBy === "asc")
                        return newA - newB;
                    if (action.payload.id !== null && action.payload.sortBy === "desc")
                        return newB - newA;
                    return 0;
                });

            } else if (action.payload.id === "contractExpiry") {
                sortedPlayers = copyPlayers.sort((a, b) => {
                    if (new Date(a.player.contractExpiration) < new Date(b.player.contractExpiration)) {
                        if (action.payload.id !== null && action.payload.sortBy === "asc")
                            return -1;
                        if (action.payload.id !== null && action.payload.sortBy === "desc")
                            return 1;
                    }
                    if (new Date(a.player.contractExpiration) > new Date(b.player.contractExpiration)) {
                        if (action.payload.id !== null && action.payload.sortBy === "asc")
                            return 1;
                        if (action.payload.id !== null && action.payload.sortBy === "desc")
                            return -1;
                    }
                    return 0;
                });
            } else if (action.payload.id === "percentValue") {
                sortedPlayers = copyPlayers.sort((a, b) => {
                    let newA = parseFloat(a.percentOfClubValue);
                    let newB = parseFloat(b.percentOfClubValue);
                    if (action.payload.id !== null && action.payload.sortBy === "asc")
                        return newA - newB;
                    if (action.payload.id !== null && action.payload.sortBy === "desc")
                        return newB - newA;
                    return 0;
                });
            }
            else if (action.payload.id === "transferStatus") {
                sortedPlayers = copyPlayers.sort((a, b) => {
                    let aAvailability = a.player.playerAvailability.isAvailableForLoan || a.player.playerAvailability.isAvailableForSale;
                    let bAvailability = b.player.playerAvailability.isAvailableForLoan || b.player.playerAvailability.isAvailableForSale;
                    let aRelease = a.player.inReleaseList;
                    let bRelease = b.player.inReleaseList;

                    if (action.payload.id !== null && action.payload.sortBy === "asc")
                        if (aAvailability && !bAvailability) return 1;
                        else if (!aAvailability && bAvailability) return -1;
                        else if (aAvailability && bAvailability) return 0;
                        else {
                            if (aRelease && !bRelease) return 1;
                            else if (!aRelease && bRelease) return -1;
                            else if (aRelease && bRelease) return 0;
                            else return 0;
                        }
                    if (action.payload.id !== null && action.payload.sortBy === "desc")
                        if (aAvailability && !bAvailability) return -1;
                        else if (!aAvailability && bAvailability) return 1;
                        else if (aAvailability && bAvailability) return 0;
                        else {
                            if (aRelease && !bRelease) return -1;
                            else if (!aRelease && bRelease) return 1;
                            else if (aRelease && bRelease) return 0;
                            else return 0;
                        }
                    return 0;
                });
            }
            else {
                let result = [];
                playerValuations.initialSorting.map(id => {
                    let element = copyPlayers.find(i => i.player.id === id);
                    result.push(element);
                    return element;
                });
                sortedPlayers = result;
            }
            let sortedPlayersToShow = sortedPlayers.slice(0, playerValuations.playersToShow.length);

            return { ...playerValuations, sortBy: action.payload, players: sortedPlayers, playersToShow: sortedPlayersToShow }

        case PLAYERS_VALUATIONS_ADD_TO_SHORTLIST.SUCCESS:
            let newPlayersAdd = [...playerValuations.players];
            let newPlayersToShowAdd = [...playerValuations.playersToShow];
            let toChange1Add = newPlayersAdd.find(i => i.player.id === action.payload.request.playerId);
            let toChange2Add = newPlayersToShowAdd.find(i => i.player.id === action.payload.request.playerId);
            if (toChange1Add) { toChange1Add.player.isInShortList = true }
            if (toChange2Add) { toChange2Add.player.isInShortList = true }
            return { ...playerValuations, players: newPlayersAdd, playersToShow: newPlayersToShowAdd }

        case PLAYERS_VALUATIONS_REMOVE_FROM_SHORTLIST.SUCCESS:
            let newPlayers = [...playerValuations.players];
            let newPlayersToShow = [...playerValuations.playersToShow];
            let toChange1 = newPlayers.find(i => i.player.id === action.payload.request.playerId);
            let toChange2 = newPlayersToShow.find(i => i.player.id === action.payload.request.playerId);
            if (toChange1) { toChange1.player.isInShortList = false }
            if (toChange2) { toChange2.player.isInShortList = false }
            return { ...playerValuations, players: newPlayers, playersToShow: newPlayersToShow }

        case PLAYERS_VALUATIONS_DECLARE_INTEREST.REQUEST:
            let newPlayersDeclare = cloneDeep(playerValuations.players);
            let newPlayersToShowDeclare = cloneDeep(playerValuations.playersToShow);
            let toChangeDeclare1 = newPlayersDeclare.find(i => i.player.id === action.payload.playerId);
            let toChangeDeclare2 = newPlayersToShowDeclare.find(i => i.player.id === action.payload.playerId);
            if (toChangeDeclare1) { toChangeDeclare1.player.interestWasDeclared = true }
            if (toChangeDeclare2) { toChangeDeclare2.player.interestWasDeclared = true }
            return { ...playerValuations, players: newPlayersDeclare, playersToShow: newPlayersToShowDeclare }

        case PLAYERS_VALUATIONS_DECLARE_INTEREST.ERROR:
            let newPlayersDeclareFail = cloneDeep(playerValuations.players);
            let newPlayersToShowDeclareFail = cloneDeep(playerValuations.playersToShow);
            let toChangeDeclareFail1 = newPlayersDeclareFail.find(i => i.player.id === action.payload.playerId);
            let toChangeDeclareFail2 = newPlayersToShowDeclareFail.find(i => i.player.id === action.payload.playerId);
            if (toChangeDeclareFail1) { toChangeDeclareFail1.player.interestWasDeclared = false }
            if (toChangeDeclareFail2) { toChangeDeclareFail2.player.interestWasDeclared = false }
            return { ...playerValuations, players: newPlayersDeclareFail, playersToShow: newPlayersToShowDeclareFail }

        case PLAYERS_VALUATIONS_PLAYER_INTEREST.REQUEST:
            const map = {
                1: 'isTransferAvailabilityDeclared',
                2: 'isLoanAvailabilityDeclared',
                3: 'isLoanContributionDeclared',
                4: 'isAskingPriceDeclared',
            };
            let newPlayersInterest = cloneDeep(playerValuations.players);
            let newPlayersToShowInterest = cloneDeep(playerValuations.playersToShow);
            let toChangeInterest1 = newPlayersInterest.find(i => i.player.id === action.payload.playerId);
            let toChangeInterest2 = newPlayersToShowInterest.find(i => i.player.id === action.payload.playerId);
            if (toChangeInterest1) { toChangeInterest1.player[map[action.payload.requestType]] = true }
            if (toChangeInterest2) { toChangeInterest2.player[map[action.payload.requestType]] = true }
            return { ...playerValuations, players: newPlayersInterest, playersToShow: newPlayersToShowInterest }

        case PLAYERS_VALUATIONS_PLAYER_INTEREST.ERROR:
            const map1 = {
                1: 'isTransferAvailabilityDeclared',
                2: 'isLoanAvailabilityDeclared',
                3: 'isLoanContributionDeclared',
                4: 'isAskingPriceDeclared',
            };
            let newPlayersInterestFail = cloneDeep(playerValuations.players);
            let newPlayersToShowInterestFail = cloneDeep(playerValuations.playersToShow);
            let toChangeInterestFail1 = newPlayersInterestFail.find(i => i.player.id === action.payload.playerId);
            let toChangeInterestFail2 = newPlayersToShowInterestFail.find(i => i.player.id === action.payload.playerId);
            if (toChangeInterestFail1) { toChangeInterestFail1.player[map1[action.payload.requestType]] = false }
            if (toChangeInterestFail2) { toChangeInterestFail2.player[map1[action.payload.requestType]] = false }
            return { ...playerValuations, players: newPlayersInterestFail, playersToShow: newPlayersToShowInterestFail }

        default:
            return playerValuations;
    }
};


export const PLAYERS_VALUATIONS_FETCH = asyncActionType('app/financial-insights/PLAYERS_VALUATIONS_FETCH');
export const PLAYERS_VALUATIONS_ADD_TO_SHORTLIST = asyncActionType('app/financial-insights/PLAYERS_VALUATIONS_ADD_TO_SHORTLIST');
export const PLAYERS_VALUATIONS_REMOVE_FROM_SHORTLIST = asyncActionType('app/financial-insights/PLAYERS_VALUATIONS_REMOVE_FROM_SHORTLIST');
export const PLAYERS_VALUATIONS_DECLARE_INTEREST = asyncActionType('app/financial-insights/PLAYERS_VALUATIONS_DECLARE_INTEREST');
export const PLAYERS_VALUATIONS_PLAYER_INTEREST = asyncActionType('app/financial-insights/PLAYERS_VALUATIONS_PLAYER_INTEREST');
export const PLAYERS_VALUATION_PLAYER_SELECTED = 'app/financial-insights/PLAYERS_VALUATION_PLAYER_SELECTED';
export const PLAYERS_VALUATION_SHOW_MORE = 'app/financial-insights/PLAYERS_VALUATION_SHOW_MORE';
export const PLAYERS_VALUATION_SHOW_FIRST = 'app/financial-insights/PLAYERS_VALUATION_SHOW_FIRST';
export const PLAYERS_VALUATION_SET_SORTING = 'app/financial-insights/PLAYERS_VALUATION_SET_SORTING';

export const getPlayerValuationsLoadingStatus = state =>
    getRequests(state).some(r => r.type === PLAYERS_VALUATIONS_FETCH);


