import { StateController } from 'utils/action-declaration';
import { PitchInisghtService } from 'api/agency/pitch-insight/agency-pitch-insight.service'
import { PitchInsightResponse } from 'api/agency/pitch-insight/pitch-insight-response'
import { AppState } from 'root.reducer';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { ActionType, PageType } from 'constants/enums';

class AgencyPitchState {
    body: PitchInsightResponse;
    isLoading: boolean
}

const defaultState: AgencyPitchState = {
    body: null,
    isLoading: false
}

const stateController = new StateController<AgencyPitchState>(
    "AGENCY/PITCH_INSIGHT",
    defaultState
);

class Actions {

    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState))
        }
    }

    public static reload() {
        return async (dispatch) => {
            dispatch(stateController.setState({ isLoading: true }));
            var pithInsight = await PitchInisghtService.get();
            dispatch(stateController.setState({ body: pithInsight, isLoading: false }))
        }
    }

    public static loadInfo() {
        return async (dispatch) => {
            dispatch(stateController.setState({ isLoading: true }));
            var pithInsight = await PitchInisghtService.get();
            dispatch(stateController.setState({ body: pithInsight, isLoading: false }))
        }
    }

    public static load() {
        return async (dispatch) => {
            dispatch(Actions.loadInfo())

            var searchParams = new URL(window.location.href).searchParams;
            var isEa = searchParams.get("ea");
            if (isEa) {
                if (searchParams.get("type") === "wkl") {
                    dispatch(userActivityInsert({
                        PageName: `Mail [Weekly]`,
                        Message: `Opened Pitch Insights`,
                    }))
                }
            }
            dispatch(Actions.sendUserActivity());
        }
    }

    public static sendUserActivity = () => {
        return (dispatch) => {
            dispatch(userActivityInsert({
                PageName: 'Pitch Insights',
                Message: 'Opened Pitch Insights',
                PageType: PageType.Pitch
            }))
        }
    }

    public static onTestInterestClick(pitchId: number, isModalMode?: boolean) {
        return async (dispatch, getState: () => AppState) => {
            await PitchInisghtService.testInterest(pitchId);
            const body = getState().agency.pitchInsight.body;

            const modifiedBody = {
                ...body,
                activity: body.activity.map(s => {
                    if (s.pitchId == pitchId) {
                        return { ...s, testInterest: { ...s.testInterest, interestStatusAt: new Date(), canTestInterest: false } }
                    }
                    return s;
                })
            }


            dispatch(stateController.setState({ body: modifiedBody }))

            const pitch = modifiedBody.activity.find(x => x.pitchId == pitchId);
            if (pitch) {
                dispatch(userActivityInsert({
                    PageName: `Pitch Insights [${isModalMode ? 'Most pitched' : 'Recent activity'}]`,
                    Message: 'Tested Interest',
                    PlayerId: pitch.playerId,
                    ClubId: pitch.pitchetTo.id,
                    ActionType: ActionType.TestedInterest,
                    PageType: PageType.Pitch
                }));
            }

        }
    }

    public static sendUpgradeUserActivity = (pageName) => {
        return (dispatch) => {
            dispatch(userActivityInsert({
                PageName: pageName,
                Message: 'Sent Upgrade Request',
            }))
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    AgencyPitchState as State,
    Actions as Actions,
    stateController as Controller
};



