import axios from 'axios-config';

export class UserReaction {
    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async shouldCollectFeedback(userId: number) : Promise<boolean> {
        const { data } = await axios.get(
            `api/UserReaction/CheckReactionRules/${userId}`,
            this.axiosConfig
        )
        return data.isShow
    }

    public static async sendFeedback(score?: number, message?: string) : Promise<boolean> {
        const { data } = await axios.post(
            `api/UserReaction/CreateReaction`,
            { score, message },
            this.axiosConfig
        )
        return data
    }
}