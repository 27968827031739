import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'root.reducer';
import Button from 'components/Buttons/Button/Button';
import Panel from 'components/Panel/Panel';
import SpinnerCentered from 'components/Spinner/spinner-centered';
import { Actions } from 'pages/landing-page/redux/speaker-card.controller';
import { userPaths } from 'routes/paths';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import Comolli from 'media/images/events/comolli.png';
import Toulouse from 'media/images/events/toulouse-fc.png';
import Irarragorri from 'media/images/events/Alejandro-Irarragorri.jpg';
import AtlasFC from 'media/images/events/Atlas-FC-Logo.jpg';
import SantosLaguna from 'media/images/events/Santos-Laguna.png';
import ArneFriedrich from 'media/images/events/Arne-Friedrich-Large.png';
import HerthaBscLogo from 'media/images/events/hertha-bsc-logo.png';
import './speaker-card.scss';
import { PageType } from 'constants/enums';
import { getEventInfo } from 'app/events/components/authorized-event-page/events-map/eventsMap';
import { getAuth } from 'store/auth/authReducer';
import historyAccessor from 'history-accessor';

class OwnProps {
    isAgentPage: boolean;
}

class StateProps {
    canShowComponent: boolean;
    isDismissing: boolean;
    eventId: number;
}
class DispatchProps {
    init: () => void;
    dismiss: (isAgentPage: boolean) => void;
    userActivityInsert: (obj: any) => void;
}

const hideSpeakerCard = true

export class SpeakerCard extends Component<StateProps & DispatchProps & OwnProps, any> {

    componentDidMount() {
        this.props.init();
    }

    render() {
        if (this.props.canShowComponent === false || hideSpeakerCard) return null
        return (
            <Panel compact className={`lp-speaker-card ${this.props.isDismissing ? 'dismissing' : ''}`}>
                <div className="lp-speaker-card__body">

                    <div className="lp-speaker-card__header">TransferRoom Virtual Summit - Speaker Announcement</div>
                    <div>10th November 2021</div>

                    <div className="lp-speaker-card__info">
                        <section>
                            <div>
                                <div style={{ width: '100%', height: 'auto', paddingTop: '100%', borderRadius: '100%', backgroundImage: `url('${ArneFriedrich}')`, backgroundPosition: 'center', backgroundSize: 'cover' }}></div>
                                <img style={{ width: "22px" }} className="lp-speaker-card__logo" alt={'Hertha BSC'} src={HerthaBscLogo} />

                                {/* Template for two logos */}
                                {/* <img style={{ width: "40%" }} className="lp-speaker-card__logo first-logo" alt={'Atlas FC'} src={AtlasFC} />
                                <img style={{ width: "40%" }} className="lp-speaker-card__logo extra-logo" alt={'Santos Laguna'} src={SantosLaguna} /> */}
                            </div>
                            <div>
                                <div className="lp-speaker-card__speaker-header">Arne Friedrich</div>
                                <div className="lp-speaker-card__subheader">Sporting Director, Hertha BSC</div>
                                <div className="lp-speaker-card__speaker-list-header">Points of Discussion:</div>
                                <ul className={'lp-speaker-card__speaker-list'}>
                                    <li> <span className="fa fa-check" /> Navigating crises through leadership </li>
                                    <li> <span className="fa fa-check" /> Developing and measuring emotional wellbeing </li>
                                    <li> <span className="fa fa-check" /> The importance of creating a winning team behind the team </li>
                                </ul>
                            </div>
                        </section>

                        {/* <section>
                            <div>
                                <img style={{ width: "100%" }} className="" alt={'damien-comolli'} src={Comolli} />
                                <img style={{ width: "40%" }} className="lp-speaker-card__logo" alt={'pmg'} src={Toulouse} />
                            </div>
                            <div>
                                <div className="lp-speaker-card__header-blue">Damien Comolli</div>
                                <div className="lp-speaker-card__subheader">President, Toulouse FC</div>
                                <div className="lp-speaker-card__subheader-blue">Points of Discussion:</div>
                                <ul className={'lp-speaker-card__speaker-list'}>
                                    <li> <span className="fa fa-check" /> From ridicule to respect: being an early adopter of data </li>
                                    <li> <span className="fa fa-check" /> Luka Modric and the dysfunctional traditional scouting model </li>
                                    <li> <span className="fa fa-check" /> Devil in the detail: how deals are done </li>
                                </ul>
                            </div>
                        </section> */}

                    </div>
                </div>
                <footer>
                    <Button effect="no" large disabled={false} type="primary" onClick={() => {
                        this.props.userActivityInsert({
                            PageName: this.props.isAgentPage ? "Agency Home [Event card Speaker]" : "Home [Event card Speaker]",
                            Message: "Register",
                            PageType: this.props.isAgentPage ? PageType.AgencyHome : PageType.Home,
                            EventId: this.props.eventId
                        })
                        historyAccessor.push(userPaths.eventRegistration);
                    }} >
                        REGISTER
                    </Button>
                    <Button type="primary" disabled={false} effect="outline" large onClick={() => { this.props.dismiss(this.props.isAgentPage) }} >
                        NOT ATTENDING
                    </Button>
                </footer>
            </Panel>
        )
    }
}

const mapStateToProps = (state: AppState): StateProps => ({
    canShowComponent: state.landingPage.speakerCard.canShowComponent,
    isDismissing: state.landingPage.speakerCard.isDismissing,
    eventId: getAuth(state).eventId
})

const mapDispatchToProps = (dispatch): DispatchProps => {
    return {
        init: () => { dispatch(Actions.init()) },
        dismiss: (isAgentPage: boolean) => { dispatch(Actions.dismiss(isAgentPage)) },
        userActivityInsert: (obj: any) => { dispatch(userActivityInsert(obj)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SpeakerCard)