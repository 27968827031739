import {
    asyncActionType
} from 'store/actionTypes';
import { getRequests } from 'store/requests/requestsReducer';

const initialState = {
    players: null,
};

export default (playersInfo = initialState, action) => {
    switch (action.type) {
        case PLAYER_INFO_FETCH.SUCCESS:
            return {
                ...playersInfo,
                players: action.payload.data.output,
            };

        default:
            return playersInfo;
    }
};

export const PLAYER_INFO_FETCH = asyncActionType('app/playerInfo/FETCH');
export const getIsSimilarityLoading = state => {
    return getRequests(state).some(r => r.type === PLAYER_INFO_FETCH);
}

export const getSimilarPlayersById = (playerId, state) => {
    return state.playersInfo.players.map(item => item.player).find(item => item.id === playerId);
}

