import * as redux from 'redux';
import * as activeLoansController from './loan-insights-controller';
import * as completedLoansController from './completed-loans-controller'
import * as loanBenchmarkingController from './benchmarking-controller'

export class LoanInsightsState {
    activeLoans: activeLoansController.State;
    completedLoans: completedLoansController.State;
    loanBenchmarking: loanBenchmarkingController.State;
}

export function combineReducers() {

    let loanInsights = {
        activeLoans: activeLoansController.Reducer,
        completedLoans: completedLoansController.Reducer,
        loanBenchmarking: loanBenchmarkingController.Reducer
    };

    return redux.combineReducers(loanInsights);
} 