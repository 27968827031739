import { PLUS_PITCHES_FETCH, TEST_INTEREST } from './indexReducer';
import { arrayToObject } from 'services/utils';
import moment from 'moment';

const initialState = {
    ids: null,
    byId: null
};

export default (plusPitchesState = initialState, action) => {
    switch (action.type) {
        case PLUS_PITCHES_FETCH.REQUEST: {
            return {
                ...plusPitchesState,
                ids: null,
            }
        }
        case PLUS_PITCHES_FETCH.SUCCESS: {
            return {
                ...plusPitchesState,
                byId: { ...(arrayToObject(action.payload.data)) },
                ids: action.payload.data.map(i => i.id)
            }
        }
        case TEST_INTEREST.SUCCESS: {
            let squadId = action.payload.request.payload.pitchSquadId;
            let pitchId = action.payload.request.payload.pitchId;
            let newSquads = plusPitchesState.byId[pitchId].squads.map(i => {
                if (i.id === squadId) {
                    i.interestTestedAt = moment.utc().format();
                    i.canTestInterest = false;
                    i.testInterestStatus = 0;
                }
                return i;
            });
            return {
                ...plusPitchesState,
                byId: {
                    ...plusPitchesState.byId,
                    [squadId]: {
                        ...plusPitchesState.byId[squadId],
                        squads: newSquads
                    }
                }
            }
        }
        default:
            return plusPitchesState;
    }
};