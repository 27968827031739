export const eventClubs = [
  {
    squadId: 2,
    squadName: "AS Roma",
    shortSquadName: "AS Roma",
    squadLogo: "logo_1835_AS_Roma.png",
  },
  {
    squadId: 3,
    squadName: "Juventus FC",
    shortSquadName: "Juventus",
    squadLogo: "logo_1832_Juventus_FC.png",
  },
  {
    squadId: 17,
    squadName: "Genoa CFC",
    shortSquadName: "Genoa",
    squadLogo: "logo_1843_Genoa_CFC.png",
  },
  {
    squadId: 45,
    squadName: "Manchester United",
    shortSquadName: "Man Utd",
    squadLogo: null,
  },
  {
    squadId: 46,
    squadName: "Liverpool FC",
    shortSquadName: "Liverpool",
    squadLogo: "logo_1123_Liverpool_FC.png",
  },
  {
    squadId: 47,
    squadName: "Tottenham Hotspur",
    shortSquadName: "Tottenham",
    squadLogo: "logo_1122_Tottenham_Hotspur.png",
  },
  {
    squadId: 48,
    squadName: "Manchester City",
    shortSquadName: "Man City",
    squadLogo: "1625_Manchester_City.png",
  },
  {
    squadId: 49,
    squadName: "Arsenal FC",
    shortSquadName: "Arsenal",
    squadLogo: "logo_1121_Arsenal_FC.png",
  },
  {
    squadId: 50,
    squadName: "Chelsea FC",
    shortSquadName: "Chelsea",
    squadLogo: "logo_Chelsea_FC.png",
  },
  {
    squadId: 60,
    squadName: "Newcastle United",
    shortSquadName: "Newcastle",
    squadLogo: "logo_Newcastle_United.png",
  },
  {
    squadId: 62,
    squadName: "Southampton FC",
    shortSquadName: "Southampton",
    squadLogo: "1619_Southampton_FC.png",
  },
  {
    squadId: 63,
    squadName: "Everton FC",
    shortSquadName: "Everton",
    squadLogo: "logo_1124_Everton_FC.png",
  },
  {
    squadId: 64,
    squadName: "Crystal Palace",
    shortSquadName: "Crystal Palace",
    squadLogo: "logo_1128_Crystal_Palace.png",
  },
  {
    squadId: 65,
    squadName: "Wolverhampton Wanderers",
    shortSquadName: "Wolves",
    squadLogo: "logo_Wolverhampton_Wanderers.png",
  },
  {
    squadId: 66,
    squadName: "Leicester City",
    shortSquadName: "Leicester",
    squadLogo: "logo_Leicester_City.png",
  },
  {
    squadId: 67,
    squadName: "West Ham United",
    shortSquadName: "West Ham",
    squadLogo: "1633_West_Ham_United.png",
  },
  {
    squadId: 68,
    squadName: "Cardiff City",
    shortSquadName: "Cardiff",
    squadLogo: "logo_1151_Cardiff_City.png",
  },
  {
    squadId: 69,
    squadName: "Watford FC",
    shortSquadName: "Watford",
    squadLogo: "1644_Watford_FC.png",
  },
  {
    squadId: 70,
    squadName: "Brighton & Hove Albion",
    shortSquadName: "Brighton",
    squadLogo: "logo_Brighton_And_Hove_Albion_FC.png",
  },
  {
    squadId: 71,
    squadName: "AFC Bournemouth",
    shortSquadName: "Bournemouth",
    squadLogo: "logo_1134_AFC_Bournemouth.png",
  },
  {
    squadId: 72,
    squadName: "Huddersfield Town",
    shortSquadName: "Huddersfield",
    squadLogo: "1673_Huddersfield_Town.png",
  },
  {
    squadId: 174,
    squadName: "CA Talleres",
    shortSquadName: "CA Talleres",
    squadLogo: "logo_2588_Club_Atletico_Talleres.png",
  },
  {
    squadId: 329,
    squadName: "Stromsgodset IF",
    shortSquadName: "Stromsgodset",
    squadLogo: "7111_Strømsgodset_IF.png",
  },
  {
    squadId: 489,
    squadName: "Helmond Sport",
    shortSquadName: "Helmond Sport",
    squadLogo: "logo_Helmond_Sport.png",
  },
  {
    squadId: 513,
    squadName: "Rodez AF",
    shortSquadName: "Rodez AF",
    squadLogo: "3867_Rodez_AF.png",
  },
  {
    squadId: 542,
    squadName: "FC Atyrau",
    shortSquadName: "Atyrau",
    squadLogo: "logo_542_FK_Atyrau.png",
  },
  {
    squadId: 606,
    squadName: "Quevilly - Rouen Metropole",
    shortSquadName: "QRM",
    squadLogo: "3865_Quevilly_-_Rouen_Métropole.png",
  },
  {
    squadId: 862,
    squadName: "CD O'Higgins",
    shortSquadName: "O'Higgins",
    squadLogo: "12302_CD_O'Higgins.png",
  },
  {
    squadId: 976,
    squadName: "AS Monaco",
    shortSquadName: "Monaco",
    squadLogo: "19830_AS_Monaco.png",
  },
  {
    squadId: 1003,
    squadName: "Hull City",
    shortSquadName: "Hull City",
    squadLogo: "1672_Hull_City.png",
  },
  {
    squadId: 1142,
    squadName: "AZ Alkmaar",
    shortSquadName: "AZ Alkmaar",
    squadLogo: "10_AZ_Alkmaar.png",
  },
  {
    squadId: 1421,
    squadName: "Gremio Foot-Ball Porto Alegrense",
    shortSquadName: "Gremio",
    squadLogo: "logo_2605_Gremio_Foot-Ball_Porto_Alegrense.png",
  },
  {
    squadId: 1740,
    squadName: "Amiens SC",
    shortSquadName: "Amiens SC",
    squadLogo: "3789_Amiens_SC.png",
  },
  {
    squadId: 1771,
    squadName: "Bryne FK",
    shortSquadName: "Bryne",
    squadLogo: "7107_Bryne_FK.png",
  },
  {
    squadId: 1930,
    squadName: "FC Viktoria Koln",
    shortSquadName: "Viktoria Koln",
    squadLogo: "logo_1388_FC_Viktoria_Koln.png",
  },
  {
    squadId: 1949,
    squadName: "Independiente Medellin",
    shortSquadName: "Indep. Medellin",
    squadLogo: "12399_Independiente_Medellín.png",
  },
  {
    squadId: 2031,
    squadName: "1.FC Union Berlin",
    shortSquadName: "Union Berlin",
    squadLogo: "logo_1277_1.FC_Union_Berlin.png",
  },
  {
    squadId: 2226,
    squadName: "Sevilla FC",
    shortSquadName: "Sevilla FC",
    squadLogo: "680_Sevilla_FC.png",
  },
  {
    squadId: 2332,
    squadName: "Cercle Brugge",
    shortSquadName: "Cercle Brugge",
    squadLogo: "5070_Cercle_Brugge.png",
  },
  {
    squadId: 2858,
    squadName: "Hapoel Beer Sheva",
    shortSquadName: "H. Beer Sheva",
    squadLogo: "13271_Hapoel_Beer_Sheva.png",
  },
  {
    squadId: 3028,
    squadName: "Palermo FC",
    shortSquadName: "Palermo",
    squadLogo: "3171_Palermo_FC.png",
  },
  {
    squadId: 3177,
    squadName: "FK Spartak Subotica",
    shortSquadName: "Spartak",
    squadLogo: "logo_1669_FK_Spartak_Subotica.png",
  },
  {
    squadId: 3695,
    squadName: "SK Slavia Prague",
    shortSquadName: "Slavia Prague",
    squadLogo: "11242_SK_Slavia_Prague.png",
  },
  {
    squadId: 3740,
    squadName: "KV Kortrijk",
    shortSquadName: "KV Kortrijk",
    squadLogo: "logo_KV_Kortrijk.png",
  },
  {
    squadId: 3774,
    squadName: "Club Necaxa",
    shortSquadName: "Necaxa",
    squadLogo: "logo_2283_Club_Necaxa.png",
  },
  {
    squadId: 3795,
    squadName: "Shonan Bellmare",
    shortSquadName: "Shonan Bellmare",
    squadLogo: "logo_2422_Shonan_Bellmare.png",
  },
  {
    squadId: 4230,
    squadName: "Stade de Reims",
    shortSquadName: "Stade Reims",
    squadLogo: "3802_Stade_Reims.png",
  },
  {
    squadId: 4234,
    squadName: "Queens Park Rangers",
    shortSquadName: "QPR",
    squadLogo: "logo_1149_Queens_Park_Rangers.png",
  },
  {
    squadId: 4474,
    squadName: "Burgos CF",
    shortSquadName: "Burgos CF",
    squadLogo: "logo_1781_Burgos_CF.png",
  },
  {
    squadId: 5065,
    squadName: "RWD Molenbeek",
    shortSquadName: "RWDM",
    squadLogo: "5622_RWD_Molenbeek.png",
  },
  {
    squadId: 5072,
    squadName: "SC Paderborn 07",
    shortSquadName: "SC Paderborn",
    squadLogo: "logo_1303_SC_Paderborn_07.png",
  },
  {
    squadId: 5109,
    squadName: "PEC Zwolle",
    shortSquadName: "PEC Zwolle",
    squadLogo: "logo_1543_PEC_Zwolle.png",
  },
  {
    squadId: 5282,
    squadName: "PSV Eindhoven",
    shortSquadName: "PSV Eindhoven",
    squadLogo: "11_PSV_Eindhoven.png",
  },
  {
    squadId: 5306,
    squadName: "Rakow Czestochowa",
    shortSquadName: "Rakow",
    squadLogo: "logo_1622_Rakow_Czestochowa.png",
  },
  {
    squadId: 5359,
    squadName: "Caracas FC",
    shortSquadName: "Caracas FC",
    squadLogo: "15680_Caracas_FC.png",
  },
  {
    squadId: 5842,
    squadName: "Hobro IK",
    shortSquadName: "Hobro IK",
    squadLogo: "logo_Hobro_IK.png",
  },
  {
    squadId: 5924,
    squadName: "Everton de Vina del Mar",
    shortSquadName: "CD Everton",
    squadLogo: "12293_CD_Everton.png",
  },
  {
    squadId: 6051,
    squadName: "Real Valladolid CF",
    shortSquadName: "Real Valladolid",
    squadLogo: "690_Real_Valladolid_CF.png",
  },
  {
    squadId: 6059,
    squadName: "CD Leganes",
    shortSquadName: "CD Leganes",
    squadLogo: "712_CD_Leganés.png",
  },
  {
    squadId: 6207,
    squadName: "Molde FK",
    shortSquadName: "Molde FK",
    squadLogo: "7098_Molde_FK.png",
  },
  {
    squadId: 6863,
    squadName: "Rayo Vallecano",
    shortSquadName: "Rayo Vallecano",
    squadLogo: "713_Rayo_Vallecano.png",
  },
  {
    squadId: 7354,
    squadName: "Forest Green Rovers",
    shortSquadName: "Forest Green",
    squadLogo: "logo_Forest_Green_Rovers.png",
  },
  {
    squadId: 7390,
    squadName: "Club Atletico Lanus",
    shortSquadName: "Lanus",
    squadLogo: "12063_Club_Atlético_Lanús.png",
  },
  {
    squadId: 7400,
    squadName: "Botafogo de Futebol e Regatas",
    shortSquadName: "Botafogo",
    squadLogo: "logo_7400_Botafogo_de_Futebol_e_Regatas.png",
  },
  {
    squadId: 7783,
    squadName: "Pyramids FC",
    shortSquadName: "Pyramids FC",
    squadLogo: "27533_Pyramids_Al_Ahram_FC.png",
  },
  {
    squadId: 7819,
    squadName: "Sarpsborg 08 FF",
    shortSquadName: "Sarpsborg 08",
    squadLogo: "logo_1472_Sarpsborg_08_FF.png",
  },
  {
    squadId: 8064,
    squadName: "Kawasaki Frontale",
    shortSquadName: "Kawasaki Front.",
    squadLogo: "logo_2410_Kawasaki_Frontale.png",
  },
  {
    squadId: 8110,
    squadName: "Hertha BSC",
    shortSquadName: "Hertha BSC",
    squadLogo: "2457_Hertha_BSC.png",
  },
  {
    squadId: 8498,
    squadName: "Odense Boldklub",
    shortSquadName: "Odense BK",
    squadLogo: "logo_1412_Odense_Boldklub.png",
  },
  {
    squadId: 8665,
    squadName: "New York City FC",
    shortSquadName: "New York City",
    squadLogo: "logo_2109_New_York_City_FC.png",
  },
  {
    squadId: 8839,
    squadName: "Ferroviaria",
    shortSquadName: "Ferroviaria",
    squadLogo: "logo_8839_Associacao_Ferroviaria_de_Esportes_(SP).png",
  },
  {
    squadId: 9029,
    squadName: "FC Ingolstadt 04",
    shortSquadName: "FC Ingolstadt",
    squadLogo: "2579_FC_Ingolstadt_04.png",
  },
  {
    squadId: 9150,
    squadName: "Venezia FC",
    shortSquadName: "Venezia",
    squadLogo: "3191_Venezia_FC.svg",
  },
  {
    squadId: 9657,
    squadName: "Los Angeles FC",
    shortSquadName: "LAFC",
    squadLogo: "62148_Los_Angeles_FC.png",
  },
  {
    squadId: 9954,
    squadName: "Sparta Rotterdam",
    shortSquadName: "Sparta R.",
    squadLogo: "logo_Sparta_Rotterdam.png",
  },
  {
    squadId: 9984,
    squadName: "CA Velez Sarsfield",
    shortSquadName: "Velez Sarsfield",
    squadLogo: "12073_Club_Atlético_Vélez_Sarsfield.png",
  },
  {
    squadId: 10026,
    squadName: "Los Angeles Galaxy",
    shortSquadName: "Los Angeles",
    squadLogo: "7847_Los_Angeles_Galaxy.png",
  },
  {
    squadId: 10088,
    squadName: "RB Leipzig",
    shortSquadName: "RB Leipzig",
    squadLogo: "logo_1261_RB_Leipzig.png",
  },
  {
    squadId: 10158,
    squadName: "Toulouse FC",
    shortSquadName: "Toulouse FC",
    squadLogo: "3780_FC_Toulouse.png",
  },
  {
    squadId: 10223,
    squadName: "CD Castellon",
    shortSquadName: "Castellon",
    squadLogo: "logo_3039_CD_Castellon.png",
  },
  {
    squadId: 10231,
    squadName: "FC Schaffhausen",
    shortSquadName: "FC Schaffhausen",
    squadLogo: "logo_2359_FC_Schaffhausen.png",
  },
  {
    squadId: 10300,
    squadName: "FC Annecy",
    shortSquadName: "FC Annecy",
    squadLogo: "4427_FC_Annecy.png",
  },
  {
    squadId: 10317,
    squadName: "New York Red Bulls",
    shortSquadName: "New York",
    squadLogo: "logo_2112_New_York_Red_Bulls.png",
  },
  {
    squadId: 10337,
    squadName: "Club Social y Atletico Guillermo Brown",
    shortSquadName: "Guillermo Brown",
    squadLogo: "logo_10337_Club_Social_y_Atletico_Guillermo_Brown.png",
  },
  {
    squadId: 10371,
    squadName: "Audax Italiano",
    shortSquadName: "Audax Italiano",
    squadLogo: "12292_Audax_Italiano.png",
  },
  {
    squadId: 10422,
    squadName: "Vancouver Whitecaps FC",
    shortSquadName: "Vancouver",
    squadLogo: "15015_Vancouver_Whitecaps_FC.png",
  },
  {
    squadId: 10541,
    squadName: "USL Dunkerque",
    shortSquadName: "USL Dunkerque",
    squadLogo: "3904_USL_Dunkerque.png",
  },
  {
    squadId: 10781,
    squadName: "Kristiansund BK",
    shortSquadName: "Kristiansund",
    squadLogo: "logo_1477_Kristiansund_BK.png",
  },
  {
    squadId: 10931,
    squadName: "CA San Lorenzo de Almagro",
    shortSquadName: "San Lorenzo",
    squadLogo: "12071_Club_Atlético_San_Lorenzo_de_Almagro.png",
  },
  {
    squadId: 11034,
    squadName: "IF Elfsborg",
    shortSquadName: "Elfsborg",
    squadLogo: "logo_IF_Elfsborg.png",
  },
  {
    squadId: 11087,
    squadName: "SK Brann",
    shortSquadName: "Brann",
    squadLogo: "7095_SK_Brann.png",
  },
  {
    squadId: 11121,
    squadName: "Club Atletico Tucuman",
    shortSquadName: "Atl. Tucuman",
    squadLogo: "logo_11121_Club_Atletico_Tucuman.png",
  },
  {
    squadId: 11474,
    squadName: "Exeter City",
    shortSquadName: "Exeter City",
    squadLogo: "logo_1187_Exeter_City.png",
  },
  {
    squadId: 11544,
    squadName: "Grasshopper Club Zurich",
    shortSquadName: "Grasshoppers",
    squadLogo: "logo_1936_Grasshopper_Club_Zurich.png",
  },
  {
    squadId: 11978,
    squadName: "FK Jerv",
    shortSquadName: "Jerv",
    squadLogo: "logo_1484_FK_Jerv.gif",
  },
  {
    squadId: 12027,
    squadName: "CA Boston River",
    shortSquadName: "Boston River",
    squadLogo: "logo_2875_Club_Atletico_Boston_River.png",
  },
  {
    squadId: 12098,
    squadName: "CD Santa Clara",
    shortSquadName: "Santa Clara",
    squadLogo: "9634_CD_Santa_Clara.png",
  },
  {
    squadId: 12173,
    squadName: "Barnsley FC",
    shortSquadName: "Barnsley FC",
    squadLogo: "logo_1158_Barnsley_FC.png",
  },
  {
    squadId: 12294,
    squadName: "Olympique de Marseille",
    shortSquadName: "Olympique Marseille",
    squadLogo: "logo_1964_Olympique_Marseille.png",
  },
  {
    squadId: 12419,
    squadName: "Carlisle United",
    shortSquadName: "Carlisle United",
    squadLogo: "logo_1203_Carlisle_United.png",
  },
  {
    squadId: 12470,
    squadName: "VfL Wolfsburg",
    shortSquadName: "VfL Wolfsburg",
    squadLogo: "logo_VfL_Wolfsburg.png",
  },
  {
    squadId: 12598,
    squadName: "VfB Stuttgart",
    shortSquadName: "VfB Stuttgart",
    squadLogo: "logo_1273_VfB_Stuttgart.png",
  },
  {
    squadId: 12612,
    squadName: "Hammarby IF",
    shortSquadName: "Hammarby",
    squadLogo: "6710_Hammarby_IF.png",
  },
  {
    squadId: 12686,
    squadName: "FC Nordsjaelland",
    shortSquadName: "Nordsjaelland",
    squadLogo: "7458_Nordsjelland.png",
  },
  {
    squadId: 12761,
    squadName: "RCD Espanyol Barcelona",
    shortSquadName: "Espanyol",
    squadLogo: "logo_1722_RCD_Espanyol_Barcelona.png",
  },
  {
    squadId: 13008,
    squadName: "Paris FC",
    shortSquadName: "Paris FC",
    squadLogo: "3846_Paris_FC.png",
  },
  {
    squadId: 13058,
    squadName: "Feyenoord Rotterdam",
    shortSquadName: "Feyenoord",
    squadLogo: "logo_1533_Feyenoord_Rotterdam.png",
  },
  {
    squadId: 13114,
    squadName: "Real Zaragoza",
    shortSquadName: "Real Zaragoza",
    squadLogo: "689_Real_Zaragoza.png",
  },
  {
    squadId: 13297,
    squadName: "GD Estoril Praia",
    shortSquadName: "Estoril Praia",
    squadLogo: "logo_1680_GD_Estoril_Praia.png",
  },
  {
    squadId: 13404,
    squadName: "Houston Dynamo FC",
    shortSquadName: "Houston",
    squadLogo: "7944_Houston_Dynamo_FC.png",
  },
  {
    squadId: 13510,
    squadName: "FC Utrecht",
    shortSquadName: "FC Utrecht",
    squadLogo: "17_FC_Utrecht.png",
  },
  {
    squadId: 13576,
    squadName: "Sonderjyske Fodbold",
    shortSquadName: "Sonderjyske",
    squadLogo: "7499_Sønderjyske_Fodbold.png",
  },
  {
    squadId: 13625,
    squadName: "FC Stade-Lausanne-Ouchy",
    shortSquadName: "Stade-Lausanne",
    squadLogo: "6526_FC_Stade-Lausanne-Ouchy.png",
  },
  {
    squadId: 13679,
    squadName: "FC Lausanne-Sport",
    shortSquadName: "Lausanne-Sport",
    squadLogo: "6434_FC_Lausanne-Sport.png",
  },
  {
    squadId: 13756,
    squadName: "Debreceni VSC",
    shortSquadName: "Debrecen",
    squadLogo: "logo_1634_Debreceni_VSC.png",
  },
  {
    squadId: 14079,
    squadName: "Fortaleza Esporte Clube",
    shortSquadName: "Fortaleza",
    squadLogo: "5663_Fortaleza_Esporte_Clube.png",
  },
  {
    squadId: 14291,
    squadName: "FK Sarajevo",
    shortSquadName: "FK Sarajevo",
    squadLogo: "9918_FK_Sarajevo.png",
  },
  {
    squadId: 14733,
    squadName: "FC Arouca",
    shortSquadName: "Arouca",
    squadLogo: "logo_1690_FC_Arouca.png",
  },
  {
    squadId: 15024,
    squadName: "Yverdon Sport FC",
    shortSquadName: "Yverdon Sport",
    squadLogo: "6422_Yverdon_Sport_FC.png",
  },
  {
    squadId: 15130,
    squadName: "AIK",
    shortSquadName: "AIK",
    squadLogo: "logo_1436_AIK_Solna.png",
  },
  {
    squadId: 15241,
    squadName: "CD Magallanes",
    shortSquadName: "Magallanes",
    squadLogo: "12321_Club_Deportivo_Magallanes.png",
  },
  {
    squadId: 15326,
    squadName: "Albion FC",
    shortSquadName: "Albion FC",
    squadLogo: "logo_15326_Albion_FC.png",
  },
  {
    squadId: 15469,
    squadName: "Club Nacional",
    shortSquadName: "Nacional",
    squadLogo: "15634_Club_Nacional.png",
  },
  {
    squadId: 15517,
    squadName: "Oud-Heverlee Leuven",
    shortSquadName: "OH Leuven",
    squadLogo: "5121_Oud-Heverlee_Leuven.png",
  },
  {
    squadId: 15549,
    squadName: "FC Basel 1893",
    shortSquadName: "FC Basel",
    squadLogo: "logo_1932_FC_Basel_1893.png",
  },
  {
    squadId: 15715,
    squadName: "Diosgyori VTK",
    shortSquadName: "Diosgyor",
    squadLogo: "logo_15715_Diosgyori_VTK.png",
  },
  {
    squadId: 15741,
    squadName: "US Triestina",
    shortSquadName: "Triestina",
    squadLogo: "logo_1905_US_Triestina.png",
  },
  {
    squadId: 15817,
    squadName: "FC Dinamo Bucharest",
    shortSquadName: "FC Dinamo",
    squadLogo: "logo_2342_Dinamo_Bukarest.png",
  },
  {
    squadId: 15862,
    squadName: "Universitario de Deportes",
    shortSquadName: "Universitario",
    squadLogo: "logo_15862_Universitario_de_Deportes.png",
  },
  {
    squadId: 15872,
    squadName: "Wigan Athletic",
    shortSquadName: "Wigan",
    squadLogo: "logo_1163_Wigan_Athletic.png",
  },
  {
    squadId: 16046,
    squadName: "Olympique Lyonnais",
    shortSquadName: "Olympique Lyon",
    squadLogo: "3766_Olympique_Lyon.png",
  },
  {
    squadId: 16173,
    squadName: "SCR Altach",
    shortSquadName: "SCR Altach",
    squadLogo: "logo_1946_SC_Rheindorf_Altach.png",
  },
  {
    squadId: 16247,
    squadName: "Club Athletico Paranaense",
    shortSquadName: "Athletico-PR",
    squadLogo: "5651_Club_Athletico_Paranaense.png",
  },
  {
    squadId: 16335,
    squadName: "Beerschot VA",
    shortSquadName: "Beerschot VA",
    squadLogo: "5280_K._Beerschot_V.A..png",
  },
  {
    squadId: 16727,
    squadName: "Fortaleza CEIF",
    shortSquadName: "Fortaleza CEIF",
    squadLogo: "12427_Fortaleza_CEIF_FC.png",
  },
  {
    squadId: 16832,
    squadName: "Boldklubben af 1893",
    shortSquadName: "B.93",
    squadLogo: "logo_16832_Boldklubben_af_1893.png",
  },
  {
    squadId: 16980,
    squadName: "Rio Ave FC",
    shortSquadName: "Rio Ave",
    squadLogo: "logo_Rio_Ave_FC.png",
  },
  {
    squadId: 17053,
    squadName: "Oldham Athletic",
    shortSquadName: "Oldham Athletic",
    squadLogo: "logo_Oldham_Athletic.png",
  },
  {
    squadId: 17288,
    squadName: "Riga FC",
    shortSquadName: "Riga",
    squadLogo: "logo_2924_Riga_FC.png",
  },
  {
    squadId: 17417,
    squadName: "BSC Young Boys",
    shortSquadName: "BSC Young Boys",
    squadLogo: "logo_1934_BSC_Young_Boys.png",
  },
  {
    squadId: 17418,
    squadName: "Valerenga Fotball Elite",
    shortSquadName: "Valerenga",
    squadLogo: "7092_Vålerenga_Fotball.png",
  },
  {
    squadId: 17668,
    squadName: "FC Famalicao",
    shortSquadName: "Famalicao",
    squadLogo: "logo_FC_Famalicao.png",
  },
  {
    squadId: 17757,
    squadName: "Rabotnicki Skopje",
    shortSquadName: "Rabotnicki",
    squadLogo: "10014_Rabotnicki_Skopje.png",
  },
  {
    squadId: 17770,
    squadName: "Adelaide United",
    shortSquadName: "Adelaide United",
    squadLogo: "logo_2482_Adelaide_United.png",
  },
  {
    squadId: 17795,
    squadName: "Eintracht Braunschweig",
    shortSquadName: "E. Braunschweig",
    squadLogo: "logo_Eintracht_Braunschweig.png",
  },
  {
    squadId: 17825,
    squadName: "Hillerod Fodbold",
    shortSquadName: "Hillerod F",
    squadLogo: "7699_Hilleröd_Fodbold.png",
  },
  {
    squadId: 18100,
    squadName: "Cracovia",
    shortSquadName: "Cracovia",
    squadLogo: "logo_1598_Cracovia_Krakow.png",
  },
  {
    squadId: 18499,
    squadName: "CSKA 1948",
    shortSquadName: "CSKA 1948",
    squadLogo: "60354_FK_CSKA_1948_Sofia.png",
  },
  {
    squadId: 18516,
    squadName: "SV Wehen Wiesbaden",
    shortSquadName: "Wehen Wiesbaden",
    squadLogo: "logo_1294_SV_Wehen_Wiesbaden.png",
  },
  {
    squadId: 18651,
    squadName: "Rosenborg BK",
    shortSquadName: "Rosenborg",
    squadLogo: "logo_1464_Rosenborg_BK.png",
  },
  {
    squadId: 18697,
    squadName: "FC Augsburg",
    shortSquadName: "FC Augsburg",
    squadLogo: "logo_1274_FC_Augsburg.png",
  },
  {
    squadId: 18896,
    squadName: "Stellenbosch FC",
    shortSquadName: "Stellenbosch FC",
    squadLogo: "logo_18896_Stellenbosch_FC.png",
  },
  {
    squadId: 19020,
    squadName: "CA Huracan",
    shortSquadName: "Huracan",
    squadLogo: "logo_19020_CA_Huracan.png",
  },
  {
    squadId: 19055,
    squadName: "Shimizu S-Pulse",
    shortSquadName: "Shimizu S-Pulse",
    squadLogo: "logo_2418_Shimizu_S-Pulse.png",
  },
  {
    squadId: 19138,
    squadName: "RCD Mallorca",
    shortSquadName: "RCD Mallorca",
    squadLogo: "686_RCD_Mallorca.png",
  },
  {
    squadId: 19151,
    squadName: "Clermont Foot 63",
    shortSquadName: "Clermont Foot",
    squadLogo: "3797_Clermont_Foot_63.png",
  },
  {
    squadId: 19206,
    squadName: "Santos FC",
    shortSquadName: "Santos",
    squadLogo: "5655_Santos_FC.png",
  },
  {
    squadId: 19385,
    squadName: "Cerezo Osaka",
    shortSquadName: "Cerezo Osaka",
    squadLogo: "logo_2408_Cerezo_Osaka.png",
  },
  {
    squadId: 19631,
    squadName: "FK Vojvodina Novi Sad",
    shortSquadName: "Vojvodina",
    squadLogo: "logo_1660_FK_Vojvodina_Novi_Sad.png",
  },
  {
    squadId: 20144,
    squadName: "Plymouth Argyle",
    shortSquadName: "Plymouth",
    squadLogo: "1649_Plymouth_Argyle.png",
  },
  {
    squadId: 20309,
    squadName: "Cadiz CF",
    shortSquadName: "Cadiz CF",
    squadLogo: "logo_1738_Cadiz_CF.png",
  },
  {
    squadId: 20454,
    squadName: "Legia Warszawa",
    shortSquadName: "Legia Warszawa",
    squadLogo: "logo_1594_Legia_Warszawa.png",
  },
  {
    squadId: 20630,
    squadName: "Gillingham FC",
    shortSquadName: "Gillingham FC",
    squadLogo: "1648_Gillingham_FC.png",
  },
  {
    squadId: 20830,
    squadName: "SV Sandhausen",
    shortSquadName: "SV Sandhausen",
    squadLogo: "logo_SV_Sandhausen.png",
  },
  {
    squadId: 20887,
    squadName: "Millwall FC",
    shortSquadName: "Millwall",
    squadLogo: "logo_1160_Millwall_FC.png",
  },
  {
    squadId: 21491,
    squadName: "FBC Melgar",
    shortSquadName: "FBC Melgar",
    squadLogo: "15534_FBC_Melgar.png",
  },
  {
    squadId: 21538,
    squadName: "Jacksonville United",
    shortSquadName: "Jacksonville United",
    squadLogo: null,
  },
  {
    squadId: 21706,
    squadName: "FC Hansa Rostock",
    shortSquadName: "Hansa Rostock",
    squadLogo: "logo_Hansa_Rostock.png",
  },
  {
    squadId: 22011,
    squadName: "Puebla FC",
    shortSquadName: "Puebla FC",
    squadLogo: "15414_Puebla_FC.png",
  },
  {
    squadId: 22198,
    squadName: "TSV 1860 Munich",
    shortSquadName: "1860 Munich",
    squadLogo: "2461_TSV_1860_Munich.png",
  },
  {
    squadId: 22352,
    squadName: "Cruzeiro Esporte Clube",
    shortSquadName: "Cruzeiro",
    squadLogo: "5640_Cruzeiro_Esporte_Clube.png",
  },
  {
    squadId: 22392,
    squadName: "Maccabi Tel Aviv",
    shortSquadName: "M. Tel Aviv",
    squadLogo: "13270_Maccabi_Tel_Aviv.png",
  },
  {
    squadId: 22514,
    squadName: "CF America",
    shortSquadName: "America",
    squadLogo: "15405_América.png",
  },
  {
    squadId: 22613,
    squadName: "Kuopion Palloseura",
    shortSquadName: "KuPS",
    squadLogo: "logo_1499_Kuopion_Palloseura.png",
  },
  {
    squadId: 22720,
    squadName: "Korona Kielce",
    shortSquadName: "Korona Kielce",
    squadLogo: "logo_1605_Korona_Kielce.png",
  },
  {
    squadId: 22866,
    squadName: "KMSK Deinze",
    shortSquadName: "KMSK Deinze",
    squadLogo: "logo_2368_KMSK_Deinze.png",
  },
  {
    squadId: 22922,
    squadName: "Zeleziarne Podbrezova",
    shortSquadName: "Podbrezova",
    squadLogo: "14379_FK_Zeleziarne_Podbrezova.png",
  },
  {
    squadId: 22992,
    squadName: "Pisa Sporting Club",
    shortSquadName: "Pisa",
    squadLogo: "3207_AC_Pisa_1909.png",
  },
  {
    squadId: 23117,
    squadName: "FC Versailles 78",
    shortSquadName: "FC Versailles",
    squadLogo: "4213_FC_Versailles_78.png",
  },
  {
    squadId: 23168,
    squadName: "1.FC Koln",
    shortSquadName: "1.FC Koln",
    squadLogo: "logo_1266_1._FC_Koln.png",
  },
  {
    squadId: 23452,
    squadName: "Motor Lublin",
    shortSquadName: "Motor Lublin",
    squadLogo: "logo_23452_Motor_Lublin.png",
  },
  {
    squadId: 23571,
    squadName: "Piast Gliwice",
    shortSquadName: "Piast Gliwice",
    squadLogo: "13530_Piast_Gliwice.png",
  },
  {
    squadId: 23588,
    squadName: "SC Heerenveen",
    shortSquadName: "Heerenveen",
    squadLogo: "logo_1537_SC_Heerenveen.png",
  },
  {
    squadId: 24099,
    squadName: "Valenciennes FC",
    shortSquadName: "Valenciennes FC",
    squadLogo: "3814_Valenciennes_FC.png",
  },
  {
    squadId: 24176,
    squadName: "AC Oulu",
    shortSquadName: "AC Oulu",
    squadLogo: "logo_2721_AC_Oulu.png",
  },
  {
    squadId: 24408,
    squadName: "Club Alianza Lima",
    shortSquadName: "Alianza Lima",
    squadLogo: "7239_Club_Alianza_Lima.png",
  },
  {
    squadId: 24432,
    squadName: "Pafos FC",
    shortSquadName: "Pafos FC",
    squadLogo: "34760_Pafos_FC.png",
  },
  {
    squadId: 24448,
    squadName: "Aarhus GF",
    shortSquadName: "Aarhus GF",
    squadLogo: "logo_1410_Aarhus_GF.png",
  },
  {
    squadId: 24449,
    squadName: "Viborg FF",
    shortSquadName: "Viborg FF",
    squadLogo: "logo_Viborg_FF.png",
  },
  {
    squadId: 24711,
    squadName: "Urawa Red Diamonds",
    shortSquadName: "Urawa Reds",
    squadLogo: "logo_2407_Urawa_Red_Diamonds.png",
  },
  {
    squadId: 24755,
    squadName: "Milton Keynes Dons",
    shortSquadName: "MK Dons",
    squadLogo: "logo_1164_Milton_Keynes_Dons.png",
  },
  {
    squadId: 24806,
    squadName: "Deportivo de La Coruna",
    shortSquadName: "Dep. La Coruna",
    squadLogo: "logo_1721_Deportivo_de_La_Coruna.png",
  },
  {
    squadId: 24812,
    squadName: "Club Bolivar",
    shortSquadName: "Club Bolivar",
    squadLogo: "10474_Bolívar_La_Paz.png",
  },
  {
    squadId: 24973,
    squadName: "Queretaro FC",
    shortSquadName: "Queretaro FC",
    squadLogo: "15421_Querétaro_FC.png",
  },
  {
    squadId: 25039,
    squadName: "LDU Quito",
    shortSquadName: "LDU Quito",
    squadLogo: "15764_LDU_Quito.png",
  },
  {
    squadId: 25051,
    squadName: "Wycombe Wanderers",
    shortSquadName: "Wycombe",
    squadLogo: "logo_Wycombe_Wanderers.png",
  },
  {
    squadId: 25285,
    squadName: "Levante UD",
    shortSquadName: "Levante",
    squadLogo: "logo_Levante_UD.png",
  },
  {
    squadId: 25519,
    squadName: "Rapid Vienna",
    shortSquadName: "Rapid Vienna",
    squadLogo: "logo_1943_Rapid_Vienna.png",
  },
  {
    squadId: 25579,
    squadName: "IK Brage",
    shortSquadName: "Brage",
    squadLogo: "logo_2471_IK_Brage.png",
  },
  {
    squadId: 25789,
    squadName: "Union La Calera",
    shortSquadName: "La Calera",
    squadLogo: "12314_Unión_La_Calera.png",
  },
  {
    squadId: 26124,
    squadName: "GKS Tychy",
    shortSquadName: "GKS Tychy",
    squadLogo: "logo_1617_GKS_Tychy.png",
  },
  {
    squadId: 26202,
    squadName: "Ludogorets Razgrad",
    shortSquadName: "Ludogorets",
    squadLogo: "9311_Ludogorets.png",
  },
  {
    squadId: 26232,
    squadName: "FC Schalke 04",
    shortSquadName: "FC Schalke 04",
    squadLogo: "logo_FC_Schalke_04.png",
  },
  {
    squadId: 26353,
    squadName: "SV Darmstadt 98",
    shortSquadName: "Darmstadt 98",
    squadLogo: "2520_SV_Darmstadt_98.png",
  },
  {
    squadId: 26379,
    squadName: "HNK Segesta Sisak",
    shortSquadName: "HNK Segesta Sisak",
    squadLogo: "logo_26379_HNK_Segesta_Sisak.png",
  },
  {
    squadId: 26457,
    squadName: "Stabaek Fotball",
    shortSquadName: "Stabaek",
    squadLogo: "logo_1473_Stabaek_IF.png",
  },
  {
    squadId: 26635,
    squadName: "Swift Hesperange",
    shortSquadName: "Hesperange",
    squadLogo: "logo_26635_Swift_Hesperange.png",
  },
  {
    squadId: 26699,
    squadName: "Galatasaray",
    shortSquadName: "Galatasaray",
    squadLogo: "logo_2003_Galatasaray_SK.png",
  },
  {
    squadId: 27115,
    squadName: "NK Sesvete",
    shortSquadName: "NK Sesvete",
    squadLogo: "logo_27115_NK_Sesvete.png",
  },
  {
    squadId: 27178,
    squadName: "Egersunds IK",
    shortSquadName: "Egersund",
    squadLogo: "logo_27178_Egersunds_IK.png",
  },
  {
    squadId: 27650,
    squadName: "FC Juarez",
    shortSquadName: "FC Juarez",
    squadLogo: "34402_FC_Juárez.png",
  },
  {
    squadId: 28130,
    squadName: "IF Brommapojkarna",
    shortSquadName: "Brommapojkarna",
    squadLogo: "logo_IF_Brommapojkarna.png",
  },
  {
    squadId: 28158,
    squadName: "Aalborg BK",
    shortSquadName: "Aalborg BK",
    squadLogo: "logo_Aalborg_BK.png",
  },
  {
    squadId: 28212,
    squadName: "RC Strasbourg Alsace",
    shortSquadName: "R. Strasbourg",
    squadLogo: "logo_1981_RC_Strasbourg_Alsace.png",
  },
  {
    squadId: 28242,
    squadName: "Kryvbas Kryvyi Rig",
    shortSquadName: "Kryvbas",
    squadLogo: "logo_28242_Kryvbas_Kryvyi_Rig.png",
  },
  {
    squadId: 28356,
    squadName: "Al-Nassr FC",
    shortSquadName: "Al-Nassr",
    squadLogo: "16470_Al-Nassr_FC.png",
  },
  {
    squadId: 28879,
    squadName: "Athletic Bilbao",
    shortSquadName: "Athletic",
    squadLogo: "678_Athletic_Bilbao.png",
  },
  {
    squadId: 28997,
    squadName: "Aberdeen FC",
    shortSquadName: "Aberdeen FC",
    squadLogo: "logo_2190_Aberdeen_FC.png",
  },
  {
    squadId: 29065,
    squadName: "Club Sporting Cristal",
    shortSquadName: "Sport. Cristal",
    squadLogo: "15532_Club_Sporting_Cristal.png",
  },
  {
    squadId: 29155,
    squadName: "1.FC Kaiserslautern",
    shortSquadName: "1.FC K'lautern",
    squadLogo: "logo_1286_1.FC_Kaiserslautern.png",
  },
  {
    squadId: 29198,
    squadName: "FC Den Bosch",
    shortSquadName: "FC Den Bosch",
    squadLogo: "logo_FC_Den_Bosch.png",
  },
  {
    squadId: 29285,
    squadName: "BK Hacken",
    shortSquadName: "Hacken",
    squadLogo: "6736_BK_Häcken.png",
  },
  {
    squadId: 29455,
    squadName: "Nottingham Forest",
    shortSquadName: "Nottm Forest",
    squadLogo: "logo_1152_Nottingham_Forest.png",
  },
  {
    squadId: 29469,
    squadName: "GNK Dinamo Zagreb",
    shortSquadName: "Dinamo Zagreb",
    squadLogo: "logo_2163_GNK_Dinamo_Zagreb.png",
  },
  {
    squadId: 29529,
    squadName: "Cheltenham Town",
    shortSquadName: "Cheltenham",
    squadLogo: "logo_1197_Cheltenham_Town.png",
  },
  {
    squadId: 29704,
    squadName: "NAC Breda",
    shortSquadName: "NAC Breda",
    squadLogo: "logo_NAC_Breda.png",
  },
  {
    squadId: 29747,
    squadName: "Ajax Amsterdam",
    shortSquadName: "Ajax",
    squadLogo: "9_Ajax_Amsterdam.png",
  },
  {
    squadId: 29766,
    squadName: "Gornik Zabrze",
    shortSquadName: "Gornik Zabrze",
    squadLogo: "logo_1609_Gornik_Zabrze.png",
  },
  {
    squadId: 30012,
    squadName: "Crewe Alexandra",
    shortSquadName: "Crewe Alexandra",
    squadLogo: "logo_1202_Crewe_Alexandra.png",
  },
  {
    squadId: 30168,
    squadName: "Deportivo Saprissa",
    shortSquadName: "Saprissa",
    squadLogo: "logo_30168_Deportivo_Saprissa.png",
  },
  {
    squadId: 30352,
    squadName: "FC Vizela",
    shortSquadName: "Vizela",
    squadLogo: "9683_FC_Vizela.png",
  },
  {
    squadId: 30372,
    squadName: "Envigado FC",
    shortSquadName: "Envigado",
    squadLogo: "12405_Envigado_FC.png",
  },
  {
    squadId: 30411,
    squadName: "FK TSC Backa Topola",
    shortSquadName: "FK TSC",
    squadLogo: "logo_30411_FK_TSC_Backa_Topola.png",
  },
  {
    squadId: 30475,
    squadName: "Malmo FF",
    shortSquadName: "Malmo FF",
    squadLogo: "logo_1432_Malmo_FF.png",
  },
  {
    squadId: 30586,
    squadName: "AC Sparta Prague",
    shortSquadName: "Sparta Prague",
    squadLogo: "11241_AC_Sparta_Prague.png",
  },
  {
    squadId: 30723,
    squadName: "Borussia Dortmund",
    shortSquadName: "Bor. Dortmund",
    squadLogo: "logo_1259_Borussia_Dortmund.png",
  },
  {
    squadId: 30997,
    squadName: "Red Bull Salzburg",
    shortSquadName: "RB Salzburg",
    squadLogo: "logo_1942_Red_Bull_Salzburg.png",
  },
  {
    squadId: 31138,
    squadName: "Club Leon FC",
    shortSquadName: "Leon",
    squadLogo: "15424_Club_León_FC.png",
  },
  {
    squadId: 31172,
    squadName: "Montpellier HSC",
    shortSquadName: "Montpellier",
    squadLogo: "3787_Montpellier.png",
  },
  {
    squadId: 31205,
    squadName: "SG Dynamo Dresden",
    shortSquadName: "Dynamo Dresden",
    squadLogo: "2478_SG_Dynamo_Dresden.png",
  },
  {
    squadId: 31306,
    squadName: "Kolding IF",
    shortSquadName: "Kolding IF",
    squadLogo: "7622_Kolding_IF.png",
  },
  {
    squadId: 31484,
    squadName: "Deportivo Guadalajara",
    shortSquadName: "Chivas",
    squadLogo: "15409_Deportivo_Guadalajara.png",
  },
  {
    squadId: 31536,
    squadName: "Aris Limassol",
    shortSquadName: "Aris Limassol",
    squadLogo: "logo_31536_Aris_Limassol.png",
  },
  {
    squadId: 31588,
    squadName: "Rangers FC",
    shortSquadName: "Rangers",
    squadLogo: "logo_2188_Rangers_FC.png",
  },
  {
    squadId: 32332,
    squadName: "Al-Ahli SFC",
    shortSquadName: "Al-Ahli",
    squadLogo: "logo_32332_Al-Ahli_Saudi_FC.png",
  },
  {
    squadId: 32429,
    squadName: "Eintracht Frankfurt",
    shortSquadName: "E. Frankfurt",
    squadLogo: "logo_1268_Eintracht_Frankfurt.png",
  },
  {
    squadId: 32489,
    squadName: "Stockport County",
    shortSquadName: "Stockport",
    squadLogo: "1667_Stockport_County.jpg",
  },
  {
    squadId: 32515,
    squadName: "IK Sirius",
    shortSquadName: "Sirius",
    squadLogo: "logo_1446_IK_Sirius.png",
  },
  {
    squadId: 32753,
    squadName: "Deportivo Toluca",
    shortSquadName: "Toluca",
    squadLogo: "15418_Deportivo_Toluca.png",
  },
  {
    squadId: 32775,
    squadName: "FC Nantes",
    shortSquadName: "FC Nantes",
    squadLogo: "3770_FC_Nantes.png",
  },
  {
    squadId: 32802,
    squadName: "CD Tenerife",
    shortSquadName: "CD Tenerife",
    squadLogo: "logo_1743_CD_Tenerife.png",
  },
  {
    squadId: 32865,
    squadName: "Portland Timbers",
    shortSquadName: "Portland",
    squadLogo: "8113_Portland_Timbers.png",
  },
  {
    squadId: 33026,
    squadName: "FC Rapid Bucharest",
    shortSquadName: "FC Rapid",
    squadLogo: "11566_FC_Rapid_1923.png",
  },
  {
    squadId: 33048,
    squadName: "R Charleroi SC",
    shortSquadName: "R Charleroi SC",
    squadLogo: "logo_1580_RSC_Charleroi.png",
  },
  {
    squadId: 33217,
    squadName: "Real CD Espana",
    shortSquadName: "Real Espana",
    squadLogo: "17385_Real_CD_España.png",
  },
  {
    squadId: 33438,
    squadName: "FC Thun",
    shortSquadName: "FC Thun",
    squadLogo: "logo_1941_FC_Thun.png",
  },
  {
    squadId: 33479,
    squadName: "Fleetwood Town",
    shortSquadName: "Fleetwood",
    squadLogo: "logo_Fleetwood_Town.png",
  },
  {
    squadId: 33615,
    squadName: "Zalaegerszegi TE FC",
    shortSquadName: "Zalaegerszeg",
    squadLogo: "logo_33615_Zalaegerszegi_TE_FC.png",
  },
  {
    squadId: 33648,
    squadName: "Atletico Nacional",
    shortSquadName: "Atl. Nacional",
    squadLogo: "12391_Atlético_Nacional.png",
  },
  {
    squadId: 33676,
    squadName: "Sandefjord Fotball",
    shortSquadName: "Sandefjord",
    squadLogo: "logo_Sandefjord_Fotball.png",
  },
  {
    squadId: 33794,
    squadName: "Sport Club Internacional",
    shortSquadName: "Internacional",
    squadLogo: "logo_2611_Sport_Club_Internacional.png",
  },
  {
    squadId: 34079,
    squadName: "Celtic FC",
    shortSquadName: "Celtic",
    squadLogo: "logo_Celtic_FC.png",
  },
  {
    squadId: 34616,
    squadName: "Vejle Boldklub",
    shortSquadName: "Vejle BK",
    squadLogo: "logo_1422_Vejle_Boldklub.png",
  },
  {
    squadId: 34776,
    squadName: "Goztepe",
    shortSquadName: "Goztepe",
    squadLogo: "logo_2012_Goztepe.png",
  },
  {
    squadId: 35181,
    squadName: "SpVgg Unterhaching",
    shortSquadName: "Unterhaching",
    squadLogo: "2471_SpVgg_Unterhaching.png",
  },
  {
    squadId: 35187,
    squadName: "Virtus Entella",
    shortSquadName: "Virtus Entella",
    squadLogo: "3404_Virtus_Entella.png",
  },
  {
    squadId: 35303,
    squadName: "Stal Mielec",
    shortSquadName: "Stal Mielec",
    squadLogo: "logo_1614_Stal_Mielec.png",
  },
  {
    squadId: 35316,
    squadName: "NK Dugopolje",
    shortSquadName: "NK Dugopolje",
    squadLogo: "logo_35316_NK_Dugopolje.png",
  },
  {
    squadId: 35764,
    squadName: "SV 07 Elversberg",
    shortSquadName: "SV 07 Elversberg",
    squadLogo: "logo_1371_SV_07_Elversberg.png",
  },
  {
    squadId: 35822,
    squadName: "Bayern Munich",
    shortSquadName: "Bayern Munich",
    squadLogo: "logo_Bayern_Munich.png",
  },
  {
    squadId: 35839,
    squadName: "Sociedade Esportiva Palmeiras",
    shortSquadName: "Palmeiras",
    squadLogo: "5646_Sociedade_Esportiva_Palmeiras.png",
  },
  {
    squadId: 36192,
    squadName: "Tigres UANL",
    shortSquadName: "Tigres UANL",
    squadLogo: "15417_Tigres_UANL.png",
  },
  {
    squadId: 36491,
    squadName: "FK Vozdovac",
    shortSquadName: "Vozdovac",
    squadLogo: "logo_1662_FK_Vozdovac.png",
  },
  {
    squadId: 36627,
    squadName: "FC Metz",
    shortSquadName: "FC Metz",
    squadLogo: "3777_FC_Metz.png",
  },
  {
    squadId: 36736,
    squadName: "Olimpia Asuncion",
    shortSquadName: "Olimpia",
    squadLogo: "15486_Olimpia.png",
  },
  {
    squadId: 36837,
    squadName: "AJ Auxerre",
    shortSquadName: "AJ Auxerre",
    squadLogo: "3769_AJ_Auxerre.png",
  },
  {
    squadId: 37066,
    squadName: "SSV Ulm 1846",
    shortSquadName: "SSV Ulm 1846",
    squadLogo: "2508_SSV_Ulm_1846.png",
  },
  {
    squadId: 37217,
    squadName: "Deportes Tolima",
    shortSquadName: "Deportes Tolima",
    squadLogo: "12396_Deportes_Tolima.png",
  },
  {
    squadId: 37659,
    squadName: "Kongsvinger IL",
    shortSquadName: "Kongsvinger",
    squadLogo: "logo_Kongsvinger_IL.png",
  },
  {
    squadId: 38328,
    squadName: "D.C. United",
    shortSquadName: "D.C. United",
    squadLogo: "logo_2118_D.C._United.png",
  },
  {
    squadId: 38564,
    squadName: "FC Volendam",
    shortSquadName: "FC Volendam",
    squadLogo: "logo_FC_Volendam.png",
  },
  {
    squadId: 38580,
    squadName: "Valencia CF",
    shortSquadName: "Valencia",
    squadLogo: "logo_1715_Valencia_CF.png",
  },
  {
    squadId: 38623,
    squadName: "Pogon Szczecin",
    shortSquadName: "Pogon Szczecin",
    squadLogo: "logo_1599_Pogon_Szczecin.png",
  },
  {
    squadId: 38864,
    squadName: "Slask Wroclaw",
    shortSquadName: "Slask Wroclaw",
    squadLogo: "logo_WKS_Slask_Wroclaw.png",
  },
  {
    squadId: 39031,
    squadName: "Esporte Clube Juventude",
    shortSquadName: "Juventude",
    squadLogo: "logo_39031_Esporte_Clube_Juventude.png",
  },
  {
    squadId: 39176,
    squadName: "Leeds United",
    shortSquadName: "Leeds",
    squadLogo: "logo_1150_Leeds_United.png",
  },
  {
    squadId: 39191,
    squadName: "LKS Lodz",
    shortSquadName: "LKS Lodz",
    squadLogo: "13528_Łódzki_KS.png",
  },
  {
    squadId: 39292,
    squadName: "Liverpool FC Montevideo",
    shortSquadName: "Liverpool FC",
    squadLogo: "15632_Liverpool_FC_Montevideo.png",
  },
  {
    squadId: 39603,
    squadName: "Guarani Futebol Clube (SP)",
    shortSquadName: "Guarani",
    squadLogo: "logo_39603_Guarani_Futebol_Clube_(SP).png",
  },
  {
    squadId: 39668,
    squadName: "SKN St. Polten",
    shortSquadName: "SKN St. Polten",
    squadLogo: "8756_SKN_St._Pölten.png",
  },
  {
    squadId: 39789,
    squadName: "Como 1907",
    shortSquadName: "Como",
    squadLogo: "logo_39789_Como_1907.png",
  },
  {
    squadId: 39818,
    squadName: "Al-Qadsiah FC",
    shortSquadName: "Al-Qadsiah",
    squadLogo: "39818_AlQadsiah.png",
  },
  {
    squadId: 39885,
    squadName: "AP Brera Strumica",
    shortSquadName: "Brera Strumica",
    squadLogo: "39679_AP_Brera.png",
  },
  {
    squadId: 40123,
    squadName: "Besiktas JK",
    shortSquadName: "Besiktas",
    squadLogo: "4489_Besiktas_JK.svg",
  },
  {
    squadId: 40165,
    squadName: "Beroe Stara Zagora",
    shortSquadName: "Beroe",
    squadLogo: "logo_2042_PFC_Beroe_Stara_Zagora.png",
  },
  {
    squadId: 40356,
    squadName: "SV Werder Bremen",
    shortSquadName: "Werder Bremen",
    squadLogo: "logo_1269_SV_Werder_Bremen.png",
  },
  {
    squadId: 40409,
    squadName: "Oxford United",
    shortSquadName: "Oxford United",
    squadLogo: "1683_Oxford_United.png",
  },
  {
    squadId: 40508,
    squadName: "LASK",
    shortSquadName: "LASK",
    squadLogo: "8754_LASK.png",
  },
  {
    squadId: 40692,
    squadName: "Columbus Crew",
    shortSquadName: "Columbus",
    squadLogo: "7855_Columbus_Crew.png",
  },
  {
    squadId: 40848,
    squadName: "Gil Vicente FC",
    shortSquadName: "Gil Vicente",
    squadLogo: "9700_Gil_Vicente_FC.png",
  },
  {
    squadId: 41279,
    squadName: "Racing Club de Montevideo",
    shortSquadName: "Racing Club",
    squadLogo: "15659_Racing_Club_de_Montevideo.png",
  },
  {
    squadId: 41684,
    squadName: "Twente Enschede FC",
    shortSquadName: "Twente FC",
    squadLogo: "logo_1540_FC_Twente_Enschede.png",
  },
  {
    squadId: 41761,
    squadName: "Leyton Orient",
    shortSquadName: "Leyton Orient",
    squadLogo: "logo_Leyton_Orient.png",
  },
  {
    squadId: 41792,
    squadName: "Las Vegas Lights FC",
    shortSquadName: "Las Vegas",
    squadLogo: "62275_Las_Vegas_Lights_FC.png",
  },
  {
    squadId: 41795,
    squadName: "FK Partizani",
    shortSquadName: "FK Partizani",
    squadLogo: "8675_FK_Partizani.png",
  },
  {
    squadId: 42338,
    squadName: "K Lierse SK",
    shortSquadName: "Lierse SK",
    squadLogo: "63141_KSK_Lierse_Kempenzonen.png",
  },
  {
    squadId: 43347,
    squadName: "Dynamo Puerto FC",
    shortSquadName: "Dynamo Puerto",
    squadLogo: "64966_Dynamo_Puerto_FC.png",
  },
  {
    squadId: 50113,
    squadName: "Inter Miami CF",
    shortSquadName: "Miami",
    squadLogo: "-69261_Inter_Miami_CF.png",
  },
  {
    squadId: 200001,
    squadName: "Mazatlan FC",
    shortSquadName: "Mazatlan FC",
    squadLogo: "-82696_Mazatlán_FC.png",
  },
  {
    squadId: 200003,
    squadName: "Oakland Roots SC",
    shortSquadName: "Oakland Roots",
    squadLogo: "-69044_Oakland_Roots_SC.png",
  },
  {
    squadId: 200004,
    squadName: "Charlotte FC",
    shortSquadName: "Charlotte",
    squadLogo: "-78435_Charlotte_FC.png",
  },
  {
    squadId: 200113,
    squadName: "1.FC Phonix Lubeck",
    shortSquadName: "Phonix Lubeck",
    squadLogo:
      "https://tmssl.akamaized.net/images/wappen/head/2773.png?lm=1593726229",
  },
  {
    squadId: 200414,
    squadName: "Real Cundinamarca",
    shortSquadName: "Real C.",
    squadLogo:
      "https://tmssl.akamaized.net/images/wappen/head/18111.png?lm=1512254152",
  },
  {
    squadId: 220000,
    squadName: "United Soccer League",
    shortSquadName: "United Soccer League",
    squadLogo: null,
  },
  {
    squadId: 225010,
    squadName: "CD Real Cartagena",
    shortSquadName: "Real Cartagena",
    squadLogo: "-13046_CD_Real_Cartagena.png",
  },
  {
    squadId: 225041,
    squadName: "Karpaty Lviv",
    shortSquadName: "Karpaty Lviv",
    squadLogo:
      "https://tmssl.akamaized.net/images/wappen/head/85465.png?lm=1626717874",
  },
  {
    squadId: 225557,
    squadName: "Clube Atletico Mineiro",
    shortSquadName: "Atletico-MG",
    squadLogo: "-330_Clube_Atlético_Mineiro.png",
  },
  {
    squadId: 225568,
    squadName: "Avs Futebol",
    shortSquadName: "Avs FS",
    squadLogo: null,
  },
  {
    squadId: 225678,
    squadName: "San Diego FC",
    shortSquadName: "San Diego FC",
    squadLogo: "114977_San_Diego_FC.png",
  },
  {
    squadId: 225956,
    squadName: "Sfera Futebol Clube",
    shortSquadName: "Sfera Futebol Clube",
    squadLogo: null,
  },
];