let config = null;

if (process.env.REACT_APP_ENV === "local") {
    config = {
        imagesUrl: "https://transferroomcloud.blob.core.windows.net",
        baseUrl: "https://localhost:44324",
        profileWebAppUrl: "http://localhost:3001",
        stripeConfig: "pk_test_YchQkmoTQwEcr9jquodj5LwE",
        ltaSubscriptionPlanId: 40553,
        paddleVendorId: 8922,
        isPaddleSandbox: true,
        googleApiKey: "AIzaSyDn4s0cINDF8MR0WXskdDI94AlNd0_CZEo",
        landingUrl: null,
        eventHub: {
            connectionString: "Endpoint=sb://trtesteventhub.servicebus.windows.net/;SharedAccessKeyName=SharedAccess;SharedAccessKey=Nn//GHImT5CqOuCuFAHvggOFXTMuXBBy8qnSIdbQJ9o=;EntityPath=deveventhub",
            name: "deveventhub"
        },
        hubspotForms:{
            portalId: "23325774",
            inviteAgencyFormId: "81e450ad-3dfb-41d2-85d9-aef06492f909",
            getInTouchFormId: "46fed606-7945-4da0-a3f2-7d762828933e",
            claimThisProfileFormId: "36ba1ec5-61b1-4729-879e-aae4acfd5247",
        },
        appcues:{
            apiId: 210810
        }
    }
}
if (process.env.REACT_APP_ENV === "qa") {
    config = {
        imagesUrl: "https://transferroomcloud.blob.core.windows.net",
        baseUrl: "https://api.qa.transferroom.com",
        profileWebAppUrl: "http://localhost:3001",
        stripeConfig: "pk_test_YchQkmoTQwEcr9jquodj5LwE",
        ltaSubscriptionPlanId: 40553,
        paddleVendorId: 8922,
        isPaddleSandbox: true,
        googleApiKey: "AIzaSyDn4s0cINDF8MR0WXskdDI94AlNd0_CZEo",
        landingUrl: null,
        eventHub: {
            connectionString: "Endpoint=sb://trtesteventhub.servicebus.windows.net/;SharedAccessKeyName=SharedAccess;SharedAccessKey=Nn//GHImT5CqOuCuFAHvggOFXTMuXBBy8qnSIdbQJ9o=;EntityPath=deveventhub",
            name: "deveventhub"
        },
        hubspotForms:{
            portalId: "23325774",
            inviteAgencyFormId: "81e450ad-3dfb-41d2-85d9-aef06492f909",
            getInTouchFormId: "46fed606-7945-4da0-a3f2-7d762828933e",
            claimThisProfileFormId: "36ba1ec5-61b1-4729-879e-aae4acfd5247",
        },
        appcues:{
            apiId: 210810
        }
    }
}
if (process.env.REACT_APP_ENV === "dev-agency") {
    config = {
        imagesUrl: "https://transferroomcloud.blob.core.windows.net",
        baseUrl: "https://apidev.transferroom.com",
        profileWebAppUrl: "https://dev-profile.transferroom.com",
        stripeConfig: "pk_test_YchQkmoTQwEcr9jquodj5LwE",
        ltaSubscriptionPlanId: 40553,
        paddleVendorId: 8922,
        isPaddleSandbox: true,
        googleApiKey: "AIzaSyDn4s0cINDF8MR0WXskdDI94AlNd0_CZEo",
        landingUrl: null,
        eventHub: {
            connectionString: "Endpoint=sb://trtesteventhub.servicebus.windows.net/;SharedAccessKeyName=SharedAccess;SharedAccessKey=Nn//GHImT5CqOuCuFAHvggOFXTMuXBBy8qnSIdbQJ9o=;EntityPath=deveventhub",
            name: "deveventhub"
        },
        hubspotForms:{
            portalId: "23325774",
            inviteAgencyFormId: "81e450ad-3dfb-41d2-85d9-aef06492f909",
            getInTouchFormId: "46fed606-7945-4da0-a3f2-7d762828933e",
            claimThisProfileFormId: "36ba1ec5-61b1-4729-879e-aae4acfd5247",
        },
        appcues:{
            apiId: 210810
        }
    }
}
if (process.env.REACT_APP_ENV === "test") {
    config = {
        imagesUrl: "https://transferroomcloud.blob.core.windows.net",
        baseUrl: "https://apitest.transferroom.com",
        profileWebAppUrl: "https://dev-profile.transferroom.com",
        stripeConfig: "pk_test_YchQkmoTQwEcr9jquodj5LwE",
        ltaSubscriptionPlanId: 40553,
        paddleVendorId: 8922,
        isPaddleSandbox: true,
        googleApiKey: "AIzaSyDn4s0cINDF8MR0WXskdDI94AlNd0_CZEo",
        landingUrl: null,
        eventHub: {
            connectionString: "Endpoint=sb://trtesteventhub.servicebus.windows.net/;SharedAccessKeyName=SharedAccess;SharedAccessKey=Nn//GHImT5CqOuCuFAHvggOFXTMuXBBy8qnSIdbQJ9o=;EntityPath=deveventhub",
            name: "deveventhub"
        },
        hubspotForms:{
            portalId: "23325774",
            inviteAgencyFormId: "81e450ad-3dfb-41d2-85d9-aef06492f909",
            getInTouchFormId: "46fed606-7945-4da0-a3f2-7d762828933e",
            claimThisProfileFormId: "36ba1ec5-61b1-4729-879e-aae4acfd5247",
        },
        appcues:{
            apiId: 210810
        }
    }
}
if (process.env.REACT_APP_ENV === "dev-club") {
    config = {
        imagesUrl: "https://transferroomcloud.blob.core.windows.net",
        baseUrl: "https://apifeature.transferroom.com",
        profileWebAppUrl: "https://feature-profile.transferroom.com",
        stripeConfig: "pk_test_YchQkmoTQwEcr9jquodj5LwE",
        ltaSubscriptionPlanId: 40553,
        paddleVendorId: 8922,
        isPaddleSandbox: true,
        googleApiKey: "AIzaSyDn4s0cINDF8MR0WXskdDI94AlNd0_CZEo",
        landingUrl: null,
        eventHub: {
            connectionString: "Endpoint=sb://trtesteventhub.servicebus.windows.net/;SharedAccessKeyName=SharedAccess;SharedAccessKey=Nn//GHImT5CqOuCuFAHvggOFXTMuXBBy8qnSIdbQJ9o=;EntityPath=deveventhub",
            name: "deveventhub"
        },
        hubspotForms:{
            portalId: "23325774",
            inviteAgencyFormId: "81e450ad-3dfb-41d2-85d9-aef06492f909",
            getInTouchFormId: "46fed606-7945-4da0-a3f2-7d762828933e",
            claimThisProfileFormId: "36ba1ec5-61b1-4729-879e-aae4acfd5247",
        },
        appcues:{
            apiId: 210810
        }
    }
}
if (process.env.REACT_APP_ENV === "dev-player") {
    config = {
        imagesUrl: "https://transferroomcloud.blob.core.windows.net",
        baseUrl: "https://dev-player-api.transferroom.com",
        profileWebAppUrl: "https://dev-profile.transferroom.com",
        stripeConfig: "pk_test_YchQkmoTQwEcr9jquodj5LwE",
        ltaSubscriptionPlanId: 40553,
        paddleVendorId: 8922,
        isPaddleSandbox: true,
        googleApiKey: "AIzaSyDn4s0cINDF8MR0WXskdDI94AlNd0_CZEo",
        landingUrl: null,
        eventHub: {
            connectionString: "Endpoint=sb://trtesteventhub.servicebus.windows.net/;SharedAccessKeyName=SharedAccess;SharedAccessKey=Nn//GHImT5CqOuCuFAHvggOFXTMuXBBy8qnSIdbQJ9o=;EntityPath=deveventhub",
            name: "deveventhub"
        },
        hubspotForms:{
            portalId: "23325774",
            inviteAgencyFormId: "81e450ad-3dfb-41d2-85d9-aef06492f909",
            getInTouchFormId: "46fed606-7945-4da0-a3f2-7d762828933e",
            claimThisProfileFormId: "36ba1ec5-61b1-4729-879e-aae4acfd5247",
        },
        appcues:{
            apiId: 210810
        }
    }
}
if (process.env.REACT_APP_ENV === "stage") {
    config = {
        imagesUrl: "https://transferroomcloud.blob.core.windows.net",
        baseUrl: "https://apistage.transferroom.com",
        profileWebAppUrl: "https://stage-profile.transferroom.com",
        stripeConfig: "pk_test_YchQkmoTQwEcr9jquodj5LwE",
        ltaSubscriptionPlanId: 40553,
        paddleVendorId: 8922,
        isPaddleSandbox: true,
        googleApiKey: "AIzaSyDn4s0cINDF8MR0WXskdDI94AlNd0_CZEo",
        landingUrl: null,
        eventHub: {
            connectionString: "Endpoint=sb://trtesteventhub.servicebus.windows.net/;SharedAccessKeyName=SharedAccess;SharedAccessKey=GReiBfhkgJQ1Ex9WtgcHvQks1NX+H6itYLYdoiKg+8k=;EntityPath=stageeventhub",
            name: "stageeventhub"
        },
        hubspotForms:{
            portalId: "23325774",
            inviteAgencyFormId: "81e450ad-3dfb-41d2-85d9-aef06492f909",
            getInTouchFormId: "46fed606-7945-4da0-a3f2-7d762828933e",
            claimThisProfileFormId: "36ba1ec5-61b1-4729-879e-aae4acfd5247",
        },
        appcues:{
            apiId: 210810
        }
    }
}

if (process.env.REACT_APP_ENV === "prod") {
    config = {
        imagesUrl: "https://transferroom.blob.core.windows.net",
        baseUrl: "https://apiprod.transferroom.com",
        profileWebAppUrl: "https://profile.transferroom.com",
        stripeConfig: "pk_live_OuDYPFdnw3yb2GcYkL6bOQzY",
        ltaSubscriptionPlanId: 806055,
        paddleVendorId: 162560,
        isPaddleSandbox: false,
        googleApiKey: "AIzaSyDn4s0cINDF8MR0WXskdDI94AlNd0_CZEo",
        landingUrl: "https://www.transferroom.com",
        eventHub: {
            connectionString: "Endpoint=sb://trprodeventhub.servicebus.windows.net/;SharedAccessKeyName=SharedAccess;SharedAccessKey=BIpwWBfEC/OZuXoiMftX0NPzq4BOncRN9zLUfa7qaz4=;EntityPath=prodeventhub",
            name: "prodeventhub"
        },
        hubspotForms:{
            portalId: "6939831",
            inviteAgencyFormId: "86928c75-0336-44c9-bcee-661f5e4c3695",
            getInTouchFormId: "2fe79039-c645-49fc-9f48-d963db7c6542",
            claimThisProfileFormId: "1694f747-fb98-4d8d-af55-1983b89f3cf1",
        },
        appcues:{
            apiId: 210810
        }
    }
}

export default config;
