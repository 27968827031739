export const eventClubs = [
  {
    squadId: 2,
    squadName: "AS Roma",
    shortSquadName: "AS Roma",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1835_AS_Roma.png"
  },
  {
    squadId: 4,
    squadName: "Parma Calcio 1913",
    shortSquadName: "Parma",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3160_Parma_Calcio_1913.png"
  },
  {
    squadId: 14,
    squadName: "FC Empoli",
    shortSquadName: "FC Empoli",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1856_FC_Empoli.png"
  },
  {
    squadId: 15,
    squadName: "Torino FC",
    shortSquadName: "Torino",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1839_Torino_FC.png"
  },
  {
    squadId: 17,
    squadName: "Genoa CFC",
    shortSquadName: "Genoa",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1843_Genoa_CFC.png"
  },
  {
    squadId: 45,
    squadName: "Manchester United",
    shortSquadName: "Man Utd",
    squadLogo: null //"https://transferroom.blob.core.windows.net/clubs/logo_1120_Manchester_United.png"
  },
  {
    squadId: 46,
    squadName: "Liverpool FC",
    shortSquadName: "Liverpool",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1123_Liverpool_FC.png"
  },
  {
    squadId: 47,
    squadName: "Tottenham Hotspur",
    shortSquadName: "Spurs",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1122_Tottenham_Hotspur.png"
  },
  {
    squadId: 48,
    squadName: "Manchester City",
    shortSquadName: "Man City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1625_Manchester_City.png"
  },
  {
    squadId: 49,
    squadName: "Arsenal FC",
    shortSquadName: "Arsenal",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1121_Arsenal_FC.png"
  },
  {
    squadId: 50,
    squadName: "Chelsea FC",
    shortSquadName: "Chelsea",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Chelsea_FC.png"
  },
  {
    squadId: 60,
    squadName: "Newcastle United",
    shortSquadName: "Newcastle",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Newcastle_United.png"
  },
  {
    squadId: 62,
    squadName: "Southampton FC",
    shortSquadName: "Southampton",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1619_Southampton_FC.png"
  },
  {
    squadId: 63,
    squadName: "Everton FC",
    shortSquadName: "Everton",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1124_Everton_FC.png"
  },
  {
    squadId: 64,
    squadName: "Crystal Palace",
    shortSquadName: "Crystal Palace",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1128_Crystal_Palace.png"
  },
  {
    squadId: 65,
    squadName: "Wolverhampton Wanderers",
    shortSquadName: "Wolves",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Wolverhampton_Wanderers.png"
  },
  {
    squadId: 66,
    squadName: "Leicester City",
    shortSquadName: "Leicester",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Leicester_City.png"
  },
  {
    squadId: 67,
    squadName: "West Ham United",
    shortSquadName: "West Ham",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1633_West_Ham_United.png"
  },
  {
    squadId: 68,
    squadName: "Cardiff City",
    shortSquadName: "Cardiff",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1151_Cardiff_City.png"
  },
  {
    squadId: 69,
    squadName: "Watford FC",
    shortSquadName: "Watford",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1644_Watford_FC.png"
  },
  {
    squadId: 70,
    squadName: "Brighton & Hove Albion",
    shortSquadName: "Brighton",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Brighton_And_Hove_Albion_FC.png"
  },
  {
    squadId: 71,
    squadName: "AFC Bournemouth",
    shortSquadName: "Bournemouth",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1134_AFC_Bournemouth.png"
  },
  {
    squadId: 72,
    squadName: "Huddersfield Town",
    shortSquadName: "Huddersfield",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1673_Huddersfield_Town.png"
  },
  {
    squadId: 174,
    squadName: "Club Atletico Talleres",
    shortSquadName: "CA Talleres",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2588_Club_Atletico_Talleres.png"
  },
  {
    squadId: 329,
    squadName: "Stromsgodset IF",
    shortSquadName: "Stromsgodset",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7111_Strømsgodset_IF.png"
  },
  {
    squadId: 372,
    squadName: "Criciuma Esporte Clube",
    shortSquadName: "Criciuma EC",
    squadLogo: null
  },
  {
    squadId: 496,
    squadName: "HNK Rijeka",
    shortSquadName: "HNK Rijeka",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2164_HNK_Rijeka.png"
  },
  {
    squadId: 542,
    squadName: "FK Atyrau",
    shortSquadName: "Atyrau",
    squadLogo: null
  },
  {
    squadId: 565,
    squadName: "Ituano Futebol Clube (SP)",
    shortSquadName: "Ituano",
    squadLogo: null
  },
  {
    squadId: 773,
    squadName: "Getafe CF",
    shortSquadName: "Getafe",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1728_Getafe_CF.png"
  },
  {
    squadId: 1065,
    squadName: "SC Braga",
    shortSquadName: "SC Braga",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Sporting_Braga.png"
  },
  {
    squadId: 1177,
    squadName: "OFI Crete FC",
    shortSquadName: "OFI Crete FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12517_OFI_Crete.png"
  },
  {
    squadId: 1421,
    squadName: "Gremio Foot-Ball Porto Alegrense",
    shortSquadName: "Gremio",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2605_Gremio_Foot-Ball_Porto_Alegrense.png"
  },
  {
    squadId: 1423,
    squadName: "LD Alajuelense",
    shortSquadName: "LD Alajuelense",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/16835_LD_Alajuelense.png"
  },
  {
    squadId: 1535,
    squadName: "FC Zurich",
    shortSquadName: "FC Zurich",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/6412_FC_Zürich.png"
  },
  {
    squadId: 1559,
    squadName: "FC Cincinnati",
    shortSquadName: "Cincinnati",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/37892_Cincinnati.png"
  },
  {
    squadId: 1728,
    squadName: "AC Monza",
    shortSquadName: "Monza",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3236_AC_Monza.png"
  },
  {
    squadId: 1740,
    squadName: "Amiens SC",
    shortSquadName: "Amiens SC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3789_Amiens_SC.png"
  },
  {
    squadId: 1771,
    squadName: "Bryne FK",
    shortSquadName: "Bryne FK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7107_Bryne_FK.png"
  },
  {
    squadId: 1930,
    squadName: "FC Viktoria Koln",
    shortSquadName: "Viktoria Koln",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1388_FC_Viktoria_Koln.png"
  },
  {
    squadId: 2031,
    squadName: "1.FC Union Berlin",
    shortSquadName: "Union Berlin",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1277_1.FC_Union_Berlin.png"
  },
  {
    squadId: 2041,
    squadName: "Club SD Flandria",
    shortSquadName: "Club Flandria",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12112_Club_SD_Flandria.png"
  },
  {
    squadId: 2179,
    squadName: "Club Tijuana",
    shortSquadName: "Club Tijuana",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15459_Club_Tijuana.png"
  },
  {
    squadId: 2263,
    squadName: "Club Atletico Tigre",
    shortSquadName: "Tigre",
    squadLogo: null
  },
  {
    squadId: 2332,
    squadName: "Cercle Brugge",
    shortSquadName: "Cercle Brugge",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5070_Cercle_Brugge.png"
  },
  {
    squadId: 2459,
    squadName: "Fenerbahce",
    shortSquadName: "Fenerbahce",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2004_Fenerbahce_SK.png"
  },
  {
    squadId: 2768,
    squadName: "Eastleigh FC",
    shortSquadName: "Eastleigh",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1772_Eastleigh_FC.png"
  },
  {
    squadId: 2956,
    squadName: "KAA Gent",
    shortSquadName: "KAA Gent",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1571_KAA_Gent.png"
  },
  {
    squadId: 3411,
    squadName: "Willem II Tilburg",
    shortSquadName: "Willem II",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Willem_II_Tilburg.png"
  },
  {
    squadId: 3528,
    squadName: "SD Logrones",
    shortSquadName: "SD Logrones",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1480_SD_Logroñés.png"
  },
  {
    squadId: 3670,
    squadName: "Queen's Park FC",
    shortSquadName: "Queen's Park",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/8546_Queen's_Park_FC.png"
  },
  {
    squadId: 3695,
    squadName: "SK Slavia Prague",
    shortSquadName: "Slavia Prague",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2051_SK_Slavia_Prag.png"
  },
  {
    squadId: 3704,
    squadName: "FC Hegelmann",
    shortSquadName: "Hegelmann",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/31322_FC_Hegelmann.svg"
  },
  {
    squadId: 3740,
    squadName: "KV Kortrijk",
    shortSquadName: "KV Kortrijk",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_KV_Kortrijk.png"
  },
  {
    squadId: 3774,
    squadName: "Club Necaxa",
    shortSquadName: "Necaxa",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2283_Club_Necaxa.png"
  },
  {
    squadId: 3895,
    squadName: "Sumgayit PFC",
    shortSquadName: "Sumgayit",
    squadLogo: null
  },
  {
    squadId: 4178,
    squadName: "Servette FC",
    shortSquadName: "Servette FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2355_Servette_FC.png"
  },
  {
    squadId: 4230,
    squadName: "Stade Reims",
    shortSquadName: "Stade Reims",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3802_Stade_Reims.png"
  },
  {
    squadId: 4270,
    squadName: "SpVgg Bayreuth",
    shortSquadName: "SpVgg Bayreuth",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2546_SpVgg_Bayreuth.png"
  },
  {
    squadId: 4416,
    squadName: "Sport Club do Recife",
    shortSquadName: "Sport Recife",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5674_Sport_Club_do_Recife.png"
  },
  {
    squadId: 4870,
    squadName: "Solihull Moors",
    shortSquadName: "Solihull Moors",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2015_Solihull_Moors.png"
  },
  {
    squadId: 5072,
    squadName: "SC Paderborn 07",
    shortSquadName: "SC Paderborn",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1303_SC_Paderborn_07.png"
  },
  {
    squadId: 5109,
    squadName: "PEC Zwolle",
    shortSquadName: "PEC Zwolle",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1543_PEC_Zwolle.png"
  },
  {
    squadId: 5159,
    squadName: "FC Groningen",
    shortSquadName: "FC Groningen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Groningen.png"
  },
  {
    squadId: 5180,
    squadName: "Basaksehir FK",
    shortSquadName: "Basaksehir",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2005_Medipol_Basaksehir.png"
  },
  {
    squadId: 5249,
    squadName: "Dulwich Hamlet",
    shortSquadName: "Dulwich Hamlet",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2487_Dulwich_Hamlet_FC.png"
  },
  {
    squadId: 5282,
    squadName: "PSV Eindhoven",
    shortSquadName: "PSV Eindhoven",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/11_PSV_Eindhoven.png"
  },
  {
    squadId: 5306,
    squadName: "Rakow Czestochowa",
    shortSquadName: "Rakow",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1622_Rakow_Czestochowa.png"
  },
  {
    squadId: 5359,
    squadName: "Caracas FC",
    shortSquadName: "Caracas FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15680_Caracas_FC.png"
  },
  {
    squadId: 5430,
    squadName: "GAIS Goteborg",
    shortSquadName: "GAIS",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1451_GAIS_Goteborg.png"
  },
  {
    squadId: 5504,
    squadName: "FC Helsingor",
    shortSquadName: "FC Helsingor",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Helsingor.png"
  },
  {
    squadId: 5548,
    squadName: "Hereford FC",
    shortSquadName: "Hereford",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2517_FC_Hereford.png"
  },
  {
    squadId: 5572,
    squadName: "Deportivo Municipal",
    shortSquadName: "Dep. Municipal",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15786_Deportivo_Municipal.png"
  },
  {
    squadId: 5681,
    squadName: "Vikingur Reykjavik",
    shortSquadName: "Vikingur",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1515_Vikingur_Reykjavik.png"
  },
  {
    squadId: 5869,
    squadName: "Viking FK",
    shortSquadName: "Viking FK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7100_Viking_Stavanger.png"
  },
  {
    squadId: 5924,
    squadName: "CD Everton",
    shortSquadName: "CD Everton",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12293_CD_Everton.png"
  },
  {
    squadId: 5968,
    squadName: "AFC Wimbledon",
    shortSquadName: "AFC Wimbledon",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1178_AFC_Wimbledon.png"
  },
  {
    squadId: 5972,
    squadName: "San Jose Earthquakes",
    shortSquadName: "San Jose",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7851_San_Jose_Earthquakes.png"
  },
  {
    squadId: 6051,
    squadName: "Real Valladolid CF",
    shortSquadName: "Real Valladolid",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1735_Real_Valladolid_CF.png"
  },
  {
    squadId: 6098,
    squadName: "Kalmar FF",
    shortSquadName: "Kalmar FF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Kalmar_FF.png"
  },
  {
    squadId: 6152,
    squadName: "Eastbourne Borough",
    shortSquadName: "Eastbourne",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2306_Eastbourne_Borough.png"
  },
  {
    squadId: 6249,
    squadName: "Bayer 04 Leverkusen",
    shortSquadName: "B. Leverkusen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1260_Bayer_04_Leverkusen.png"
  },
  {
    squadId: 6306,
    squadName: "Hibernian FC",
    shortSquadName: "Hibernian FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Hibernian_FC.png"
  },
  {
    squadId: 6571,
    squadName: "US Salernitana 1919",
    shortSquadName: "Salernitana",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1865_US_Salernitana_1919.png"
  },
  {
    squadId: 6614,
    squadName: "Stjarnan Gardabaer",
    shortSquadName: "Stjarnan",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1511_UMF_Stjarnan_Gardabaer.png"
  },
  {
    squadId: 7083,
    squadName: "Elche CF",
    shortSquadName: "Elche CF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1793_Elche_CF.png"
  },
  {
    squadId: 7390,
    squadName: "Club Atletico Lanus",
    shortSquadName: "Lanus",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12063_Club_Atlético_Lanús.png"
  },
  {
    squadId: 7527,
    squadName: "FC Vaduz",
    shortSquadName: "FC Vaduz",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2354_FC_Vaduz.png"
  },
  {
    squadId: 7594,
    squadName: "Ceara Sporting Club",
    shortSquadName: "Ceara SC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2620_Ceara_Sporting_Club.png"
  },
  {
    squadId: 7783,
    squadName: "Pyramids FC",
    shortSquadName: "Pyramids FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/27533_Pyramids_Al_Ahram_FC.png"
  },
  {
    squadId: 7819,
    squadName: "Sarpsborg 08 FF",
    shortSquadName: "Sarpsborg 08",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1472_Sarpsborg_08_FF.png"
  },
  {
    squadId: 7845,
    squadName: "CF Pachuca",
    shortSquadName: "Pachuca",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15413_CF_Pachuca.png"
  },
  {
    squadId: 7853,
    squadName: "Toronto FC",
    shortSquadName: "Toronto",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2102_Toronto_FC.png"
  },
  {
    squadId: 7948,
    squadName: "Worthing FC",
    shortSquadName: "Worthing",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2506_Worthing_FC.png"
  },
  {
    squadId: 7972,
    squadName: "LOSC Lille",
    shortSquadName: "LOSC Lille",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1974_LOSC_Lille.png"
  },
  {
    squadId: 8110,
    squadName: "Hertha BSC",
    shortSquadName: "Hertha BSC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2457_Hertha_BSC.png"
  },
  {
    squadId: 8228,
    squadName: "AC Horsens",
    shortSquadName: "AC Horsens",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7465_AC_Horsens.png"
  },
  {
    squadId: 8239,
    squadName: "Brentford FC",
    shortSquadName: "Brentford",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1153_Brentford_FC.png"
  },
  {
    squadId: 8461,
    squadName: "NK Lokomotiva Zagreb",
    shortSquadName: "NK Lokomotiva",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2167_NK_Lokomotiva_Zagreb.png"
  },
  {
    squadId: 8583,
    squadName: "CD Eldense",
    shortSquadName: "Eldense",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/845_CD_Eldense.png"
  },
  {
    squadId: 9150,
    squadName: "Venezia FC",
    shortSquadName: "Venezia",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3191_Venezia_FC.png"
  },
  {
    squadId: 9566,
    squadName: "HNK Gorica",
    shortSquadName: "HNK Gorica",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/9557_HNK_Gorica.png"
  },
  {
    squadId: 9657,
    squadName: "Los Angeles FC",
    shortSquadName: "LAFC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/62148_Los_Angeles_FC.png"
  },
  {
    squadId: 9794,
    squadName: "Zaglebie Sosnowiec",
    shortSquadName: "Z. Sosnowiec",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1612_Zaglebie_Sosnowiec.png"
  },
  {
    squadId: 9929,
    squadName: "Lincoln City",
    shortSquadName: "Lincoln City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1207_Lincoln_City.png"
  },
  {
    squadId: 9954,
    squadName: "Sparta Rotterdam",
    shortSquadName: "Sparta R.",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Sparta_Rotterdam.png"
  },
  {
    squadId: 10026,
    squadName: "Los Angeles Galaxy",
    shortSquadName: "Los Angeles",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7847_Los_Angeles_Galaxy.png"
  },
  {
    squadId: 10065,
    squadName: "Partick Thistle FC",
    shortSquadName: "Partick Thistle",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2195_Partick_Thistle_FC.png"
  },
  {
    squadId: 10088,
    squadName: "RB Leipzig",
    shortSquadName: "RB Leipzig",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1261_RB_Leipzig.png"
  },
  {
    squadId: 10183,
    squadName: "Podbeskidzie Bielsko-Biala",
    shortSquadName: "Podbeskidzie",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1610_Podbeskidzie_Bielsko-Biala.png"
  },
  {
    squadId: 10282,
    squadName: "Neftchi Baku",
    shortSquadName: "Neftchi Baku",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_3504_Neftchi_Baku.png"
  },
  {
    squadId: 10585,
    squadName: "Breidablik Kopavogur",
    shortSquadName: "Breidablik",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1513_Breidablik_Kopavogur.png"
  },
  {
    squadId: 10781,
    squadName: "Kristiansund BK",
    shortSquadName: "Kristiansund",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1477_Kristiansund_BK.png"
  },
  {
    squadId: 11083,
    squadName: "Kalju FC",
    shortSquadName: "Kalju FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12960_Kalju_FC.png"
  },
  {
    squadId: 11087,
    squadName: "SK Brann",
    shortSquadName: "Brann",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7095_SK_Brann.png"
  },
  {
    squadId: 11209,
    squadName: "Bristol City",
    shortSquadName: "Bristol City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1655_Bristol_City.png"
  },
  {
    squadId: 11210,
    squadName: "CS Cartagines",
    shortSquadName: "Cartagines",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/16836_CS_Cartaginés.png"
  },
  {
    squadId: 11474,
    squadName: "Exeter City",
    shortSquadName: "Exeter City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1187_Exeter_City.png"
  },
  {
    squadId: 11544,
    squadName: "Grasshopper Club Zurich",
    shortSquadName: "Grasshoppers",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1936_Grasshopper_Club_Zurich.png"
  },
  {
    squadId: 11585,
    squadName: "Tromso IL",
    shortSquadName: "Tromso IL",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7089_Tromsø_IL.png"
  },
  {
    squadId: 11592,
    squadName: "Venados FC Yucatan",
    shortSquadName: "Venados FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15428_Venados_FC_Yucatán.png"
  },
  {
    squadId: 11596,
    squadName: "Chorley FC",
    shortSquadName: "Chorley",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2323_Chorley_FC.png"
  },
  {
    squadId: 11640,
    squadName: "UE Cornella",
    shortSquadName: "UE Cornella",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1799_UE_Cornella.png"
  },
  {
    squadId: 11890,
    squadName: "Correcaminos de la UAT",
    shortSquadName: "Correcaminos",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15440_Correcaminos_de_la_UAT.png"
  },
  {
    squadId: 11978,
    squadName: "FK Jerv",
    shortSquadName: "FK Jerv",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1484_FK_Jerv.gif"
  },
  {
    squadId: 12027,
    squadName: "CA Boston River",
    shortSquadName: "Boston River",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2875_Club_Atletico_Boston_River.png"
  },
  {
    squadId: 12139,
    squadName: "Budapest Honved FC",
    shortSquadName: "Honved",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/10194_Budapest_Honvéd_FC.png"
  },
  {
    squadId: 12156,
    squadName: "Charlton Athletic",
    shortSquadName: "Charlton",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Charlton_Athletic.png"
  },
  {
    squadId: 12173,
    squadName: "Barnsley FC",
    shortSquadName: "Barnsley FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1158_Barnsley_FC.png"
  },
  {
    squadId: 12294,
    squadName: "Olympique Marseille",
    shortSquadName: "Marseille",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1964_Olympique_Marseille.png"
  },
  {
    squadId: 12359,
    squadName: "Fortuna Sittard",
    shortSquadName: "Fortuna Sittard",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/44_Fortuna_Sittard.png"
  },
  {
    squadId: 12372,
    squadName: "Keflavik IF",
    shortSquadName: "Keflavik",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7725_Keflavík_ÍF.png"
  },
  {
    squadId: 12470,
    squadName: "VfL Wolfsburg",
    shortSquadName: "VfL Wolfsburg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_VfL_Wolfsburg.png"
  },
  {
    squadId: 12686,
    squadName: "FC Nordsjaelland",
    shortSquadName: "Nordsjaelland",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7458_Nordsjelland.png"
  },
  {
    squadId: 12760,
    squadName: "Dundalk FC",
    shortSquadName: "Dundalk FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2091_Dundalk_FC.png"
  },
  {
    squadId: 13008,
    squadName: "Paris FC",
    shortSquadName: "Paris FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3846_Paris_FC.png"
  },
  {
    squadId: 13058,
    squadName: "Feyenoord Rotterdam",
    shortSquadName: "Feyenoord",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1533_Feyenoord_Rotterdam.png"
  },
  {
    squadId: 13099,
    squadName: "CF Montreal",
    shortSquadName: "Montreal",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/14969_Club_de_Foot_Montréal.png"
  },
  {
    squadId: 13404,
    squadName: "Houston Dynamo FC",
    shortSquadName: "Houston",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7944_Houston_Dynamo_FC.png"
  },
  {
    squadId: 13510,
    squadName: "FC Utrecht",
    shortSquadName: "FC Utrecht",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/17_FC_Utrecht.png"
  },
  {
    squadId: 13679,
    squadName: "FC Lausanne-Sport",
    shortSquadName: "Lausanne-Sport",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/6434_FC_Lausanne-Sport.png"
  },
  {
    squadId: 14079,
    squadName: "Fortaleza Esporte Clube",
    shortSquadName: "Fortaleza",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5663_Fortaleza_Esporte_Clube.png"
  },
  {
    squadId: 14339,
    squadName: "Newport County",
    shortSquadName: "Newport County",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Newport_County.png"
  },
  {
    squadId: 14447,
    squadName: "CD Trasandino de Los Andes",
    shortSquadName: "CD Trasandino de Los Andes",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12332_CD_Trasandino_de_Los_Andes.png"
  },
  {
    squadId: 14480,
    squadName: "Real Union Club",
    shortSquadName: "Real Union",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1786_Real_Union_Club.png"
  },
  {
    squadId: 14512,
    squadName: "Royal Excelsior Virton",
    shortSquadName: "RE Virton",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2366_Royal_Excelsior_Virton.png"
  },
  {
    squadId: 14649,
    squadName: "SK Beveren",
    shortSquadName: "SK Beveren",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1579_Waasland-Beveren.png"
  },
  {
    squadId: 14733,
    squadName: "FC Arouca",
    shortSquadName: "Arouca",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1690_FC_Arouca.png"
  },
  {
    squadId: 14930,
    squadName: "Celaya FC",
    shortSquadName: "Celaya",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15426_Celaya_FC.png"
  },
  {
    squadId: 14958,
    squadName: "Coventry City",
    shortSquadName: "Coventry",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1188_Coventry_City.png"
  },
  {
    squadId: 14994,
    squadName: "CD Leones Negros de la UdeG",
    shortSquadName: "Leones Negros",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15441_CD_Leones_Negros_de_la_UdeG.png"
  },
  {
    squadId: 15052,
    squadName: "Sogndal IL",
    shortSquadName: "Sogndal IL",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7101_Sogndal_IL.png"
  },
  {
    squadId: 15130,
    squadName: "AIK Solna",
    shortSquadName: "AIK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1436_AIK_Solna.png"
  },
  {
    squadId: 15241,
    squadName: "Magallanes CF",
    shortSquadName: "Magallanes",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12321_Club_Deportivo_Magallanes.png"
  },
  {
    squadId: 15268,
    squadName: "Widzew Lodz",
    shortSquadName: "Widzew Lodz",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/13515_Widzew_Lodz.png"
  },
  {
    squadId: 15349,
    squadName: "CA River Plate",
    shortSquadName: "River Plate",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2577_Club_Atletico_River_Plate.png"
  },
  {
    squadId: 15469,
    squadName: "Club Nacional",
    shortSquadName: "Nacional",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15634_Club_Nacional.png"
  },
  {
    squadId: 15517,
    squadName: "Oud-Heverlee Leuven",
    shortSquadName: "OH Leuven",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1587_Oud-Heverlee_Leuven.png"
  },
  {
    squadId: 15549,
    squadName: "FC Basel 1893",
    shortSquadName: "FC Basel",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1932_FC_Basel_1893.png"
  },
  {
    squadId: 15581,
    squadName: "Lech Poznan",
    shortSquadName: "Lech Poznan",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1595_Lech_Poznan.png"
  },
  {
    squadId: 15821,
    squadName: "Arminia Bielefeld",
    shortSquadName: "Arm. Bielefeld",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Arminia_Bielefeld.png"
  },
  {
    squadId: 15840,
    squadName: "Warta Poznan",
    shortSquadName: "Warta Poznan",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/13545_Warta_Poznan.png"
  },
  {
    squadId: 16046,
    squadName: "Olympique Lyon",
    shortSquadName: "Olympique Lyon",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1966_Olympique_Lyon.png"
  },
  {
    squadId: 16232,
    squadName: "FC Girondins Bordeaux",
    shortSquadName: "G. Bordeaux",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3772_FC_Girondins_Bordeaux.png"
  },
  {
    squadId: 16247,
    squadName: "Club Athletico Paranaense",
    shortSquadName: "Athletico-PR",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5651_Club_Athletico_Paranaense.png"
  },
  {
    squadId: 16280,
    squadName: "Woking FC",
    shortSquadName: "Woking",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1706_Woking_FC.png"
  },
  {
    squadId: 16335,
    squadName: "Beerschot V.A.",
    shortSquadName: "Beerschot V.A.",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5280_K._Beerschot_V.A..png"
  },
  {
    squadId: 16437,
    squadName: "Excelsior Rotterdam",
    shortSquadName: "Excelsior",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/18608_SBV_Excelsior_Rotterdam.png"
  },
  {
    squadId: 16641,
    squadName: "Oxford City",
    shortSquadName: "Oxford City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1847_Oxford_City.png"
  },
  {
    squadId: 16675,
    squadName: "Odds BK",
    shortSquadName: "Odds BK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7090_Odds_BK.png"
  },
  {
    squadId: 16775,
    squadName: "Reading FC",
    shortSquadName: "Reading",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1147_Reading_FC.png"
  },
  {
    squadId: 17005,
    squadName: "Samsunspor",
    shortSquadName: "Samsunspor",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/4495_Samsunspor.png"
  },
  {
    squadId: 17017,
    squadName: "Philadelphia Union",
    shortSquadName: "Philadelphia",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2115_Philadelphia_Union.png"
  },
  {
    squadId: 17123,
    squadName: "KA Akureyri",
    shortSquadName: "KA Akureyri",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1512_KA_Akureyri.png"
  },
  {
    squadId: 17417,
    squadName: "BSC Young Boys",
    shortSquadName: "BSC Young Boys",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1934_BSC_Young_Boys.png"
  },
  {
    squadId: 17418,
    squadName: "Valerenga Fotball",
    shortSquadName: "Valerenga",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7092_Vålerenga_Fotball.png"
  },
  {
    squadId: 17454,
    squadName: "Avai Futebol Clube (SC)",
    shortSquadName: "Avai FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5666_Avaí_Futebol_Clube_(SC).png"
  },
  {
    squadId: 17668,
    squadName: "FC Famalicao",
    shortSquadName: "Famalicao",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Famalicao.png"
  },
  {
    squadId: 17923,
    squadName: "FC Eindhoven",
    shortSquadName: "FC Eindhoven",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/42_FC_Eindhoven.png"
  },
  {
    squadId: 18100,
    squadName: "Cracovia",
    shortSquadName: "Cracovia",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1598_Cracovia_Krakow.png"
  },
  {
    squadId: 18179,
    squadName: "SC Verl",
    shortSquadName: "SC Verl",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1395_SC_Verl.png"
  },
  {
    squadId: 18451,
    squadName: "HNK Sibenik",
    shortSquadName: "HNK Sibenik",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/9443_HNK_Sibenik.png"
  },
  {
    squadId: 18516,
    squadName: "SV Wehen Wiesbaden",
    shortSquadName: "Wehen Wiesbaden",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1294_SV_Wehen_Wiesbaden.png"
  },
  {
    squadId: 18582,
    squadName: "Panathinaikos Athens",
    shortSquadName: "Panathinaikos",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2022_Panathinaikos_Athens.png"
  },
  {
    squadId: 18651,
    squadName: "Rosenborg BK",
    shortSquadName: "Rosenborg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1464_Rosenborg_BK.png"
  },
  {
    squadId: 18697,
    squadName: "FC Augsburg",
    shortSquadName: "FC Augsburg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1274_FC_Augsburg.png"
  },
  {
    squadId: 18705,
    squadName: "Fredrikstad FK",
    shortSquadName: "Fredrikstad",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Fredrikstad_FK.png"
  },
  {
    squadId: 18894,
    squadName: "Brondby IF",
    shortSquadName: "Brondby IF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1407_Brondby_IF.png"
  },
  {
    squadId: 18896,
    squadName: "Stellenbosch FC",
    shortSquadName: "Stellenbosch FC",
    squadLogo: null
  },
  {
    squadId: 18960,
    squadName: "FC Porto",
    shortSquadName: "FC Porto",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1672_FC_Porto.png"
  },
  {
    squadId: 19110,
    squadName: "CA Rentistas",
    shortSquadName: "Rentistas",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15639_CA_Rentistas.png"
  },
  {
    squadId: 19138,
    squadName: "RCD Mallorca",
    shortSquadName: "RCD Mallorca",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/686_RCD_Mallorca.png"
  },
  {
    squadId: 19151,
    squadName: "Clermont Foot 63",
    shortSquadName: "Clermont Foot",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3797_Clermont_Foot_Auvergne_63.png"
  },
  {
    squadId: 19177,
    squadName: "SV Wacker Burghausen",
    shortSquadName: "W. Burghausen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1321_SV_Wacker_Burghausen.png"
  },
  {
    squadId: 19206,
    squadName: "Santos FC",
    shortSquadName: "Santos FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5655_Santos_FC.png"
  },
  {
    squadId: 19251,
    squadName: "SV Waldhof Mannheim",
    shortSquadName: "Waldh. Mannheim",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1370_SV_Waldhof_Mannheim.png"
  },
  {
    squadId: 19616,
    squadName: "Aalesunds FK",
    shortSquadName: "Aalesund",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7106_Aalesunds_FK.png"
  },
  {
    squadId: 19775,
    squadName: "HB Koge",
    shortSquadName: "HB Koge",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_HB_Koge.png"
  },
  {
    squadId: 20016,
    squadName: "Motherwell FC",
    shortSquadName: "Motherwell FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Motherwell_FC.png"
  },
  {
    squadId: 20133,
    squadName: "Chicago Fire FC",
    shortSquadName: "Chicago",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7848_Chicago_Fire_FC.png"
  },
  {
    squadId: 20144,
    squadName: "Plymouth Argyle",
    shortSquadName: "Plymouth",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1649_Plymouth_Argyle.png"
  },
  {
    squadId: 20302,
    squadName: "Hallescher FC",
    shortSquadName: "Hallescher FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Hallescher_FC.png"
  },
  {
    squadId: 20454,
    squadName: "Legia Warszawa",
    shortSquadName: "Legia Warszawa",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1594_Legia_Warszawa.png"
  },
  {
    squadId: 20887,
    squadName: "Millwall FC",
    shortSquadName: "Millwall",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1160_Millwall_FC.png"
  },
  {
    squadId: 21139,
    squadName: "Olympiacos Piraeus",
    shortSquadName: "Olympiacos",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12509_Olympiacos_Piraeus.png"
  },
  {
    squadId: 21145,
    squadName: "Tonbridge Angels",
    shortSquadName: "Tonbridge",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2502_Tonbridge_Angels_FC.png"
  },
  {
    squadId: 21425,
    squadName: "FC St. Gallen 1879",
    shortSquadName: "FC St. Gallen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1935_FC_St._Gallen_1879.png"
  },
  {
    squadId: 21681,
    squadName: "Boavista FC",
    shortSquadName: "Boavista",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/9613_Boavista_FC.png"
  },
  {
    squadId: 21702,
    squadName: "FCI Levadia",
    shortSquadName: "FCI Levadia",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12927_FCI_Levadia.png"
  },
  {
    squadId: 21706,
    squadName: "FC Hansa Rostock",
    shortSquadName: "Hansa Rostock",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Hansa_Rostock.png"
  },
  {
    squadId: 22196,
    squadName: "SpVgg Greuther Furth",
    shortSquadName: "Greuther Furth",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2469_SpVgg_Greuther_Fürth.png"
  },
  {
    squadId: 22198,
    squadName: "TSV 1860 Munich",
    shortSquadName: "1860 Munich",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2461_TSV_1860_Munich.png"
  },
  {
    squadId: 22240,
    squadName: "PAOK Thessaloniki",
    shortSquadName: "PAOK Salonika",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2021_PAOK_Thessaloniki.png"
  },
  {
    squadId: 22392,
    squadName: "Maccabi Tel Aviv",
    shortSquadName: "M. Tel Aviv",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2232_Maccabi_Tel_Aviv.png"
  },
  {
    squadId: 22613,
    squadName: "Kuopion Palloseura",
    shortSquadName: "KuPS",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1499_Kuopion_Palloseura.png"
  },
  {
    squadId: 22827,
    squadName: "HNK Hajduk Split",
    shortSquadName: "Hajduk Split",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2165_HNK_Hajduk_Split.png"
  },
  {
    squadId: 22838,
    squadName: "Dunfermline Athletic FC",
    shortSquadName: "Dunfermline A.",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Dunfermline_Athletic.png"
  },
  {
    squadId: 22958,
    squadName: "Concord Rangers",
    shortSquadName: "Concord Rangers",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2081_Concord_Rangers.png"
  },
  {
    squadId: 22992,
    squadName: "Pisa Sporting Club",
    shortSquadName: "Pisa",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3207_AC_Pisa_1909.png"
  },
  {
    squadId: 23168,
    squadName: "1. FC Koln",
    shortSquadName: "1. FC Koln",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1266_1._FC_Koln.png"
  },
  {
    squadId: 23588,
    squadName: "SC Heerenveen",
    shortSquadName: "Heerenveen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1537_SC_Heerenveen.png"
  },
  {
    squadId: 23647,
    squadName: "Atletico de San Luis",
    shortSquadName: "San Luis",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/25866_Atlético_de_San_Luis.png"
  },
  {
    squadId: 23767,
    squadName: "Crawley Town",
    shortSquadName: "Crawley Town",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1198_Crawley_Town.png"
  },
  {
    squadId: 23819,
    squadName: "FC Dordrecht",
    shortSquadName: "FC Dordrecht",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1568_FC_Dordrecht.png"
  },
  {
    squadId: 23873,
    squadName: "Botev Plovdiv",
    shortSquadName: "Botev Plovdiv",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2039_Botev_Plovdiv.png"
  },
  {
    squadId: 24016,
    squadName: "Quilmes Atletico Club",
    shortSquadName: "Quilmes",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12067_Quilmes_Atlético_Club.png"
  },
  {
    squadId: 24408,
    squadName: "Club Alianza Lima",
    shortSquadName: "Alianza Lima",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7239_Club_Alianza_Lima.png"
  },
  {
    squadId: 24432,
    squadName: "Pafos FC",
    shortSquadName: "Pafos FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2178_Pafos_FC.png"
  },
  {
    squadId: 24448,
    squadName: "Aarhus GF",
    shortSquadName: "Aarhus GF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1410_Aarhus_GF.png"
  },
  {
    squadId: 24449,
    squadName: "Viborg FF",
    shortSquadName: "Viborg FF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Viborg_FF.png"
  },
  {
    squadId: 24695,
    squadName: "Royale Union Saint Gilloise",
    shortSquadName: "Union SG",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1590_Royale_Union_Saint_Gilloise.png"
  },
  {
    squadId: 24701,
    squadName: "CF Atlante",
    shortSquadName: "Atlante",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15406_CF_Atlante.png"
  },
  {
    squadId: 24711,
    squadName: "Urawa Red Diamonds",
    shortSquadName: "Urawa Reds",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2407_Urawa_Red_Diamonds.png"
  },
  {
    squadId: 25037,
    squadName: "Montevideo City Torque",
    shortSquadName: "Mvd City Torque",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/23497_Montevideo_City_Torque.png"
  },
  {
    squadId: 25051,
    squadName: "Wycombe Wanderers",
    shortSquadName: "Wycombe",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Wycombe_Wanderers.png"
  },
  {
    squadId: 25054,
    squadName: "Preussen Munster",
    shortSquadName: "Pr. Munster",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Preussen_Munster.png"
  },
  {
    squadId: 25090,
    squadName: "Cimarrones de Sonora",
    shortSquadName: "Cimarrones",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/26089_Cimarrones_de_Sonora.png"
  },
  {
    squadId: 25098,
    squadName: "FSV Zwickau",
    shortSquadName: "FSV Zwickau",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FSV_Zwickau.png"
  },
  {
    squadId: 25285,
    squadName: "Levante UD",
    shortSquadName: "Levante",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Levante_UD.png"
  },
  {
    squadId: 25293,
    squadName: "IK Start",
    shortSquadName: "IK Start",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7102_IK_Start.png"
  },
  {
    squadId: 25436,
    squadName: "Nashville SC",
    shortSquadName: "Nashville",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/30149_Nashville.png"
  },
  {
    squadId: 25519,
    squadName: "Rapid Vienna",
    shortSquadName: "Rapid Vienna",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1943_Rapid_Vienna.png"
  },
  {
    squadId: 25579,
    squadName: "IK Brage",
    shortSquadName: "Brage",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2471_IK_Brage.png"
  },
  {
    squadId: 25789,
    squadName: "Union La Calera",
    shortSquadName: "La Calera",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12314_Unión_La_Calera.png"
  },
  {
    squadId: 25852,
    squadName: "CA Newell's Old Boys",
    shortSquadName: "Newell's",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12064_Newell's_Old_Boys.png"
  },
  {
    squadId: 25911,
    squadName: "Royal Antwerp FC",
    shortSquadName: "Royal Antwerp",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1583_Royal_Antwerp_FC.png"
  },
  {
    squadId: 25989,
    squadName: "Falkenbergs FF",
    shortSquadName: "Falkenberg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Falkenbergs_FF.png"
  },
  {
    squadId: 26054,
    squadName: "CFR Cluj",
    shortSquadName: "CFR Cluj",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2341_CFR_Cluj.png"
  },
  {
    squadId: 26056,
    squadName: "AeK Athens",
    shortSquadName: "AeK Athens",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12511_AEK_Athens.png"
  },
  {
    squadId: 26202,
    squadName: "Ludogorets Razgrad",
    shortSquadName: "Ludogorets",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/9311_Ludogorets.png"
  },
  {
    squadId: 26353,
    squadName: "SV Darmstadt 98",
    shortSquadName: "Darmstadt 98",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2520_SV_Darmstadt_98.png"
  },
  {
    squadId: 26367,
    squadName: "Santos Laguna",
    shortSquadName: "Santos Laguna",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2268_Santos_Laguna.png"
  },
  {
    squadId: 26385,
    squadName: "Cesena FC",
    shortSquadName: "Cesena",
    squadLogo: null
  },
  {
    squadId: 26457,
    squadName: "Stabaek Fotball",
    shortSquadName: "Stabaek Fotball",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1473_Stabaek_IF.png"
  },
  {
    squadId: 26502,
    squadName: "Ae Larisa",
    shortSquadName: "Ae Larisa",
    squadLogo: null
  },
  {
    squadId: 26623,
    squadName: "Miedz Legnica",
    shortSquadName: "Miedz Legnica",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1613_Miedz_Legnica.png"
  },
  {
    squadId: 26667,
    squadName: "Coritiba Foot Ball Club",
    shortSquadName: "Coritiba FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2625_Coritiba_Foot_Ball_Club.png"
  },
  {
    squadId: 26699,
    squadName: "Galatasaray",
    shortSquadName: "Galatasaray",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2003_Galatasaray_SK.png"
  },
  {
    squadId: 26867,
    squadName: "CA Penarol",
    shortSquadName: "Penarol",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15633_Peñarol.png"
  },
  {
    squadId: 26895,
    squadName: "Jagiellonia Bialystok",
    shortSquadName: "Jagiellonia",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1597_Jagiellonia_Bialystok.png"
  },
  {
    squadId: 26989,
    squadName: "St. Johnstone FC",
    shortSquadName: "St. Johnstone",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_St_Johnstone.png"
  },
  {
    squadId: 27085,
    squadName: "1.FC Nuremberg",
    shortSquadName: "1.FC Nuremberg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1278_1.FC_Nuremberg.png"
  },
  {
    squadId: 27091,
    squadName: "Chesterfield FC",
    shortSquadName: "Chesterfield FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1193_Chesterfield_FC.png"
  },
  {
    squadId: 27176,
    squadName: "KV Mechelen",
    shortSquadName: "KV Mechelen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Yellow-Red_KV_Mechelen.png"
  },
  {
    squadId: 27180,
    squadName: "NS Mura",
    shortSquadName: "NS Mura",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/31278_NŠ_Mura.png"
  },
  {
    squadId: 27251,
    squadName: "Clube de Regatas do Flamengo",
    shortSquadName: "Flamengo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5654_Clube_de_Regatas_do_Flamengo.png"
  },
  {
    squadId: 27287,
    squadName: "Vitoria SC",
    shortSquadName: "Vitoria SC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1676_Vitoria_Guimaraes_SC.png"
  },
  {
    squadId: 27504,
    squadName: "Goias Esporte Clube",
    shortSquadName: "Goias EC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5643_Goiás_EC.png"
  },
  {
    squadId: 27570,
    squadName: "Puskas Akademia FC",
    shortSquadName: "Puskas AFC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1630_Puskas_Akademia_FC.png"
  },
  {
    squadId: 27985,
    squadName: "AC Le Havre",
    shortSquadName: "AC Le Havre",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3793_Le_Havre.png"
  },
  {
    squadId: 28158,
    squadName: "Aalborg BK",
    shortSquadName: "Aalborg BK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Aalborg_BK.png"
  },
  {
    squadId: 28212,
    squadName: "RC Strasbourg Alsace",
    shortSquadName: "R. Strasbourg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1981_RC_Strasbourg_Alsace.png"
  },
  {
    squadId: 28372,
    squadName: "Club Atletico Morelia",
    shortSquadName: "Morelia",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15411_Club_Atlético_Morelia.png"
  },
  {
    squadId: 28535,
    squadName: "Naestved Boldklub",
    shortSquadName: "Naestved BK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7475_Naestved_Boldklub.jpg"
  },
  {
    squadId: 28997,
    squadName: "Aberdeen FC",
    shortSquadName: "Aberdeen FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2190_Aberdeen_FC.png"
  },
  {
    squadId: 29065,
    squadName: "Club Sporting Cristal",
    shortSquadName: "Sport. Cristal",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15532_Club_Sporting_Cristal.png"
  },
  {
    squadId: 29069,
    squadName: "Chemnitzer FC",
    shortSquadName: "Chemnitzer FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Chemnitzer_FC.png"
  },
  {
    squadId: 29155,
    squadName: "1.FC Kaiserslautern",
    shortSquadName: "1.FC K'lautern",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1286_1.FC_Kaiserslautern.png"
  },
  {
    squadId: 29285,
    squadName: "BK Hacken",
    shortSquadName: "Hacken",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/6736_BK_Häcken.png"
  },
  {
    squadId: 29306,
    squadName: "Mineros de Zacatecas",
    shortSquadName: "Min. Zacatecas",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/30286_Mineros_de_Zacatecas.png"
  },
  {
    squadId: 29469,
    squadName: "GNK Dinamo Zagreb",
    shortSquadName: "Dinamo Zagreb",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2163_GNK_Dinamo_Zagreb.png"
  },
  {
    squadId: 29529,
    squadName: "Cheltenham Town",
    shortSquadName: "Cheltenham",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1197_Cheltenham_Town.png"
  },
  {
    squadId: 29548,
    squadName: "Ae Kifisias",
    shortSquadName: "Ae Kifisias",
    squadLogo: null
  },
  {
    squadId: 29580,
    squadName: "NK Bravo",
    shortSquadName: "NK Bravo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/35651_AŠK_Bravo_Publikum.png"
  },
  {
    squadId: 29631,
    squadName: "Landskrona BoIS",
    shortSquadName: "Landskrona",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2472_Landskrona_BoIS.png"
  },
  {
    squadId: 29646,
    squadName: "NK Domzale",
    shortSquadName: "NK Domzale",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2463_NK_Domzale.png"
  },
  {
    squadId: 29655,
    squadName: "Blackburn Rovers",
    shortSquadName: "Blackburn",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/16388_Blackburn_Rovers.svg"
  },
  {
    squadId: 29757,
    squadName: "Bradford City",
    shortSquadName: "Bradford",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1653_Bradford_City.png"
  },
  {
    squadId: 29766,
    squadName: "Gornik Zabrze",
    shortSquadName: "Gornik Zabrze",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1609_Gornik_Zabrze.png"
  },
  {
    squadId: 29777,
    squadName: "LR Vicenza",
    shortSquadName: "LR Vicenza",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3186_LR_Vicenza_Virtus.png"
  },
  {
    squadId: 29796,
    squadName: "Alebrijes de Oaxaca",
    shortSquadName: "Oaxaca",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/25865_Alebrijes_de_Oaxaca.png"
  },
  {
    squadId: 29989,
    squadName: "Middlesbrough FC",
    shortSquadName: "Middlesbrough",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Middlesbrough_FC.png"
  },
  {
    squadId: 30012,
    squadName: "Crewe Alexandra",
    shortSquadName: "Crewe Alexandra",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1202_Crewe_Alexandra.png"
  },
  {
    squadId: 30372,
    squadName: "Envigado FC",
    shortSquadName: "Envigado",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12405_Envigado_FC.png"
  },
  {
    squadId: 30475,
    squadName: "Malmo FF",
    shortSquadName: "Malmo FF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1432_Malmo_FF.png"
  },
  {
    squadId: 30586,
    squadName: "AC Sparta Prague",
    shortSquadName: "Sparta Prague",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/11241_AC_Sparta_Prague.png"
  },
  {
    squadId: 30734,
    squadName: "Altinordu FK",
    shortSquadName: "Altinordu",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2830_Altinordu.png"
  },
  {
    squadId: 30997,
    squadName: "Red Bull Salzburg",
    shortSquadName: "RB Salzburg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1942_Red_Bull_Salzburg.png"
  },
  {
    squadId: 31138,
    squadName: "Club Leon FC",
    shortSquadName: "Leon",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15424_Club_León_FC.png"
  },
  {
    squadId: 31205,
    squadName: "SG Dynamo Dresden",
    shortSquadName: "Dynamo Dresden",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1281_SG_Dynamo_Dresden.png"
  },
  {
    squadId: 31328,
    squadName: "Sabah FK",
    shortSquadName: "Sabah FK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/61081_Sabah_FK.png"
  },
  {
    squadId: 31367,
    squadName: "Sheffield United",
    shortSquadName: "Sheff Utd",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1159_Sheffield_United.png"
  },
  {
    squadId: 31396,
    squadName: "FC Blau-Weiss Linz",
    shortSquadName: "Blau Weiss Linz",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1957_FC_Blau_Weiss_Linz.png"
  },
  {
    squadId: 31588,
    squadName: "Rangers FC",
    shortSquadName: "Rangers",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2188_Rangers_FC.png"
  },
  {
    squadId: 32070,
    squadName: "Wisla Pulawy",
    shortSquadName: "Wisla Pulawy",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/13769_Wisla_Pulawy.png"
  },
  {
    squadId: 32380,
    squadName: "VfB Oldenburg",
    shortSquadName: "VfB Oldenburg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1337_VfB_Oldenburg.png"
  },
  {
    squadId: 32704,
    squadName: "UNAM Pumas",
    shortSquadName: "UNAM Pumas",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15420_UNAM_Pumas.png"
  },
  {
    squadId: 32757,
    squadName: "Fram Reykjavik",
    shortSquadName: "Fram Reykjavik",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7728_Fram_Reykjavík.png"
  },
  {
    squadId: 32865,
    squadName: "Portland Timbers",
    shortSquadName: "Portland",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/8113_Portland_Timbers.png"
  },
  {
    squadId: 33026,
    squadName: "FC Rapid 1923",
    shortSquadName: "FC Rapid",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/11566_FC_Rapid_1923.png"
  },
  {
    squadId: 33183,
    squadName: "CD Mafra",
    shortSquadName: "Mafra",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_3016_CD_Mafra.png"
  },
  {
    squadId: 33217,
    squadName: "Real CD Espana",
    shortSquadName: "Real Espana",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/17385_Real_CD_España.png"
  },
  {
    squadId: 33438,
    squadName: "FC Thun",
    shortSquadName: "FC Thun",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1941_FC_Thun.png"
  },
  {
    squadId: 33471,
    squadName: "Morecambe FC",
    shortSquadName: "Morecambe FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1200_Morecambe_FC.png"
  },
  {
    squadId: 33648,
    squadName: "Atletico Nacional",
    shortSquadName: "Atl. Nacional",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12391_Atlético_Nacional.png"
  },
  {
    squadId: 33781,
    squadName: "Wurzburger Kickers",
    shortSquadName: "Wurzb. Kickers",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1295_Wurzburger_Kickers.png"
  },
  {
    squadId: 33794,
    squadName: "Sport Club Internacional",
    shortSquadName: "Internacional",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2611_Sport_Club_Internacional.png"
  },
  {
    squadId: 33797,
    squadName: "Spezia Calcio",
    shortSquadName: "Spezia Calcio",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1860_Spezia_Calcio.png"
  },
  {
    squadId: 33813,
    squadName: "Karlsruher SC",
    shortSquadName: "Karlsruher SC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Karlsruher_SC.png"
  },
  {
    squadId: 33893,
    squadName: "FC Emmen",
    shortSquadName: "FC Emmen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Emmen.png"
  },
  {
    squadId: 33983,
    squadName: "VVV-Venlo",
    shortSquadName: "VVV-Venlo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1549_VVV-Venlo.png"
  },
  {
    squadId: 34152,
    squadName: "Tranmere Rovers",
    shortSquadName: "Tranmere Rovers",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1216_Tranmere_Rovers.png"
  },
  {
    squadId: 34776,
    squadName: "Goztepe",
    shortSquadName: "Goztepe",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2012_Goztepe.png"
  },
  {
    squadId: 34923,
    squadName: "Harrogate Town",
    shortSquadName: "Harrogate Town",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2318_Harrogate_Town.png"
  },
  {
    squadId: 35045,
    squadName: "Vitesse Arnhem",
    shortSquadName: "Vitesse",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/24_Vitesse_Arnhem.png"
  },
  {
    squadId: 35103,
    squadName: "Standard Liege",
    shortSquadName: "Standard Liege",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1574_Standard_Liege.png"
  },
  {
    squadId: 35187,
    squadName: "Virtus Entella",
    shortSquadName: "Virtus Entella",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3404_Virtus_Entella.png"
  },
  {
    squadId: 35255,
    squadName: "CA Sarmiento (Junin)",
    shortSquadName: "Sarmiento Junin",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12095_CA_Sarmiento_(Junin).png"
  },
  {
    squadId: 35338,
    squadName: "Skovde AIK",
    shortSquadName: "Skovde AIK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/6792_Skövde_AIK.png"
  },
  {
    squadId: 36226,
    squadName: "Go Ahead Eagles",
    shortSquadName: "Go Ahead Eagles",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1553_Go_Ahead_Eagles_Deventer.png"
  },
  {
    squadId: 36491,
    squadName: "FK Vozdovac",
    shortSquadName: "Vozdovac",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1662_FK_Vozdovac.png"
  },
  {
    squadId: 36568,
    squadName: "Kolos Kovalivka",
    shortSquadName: "Kolos Kovalivka",
    squadLogo: null
  },
  {
    squadId: 36678,
    squadName: "SC Fortuna Koln",
    shortSquadName: "Fortuna Koln",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1308_SC_Fortuna_Koln.png"
  },
  {
    squadId: 37066,
    squadName: "SSV Ulm 1846",
    shortSquadName: "SSV Ulm 1846",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2508_SSV_Ulm_1846.png"
  },
  {
    squadId: 37217,
    squadName: "Deportes Tolima",
    shortSquadName: "Deportes Tolima",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12396_Deportes_Tolima.png"
  },
  {
    squadId: 37475,
    squadName: "Mamelodi Sundowns FC",
    shortSquadName: "Sundowns",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/16344_Mamelodi_Sundowns_FC.png"
  },
  {
    squadId: 37659,
    squadName: "Kongsvinger IL",
    shortSquadName: "Kongsvinger",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Kongsvinger_IL.png"
  },
  {
    squadId: 38328,
    squadName: "D.C. United",
    shortSquadName: "D.C. United",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2118_D.C._United.png"
  },
  {
    squadId: 38553,
    squadName: "Aston Villa",
    shortSquadName: "Aston Villa",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1138_Aston_Villa.png"
  },
  {
    squadId: 38623,
    squadName: "Pogon Szczecin",
    shortSquadName: "Pogon Szczecin",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1599_Pogon_Szczecin.png"
  },
  {
    squadId: 38864,
    squadName: "Slask Wroclaw",
    shortSquadName: "Slask Wroclaw",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_WKS_Slask_Wroclaw.png"
  },
  {
    squadId: 39042,
    squadName: "1.FC Lokomotive Leipzig",
    shortSquadName: "Lok Leipzig",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2861_1.FC_Lokomotive_Leipzig.png"
  },
  {
    squadId: 39085,
    squadName: "FC Dornbirn",
    shortSquadName: "FC Dornbirn",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/19889_FC_Dornbirn.png"
  },
  {
    squadId: 39176,
    squadName: "Leeds United",
    shortSquadName: "Leeds",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1150_Leeds_United.png"
  },
  {
    squadId: 39191,
    squadName: "LKS Lodz",
    shortSquadName: "LKS Lodz",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/13528_Łódzki_KS.png"
  },
  {
    squadId: 39337,
    squadName: "SK Dnipro-1",
    shortSquadName: "SK Dnipro-1",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/59603_SK_Dnipro-1.png"
  },
  {
    squadId: 39599,
    squadName: "FC Midtjylland",
    shortSquadName: "FC Midtjylland",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Midtjylland.png"
  },
  {
    squadId: 39648,
    squadName: "New England Revolution",
    shortSquadName: "New England",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2111_New_England_Revolution.png"
  },
  {
    squadId: 39668,
    squadName: "SKN St. Polten",
    shortSquadName: "SKN St. Polten",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/8756_SKN_St._Pölten.png"
  },
  {
    squadId: 39981,
    squadName: "Clube Atletico Mineiro",
    shortSquadName: "Atletico-MG",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5653_Atlético-MG.png"
  },
  {
    squadId: 40170,
    squadName: "MSV Duisburg",
    shortSquadName: "MSV Duisburg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1292_MSV_Duisburg.png"
  },
  {
    squadId: 40208,
    squadName: "Tlaxcala FC",
    shortSquadName: "Tlaxcala",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/31554_Tlaxcala_FC.png"
  },
  {
    squadId: 40356,
    squadName: "SV Werder Bremen",
    shortSquadName: "Werder Bremen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1269_SV_Werder_Bremen.png"
  },
  {
    squadId: 40409,
    squadName: "Oxford United",
    shortSquadName: "Oxford United",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1683_Oxford_United.png"
  },
  {
    squadId: 40498,
    squadName: "Swansea City",
    shortSquadName: "Swansea",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1131_Swansea_City.png"
  },
  {
    squadId: 40508,
    squadName: "LASK",
    shortSquadName: "LASK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/8754_LASK.png"
  },
  {
    squadId: 40550,
    squadName: "Rot-Weiss Essen",
    shortSquadName: "RW Essen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1391_Rot-Weiss_Essen.png"
  },
  {
    squadId: 40635,
    squadName: "Ross County FC",
    shortSquadName: "Ross County",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2197_Ross_County_FC.png"
  },
  {
    squadId: 40692,
    squadName: "Columbus Crew",
    shortSquadName: "Columbus",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2116_Columbus_Crew_SC.png"
  },
  {
    squadId: 40969,
    squadName: "Associacao Atletica Ponte Preta",
    shortSquadName: "Ponte Preta",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2627_Associacao_Atletica_Ponte_Preta.png"
  },
  {
    squadId: 41155,
    squadName: "Raith Rovers FC",
    shortSquadName: "Raith Rovers",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2285_Raith_Rovers_FC.png"
  },
  {
    squadId: 41646,
    squadName: "FC Dallas",
    shortSquadName: "Dallas",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2113_FC_Dallas.png"
  },
  {
    squadId: 41684,
    squadName: "Twente Enschede FC",
    shortSquadName: "Twente FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1540_FC_Twente_Enschede.png"
  },
  {
    squadId: 41761,
    squadName: "Leyton Orient",
    shortSquadName: "Leyton Orient",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Leyton_Orient.png"
  },
  {
    squadId: 41941,
    squadName: "King's Lynn Town",
    shortSquadName: "King's Lynn",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2520_King_s_Lynn_Town.png"
  },
  {
    squadId: 41956,
    squadName: "Deportivo Cali",
    shortSquadName: "Deportivo Cali",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2667_Deportivo_Cali.png"
  },
  {
    squadId: 42063,
    squadName: "Ujpest FC",
    shortSquadName: "Ujpest FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1633_Ujpest_FC.png"
  },
  {
    squadId: 42505,
    squadName: "FC Ballkani",
    shortSquadName: "FC Ballkani",
    squadLogo: null
  },
  {
    squadId: 42798,
    squadName: "Cavalry FC",
    shortSquadName: "Cavalry FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/64336_Cavalry_FC.png"
  },
  {
    squadId: 50113,
    squadName: "Inter Miami CF",
    shortSquadName: "Miami",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-69261_Inter_Miami_CF.png"
  },
  {
    squadId: 60008,
    squadName: "Union Omaha",
    shortSquadName: "Union Omaha",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-77059_Union_Omaha.png"
  },
  {
    squadId: 200003,
    squadName: "Oakland Roots SC",
    shortSquadName: "Oakland Roots",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-69044_Oakland_Roots_SC.png"
  },
  {
    squadId: 200106,
    squadName: "Club Deportivo Maipu",
    shortSquadName: "CD Maipu",
    squadLogo: null
  },
  {
    squadId: 200108,
    squadName: "Tepatitlan FC",
    shortSquadName: "Tepatitlan",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/64345_Tepatitlán_FC.png"
  },
  {
    squadId: 200109,
    squadName: "Cancun FC",
    shortSquadName: "Cancun FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/83082_Cancún_FC.png"
  },
  {
    squadId: 200193,
    squadName: "Manisa FK",
    shortSquadName: "Manisa FK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/48913_Manisa_FK.png"
  },
  {
    squadId: 200414,
    squadName: "FCR Valledupar",
    shortSquadName: "Valledupar",
    squadLogo: null
  },
  {
    squadId: 221000,
    squadName: "Canadian Premier League",
    shortSquadName: "Canadian Premier League",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/Canadian_Premier_League.png"
  },
  {
    squadId: 222120,
    squadName: "Maringa Futebol Clube (PR)",
    shortSquadName: "Maringa Futebol Clube (PR)",
    squadLogo: null
  },
  {
    squadId: 225010,
    squadName: "CD Real Cartagena",
    shortSquadName: "Real Cartagena",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-13046_CD_Real_Cartagena.png"
  },
  {
    squadId: 225037,
    squadName: "Atletico La Paz",
    shortSquadName: "Atletico La Paz",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/100124_Atlético_La_Paz.png"
  }
];