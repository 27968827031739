import { AppState } from 'root.reducer';
import { StateController } from 'utils/action-declaration';
import { AgencyPlayerOnboardingService } from 'api/agency/player/onboarding-v2/onboarding-v2.service';
import { OnboardingSource } from 'api/agency/player/onboarding-v2/models';
import PasswordValidation from "utils/validate-password";
import validator from "services/validator";
import { modalClose, modalOpen } from "store/modals/modalsActions";
import { PLAYER_TERMS_OF_SERVICE, PLAYER_HEALTH_CONSENT } from "constants/modals";
import { AUTH_LOGIN } from "store/actionTypes";
import historyAccessor from "history-accessor";
import { playerPathsV2 } from "routes/paths";
import { EmailConfirmationService } from "api/player-side/email-confirmation.service";
import { notificationCreate } from 'app/notifications/notifications.actions';
import { AgentPlayerInvitationStatusEnum } from "api/agency/player/shared/shared-models";
import userActivityInsert, { eventHubInsert, insertAnonymousActivity } from 'app/user-activity/actions/user-activity.actions';
import { getAuth } from 'store/auth/authReducer';
import { PageType } from 'constants/enums';

export enum PlayerOnboardingStep {
    Registration = 1,
    ConfirmAgency = 2,
    InvalidEmail = 3,
    ThankYouMessage = 4,
    LinkExpired = 5,
}
export enum EmailOnboardingStep {
    EmailVerification = 0,
    ThankYouMessage,
    RepresentationScreen
}

class State {
    currentStep: PlayerOnboardingStep;
    token: string;
    isInitializing: boolean;
    playerId: number;
    source: OnboardingSource;
    invitationUid: string;
    userId: number;
    registrationStep: {
        phoneCode: string;
        phoneCodeAreaId?: number;
        playerPhone: string;
        email: string;
        phoneCodeAreaIdPredefined?: number;
        playerPhonePredefined: string;
        emailPredefined: string;
        password: string;
        retypePassword: string;
        isAgreedTermsOfService: boolean;
        isAgreedHealthConsent: boolean;
        isRegistrationProcessing: boolean;
        emailError: boolean;
        emailErrorText: string;
        passwordRules: Array<{ ruleCode: string, passed: boolean }>;
        passwordError: boolean;
        retypePasswordError: any;
    };
    agencyRelationsStep: {
        id: number,
        name: string;
        isRelationConfirmed: boolean;
        shouldAgentBeEndorced: boolean;
        isSubmitProcessing: boolean;
        isTrusted: boolean,
        isProcessing: boolean,
        isTransparent: boolean,
        playersCount: number,
        coachesCount: number,
        primaryMarket: {
            id: number,
            name: string,
            flagPath: string,
        },
        secondaryMarket: {
            id: number,
            name: string,
            flagPath: string,
        },
    }
    emailVerificationStep: {
        step: EmailOnboardingStep,
        playerInvitationStatus: null | AgentPlayerInvitationStatusEnum,
    }
}

const defaultState: State = {
    currentStep: PlayerOnboardingStep.Registration,
    token: null,
    isInitializing: false,
    playerId: null,
    source: null,
    invitationUid: '',
    userId: 0,
    registrationStep: {
        phoneCode: null,
        phoneCodeAreaId: null,
        playerPhone: '',
        email: '',
        phoneCodeAreaIdPredefined: null,
        playerPhonePredefined: '',
        emailPredefined: '',
        password: '',
        retypePassword: '',
        isAgreedTermsOfService: false,
        isAgreedHealthConsent: false,
        isRegistrationProcessing: false,
        emailError: false,
        emailErrorText: '',
        passwordRules: [],
        passwordError: false,
        retypePasswordError: false
    },
    agencyRelationsStep: {
        id: null,
        name: '',
        isRelationConfirmed: true,
        shouldAgentBeEndorced: false,
        isProcessing: false,
        isSubmitProcessing: false,
        isTrusted: false,
        isTransparent: false,
        playersCount: 0,
        coachesCount: 0,
        primaryMarket: {
            id: 0,
            name: '',
            flagPath: '',
        },
        secondaryMarket: {
            id: 0,
            name: '',
            flagPath: '',
        },
    },
    emailVerificationStep: {
        step: EmailOnboardingStep.ThankYouMessage,
        playerInvitationStatus: null,
    },
}

const stateController = new StateController<State>(
    'PLAYER_TO_AGENCY_ONBOARDING',
    defaultState
)

class Actions {
    public static init(token: string, queryString?: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isInitializing: true, token: token }))
                const result = await AgencyPlayerOnboardingService.getAccessForSignUpV2(token);

                if (result.isValid) {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        playerId: result.player.id,
                        invitationUid: token,
                        registrationStep: {
                            ...prevState.registrationStep,
                            phoneCode: result.player.phoneCode,
                            playerPhone: result.player.phone,
                            phoneCodeAreaId: result.player.phoneCodeAreaId,
                            email: result.player.email,
                            playerPhonePredefined: result.player.phone,
                            phoneCodeAreaIdPredefined: result.player.phoneCodeAreaId,
                            emailPredefined: result.player.email
                        },
                        agencyRelationsStep: {
                            ...prevState.agencyRelationsStep,
                            id: result.agency.id,
                            name: result.agency.name,
                            isTransparent: result.agency.isTransparent,
                            isTrusted: result.agency.isTrusted,
                            playersCount: result.agency.playersCount,
                            coachesCount: result.agency.coachesCount,
                            primaryMarket: result.agency.primaryMarket,
                            secondaryMarket: result.agency.secondaryMarket,
                        }
                    })))
                } else {
                    const res = await AgencyPlayerOnboardingService.getAccessForReconfirmV2(token)

                    if (res.isValid) {
                        dispatch(stateController.setState(prevState => ({ ...prevState, currentStep: PlayerOnboardingStep.ConfirmAgency })))
                        dispatch(stateController.setState(prevState => ({
                            ...prevState,
                            playerId: res.player.id,
                            invitationUid: token,
                            registrationStep: {
                                ...prevState.registrationStep,
                                phoneCode: res.player.phoneCode,
                                playerPhone: res.player.phone,
                                phoneCodeAreaId: res.player.phoneCodeAreaId,
                                email: res.player.email,
                                playerPhonePredefined: res.player.phone,
                                phoneCodeAreaIdPredefined: res.player.phoneCodeAreaId,
                                emailPredefined: res.player.email
                            },
                            agencyRelationsStep: {
                                ...prevState.agencyRelationsStep,
                                id: res.agency.id,
                                name: res.agency.name,
                                isTransparent: res.agency.isTransparent,
                                isTrusted: res.agency.isTrusted,
                                playersCount: res.agency.playersCount,
                                coachesCount: res.agency.coachesCount,
                                primaryMarket: res.agency.primaryMarket,
                                secondaryMarket: res.agency.secondaryMarket,

                            }
                        })));
                    } else {
                        if (res.isExpired) {
                            dispatch(stateController.setState({ currentStep: PlayerOnboardingStep.LinkExpired }))
                        } else {
                            historyAccessor.push(playerPathsV2.homePage);
                        }
                    }
                }
            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState({ isInitializing: false }))
            }
        }
    }

    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState({ ...defaultState }));
        }
    }
    public static setStep(step: PlayerOnboardingStep) {
        return async (dispatch) => {
            dispatch(stateController.setState({ currentStep: step }));
        }
    }
    public static initVerifyEmail() {
        return async (dispatch) => {
            const { invitationStatus } = await AgencyPlayerOnboardingService.GetPlayerInvitationStatus();
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                emailVerificationStep: {
                    ...prevState.emailVerificationStep,
                    playerInvitationStatus: invitationStatus,
                }
            })));
        }
    }
    public static onEmailChange(value: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                registrationStep: {
                    ...prevState.registrationStep,
                    email: value,
                    emailError: false
                }
            })))
        }
    }

    public static onPhoneCodeChange(value: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                registrationStep: {
                    ...prevState.registrationStep,
                    phoneCodeAreaId: value
                }
            })))
        }
    }

    public static onPhoneChange(value: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                registrationStep: {
                    ...prevState.registrationStep,
                    playerPhone: value
                }
            })))
        }
    }

    public static onPasswordChange(value: string) {
        return (dispatch, getState: () => AppState) => {
            const subState = getState().player.onboarding;
            const rePassword = subState.registrationStep.retypePassword;

            const validation = PasswordValidation.validate(value);
            const passwordError = !validation.isSuccess;
            const retypePasswordError = PasswordValidation.validateConfirmPassword(value, rePassword);

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                registrationStep: {
                    ...prevState.registrationStep,
                    password: value,
                    passwordError,
                    retypePasswordError,
                    passwordRules: validation.rules,
                }
            })));
        }
    }
    public static onRetypePasswordChange(value: string) {
        return (dispatch, getState: () => AppState) => {
            const password = getState().player.onboarding.registrationStep.password;
            const retypePasswordError = PasswordValidation.validateConfirmPassword(password, value);

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                registrationStep: {
                    ...prevState.registrationStep,
                    retypePassword: value,
                    retypePasswordError: retypePasswordError
                }
            })))
        }
    }

    public static onTermsOfServiceCheckboxChange(isChecked: boolean) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                registrationStep: {
                    ...prevState.registrationStep,
                    isAgreedTermsOfService: isChecked
                }
            })))
        }
    }

    public static onHealthConsentCheckboxChange(isChecked: boolean) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                registrationStep: {
                    ...prevState.registrationStep,
                    isAgreedHealthConsent: isChecked
                }
            })))
        }
    }

    public static confirmAgency(confirmStatus: boolean, agencyId: number) {
        return async (dispatch, getState: () => AppState) => {
            const state = getState();
            const uAMessage = confirmStatus ? 'Confirmed Request' : 'Rejected Request';
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                agencyRelationsStep: {
                    ...prevState.agencyRelationsStep,
                    isProcessing: true,
                }
            })))

            const confirmRequest = {
                invitationUid: state.playerV2.onboarding.invitationUid,
                isAgentConfirmed: confirmStatus,
                endorseAgent: null,
            }
            await dispatch(Actions.trackAgentConfirmationUserActivity(uAMessage, agencyId))
            const authData = await AgencyPlayerOnboardingService.submitAgent(confirmRequest);

            dispatch({ type: AUTH_LOGIN.SUCCESS, payload: { data: authData } });
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                agencyRelationsStep: {
                    ...prevState.agencyRelationsStep,
                    isProcessing: false,
                }
            })))
            historyAccessor.push(playerPathsV2.emailVerification);
        }
    }

    public static openEmailVerificationPage() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                emailVerificationStep: {
                    ...prevState.agencyRelationsStep,
                    step: EmailOnboardingStep.EmailVerification
                }
            })));
            historyAccessor.push(playerPathsV2.emailVerification);
        }
    }

    public static register() {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().playerV2.onboarding;
            const { invitationUid, playerId } = subState;
            const isEmailValid = validator.isValidEmail(subState.registrationStep.email);

            if (!isEmailValid) {
                dispatch(insertAnonymousActivity({
                    PageName: 'Onboarding [Player Details]',
                    Message: `Error Displayed:  Incorrect email`,
                    PlayerId: playerId,
                    PageType: PageType.PlayerOnboarding,
                }))
                return dispatch(stateController.setState(prevState => ({
                    ...prevState, registrationStep: { ...prevState.registrationStep, emailError: !isEmailValid, emailErrorText: 'Incorrect email' }
                })))
            }

            const {
                email, password, emailError, isAgreedTermsOfService, isAgreedHealthConsent, passwordError, retypePasswordError, phoneCodeAreaId, playerPhone
            } = subState.registrationStep

            if (!emailError && !passwordError && !retypePasswordError && isAgreedTermsOfService && isAgreedHealthConsent) {
                try {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        registrationStep: {
                            ...prevState.registrationStep,
                            isRegistrationProcessing: true
                        }
                    })))

                    const data = await AgencyPlayerOnboardingService.signup({
                        email, password, invitationUid, phone: playerPhone, phoneCodeAreaId: phoneCodeAreaId
                    });

                    if (data.isSuccess) {
                        dispatch(insertAnonymousActivity({
                            PageName: 'Onboarding',
                            Message: 'Created Account',
                            PlayerId: playerId,
                            PageType: PageType.PlayerOnboarding,
                        }))

                        dispatch(insertAnonymousActivity({
                            PageName: 'Onboarding [Player Details]',
                            Message: 'Clicked Continue',
                            PlayerId: playerId,
                            PageType: PageType.PlayerOnboarding,
                        }))

                        dispatch(Actions.setStep(PlayerOnboardingStep.ConfirmAgency));
                        dispatch(stateController.setState(prevState => ({
                            ...prevState,
                            userId: data.userId,
                        })));
                    } else {
                        dispatch(stateController.setState(prevState => ({
                            ...prevState,
                            registrationStep: { ...prevState.registrationStep, emailError: true, emailErrorText: data.message }
                        })))
                        dispatch(insertAnonymousActivity({
                            PageName: 'Onboarding [Player Details]',
                            Message: `Error Displayed:  ${data.message}`,
                            PlayerId: playerId,
                            PageType: PageType.PlayerOnboarding,
                        }))
                    }
                } catch (error) {
                    console.error(error)
                } finally {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        registrationStep: {
                            ...prevState.registrationStep,
                            isRegistrationProcessing: false
                        }
                    })))
                }

            } else {
                return
            }
        }
    }

    public static openTermsOfServiceModal = () => (dispatch, getState: () => AppState) => {
        dispatch(modalOpen({
            id: PLAYER_TERMS_OF_SERVICE,
            content: {
                closeModal: () => dispatch(Actions.closeTermsOfServiceModal)
            }
        }))
    }

    public static closeTermsOfServiceModal = () => (dispatch) => {
        dispatch(modalClose(PLAYER_TERMS_OF_SERVICE))
    }

    public static openHealthConsentModal = () => (dispatch, getState: () => AppState) => {
        dispatch(modalOpen({
            id: PLAYER_HEALTH_CONSENT,
            content: {
                closeModal: () => dispatch(Actions.closeHealthConsentModal)
            }
        }))
    }

    public static closeHealthConsentModal = () => (dispatch) => {
        dispatch(modalClose(PLAYER_HEALTH_CONSENT))
    }

    /**-------Email Verification--------------------*/
    public static setEmailVerificationStep(step: EmailOnboardingStep) {
        return async (dispatch) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                emailVerificationStep: {
                    ...prevState.emailVerificationStep,
                    step,
                }
            })));
        }
    }

    public static initVerificationScreen() {
        return async (dispatch, getState: () => AppState) => {
            const { playerId } = getAuth(getState());
            dispatch(userActivityInsert({
                PageName: 'Onboarding [Verify Email]',
                Message: 'Viewed Verify Email Message',
                PlayerId: playerId,
                PageType: PageType.PlayerOnboarding,
            }))
        }
    }

    public static resendVerificationEmail() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(Actions.sendEmailConfirmation())
            const { playerId } = getAuth(getState());
            dispatch(userActivityInsert({
                PageName: 'Onboarding [Verify Email]',
                Message: 'Clicked Resend Verification Email',
                PlayerId: playerId,
                PageType: PageType.PlayerOnboarding,
            }))
        }
    }

    public static sendEmailConfirmation() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const playerEmail = getState().auth.userEmail;
                await EmailConfirmationService.sendConfirmEmail({ newEmail: playerEmail, oldEmail: playerEmail });
                dispatch(notificationCreate({ message: 'Verification email sent!', level: 'success' }));
            } catch (err) {
                console.log(err)
            }
        }
    }

    public static close() {
        return (dispatch, getState: () => AppState) => {
            const { playerId } = getAuth(getState());
            dispatch(userActivityInsert({
                PageName: 'Onboarding [Verify Email]',
                Message: 'Clicked Close',
                PlayerId: playerId,
                PageType: PageType.PlayerOnboarding,
            }))

            historyAccessor.push(playerPathsV2.homePage);
        }
    }

    public static trackAgentConfirmationUserActivity(message: string, agencyId: number) {
        return async (dispatch, getState: () => AppState) => {
            const { playerId, userId } = getState().playerV2.onboarding;
            const userActivity = {
                PageName: 'Agent Confirmation',
                Message: message,
                PlayerId: playerId,
                AgencyId: agencyId,
            }
            const { metaInfo } = getAuth(getState());
            await eventHubInsert(userActivity, userId, null, null, metaInfo);
        }
    }

    public static trackInitOnboardingRegistration() {
        return (dispatch, getState: () => AppState) => {
            const { playerId } = getState().playerV2.onboarding;

            dispatch(insertAnonymousActivity({
                PageName: 'Onboarding',
                Message: 'Opened Onboarding',
                PlayerId: playerId,
                PageType: PageType.PlayerOnboarding,
            }))
        }
    }

    public static trackEnteredEmailUserActivity() {
        return async (dispatch, getState: () => AppState) => {
            const { playerId } = getState().playerV2.onboarding;

            dispatch(insertAnonymousActivity({
                PageName: 'Onboarding',
                Message: 'Entered Email',
                PlayerId: playerId,
                PageType: PageType.PlayerOnboarding,
            }))
        }
    }

    public static trackEnteredPasswordUserActivity() {
        return (dispatch, getState: () => AppState) => {
            const { playerId, registrationStep } = getState().playerV2.onboarding;
            const { passwordError, passwordRules } = registrationStep;
            const errors = passwordRules.filter(rule => !rule.passed).map(rule => rule.ruleCode).join(', ');

            if (passwordError) {
                dispatch(insertAnonymousActivity({
                    PageName: 'Onboarding [Player Details]',
                    Message: `Error Displayed:  ${errors}`,
                    PlayerId: playerId,
                    PageType: PageType.PlayerOnboarding,
                }))
            } else {
                dispatch(insertAnonymousActivity({
                    PageName: 'Onboarding [Player Details]',
                    Message: 'Entered New Password',
                    PlayerId: playerId,
                    PageType: PageType.PlayerOnboarding,
                }))
            }
        }
    }

    public static trackEnteredPasswordConfirmationUserActivity() {
        return (dispatch, getState: () => AppState) => {
            const { playerId, } = getState().playerV2.onboarding;
            const confirmedPasswordError = Selectors.getConfirmedPasswordError(getState());

            if (confirmedPasswordError) {
                dispatch(insertAnonymousActivity({
                    PageName: 'Onboarding [Player Details]',
                    Message: `Error Displayed:  ${confirmedPasswordError}`,
                    PlayerId: playerId,
                    PageType: PageType.PlayerOnboarding,
                }))
            } else {
                dispatch(insertAnonymousActivity({
                    PageName: 'Onboarding [Player Details]',
                    Message: 'Entered Password Confirmation',
                    PlayerId: playerId,
                    PageType: PageType.PlayerOnboarding,
                }))
            }
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState): State => state.playerV2.onboarding;
    public static getCurrentStep = (state: AppState) => Selectors.getRoot(state).currentStep;
    public static getPassword = (state: AppState) => Selectors.getRoot(state).registrationStep.password;
    public static getConfirmedPassword = (state: AppState) => Selectors.getRoot(state).registrationStep.retypePassword;
    public static getConfirmedPasswordError = (state: AppState) => Selectors.getRoot(state).registrationStep.retypePasswordError;
    public static getPasswordRules = (state: AppState) => Selectors.getRoot(state).registrationStep.passwordRules;
    public static getEmail = (state: AppState) => Selectors.getRoot(state).registrationStep.email;
    public static getPhoneCode = (state: AppState) => Selectors.getRoot(state).registrationStep.phoneCode;
    public static getPlayerPhone = (state: AppState) => Selectors.getRoot(state).registrationStep.playerPhone;
    public static getEmailError = (state: AppState) => Selectors.getRoot(state).registrationStep.emailError;
    public static getPasswordError = (state: AppState) => Selectors.getRoot(state).registrationStep.passwordError;
    public static isAgreedTermsOfService = (state: AppState) => Selectors.getRoot(state).registrationStep.isAgreedTermsOfService;
    public static isAgreedHealthConsent = (state: AppState) => Selectors.getRoot(state).registrationStep.isAgreedHealthConsent;
    public static isRegistrationProcessing = (state: AppState) => Selectors.getRoot(state).registrationStep.isRegistrationProcessing;
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};