import * as redux from 'redux';
import restrictionsReducer, { RestrictionsState } from './restrictions.reducer'
import CommonReducer, { CommonReducerTypes } from './common.reducer';
import * as outdatedAdsController from './outdated-ads.controller'
import * as performanceComtroller from './performance-data.controller'
import * as playerStatisticController from './player-statistic.controller'
import * as makePlayersAvailableController from './make-players-available.contorller';
import * as pitchPlayersController from './pitch-players.controller';
import * as createPlayerAdController from './create-player-ad.controller'
import * as addUsersController from './add-users.controller'
import * as messageSend from './message.send.controller'
import * as superPitches from './super-pitches-all.controller'
import * as friendlyAdModalController from './friendly-ads-modal.controller'
import * as friendlyInvitationsController from './friendly-invitations.controller'
import * as notificationController from './notification.controller'
import * as bookSessionController from './book-session.controller'
import * as playerTrendingController from './player-trending.controller';
import * as speakerCardController from './speaker-card.controller';

import * as PlusPitchV2Controller from './plus-pitch.v2.controller'
import * as PitchV2Controller from './pitch.v2.controller'
import * as AgencyCardAlertController from './agency-card-alert.controller'
import * as ManageSharingModalController from './manage-sharing-modal.controller'
import * as HeadCoachController from './find-coach-banner.controller'
import * as FindHeadCoachPopupController from './find-coach-popup.controller'


export class LandingPageState {
    common: CommonReducerTypes;
    performance: performanceComtroller.State;
    restrictions: RestrictionsState;
    outdatedAds: outdatedAdsController.State;
    playerStatistic: playerStatisticController.State;
    makePlayersAvailable: makePlayersAvailableController.State;
    pitchPlayers: pitchPlayersController.State;
    createPlayerAd: createPlayerAdController.State;
    addUsersFlow: addUsersController.State;
    messageSend: messageSend.State;
    superPitches: superPitches.State;
    friendlyAdModal: friendlyAdModalController.State;
    friendlyInvitations: friendlyInvitationsController.State;
    notification: notificationController.State;
    bookSession: bookSessionController.State;
    playerTrending: playerTrendingController.State;
    speakerCard: speakerCardController.State;
    plusPitchV2: PlusPitchV2Controller.State;
    pitchV2: PitchV2Controller.State;
    agencyCardAlert: AgencyCardAlertController.State;
    manageSharingModal: ManageSharingModalController.State;
    headCoachBanner: HeadCoachController.State;
    headCoachPopup: FindHeadCoachPopupController.State;
}

export function combineReducers() {

    let landingPageReducers = {
        common: CommonReducer,
        performance: performanceComtroller.Reducer,
        restrictions: restrictionsReducer,
        outdatedAds: outdatedAdsController.Reducer,
        playerStatistic: playerStatisticController.Reducer,
        makePlayersAvailable: makePlayersAvailableController.Reducer,
        pitchPlayers: pitchPlayersController.Reducer,
        createPlayerAd: createPlayerAdController.Reducer,
        addUsersFlow: addUsersController.Reducer,
        messageSend: messageSend.Reducer,
        superPitches: superPitches.Reducer,
        friendlyAdModal: friendlyAdModalController.Reducer,
        friendlyInvitations: friendlyInvitationsController.Reducer,
        notification: notificationController.Reducer,
        bookSession: bookSessionController.Reducer,
        playerTrending: playerTrendingController.Reducer,
        speakerCard: speakerCardController.Reducer,
        plusPitchV2: PlusPitchV2Controller.Reducer,
        pitchV2: PitchV2Controller.Reducer,
        agencyCardAlert: AgencyCardAlertController.Reducer,
        manageSharingModal: ManageSharingModalController.Reducer,
        headCoachBanner: HeadCoachController.Reducer,
        headCoachPopup: FindHeadCoachPopupController.Reducer,
    };

    return redux.combineReducers(landingPageReducers)
} 