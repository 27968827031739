import moment from 'moment';
import { AppState } from 'root.reducer';
import { StateController } from 'utils/action-declaration';
import AdminPermissionsService from 'api/admin/payments-of-agencies/payments-of-agencies.service';
import {
    FutureSubscriptionModel,
    SubscriptionItemModel,
    SubscriptionModel
} from 'api/admin/payments-of-agencies/models';
import { removeAccents } from 'utils/remove-accents';

export interface ChangeNoteModalState {
    isModalOpen: boolean;
    isProcessing: boolean;
    note: string;
    subscriptionId: number;
    agencyName: string;
}
export interface RenewSubscriptionModalState {
    isModalOpen: boolean;
    isProcessing: boolean;
    isInputEnabled: boolean;
    didTryToProcess: boolean;
    isUnlimitedRegularSlots: boolean;
    agencyId: number;
    regularSlots: number;
    prioritySlots: number;
    regularSlotsUsed: number;
    prioritySlotsUsed: number;
    agencyName: string;
    renewalDate: string;
    futureRenewalDate: string;
    subscriptionPlanName: string;
}
export interface ChangePlayerCreditsModalState {
    isModalOpen: boolean
    isProcessing: boolean
    isUnlimitedRegularPlayerSlots: boolean
    agencyId: number
    agencyName: string
    savedValue: number
    currentValue: number
    slotsInUse: number
}
export interface ChangePriorityCreditsModalState {
    isModalOpen: boolean
    isProcessing: boolean
    isSelectFieldDisabled: boolean
    agencyId: number
    agencyName: string
    selectedValue: string
    selectedValueId: string
    subscription: SubscriptionModel
}

class PaymentsOfAgencies {
    items: Array<SubscriptionItemModel>
    keyword: string
    isProcessing: boolean
    changeNoteModal: ChangeNoteModalState
    renewSubscriptionModal: RenewSubscriptionModalState
    changePlayerCreditsModal: ChangePlayerCreditsModalState
    changePriorityCreditsModal: ChangePriorityCreditsModalState
}
const defaultState: PaymentsOfAgencies = {
    items: [],
    keyword: '',
    isProcessing: false,
    changeNoteModal: {
        isModalOpen: false,
        isProcessing: false,
        note: '',
        subscriptionId: null,
        agencyName: '',
    },
    renewSubscriptionModal: {
        isModalOpen: false,
        isProcessing: false,
        isInputEnabled: false,
        didTryToProcess: false,
        isUnlimitedRegularSlots: false,
        agencyId: null,
        regularSlots: null,
        prioritySlots: null,
        regularSlotsUsed: null,
        prioritySlotsUsed: null,
        agencyName: '',
        renewalDate: '',
        futureRenewalDate: '',
        subscriptionPlanName: null,
    },
    changePlayerCreditsModal: {
        isModalOpen: false,
        isProcessing: false,
        isUnlimitedRegularPlayerSlots: false,
        agencyId: null,
        agencyName: '',
        savedValue: null,
        currentValue: null,
        slotsInUse: null,
    },
    changePriorityCreditsModal: {
        isModalOpen: false,
        isProcessing: false,
        isSelectFieldDisabled: false,
        agencyId: null,
        agencyName: '',
        selectedValue: '',
        selectedValueId: '',
        subscription: null,
    },
}

const stateController = new StateController<PaymentsOfAgencies>(
    'ADMIN/PAYMENTS_OF_AGENCIES',
    defaultState
)

class Actions {

    public static GetAgencySubscriptions() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const data = await AdminPermissionsService.getAgencySubscriptions()
                const pageItems = data.pageItems.map(item => ({
                    ...item,
                    key: item.agencyId
                }))
                dispatch(stateController.setState({ items: pageItems }))
            } catch(error) {
                console.error(error)
                throw error
            }
        }
    }
    public static GetAgencySubscriptionsWithSpinner() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isProcessing: true }))
                const data = await AdminPermissionsService.getAgencySubscriptions()
                const pageItems = data.pageItems.map(item => ({
                    ...item,
                    key: item.agencyId
                }))
                dispatch(stateController.setState({ items: pageItems }))
            } catch(error) {
                console.error(error)
                throw error
            } finally {
                dispatch(stateController.setState({ isProcessing: false }))
            }
        }
    }
    public static changeFilterKeyword(value: string) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                keyword: value,
            })))
        }
    }

    //====== Change player credits Modal ================================================================================

    public static playerCreditsModalOpen(item: SubscriptionItemModel) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                changePlayerCreditsModal: {
                    ...prevState.changePlayerCreditsModal,
                    isModalOpen: true,
                    isUnlimitedRegularPlayerSlots: item.regularPlayersSlotsAmount === -1,
                    agencyId: item.agencyId,
                    agencyName: item.agencyName,
                    savedValue: item.regularPlayersSlotsAmount,
                    currentValue: item.regularPlayersSlotsAmount === -1 ? item.regularPlayersSlotsInUse : item.regularPlayersSlotsAmount,
                    slotsInUse: item.regularPlayersSlotsInUse,
                }
            })))
        }
    }
    public static playerCreditsModalClose() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                changePlayerCreditsModal: {
                    ...defaultState.changePlayerCreditsModal,
                }
            })))
        }
    }
    public static changePlayerCredits(value: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                changePlayerCreditsModal: {
                    ...prevState.changePlayerCreditsModal,
                    currentValue: value,
                }
            })))
        }
    }
    public static setIsUnlimitedRegularPlayerSlots(value: boolean) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                changePlayerCreditsModal: {
                    ...prevState.changePlayerCreditsModal,
                    isUnlimitedRegularPlayerSlots: value,
                }
            })))
        }
    }
    public static savePlayerCredits() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    changePlayerCreditsModal: {
                        ...prevState.changePlayerCreditsModal,
                        isProcessing: true,
                    }
                })))
                const agencyId = getState().admin.paymentsOfAgencies.changePlayerCreditsModal.agencyId;
                const currentValue = getState().admin.paymentsOfAgencies.changePlayerCreditsModal.currentValue;
                const isUnlimited = getState().admin.paymentsOfAgencies.changePlayerCreditsModal.isUnlimitedRegularPlayerSlots;
                const value = isUnlimited ? -1 : currentValue
                await AdminPermissionsService.setRegularPlayersSlotsAmount(agencyId, value)
            } catch (err) {
                console.error(err)
                throw err
            } finally {
                await dispatch(Actions.GetAgencySubscriptions())
                dispatch(Actions.playerCreditsModalClose())
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    changePlayerCreditsModal: {
                        ...prevState.changePlayerCreditsModal,
                        isProcessing: false,
                    }
                })))
            }
        }
    }

    //====== Change priority credits Modal ==============================================================================

    public static priorityCreditsModalOpen(item: SubscriptionItemModel) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    changePriorityCreditsModal: {
                        ...prevState.changePriorityCreditsModal,
                        isModalOpen: true,
                        isSelectFieldDisabled: true,
                        agencyId: item.agencyId,
                        agencyName: item.agencyName,
                    }
                })));
                const subscription = await AdminPermissionsService.getAllPlansForAgencyToSubscribe(item.agencyId)
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    changePriorityCreditsModal: {
                        ...prevState.changePriorityCreditsModal,
                        subscription: subscription,
                        selectedValue: null,
                        selectedValueId: null,
                        isSelectFieldDisabled: false
                    }
                })))
            } catch (err) {
                console.error(err)
                throw err
            }
        }
    }
    public static priorityCreditsModalClose() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                changePriorityCreditsModal: {
                    ...defaultState.changePlayerCreditsModal,
                }
            })))
        }
    }
    public static selectAdditionalSubscriptionPlan(subscription: any) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                changePriorityCreditsModal: {
                    ...prevState.changePriorityCreditsModal,
                    selectedValue: subscription.children,
                    selectedValueId: subscription.key,
                }
            })))
        }
    }
    public static saveSubscriptionPlan() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const agencyId = getState().admin.paymentsOfAgencies.changePriorityCreditsModal.agencyId
                const planId = getState().admin.paymentsOfAgencies.changePriorityCreditsModal.selectedValueId
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    changePriorityCreditsModal: {
                        ...prevState.changePriorityCreditsModal,
                        isProcessing: true,
                    }
                })))
                await AdminPermissionsService.agencyAddSubscription(agencyId, planId)
            } catch (err) {
                console.error(err)
                throw err
            } finally {
                await dispatch(Actions.GetAgencySubscriptions())
                dispatch(Actions.priorityCreditsModalClose())
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    changePriorityCreditsModal: {
                        ...prevState.changePriorityCreditsModal,
                        isProcessing: false,
                    }
                })))
            }
        }
    }

    //====== Renew subscription Modal ===================================================================================

    public static renewSubscriptionModalOpen(item: SubscriptionItemModel) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    renewSubscriptionModal: {
                        ...prevState.renewSubscriptionModal,
                        isModalOpen: true,
                        agencyId: item.agencyId,
                        agencyName: item.agencyName,
                        prioritySlots: item.slotsAmount,
                        regularSlots: item.regularPlayersSlotsAmount,
                        prioritySlotsUsed: item.slotsInUse,
                        regularSlotsUsed: item.regularPlayersSlotsInUse,
                        renewalDate: item.renewalDate,
                        subscriptionPlanName: item.subscriptionPlanName
                    }
                })))

                const futureSubscription = await AdminPermissionsService.getFutureSubscription(item.agencyId) as FutureSubscriptionModel

                await dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    renewSubscriptionModal: {
                        ...prevState.renewSubscriptionModal,
                    }
                })))

                const date = futureSubscription.renewalDate 
                    ? moment(futureSubscription.renewalDate).format() 
                    : Selectors.selectedMinExpirationDate(getState())

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    renewSubscriptionModal: {
                        ...prevState.renewSubscriptionModal,
                        futureRenewalDate: date,
                    }
                })))

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    renewSubscriptionModal: {
                        ...prevState.renewSubscriptionModal,
                        isUnlimitedRegularSlots: futureSubscription?.regularPlayersSlotsAmount === -1 
                            ? true 
                            : item.regularPlayersSlotsAmount === -1,
                        isInputEnabled: true,
                    }
                })))
            } catch (err) {
                console.error(err)
                throw err
            }
        }
    }
    public static renewSubscriptionModalClose() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                renewSubscriptionModal: { 
                    ...defaultState.renewSubscriptionModal,
                }
            })))
        }
    }
    public static changeNewExpirationDate(date: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                renewSubscriptionModal: {
                    ...prevState.renewSubscriptionModal,
                    futureRenewalDate: date,
                }
            })))
        }
    }

    public static renewSubscription() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    renewSubscriptionModal: {
                        ...prevState.renewSubscriptionModal,
                        didTryToProcess: true,
                    }
                })))

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    renewSubscriptionModal: {
                        ...prevState.renewSubscriptionModal,
                        isProcessing: true,
                    }
                })))
                const renewSubscription = getState().admin.paymentsOfAgencies.renewSubscriptionModal
                await AdminPermissionsService.renewSubscription({
                    agencyId: renewSubscription.agencyId,
                    expirationDate: renewSubscription.futureRenewalDate,
                })
                await dispatch(Actions.GetAgencySubscriptions())
                dispatch(Actions.renewSubscriptionModalClose())
            } catch (err) {
                console.error(err)
                throw err
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    renewSubscriptionModal: {
                        ...prevState.renewSubscriptionModal,
                        isProcessing: false,
                    }
                })))
            }
        }
    }

    //====== Change note Modal ==========================================================================================

    public static changeNoteModalOpen(item: SubscriptionItemModel) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                changeNoteModal: {
                    ...prevState.changeNoteModal,
                    isModalOpen: true,
                    note: item.notes,
                    agencyName: item.agencyName,
                    subscriptionId: item.agencySubscriptionId,
                }
            })))
        }
    }
    public static changeNoteModalClose() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                changeNoteModal: {
                    ...defaultState.changeNoteModal,
                }
            })))
        }
    }
    public static changeTextOfNote(text: string) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                changeNoteModal: {
                    ...prevState.changeNoteModal,
                    note: text,
                }
            })))
        }
    }
    public static UpdateNote(subscriptionId: number, textOfNote: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    changeNoteModal: {
                        ...prevState.changeNoteModal,
                        isProcessing: true,
                    }
                })))
                await AdminPermissionsService.updateNotes(subscriptionId, textOfNote.trim())
            } catch(err) {
                console.error(err)
                throw err
            } finally {
                await dispatch(Actions.GetAgencySubscriptions())
                dispatch(Actions.changeNoteModalClose())
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    changeNoteModal: {
                        ...prevState.changeNoteModal,
                        isProcessing: false,
                    }
                })))
            }
        }
    }
}

class Selectors { 

    public static isPlayerCreditsUnchanged(state: AppState) {
        const currentValue = state.admin.paymentsOfAgencies.changePlayerCreditsModal.currentValue
        const savedValue = state.admin.paymentsOfAgencies.changePlayerCreditsModal.savedValue
        const isUnlimited = state.admin.paymentsOfAgencies.changePlayerCreditsModal.isUnlimitedRegularPlayerSlots
        return ((currentValue === savedValue) && !isUnlimited) || (currentValue === null) ? true : false
    }
    public static minExpirationDate(state: AppState) {
        const renewalDate = state.admin.paymentsOfAgencies.renewSubscriptionModal.renewalDate
        return moment(renewalDate).add(1, 'years').add(-1, 'days').format()
    }
    public static selectedMinExpirationDate(state: AppState) {
        const renewalDate = state.admin.paymentsOfAgencies.renewSubscriptionModal.renewalDate
        return moment(renewalDate).add(1, 'years').format()
    }
    public static selectFilteredItems(state: AppState) {
        const keyword = state.admin.paymentsOfAgencies.keyword;
        return state.admin.paymentsOfAgencies.items
            .filter(
                item => (item.agencyName && removeAccents(item.agencyName).toLowerCase()  || "")
                .includes(removeAccents(keyword).toLowerCase())
            );
    }
    public static isRenewSubscriptionBtnEnabled(state: AppState) {
        const futureRenewalDate = state.admin.paymentsOfAgencies.renewSubscriptionModal.futureRenewalDate;
        return !!futureRenewalDate
    }
}

const reducer = stateController.getReducer()

export {
    reducer as Reducer,
    PaymentsOfAgencies as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
}