import { StateController } from 'utils/action-declaration'
import { AppState } from 'root.reducer'
import { EmailConfirmationService } from 'api/player-side/email-confirmation.service'

class PlayerEmailConfirmationState {
    isConfirmPageProcessing: boolean;
    isConfirmSucceed: boolean;
}

const defaultState: PlayerEmailConfirmationState = {
    isConfirmPageProcessing: false,
    isConfirmSucceed: null,
}

const stateController = new StateController<PlayerEmailConfirmationState>(
    'PLAYER_EMAIL_CONFIRMATIONV2',
    defaultState
)

class Actions {
    public static validateGuid(guid: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isConfirmPageProcessing: true }))
                const { isSuccess } = await EmailConfirmationService.confirmEmailVerification(guid);
                if (isSuccess) {
                    dispatch(stateController.setState({ isConfirmSucceed: true }))
                } else {
                    dispatch(stateController.setState({ isConfirmSucceed: false }))
                }
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState({ isConfirmPageProcessing: false }))
            }
        }
    }
}

class Selectors {

}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    PlayerEmailConfirmationState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};