import React, { useState } from 'react';
import config from 'config';
import defaultLogo from 'media/images/custom-logo.svg';
import Tooltip from "rc-tooltip";

const classes = {
    large: 'tr-club-image--large',
    medium: 'tr-club-image--medium',
    small: 'tr-club-image--small',
    extrasmall: 'tr-club-image--extrasmall',
    thumb: 'tr-club-image--thumb',
};

type OwnProps = {
    size?: 'extrasmall' | 'small' | 'medium' | 'large' | 'thumb';
    customSize?: number;
    path?: string;
    alt?: string;
    tooltipName?: string;
    name?: string;
    customMaxWidth?: string;
    isLogoRestricted?: boolean;
}

const ClubImage = (props: OwnProps) => {
    const { 
        size = 'medium', 
        customSize, 
        name, 
        path, 
        alt, 
        tooltipName,
        isLogoRestricted
    } = props;

    const [showTooltip, setShowTooltip] = useState(!props.name && !!tooltipName);
    const [error, setError] = useState(false);

    const style = customSize
        ? { width: 'auto', maxHeight: customSize }
        : { width: 'auto' };

    let src = null;
    if(!isLogoRestricted){
        src = getServerImageSrc(name, path)
    }

    const handleImageError = () => {
        setError(true);
        setShowTooltip(!!tooltipName);
    };

    const renderImage = () => {
        return (
            <img
                src={src && src.length && !error ? src : defaultLogo}
                alt={alt || 'Club'}
                onError={() => handleImageError()}
                style={style}
            />
        );
    }

    return (
        <div style={props.customMaxWidth ? { maxWidth: props.customMaxWidth } : null} className={`tr-club-image ${classes[size]}`}>
            {
                showTooltip 
                ? <Tooltip
                    placement='top'
                    overlayStyle={{
                        maxWidth: '150px'
                    }}
                    mouseEnterDelay={0.1}
                    align={{ offset: [1, -7] }}
                    overlay={tooltipName}
                    overlayClassName={'dark-theme-tooltip profile-custom-size'}
                >
                    {renderImage()}
                </Tooltip> 
                : renderImage()
            }
        </div>
    );
}


export default ClubImage;

function getServerImageSrc(imageName: string, imagePath: string) {
    if (!imageName) {
        return '';
    }
    if (imageName && imageName.indexOf("https://") === 0) {
        return imageName;
    }
    const basePath = `${config.imagesUrl}/clubs`;
    if (imagePath) {
        return imagePath;
    }
    return `${basePath}/${imageName}`;
}
