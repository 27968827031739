import { StateController } from 'utils/action-declaration';

import { AppState } from 'root.reducer'
import Service from 'api/virtual-summit/virtual-summit.service';
import { Actions as StepActions } from 'pages/virtual-summit/redux/complete-details.controller';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { ActionType, PageType } from 'constants/enums';

class ActivePitchesState {
    players: Array<any>;
    displayPlayers: Array<any>;
    selectedPlayer: any;
    availabilitiesToSave: Array<any>;
    keyword: string;
    availablePlayersCount: number;
    availablePlayersPercent: number;
    isLoading: boolean;
    isBusy: boolean;
}

const defaultState: ActivePitchesState = {
    players: [],
    displayPlayers: [],
    selectedPlayer: null,
    availabilitiesToSave: [],
    keyword: "",
    availablePlayersCount: 0,
    availablePlayersPercent: 0,
    isLoading: false,
    isBusy: false
}

const stateController = new StateController<ActivePitchesState>(
    "VIRTUAL_SUMMIT/MAKE_PLAYERS_AVAILABLE",
    defaultState
);

class Actions {
    public static loadPlayers() {
        return async (dispatch, getState: () => AppState) => {
            let appState = getState();
            let currentSquadId = appState.auth.squadId;
            let currentClubId = appState.auth.clubId;
            let currentUserId = appState.auth.userId;

            dispatch(stateController.setState({ isLoading: true }));

            let players = await Service.loadMySquadPlayers(currentClubId, currentUserId, currentSquadId);

            players = players.output;
            let displayPlayers = players.filter(i => !i.playerAvailability.isTransferStatusDisabledForCurrentSquad && !i.inReleaseList);

            let count = 0;
            let percent = 0;
            displayPlayers.map(p => {
                if (p.playerAvailability.isAvailableForLoan || p.playerAvailability.isAvailableForSale) count += 1;
            });
            percent = Math.round(100 * count / displayPlayers.length);

            dispatch(stateController.setState({
                isLoading: false,
                players: players,
                displayPlayers: displayPlayers,
                availablePlayersCount: count,
                availablePlayersPercent: percent
            }));
        }
    }

    public static saveAvailabilities(redirect: (referer: string) => void) {
        return async (dispatch, getState: () => AppState) => {
            let appState = getState();
            const { eventId  } = appState.auth;
           // let availabilities = appState.virtualSummit.makePlayersAvailable.availabilitiesToSave;

            dispatch(stateController.setState({ isBusy: true }));

          //  if (availabilities.length > 0) {
             //   await Service.setAvailabilities(appState.auth.userId, availabilities);
          //  }
            await Service.setTransferOutIsCompleted(appState.auth.userId);
            dispatch(StepActions.setOutCompleted());

            /*
            availabilities.forEach(x => {
                var userActivityMessage = 'Transfers Out Set Up. Listed Player: ';
                if (x.isAvailableForLoan) {
                    userActivityMessage += `For Loan (asking price: ${x.monthlyLoanFee || "Price upon request"})`;
                }
                if (x.isAvailableForSale) {
                    userActivityMessage += `${x.isAvailableForLoan ? ', ' : ''} For Sale (asking price: ${x.monthlyLoanFee || "Price upon request"})`;
                }
                if (x.sellOn) {
                    userActivityMessage = userActivityMessage + ' Sell On:' + x.sellOn;
                }
                dispatch(userActivityInsert({
                    PlayerId: x.playerId,
                    Message: userActivityMessage, PageName: 'Event [Details]',
                    PageType: PageType.Events,
                    EventId: eventId
                }))
            });
            */
            dispatch(this.dispose());
            dispatch(StepActions.goToNext(false, redirect));
        }
    }

    public static setSelectedPlayer(player: any) {
        return async (dispatch, getState: () => AppState) => {
            let selectedPlayer = getState().virtualSummit.makePlayersAvailable.selectedPlayer;
            if (selectedPlayer && selectedPlayer.id == player.id)
                dispatch(stateController.setState({ selectedPlayer: null }));
            else
                dispatch(stateController.setState({ selectedPlayer: player }));
        }
    }

    public static dispose() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(defaultState));
        }
    }


    public static savePlayerAvailability(availability: any) {
        return async (dispatch, getState: () => AppState) => {
            let subState = getState().virtualSummit.makePlayersAvailable;
            const { eventId  } = getState().auth;

            let player = subState.displayPlayers.find(i => i.id == availability.playerId);
            let prevAvailability = null;
            if (player) {
                prevAvailability = player.playerAvailability;
                player.playerAvailability = availability;
            }

            let displayPlayers = getState().landingPage.makePlayersAvailable.displayPlayers;
            let count = 0;
            let percent = 0;
            displayPlayers.map(p => {
                if (p.playerAvailability.isAvailableForLoan || p.playerAvailability.isAvailableForSale) count += 1;
            });
            percent = Math.round(100 * count / displayPlayers.length);

            dispatch(stateController.setState({
                selectedPlayer: null,
                displayPlayers: [...subState.displayPlayers],
                availablePlayersCount: count,
                availablePlayersPercent: percent,
            }));

            await Service.setAvailabilities(getState().auth.userId, [availability]);

            let playerStatus = '';
            if (!availability.isAvailableForSale && !availability.isAvailableForLoan) {
                playerStatus = `Delisted Player: ${Actions.getStatus(prevAvailability)}`;
            }
            else {
                playerStatus = `Listed Player: ${Actions.getStatus(availability)}`;
            }
            
            dispatch(userActivityInsert({
                PageName: 'Event [Details]',
                Message: `Transfers Out Set Up. ${playerStatus}`,
                PlayerId: availability.playerId,
                ActionType: ActionType.ListedPlayer,
                PageType: PageType.Events,
                EventId: eventId
            }));
        }
    }

    public static changeKeyword(keyword: string) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ keyword: keyword }));
        }
    }

    private static getStatus(availability: any){
        let availabilityText = '';

        if (availability.isAvailableForSale) {
            availabilityText += 'For Sale (';

            if (availability.isSellPriceUponRequest) {
                availabilityText += 'price upon request';
            }
            else {
                availabilityText += 'asking price: ' + availability.askingPrice;

                if (availability.sellOn && availability.sellOn > 0) {
                    availabilityText += ` + ${availability.sellOn}% sell-on`
                }
            }

            availabilityText += ')';
        }
        if (availability.isAvailableForLoan) {
            if (availabilityText.length > 0) {
                availabilityText += ', ';
            }

            availabilityText += 'For Loan (';

            if (availability.isLoanPriceUponRequest) {
                availabilityText += 'price upon request';
            }
            else {
                availabilityText += 'asking price: ' + availability.monthlyLoanFee;
            }

            availabilityText += ')';
        }
        return availabilityText;
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    ActivePitchesState as State,
    Actions as Actions,
    stateController as Controller
};



