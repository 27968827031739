import { PageType, ActionType } from 'constants/enums';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions'
import { GridToggleState } from './grid.controller';

export class GridActivityService {
    private static page: string = 'Search';

    private static buildCommand(container: string, action: string, playerId?: number, clubId?: number, agencyId?: number, actionType?: ActionType) {
        
        const currentUrl = window.location.href;
        const currentPage = currentUrl.includes('/profile/') ? 'Player Profile': 'Search'
        let currentPageType = PageType.Search;
        switch(currentPage){
            case 'Search':
                currentPageType = PageType.Search;
                break;
            case 'Player Profile':
                currentPageType = PageType.PlayerProfile;
                break;
            default:
                currentPageType = PageType.Search;
                break;
        }

        return {
            Message: action,
            PageType: currentPageType,
            PlayerId: playerId,
            ClubId: clubId,
            AgencyId: agencyId,
            PageName: `${currentPage}${container ? ` [${container}]` : ''}`,
            ActionType: actionType,
        }
    }

    public static openSearch() {
        return dispatch => {
            dispatch(userActivityInsert(
                this.buildCommand(null, `Opened Player Search`)
            ))
        }
    }

    public static openShortList() {
        return dispatch => {
            dispatch(userActivityInsert(
                this.buildCommand('Shortlist', `Opened Shortlist`)
            ))
        }
    }

    public static setPageSize(size: number) {
        return dispatch => {
            dispatch(userActivityInsert(
                this.buildCommand(null, `Selected ${size} rows`)
            ))
        }
    }

    public static openPlayerProfile(playerId: number, clubId?: number, agencyId?: number, gridState?: GridToggleState) {
        return (dispatch) => {
            const state = GridActivityService.getStatePage(gridState)
            const command = this.buildCommand(state, 'Opened Player Profile', playerId, clubId, agencyId)

            dispatch(userActivityInsert(command))
        }
    }

    public static claimRepresentation(playerId: number, clubId?: number, agencyId?: number, gridState?: GridToggleState) {
        return (dispatch) => {
            const state = GridActivityService.getStatePage(gridState)
            const command = this.buildCommand(state, 'Claim Representation', playerId, clubId, agencyId)

            dispatch(userActivityInsert(command))
        }
    }

    public static verifyPlayer(playerId: number, clubId?: number, agencyId?: number, gridState?: GridToggleState) {
        return (dispatch) => {
            const state = GridActivityService.getStatePage(gridState)
            const command = this.buildCommand(state, 'Verify Player', playerId, clubId, agencyId)

            dispatch(userActivityInsert(command))
        }
    }

    public static pitch(playerId: number, clubId?: number, gridState?: GridToggleState) {
        return (dispatch) => {
            const state = GridActivityService.getStatePage(gridState)
            const command = this.buildCommand(state, 'Pitch opportunities', playerId, clubId)

            dispatch(userActivityInsert(command))
        }
    }

    public static toggleShortlist(playerId: number, isInShortList: boolean, clubId?: number, agencyId?: number, gridState?: GridToggleState) {
        return (dispatch) => {
            const state = GridActivityService.getStatePage(gridState)
            const message = isInShortList ? 'Added to Shortlist' : 'Removed from Shortlist'
            const actionType = isInShortList ? ActionType.AddToShortlist : null;
            const command = this.buildCommand(state, message, playerId, clubId, agencyId, actionType)

            dispatch(userActivityInsert(command))
        }
    }

    private static getStatePage(state: GridToggleState) {
        let action = ''
        if (state === GridToggleState.ShortList) { action = 'Shortlist' }
        return action;
    }

    public static openPositionRoleTootip(playerId: number, clubId?: number, agencyId?: number, gridState?: GridToggleState) {

        return (dispatch) => {
            const state = GridActivityService.getStatePage(gridState)
            const command = this.buildCommand(state, 'Opened Player Style Info', playerId, clubId, agencyId)
            dispatch(userActivityInsert(command))
        }
    }

    public static openRatingTootip(playerId: number, clubId?: number, agencyId?: number, gridState?: GridToggleState) {
        return (dispatch) => {
            const state = GridActivityService.getStatePage(gridState)
            const command = this.buildCommand(state, 'Opened Player Rating Info', playerId, clubId, agencyId)
            dispatch(userActivityInsert(command))
        }
    }
}
