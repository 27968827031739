import { CancelToken } from 'axios';
import axios from 'axios-config';
import { AgentProfileResponse } from './models/agent-profile-response.model';

export class AgencyProfileService {
    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async getAgencyProfileData(externalAgencyId: number, currencyId: number): Promise<AgentProfileResponse> {
        const { data } = await axios.get(
            `api/v2/Agency/GetAgencyProfile/${externalAgencyId}/${currencyId}`,
            this.axiosConfig
        )
        return data
    }

    public static async getAgencyProfileDataPreview(currencyId: number): Promise<AgentProfileResponse> {
        const { data } = await axios.get(
            `api/v2/Agency/GetAgencyProfilePreview/${currencyId}`,
            this.axiosConfig
        )
        return data
    }

    public static async uploadHeaderImg(file: string | Blob, name: string, onUploadProgressCallback: (progressEvent: any) => void, cancelToken: CancelToken) {
        let formData = new FormData();
        formData.append(name, file);
        let res = await axios.post(
            'api/v2/Agency/UploadProfileHeaderImage',
            formData,
            {
                headers: { "Content-Type": "multipart/form-data", },
                onUploadProgress: onUploadProgressCallback,
                cancelToken: cancelToken
            }
        );
        return res;
    }

    public static async uploadLogoImg(file: string | Blob, name: string, onUploadProgressCallback: (progressEvent: any) => void, cancelToken: CancelToken) {
        let formData = new FormData();
        formData.append(name, file);
        let res = await axios.post(
            'api/v2/Agency/UploadProfileLogoImage',
            formData,
            {
                headers: { "Content-Type": "multipart/form-data", },
                onUploadProgress: onUploadProgressCallback,
                cancelToken: cancelToken
            }
        );
        return res;
    }
}