import React from 'react';
import TestimonialSlider from 'app/testimonial-slider/testimonials-slider'
import { whatToExpectBenefits } from '../../data/benefits';
import Section from './what-to-expect.styled';

type Props = {
    hideBottomLine?: boolean;
}

const WhatToExpect = (props: Props) => {
    return (
        <Section hideBottomLine={props.hideBottomLine}>
            <h2>What to expect:</h2>
            <div className="row">
                <div className="list-container">
                    <ul>
                        {whatToExpectBenefits.map((item, index) => (
                            <li className={'mobile-li'} key={index}>
                                <span className="fa fa-check" />
                                {item}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </Section>
    )
}

export default WhatToExpect