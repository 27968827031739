import * as types from 'store/actionTypes';

const initialState = {
    receivers: [],
    isSuccess: true,
};

export default (adminBlockedReceivers = initialState, action) => {
    switch (action.type) {
        
        // case types.ADMIN_BLOCKED_RECEIVERS_FETCH.REQUEST: {
        //     return {
        //         ...adminBlockedReceivers,
        //         receivers: [],
        //     };
        // }

        case types.ADMIN_BLOCKED_RECEIVERS_FETCH.SUCCESS: {
            return {
                ...adminBlockedReceivers,
                receivers: action.payload.data.output,
                totalRecords: action.payload.data.rowCount,
                currentPageNumber: action.payload.data.currentPageNumber,
            };
        }        

        default:
            return {
                ...adminBlockedReceivers
            };
    }
};

export const getBlockedReceiversList = state => {
    return state.adminBlockedReceivers.receivers;
};

export const getBlockedReceiversTotalRecordsCount = state => {
    return state.adminBlockedReceivers.totalRecords;
};

export const getBlockedReceiversPageNumber = state => {
    return state.adminBlockedReceivers.currentPageNumber;
};

