import { StateController } from 'utils/action-declaration'
import {AppState} from 'root.reducer';
import { Actions as GridActions } from "pages/PlayerSearch-v3/squad/redux/grid.controller";
import historyAccessor from 'history-accessor';
import { userPaths } from 'routes/paths';
import userActivityInsert from "../../../app/user-activity/actions/user-activity.actions";
import { CoachProfileService } from 'api/coach-profile/coach-profile.service';

export enum ConfirmSteps {
	StartScreen,
	ThankYouScreen,
}

export class BannerState {
	isCoachBanner: boolean;
	step: ConfirmSteps;
	isFindHeadCoachVisible: boolean;
}

const defaultState: BannerState = {
	isCoachBanner: true,
	step: ConfirmSteps.StartScreen,
	isFindHeadCoachVisible: false,
}

const stateController = new StateController<BannerState>(
	"LANDING_PAGE/FIND_COACH_BANNER",
	defaultState
);

class Actions {
	public static setIsCoachBanner = (isBanner: boolean) => {
		return (dispatch, getState) => {
			dispatch(stateController.setState({ isCoachBanner: isBanner }));
		}
	}
	
	public static setStep(step: ConfirmSteps) {
		return (dispatch) => {
			dispatch(stateController.setState({ step: step }));
		}
	}
	
	public static onInterested() {
		return (dispatch) => {
			CoachProfileService.showInterestToCoachProfile();
			dispatch(Actions.setStep(ConfirmSteps.ThankYouScreen));
		}
	}
	public static openModal() {
		return (dispatch) => {
			dispatch(stateController.setState(prevState =>({
				...prevState,
				isFindHeadCoachVisible: true,
			})))
		}
	}

	public static openExploreHeadCoaches() {
		return (dispatch) => {
			historyAccessor.push(userPaths.playerSearch);
			dispatch(GridActions.onSearchHeadCoaches());
		}
	}
	public static sendUserActivity(message, pageType) {
		return (dispatch, getState: () => AppState) => {
			dispatch(userActivityInsert({
				PageName: pageType,
				Message: message,
			}))
		}
	}

	public static closeModal() {
		return (dispatch) => {
			dispatch(stateController.setState(prevState =>({
				...prevState,
				isFindHeadCoachVisible: false,
			})));
			dispatch(Actions.setStep(ConfirmSteps.StartScreen));
			dispatch(Actions.setIsCoachBanner(false));
			localStorage.setItem('isCoachBanner', 'false');
		}
	}
}


class Selectors {
	public static getRoot = (state: AppState): BannerState => state.landingPage.headCoachBanner;
	public static getCurrentStep = (state: AppState) => Selectors.getRoot(state).step;
}

const reducer = stateController.getReducer();

export {
	reducer as Reducer,
	BannerState as State,
	Selectors as Selectors,
	Actions as Actions,
	stateController as Controller
};


