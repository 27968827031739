export const eventClubs = [
  {
    squadId: 2,
    squadName: "AS Roma",
    shortSquadName: "AS Roma",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1835_AS_Roma.png"
  },
  {
    squadId: 3,
    squadName: "Juventus FC",
    shortSquadName: "Juventus",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1832_Juventus_FC.png"
  },
  {
    squadId: 4,
    squadName: "Parma Calcio 1913",
    shortSquadName: "Parma",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3160_Parma_Calcio_1913.png"
  },
  {
    squadId: 7,
    squadName: "Udinese Calcio",
    shortSquadName: "Udinese Calcio",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1844_Udinese_Calcio.png"
  },
  {
    squadId: 17,
    squadName: "Genoa CFC",
    shortSquadName: "Genoa",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1843_Genoa_CFC.png"
  },
  {
    squadId: 45,
    squadName: "Manchester United",
    shortSquadName: "Man Utd",
    squadLogo: null //"https://transferroom.blob.core.windows.net/clubs/logo_1120_Manchester_United.png"
  },
  {
    squadId: 46,
    squadName: "Liverpool FC",
    shortSquadName: "Liverpool",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1123_Liverpool_FC.png"
  },
  {
    squadId: 47,
    squadName: "Tottenham Hotspur",
    shortSquadName: "Tottenham",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1122_Tottenham_Hotspur.png"
  },
  {
    squadId: 48,
    squadName: "Manchester City",
    shortSquadName: "Man City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1625_Manchester_City.png"
  },
  {
    squadId: 49,
    squadName: "Arsenal FC",
    shortSquadName: "Arsenal",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1121_Arsenal_FC.png"
  },
  {
    squadId: 50,
    squadName: "Chelsea FC",
    shortSquadName: "Chelsea",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Chelsea_FC.png"
  },
  {
    squadId: 60,
    squadName: "Newcastle United",
    shortSquadName: "Newcastle",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Newcastle_United.png"
  },
  {
    squadId: 62,
    squadName: "Southampton FC",
    shortSquadName: "Southampton",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1619_Southampton_FC.png"
  },
  {
    squadId: 63,
    squadName: "Everton FC",
    shortSquadName: "Everton",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1124_Everton_FC.png"
  },
  {
    squadId: 64,
    squadName: "Crystal Palace",
    shortSquadName: "Crystal Palace",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1128_Crystal_Palace.png"
  },
  {
    squadId: 65,
    squadName: "Wolverhampton Wanderers",
    shortSquadName: "Wolves",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Wolverhampton_Wanderers.png"
  },
  {
    squadId: 66,
    squadName: "Leicester City",
    shortSquadName: "Leicester",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Leicester_City.png"
  },
  {
    squadId: 68,
    squadName: "Cardiff City",
    shortSquadName: "Cardiff",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1151_Cardiff_City.png"
  },
  {
    squadId: 69,
    squadName: "Watford FC",
    shortSquadName: "Watford",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1644_Watford_FC.png"
  },
  {
    squadId: 70,
    squadName: "Brighton & Hove Albion",
    shortSquadName: "Brighton",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Brighton_And_Hove_Albion_FC.png"
  },
  {
    squadId: 71,
    squadName: "AFC Bournemouth",
    shortSquadName: "Bournemouth",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1134_AFC_Bournemouth.png"
  },
  {
    squadId: 72,
    squadName: "Huddersfield Town",
    shortSquadName: "Huddersfield",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1673_Huddersfield_Town.png"
  },
  {
    squadId: 174,
    squadName: "CA Talleres",
    shortSquadName: "CA Talleres",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2588_Club_Atletico_Talleres.png"
  },
  {
    squadId: 416,
    squadName: "Al-Fateh SC",
    shortSquadName: "Al-Fateh",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/16483_Al-Fateh_SC.png"
  },
  {
    squadId: 489,
    squadName: "Helmond Sport",
    shortSquadName: "Helmond Sport",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Helmond_Sport.png"
  },
  {
    squadId: 513,
    squadName: "Rodez AF",
    shortSquadName: "Rodez AF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3867_Rodez_AF.png"
  },
  {
    squadId: 542,
    squadName: "FK Atyrau",
    shortSquadName: "Atyrau",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_542_FK_Atyrau.png"
  },
  {
    squadId: 606,
    squadName: "Quevilly - Rouen Metropole",
    shortSquadName: "QRM",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3865_Quevilly_-_Rouen_Métropole.png"
  },
  {
    squadId: 661,
    squadName: "Real Esteli FC",
    shortSquadName: "Real Esteli FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_661_Real_Esteli_FC.png"
  },
  {
    squadId: 773,
    squadName: "Getafe CF",
    shortSquadName: "Getafe",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1728_Getafe_CF.png"
  },
  {
    squadId: 831,
    squadName: "Konyaspor",
    shortSquadName: "Konyaspor",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2011_Atiker_Konyaspor.png"
  },
  {
    squadId: 862,
    squadName: "CD O'Higgins",
    shortSquadName: "O'Higgins",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12302_CD_O'Higgins.png"
  },
  {
    squadId: 976,
    squadName: "AS Monaco",
    shortSquadName: "Monaco",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/19830_AS_Monaco.png"
  },
  {
    squadId: 1003,
    squadName: "Hull City",
    shortSquadName: "Hull City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1672_Hull_City.png"
  },
  {
    squadId: 1142,
    squadName: "AZ Alkmaar",
    shortSquadName: "AZ Alkmaar",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/10_AZ_Alkmaar.png"
  },
  {
    squadId: 1423,
    squadName: "LD Alajuelense",
    shortSquadName: "LD Alajuelense",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/16835_LD_Alajuelense.png"
  },
  {
    squadId: 1494,
    squadName: "NEC Nijmegen",
    shortSquadName: "NEC Nijmegen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/22_NEC_Nijmegen.png"
  },
  {
    squadId: 1535,
    squadName: "FC Zurich",
    shortSquadName: "FC Zurich",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/6412_FC_Zürich.png"
  },
  {
    squadId: 1740,
    squadName: "Amiens SC",
    shortSquadName: "Amiens SC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3789_Amiens_SC.png"
  },
  {
    squadId: 1771,
    squadName: "Bryne FK",
    shortSquadName: "Bryne",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7107_Bryne_FK.png"
  },
  {
    squadId: 1792,
    squadName: "Monagas SC",
    shortSquadName: "Monagas SC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1792_Monagas_SC.png"
  },
  {
    squadId: 1799,
    squadName: "Angostura FC",
    shortSquadName: "Angostura FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1799_Angostura_FC.png"
  },
  {
    squadId: 1930,
    squadName: "FC Viktoria Koln",
    shortSquadName: "Viktoria Koln",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1388_FC_Viktoria_Koln.png"
  },
  {
    squadId: 1949,
    squadName: "Independiente Medellin",
    shortSquadName: "Indep. Medellin",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12399_Independiente_Medellín.png"
  },
  {
    squadId: 2031,
    squadName: "1.FC Union Berlin",
    shortSquadName: "Union Berlin",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1277_1.FC_Union_Berlin.png"
  },
  {
    squadId: 2086,
    squadName: "FC Erzgebirge Aue",
    shortSquadName: "Erzgebirge Aue",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Erzgebirge_Aue.png"
  },
  {
    squadId: 2226,
    squadName: "Sevilla FC",
    shortSquadName: "Sevilla FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/680_Sevilla_FC.png"
  },
  {
    squadId: 2332,
    squadName: "Cercle Brugge",
    shortSquadName: "Cercle Brugge",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5070_Cercle_Brugge.png"
  },
  {
    squadId: 2391,
    squadName: "Roda JC Kerkrade",
    shortSquadName: "Roda JC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Roda_JC_Kerkrade.png"
  },
  {
    squadId: 2956,
    squadName: "KAA Gent",
    shortSquadName: "KAA Gent",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1571_KAA_Gent.png"
  },
  {
    squadId: 2994,
    squadName: "1.FC Saarbrucken",
    shortSquadName: "Saarbrucken",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2476_1.FC_Saarbrücken.png"
  },
  {
    squadId: 3028,
    squadName: "Palermo FC",
    shortSquadName: "Palermo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3171_Palermo_FC.png"
  },
  {
    squadId: 3670,
    squadName: "Queen's Park FC",
    shortSquadName: "Queen's Park",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/8546_Queen's_Park_FC.png"
  },
  {
    squadId: 3695,
    squadName: "SK Slavia Prague",
    shortSquadName: "Slavia Prague",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/11242_SK_Slavia_Prague.png"
  },
  {
    squadId: 3725,
    squadName: "KV Oostende",
    shortSquadName: "KV Oostende",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5080_KV_Oostende.png"
  },
  {
    squadId: 3740,
    squadName: "KV Kortrijk",
    shortSquadName: "KV Kortrijk",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_KV_Kortrijk.png"
  },
  {
    squadId: 3774,
    squadName: "Club Necaxa",
    shortSquadName: "Necaxa",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2283_Club_Necaxa.png"
  },
  {
    squadId: 4059,
    squadName: "OGC Nice",
    shortSquadName: "OGC Nice",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1965_OGC_Nice.png"
  },
  {
    squadId: 4178,
    squadName: "Servette FC",
    shortSquadName: "Servette FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2355_Servette_FC.png"
  },
  {
    squadId: 4186,
    squadName: "Ermis Aradippou",
    shortSquadName: "Ermis Aradippou",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/10711_Ermis_Aradippou.png"
  },
  {
    squadId: 4230,
    squadName: "Stade de Reims",
    shortSquadName: "Stade Reims",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3802_Stade_Reims.png"
  },
  {
    squadId: 4234,
    squadName: "Queens Park Rangers",
    shortSquadName: "QPR",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1149_Queens_Park_Rangers.png"
  },
  {
    squadId: 4673,
    squadName: "Portsmouth FC",
    shortSquadName: "Portsmouth",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Portsmouth_FC.png"
  },
  {
    squadId: 5072,
    squadName: "SC Paderborn 07",
    shortSquadName: "SC Paderborn",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1303_SC_Paderborn_07.png"
  },
  {
    squadId: 5121,
    squadName: "Minnesota United FC",
    shortSquadName: "Minnesota",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2122_Minnesota_United_FC.png"
  },
  {
    squadId: 5282,
    squadName: "PSV Eindhoven",
    shortSquadName: "PSV Eindhoven",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/11_PSV_Eindhoven.png"
  },
  {
    squadId: 5359,
    squadName: "Caracas FC",
    shortSquadName: "Caracas FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15680_Caracas_FC.png"
  },
  {
    squadId: 5592,
    squadName: "SD Aucas",
    shortSquadName: "SD Aucas",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_5592_SD_Aucas.png"
  },
  {
    squadId: 5842,
    squadName: "Hobro IK",
    shortSquadName: "Hobro IK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Hobro_IK.png"
  },
  {
    squadId: 5924,
    squadName: "Everton de Vina del Mar",
    shortSquadName: "CD Everton",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12293_CD_Everton.png"
  },
  {
    squadId: 5968,
    squadName: "AFC Wimbledon",
    shortSquadName: "AFC Wimbledon",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1178_AFC_Wimbledon.png"
  },
  {
    squadId: 5972,
    squadName: "San Jose Earthquakes",
    shortSquadName: "San Jose",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7851_San_Jose_Earthquakes.png"
  },
  {
    squadId: 6031,
    squadName: "Tampa Bay Rowdies",
    shortSquadName: "TB Rowdies",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/8096_Tampa_Bay_Rowdies.png"
  },
  {
    squadId: 6051,
    squadName: "Real Valladolid CF",
    shortSquadName: "Real Valladolid",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/690_Real_Valladolid_CF.png"
  },
  {
    squadId: 6059,
    squadName: "CD Leganes",
    shortSquadName: "CD Leganes",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/712_CD_Leganés.png"
  },
  {
    squadId: 6098,
    squadName: "Kalmar FF",
    shortSquadName: "Kalmar FF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Kalmar_FF.png"
  },
  {
    squadId: 6207,
    squadName: "Molde FK",
    shortSquadName: "Molde FK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7098_Molde_FK.png"
  },
  {
    squadId: 6249,
    squadName: "Bayer 04 Leverkusen",
    shortSquadName: "B. Leverkusen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1260_Bayer_04_Leverkusen.png"
  },
  {
    squadId: 6306,
    squadName: "Hibernian FC",
    shortSquadName: "Hibernian FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Hibernian_FC.png"
  },
  {
    squadId: 6499,
    squadName: "Accrington Stanley",
    shortSquadName: "Accrington",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Accrington_Stanley.png"
  },
  {
    squadId: 6571,
    squadName: "US Salernitana 1919",
    shortSquadName: "Salernitana",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1865_US_Salernitana_1919.png"
  },
  {
    squadId: 7354,
    squadName: "Forest Green Rovers",
    shortSquadName: "Forest Green",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Forest_Green_Rovers.png"
  },
  {
    squadId: 7368,
    squadName: "Nueva Esparta F.C",
    shortSquadName: "Nueva Esparta F.C",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/58948_Nueva_Esparta_F.C.png"
  },
  {
    squadId: 7390,
    squadName: "Club Atletico Lanus",
    shortSquadName: "Lanus",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12063_Club_Atlético_Lanús.png"
  },
  {
    squadId: 7633,
    squadName: "Real Salt Lake City",
    shortSquadName: "Salt Lake",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2117_Real_Salt_Lake_City.png"
  },
  {
    squadId: 7819,
    squadName: "Sarpsborg 08 FF",
    shortSquadName: "Sarpsborg 08",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1472_Sarpsborg_08_FF.png"
  },
  {
    squadId: 7845,
    squadName: "CF Pachuca",
    shortSquadName: "Pachuca",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15413_CF_Pachuca.png"
  },
  {
    squadId: 7853,
    squadName: "Toronto FC",
    shortSquadName: "Toronto",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2102_Toronto_FC.png"
  },
  {
    squadId: 8077,
    squadName: "Angers SCO",
    shortSquadName: "Angers SCO",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3799_Angers_SCO.jpg"
  },
  {
    squadId: 8110,
    squadName: "Hertha BSC",
    shortSquadName: "Hertha BSC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2457_Hertha_BSC.png"
  },
  {
    squadId: 8239,
    squadName: "Brentford FC",
    shortSquadName: "Brentford",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1153_Brentford_FC.png"
  },
  {
    squadId: 8498,
    squadName: "Odense Boldklub",
    shortSquadName: "Odense BK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1412_Odense_Boldklub.png"
  },
  {
    squadId: 8934,
    squadName: "FC Sion",
    shortSquadName: "FC Sion",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/6421_FC_Sion.png"
  },
  {
    squadId: 9029,
    squadName: "FC Ingolstadt 04",
    shortSquadName: "FC Ingolstadt",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2579_FC_Ingolstadt_04.png"
  },
  {
    squadId: 9150,
    squadName: "Venezia FC",
    shortSquadName: "Venezia",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3191_Venezia_FC.svg"
  },
  {
    squadId: 9276,
    squadName: "CF Monterrey",
    shortSquadName: "Monterrey",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2267_CF_Monterrey.png"
  },
  {
    squadId: 9385,
    squadName: "Sporting Kansas City",
    shortSquadName: "Kansas City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7849_Sporting_Kansas_City.png"
  },
  {
    squadId: 9451,
    squadName: "Hamilton Academical FC",
    shortSquadName: "Hamilton Acad.",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Hamilton_Academical.png"
  },
  {
    squadId: 9954,
    squadName: "Sparta Rotterdam",
    shortSquadName: "Sparta R.",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Sparta_Rotterdam.png"
  },
  {
    squadId: 10065,
    squadName: "Partick Thistle FC",
    shortSquadName: "Partick Thistle",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2195_Partick_Thistle_FC.png"
  },
  {
    squadId: 10088,
    squadName: "RB Leipzig",
    shortSquadName: "RB Leipzig",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1261_RB_Leipzig.png"
  },
  {
    squadId: 10158,
    squadName: "Toulouse FC",
    shortSquadName: "Toulouse FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3780_FC_Toulouse.png"
  },
  {
    squadId: 10223,
    squadName: "CD Castellon",
    shortSquadName: "Castellon",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_3039_CD_Castellon.png"
  },
  {
    squadId: 10317,
    squadName: "New York Red Bulls",
    shortSquadName: "New York",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2112_New_York_Red_Bulls.png"
  },
  {
    squadId: 10422,
    squadName: "Vancouver Whitecaps FC",
    shortSquadName: "Vancouver",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15015_Vancouver_Whitecaps_FC.png"
  },
  {
    squadId: 10541,
    squadName: "USL Dunkerque",
    shortSquadName: "USL Dunkerque",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3904_USL_Dunkerque.png"
  },
  {
    squadId: 10774,
    squadName: "IFK Varnamo",
    shortSquadName: "Varnamo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/6769_IFK_Värnamo.png"
  },
  {
    squadId: 10781,
    squadName: "Kristiansund BK",
    shortSquadName: "Kristiansund",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1477_Kristiansund_BK.png"
  },
  {
    squadId: 11034,
    squadName: "IF Elfsborg",
    shortSquadName: "Elfsborg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_IF_Elfsborg.png"
  },
  {
    squadId: 11087,
    squadName: "SK Brann",
    shortSquadName: "Brann",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7095_SK_Brann.png"
  },
  {
    squadId: 11256,
    squadName: "Club Guarani",
    shortSquadName: "Guarani",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_11256_Club_Guarani.png"
  },
  {
    squadId: 11474,
    squadName: "Exeter City",
    shortSquadName: "Exeter City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1187_Exeter_City.png"
  },
  {
    squadId: 11544,
    squadName: "Grasshopper Club Zurich",
    shortSquadName: "Grasshoppers",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1936_Grasshopper_Club_Zurich.png"
  },
  {
    squadId: 11585,
    squadName: "Tromso IL",
    shortSquadName: "Tromso",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7089_Tromsø_IL.png"
  },
  {
    squadId: 11717,
    squadName: "Fehervar FC",
    shortSquadName: "Fehervar",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/10260_Fehérvár_FC.png"
  },
  {
    squadId: 11978,
    squadName: "FK Jerv",
    shortSquadName: "Jerv",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1484_FK_Jerv.gif"
  },
  {
    squadId: 12027,
    squadName: "CA Boston River",
    shortSquadName: "Boston River",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2875_Club_Atletico_Boston_River.png"
  },
  {
    squadId: 12098,
    squadName: "CD Santa Clara",
    shortSquadName: "Santa Clara",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/9634_CD_Santa_Clara.png"
  },
  {
    squadId: 12139,
    squadName: "Budapest Honved FC",
    shortSquadName: "Honved",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/10194_Budapest_Honvéd_FC.png"
  },
  {
    squadId: 12156,
    squadName: "Charlton Athletic",
    shortSquadName: "Charlton",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Charlton_Athletic.png"
  },
  {
    squadId: 12173,
    squadName: "Barnsley FC",
    shortSquadName: "Barnsley FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1158_Barnsley_FC.png"
  },
  {
    squadId: 12294,
    squadName: "Olympique de Marseille",
    shortSquadName: "Olympique Marseille",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1964_Olympique_Marseille.png"
  },
  {
    squadId: 12598,
    squadName: "VfB Stuttgart",
    shortSquadName: "VfB Stuttgart",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1273_VfB_Stuttgart.png"
  },
  {
    squadId: 12612,
    squadName: "Hammarby IF",
    shortSquadName: "Hammarby",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/6710_Hammarby_IF.png"
  },
  {
    squadId: 12686,
    squadName: "FC Nordsjaelland",
    shortSquadName: "Nordsjaelland",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7458_Nordsjelland.png"
  },
  {
    squadId: 13058,
    squadName: "Feyenoord Rotterdam",
    shortSquadName: "Feyenoord",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1533_Feyenoord_Rotterdam.png"
  },
  {
    squadId: 13371,
    squadName: "NK Tabor Sezana",
    shortSquadName: "Tabor Sezana",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/11971_MND_Tabor_Sežana.png"
  },
  {
    squadId: 13404,
    squadName: "Houston Dynamo FC",
    shortSquadName: "Houston",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7944_Houston_Dynamo_FC.png"
  },
  {
    squadId: 13510,
    squadName: "FC Utrecht",
    shortSquadName: "FC Utrecht",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/17_FC_Utrecht.png"
  },
  {
    squadId: 13679,
    squadName: "FC Lausanne-Sport",
    shortSquadName: "Lausanne-Sport",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/6434_FC_Lausanne-Sport.png"
  },
  {
    squadId: 13756,
    squadName: "Debreceni VSC",
    shortSquadName: "Debrecen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1634_Debreceni_VSC.png"
  },
  {
    squadId: 14079,
    squadName: "Fortaleza Esporte Clube",
    shortSquadName: "Fortaleza",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5663_Fortaleza_Esporte_Clube.png"
  },
  {
    squadId: 14291,
    squadName: "FK Sarajevo",
    shortSquadName: "FK Sarajevo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/9918_FK_Sarajevo.png"
  },
  {
    squadId: 14649,
    squadName: "SK Beveren",
    shortSquadName: "SK Beveren",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5104_SK_Beveren.png"
  },
  {
    squadId: 14651,
    squadName: "Stade Brestois 29",
    shortSquadName: "Stade Brestois",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3803_Brest.png"
  },
  {
    squadId: 14930,
    squadName: "Celaya FC",
    shortSquadName: "Celaya",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15426_Celaya_FC.png"
  },
  {
    squadId: 14994,
    squadName: "CD Leones Negros de la UdeG",
    shortSquadName: "Leones Negros",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15441_CD_Leones_Negros_de_la_UdeG.png"
  },
  {
    squadId: 15024,
    squadName: "Yverdon Sport FC",
    shortSquadName: "Yverdon Sport",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/6422_Yverdon_Sport_FC.png"
  },
  {
    squadId: 15052,
    squadName: "Sogndal IL",
    shortSquadName: "Sogndal",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7101_Sogndal_IL.png"
  },
  {
    squadId: 15130,
    squadName: "AIK Solna",
    shortSquadName: "AIK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1436_AIK_Solna.png"
  },
  {
    squadId: 15268,
    squadName: "Widzew Lodz",
    shortSquadName: "Widzew Lodz",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/13515_Widzew_Lodz.png"
  },
  {
    squadId: 15326,
    squadName: "Albion FC",
    shortSquadName: "Albion FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_15326_Albion_FC.png"
  },
  {
    squadId: 15469,
    squadName: "Club Nacional",
    shortSquadName: "Nacional",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15634_Club_Nacional.png"
  },
  {
    squadId: 15517,
    squadName: "Oud-Heverlee Leuven",
    shortSquadName: "OH Leuven",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5121_Oud-Heverlee_Leuven.png"
  },
  {
    squadId: 15549,
    squadName: "FC Basel 1893",
    shortSquadName: "FC Basel",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1932_FC_Basel_1893.png"
  },
  {
    squadId: 15741,
    squadName: "US Triestina",
    shortSquadName: "Triestina",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1905_US_Triestina.png"
  },
  {
    squadId: 15753,
    squadName: "FC Sudtirol",
    shortSquadName: "Sudtirol",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_15753_FC_Sudtirol.png"
  },
  {
    squadId: 15821,
    squadName: "Arminia Bielefeld",
    shortSquadName: "Arm. Bielefeld",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Arminia_Bielefeld.png"
  },
  {
    squadId: 15840,
    squadName: "Warta Poznan",
    shortSquadName: "Warta Poznan",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/13545_Warta_Poznan.png"
  },
  {
    squadId: 15862,
    squadName: "Universitario de Deportes",
    shortSquadName: "Universitario",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_15862_Universitario_de_Deportes.png"
  },
  {
    squadId: 15872,
    squadName: "Wigan Athletic",
    shortSquadName: "Wigan",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1163_Wigan_Athletic.png"
  },
  {
    squadId: 16046,
    squadName: "Olympique Lyonnais",
    shortSquadName: "Olympique Lyon",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3766_Olympique_Lyon.png"
  },
  {
    squadId: 16173,
    squadName: "SCR Altach",
    shortSquadName: "SCR Altach",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1946_SC_Rheindorf_Altach.png"
  },
  {
    squadId: 16210,
    squadName: "AeL Limassol",
    shortSquadName: "AeL Limassol",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2179_AEL_Limassol.png"
  },
  {
    squadId: 16232,
    squadName: "FC Girondins Bordeaux",
    shortSquadName: "G. Bordeaux",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3772_FC_Girondins_Bordeaux.png"
  },
  {
    squadId: 16247,
    squadName: "Club Athletico Paranaense",
    shortSquadName: "Athletico-PR",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5651_Club_Athletico_Paranaense.png"
  },
  {
    squadId: 16324,
    squadName: "Academia Puerto Cabello",
    shortSquadName: "Puerto Cabello",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_16324_Academia_Puerto_Cabello.png"
  },
  {
    squadId: 16335,
    squadName: "Beerschot V.A.",
    shortSquadName: "Beerschot V.A.",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5280_K._Beerschot_V.A..png"
  },
  {
    squadId: 16437,
    squadName: "Excelsior Rotterdam",
    shortSquadName: "Excelsior",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/18608_SBV_Excelsior_Rotterdam.png"
  },
  {
    squadId: 17053,
    squadName: "Oldham Athletic",
    shortSquadName: "Oldham Athletic",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Oldham_Athletic.png"
  },
  {
    squadId: 17288,
    squadName: "Riga FC",
    shortSquadName: "Riga",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2924_Riga_FC.png"
  },
  {
    squadId: 17325,
    squadName: "Maritimo La Guaira",
    shortSquadName: "Maritimo La Guaira",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15714_Marítimo_La_Guaira.png"
  },
  {
    squadId: 17331,
    squadName: "TOP Oss",
    shortSquadName: "TOP Oss",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1565_TOP_Oss.png"
  },
  {
    squadId: 17417,
    squadName: "BSC Young Boys",
    shortSquadName: "BSC Young Boys",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1934_BSC_Young_Boys.png"
  },
  {
    squadId: 17418,
    squadName: "Valerenga Fotball Elite",
    shortSquadName: "Valerenga",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7092_Vålerenga_Fotball.png"
  },
  {
    squadId: 17770,
    squadName: "Adelaide United",
    shortSquadName: "Adelaide United",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2482_Adelaide_United.png"
  },
  {
    squadId: 17795,
    squadName: "Eintracht Braunschweig",
    shortSquadName: "E. Braunschweig",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Eintracht_Braunschweig.png"
  },
  {
    squadId: 17825,
    squadName: "Hillerod Fodbold",
    shortSquadName: "Hillerod F",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7699_Hilleröd_Fodbold.png"
  },
  {
    squadId: 17923,
    squadName: "FC Eindhoven",
    shortSquadName: "FC Eindhoven",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/42_FC_Eindhoven.png"
  },
  {
    squadId: 18100,
    squadName: "Cracovia",
    shortSquadName: "Cracovia",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1598_Cracovia_Krakow.png"
  },
  {
    squadId: 18179,
    squadName: "SC Verl",
    shortSquadName: "SC Verl",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1395_SC_Verl.png"
  },
  {
    squadId: 18429,
    squadName: "Ebbsfleet United",
    shortSquadName: "Ebbsfleet",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1218_Ebbsfleet_United.png"
  },
  {
    squadId: 18516,
    squadName: "SV Wehen Wiesbaden",
    shortSquadName: "Wehen Wiesbaden",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1294_SV_Wehen_Wiesbaden.png"
  },
  {
    squadId: 18582,
    squadName: "Panathinaikos FC",
    shortSquadName: "Panathinaikos",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2022_Panathinaikos_Athens.png"
  },
  {
    squadId: 18651,
    squadName: "Rosenborg BK",
    shortSquadName: "Rosenborg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1464_Rosenborg_BK.png"
  },
  {
    squadId: 18894,
    squadName: "Brondby IF",
    shortSquadName: "Brondby IF",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1407_Brondby_IF.png"
  },
  {
    squadId: 19138,
    squadName: "RCD Mallorca",
    shortSquadName: "RCD Mallorca",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/686_RCD_Mallorca.png"
  },
  {
    squadId: 19251,
    squadName: "SV Waldhof Mannheim",
    shortSquadName: "W. Mannheim",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1370_SV_Waldhof_Mannheim.png"
  },
  {
    squadId: 19631,
    squadName: "FK Vojvodina Novi Sad",
    shortSquadName: "Vojvodina",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1660_FK_Vojvodina_Novi_Sad.png"
  },
  {
    squadId: 19775,
    squadName: "HB Koge",
    shortSquadName: "HB Koge",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_HB_Koge.png"
  },
  {
    squadId: 20016,
    squadName: "Motherwell FC",
    shortSquadName: "Motherwell FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Motherwell_FC.png"
  },
  {
    squadId: 20050,
    squadName: "Giresunspor",
    shortSquadName: "Giresunspor",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_20050_Giresunspor.png"
  },
  {
    squadId: 20144,
    squadName: "Plymouth Argyle",
    shortSquadName: "Plymouth",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1649_Plymouth_Argyle.png"
  },
  {
    squadId: 20454,
    squadName: "Legia Warszawa",
    shortSquadName: "Legia Warszawa",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1594_Legia_Warszawa.png"
  },
  {
    squadId: 20482,
    squadName: "FC Noah Yerevan",
    shortSquadName: "FC Noah",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_20482_FC_Noah_Yerevan.png"
  },
  {
    squadId: 20830,
    squadName: "SV Sandhausen",
    shortSquadName: "SV Sandhausen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_SV_Sandhausen.png"
  },
  {
    squadId: 21139,
    squadName: "Olympiacos Piraeus",
    shortSquadName: "Olympiacos",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12509_Olympiacos_Piraeus.png"
  },
  {
    squadId: 21408,
    squadName: "Utenis Utena",
    shortSquadName: "Utenis Utena",
    squadLogo: null
  },
  {
    squadId: 21425,
    squadName: "FC St. Gallen 1879",
    shortSquadName: "FC St. Gallen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1935_FC_St._Gallen_1879.png"
  },
  {
    squadId: 22011,
    squadName: "Puebla FC",
    shortSquadName: "Puebla FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15414_Puebla_FC.png"
  },
  {
    squadId: 22030,
    squadName: "Stade Lavallois",
    shortSquadName: "Stade Lavallois",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3801_Stade_Lavallois.png"
  },
  {
    squadId: 22110,
    squadName: "Le Mans FC",
    shortSquadName: "Le Mans FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3786_Le_Mans.png"
  },
  {
    squadId: 22196,
    squadName: "SpVgg Greuther Furth",
    shortSquadName: "Greuther Furth",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2469_SpVgg_Greuther_Fürth.png"
  },
  {
    squadId: 22240,
    squadName: "PAOK Thessaloniki",
    shortSquadName: "PAOK Salonika",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2021_PAOK_Thessaloniki.png"
  },
  {
    squadId: 22352,
    squadName: "Cruzeiro Esporte Clube",
    shortSquadName: "Cruzeiro",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5640_Cruzeiro_Esporte_Clube.png"
  },
  {
    squadId: 22392,
    squadName: "Maccabi Tel Aviv",
    shortSquadName: "M. Tel Aviv",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/13270_Maccabi_Tel_Aviv.png"
  },
  {
    squadId: 22514,
    squadName: "CF America",
    shortSquadName: "America",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15405_América.png"
  },
  {
    squadId: 22613,
    squadName: "Kuopion Palloseura",
    shortSquadName: "KuPS",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1499_Kuopion_Palloseura.png"
  },
  {
    squadId: 22719,
    squadName: "Clube de Regatas Vasco da Gama",
    shortSquadName: "Vasco da Gama",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5657_Club_de_Regatas_Vasco_da_Gama.png"
  },
  {
    squadId: 22720,
    squadName: "Korona Kielce",
    shortSquadName: "Korona Kielce",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1605_Korona_Kielce.png"
  },
  {
    squadId: 22740,
    squadName: "Birmingham City",
    shortSquadName: "Birmingham",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Birmingham_City.png"
  },
  {
    squadId: 22788,
    squadName: "Red Bull Bragantino",
    shortSquadName: "RB Bragantino",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5685_Red_Bull_Bragantino.png"
  },
  {
    squadId: 22827,
    squadName: "HNK Hajduk Split",
    shortSquadName: "Hajduk Split",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2165_HNK_Hajduk_Split.png"
  },
  {
    squadId: 22866,
    squadName: "KMSK Deinze",
    shortSquadName: "KMSK Deinze",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2368_KMSK_Deinze.png"
  },
  {
    squadId: 22922,
    squadName: "FK Zeleziarne Podbrezova",
    shortSquadName: "Podbrezova",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/14379_FK_Zeleziarne_Podbrezova.png"
  },
  {
    squadId: 22992,
    squadName: "Pisa Sporting Club",
    shortSquadName: "Pisa",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3207_AC_Pisa_1909.png"
  },
  {
    squadId: 23117,
    squadName: "FC Versailles 78",
    shortSquadName: "FC Versailles",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/4213_FC_Versailles_78.png"
  },
  {
    squadId: 23506,
    squadName: "Atlas Guadalajara",
    shortSquadName: "Atlas",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15407_Atlas.png"
  },
  {
    squadId: 23588,
    squadName: "SC Heerenveen",
    shortSquadName: "Heerenveen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1537_SC_Heerenveen.png"
  },
  {
    squadId: 23767,
    squadName: "Crawley Town",
    shortSquadName: "Crawley Town",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1198_Crawley_Town.png"
  },
  {
    squadId: 23873,
    squadName: "Botev Plovdiv",
    shortSquadName: "Botev Plovdiv",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2039_Botev_Plovdiv.png"
  },
  {
    squadId: 24099,
    squadName: "Valenciennes FC",
    shortSquadName: "Valenciennes FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3814_Valenciennes_FC.png"
  },
  {
    squadId: 24198,
    squadName: "Esporte Clube Bahia",
    shortSquadName: "EC Bahia",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5677_Esporte_Clube_Bahia.png"
  },
  {
    squadId: 24432,
    squadName: "Pafos FC",
    shortSquadName: "Pafos FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/34760_Pafos_FC.png"
  },
  {
    squadId: 24711,
    squadName: "Urawa Red Diamonds",
    shortSquadName: "Urawa Reds",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2407_Urawa_Red_Diamonds.png"
  },
  {
    squadId: 24755,
    squadName: "Milton Keynes Dons",
    shortSquadName: "MK Dons",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1164_Milton_Keynes_Dons.png"
  },
  {
    squadId: 24806,
    squadName: "Deportivo de La Coruna",
    shortSquadName: "Dep. La Coruna",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1721_Deportivo_de_La_Coruna.png"
  },
  {
    squadId: 24973,
    squadName: "Queretaro FC",
    shortSquadName: "Queretaro FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15421_Querétaro_FC.png"
  },
  {
    squadId: 25037,
    squadName: "Montevideo City Torque",
    shortSquadName: "Montevideo City Torque",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/23497_Montevideo_City_Torque.png"
  },
  {
    squadId: 25054,
    squadName: "Preussen Munster",
    shortSquadName: "Pr. Munster",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Preussen_Munster.png"
  },
  {
    squadId: 25090,
    squadName: "Cimarrones de Sonora",
    shortSquadName: "Cimarrones",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/26089_Cimarrones_de_Sonora.png"
  },
  {
    squadId: 25123,
    squadName: "AeZ Zakakiou",
    shortSquadName: "AeZ Zakakiou",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/10720_AEZ_Zakakiou.png"
  },
  {
    squadId: 25285,
    squadName: "Levante UD",
    shortSquadName: "Levante",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Levante_UD.png"
  },
  {
    squadId: 25519,
    squadName: "Rapid Vienna",
    shortSquadName: "Rapid Vienna",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1943_Rapid_Vienna.png"
  },
  {
    squadId: 25579,
    squadName: "IK Brage",
    shortSquadName: "Brage",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2471_IK_Brage.png"
  },
  {
    squadId: 25671,
    squadName: "AC Reggiana 1919",
    shortSquadName: "Reggiana",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3170_AC_Reggiana_1919.png"
  },
  {
    squadId: 26054,
    squadName: "CFR Cluj",
    shortSquadName: "CFR Cluj",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2341_CFR_Cluj.png"
  },
  {
    squadId: 26202,
    squadName: "Ludogorets Razgrad",
    shortSquadName: "Ludogorets",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/9311_Ludogorets.png"
  },
  {
    squadId: 26255,
    squadName: "Alianza FC",
    shortSquadName: "Alianza FC",
    squadLogo: null
  },
  {
    squadId: 26329,
    squadName: "Trabzonspor",
    shortSquadName: "Trabzonspor",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2006_Trabzonspor.png"
  },
  {
    squadId: 26333,
    squadName: "SV Ried",
    shortSquadName: "SV Ried",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_SV_Ried.png"
  },
  {
    squadId: 26353,
    squadName: "SV Darmstadt 98",
    shortSquadName: "Darmstadt 98",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2520_SV_Darmstadt_98.png"
  },
  {
    squadId: 26367,
    squadName: "Santos Laguna",
    shortSquadName: "Santos Laguna",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2268_Santos_Laguna.png"
  },
  {
    squadId: 26463,
    squadName: "CD Hermanos Colmenarez",
    shortSquadName: "Colmenarez",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_26463_CD_Hermanos_Colmenarez.png"
  },
  {
    squadId: 26667,
    squadName: "Coritiba Foot Ball Club",
    shortSquadName: "Coritiba FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2625_Coritiba_Foot_Ball_Club.png"
  },
  {
    squadId: 26895,
    squadName: "Jagiellonia Bialystok",
    shortSquadName: "Jagiellonia",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1597_Jagiellonia_Bialystok.png"
  },
  {
    squadId: 27176,
    squadName: "KV Mechelen",
    shortSquadName: "KV Mechelen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Yellow-Red_KV_Mechelen.png"
  },
  {
    squadId: 27180,
    squadName: "NS Mura",
    shortSquadName: "NS Mura",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/31278_NŠ_Mura.png"
  },
  {
    squadId: 27570,
    squadName: "Puskas Akademia FC",
    shortSquadName: "Puskas AFC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1630_Puskas_Akademia_FC.png"
  },
  {
    squadId: 27833,
    squadName: "America Futebol Clube (MG)",
    shortSquadName: "America-MG",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5673_América_Futebol_Clube_(MG).png"
  },
  {
    squadId: 27984,
    squadName: "Ranheim IL",
    shortSquadName: "Ranheim",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7193_Ranheim_IL.png"
  },
  {
    squadId: 28108,
    squadName: "FC Wil 1900",
    shortSquadName: "FC Wil 1900",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2361_FC_Wil_1900.png"
  },
  {
    squadId: 28130,
    squadName: "IF Brommapojkarna",
    shortSquadName: "Brommapojkarna",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_IF_Brommapojkarna.png"
  },
  {
    squadId: 28158,
    squadName: "Aalborg BK",
    shortSquadName: "Aalborg BK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Aalborg_BK.png"
  },
  {
    squadId: 28212,
    squadName: "RC Strasbourg Alsace",
    shortSquadName: "R. Strasbourg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1981_RC_Strasbourg_Alsace.png"
  },
  {
    squadId: 28372,
    squadName: "Club Atletico Morelia",
    shortSquadName: "Morelia",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15411_Club_Atlético_Morelia.png"
  },
  {
    squadId: 28535,
    squadName: "Naestved Boldklub",
    shortSquadName: "Naestved BK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7475_Naestved_Boldklub.jpg"
  },
  {
    squadId: 28879,
    squadName: "Athletic Bilbao",
    shortSquadName: "Athletic",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/678_Athletic_Bilbao.png"
  },
  {
    squadId: 28997,
    squadName: "Aberdeen FC",
    shortSquadName: "Aberdeen FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2190_Aberdeen_FC.png"
  },
  {
    squadId: 29065,
    squadName: "Club Sporting Cristal",
    shortSquadName: "Sport. Cristal",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15532_Club_Sporting_Cristal.png"
  },
  {
    squadId: 29178,
    squadName: "Melbourne City FC",
    shortSquadName: "Melbourne City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2473_Melbourne_City_FC.png"
  },
  {
    squadId: 29198,
    squadName: "FC Den Bosch",
    shortSquadName: "FC Den Bosch",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Den_Bosch.png"
  },
  {
    squadId: 29285,
    squadName: "BK Hacken",
    shortSquadName: "Hacken",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/6736_BK_Häcken.png"
  },
  {
    squadId: 29306,
    squadName: "Mineros de Zacatecas",
    shortSquadName: "Min. Zacatecas",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/30286_Mineros_de_Zacatecas.png"
  },
  {
    squadId: 29455,
    squadName: "Nottingham Forest",
    shortSquadName: "Nottm Forest",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1152_Nottingham_Forest.png"
  },
  {
    squadId: 29529,
    squadName: "Cheltenham Town",
    shortSquadName: "Cheltenham",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1197_Cheltenham_Town.png"
  },
  {
    squadId: 29580,
    squadName: "NK Bravo",
    shortSquadName: "NK Bravo",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/35651_AŠK_Bravo_Publikum.png"
  },
  {
    squadId: 29704,
    squadName: "NAC Breda",
    shortSquadName: "NAC Breda",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_NAC_Breda.png"
  },
  {
    squadId: 29747,
    squadName: "Ajax Amsterdam",
    shortSquadName: "Ajax",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/9_Ajax_Amsterdam.png"
  },
  {
    squadId: 29766,
    squadName: "Gornik Zabrze",
    shortSquadName: "Gornik Zabrze",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1609_Gornik_Zabrze.png"
  },
  {
    squadId: 29777,
    squadName: "LR Vicenza",
    shortSquadName: "LR Vicenza",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3186_LR_Vicenza_Virtus.png"
  },
  {
    squadId: 29796,
    squadName: "Alebrijes de Oaxaca",
    shortSquadName: "Oaxaca",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/25865_Alebrijes_de_Oaxaca.png"
  },
  {
    squadId: 30012,
    squadName: "Crewe Alexandra",
    shortSquadName: "Crewe Alexandra",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1202_Crewe_Alexandra.png"
  },
  {
    squadId: 30168,
    squadName: "Deportivo Saprissa",
    shortSquadName: "Saprissa",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_30168_Deportivo_Saprissa.png"
  },
  {
    squadId: 30352,
    squadName: "FC Vizela",
    shortSquadName: "Vizela",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/9683_FC_Vizela.png"
  },
  {
    squadId: 30411,
    squadName: "FK TSC Backa Topola",
    shortSquadName: "FK TSC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_30411_FK_TSC_Backa_Topola.png"
  },
  {
    squadId: 30436,
    squadName: "Dijon FCO",
    shortSquadName: "Dijon",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1976_FCO_Dijon.png"
  },
  {
    squadId: 30440,
    squadName: "CA Independiente",
    shortSquadName: "Independiente",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_30440_CA_Independiente.png"
  },
  {
    squadId: 30723,
    squadName: "Borussia Dortmund",
    shortSquadName: "Bor. Dortmund",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1259_Borussia_Dortmund.png"
  },
  {
    squadId: 30734,
    squadName: "Altinordu FK",
    shortSquadName: "Altinordu",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2830_Altinordu.png"
  },
  {
    squadId: 30736,
    squadName: "FC Sochaux-Montbeliard",
    shortSquadName: "FC Sochaux",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3768_FC_Sochaux-Montbéliard.png"
  },
  {
    squadId: 30849,
    squadName: "Atlanta United FC",
    shortSquadName: "Atlanta",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2103_Atlanta_United_FC.png"
  },
  {
    squadId: 31138,
    squadName: "Club Leon FC",
    shortSquadName: "Leon",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15424_Club_León_FC.png"
  },
  {
    squadId: 31205,
    squadName: "SG Dynamo Dresden",
    shortSquadName: "Dynamo Dresden",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2478_SG_Dynamo_Dresden.png"
  },
  {
    squadId: 31340,
    squadName: "Casa Pia AC",
    shortSquadName: "Casa Pia",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/9708_Casa_Pia_AC.png"
  },
  {
    squadId: 31367,
    squadName: "Sheffield United",
    shortSquadName: "Sheff Utd",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1159_Sheffield_United.png"
  },
  {
    squadId: 31484,
    squadName: "Deportivo Guadalajara",
    shortSquadName: "Chivas",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15409_Deportivo_Guadalajara.png"
  },
  {
    squadId: 31536,
    squadName: "Aris Limassol",
    shortSquadName: "Aris Limassol",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_31536_Aris_Limassol.png"
  },
  {
    squadId: 31588,
    squadName: "Rangers FC",
    shortSquadName: "Rangers",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2188_Rangers_FC.png"
  },
  {
    squadId: 32429,
    squadName: "Eintracht Frankfurt",
    shortSquadName: "E. Frankfurt",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1268_Eintracht_Frankfurt.png"
  },
  {
    squadId: 32515,
    squadName: "IK Sirius",
    shortSquadName: "Sirius",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1446_IK_Sirius.png"
  },
  {
    squadId: 32566,
    squadName: "Vaasan Palloseura",
    shortSquadName: "VPS",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1503_Vaasan_Palloseura.png"
  },
  {
    squadId: 32694,
    squadName: "Sacramento Republic FC",
    shortSquadName: "Sacramento FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/28727_Sacramento_Republic.png"
  },
  {
    squadId: 32704,
    squadName: "UNAM Pumas",
    shortSquadName: "UNAM Pumas",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15420_UNAM_Pumas.png"
  },
  {
    squadId: 32753,
    squadName: "Deportivo Toluca",
    shortSquadName: "Toluca",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15418_Deportivo_Toluca.png"
  },
  {
    squadId: 32775,
    squadName: "FC Nantes",
    shortSquadName: "FC Nantes",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3770_FC_Nantes.png"
  },
  {
    squadId: 33026,
    squadName: "FC Rapid Bucharest",
    shortSquadName: "Rapid",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/11566_FC_Rapid_1923.png"
  },
  {
    squadId: 33048,
    squadName: "RSC Charleroi",
    shortSquadName: "RSC Charleroi",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1580_RSC_Charleroi.png"
  },
  {
    squadId: 33192,
    squadName: "Sligo Rovers",
    shortSquadName: "Sligo Rovers",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2097_Sligo_Rovers.png"
  },
  {
    squadId: 33217,
    squadName: "Real CD Espana",
    shortSquadName: "Real Espana",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/17385_Real_CD_España.png"
  },
  {
    squadId: 33438,
    squadName: "FC Thun",
    shortSquadName: "FC Thun",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1941_FC_Thun.png"
  },
  {
    squadId: 33615,
    squadName: "Zalaegerszegi TE FC",
    shortSquadName: "Zalaegerszeg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_33615_Zalaegerszegi_TE_FC.png"
  },
  {
    squadId: 33648,
    squadName: "Atletico Nacional",
    shortSquadName: "Atl. Nacional",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12391_Atlético_Nacional.png"
  },
  {
    squadId: 33794,
    squadName: "Sport Club Internacional",
    shortSquadName: "Internacional",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2611_Sport_Club_Internacional.png"
  },
  {
    squadId: 33813,
    squadName: "Karlsruher SC",
    shortSquadName: "Karlsruher SC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Karlsruher_SC.png"
  },
  {
    squadId: 33927,
    squadName: "UD Almeria",
    shortSquadName: "UD Almeria",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/708_UD_Almería.png"
  },
  {
    squadId: 34038,
    squadName: "Club Cerro Porteno",
    shortSquadName: "Cerro Porteno",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_34038_Club_Cerro_Porteno.png"
  },
  {
    squadId: 34776,
    squadName: "Goztepe",
    shortSquadName: "Goztepe",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2012_Goztepe.png"
  },
  {
    squadId: 34923,
    squadName: "Harrogate Town",
    shortSquadName: "Harrogate Town",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2318_Harrogate_Town.png"
  },
  {
    squadId: 35045,
    squadName: "Vitesse Arnhem",
    shortSquadName: "Vitesse",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/24_Vitesse_Arnhem.png"
  },
  {
    squadId: 35103,
    squadName: "Standard Liege",
    shortSquadName: "Standard Liege",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1574_Standard_Liege.png"
  },
  {
    squadId: 35181,
    squadName: "SpVgg Unterhaching",
    shortSquadName: "Unterhaching",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2471_SpVgg_Unterhaching.png"
  },
  {
    squadId: 35187,
    squadName: "Virtus Entella",
    shortSquadName: "Virtus Entella",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3404_Virtus_Entella.png"
  },
  {
    squadId: 35255,
    squadName: "CA Sarmiento (Junin)",
    shortSquadName: "Sarmiento Junin",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12095_CA_Sarmiento_(Junin).png"
  },
  {
    squadId: 35839,
    squadName: "Sociedade Esportiva Palmeiras",
    shortSquadName: "Palmeiras",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/5646_Sociedade_Esportiva_Palmeiras.png"
  },
  {
    squadId: 36192,
    squadName: "Tigres UANL",
    shortSquadName: "Tigres UANL",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15417_Tigres_UANL.png"
  },
  {
    squadId: 36491,
    squadName: "FK Vozdovac",
    shortSquadName: "Vozdovac",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1662_FK_Vozdovac.png"
  },
  {
    squadId: 36627,
    squadName: "FC Metz",
    shortSquadName: "FC Metz",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3777_FC_Metz.png"
  },
  {
    squadId: 36736,
    squadName: "Olimpia Asuncion",
    shortSquadName: "Olimpia",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15486_Olimpia.png"
  },
  {
    squadId: 36837,
    squadName: "AJ Auxerre",
    shortSquadName: "AJ Auxerre",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3769_AJ_Auxerre.png"
  },
  {
    squadId: 36860,
    squadName: "Western Sydney Wanderers",
    shortSquadName: "Western Sydney",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2476_Western_Sydney_Wanderers.png"
  },
  {
    squadId: 37066,
    squadName: "SSV Ulm 1846",
    shortSquadName: "SSV Ulm 1846",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2508_SSV_Ulm_1846.png"
  },
  {
    squadId: 37217,
    squadName: "Deportes Tolima",
    shortSquadName: "Deportes Tolima",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/12396_Deportes_Tolima.png"
  },
  {
    squadId: 37333,
    squadName: "NK Istra 1961",
    shortSquadName: "NK Istra",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2169_NK_Istra_1961.png"
  },
  {
    squadId: 37696,
    squadName: "Wellington Phoenix",
    shortSquadName: "Wellington",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/8341_Wellington_Phoenix.png"
  },
  {
    squadId: 37706,
    squadName: "Dundee United FC",
    shortSquadName: "Dundee United",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/8513_Dundee_United_FC.png"
  },
  {
    squadId: 37760,
    squadName: "Carabobo FC",
    shortSquadName: "Carabobo FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15681_Carabobo_FC.png"
  },
  {
    squadId: 38553,
    squadName: "Aston Villa",
    shortSquadName: "Aston Villa",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1138_Aston_Villa.png"
  },
  {
    squadId: 38564,
    squadName: "FC Volendam",
    shortSquadName: "FC Volendam",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_FC_Volendam.png"
  },
  {
    squadId: 38623,
    squadName: "Pogon Szczecin",
    shortSquadName: "Pogon Szczecin",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1599_Pogon_Szczecin.png"
  },
  {
    squadId: 38747,
    squadName: "Deportivo Tachira",
    shortSquadName: "Tachira",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_38747_Deportivo_Tachira.png"
  },
  {
    squadId: 39176,
    squadName: "Leeds United",
    shortSquadName: "Leeds",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1150_Leeds_United.png"
  },
  {
    squadId: 39191,
    squadName: "LKS Lodz",
    shortSquadName: "LKS Lodz",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/13528_Łódzki_KS.png"
  },
  {
    squadId: 39292,
    squadName: "Liverpool FC Montevideo",
    shortSquadName: "Liverpool FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/15632_Liverpool_FC_Montevideo.png"
  },
  {
    squadId: 39339,
    squadName: "Villarreal CF",
    shortSquadName: "Villarreal",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1714_Villarreal_CF.png"
  },
  {
    squadId: 39668,
    squadName: "SKN St. Polten",
    shortSquadName: "SKN St. Polten",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/8756_SKN_St._Pölten.png"
  },
  {
    squadId: 39789,
    squadName: "Como 1907",
    shortSquadName: "Como",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_39789_Como_1907.png"
  },
  {
    squadId: 39818,
    squadName: "Al-Qadsiah FC",
    shortSquadName: "Al-Qadsiah",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_39818_Al_Qadisiyah_Al_Khubar.png"
  },
  {
    squadId: 39885,
    squadName: "AP Brera Strumica",
    shortSquadName: "Brera Strumica",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/39679_AP_Brera.png"
  },
  {
    squadId: 40170,
    squadName: "MSV Duisburg",
    shortSquadName: "MSV Duisburg",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1292_MSV_Duisburg.png"
  },
  {
    squadId: 40208,
    squadName: "Tlaxcala FC",
    shortSquadName: "Tlaxcala",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/31554_Tlaxcala_FC.png"
  },
  {
    squadId: 40356,
    squadName: "SV Werder Bremen",
    shortSquadName: "Werder Bremen",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1269_SV_Werder_Bremen.png"
  },
  {
    squadId: 40409,
    squadName: "Oxford United",
    shortSquadName: "Oxford United",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/1683_Oxford_United.png"
  },
  {
    squadId: 40508,
    squadName: "LASK",
    shortSquadName: "LASK",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/8754_LASK.png"
  },
  {
    squadId: 40635,
    squadName: "Ross County FC",
    shortSquadName: "Ross County",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2197_Ross_County_FC.png"
  },
  {
    squadId: 40692,
    squadName: "Columbus Crew",
    shortSquadName: "Columbus",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/7855_Columbus_Crew.png"
  },
  {
    squadId: 41304,
    squadName: "Cape Town City FC",
    shortSquadName: "Cape Town City",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/16369_Cape_Town_City_FC.png"
  },
  {
    squadId: 41360,
    squadName: "EA Guingamp",
    shortSquadName: "Guingamp",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/3785_EA_Guingamp.png"
  },
  {
    squadId: 41660,
    squadName: "VfL Osnabruck",
    shortSquadName: "VfL Osnabruck",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/2501_VfL_Osnabrück.jpg"
  },
  {
    squadId: 41684,
    squadName: "Twente Enschede FC",
    shortSquadName: "Twente FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1540_FC_Twente_Enschede.png"
  },
  {
    squadId: 41692,
    squadName: "Seattle Sounders FC",
    shortSquadName: "Seattle",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_2106_Seattle_Sounders_FC.png"
  },
  {
    squadId: 41761,
    squadName: "Leyton Orient",
    shortSquadName: "Leyton Orient",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_Leyton_Orient.png"
  },
  {
    squadId: 42063,
    squadName: "Ujpest FC",
    shortSquadName: "Ujpest FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/logo_1633_Ujpest_FC.png"
  },
  {
    squadId: 42740,
    squadName: "Memphis 901 FC",
    shortSquadName: "Memphis 901",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/63760_Memphis_901_FC.png"
  },
  {
    squadId: 42796,
    squadName: "HFX Wanderers FC",
    shortSquadName: "HFX Wanderers",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/64338_HFX_Wanderers.png"
  },
  {
    squadId: 42798,
    squadName: "Cavalry FC",
    shortSquadName: "Cavalry FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/64336_Cavalry_FC.png"
  },
  {
    squadId: 43155,
    squadName: "New Mexico United",
    shortSquadName: "New Mexico Utd.",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/63763_New_Mexico_United.png"
  },
  {
    squadId: 50113,
    squadName: "Inter Miami CF",
    shortSquadName: "Miami",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-69261_Inter_Miami_CF.png"
  },
  {
    squadId: 200001,
    squadName: "Mazatlan FC",
    shortSquadName: "Mazatlan FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-82696_Mazatlán_FC.png"
  },
  {
    squadId: 200004,
    squadName: "Charlotte FC",
    shortSquadName: "Charlotte",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-78435_Charlotte_FC.png"
  },
  {
    squadId: 200102,
    squadName: "FC KTP",
    shortSquadName: "FC KTP",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/https://tmssl.akamaized.net/images/wappen/head/7315.png?lm=1443854478"
  },
  {
    squadId: 200109,
    squadName: "Cancun FC",
    shortSquadName: "Cancun FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/83082_Cancún_FC.png"
  },
  {
    squadId: 200113,
    squadName: "1.FC Phonix Lubeck",
    shortSquadName: "Phonix Lubeck",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/https://tmssl.akamaized.net/images/wappen/head/2773.png?lm=1593726229"
  },
  {
    squadId: 224003,
    squadName: "LIGA MX",
    shortSquadName: "LIGA MX",
    squadLogo: null
  },
  {
    squadId: 225010,
    squadName: "CD Real Cartagena",
    shortSquadName: "Real Cartagena",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-13046_CD_Real_Cartagena.png"
  },
  {
    squadId: 225037,
    squadName: "Atletico La Paz",
    shortSquadName: "Atletico La Paz",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/100124_Atlético_La_Paz.png"
  },
  {
    squadId: 225430,
    squadName: "Dvo. Rayo Zuliano",
    shortSquadName: "Rayo Zuliano",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-93338_Dvo._Rayo_Zuliano.png"
  },
  {
    squadId: 225490,
    squadName: "FA El Vigia Futbol Club",
    shortSquadName: "FA El Vigia Futbol Club",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-22904_FA_El_Vigía_Fútbol_Club.png"
  },
  {
    squadId: 225491,
    squadName: "Pegia 2014",
    shortSquadName: "Pegia 2014",
    squadLogo: null
  },
  {
    squadId: 225557,
    squadName: "Clube Atletico Mineiro",
    shortSquadName: "Atletico-MG",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-330_Clube_Atlético_Mineiro.png"
  },
  {
    squadId: 225590,
    squadName: "Deportivo Miranda FC",
    shortSquadName: "Deportivo Miranda FC",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/29750_Deportivo_Miranda_FC.png"
  },
  {
    squadId: 225593,
    squadName: "Heroes de Falcon",
    shortSquadName: "Heroes de Falcon",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/100079_Héroes_de_Falcón.png"
  },
  {
    squadId: 225694,
    squadName: "Lyn 1896 FK",
    shortSquadName: "Lyn",
    squadLogo: "https://transferroom.blob.core.windows.net/clubs/-175_Lyn_1896_FK.png"
  }
];