import { StateController } from 'utils/action-declaration';

import { AppState } from 'root.reducer'
import VirtualSummitService from 'api/virtual-summit/virtual-summit.service'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';

class CompleteDetailsState {
    currentStep: number;
    transferIn: boolean;
    transferInCompleted: boolean;
    transferOut: boolean;
    transferOutCompleted: boolean;

    generalNetworing: boolean;
    generalNetworingCompleted: boolean;

    isCompleting: boolean;
    nextStepsMap: Array<number>;
    isLoading: boolean;
    backReferer: string;
    isFirtsLaunch: boolean;
}

const defaultState: CompleteDetailsState = {
    currentStep: 1,
    transferIn: false,
    transferInCompleted: false,
    transferOut: false,
    transferOutCompleted: false,
    generalNetworing: true,
    generalNetworingCompleted: true,
    isCompleting: false,
    nextStepsMap: [],
    isLoading: false,
    backReferer: null,
    isFirtsLaunch: false
}

const stateController = new StateController<CompleteDetailsState>(
    "VIRTUAL_SUMMIT/COMPLETE_DETAILS",
    defaultState
);

class Actions {
    static initStepper(step: string, track: string, referer: string, isFirstLaunch: boolean, redirectToConfirm: () => void) {
        return async (dispatch, getState: () => AppState) => {
            let appState = getState();

            const { eventId  } = appState.auth;

            if (track == 'email') {
                dispatch(userActivityInsert({ 
                    Message: 'Complete Details', 
                    PageName: 'Event [Email]',
                    PageType: PageType.Events,
                    EventId: eventId
                }))
            }
            let defaultScenario = false;
            let stepConverted: number;

            if (step == null) {
                defaultScenario = true;
                stepConverted = 1;
            }
            else {
                stepConverted = parseInt(step);
            }
            dispatch(
                stateController.setState({
                    isLoading: true,
                })
            );

            let info = await VirtualSummitService.getConfirmRegistrationData(appState.auth.userId);
            if (!info.isUserRegistered) {
                redirectToConfirm();
                return;
            }

            if (stepConverted == 1) {
            }
            else if (stepConverted == 2 && !info.isTransferIn) {
                await VirtualSummitService.setTransferIn(appState.auth.userId)
                info.isTransferIn = true;
            }
            else if (stepConverted == 3 && !info.isTransferOut) {
                await VirtualSummitService.setTransferOut(appState.auth.userId)
                info.isTransferOut = true;

            }
            else if (stepConverted == 4 && !info.isGeneralNetworking) {
                await VirtualSummitService.setGeneralNetworking(appState.auth.userId)
                info.isGeneralNetworking = true;
            }
            dispatch(
                stateController.setState({
                    transferIn: info.isTransferIn,
                    transferInCompleted: info.transferInStepCompleted,
                    transferOut: info.isTransferOut,
                    transferOutCompleted: info.transferOutStepCompleted,
                    //generalNetworing: info.isGeneralNetworking,
                   // generalNetworingCompleted: info.generalNetworkingStepCompleted,
                    isFirtsLaunch: isFirstLaunch
                })
            );
            appState = getState();

            let nextStepsMap: Array<number> = this.getStepsMap(appState.virtualSummit.completeDetails, appState.virtualSummit.completeDetails.isFirtsLaunch, stepConverted);
            var stepInt = stepConverted;
            // if ((info.isTransferIn || info.isTransferOut || info.isGeneralNetworking) && defaultScenario) {
            //     stepInt = nextStepsMap[0];
            // }

            dispatch(
                stateController.setState({
                    nextStepsMap: nextStepsMap,
                    currentStep: stepInt,
                    isLoading: false,
                    backReferer: referer
                })
            );
        }
    }

    private static getStepsMap = (state: CompleteDetailsState, includeNetworking: boolean, step: number): Array<number> => {
        let nextStepsMap: Array<number> = [];
        if (state.transferIn) nextStepsMap.push(2);
        if (state.transferOut) nextStepsMap.push(3);
        if (state.generalNetworing || includeNetworking) nextStepsMap.push(4);
         //it is temporarily hidden
        //if (includeNetworking || step == 5) nextStepsMap.push(5, 6);
        
        nextStepsMap.push(7);
        return nextStepsMap;
    }

    public static saveObjectives(redirect: (referer: string) => void) {
        return async (dispatch, getState: () => AppState) => {
            let appState = getState();
            let currentState = appState.virtualSummit.completeDetails;

            dispatch(stateController.setState({ isCompleting: true }))

            await VirtualSummitService.setObjectives(
                appState.auth.userId,
                currentState.transferIn,
                currentState.transferOut,
                currentState.generalNetworing
            )

            let nextStepsMap: Array<number> = this.getStepsMap(currentState, currentState.isFirtsLaunch, 1);

            dispatch(stateController.setState({ currentStep: nextStepsMap[0], isCompleting: true, nextStepsMap: nextStepsMap }))
        }
    }

    public static setInCompleted(){
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ transferInCompleted: true }))
        }
    }

    public static setOutCompleted(){
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ transferOutCompleted: true }))
        }
    }

    public static setNetworkingCompleted(){
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ generalNetworingCompleted: true }))
        }
    }

    public static goToNext(isSkip: boolean, redirect: (referer?: string) => void) {
        return async (dispatch, getState: () => AppState) => {
            let appState = getState();
            let currentState = appState.virtualSummit.completeDetails;

            if (!isSkip && currentState.backReferer != null && currentState.currentStep != 4 && currentState.currentStep != 5) {
                redirect(currentState.backReferer);
                return null;
            }
            if (currentState.currentStep == 5 && isSkip) redirect();

            let map = currentState.nextStepsMap;
            let currentIndex = map.indexOf(currentState.currentStep);
            let nextStep = map[currentIndex + 1];
         
            if (nextStep === undefined) {
                if (!isSkip) {
                    nextStep = 7;
                }
                else {
                    redirect();
                }
            }
            dispatch(stateController.setState({ currentStep: nextStep }))
        }
    }

    public static goBack(redirect: (path: string) => void) {
        return async (dispatch, getState: () => AppState) => {
            let appState = getState();
            let currentState = appState.virtualSummit.completeDetails;

            if (currentState.backReferer != null) {
                redirect(currentState.backReferer);
                return
            }

            let map = currentState.nextStepsMap;
            let currentIndex = map.indexOf(currentState.currentStep);
            let backStep = map[currentIndex - 1];
            if (backStep === undefined) backStep = 1;

            dispatch(stateController.setState({ currentStep: backStep }))
        }
    }

    public static setTransferIn = () => {
        return (dispatch, getState: () => AppState) => {
            let state = getState().virtualSummit.completeDetails;
            dispatch(
                stateController.setState({ transferIn: !state.transferIn })
            );
        }
    }


    public static setTransferOut = () => {
        return (dispatch, getState: () => AppState) =>
            dispatch(
                stateController.setState({ transferOut: !getState().virtualSummit.completeDetails.transferOut })
            );
    }

    public static setGeneralNetworing = () => {
        return (dispatch, getState: () => AppState) =>
            dispatch(
                stateController.setState({ generalNetworing: !getState().virtualSummit.completeDetails.generalNetworing })
            );
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    CompleteDetailsState as State,
    Actions as Actions,
    stateController as Controller
};



