export class PlayerAdWithCountersModel {
    ad: PlayerAdModel;
    receivedPitchCount: number;
    savedPitchCount: number;
    declaredInterestCount: number;
}

export class PlayerAdModel {
    id: number;
    updatedAt: string;
    amount: FormattedPriceModel;
    sellOn: number;
    grossSalary: FormattedPriceModel;
    positionCode: string;
    positionName: string;
    type: PlayerAdType;
    currency: CurrencyModel;
    minHeight: number;
    fromAge: number;
    toAge: number;
    updateAtFormatted: string;
    isOutdated: boolean;
    isUpdatable: boolean;
    isUpdated?: boolean;
    isLeftFooted: boolean;
}

export enum PlayerAdType {
    Buy = 1,
    Loan = 2
}

export class FormattedPriceModel {
    price: number;
    formattedComma: string;
    formattedKMB: string;
}

export class CurrencyModel {
    id: number;
    name: string;
    sign: string;
}
