import { StateController } from 'utils/action-declaration';

import { AppState } from 'root.reducer'
import { LandingPageService } from 'api/landing-page/landing-page-service'
import { getAuthSquadId, getAuthClubId, getAuthUserId } from 'store/auth/authReducer'
import { closeMakePlayersAvailable } from 'pages/landing-page/redux/landing-page.actions'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import * as Recommendation from './performance-data.controller'
import { PageType } from 'constants/enums';

class ActivePitchesState {
    players: Array<any>;
    displayPlayers: Array<any>;
    selectedPlayer: any;
    keyword: string;
    availablePlayersCount: number;
    availablePlayersPercent: number;
    isLoading: boolean;
    doneActive: boolean;
}

const defaultState: ActivePitchesState = {
    players: [],
    displayPlayers: [],
    selectedPlayer: null,
    keyword: "",
    availablePlayersCount: 0,
    availablePlayersPercent: 0,
    isLoading: false,
    doneActive: false
}

const stateController = new StateController<ActivePitchesState>(
    "LANDING_PAGE/MAKE_PLAYERS_AVAILABLE",
    defaultState
);

class Actions {
    public static loadPlayers() {
        return async (dispatch, getState: () => AppState) => {
            let appState = getState();
            let currentSquadId = getAuthSquadId(appState);
            let currentClubId = getAuthClubId(appState);
            let currentUserId = getAuthUserId(appState);

            dispatch(stateController.setState({ isLoading: true }));

            let players = await LandingPageService.loadMySquadPlayers(currentClubId, currentUserId, currentSquadId);
            let displayPlayers = players.filter(i => !i.playerAvailability.isTransferStatusDisabledForCurrentSquad && !i.inReleaseList);

            let count = 0;
            let percent = 0;
            displayPlayers.map(p => {
                if (p.playerAvailability.isAvailableForLoan || p.playerAvailability.isAvailableForSale) count += 1;
            });
            percent = Math.round(100 * count / displayPlayers.length);

            dispatch(stateController.setState({
                isLoading: false,
                players: players,
                displayPlayers: displayPlayers,
                availablePlayersCount: count,
                availablePlayersPercent: percent
            }));
        }
    }

    public static saveAvailabilities() {
        return async (dispatch, getState: () => AppState) => {

            dispatch(stateController.setState({ isLoading: true }));
            dispatch(Recommendation.Actions.completedMakeAvailableRecommendation())

            dispatch(userActivityInsert({
                PageName: 'Home [Make player available]',
                Message: 'Done',
                PageType: PageType.Home
            }));

            dispatch(this.dispose());
            dispatch(closeMakePlayersAvailable());
        }
    }

    public static setSelectedPlayer(player: any) {
        return async (dispatch, getState: () => AppState) => {
            let selectedPlayer = getState().landingPage.makePlayersAvailable.selectedPlayer;
            if (selectedPlayer && selectedPlayer.id == player.id)
                dispatch(stateController.setState({ selectedPlayer: null }));
            else {
                dispatch(stateController.setState({ selectedPlayer: player }));
                dispatch(userActivityInsert({
                    PageName: 'Home [Make player available]',
                    Message: 'Opened Sale/Loan Details',
                    PlayerId: player.id,
                    PageType: PageType.Home
                }));
            }

        }
    }

    public static dispose() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(defaultState));
        }
    }

    public static savePlayerAvailability(availability: any) {
        return async (dispatch, getState: () => AppState) => {
            let subState = getState().landingPage.makePlayersAvailable;

            let player = subState.displayPlayers.find(i => i.id == availability.playerId);
            let prevAvailability = null;
            if (player) {
                prevAvailability = player.playerAvailability;
                player.playerAvailability = availability;
            }

            let displayPlayers = getState().landingPage.makePlayersAvailable.displayPlayers;
            let count = 0;
            let percent = 0;
            displayPlayers.map(p => {
                if (p.playerAvailability.isAvailableForLoan || p.playerAvailability.isAvailableForSale) count += 1;
            });
            percent = Math.round(100 * count / displayPlayers.length);

            dispatch(stateController.setState({
                selectedPlayer: null,
                displayPlayers: [...subState.displayPlayers],
                availablePlayersCount: count,
                availablePlayersPercent: percent,
                doneActive: true
            }));

            await LandingPageService.savePlayerAvailabilities([availability]);

            dispatch(Recommendation.Actions.completedMakeAvailableRecommendation())

            const currentStatus = Actions.getStatus(availability);
            if(currentStatus){
                dispatch(userActivityInsert({
                    PageName: 'Home [Make player available]',
                    Message: 'Listed Player: ' + currentStatus,
                    PlayerId: availability.playerId,
                    PageType: PageType.Home
                }));
            } else if(prevAvailability){
                const prevStatus = Actions.getStatus(prevAvailability);
                dispatch(userActivityInsert({
                    PageName: 'Home [Make player available]',
                    Message: 'Delisted Player: ' + prevStatus,
                    PlayerId: availability.playerId,
                    PageType: PageType.Home
                }));
            } 
        }
    }

    private static getStatus(availability: any){
        let availabilityText = '';

        if (availability.isAvailableForSale) {
            availabilityText += 'For Sale (';

            if (availability.isSellPriceUponRequest) {
                availabilityText += 'price upon request';
            }
            else {
                availabilityText += 'asking price: ' + availability.askingPrice;

                if (availability.sellOn && availability.sellOn > 0) {
                    availabilityText = availabilityText + ' sell on: ' + availability.sellOn;
                }
            }

            availabilityText += ')';
        }

        if (availability.isAvailableForLoan) {
            if (availabilityText.length > 0) {
                availabilityText += ', ';
            }

            availabilityText += 'For Loan (';

            if (availability.isLoanPriceUponRequest) {
                availabilityText += 'price upon request';
            }
            else {
                availabilityText += 'asking price: ' + availability.monthlyLoanFee;
            }

            availabilityText += ')';
        }

        return availabilityText;
    }

    public static changeKeyword(keyword: string) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ keyword: keyword }));
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    ActivePitchesState as State,
    Actions as Actions,
    stateController as Controller
};



