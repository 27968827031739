import axios from 'axios-config';
import {
    AgencyPermissionResponse,
    Filter,
    SalesSupervisor,
    PendingAgencyEventRequest,
    EditCostValueRequest,
    PlayerMarketRequest,
    PlayerMarketsSubscriptionModel,
    RepresentationRequestSettingOption,
    AdditionalPlayerMarketSubscriptionEnum,
    RepresentationRequestSettingOptionEnum
} from "./model";

export class AgencyPermissions {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async getCountries(userId: number): Promise<Array<any>> {
        const { data } = await axios.get(
            `api/Competition/GetAreasWithCompetitions/${userId}?withSubSquads=true&onlyWithUsers=false`,
            this.axiosConfig
        );
        return data;
    }

    public static async getSalessupervisorList(): Promise<Array<SalesSupervisor>> {
        const { data } = await axios.get(
            `api/v2/AdminAgencySubscription/GetAvailableSaleSupervisors`,
            this.axiosConfig
        );
        return data;
    }

    public static async getAdditionalPlayerMarketSubscriptions(): Promise<Array<PlayerMarketsSubscriptionModel>> {
        const { data } = await axios.get(
            `api/AdminAgencyPermissions/GetAdditionalPlayerMarketSubscriptions`,
            this.axiosConfig
        );
        return data;
    }

    public static async getRepresentationRequestOptions(): Promise<Array<RepresentationRequestSettingOption>> {
        const { data } = await axios.get(
            `api/AdminAgencyPermissions/GetRepresentationRequestOptions`,
            this.axiosConfig
        );
        return data;
    }

    public static async setAgencySalesSupervisor(agencyId: number, salesSupervisorId?: number){
        const { data } = await axios.post(
            `api/v2/AdminAgencySubscription/SetAgencySalesSuperVisor`,
            {agencyId: agencyId,salesSupervisorId: salesSupervisorId },
            this.axiosConfig
        );
        return data;
    }

    public static async getPlayerCostPermissions(request: any): Promise<Array<AgencyPermissionResponse>> {
        const { data } = await axios.post(
            `api/AdminAgencyPermissions/GetPlayerCostPermissions`,
            request,
            this.axiosConfig
        );
        return data;
    }

    public static async editCostValue(request: EditCostValueRequest): Promise<any> {
        const { data } = await axios.post(
            `api/AdminAgencyPermissions/EditCostValue`,
            request,
            this.axiosConfig,
        );
        return data.output;
    }

    public static async getAgencyPermissions (request: Filter): Promise<any> {
        const { data } = await axios.post(
            `api/AdminAgencyPermissions/GetAgencyPermissions`,
            request,
            this.axiosConfig
        );
        return data;
    }

    public static async getPendingAgencyEventRequests (eventId: number): Promise<Array<PendingAgencyEventRequest>> {
        const { data } = await axios.get(
            `api/AdminAgencyPermissions/GetPendingAgencyEventRequests/${eventId}`,
            this.axiosConfig
        );
        return data;
    }

    public static async applyAgencyRequest(eventId: number, agencyId: number, isApplied:boolean){
        await axios.post(
            `api/AdminAgencyPermissions/ApplyAgencyEvent`,
            {eventId: eventId, agencyId: agencyId,isApplied:isApplied}
        )
    }

    public static async getSalesSupervisorUsers(): Promise<Array<any>> {
        const { data } = await axios.get(
            `api/AdminSquads/GetSalesSupervisorUsers`,
            this.axiosConfig
        );
        return data.output;
    }


    public static async getAllAgencies(request: any): Promise<any> {
        const { data } = await axios.post(
            `api/Agency/GetAllAgencies`,
            request,
            this.axiosConfig
        );
        return data;
    }

    public static async allowAction(agencyId: number, permissionCode: string){
        await axios.post(
            `api/AdminAgencyPermissions/AllowAction`,
            { agencyId, permissionCode }
        )
    }

    public static async disallowAction(agencyId: number, permissionCode: string){
        await axios.post(
            `api/AdminAgencyPermissions/DisallowAction`,
            { agencyId, permissionCode }
        )
    }

    public static async savePlayerMarkets (request: PlayerMarketRequest): Promise<any> {
        const { data } = await axios.post(
            `api/AdminAgencyPermissions/SavePlayerMarkets`,
            request,
            this.axiosConfig
        );
        return data;
    }

    public static async saveAdditionalPlayerMarketSubscription(agencyId: number, additionalPlayerMarketSubscriptionId: AdditionalPlayerMarketSubscriptionEnum): Promise<any> {
        const { data } = await axios.post(
            `api/AdminAgencyPermissions/SaveAdditionalPlayerMarketSubscription`,
            {
                agencyId,
                additionalPlayerMarketSubscriptionId
            },
            this.axiosConfig
        );
        return data;
    }

    public static async saveRepresentationRequestOption(agencyId: number, representationRequestSettingOptionId: RepresentationRequestSettingOptionEnum): Promise<any> {
        const { data } = await axios.post(
            `api/AdminAgencyPermissions/SaveReprestntationRequestSettingOption`,
            {
                agencyId,
                representationRequestSettingOptionId
            },
            this.axiosConfig
        );
        return data;
    }
}
