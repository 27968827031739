import { StateController } from 'utils/action-declaration'
import { AppState } from 'root.reducer'
import { AgencyCoachOnboardingService } from 'api/agency/coach/onboarding/coach-onboarding.service';
import { getAuth } from 'store/auth/authReducer';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import historyAccessor from 'history-accessor';
import { coachPaths } from 'routes/paths';

class PlayerEmailConfirmationState {
    isConfirmPageProcessing: boolean;
    isConfirmSucceed: boolean;
}

const defaultState: PlayerEmailConfirmationState = {
    isConfirmPageProcessing: false,
    isConfirmSucceed: null,
}

const stateController = new StateController<PlayerEmailConfirmationState>(
    'COACH/EMAIL_CONFIRMATION',
    defaultState
)

class Actions {
    public static validateGuid(guid: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const { coachId } = getAuth(getState());
                dispatch(stateController.setState({ isConfirmPageProcessing: true }))
                const { isSuccess } = await AgencyCoachOnboardingService.confirmEmailVerification(guid);
                if (isSuccess) {
                    dispatch(stateController.setState({ isConfirmSucceed: true }));

                    dispatch(userActivityInsert({
                        PageName: 'Email Verification',
                        Message: 'Opened Email Verification Success Page',
                        CoachId: coachId
                    }));

                } else {
                    dispatch(stateController.setState({ isConfirmSucceed: false }));

                    dispatch(userActivityInsert({
                        PageName: 'Email Verification',
                        Message: 'Opened Email Verification Error Page',
                        CoachId: coachId
                    }));
                }
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isConfirmPageProcessing: false }))
            }
        }
    }

    public static onClickContinue() {
        return (dispatch, getState: () => AppState) => {
            const { coachId } = getAuth(getState());

            historyAccessor.push(coachPaths.myProfile);

            dispatch(userActivityInsert({
                PageName: 'Email Verification',
                Message: 'Clicked Continue',
                CoachId: coachId
            }));
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState): PlayerEmailConfirmationState => state.coach.confirmEmail;
    public static isProcessing = (state: AppState) => Selectors.getRoot(state).isConfirmPageProcessing
    public static isConfirmSucceed = (state: AppState) => Selectors.getRoot(state).isConfirmSucceed
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    PlayerEmailConfirmationState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};