import headerReducer, { HeaderStylesState } from './header/header.reducer'
import { combineReducers } from 'redux'

export class CommonStylesState {
    header: HeaderStylesState
}


export default combineReducers({
    header: headerReducer  
})
