export interface GeneralPositionModel {
    id: number
    code: string
    name: string
}

export enum GeneralPositionEnum {
    AttackingMidfielder = 1,
    CentralMidfielder = 2,
    CentreBack = 3,
    DefensiveMidfielder = 4,
    Forward = 5,
    Goalkeeper = 6,
    LeftBack = 7,
    RightBack = 8,
    Winger = 9,
}