import { AppState } from "root.reducer";
import { StateController } from "utils/action-declaration";
import { AdminClubReonboardingService } from 'api/admin/club-reonboarding/club-reonboarding.service';
import { UserItem } from "api/admin/club-reonboarding/models/get-users-response";
import { HistoryItem, ReonboardingTypeEnum } from "api/admin/club-reonboarding/models/get-history-response";
import { SquadShortInfo } from "api/core/squad-short-info";
import { Actions as ClubReonboardingActions } from "../club-reonboarding.controller";
import { ClubReonboardingItemAdminStatusEnum } from "api/admin/club-reonboarding/models/get-squads-response";

class ClubReonboardingModalState {
    isModalOpen: boolean
    selectedSquad: SquadShortInfo
    isLoading: boolean
    isProcessing: boolean
    users: UserItem[]
    hasPermission: boolean
    isLoadingHistory: boolean
    history: HistoryItem[]
}

const defaultState: ClubReonboardingModalState = {
    isModalOpen: false,
    selectedSquad: null,
    isLoading: false,
    isProcessing: false,
    users: [],
    hasPermission: false,
    isLoadingHistory: false,
    history: []
}

const stateController = new StateController<ClubReonboardingModalState>(
    'ADMIN_V2/CLUB_REONBOARDING_MODAL',
    defaultState
)

class Actions {
    public static openModal(squad: SquadShortInfo) {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.loadSquadInfo(squad.id));
            dispatch(Actions.loadHistory(squad.id));

            dispatch(stateController.setState({ 
                isModalOpen: true,
                selectedSquad: squad
            }));
        };
    }

    public static closeModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.dispose());
        };
    }

    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState({ ...defaultState }));
        };
    }

    public static onCheckboxChange(user: UserItem, value: boolean) {
        return async (dispatch, getState: () => AppState) => {
            if(!value && user.reonboardingType === ReonboardingTypeEnum.ClubTransferWindow)
                return;

            const { selectedSquad } = Selectors.getRoot(getState());
            if(value){
                await dispatch(Actions.activate(user.userId));
                dispatch(Actions.loadHistory(selectedSquad.id));
            } else {
                await dispatch(Actions.deactivate(user.reonboardingId));
            }
            
            await dispatch(Actions.loadSquadInfo(selectedSquad.id));

            const { users } = Selectors.getRoot(getState());
            const newUserItem = users.find(x => x.userId === user.userId);
            const squadItemStatus = users.some(x => x.isActive && x.reonboardingType ===  ReonboardingTypeEnum.ClubTransferWindow) 
                ? ClubReonboardingItemAdminStatusEnum.TransferWindow 
                : users.some(x => x.isActive) 
                ? ClubReonboardingItemAdminStatusEnum.ManualLaunch
                :  ClubReonboardingItemAdminStatusEnum.NotActive;

            dispatch(ClubReonboardingActions.updateSquadItem(selectedSquad.id, newUserItem, squadItemStatus));
        }
    }

    private static activate(userId: number) {
        return async (dispatch) => {
            dispatch(stateController.setState({ isProcessing: true }));
            try {
                await AdminClubReonboardingService.activate(userId);
            } catch(err){
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }));
            }
        };
    }

    private static deactivate(reonboardingId: number) {
        return async (dispatch) => {
            dispatch(stateController.setState({ isProcessing: true }));
            try {
                await AdminClubReonboardingService.deactivate(reonboardingId);
            } catch(err){
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }));
            }
        };
    }

    public static loadSquadInfo(squadId: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));
            try {
                const data = await AdminClubReonboardingService.getSquadInfo(squadId);
                dispatch(stateController.setState({ 
                    users: data.items,
                    hasPermission: data.hasPermission
                }));
            } catch(err){
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        }
    }

    public static loadHistory(squadId: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoadingHistory: true }));
            try {
                const data = await AdminClubReonboardingService.getHistory(squadId);
                dispatch(stateController.setState({ history: data.items }));
            } catch(err){
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isLoadingHistory: false }));
            }
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.admin.clubReonboarding.modal;
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    ClubReonboardingModalState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};