import _ from 'lodash'
import { AppState } from 'root.reducer';
import { StateController } from 'utils/action-declaration';
import { SuggestionList } from 'api/search-v3/model/suggestion-list';
import { getCancelTokenSource } from 'axios-config';
import SearchService from 'api/search-v3/search.clubside.service';
import AgenciesSearchService from 'api/search-v3/search.agenciesside.service';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';
import { Selectors as FilterSelectors } from 'pages/PlayerSearch-v3/agency/redux/filter.controller';
import { Actions as GridActions, Selectors as GridSelectors } from 'pages/PlayerSearch-v3/agency/redux/grid.controller';
import { GridToggleState, Tab } from 'pages/PlayerSearch-v3/shared-components/models/shared-models';

class SearchAutosuggestState {
    keyword: string;
    isLoading: boolean;
    wasLoaded: boolean;
    players: SuggestionList;
    headCoaches: SuggestionList;
    activeTab: Tab;
}

const defaultState: SearchAutosuggestState = {
    keyword: '',
    players: null,
    headCoaches: null,
    isLoading: false,
    wasLoaded: false,
    activeTab: Tab.Players
}

const stateController = new StateController<SearchAutosuggestState>(
    'AGENCY_SEARCH/AUTOSUGGEST',
    defaultState
);

class Actions {
    public static createDebounce = () => _.debounce(
        (dispatch, getState: () => AppState) => {
            dispatch(Actions.fetchSuggestions());
        },
        500);

    public static cancelToken = null;
    public static fetchDebounced = Actions.createDebounce();

    public static onKeywordChange(keyword: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ keyword }))
            if (keyword.length > 0) {
                if (keyword.length > 2) {
                    Actions.fetchDebounced(dispatch, getState)
                }
            } else {
                dispatch(Actions.onKeywordClear())
            }
        }
    }

    public static setKeyword(keyword: string) {
        return dispatch => {
            dispatch(stateController.setState({ keyword }))
        }
    }

    public static onKeywordClearWithoutRefresh() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({
                keyword: '',
                wasLoaded: false,
                isLoading: false,
                players: null,
                headCoaches: null,
            }))

            Actions.fetchDebounced.cancel()
            if (Actions.cancelToken) {
                Actions.cancelToken.cancel()
            }
            Actions.cancelToken = null;
            Actions.fetchDebounced = Actions.createDebounce()
        }
    }

    public static onKeywordClear() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.onKeywordClearWithoutRefresh());
            dispatch(Actions.setDefaultTab());

            function processSearchModeReaction() {
                // Need to handle scenario when user clear search keyword while in In Filtered State
                // See: https://transferroom.visualstudio.com/TransferRoom/_wiki/wikis/TransferRoom.wiki/994/Player-Search-V3
                // if (FilterSelectors.isSearchModeInFilteredState(getState())) {
                //     // If user have any other filters active, then need to reSave latest filterSet to DB
                //     if (FilterSelectors.isFiltersInActiveState(getState())) {
                //         dispatch(FilterActions.saveLatestFilterSet())
                //     }
                //     // Else if there are no other active filters and no search subsets selected except keyword, than just move to Default SearchMode state (NOT Filtered)
                //     const isSubsetSelected = FilterSelectors.getRoot(getState()).searchSubset
                //     const isFiltersInActiveState = FilterSelectors.isFiltersInActiveState(getState())
                //     if (!isSubsetSelected && !isFiltersInActiveState) {
                //         dispatch(FilterActions.setSearchModeToDefault())
                //     }
                // }
            }
            processSearchModeReaction()
        }
    }

    public static fetchSuggestions() {
        return async (dispatch, getState: () => AppState) => {
            const keyword = Selectors.getKeyword(getState());
            const isSimilarity = false;
            const isInShortList = false;
            const playerSet = (FilterSelectors.getRoot(getState()).searchSubset || {}).playerSet || null;

            if (Actions.cancelToken) {
                Actions.cancelToken.cancel();
            }

            Actions.cancelToken = getCancelTokenSource();

            try {
                dispatch(stateController.setState({ isLoading: true }));

                let data = await AgenciesSearchService.getSuggestions(keyword, false, isSimilarity, isInShortList, playerSet, Actions.cancelToken.token)

                if (data) {
                    dispatch(stateController.setState({
                        wasLoaded: true,
                        players: data.players,
                        headCoaches: data.headCoaches,
                    }));

                    dispatch(Actions.sendUserActivity(`Search: ${keyword}`));
                }

            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState({ isLoading: false }))
            }
        }
    }

    public static disposeState() {
        return dispatch => {
            dispatch(stateController.setState({ ...defaultState }));
        }
    }

    public static saveLatestSearchKeyword(keyword: string = null) {
        return async (dispatch, getState: () => AppState) => {
            if (keyword == null) {
                const searchState = Selectors.getRoot(getState());
                keyword = searchState.keyword;
            }
            await SearchService.saveLatestSearchKeyword(keyword);
        }
    }

    public static onPlayerSelected(playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            const player = Selectors.getPlayerSuggestionItem(getState())(playerId);
            const { title, currentClubId, agencyId } = player;

            dispatch(userActivityInsert({
                PageName: `Search Bar`,
                Message: `Selected ${title}`,
                PlayerId: player.id,
                AgencyId: !!currentClubId ? null : agencyId,
                ClubId: currentClubId ? currentClubId : null,
                PageType: PageType.Search,
            }));

            window.open('/profile/' + playerId, '_blank');
            await dispatch(Actions.saveLatestSearchKeyword(player.title));
            dispatch(Actions.onKeywordClear())
        }
    }

    public static onHeadCoachSelected(coachId: number) {
        return async (dispatch, getState: () => AppState) => {
            const headCoach = Selectors.getHeadCoachSuggestionItem(getState())(coachId);
            const { title, currentClubId, agencyId } = headCoach;

            dispatch(userActivityInsert({
                PageName: `Search Bar`,
                Message: `Selected ${title}`,
                CoachId: headCoach.id,
                AgencyId: !!currentClubId ? null : agencyId,
                ClubId: currentClubId ? currentClubId : null,
                PageType: PageType.Search,
            }));

            window.open(`/coach-profile/${coachId}`, '_blank');
            await dispatch(Actions.saveLatestSearchKeyword(headCoach.title));
            dispatch(Actions.onKeywordClear());
        }
    }

    public static setActiveTab = (activeTab: Tab) => {
        return (dispatch) => {
            dispatch(stateController.setState({ activeTab }));
        }
    }

    public static sendUserActivityOnTabChange(activeTab: Tab) {
        return (dispatch) => {
            if (activeTab === Tab.Players) {
                dispatch(Actions.sendUserActivity('Players'));
            }
            if (activeTab === Tab.HeadCoaches) {
                dispatch(Actions.sendUserActivity('Head Coaches'));
            }
        }
    }

    public static setDefaultTab = () => {
        return (dispatch, getState: () => AppState) => {
            const activeTab = Tab.Players;
            dispatch(stateController.setState({ activeTab }))
        }
    }

    public static onReleaseListTooltipHover = (playerId: number) => {
        return async (dispatch, getState: () => AppState) => {
            const item = Selectors.getPlayerSuggestionItem(getState())(playerId);
            // const gridState = GridSelectors.getGridState(getState());
            // dispatch(PlayerActivityService.openReleaseListTootip(
            //     playerId,
            //     item.currentClubId,
            //     item.agencyId,
            //     gridState,
            // ))
        }
    }

    public static createSearchDebounce = () => _.debounce(
        (dispatch, getState: () => AppState) => {
            const isUserActivityTracked = true;
            dispatch(GridActions.refresh(isUserActivityTracked));
        },
        800);

    public static searchDebounced = Actions.createSearchDebounce();

    public static onSearchKeywordChange(keyword: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ keyword }))
            if (keyword.length > 0) {
                if (keyword.length > 2) {
                    Actions.searchDebounced(dispatch, getState);
                }
            } else {
                dispatch(Actions.onKeywordClear())
            }
        }
    }

    public static onSearchKeywordClear() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.onKeywordClearWithoutRefresh())
            // dispatch(GridActions.searchPlayers());
            dispatch(GridActions.refresh()) //SEARCH
            // dispatch(GridController.resetPage())
        }
    }

    public static gridSendUserActivity() {
        return (dispatch, getState: () => AppState) => {
            const searchState = Selectors.getRoot(getState());
            const gridState = GridSelectors.getGridState(getState());

            const gridName = () => {
                if (gridState === GridToggleState.PlayersSearch) {
                    return 'Players';
                }
                if (gridState === GridToggleState.HeadCoachesSearch) {
                    return 'Coaches';
                }
                return ''
            }

            dispatch(userActivityInsert({
                PageName: `${gridName()} Search Bar`,
                Message: `Search: ${searchState.keyword}`,
                PageType: PageType.Search,
            }))

        }
    }

    public static sendUserActivity(message) {
        return (dispatch, getState: () => AppState) => {
            dispatch(userActivityInsert({
                PageName: 'Search Bar',
                Message: message,
                PageType: PageType.Search,
            }));
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState): SearchAutosuggestState => state.agencySearch.search;
    public static getKeyword = (state: AppState) => Selectors.getRoot(state).keyword;
    public static getPlayerSuggestionItem = (state: AppState) => (id: number) =>
        Selectors.getRoot(state).players.items.find(x => x.id === id);
    public static getHeadCoachSuggestionItem = (state: AppState) => (id: number) =>
        Selectors.getRoot(state).headCoaches.items.find(x => x.id === id);
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    SearchAutosuggestState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};
