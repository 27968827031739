import { AppState } from 'root.reducer'
import { RcFile } from 'antd/lib/upload'
import validator from 'services/validator'
import { StateController } from 'utils/action-declaration'
import ExpertCardsService from 'api/admin/expert-cards/expert-cards.service'
import { notificationCreate } from 'app/notifications/notifications.actions'
import { ExpertCardModel, PhoneCodeModel, AdminModel } from 'api/admin/expert-cards/models'


export type AddImageModalState = {
    adminId: number;
    name: string;
    currentImage: string;
} | null
export type AddExpertModalState = {
    adminId: number
    modalOpen: boolean
    processing: boolean
    email: string
    phoneCode: string
    adminName: string
    phoneNumber: string
    expertPosition: string
    googleCalendarId: string
    phoneCodeAreaId: number
    expertsWithoutCards: Array<AdminModel>
}
export type EditCardModalState = {
    adminId: number
    modalOpen: boolean
    processing: boolean
    email: string
    phoneCode: string
    adminName: string
    phoneNumber: string
    expertPosition: string
    googleCalendarId: string
    phoneCodeAreaId: number
    buttonDisabled: boolean
}
export type DeleteItemModalState = {
    id: number
    userName: string
    modalOpen: boolean
    processing: boolean
}
export type ListOfSquadsModalState = {
    expertSquads: Array<any>
    modalOpen: boolean
    isFetching: boolean
    adminUserName: string
}
export type ListOfAgenciesModalState = {
    expertAgencies: Array<any>
    modalOpen: boolean
    isFetching: boolean
    adminUserName: string
}
export type ValidateFieldsState = {
    isEmailValid: boolean
    isPhoneCodeValid: boolean
    isAdminNameValid: boolean
    isPhoneNumberValid: boolean
    isExpertPositionValid: boolean
}

class ExpertCardsState {
    cards: Array<ExpertCardModel>
    phoneCodes: Array<PhoneCodeModel>
    isFetching: boolean
    didTryToProcess: boolean
    advisorFetchingId: number
    supervisorFetchingId: number

    editCard: EditCardModalState
    addExpert: AddExpertModalState
    deleteItem: DeleteItemModalState
    listOfSquads: ListOfSquadsModalState
    listOfAgencies: ListOfAgenciesModalState

    addImageModalOpen: boolean
    addImageModal: AddImageModalState

    validatedFields: ValidateFieldsState
}

const defaultState: ExpertCardsState = {
    cards: [],
    phoneCodes: [],
    isFetching: false,
    didTryToProcess: false,
    advisorFetchingId: null,
    supervisorFetchingId: null,

    addImageModalOpen: false,
    addImageModal: null,

    deleteItem: {
        modalOpen: false,
        processing: false,
        id: null,
        userName: ''
    },
    addExpert: {
        adminId: null,
        modalOpen: false,
        processing: false,
        email: '',
        phoneCode: '',
        adminName: '',
        phoneNumber: '',
        expertPosition: '',
        googleCalendarId: '',
        phoneCodeAreaId: null,
        expertsWithoutCards: [],
    },
    editCard: {
        adminId: null,
        modalOpen: false,
        processing: false,
        email: '',
        phoneCode: '',
        adminName: '',
        phoneNumber: '',
        expertPosition: '',
        googleCalendarId: '',
        phoneCodeAreaId: null,
        buttonDisabled: true
    },
    listOfSquads: {
        expertSquads: [],
        modalOpen: false,
        isFetching: false,
        adminUserName: ''
    },
    listOfAgencies: {
        expertAgencies: [],
        modalOpen: false,
        isFetching: false,
        adminUserName: ''
    },
    validatedFields: {
        isEmailValid: true,
        isPhoneCodeValid: true,
        isAdminNameValid: true,
        isPhoneNumberValid: true,
        isExpertPositionValid: true
    }
}

const stateController = new StateController<ExpertCardsState>(
    'ADMIN_V2/EXPERT_CARDS',
    defaultState
)

class Actions {

    //====== Expert cards Page ======

    public static loadCards() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isFetching: true }))
            const data = await ExpertCardsService.getAllExpertCards()
            const cards = data.map(item => ({
                ...item,
                key: item.id
            }))
            dispatch(stateController.setState({ cards: cards, isFetching: false }))
        }
    }
    public static loadCardsSilent() {
        return async (dispatch, getState: () => AppState) => {
            const data = await ExpertCardsService.getAllExpertCards()
            const cards = data.map(item => ({
                ...item,
                key: item.id
            }))
            dispatch(stateController.setState({ cards: cards }))
        }
    }
    public static setDefaultSupervisor(item: ExpertCardModel) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ supervisorFetchingId: item.id }))
            await ExpertCardsService.setDefaultSupervisor(item.id)
            await dispatch(Actions.loadCardsSilent())
            dispatch(stateController.setState({ supervisorFetchingId: null }))
        }
    }
    public static setDefaultAdvisor(item: ExpertCardModel) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ advisorFetchingId: item.id }))
            await ExpertCardsService.setDefaultAdvisor(item.id)
            await dispatch(Actions.loadCardsSilent())
            dispatch(stateController.setState({ advisorFetchingId: null }))
        }
    }
    private static getPhoneCodes() {
        return async (dispatch, getState: () => AppState) => {
            const data = await ExpertCardsService.getPhoneCodes()
            dispatch(stateController.setState({ phoneCodes: data }))
        }
    }

    //====== Delete card Modal ======

    public static deleteCardModalOpen(item: ExpertCardModel) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                deleteItem: {
                    ...prevState.deleteItem,
                    modalOpen: true,
                    id: item.id,
                    userName: item.adminUserName
                }
            })))
        }
    }
    public static deleteCardModalClose() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                deleteItem: {
                    ...prevState.deleteItem,
                    modalOpen: false,
                    id: null,
                    userName: ''
                }
            })))
        }
    }
    public static deleteExpertCard(id: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    deleteItem: {
                        ...prevState.deleteItem,
                        processing: true
                    }
                })))
                await ExpertCardsService.deleteExpertCard(id)
                await dispatch(Actions.loadCardsSilent())
                dispatch(Actions.deleteCardModalClose())
                dispatch(notificationCreate({
                    message: "Expert was deleted",
                    level: 'warn'
                }))
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    deleteItem: {
                        ...prevState.deleteItem,
                        processing: false
                    }
                })))
            }
        }
    }

    //====== Add expert Modal ======

    public static addExpertModalOpen() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                addExpert: {
                    ...prevState.addExpert,
                    modalOpen: true
                }
            })))
            dispatch(Actions.getPhoneCodes())
            dispatch(Actions.getAdminsWithoutCards())
        }
    }
    public static addExpertModalClose() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                didTryToProcess: false,
                addExpert: {
                    ...prevState.addExpert,
                    modalOpen: false,
                    email: '',
                    phoneCode: '',
                    adminName: '',
                    phoneNumber: '',
                    expertPosition: '',
                    googleCalendarId: '',
                    phoneCodeAreaId: null,
                    expertsWithoutCards: []
                },
                validatedFields: {
                    ...prevState.validatedFields,
                    isEmailValid: true,
                    isPhoneCodeValid: true,
                    isAdminNameValid: true,
                    isPhoneNumberValid: true,
                    isExpertPositionValid: true
                }
            })))
        }
    }
    private static getAdminsWithoutCards() {
        return async (dispatch, getState: () => AppState) => {
            const data = await ExpertCardsService.getAdminsWithoutCards()
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                addExpert: {
                    ...prevState.addExpert,
                    expertsWithoutCards: data.output
                }
            })))
        }
    }
    public static onChangeAddExpertModalInput(value: { [T in keyof AddExpertModalState]?: AddExpertModalState[T] }) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                addExpert: {
                    ...prevState.addExpert,
                    ...value
                }
            })))
            if (getState().admin.expertCards.didTryToProcess === true) {
                dispatch(Actions.addExpertValidateFields())
            }
        }
    }
    public static saveExpertCard() {
        return async (dispatch, getState: () => AppState) => {

            await dispatch(Actions.addExpertValidateFields())

            const validatedFields = getState().admin.expertCards.validatedFields
            if (validatedFields.isAdminNameValid !== true) {dispatch(notificationCreate({ message: 'Select admin user name', level: 'error' }))}
            if (validatedFields.isEmailValid !== true) {dispatch(notificationCreate({ message: 'Insert valid email', level: 'error' }))}
            if (validatedFields.isExpertPositionValid !== true) {dispatch(notificationCreate({ message: 'Insert expert position', level: 'error' }))}
            if (validatedFields.isPhoneCodeValid !== true) {dispatch(notificationCreate({ message: 'Select phone code', level: 'error' }))}
            if (validatedFields.isPhoneNumberValid !== true) {dispatch(notificationCreate({ message: 'Insert phone number', level: 'error' }))}

            const isFormNotValid = Object.values(validatedFields).some(item => item === false)
            if (isFormNotValid) {
                dispatch(stateController.setState({ didTryToProcess: true }))
                return
            }
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    addExpert: {
                        ...prevState.addExpert,
                        processing: true
                    }
                })))
                const data = getState().admin.expertCards.addExpert
                const expertCard = {
                    adminUserId: data.adminId,
                    email: data.email,
                    expertPosition: data.expertPosition,
                    phoneCodeAreaId: data.phoneCodeAreaId,
                    phone: data.phoneNumber,
                    googleCalendarId: data.googleCalendarId
                }
                await ExpertCardsService.saveExpertCard(expertCard)
                await dispatch(Actions.loadCardsSilent())
                dispatch(notificationCreate({
                    message: "Expert was successfully added",
                    level: 'success'
                }))
                dispatch(Actions.addExpertModalClose())
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    didTryToProcess: false,
                    addExpert: {
                        ...prevState.addExpert,
                        processing: false
                    }
                })))
            }
        }
    }
    private static addExpertValidateFields() {
        return async (dispatch, getState: () => AppState) => {
            const validationRules = {
                email: validator.isNotEmpty(getState().admin.expertCards.addExpert.email) && validator.isValidEmail(getState().admin.expertCards.addExpert.email),
                adminName: validator.isNotEmpty(getState().admin.expertCards.addExpert.adminName),
                expertPosition: validator.isNotEmpty(getState().admin.expertCards.addExpert.expertPosition),
                phoneCode: validator.isNotEmpty(getState().admin.expertCards.addExpert.phoneCode),
                phoneNumber: validator.isNotEmpty(getState().admin.expertCards.addExpert.phoneNumber) && validator.isNumber(getState().admin.expertCards.addExpert.phoneNumber)
            }
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                validatedFields: {
                    ...prevState.validatedFields,
                    isEmailValid: validationRules.email,
                    isPhoneCodeValid: validationRules.phoneCode,
                    isAdminNameValid: validationRules.adminName,
                    isPhoneNumberValid: validationRules.phoneNumber,
                    isExpertPositionValid: validationRules.expertPosition
                }
            })))
        }
    }

    //====== Add image Modal ======

    public static addImageModalOpen(expertId: number) {
        return async (dispatch, getState: () => AppState) => {
            const expertData = getState().admin.expertCards.cards.find(item => item.id === expertId)
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                addImageModalOpen: true,
                addImageModal: {
                    adminId: expertData.id,
                    currentImage: expertData.pictureFile,
                    name: expertData.adminUserName
                }
            })))
        }
    }
    public static addImageModalClose() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ addImageModalOpen: false, addImageModal: null }))
        }
    }
    public static uploadImage(adminId: number, file: Blob | RcFile | string) {
        return async (dispatch, getState: () => AppState) => {
            const pictureName = await ExpertCardsService.updatePhoto(adminId, file);
            const newPictureName = pictureName + `?${new Date().getTime()}`
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                cards: prevState.cards.map(item => {
                    if (item.id === adminId) {
                        return {
                            ...item,
                            pictureFile: newPictureName
                        }
                    } else {
                        return item
                    }
                }),
                addImageModal: {
                    ...prevState.addImageModal,
                    currentImage: newPictureName
                }
            })))
            dispatch(notificationCreate({
                message: "Image successfully changed",
                level: 'success'
            }))
        }
    }
    public static deleteImage(adminId: number) {
        return async (dispatch) => {
            await ExpertCardsService.deletePhoto(adminId)
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                cards: prevState.cards.map(item => {
                    if (item.id === adminId) {
                        return {
                            ...item,
                            pictureFile: undefined
                        }
                    } else {
                        return item
                    }
                }),
                addImageModal: {
                    ...prevState.addImageModal,
                    currentImage: undefined,
                }
            })))
        }
    }

    //====== List of squads Modal ======

    public static listOfSquadsModalOpen(item: ExpertCardModel) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                listOfSquads: {
                    ...prevState.listOfSquads,
                    modalOpen: true,
                    adminUserName: item.adminUserName
                }
            })))
            dispatch(Actions.getExpertSquads(item.adminUserId))
        }
    }
    public static listOfSquadsModalClose() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                listOfSquads: {
                    ...prevState.listOfSquads,
                    modalOpen: false,
                    expertSquads: [],
                    adminUserName: ''
                }
            })))
        }
    }
    private static getExpertSquads(adminUserId) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    listOfSquads: {
                        ...prevState.listOfSquads,
                        isFetching: true
                    }
                })))
                const data = await ExpertCardsService.getExpertSquads(adminUserId)
                const squads = data.map((item, index) => ({
                    ...item,
                    key: index
                }))
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    listOfSquads: {
                        ...prevState.listOfSquads,
                        expertSquads: squads
                    }
                })))
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    listOfSquads: {
                        ...prevState.listOfSquads,
                        isFetching: false
                    }
                })))
            }
        }
    }

    //====== List of agencies Modal ======

    public static listOfAgenciesModalOpen(item: ExpertCardModel) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                listOfAgencies: {
                    ...prevState.listOfAgencies,
                    modalOpen: true,
                    adminUserName: item.adminUserName
                }
            })))
            dispatch(Actions.getExpertAgencies(item.adminUserId))
        }
    }
    public static listOfAgenciesModalClose() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                listOfAgencies: {
                    ...prevState.listOfAgencies,
                    modalOpen: false,
                    expertAgencies: [],
                    adminUserName: ''
                }
            })))
        }
    }
    private static getExpertAgencies(adminUserId) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    listOfAgencies: {
                        ...prevState.listOfAgencies,
                        isFetching: true
                    }
                })))
                const data = await ExpertCardsService.getExpertAgencies(adminUserId)
                const agencies = data.map((item, index) => ({
                    ...item,
                    key: index
                }))
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    listOfAgencies: {
                        ...prevState.listOfAgencies,
                        expertAgencies: agencies
                    }
                })))
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    listOfAgencies: {
                        ...prevState.listOfAgencies,
                        isFetching: false
                    }
                })))
            }
        }
    }

    //====== Edit card Modal ======

    public static editCardModalOpen(item: ExpertCardModel) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                editCard: {
                    ...prevState.editCard,
                    modalOpen: true
                }
            })))
            dispatch(Actions.getPhoneCodes())
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                editCard: {
                    ...prevState.editCard,
                    adminId: item.adminUserId,
                    email: item.email,
                    phoneCode: item.phoneCode,
                    phoneCodeAreaId: item.phoneCodeAreaId,
                    adminName: item.adminUserName,
                    phoneNumber: item.phone,
                    expertPosition: item.expertPosition,
                    googleCalendarId: item.calendarId
                }
            })))
        }
    }
    public static editCardModalClose() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                editCard: {
                    ...prevState.editCard,
                    modalOpen: false
                }
            })))
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                editCard: {
                    ...prevState.editCard,
                    adminId: null,
                    email: '',
                    phoneCode: '',
                    adminName: '',
                    phoneNumber: '',
                    expertPosition: '',
                    googleCalendarId: '',
                    phoneCodeAreaId: null,
                    buttonDisabled: true
                },
                validatedFields: {
                    ...prevState.validatedFields,
                    isEmailValid: true,
                    isPhoneCodeValid: true,
                    isAdminNameValid: true,
                    isPhoneNumberValid: true,
                    isExpertPositionValid: true
                }
            })))
        }
    }
    public static onChangeEditCardModalInput(value: { [T in keyof EditCardModalState]?: EditCardModalState[T] }) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                editCard: {
                    ...prevState.editCard,
                    ...value,
                    buttonDisabled: false
                }
            })))
            dispatch(Actions.editCardValidateFields())
        }
    }
    public static saveChangesInExpertCard() {
        return async (dispatch, getState: () => AppState) => {

            await dispatch(Actions.editCardValidateFields())

            const validatedFields = getState().admin.expertCards.validatedFields
            if (validatedFields.isEmailValid !== true) {dispatch(notificationCreate({ message: 'Insert valid email', level: 'error' }))}
            if (validatedFields.isExpertPositionValid !== true) {dispatch(notificationCreate({ message: 'Insert expert position', level: 'error' }))}
            if (validatedFields.isPhoneCodeValid !== true) {dispatch(notificationCreate({ message: 'Select phone code', level: 'error' }))}
            if (validatedFields.isPhoneNumberValid !== true) {dispatch(notificationCreate({ message: 'Insert phone number', level: 'error' }))}

            const isFormNotValid = Object.values(validatedFields).some(item => item === false)
            if (isFormNotValid) { return }

            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    editCard: {
                        ...prevState.editCard,
                        processing: true
                    }
                })))
                const data = getState().admin.expertCards.editCard
                const expertCard = {
                    adminUserId: data.adminId,
                    email: data.email,
                    expertPosition: data.expertPosition,
                    phoneCodeAreaId: data.phoneCodeAreaId,
                    phone: data.phoneNumber,
                    googleCalendarId: data.googleCalendarId
                }
                await ExpertCardsService.saveExpertCard(expertCard)
                await dispatch(Actions.loadCardsSilent())
                dispatch(notificationCreate({
                    message: "Expert data successfully changed",
                    level: 'success'
                }))
                dispatch(Actions.editCardModalClose())
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    editCard: {
                        ...prevState.editCard,
                        processing: false
                    }
                })))
            }
        }
    }
    private static editCardValidateFields() {
        return async (dispatch, getState: () => AppState) => {
            const validationRules = {
                email: validator.isNotEmpty(getState().admin.expertCards.editCard.email) && validator.isValidEmail(getState().admin.expertCards.editCard.email),
                expertPosition: validator.isNotEmpty(getState().admin.expertCards.editCard.expertPosition),
                phoneCode: validator.isNotEmpty(getState().admin.expertCards.editCard.phoneCode),
                phoneNumber: validator.isNotEmpty(getState().admin.expertCards.editCard.phoneNumber) && validator.isNumber(getState().admin.expertCards.editCard.phoneNumber)
            }
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                validatedFields: {
                    ...prevState.validatedFields,
                    isEmailValid: validationRules.email,
                    isPhoneCodeValid: validationRules.phoneCode,
                    isPhoneNumberValid: validationRules.phoneNumber,
                    isExpertPositionValid: validationRules.expertPosition
                }
            })))
        }
    }
}

class Selectors { 

}


const reducer = stateController.getReducer()

export {
    reducer as Reducer,
    ExpertCardsState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
}