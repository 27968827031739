import { AreaResponse } from 'api/area/model';
import { IdName } from 'api/core/id-name';
import { CancelToken } from 'axios';
import axios from 'axios-config';
import { 
    EditAgencyPublicProfileResponse, 
    SaveAgencyTransfersRequest, 
    SaveMarketsRequest, 
    SaveProfileTextRequest, 
    SaveRegisteredAgentsRequest
} from './models'

export default class AgencyPublicProfileService {
    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async getProfileForEdit(): Promise<EditAgencyPublicProfileResponse> {
        const { data } = await axios.get(
            `api/AgencyPublicProfile/GetProfileForEdit`,
            this.axiosConfig
        );
        return data;
    }

    public static async saveProfileText(request: SaveProfileTextRequest): Promise<any> {
        const { data } = await axios.post(
            `api/AgencyPublicProfile/SaveProfileText`,
            request,
            this.axiosConfig
        );
        return data;
    }

    public static async saveMarkets(request: SaveMarketsRequest): Promise<any> {
        const { data } = await axios.post(
            `api/AgencyPublicProfile/SaveMarkets`,
            request,
            this.axiosConfig
        );
        return data;
    }

    public static async getMarketAreas(): Promise<AreaResponse> {
        const { data } = await axios.get(
            `api/AgencyPublicProfile/GetMarketAreas`,
            this.axiosConfig
        );
        return data;
    }
    
    public static async saveRegisteredAgents(request: SaveRegisteredAgentsRequest): Promise<any> {
        const { data } = await axios.post(
            `api/AgencyPublicProfile/SaveRegisteredAgents`,
            request,
            this.axiosConfig
        );
        return data;
    }

    public static async saveTransfers(request: SaveAgencyTransfersRequest): Promise<any> {
        const { data } = await axios.post(
            `api/AgencyPublicProfile/SaveAgencyTransfers`,
            request,
            this.axiosConfig
        );
        return data;
    }

    public static async getPlayers(): Promise<IdName[]> {
        const { data } = await axios.get(
            `api/AgencyPublicProfile/GetPlayers`,
            this.axiosConfig
        );
        return data;
    }

    public static async getSquads(keyword: string, cancelToken: CancelToken): Promise<IdName[]> {
        const { data } = await axios.get(
            `api/Squad/SuggestSquads/0?keyword=${keyword}&take=200`,
            { ...this.axiosConfig, cancelToken: cancelToken }
        );
        return data.map(x => ({ id: x.id, name: x.englishOfficialName }));
    }
}