import axios from 'axios-config';
import { SuccessStoryModel, StoryModel } from "./models";

export class SuccessStoryService {
    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    }

    public static async getAll(userId: number): Promise<SuccessStoryModel> {
        const { data } = await axios.get(
            `api/AdminSuccessStory/Transfers/GetAll/${userId}`,
            this.axiosConfig
        );
        return data;
    }

    public static async createStory(userId: number, storyModel: StoryModel) {
        const { data } = await axios.post(
            `api/AdminSuccessStory/Transfers/Create/${userId}`,
            storyModel,
            this.axiosConfig
        );
        return data;
    }

    public static async updateStory(userId: number, id: number, storyModel: StoryModel) {
        const { data } = await axios.put(
            `api/AdminSuccessStory/Transfers/Update/${userId}/${id}`,
            storyModel,
            this.axiosConfig
        );
        return data;
    }

    public static async deleteStory(userId: number, id: number) {
        const { data } = await axios.delete(
            `api/AdminSuccessStory/Transfers/Delete/${userId}/${id}`,
            this.axiosConfig
        );
        return data;
    }

    public static async loadPlayerSuggestions(keyword: string, clubId: number, squadId: number) {
        const { data } = await axios.get(
            `api/Suggestion/GetPlayerSearchSuggestion/${squadId}/${keyword}?clubId=${clubId}`,
            this.axiosConfig
        );
        return data;
    }

    public static async loadSquadSuggestions(keyword: string, squadId: number, clubId: number, competitionId: []) {
        const { data } = await axios.post(
            `api/Suggestion/GetSquadGreedySuggestionByKeyword/${squadId}/${clubId}`,
            { keyword: keyword, pageSize: 100, currentPageNumber: 1, competitionIds: competitionId ? [competitionId] : [] },
            this.axiosConfig
        );
        return data;
    }

    public static async loadAgencySuggestions(keyword: string) {
        const { data } = await axios.post(
            `api/Suggestion/GetAgencySearchFilterSuggestion`,
            { keyword: keyword, pageSize: 100, currentPageNumber: 1, isOnlyUnsubscribed: false },
            this.axiosConfig
        );
        return data;
    }

}