import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import { CancelTokenSource } from 'axios';
import historyAccessor from 'history-accessor';
import { agencyPaths } from 'routes/paths';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';
import { HUBSPOT_COACH_FINDER_URL } from 'pages/PlayerSearch-v3/agency/constants/common-urls';
import { CoachAccessSubscriptionEnum } from 'constants/enums';
import { getCoachAccess, isCoachAccess } from 'store/auth/authReducer';
import AgencyHeadCoachesService from 'api/agency/agent/head-coaches/head-coaches.service';
import { Actions as FilterActions } from 'pages/PlayerSearch-v3/agency/redux/filter.controller';
import { GridToggleState } from 'pages/PlayerSearch-v3/shared-components/models/shared-models';
import { Actions as GridActions } from 'pages/PlayerSearch-v3/agency/redux/grid.controller';

class UpsellBannerState {
    isVideoModalOpen: boolean;
    isSearchUpsellModalOpen: boolean;
    isRequestSent: boolean;
}

const defaultState: UpsellBannerState = {
    isVideoModalOpen: false,
    isSearchUpsellModalOpen: false,
    isRequestSent: false,
}

const stateController = new StateController<UpsellBannerState>(
    'AGENCY_SEARCH/UPSELL_BANNER',
    defaultState
);

class Actions {
    public static token: CancelTokenSource = null;

    public static dispose() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ ...defaultState }))
        }
    }

    public static onSearchHeadCoaches(isSearchPage?: boolean) {
        return (dispatch, getState: () => AppState) => {
            // dispatch(FilterActions.setSearchModeToFiltered());
            // dispatch(FilterActions.initSorting());
            // dispatch(FilterActions.toggleQuickSearchMode(true));
            // dispatch(GridActions.toggleGridState(GridToggleState.HeadCoachesSearch));

            if (isSearchPage) {
                dispatch(userActivityInsert({
                    PageName: 'Search [Coach banner]',
                    Message: 'Clicked Go To Coachfinder Button',
                    PageType: PageType.Search,
                }));
            }
        }
    }

    public static onFindOutMoreClick() {
        return (dispatch, getState: () => AppState) => {
            window.open(HUBSPOT_COACH_FINDER_URL, '_blank');

            dispatch(userActivityInsert({
                PageName: 'Search [Banner]',
                Message: 'Clicked Find Out More Button',
                PageType: PageType.Search,
            }));

            // if (!!isSearchBar) {
            //     dispatch(userActivityInsert({
            //         PageName: 'Search [Search Bar]',
            //         Message: 'Clicked Find Out More Button',
            //         PageType: PageType.Search,
            //     }))
            // } else {
            //
            // }
        }
    }

    public static onHeadCoachPortfolioClick() {
        return (dispatch, getState: () => AppState) => {
            historyAccessor.push(agencyPaths.landingPageHeadCoaches)
        }
    }

    public static onSearchHeadCoachesClick() {
        return (dispatch, getState: () => AppState) => {
            const isSearchByCoachAvailable = isCoachAccess(getState());

            if (isSearchByCoachAvailable) {
                dispatch(FilterActions.setSearchModeToFiltered());
                dispatch(FilterActions.initSorting());
                dispatch(FilterActions.toggleQuickSearchMode(true));
                dispatch(GridActions.toggleGridState(GridToggleState.HeadCoachesSearch));
            } else {
                dispatch(Actions.onSearchUpsellModalOpen())
            }

            dispatch(userActivityInsert({
                PageName: 'Search [Banner]',
                Message: 'Clicked Search Head Coaches Button',
                PageType: PageType.Search,
            }));
        }
    }

    public static onSearchUpsellModalOpen() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isSearchUpsellModalOpen: true }));
            dispatch(userActivityInsert({
                PageName: 'Agency Head Coaches [Upgrade Access]',
                Message: 'Opened Upgrade Access Pop-up',
                PageType: PageType.Search,
            }));
        }
    }

    public static onSearchUpsellModalClose() {
        return (dispatch, getState: () => AppState) => {
            dispatch(userActivityInsert({
                PageName: 'Agency Head Coaches [Upgrade Access]',
                Message: 'Clicked Close',
                PageType: PageType.Search,
            }));

            dispatch(stateController.setState({
                isSearchUpsellModalOpen: false,
                isRequestSent: false,
            }));
        }
    }

    public static onInterestedClick(isUpsellModal?: boolean) {
        return async (dispatch, getState: () => AppState) => {
            const coachAccess = getCoachAccess(getState());
            try {
                AgencyHeadCoachesService.showAgencyInterestToCoachProfile();

                dispatch(userActivityInsert({
                    PageName: !!isUpsellModal ? 'Agency Head Coaches [Upgrade Access]' : 'Search [Banner]',
                    Message: 'Clicked Contact My Representative Button',
                    PageType: PageType.Search,
                }));

                dispatch(stateController.setState({ isRequestSent: true }))

                if (coachAccess !== CoachAccessSubscriptionEnum.None) {
                    dispatch(userActivityInsert({
                        PageName: 'Agency Head Coaches [Upgrade Access]',
                        Message: 'Opened Access Upgrade Request Sent Successfully Pop-up',
                        PageType: PageType.Search,
                    }));
                }

                if (coachAccess === CoachAccessSubscriptionEnum.None) {
                    dispatch(stateController.setState({ isSearchUpsellModalOpen: true }))
                }
            } catch (e) {
                console.error(e)
            }
        }
    }

    public static onRegisterEventClick() {
        return async (dispatch, getState: () => AppState) => {
            historyAccessor.push(agencyPaths.eventRegistration);

            dispatch(userActivityInsert({
                PageName: 'Search [Event banner]',
                Message: 'Register Interest',
                PageType: PageType.Search,
                EventId: getState().auth.eventId,
            }));
        }
    }

    public static onVideoModalOpen() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isVideoModalOpen: true }));

            dispatch(userActivityInsert({
                PageName: 'Search [Coach banner]',
                Message: 'Clicked Watch Video Button',
                PageType: PageType.Search,
            }));
        }
    }

    public static onVideoModalClose() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isVideoModalOpen: false }));

            dispatch(userActivityInsert({
                PageName: 'Search [Coach banner]',
                Message: 'Clicked Close Video',
                PageType: PageType.Search,
            }));
        }
    }

    public static onVideoModalPlay() {
        return (dispatch, getState: () => AppState) => {
            dispatch(userActivityInsert({
                PageName: 'Search [Coach banner]',
                Message: 'Clicked Play',
                PageType: PageType.Search,
            }));
        }
    }

    public static onVideoModalPause() {
        return (dispatch, getState: () => AppState) => {
            dispatch(userActivityInsert({
                PageName: 'Search [Coach banner]',
                Message: 'Clicked Pause',
                PageType: PageType.Search,
            }));
        }
    }

    public static onClickBannerPrevArrow() {
        return (dispatch, getState: () => AppState) => {
            dispatch(userActivityInsert({
                PageName: 'Search [Banner]',
                Message: 'Navigated to Previous Banner',
                PageType: PageType.Search,
            }));
        }
    }

    public static onClickBannerNextArrow() {
        return (dispatch, getState: () => AppState) => {
            dispatch(userActivityInsert({
                PageName: 'Search [Banner]',
                Message: 'Navigated to Next Banner',
                PageType: PageType.Search,
            }));
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState): UpsellBannerState => state.agencySearch.upsellBanner;
    public static getIsVideoModalOpen = (state: AppState) => Selectors.getRoot(state).isVideoModalOpen;
    public static getIsSearchUpsellModalOpen = (state: AppState) => Selectors.getRoot(state).isSearchUpsellModalOpen;
    public static getIsRequestSent = (state: AppState) => Selectors.getRoot(state).isRequestSent;
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    UpsellBannerState as State,
    Actions as Actions,
    stateController as Controller,
    Selectors,
};