import * as redux from 'redux';
import * as profileController from './profile.controller';
import * as usersController from './users.controller';
import * as notificationsController from './notifications.controller';
import * as changePasswordController from './change.password.controller';

export class AgencySettingsState {
    profile: profileController.State;
    users: usersController.State;
    notifications: notificationsController.State;
    changePassword: changePasswordController.State;
}

export function combineReducers() {
    let landingPageReducer = {
        profile: profileController.Reducer,
        users: usersController.Reducer,
        notifications: notificationsController.Reducer,
        changePassword: changePasswordController.Reducer,
    };

    return redux.combineReducers(landingPageReducer);
}
