import { messageSend } from 'app/send-message/actions/send-message.actions'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import {
    IMessageSendSpecificaion,
    MessageSendSession,
    Actions as MessageModalController
} from 'components/send-message-multi-addressee-modal/send-message-multi-recipients-modal.controller'
import { AppState } from 'root.reducer'
import { ReceiverType } from 'api/messaging/models/get-channel-response';
import { ActionType, PageType, UserActivityType } from 'constants/enums';
import { ReceiverRole } from 'api/search/model/declare-interest-definition';
import { Selectors } from 'pages/coach-profile/redux/coach-profile.controller';
import { MessageType } from 'api/messaging/models/message';

class Actions {
    public static openModal(messageReceiverType: ReceiverType) {
        return (dispatch, getState: () => AppState) => {
            const appState = getState();
            const coach = Selectors.getProfile(appState);

            const {
                shortInfo,
                agencyRelatedInfo,
            } = coach;

            const { agencyId } = agencyRelatedInfo;

            const canSendToAgency = coach.declareInterest?.messageRule?.receiver === ReceiverRole.Agent;

            let messageSession: MessageSendSession = {
                toClub: false,
                toAgency: messageReceiverType === ReceiverType.Agency && canSendToAgency,
                subject: `${shortInfo.shortName} interest`,
                mesageTypeId: canSendToAgency ? MessageType.ClubAgencyChatMessage : MessageType.ChatMessage,
                agencyId: agencyId,
                agencyCountry: agencyRelatedInfo.agencyCountry,
                agencyName: agencyRelatedInfo.agencyName,
                isAgentPlayerVerified: agencyRelatedInfo.isAgencyVerified,
                recipientId: agencyId ? agencyId : null,
                recipientType: ReceiverType.Agency
            }


            const specification: IMessageSendSpecificaion = {
                cancelMessage: (session: MessageSendSession) => {
                    const messageToAgency = session.recipientType === ReceiverType.Agency;
                    dispatch(userActivityInsert({
                        Message: `Clicked Cancel`,
                        PageName: 'Coach Profile [Send Message Pop-up]',
                        CoachId: coach.coachId,
                        AgencyId: messageToAgency ? session.recipientId : null,
                        ClubId: !messageToAgency ? session.recipientId : null,
                        PageType: PageType.CoachProfile,
                    }))
                },
                sendMessage: async (session: MessageSendSession, message: string) => {
                    try {
                        const messageToAgency = session.recipientType === ReceiverType.Agency;
                        if (messageToAgency) {
                            await dispatch(messageSend(session.recipientId, message, coach, session.agencyName, session.mesageTypeId, session.subject, true));
                        } else {
                            await dispatch(messageSend(session.recipientId, message, coach, session.squadName, session.mesageTypeId, session.subject, false));
                        }

                        dispatch(userActivityInsert({
                            Message: `Clicked Send`,
                            PageName: 'Coach Profile [Send Message Pop-up]',
                            CoachId: coach.coachId,
                            AgencyId: messageToAgency ? session.recipientId : null,
                            ClubId: !messageToAgency ? session.recipientId : null,
                            ActionType: ActionType.SentNewMessage,
                            PageType: PageType.CoachProfile,
                            UserActivityType: UserActivityType.Action
                        }));
                    } catch(e) {
                        console.error(e)
                    }
                }
            }

            const messageToAgency = messageSession.recipientType === ReceiverType.Agency;
            // dispatch(userActivityInsert({
            //     Message: 'Opened New Message',
            //     PageName: 'Coach Profile',
            //     CoachId: coach.coachId,
            //     AgencyId: messageToAgency ? messageSession.recipientId : null,
            //     ClubId: !messageToAgency ? messageSession.recipientId : null,
            //     // CurrentClubId: player.currentSquad?.id,
            //     PageType: PageType.CoachProfile
            // }));

            dispatch(MessageModalController.openSession(messageSession, specification))
        }
    }
}

export {
    Actions as Actions,
};