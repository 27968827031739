import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions'

export class MessageSendSession {
    logo: string | boolean;
    officialName: string;
    leagueName: string;
    subject: string;
    checkBoxTitle: string;
    mesageTypeId: number;    
}

class MessageSendSate {
    isSending: boolean;
    currentSession: MessageSendSession;
    specifcation: IMessageSendSpecificaion;
}

const defaultState: MessageSendSate = {
    isSending: false,
    currentSession: null,
    specifcation: null
}

const stateController = new StateController<MessageSendSate>(
    "LANDING_PAGE/MESSAGE_SEND-2",
    defaultState
);

export interface IMessageSendSpecificaion {
    sendMessage: (session: MessageSendSession, message: string, checked: boolean) => Promise<any>;
    cancelMessage: () => any;
}

class Actions {


    private static specifcation: IMessageSendSpecificaion;

    public static openSession(session: MessageSendSession, specification: IMessageSendSpecificaion) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ currentSession: session }));
            Actions.specifcation = specification;
        }
    }

    public static sendMessage(message: string, checked: boolean) {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            dispatch(stateController.setState({ isSending: true }));
            await Actions.specifcation.sendMessage(state.landingPage.messageSend.currentSession, message, checked)
            Actions.specifcation = null;
            dispatch(stateController.setState({ isSending: false, currentSession: null }));
        }
    }

    public static close() {
        return (dispatch) => {            
            Actions.specifcation.cancelMessage();
            Actions.specifcation = null;
            dispatch(stateController.setState({ isSending: false, currentSession: null }));
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    MessageSendSate as State,
    Actions as Actions,
    stateController as Controller
};



