import axios from 'axios-config'



export default class MappingService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    }

    //==================================================== Squads tab ===================================================

    public static async loadSquads(source: string, pageNumber: number, pageSize: number, squadName?: string, currentCompetitionId?: string) {
        let url = `api/AdminMappingSquads/GetSquads/${source}?pageSize=${pageSize}&pageNumber=${pageNumber}`

        if (currentCompetitionId) {
            url += `&competitionId=${currentCompetitionId}`
        }
        url += `&squadName=${squadName || ''}`

        return await axios.get( url, this.axiosConfig )
    }

    public static async createSquad(source: string, apiId: number) {
        return await axios.post(`api/AdminMappingSquads/CreateSquad/${source}?squadApiId=${apiId}`,
            this.axiosConfig 
        )
    }

    public static async mapSquad(source: string, apiId: number, squadId: number) {
        return await axios.post(`api/AdminMappingSquads/MapSquadV2/${source}?apiId=${apiId}&squadId=${squadId}`,
            this.axiosConfig
        )
    }

    public static async unmapSquad(source: string, apiId: number) {
        return await axios.post(`api/AdminMappingSquads/UnmapSquadV2/${source}?apiId=${apiId}`,
            this.axiosConfig
        )
    }

    public static async loadSquadSuggestionsOnSquadsTab(keyword: string, squadId: number, clubId: number, competitionId?: any) {
        return await axios.post(`api/Suggestion/GetSquadGreedySuggestionByKeyword/${squadId}/${clubId}`,
            {
                keyword: keyword, 
                pageSize: 100, 
                currentPageNumber: 1, 
                competitionIds:  competitionId ? [competitionId]: [],
            }
        )
    }

    public static async loadAllCompetitions(source: string) {
        return await axios.get(`api/AdminMappingSquads/GetAllCompetitions/${source}`,
            this.axiosConfig
        )
    }

    //==================================================== Players tab ==================================================

    public static async loadPlayers(source: string, pageNumber: number, pageSize: number, squadApiId?: number)  {
        let url = `api/AdminMappingPlayers/GetPlayers/${source}?pageSize=${pageSize}&pageNumber=${pageNumber}`

        if (squadApiId !== null) {
            url += `&squadApiId=${squadApiId}`
        }

        return await axios.get( url, this.axiosConfig )
    }

    public static async loadSquadSuggestionsOnPlayersTab(source: string, keyword: string) {
        return await axios.get(`api/AdminMappingSquads/GetSquadsByFilter/${source}?keyword=${keyword}`)
    }

    public static async mapPlayer(source: string, transferMarketId: number, playerId: number) {
        return await axios.post(`api/AdminMappingPlayers/MapPlayerV2/${source}?apiId=${transferMarketId}&playerId=${playerId}`)
    }

    public static async unmapPlayer(source: string, apiId: number) {
        return await axios.post(`api/AdminMappingPlayers/UnmapPlayerV2/${source}?apiId=${apiId}`,
            this.axiosConfig
        )
    }

    public static async createPlayer(source: string, apiId: number) {
        return await axios.post(`api/AdminMappingPlayers/CreatePlayer/${source}?apiId=${apiId}`,
            this.axiosConfig 
        )
    }

    public static async loadPlayerSuggestionsOnPlayersTab(keyword: string, squadId: number, clubId: number) {
        return await axios.get(`api/Suggestion/GetPlayerSearchSuggestion/${squadId}/${keyword}?clubId=${clubId}`)
    }

}

