export class ComparisonModel {
    firstKey: string;
    secondKey: string;
    thirdKey: string;
    characteristicSource: Array<{
        key: string;
        value: string
    }>;
    comparisonLists: {
        [key: string]: Array<Comparisonitem>
    }
}

export class ComparisonRequest {
    keys: Array<string>;
    squadIds: Array<number>;
    superPitchId: number;
}

export class Comparisonitem {
    position: number;
    positionFormatted: string;
    playerId: number;
    name: string;
    clubName: string;
    value: string;
    valueTip: string;
    originalValue:number;
    total: string;
    totalTip: string;
    isHighlighted: boolean;
    playerImage?: string;
}

export class ComparisonChangeItem {
    public playerId: number;
    public position: number;
}

export class ComparisonLists {
    items: {
        [key: string]: Array<Comparisonitem>
    }
    characteristicSource: Array<{
        key: string;
        value: string
    }>;
}
