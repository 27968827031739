import _ from 'lodash';
import { StateController } from 'utils/action-declaration';
import PitchService from 'api/pitch-v2/pitch-service.v2';
import { AppState } from 'root.reducer';
import { PitchGroupModel } from 'api/pitch-v2/models/pitch-group.model';
import { PitchModel, PitchType } from 'api/pitch-v2/models/pitch.model';
import * as CommonController from '../common.controller'
import { PitchPageType } from '../../constants/page-type'
import { createAction } from 'redux-actions';
import { parseAndTranslate, translate } from 'services/localization';
import * as MessageSend from 'pages/landing-page/redux/message.send.controller'
import { messageSend } from 'app/send-message/actions/send-message.actions'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions'
import { CancelToken } from 'axios';
import * as CommontController from 'pages/player-ads/redux/common.controller'

import {
    MESSAGES_SEND_LITE
} from 'store/actionTypes';
import { PlayerAdModel } from 'api/player-ad-v2/models/player-ad-with-counters';
import { ActionType, PageType, UserActivityType } from 'constants/enums';
import { notificationCreate } from 'app/notifications/notifications.actions';

class PlayerAdPitchState {
    initialized: boolean;
    isLoading: boolean;
    groupedActivePitches: Array<PitchGroupModel>;
    testedPitches: Array<PitchModel>;
    savedPitches: Array<PitchModel>;
    savedPitchesPlayerAds: Array<PlayerAdModel>;
    processingIds: Array<number>;
    declareInterestProcessingIds: Array<number>;
    processingPitch: PitchModel;
    showWarningPopup: boolean;
    pitchPageType: PitchPageType;
}

const defaultState: PlayerAdPitchState = {
    initialized: false,
    isLoading: false,
    groupedActivePitches: [],
    savedPitches: [],
    savedPitchesPlayerAds: [],
    testedPitches: [],
    processingIds: [],
    declareInterestProcessingIds: [],
    processingPitch: null,
    showWarningPopup: false,
    pitchPageType: null
}

const stateController = new StateController<PlayerAdPitchState>(
    "PLAYR_ADS/ACTIVITY/PITCH",
    defaultState
);
class SendTestPlusPitchSpecification implements MessageSend.IMessageSendSpecificaion {
    private dispatch;
    private toSquadId: number;
    private playerId: number;
    private squadPitchId: number;
    private isSavedMode: boolean;
    private agency: boolean;
    private agencyId: number;

    constructor(dispatch, toSquadId: number, playerId: number, squadPitchId: number, isSavedMode: boolean, agency: boolean, agencyId: number) {
        this.dispatch = dispatch;
        this.toSquadId = toSquadId;
        this.playerId = playerId;
        this.squadPitchId = squadPitchId;
        this.isSavedMode = isSavedMode;
        this.agency = agency;
        this.agencyId = agencyId;
    }

    public async sendMessage(session: MessageSend.MessageSendSession, message: string, checked: boolean): Promise<any> {
        const pitchSource = this.agency ? 'Agents ' : '';

        if (!this.isSavedMode) {
            if (checked) {
                this.dispatch(Actions.deleteTestInterest(this.squadPitchId))

            } else {
                this.dispatch(Actions.dismissPitch(PitchPageType.testPitch, this.squadPitchId))
            }
        }
        if (this.agency) {
            await PitchService.sendAgentMessage(this.squadPitchId, session.subject, message);
            this.dispatch(notificationCreate({ 
                level: 'success', 
                message: parseAndTranslate(`[%notifications.yourMessageTo%] <b>${session.officialName}</b> [%notifications.hasBeenSent%]`) 
            }))
        }
        else {
            await this.dispatch(messageSend(this.toSquadId, message, null, session.officialName, session.mesageTypeId, session.subject, false, this.squadPitchId));
        }

        this.dispatch(userActivityInsert({
            PageName: this.isSavedMode ? `Player Ads [Saved ${pitchSource}Pitch]` : `Player Ads [${pitchSource}Test Pitch]`,
            Message: 'Sent New Message',
            PlayerId: this.playerId,
            ClubId: this.agencyId ? null : this.toSquadId,
            AgencyId: this.agencyId,
            ActionType: ActionType.SentNewMessage,
            PageType: PageType.PlayerAds,
            UserActivityType: UserActivityType.Action
        }));
    }

    public async cancelMessage() {

        const pitchSource = this.agency ? 'Agents ' : '';

        this.dispatch(userActivityInsert({
            PageName: this.isSavedMode ? `Player Ads [Saved ${pitchSource}Pitch]` : `Player Ads [${pitchSource} Test Pitch]`,
            Message: 'Cancelled New Message',
            PlayerId: this.playerId,
            ClubId: this.agencyId ? null : this.toSquadId,
            AgencyId: this.agencyId,
            PageType: PageType.PlayerAds
        }));
    }
}

class Actions {

    public static deleteTestInterest(id: number) {
        return async (dispatch, getState: () => AppState) => {
            let subState = getState().playerAdPage.pitch;

            try {
                dispatch(stateController.setState({ processingIds: [...subState.processingIds, id] }))
                await PitchService.deleteTestInterest(id);

                const applyDismissToCollection = (items: Array<PitchModel>, deletedId: number) => {
                    return [...items.filter(x => x.pitchId != deletedId)]
                }

                let testedPitches = applyDismissToCollection(subState.testedPitches, id);

                subState = getState().playerAdPage.pitch;

                dispatch(stateController.setState({
                    testedPitches: testedPitches,
                }))

                dispatch(CommonController.Actions.loadCounters());
            } catch (error) {
                console.error(error)
            } finally {
                dispatch(stateController.setState({
                    processingIds: subState.processingIds.filter(x => x != id),
                }))
            }
        }
    }

    public static loadPitches(cancellationToken?: CancelToken) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));

            const pitches = await PitchService.GetPitches(cancellationToken);
            if (pitches) {
                dispatch(stateController.setState({
                    groupedActivePitches: pitches.groupedPitches,
                    testedPitches: pitches.testInterestedPitches,
                    isLoading: false,
                    initialized: true
                }));

                dispatch(CommonController.Actions.ensurePreloadStatistic(() => { }));
            }
        }
    }

    public static loadSavedPitches(cancellationToken?: CancelToken) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));

            const savedPitches = await PitchService.GetSavedPitches(cancellationToken);
            if (savedPitches.pitches) {
                savedPitches.pitches.forEach(item => item.uid = _.uniqueId('pitch-'))

                dispatch(stateController.setState({
                    savedPitches: savedPitches.pitches,
                    savedPitchesPlayerAds: savedPitches.playerAds,
                    isLoading: false,
                    initialized: true
                }));
            }
        }
    }


    private static getPitch(id: number, pitches: any): PitchModel {
        let pitch = null;
        pitches.map(p => {
            if (p.pitchId == id) {
                pitch = p;
            }
        });

        return pitch;
    }



    public static dismissPitch(pitchPageType: PitchPageType, id: number) {
        return async (dispatch, getState: () => AppState) => new Promise(async (resolve, reject) => {
            let subState = getState().playerAdPage.pitch;

            try {
                const pitches = []
                subState.groupedActivePitches.forEach(group => group.pitches.forEach(item => pitches.push(item)))
                const pitch = this.getPitch(id, [
                    ...pitches,
                    ...subState.savedPitches,
                    ...subState.testedPitches,
                ])

                dispatch(stateController.setState({ processingIds: [...subState.processingIds, id] }))
                await PitchService.dismiss(id);

                const applyDismissToCollection = (items: Array<PitchModel>, deletedId: number) => {
                    return [...items.filter(x => x.pitchId != deletedId)]
                }
                subState = getState().playerAdPage.pitch;

                let savedPitches = applyDismissToCollection(subState.savedPitches, id);
                let testedPitches = applyDismissToCollection(subState.testedPitches, id);
                let pitchGroups = [
                    ...subState.groupedActivePitches.map(item => ({ ...item, pitches: applyDismissToCollection(item.pitches, id) }))
                ].filter(x => x.pitches.length > 0);


                dispatch(stateController.setState({
                    savedPitches: savedPitches,
                    testedPitches: testedPitches,
                    groupedActivePitches: pitchGroups
                }))

                dispatch(CommonController.Actions.loadCounters());

                const message = pitchPageType === PitchPageType.testPitch ? 'Deleted Saved Pitch' : 'Dismissed Pitch'
                dispatch(Actions.userActivityInsert(pitchPageType, message, pitch, null))

                resolve(true)
            } catch (error) {
                console.error(error)
                reject(error)
            } finally {
                dispatch(stateController.setState({
                    processingIds: subState.processingIds.filter(item => item !== id),
                }))
            }
        })
    }

    public static removePitch(id: number) {
        return async (dispatch, getState: () => AppState) => new Promise(async (resolve, reject) => {
            let subState = getState().playerAdPage.pitch;

            try {

                const pitches = []
                subState.groupedActivePitches.forEach(group => group.pitches.forEach(item => pitches.push(item)))
                const pitch = this.getPitch(id, [
                    ...pitches,
                    ...subState.savedPitches,
                    ...subState.testedPitches,
                ])

                dispatch(stateController.setState({ processingIds: [...subState.processingIds, id] }))
                await PitchService.remove(id);

                const applyDismissToCollection = (items: Array<PitchModel>, deletedId: number) => {
                    return [...items.filter(x => x.pitchId != deletedId)]
                }
                subState = getState().playerAdPage.pitch;

                let savedPitches = applyDismissToCollection(subState.savedPitches, id);
                let testedPitches = applyDismissToCollection(subState.testedPitches, id);
                let pitchGroups = [
                    ...subState.groupedActivePitches.map(item => ({ ...item, pitches: applyDismissToCollection(item.pitches, id) }))
                ].filter(x => x.pitches.length > 0);


                dispatch(stateController.setState({
                    savedPitches: savedPitches,
                    testedPitches: testedPitches,
                    groupedActivePitches: pitchGroups
                }))

                dispatch(CommonController.Actions.loadCounters());

                dispatch(Actions.userActivityInsert(PitchPageType.savedPitch, 'Removed Pitch', pitch, null))

                resolve(true)

            } catch (error) {
                console.error(error)
                reject(error)
            } finally {
                dispatch(stateController.setState({
                    processingIds: subState.processingIds.filter(item => item !== id),
                }))
            }
        })
    }

    public static savePitch(pitchPageType: PitchPageType, id: number) {
        return async (dispatch, getState: () => AppState) => new Promise(async (resolve, reject) => {
            let subState = getState().playerAdPage.pitch;

            try {

                const pitches = []
                subState.groupedActivePitches.forEach(group => group.pitches.forEach(item => pitches.push(item)))
                const pitch = this.getPitch(id, [
                    ...pitches,
                    ...subState.savedPitches,
                    ...subState.testedPitches,
                ])

                dispatch(stateController.setState({ processingIds: [...subState.processingIds, id] }))
                await PitchService.savePitch(id);
                subState = getState().playerAdPage.pitch;


                const applyDismissToCollection = (items: Array<PitchModel>, deletedId: number) => {
                    return [...items.filter(x => x.pitchId != deletedId)]
                }


                let pitchGroups = [
                    ...subState.groupedActivePitches.map(item => ({ ...item, pitches: applyDismissToCollection(item.pitches, id) }))
                ].filter(x => x.pitches.length > 0);


                dispatch(stateController.setState({
                    groupedActivePitches: pitchGroups
                }))
                dispatch(CommonController.Actions.loadCounters());

                dispatch(Actions.userActivityInsert(pitchPageType, 'Saved Pitch', pitch, null))

                resolve(true)
            } catch (e) {
                console.error(e)
                reject(e)
            } finally {
                dispatch(stateController.setState({
                    processingIds: subState.processingIds.filter(item => item !== id),
                }))
            }
        })
    }

    public static declareInterest(pitchPageType: PitchPageType, id: number, playerId: number) {
        return async (dispatch, getState: () => AppState) => new Promise(async (resolve, reject) => {
            let subState = getState().playerAdPage.pitch;
            let shortlistedPlayers = getState().playerAdPage.common.shortListedPlayers

            dispatch(stateController.setState({
                processingIds: [...subState.processingIds, id],
                declareInterestProcessingIds: [...subState.declareInterestProcessingIds, id]
            }))
            if (!shortlistedPlayers.includes(playerId)) {
                dispatch(Actions.togglePlayerInShortList(PitchPageType.pitch, playerId, id));
            }

            try {

                const pitches = []
                subState.groupedActivePitches.forEach(group => group.pitches.forEach(item => pitches.push(item)))
                const pitch = this.getPitch(id, [
                    ...pitches,
                    ...subState.savedPitches,
                    ...subState.testedPitches,
                ])

                const pitchWasNotSaved = !subState.savedPitches.some(item => item.pitchId === id) && !subState.testedPitches.some(item => item.pitchId == id)
                if (pitchWasNotSaved) {
                    await PitchService.savePitch(id);
                }
                await PitchService.declareInterest(id);
                subState = getState().playerAdPage.pitch;

                const applyDeclareInterestToCollection = (items: Array<PitchModel>, processingId: number) => {
                    
                    const processingPitch = items.find(pitch => pitch.pitchId === processingId);  
                    const processingPitchPlayerAdId = processingPitch?.playerAdId;
                    const processingPitchCreatedBySquadId = processingPitch?.createdBySquad?.clubId
                    
                    return [...items.map(
                        x => {
                            if (x.pitchId == processingId) {
                                return { ...x, saved: true, interestDeclared: true, isAdAnonymised: false }
                            }
                            {/* changing isAdAnonymised property value to "false" for pitches with the same playerAd */}
                            if (x.pitchId !== processingId && x.playerAdId === processingPitchPlayerAdId && x.createdBySquad.clubId === processingPitchCreatedBySquadId) {       
                                return {...x, isAdAnonymised: false}
                            }

                            else return x
                        })
                    ]
                }

                const applyDismissToCollection = (items: Array<PitchModel>, deletedId: number) => {
                    return [...items.filter(x => x.pitchId != deletedId)]
                }

                let savedPitches = applyDeclareInterestToCollection(subState.savedPitches, id);

                let testedPitches = applyDismissToCollection(subState.testedPitches, id);

                let pitchGroups = [
                    ...subState.groupedActivePitches.map(item => ({ ...item, pitches: applyDismissToCollection(item.pitches, id) }))
                ].filter(x => x.pitches.length > 0);


                dispatch(stateController.setState({
                    savedPitches: savedPitches,
                    testedPitches: testedPitches,
                    groupedActivePitches: pitchGroups
                }))

                dispatch(Actions.userActivityInsert(pitchPageType, 'Declared Interest', pitch, ActionType.DeclaredInterest, UserActivityType.Action))

                dispatch(CommonController.Actions.loadCounters());
                resolve(true)
            } catch (e) {
                console.error(e);
                reject(e)
            } finally {
                dispatch(stateController.setState({
                    processingIds: subState.processingIds.filter(item => item !== id),
                    declareInterestProcessingIds: subState.declareInterestProcessingIds.filter(item => item !== id),
                }))
            }

        })
    }

    public static sendMessage(pitchPageType: PitchPageType, pitchId: number) {
        return async (dispatch, getState: () => AppState) => {
            const isSavedMode = pitchPageType === PitchPageType.savedPitch

            const subState = getState().playerAdPage.pitch
            const pitches = []
            subState.groupedActivePitches.forEach(group => group.pitches.forEach(item => pitches.push(item)))
            const pitch = this.getPitch(pitchId, [
                ...pitches,
                ...subState.savedPitches,
                ...subState.testedPitches,
            ])

            let pitchSource = "";


            if (pitch.type == PitchType.Agency) {
                pitchSource = "Agents "
            }

            dispatch(this.userActivityInsert(pitchPageType, 'Opened New Message', pitch, null))

            let session: MessageSend.MessageSendSession = {
                subject: "Re: " + pitch.player.englishShortName + " Pitch",
                logo: pitch.player.clubInfo.logo,
                checkBoxTitle: isSavedMode ? null : 'Keep player in saved pitches',
                officialName: pitch.player.clubInfo.parentSquad.name,
                leagueName: pitch.player.clubInfo.parentSquadCompetitionName + ", " + pitch.player.clubInfo.parentSquadCompetitionCountry,
                mesageTypeId: 6,
            }

            if (pitch.type == PitchType.Agency) {
                let area = translate(`apiTexts.areas.${pitch.agentInfo.agencyAreaId}`)
                session = {
                    subject: "Re: " + pitch.player.englishShortName + " Pitch",
                    logo: false,
                    checkBoxTitle: isSavedMode ? null : 'Keep player in saved pitches',
                    officialName: pitch.agentInfo.agencyName,
                    leagueName: `Agent${area ? ', ' : ''} ${area}`,
                    mesageTypeId: -1,
                }
            }

            let agencyId = null;
            if (pitch.agentInfo) {
                agencyId = pitch.agentInfo.agencyId;
            }

            dispatch(
                MessageSend.Actions.openSession(
                    session,
                    new SendTestPlusPitchSpecification(dispatch, pitch.player.clubInfo.parentSquad.id, pitch.player.id, pitch.pitchId, isSavedMode, pitch.type == PitchType.Agency, agencyId)
                )
            )
        }
    }

    public static userActivityInsert(pageType: string, message: string, pitch: any, actionType: ActionType | null, userActivityType: number = null) {
        return async (dispatch, getState: () => AppState) => {

            if (pitch) {

                let agencyId = null;
                let clubId = null
                if (pitch.agentInfo) {
                    agencyId = pitch.agentInfo.agencyId;
                    switch (pageType) {
                        case PitchPageType.savedPitch:
                            pageType = "Saved Agents Pitch";
                            break;
                        case PitchPageType.pitch:
                            pageType = "Agents Pitch";
                            break;
                        case PitchPageType.testPitch:
                            pageType = "Agents Test Pitch";
                            break;
                        default:
                            break;
                    }
                } else {
                    clubId = pitch.player.clubInfo.parentSquad.id
                }

                dispatch(userActivityInsert({
                    PageName: `Player Ads [${pageType}]`,
                    Message: message,
                    PlayerId: pitch.player.id,
                    ClubId: clubId,
                    AgencyId: agencyId,
                    ActionType: actionType,
                    PageType: PageType.PlayerAds,
                    Links: [{
                        Type: pageType === PitchPageType.pitch
                            || pageType === PitchPageType.testPitch
                            || pageType === PitchPageType.savedPitch ? 2 : 3,
                        TargetId: pitch.pitchId
                    }
                    ],
                    UserActivityType: userActivityType
                }));
            }
        }

        
    }

    public static togglePlayerInShortList(pageType, playerId, pitchId) {
        return (dispatch, getState: () => AppState) => {
            let subState = getState().playerAdPage.pitch;
            const pitches = []
            subState.groupedActivePitches.forEach(group => group.pitches.forEach(item => pitches.push(item)))
            const pitch = this.getPitch(pitchId, [
                ...pitches,
                ...subState.savedPitches,
                ...subState.testedPitches,
            ])
            let agencyId = null;
            let clubId = null;
            if (pitch != null) {
                if (pitch.agentInfo) {
                    agencyId = pitch.agentInfo.agencyId;
                    switch (pageType) {
                        case PitchPageType.savedPitch:
                            pageType = "Saved Agents Pitch";
                            break;
                        case PitchPageType.pitch:
                            pageType = "Agents Pitch";
                            break;
                        case PitchPageType.testPitch:
                            pageType = "Agents Test Pitch";
                            break;
                        default:
                            break;
                    }
                } else {
                    clubId = pitch.player.clubInfo.parentSquad.id
                }
            }

            dispatch(CommontController.Actions.togglePlayerInShortList(pageType, playerId, pitchId, clubId, agencyId, pitch.pitchType))
        }
    }

    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState))
        }
    }

    //*---------------------------------------------------Warning Popup Actions-------------------------------------------------*//

    public static openWarningPopup() {
        return (dispatch, getState: () => AppState) => {
            
            const substate: PitchModel = getState().playerAdPage.pitch.processingPitch;
            
            dispatch(userActivityInsert({
                PageName: 'Player Ads [Reveal Name Warning Pop-Up]',
                Message: 'Opened Reveal Name Warning Pop-Up',
                PageType: PageType.PlayerAds,
                PlayerId: substate?.player.id,
                ClubId: substate?.player.clubInfo.parentSquad?.id,
            }))

            dispatch(stateController.setState({ showWarningPopup: true }))
        }
    }

    public static cencelWarningPopup() {
        return (dispatch, getState: () => AppState) => {

            const substate: PitchModel = getState().playerAdPage.pitch.processingPitch

            dispatch(userActivityInsert({
                PageName: 'Player Ads [Reveal Name Warning Pop-Up]',
                Message: 'Cancelled Reveal Name Warning Pop-Up',
                PageType: PageType.PlayerAds,
                PlayerId: substate?.player.id,
                ClubId: substate?.player.clubInfo.parentSquad?.id,

            }))

            dispatch(Actions.closeWarningPopup())
        }
    }

    public static closeWarningPopup() {
        return (dispatch) => {
            dispatch(stateController.setState({
                showWarningPopup: false,
                pitchPageType: null,
                processingPitch: null,
            }))
        }
    }

    public static openSendMessage() {
        return (dispatch, getState: () => AppState) => {

            const substate: PitchModel = getState().playerAdPage.pitch.processingPitch
            const pitchPageType = getState().playerAdPage.pitch.pitchPageType

            dispatch(userActivityInsert({
                PageName: 'Player Ads [Reveal Name Warning Pop-Up]',
                Message: 'Clicked Confirm',
                PageType: PageType.PlayerAds,
                PlayerId: substate?.player.id,
                ClubId: substate?.player.clubInfo.parentSquad?.id,
                ActionType: ActionType.SentNewMessage
            }))

            dispatch(Actions.sendMessage(pitchPageType, substate?.pitchId))
            dispatch(Actions.closeWarningPopup())
        }
    }

    public static onSendMessageClick(pitchPageType: PitchPageType, pitchId: number) {
        return (dispatch, getState: () => AppState) => {
            const subState = getState().playerAdPage.pitch;
            
            let pitch: PitchModel = null;

            if (pitchPageType === PitchPageType.savedPitch) {
                pitch = this.getPitch(pitchId, [
                    ...subState.savedPitches,
                ])
            } else {
                pitch = this.getPitch(pitchId, [
                    ...subState.testedPitches,
                ])
            }
            
            const isAdAnonymised = pitch?.isAdAnonymised
            const interestWasDeclared = pitch?.interestDeclared
            
            if (isAdAnonymised && !interestWasDeclared) {
                dispatch(stateController.setState({ 
                    pitchPageType: pitchPageType,
                    processingPitch: pitch 
                }))
                
                dispatch(Actions.openWarningPopup())

            } else {
                dispatch(Actions.sendMessage(pitchPageType, pitchId))
            }
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    Actions as Actions,
    PlayerAdPitchState as State,
    stateController as Controller
};



