import { asyncActionType } from 'store/actionTypes';
import { getRequests } from 'store/requests/requestsReducer';

const initialState = {
    totalClubValue: {
        historicalMarketValues: {},
        total: '',
        isGrowing: false,
    },
    clubValueRank: {
        historicalMarketValues: {},
        isGrowing: false,
        currentPlace: undefined,
        totalPlaces: undefined,
        historicalMaxPlaces: undefined
    },
    showBlocksInfo: {
        isSpendingPowerShow: true,
        isTransferOverviewShow: true,
        isBiggestTransfersShow: true,
        isPlayersValuationDetailsShow: true,
        isPlayersValuationAverageShow: true,
        isSquadSizeShow: true,
        isPlayersAgeShow: true,
        isIncomingTransfersShow: true,
        isOutgoingTransfersShow: true
    }
}

export default function (state = initialState, action) {
    switch(action.type) {
        case TOTAL_CLUB_INFO_FETCH.SUCCESS: {
            const { historicalMarketValues, total, isGrowing } = action.payload.data
            return {
                ...state, 
                totalClubValue: {
                    historicalMarketValues: historicalMarketValues,
                    total: total,
                    isGrowing: isGrowing
                }
            }
        }
        case SHOW_BLOCKS_INFO_FETCH.SUCCESS: {
            return {
                ...state, 
                showBlocksInfo: action.payload.data
            }
        }
        case CLUB_RANK_INFO_FETCH.SUCCESS: {
            const { historicalMarketValues, isGrowing, currentPlace, totalPlaces, historicalMaxPlaces } = action.payload.data
            return {
                ...state, 
                clubValueRank: {
                    historicalMarketValues,
                    isGrowing,
                    currentPlace,
                    totalPlaces, 
                    historicalMaxPlaces
                }
            }
        }
        default: 
            return state
    }
}

export const SHOW_BLOCKS_INFO_FETCH = asyncActionType('app/financial-insights/SHOW_BLOCKS_INFO_FETCH')
export const TOTAL_CLUB_INFO_FETCH = asyncActionType('app/financial-insights/TOTAL_CLUB_INFO_FETCH')
export const CLUB_RANK_INFO_FETCH = asyncActionType('app/financial-insights/CLUB_RANK_INFO_FETCH')

export const getTotalClubInfoLoadingStatus = state =>
    getRequests(state).some(r => r.type === TOTAL_CLUB_INFO_FETCH);

export const getClubRankInfoLoadingStatus = state =>
    getRequests(state).some(r => r.type === CLUB_RANK_INFO_FETCH);