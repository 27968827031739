import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import AgencyCustomPitchService from 'api/agency/custom-pitch/custom-pitch.service'
import { GetInTouchRequest, LogCustomPitchRequest, PitchSquadInterestType, PublishPitchInfo } from 'api/agency/custom-pitch/models';
import { Comparisonitem } from 'api/super-pitch/models/comparison';
import { copyToClipboard as copy } from 'utils/copy-to-clipboard';
import * as PitchController from './pitch.controller';
import { getAuth } from 'store/auth/authReducer';
import { generateLink } from '../../helpers/custom-pitch';
import userActivityInsert, { insertAnonymousActivity } from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';
import historyAccessor from 'history-accessor';
import { anonymousPaths } from 'routes/paths';
import { createAction } from 'redux-actions';
import { MESSAGES_SEND_LITE } from 'store/actionTypes';
import { notificationCreate } from 'app/notifications/notifications.actions';
import { parseAndTranslate } from 'services/localization';

class AgencyCustomPitchPublishedState {
    isLoadingComparison: boolean;
    comparisonCharacteristicSource: {
        keys: {
            firstKey: string,
            secondKey: string,
            thirdKey: string,
        },
        characteristicSource: Array<{
            'key': string;
            'value': string
        }>,
        items: { [key: string]: Array<Comparisonitem> } | null;
        wasInitiallyLoaded?: boolean;
    };
    publishedPitchData: PublishPitchInfo;
    isDataLoading: boolean;
    sharePitchModal: SharePitchModalData;
    getInTouchModal: GetInTouchModalData;
    isGbeCalculated: boolean;
    pitchPublishedModal: PitchPublishedModalData;
}

class SharePitchModalData {
    isProcessing: boolean;
}

class GetInTouchModalData {
    name: string;
    email: string;
    club: string;
    position: string;
    message: string;
    nameError: boolean;
    emailError: boolean;
    clubError: boolean;
    positionError: boolean;
    isDirty: boolean;
}

class PitchPublishedModalData {
    isModalOpen: boolean;
    publishedUrl: string;
}

export enum ButtonState {
    Default = 0,
    Message = 1,
    GetInTouch = 2
}

const defaultState: AgencyCustomPitchPublishedState = {
    isLoadingComparison: false,
    comparisonCharacteristicSource: {
        keys: {
            firstKey: '',
            secondKey: '',
            thirdKey: '',
        },
        characteristicSource: [],
        items: null,
        wasInitiallyLoaded: false,
    },
    publishedPitchData: null,
    isDataLoading: false,
    sharePitchModal: null,
    getInTouchModal: null,
    isGbeCalculated: false,
    pitchPublishedModal: {
        isModalOpen: true,
        publishedUrl: '',
    },
}

const stateController = new StateController<AgencyCustomPitchPublishedState>("AGENCY/CUSTOM_PITCH/PUBLISHED_PITCH", defaultState);


class Actions {
    public static dispose() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ ...defaultState }))
        }
    }

    public static loadPublishedData(pitchId: string, isIos: boolean) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isDataLoading: true }));
            var data = await AgencyCustomPitchService.getPublishedPitch(pitchId);
            dispatch(stateController.setState({ isDataLoading: false, publishedPitchData: data }));

            if (data.isActive && !isIos) {
                await dispatch(Actions.logCustomPitchAction(data.pitchSquadId, PitchSquadInterestType.View));
            }
        }
    }

    public static openSharePitchModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({
                sharePitchModal: {
                    isProcessing: false
                }
            }))

            const { player, squadId } = Selectors.getRoot(getState()).publishedPitchData;

            const userId = getState().auth.userId;

            if (userId) {
                dispatch(userActivityInsert({
                    PageName: 'Custom Pitch [Published]',
                    Message: `Opened Sharing`,
                    PlayerId: player.id,
                    ClubId: squadId,
                    PageType: PageType.Pitch,
                }))
            } else {
                dispatch(insertAnonymousActivity({
                    PageName: 'Custom Pitch [Published]',
                    Message: `Opened Sharing`,
                    PlayerId: player.id,
                    ClubId: squadId,
                    PageType: PageType.Pitch,
                }))
            }

        }
    }

    public static openGbeCalculator(isUserPlayer: boolean, playerId: number, userId: number) {
        return (dispatch, getState: () => AppState) => {
            if (userId === 0) {
                window.open(`https://www.transferroom.com/gbe?hsLang=en#calculator`, '_blank')
            } else if (isUserPlayer) {
                window.open(`https://www.transferroom.com/gbe?hsLang=en#calculator`, '_blank')
            } else {
                window.open(`/player-points/${playerId}`, '_blank')
            }
        }
    }

    public static closeSharePitchModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ sharePitchModal: null }))

            const { player, squadId } = Selectors.getRoot(getState()).publishedPitchData;
            const userId = getState().auth.userId;

            if (userId) {
                dispatch(userActivityInsert({
                    PageName: 'Custom Pitch [Share]',
                    Message: 'Closed',
                    PlayerId: player.id,
                    ClubId: squadId,
                    PageType: PageType.Pitch,
                }))
            }
            else {
                dispatch(insertAnonymousActivity({
                    PageName: 'Custom Pitch [Share]',
                    Message: 'Closed',
                    PlayerId: player.id,
                    ClubId: squadId,
                    PageType: PageType.Pitch,
                }))
            }
        }
    }

    public static openGetInTouchPitchModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({
                getInTouchModal: {
                    club: "", position: "", email: "", name: "", message: "",
                    clubError: false, emailError: false, nameError: false, positionError: false, isDirty: true
                }
            }))

            const { player, squadId } = Selectors.getRoot(getState()).publishedPitchData;
            const userId = getState().auth.userId;

            if (userId) {
                dispatch(userActivityInsert({
                    PageName: 'Custom Pitch [Published]',
                    Message: `Opened Get in Touch`,
                    PlayerId: player.id,
                    ClubId: squadId,
                    PageType: PageType.Pitch,
                }))
            }
            else {
                dispatch(insertAnonymousActivity({
                    PageName: 'Custom Pitch [Published]',
                    Message: `Opened Get in Touch`,
                    PlayerId: player.id,
                    ClubId: squadId,
                    PageType: PageType.Pitch,
                }))
            }
        }
    }

    public static updateGetInTouchData(value) {
        return (dispatch, getState: () => AppState) => {
            let substate = getState().agency.customPitch.published.getInTouchModal
            dispatch(stateController.setState({
                getInTouchModal: { ...substate, ...value }
            }))
        }
    }

    public static closeGetInTouchPitchModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ getInTouchModal: null }))

            const { player, squadId } = Selectors.getRoot(getState()).publishedPitchData;
            const userId = getState().auth.userId;

            if (userId) {
                dispatch(userActivityInsert({
                    PageName: 'Custom Pitch [Get in touch]',
                    Message: 'Closed',
                    PlayerId: player.id,
                    ClubId: squadId,
                    PageType: PageType.Pitch,
                }))
            } else {
                dispatch(insertAnonymousActivity({
                    PageName: 'Custom Pitch [Get in touch]',
                    Message: 'Closed',
                    PlayerId: player.id,
                    ClubId: squadId,
                    PageType: PageType.Pitch,
                }))
            }
        }
    }

    public static loginGetInTouchModal() {
        return (dispatch, getState: () => AppState) => {
            const { player, squadId, publishedUid } = Selectors.getRoot(getState()).publishedPitchData;

            historyAccessor.push(anonymousPaths.login + '?returnUrl=' + anonymousPaths.customPitchPublished.replace(":id", publishedUid));
            const userId = getState().auth.userId;

            if (userId) {
                dispatch(userActivityInsert({
                    PageName: 'Custom Pitch [Get in touch]',
                    Message: 'Clicked Log in',
                    PlayerId: player.id,
                    ClubId: squadId,
                    PageType: PageType.Pitch,
                }))
            }
            else {
                dispatch(insertAnonymousActivity({
                    PageName: 'Custom Pitch [Get in touch]',
                    Message: 'Clicked Log in',
                    PlayerId: player.id,
                    ClubId: squadId,
                    PageType: PageType.Pitch,
                }))
            }
            dispatch(stateController.setState({ getInTouchModal: null }))
        }
    }

    public static sendGetInTouchModalData() {
        return async (dispatch, getState: () => AppState) => {
            const { player, squadId, pitchSquadId, agencyInfo } = Selectors.getRoot(getState()).publishedPitchData;
            const { club, email, message, name, position } = Selectors.getRoot(getState()).getInTouchModal;

            const href = new URL(window.location.href);
            const isIos = href.searchParams.has('isios')

            if(!isIos){
                dispatch(Actions.logCustomPitchAction(pitchSquadId, PitchSquadInterestType.UserInterest))
            }
            

            const args: GetInTouchRequest = {
                agencyId: agencyInfo.id, bodyText: message, club: club, email: email, name: name, playerName: player.name, position: position
            }
            dispatch(stateController.setState({ getInTouchModal: null }));
            await AgencyCustomPitchService.sendGetInTouchData(args);

            dispatch(notificationCreate({
                level: 'success',
                message: parseAndTranslate(`[%notifications.yourMessageTo%] <b>${agencyInfo.name}</b> [%notifications.hasBeenSent%]`)
            }))

            const userId = getState().auth.userId;

            if (userId) {
                dispatch(userActivityInsert({
                    PageName: 'Custom Pitch [Get in touch]',
                    Message: 'Sent a Message',
                    PlayerId: player.id,
                    ClubId: squadId,
                    PageType: PageType.Pitch
                }))
            }
            else {
                dispatch(insertAnonymousActivity({
                    PageName: 'Custom Pitch [Get in touch]',
                    Message: 'Sent a Message',
                    PlayerId: player.id,
                    ClubId: squadId,
                    PageType: PageType.Pitch
                }))
            }
        }
    }

    public static copyLink() {
        return async (dispatch, getState: () => AppState) => {
            const { publishedPitchData, sharePitchModal } = getState().agency.customPitch.published;
            const { pitchSquadId, publishedUid } = publishedPitchData;
            const href = new URL(window.location.href);
            const isIos = href.searchParams.has('isios')
            try {
                dispatch(stateController.setState({ sharePitchModal: { ...sharePitchModal, isProcessing: true } }))
                const url = generateLink(publishedUid);
                copy(url);
                if(!isIos){
                    await dispatch(Actions.logCustomPitchAction(pitchSquadId, PitchSquadInterestType.Share));
                }
                
                const { player, squadId } = Selectors.getRoot(getState()).publishedPitchData;
                const userId = getState().auth.userId;

                if (userId && isIos) {
                    dispatch(userActivityInsert({
                        PageName: 'Custom Pitch [Share]',
                        Message: 'Copied Link',
                        PlayerId: player.id,
                        ClubId: squadId,
                        PageType: PageType.Pitch,
                        UserId: userId,
                        Device: 'iPhone',
                    }))
                } else {
                    dispatch(insertAnonymousActivity({
                        PageName: 'Custom Pitch [Share]',
                        Message: 'Copied Link',
                        PlayerId: player.id,
                        ClubId: squadId,
                        PageType: PageType.Pitch,
                        Device: 'iPhone',
                    }))
                }

                dispatch(notificationCreate({ message: 'The link is copied to the clipboard.', level: 'info' }))
            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState({ sharePitchModal: { ...sharePitchModal, isProcessing: false } }))
            }
        }
    }

    public static logCustomPitchAction(pitchSquadId: number, type: PitchSquadInterestType) {
        return async (dispatch, getState: () => AppState) => {
            const auth = getAuth(getState())

            const args: LogCustomPitchRequest = {
                userId: auth.userId == 0 ? null : auth.userId,
                pitchSquadId,
                type,
            }
            await AgencyCustomPitchService.logCustomPitchAction(args);
        }
    }

    public static calculateGbeStatus() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isGbeCalculated: true }))

            const { player, squadId } = Selectors.getRoot(getState()).publishedPitchData;

                dispatch(userActivityInsert({
                    PageName: 'Custom Pitch [Published]',
                    Message: 'Opened GBE Stats',
                    PlayerId: player.id,
                    ClubId: squadId,
                    PageType: PageType.Pitch
                }))
        }
    }

    public static onOpenFA() {
        return async (dispatch, getState: () => AppState) => {
            const { player, squadId } = Selectors.getRoot(getState()).publishedPitchData;
            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Published]',
                Message: 'Opened FA Website',
                PlayerId: player.id,
                ClubId: squadId,
                PageType: PageType.Pitch
            }))
        }
    }

    public static generatePublishedLink() {
        return (dispatch, getState: () => AppState) => {
            const url = generateLink(getState().agency.customPitch.published.publishedPitchData.publishedUid);
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                pitchPublishedModal: {
                    ...prevState.pitchPublishedModal,
                    publishedUrl: url,
                }
            })))
        }
    }

    public static closeCustomPitchPublishedModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                pitchPublishedModal: {
                    ...prevState.pitchPublishedModal,
                    isModalOpen: false,
                }
            })))
        }
    }

    public static copyPublishedLink() {
        return (dispatch, getState: () => AppState) => {
            copy(getState().agency.customPitch.published.pitchPublishedModal.publishedUrl);
            dispatch(notificationCreate({ message: 'Copied to clipboard', level: 'info' }));
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                pitchPublishedModal: {
                    ...prevState.pitchPublishedModal,
                    isModalOpen: false,
                }
            })))
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState): AgencyCustomPitchPublishedState => state.agency.customPitch.published;
    public static getButtonState = (state: AppState) => {
        if (state.auth.clubId > 0) return ButtonState.Message;
        if ((state.auth.clubId == 0 || state.auth.clubId == null) &&
            (state.auth.agencyId == 0 || state.auth.agencyId == null) &&
            (state.auth.playerId == 0 || state.auth.playerId == null)) return ButtonState.GetInTouch;
        return ButtonState.Default;
    }
    // public static getProcessingIds = (state: AppState): Array<number> => Selectors.getRoot(state).processingIds
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    AgencyCustomPitchPublishedState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};



