import { StateController } from 'utils/action-declaration';
import { RegistrationService } from 'api/registration/registration.service';

export enum RegistrationState {
    SignUp = 1,
    SignUpDone = 2,
    BookDemoDone = 3,
    Error = 4
}

class ClubRegistration {
    isProcessing: boolean;
    result: {[key: string]: any};
    currentState: RegistrationState
}

const defaultState: ClubRegistration = {
    currentState: RegistrationState.SignUp,
    isProcessing: false,
    result: null
}

const stateController = new StateController<ClubRegistration>(
    "REGISTRATION_NEW/CLUB",
    defaultState
);

class Actions {

    public static token = null;

    public static changeState(destination: RegistrationState) {
        return dispatch => {
            dispatch(stateController.setState({ currentState: destination }))
        }
    }
    
    public static processClubRegistration(registrationData: any) {
        return async (dispatch, getState) => {
            registrationData.userPositionId = 16; // remove if position selection is returned back
            registrationData.registrationSource = 'Registration form';
            try {
                dispatch(stateController.setState({ isProcessing: true }))
                const result = await RegistrationService.registerClub(registrationData)
                if (result.value !== true && result.errorMessage) {
                    dispatch(Actions.changeState(RegistrationState.Error))
                } else {
                    dispatch(stateController.setState({ result: result }))
                    dispatch(Actions.changeState(RegistrationState.SignUpDone))
                }
            } catch(e) {
                console.error(e)
                
            } finally {
                dispatch(stateController.setState({ isProcessing: false }))
            }
        }
    }
    public static processBookDemo(registrationData: any) {
        return async (dispatch, getState) => {
            registrationData.userPositionId = 16; // remove if position selection is returned back
            registrationData.registrationSource = 'Registration form';
            try {
                dispatch(stateController.setState({ isProcessing: true }))
                const result = await RegistrationService.requestClubDemo(registrationData)
                if (result.value !== true && result.errorMessage) {
                    dispatch(Actions.changeState(RegistrationState.Error))
                } else {
                    dispatch(stateController.setState({ result: result }))
                    dispatch(Actions.changeState(RegistrationState.BookDemoDone))
                }
            } catch(e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState({ isProcessing: false }))
            }
        }
    }

    public static disposeError() {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({ 
                ...prevState,
                result: null,
            })))
        }
    }

    public static setDefaultViewState() {
        return dispatch => {
            dispatch(stateController.setState(defaultState))
        }
    }

}


const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    ClubRegistration as State,
    Actions as Actions,
    stateController as Controller
};



