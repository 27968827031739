import { Input } from 'antd'
import { RefObject } from 'react'
import { copyToClipboard as copy } from 'utils/copy-to-clipboard';
import { AppState } from 'root.reducer'
import { StateController } from 'utils/action-declaration'
import { notificationCreate } from 'app/notifications/notifications.actions'
import { AdminResetPasswordLinkService } from 'api/admin/reset-password-link/reset-password-link.service'


class AdminResetPasswordLinkState {
    isModalOpen: boolean;
    email: string;
    confirmLink: string;
    isSuccess: boolean;
    processing: boolean;
    isError: boolean;
}

const defaultState = {
    isModalOpen: false,
    email: '',
    confirmLink: '',
    isSuccess: false,
    processing: false,
    isError: false
}

const stateController = new StateController<AdminResetPasswordLinkState>(
    'ADMIN_V2/RESET_PASSWORD_LINK_MODAL',
    defaultState
)

class Actions {

    public static openModal() {
        return (dispatch) => {
            dispatch(stateController.setState({ isModalOpen: true }))
        }
    }

    public static closeModal() {
        return (dispatch) => {
            dispatch(stateController.setState({ isModalOpen: false, isError: false }))
            dispatch(Actions.clearResetPasswordLink())
        }
    }

    public static onChangeEmail(value: string) {
        return (dispatch) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                email: value,
                isError: false
            })))
        }
    }

    public static getResetPasswordLink(email: string, linkInputRef: RefObject<Input>, emailInputRef: RefObject<Input>) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const CONFIRMATION_LINK_TYPE = 3;

                dispatch(stateController.setState({ confirmLink: '', processing: true }))
                const data = await AdminResetPasswordLinkService.getResetPasswordLinkAsync(email, CONFIRMATION_LINK_TYPE)

                if (data.isError) {
                    dispatch(stateController.setState({ isError: true, processing: false }))
                    dispatch(notificationCreate({ message: 'The email is wrong', level: 'error' }))
                    emailInputRef.current.focus()
                    return
                } else {
                    dispatch(stateController.setState({ confirmLink: data.output, processing: false }))
                    dispatch(notificationCreate({ message: 'Link generated successfully', level: 'success' }))
                    linkInputRef.current.focus()
                    copy(getState().admin.resetPasswordLinkModal.confirmLink)
                    dispatch(notificationCreate({ message: 'Copied to clipboard', level: 'info' }))
                }

            } catch (error) {
                dispatch(notificationCreate({ message: `${error}`, level: 'error' }))
                throw error
            }
        }
    }

    private static clearResetPasswordLink() {
        return (dispatch) => {
            dispatch(stateController.setState({
                email: '',
                confirmLink: ''
            }))
        }
    }

    public static copyToClipboard() {
        return (dispatch, getState: () => AppState) => {
            copy(getState().admin.resetPasswordLinkModal.confirmLink)
            dispatch(notificationCreate({ message: 'Copied to clipboard', level: 'info' }))
        }
    }
    
}

class Selectors {

    public static isLinkDisabled(state: AppState) {
        const confirmLink = state.admin.resetPasswordLinkModal.confirmLink
        return confirmLink === '' || confirmLink === null || confirmLink === undefined;
    }

}

const reducer = stateController.getReducer()

export {
    reducer as Reducer,
    AdminResetPasswordLinkState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
}