import axios from 'axios-config'



export default class EventAttendanceService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    }

    public static async loadEvents() {
        return await axios.get(`api/AdminEvents/GetAllEvents`,
            this.axiosConfig
        )
    }

    public static async loadEventSquads() {
        return await axios.get(`api/AdminEvents/GetEventSquads`,
            this.axiosConfig
        )
    }

    public static async removeSquadFromEvent(squadId: number, eventId: number) {
        return await axios.post(`api/AdminEvents/RemoveEventSquad`,
        {
            eventId: eventId,
            squadId: squadId,
            id: 0,
        },
            this.axiosConfig
        )
    }

    public static async addSquadToEvent(squadId: number, eventId: number) {
        return await axios.post(`api/AdminEvents/AddSquadToEvent`,
        {
            eventId: eventId,
            squadId: squadId,
            id: 0,
        },
            this.axiosConfig
        )
    }

    public static async loadSquadSuggestions(keyword: string, squadId: number, clubId: number, competitionId?) {
        return await axios.post(`api/Suggestion/GetSquadGreedySuggestionByKeyword/${squadId}/${clubId}`,
        {
            keyword: keyword, 
            pageSize: 100, 
            currentPageNumber: 1, 
            competitionIds: competitionId ? [competitionId]: []
        },
            this.axiosConfig
        )
    }

    public static async addSquad(squadId: number) {
        return await axios.post(`api/AdminEvents/AddSquad/${squadId}`,
            this.axiosConfig
        )
    }

    public static async loadAreasWithCompetitions(userId: number) {
        return await axios.get(`api/Competition/GetAreasWithCompetitions/${userId}?withSubSquads=true`,
            this.axiosConfig 
        )
    }

    public static async loadSquadSuggestionsByCompetition(areaId: number, competitionId: number, squadId: number, keyword: string) {
        let url = `api/Squad/SuggestSquads/${squadId}?keyword=${decodeURI(keyword)}&take=30`
        if (areaId !== -1) {
            url += `&areaId=${areaId}`
        }
        if (competitionId !== -1) {
            url += `&competitionId=${competitionId}`
        }

        return await axios.get(url,
            this.axiosConfig 
        )
    }

    public static async addEvent(eventName: string, squads: any) {
        return await axios.post(`api/AdminEvents/AddEvent`,
        {
            name: eventName,
            registeredSquads: squads,
        },
            this.axiosConfig 
        )
    }

}