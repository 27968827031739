import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer'
import Service from 'api/agency/player/shared/agency-player-service'
import { GeneralPlayerInfoModel } from 'api/agency/player/shared/shared-models';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { logout } from 'store/auth/auth.actions';
import history from 'history-accessor';
import { playerPaths } from 'routes/paths'
import { PageType } from 'constants/enums';
// import { AgencyPlayerOnboardingService } from 'api/agency/player/onboarding-v2/onboarding-v2.service'
import { PlayerPreferenceService } from 'api/player-side/player-preference'
import { PlayerTransferMessage } from 'api/agency/player/shared/shared-models'
import historyAccessor from 'history-accessor';


class State {
    isLoading: boolean;
    serverData: GeneralPlayerInfoModel;
    isRelationConfirmed: boolean;
    shouldAgentBeEndorced: boolean;
    scrollPosition?: number;
    wasScroll?: boolean;
}

const defaultState: State = {
    isLoading: false,
    serverData: null,
    isRelationConfirmed: true,
    shouldAgentBeEndorced: false,
    scrollPosition: null,
    wasScroll: false,
}

const stateController = new StateController<State>(
    "PLAYER/HOME-PAGE",
    defaultState
)

class Actions {
    public static init() {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().player.myPage;
            dispatch(stateController.setState({ isLoading: true }));
            let data = await Service.getPlayerHomePageData();
            dispatch(stateController.setState({ isLoading: false, serverData: data }))


        }
    }

    public static setAgreement(date) {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().player.myPage;
            dispatch(stateController.setState({ isLoading: false, serverData: { ...substate.serverData, expiryDate: date, agreementPassed: true } }))
        }
    }

    public static logOut() {
        return async (dispatch, getState: () => AppState) => {
            await dispatch(userActivityInsert({
                PageName: 'My Page',
                Message: 'Logged Out',
                PlayerId: getState().auth.playerId,
                PageType: PageType.PlayerPage
            }))
            dispatch(logout())
        }
    }

    public static activateAccount() {
        return async (dispatch, getState: () => AppState) => {
            history.push(playerPaths.emailConfirmationFlow)
            dispatch(userActivityInsert({
                Message: 'Activate account',
                PageName: 'My Page',
                PlayerId: getState().auth.playerId,
                PageType: PageType.PlayerPage
            }))
        }
    }

    public static latestActivity() {
        return async (dispatch, getState: () => AppState) => {
            const substate = getState().player.myPage;
            history.push(playerPaths.latestActivity);

            dispatch(userActivityInsert({
                PageName: 'My Page [Latest Activity]',
                Message: 'Clicked See All Activity',
                PlayerId: substate.serverData.playerId,
                AgencyId: substate.serverData.agent.id,
                PageType: PageType.PlayerPage,
            }))
        }
    }

    public static viewPlayerProfile() {
        return async (dispatch, getState: () => AppState) => {
            history.push(playerPaths.myProfile)
            dispatch(userActivityInsert({
                Message: 'Opened Player Profile',
                PageName: 'My Page',
                PlayerId: getState().auth.playerId,
                PageType: PageType.PlayerPage
            }))
        }
    }

    public static viewMyMessages(TransferMessage: PlayerTransferMessage) {
        return async (dispatch, getState: () => AppState) => {
            history.push(playerPaths.myMessages)
        }
    }

    public static editAgent() {
        return async (dispatch, getState: () => AppState) => {
            history.push(playerPaths.myAgentFlow);
        }
    }

    public static addAgent() {
        return async (dispatch, getState: () => AppState) => {
            history.push(playerPaths.addAgent);
        }
    }

    public static setAgencyRelation = (value: boolean | null) => (dispatch, getState: () => AppState) => {
        dispatch(stateController.setState(prevState => ({ ...prevState, isRelationConfirmed: value })));
    }

    public static setEndorsement = (value: boolean) => (dispatch, getState: () => AppState) => {
        dispatch(stateController.setState(prevState => ({ ...prevState, shouldAgentBeEndorced: value })));
    }

    public static submitAgencyRelation() {
        return async (dispatch, getState: () => AppState) => {
            const substate = getState().player.myPage;
            dispatch(stateController.setState(prevState => ({ ...prevState, isLoading: true })));

            await Service.confirmAgentAuthenticated({
                endorseAgent: substate.shouldAgentBeEndorced,
                invitationUid: substate.serverData.invitationUid,
                isAgentConfirmed: substate.isRelationConfirmed
            });

            await dispatch(userActivityInsert({
                Message: substate.isRelationConfirmed
                    ? `Confirmed${substate.shouldAgentBeEndorced ? ' + recommend' : ''}`
                    : 'Not My Agent',
                PageName: 'Agent confirmation',
                PlayerId: substate.serverData.playerId,
                AgencyId: substate.serverData.agent.id,
            }));

            await dispatch(Actions.init());
        }
    }

    public static removeAgent(redirectToAdd: boolean, data: GeneralPlayerInfoModel) {
        return async (dispatch, getState: () => AppState) => {

            dispatch(stateController.setState({ isLoading: true }));
            await PlayerPreferenceService.removeAgency()
            dispatch(stateController.setState({ isLoading: false }));

            if (redirectToAdd) {
                history.push(playerPaths.addAgent);
                dispatch(userActivityInsert({
                    Message: 'Opened Representation Request',
                    PageName: 'Agent removed',
                    PlayerId: data.playerId
                }))
            } else {
                history.push('');
                dispatch(userActivityInsert({
                    Message: 'Closed Notification',
                    PageName: 'Agent removed',
                    PlayerId: data.playerId,
                    AgencyId: data.agent.id
                }))
            }
        }
    }

    public static setScrollPosition(value: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({ ...prevState, scrollPosition: value, wasScroll: true })));
        }
    }

    public static userActivityForFirstScroll () {
        return (dispatch, getState: () => AppState) => {
            const playerId = getState().player.myPage.serverData.playerId;
            dispatch(userActivityInsert({
                Message: 'Scrolled Home Page',
                PageName: 'My Page',
                PlayerId: playerId,
            }))
        };
    };
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    stateController as Controller
};