import axios from 'axios-config';
import { SearchRequest, SearchResponse } from './models';

export class AgencySearchService {

    private static axiosConfig = {
        headers: {
            "Content-Type": "application/json"
        }
    }

    public static async getAllAgencies(request: SearchRequest, cancellationToken?: any): Promise<SearchResponse> {
        const { data } = await axios.post(
            `api/v2/Agency/GetAllAgencies`,
            request,
            {
                ...this.axiosConfig,
                cancelToken: cancellationToken
            }
        )
        return data
    }
}