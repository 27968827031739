import { AppState } from "root.reducer";
import { StateController } from "utils/action-declaration";
import { RequestService } from 'api/admin/user-requests/user-requests'
import { UserRequestModel } from 'api/admin/user-requests/models'
import { notificationCreate } from 'app/notifications/notifications.actions';

class UserRequestState {
    items: Array<UserRequestModel>;
    isLoading: boolean;
    processingIds: Array<number>;
    applyAccessIds: Array<number>;
    suggestions: Array<any>;
    updateItemResult: any;
    editModal?: UserRequestModel;
}

const defaultState: UserRequestState = {
    items: [],
    isLoading: false,
    processingIds: [],
    suggestions: [],
    updateItemResult: null,
    editModal: null,
    applyAccessIds: [],
}

const stateController = new StateController<UserRequestState>(
    'ADMIN_V2/USER_REQUEST',
    defaultState
)

class Actions {
    public static loadUserRequestData() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isLoading: true }))

                const data = await RequestService.getAgentsWaitingConfirmation()
                const itemsWithKey = data.map(item => ({
                    ...item,
                    key: item.id,
                }))

                dispatch(stateController.setState({ items: itemsWithKey }))
            } finally {
                dispatch(stateController.setState({ isLoading: false }))
            }
        }
    }

    public static deleteUser(userRequestId: number) {
        return async (dispatch, getState: () => AppState) => {
            const ids = Selectors.selectProcessingIds(getState())
            try {
                dispatch(stateController.setState({ processingIds: [...ids, userRequestId] }))

                await RequestService.deleteUser(userRequestId)

                dispatch(stateController.setState((draftState) => ({
                    ...draftState,
                    items: draftState.items.filter(i => i.id !== userRequestId)
                })))
            } finally {
                dispatch(stateController.setState((draftState) => ({
                    ...draftState,
                    processingIds: draftState.processingIds.filter(i => i !== userRequestId)
                })))
            }

        }
    }

    public static activateUser(userRequestId: number) {
        return async (dispatch, getState: () => AppState) => {
            const ids = Selectors.selectProcessingIds(getState())

            try {
                dispatch(stateController.setState({ applyAccessIds: [...ids, userRequestId] }))

                const data: any = await RequestService.activateUserFromRequest(userRequestId)

                const isSuccess = data.output;
                if (isSuccess) {
                    dispatch(stateController.setState((draftState) => ({
                        ...draftState,
                        items: draftState.items.filter(i => i.id !== userRequestId)
                    })))
                }

                let errorMessage = data.humanReadableErrorMessage;
                if (errorMessage === undefined || errorMessage === null || errorMessage === "") {
                    errorMessage = 'User email already exists.'
                }
                const message = isSuccess ? 'User activated successfully' : errorMessage;
                const level = isSuccess ? 'success' : 'error';

                dispatch(notificationCreate({
                    message: message,
                    level: level,
                }));
            }
            finally {
                dispatch(stateController.setState((draftState) => ({
                    ...draftState,
                    applyAccessIds: draftState.applyAccessIds.filter(i => i !== userRequestId)
                })))
            }
        }
    }

    public static updateUserAgency(
        userRequestId: number,
        newAgencyExternalId: number,
        agencyName: string,
        oldAgencyId: number,
        contactId?: number,
    ) {
        return async (dispatch, getState: () => AppState) => {
            const ids = Selectors.selectProcessingIds(getState())

            try {
                dispatch(stateController.setState({ processingIds: [...ids, userRequestId] }))

                const data = await RequestService.updateUserExternalAgency(userRequestId, newAgencyExternalId, oldAgencyId, contactId)

                dispatch(stateController.setState((draftState) => ({
                    ...draftState,
                    updateItemResult: data,
                    items: draftState.items
                        .map(item => item.id === userRequestId ? { ...item, agencyExternalId: newAgencyExternalId, agencyName } : item)
                })))
                dispatch(Actions.clearEditForm())
            } finally {
                dispatch(stateController.setState((draftState) => ({
                    ...draftState,
                    processingIds: draftState.processingIds.filter(i => i !== userRequestId)
                })))
            }
        }
    }

    public static clearEditForm() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ editModal: null }))
        }
    }

    public static toggleEditModal(item?: UserRequestModel) {
        return dispatch => {
            dispatch(stateController.setState({ editModal: item }))
        }
    }
}

class Selectors {
    public static selectState = (state: AppState) => state.admin.agencyRequests;
    public static getUserRequests = (state: AppState) => Selectors.selectState(state).items
    public static selectIsLoading = (state: AppState) => Selectors.selectState(state).isLoading
    public static selectProcessingIds = (state: AppState) => Selectors.selectState(state).processingIds
    public static selectApplyProcessingIds = (state: AppState) => Selectors.selectState(state).applyAccessIds
    public static getSquadSuggestionsList = (state: AppState) => Selectors.selectState(state).suggestions
    public static getUpdateUserItemResult = (state: AppState) => Selectors.selectState(state).updateItemResult
    public static selectEditModal = (state: AppState) => Selectors.selectState(state).editModal
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    UserRequestState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};