import { PageType } from 'constants/enums';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions'
import { MySquadToBuy, MySquadToLoan } from 'api/my-squad/model/my-squad-player'
import ContractExpirySelector from 'components/Forms/ContractExpirySelector/ContractExpirySelectorV2'
import moment from 'moment'
import { dispatch } from 'index';
import { AppState } from 'root.reducer';
import _ from 'lodash';
import { Selectors } from './my-squad.controller';

export class MySquadActivityService {
    private static page: string = 'Squad';
    private static containers = {
        filter: 'Filter',
        edit: 'Edit',
        availability: 'SALE/LOAN',
        Restriction: 'Restriction',
        MatchMaker: 'Match Maker',
        MatchMakerRestriction: 'Match Maker Restriction',
        Recommender: 'Recommender',
        recommendedPlayer: 'Recommended Player',
    }

    private static buildCommand(container: string, action: string, playerId: number) {
        return {
            Message: action,
            PageType: PageType.Squad,
            PlayerId: playerId,
            PageName: `${this.page} ${container != null ? ` [${container}]` : ''}`
        }
    }
    public static dispatchUserActivityDebounced = _.debounce((command) => dispatch(userActivityInsert(command)), 500)

    public static openedSquadScreen() {
        return (dispatch) => {
            let command = this.buildCommand(
                null,
                'Opened Squad',
                null
            )
            dispatch(userActivityInsert(command))
        }
    }

    public static filterSelectSquad(squadName: string) {
        return (dispatch) => {
            let command = this.buildCommand(
                this.containers.filter,
                `Selected Squad:${squadName}`,
                null
            )
            dispatch(userActivityInsert(command))
        }
    }

    public static filterSelectPositions(positions: Array<string>) {
        return (dispatch) => {
            let command = this.buildCommand(
                this.containers.filter,
                `Selected Position:${positions.join(', ')}`,
                null
            )
            this.dispatchUserActivityDebounced(command)
        }
    }

    public static filterContractExpiryRange(fromMonthes: number, toMonthes: number) {
        return (dispatch) => {

            let command = this.buildCommand(
                this.containers.filter,
                `Contract Expiry:${ContractExpirySelector.toYrOrMonth(fromMonthes)} - ${ContractExpirySelector.toYrOrMonth(toMonthes)}`,
                null
            )
            this.dispatchUserActivityDebounced(command)
        }
    }

    public static filterAgeRange(fromYear: number, maxYear: number) {
        return (dispatch) => {
            let command = this.buildCommand(
                this.containers.filter,
                `Selected Age:${fromYear}-${maxYear}`,
                null
            )
            this.dispatchUserActivityDebounced(command)
        }
    }

    public static edit() {
        return (dispatch) => {
            let command = this.buildCommand(
                this.containers.edit,
                'Edit',
                null
            )
            dispatch(userActivityInsert(command))
        }
    }

    public static cancel() {
        return (dispatch) => {
            let command = this.buildCommand(
                this.containers.edit,
                'Cancel',
                null
            )
            dispatch(userActivityInsert(command))
        }
    }

    public static save() {
        return (dispatch) => {
            let command = this.buildCommand(
                this.containers.edit,
                'Save',
                null
            )
            dispatch(userActivityInsert(command))
        }
    }

    public static onEditPosition(playerId: number, firstPositionCode: string, secondPositionCode: string) {
        return (dispatch) => {
            let command = this.buildCommand(
                this.containers.edit,
                `Select Position: ${firstPositionCode}${secondPositionCode ? ` secondary ${secondPositionCode}` : ''}`,
                playerId
            )
            dispatch(userActivityInsert(command))
        }
    }

    public static onEditContractExpiration(playerId: number, contractExpiration: Date) {
        return (dispatch) => {
            let command = this.buildCommand(
                this.containers.edit,
                `Select Contract Expiry: ${moment(contractExpiration).format('MMM DD yyyy')}`,
                playerId
            )
            dispatch(userActivityInsert(command))
        }
    }

    public static openRemovePlayer(playerId: number) {
        return (dispatch) => {
            let command = this.buildCommand(
                this.containers.edit,
                'Opened Remove Player',
                playerId
            )
            dispatch(userActivityInsert(command))
        }
    }

    public static removedPlayer(playerId: number, reason: string) {
        return (dispatch) => {
            let command = this.buildCommand(
                this.containers.edit,
                `Removed Player ${reason}`,
                playerId
            )
            dispatch(userActivityInsert(command))
        }
    }

    public static cancelRemovePlayer(playerId: number) {
        return (dispatch) => {
            let command = this.buildCommand(
                this.containers.edit,
                'Cancelled Remove Player',
                playerId
            )
            dispatch(userActivityInsert(command))
        }
    }

    public static addedVideo(playerId: number) {
        return (dispatch) => {
            let command = this.buildCommand(
                this.containers.edit,
                'Added Video',
                playerId
            )
            dispatch(userActivityInsert(command))
        }
    }

    public static openAvailability(playerId: number) {
        return (dispatch, getState: () => AppState) => {
            const substate = Selectors.getRoot(getState());
            const recommendedIds = substate?.recommendedPlayersIds;
            const isPlayerRecommended = recommendedIds?.includes(playerId)
            
            let command = this.buildCommand(
                isPlayerRecommended ? this.containers.recommendedPlayer : this.containers.availability,
                isPlayerRecommended ? 'Opened Transfer Details' : 'Opened Sale/Loan Details',
                playerId
            )
            dispatch(userActivityInsert(command))
        }
    }

    public static availabilitySaved(playerId: number, prev: { buy: MySquadToBuy, loan: MySquadToLoan }, current: { buy: MySquadToBuy, loan: MySquadToLoan }) {
        return (dispatch, getState: () => AppState) => {
            let prevCommand = this.getStatus(prev.buy, prev.loan);
            let newCommand = this.getStatus(current.buy, current.loan);
            
            const substate = Selectors.getRoot(getState());
            const recommendedIds = substate?.recommendedPlayersIds;
            const isPlayerRecommended = recommendedIds?.includes(playerId)

            let command1 = this.buildCommand(
                isPlayerRecommended ? this.containers.recommendedPlayer : this.containers.availability,
                `Listed Player: ${newCommand}`,
                playerId
            )
            if(newCommand != '')
                dispatch(userActivityInsert(command1))

            if ((current.buy == null || current.loan == null) && (Object.keys(prev).length != 0) && (Object.keys(prev.buy).length != 0 || Object.keys(prev.loan).length != 0)) {
                let command2 = this.buildCommand(
                    isPlayerRecommended ? this.containers.recommendedPlayer : this.containers.availability,
                    `Delisted Player: ${prevCommand}`,
                    playerId
                )
                dispatch(userActivityInsert(command2))
            }
        }
    }




    private static getStatus(buy: MySquadToBuy, loan: MySquadToLoan) {
        let availabilityText = '';

        if (buy != null) {
            availabilityText += 'For Sale (';

            if (buy.request) {
                availabilityText += 'Price Upon Request';
            }
            if (buy.askingPrice) {
                availabilityText += 'Asking Price: ' + buy.askingPrice;

                if (buy.sellOn && buy.sellOn > 0) {
                    availabilityText = availabilityText + ' Sell-on: ' + buy.sellOn;
                }
            }

            availabilityText += ')';
        }

        if (loan != null) {
            if (availabilityText.length > 0) {
                availabilityText += ', ';
            }

            availabilityText += 'For Loan (';

            if (loan.request) {
                availabilityText += 'Price Upon Request';
            }
            if (loan.monthlyLoan) {
                availabilityText += 'Asking Price: ' + loan.monthlyLoan;
            }

            availabilityText += ')';
        }

        return availabilityText;
    }

    public static closeBunner() {
        return (dispatch) => {         
            dispatch(userActivityInsert({
                Message: 'Closed',
                PageType: PageType.Squad,
                PageName: 'Squad [Recommended Availability Banner]',
            }))                
        }
    }
}
