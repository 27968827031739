import createApiThunk from 'services/createApiThunk';
import { getAuthAspId } from 'store/auth/authReducer';
import {
    AD_FORCE_UPDATE_REMOVE_AD,
} from './ad-force-update.reducer';


export const removeAdVirtualSummit = id => async (dispatch, getState) => {
    let state = getState();
    const aspId = getAuthAspId(state);

    const request = {
        type: AD_FORCE_UPDATE_REMOVE_AD,
        method: 'post',
        payload: id,
        url: `PlayerAds/DeactivatePlayerAd/${aspId}`,
    };

    await dispatch(createApiThunk(request));
};
