import { mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { TEST_INTEREST_BATCH } from './indexReducer';
import { plusPitchesFetch, closeTestInterestModal } from './actions';

const testInterestBatchEpic = (action$, state$) => {
    return action$.pipe(
        ofType(TEST_INTEREST_BATCH.SUCCESS),
        mergeMap(() => [closeTestInterestModal(), plusPitchesFetch()]),
    );
};

export default testInterestBatchEpic;
