import axios from 'axios-config';
import { CancelToken } from 'axios';
import { SearchResult, SearchItemForAgency } from "./model/search-result"
import { AgencyPlayerDisplayInfo } from "./model/search-player-item-for-agency"

import { SearchRequest } from './model/search-request'
import { SearchFilterCriteria, CardTextOfPlayer } from './model/search-filter-criteria';
import { UpsellBannerResponse } from './model/upsell-banner';


export default class SearchService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async search(request: SearchRequest, cancelToken: CancelToken): Promise<SearchResult<SearchItemForAgency>> {

        const { data } = await axios.post(
            `api/v2/AgencySearch`,
            request,
            { ...this.axiosConfig, cancelToken: cancelToken }
        )
        return data
    }

    public static async getCriteria(): Promise<SearchFilterCriteria> {

        const { data } = await axios.get(
            `api/v2/AgencySearch/Criteria`,
            { ...this.axiosConfig }
        )
        return data
    }

    public static async getUpsellBannerData(): Promise<UpsellBannerResponse> {
        const { data } = await axios.get(
            `api/v2/Agency/GetUpgradeBanner`,
            { ...this.axiosConfig }
        )
        return data
    }
    
    public static async getInTouch() {
        await axios.post<any>(`api/v2/Agency/SendToGetInTouch`);

    }
    
    public static async closeUpsellBanner() {
        await axios.post<any>(`api/v2/Agency/CloseUpgradeBanner`);
    }
    
}