import { StateController } from 'utils/action-declaration';

import { AppState } from 'root.reducer'
import { PlayerStatisticService } from 'api/player/player-statistic-service'
import getDataForChart from 'app/players-comparison/helpers/get-data-for-chart';
import { PitchPerformanceRadarItem } from 'ts-components/pitch/models/pitch-performance-radar-item'
import { ShortlistService } from "api/shortlist/shortlist-service"

class OutdatedAdsState {
    playerStatistic: { [id: string]: Array<PitchPerformanceRadarItem>; };
    shortListedPlayers: Array<number>;
    isLoading: boolean;
    initialized: boolean;
}

const defaultState: OutdatedAdsState = {
    playerStatistic: {},
    isLoading: false,
    initialized: false,
    shortListedPlayers: []
}

const stateController = new StateController<OutdatedAdsState>(
    "LANDING_PAGE/PLAYER_STATISTIC",
    defaultState
);


class Actions {

    public static loadShortListedPlayers() {
        return async (dispatch, getState: () => AppState) => {
            let playerIds = await ShortlistService.loadShortListedPlayers();
            dispatch(stateController.setState({ shortListedPlayers: playerIds }));
        }
    }

    public static togglePlayerInShortList(playerId: number, prevState: boolean) {
        return async (dispatch, getState: () => AppState) => new Promise(async (resolve, reject) => {
            const wasPlayerShortlisted = prevState
            try {

                let players = getState().landingPage.playerStatistic.shortListedPlayers;
                
                if (wasPlayerShortlisted) {
                    dispatch(stateController.setState({ shortListedPlayers: players.filter(item => item !== playerId) }));
                } else {
                    dispatch(stateController.setState({ shortListedPlayers: [...players, playerId] }));
                }
                
                if (!wasPlayerShortlisted) {
                    await ShortlistService.addToShortlist(playerId);
                }
                else {
                    await ShortlistService.removeFromShortlist(playerId);
                }

                resolve(true)
            } catch(e) {
                console.log(e)
                let players = getState().landingPage.playerStatistic.shortListedPlayers;
                if (wasPlayerShortlisted) {
                    dispatch(stateController.setState({ shortListedPlayers: [...players, playerId] }));
                } else {
                    dispatch(stateController.setState({ shortListedPlayers: players.filter(item => item !== playerId) }));
                }
                reject(e)
            }
        })
    }

    public static ensurePreloadStatistic(dependAction?: () => void) {

        return async (dispatch, getState: () => AppState) => {
            let subState = getState().landingPage;

            var states: Array<boolean> = [
                subState.plusPitchV2.initialized,
                subState.plusPitchV2.initialized,
            ];

            if (!states.includes(false) && !subState.playerStatistic.initialized) {

                let activePitches = [];
                for (var group of subState.pitchV2.activePitchesGroups) activePitches = [...activePitches, ...group.pitches.map(item => item.player.id)];

                let allPlayerIds = [
                    ...subState.plusPitchV2.testedPlusPitches.filter(item => item.player !== null).map(item => item.player.id),
                    ...subState.plusPitchV2.activePlusPitches.filter(item => item.player !== null).map(item => item.player.id),
                    ...subState.pitchV2.testedPitches.map(item => item.player.id),
                    ...activePitches,
                ]

                let withoutDuplicates = [];
                for (var playerId of allPlayerIds) if (!withoutDuplicates.includes(playerId)) withoutDuplicates.push(playerId);

                let statistic = await PlayerStatisticService.getPlayerStatisticBatchByPlayerIds(withoutDuplicates);
                var radarCahrtinfoPerPlayer: { [id: string]: any; } = {};
                for (var stat of statistic) {

                    const data = getDataForChart({
                        firstPlayer: stat.firstPlayer,
                        secondPlayer: null,
                        thirdPlayer: null,
                        comparison: stat
                    });

                    radarCahrtinfoPerPlayer[stat.key] = data;
                }

                dispatch(stateController.setState({ playerStatistic: radarCahrtinfoPerPlayer, initialized: true }));
            }
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    OutdatedAdsState as State,
    Actions as Actions,
    stateController as Controller
};



