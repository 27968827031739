import axios from 'axios-config';
import { PlayerProposalsGroupedByPlayerAdModel } from './models/player-proposals.model';
import { PlayerAdModel } from './models/otdated-ads.model';
import { FriendlyProposalModel } from './models/friendly-proposal.model';
import { MySquadPlayerAdsRecommendation } from './models/club-ads-proposal.model';

export default class CustomSignIn {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async getAdsRecommendationByUserId(userId: number): Promise<Array<PlayerProposalsGroupedByPlayerAdModel>> {
        const { data } = await axios.get(
            `api/Recommend/GetAdsRecommendationByUserId/${userId}`,
            this.axiosConfig
        )
        return data;
    }

    public static async notInterested(playerId: number, playerAdId: number): Promise<any> {
        await axios.post(
            `api/Recommend/DeclinePlayerAd`,
            { playerId: playerId, playerAdId: playerAdId },
            this.axiosConfig
        );
    }

    public static async declinePlayer(playerId: number): Promise<any> {
        await axios.post(
            `api/Recommend/DeclinePlayer/-1/${playerId}`,
            this.axiosConfig
        );
    }

    public static async getOutdatedAdsWithAction(userId: number, updateAdId: number, removeAdId: number): Promise<Array<PlayerAdModel>> {
        let url = `api/PlayerAds/MyExpiredPlayerAds/${userId}/Get`;

        if (updateAdId != null)
            url = url + `?updateAdId=${updateAdId}`
        if (removeAdId != null)
            url = url + `?removeAdId=${removeAdId}`

        const { data } = await axios.post(url, {}, this.axiosConfig);
        return data.ads;
    }

    public static async updatePlayerAd(userId: number, adId: number): Promise<any> {
        await axios.post(
            `api/PlayerAds/MyExpiredPlayerAds/Update/${userId}`,
            { PlayerAdId: adId },
            this.axiosConfig
        );
    }

    public static async removePlayerAd(userId: number, adId: number): Promise<any> {
        await axios.post(
            `api/PlayerAds/MyExpiredPlayerAds/Remove/${userId}`,
            { PlayerAdId: adId },
            this.axiosConfig
        );
    }


    public static async getFriendlyProposals(userId: number, interestMatchId: number, notAvailableMatchId: number): Promise<Array<FriendlyProposalModel>> {
        let url = `api/FriendlyMatches/Proposals/${userId}`;

        if (interestMatchId != null)
            url = url + `?interestMatchId=${interestMatchId}`
        if (notAvailableMatchId != null)
            url = url + `?notAvailableMatchId=${notAvailableMatchId}`

        const { data } = await axios.get(
            url,
            this.axiosConfig
        )
        return data;
    }

    public static async declareInterestForFriendly(aspId: string, friendlyId: number): Promise<any> {
        await axios.post(
            `api/FriendlyMatches/DeclareInterest/${aspId}`,
            friendlyId,
            this.axiosConfig
        );
    }

    public static async unavailableFriendly(aspId: string, friendlyId: number): Promise<any> {
        await axios.post(
            `api/FriendlyMatches/SetUnavailableStatusForMatch/${aspId}`,
            friendlyId,
            this.axiosConfig
        );
    }


    public static async getClubAdsProposals(squadId: number, userId: number): Promise<Array<MySquadPlayerAdsRecommendation>> {
        const { data } = await axios.get(
            `api/MySquad/GetMySquadRecommendationPerPlayerWithPlayerInfo/${squadId}/${userId}`,
            this.axiosConfig
        )
        return data;
    }

    public static async pitchPlayer(userId: number, playerAdId: number, amount: number, playerId: number, squadId?: number, squadName?: string): Promise<any> {
        await axios.post(
            `api/ClubAds/PitchPlayer`,
            { userId: userId, playerAdId: playerAdId, amount: amount, playerId: playerId, squadName: squadName, squadId: squadId },
            this.axiosConfig
        );
    }
}