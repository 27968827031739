import React from 'react'
import styled from 'styled-components'
import { ReadFilled } from '@ant-design/icons'
import { Actions as EventMiscController } from 'app/events/redux/misc.controller'
import { connect } from 'react-redux';
import Button from 'components/Buttons/Button/Button';

type Props = {
    openBlogIframeModal: () => void;
}

function BlogIframeModalCTA(props: Props) {

    return (
        <Btn large type="primary" className="link" onClick={props.openBlogIframeModal}>
            <ReadFilled />
            'How-to' guide for the TransferRoom Summit
        </Btn>
    )
}

const Btn = styled(Button)`
    font-weight: 600;
    margin-right: 20px;
    display: flex;
    align-items: center;

    span {
        margin-right: 6px;
    }
`

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    openBlogIframeModal: EventMiscController.openBlogIframeModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(BlogIframeModalCTA)



