import {
    FRIENDLY_MATCH_DECLARE_INTEREST,
    FRIENDLY_MATCH_SET_UNAVAILABLE_STATUS
} from 'store/actionTypes';

type Ids = Array<number>

export default function processingIds(ids: Ids = [], action) {
    switch(action.type) {
        case FRIENDLY_MATCH_DECLARE_INTEREST.REQUEST: {
            return [ ...ids, action.payload.ad.id ]
        }
        case FRIENDLY_MATCH_DECLARE_INTEREST.SUCCESS: {
            return [ ...ids.filter(item => item !== action.payload.request.ad.id) ]
        }
        case FRIENDLY_MATCH_DECLARE_INTEREST.ERROR: {
            return [ ...ids.filter(item => item !== action.payload.ad.id) ]
        }

        case FRIENDLY_MATCH_SET_UNAVAILABLE_STATUS.REQUEST: {
            return [ ...ids, action.payload.ad.id ]
        }
        case FRIENDLY_MATCH_SET_UNAVAILABLE_STATUS.SUCCESS: {
            return [ ...ids.filter(item => item !== action.payload.request.ad.id) ]
        }
        case FRIENDLY_MATCH_SET_UNAVAILABLE_STATUS.ERROR: {
            return [ ...ids.filter(item => item !== action.payload.ad.id) ]
        }
        
        default: {
            return ids
        }
    }
}