import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer'
import { LoanInsightsService } from 'api/loan-insights/loan-insights-service';
import { ActiveLoansModel } from 'api/loan-insights/models/active-loans-model';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import BenchMarkingModel from 'api/loan-insights/models/benchmarking-model'

class LoanBenchmarkingState {
    isLoading: boolean;
    data: BenchMarkingModel;
}

const defaultState: LoanBenchmarkingState = {
    isLoading: false,
    data: null
}

const stateController = new StateController<LoanBenchmarkingState>(
    "LOAN_INSIGHTS/LOAN_BENCHMARKING",
    defaultState
);

class Actions {

    public static loadData() {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let userId = state.auth.userId;
            let squadId = state.auth.squadId;

            dispatch(stateController.setState({ isLoading: true }));
            var data: BenchMarkingModel = await LoanInsightsService.getBenchmarking(squadId, userId);

            data.loanerRatings.forEach((item, index) => item.index = index)
            data.loaneeRatings.forEach((item, index) => item.index = index)

            dispatch(stateController.setState({ isLoading: false, data: data }));
        }
    }


    public static dispose() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ ...defaultState }, "DISPOSING LOAN INSIGHTS"));
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    LoanBenchmarkingState as State,
    Actions as Actions,
    stateController as Controller
};



