import React, { useState } from 'react';
import ClubImage from 'components/images/club-image';
import { translate } from 'services/localization'
import { AppState } from 'root.reducer';
import * as ConfirmedAttendeesController from 'app/events/redux/virtual-summit/confirmed-attendees.controller'
import { connect } from 'react-redux';
import DynamicClubName from 'ts-components/utils/dynamic-club-name/dynamic-club-name'

interface StateProps {
    headerHeight: number
}

interface DispatchProps {
    loadAttendees: () => void
}
interface OwnProps {
    title: string;
    titleWrap?: boolean;
    notRegisteredState?: boolean;
    attendees: any,
    showDetails
}

class Attendees extends React.Component<StateProps & DispatchProps & OwnProps, {}> {

    componentDidMount() {
        this.props.loadAttendees()
    }

    render() {

        const {
            title,
            notRegisteredState = false,
            titleWrap = false
        } = this.props

        if (this.props.attendees.length === 0) return null

        const isEventPageTabsAreActive = false
        const extraHeight = isEventPageTabsAreActive ? 43 : 0

        return (
            <div className="ep-confirmed-attendees">
                <div
                    className={`ep-confirmed-attendees__head ${notRegisteredState ? 'not-registered-state' : ''}`}
                    style={notRegisteredState ? {} : { top: this.props.headerHeight + extraHeight }}
                // 43px = static and well known height of the first fixed tab on the page
                >
                    <p className={`column-name__one ${titleWrap ? 'default-wrap' : ''}`}>{title}</p>
                    {this.props.showDetails && <><div className="column-name__two-three">
                        <p style={{ gridArea: 'tr', textAlign: 'center' }}>Transfers</p>
                        <p style={{ gridArea: 'in' }}>In</p>
                        <p style={{ gridArea: 'out' }}>Out</p>
                    </div>
                        <div className="column-name__four">General Network</div> </>}
                </div>
                <div className="ep-confirmed-attendees__body">
                    <Grid
                        attendees={this.props.attendees}
                        countlimit={1000}
                        showDetails={this.props.showDetails}
                    />
                </div>
            </div>
        )
    }
}

const Grid = ({ attendees = [], countlimit = 200, showDetails }) => {
    return (
        <ul className="ep-confirmed-attendees__attendee-items">
            {attendees && attendees.slice(0, countlimit).map((item, index) => {
                return <Row key={`${index}-${item.id}-list`} item={item} showDetails={showDetails} />
            })}
        </ul>
    )
};

const Row = ({ item, showDetails }) => {

    const [isHovered, setIsHovered] = useState(false)

    const canShowTooltip = item.generalNetworkingStepCompleted
                        || item.transferInStepCompleted
                        || item.transferOutStepCompleted || item.countOfAvailablePlayers > 0

    return (
        <li className="attendee-item">
            <div className="attendee-item__club" style={canShowTooltip && isHovered ? { cursor: 'pointer' } : { cursor: 'default' }}>
                {showDetails ? <Tooltip
                    show={canShowTooltip && isHovered}
                    onMouseOver={() => { setIsHovered(true) }}
                    onMouseOut={() => { setIsHovered(false) }}

                    clubName={item.squadName}
                    transfersIn={item.adPositionNames}
                    transfersOut={item.countOfAvailablePlayers}
                    generalNetworking={item.generalNetworkingStepCompleted}
                    transferInStepCompleted={item.transferInStepCompleted}
                    transferOutStepCompleted={item.transferOutStepCompleted}
                    generalNetworkingStepCompleted={item.generalNetworkingStepCompleted}
                >
                    <>
                        <div className="club-logo">
                            <ClubImage customSize={24} name={item.squadLogo} />
                        </div>
                        <div className="club-name">
                            <DynamicClubName 
                                style={{color: 'black', fontWeight: 'bold'}} 
                                clubName={item.squadName} 
                                shortClubName={item.shortSquadName} 
                                oneDigitLengh={7}
                            />
                        </div>
                    </>
                </Tooltip> :
                    <>
                        <div className="club-logo">
                            <ClubImage customSize={24} name={item.squadLogo} />
                        </div>
                        <div className="club-name">
                            <DynamicClubName 
                                style={{color: 'black'}} 
                                clubName={item.squadName} 
                                shortClubName={item.shortSquadName} 
                                oneDigitLengh={7}
                            />
                        </div>
                    </>
                }

            </div>
            {showDetails && <>
                <div
                    className={`attendee-item__check-1 ${item.transferInStepCompleted ? 'checked' : 'unchecked'}`}
                    onMouseOver={() => { item.transferInStepCompleted ? setIsHovered(true) : () => { } }}
                    onMouseOut={() => { item.transferInStepCompleted ? setIsHovered(false) : () => { } }}
                >
                    <i className="fa fa-check" />
                </div>
                <div
                    className={`attendee-item__check-2 ${item.transferOutStepCompleted ? 'checked' : 'unchecked'}`}
                    onMouseOver={() => { item.transferOutStepCompleted ? setIsHovered(true) : () => { } }}
                    onMouseOut={() => { item.transferOutStepCompleted ? setIsHovered(false) : () => { } }}
                >
                    <i className="fa fa-check" />
                </div>
                <div
                    className={`attendee-item__check-3 ${item.generalNetworkingStepCompleted ? 'checked' : 'unchecked'}`}
                    onMouseOver={() => { item.generalNetworkingStepCompleted ? setIsHovered(true) : () => { } }}
                    onMouseOut={() => { item.generalNetworkingStepCompleted ? setIsHovered(false) : () => { } }}
                >
                    <i className="fa fa-check" />
                </div>
            </>}
        </li>
    )
}

interface TooltipProps {
    children: JSX.Element;
    onMouseOut: () => void;
    onMouseOver: () => void;
    show: boolean;
    clubName: string;
    transfersIn: Array<string>;
    transfersOut: number;
    generalNetworking: boolean;
    transferInStepCompleted: boolean
    transferOutStepCompleted: boolean
    generalNetworkingStepCompleted: boolean
}

const Tooltip = ({
    show,
    children,
    clubName,
    transfersIn,
    transfersOut,
    generalNetworking,
    transferInStepCompleted,
    transferOutStepCompleted,
    generalNetworkingStepCompleted,
    ...otherProps
}: TooltipProps) => {

    return (
        <div {...otherProps} className={`ep-confirmed-attendees__tooltip ${show ? 'hovered' : ''}`}>
            {children}
            <div style={show ? { display: 'block' } : { display: 'none' }} className="ep-confirmed-attendees__tooltip-body">
                <p><b>{clubName}</b></p>
                {transferInStepCompleted && transfersIn.length > 0 && (
                    <p>{transfersIn.length === 1 ? 'Transfer In' : 'Transfers In'} ({transfersIn.join(', ')})</p>
                )}
                {transferOutStepCompleted && transfersOut > 0 && (
                    <p>{transfersOut === 1 ? 'Transfer Out' : 'Transfers Out'} ({transfersOut} {transfersOut === 1 ? 'player' : 'players'} available)</p>
                )}
                {generalNetworkingStepCompleted && generalNetworking && <p>General Networking</p>}
                <div className="triangle" />
            </div>
        </div>
    )
}

const mapStateToProps = (state: AppState): StateProps => {
    return {
        headerHeight: (state as any).commonStyles.header.headerHeight
    }
}
const mapDispatchToProps: DispatchProps = {
    loadAttendees: ConfirmedAttendeesController.Actions.loadConfirmedAttendeesData
}
export default connect(mapStateToProps, mapDispatchToProps)(Attendees)