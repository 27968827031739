import { combineReducers } from 'redux';
import * as MiscController from './misc.controller'
import * as AgencyEventController from './agency-event.controller'
import * as ConfirmedAttendeesController from './virtual-summit/confirmed-attendees.controller'
import * as MeetingPreparationController from './virtual-summit/meeting-preparation.controller'
import * as B2MatchController from './virtual-summit/b2match.controller'
import * as B2MatchObjectivitiesController from './virtual-summit/objectivities.controller'
import * as B2MatchTransfersInController from './virtual-summit/transfers-in.controller'
import * as B2MatchTransfersOutController from './virtual-summit/transfers-out.controller'
import * as SummitSurveyController from 'app/summit-survey/summit-survey.controller'


export class VirtualSummitState {
    confirmedAttendees: ConfirmedAttendeesController.State;
    meetingPreparation: MeetingPreparationController.State;
    b2match: B2MatchController.State;
    b2matchObjectivities: B2MatchObjectivitiesController.State;
    b2matchTransfersIn: B2MatchTransfersInController.State;
    b2matchTransfersOut: B2MatchTransfersOutController.State
}

export class EventsPageState {
    virtualSummit: VirtualSummitState;
    agencyEvent: AgencyEventController.State;
    misc: MiscController.State;
    summitSurvey: SummitSurveyController.State
}

const virtualSummitReducers = combineReducers({
    confirmedAttendees: ConfirmedAttendeesController.Reducer,
    meetingPreparation: MeetingPreparationController.Reducer,
    b2match: B2MatchController.Reducer,
    b2matchObjectivities: B2MatchObjectivitiesController.Reducer,
    b2matchTransfersIn: B2MatchTransfersInController.Reducer,
    b2matchTransfersOut: B2MatchTransfersOutController.Reducer
})

export default combineReducers({
    misc: MiscController.Reducer,
    virtualSummit: virtualSummitReducers,
    agencyEvent: AgencyEventController.Reducer,
    summitSurvey: SummitSurveyController.Reducer
})