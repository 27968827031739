import moment from 'moment'
import config from 'config';
import { AppState } from 'root.reducer'
import { getAuth } from 'store/auth/authReducer' 
import { StateController } from 'utils/action-declaration'
import LiveChatController from 'services/live-chat-controller'
import { UserTypeEnum } from 'constants/enums'

class MobileAppPromoModalState {
    isModalOpen: boolean
}

const defaultState: MobileAppPromoModalState = {
    isModalOpen: false,
}

const stateController = new StateController<MobileAppPromoModalState>(
    'MOBILE_APP_PROMO_MODAL',
    defaultState
)

class Actions {

    public static init() {
        return async (dispatch, getState: () => AppState) => {
            const oldDate = localStorage.getItem('mobileAppPromoModal')
            const isLta = getAuth(getState()).agencyId !== null && getAuth(getState()).agencyPermission.isLowerTier;
            const isAvoidedLeague = getAuth(getState()).isLeague;
            const isPlayer = getAuth(getState()).userTypeId === UserTypeEnum.Player;
            const isModalHidden = window.location.href.includes('/my-messages') || window.location.href.includes('agency/player/confirm-new') || window.location.href.includes('/add-players') || isLta || isPlayer;
            
            if (isModalHidden || isAvoidedLeague) {
                dispatch(stateController.setState({ isModalOpen: false }))
                return;
            }

            if (oldDate === 'Never again!') {
                dispatch(stateController.setState({ isModalOpen: false }));
                return;
            }

            const iphone = getAuth(getState()).metaInfo?.deviceVersion.toLowerCase().includes('iphone')
            const currentDate = moment()
            const difference = currentDate.diff(oldDate, 'days');

            if (!!iphone && !oldDate) {
                await LiveChatController.hide()
                dispatch(stateController.setState({ isModalOpen: true }))
                return
            }
            if (difference >= 7 && !!iphone) {
                await LiveChatController.hide()
                dispatch(stateController.setState({ isModalOpen: true }))
            } else {
                dispatch(stateController.setState({ isModalOpen: false }))
            }
        }
    }

    public static openApp() {
        return (dispatch, getState: () => AppState) => {
            localStorage.setItem('mobileAppPromoModal', 'Never again!');

            window.open(`${config.baseUrl}/mobileapp`, '_self');

            LiveChatController.show()
            dispatch(Actions.init())
        }
    }

    public static continue() {
        return (dispatch, getState: () => AppState) => {
            localStorage.setItem('mobileAppPromoModal', `${moment()}`)
            LiveChatController.show()
            dispatch(Actions.init())
        }
    }
}

class Selectors {}



const reducer = stateController.getReducer()

export {
    reducer as Reducer,
    MobileAppPromoModalState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
}