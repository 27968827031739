//import { sections } from '../../components/filter/filter-sections';
import getSections from '../../tab-ads/components/filter/filter-sections';
import {
    CLUB_ADS_SCREEN_DISPOSE,
    CLUB_ADS_FILTER_CHANGED,
    CLUB_ADS_FILTER_SECTION_CLEAR,
    CLUB_ADS_FILTER_KEYWORD_RESET,
    CLUB_ADS_FILTER_CRITERIA_CLEAR,
    CLUB_ADS_FILTER_STRUCTURE_FETCH,
    CLUB_ADS_FILTER_COLLAPSE_TOGGLE,
    CLUB_ADS_MY_PITCHES_FILTER_STRUCTURE_FETCH,
    CLUB_ADS_FETCH,
} from 'store/actionTypes';
import compose from 'lodash/fp/compose';
import { adjustCriteriaBySelectionRanges } from './utils';

const sections = getSections();

const initialState = {
    expandedSections: Object.values(sections).map(s => s.id),
    structure: {
        toBuy: sections.transferType.default.toBuy,
        toLoan: sections.transferType.default.toLoan,
        transferFeeMin: sections.transferFee.default.transferFeeMin,
        transferFeeMax: sections.transferFee.default.transferFeeMax,
        transferFeeRangeSteps:sections.transferFee.default.transferFeeRangeSteps,
        monthlyLoanFeeMin: sections.monthlyLoanFee.default.monthlyLoanFeeMin,
        monthlyLoanFeeMax: sections.monthlyLoanFee.default.monthlyLoanFeeMax,
        monthlyLoanFeeRangeSteps: sections.monthlyLoanFee.default.monthlyLoanFeeRangeSteps,
        positions: sections.position.default.positions,
        statuses: sections.status.default.statuses,
        isLeftFooted: sections.isLeftFooted.default.isLeftFooted,
    },
};

const initialCriteria = {
    keyword: '',
    ...sections.transferType.default,
    ...sections.transferFee.default,
    ...sections.monthlyLoanFee.default,
    positions: [],
    ...sections.league.default,
    statuses: [],
    ...sections.isLeftFooted.default,
};

initialState.criteria = initialCriteria;
export default (filter = initialState, action) => {
    switch (action.type) {
        case CLUB_ADS_SCREEN_DISPOSE: {
            return initialState;
        }

        case CLUB_ADS_FETCH.SUCCESS: {
            let filterData = action.payload.data.filterData;
            let forLoan = action.payload.request.payload.isForLoan;
            let forSale = action.payload.request.payload.isForSale;

            if (filterData !== null) {
                let monthlyLoanFeeMin = Math.min(filterData.monthlyLoanFeeMin, filterData.grossSalaryMin);
                let monthlyLoanFeeMax = Math.max(filterData.monthlyLoanFeeMax, filterData.grossSalaryMax);
                if(forLoan && !forSale)
                {
                    monthlyLoanFeeMin = filterData.monthlyLoanFeeMin;
                    monthlyLoanFeeMax = filterData.monthlyLoanFeeMax;
                }
                if(!forLoan && forSale)
                {
                    monthlyLoanFeeMin = filterData.grossSalaryMin;
                    monthlyLoanFeeMax = filterData.grossSalaryMax;
                }

                let criteria = adjustCriteriaBySelectionRanges(
                    filter.criteria,
                    filterData,
                    filter.structure.monthlyLoanFeeMin !== monthlyLoanFeeMin || filter.structure.monthlyLoanFeeMax !== monthlyLoanFeeMax,
                    filter.structure.transferFeeMin !== filterData.transferFeeMin || filter.structure.transferFeeMax !== filterData.transferFeeMax,
                    forLoan,
                    forSale
                );

                return {
                    ...filter,
                    structure: {
                        ...filter.structure,
                        monthlyLoanFeeMin: monthlyLoanFeeMin,
                        monthlyLoanFeeMax: monthlyLoanFeeMax,
                        transferFeeMin: filterData.transferFeeMin,
                        transferFeeMax: filterData.transferFeeMax,
                    },
                    criteria: {...criteria, newPositionAdded: false},
                };
            }
            return { ...filter };
        }

        case CLUB_ADS_FILTER_CHANGED: {
            let change = action.payload;
            if (
                change.keyword !== undefined &&
                change.keyword !== filter.criteria.keyword
            ) {
                change = { ...change };
            }

            let criteria = { ...filter.criteria, ...change };
            if(change.newPositionAdded){
                criteria ={...criteria, newPositionAdded: true}
            }

            return {
                ...filter,
                criteria: criteria,
            };
        }
     
        case CLUB_ADS_FILTER_SECTION_CLEAR: {
            const section = action.payload;
            const isTransferFee = section.propName === 'transferFee';
            const isStatus = section.propName === 'status';
            const isPosition = section.propName === 'position';
            if (isTransferFee || section.propName === 'monthlyLoanFee') {
                if (isTransferFee) {
                    return {
                        ...filter,
                        criteria: {
                            ...filter.criteria,
                            transferFeeMin: filter.structure.transferFeeMin,
                            transferFeeMax: filter.structure.transferFeeMax,
                        },
                    };
                }
                return {
                    ...filter,
                    criteria: {
                        ...filter.criteria,
                        monthlyLoanFeeMin: filter.structure.monthlyLoanFeeMin,
                        monthlyLoanFeeMax: filter.structure.monthlyLoanFeeMax,
                    },
                };
            }
            if (isStatus) {
                return {
                    ...filter,
                    criteria: {
                        ...filter.criteria,
                        ...{ statuses: [] },
                    },
                };
            }
            if (isPosition) {
                return {
                    ...filter,
                    criteria: {
                        ...filter.criteria,
                        ...{ positions: [] },
                    },
                };
            }
            return {
                ...filter,
                criteria: {
                    ...filter.criteria,
                    ...section.default,
                },
            };
        }

        case CLUB_ADS_FILTER_KEYWORD_RESET: {
            return {
                ...filter,
                criteria: { ...filter.criteria, keyword: '' },
            };
        }

        case CLUB_ADS_FILTER_CRITERIA_CLEAR: {
            return {
                ...filter,
                criteria: initialCriteria,
            };
        }
        case CLUB_ADS_FILTER_STRUCTURE_FETCH.REQUEST:
        case CLUB_ADS_MY_PITCHES_FILTER_STRUCTURE_FETCH.REQUEST: {
            return {
                ...filter,
                isLoading: true,
            };
        }
        case CLUB_ADS_FILTER_STRUCTURE_FETCH.ERROR:
        case CLUB_ADS_MY_PITCHES_FILTER_STRUCTURE_FETCH.ERROR: {
            return {
                ...filter,
                isLoading: false,
            };
        }
        case CLUB_ADS_FILTER_STRUCTURE_FETCH.SUCCESS:
        case CLUB_ADS_MY_PITCHES_FILTER_STRUCTURE_FETCH.SUCCESS: {
            return {
                ...filter,
                isLoading: false,
                criteria: {
                    ...filter.criteria,
                    transferFeeMin: action.payload.data.transferFeeMin,
                    transferFeeMax: action.payload.data.transferFeeMax,
                    monthlyLoanFeeMin: action.payload.data.monthlyLoanFeeMin,
                    monthlyLoanFeeMax: action.payload.data.monthlyLoanFeeMax,
                },
                structure: { ...filter.structure, ...action.payload.data },
            };
        }
        case CLUB_ADS_FILTER_COLLAPSE_TOGGLE: {
            const toggledSectionId = action.payload;
            const { expandedSections } = filter;
            return {
                ...filter,
                expandedSections: expandedSections.includes(toggledSectionId)
                    ? expandedSections.filter(s => s !== toggledSectionId)
                    : [...expandedSections, toggledSectionId],
            };
        }
        default:
            return filter;
    }
};

const path = state => state.clubAds.filter;

export const getClubAdsExpandedSections = compose(
    filter => filter.expandedSections,
    path,
);
export const getClubAdsFilterStructure = compose(
    filter => filter.structure,
    path,
);
export const getClubAdsFilterCriteria = compose(
    filter => filter.criteria,
    path,
);
export const getClubAdsFilterLoading = compose(
    filter => filter.isLoading,
    path,
);
