import userActivityInsert from 'app/user-activity/actions/user-activity.actions'
import { PageType } from 'constants/enums';

export class EmailConfirmationActivityService {
    private static page: string = 'My Page';
    private static containers = {
        activation: 'Activation',
        wrongEmail: 'Activation - wrong email'
    }

    private static buildCommand(container: string, action: string, playerId: number) {
        return {
            Message: action,
            PlayerId: playerId,
            PageName: `${this.page}${container != null ? ` [${container}]` : ''}`,
            PageType: PageType.PlayerPage
        }
    }

    public static resendEmail(playerId: number) {
        return (dispatch) => {
            let command = this.buildCommand(
                this.containers.activation,
                'Resend Activation Mail',
                playerId
            )
            dispatch(userActivityInsert(command))
        }
    }

    public static closedActivation(playerId: number) {
        return (dispatch) => {
            let command = this.buildCommand(
                this.containers.activation,
                'Closed',
                playerId
            )
            dispatch(userActivityInsert(command))
        }
    }

    public static openedEmailUpdate(playerId: number) {
        return (dispatch) => {
            let command = this.buildCommand(
                this.containers.wrongEmail,
                'Opened Email Address Update',
                playerId
            )
            dispatch(userActivityInsert(command))
        }
    }

    public static sendActivationEmail(playerId: number) {
        return (dispatch) => {
            let command = this.buildCommand(
                this.containers.wrongEmail,
                'Send Activation Mail',
                playerId
            )
            dispatch(userActivityInsert(command))
        }
    }

    public static closedActivationEmail(playerId: number) {
        return (dispatch) => {
            let command = this.buildCommand(
                this.containers.wrongEmail,
                'Closed',
                playerId
            )
            dispatch(userActivityInsert(command))
        }
    }
}
