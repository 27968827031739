import { PageType, ActionType } from 'constants/enums';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions'
import { Tab } from './search-autosuggest.controller';

export class SuggestionActivityService {
    private static page: string = 'Search Bar';

    private static buildCommand(container: string, action: string, playerId?: number, clubId?: number, agencyId?: number, type?: ActionType) {
        return {
            Message: action,
            PageType: PageType.Search,
            PlayerId: playerId,
            ClubId: clubId,
            AgencyId: agencyId,
            PageName: `${this.page}${container ? ` [${container}]` : ''}`,
            ActionType: type
        }
    }

    public static searchValue(keyword: string, isInShortList: boolean) {
        return (dispatch) => {
            const command = this.buildCommand(isInShortList ? 'Shortlist' : '', `Search: ${keyword}`, null, null, null, ActionType.Search)
            dispatch(userActivityInsert(command))
        }
    }

    public static selectTab(tab: Tab) {
        return (dispatch) => {
            let action = 'All'
            if (tab === Tab.Players) { action = 'Players' }
            else if (tab === Tab.Clubs) { action = 'Clubs' }
            else if (tab === Tab.Agents) { action = 'Trusted Agents' }

            dispatch(userActivityInsert(this.buildCommand(null, action)))
        }
    }

    public static selectPlayer(playerId: number, playerName: string, squadId?: number, agencyId?: number) {
        return (dispatch) => {
            const command = this.buildCommand(null, `Selected ${playerName}`, playerId, squadId, agencyId)
            dispatch(userActivityInsert(command))
        }
    }

    public static selectClub(squadId: number, squadName: string) {
        return (dispatch) => {
            const command = this.buildCommand(null, `Selected ${squadName}`, null, squadId)
            dispatch(userActivityInsert(command))
        }
    }

    public static selectAgency(agencyId: number, agencyName: string) {
        return (dispatch) => {
            const command = this.buildCommand(null, `Selected ${agencyName}`, null, null, agencyId)
            dispatch(userActivityInsert(command))
        }
    }
}
