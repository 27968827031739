import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import { Actions as ReonboardingActions, ReonboardingSubsteppers } from '../reonboarding.controller';
import { Selectors as SelectPitchablePlayersSelectors } from '../select-pitchable-players-stepper/select-pitchable-players-stepper.controller';
import { PitchSuggestionsModelExtended } from "api/agency/agent/reonboarding/reonboarding-models";
import ReonboardingService from 'api/agency/agent/reonboarding/reonboarding.service';
import { PlayerAdTypeEnumModel } from 'api/agency/club/onboarding/onboarding-models';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { validatePriceInput } from './helpers/create-pitches.helpers'
import { formatWithCommas } from 'services/utils';

export enum CreatePitchesSteps {
    PreparePitches = 1,
    CreatePitchesDone = 2,
}

class State {
    isLoading: boolean;
    processing: boolean;
    activeStep: CreatePitchesSteps;
    playersPitches: PitchSuggestionsModelExtended[];
    currentPlayerIndex: number;
    currentPlayer: PitchSuggestionsModelExtended;
    isRemovedPitchExists: boolean;
    isSentPitchExists: boolean;
    isEditing: boolean;
    editingPlayerAdId: number | null;
    totalPreparedPitches: number;
}

const defaultState: State = {
    isLoading: false,
    processing: false,
    activeStep: CreatePitchesSteps.PreparePitches,
    playersPitches: [],
    currentPlayerIndex: 0,
    currentPlayer: {
        playerId: null,
        playerName: '',
        pitchSuggestions: []
    },
    isRemovedPitchExists: false,
    isSentPitchExists: false,
    isEditing: false,
    editingPlayerAdId: null,
    totalPreparedPitches: 0
}

const stateController = new StateController<State>(
    'AGENCY/REONBOARDING-FLOW/CREATE-PITCHES',
    defaultState
);

class Actions {
    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState({ ...defaultState }));
        }
    }

    public static init() {
        return (dispatch, getState: () => AppState) => {
            const pitchSuggestions = SelectPitchablePlayersSelectors.getRoot(getState()).pitchSuggestions;

            const playersPitchesExtended = pitchSuggestions.map(playerPitch => {
                const suggestionExtended = playerPitch.pitchSuggestions.map(pitchSuggestion => {
                    const suggestionsExtended = pitchSuggestion.suggestions.map((x, index) => ({
                        ...x,
                        id: index,
                        isCustom: false,
                        isEdit: false,
                        isSelected: x.isRecommended
                    }))
                    return {
                        ...pitchSuggestion,
                        suggestions: [...suggestionsExtended, {
                            id: pitchSuggestion.suggestions.length,
                            isCustom: true,
                            isEdit: false,
                            isRecommended: false,
                            isSelected: false,
                            amount: {
                                price: '',
                                formattedComma: '',
                                formattedKMB: '',
                            }
                        }]
                    }
                })
                return {
                    ...playerPitch,
                    pitchSuggestions: [...suggestionExtended]
                }
            });

            dispatch(stateController.setState({
                currentPlayerIndex: 0,
                currentPlayer: playersPitchesExtended[0],
                playersPitches: playersPitchesExtended,
                isSentPitchExists: false
            }));
        }
    }

    public static setActiveStep(step: CreatePitchesSteps) {
        return (dispatch) => {
            if (step === CreatePitchesSteps.CreatePitchesDone) {
                dispatch(ReonboardingActions.setIsCongratulationsScreen(true))
            }
            dispatch(stateController.setState({ activeStep: step }));
            dispatch(ReonboardingActions.disposeScrollingEffect());

        }
    }

    public static goToVerifyPlayersStepper() {
        return (dispatch) => {
            dispatch(userActivityInsert({
                Message: `Verify Players Now`,
                PageName: 'Verification Flow [Verify Players]',
            }));
            dispatch(ReonboardingActions.setIsCongratulationsScreen(false));
            dispatch(ReonboardingActions.setActiveStepper(ReonboardingSubsteppers.VerifyPlayers));
        }
    }

    public static removePlayerPitch(pitchId: number) {
        return (dispatch, getState: () => AppState) => {
            const currentPlayer = Selectors.getCurrentPlayer(getState());

            dispatch(stateController.setState({
                currentPlayer: {
                    ...currentPlayer,
                    pitchSuggestions: currentPlayer.pitchSuggestions.filter(i => i.playerAd.id !== pitchId)
                },
                isRemovedPitchExists: true
            }));

            dispatch(userActivityInsert({
                PageName: 'Verification Flow [Pitch Opportunities]',
                Message: 'Removed Ad',
                PlayerId: currentPlayer.playerId,
            }));
        }
    }

    public static goToNextPlayer() {
        return (dispatch, getState: () => AppState) => {
            const currentPlayerIndex = Selectors.getCurrentPlayerIndex(getState());
            const playersPitches = Selectors.getPlayersPitches(getState());

            if (currentPlayerIndex + 1 > playersPitches.length) {
                return;
            }

            dispatch(stateController.setState({
                currentPlayerIndex: currentPlayerIndex + 1,
                currentPlayer: playersPitches[currentPlayerIndex + 1],
                isRemovedPitchExists: false
            }));
        }
    }

    public static skipPlayer() {
        return (dispatch, getState: () => AppState) => {
            const currentPlayerIndex = Selectors.getCurrentPlayerIndex(getState());
            const playersPitches = Selectors.getPlayersPitches(getState());
            const isSentPitchExists = Selectors.isSentPitchExists(getState());
            const currentPlayer = Selectors.getCurrentPlayer(getState());

            if (currentPlayerIndex + 1 === playersPitches.length) {
                if (isSentPitchExists) {
                    dispatch(Actions.setActiveStep(CreatePitchesSteps.CreatePitchesDone));
                } else {
                    dispatch(ReonboardingActions.setActiveStepper(ReonboardingSubsteppers.VerifyPlayers));
                }
            } else {
                dispatch(Actions.goToNextPlayer())
            }

            dispatch(userActivityInsert({
                PageName: 'Verification Flow [Pitch Opportunities]',
                Message: 'Skip and Don’t Pitch',
                PlayerId: currentPlayer.playerId,
            }));
        }
    }

    public static sendPitchAndContinue() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ processing: true }));
                const currentPlayerIndex = Selectors.getCurrentPlayerIndex(getState());
                const playersPitches = Selectors.getPlayersPitches(getState());
                const currentPlayer = Selectors.getCurrentPlayer(getState());

                const prepareRequestData = {
                    playerId: currentPlayer.playerId,
                    pitches: currentPlayer.pitchSuggestions.map((pitchSuggestion) => {
                        const selectedSuggestion = pitchSuggestion.suggestions.find(x => x.isSelected);
                        const selectedSuggestionPrice = selectedSuggestion.amount.price.toString().replace(/\,/g,'');
                        return {
                            playerAdId: pitchSuggestion.playerAd.id,
                            amount: Number(selectedSuggestionPrice)
                        }
                    })
                }

                await ReonboardingService.createConditionalPitches(prepareRequestData);

                currentPlayer.pitchSuggestions.forEach((pitchSuggestion) => {
                    const selectedSuggestion = pitchSuggestion.suggestions.find(x => x.isSelected);
                    const selectedAdCurrency = pitchSuggestion.playerAd.type === PlayerAdTypeEnumModel.Buy
                        ? `${pitchSuggestion.playerAd.currency.name}/yr`
                        : `${pitchSuggestion.playerAd.currency.name}/mo`;

                    dispatch(userActivityInsert({
                        PageName: 'Verification Flow [Pitch Opportunities]',
                        Message: `Sent a Pitch: ${currentPlayer.playerName}; ${pitchSuggestion.playerAd.createdBySquad.name}; ${selectedSuggestion.amount.price} ${selectedAdCurrency}`,
                        PlayerId: currentPlayer.playerId,
                        ClubId: pitchSuggestion.playerAd.createdBySquad.id,
                    }));
                });

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    totalPreparedPitches: prevState.totalPreparedPitches +  prepareRequestData.pitches.length
                })));

                if (currentPlayerIndex + 1 === playersPitches.length) {
                    dispatch(Actions.setActiveStep(CreatePitchesSteps.CreatePitchesDone));
                } else {
                    dispatch(stateController.setState({
                        isSentPitchExists: true
                    }));
                    dispatch(Actions.goToNextPlayer());
                }

                dispatch(userActivityInsert({
                    PageName: 'Verification Flow [Pitch Opportunities]',
                    Message: 'Continued',
                    PlayerId: currentPlayer.playerId,
                }));
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState({ processing: false }));
            }
        }
    }

    public static continueWithoutPitch() {
        return async (dispatch, getState: () => AppState) => {
            const currentPlayerIndex = Selectors.getCurrentPlayerIndex(getState());
            const playersPitches = Selectors.getPlayersPitches(getState());
            const isSentPitchExists = Selectors.isSentPitchExists(getState());
            const currentPlayer = Selectors.getCurrentPlayer(getState());

            if (currentPlayerIndex + 1 === playersPitches.length) {
                if (isSentPitchExists) {
                    dispatch(Actions.setActiveStep(CreatePitchesSteps.CreatePitchesDone));
                } else {
                    dispatch(ReonboardingActions.setActiveStepper(ReonboardingSubsteppers.VerifyPlayers));
                }
            } else {
                dispatch(Actions.goToNextPlayer());
            }

            dispatch(userActivityInsert({
                PageName: 'Verification Flow [Pitch Opportunities]',
                Message: 'Continued',
                PlayerId: currentPlayer.playerId,
            }));
        }
    }

    public static selectSalary(playerAdId: number, salaryId: number) {
        return (dispatch, getState: () => AppState) => {
            const currentPlayer = Selectors.getCurrentPlayer(getState());
            const isEditing = Selectors.isEditing(getState());
            const isEditableOption = !!currentPlayer.pitchSuggestions
                .find(suggestion => suggestion.playerAd.id === playerAdId).suggestions
                .find(x => x.id === salaryId && x.isCustom);

            if (isEditing) {
                dispatch(Actions.finishEditing());
            }

            const pitchSuggestionsUpdated = currentPlayer.pitchSuggestions.map(pitchSuggestion => {
                if (pitchSuggestion.playerAd.id === playerAdId) {
                    return {
                        ...pitchSuggestion,
                        suggestions: pitchSuggestion.suggestions.map(x => ({
                            ...x,
                            isSelected: x.id === salaryId,
                            isEdit: isEditableOption
                        }))
                    }
                } else {
                    return ({
                        ...pitchSuggestion
                    })
                }
            });

            dispatch(stateController.setState({
                currentPlayer: {
                    ...currentPlayer,
                    pitchSuggestions: [
                        ...pitchSuggestionsUpdated,
                    ]
                },
                isEditing: isEditableOption,
                editingPlayerAdId: isEditableOption ? playerAdId : null
            }));

            if (!isEditableOption) {
                const selectedPlayerData = currentPlayer.pitchSuggestions.find(suggestion => suggestion.playerAd.id === playerAdId);
                const selectedSuggestion = selectedPlayerData.suggestions.find(x => x.id === salaryId);
                const selectedAdCurrency = selectedPlayerData.playerAd.type === PlayerAdTypeEnumModel.Buy
                    ? `${selectedPlayerData.playerAd.currency.name}/yr`
                    : `${selectedPlayerData.playerAd.currency.name}/mo`;

                dispatch(userActivityInsert({
                    PageName: 'Verification Flow [Pitch Opportunities]',
                    Message: `Selected Value ${selectedSuggestion.amount.price} ${selectedAdCurrency}`,
                    PlayerId: currentPlayer.playerId,
                }));
            }
        }
    }

    public static changeSalary(playerAdId: number, salaryId: number, value: string) {
        return (dispatch, getState: () => AppState) => {
            const currentPlayer = Selectors.getCurrentPlayer(getState());
            const currentPitchSuggestion = currentPlayer.pitchSuggestions.find(suggestion => suggestion.playerAd.id === playerAdId);
            const validatedValue = validatePriceInput(value, currentPitchSuggestion.playerAd.type === PlayerAdTypeEnumModel.Buy ? 'buy' : 'loan');

            const pitchSuggestionsUpdated =
                currentPlayer.pitchSuggestions.map(pitchSuggestion => {
                    if (pitchSuggestion.playerAd.id === playerAdId) {
                        return {
                            ...pitchSuggestion,
                            suggestions: pitchSuggestion.suggestions.map(x => {
                                return x.id === salaryId ? ({
                                    ...x,
                                    amount: {
                                        ...x.amount,
                                        price: value === '' ? '' : formatWithCommas(validatedValue),
                                        formattedComma: value === '' ? '' : formatWithCommas(validatedValue),
                                    }
                                }) : ({
                                    ...x
                                })

                            })
                        }
                    } else {
                        return ({
                            ...pitchSuggestion
                        })
                    }
                });

            dispatch(stateController.setState({
                currentPlayer: {
                    ...currentPlayer,
                    pitchSuggestions: [
                        ...pitchSuggestionsUpdated,
                    ]
                }
            }));
        }
    }

    public static finishEditing() {
        return (dispatch, getState: () => AppState) => {
            const currentPlayer = Selectors.getCurrentPlayer(getState());
            const playerAdId = Selectors.getEditingPlayerAdId(getState());

            const pitchSuggestionsUpdated = currentPlayer.pitchSuggestions.map(pitchSuggestion => {
                if (pitchSuggestion.playerAd.id === playerAdId) {
                    return {
                        ...pitchSuggestion,
                        suggestions: pitchSuggestion.suggestions.map(x => ({
                            ...x,
                            isEdit: false
                        }))
                    }
                } else {
                    return ({
                        ...pitchSuggestion
                    })
                }
            });

            dispatch(stateController.setState({
                currentPlayer: {
                    ...currentPlayer,
                    pitchSuggestions: [
                        ...pitchSuggestionsUpdated,
                    ]
                },
                isEditing: false,
                editingPlayerAdId: null
            }));

            const selectedPlayerData = currentPlayer.pitchSuggestions.find(suggestion => suggestion.playerAd.id === playerAdId);
            const selectedSuggestion = selectedPlayerData.suggestions.find(x => x.isCustom);

            if (selectedSuggestion.amount.price) {
                const selectedAdCurrency = selectedPlayerData.playerAd.type === PlayerAdTypeEnumModel.Buy
                    ? `${selectedPlayerData.playerAd.currency.name}/yr`
                    : `${selectedPlayerData.playerAd.currency.name}/mo`;

                dispatch(userActivityInsert({
                    PageName: 'Verification Flow [Pitch Opportunities]',
                    Message: `Entered Custom Value ${selectedSuggestion.amount.price} ${selectedAdCurrency}`,
                    PlayerId: currentPlayer.playerId,
                }));
            }
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState): State => state.agency.reonboarding.createPitches;
    public static isLoading = (state: AppState): boolean => Selectors.getRoot(state).isLoading;
    public static isProcessing = (state: AppState): boolean => Selectors.getRoot(state).processing;
    public static isRemovedPitchExists = (state: AppState): boolean => Selectors.getRoot(state).isRemovedPitchExists;
    public static isSentPitchExists = (state: AppState): boolean => Selectors.getRoot(state).isSentPitchExists;
    public static isEditing = (state: AppState): boolean => Selectors.getRoot(state).isEditing;
    public static getCurrentStep = (state: AppState) => Selectors.getRoot(state).activeStep;
    public static getPlayersPitches = (state: AppState) => Selectors.getRoot(state).playersPitches;
    public static getCurrentPlayer = (state: AppState) => Selectors.getRoot(state).currentPlayer;
    public static getCurrentPlayerIndex = (state: AppState) => Selectors.getRoot(state).currentPlayerIndex;
    public static getPlayersPitchesLength = (state: AppState) => Selectors.getRoot(state).playersPitches.length;
    public static getEditingPlayerAdId = (state: AppState) => Selectors.getRoot(state).editingPlayerAdId;
    public static getTotalPreparedPitches = (state: AppState) => Selectors.getRoot(state).totalPreparedPitches;

    public static isEmptySalaryExists = (state: AppState) => {
        const currentPlayer = Selectors.getCurrentPlayer(state);
        const allSuggestions = currentPlayer.pitchSuggestions.reduce((acc, suggestion) => {
            acc.push(...suggestion.suggestions)
            return acc;
        }, []);

        return allSuggestions.some(x => x.isSelected && x.isCustom && !x.amount.price);
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    stateController as Controller,
    Selectors as Selectors
};


