import axios from 'axios-config';
import { CancelToken } from 'axios';
import { SearchResult } from "./model/search-result"
import { SearchRequest, SearchCoachRequest, SearchAgenciesRequest } from "./model/search-request";
import { SearchFilterCriteria, SearchFilterDynamicCriteria } from './model/search-filter-criteria';
import { SearchPlayerItemForClub } from './model/search-player-item-for-club';
import { SearchCoachItemForClub } from './model/search-coach-item-for-club';
import { SearchAgencyItemForClub } from './model/search-agency-item-for-club';
import { SuggestionResponse } from './model/suggestion-response';
import { PlayerPositionsResponse } from './model/player-positions';

export default class SearchService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async getSuggestions(
        keyword: string,
        showOnlyAgencies: boolean,
        includeOwnSquad: boolean,
        showOnlyShortlist: boolean,
        playerSet: number[],
        cancelToken: CancelToken
    ): Promise<SuggestionResponse> {
        const { data } = await axios.post(
            `api/v3/Search/Suggestions`,
            {
                keyword,
                showOnlyAgencies,
                includeOwnSquad,
                showOnlyShortlist,
                playerSet
            },
            { ...this.axiosConfig, cancelToken: cancelToken }
        )
        return data
    }

    public static async getPlayerPositions(): Promise<PlayerPositionsResponse> {
        const { data } = await axios.get(
            `api/v3/Search/PlayerPositions`,
            this.axiosConfig
        )
        return data;
    }

    public static async getCriteria(cancelToken: CancelToken): Promise<SearchFilterCriteria> {
        const { data } = await axios.get(
            `api/v3/Search/Criteria`,
            { ...this.axiosConfig, cancelToken: cancelToken }
        )
        return data;
    }

    public static async getDynamicCriteria(): Promise<SearchFilterDynamicCriteria> {
        const { data } = await axios.get(
            `api/v3/Search/DynamicCriteria`,
            { ...this.axiosConfig }
        )
        return data;
    }

    public static async searchPlayers(request: SearchRequest, cancelToken: CancelToken): Promise<SearchResult<SearchPlayerItemForClub>> {

        const { data } = await axios.post(
            `api/v3/Search/Players`,
            request,
            { ...this.axiosConfig, cancelToken: cancelToken }
        )
        return data
    }

    public static async searchCoaches(request: SearchCoachRequest, cancelToken: CancelToken): Promise<SearchResult<SearchCoachItemForClub>> {
        const { data } = await axios.post(
            `api/v3/Search/Coaches`,
            request,
            { ...this.axiosConfig, cancelToken: cancelToken }
        )
        return data
    }

    public static async searchAgencies(request: SearchAgenciesRequest, cancelToken: CancelToken): Promise<SearchResult<SearchAgencyItemForClub>> {
        const { data } = await axios.post(
            `api/v3/Search/Agencies`,
            request,
            { ...this.axiosConfig, cancelToken: cancelToken }
        )
        return data
    }

    public static async saveLatestSearchKeyword(keyword: string): Promise<any> {
        const { data } = await axios.post(
            `api/v3/Search/SaveLatestSearchKeyword`,
            JSON.stringify(keyword),
            { ...this.axiosConfig }
        )
        return data
    }
}