import axios from 'axios-config';
import {EvidenceContract, NotesContract} from "./model";
import {StatusesContract} from "../upgrade-banner-report/models";

export default class PlayerVerificationService {
    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async loadNotes(uid: string): Promise<NotesContract> {
       return await axios.get(
            `api/v2/AdminPlayerVerification/GetNotes/${uid}`,
            this.axiosConfig
        );
    }

    public static async saveNote(uid: string, note: string){
       return  await axios.post(
            `api/v2/AdminPlayerVerification/AddNote`,
            {
                agentPlayerInvitationUid: uid,
                note: note
            },
            this.axiosConfig
        );
    }

    public static async playerInvitationsFetch (searchParameters) {
        return await axios.post(
            `api/v2/AdminPlayerVerification/GetPlayerVerificationData`,
            {
                AgentName: searchParameters.agentName,
                AgencyName: searchParameters.agencyName,
                PlayerName: searchParameters.playerName,
                ParentSquadName: searchParameters.parentSquadName,
                PlayerPhone: searchParameters.playerPhone,
                VerificationStatusId: searchParameters.verificationStatusName,
                PageIndex: searchParameters.page,
                PageSize: searchParameters.recordsPerPage,
                Note: searchParameters.notes,
            },
            this.axiosConfig
        )

        //todo Investigate:
        /* if (request.responseSchema) {
             data = normalize(data, request.responseSchema);
         }*/
        };

    public static async statusesFetch (): Promise<StatusesContract> {
        return await axios.get(
            `api/v2/AdminPlayerVerification/GetPlayerInvitationStatuses`,
            this.axiosConfig
        )
    }

    public static async rejectPlayer (invitationUid, isReturnCredit) {
        return await axios.post(
            `api/v2/AgentPlayerInvitation/RejectByAdmin/${invitationUid}/${isReturnCredit}`,
            {
                uid: invitationUid
            },
            this.axiosConfig
        )
    }

    public static async assignContractExpiration (uid, expirationDate) {
        return await axios.post(
            `api/v2/AgentPlayerInvitation/AssignExpirationDate`,
            {
                uid: uid,
                expirationDate: expirationDate
            },
            this.axiosConfig
        )
    }

    public static async verifyPlayer (invitationUid) {
        return await axios.post(
            `api/v2/AgentPlayerInvitation/ConfirmByAdmin/${invitationUid}`,
            {
                uid: invitationUid
            },
            this.axiosConfig
        )
    }

    public static async agencyPlayerEvidenceFetch (playerId): Promise<EvidenceContract>  {
        return await axios.get(
            `api/v2/AdminPlayerVerification/GetPlayerEvidence/${playerId}`,
            this.axiosConfig,
        )
    }

    public static async getAgencyFreeCredits(agencyId) {
        const { data } = await axios.get(
            `api/v2/AdminPlayerVerification/HasAgencyFreeCredits/${agencyId}`,
            this.axiosConfig
        );
        return data;
    }

    public static async setStatusToSent(invitationUid) {
        const { data } = await axios.post(
            `api/v2/AgentPlayerInvitation/SetStatusToSent/${invitationUid}`,
            {
                uid: invitationUid
            },
            this.axiosConfig
        );
        return data;
    }
}