import { StateController } from 'utils/action-declaration'
import { AppState } from 'root.reducer'

import {
    OverviewModel,
    InternationalAppModel,
    DomesticMinsModel,
    ContinentalMinsModel,
    LastClubModel,
    CurrentClubModel,
} from 'api/player/model/player-point'
import { PlayerPointsService } from 'api/player/player-points'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { isUKPlayer } from './utils';
import { Selectors as TopPanelSelectors } from './player-point.controller'
import { PageType } from 'constants/enums';

export enum StatisticsTabsEnunm {
    Overview = 1,
    InternationalApps = 2,
    DomesticMins = 3,
    ContinentalMins = 4,
    LastClub = 5,
    CurrentClub = 6,
    Information = 7,
}

export enum OverviewItem {
    InternationalApps = 1,
    DomesticMins = 2,
    ContinentalMins = 3,
    LastClub = 4,
    CurrentClub = 5,
}

export interface CommonValue {
    loaded: boolean,
}

class StatisticsCommonState {
    currentTab: StatisticsTabsEnunm;
    isLoading: boolean;
    overview: OverviewModel & CommonValue
    internalApp: InternationalAppModel & CommonValue
    domesticMins: DomesticMinsModel & CommonValue
    continentalAppereances: ContinentalMinsModel & CommonValue
    lastClub: LastClubModel & CommonValue
    currentClub: CurrentClubModel & CommonValue
}

const defaultState: StatisticsCommonState = {
    currentTab: StatisticsTabsEnunm.Overview,
    isLoading: false,
    overview: null,
    domesticMins: {
        points: 0,
        pointsTableColumnValue: null,
        pointsTableRowValue: null,
        appearances: [],
        domesticMinutes: 0,
        totalAvailableMinutes: 0,
        totalMinutesPlayed: 0,
        loaded: false,
    },
    internalApp: {
        points: 0,
        appearancesPerc: 0,
        appearances: 0,
        includeFriendlies: false,
        totalAvailableCompetitiveFixture: null,
        totalCompetitiveFixture: null,
        competitive: 0,
        fifaRnk: 0,
        table: [],
        fifaRanking: [],
        loaded: false,
    },
    continentalAppereances: {
        points: 0,
        pointsTableColumnValue: null,
        pointsTableRowValue: null,
        appearances: [],
        continentalMinutes: 0,
        totalAvailableMinutes: 0,
        totalMinutesPlayed: 0,
        loaded: false,
    },
    lastClub: {
        points: 0,
        pointsTableCompetition: '',
        pointsTableColumnValue: null,
        pointsTableRowValue: null,
        pointsTableContinentalCompetition: '',
        pointsTableContinentalColumnValue: null,
        pointsTableContinentalRowValue: null,
        leagueSeason: '',
        leagueTable: [],
        loaded: false,
    },
    currentClub: {
        points: 0,
        pointsTableCompetition: '',
        pointsTableRowValue: null,
        loaded: false,
    },
}

const stateController = new StateController<StatisticsCommonState>(
    'FA_PLAYER_POINTS/COMMON',
    defaultState
)

class Actions {
    public static changeTab(tab: StatisticsTabsEnunm = StatisticsTabsEnunm.Overview, playerId: number) {
        return dispatch => {
            dispatch(stateController.setState({ currentTab: tab }))
            dispatch(Actions.initTabData(tab, playerId))
        }
    }

    public static init(playerId: number) {
        return (dispatch) => {
            dispatch(stateController.setState({ currentTab: StatisticsTabsEnunm.Overview, ...defaultState }))
            dispatch(Actions.initTabData(StatisticsTabsEnunm.Overview, playerId))
        }
    }

    public static initTabData(tab: StatisticsTabsEnunm, playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            if(!playerId){
                return;
            }

            try {

                let message = ''
                const state = getState()

                if (!state.playerPoints.player.gbeInfo.faPointResult || isUKPlayer(state.playerPoints.player.gbeInfo.faPointResult)) {

                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        overview: { ...prevState.overview, loaded: true },
                        internalApp: { ...prevState.internalApp, loaded: true },
                        domesticMins: { ...prevState.domesticMins, loaded: true },
                        continentalAppereances: { ...prevState.continentalAppereances, loaded: true },
                        lastClub: { ...prevState.lastClub, loaded: true },
                        currentClub: { ...prevState.currentClub, loaded: true }
                    })))

                } else {

                    dispatch(stateController.setState({ isLoading: true }))

                    if (tab === StatisticsTabsEnunm.Overview && !Selectors.getOverview(state)) {
                        const data = await PlayerPointsService.getPlayerPointOverview(playerId)
                        dispatch(stateController.setState({ overview: { loaded: true, ...data } }))
                    } else if (tab === StatisticsTabsEnunm.InternationalApps && !Selectors.getInternationalApp(state).loaded) {
                        const data = await PlayerPointsService.getInternationalApp(playerId)
                        dispatch(stateController.setState({ internalApp: { loaded: true, ...data } }));
                    } else if (tab === StatisticsTabsEnunm.DomesticMins && !Selectors.getDomescticMins(state).loaded) {
                        const data = await PlayerPointsService.getDomesticMins(playerId)
                        dispatch(stateController.setState({ domesticMins: { loaded: true, ...data } }));
                    } else if (tab === StatisticsTabsEnunm.ContinentalMins && !Selectors.getContinentalAppearences(state).loaded) {
                        const data = await PlayerPointsService.getContinentalAppearences(playerId)
                        dispatch(stateController.setState({ continentalAppereances: { loaded: true, ...data } }));
                    } else if (tab === StatisticsTabsEnunm.LastClub && !Selectors.getLastClub(state).loaded) {
                        const data = await PlayerPointsService.getLastClub(playerId)
                        dispatch(stateController.setState({ lastClub: { loaded: true, ...data } }));
                    } else if (tab === StatisticsTabsEnunm.CurrentClub && !Selectors.getCurrentClub(state).loaded) {
                        const data = await PlayerPointsService.getCurrentClub(playerId)
                        dispatch(stateController.setState({ currentClub: { loaded: true, ...data } }));
                    }

                }

                if (tab === StatisticsTabsEnunm.Overview) {
                    message = 'Opened Overview'
                } else if (tab === StatisticsTabsEnunm.InternationalApps) {
                    message = 'Opened International Apps'
                } else if (tab === StatisticsTabsEnunm.DomesticMins) {
                    message = 'Opened Domestic Mins'
                } else if (tab === StatisticsTabsEnunm.ContinentalMins) {
                    message = 'Opened Continental Mins'
                } else if (tab === StatisticsTabsEnunm.LastClub) {
                    message = 'Opened Last Club'
                } else if (tab === StatisticsTabsEnunm.CurrentClub) {
                    message = 'Opened Current Club'
                } else if (tab === StatisticsTabsEnunm.Information) {
                    message = 'Opened Information'
                }

                const player = getState().playerPoints.player.gbeInfo.player;

                if (player && message) {
                    dispatch(userActivityInsert({
                        PageName: 'GBE Calculator',
                        Message: message,
                        ClubId: player.parentSquadId,
                        PlayerId: player.id,
                        AgencyId: player.agencyId,
                        PageType: PageType.GBE_Calculator
                    }))
                }
            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState({ isLoading: false }))
            }
        }
    }

    public static disposeAllTabsData() {
        return dispatch => {
            dispatch(stateController.setState({ ...defaultState }))
        }
    }

    public static toggleOverview(fly: OverviewItem, open: boolean) {
        return async (dispatch, getState: () => AppState) => {
            const player = getState().playerPoints.player.gbeInfo.player;

            let message = ''

            if (fly === OverviewItem.InternationalApps) {
                message = open ? 'Opened International Apps' : 'Closed International Apps'
            } else if (fly === OverviewItem.DomesticMins) {
                message = open ? 'Opened Domestic Mins' : 'Closed Domestic Mins'
            } else if (fly === OverviewItem.ContinentalMins) {
                message = open ? 'Opened Continental Mins' : 'Closed Continental Mins'
            } else if (fly === OverviewItem.LastClub) {
                message = open ? 'Opened Last Club' : 'Closed Last Club'
            } else if (fly === OverviewItem.CurrentClub) {
                message = open ? 'Opened Current Club' : 'Closed Current Club'
            }

            if (player && message) {
                dispatch(userActivityInsert({
                    PageName: 'GBE Calculator [Overview]',
                    Message: message,
                    ClubId: player.parentSquadId,
                    PlayerId: player.id,
                    AgencyId: player.agencyId,
                    PageType: PageType.GBE_Calculator
                }))
            }
        }
    }

    public static onOpenFA = () => {
        return (dispatch, getState) => {
            const player = TopPanelSelectors.getPlayer(getState());
            dispatch(userActivityInsert(
                {
                    Message: 'Opened FA Website',
                    PageName: 'GBE Calculator [Information]',
                    PlayerId: player.Id,
                    ClubId: player.parentSquadId,
                    AgencyId: player.agencyId,
                    PageType: PageType.GBE_Calculator
                },
            ))
        }
    }

}

export type TotalModel = Array<{
    key: string, value: any
}>

class Selectors {
    public static getStatictic = (state: AppState) => state.playerPoints.statisticsCommon

    public static getCurrentTab = (state: AppState) => Selectors.getStatictic(state).currentTab
    public static isLoading = (state: AppState) => Selectors.getStatictic(state).isLoading;

    public static getOverview = (state: AppState) => Selectors.getStatictic(state).overview


    public static getInternationalApp = (state: AppState) => Selectors.getStatictic(state).internalApp
    public static getInternationalAppPoints = (state: AppState) => Selectors.getInternationalApp(state).points
    public static getInternationalAppFifaRanking = (state: AppState) => Selectors.getInternationalApp(state).fifaRanking

    public static getDomescticMins = (state: AppState) => Selectors.getStatictic(state).domesticMins
    public static getContinentalAppearences = (state: AppState) => Selectors.getStatictic(state).continentalAppereances

    public static getLastClub = (state: AppState) => Selectors.getStatictic(state).lastClub
    public static getLastClubTable = (state: AppState) => Selectors.getLastClub(state).leagueTable

    public static getCurrentClub = (state: AppState) => Selectors.getStatictic(state).currentClub
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    StatisticsCommonState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};