import * as types from 'store/actionTypes'; 

const INITIAL_STATE = {
    phoneCodes: []
};

export default (keys = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.GET_AREA_PHONE_CODES.SUCCESS:
            return{
                ...keys,
                phoneCodes: action.payload.data
            };
        default:
            return keys;
    }
}



export const getPhoneCodes = state => {
    return state.entities.phoneCodes.phoneCodes
        .map(
        code => {
            // let p = state.entities.positions.byKey[code];
            // position.value = translate(position.translationKey)
            // return position;
            return {   
                areaId: code.id,
                flagImage: code.flagImage,             
                code: code.phoneCode,
                mask: code.mask
            };
        },
    );
};