import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import AgencyPublicProfileService from 'api/agency/public-profile/public-profile.service'
import * as AgencyProfileService from 'api/agency/club/agency-profile/agency-profile.service';
import { AreaItem } from 'api/area/model';
import {
    AgencyPlayerTransfer,
    ProfileRegisteredAgent,
    SaveAgencyTransfer,
    SaveProfileRegisteredAgent
} from 'api/agency/public-profile/models';
import axiosLib, { CancelTokenSource } from 'axios';
import { getCancelTokenSource } from 'axios-config';
import config from 'config'
import { IdName } from 'api/core/id-name';
import { createImage, getRadianAngle, rotateSize } from 'utils/blob-image-path';
import { Actions as LtaModalActions, LtaUpgradeModalType, LTA_PROFILE_TRANSFERS_LIMIT } from 'pages/agency/common/low-tier-agency-upgrade-modal/lta-upgrade-modal.controller'
import { isLowerTierAgency } from 'store/auth/authReducer';

class AgencyPublicProfileEditSession {
    headerImageServerPath: string;
    headerImage: string;
    headerImageName: string;
    headerImageSize: number;
    headereImageUploadProgress: number;
    headerImageValidationErrors: string;
    logoImageServerPath: string;
    logoImage: string;
    logoImageName: string;
    logoImageSize: number;
    logoImageUploadProgress: number;
    logoImageValidationErrors: string;
    profileText: string;
    primaryMarketAreaId: number;
    secondaryMarketAreaId?: number;
    registeredAgents: ProfileRegisteredAgent[];
    transfers: AgencyPlayerTransfer[];
    deleteTrackRecordConfirmationModal: {
        item: AgencyPlayerTransfer
    };
}

export const editProfileSteps = {
    headerImage: 0,
    agencyLogo: 1,
    profileText: 2,
    markets: 3,
    registeredAgents: 4,
    addRegisteredAgents: 5,
    trackRecord: 6,
    addTrackRecordItem: 7,
    players: 8,
}

class AgencyEditPublicProfileState {
    isLoading: boolean;
    isProcessing: boolean;
    entity: AgencyPublicProfileEditSession;
    currentStep?: number;
    updatedAt: Date | string | null;
    updatedByUserFullName: string;
    primaryMarketAreaName: string;
    secondaryMarketAreaName: string;
    marketAreas: AreaItem[];
    registeredAgents: ProfileRegisteredAgent[];
    transfers: AgencyPlayerTransfer[];
    players: IdName[];
    isAgencyTrusted?: boolean;
    isAgencyTransparent? : boolean;
    isMarketExpert?: boolean;
    navbarCount?: number;
    reachDealLimitModal: boolean;
}

const defaultState: AgencyEditPublicProfileState = {
    isLoading: false,
    isProcessing: false,
    entity: {
        headerImageServerPath: null,
        headerImage: null,
        headerImageName: null,
        headerImageSize: null,
        headereImageUploadProgress: null,
        headerImageValidationErrors: null,
        logoImageServerPath: null,
        logoImage: null,
        logoImageName: null,
        logoImageSize: null,
        logoImageUploadProgress: null,
        logoImageValidationErrors: null,
        profileText: '',
        primaryMarketAreaId: null,
        secondaryMarketAreaId: null,
        registeredAgents: [],
        transfers: [],
        deleteTrackRecordConfirmationModal: null
    },
    currentStep: null,
    updatedAt: null,
    updatedByUserFullName: null,
    primaryMarketAreaName: null,
    secondaryMarketAreaName: null,
    marketAreas: [],
    registeredAgents: [],
    transfers: [],
    players: [],
    isAgencyTrusted: false,
    isAgencyTransparent: false,
    isMarketExpert: false,
    navbarCount: 0,
    reachDealLimitModal: false
}

const LOCAL_STORAGE_KEY = "agencyPublicProfile";

interface APP_LocalStorageInfo {
    visited?: boolean;
    completelyFilled: boolean;
}

const stateController = new StateController<AgencyEditPublicProfileState>("AGENCY/PUBLIC_PROFILE/EDIT", defaultState);

class Actions {
    public static token: CancelTokenSource = null;

    public static loadProfile() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isLoading: true }))
                const data = await AgencyPublicProfileService.getProfileForEdit();

                dispatch(stateController.setState({
                    entity: {
                        ...defaultState.entity,
                        headerImageServerPath: data.headerImage,
                        logoImageServerPath: data.logoImage,
                        profileText: data.description ?? '',
                        primaryMarketAreaId: data.primaryMarketAreaId,
                        secondaryMarketAreaId: data.secondaryMarketAreaId,
                    },
                    updatedAt: data.updatedAt,
                    updatedByUserFullName: data.updatedByUserFullName,
                    primaryMarketAreaName: data.primaryMarketAreaName,
                    secondaryMarketAreaName: data.secondaryMarketAreaName,
                    registeredAgents: data.registeredAgents,
                    transfers: data.transfers,
                    isAgencyTrusted: data.isAgencyTrusted,
                    isAgencyTransparent: data.isAgencyTransparent,
                    isMarketExpert: data.isMarketExpert,
                }));
                dispatch(Actions.updateNavbarCounter());
                dispatch(userActivityInsert({
                    PageName: 'Agency Profile',
                    Message: 'Opened profile',
                }))
            } finally {
                dispatch(stateController.setState({ isLoading: false }))
            }
        }
    }

    public static onSectionSaved() {
        return async (dispatch, getState: () => AppState) => {
            await dispatch(Actions.loadProfile());
            dispatch(stateController.setState({ currentStep: null }));
        }
    }

    public static checkCounter() {
        return async (dispatch, getState: () => AppState) => {
            const { auth } = getState();
            let localStorageInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) ?? {};
            let info: APP_LocalStorageInfo = localStorageInfo[auth.userId];
            if (!info || !info.completelyFilled) {
                dispatch(stateController.setState({ navbarCount: 1 }))
            }
        }
    }

    public static updateNavbarCounter() {
        return async (dispatch, getState: () => AppState) => {
            const { auth, agency } = getState();
            const { entity, registeredAgents, transfers } = agency.publicProfile.editPublicProfile;
            const { headerImageServerPath, logoImageServerPath, profileText, primaryMarketAreaId } = entity;

            let localStorageInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) ?? {};
            let info: APP_LocalStorageInfo = localStorageInfo[auth.userId] ?? { completelyFilled: false };

            const allSectionsFilled = registeredAgents.length > 0
                && transfers.length > 0
                && !!headerImageServerPath
                && !!logoImageServerPath
                && !!(profileText?.trim() ?? '')
                && !!primaryMarketAreaId;

            info.completelyFilled = allSectionsFilled;
            localStorageInfo[auth.userId] = info;
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(localStorageInfo));
            dispatch(stateController.setState({ navbarCount: allSectionsFilled ? 0 : 1 }))
        }
    }

    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(prevState => ({
                ...defaultState,
                navbarCount: prevState.navbarCount
            })))
        }
    }

    public static viewPublicProfile(agencyName: string) {
        return (dispatch, getState: () => AppState) => {
            // window.open(`${config.profileWebAppUrl}/agency-profile/${encodeURIComponent(agencyName)}`);
            window.open(`/agency-profile/${encodeURIComponent(agencyName)}`, '_blank');
            dispatch(userActivityInsert({
                Message: 'View Profile',
                PageName: 'Agency Profile [Edit]',
            }))
        }
    }

    public static openProfileLogoImageEditor() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ currentStep: editProfileSteps.agencyLogo }))
            dispatch(userActivityInsert({
                Message: 'Opened Agency Logo',
                PageName: 'Agency Profile [Edit]',
            }))
        }
    }

    public static openProfileHeaderImageEditor() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ currentStep: editProfileSteps.headerImage }))
            dispatch(userActivityInsert({
                Message: 'Opened Header Image',
                PageName: 'Agency Profile [Edit]',
            }))
        }
    }

    public static closeProfileLogoImageEditor() {
        return (dispatch, getState: () => AppState) => {
            let substate = getState().agency.publicProfile.editPublicProfile;
            if (substate.entity.logoImage) {
                window.URL.revokeObjectURL(substate.entity.logoImage);
            }
            dispatch(Actions.cancelUpload());
            dispatch(stateController.setState({
                ...substate,
                currentStep: null, entity: {
                    ...substate.entity,
                    logoImageUploadProgress: null, logoImage: null, logoImageName: null, logoImageSize: null
                }
            }));
            dispatch(userActivityInsert({
                Message: 'Closed Agency Logo',
                PageName: 'Agency Profile [Edit]',
            }))
        }
    }

    public static closeProfileHeaderImageEditor() {
        return (dispatch, getState: () => AppState) => {
            let substate = getState().agency.publicProfile.editPublicProfile;
            if (substate.entity.headerImage) {
                window.URL.revokeObjectURL(substate.entity.headerImage);
            }
            dispatch(Actions.cancelUpload());
            dispatch(stateController.setState({
                ...substate,
                currentStep: null, entity: {
                    ...substate.entity,
                    headereImageUploadProgress: null, headerImage: null, headerImageName: null, headerImageSize: null
                }
            }));
            dispatch(userActivityInsert({
                Message: 'Closed Header Image',
                PageName: 'Agency Profile [Edit]',
            }))
        }
    }

    public static removeProfileLogoImage() {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().agency.publicProfile.editPublicProfile;
            dispatch(stateController.setState({
                ...substate,
                entity: {
                    ...substate.entity,
                    logoImage: null, logoImageName: null, logoImageSize: null, logoImageUploadProgress: null
                }
            }));
            dispatch(userActivityInsert({
                Message: 'Removed Agency Logo',
                PageName: 'Agency Profile [Edit]',
            }))
        }
    }

    public static removeProfileHeaderImage() {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().agency.publicProfile.editPublicProfile;
            dispatch(stateController.setState({
                ...substate,
                entity: {
                    ...substate.entity,
                    headerImage: null, headerImageName: null, headerImageSize: null, headereImageUploadProgress: null
                }
            }));
            dispatch(userActivityInsert({
                Message: 'Removed Header Image',
                PageName: 'Agency Profile [Edit]',
            }))
        }
    }

    public static cancelUpload() {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().agency.publicProfile.editPublicProfile;

            if (Actions.token) {
                Actions.token.cancel();
            }

            dispatch(stateController.setState({
                ...substate,
                entity: {
                    ...substate.entity,
                    headereImageUploadProgress: null,
                    logoImageUploadProgress: null
                }
            }));
        }
    }

    public static setProfileLogoImage(image: string, name: string, size: number, validationErrors: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                let substate = getState().agency.publicProfile.editPublicProfile;
                dispatch(stateController.setState({
                    ...substate,
                    entity: {
                        ...substate.entity,
                        logoImage: image,
                        logoImageName: name,
                        logoImageSize: size,
                        logoImageValidationErrors: validationErrors
                    }
                }));
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }))
            }
        }
    }

    public static setProfileHeaderImage(image: string, name: string, size: number, validationErrors: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                let substate = getState().agency.publicProfile.editPublicProfile;
                dispatch(stateController.setState({
                    ...substate,
                    entity: {
                        ...substate.entity,
                        headerImage: image,
                        headerImageName: name,
                        headerImageSize: size,
                        headerImageValidationErrors: validationErrors
                    }
                }));
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }))
            }
        }
    }

    private static async getCroppedImg(imageSrc, type, pixelCrop, rotation = 0, flip = { horizontal: false, vertical: false }) {
        const image: any = await createImage(imageSrc);
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        if (!ctx) { return null }
        const rotRad = getRadianAngle(rotation);
        const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);

        canvas.width = bBoxWidth;
        canvas.height = bBoxHeight;

        ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
        ctx.rotate(rotRad)
        ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
        ctx.translate(-image.width / 2, -image.height / 2)
        ctx.fillStyle = '#FFF';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(image, 0, 0);

        const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;

        ctx.putImageData(data, 0, 0);

        return new Promise((resolve, reject) => {
            canvas.toBlob((file) => {
                resolve(URL.createObjectURL(file))
            }, 'image/png')
        })
    }

    public static saveProfileLogoImage(croppedAreaPixels: { height: number, width: number, x: number, y: number }) {
        return async (dispatch, getState: () => AppState) => {
            Actions.token = getCancelTokenSource();

            try {
                let substate = getState().agency.publicProfile.editPublicProfile;
                dispatch(stateController.setState({ isProcessing: true }));
                var req = await axiosLib({ method: 'GET', url: substate.entity.logoImage, responseType: 'blob' });
                var cropped: any = await this.getCroppedImg(substate.entity.logoImage, req.data.type, croppedAreaPixels);
                var req2 = await axiosLib({ method: 'GET', url: cropped, responseType: 'blob' });

                var res = await AgencyProfileService.AgencyProfileService.uploadLogoImg(
                    req2.data,
                    substate.entity.logoImageName,
                    (progressEvent) => {
                        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        dispatch(stateController.setState({
                            ...getState().agency.publicProfile.editPublicProfile,
                            entity: {
                                ...getState().agency.publicProfile.editPublicProfile.entity,
                                logoImageUploadProgress: percentCompleted
                            }
                        }));
                    },
                    Actions.token.token
                );

                if (res) {
                    dispatch(Actions.onSectionSaved());
                }
                dispatch(userActivityInsert({
                    Message: 'Saved Agency Logo',
                    PageName: 'Agency Profile [Edit]',
                }))
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }))
            }
        }
    }

    public static saveProfileHeaderImage(croppedAreaPixels: { height: number, width: number, x: number, y: number }) {
        return async (dispatch, getState: () => AppState) => {
            Actions.token = getCancelTokenSource();

            try {
                let substate = getState().agency.publicProfile.editPublicProfile;
                dispatch(stateController.setState({ isProcessing: true }));
                var req = await axiosLib({ method: 'GET', url: substate.entity.headerImage, responseType: 'blob' });
                var cropped: any = await this.getCroppedImg(substate.entity.headerImage, req.data.type, croppedAreaPixels);
                var req2 = await axiosLib({ method: 'GET', url: cropped, responseType: 'blob' });

                var res = await AgencyProfileService.AgencyProfileService.uploadHeaderImg(
                    req2.data,
                    substate.entity.headerImageName,
                    (progressEvent) => {
                        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        dispatch(stateController.setState({
                            ...getState().agency.publicProfile.editPublicProfile,
                            entity: {
                                ...getState().agency.publicProfile.editPublicProfile.entity,
                                headereImageUploadProgress: percentCompleted
                            }
                        }));
                    },
                    Actions.token.token
                );

                if (res) {
                    dispatch(Actions.onSectionSaved());
                }
                dispatch(userActivityInsert({
                    Message: 'Saved Header Image',
                    PageName: 'Agency Profile [Edit]',
                }))
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }))
            }
        }
    }

    public static openProfileTextEditor() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ currentStep: editProfileSteps.profileText }))
            dispatch(userActivityInsert({
                Message: 'Opened Profile Text',
                PageName: 'Agency Profile [Edit]',
            }))
        }
    }

    public static closeProfileTextEditor() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ currentStep: null }))
            dispatch(userActivityInsert({
                Message: 'Closed Profile Text',
                PageName: 'Agency Profile [Edit]',
            }))
        }
    }

    public static setProfileText(profileText: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isProcessing: true }))
                await AgencyPublicProfileService.saveProfileText({ profileText });
                dispatch(Actions.onSectionSaved());
                dispatch(userActivityInsert({
                    Message: `Saved Profile Text: ${profileText}`,
                    PageName: 'Agency Profile [Edit]',
                }))
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }))
            }
        }
    }

    public static openMarketsEditor() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ currentStep: editProfileSteps.markets }))
            dispatch(userActivityInsert({
                Message: 'Opened Markets',
                PageName: 'Agency Profile [Edit]',
            }))
        }
    }

    public static setMarkets(primaryMarketAreaId: number, secondaryMarketAreaId: number | null) {
        return async (dispatch, getState: () => AppState) => {
            const markets = getState().agency.publicProfile.editPublicProfile.marketAreas
            const primaryMarketName = markets.find(el => el.id === primaryMarketAreaId).name
            const secondaryMarketName = markets.find(el => el.id === secondaryMarketAreaId)?.name
            try {
                dispatch(stateController.setState({ isProcessing: true }))
                await AgencyPublicProfileService.saveMarkets({ primaryMarketAreaId, secondaryMarketAreaId });
                dispatch(Actions.onSectionSaved());
                dispatch(userActivityInsert({
                    Message: `Saved Markets: ${primaryMarketName}, ${secondaryMarketName}`,
                    PageName: 'Agency Profile [Edit]',
                }))
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }))
            }
        }
    }

    public static closeMarketsEditor() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ currentStep: null }))
            dispatch(userActivityInsert({
                Message: 'Closed Markets',
                PageName: 'Agency Profile [Edit]',
            }))
        }
    }

    public static loadMarketAreas() {
        return async (dispatch, getState: () => AppState) => {
            const data = await AgencyPublicProfileService.getMarketAreas();
            dispatch(stateController.setState({ marketAreas: data.items }));
        }
    }

    public static openRegisteredAgentsEditor() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                entity: {
                    ...prevState.entity,
                    registeredAgents: prevState.registeredAgents
                },
                currentStep: editProfileSteps.registeredAgents
            })))
            dispatch(userActivityInsert({
                Message: 'Opened Agency Contacts',
                PageName: 'Agency Profile [Edit]',
            }))
        }
    }

    public static setRegisteredAgents() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isProcessing: true }))
                const { registeredAgents } = Selectors.getSession(getState());
                const agents = registeredAgents.map<SaveProfileRegisteredAgent>(x => ({
                    id: x.id,
                    name: x.name,
                    title: x.title,
                    email: x.email,
                    phone: x.phone,
                    licensedAreaIds: x.licensedAreas ? x.licensedAreas.map(a => a.id) : [],
                }));
                await AgencyPublicProfileService.saveRegisteredAgents({ agents });
                dispatch(Actions.onSectionSaved());
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }))
            }
        }
    }

    public static closeRegisteredAgentsEditor() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ currentStep: null }))
            dispatch(userActivityInsert({
                Message: 'Closed Agency Contacts',
                PageName: 'Agency Profile [Edit]',
            }))
        }
    }

    public static openAddRegisteredAgentModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ currentStep: editProfileSteps.addRegisteredAgents }))
        }
    }

    public static closeAddRegisteredAgentModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ currentStep: editProfileSteps.registeredAgents }));
        }
    }

    public static addRegisteredAgent(registeredAgent: ProfileRegisteredAgent) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                entity: {
                    ...prevState.entity,
                    registeredAgents: [...prevState.entity.registeredAgents, registeredAgent]
                },
                currentStep: editProfileSteps.registeredAgents
            })));
            dispatch(userActivityInsert({
                Message: `Added Agency Contact: ${registeredAgent.name}, ${registeredAgent.title}, ${registeredAgent.email}, ${registeredAgent.phone}`,
                PageName: 'Agency Profile [Edit]',
            }))
        }
    }

    public static deleteRegisteredAgent(registeredAgent: ProfileRegisteredAgent) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                entity: {
                    ...prevState.entity,
                    registeredAgents: prevState.entity.registeredAgents.filter(x => x != registeredAgent)
                },
                currentStep: editProfileSteps.registeredAgents
            })));
            dispatch(userActivityInsert({
                Message: `Removed Agency Contact: ${registeredAgent.name}, ${registeredAgent.title}, ${registeredAgent.email}, ${registeredAgent.phone}`,
                PageName: 'Agency Profile [Edit]',
            }))
        }
    }

    public static openTrackRecordsEditor() {
        return (dispatch, getState: () => AppState) => {
            document.body.classList.add('track-record-modal');
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                entity: {
                    ...prevState.entity,
                    transfers: prevState.transfers.map(item => ({ ...item }))
                },
                currentStep: editProfileSteps.trackRecord
            })))
            dispatch(userActivityInsert({
                Message: 'Opened Track Record',
                PageName: 'Agency Profile [Edit]',
            }))
        }
    }

    public static setTransfers() {
        return async (dispatch, getState: () => AppState) => {
            document.body.classList.remove('track-record-modal');
            try {
                dispatch(stateController.setState({ isProcessing: true }))
                const { transfers } = Selectors.getSession(getState());
                const transferArgs = transfers.map<SaveAgencyTransfer>(x => ({
                    id: x.id,
                    year: x.year,
                    playerId: x.player.id,
                    fromSquadId: x.squadFrom.id,
                    toSquadId: x.squadTo.id,
                    isContractExtension: x.isContractExtension,
                    durationYears: x.durationYears
                }));
                await AgencyPublicProfileService.saveTransfers({ transfers: transferArgs });
                dispatch(Actions.onSectionSaved());
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }))
            }
        }
    }

    public static closeTrackRecordsEditor() {
        return (dispatch, getState: () => AppState) => {
            document.body.classList.remove('track-record-modal');
            dispatch(stateController.setState({ currentStep: null }))
            dispatch(userActivityInsert({
                Message: 'Closed Track Record',
                PageName: 'Agency Profile [Edit]',
            }))
        }
    }

    public static openAddTrackRecordItemModal() {
        return (dispatch, getState: () => AppState) => {
            const isLimitReached = Selectors.isTransfersLimitReached(getState());
            if (isLimitReached)
            {
                dispatch(stateController.setState({ reachDealLimitModal: true }));
                dispatch(Actions.sendUserActivity('Opened Upgrade Request'));
            }
            else
                dispatch(stateController.setState({ currentStep: editProfileSteps.addTrackRecordItem }));
        }
    }

    public static closeReachDealLimitModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ reachDealLimitModal: false }));
            dispatch(Actions.sendUserActivity('Closed Upgrade Request'));
        }
    }

    public static upgradeLta() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ reachDealLimitModal: false }));
            dispatch(LtaModalActions.open(LtaUpgradeModalType.InstantUpgrade, 'Agency Profile [Track Record]'));
            dispatch(Actions.sendUserActivity('Sent Upgrade Request'));
        }
    }

    public static sendUserActivity (message) {
        return (dispatch, getState: () => AppState) => {
            dispatch(userActivityInsert({
                Message: message,
                PageName: 'Agency Profile [Track Record]',
            }))
        }
    }

    public static closeAddTrackRecordItemModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ currentStep: editProfileSteps.trackRecord }));
        }
    }

    public static addTrackRecordItem(item: AgencyPlayerTransfer) {
        return async (dispatch, getState: () => AppState) => {
            const { entity } = Selectors.getRoot(getState());
            let existingContractExtension: AgencyPlayerTransfer = null;
            if(item.isContractExtension){
                existingContractExtension = entity.transfers.find(x => x.isContractExtension 
                    && x.player.id === item.player.id
                    && x.squadFrom.id === item.squadFrom.id 
                    && x.year === item.year 
                );
            }

            let transfers: AgencyPlayerTransfer[]; 
            if(existingContractExtension){
                existingContractExtension.durationYears = item.durationYears;
                transfers = [...entity.transfers];
            } else {
                transfers = [...entity.transfers, item];
            }

            dispatch(stateController.setState({
                entity: {
                    ...entity,
                    transfers
                },
                currentStep: editProfileSteps.trackRecord
            }));

            if (item.isContractExtension) {
                dispatch(userActivityInsert({
                    Message: ` Added Contract Extension: ${item.year}, ${item.player.name}, Club: ${item.squadFrom.name}, Extension: ${item.durationYears} Years`,
                    PageName: 'Agency Profile [Edit]',
                    PlayerId: item.player.id,
                }))
            } else {
                dispatch(userActivityInsert({
                    Message: `Added Deal: ${item.year}, ${item.player.name}, From: ${item.squadFrom.name}, To: ${item.squadTo.name}`,
                    PageName: 'Agency Profile [Edit]',
                    PlayerId: item.player.id,
                }))
            }
        }
    }

    public static openDeleteTrackRecordConfirmationModal(item: AgencyPlayerTransfer) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                entity: {
                    ...prevState.entity,
                    deleteTrackRecordConfirmationModal: {
                        item
                    }
                }
            })));
        }
    }

    public static closeDeleteTrackRecordConfirmationModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                entity: {
                    ...prevState.entity,
                    deleteTrackRecordConfirmationModal: null
                }
            })));
        }
    }

    public static confirmTrackRecordItemRemoving() {
        return (dispatch, getState: () => AppState) => {
            const { deleteTrackRecordConfirmationModal } = Selectors.getSession(getState());
            const { item } = deleteTrackRecordConfirmationModal;
            const verified = (item.verificationStatus === 2 || item.verificationStatus === 4) ? 'Yes' : 'No'
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                entity: {
                    ...prevState.entity,
                    transfers: prevState.entity.transfers.filter(x => x != item),
                    deleteTrackRecordConfirmationModal: null
                }
            })));
            if (item.isContractExtension) {
                dispatch(userActivityInsert({
                    Message: `Removed Contract Extension: ${item.year}, ${item.player.name}, Club: ${item.squadFrom.name}, Extension: ${item.durationYears} Years, Verified: ${verified}`,
                    PageName: 'Agency Profile [Edit]',
                    PlayerId: item.player.id,
                }))
            } else {
                dispatch(userActivityInsert({
                    Message: `Removed Deal: ${item.year}, ${item.player.name}, From: ${item.squadFrom.name}, To: ${item.squadTo.name}, Verified: ${verified}`,
                    PageName: 'Agency Profile [Edit]',
                    PlayerId: item.player.id,
                }))
            }
        }
    }

    public static changeTrackRecordOrder(result: any, trackRecordItems: AgencyPlayerTransfer[]) {
        return (dispatch, getState: () => AppState) => {
            const { destination, source, draggableId } = result;

            const newTrackRecordItems = Array.from(trackRecordItems);
            const sourceItem = trackRecordItems.filter(item => item.id === Number(draggableId))[0];

            newTrackRecordItems.splice(source.index, 1);
            newTrackRecordItems.splice(destination.index, 0, sourceItem);

            const { entity } = Selectors.getRoot(getState());
            dispatch(stateController.setState({
                entity: {
                    ...entity,
                    transfers: newTrackRecordItems
                }
            }));
        }
    }

    public static loadPlayers() {
        return async (dispatch, getState: () => AppState) => {
            const data = await AgencyPublicProfileService.getPlayers();
            dispatch(stateController.setState({ players: data }));
        }
    }

    public static onOpenLinkFromEmail(isAddMoreDeals: boolean) {
        return (dispatch, getState: () => AppState) => {
            dispatch(userActivityInsert({
                Message: `Clicked on ${isAddMoreDeals ? 'Add a Deal' : 'See Updates'}`,
                PageName: 'Deal Weekly Email',
            }))
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.agency.publicProfile.editPublicProfile
    public static isProcessing = (state: AppState) => Selectors.getRoot(state).isProcessing;
    public static getSession = (state: AppState) => Selectors.getRoot(state).entity;
    public static getCurrentStep = (state: AppState) => Selectors.getRoot(state).currentStep;
    public static isHeaderImageModalOpen = (state: AppState) => Selectors.getCurrentStep(state) === editProfileSteps.headerImage;
    public static isLogoImageModalOpen = (state: AppState) => Selectors.getCurrentStep(state) === editProfileSteps.agencyLogo;
    public static isProfileTextModalOpen = (state: AppState) => Selectors.getCurrentStep(state) === editProfileSteps.profileText;
    public static isMarketsModalOpen = (state: AppState) => Selectors.getCurrentStep(state) === editProfileSteps.markets;
    public static isRegisteredAgentsModalOpen = (state: AppState) => Selectors.getCurrentStep(state) === editProfileSteps.registeredAgents;
    public static isAddAgentModalOpen = (state: AppState) => Selectors.getCurrentStep(state) === editProfileSteps.addRegisteredAgents;
    public static isTrackRecordModalOpen = (state: AppState) => Selectors.getCurrentStep(state) === editProfileSteps.trackRecord;
    public static isAddTrackRecordItemModalOpen = (state: AppState) => Selectors.getCurrentStep(state) === editProfileSteps.addTrackRecordItem;
    public static verifiedPlayersCount = (state: AppState) => {
        return state.agency.publicProfile.editPublicProfile.transfers.filter((el) => (el.verificationStatus === 2 || el.verificationStatus === 4))
    }
    public static getMaxOrder = (state: AppState) => {
        const { transfers } = Selectors.getSession(state);
        if (!transfers.length) {
            return 0;
        }
        return Math.max(...transfers.map(x => x.order));
    };

    public static isTransfersLimitReached = (state: AppState): boolean => {
        const { transfers } = Selectors.getSession(state);

        if(!isLowerTierAgency(state)){
            return false;
        }
        return transfers.length >= LTA_PROFILE_TRANSFERS_LIMIT;
    } 
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    AgencyEditPublicProfileState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};