import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import { CancelTokenSource } from 'axios';
import { getCancelTokenSource } from 'axios-config';
import { getPageSize } from 'ts-components/pagination/helpers/paging-store';
import { 
    SearchRequest, 
    SearchCoachRequest, 
    SearchAgenciesRequest, 
    HeadCoachExperienceEnum, 
    SortByKeyEnum, 
} from "api/search-v3/model/search-request";
import { FootTypeEnum } from 'api/core/foot-type';
import { Actions as FilterActions, Selectors as FilterSelectors } from "./filter.controller";
import { SearchMode, GridToggleState, PaginationClickState } from 'pages/PlayerSearch-v3/shared-components/models/shared-models';
import ClubSideSearchService from 'api/search-v3/search.clubside.service';
import { SearchPlayerPositionEnum } from 'api/search-v3/model/player-positions';
import { SearchPlayerItemForClub } from 'api/search-v3/model/search-player-item-for-club';
import { SearchCoachItemForClub } from 'api/search-v3/model/search-coach-item-for-club'
import { SearchResult } from 'api/search-v3/model/search-result';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { ActionType, PageType, UserActivityType } from 'constants/enums';
import { SearchAgencyItemForClub } from 'api/search-v3/model/search-agency-item-for-club';
import { Actions as AutoSuggestActions} from "pages/PlayerSearch-v3/squad/redux/autosuggest.controller"
import { PlayerActivityService } from "./player-activity.service";
import { CoachProfileService } from 'api/coach-profile/coach-profile.service';
import { DeclareInterestToRole, ReceiverRole } from 'api/search-v3/model/declare-interest-definition';
import historyAccessor from 'history-accessor';
import {userPaths} from 'routes/paths';

type SearchResultClub = SearchResult<SearchPlayerItemForClub>;
type SearchCoachResultClub = SearchResult<SearchCoachItemForClub>;
type SearchAgencyResultClub = SearchResult<SearchAgencyItemForClub>;

class GridState {
    result: SearchResultClub | SearchCoachResultClub | SearchAgencyResultClub;
    resultLoading: boolean;
    selectedItemId: number;
    gridState: GridToggleState;
    pageSize: number;
    processingItems: number[];
    similarityPlayerId: number;
    paginationState: number;
    isVideoModalOpen: boolean;
}

const defaultState: GridState = {
    result: null,
    gridState: GridToggleState.None,
    resultLoading: false,
    selectedItemId: null,
    pageSize: getPageSize('search', 10),
    processingItems: [],
    similarityPlayerId: null,
    paginationState: PaginationClickState.Number,
    isVideoModalOpen: false,
}

const stateController = new StateController<GridState>(
    'SQUAD_SEARCH/GRID',
    defaultState
);

class Actions {
    public static token: CancelTokenSource = null;

    public static dispose() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ ...defaultState }))
        }
    }

    public static setSelectedItem(id: number) {
        return (dispatch, getState: () => AppState) => {
            let oldId = getState().squadSearch.grid.selectedItemId;
            let newId = oldId == id ? null : id;
            dispatch(stateController.setState({ selectedItemId: newId }));
            if (newId == null) return;
            const item = Selectors.getSearchItem(getState(), newId)

            if (!item == null) return;

            // if (item.type === SearchItemType.Agency) { dispatch(AgencyActivityService.openDropDown(item.agency.id)) }
            // else if (item.type === SearchItemType.Club) { dispatch(ClubActivityService.openDropDown(item.club.id)) }
            // else {
            //     dispatch(PlayerActivityService.openDropDown(
            //         item.player.id,
            //         item.player.recommendedFlag,
            //         item.player.isSuspendedContract,
            //         item.player.isOnReleaseList,
            //         item.player.parentSquad ? item.player.parentSquad.id : null,
            //         !item.player.parentSquad && item.player.agency ? item.player.agency.id : null,
            //         Selectors.getGridState(getState())
            //     ))
            // }
        }
    }
    public static sendUserActivity(message) {
        return (dispatch, getState: () => AppState) => {
            dispatch(userActivityInsert({
                PageName: 'Search [Highlight]',
                Message: message,
                PageType: PageType.Search,
            }))
        }
    }
    public static toggleGridState(gridState: GridToggleState, definedSorting: SortByKeyEnum = null) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({
                gridState: gridState,
            }));
            dispatch(FilterActions.initSorting(definedSorting));
        }
    }

    public static onDiscoverPlayersByAttributes() {
        return (dispatch, getState: () => AppState) => {
            dispatch(FilterActions.setSearchModeToFiltered());
            dispatch(FilterActions.initSorting());
            dispatch(FilterActions.toggleQuickSearchMode(true));
            dispatch(Actions.toggleGridState(GridToggleState.PlayersSearch));
        }
    }
    
    public static onReleasedPlayersByAttributes() {
        return (dispatch, getState: () => AppState) => {
            dispatch(FilterActions.setFilterByJustReleasedPlayers());
            dispatch(FilterActions.setSearchModeToFiltered());
            dispatch(FilterActions.initSorting(SortByKeyEnum.RatingHighToLow));
            dispatch(FilterActions.toggleQuickSearchMode(true));
            dispatch(Actions.toggleGridState(GridToggleState.PlayersSearch, SortByKeyEnum.RatingHighToLow));
        }
    }

    public static onSearchAvailablePlayers() {
        return (dispatch, getState: () => AppState) => {
            dispatch(FilterActions.setFilterByOnlyAvailablePlayers());
            dispatch(FilterActions.setSearchModeToFiltered());
            dispatch(FilterActions.initSorting(SortByKeyEnum.AvailableSinceNewestToOldest));
            dispatch(FilterActions.toggleQuickSearchMode(true));
            dispatch(Actions.toggleGridState(GridToggleState.PlayersSearch, SortByKeyEnum.AvailableSinceNewestToOldest));
        }
    }

    // ---------------- UPSELL BANNER ------------------ //
    public static onSearchHeadCoaches(isSearchPage?: boolean) {
        return (dispatch, getState: () => AppState) => {
            dispatch(FilterActions.setSearchModeToFiltered());
            dispatch(FilterActions.initSorting());
            dispatch(FilterActions.toggleQuickSearchMode(true));
            dispatch(Actions.toggleGridState(GridToggleState.HeadCoachesSearch));

            if (isSearchPage) {
                dispatch(userActivityInsert({
                    PageName: 'Search [Coach banner]',
                    Message: 'Clicked Go To Coachfinder Button',
                    PageType: PageType.Search,
                }));
            }
        }
    }

    public static onFindOutMoreClick(isSearchBar?: boolean) {
        return (dispatch, getState: () => AppState) => {
            window.open('https://www.transferroom.com/coachfinder-ipb', '_blank');

            if (!!isSearchBar) {
                dispatch(userActivityInsert({
                    PageName: 'Search Bar',
                    Message: 'Clicked Find Out More',
                    PageType: PageType.Search,
                }))
            } else {
                dispatch(userActivityInsert({
                    PageName: 'Search [Coach banner]',
                    Message: 'Clicked Find Out More Button',
                    PageType: PageType.Search,
                }))
            }

        }
    }

    public static onRegisterEventClick() {
        return async (dispatch, getState: () => AppState) => {
            historyAccessor.push(userPaths.eventRegistration);

            dispatch(userActivityInsert({
                PageName: 'Search [Event banner]',
                Message: 'Register Interest',
                PageType: PageType.Search,
                EventId: getState().auth.eventId,
            }));
        }
    }

    public static moveToSearchResultsByGridToggleState(grid: GridToggleState) {
        return (dispatch, getState: () => AppState) => {
            dispatch(AutoSuggestActions.saveLatestSearchKeyword());
            dispatch(FilterActions.setSearchModeToFiltered());
            dispatch(Actions.toggleGridState(grid));
            dispatch(FilterActions.toggleQuickSearchMode( false ));

            if(grid === GridToggleState.PlayersSearch) {
                dispatch(AutoSuggestActions.sendUserActivity('See All Players'));
            } else if(grid === GridToggleState.AgenciesSearch) {
                dispatch(AutoSuggestActions.sendUserActivity('See All Agencies'));
            } else if (grid === GridToggleState.HeadCoachesSearch) {
                dispatch(AutoSuggestActions.sendUserActivity('See All Coaches'));
            }
        }
    }

    public static quickSearchAll(grid: GridToggleState) {
        return (dispatch, getState: () => AppState) => {
            dispatch(AutoSuggestActions.saveLatestSearchKeyword());
            dispatch(FilterActions.resetFilter());
            dispatch(Actions.toggleGridState(grid));

            if(grid === GridToggleState.PlayersSearch) {
                dispatch(Actions.searchPlayers());

                dispatch(AutoSuggestActions.sendUserActivity('See All Players'));
            } else if(grid === GridToggleState.AgenciesSearch) {
                dispatch(Actions.searchAgencies());

                dispatch(AutoSuggestActions.sendUserActivity('See All Agencies'));
            } else if (grid === GridToggleState.HeadCoachesSearch) {
                dispatch(Actions.searchCoaches());

                dispatch(AutoSuggestActions.sendUserActivity('See All Coaches'));
            }
        }
    }

    public static goToShortList() {
        return (dispatch, getState: () => AppState) => {
            dispatch(FilterActions.setSearchModeToFiltered());
            dispatch(FilterActions.initSorting());
            dispatch(FilterActions.toggleQuickSearchMode(true));
            dispatch(FilterActions.setShowOnlyShortListedPlayers(true))
            dispatch(Actions.toggleGridState(GridToggleState.ShortList));
        }
    }

    public static onSearchByPosition(positionId: SearchPlayerPositionEnum, itemName: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(FilterActions.setPositionFilterValue(positionId, itemName));
            dispatch(FilterActions.setSearchModeToFiltered());
            dispatch(FilterActions.initSorting());
            dispatch(FilterActions.toggleQuickSearchMode(true));
            dispatch(Actions.toggleGridState(GridToggleState.PlayersSearch));
        }
    }

    public static refresh(isUserActivityTracked?: boolean) {
        return (dispatch, getState: () => AppState) => {
            const gridState = Selectors.getGridState(getState());
            if (gridState === GridToggleState.PlayersSearch || gridState === GridToggleState.ShortList) {
                dispatch(Actions.searchPlayers(isUserActivityTracked));
            } else if (gridState === GridToggleState.HeadCoachesSearch) {
                dispatch(Actions.searchCoaches(isUserActivityTracked));
            } else if (gridState === GridToggleState.AgenciesSearch) {
                dispatch(Actions.searchAgencies(isUserActivityTracked));
            }
        }
    }

    public static searchPlayers(isUserActivityTracked?: boolean) {
        return async (dispatch, getState: () => AppState) => {
            try {
                let tabKey = Selectors.getGridState(getState());
                let searchMode = FilterSelectors.getRoot(getState()).searchMode;
                let isAnyFilterActive = FilterSelectors.isFiltersInActiveState(getState());
                if (tabKey == GridToggleState.Search && searchMode == SearchMode.Default && !isAnyFilterActive) {
                    return;
                }

                if (Actions.token) {
                    Actions.token.cancel();
                }
                Actions.token = getCancelTokenSource();
                const state = getState();

                dispatch(stateController.setState({ resultLoading: true, result: null }));
           
                let req: SearchRequest = Selectors.getPlayerRequest(state);
                let result: SearchResultClub = null;
                result = await ClubSideSearchService.searchPlayers(req, Actions.token.token);

                dispatch(stateController.setState({ result: result }));
                dispatch(FilterActions.removeJustReleased());
                if (isUserActivityTracked) {
                    dispatch(AutoSuggestActions.gridSendUserActivity());
                }
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ resultLoading: false }));
            }
        }
    }

    public static searchCoaches(isUserActivityTracked?: boolean) {
        return async (dispatch, getState: () => AppState) => {
            try {
                let tabKey = Selectors.getGridState(getState());
                let searchMode = FilterSelectors.getRoot(getState()).searchMode;
                let isAnyFilterActive = FilterSelectors.isFiltersInActiveState(getState());
                if (tabKey == GridToggleState.Search && searchMode == SearchMode.Default && !isAnyFilterActive)
                    return;

                if (Actions.token) {
                    Actions.token.cancel();
                }
                Actions.token = getCancelTokenSource();

                const state = getState();

                dispatch(stateController.setState({ resultLoading: true, result: null }));

                let req: SearchCoachRequest = Selectors.getCoachRequest(state);
                let result: SearchCoachResultClub = null;
                result = await ClubSideSearchService.searchCoaches(req, Actions.token.token);

                dispatch(stateController.setState({ result: result }));

                if (isUserActivityTracked) {
                    dispatch(AutoSuggestActions.gridSendUserActivity());
                }
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ resultLoading: false }));
            }
        }
    }

    public static searchAgencies(isUserActivityTracked?: boolean) {
        return async (dispatch, getState: () => AppState) => {
            try {
                let tabKey = Selectors.getGridState(getState());
                let searchMode = FilterSelectors.getRoot(getState()).searchMode;
                let isAnyFilterActive = FilterSelectors.isFiltersInActiveState(getState());
                if (tabKey == GridToggleState.Search && searchMode == SearchMode.Default && !isAnyFilterActive) {
                    return;
                }

                if (Actions.token) {
                    Actions.token.cancel();
                }
                Actions.token = getCancelTokenSource();

                const state = getState();

                dispatch(stateController.setState({ resultLoading: true, result: null }));

                let req: any = Selectors.getAgenciesRequest(state);
                let result: SearchAgencyResultClub = null;
                result = await ClubSideSearchService.searchAgencies(req, Actions.token.token);

                dispatch(stateController.setState({ result: result }));

                if (isUserActivityTracked) {
                    dispatch(AutoSuggestActions.gridSendUserActivity());
                }
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ resultLoading: false }));
            }
        }
    }

    public static setAsProcessing = (id: number, isProcessing: boolean) => {
        return (dispatch, getState: () => AppState) => {
            const state = Selectors.getRoot(getState());

            if (isProcessing) {
                dispatch(stateController.setState(
                    { processingItems: [...state.processingItems, id] }
                ))
            }
            else {
                dispatch(stateController.setState(
                    { processingItems: [...state.processingItems.filter(x => x != id)] }
                ))
            }
        }
    }

    public static updatePlayer(id: number, newItem: SearchPlayerItemForClub) {
        return (dispatch, getState: () => AppState) => {
            const state = Selectors.getRoot(getState());

            if (!state.result) return;

            const array: Array<SearchPlayerItemForClub> = state.result.items.map(x => x.id == id ? { ...newItem } : x)

            dispatch(stateController.setState(
                {
                    result: {
                        ...state.result,
                        items: array
                    }
                }
            ))
        }
    }

    public static removePlayerFromList(id: number) {
        return (dispatch, getState: () => AppState) => {
            const state = Selectors.getRoot(getState());

            if (!state.result) return;

            let updated: Array<SearchPlayerItemForClub> = state.result.items.filter(x => (x as SearchPlayerItemForClub).id != id) as Array<SearchPlayerItemForClub>;

            dispatch(stateController.setState(
                {
                    result: {
                        ...state.result,
                        items: updated,
                        totalResultCount: state.result.totalResultCount - 1
                    }
                }
            ))
        }
    }

    public static setPage = (page: number) => {
        return (dispatch, getState: () => AppState) => {
            const paginationState = Selectors.getRoot(getState()).paginationState;

            dispatch(stateController.setState((draftState) => {
                return {
                    ...draftState,
                    result: { ...draftState.result, currentPage: page },
                }
            }))

            dispatch(Actions.refresh());

            let message = '';
            switch (paginationState) {
                case PaginationClickState.Number:
                    message = `Moved to Page: ${page}`;
                    break;
                case PaginationClickState.Left:
                    message = `Moved to Previous Page: ${page}`;
                    break;
                case PaginationClickState.Right:
                    message = `Moved to Next Page: ${page}`;
                    break;
            }

            dispatch(userActivityInsert({
                PageName: 'Search [Pagination]',
                Message: message,
                PageType: PageType.Search
            }))
        }
    }

    public static setPageSize = (page: number, pageSize: number) => {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((draftState) => {
                return {
                    ...draftState,
                    result: { ...draftState.result, currentPage: page },
                    pageSize,
                }
            }))
            dispatch(Actions.refresh())

            dispatch(userActivityInsert({
                PageName: 'Search',
                Message: `Selected ${pageSize} rows`,
                PageType: PageType.Search
            }))
        }
    }

    public static resetPage = () => {
        return (dispatch) => {
            dispatch(stateController.setState((draftState) => {
                return {
                    ...draftState,
                    result: { ...draftState.result, currentPage: 1 },
                }
            }));
            dispatch(FilterActions.removeJustReleased());
        }
    }

    public static paginationSetState(value: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ paginationState: value }));
        }
    }
    
    private static getCoach = (state: AppState, id: number) => {
        return state.squadSearch.grid.result.items
            .filter(x => x != null)
            .map(x => x as SearchCoachItemForClub)
            .find(x => x.staffId == id);
    }

    public static onTooltipHover = (coachId: number, isRating) => {
        return async (dispatch, getState: () => AppState) => {
            const coach = Actions.getCoach(getState(), coachId);
            const gridState = Selectors.getGridState(getState());
            const { squad } = coach as SearchCoachItemForClub;
            
            if(isRating) {
                dispatch(PlayerActivityService.openRatingTooltip(
                    undefined,
                    squad?.id,
                    null,
                    gridState,
                    coachId,
                ));
            } else {
                dispatch(PlayerActivityService.openPositionRoleTooltip(
                    undefined,
                    squad?.id,
                    null,
                    gridState,
                    coachId,
                ))
            }
        }
    }

    public static onVideoModalOpen() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isVideoModalOpen: true }));

            dispatch(userActivityInsert({
                PageName: 'Search [Coach banner]',
                Message: 'Clicked Watch Video Button',
                PageType: PageType.Search,
            }));
        }
    }

    public static onVideoModalClose() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isVideoModalOpen: false }));

            dispatch(userActivityInsert({
                PageName: 'Search [Coach banner]',
                Message: 'Clicked Close Video',
                PageType: PageType.Search,
            }));
        }
    }

    public static onVideoModalPlay() {
        return (dispatch, getState: () => AppState) => {
            dispatch(userActivityInsert({
                PageName: 'Search [Coach banner]',
                Message: 'Clicked Play',
                PageType: PageType.Search,
            }));
        }
    }

    public static onVideoModalPause() {
        return (dispatch, getState: () => AppState) => {
            dispatch(userActivityInsert({
                PageName: 'Search [Coach banner]',
                Message: 'Clicked Pause',
                PageType: PageType.Search,
            }));
        }
    }

    public static onClickBannerPrevArrow(){
        return (dispatch, getState: () => AppState) => {
            dispatch(userActivityInsert({
                PageName: 'Search [Coach banner]',
                Message: 'Navigated to Previous Banner',
                PageType: PageType.Search,
            }));
        }
    }

    public static onClickBannerNextArrow(){
        return (dispatch, getState: () => AppState) => {
            dispatch(userActivityInsert({
                PageName: 'Search [Coach banner]',
                Message: 'Navigated to Next Banner',
                PageType: PageType.Search,
            }));
        }
    }

    public static updateCoach(staffId: number, newItem: SearchCoachItemForClub) {
        return (dispatch, getState: () => AppState) => {
            const state = Selectors.getRoot(getState());

            if (!state.result) return;

            const array: Array<SearchCoachItemForClub> = state.result.items.map(x => x.staffId === staffId ? { ...newItem } : x)

            dispatch(stateController.setState(
                {
                    result: {
                        ...state.result,
                        items: array
                    }
                }
            ))
        }
    }

    public static declareInterestToAgentForCoach = (staffId: number) => {
        return async (dispatch, getState: () => AppState) => {
            try {
                const coach = Actions.getCoach(getState(), staffId);

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processingItems: [...prevState.processingItems, staffId]
                })));

                await CoachProfileService.declareInterestToAgentForCoach(staffId);

                const declareInterestToRole: DeclareInterestToRole = { 
                    ...coach.declareInterest.declareInterestToRole 
                };
                if (declareInterestToRole.toRole === ReceiverRole.Agent) {
                    declareInterestToRole.interestDeclared = true;
                }
                coach.declareInterest = {
                    ...coach.declareInterest,
                    interestDeclaredToAgent: true,
                    declareInterestToRole,
                }

                dispatch(userActivityInsert({
                    PageName: 'Search',
                    Message: 'Declared Interest',
                    PageType: PageType.Search,
                    CoachId: staffId,
                    AgencyId: coach?.agency?.id,
                    ActionType: ActionType.DeclaredInterest,
                    UserActivityType: UserActivityType.Action,
                }));

                dispatch(Actions.updateCoach(staffId, coach));
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processingItems: prevState.processingItems.filter(i => i !== staffId)
                })));
            }
        }
    }

    public static onOpenCoachProfile(staffId: number) {
        return (dispatch, getState: () => AppState) => {
            const coach = Actions.getCoach(getState(), staffId);
            const { squad } = coach as SearchCoachItemForClub;
            const { agencyCoachDisplayInfo } = coach;
            const agencyId = agencyCoachDisplayInfo?.representedBy?.id;

            dispatch(userActivityInsert({
                PageName: 'Search',
                Message: 'Opened Coach Profile',
                PageType: PageType.Search,
                CoachId: staffId,
                ClubId: squad ? squad.id : null,
                AgencyId: agencyId ? agencyId : null,
            }));

            window.open('/coach-profile/' + staffId, '_blank');
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.squadSearch.grid;
    public static isLoading = (state: AppState): boolean => Selectors.getRoot(state).resultLoading || Selectors.getRoot(state).result == null;
    public static getCurrentPage = (state: AppState) => (Selectors.getRoot(state).result || {}).currentPage || 1;
    public static getPageSize = (state: AppState) => Selectors.getRoot(state).pageSize;
    public static getTotalRowCount = (state: AppState) => (Selectors.getRoot(state).result || {}).totalResultCount;
    public static getTotalPageCount = (state: AppState) => (Selectors.getRoot(state).result || {}).totalPageCount;
    public static getGridState = (state: AppState) => Selectors.getRoot(state).gridState;
    public static getIsVideoModalOpen = (state: AppState) => Selectors.getRoot(state).isVideoModalOpen;
    public static getProcessingItems = (state: AppState) => Selectors.getRoot(state).processingItems;

    public static getPlayerRequest = (state: AppState) => {
        const subState = state.squadSearch;
        const structure = subState.filter.structure;
        const pageNumber = Selectors.getCurrentPage(state);

        // const getRange = (criteria, structure) => {
        //     if (!criteria) return null;
        //     if (criteria.max === null && criteria.min === null) return null;
        //
        //     const value = { ...criteria };
        //     if (value.max === structure.max) { value.max = null; }
        //     if (value.min === structure.min) { value.min = null; }
        //
        //     return value;
        // }

        const contractExpiry = subState.filter.playerAttributesFilter.filterData.lessThanTwelveMonth
            ? 12
            : subState.filter.playerAttributesFilter.filterData.lessThanSixMonth
                ? 6
                : subState.filter.playerAttributesFilter.filterData.contractExpiryMonthesMax;

        const getLeaguesList = () => {
            let currentList = [];
            if (subState.filter.playerAttributesFilter.filterData.isCurrentLeague &&
                !subState.filter.playerAttributesFilter.filterData.isCurrentAllLeagues) {
                currentList.push(subState.filter.currentLeague.id);
            }
            if (subState.filter.playerAttributesFilter.filterData.isCurrentAllLeagues) {
                currentList.push(...subState.filter.currentCountry.competitions.map(i => i.id));
            }
            const uniqueList = new Set([...currentList,
                ...subState.filter.playerAttributesFilter.filterData.leaguesList.map(i => i),
                ...subState.filter.playerAttributesFilter.filterData.currentLeagueList.filter(item => item.checked).map(item => item.competitionIds).flat()]);
    
            return Array.from(uniqueList);
        }

        const getPreviousLeaguesList = () => {
            let previousList = [];
            if (subState.filter.playerAttributesFilter.filterData.isPreviousLeague &&
                !subState.filter.playerAttributesFilter.filterData.isPreviousAllLeagues) {
                previousList.push(subState.filter.currentLeague.id);
            }
            if (subState.filter.playerAttributesFilter.filterData.isPreviousAllLeagues) {
                previousList.push(...subState.filter.currentCountry.competitions.map(i => i.id));
            }
            const uniqueList = new Set([
                ...previousList,
                ...subState.filter.playerAttributesFilter.filterData.previousLeaguesList.map(i => i),
                ...subState.filter.playerAttributesFilter.filterData.previousLeagueList.filter(item => item.checked).map(item => item.competitionIds).flat()]);
    
            return Array.from(uniqueList)
        }
        

        const getNationalityList = () => {
            let currentList = [];
            if (subState.filter.playerAttributesFilter.filterData.isCurrentNationality) {
                currentList.push(subState.filter.currentCountry.id);
            }

            return [
                ...currentList,
                ...subState.filter.playerAttributesFilter.filterData.nationalityList.map(i => i)
            ];
        }

        const getRating = () => {
            var ratingFilter = subState.filter.playerAttributesFilter.filterData.rating;
            if (ratingFilter.min == structure.rating.min && ratingFilter.max == structure.rating.max)
                return null;
            else
                return {
                    min: ratingFilter.min !== null ? Number(ratingFilter.min) : ratingFilter.min,
                    max: ratingFilter.max !== null ? Number(ratingFilter.max) : ratingFilter.max,
                };
        }

        const getPrefferedFootTypes = () => {
            let selectedFootTypes = [];

            if (subState.filter.playerAttributesFilter.filterData.preferredFootLeft) {
                selectedFootTypes.push(FootTypeEnum.Left)
            }
            if (subState.filter.playerAttributesFilter.filterData.preferredFootRight) {
                selectedFootTypes.push(FootTypeEnum.Right)
            }
            if (subState.filter.playerAttributesFilter.filterData.preferredFootBoth) {
                selectedFootTypes.push(FootTypeEnum.Both)
            }
            
           return selectedFootTypes;
        }
        let req: SearchRequest = {
            playerFilter: {
                age: {
                    min: subState.filter.playerAttributesFilter.filterData.age.min,
                    max: subState.filter.playerAttributesFilter.filterData.age.max
                },
                gbePass: subState.filter.playerAttributesFilter.filterData.gbePass,
                minHeight: subState.filter.playerAttributesFilter.filterData.height.min,
                maxHeight: subState.filter.playerAttributesFilter.filterData.height.max,
                rating: getRating(),
                contractExpiryMonthesMax: contractExpiry,
                leaguesList: getLeaguesList(),
                previousLeaguesList: getPreviousLeaguesList(),
                nationalityList: getNationalityList(),
                position: subState.filter.positionFilter,
                showOnlyFreeAgentPlayers: subState.filter.playerAttributesFilter.filterData.showOnlyFreeAgentPlayers,
                showOnlyAvailablePlayers: subState.filter.transferTypeFilter.showOnlyAvailablePlayers,
                showOnlyPlayersWithSuspendedContract: subState.filter.transferTypeFilter.showOnlyPlayersWithSuspendedContract,
                showOnlyPlayersLookingForAgent: subState.filter.showOnlyPlayersLookingForAgent,
                showOnlyPlayersOnReleaseList: subState.filter.transferTypeFilter.showOnlyPlayersOnReleaseList,
                transferFee: subState.filter.transferTypeFilter.transferFee,
                anuallGrossSalaryYearly: subState.filter.transferTypeFilter.anuallGrossSalaryYearly,
                loanFee: subState.filter.transferTypeFilter.loanFee,
                marketValue: subState.filter.marketValue,
                minutesPlayed: structure.minutesPlayed,
                footTypes: getPrefferedFootTypes(),
                positionRoles: subState.filter.playerAttributesFilter.filterData.playingStyleRoles,
            },
            shortListOnly: subState.filter.showOnlyShortListedPlayers,
            sortByKey: subState.filter.sortBy,
            keyword: subState.search.keyword,
            pageNumber: pageNumber,
            pageSize: Selectors.getPageSize(state),
        };

        return req;
    }

    public static getCoachRequest = (state: AppState) => {
        const subState = state.squadSearch;
        const coachAttributesFilterState = subState.filter.coachAttributesFilter;
        const structure = subState.filter.structure;
        const pageNumber = Selectors.getCurrentPage(state);

        const getLeaguesList = () => {
            let currentList = [];
            if (coachAttributesFilterState.filterData.isCurrentLeague &&
                !coachAttributesFilterState.filterData.isCurrentAllLeagues) {
                currentList.push(subState.filter.currentLeague.id);
            }
            if (coachAttributesFilterState.filterData.isCurrentAllLeagues) {
                currentList.push(...subState.filter.currentCountry.competitions.map(i => i.id));
            }
            
            
            const leaguesList = [
                ...currentList,
                ...coachAttributesFilterState.filterData.leaguesList.map(i => i),
                ...coachAttributesFilterState.filterData.leagueExperienceList.filter(item => item.checked).map(item => item.competitionIds).flat(),
        ]

            return leaguesList.length > 0 ? leaguesList : null;
        };

        const getNationalityList = () => {
            let currentList = [];
            if (coachAttributesFilterState.filterData.isCurrentNationality) {
                currentList.push(subState.filter.currentCountry.id);
            }
            const nationalityList = [
                ...currentList,
                ...coachAttributesFilterState.filterData.nationalityList.map(i => i)
            ];

            return nationalityList.length > 0 ? nationalityList : null;
        }

        const getMinExperience = () => {
            if (coachAttributesFilterState.filterData.headCoachExperienceList.find(x => x == HeadCoachExperienceEnum.OverTenYears)) {
                return 120; // 10 * 12
            }
            if (coachAttributesFilterState.filterData.headCoachExperienceList.find(x => x == HeadCoachExperienceEnum.OverEightYears)) {
                return 96; // 8 * 12
            }
            if (coachAttributesFilterState.filterData.headCoachExperienceList.find(x => x == HeadCoachExperienceEnum.OverFiveYears)) {
                return 60; // 5 * 12
            }
            if (coachAttributesFilterState.filterData.headCoachExperienceList.find(x => x == HeadCoachExperienceEnum.OverTwoYears)) {
                return 24; // 2*12
            }

            return null;
        }

        const getMaxAge = () => {
            if (coachAttributesFilterState.filterData.isUnder60) {
                return 60;
            }
            if (coachAttributesFilterState.filterData.isUnder50) {
                return 50;
            }
            if (coachAttributesFilterState.filterData.isUnder40) {
                return 40;
            }
            if (coachAttributesFilterState.filterData.isUnder35) {
                return 35;
            }

            return null;
        }

        const getRange = (criteria, structure) => {
            if (!criteria) {
                return null;
            }
            if (criteria.max === null && criteria.min === null) {
                return null;
            }
            if (JSON.stringify(criteria) === JSON.stringify(structure)) {
                return null;
            }

            return {
                min: criteria.min ? Number(criteria.min) : structure.min,
                max: criteria.max ? Number(criteria.max) : structure.max,
            };
        }

        let req: SearchCoachRequest = {
            searchCoachFilter: {
                minExperienceInMonths: getMinExperience(),
                hasPlayingCareer: coachAttributesFilterState.filterData.headCoachExperienceList.find(x=>x == HeadCoachExperienceEnum.HasPlayingCareer) != null,
                leaguesList: getLeaguesList(),
                achievementList:
                    coachAttributesFilterState.filterData.achievementList.length > 0
                        ? coachAttributesFilterState.filterData.achievementList
                        : null,
                achievementAgencyList: null,
                rating: getRange(coachAttributesFilterState.filterData.rating, structure.coachRating),
                trustInYouth: getRange(coachAttributesFilterState.filterData.trustInYouth, structure.trustInYouth),
                playingStyleList:
                    coachAttributesFilterState.filterData.playingStyleList.length > 0
                        ? coachAttributesFilterState.filterData.playingStyleList
                        : null,
                formationList:
                    coachAttributesFilterState.filterData.formationList.length > 0
                        ? coachAttributesFilterState.filterData.formationList
                        : null,
                nationalityList: getNationalityList(),
                maxAge: getMaxAge(),
                licenseTypeList:
                    coachAttributesFilterState.filterData.licenseTypeList.length > 0
                        ? coachAttributesFilterState.filterData.licenseTypeList
                        : null,
                showOnlyFreeAgentCoaches: subState.filter.showOnlyFreeAgentCoaches,
                isHighSuitability: coachAttributesFilterState.filterData.isHighSuitability
            },
            coachesSet: null,
            sortByKey: subState.filter.sortBy,
            keyword: subState.search.keyword,
            pageNumber: pageNumber,
            pageSize: Selectors.getPageSize(state),
        }

        return req;
    }

    public static getAgenciesRequest = (state: AppState) => {
        const subState = state.squadSearch;
        const pageNumber = Selectors.getCurrentPage(state);

        let req: SearchAgenciesRequest = {
            sortByKey: subState.filter.sortBy,
            keyword: subState.search.keyword,
            pageNumber: pageNumber,
            pageSize: Selectors.getPageSize(state),
        }

        return req;
    }
    
    public static getItems = (state: AppState) => (Selectors.getRoot(state).result || {}).items || [];
    public static getSearchItem = (state: AppState, id: number) => {
        return Selectors.getItems(state).find(x => (x as SearchAgencyItemForClub).id == id);
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    GridState as State,
    Actions as Actions,
    stateController as Controller,
    Selectors,
};