import { map, debounceTime } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import {
    CLUB_AD_PITCH_PLAYER,
    CLUB_ADS_FILTER_CHANGED,
    CLUB_ADS_FILTER_SECTION_CLEAR,
} from 'store/actionTypes';
import { clubAdsFetch } from '../actions/club-ads.actions';
import { getClubAdsFetchRequest } from '../reducers/club-ads.reducer';

export const refreshClubAdsOnPitchPlayer = (action$, state$) => {
    // noinspection JSCheckFunctionSignatures
    return action$.pipe(
        ofType(
            CLUB_AD_PITCH_PLAYER.SUCCESS
        ),
        map(() => {
            return clubAdsFetch(getClubAdsFetchRequest(state$.value));
        }),
    );
};

export const refreshClubAdsOnSetFilter = (action$, state$) => {
    // noinspection JSCheckFunctionSignatures
    return action$.pipe(
        ofType(
            CLUB_ADS_FILTER_CHANGED,
            CLUB_ADS_FILTER_SECTION_CLEAR
        ),
        debounceTime(400),
        map(() => {
            return clubAdsFetch(getClubAdsFetchRequest(state$.value));
        }),
    )
}
