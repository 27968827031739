import { getPlayersBySearchKeywordInStatIncludeOwn } from "store/playerProfile/gsnRedux/gsn-performance.actions"

export default {
    GK: 'Goalkeeper',
    LB: 'Left Back',
    RB: 'Right Back',
    CB: 'Centre Back',
    DM: 'Defensive Midfielder',
    CM: 'Central Midfielder',
    AM: 'Attacking Midfielder',
    W: 'Winger',
    F: 'Forward',
}

export const getArticle = (code: string) => {
    if (code == 'AM') return 'an';
    return 'a';
}