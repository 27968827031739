import { IdName } from "api/agency/agent/landing/agency-dashboard";

export interface SummitAgentInvitationModel {
    agencies: AgencyInvitationModel[];
}

export interface AgencyInvitationModel {
    agencyId: number;
    agencyName: string;
    shortlistedPlayersCount: number;
}

export class SummitInvitationSquads {
    public competitions: Array<CompetitionWithActiveSquadsModel>
    public selectedIds: Array<number>;
    public disabledIds: Array<number>;
    public recommendedSquadIds: Array<number>;
}

export class UserSummitRegisterInfo {
    public fullName: string;
    public firstName: string;
    public lastName: string;

    public email: string;

    public customPosition: string;

    public registrationClubName: string;
}

export enum UserSummitPreferenceEnum {
    Ticket1,
    Ticket2,
    Ticket4
}

export class CompetitionWithActiveSquadsModel {

    public id: string;

    public name: string;

    public nameWithArea: string;

    public squads: Array<SquadRefModel>;

    public divisionLevel: number;

    public areaId: number;

    public areaName: string;
}

export class SquadRefModel {
    public id: number;

    public ClubId: number;

    public name: string;

    public englishName: string;

    public photo: string;

    public currency: string;
}

export class UserSummitModel {
    id: number
    userId: number
    leadUserId: number;
    leadUserName: string;
    user: any
    isUserRegistered: boolean
    createdAt: Date
    timestamp: Date
    isTransferIn: boolean
    transferInPositions: Array<string>;
    transferInStepCompleted: boolean
    isTransferOut: boolean
    transferOutPlayers: Array<string>;
    transferOutStepCompleted: boolean
    isGeneralNetworking: boolean
    generalNetworkingStepCompleted: boolean
    generalNetworkingClubs: Array<string>;
    positionNames: Array<string>;
    extraAttendees: Array<ExtraAttendeeModel>;
    registerNowVisible: boolean;
    invitedAgncies: Array<{ id: number, name: string }>;
    invitedAgncySuggestionCount: number;
    pendingAgencyRequest: boolean;
    offlineEventTimeZone?: number | null;
}


export class ExtraAttendeeModel {
    public id: number;

    public name: string;

    public position: string;

    public isRegistered: boolean;

    public order?: number;

}

export enum EventParticipationDaysEnum {
    VipNetworkingEvening = 0,
    InPersonSummit = 1,
    OnlineSummit = 2
}
