import { PageType } from 'constants/enums';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions'
import { GridToggleState } from './grid.controller';
import { OnlyAgentsAndClubsFilter, TransferTypeFilter, PositionFilter, SearchMoreFilter, Structure } from './filter.controller'
import { currencyById } from 'constants/currency';
import MarketValueSelector from 'components/Forms/MarketValueSelector/MarketValueSelector';
import { AppState } from 'root.reducer';
import { getAuth } from 'store/auth/authReducer';
import ContractExpireSelector from 'components/Forms/ContractExpirySelector/ContractExpirySelectorV2';

export class FilterActivityService {
    private static page: string = 'Search';
    private static containers = {
        filter: 'Filter',
        sortBy: 'Sort by',
    }

    private static buildCommand(container: string, action: string, playerId?: number, clubId?: number, agencyId?: number) {
        return {
            Message: action,
            PageType: PageType.Search,
            PlayerId: playerId,
            ClubId: clubId,
            AgencyId: agencyId,
            PageName: `${this.page}${container ? ` [${container}]` : ''}`
        }
    }

    public static sortBy(message: string, gridState: GridToggleState) {
        return (dispatch) => {
            const state = FilterActivityService.getStatePage(gridState)
            const command = this.buildCommand(state ? `${state}-${this.containers.sortBy}` : this.containers.sortBy, message)
            dispatch(userActivityInsert(command))
        }
    }

    public static filterClubAgents(filter: OnlyAgentsAndClubsFilter) {
        return (dispatch) => {
            const message = filter.showOnlyClubs ? 'Only Clubs' : filter.showOnlyTrustedAgents ? 'Only Trusted Agents' : ''

            if (message) {
                const command = this.buildCommand(this.containers.filter, message)
                dispatch(userActivityInsert(command))
            }
        }
    }

    public static filterPlayersLookingForAgent(isLookingForAgentFilter: boolean) {
        return (dispatch) => {
            const message = isLookingForAgentFilter ? 'Only Players Looking for Agent' : '';

            if (message) {
                const command = this.buildCommand(this.containers.filter, message)
                dispatch(userActivityInsert(command))
            }
        }
    }

    public static filterMarketValue(filter: TransferTypeFilter & { positionList: Array<string> }, gridState: GridToggleState) {
        return (dispatch, getState: () => AppState) => {
            const auth = getAuth(getState())
            const message = FilterActivityService.getTranferTypeMessage(filter, currencyById[auth.currency.id])
            const state = FilterActivityService.getStatePage(gridState)

            if (message) {
                const command = this.buildCommand(state ? `${state} ${this.containers.filter}` : this.containers.filter, message)
                dispatch(userActivityInsert(command))
            }
        }
    }

    public static filterPosition(filter: PositionFilter, gridState: GridToggleState) {
        return (dispatch, getState: () => AppState) => {
            if (filter.positionList && filter.positionList[0]) {
                const message = `Position: ${filter.positionList.join(', ')}`
                const state = FilterActivityService.getStatePage(gridState)
                const command = this.buildCommand(state ? `${state} ${this.containers.filter}` : this.containers.filter, message)
                dispatch(userActivityInsert(command))
            }
        }
    }

    public static filterMore(filter: SearchMoreFilter, structure: Structure, gridState: GridToggleState) {
        return (dispatch) => {
            const message = FilterActivityService.getMoreFilterMessage(filter, structure)
            const state = FilterActivityService.getStatePage(gridState)

            if (message) {
                const command = this.buildCommand(state ? `${state} ${this.containers.filter}` : this.containers.filter, message)
                dispatch(userActivityInsert(command))
            }
        }
    }

    public static filterClubPlayers(clubId: number) {
        return (dispatch) => {
            const command = this.buildCommand('Club card', 'View Clubs Players', null, clubId)
            dispatch(userActivityInsert(command))
        }
    }

    public static filterAgencyPlayers(agencyId: number) {
        return (dispatch) => {
            const command = this.buildCommand('Agency card', 'View Agency Players', null, null, agencyId)
            dispatch(userActivityInsert(command))
        }
    }

    private static getMoreFilterMessage(filter: SearchMoreFilter, structure: Structure) {
        const action = []
        if (filter.minRating !== null) { action.push(`Min Player Rating: ${filter.minRating}`) }
        if (filter.age && (filter.age.min !== structure.age.min || filter.age.max !== structure.age.max)) {
            action.push(`Age: ${filter.age.min === filter.age.max ? filter.age.max : filter.age.min + '-' + filter.age.max}`)
        }
        if (filter.gbePass) { action.push(`GBE: Pass`) }
        if (filter.minHeight !== null) { action.push(`Min height: ${filter.minHeight}`) }
        if (filter.minutesPlayed && (filter.minutesPlayed.min !== structure.minutesPlayed.min || filter.minutesPlayed.max !== structure.minutesPlayed.max)) {
            action.push(`Min played: ${filter.minutesPlayed.min === filter.minutesPlayed.max ? filter.minutesPlayed.max : filter.minutesPlayed.min + '-' + filter.minutesPlayed.max}`)
        }
        if (filter.nationalityList && filter.nationalityList[0]) {
            action.push(`Nationality: ${filter.nationalityList.map(item => structure.nationalityList.find(el => el.id === item).name).join(', ')}`)
        }

        if (filter.leaguesList && filter.leaguesList[0]) {
            action.push(`League: ${filter.leaguesList
                .map(item => {
                    const name = structure.leaguesList.find(el => el.id === item).name
                    const i = name.split(',')
                    return `${i[0]}(${i[1] ? i[1].trim() : ''})`;
                })
                .join(', ')}`)
        }

        if (filter.leaguesLists && filter.leaguesLists[0]) {
            action.push(`League List: ${filter.leaguesLists
                .map(item => {
                    return `${item.name}(${item.competitions.map(i => i.name.split(',')[0]).join(', ')})`;
                })
                .join(', ')}`)
        }
        if (filter.contractExpiryMonthesMax) {
            action.push(`Contract expiry: ${ContractExpireSelector.toYrOrMonth(filter.contractExpiryMonthesMax)}`)
        }
        return action.join('; ')
    }

    private static getTranferTypeMessage(filter: TransferTypeFilter & { positionList: Array<string> }, currencyCode: string) {
        let action = []

        if (filter.showOnlyFreeAgentPlayers) {
            action.push('Only Free Agents')
        } else {
            const marketValue = FilterActivityService.getFeeRange(filter.marketValue, currencyCode)

            if (marketValue) { action.push(`xTV: ${marketValue}`) }
        }

        return action.join(', ');
    }

    private static displayWithUnts = (value: number) => MarketValueSelector.displayMarketValue(value) + MarketValueSelector.valueUnts(value)

    public static getFeeRange(range: { min?: number, max?: number }, currencyCode, unts = '') {
        if (!range) return '';

        if (range.min === null && range.max === null) return '';

        if (range.min === null) {
            return `max ${currencyCode}${FilterActivityService.displayWithUnts(range.max)}${unts}`;
        }

        if (range.max === null) {
            return `min ${currencyCode}${FilterActivityService.displayWithUnts(range.min)}${unts}`;
        }

        return `${currencyCode}${FilterActivityService.displayWithUnts(range.min)} - ${FilterActivityService.displayWithUnts(range.max)}${unts}`;
    }

    private static getStatePage(state: GridToggleState) {
        let action = ''
        if (state === GridToggleState.ShortList) { action = 'Shortlist' }
        return action;
    }
}
