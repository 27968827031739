import axios from 'axios-config';
import axiosLib from 'axios'
import { FilterStructure, ScatterChartRequest, ScatterChartData } from "api/player/model/scatter-chart";

export class ScatterChartService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async getData(userId: number, squadId: number, playerId: number): Promise<FilterStructure> {
        const { data } = await axios.get(
            `api/ScatterChart/GetCriteriaData?currentUserId=${userId}&currentSquadId=${squadId}&playerId=${playerId}`,
            this.axiosConfig
        );
        return data;
    }

    public static async getChartData(request: ScatterChartRequest, cancellationtoken: any): Promise<ScatterChartData> {
        const { data } = await axios.post(
            `api/ScatterChart/GetChartDataV2`,
            request,
            {
                ...this.axiosConfig,
                cancelToken: cancellationtoken
            }
        );
        return data;
    }
}