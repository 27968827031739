export const clubBenefits = [
    'Exclusively for club decision makers and trusted agents',
    '250 clubs from 45 countries',
    '15 meetings with other clubs',
    'Get key market information',
    'Expand your market access in the Americas'
]

export const clubBenefitsUsa = [
    'Exclusively for club decision makers',
    'Meet decision makers from 160 clubs',
    'Up to 18 meetings with other clubs',
    'Get key market information',
    'Expand your market access in the Americas'
]


export const agencyBenefits = [
    'Exclusively for club decision makers and trusted agents',
    '250 clubs from 45 countries and approximately a 4:1 club-to-agent ratio',
    'Up to 15 meetings with other clubs',
    'Get key market information',
    'Expand your market access in the Americas'
]
