import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer'
import AgencyAdPermissionsService from 'api/agency/agent/landing/agency-ads-permission.service'
import { AgencyRecommendedAd } from 'api/agency/agent/landing/agency-recommended-ad'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';
import { Actions as PitchActions } from 'pages/agency/authorized-agent/home-page/redux/pitch-opportunities-verified.controller';
import { Actions as DashboardActions } from 'pages/agency/authorized-agent/home-page/redux/dashboard.controller';
import { Dashboard } from 'api/agency/agent/landing/agency-dashboard'

class PitchInvitationState {
    ads: Array<AgencyRecommendedAd>;
    isLoading: boolean;
    processingIds: Array<number>;
    dashboard: Dashboard;
}

const defaultState: PitchInvitationState = {
    ads: [],
    isLoading: false,
    processingIds: [],
    dashboard: {
        playerVerifications: [],
        playerPitchesCount: 0,
        totalPlayersCount: 0,
        countOfVerifiedPlayers: 0,
        playerCreditAvailable: 0,
        recommendationFlags: {
            addPlayers: false,
            chooseYourSubscription: false,
            connectWithClubs: false,
            getVerified: false,
            inviteAllYourColleagues: false,
            eventRegister: false
        },
        invitedClubs: [],
        connectedToAgencyClubs: [],
        countOfPitchOpportunities: 0,
        playersWithOpportunities: [],
        countOfAllActiveSquads: 0,
        totalOpportunitiesWithDeclined: 0
    },
}

const stateController = new StateController<PitchInvitationState>(
    "AGENCY/LANDING_PAGE/PITCH_INVITATION",
    defaultState
);

class Actions {
    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState))
        }
    }

    public static loadAds(adId: number) {
        return async (dispatch) => {
            dispatch(stateController.setState({ isLoading: false }));
            const ads = await AgencyAdPermissionsService.getReccomendedAds();
            if (!!adId) {
                const adIndex = ads.findIndex(item => item.playerAd.id === adId);
                const adItem = ads[adIndex];
                if (adIndex !== -1) {
                    ads.splice(adIndex, 1);
                    ads.unshift(adItem);
                }
                dispatch(stateController.setState({ isLoading: true, ads: ads }));
            } else {
                dispatch(stateController.setState({ isLoading: true, ads: ads }));
            }
        }
    }

    public static dismiss(id: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const subState = getState().agency.landingPage.pitchInvitations;
                const processing = subState.processingIds;
                dispatch(stateController.setState({ processingIds: [...processing, id] }))
                await AgencyAdPermissionsService.dismissAd(id);
                
                const deletedAds = subState.ads.find(x => x.playerAd.id == id);
                if(deletedAds) {
                    dispatch(userActivityInsert({
                        PageName: 'Agency Home [Pitch Invitation]',
                        Message: 'Dismissed a Pitch',
                        ClubId: deletedAds.playerAd.createdBySquad.id,
                        PageType: PageType.AgencyHome,
                    }))
                }

                dispatch(stateController.setState({ ads: [...subState.ads.filter(x => x.playerAd.id != id)] }))
            } catch(e) {
                console.error(e)
            } finally {
                const processing = getState().agency.landingPage.pitchInvitations.processingIds;
                dispatch(stateController.setState({ processingIds: [...processing.filter(x => x != id)] }))
            }
        }
    }

    public static cancel(id: number) {
        return async (dispatch, getState: () => AppState) => {

            const subState = getState().agency.landingPage.pitchInvitations;
            const deletedAds = subState.ads.find(x => x.playerAd.id == id);
            if(deletedAds) {
                dispatch(userActivityInsert({
                    PageName: 'Agency Home [Pitch Invitation]',
                    Message: 'Cancelled a Pitch',
                    ClubId: deletedAds.playerAd.createdBySquad.id,
                    PageType: PageType.AgencyHome,
                }))
            }
        }
    }

    public static openPitch(id: number) {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.landingPage.pitchInvitations;
            const deletedAds = subState.ads.find(x => x.playerAd.id == id);
            if(deletedAds) {
                dispatch(userActivityInsert({
                    PageName: 'Agency Home [Pitch Invitation]',
                    Message: 'Opened a Pitch',
                    ClubId: deletedAds.playerAd.createdBySquad.id,
                    PageType: PageType.AgencyHome,
                }))
            }
        }
    }

    public static pitch(id: number, playerId: number, amount: number, note: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                let subState = getState().agency.landingPage.pitchInvitations;
                const processing = subState.processingIds;
                dispatch(stateController.setState({ processingIds: [...processing, id] }))

                // remove pitched player from opportunities
                const dashboard = getState().agency.landingPage.dashboard;
                const playersWithOpportunities = dashboard.dashboard.playersWithOpportunities; // getState().agency.landingPage.dashboard.dashboard.playersWithOpportunities;

                const playerWithOpportunities = playersWithOpportunities.find(x => x.playerId === playerId);
                if(playerWithOpportunities){
                    const playerAd = playerWithOpportunities.opportunityAds.find(x => x.id === id);

                    if(playerAd){
                        const playerAdIndex = playerWithOpportunities.opportunityAds.indexOf(playerAd);
                        playerWithOpportunities.opportunityAds.splice(playerAdIndex, 1);
                        
                        if(playerWithOpportunities.opportunityAds.length === 0){
                            const playerWithOpportunitiesIndex = playersWithOpportunities.indexOf(playerWithOpportunities);
                            playersWithOpportunities.splice(playerWithOpportunitiesIndex, 1);
                        }
                    }                    
                }

                dispatch(stateController.setState({ dashboard: dashboard.dashboard }))
                dispatch(PitchActions.setPlayers(playersWithOpportunities));

                await AgencyAdPermissionsService.pitchByAd(id,playerId,amount,note);
                
                const ads = subState.ads.find(x => x.playerAd.id == id);
                const player = ads ? ads.players.find(x => x.id == playerId) : null
                
                if(player) {
                    dispatch(userActivityInsert({
                        PageName: 'Agency Home [Pitch Invitation]',
                        Message: 'Pitched a Player',
                        ClubId: ads.playerAd.createdBySquad.id,
                        PlayerId: player.id,
                        PageType: PageType.AgencyHome,
                    }))
                }
                
                subState = getState().agency.landingPage.pitchInvitations;
                dispatch(stateController.setState({  ads: [...subState.ads.filter(x => x.playerAd.id != id)] }))
                dispatch(DashboardActions.refreshCounters());
            } catch(e) {
                console.error(e)
            } finally {
                const processing = getState().agency.landingPage.pitchInvitations.processingIds;
                dispatch(stateController.setState({  processingIds: [...processing.filter(x => x != id)] }))
            }
        }
    }

}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    PitchInvitationState as State,
    Actions as Actions,
    stateController as Controller
};



