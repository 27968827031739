import axios from 'axios-config';
import { ReportModel, SoldPlayersReport } from "./models";

export default class SoldPlayersService {
  private static axiosConfig = {
    headers: { 'Content-Type': 'application/json' },
  }

  public static async getSoldPlayerReportsList(): Promise<Array<ReportModel>> {
    const { data } = await axios.get(
      `api/AdminPlayers/GetSoldPlayersReportsList`,
      this.axiosConfig
    );
    return data;
  }

  public static async getSoldPlayersReport(reportDateStamp: string): Promise<SoldPlayersReport> {
    const { data } = await axios.get(
      `api/AdminPlayers/GetSoldPlayerReport/${reportDateStamp}`,
      this.axiosConfig
    );
    return data;
  }
}
