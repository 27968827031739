import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import PitchService from "api/agency/agent/pitch/pitch.service";
import { Actions as PitchActions } from "pages/agency/authorized-agent/pitch-page/redux/agency-pith-insight.controller";
import { AgencyAdFilterRequest } from "api/agency/agent/pitch/agency-ad-filter-model";
import { AgencyPlayerAd, PlayerIdName } from "api/agency/agent/pitch/pitch-models";
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { ActionType, PageType } from 'constants/enums';
import { currencyById } from 'constants/currency';
import { formatWithCommas } from 'services/utils';

const DEFAULT_PAGE_SIZE = 20;
class State {
    isLoading: boolean;
    pagination: AgentsPaginationState;
    filterCriteria: FilterCriteria;
    playerAds: AgencyPlayerAd[];
    playersShortInfo: PlayerIdName[];
    currentPitchInfo: CurrentPitchInfo;
    filterVisible: boolean;
    pitchOpportunityAmount: number;
    processingIds: Array<number>;
    isNoPitchAccessModalOpen: boolean;
    selectedAd: AgencyPlayerAd;
}

export class CurrentPitchInfo {
    selectedPlayerAd: AgencyPlayerAd;
    currentPitchPlayerId?: number;
    isCurrentPitchPlayerPitchable?: boolean;
    agentMessage: string;
    pitchAmount: number;
}

export class AgentsPaginationState {
    currentPage: number;
    pageCount: number;
    rowCount: number;
    pageSize: number;
    totalLength: number;
}

export class FilterCriteria {
    isForLoan: boolean;
    isForSale: boolean;
    showOnlyPitchableAds: boolean;
    keyword: string;
    positionCodes: string[];
    playerIds: number[];
    isLeftFooted?: boolean;
}

const defaultCurrentPitchInfo: CurrentPitchInfo = {
    selectedPlayerAd: null,
    currentPitchPlayerId: null,
    isCurrentPitchPlayerPitchable: null,
    agentMessage: "",
    pitchAmount: 0,
}

const defaultFilterCriteria: FilterCriteria = {
    isForLoan: false,
    isForSale: false,
    showOnlyPitchableAds: false,
    keyword: "",
    positionCodes: [],
    playerIds: [],
    isLeftFooted: false,
}

const defaultPagination: AgentsPaginationState = {
    currentPage: 1,
    pageCount: 0,
    rowCount: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    totalLength: 0
};

const defaultState: State = {
    isLoading: true,
    processingIds: [],
    pagination: defaultPagination,
    filterCriteria: defaultFilterCriteria,
    playerAds: [],
    playersShortInfo: [],
    filterVisible: false,
    currentPitchInfo: defaultCurrentPitchInfo,
    pitchOpportunityAmount: 0,
    isNoPitchAccessModalOpen: false,
    selectedAd: null
}

const stateController = new StateController<State>(
    "AGENCY/AUTHORIZED-AGENT/PITCH-PAGE",
    defaultState
)

class Actions {
    public static init() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.loadPlayerAds());
            dispatch(Actions.getPitchOpportunityAmount());
            dispatch(Actions.getVerifiedPlayersIdNames())

            var searchParams = new URL(window.location.href).searchParams;
            var isEa = searchParams.get("ea");
            if (isEa) {
                if (searchParams.get("type") === "wkl") {
                    dispatch(userActivityInsert({
                        PageName: `Mail [Weekly]`,
                        Message: `Opened Pitch Page`,
                    }))
                }
            }
            dispatch(userActivityInsert({
                PageName: 'Pitch [Ads]',
                Message: 'Opened Club Ads',
                PageType: PageType.Pitch,
            }))
        }
    }

    public static onPageTurn(paginationIndex) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                pagination: {
                    ...prevState.pagination,
                    currentPage: paginationIndex
                }
            })));
            dispatch(Actions.loadPlayerAds());
        }
    }

    public static loadPlayerAds() {
        return async (dispatch, getState: () => AppState) => {

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                isLoading: true
            })));
            const subState = getState().agency.agent.pitch;
            const pagination: AgentsPaginationState = subState.pagination;
            const filter: FilterCriteria = subState.filterCriteria;
            const requestFilter = this.createFilterRequest(pagination, filter);
            const data = await PitchService.getPlayerAdsByFilter(requestFilter);            

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                playerAds: data.pageItems,
                pagination: {
                    ...prevState.pagination,
                    rowCount: data.total,
                    pageSize: data.pageSize,
                    pageCount: data.pageCount,
                },
                currentPitchInfo: defaultCurrentPitchInfo,
                isLoading: false
            })));
        };
    }

    public static getPitchOpportunityAmount() {
        return async (dispatch, getState: () => AppState) => {
            const res = await PitchService.getPitchOpportunityAmount();

            dispatch(stateController.setState(prevState => ({ ...prevState, pitchOpportunityAmount: res })));
        }
    }

    public static getVerifiedPlayersIdNames() {
        return async (dispatch, getState: () => AppState) => {
            const res = await PitchService.getVerifiedPremiumPlayersIdNames();

            dispatch(stateController.setState(prevState => ({ ...prevState, playersShortInfo: res })));
        }
    }

    public static onFilterChange(filter: FilterCriteria) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                pagination: { ...defaultPagination },
                filterCriteria: { ...filter },
                currentPitchInfo: defaultCurrentPitchInfo,
            })));
            dispatch(Actions.loadPlayerAds());
        }
    }

    public static searchByKeyword(keyword: string) {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let subState = state.agency.agent.pitch;
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                pagination: { ...defaultPagination },
                filterCriteria: { ...subState.filterCriteria, keyword },
                currentPitchInfo: defaultCurrentPitchInfo
            })));
            dispatch(Actions.loadPlayerAds());
            dispatch(userActivityInsert({
                PageName: 'Pitch [Ads]',
                Message: `Search by Club Name or Position: ${keyword}`,
                ActionType: ActionType.Search,
                PageType: PageType.Pitch,
            }))
        }
    }

    public static updateSelectedPlayerAd(agencyPlayerAd: AgencyPlayerAd) {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let subState = state.agency.agent.pitch;
            let currentPitchInfo = { ...subState.currentPitchInfo };
            currentPitchInfo.selectedPlayerAd = agencyPlayerAd === null ? null : { ...agencyPlayerAd };
            currentPitchInfo.currentPitchPlayerId = null;
            currentPitchInfo.isCurrentPitchPlayerPitchable = null;
            currentPitchInfo.agentMessage = null;
            currentPitchInfo.pitchAmount = 0;
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                currentPitchInfo: { ...currentPitchInfo }
            })));
        }
    }

    public static updateFilterVisibleState(isVisible: boolean) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterVisible: isVisible
            })));
        }
    }

    public static updateCurrentPitchPlayerId(playerId: number, isPitchable: boolean) {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let subState = state.agency.agent.pitch;
            let currentPitchInfo = { ...subState.currentPitchInfo };
            currentPitchInfo.currentPitchPlayerId = playerId;
            currentPitchInfo.isCurrentPitchPlayerPitchable = isPitchable;
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                currentPitchInfo: { ...currentPitchInfo }
            })));
        }
    }

    public static updateAgentMessage(message: string) {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let subState = state.agency.agent.pitch;
            let currentPitchInfo = { ...subState.currentPitchInfo };
            currentPitchInfo.agentMessage = message;
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                currentPitchInfo: { ...currentPitchInfo }
            })));
        }
    }

    public static updateAmountPitch(amount: number) {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let subState = state.agency.agent.pitch;
            let currentPitchInfo = { ...subState.currentPitchInfo };
            currentPitchInfo.pitchAmount = amount;
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                currentPitchInfo: { ...currentPitchInfo }
            })));
        }
    }

    public static pitch() {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let subState = state.agency.agent.pitch;
            const adId = subState.currentPitchInfo.selectedPlayerAd.playerAd.id
            const adType = subState.currentPitchInfo.selectedPlayerAd.playerAd.type
            const adAmount = subState.currentPitchInfo.selectedPlayerAd.playerAd.amount
            const currency = subState.currentPitchInfo.selectedPlayerAd.playerAd.currency
            const currencySymbol = currencyById[currency.id];

            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processingIds: [...prevState.processingIds, adId]
                })));

                await PitchService.pitch(subState.currentPitchInfo.selectedPlayerAd.playerAd.id, subState.currentPitchInfo.currentPitchPlayerId, subState.currentPitchInfo.pitchAmount, subState.currentPitchInfo.agentMessage);

                dispatch(userActivityInsert({
                    Message: `Pitched a Player. ${adType == 1 ? 'To Buy' : 'To Loan'}.
                        ${currencySymbol}${formatWithCommas(subState.currentPitchInfo.pitchAmount)}${adType == 1 ? '/yr' : '/mo'}. 
                        ${subState.currentPitchInfo.agentMessage ? subState.currentPitchInfo.agentMessage : ''}
                        ${subState.currentPitchInfo.selectedPlayerAd.playerAd.isAnonymised ? 'Anonymised requirement' : ''}`,
                    PageName: 'Pitch [Ads]',
                    ClubId: subState.currentPitchInfo.selectedPlayerAd.playerAd.createdBySquad.id,
                    PlayerId: subState.currentPitchInfo.currentPitchPlayerId,
                    PageType: PageType.Pitch,
                }))

                dispatch(Actions.loadPlayerAds());
                dispatch(Actions.getPitchOpportunityAmount());
                dispatch(PitchActions.reload());

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processingIds: prevState.processingIds.filter(item => item !== adId),
                    currentPitchInfo: defaultCurrentPitchInfo
                })));

            } catch (e) {
                console.error(e)
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processingIds: prevState.processingIds.filter(item => item !== adId)
                })));
            }
        }
    }

    public static createFilterRequest(pagination: AgentsPaginationState, filter: FilterCriteria): AgencyAdFilterRequest {
        let res = new AgencyAdFilterRequest();
        res.isForLoan = filter.isForLoan;
        res.isForSale = filter.isForSale;
        res.keyword = filter.keyword;
        res.showOnlyPitchableAds = filter.showOnlyPitchableAds;
        res.page = pagination.currentPage;
        res.pageSize = pagination.pageSize;
        res.positionCodes = filter.positionCodes;
        res.playerIds = filter.playerIds;
        res.isLeftFooted = filter.isLeftFooted;

        return res;
    }

    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState))
        }
    }

    public static sendUpgradeUserActivity() {
        return (dispatch) => {
            dispatch(userActivityInsert({
                PageName: 'Pitch',
                Message: `Opened Upgrade Request`,
            }))
        }
    }

    public static openNoPitchAccessModal(selectedAdId: number) {
        return (dispatch, getState: () => AppState) => {
            const subState = getState().agency.agent.pitch;
            const selectedAd = subState.playerAds.find(x => x.playerAd.id === selectedAdId);

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                isNoPitchAccessModalOpen: true,
                selectedAd: selectedAd
            })));

            dispatch(userActivityInsert({
                PageName: 'Pitch [Ads]',
                Message: `Why Can't Pitch Tooltip Viewed`,
                PageType: PageType.Pitch,
            }))
        }
    }

    public static closeNoPitchAccessModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                isNoPitchAccessModalOpen: false,
                selectedAd: null
            })));
        }
    }

}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    stateController as Controller
};