import _ from 'lodash'
import groupBy from 'lodash/groupBy'
import { AppState } from 'root.reducer'
import { getAuth } from 'store/auth/authReducer'
import { StateController } from 'utils/action-declaration'
import { SquadModel } from 'api/admin/club-permissions/models'
import { SuggestionModel } from 'api/admin/onboard-agency/models'
import { getAuthClubId, getAuthSquadId } from 'store/auth/authReducer'
import EventAttendanceService from 'api/admin/event-attendance/event-attendance.service'
import { EventModel, EventSquadModel, AreaWithCompetitionsModel, CompetitionModel } from 'api/admin/event-attendance/models'



export interface SquadModelWithKey extends SquadModel {
    key: string
    value: string
} 
interface AutosuggestOption {
    countryName: string
    id: number
    key: number
    league: string
    logo: string
    value: string
}
export interface AddNewClubModalState {
    suggestionItems: Array<SuggestionModel>
    isModalOpen: boolean
    isProcessing: boolean
    isSuggestionProcessing: boolean
    squadId: number
    league: string
    keyword: string
    countryName: string
}
export interface AddNewEventModalState {
    squads: Array<SquadModelWithKey>
    competitions: Array<CompetitionModel>
    suggestionItems: Array<SquadModelWithKey>
    areasWithCompetitions: Array<AreaWithCompetitionsModel>
    isModalOpen: boolean
    isProcessing: boolean
    isSuggestionProcessing: boolean
    keyword: string
    eventName: string
    selectedArea: {key: string, value: string, children: string}
    selectedCompetition: {key: string, value: string, children: string}
}

class EventAttendanceState {
    rows: Array<EventSquadModel>
    events: Array<EventModel>
    procesingIds: Array<{squadId: number, eventId: number}>
    isFetching: boolean
    filterByClubName: string
    addNewClubModal: AddNewClubModalState
    addNewEventModal: AddNewEventModalState
}

const defaultState = {
    rows: [],
    events: [],
    procesingIds: [],
    isFetching: false,
    filterByClubName: '',

    addNewClubModal: {
        suggestionItems: [],
        isModalOpen: false,
        isProcessing: false,
        isSuggestionProcessing: false,
        squadId: null,
        league: '',
        keyword: '',
        countryName: '',
    },

    addNewEventModal: {
        squads: [],
        competitions: [],
        suggestionItems: [],
        areasWithCompetitions: [],
        isModalOpen: false,
        isProcessing: false,
        isSuggestionProcessing: false,
        keyword: '',
        eventName: '',
        selectedArea: {key: '-1', value: '-1', children: 'All'},
        selectedCompetition: {key: '-1', value: '-1', children: 'All'},
    }
}


const stateController = new StateController<EventAttendanceState>(
    'ADMIN_V2/EVENT-ATTENDANCE',
    defaultState
)


class Actions {

    public static getData() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isFetching: true }))
                await dispatch(Actions.getEvents())
                await dispatch(Actions.getEventSquads())
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState({ isFetching: false }))
            }
        }
    }

    public static getDataSilent() {
        return async (dispatch, getState: () => AppState) => {
            try {
                await dispatch(Actions.getEvents())
                await dispatch(Actions.getEventSquads())
            } catch (err) {
                console.error(err)
            }
        }
    }

    public static getEvents() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const data = await (await EventAttendanceService.loadEvents()).data as Array<EventModel>
                dispatch(stateController.setState({ events: data }))
            } catch (err) {
                console.error(err)
            }
        }
    }

    public static getEventSquads() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const data = await (await EventAttendanceService.loadEventSquads()).data as Array<EventSquadModel>
                const grouped = groupBy(data, 'squadId')
                const rows = Object.values(grouped).map((item: Array<any>) => ({
                    ...item[0].squad,
                    key: item[0].squadId,
                    eventIds: item.filter(i => i.eventId !== 0).map(i => i.eventId),
                }))
                .sort((a, b) => a.englishOfficialName > b.englishOfficialName ? 1 : -1)
                dispatch(stateController.setState({ rows: rows }))
            } catch (err) {
                console.error(err)
            }
        }
    }

    public static onFilterKeywordChange(keyword: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ filterByClubName: keyword }))
        }
    }

    public static onChangeCheckbox(cheked: boolean, squadId: number, eventId: number) {
        return (dispatch, getState: () => AppState) => {
            if (cheked) {
                dispatch(Actions.addSquadToEvent(squadId, eventId))
            }
            if (!cheked) {
                dispatch(Actions.removeSquadFromEvent(squadId, eventId))
            }
        }
    }

    public static addSquadToEvent(squadId: number, eventId: number) {
        return async (dispatch, getState: () => AppState) => {
            const idsItem = {squadId, eventId}
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    procesingIds: [
                        ...prevState.procesingIds,
                        idsItem,
                    ]
                })))
                const data = EventAttendanceService.addSquadToEvent(squadId, eventId)
                if ((await data).status === 200) {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        rows: [
                            ...prevState.rows.map(item => item.id === squadId ? ({ ...item, eventIds: [...item.eventIds, eventId] }) : ({ ...item }))
                        ]
                    })))
                }
            } catch (err) {
                console.error(err)
            } finally {
                await dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    procesingIds: [
                        ...prevState.procesingIds.filter(item => item !== idsItem)
                    ]
                })))
            }
        }
    }

    public static removeSquadFromEvent(squadId: number, eventId: number) {
        return async (dispatch, getState: () => AppState) => {
            const idsItem = {squadId, eventId}
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    procesingIds: [
                        ...prevState.procesingIds,
                        idsItem,
                    ]
                })))
                const data = EventAttendanceService.removeSquadFromEvent(squadId, eventId)
                if ((await data).status === 200) {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        rows: [
                            ...prevState.rows.map(item => item.id === squadId ? ({ ...item, eventIds: [...item.eventIds.filter(id => id !== eventId)] }) : ({ ...item }))
                        ]
                    })))
                }
            } catch (err) {
                console.error(err)
            } finally {
                await dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    procesingIds: [
                        ...prevState.procesingIds.filter(item => item !== idsItem)
                    ]
                })))
            }
        }
    }

    //====== Add new club (Modal) ===========================================================================================

    public static addNewClubOpenModal() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                addNewClubModal: {
                    ...prevState.addNewClubModal,
                    isModalOpen: true,
                }
            })))
        }
    }

    public static addNewClubCloseModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                addNewClubModal: {
                    ...defaultState.addNewClubModal,
                }
            })))
        }
    }

    public static onAddClubKeywordChange(value: string) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                addNewClubModal: {
                    ...prevState.addNewClubModal,
                    keyword: value,
                } 
            })))
            const keyword = getState().admin.eventAttendance.addNewClubModal.keyword
            if (!keyword || keyword === '') {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    addNewClubModal: {
                        ...prevState.addNewClubModal,
                        squadId: null,
                        league: '',
                        countryName: '',
                    }
                })))
            }
            if (value) {
                Actions.loadSquadSuggestionsDebounce(dispatch, value)
            } else {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    addNewClubModal: {
                        ...prevState.addNewClubModal,
                        suggestionItems: [],
                    }
                })))
            }
        }
    }

    private static loadSquadSuggestionsDebounce = _.debounce((dispatch, keyword) => dispatch(Actions.loadClubSuggestions(keyword)), 1000)


    public static loadClubSuggestions(keyword: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const squadId = getAuthSquadId(getState())
                const clubId = getAuthClubId(getState())
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    addNewClubModal: {
                        ...prevState.addNewClubModal,
                        isSuggestionProcessing: true,
                    }
                })))
                const data = await EventAttendanceService.loadSquadSuggestions(keyword, squadId, clubId)
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    addNewClubModal: {
                        ...prevState.addNewClubModal,
                        suggestionItems: data.data,
                    }
                })))
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    addNewClubModal: {
                        ...prevState.addNewClubModal,
                        isSuggestionProcessing: false,
                    }
                })))
            }
        }
    }

    public static loadClubData(item: AutosuggestOption) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                addNewClubModal: {
                    ...prevState.addNewClubModal,
                    league: item.league,
                    countryName: item.countryName,
                    squadId: item.id,
                }
            })))
        }
    }

    public static addSquad() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const squadId = getState().admin.eventAttendance.addNewClubModal.squadId
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    addNewClubModal: {
                        ...prevState.addNewClubModal,
                        isProcessing: true,
                    }
                })))
                await EventAttendanceService.addSquad(squadId)
                await dispatch(Actions.getDataSilent())
                dispatch(Actions.addNewClubCloseModal())
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    addNewClubModal: {
                        ...prevState.addNewClubModal,
                        isProcessing: false,
                    }
                })))
            }
        }
    }

    //====== Add new event (Modal) ==========================================================================================

    public static addNewEventOpenModal() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                addNewEventModal: {
                    ...prevState.addNewEventModal,
                    isModalOpen: true,
                }
            })))
            dispatch(Actions.loadAreasWithCompetitions())
        }
    }

    public static addNewEventCloseModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                addNewEventModal: {
                    ...prevState.addNewEventModal,
                    isModalOpen: false,
                    eventName: '',
                    selectedArea: {key: '-1', value: '-1', children: 'All'},
                    selectedCompetition: {key: '-1', value: '-1', children: 'All'},
                    squads: [],
                    suggestionItems: [],
                    keyword: '',
                }
            })))
        }
    }

    public static loadAreasWithCompetitions() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const areas = getState().admin.eventAttendance.addNewEventModal.areasWithCompetitions
                if (areas.length > 0) return 
                
                const userId = await getAuth(getState()).userId
                const data = (await EventAttendanceService.loadAreasWithCompetitions(userId)).data as Array<any>
                data.unshift({id: -1, name: 'All'})
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    addNewEventModal: {
                        ...prevState.addNewEventModal,
                        areasWithCompetitions: data,
                    }
                })))
            } catch (err) {
                console.error(err)
            } 
        }
    }

    public static onChangeEventName(value: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                addNewEventModal: {
                    ...prevState.addNewEventModal,
                    eventName: value,
                }
            })))
        }
    }

    public static setCountry(option: {key: string, value: string, children: string}) {
        return (dispatch, getState: () => AppState) => {
            const competitions = getState().admin.eventAttendance.addNewEventModal.areasWithCompetitions.find(item => item.id === parseInt(option.value)).competitions as Array<any>

            if (competitions?.filter(item => item.id === -1).length === 0 ) {
                competitions.unshift({id: -1, name: 'All'})
            }
            
            const prevSelectedCountry = getState().admin.eventAttendance.addNewEventModal.selectedArea.value
           
            if (option.value === '-1' || (option.value !== '-1' && prevSelectedCountry !== option.value)) {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    addNewEventModal: {
                        ...prevState.addNewEventModal,
                        selectedCompetition: defaultState.addNewEventModal.selectedCompetition,
                    }
                })))
            }
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                addNewEventModal: {
                    ...prevState.addNewEventModal,
                    selectedArea: option,
                    competitions: competitions || [],
                }
            })))
        }
    }

    public static setCompetition(option: {key: string, value: string, children: string}) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                addNewEventModal: {
                    ...prevState.addNewEventModal,
                    selectedCompetition: option,
                }
            })))
        }
    }

    public static onAddEventKeywordChange(value: string) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                addNewEventModal: {
                    ...prevState.addNewEventModal,
                    keyword: value,
                } 
            })))
            if (value) {
                Actions.loadSquadSuggestionsByCompetitionDebounce(dispatch, value)
            } else {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    addNewEventModal: {
                        ...prevState.addNewEventModal,
                        suggestionItems: [],
                    }
                })))
            }
        }
    }

    private static loadSquadSuggestionsByCompetitionDebounce = _.debounce((dispatch, keyword) => dispatch(Actions.loadSquadSuggestionsByCompetition(keyword)), 1000)


    public static loadSquadSuggestionsByCompetition(keyword: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const areaId = parseInt(getState().admin.eventAttendance.addNewEventModal.selectedArea.value)
                const competitionId = parseInt(getState().admin.eventAttendance.addNewEventModal.selectedCompetition.value)
                const squadId = getAuth(getState()).squadId
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    addNewEventModal: {
                        ...prevState.addNewEventModal,
                        isSuggestionProcessing: true,
                    }
                })))
                const data = (await EventAttendanceService.loadSquadSuggestionsByCompetition(areaId, competitionId, squadId, keyword)).data as Array<SquadModel>
                const withValue = data.map(item => ({
                    ...item,
                    value: item.officialName,
                    key: item.id,
                }))
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    addNewEventModal: {
                        ...prevState.addNewEventModal,
                        suggestionItems: withValue,
                    }
                })))
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    addNewEventModal: {
                        ...prevState.addNewEventModal,
                        isSuggestionProcessing: false,
                    }
                })))
            }
        }
    }

    public static onSuggestionSelected(squad: SquadModel) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                addNewEventModal: {
                    ...prevState.addNewEventModal,
                    squads: [
                        ...prevState.addNewEventModal.squads, squad
                    ]
                }
            })))
        }
    }

    public static removeSquad(id: number) {
        return (dispatch, getState: () => AppState ) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                addNewEventModal: {
                    ...prevState.addNewEventModal,
                    squads: prevState.addNewEventModal.squads.filter(i => i.id !== id)
                }
            })))
        }
    }

    public static addEvent() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    addNewEventModal: {
                        ...prevState.addNewEventModal,
                        isProcessing: true,
                    }
                })))
                const eventName = getState().admin.eventAttendance.addNewEventModal.eventName
                const squads = getState().admin.eventAttendance.addNewEventModal.squads
                await EventAttendanceService.addEvent(eventName, squads)
                await dispatch(Actions.getDataSilent())
                dispatch(Actions.addNewEventCloseModal())
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    addNewEventModal: {
                        ...prevState.addNewEventModal,
                        isProcessing: false,
                    }
                })))
            }
        }
    }
}


class Selectors {
    public static filteredItems(state: AppState) {
        const keyword = state.admin.eventAttendance.filterByClubName
        const rows = state.admin.eventAttendance.rows
        return rows.filter(item => (item.officialName && item.officialName.toLowerCase() || '').includes(keyword.toLowerCase()))
    }
    
    public static addedClubIds(state: AppState) {
        const items = Selectors.filteredItems(state);
        return items.map(item => item.id)
    }
}


const reducer = stateController.getReducer()


export {
    reducer as Reducer,
    EventAttendanceState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
}