import { map, debounceTime } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';
import { ofType } from 'redux-observable';
import {
    SHORTLIST_FILTER_CLEAR,
    SHORTLIST_FILTER_CHANGED,
} from 'store/actionTypes';
import { shortListFetch } from '../actions/shortlist.actions';
import debounceDuration from 'services/debounce-duration';

const refreshShortListEpic = combineEpics(
    (action$, state$) =>
        action$.pipe(
            ofType(SHORTLIST_FILTER_CHANGED, SHORTLIST_FILTER_CLEAR),
            debounceTime(debounceDuration.long),
            map(() => shortListFetch()),
        ),
);

export default refreshShortListEpic;
