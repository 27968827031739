import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer'
import CustomSignInService from 'api/custom-sign-in/custom-sign-in.service'
import { FriendlyProposalModel } from 'api/custom-sign-in/models/friendly-proposal.model';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { modalClose } from 'store/modals/modalsActions'
import { MODAL_OPEN } from 'store/actionTypes'
import { EMAIL_LANDING_FRIENDLY_DONE } from 'constants/modals'
import { ActionType, UserActivityType } from 'constants/enums';

class FriendlyProposalsState {
    isLoading: boolean;
    data: FriendlyProposalModel[];
    interested: Array<number>;
    notAvailable: Array<number>;
    friendlyIdInProgress: Array<number>;
}

const defaultState: FriendlyProposalsState = {
    isLoading: false,
    data: null,
    interested: [],
    notAvailable: [],
    friendlyIdInProgress: []
}

const stateController = new StateController<FriendlyProposalsState>(
    "LANDING/FRIENDLY_PROPOSALS",
    defaultState
);


class Actions {
    public static makeNotAvailable(adId: number) {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().landing.friendlyProposals;

            dispatch(stateController.setState({ friendlyIdInProgress: [...substate.friendlyIdInProgress, adId] }));
            await CustomSignInService.unavailableFriendly(getState().auth.aspNetUserId, adId);
            let ad = substate.data.find(i => i.matchId == adId);
            if (ad) {
                dispatch(userActivityInsert({
                    Message: `Not Available, ${ad.teamType}, ${ad.venue}`,
                    PageName: 'Mail [Friendly Proposals]',
                }))
            }
            
            dispatch(stateController.setState({
                friendlyIdInProgress: substate.friendlyIdInProgress.filter(i => i != adId),
                data: substate.data.filter(i => i.matchId != adId)
            }));
            
        }
    }

    public static makeInterested(adId: number) {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().landing.friendlyProposals;

            dispatch(stateController.setState({ friendlyIdInProgress: [...substate.friendlyIdInProgress, adId] }));
            await CustomSignInService.declareInterestForFriendly(getState().auth.aspNetUserId, adId);
            dispatch(stateController.setState({
                friendlyIdInProgress: substate.friendlyIdInProgress.filter(i => i != adId),
                data: substate.data.map(i => {
                    if (i.matchId == adId) {
                        i.interestDeclared = true;
                        return i;
                    }
                    else {
                        return i;
                    }
                })
            }));
            let ad = substate.data.find(i => i.matchId == adId);
            if (ad) {
                dispatch(userActivityInsert({
                    Message: `Declared Interest, ${ad.teamType}, ${ad.venue}`,
                    PageName: 'Mail [Friendly Proposals]',
                    UserActivityType: UserActivityType.Action,
                    ActionType: ActionType.DeclaredInterest
                }))
            }
        }
    }

    public static loadData(interestedId, notAvailableId) {
        return async (dispatch, getState: () => AppState) => {
            let appState = getState();

            dispatch(stateController.setState({ isLoading: true, }))
            var result = await CustomSignInService.getFriendlyProposals(appState.auth.userId, interestedId, notAvailableId);
            dispatch(stateController.setState({ isLoading: false, data: result }));

            let substate = getState().landing.friendlyProposals;

            if (interestedId != null) {
                dispatch(stateController.setState({
                    data: substate.data.map(i => {
                        if (i.matchId == interestedId) {
                            i.interestDeclared = true;
                            return i;
                        }
                        else {
                            return i;
                        }
                    })
                }));
            }
            if (notAvailableId != null) {
                dispatch(stateController.setState({
                    data: substate.data.map(i => {
                        if (i.matchId == notAvailableId) {
                            i.notAvailable = true;
                            return i;
                        }
                        else {
                            return i;
                        }
                    })
                }));
            }
        }
    }

    public static doneAndRedirect(redirect) {
        return dispatch => {
            dispatch(modalClose(EMAIL_LANDING_FRIENDLY_DONE))
            redirect()
        }
    }

    public static openDoneModal(redirect) {
        return dispatch => {
            const command = {
                type: MODAL_OPEN,
                payload: {
                    id: EMAIL_LANDING_FRIENDLY_DONE,
                    customToggle: () => Actions.doneAndRedirect(redirect),
                    content: {
                        onDone: () => dispatch(Actions.doneAndRedirect(redirect)),
                        text: 'You have cleared all proposals',
                    }
                }
            };
            dispatch(command);
            dispatch(userActivityInsert({
                Message: `Updated all friendly proposals`,
                PageName: 'Mail [Friendly Proposals]'
            }))
        }
    }
}

class Selectors {
    public static isAllFriendliesWasReacted(state: AppState) {
        const items = (state.landing.friendlyProposals.data || [])
        const allItemsCount = items.length
        const interestedCount = items.reduce((acc, item) => {
            if (item.interestDeclared === true) {
                return acc + 1
            } else {
                return acc
            }
        }, 0)
        const notAvailableCount = items.reduce((acc, item) => {
            if (item.notAvailable === true) {
                return acc + 1
            } else {
                return acc
            }
        }, 0)
        const reactedItemsCount = interestedCount + notAvailableCount
        return allItemsCount > 0 && reactedItemsCount >= allItemsCount;
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    FriendlyProposalsState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};



