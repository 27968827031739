import axios from 'axios-config';
import { AgencyRecommendedAd } from './agency-recommended-ad'

export default class AgencyAdPermissionsService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async getReccomendedAds(): Promise<Array<AgencyRecommendedAd>> {
        const { data } = await axios.get<Array<AgencyRecommendedAd>>(`api/v2/AgencyAdPermissions/GetRecommendedAds`, this.axiosConfig)
        return data;
    }

  

    public static async dismissAd(adId: number) {
        const { data } = await axios.post(
            `api/v2/AgencyAdPermissions/Dismiss`,
            { playerAdId: adId },
            this.axiosConfig
        );
        return data;
    }

    public static async pitchByAd(adId: number, playerId: number, amount: number, note: string) {
        const { data } = await axios.post(
            `api/v2/AgencyAdPermissions/Pitch`,
            { playerAdId: adId, playerId: playerId, agentMessage: note, amount: amount },
            this.axiosConfig
        );
        return data;
    }

    public static async activatePitchAccess() {
        const { data } = await axios.post(
            `api/AgencyHomePage/ActivatePitchAccess`,

            this.axiosConfig
        );
        return data;
    }
}