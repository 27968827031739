import React, { Component } from 'react';
import LibTooltip from 'rc-tooltip';
import { v4 } from 'uuid';

/*
    IMPORTANT!
    Tooltip component used here requires child node accepts 
    onMouseEnter, onMouseLeave, onFocus, onClick event.
*/
export default class Tooltip extends Component {
    render() {
        const { children, render, afterVisibleChange, maxWidth, pointerEventsNone, overlayClassName = '', 
        overlayStyle, placement = 'top', offset = [0, -7] } = this.props;
        const tooltipContent = typeof render === 'function' ? render() : <span>{render}</span>;

        const width = maxWidth ? { maxWidth: maxWidth } : {};
        const style = {
            ...overlayStyle,
            ...width
        }

        return (
            <>
                <LibTooltip
                    
                    placement={placement}
                    trigger={['hover']}
                    mouseEnterDelay={0.1}
                    overlayStyle={style}
                    align={{
                        //points: ['tl', 'tr'], // align top left point of sourceNode with top right point of targetNode
                        offset: offset, // the offset sourceNode by 10px in x and 20px in y,
                        // targetOffset: ['30%', '40%'], // the offset targetNode by 30% of targetNode width in x and 40% of targetNode height in y,
                        //overflow: { adjustX: true, adjustY: true }, // auto adjust position when sourceNode is overflowed
                    }}
                    overlay={tooltipContent}
                    overlayClassName={`${pointerEventsNone ? 'pointer-events-none' : ''} ${overlayClassName}`}
                    id={v4()}
                    afterVisibleChange={afterVisibleChange}

                    // props for dev purposes:
                    // mouseLeaveDelay={100_000_000}
                    // defaultVisible
                >
                    {children}
                </LibTooltip>
            </>
        );
    }
}
