import './meeting-session.scss'
import * as React from 'react'
import Button from 'components/Buttons/Button/Button';

export class MeetingSession {
    id: number;
    atFormatted: string;
    atTimeZone: string;
    title: string;
    contactPerson: string;
    contactPersonRole: string;
    playersToDiscuss: Array<MeetingSessionPlayer>;
    playersPersonWantToDiscuss: Array<MeetingSessionPlayer>;
    agendaPassed: boolean;
    squadHasPlayers: boolean;
}

export class MeetingSessionPlayer {
    id: number;
    name: string;
    position: string;
}

class Props {
    entity: MeetingSession;
    isAgency: boolean;
}

class DispatchProps {
    onAgendaClick: () => void;
    onPlayerClick: (id: number) => void;
}

class State {
    showMore: boolean;
}

export class MeetingSessionComponent extends React.Component<Props & DispatchProps, State>{

    private showMoreLimit = 5;
    constructor(props) {
        super(props);
        this.state = { showMore: false }
    }

    showMoreVisible = () => this.props.entity.playersToDiscuss.length > this.showMoreLimit || this.props.entity.playersPersonWantToDiscuss.length > this.showMoreLimit

    withShowMore = (array: Array<MeetingSessionPlayer>) => {
        if (this.state.showMore) return array;
        return array.slice(0, this.showMoreLimit);
    }

    player = (player: MeetingSessionPlayer, id: number, onClick: (id: number) => void) => {
        return <div key={id} className="vs-meeting-session__bodyClubList-player">
            <div onClick={() => { onClick(player.id) }} title={player.name}>{player.name}</div>{' '}{`(${player.position})`}
        </div>
    }

    toggleShowMore = () => {
        this.setState((prev) => ({ showMore: !prev.showMore }))
    }

    updteButton = () => {
        return <Button
            effect={this.props.entity.agendaPassed ? 'outline' : 'no'}
            type="primary"
            large
            style={{ width: 185 }}
            onClick={this.props.onAgendaClick} >
            {this.props.entity.agendaPassed ? 'UPDATE YOUR AGENDA' : 'PREPARE YOUR AGENDA >'}
        </Button>
    }

    private isEmpty = () => this.props.entity.title == null;

    renderEmptyPersonDiscussTip = () => {
        return <div className={"vs-meeting-session__tip"}>
            They have not prepared
            their agenda for this
            meeting yet
        </div>
    }

    renderEmptyYouDiscussTip = () => {
        return <div className={"vs-meeting-session__tip"}>
            You have not prepared
            your agenda for this
            meeting yet
        </div>
    }

    render() {
        const { entity } = this.props;
        let showMoreVisible = this.showMoreVisible();

        return <div className={"vs-meeting-session"}>
            <div className={"vs-meeting-session__header"}>
                <div className={"vs-meeting-session__headerTime"}>
                    <span>{entity.atFormatted}</span>{' '}{entity.atTimeZone}
                </div>
                <div className={"vs-meeting-session__headerClub"} title={entity.title?.length > 24 ? entity.title : undefined}>
                    {this.isEmpty() ? '-' : entity.title}
                </div>
                {
                    !this.isEmpty()
                    &&
                    <div className={"vs-meeting-session__headerUser"}>
                        {entity.contactPerson}{' '}{entity.contactPersonRole && `(${entity.contactPersonRole})`}
                    </div>
                }
            </div>
            {
                !this.isEmpty() && !this.props.isAgency && entity.squadHasPlayers
                &&
                <div className={"vs-meeting-session__body"}>
                    <div className={'vs-meeting-session__bodyClubList'}>
                        {
                            entity.playersToDiscuss.length > 0
                                ?
                                <>
                                    They would like to discuss
                                    {this.withShowMore(entity.playersToDiscuss).map((item, index) => this.player(item, index, this.props.onPlayerClick))}
                                </>
                                :
                                this.renderEmptyPersonDiscussTip()
                        }
                    </div>
                    <div className={'vs-meeting-session__bodyClubList'}>
                        {
                            entity.playersPersonWantToDiscuss.length > 0
                                ?
                                <>
                                    You would like to discuss
                                    {this.withShowMore(entity.playersPersonWantToDiscuss).map((item, index) => this.player(item, index, this.props.onPlayerClick))}
                                </>
                                :
                                this.renderEmptyYouDiscussTip()
                        }
                    </div>
                    <div className={'vs-meeting-session__bodyButton'}>
                        {this.updteButton()}
                    </div>
                </div>
            }
            {
                showMoreVisible
                &&
                <div className={"vs-meeting-session__actions"}>
                    <span onClick={this.toggleShowMore}>{this.state.showMore ? 'Less...' : 'More...'}</span>
                </div>
            }
        </div>
    }
}
