import axios from 'axios-config';
import { PlayerVerificationResponse, SendToVerificationRequest } from "./models";


export default class VerifyService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async getPlayers(): Promise<PlayerVerificationResponse> {
        const { data } = await axios.get<PlayerVerificationResponse>(`api/v2/AgentPlayerInvitation/GetUnverifiedPlayers`, this.axiosConfig)
        return data;
    }

    public static async sendToVerify(request: SendToVerificationRequest): Promise<any> {
        const { data } = await axios.post<any>(`api/v2/AgentPlayerInvitation/SendToVerify`, request, this.axiosConfig)
        return data;
    }
}