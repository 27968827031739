import { StateController } from 'utils/action-declaration';
import AgencySettingsService from 'api/agency/agent/agent-profile/agent-profile.service'
import {
    AgencyProfilePermissionsModel,
    AgencyProfileUserModel,
    AgentProfileModel,
    SaveUserAgentProfileRequest
} from 'api/agency/agent/agent-profile/agent-profile'
import {
    // USER_EDITOR_MODAL,
    // CHANGE_PASSWORD_MODAL,
    DELTE_PROFILE_WARNING
} from 'constants/modals';
import { MODAL_CLOSE, MODAL_OPEN } from 'store/actionTypes'
import { AppState } from 'root.reducer';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';

class State {
    isLoading: boolean;
    list: Array<AgencyProfileUserModel>;
    editUserModalData: any;
    maxUserAmount: number;
    isAllowedAddUsers: boolean;
}

const defaultState: State = {
    isLoading: false,
    list: [],
    editUserModalData: null,
    maxUserAmount: -1,
    isAllowedAddUsers: false,
}

const stateController = new StateController<State>(
    "AGENCY/EDIT_PROFILE/USERS",
    defaultState
);

class Actions {

    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState))
        }
    }

    public static load() {
        return async (dispatch) => {
            try {
                dispatch(stateController.setState({ isLoading: true }));

                dispatch(Actions.getAgencyProfilePermissions());
                const list: Array<AgencyProfileUserModel> = await AgencySettingsService.getAgencyUsers();

                dispatch(stateController.setState({
                    isLoading: false,
                    list,
                }))

            } catch (error) {
                dispatch(stateController.setState({ isLoading: false }))
            }

        }
    }

    public static getAgencyProfilePermissions() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const permissions: AgencyProfilePermissionsModel = await AgencySettingsService.getAgencyProfilePermissions();

                dispatch(stateController.setState({
                    maxUserAmount: permissions.maxUserAmount,
                    isAllowedAddUsers: permissions.isAllowedAddUsers,
                }))

            } catch (err) {
                console.log(err)
            }
        }
    }

    public static deleteUser(id: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isLoading: true }))
                const agencySettings = getState().agency.agent.agencySettings;
                const deletedUser = agencySettings.users.list.find(item => item.agentId == id)

                await AgencySettingsService.deleteUser(id);
                dispatch(Actions.getAgencyProfilePermissions());

                dispatch(stateController.setState((state) => ({
                    ...state,
                    isLoading: false,
                    list: state.list.filter(item => item.agentId != id),
                })))

                dispatch(userActivityInsert({
                    PageName: 'Edit Profile [Users]',
                    Message: `Remove User: ${deletedUser.fullName}`,
                    PageType: PageType.EditProfile
                }))

                dispatch(Actions.closeDeleteProfileModal())

            } catch (error) {
                dispatch(stateController.setState({
                    isLoading: false,
                }))
            }
        }
    }

    public static saveUser(id: number, request: SaveUserAgentProfileRequest) {
        return async (dispatch) => {
            try {
                dispatch(stateController.setState({ isLoading: true }))

                await AgencySettingsService.updateUser(id, request)

                const fullName = `${request.firstName} ${request.lastName}`.trim();
                dispatch(stateController.setState((state) => ({
                    ...state,
                    isLoading: false,
                    list: state.list.map((item) => item.agentId === id ? {
                        ...item, 
                        firstName: request.firstName, 
                        lastName: request.lastName,
                        fullName,
                        userPositionName: request.userPosition
                    } : item)
                })))

                dispatch(userActivityInsert({
                    PageName: 'Edit Profile [Users]',
                    Message: `Updated user: ${request.firstName} ${request.lastName}, ${request.userPosition}`,
                    PageType: PageType.EditProfile
                }))

                dispatch(Actions.closeEditUserModal())

            } catch (error) {
                dispatch(stateController.setState({
                    isLoading: false,
                }))
            }
        }
    }

    public static openDeleteProfileModal = (id: number) => {
        return (dispatch) => {
            let command = {
                type: MODAL_OPEN,
                payload: {
                    id: DELTE_PROFILE_WARNING,
                    content: {
                        onDelete: () => {
                            dispatch(Actions.deleteUser(id))
                        },
                        onModalClose: () => dispatch(Actions.closeDeleteProfileModal())
                    }
                }
            }
            dispatch(command);
        }
    }

    public static closeDeleteProfileModal = () => {
        return (dispatch) => {
            let command = {
                type: MODAL_CLOSE,
                payload: DELTE_PROFILE_WARNING
            }
            dispatch(command);

        }
    }

    public static openEditUserModal(data: any) {
        return async (dispatch) => {
            dispatch(stateController.setState({ editUserModalData: data }))
        }
    }

    public static closeEditUserModal() {
        return async (dispatch) => {
            dispatch(stateController.setState({ editUserModalData: null }))
        }
    }

    public static cancelEditUserModal() {
        return async (dispatch) => {
            dispatch(Actions.closeEditUserModal())
            
            dispatch(userActivityInsert({
                PageName: 'Edit Profile [Users]',
                Message: `Cancelled`,
                PageType: PageType.EditProfile
            }))
        }
    }

    public static addAgent() {
        return async (dispatch) => {
            dispatch(userActivityInsert({
                PageName: 'Edit Profile [Users]',
                Message: `Add an Agent`,
                PageType: PageType.EditProfile
            }))
        }
    }
}

class Selectors {
    public static getAgencySettingsUsers = (state: AppState): State => state.agency.agent.agencySettings.users;
    public static getUserList = (state: AppState): Array<AgencyProfileUserModel> => Selectors.getAgencySettingsUsers(state).list;
    public static isLoading = (state: AppState): boolean => Selectors.getAgencySettingsUsers(state).isLoading;
    public static getEditUserModalData = (state: AppState) => Selectors.getAgencySettingsUsers(state).editUserModalData;
    public static getIsAllowedAddUsers = (state: AppState) => Selectors.getAgencySettingsUsers(state).isAllowedAddUsers;
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    stateController as Controller,
    Selectors as Selectors,
};
