import { StateController } from 'utils/action-declaration';

import { AppState } from 'root.reducer'
import { PlayerAd } from 'api/player-ad/models/player-ad'
import { PlayerAdService } from 'api/player-ad/player-ad-service'
import { getAuthAspId } from 'store/auth/authReducer'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions'
import {Actions as PerformanceActions} from './performance-data.controller'
import { PageType } from 'constants/enums';

class OutdatedAdsState {
    outdatedAds: Array<PlayerAd>;
    isLoading: boolean;
}

const defaultState: OutdatedAdsState = {
    outdatedAds: [],
    isLoading: false
}

const stateController = new StateController<OutdatedAdsState>(
    "LANDING_PAGE/OUTDATED_ADS",
    defaultState
);

class Actions {

    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState))
        }
    }

    public static loadOutdatedPlayerAds() {
        return async (dispatch, getState: () => AppState) => {

            let appState = getState();
            const aspId = getAuthAspId(appState);

            dispatch(stateController.setState({ isLoading: true }));

            let result = await PlayerAdService.getMyOutdatedPlayerAds(aspId);

            dispatch(stateController.setState({ isLoading: false, outdatedAds: result }))
        }
    }
    public static updateAd(adId) {
        return async (dispatch, getState: () => AppState) => {
            const aspId = getState().auth.aspNetUserId
            
            var ad = (getState().landingPage.outdatedAds.outdatedAds).filter(item => item.id === adId);
            if (ad.length > 0) {

                let type = 'To Buy';
                if (ad[0].type === 1) {
                    type = 'To Loan';
                }

                dispatch(userActivityInsert({
                    Message: `Updated Ad: ${ad[0].positionName} ${type}`,
                    PageName: 'Home [Expired Ad]',
                    PageType: PageType.Home
                }));
            }
            
            await PlayerAdService.updateOutdatedAd(aspId, adId)           

            let newList = (getState().landingPage.outdatedAds.outdatedAds).filter(item => item.id !== adId)
            dispatch(stateController.setState({
                outdatedAds: newList
            }));
            dispatch(PerformanceActions.refreshCounters());
        }
    }

    public static removeAd(adId) {
        return async (dispatch, getState: () => AppState) => {
            const aspId = getState().auth.aspNetUserId

            var ad = (getState().landingPage.outdatedAds.outdatedAds).filter(item => item.id === adId);
            if (ad.length > 0) {

                let type = 'To Buy';
                if (ad[0].type === 1) {
                    type = 'To Loan';
                }

                dispatch(userActivityInsert({
                    Message: `Removed Ad: ${ad[0].positionName} ${type}`,
                    PageName: 'Home [Expired Ad]',
                    PageType: PageType.Home
                }));
            }

            await PlayerAdService.removeOutdatedAd(aspId, adId)
            let newList = (getState().landingPage.outdatedAds.outdatedAds).filter(item => item.id !== adId)
            dispatch(stateController.setState({
                outdatedAds: newList
            }))
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    OutdatedAdsState as State,
    Actions as Actions,
    stateController as Controller
};



