import * as redux from 'redux';
import * as reonboarding from './reonboarding-flow/root.controller';
import * as recruitPlayers from './recruit-players/recruit-players.controller';
import * as reonboardingPopup from './reonboarding-popup/reonboarding-popup.controller';

export class ClubState {
    recruitPlayers: recruitPlayers.State;
    reonboarding: reonboarding.ReonboardingRootState;
    reonboardingPopup: reonboardingPopup.State;
}

export function combineReducers() {
    let clubReducer = {
        recruitPlayers: recruitPlayers.Reducer,
        reonboarding: reonboarding.combineReducers(),
        reonboardingPopup: reonboardingPopup.Reducer,
    }

    return redux.combineReducers(clubReducer)
}