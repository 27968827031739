import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import EventPageNotRegistered from './not-registered-state/events.entry'
import EventPageRegistered from './registered-state/events.entry'
import { translate } from 'services/localization';
import FeatureCoordinator from 'utils/featureCoordinator'
import { getAuth } from 'store/auth/authReducer';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { Actions as MiscEventController, Selectors as MiscEventSelectors } from 'app/events/redux/misc.controller'
import Spinner from 'components/Spinner/Spinner';
import { PageType } from 'constants/enums';
import { AppState } from 'root.reducer';
import { UserSummitModel } from 'api/virtual-summit/models/summit-invitationl';
import AuthState from 'store/auth/auth-state';
import { getEventInfo } from 'app/events/components/authorized-event-page/events-map/eventsMap';

interface StateProps {
    isLoading: boolean;
    upcommingEventData: UserSummitModel;
    isRegistrationShown: boolean;
    auth: AuthState;
}

interface DispatchProps {
    onLoad: (squadId: number) => void;
    loadUserSummitsData: () => void;
    loadEventAgenda: (eventId: number) => void;
    userActivityInsert: (obj: any) => void;
}

type Props = StateProps & DispatchProps;

const EventsSwitch = (props: Props) => {

    useEffect(() => {
        const { squadId, userId, eventId } = props.auth;
        document.title = translate('menu.events');
        props.onLoad(squadId);
        props.loadUserSummitsData()
        props.loadEventAgenda(eventId);
        props.userActivityInsert({
            Message: 'Opened Event Page',
            PageName: 'Event Page',
            PageType: PageType.Events,
            EventId: eventId,
        });

        // this is temporary, until we need to define red icon in Event menu
        // by user's visit to Event page
        FeatureCoordinator.setIsDisplayed(FeatureCoordinator.eventsNewFeature);
    }, []);

    const { eventId } = props.auth;
    const { isUserRegistered, /*isLoading*/ } = props.upcommingEventData;
    const { isEventFinished } = getEventInfo(eventId).visibility;

    if (isEventFinished) return <EventPageNotRegistered />

    if (isUserRegistered == null /*|| isLoading*/) return <Spinner />;
    if (isUserRegistered)
        return <EventPageRegistered />;

    return <EventPageNotRegistered />
}

const mapStateToProps = (state: AppState): StateProps => ({
    isLoading: MiscEventSelectors.selectIsLoading(state),
    upcommingEventData: state.events.misc.upcommingSummitData,
    isRegistrationShown: state.events.misc.isRegistrationShown,
    auth: getAuth(state),
});

const mapDispatchToProps: DispatchProps = {
    onLoad: MiscEventController.getEventData,
    loadUserSummitsData: MiscEventController.getUserSummitsData,
    userActivityInsert,
    loadEventAgenda: MiscEventController.getEventAgenda
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsSwitch)
