import { AppState } from 'root.reducer';
import { StateController } from 'utils/action-declaration';
import { SearchItemType, SuggestionItem } from 'api/search/model/suggestion-item'
import _ from 'lodash'
import { getCancelTokenSource } from 'axios-config'
import SearchService from 'api/search/search.clubside.service'
import { Actions as GridController, Selectors as GridSelectors } from './grid.controller'
import * as FilterController from './filter.controller'
import { SuggestionActivityService } from './autosuggest.activity.service'
import { PlayerActivityService } from 'pages/PlayerSearch-v2/redux/player-activity.service';

export enum Tab {
    All = 1,
    Players = 2,
    Agents = 3,
    Clubs = 4
}

class SearchAutosuggestState {
    keyword: string;
    isLoading: boolean;
    wasLoaded: boolean;
    items: SuggestionItem[];
    activeTab: Tab;
}

const defaultState: SearchAutosuggestState = {
    keyword: '',
    items: [],
    isLoading: false,
    wasLoaded: false,
    activeTab: Tab.All,
}

const stateController = new StateController<SearchAutosuggestState>(
    'PLAYER_SEARCH_V2/AUTOSUGGEST',
    defaultState
)

class Actions {

    public static createDebounce = () => _.debounce((dispatch) => dispatch(Actions.fetchSuggestions()), 1000)

    public static cancelToken = null;
    public static fetchDebounced = Actions.createDebounce()

    public static onKeywordChange(keyword: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ keyword }))

            if (keyword.length > 0) {
                Actions.fetchDebounced(dispatch)
            } else {
                dispatch(Actions.onKeywordClear())
            }
        }
    }

    public static setKeywordWithGridRefresh(keyword: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ keyword }))
            dispatch(GridController.resetPage())
            dispatch(GridController.refresh())
        }
    }

    public static setKeyword(keyword: string) {
        return dispatch => {
            dispatch(stateController.setState({ keyword }))
        }
    }

    public static onKeywordClearWithoutRefresh() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ keyword: '', wasLoaded: false, isLoading: false, items: [] }))
            Actions.fetchDebounced.cancel()
            if (Actions.cancelToken) { Actions.cancelToken.cancel() }
            Actions.cancelToken = null;
            Actions.fetchDebounced = Actions.createDebounce()
        }
    }

    public static onKeywordClear() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.onKeywordClearWithoutRefresh())
            dispatch(GridController.resetPage())
            dispatch(Actions.setDefaultTab())
            dispatch(GridController.refresh())
        }
    }

    public static fetchSuggestions() {
        return async (dispatch, getState: () => AppState) => {
            const state = getState();
            const keyword = state.newPlayerSearch.search.keyword
            const showOnlyClubs = FilterController.Selectors.getClubAgentsFilter(state).showOnlyClubs
            const showOnlyAgencies = FilterController.Selectors.getClubAgentsFilter(state).showOnlyTrustedAgents
            const isInShortList = GridSelectors.isInShortList(state)

            if (Actions.cancelToken)
                Actions.cancelToken.cancel()

            Actions.cancelToken = getCancelTokenSource();

            try {
                dispatch(stateController.setState({ isLoading: true }))
                dispatch(GridController.resetPage())
                dispatch(GridController.refresh());

                const data = await SearchService.getSuggestions(keyword, showOnlyClubs, showOnlyAgencies, false, isInShortList, null, Actions.cancelToken.token)

                if (data) {
                    dispatch(SuggestionActivityService.searchValue(keyword, isInShortList))
                    dispatch(stateController.setState({ wasLoaded: true, items: data }))
                }

            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState({ isLoading: false }))
            }
        }
    }

    public static fetchSuggestionsSilentAndNoRefresh() {
        return async (dispatch, getState: () => AppState) => {
            const state = getState();
            const keyword = state.newPlayerSearch.search.keyword
            const showOnlyClubs = FilterController.Selectors.getClubAgentsFilter(state).showOnlyClubs
            const showOnlyAgencies = FilterController.Selectors.getClubAgentsFilter(state).showOnlyTrustedAgents
            const isInShortList = GridSelectors.isInShortList(state)

            if (!keyword.trim()) return;

            try {
                const data = await SearchService.getSuggestions(keyword, showOnlyClubs, showOnlyAgencies, false, isInShortList, null, Actions.cancelToken.token)

                if (data) {
                    dispatch(stateController.setState({ wasLoaded: true, items: data }))
                }
            } catch (e) {
                console.error(e)
            }
        }
    }

    public static disposeState() {
        return dispatch => {
            dispatch(stateController.setState({ isLoading: false, wasLoaded: false, items: [], activeTab: Tab.All }))
        }
    }

    public static onPlayerSelected(playerId: number) {
        return (dispatch, getState: () => AppState) => {
            const player = Selectors.getSuggestionItem(getState())(playerId, SearchItemType.Player)
            window.open('/profile/' + playerId, '_blank');
            dispatch(Actions.onKeywordClear());
            const { currentClubId, agencyId } = player;
            dispatch(SuggestionActivityService.selectPlayer(playerId, player.title, currentClubId, currentClubId ? null : agencyId))
        }
    }

    public static setActiveTab = (activeTab: Tab) => {
        return (dispatch) => {
            dispatch(stateController.setState({ activeTab }))
            dispatch(SuggestionActivityService.selectTab(activeTab))
        }
    }

    public static setDefaultTab = () => {
        return (dispatch, getState: () => AppState) => {
            const clubAgentsFilter = FilterController.Selectors.getClubAgentsFilter(getState())
            const activeTab = clubAgentsFilter.showOnlyClubs ? Tab.Clubs : clubAgentsFilter.showOnlyTrustedAgents ? Tab.Agents : Tab.All;
            dispatch(stateController.setState({ activeTab }))
        }
    }

    public static onReleaseListTooltipHover = (playerId: number) => {
        return async (dispatch, getState: () => AppState) => {
            const item = Selectors.getSuggestionItem(getState())(playerId, SearchItemType.Player);
            const gridState = GridSelectors.getGridState(getState());
            dispatch(PlayerActivityService.openReleaseListTootip(
                playerId,
                item.currentClubId,
                item.agencyId,
                gridState,
            ))
        }
    }
}

class Selectors {
    public static getSuggestionItem = (state: AppState) => (id, type: SearchItemType) =>
        state.newPlayerSearch.search.items.find(item => item.id === id && item.searchItemType === type)
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    SearchAutosuggestState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};