import axios from 'axios-config';
import { User } from 'pages/virtual-summit/redux/confirm-page.controller';
import { SummitAgentInvitationModel, SummitInvitationSquads, UserSummitModel, UserSummitPreferenceEnum, UserSummitRegisterInfo } from './models/summit-invitationl';
import { MeetingPreparationModel, MeetingModel } from './models/meeting-preparation';
import { PreparationTransferIn } from 'api/virtual-summit/models/preparation-transfer-in'
import { PreparationTransferOut } from 'api/virtual-summit/models/preparation-transfer-out'
import { RewardProgramModel, RewardProgramItemModel } from 'api/virtual-summit/models/reward-program'
import { AgencyRegistrationRequest } from './models/agency-registration'
import { DeclineEventRegistrationModel } from './models/declined-event-registration'

export default class VirtualSummitService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async setObjectives(userId: number, isTransferIn: boolean, isTransferOut: boolean, isGeneralNetworking: boolean): Promise<any> {
        await axios.post(
            `api/UserSummits/Attendance/${userId}/Details`,
            {
                isTransferIn: isTransferIn,
                isTransferOut: isTransferOut,
                isGeneralNetworking: isGeneralNetworking,
            },
            this.axiosConfig
        );
    }

    public static async setTransferIn(userId: number): Promise<any> {
        await axios.post(
            `api/UserSummits/Attendance/${userId}/Details`,
            {
                isTransferIn: true,
            },
            this.axiosConfig
        );
    }


    public static async setTransferOut(userId: number): Promise<any> {
        await axios.post(
            `api/UserSummits/Attendance/${userId}/Details`,
            {
                isTransferOut: true,
            },
            this.axiosConfig
        );
    }

    public static async setGeneralNetworking(userId: number): Promise<any> {
        await axios.post(
            `api/UserSummits/Attendance/${userId}/Details`,
            {
                isGeneralNetworking: true,
            },
            this.axiosConfig
        );
    }

    public static async setTransferInIsCompleted(userId: number): Promise<any> {
        await axios.post(
            `api/UserSummits/Attendance/${userId}/Details`,
            {
                transferInStepCompleted: true,
            },
            this.axiosConfig
        );
    }

    public static async setTransferOutIsCompleted(userId: number): Promise<any> {
        await axios.post(
            `api/UserSummits/Attendance/${userId}/Details`,
            {
                transferOutStepCompleted: true,
            },
            this.axiosConfig
        );
    }

    public static async setGeneralNetworingIsCompleted(userId: number): Promise<any> {
        await axios.post(
            `api/UserSummits/Attendance/${userId}/Details`,
            {
                generalNetworkingStepCompleted: true,
                isGeneralNetworking: true
            },
            this.axiosConfig
        );
    }

    public static async setRewardIsCompleted(userId: number): Promise<any> {
        await axios.post(
            `api/UserSummits/Attendance/${userId}/Details`,
            {
                isRewardComplete: true
            },
            this.axiosConfig
        );
    }

    public static async loadMySquadPlayers(currentClubId: number, currentUserId: number, currentSquadId: number): Promise<any> {
        const { data } = await axios.post(
            `api/MySquad/GetPlayersByFilterWithPagination/${currentClubId}/${currentSquadId}/${currentUserId}`,
            {
                ContractExpiryMax: 120,
                ContractExpiryMin: 0,
                MaxAge: 40,
                MinAge: 16,
                PageSize: 10000
            },
            this.axiosConfig
        )
        return data;
    }

    public static async setAvailabilities(currentUserId: number, availabilities: Array<any>): Promise<any> {
        const { data } = await axios.patch(
            `api/UserSummits/PlayerAvailability/${currentUserId}`,
            availabilities,
            this.axiosConfig
        )
        return data;
    }


    public static async setExtraAtendee(currentUserId: number, extraAttendees: Array<{ userId: number, orderNumber: number }>): Promise<UserSummitModel> {
        const { data } = await axios.post(
            `api/UserSummits/ExtraAttendance/${currentUserId}`,
            { attendees: extraAttendees },
            this.axiosConfig
        )
        return data;
    }

    public static async setPreferences(currentUserId: number, tablesCount: UserSummitPreferenceEnum): Promise<UserSummitModel> {
        const { data } = await axios.post(
            `api/UserSummits/Preference/${currentUserId}`,
            { preference: tablesCount },
            this.axiosConfig
        )
        return data;
    }

    public static async getConfirmRegistrationData(currentUserId: number): Promise<UserSummitModel> {
        const { data } = await axios.get(
            `api/UserSummits/Attendance/${currentUserId}`,
            this.axiosConfig
        )
        return data;
    }

    public static async setConfirmRegistrationData(currentUserId: number, userData: User): Promise<any> {
        const { data } = await axios.post(
            `api/UserSummits/Attendance/${currentUserId}`,
            userData,
            this.axiosConfig
        )
        return data;
    }

    public static async registerUser(userData: User, squadId: any, eventId: number): Promise<any> {
        const { data } = await axios.post(
            `api/Users/RegisterSummitUser`,
            squadId ? {
                firstName: userData.firstName,
                lastName: userData.lastName,
                fullName: `${userData.firstName} ${userData.lastName}`,
                email: userData.email,
                phoneCodeAreaId: userData.phoneCodeAreaId,
                phone: userData.mobilePhone,
                customPosition: userData.title,
                registrationClubName: userData.club,
                userPositionId: 16,
                squadId: squadId,
                eventId: eventId,
            } :
                {
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    fullName: `${userData.firstName} ${userData.lastName}`,
                    email: userData.email,
                    phoneCodeAreaId: userData.phoneCodeAreaId,
                    phone: userData.mobilePhone,
                    customPosition: userData.title,
                    registrationClubName: userData.club,
                    userPositionId: 16,
                    eventId: eventId
                },
            this.axiosConfig
        )
        return data;
    }

    public static async registerAgency(userData: AgencyRegistrationRequest): Promise<any> {
        const { data } = await axios.post(
            `api/UserSummits/AttendanceAgency`,
            userData,
            this.axiosConfig
        )
        return data;
    }

    

    public static async GetSummitRegisterInfo(email: string): Promise<UserSummitRegisterInfo> {
        const { data } = await axios.get(
            `api/Users/GetSummitRegisterInfo/${email}`,
            this.axiosConfig
        )
        return data;
    }

    public static async getSummitAgencies(): Promise<SummitAgentInvitationModel> {
        const { data } = await axios.get(
            `api/UserSummits/GetSummitAgentsInvitations`,
            this.axiosConfig
        )
        return data;
    }

    public static async getSummitClubs(currentUserId: number): Promise<SummitInvitationSquads> {
        const { data } = await axios.get(
            `api/UserSummits/GetSummitInvitations/${currentUserId}`,
            this.axiosConfig
        )
        return data;
    }

    public static async getSummitClubsNonTr(currentUserId: number): Promise<SummitInvitationSquads> {
        const { data } = await axios.get(
            `api/UserSummits/GetSummitInvitationsNonTr/${currentUserId}`,
            this.axiosConfig
        )
        return data;
    }

    public static async sendSummitAgentInvitations(agencyIds: Array<number>): Promise<any> {
        await axios.post(
            `api/UserSummits/SendSummitAgentInvitations`,
            agencyIds,
            this.axiosConfig
        );
    }

    public static async setSummitClubs(userId: number, ids: Array<number>): Promise<any> {
        await axios.post(
            `api/UserSummits/SaveSummitInvitations/${userId}`,
            ids,
            this.axiosConfig
        );
    }

    public static async setSummitClubsNonTr(userId: number, ids: Array<number>): Promise<any> {
        await axios.post(
            `api/UserSummits/SaveSummitInvitationsNonTr/${userId}`,
            ids,
            this.axiosConfig
        );
    }

    public static async unsubscribe(email: string): Promise<any> {
        const { data } = await axios.get(
            `api/UserSummits/UnsubscribeSummitInvitations/${email}`,
            this.axiosConfig
        );
        return data;
    }

    public static async getConfirmedAttendeesData(): Promise<any> {
        const { data } = await axios.get(
            `api/Event/GetVirtualSummitSquads`,
            this.axiosConfig
        )
        return data
    }

    public static async getMeetingPraparationData(userId: number): Promise<MeetingPreparationModel> {

        const { data } = await axios.get(
            `api/UserMeeting/Preparation/${userId}`,
            this.axiosConfig
        )
        return data
    }

    public static async getMeetingPraparationDataV2(userId: number): Promise<Array<MeetingPreparationModel>> {

        const { data } = await axios.get(
            `api/UserMeeting/PreparationV2/${userId}`,
            this.axiosConfig
        )
        return data
    }

    public static async getPreparationTransferIn(userId: number, meetingId: number): Promise<PreparationTransferIn> {
        const { data } = await axios.get(
            `api/UserMeeting/PreparationTransferInSource/${userId}/${meetingId}`,
            this.axiosConfig
        )
        return data
    }


    public static async SetTransferInPlayers(userId: number, meetingId: number, playerIds: Array<number>): Promise<any> {
        const { data } = await axios.post(
            `api/UserMeeting/SetTransferInPlayers/${userId}/${meetingId}`,
            playerIds,
            this.axiosConfig
        )
        return data
    }

    public static async SetTransferOutPlayers(userId: number, meetingId: number, playerIds: Array<number>): Promise<any> {
        const { data } = await axios.post(
            `api/UserMeeting/SetTransferOutPlayers/${userId}/${meetingId}`,
            playerIds,
            this.axiosConfig
        )
        return data
    }

    public static async CompleteStepper(userId: number, meetingId: number): Promise<any> {
        const { data } = await axios.post(
            `api/UserMeeting/CompleteStepper/${userId}/${meetingId}`,
            null,
            this.axiosConfig
        )
        return data
    }

    public static async getPreparationTransferOut(userId: number, meetingId: number): Promise<PreparationTransferOut> {
        const { data } = await axios.get(
            `api/UserMeeting/PreparationTransferOutSource/${userId}/${meetingId}`,
            this.axiosConfig
        )
        return data
    }

    public static async getPreparationByMeetingId(userId: number, meetingId: number): Promise<MeetingModel> {
        const { data } = await axios.get(
            `api/UserMeeting/Preparation/${userId}/Meetings/${meetingId}`,
            this.axiosConfig
        )
        return data;
    }

    public static async saveAgendaObjectivities(id: number, transfersIn: boolean, transfersOut: boolean): Promise<any> {
        const { data } = await axios.post(
            `api/UserMeeting/Agenda/${id}`,
            {
                TransferIn: transfersIn,
                TransferOut: transfersOut
            },
            this.axiosConfig
        )
        return data;
    }

    public static async requestUpgradeToGetAccess(userId): Promise<any> {
        const { data } = await axios.post(
            `api/UserMeeting/RequestUpgradeToGetAccess/${userId}`,
            null,
            this.axiosConfig
        )
        return data;
    }

    public static async getRewardProgram(userId: number): Promise<RewardProgramModel> {
        const { data } = await axios.get(
            `api/UserSummits/RewardProgram/${userId}`,
            this.axiosConfig
        )
        return data;
    }


    public static async setRewardProgram(userId: number, items: Array<RewardProgramItemModel>): Promise<any> {
        const { data } = await axios.post(
            `api/UserSummits/RewardProgram/${userId}`,
            { items },
            this.axiosConfig
        )
        return data;
    }

    public static async declineEventRegistration(payload: DeclineEventRegistrationModel): Promise<boolean> {
        const { data } = await axios.post(
            `api/UserSummits/DeclineEventRegistration`,
            payload,
            this.axiosConfig
        )
        return data;
    }
}