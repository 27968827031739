import React from 'react'
import styled from 'styled-components'

export const PastEventMainPhoto = ({ images }) => (
    <Section>
        <div className="col-main">
            <img src={images[0].src} alt="" />
        </div>
        <div className="col-sub">
            <ul>
                <li>
                    <img src={images[1].src} alt="" />
                </li>
                <li>
                    <img src={images[2].src} alt="" />
                </li>
                <li>
                    <img src={images[3].src} alt="" />
                </li>
            </ul>
        </div>
    </Section>
);

const Section = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-right: 0px;
    margin-left: 0px;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
    .col-main {
        width: 77%;
        @media (max-width: 991px) {
            width: 100%;
        }
        > img {
            max-width: 100%;
            height: auto;
            border-radius: 10px;
            overflow: hidden;
            box-shadow: 0 8px 30px -5px rgba(34, 42, 60, 0.4);
        }
    }
    .col-sub {
        width: 19%;
        @media (max-width: 991px) {
            width: 100%;
        }
        > ul {
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            padding: 0;
            @media (max-width: 991px) {
                flex-wrap: nowrap;
            }
            > li {
                list-style-type: none;
                display: block;
                position: relative;
                margin: 10px 0;
                border-radius: 10px;
                overflow: hidden;
                box-shadow: 0 8px 30px -5px rgba(34, 42, 60, 0.4);
                @media (max-width: 991px) {
                    margin: 17px 5px 0px;
                }
                > img {
                    max-width: 100%;
                    height: auto;
                }
            }
        }
    }
`