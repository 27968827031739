export interface CompetitionWithActiveSquadsAndRecommendersModel {
    recommenders: SquadRefModel[];
    competitionWithActiveSquads: CompetitionWithActiveSquadsModel[];
}
export interface CompetitionWithActiveSquadsModel {
    id: number;
    name: string;
    nameWithArea: string;
    squads: SquadRefModel[];
    divisionLevel: number;
    areaId: number;
    areaName: string;
    isCollapsed: boolean;
}

export interface SquadRefModel {
    id: number;
    clubId: number | null;
    name: string;
    englishName: string;
    photo: string;
    currency: CurrencyEnum;
}

export enum CurrencyEnum {
    EUR = 1,
    USD = 2,
    GBP = 3,
    //CHF = 4,
}

export interface SetStrategyRequest {
    isAnyPlan: boolean;
    plan: StrategyPlanModel;
}

export interface StrategyPlanModel {
    playerId: number;
    isPlanningTransfer: boolean;
    isPlanningLoan: boolean;
    transferFeeFrom: number;
    transferFeeTo: number;
    grossSalaryFrom: number;
    grossSalaryTo: number;
    loanBudgetFrom: number;
    loanBudgetTo: number;
    strategySquadSet: number[];
}