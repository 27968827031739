import { SearchAgencyItem } from './search-agency-item';
import { SearchClubItem } from './search-club-item';
import { SearchPlayerItemForClub } from './search-player-item-for-club';
import { SearchPlayerItemForAgency } from './search-player-item-for-agency';

export class SearchResult<TSearchItemType> {
    public items: Array<TSearchItemType>;
    public totalResultCount: number;
    public totalPageCount: number;
    public currentPage: number;
}

export class SearchItemForClub {
    public type: SearchItemType;
    public player: SearchPlayerItemForClub;
    public agency: SearchAgencyItem;
    public club: SearchClubItem;
}

export class SearchItemForAgency {
    public type: SearchItemType;
    public player: SearchPlayerItemForAgency;
    public agency: SearchAgencyItem;
    public club: SearchClubItem;
}

export enum SearchItemType {
    Player = 1,
    Club = 2,
    Agency = 3
}
