export interface AgencyAgentInvitationCreateRequest {
    agents: AgentInvitation[];
}

export interface AgentInvitation {
    name: string;
    email: string;
    isMaster: boolean;
}

export class InviteAgencyResponse {
    isSucess: boolean;
    errors: Array<InviteAgencyReponseError>;
}

export class InviteAgencyReponseError {
    email: string;
    reason: InviteAgencyReponseErrorReason
}

export enum InviteAgencyReponseErrorReason {
    AlreadyExist = 0,
    AlreadyInvited = 1
}