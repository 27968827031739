import * as redux from 'redux';
import * as SettingsController from 'pages/coach/settings-page/settings-page.controller';
import * as OnboardingController from 'pages/coach/onboarding/onboarding.controller';
import * as ConfirmEmailController from 'pages/coach/email-confimation/email-confirmation.controller';
import * as AgencyConfirmController from 'pages/coach/agency-confirm/agency-confirm.controller';

export type CoachState = {
    settings: SettingsController.State,
    onboarding: OnboardingController.State,
    confirmEmail: ConfirmEmailController.State,
    agencyConfirm: AgencyConfirmController.State,
}

export function combineReducers() {

    const coachReducers = {
        settings: SettingsController.Reducer,
        onboarding: OnboardingController.Reducer,
        confirmEmail: ConfirmEmailController.Reducer,
        agencyConfirm: AgencyConfirmController.Reducer,
    };

    return redux.combineReducers(coachReducers);
}
