import React from 'react'
import Slider from "react-slick";
import Section from "./testimonials-slider.styled";

type OwnProps = {
    testimonials: any[]
}

const TestimonialsSlider = ({ testimonials }: OwnProps) => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        cssEase: 'cubic-bezier(0.93,-0.29,0.26,1.16)',
        nextArrow: <RightArrow />,
        prevArrow: <LeftArrow />
    };

    const renderWithImage = (item, index) => (
        <div key={index}>
            <div className="testimonial-slider__item">
                <div className="testimonial-slider__item-image">
                    <div style={{backgroundImage: `url('${item.img}')`}}></div>
                </div>
                <div className="testimonial-slider__item-description">
                    <h6><b>{item.fullName}</b>{`, ${item.position || ''}${item.position ? ',' : ''} ${item.club}`}</h6>
                    <p>{item.text}</p>
                </div>
            </div>
        </div>
    )

    const renderOnlyText = (item, index) => (
        <div key={index}>
            <div className="testimonial-slider__item" style={{ height: '100%', alignItems: 'center' }}>
                <div className="testimonial-slider__item-description">
                    <p>{item.text}</p>
                </div>
            </div>
        </div>
    )


    return (
        <Section>
            <div className="testimonial-slider">
                <Slider {...settings} >
                    {testimonials.map((item, index) => {
                        if (item.img) {
                            return renderWithImage(item, index)
                        } else {
                            return renderOnlyText(item, index)
                        }
                    })}
                </Slider>
            </div>
        </Section>
    )
}

const LeftArrow = (props) => {
    return (
        <div className="testimonial-slider__arrow left" onClick={props.onClick}>
            <i className="fa fa-chevron-left" aria-hidden="true"></i>
        </div>
    )
}
const RightArrow = (props) => {
    return (
        <div className="testimonial-slider__arrow right" onClick={props.onClick}>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
        </div>
    )
}

export default TestimonialsSlider