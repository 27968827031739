import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer'
import { anonymousPaths } from 'routes/paths';
import Service from 'api/agency/agent/onboarding/onboarding.service'
import history from 'history-accessor'
import { AgencyPlayerDefaultResponse, ExternalAgentShortModel, AgentRegistrationState } from 'api/agency/agent/onboarding/onboarding-models';
import { AgencyOnboarding, AgentInvitation, AgentOnboarding } from 'api/agency/agent/onboarding/onboarding-set-models';
import validator from 'services/validator';
import historyAccessor from 'history-accessor';
import { logInV2 } from 'store/auth/auth.actions';
import { insertAnonymousActivity } from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';
import PasswordValidation from 'utils/validate-password';
import {
    AUTH_LOGIN,
} from 'store/actionTypes';

class AgencyNameStep {
    agencyId: number;
    agentId: number;
    agencyName: string;
    userName: string;
    userEmail: string;
    userNameError: string;
    userEmailError: string;
    isAgencyCreating: boolean;
    isUsedInvitation: boolean;
    isShowModal: boolean;
    isAgencyInvitation: boolean;
    isAgentInvitation: boolean;
    userId: number;
    aspNetUserId: string;
}

class AgencyPropsStep {
    countries: Array<{ id: number, name: string }>;
    selectedCountryId: number;
    agents: Array<{ id: number, name: string }>;
    selectedAgentsId: number;
    players: Array<{ id: number, name: string }>;
    selectedPlayersId: number;
    city: string;
    url: string;
    urlError: string;
}

export class AgentsModel {
    id: number;
    fullName: string;
    email: string;
    masterUser: boolean;
}

export class AgentsErrorModel {
    id: number;
    nameError: string;
    emailError: string;
}

class AgencyAgentsStep {
    loadedAgents: Array<ExternalAgentShortModel>;
    customAgents: Array<AgentsModel>;
    errorAgents: Array<AgentsErrorModel>;
}

class AgencyLoginStep {
    title: string;
    password: string;
    rePassword: string;
    phoneAreaId: number;
    phone: string;
    termsCheckbox: boolean;
    titleError: string;
    passwordError?: boolean;
    rePasswordError: string;
    phoneAreaIdError: string;
    phoneError: string;
    passwordRules: Array<any>;
}

class AgencyPlayersStep {
    loadedPlayers: Array<AgencyPlayerDefaultResponse>;
    customPlayers: Array<AgencyPlayerDefaultResponse>;
}

export class PlayerAgent {
    playerId: number;
    agentId: number
}
export class AgencyPlayerAgentStep {
    agentList: Array<{ id: number, name: string }>
    playerAgentMap: Array<PlayerAgent>
}

class OnboardingState {
    currentStep: number;
    currentAgentStep: number;
    guid: string;
    isRequestProcessing: boolean;
    isLtaAwaitingPayment: boolean;
    agencyNameStepState: AgencyNameStep;
    agencyPropsStepState: AgencyPropsStep;
    agencyAgentStepState: AgencyAgentsStep;
    agencyLoginStepState: AgencyLoginStep;
    agencyPlayersStepState: AgencyPlayersStep;
    agencyPlayerAgentStepState: AgencyPlayerAgentStep;
}

const defaultState: OnboardingState = {
    currentStep: 1,
    currentAgentStep: null,
    guid: null,
    isRequestProcessing: false,
    isLtaAwaitingPayment: false,
    agencyNameStepState: {
        agencyId: null, agencyName: "", userEmail: "", userName: "", userEmailError: "",
        userNameError: "", isAgencyCreating: null, agentId: null, isUsedInvitation: false, isShowModal: false,
        isAgentInvitation: false,
        isAgencyInvitation: false,
        userId: null,
        aspNetUserId: ""
    },
    agencyPropsStepState: {
        countries: [],
        selectedCountryId: null,
        agents: [
            { id: 1, name: "1" }, { id: 2, name: "2" }, { id: 3, name: "3" }, { id: 4, name: "4" }, { id: 5, name: "5" }, { id: 6, name: "6" },
            { id: 7, name: "7" }, { id: 8, name: "8" }, { id: 9, name: "9" }, { id: 10, name: "10" }, { id: 11, name: "11" }, { id: 12, name: "12" },
            { id: 13, name: "13" }, { id: 14, name: "14" }, { id: 15, name: "15" }, { id: 16, name: "16" }, { id: 17, name: "17" }, { id: 18, name: "18" },
            { id: 19, name: "19" }, { id: 20, name: "20" },
        ],
        selectedAgentsId: null,
        players: [{ id: 1, name: "10-20" }, { id: 2, name: "21-30" }, { id: 3, name: "31-40" }, { id: 4, name: "41-50" }, { id: 5, name: "50+" }],
        selectedPlayersId: null,
        city: "",
        url: "",
        urlError: null
    },
    agencyAgentStepState: { customAgents: null, loadedAgents: null, errorAgents: null },
    agencyLoginStepState: {
        title: "", phoneAreaId: null, phone: "", password: "", rePassword: "", termsCheckbox: false, passwordError: null,
        rePasswordError: null, titleError: null, phoneAreaIdError: null, phoneError: null, passwordRules: null,
    },
    agencyPlayersStepState: { customPlayers: null, loadedPlayers: null },
    agencyPlayerAgentStepState: { agentList: [], playerAgentMap: [] }
}

const stateController = new StateController<OnboardingState>(
    "AGENCY/AGENCY/ONBOARDING",
    defaultState
);

class Actions {
    public static getAccess(guid: string) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isRequestProcessing: true }));
            const subState = getState().agency.agent.onboarding;
            let result = null;

            result = await Service.authenticateAgentByOnboardingGuid(guid);
            if (result.data) {
                dispatch(
                    {
                        type: AUTH_LOGIN.SUCCESS,
                        payload: { data: result.data }
                    }
                );
                historyAccessor.push("/");
            }
            else {
                var invitation = await Service.getOnoardingAccessByInvitationGuid(guid);
                if (!invitation.isValid) {
                    history.push(anonymousPaths.agencyExpired);
                    return;
                }
                else {
                    result = invitation;
                }
            }

            if (result.isAgencyInvitation) {
                dispatch(stateController.setState({
                    isRequestProcessing: false,
                    isLtaAwaitingPayment: result.isLtaAwaitingPayment,
                    guid: guid,
                    agencyNameStepState: {
                        ...subState.agencyNameStepState,
                        agencyName: result.agencyName,
                        userEmail: result.agentEmail,
                        agencyId: result.agencyId,
                        isAgencyCreating: result.isAgencyCreating,
                        agentId: result.externalAgentId,
                        userName: result.agentFullName,
                        isUsedInvitation: result.isUsedInvitation,
                        isAgencyInvitation: result.isAgencyInvitation,
                        userId: result.userId,
                        aspNetUserId: result.aspNetUserId
                    },
                    agencyLoginStepState: {
                        ...subState.agencyLoginStepState,
                        phoneAreaId: result.phoneAreaId,
                        phone: result.phone,
                        title: result.jobTitle
                    }
                }));

                if (result.isUsedInvitation) {
                    dispatch(stateController.setState({ currentStep: 7 }));
                }
            }

            if (result.isAgentInvitation) {
                dispatch(stateController.setState({
                    isRequestProcessing: false,
                    guid: guid,
                    currentAgentStep: 1,
                    agencyNameStepState: {
                        ...subState.agencyNameStepState,
                        agencyName: result.agencyName,
                        userEmail: result.agentEmail,
                        agencyId: result.agencyId,
                        isAgencyCreating: result.isAgencyCreating,
                        agentId: result.externalAgentId,
                        userName: result.agentFullName,
                        isUsedInvitation: result.isUsedInvitation,
                        isAgentInvitation: result.isAgentInvitation
                    },
                    agencyLoginStepState: {
                        ...subState.agencyLoginStepState,
                        phoneAreaId: result.phoneAreaId,
                        phone: result.phone,
                        title: result.jobTitle
                    }
                }))
            }
        }
    }

    public static getOnboardingPrefix(state: AppState) {
        return state.agency.agent.onboarding.agencyNameStepState.isAgencyInvitation ? 'Agency' : 'Agent';
    }

    public static isAgentRegistered() {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.agent.onboarding;
            const email = subState.agencyNameStepState.userEmail;
            const name = subState.agencyNameStepState.userName;
            let emailError = '';
            let nameError = '';

            if (!validator.isValidEmail(email))
                emailError = "Invalid email"
            if (!validator.isNotEmpty(name))
                nameError = "Enter the name"

            if (emailError !== '' || nameError !== '') {
                dispatch(stateController.setState({ agencyNameStepState: { ...subState.agencyNameStepState, userNameError: nameError, userEmailError: emailError } }))
            }
            else {
                dispatch(stateController.setState({ isRequestProcessing: true }));
                let result = await Service.isAgentInvalidForOnboarding(subState.guid, subState.agencyNameStepState.userEmail);


                dispatch(insertAnonymousActivity({
                    Message: `Entered Correct Email`,
                    PageName: `${Actions.getOnboardingPrefix(getState())} [Onboarding - Get started]`,
                    AgencyId: subState.agencyNameStepState.agencyId,
                    PageType: PageType.AgencyOnboarding
                }));

                if (result == AgentRegistrationState.RegistrationRejected) {
                    dispatch(stateController.setState({
                        isRequestProcessing: false,
                        agencyNameStepState: { ...subState.agencyNameStepState, isShowModal: true }
                    }));
                    return;
                }
                else if (result == AgentRegistrationState.AlreadyRegisteredInAgency) {
                    historyAccessor.push('/login')
                    return;
                }

                dispatch(stateController.setState({
                    isRequestProcessing: false,
                    agencyNameStepState: { ...subState.agencyNameStepState, userNameError: null, userEmailError: null }
                }));
                dispatch(this.nextStep());


            }
        }
    }

    public static agencyNameStepModalToggle() {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.agent.onboarding;
            dispatch(stateController.setState({
                agencyNameStepState: { ...subState.agencyNameStepState, isShowModal: !subState.agencyNameStepState.isShowModal }
            }));
        }
    }

    public static nextStep() {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.agent.onboarding;
            if (subState.currentAgentStep != null)
                dispatch(stateController.setState({ currentAgentStep: subState.currentAgentStep + 1 }));
            else
                dispatch(stateController.setState({ currentStep: subState.currentStep + 1 }));
        }
    }

    public static playersNextStep() {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.agent.onboarding;
            if (subState.currentAgentStep !== null) {
                dispatch(Actions.setAgentOnboarding());
            } else {
                //let playersStep = substate.agencyPlayersStepState;
                // if ((playersStep.customPlayers || {}).length === 0) {
                //     await dispatch(this.setAgencyOnboarding());
                //     dispatch(stateController.setState({ currentStep: substate.currentStep + 2 }));
                //     return;
                // }
                dispatch(Actions.setAgencyOnboarding());
                dispatch(insertAnonymousActivity({
                    Message: `Completed List`,
                    PageName: `${Actions.getOnboardingPrefix(getState())} [Onboarding - Confirm players]`,
                    AgencyId: subState.agencyNameStepState.agencyId,
                    PageType: PageType.AgencyOnboarding
                }));

                //dispatch(stateController.setState({ currentStep: substate.currentStep + 2 }));
            }
        }
    }

    public static prevStep() {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.agent.onboarding;
            if (subState.currentAgentStep !== null)
                dispatch(stateController.setState({ currentAgentStep: subState.currentAgentStep - 1 }));
            else
                dispatch(stateController.setState({ currentStep: subState.currentStep - 1 }));
        }
    }

    public static setUserEmail(email: string) {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.agent.onboarding;
            email = email && typeof email === "string" ? email.trim() : email
            dispatch(stateController.setState({ agencyNameStepState: { ...subState.agencyNameStepState, userEmail: email } }));
        }
    }

    public static setUserName(name: string) {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.agent.onboarding;
            dispatch(stateController.setState({ agencyNameStepState: { ...subState.agencyNameStepState, userName: name } }));
        }
    }

    public static setAgencyOnboarding() {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.agent.onboarding;
            const nameStep = subState.agencyNameStepState;
            const loginStep = subState.agencyLoginStepState;
            const propsStep = subState.agencyPropsStepState;
            const playersStep = subState.agencyPlayersStepState;
            const agentsStep = subState.agencyAgentStepState;
            const customAgent = agentsStep.customAgents || [];

            const agents: Array<AgentInvitation> = [];
            const playerIdAgentEmail: { [key: number]: string } = {};
            const playerIdsRejected: Array<number> = playersStep.loadedPlayers.filter(x => !playersStep.customPlayers.some(i => i.id == x.id)).map(k => k.id);
            customAgent.map(x => agents.push(
                {
                    email: x.email,
                    agentId: x.id > 0 ? x.id : null,
                    isMaster: x.masterUser,
                    name: x.fullName,
                    isCurrentRegistrationUser: false
                }
            ));
            agents.push({ email: nameStep.userEmail, isCurrentRegistrationUser: true, name: nameStep.userName, isMaster: true, agentId: nameStep.agentId });

            playersStep.customPlayers.map(x => {
                playerIdAgentEmail[x.id] = nameStep.userEmail;
            });

            dispatch(stateController.setState({ isRequestProcessing: true }));
            const request: AgencyOnboarding = {
                userEmail: nameStep.userEmail,
                userName: nameStep.userName,
                invitationGuid: subState.guid,
                user: { title: loginStep.title, areaId: loginStep.phoneAreaId, password: loginStep.password, confirmPassword: loginStep.rePassword, phone: loginStep.phone },
                agencyInfo: {
                    amountOfAgents: propsStep.selectedAgentsId, amountOfPlayers: propsStep.selectedPlayersId,
                    homeCity: propsStep.city, homeCountryAreaId: propsStep.selectedCountryId, url: propsStep.url
                },
                agents: agents,
                playerIdAgentEmail: playerIdAgentEmail,
                playerIdsRejected: playerIdsRejected,
                registrationSource: "Agent Onboarding",
                userRegistrationSource: null
            };

            await Service.setAgencyOnboarding(request);

            dispatch(stateController.setState({ isRequestProcessing: false, currentStep: subState.currentStep + 1 }));
        }
    }

    public static setAgentOnboarding() {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.agent.onboarding;
            const nameStep = subState.agencyNameStepState;
            const loginStep = subState.agencyLoginStepState;

            dispatch(stateController.setState({ isRequestProcessing: true }));
            const request: AgentOnboarding = {
                userEmail: nameStep.userEmail,
                userName: nameStep.userName,
                invitationGuid: subState.guid,
                user: { title: loginStep.title, areaId: loginStep.phoneAreaId, password: loginStep.password, confirmPassword: loginStep.rePassword, phone: loginStep.phone },
                playerIds: [],//playersStep.customPlayers.map(i => i.id),
                playerIdsRejected: [],//playerIdsRejected,
                registrationSource: "Agent Onboarding",
                userRegistrationSource: null
            };
            // if (!substate.agencyNameStepState.va) {
            await Service.setAgentOnboarding(request);
            /*       await Service.setAgentOnboarding(request);
               } else {
                   historyAccessor.push('/')
               }
               */

            dispatch(stateController.setState({ isRequestProcessing: false, currentAgentStep: subState.currentAgentStep + 1 }));
        }
    }

    public static agentOnboardingDone() {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.agent.onboarding;
            let nameStep = subState.agencyNameStepState;
            let loginStep = subState.agencyLoginStepState;

            dispatch(stateController.setState({ isRequestProcessing: true }));
            await dispatch(logInV2({ userLogin: nameStep.userEmail, password: loginStep.password }));

            dispatch(
                insertAnonymousActivity(
                    {
                        Message: `Continued to Home Page`,
                        PageName: `${Actions.getOnboardingPrefix(getState())} [Onboarding - Confirm players]`,
                        AgencyId: subState.agencyNameStepState.agencyId,
                        PageType: PageType.AgencyOnboarding
                    }
                ),
            );

            historyAccessor.push('/');
        }
    }
    public static openDoneStep() {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.agent.onboarding;
            dispatch(insertAnonymousActivity({
                Message: `Completed Onboarding`,
                PageName: `${Actions.getOnboardingPrefix(getState())} [Onboarding - Confirm players]`,
                AgencyId: subState.agencyNameStepState.agencyId,
                PageType: PageType.AgencyOnboarding
            }));
        }
    }

    public static activateAccount() {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.agent.onboarding;
            dispatch(insertAnonymousActivity({
                Message: `Activate account`,
                PageName: `${Actions.getOnboardingPrefix(getState())} [Onboarding - Welcome]`,
                AgencyId: subState.agencyNameStepState.agencyId,
                PageType: PageType.AgencyOnboarding
            }));
        }
    }
}

class PropsStepActions {
    public static loadPropsData() {
        return async (dispatch, getState: () => AppState) => {

            const subState = getState().agency.agent.onboarding;
            dispatch(stateController.setState({ isRequestProcessing: true }));

            const [countries, onboardingData, resultAgents, resultPlayers] = await Promise.all([
                Service.getCountries(subState.guid),
                Service.getOnoardingAccessByInvitationGuid(subState.guid),
                Service.getAgents(subState.guid),
                Service.getPlayers(subState.guid),
            ])

            const mapped = countries.map(x => { return { id: x.id, name: x.name } })

            let agencyClone = [...subState.agencyPropsStepState.agents];

            if (agencyClone.length < resultAgents.length) {
                agencyClone = this.getAgentsByServerCount(resultAgents, agencyClone)
            }


            const selectedPlayersId = resultPlayers.length > 50 ? 5 : resultPlayers.length > 40 ? 4 : resultPlayers.length > 30 ? 3 : resultPlayers.length > 20 ? 2 : 1;

            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                isRequestProcessing: false,
                agencyPropsStepState: {
                    ...prevState.agencyPropsStepState,
                    countries: mapped,
                    url: onboardingData.webSiteUrl,
                    selectedCountryId: onboardingData.agencyCountryId,
                    city: onboardingData.agencyCity,
                    selectedAgentsId: resultAgents.length > 0 ? resultAgents.length : null,
                    selectedPlayersId: selectedPlayersId,
                    agents: agencyClone
                }
            })));
        }
    }

    public static getAgentsByServerCount(resultAgents: Array<{}>, agencyClone: Array<{ name: string, id: number }>) {
        resultAgents.map((e, i) => {
            const item = i + 1;
            if (!agencyClone.find(x => x.id == item)) {
                agencyClone.push({ id: item, name: item.toString() });
            }
        })
        return agencyClone;
    }

    public static selectCountry(id: number) {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.agent.onboarding;
            dispatch(stateController.setState({ agencyPropsStepState: { ...subState.agencyPropsStepState, selectedCountryId: id } }));
        }
    }
    public static selectAgents(id: number) {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.agent.onboarding;
            dispatch(stateController.setState({ agencyPropsStepState: { ...subState.agencyPropsStepState, selectedAgentsId: id } }));
        }
    }
    public static selectPlayers(id: number) {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.agent.onboarding;
            dispatch(stateController.setState({ agencyPropsStepState: { ...subState.agencyPropsStepState, selectedPlayersId: id } }));
        }
    }

    public static setHomeCity(val: string) {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.agent.onboarding;
            dispatch(stateController.setState({ agencyPropsStepState: { ...subState.agencyPropsStepState, city: val } }));
        }
    }

    public static setUrl(val: string) {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.agent.onboarding;
            dispatch(stateController.setState({ agencyPropsStepState: { ...subState.agencyPropsStepState, url: val } }));
        }
    }

    public static next() {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.agent.onboarding;
            if (subState.agencyPropsStepState.url !== "" && subState.agencyPropsStepState.url !== null && !validator.isValidURL(subState.agencyPropsStepState.url)) {
                dispatch(stateController.setState({ agencyPropsStepState: { ...subState.agencyPropsStepState, urlError: "Invalid url" } }));
            }
            else {
                dispatch(stateController.setState({ agencyPropsStepState: { ...subState.agencyPropsStepState, urlError: null } }));
                dispatch(Actions.nextStep())
            }
        }
    }
}

class AgentsStepActions {
    public static getAgents() {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.agent.onboarding;
            dispatch(stateController.setState({ isRequestProcessing: true }));
            const result: Array<ExternalAgentShortModel> = await Service.getAgents(subState.guid);
            let custom: Array<AgentsModel> = result.map(x => {
                return { email: x.email, fullName: x.fullName, id: x.id, masterUser: false }
            });

            const customCount = subState.agencyPropsStepState.selectedAgentsId - custom.length - 1;
            for (let i = 0; i < customCount; i++) {
                custom.push({ id: (new Date().getTime() * -1) + i, email: "", fullName: "", masterUser: false });
            }

            custom = custom.filter(x => x.id != subState.agencyNameStepState.agentId);
            const list = custom.map(x => { return { id: x.id, name: x.fullName } });
            dispatch(stateController.setState({
                isRequestProcessing: false,
                agencyAgentStepState: { ...subState.agencyAgentStepState, loadedAgents: result, customAgents: custom, errorAgents: [] },
                agencyPlayerAgentStepState: { ...subState.agencyPlayerAgentStepState, agentList: list }
            }));
        }
    }

    public static setName(id: number, name: string) {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.agent.onboarding;
            const agentsCopy = [...subState.agencyAgentStepState.customAgents];
            const item = agentsCopy.find(x => x.id == id);
            item.fullName = name;

            const list = agentsCopy.map(x => { return { id: x.id, name: x.fullName } });
            dispatch(stateController.setState({
                agencyAgentStepState: { ...subState.agencyAgentStepState, customAgents: agentsCopy },
                agencyPlayerAgentStepState: { ...subState.agencyPlayerAgentStepState, agentList: list }
            }));
        }
    }

    public static setEmail(id: number, email: string) {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.agent.onboarding;
            const agentsCopy = [...subState.agencyAgentStepState.customAgents];
            const item = agentsCopy.find(x => x.id == id);
            item.email = email;

            dispatch(stateController.setState({ agencyAgentStepState: { ...subState.agencyAgentStepState, customAgents: agentsCopy } }));
        }
    }

    public static setMaster(id: number, isMaster: boolean) {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.agent.onboarding;
            let agentsCopy = [...subState.agencyAgentStepState.customAgents];
            let item = agentsCopy.find(x => x.id == id);
            item.masterUser = isMaster;

            dispatch(stateController.setState({ agencyAgentStepState: { ...subState.agencyAgentStepState, customAgents: agentsCopy } }));
        }
    }

    public static remove(id: number) {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.agent.onboarding;
            const removedAgent = subState.agencyAgentStepState.customAgents.find(x => x.id == id);
            const agentsCopy = subState.agencyAgentStepState.customAgents.filter(x => x.id != id);

            let list = agentsCopy.map(x => { return { id: x.id, name: x.fullName } });
            dispatch(stateController.setState({
                agencyAgentStepState: { ...subState.agencyAgentStepState, customAgents: agentsCopy },
                agencyPlayerAgentStepState: { ...subState.agencyPlayerAgentStepState, agentList: list }
            }));

            dispatch(insertAnonymousActivity({
                Message: `Removed Agent: ${removedAgent.fullName}, ${removedAgent.email}`,
                PageName: `${Actions.getOnboardingPrefix(getState())} [Onboarding - Confirm agents]`,
                AgencyId: subState.agencyNameStepState.agencyId,
                PageType: PageType.AgencyOnboarding
            }));
        }
    }

    public static add() {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.agent.onboarding;
            const agentsCopy: Array<AgentsModel> = [...subState.agencyAgentStepState.customAgents, { id: new Date().getTime() * -1, email: "", fullName: "", masterUser: false }]
            dispatch(stateController.setState({ agencyAgentStepState: { ...subState.agencyAgentStepState, customAgents: agentsCopy } }));
        }
    }

    public static next() {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.agent.onboarding;
            const errors: Array<AgentsErrorModel> = [];

            subState.agencyAgentStepState.customAgents.map(x => {
                const nameError = !validator.isNotEmpty(x.fullName);
                const emailError = !validator.isValidEmail(x.email);
                if (nameError || emailError) {
                    errors.push({ id: x.id, emailError: emailError ? "Invalid email" : null, nameError: nameError ? "Empty name" : null })
                }
            });

            const masters = new Array<{ name: string, email: string }>();
            const agents = new Array<{ name: string, email: string }>();

            for (const item of subState.agencyAgentStepState.customAgents) {
                if (item.masterUser) {
                    masters.push({ name: item.fullName, email: item.email });
                }
                else {
                    agents.push({ name: item.fullName, email: item.email });
                }
            }

            for (let master of masters) {
                dispatch(insertAnonymousActivity({
                    PageName: `${Actions.getOnboardingPrefix(getState())} [Onboarding - Confirm agents]`,
                    Message: `Added master agent: ${master.name}, ${master.email}`,
                    AgencyId: subState.agencyNameStepState.agencyId,
                    PageType: PageType.AgencyOnboarding
                }));
            }

            for (let agent of agents) {
                dispatch(insertAnonymousActivity({
                    PageName: `${Actions.getOnboardingPrefix(getState())} [Onboarding - Confirm agents]`,
                    Message: `Added Agent: ${agent.name}, ${agent.email}`,
                    AgencyId: subState.agencyNameStepState.agencyId,
                    PageType: PageType.AgencyOnboarding
                }));
            }

            if (errors.length != 0) {
                dispatch(stateController.setState({ agencyAgentStepState: { ...subState.agencyAgentStepState, errorAgents: errors } }));
            }
            else {
                dispatch(stateController.setState({ agencyAgentStepState: { ...subState.agencyAgentStepState, errorAgents: errors } }));
                dispatch(Actions.nextStep());
            }
        }
    }
}

class LoginStepActions {

    public static clearErrors() {
        return (dispatch, getState: () => AppState) => {
            const subState = getState().agency.agent.onboarding;
            dispatch(stateController.setState({
                agencyLoginStepState: {
                    ...subState.agencyLoginStepState,
                    phoneError: null,
                    titleError: null,
                    phoneAreaIdError: null
                }
            }
            ));
        }
    }

    public static setTitle(title: string) {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.agent.onboarding;
            dispatch(stateController.setState({ agencyLoginStepState: { ...subState.agencyLoginStepState, title: title } }));
            dispatch(LoginStepActions.clearErrors())
        }
    }
    public static setPassword(password: string) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(LoginStepActions.clearErrors())

            const subState = getState().agency.agent.onboarding;

            const rePassword = subState.agencyLoginStepState.rePassword;

            const validation = PasswordValidation.validate(password)
            const passwordError = !validation.isSuccess
            const rePasswordError = PasswordValidation.validateConfirmPassword(password, rePassword);
            dispatch(stateController.setState({
                agencyLoginStepState: {
                    ...subState.agencyLoginStepState,
                    password: password,
                    passwordError,
                    rePasswordError,
                    passwordRules: validation.rules,
                }
            }));
        }
    }
    public static setPasswordRe(passwordRe: string) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(LoginStepActions.clearErrors())

            const subState = getState().agency.agent.onboarding;

            const password = subState.agencyLoginStepState.password;
            let rePasswordError = PasswordValidation.validateConfirmPassword(password, passwordRe);

            dispatch(stateController.setState({
                agencyLoginStepState: {
                    ...subState.agencyLoginStepState,
                    rePassword: passwordRe,
                    rePasswordError,
                }
            }));
        }
    }

    public static setAreaId(areaId: number) {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.agent.onboarding;
            dispatch(stateController.setState({ agencyLoginStepState: { ...subState.agencyLoginStepState, phoneAreaId: areaId } }));
            dispatch(LoginStepActions.clearErrors())

        }
    }
    public static setPhone(phone: string) {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.agent.onboarding;
            dispatch(stateController.setState({ agencyLoginStepState: { ...subState.agencyLoginStepState, phone: phone } }));
            dispatch(LoginStepActions.clearErrors())
        }
    }



    public static setTerms(agreed: boolean) {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.agent.onboarding;
            dispatch(stateController.setState({ agencyLoginStepState: { ...subState.agencyLoginStepState, termsCheckbox: agreed } }));
        }
    }

    public static next() {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.agent.onboarding;

            const password = subState.agencyLoginStepState.password;
            const rePassword = subState.agencyLoginStepState.rePassword;

            const titleError = !validator.isNotEmpty(subState.agencyLoginStepState.title);
            const areaError = subState.agencyLoginStepState.phoneAreaId == null;
            const phoneError = !validator.isNotEmpty(subState.agencyLoginStepState.phone);

            const validation = PasswordValidation.validate(password)
            const passwordError = !validation.isSuccess
            const rePasswordError = PasswordValidation.validateConfirmPassword(password, rePassword);

            if (titleError || passwordError || rePasswordError || areaError || phoneError) {
                dispatch(stateController.setState({
                    agencyLoginStepState: {
                        ...subState.agencyLoginStepState,
                        passwordError: passwordError,
                        passwordRules: validation.rules,
                        phoneAreaIdError: areaError ? " " : null,
                        phoneError: phoneError ? " " : null,
                        rePasswordError: rePasswordError,
                        titleError: titleError ? " " : null,
                    }
                }));


            }
            else {
                dispatch(stateController.setState({
                    agencyLoginStepState: {
                        ...subState.agencyLoginStepState,
                        passwordError: null, phoneAreaIdError: null, phoneError: null, rePasswordError: null, titleError: null,
                    }
                }));
                if (subState.currentAgentStep !== null) {
                    dispatch(Actions.setAgentOnboarding())
                }
                else {
                    dispatch(Actions.nextStep());
                }
            }

            if (!titleError && !passwordError && !rePasswordError && !areaError && !phoneError) {
                dispatch(insertAnonymousActivity({
                    PageName: `${Actions.getOnboardingPrefix(getState())} [Onboarding - Your account]`,
                    Message: 'Entered account info and password',
                    AgencyId: subState.agencyNameStepState.agencyId,
                    PageType: PageType.AgencyOnboarding
                }));
            }

        }
    }
}

class PlayersStepActions {

    public static next() {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.agent.onboarding;
            if (subState.currentAgentStep != null) {
                dispatch(Actions.setAgentOnboarding())
            }
            else {
                dispatch(Actions.nextStep())
            }
        }
    }

    public static getPlayers() {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.agent.onboarding;
            dispatch(stateController.setState({ isRequestProcessing: true }));

            let result: Array<AgencyPlayerDefaultResponse> = null;

            if (subState.agencyNameStepState.isAgencyCreating)
                result = await Service.getPlayers(subState.guid);
            else
                result = await Service.getPlayersByAgentInvitationGuid(subState.guid);

            dispatch(stateController.setState({
                isRequestProcessing: false,
                agencyPlayersStepState: { ...subState.agencyPlayersStepState, loadedPlayers: result, customPlayers: result }
            }));
        }
    }

    public static addPlayer(player: any) {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.agent.onboarding;
            let players = [...subState.agencyPlayersStepState.customPlayers, {
                id: player.id,
                shortName: player.shortName,
                parentSquadName: player.parentSquadName,
                age: player.age,
                positionCode: player.positionCode
            }]
            dispatch(stateController.setState({
                agencyPlayersStepState: { ...subState.agencyPlayersStepState, customPlayers: players }
            }));

            dispatch(insertAnonymousActivity({
                PageName: `${Actions.getOnboardingPrefix(getState())} [Onboarding - Confirm players]`,
                Message: `Added Player`,
                AgencyId: subState.agencyNameStepState.agencyId,
                PlayerId: player.id,
            }));
        }
    }

    public static getPlayersByKeyword = (keyword) => {
        return async (dispatch, getState: () => AppState) => {
            return  await Service.getPlayersByKeyword(keyword, getState().agency.agent.onboarding.guid);
        }
    }

    public static remove(id: number) {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.agent.onboarding;
            const playersCopy = subState.agencyPlayersStepState.customPlayers.filter(x => x.id != id);
            dispatch(stateController.setState({ agencyPlayersStepState: { ...subState.agencyPlayersStepState, customPlayers: playersCopy } }));
            dispatch(PlayerAgentStepActions.removeExactPlayerAgentsConnection(id))
            dispatch(insertAnonymousActivity({
                PageName: `${Actions.getOnboardingPrefix(getState())} [Onboarding - Confirm players]`,
                Message: `Removed Player`,
                AgencyId: subState.agencyNameStepState.agencyId,
                PlayerId: id,
                PageType: PageType.AgencyOnboarding
            }));
        }
    }
}

class PlayerAgentStepActions {
    public static setAgentToPlayer(playerId: number, agentId: number) {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.agent.onboarding;
            const mapCopy = [...subState.agencyPlayerAgentStepState.playerAgentMap];
            const find = mapCopy.find(x => x.playerId == playerId);

            if (!find) {
                mapCopy.push({ playerId: playerId, agentId: agentId });
            } else {
                mapCopy.find(x => x.playerId == playerId).agentId = agentId
            }

            dispatch(stateController.setState(
                { agencyPlayerAgentStepState: { ...subState.agencyPlayerAgentStepState, playerAgentMap: mapCopy } }
            ));
        }
    }

    public static removeExactPlayerAgentsConnection(playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.agent.onboarding;
            let mapCopy = [...subState.agencyPlayerAgentStepState.playerAgentMap];
            mapCopy = mapCopy.filter(item => item.playerId !== playerId)

            dispatch(stateController.setState(
                { agencyPlayerAgentStepState: { ...subState.agencyPlayerAgentStepState, playerAgentMap: mapCopy } }
            ));
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    OnboardingState as State,
    Actions as Actions,
    PropsStepActions as PropsStepActions,
    AgentsStepActions as AgentsStepActions,
    LoginStepActions as LoginStepActions,
    PlayersStepActions as PlayersStepActions,
    PlayerAgentStepActions as PlayerAgentStepActions,
    stateController as Controller
};



