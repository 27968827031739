import axios from 'axios-config';
import { NewMessage, MessageItem, ChannelMessages } from './models/message'
import { ChannelItem } from './models/get-channel-response'
import { PushNotificationRequest } from './models/push-nofication-request'
import { SearchEndpointResponseModel } from './models/search-suggestions'
import { AdminSearchEndpoint } from './models/admin-suggestion-endpoint'
import { GetChatResponse } from './models/admin-all-channels-search-request'
import { GetMessagesResponse } from './models/admin-messages-request';
import { CancelToken } from 'axios';

export class MessagingService {
    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };
    public static async getSquadChannels(squadId: number, cancelToken: CancelToken): Promise<{items: Array<ChannelItem>}> {
        const { data } = await axios.get(
            `api/v2/Messaging/Squad/${squadId}/Chat`,
            {
                ...this.axiosConfig,
                cancelToken: cancelToken
            }
        )as any;
        return data
    }
    public static async getAgencyChannels(agencyId: number, cancelToken: CancelToken): Promise<{items: Array<ChannelItem>}> {
        const { data } = await axios.get(
            `api/v2/Messaging/Agency/${agencyId}/Chat`,
            {
                ...this.axiosConfig,
                cancelToken: cancelToken
            }
        )
        return data
    }
    public static async getPlayerChannels(playerId: number, cancelToken: CancelToken): Promise<{items: Array<ChannelItem>}> {
        const { data } = await axios.get(
            `api/v2/Messaging/Player/${playerId}/Chat`,
            {
                ...this.axiosConfig,
                cancelToken: cancelToken
            }
        )
        return data
    }
    public static async getReceiverSuggestions(keyword: string): Promise<SearchEndpointResponseModel> {
        const { data } = await axios.get(
            `api/v2/Messaging/Endpoint?keyword=${keyword}`,
            this.axiosConfig
        )
        return data
    }
    public static async getMessages(channelId: string): Promise<ChannelMessages> {
        const { data } = await axios.get(
            `api/v2/Messaging/Chat/${channelId}/Message`,
            this.axiosConfig
        )
        return data
    }

    public static async sendMessageToSquad(squadId: number, chatId: string, message: NewMessage): Promise<any> {
      
        const { data } = await axios.post(
            `api/v2/Messaging/Squad/${squadId}/Chat/${chatId}/Message`,
            { ...message },
            this.axiosConfig
        )
        return data
    }
    public static async sendMessageToAgency(agencyId: number, chatId: string, message: NewMessage): Promise<any> {
        const { data } = await axios.post(
            `api/v2/Messaging/Agency/${agencyId}/Chat/${chatId}/Message`,
            { ...message },
            this.axiosConfig
        )as any;
        return data
    }
    public static async sendMessageToPlayer(playerId: number, chatId: string, message: NewMessage): Promise<any> {
        const { data } = await axios.post(
            `api/v2/Messaging/Player/${playerId}/Chat/${chatId}/Message`,
            { ...message },
            this.axiosConfig
        )as any;
        return data
    }

    public static async createChatWithSquad(squadId: number, message: NewMessage): Promise<any> {
        const { data } = await axios.post(
            `api/V2/Messaging/Squad/${squadId}/Chat`,
            { ...message },
            this.axiosConfig
        )
        return data
    }
    public static async createChatWithAgency(agencyId: number, message: NewMessage): Promise<any> {
        const { data } = await axios.post(
            `api/V2/Messaging/Agency/${agencyId}/Chat`,
            { ...message },
            this.axiosConfig
        )
        return data
    }
    public static async createChatWithPlayer(playerId: number, message: NewMessage): Promise<any> {
        const { data } = await axios.post(
            `api/V2/Messaging/Player/${playerId}/Chat`,
            { ...message },
            this.axiosConfig
        )
        return data
    }

    public static async markChatAsRead(channelId: string): Promise<any> {
        const { data } = await axios.post(
            `api/v2/Messaging/Chat/${channelId}/Read`,
            {},
            this.axiosConfig
        )
        return data
    }

    public static async markChatMessageAsReadById(channelId: string, messageId: number, cancelToken: CancelToken): Promise<any> {
        const { data } = await axios.post(
            `api/v2/Messaging/Chat/${channelId}/${messageId}/Read`,
            {},
          {
              ...this.axiosConfig,
              cancelToken: cancelToken
          }
        )
        return data
    }

    public static async getUnreadMessagesCount(cancelToken: CancelToken): Promise<number> {
        const { data } = await axios.get(
            `api/v2/Messaging/UnreadMessagesCount`,
          {
              ...this.axiosConfig,
              cancelToken: cancelToken
          }
        )
        return data
    }

    public static async checkNewMessageLimit(userId: number) {
        const { data } = await axios.get(
            `api/Message/GetAvailableNewMessageChannelCount/${userId}`,
            this.axiosConfig
        )
        return data
    }

    public static async deleteChat(chatId): Promise<any> {
        const { data } = await axios.delete(
            `api/v2/Messaging/Chat/${chatId}`,
            this.axiosConfig
        )
    }

    public static async getAdminChannelsAutosuggest(encodedKeyword: string): Promise<Array<AdminSearchEndpoint>> {
        const { data } = await axios.get(
            `api/v2/Messaging/Admin/Endpoint?keyword=${encodedKeyword}`,
            this.axiosConfig
        )
        return data
    }
    public static async getAdminAllChannels(fileterParams): Promise<GetChatResponse> {
        const { data } = await axios.post(
            `api/v2/Messaging/Admin/Chat`,
            { ...fileterParams },
            this.axiosConfig
        )
        return data
    }
    public static async getAdminMessages(channelId: string): Promise<GetMessagesResponse> {
        const { data } = await axios.get(
            `api/v2/Messaging/Admin/Chat/${channelId}/Message`,
            this.axiosConfig
        )
        return data
    }

    public static async sendPushNotificationToUser({squadId, channelId, receiverSquadId, messageText}: PushNotificationRequest) {
        const { data } = await axios.post(
            `api/Message/sendPushNotificationToUser/${receiverSquadId}/${squadId}/${channelId}`,
            JSON.stringify(messageText),
            this.axiosConfig
        )
        return data
    }
    public static async sendPushNotificationToUserFromAgency({agencyId, channelId, receiverSquadId, messageText}: PushNotificationRequest) {
        const { data } = await axios.post(
            `api/Message/sendPushNotificationToUserFromAgency/${receiverSquadId}/${agencyId}/${channelId}`,
            JSON.stringify(messageText),
            this.axiosConfig
        )
        return data
    }
    public static async sendPushNotificationToSquad({squadId, channelId, receiverSquadId, messageText}: PushNotificationRequest) {
        const { data } = await axios.post(
            `api/Message/SendPushNotificationToSquad/${receiverSquadId}/${squadId}/${channelId}`,
            JSON.stringify(messageText),
            this.axiosConfig
        )
        return data
    }
    public static async sendPushNotificationToSquadFromAgency({agencyId, channelId, receiverSquadId, messageText}: PushNotificationRequest) {
        const { data } = await axios.post(
            `api/Message/SendPushNotificationToSquadFromAgency/${receiverSquadId}/${agencyId}/${channelId}`,
            JSON.stringify(messageText),
            this.axiosConfig
        )
        return data
    }
    public static async sendPushNotificationToSquadWithoutFriendlySupervisors({squadId, channelId, receiverSquadId, messageText}: PushNotificationRequest) {
        const { data } = await axios.post(
            `api/Message/SendPushNotificationToSquadWithoutFriendlySupervisors/${receiverSquadId}/${squadId}/${channelId}`,
            JSON.stringify(messageText),
            this.axiosConfig
        )
        return data
    }
    public static async sendPushNotificationToSquadWithoutFriendlySupervisorsFromAgency({agencyId, channelId, receiverSquadId, messageText}: PushNotificationRequest) {
        const { data } = await axios.post(
            `api/Message/SendPushNotificationToSquadWithoutFriendlySupervisorsFromAgency/${receiverSquadId}/${agencyId}/${channelId}`,
            JSON.stringify(messageText),
            this.axiosConfig
        )
        return data
    }
    public static async sendPushNotificationToAgency({squadId, channelId, receiverAgencyId, messageText}: PushNotificationRequest) {
        const { data } = await axios.post(
            `api/Message/SendPushNotificationToAgency/${receiverAgencyId}/${squadId}/${channelId}`,
            JSON.stringify(messageText),
            this.axiosConfig
        )
        return data
    }
    public static async SendPushNotificationToPlayerFromAgency({agencyId, channelId = '', receiverPlayerId, messageText}: PushNotificationRequest) {
        const { data } = await axios.post(
            `api/Message/SendPushNotificationToPlayerFromAgency/${receiverPlayerId}/${agencyId}/${channelId}`,
            JSON.stringify(messageText),
            this.axiosConfig
        )
        return data
    }
    public static async SendPushNotificationToAgencyFromPlayer({playerId, channelId = '', receiverAgencyId, messageText}: PushNotificationRequest) {
        const { data } = await axios.post(
            `api/Message/SendPushNotificationToAgencyFromPlayer/${receiverAgencyId}/${playerId}/${channelId}`,
            JSON.stringify(messageText),
            this.axiosConfig
        )
        return data
    }

    public static async rejectPlayerRepresentationRequest(messageId: number) {
        const { data } = await axios.post(
            `api/v2/Messaging/Agency/RejectPlayerRepresentationRequest/${messageId}`,
            this.axiosConfig
        )
        return data
    }
    public static async declareInterestPlayerRepresentationRequest(messageId: number) {
        const { data } = await axios.post(
            `api/v2/Messaging/Agency/DeclareInterestPlayerRepresentationRequest/${messageId}`,
            this.axiosConfig
        )
        return data
    }

    public static async checkNewMessageLimitServer(userId: number, cookie: string) {
        const { data } = await axios.get(
            `api/Message/GetAvailableNewMessageChannelCount/${userId}`,
            { headers: { 'Content-Type': 'application/json', Cookie: cookie }, }
        )
        return data
    }
}