import { IdName } from 'api/core/id-name';
import Range from 'api/core/range'
import { AchievementItem } from "./search-request";
import { CustomLeagueList } from "api/search/model/search-filter-criteria";

export interface SearchFilterCriteria {
    availableLeagues: AvailableLeagueCriteria[];
    customLeagueList: CustomLeagueList[];
    coachCriteria: CoachCriteria;
    country: AreaCriteria;
    league: IdName;
    latestFilters: any;
    playerCriteria: PlayerCriteria;
}

export interface AgencySearchFilterCriteria {
    availableLeagues: AvailableLeagueCriteria[];
    customLeagueList: CustomLeagueList[];
    coachCriteria: CoachCriteria;
    country: AreaCriteria;
    league: IdName;
    latestFilters: any;
    playerCriteria: PlayerCriteria;
    maxTransferFee: number;
}

export type SearchFilterDynamicCriteria = {
    coachSearchFilterDynamicCriteria: CoachSearchFilterDynamicCriteria;
}

export interface PlayerCriteria {
    playerMarketPermissionRequested: boolean;
    age: Range<number>;
    rating: CriteriaRating;
    availableNationalities: AvailableNationalityCriteria[];
    height: Range<number>;
    positionRoles: PositionRoleCriteria[];
}

export interface CoachCriteria {
    rating: CriteriaRating;
    availableNationalities: AvailableLeagueCriteria[];
}

export interface CoachSearchFilterDynamicCriteria {
    availableAchievements: AchievementItem[];
}

export interface CriteriaRating {
    ambitious: Range<number>;
    default: Range<number>;
    matchingMe: Range<number>;
}

export interface AvailableLeagueCriteria {
    name: string;
    id: number;
    shortName: string;
}

export interface AreaCriteria {
    id: number;
    code: number | null;
    competitions: IdName[];
    flagPath: string;
    name: string;
}

export interface AvailableNationalityCriteria {
    name: string;
    areas: AreaCriteria[];
}
export interface PositionRoleCriteria {
    id: number;
    name: string;
    roles: PositionRoleItem[];
}
export interface PositionRoleItem {
    id: number;
    name: string;
    shortName: string | null;
}

export enum PlayingStyleRoleEnum {
    Agressive = 1,
    BalancedPlayer = 2,
    BallPlaying = 3,
    Shadow = 4,
    Defensive = 5,
    WingBack = 6,
    BallWinning = 7,
    BoxToBox = 8,
    Creative = 9,
    Playmaker = 10,
    SecondStriker = 11,
    InvertedWinger = 12,
    WideWinger = 13,
    False9s = 14,
    Finisher = 15,
    TargetMan = 16,
}