import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions'
import * as CommocnController from 'pages/player-ads/redux/common.controller'
import PlusPitchServicev2 from 'api/pitch-v2/plus-pitch-service.v2'
import { PlusPitchModel } from 'api/pitch-v2/models/plus-pitch.model';
import * as MessageSend from './message.send.controller'
import { messageSend } from 'app/send-message/actions/send-message.actions'
import { Actions as PlayerStatisticActions } from './player-statistic.controller';
import { translate } from 'services/localization';
import { ActionType, PageType,UserActivityType } from 'constants/enums';
import { openCoachProfileById, openAgencyProfile } from "utils/open-link";

class PlusPitchesV2State {
    activePlusPitches: Array<PlusPitchModel>;
    testedPlusPitches: Array<PlusPitchModel>;
    isLoading: boolean;
    initialized: boolean;
    processingPitchIds: Array<number>;
    decalreInterestProcessingIds: Array<number>;
}

const defaultState: PlusPitchesV2State = {
    activePlusPitches: [],
    testedPlusPitches: [],
    isLoading: false,
    initialized: false,
    processingPitchIds: [],
    decalreInterestProcessingIds: []
}

const stateController = new StateController<PlusPitchesV2State>(
    "LANDING_POAGE/V2/PLUS_PITCHES",
    defaultState
);

class SendTestPlusPitchSpecification implements MessageSend.IMessageSendSpecificaion {
    private dispatch;
    private toSquadId: number;
    private playerId: number;
    private squadPitchId: number;
    private isSavedMode: boolean;
    private isAgencyReceiver: boolean;
    private agencyId: number;
    private coachId: number | null;

    constructor(dispatch, toSquadId: number, playerId: number, squadPitchId: number, isSavedMode: boolean, isAgencyReceiver: boolean, agencyId: number, coachId: number) {
        this.dispatch = dispatch;
        this.toSquadId = toSquadId;
        this.playerId = playerId;
        this.squadPitchId = squadPitchId;
        this.isSavedMode = isSavedMode;
        this.isAgencyReceiver = isAgencyReceiver;
        this.agencyId = agencyId;
        this.coachId = coachId;
    }

    public async sendMessage(session: MessageSend.MessageSendSession, message: string, checked: boolean): Promise<any> {
        // this.dispatch(CommonController.Actions.togglePlayerInShortList(this.playerId, false));

        const pitchSource = this.isAgencyReceiver ? 'Agents ' : ''
        const pitchCoachSource = this.coachId !== null ? 'Head Coach ' : ''

        let receiverId = this.isAgencyReceiver ? this.agencyId : this.toSquadId;

        this.dispatch(userActivityInsert({
            PageName: `Home [${pitchSource}Test ${pitchCoachSource}Plus Pitch]`,
            Message: 'Sent New Message',
            PlayerId: this.playerId,
            CoachId: this.coachId,
            ClubId: !this.isAgencyReceiver ? this.toSquadId : -1,
            AgencyId: this.isAgencyReceiver ? this.agencyId : null,
            ActionType: ActionType.SentNewMessage,
            PageType: PageType.Home,
            UserActivityType: UserActivityType.Action
        }));

        if (!checked) {
            this.dispatch(Actions.dismissPitch(this.squadPitchId))
        } else {
            this.dispatch(Actions.proceedPitch(this.squadPitchId, async () => { PlusPitchServicev2.deleteTestInterest(this.squadPitchId) }));
        }

        await this.dispatch(messageSend(receiverId, message, null, session.officialName, session.mesageTypeId, session.subject, this.isAgencyReceiver, this.squadPitchId));
    }

    public cancelMessage() {
        const pitchSource = this.isAgencyReceiver ? 'Agents ' : ''
        const pitchCoachSource = this.coachId !== null ? 'Head Coach ' : ''

        this.dispatch(userActivityInsert({
            PageName: `Home [${pitchSource}Test ${pitchCoachSource}Plus Pitch]`,
            Message: 'Cancelled New Message',
            PlayerId: this.playerId,
            CoachId: this.coachId,
            ClubId: !this.isAgencyReceiver ? this.toSquadId : -1,
            AgencyId: this.isAgencyReceiver ? this.agencyId : null,
            PageType: PageType.Home
        }));
    }
}

class Actions {

    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState));
        }
    }

    public static newMessage(pitchId: number) {
        return async (dispatch, getState: () => AppState) => {

            let state = getState();

            let pitch = state.landingPage.plusPitchV2.testedPlusPitches.find(x => x.pitchId == pitchId);
            const isPlayerhSession = pitch.clubStaff === null;
            const playerId = isPlayerhSession ? pitch.player.id : null
            const coachId = !isPlayerhSession ? pitch.clubStaff.id : null

            let pitchSource = "";
            let agencyId;
            let clubId;

            if (pitch.isFromAgency) {
                pitchSource = "Agents "
                agencyId = pitch.agencySenderInfo.id;
            } else {
                clubId = pitch.player.clubInfo.parentSquad.id
            }

            const pitchCoachSource = coachId !== null ? 'Head Coach ' : ''
            
            if (pitch != null) {
                dispatch(userActivityInsert({
                    PageName: `Home [${pitchSource}Test ${pitchCoachSource}Plus Pitch]`,
                    Message: 'Opened New Message',
                    PlayerId: playerId,
                    CoachId: coachId,
                    AgencyId: agencyId,
                    ClubId: clubId,
                    PageType: PageType.Home
                }));
            }
            let session = {} as MessageSend.MessageSendSession;
            if (isPlayerhSession) {
                session = {
                    subject: `Re: ${pitch.player.englishShortName} Plus Pitch`,
                    logo: pitch.player.clubInfo.logo,
                    checkBoxTitle: 'Keep player in saved pitches',
                    officialName: pitch.player.clubInfo.parentSquad.name,
                    leagueName: pitch.player.clubInfo.parentSquadCompetitionName + ", " + pitch.player.clubInfo.parentSquadCompetitionCountry,
                    mesageTypeId: 4,
                }
            }
        
            if (pitch.isFromAgency) {
                let area = translate(`apiTexts.areas.${pitch.agencyAreaId}`)
                session = {
                    subject: "Re: " + (!isPlayerhSession ? pitch.clubStaff.name : pitch.player.englishShortName) + " Plus Pitch",
                    logo: false,
                    checkBoxTitle: `Keep ${!isPlayerhSession ? 'Head Coach' : 'player'} in saved pitches`,
                    officialName: pitch.agencySenderInfo.name,
                    leagueName: `Agent${area ? ', ' : ''} ${area}`,
                    mesageTypeId: 4,

                }
            }

            let toSquadId = null;
            if (!pitch.isFromAgency) {
                toSquadId = pitch.player.clubInfo.parentSquad.id;
            }

            dispatch(
                MessageSend.Actions.openSession(
                    session,
                    new SendTestPlusPitchSpecification(dispatch, toSquadId, playerId, pitch.pitchId, false, pitch.isFromAgency, (pitch.agencySenderInfo || {}).id, coachId)
                )
            )
        }
    }

    public static loadPlusPitches() {
        return async (dispatch) => {
            dispatch(stateController.setState({ isLoading: true }));

            let allPitchesResponse = await PlusPitchServicev2.getPlusPitches(null);

            dispatch(stateController.setState({ isLoading: false, initialized: true, activePlusPitches: allPitchesResponse.active, testedPlusPitches: allPitchesResponse.tested }));
        }
    }

    public static declareInterest(pitchId: number) {
        return async (dispatch, getState: () => AppState) => {

            let currentSubState = getState().landingPage.plusPitchV2;
            let plusPitchesAll = [...currentSubState.activePlusPitches, ...currentSubState.testedPlusPitches];
            let pitch = plusPitchesAll.find(x => x.pitchId == pitchId);

            if (pitch.clubStaff === null) {
                const shortlistedPlayers = getState().landingPage.playerStatistic.shortListedPlayers;

                const playerId = pitch.player.id;
                if (!shortlistedPlayers.includes(playerId)) {
                    dispatch(PlayerStatisticActions.togglePlayerInShortList(playerId, false));
                }
            }
            
            await dispatch(
                this.proceedPitch(
                    pitchId,
                    () => new Promise(async (resolve, reject) => {
                        try {
                            dispatch(stateController.setState(prevState => ({
                                ...prevState,
                                decalreInterestProcessingIds: [...prevState.decalreInterestProcessingIds, pitchId],
                            })))
                                                        if (!pitch.saved) {
                                await PlusPitchServicev2.savePitch(pitchId);
                            }
                            await PlusPitchServicev2.declareInterest(pitchId);
                            
                            dispatch(Actions.userActivityInsert('Declared Interest', pitchId, ActionType.DeclaredInterest, UserActivityType.Action))
                            resolve(true)
                        } catch (e) {
                            console.error(e)
                            reject(e)
                        } finally {
                            dispatch(stateController.setState(prevState => ({
                                ...prevState,
                                decalreInterestProcessingIds: prevState.decalreInterestProcessingIds.filter(item => item !== pitchId),
                            })))
                        }
                    })
                )
            );
            //A.Z potential refresh
        }
    }

    public static dismissPitch(pitchId: number) {
        return async (dispatch, getState: () => AppState) => {

            let isTested = [...getState().landingPage.plusPitchV2.testedPlusPitches.map(x => x.pitchId)].includes(pitchId);

            await dispatch(
                this.proceedPitch(
                    pitchId,
                    () => new Promise(async (resolve, reject) => {
                        try {
                            await PlusPitchServicev2.dismiss(pitchId);

                            if (isTested) {
                                await PlusPitchServicev2.deleteTestInterest(pitchId);
                            }

                            dispatch(Actions.userActivityInsert(isTested ? 'Deleted Saved Pitch' : 'Dismissed Pitch', pitchId, null))
                            resolve(true)
                        } catch (e) {
                            console.error(e)
                            reject(e)
                        }
                    })
                )
            );
            //A.Z potential refresh
        }
    }

    public static savePitch(pitchId: number) {
        return async (dispatch, getState: () => AppState) => {
            await dispatch(
                this.proceedPitch(
                    pitchId,
                    () => new Promise(async (resolve, reject) => {
                        try {
                            await PlusPitchServicev2.savePitch(pitchId);
                            dispatch(Actions.userActivityInsert('Saved Pitch', pitchId, null))
                            resolve(true)
                        } catch (e) {
                            console.error(e)
                            reject(e)
                        }
                    })
                )
            );
            //A.Z potential refresh
        }
    }

    public static proceedPitch(pitchId: number, action: () => Promise<any>) {
        return async (dispatch, getState: () => AppState) => new Promise(async (resolve, reject) => {

            let subState = getState().landingPage.plusPitchV2;

            let currentProcesses = subState.processingPitchIds;
            dispatch(stateController.setState({ processingPitchIds: [...currentProcesses, pitchId] }))

            try {
                await action();
                dispatch(CommocnController.Actions.loadCounters());
                currentProcesses = subState.processingPitchIds;

                dispatch(stateController.setState(
                    {
                        testedPlusPitches: [...subState.testedPlusPitches.filter(x => x.pitchId != pitchId)],
                        activePlusPitches: [...subState.activePlusPitches.filter(x => x.pitchId != pitchId)]
                    },
                )
                )
                resolve(true)
            } catch (e) {
                console.error(e)
                reject(e)
            } finally {
                dispatch(stateController.setState(
                    { processingPitchIds: [...currentProcesses.filter(x => x != pitchId)], })
                )
            }
        })
    }

    public static userActivityInsert(message: string, pitchId: number, actionType: ActionType | null, userActivityType: number = null) {
        return async (dispatch, getState: () => AppState) => {
            let currentSubState = getState().landingPage.plusPitchV2;
            let plusPitchesAll = [...currentSubState.activePlusPitches, ...currentSubState.testedPlusPitches];

            let testedPrefix = '';
            let isTested = [...getState().landingPage.plusPitchV2.testedPlusPitches.map(x => x.pitchId)].includes(pitchId);
            if (isTested) {
                testedPrefix = 'Test ';
            }

            let pitch = plusPitchesAll.find(x => x.pitchId == pitchId);

            if (pitch != null) {
                const coachId = pitch.clubStaff?.id ?? null;
                const playerId = pitch.player?.id ?? null;
                const headCoachPrefix = coachId ? 'Head Coach ': '';
                let agencyId = null;
                let clubId = null
                let pageName = `Home [${testedPrefix}Plus Pitch]`
                let links = [];
                
                links.push({ Type: 3, TargetId: pitchId });

                if (pitch.isFromAgency) {
                    agencyId = pitch.agencySenderInfo.id;
                    pageName = `Home [Agents ${testedPrefix}${headCoachPrefix}Plus Pitch]`
                } else {
                    clubId = playerId ? pitch.player.clubInfo?.parentSquad?.id : pitch.clubStaff.clubInfo?.currentSquad?.id
                }
                               
                dispatch(userActivityInsert({
                    PageName: pageName,
                    Message: message,
                    PlayerId: playerId,
                    CoachId: coachId,
                    ClubId: clubId,
                    AgencyId: agencyId,
                    Links: links,
                    ActionType: actionType,
                    PageType: PageType.Home,
                    UserActivityType: userActivityType
                }));
            }
        }
    }
    
    public static onSuitabilityTooltipHover = (pitchId: number) => {
        return async (dispatch) => {
            dispatch(Actions.userActivityInsert('Opened Coach Suitability Info', pitchId, null))
        }
    }
    
    public static onRatingTooltipHover = (pitchId: number) => {
        return async (dispatch) => {
            dispatch(Actions.userActivityInsert('Opened Coach Rating Info', pitchId, null))
        }
    }

    public static openCoachProfile = (pitchId: number) => {
        return async (dispatch, getState: () => AppState) => {            
            const plusPitchesAll = [...Selectors.getActiveCoachPitches(getState()), ...Selectors.getTestedCoachPitches(getState())];
            const coachId = plusPitchesAll.find(x => x.pitchId == pitchId).clubStaff.id;
            
            dispatch(Actions.userActivityInsert('Opened Head Coach Profile', pitchId, null))
            openCoachProfileById(coachId)
        }
    }

    public static openAgencyProfile = (pitchId: number) => {
        return async (dispatch, getState: () => AppState) => {
            const plusPitchesAll = [...Selectors.getActiveCoachPitches(getState()), ...Selectors.getTestedCoachPitches(getState())];
            const agencyName = plusPitchesAll.find(x => x.pitchId == pitchId).agencySenderInfo.name;

            dispatch(Actions.userActivityInsert('Opened Agency Profile', pitchId, null))
            openAgencyProfile(agencyName)
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.landingPage.plusPitchV2
    public static getActivePitches = (state: AppState) => this.getRoot(state).activePlusPitches
    public static getActivePlayerPitches = (state: AppState) => Selectors.getActivePitches(state).filter(item => item.player !== null)
    public static getActiveCoachPitches = (state: AppState) => Selectors.getActivePitches(state).filter(item => item.clubStaff !== null)
    public static getTestedPitches = (state: AppState) => this.getRoot(state).testedPlusPitches
    public static getTestedPlayerPitches = (state: AppState) => Selectors.getTestedPitches(state).filter(item => item.player !== null)
    public static getTestedCoachPitches = (state: AppState) => Selectors.getTestedPitches(state).filter(item => item.clubStaff !== null)
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    PlusPitchesV2State as State,
    Actions as Actions,
    stateController as Controller,
    Selectors as Selectors
};



