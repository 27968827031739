import * as redux from 'redux';
import * as completeDetailsController from './complete-details.controller'
import * as makePlayersAvailableController from './make-players-available.controller'
import * as createPlayerAdController from './create-player-ad-step.controller'
import * as confirmRegistrationController from './confirm-page.controller';
import * as inviteClubsController from './invite-clubs.controller';
import * as inviteClubsNonTrController from './invite-clubs-non-tr.controller';
import * as rewardController from './reward.controller';
import * as confirmUnauthorizedController from './registration-flow-unauthorized.controller';
import * as confirmPageController from './confirm-page-steps/confirm.controller';
import * as confirmStep1Controller from './confirm-page-steps/step-1.controller';
import * as confirmStep2Controller from './confirm-page-steps/step-2.controller';
import * as confirmStep2_1Controller from './confirm-page-steps/step-2-1.controller';
import * as confirmStep3Controller from './confirm-page-steps/step-3.controller';
import * as registrationAuthorizedAgencyController from './agency/authorized-registration.controller'
import * as inviteAgentsController from './confirm-page-steps/invite-agents.controller';
import * as dateSelectorController from './confirm-page-steps/date-selector.controller';

export class VirtualSummitState {
    completeDetails: completeDetailsController.State;
    //confirmRegistration: confirmRegistrationController.State;
    confirmRegistrationUnauthorized: confirmUnauthorizedController.State;
    registrationAuthorizedAgency: registrationAuthorizedAgencyController.State;
    makePlayersAvailable: makePlayersAvailableController.State;
    createPlayerAd: createPlayerAdController.State;
    inviteClubs: inviteClubsController.State;
    inviteClubsNonTr: inviteClubsNonTrController.State;
    rewardProgram: rewardController.State;
    confirmPage: confirmPageController.State;
    confirmStep1: confirmStep1Controller.State;
    confirmStep2: confirmStep2Controller.State;
    confirmStep2_1: confirmStep2_1Controller.State;
    confirmStep3: confirmStep3Controller.State;
    inviteAgents: inviteAgentsController.State;
    dateSelector: dateSelectorController.State;
}

export function combineReducers() {

    let virtualSummitReducer = {
        completeDetails: completeDetailsController.Reducer,
        //confirmRegistration: confirmRegistrationController.Reducer,
        confirmRegistrationUnauthorized: confirmUnauthorizedController.Reducer,
        registrationAuthorizedAgency: registrationAuthorizedAgencyController.Reducer,
        makePlayersAvailable: makePlayersAvailableController.Reducer,
        createPlayerAd: createPlayerAdController.Reducer,
        inviteClubs: inviteClubsController.Reducer,
        inviteClubsNonTr: inviteClubsNonTrController.Reducer,
        rewardProgram: rewardController.Reducer,
        confirmPage: confirmPageController.Reducer,
        confirmStep1: confirmStep1Controller.Reducer,
        confirmStep2: confirmStep2Controller.Reducer,
        confirmStep2_1: confirmStep2_1Controller.Reducer,
        confirmStep3: confirmStep3Controller.Reducer,
        inviteAgents: inviteAgentsController.Reducer,
        dateSelector: dateSelectorController.Reducer
    };

    return redux.combineReducers(virtualSummitReducer)
} 