import { StateController } from 'utils/action-declaration';
import { AgencyProfileService } from 'api/agency/club/agency-profile/agency-profile.service';
import { Actions as AgentsActions } from './agents.controller';
import { Actions as PlayerPortfolioActions } from './player-portfolio.controller';
import { Actions as LeaguesCoverageActions } from './leagues-coverage-chart.controller';
import { Actions as AgencyInfoActions } from './agency-info.controller'
import { Actions as ClubConnectionsActions } from './club-connections.controller'
import { AppState } from 'root.reducer';
import { getAuth } from 'store/auth/authReducer';
class CommonState {
    isLoading: boolean;
    currentAgencyId: number;
    isPreview: boolean;
}

const defaultState: CommonState = {
    isLoading: false,
    currentAgencyId: null,
    isPreview: false,
}

const stateController = new StateController<CommonState>(
    'AGENCY_PROFILE/COMMON',
    defaultState
)

class Actions {

    public static init(agencyId: number, currencyId: number) {
        return async (dispatch, getState) => {
            dispatch(stateController.setState({ isLoading: true, currentAgencyId: agencyId }))
            try {
                await dispatch(Actions.loadAgencyProfileData(agencyId, currencyId))
                await dispatch(PlayerPortfolioActions.loadShortlistedPlayers())
                dispatch(stateController.setState({ isLoading: false }))
            }
            catch {
                dispatch(stateController.setState({ isLoading: false }))
            }
        }
    }

    public static loadAgencyProfileData(id: number, currencyId: number) {
        return async (dispatch, getState: () => AppState) => {
            const data = await AgencyProfileService.getAgencyProfileData(id, currencyId)
            const {
                accessGranted,
                agencyCity,
                agencyCountryName,
                agencyName,
                isVerified,
                webSiteUrl,
                markets,
                agents,
                playerPortfolio,
                playersRepresentedLeagues,
                connectedByClubs,
                connectedByLeagueAndCounties,
                agencyId,
                isTrusted,
                countOfAllActiveSquads,
                agencyIsNotAccesible
            } = data

             dispatch(AgentsActions.saveDataToState(agents))
             dispatch(PlayerPortfolioActions.setDataToState(playerPortfolio))
             dispatch(LeaguesCoverageActions.setDataToState(playersRepresentedLeagues))
             dispatch(AgencyInfoActions.setDataToState({
                accessGranted,
                agencyCity,
                agencyCountryName,
                agencyName,
                isVerified,
                isTrusted,
                webSiteUrl,
                markets,
                agencyId,
                countOfAllActiveSquads,
                agencyIsNotAccesible
            }))
            dispatch(ClubConnectionsActions.saveDataToState({
                connectedByClubs,
                connectedByLeagueAndCounties
            }))

        }
    }

    public static loadAgencyProfileDataPreview(currencyId: number) {
        return async (dispatch, getState: () => AppState) => {
            const data = await AgencyProfileService.getAgencyProfileDataPreview(currencyId)
            const {
                accessGranted,
                agencyCity,
                agencyCountryName,
                agencyName,
                isVerified,
                webSiteUrl,
                markets,
                agents,
                playerPortfolio,
                playersRepresentedLeagues,
                connectedByClubs,
                connectedByLeagueAndCounties,
                agencyId,
                countOfAllActiveSquads,
            } = data

            await dispatch(AgentsActions.saveDataToState(agents))
            await dispatch(PlayerPortfolioActions.setDataToState(playerPortfolio))
            await dispatch(LeaguesCoverageActions.setDataToState(playersRepresentedLeagues))
            await dispatch(AgencyInfoActions.setDataToState({
                accessGranted,
                agencyCity,
                agencyCountryName,
                agencyName,
                isVerified,
                webSiteUrl,
                markets,
                agencyId,
                countOfAllActiveSquads,
            }))
            await dispatch(ClubConnectionsActions.saveDataToState({
                connectedByClubs,
                connectedByLeagueAndCounties
            }))
        }
    }

    public static initPreview(currencyId: number) {
        return async (dispatch, getState) => {
            const agencyId = getAuth(getState()).agencyId;

            dispatch(stateController.setState({ isLoading: true, currentAgencyId: agencyId, isPreview: true }))

            return Promise.allSettled([
                dispatch(Actions.loadAgencyProfileDataPreview(currencyId)),
                dispatch(PlayerPortfolioActions.loadShortlistedPlayers())
            ]).then(() => {
                dispatch(stateController.setState({ isLoading: false }))
            }).catch(e => {
                console.error(e)
                dispatch(stateController.setState({ isLoading: false }))
            })
        }
    }
}

class Selectors {
    public static isPreview = (state: AppState): boolean => state.agencyProfile.common.isPreview;
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    CommonState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};