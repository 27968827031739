function LocalStorage(...key) {
    if (key.length === 0) {
        throw new Error('Param `key` is required')
    }

    const keyName = key.length === 1 ? key[0] : `@${key.join('/')}`

    function set(data) {
        window.localStorage.setItem(keyName, JSON.stringify(data))
    }

    function get() {
        const value = window.localStorage.getItem(keyName)

        try {
            return JSON.parse(value)
        } catch {
            return value
        }
    }

    function remove() {
        window.localStorage.removeItem(keyName)
    }

    return Object.freeze({ get, set, remove })
}

export default LocalStorage