import { getRequests } from 'store/requests/requestsReducer';
import { asyncActionType } from 'store/actionTypes';

const initialState = {
    topCountries: [
        {
            placeName: '',
            total: '',
            dealsAmount: undefined,
            averageAmount: ''
        }
    ],
    topLeagues: [
        {
            placeName: '',
            total: '',
            dealsAmount: undefined,
            averageAmount: ''
        }
    ],
    freeTransferPerecent: undefined,
    onLoanPerecent: undefined,
    transfersPerecent: undefined,
};

export default (outgoingPlayers = initialState, action) => {
    switch (action.type) {
        case OUTGOING_PLAYERS_FETCH.SUCCESS: {
            return {
                ...outgoingPlayers,  ...action.payload.data
            };
        }
        case OUTGOING_PLAYERS_FETCH.REQUEST:
            return { ...outgoingPlayers };

        default:
            return outgoingPlayers;
    }
};


export const OUTGOING_PLAYERS_FETCH = asyncActionType('app/financial-insights/OUTGOING_PLAYERS_FETCH');

export const getOutgoingPlayersLoadingStatus = state =>
    getRequests(state).some(r => r.type === OUTGOING_PLAYERS_FETCH);


