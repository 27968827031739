import * as redux from 'redux';
import * as reonboarding from './club-reonboarding.controller';
import * as modal from './modal/club-reonboarding-modal.controller';

export class ClubReonboardingRootState {
    reonboarding: reonboarding.State;
    modal: modal.State;
}

export function combineReducers() {

    let reonboardingRoot = {
        reonboarding: reonboarding.Reducer,
        modal: modal.Reducer,
    };

    return redux.combineReducers(reonboardingRoot)
}