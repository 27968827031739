import { AppState } from 'root.reducer'
import historyAccessor from 'history-accessor'
import { StateController } from 'utils/action-declaration'
import { AGENCY_FORCE_REONBOARDING_POPUP_FALSE } from 'store/actionTypes'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions'
import ReonboardingService from 'api/agency/agent/reonboarding/reonboarding.service'
import { Actions as DashboardActions } from 'pages/agency/authorized-agent/home-page/redux/dashboard.controller'

class State {}

const defaultState: State = {}

const stateController = new StateController<State>(
    'AGENCY/AGENCY-TRANSPARENCY',
    defaultState
)

class Actions {
    public static remindMeLater(isFromPopup: boolean = false) {
        return async (dispatch, getState: () => AppState) => {
            try {
                if(isFromPopup){
                    dispatch({ type: AGENCY_FORCE_REONBOARDING_POPUP_FALSE })
                } else {
                    dispatch(DashboardActions.hideReonboardingBanner());
                }
                await ReonboardingService.remindMeLater()
                if (isFromPopup) {
                    dispatch(userActivityInsert({
                        PageName: 'Verification Flow',
                        Message: `Remind Me Later`,
                    }))
                } else {
                    dispatch(userActivityInsert({
                        PageName: 'Agency Home [Verification Flow]',
                        Message: `Remind Me Later`,
                    }))
                }
            } catch (err) {
                console.error(err)
            }
        }
    }

    public static getStarted(isFromPopup: boolean = false) {
        return async (dispatch, getState: () => AppState) => {
            try {
                if(isFromPopup){
                    dispatch({ type: AGENCY_FORCE_REONBOARDING_POPUP_FALSE })
                }
                const promise = ReonboardingService.logGetStarted()
                historyAccessor.push('/reonboarding')

                if(!isFromPopup){
                    dispatch(DashboardActions.hideReonboardingBanner());
                }
                await promise;
                if (isFromPopup) {
                    dispatch(userActivityInsert({
                        PageName: 'Verification Flow',
                        Message: `Get Started`,
                    }))
                } else {
                    dispatch(userActivityInsert({
                        PageName: 'Agency Home [Verification Flow]',
                        Message: `Get Started`,
                    }))
                }
            } catch (err) {
                console.error(err)
            }
        }
    }
}


class Selectors {}

const reducer = stateController.getReducer()

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    stateController as Controller,
    Selectors as Selectors,
}