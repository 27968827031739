import axios from 'axios-config';
import { AgencyCoachSearchResponse, HeadCoachesVerificationResponse, PendingVerificationHeadCoachesResponse, PendingGenerateLinkVerification, SetStrategyRequest } from './head-coaches.models'

export default class AgencyHeadCoachesService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async getCoaches(): Promise<HeadCoachesVerificationResponse> {
        const { data } = await axios.get(
            `api/v2/AgencyCoach/Coaches`,
            this.axiosConfig
        )
        return data;
    }

    public static async upgradeCoach(staffId: number): Promise<void> {
        await axios.post(`api/v2/AgencyCoach/Upgrade/${staffId}`, this.axiosConfig)
    }

    public static async getByKeyword(keyword: string): Promise<AgencyCoachSearchResponse> {
        const { data } = await axios.get(
            `api/v2/AgencyCoach/GetByKeyword?keyword=${keyword}`,
            this.axiosConfig
        )
        return data;
    }

    public static async getPendingVerificationCoaches(): Promise<PendingVerificationHeadCoachesResponse> {
        const { data } = await axios.get(
            `api/v2/AgencyCoach/PendingVerification`,
            this.axiosConfig
        )
        return data;
    }

    public static async generateLink(request: PendingGenerateLinkVerification) {
        const { data } = await axios.post(`api/v2/AgencyCoach/GenerateLinks`, { coaches: [request] }, this.axiosConfig)
        return data.coachLinks[0];
    }

    public static async removeCoach(staffId: number) {
        await axios.post(`api/v2/AgencyCoach/Remove/${staffId}`, this.axiosConfig)
    }

    public static async downgradeCoach(staffId: number) {
        await axios.post(`api/v2/AgencyCoach/Downgrade/${staffId}`, null, this.axiosConfig)
    }

    public static async setStrategy(request: SetStrategyRequest) {
        const { data } = await axios.post(`api/v2/AgencyCoach/SetStrategy`, request, this.axiosConfig)
        return data;
    }

    public static async showAgencyInterestToCoachProfile(): Promise<void> {
        await axios.post(`api/v2/AgencyCoach/AgencyCoachInterest`, this.axiosConfig)
    }

    public static async setContractExpiration(staffId: number, contractExpiry: Date) {
        const { data } = await axios.post(
            `api/v2/AgencyCoach/SetContractExpiration`,
            {
                staffId: staffId,
                contractExpiration: contractExpiry,
            },
            this.axiosConfig
        )
        return data;
    }
}