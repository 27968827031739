import { toast } from 'react-toastify'
import { parseAndTranslate } from 'services/localization';


type NotificationCreateProp = {
    id?: string;
    message: string | JSX.Element;
    level?: 'success' | 'error' | 'warn' | 'info';
    removeTimeOutMilliseconds?: number,
}

export const notificationCreate = (payload: NotificationCreateProp) => {
    return () => {

        const {
            level,
            removeTimeOutMilliseconds
        } = payload
        const message = typeof payload.message === 'string' ? <div dangerouslySetInnerHTML={{ __html: payload.message}} /> : payload.message

        const options = {
            autoClose: removeTimeOutMilliseconds || 3000
        }

        if (level === 'success') {
            toast.success(message, options)
        } else if (level === 'info') {
            toast.info(message, options)
        } else if (level === 'warn') {
            toast.warn(message, options)
        } else if (level === 'error') {
            toast.error(message, options)
        } else {
            toast(message, options)
        }

    }
}

export const notificationError = message => {
    return dispatch => {
        dispatch(
            notificationCreate({
                level: 'error',
                message: message || parseAndTranslate('[%notifications.somethingWentWrong%]'),
            }),
        );
    };
};
