import { StateController } from "utils/action-declaration";
import PlayerVerificationService from "api/admin/player-verification/player-verification.service";
import {
    PlayerInvitationsItems,
    PlayerVerificationContract,
    FilterParameters, Notes, RemoveInfo, EvidenceInfo, VerificationLinkInfo,
} from "api/admin/player-verification/model";
import {AppState} from "root.reducer";
import {StatusesModel} from "api/admin/upgrade-banner-report/models";
import { copyToClipboard as copy } from 'utils/copy-to-clipboard';
import { notificationCreate } from 'app/notifications/notifications.actions'

class PlayerVerificationState {
    playerInvitations: PlayerInvitationsItems[];
    statuses: StatusesModel[];
    evidence: EvidenceInfo;
    verificationLink: VerificationLinkInfo;
    notes: Notes;
    removeInfo: RemoveInfo;
    isLoading: boolean;
    page: number;
    recordsPerPage: number;
    sortOrder: string;
    isSortOrderAscending: boolean;
    total: number;
    filterParameters: FilterParameters;
    previousFilterSnapshot: FilterParameters;
}

const defaultFilterParameters: FilterParameters = {
    agentName: '',
    agencyName: '',
    playerName: '',
    parentSquadName: '',
    playerPhone: '',
    verificationStatusName: '',
    notes: '',
};

const defaultState = {
    playerInvitations: [],
    statuses: [],
    evidence: {
        isModalOpen: false,
        transactions: [],
        linkedAgency: null,
    },
    verificationLink :{
        isModalOpen: false,
        isError: false,
        isProcessing: false,
        isDataReady: false,
        invitationUid: '',
        playerName: '',
        agencyName: '',
        link: '',
        hasPlayerAccount: false,
        hasAgencyFreeCredits: false,
        verificationStatusId: 0,
    },
    notes: {
        noteList: [],
        isModalOpen: false,
        uid: '',
        isLoading: false,
    },
    removeInfo : {
        isRemoveModalOpen: false,
        uid: '',
        isRegular: false,
        playerName: '',
        agencyName: '',
    },
    isLoading: false,
    page: 1,
    recordsPerPage: 25,
    sortOrder: 'id',
    isSortOrderAscending: true,
    total: 0,
    filterParameters: defaultFilterParameters,
    previousFilterSnapshot: defaultFilterParameters,
}
const stateController = new StateController<PlayerVerificationState>(
    'ADMIN_V2/PLAYER_VERIFICATION',
    defaultState
);

class Actions {
    public static loadData = () => {
        const wasFiltersChanged = (filterParameters: FilterParameters, previousFilterSnapshot: FilterParameters) => {
            return JSON.stringify(filterParameters) !== JSON.stringify(previousFilterSnapshot);
        }

        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isLoading: true }));
                const {filterParameters, previousFilterSnapshot, page, recordsPerPage} = Selectors.selectState(getState());

                const shouldDisposePagination = wasFiltersChanged(filterParameters, previousFilterSnapshot);

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    previousFilterSnapshot: {
                        ...prevState.filterParameters,
                    },
                    page: shouldDisposePagination ? 1 : page,
                })));

                const searchParameters = {
                    ...filterParameters,
                    page: shouldDisposePagination ? 1 : page,
                    recordsPerPage,
                }


                const playerVerificationData: PlayerVerificationContract = await PlayerVerificationService.playerInvitationsFetch(searchParameters);
                const {pageItems: playerInvitations, pageSize, total } = playerVerificationData.data.items;

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    playerInvitations,
                    page: shouldDisposePagination ? 1 : page,
                    recordsPerPage: pageSize,
                    total,
                    filterParameters: {
                        ...prevState.filterParameters,
                    },
                })));
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        };
    };

    public static getStatuses = () => {
        return async (dispatch, getState: () => AppState) => {
            try {
                const statuses = await PlayerVerificationService.statusesFetch();
                dispatch (stateController.setState(prevState => ({
                    ...prevState,
                        statuses: statuses.data,
                })))
            } catch (err) {
                console.error(err);
            }
        }
    }

    public static onPaginationChange(pageNumber: number, pageSize: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                page: pageNumber,
                recordsPerPage: pageSize,
            })))

            dispatch(Actions.loadData());
        }
    }

    public static onChangeAgentName(value: string) {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterParameters: {
                    ...prevState.filterParameters,
                    agentName: value
                }
            })));
        }
    }

    public static onChangeAgencyName(value: string) {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterParameters: {
                    ...prevState.filterParameters,
                    agencyName: value
                }
            })));
        }
    }

    public static onChangePlayerName(value: string) {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterParameters: {
                    ...prevState.filterParameters,
                    playerName: value
                }
            })));
        }
    }

    public static onChangeParentSquadName(value: string) {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterParameters: {
                    ...prevState.filterParameters,
                    parentSquadName: value
                }
            })));
        }
    }

    public static onChangePlayerPhone(value: string) {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterParameters: {
                    ...prevState.filterParameters,
                    playerPhone: value
                }
            })));
        }
    }

    public static onChangeVerificationStatusName(value: string) {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterParameters: {
                    ...prevState.filterParameters,
                    verificationStatusName: value
                }
            })));
        }
    }

    public static onChangeNote(value: string) {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterParameters: {
                    ...prevState.filterParameters,
                    notes: value
                }
            })));
        }
    }

    public static applySearch () {
        return dispatch => dispatch(Actions.loadData());
    }

    public static clearFilters () {
        return (dispatch) => {
            dispatch(stateController.setState(prevState => ({ ...prevState, filterParameters: defaultFilterParameters })));
            dispatch(Actions.applySearch());
        }
    }

    public static onVerifyPlayer (invitationUid: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                await PlayerVerificationService.verifyPlayer(invitationUid);
                dispatch(Actions.loadData());
            } catch (err) {
                console.error(err);
            }
        }
    }
    /*------------Remove-reject--------*/

    public static onRejectPlayer (invitationUid: string, isReturnCredit: boolean) {
        return async (dispatch, getState: () => AppState) => {
            try {
                await PlayerVerificationService.rejectPlayer(invitationUid, isReturnCredit)
                dispatch(Actions.loadData());
            } catch (err) {
                console.error(err);
            }
        }
    }
    public static setRemoveInfo (data: PlayerInvitationsItems) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                removeInfo: {
                    ...prevState.removeInfo,
                    uid: data.uid,
                    playerName: data.playerName,
                    isRegular: data.isRegular,
                    agencyName: data.agencyName,
                }
            })));
        }
    }
    public static openRemoveModal () {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                removeInfo: {
                    ...prevState.removeInfo,
                    isRemoveModalOpen: true,
                }
            })));
        }
    }

    public static closeRemoveModal () {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                removeInfo: {
                    ...prevState.removeInfo,
                    isRemoveModalOpen: false,
                }
            })));
        }
    }

    /*----------Notes-----*/
    public static loadNotes (uid: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    notes: {
                        ...prevState.notes,
                        isLoading:true,
                    }
                })));
                const {data} = await PlayerVerificationService.loadNotes(uid);
                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    notes: {
                        ...prevState.notes,
                        noteList: data,
                        uid,
                    }
                })));
                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    notes: {
                        ...prevState.notes,
                        isLoading: false,
                    }
                })));
            } catch (err) {
                console.error(err);
            }
        }
    }

    public static openNotesModal (uid: string) {
        return (dispatch, getState: () => AppState) => {
                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    notes: {
                        ...prevState.notes,
                        isModalOpen: true,
                        uid,
                    }
                })));
        }
    }
    public static closeNotesModal () {
        return (dispatch, getState: () => AppState) => {
                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    notes: {
                        ...prevState.notes,
                        noteList: [],
                        isModalOpen: false,
                        uid: '',
                    }
                })));
                dispatch(Actions.loadData());
        }
    }

    public static saveNotes (note: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
               await PlayerVerificationService.saveNote(getState().admin.playerVerification.notes.uid, note);
               dispatch(Actions.loadNotes(getState().admin.playerVerification.notes.uid));
            } catch (err) {
                console.error(err);
            }
        }
    }

    /*-------Evidence-----*/

    public static loadEvidence (id: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const evidenceData = await PlayerVerificationService.agencyPlayerEvidenceFetch(id);
                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    evidence: {
                        ...prevState.evidence,
                        transactions: evidenceData.data.transactions,
                        linkedAgency: evidenceData.data.linkedAgency,
                    }
                })));
            } catch (err) {
                console.error(err);
            }
        }
    }

    public static openEvidenceModal (playerId) {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.loadEvidence(playerId));

            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                evidence: {
                    ...prevState.evidence,
                    isModalOpen: true,
                }
            })));
        }
    }

    public static closeEvidenceModal () {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                evidence: {
                    linkedAgency: null,
                    transactions: [],
                    isModalOpen: false,
                }
            })));
        }
    }

    public static setExpiryDate (uid, expirationDate) {
        return async (dispatch, getState: () => AppState) => {
            try {
                await PlayerVerificationService.assignContractExpiration(uid, expirationDate);
                dispatch(Actions.loadData());
            } catch (err) {
                console.error(err);
            }
        }
    }


    /*-------Verification Link-----*/

    public static openVerificationLinkModal(data: any) {

        return (dispatch, getState: () => AppState) => {

            dispatch(Actions.loadVerificationLink(data));

            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                verificationLink: {
                    ...prevState.verificationLink,
                    isModalOpen: true,
                }
            })));
        }
    }

    public static closeVerificationLinkModal () {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                verificationLink: {                    
                    isModalOpen: false,
                }
            })));
        }
    }

    public static loadVerificationLink (data: any) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    verificationLink: {
                        ...prevState.verificationLink,
                        isDataReady: false,
                    }
                })));
                const isPending = data.verificationStatusId === 3;
                const hasAgencyFreeCredits = isPending ? true : await PlayerVerificationService.getAgencyFreeCredits(data.agencyId);
                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    verificationLink: {
                        ...prevState.verificationLink,
                        invitationUid: data.uid,
                        playerName: data.playerName,
                        agencyName: data.agencyName,
                        link: data.verificationLink,
                        hasPlayerAccount: data.hasAccount,
                        hasAgencyFreeCredits: hasAgencyFreeCredits,      
                        verificationStatusId: data.verificationStatusId,
                        isProcessing: false,              
                    }
                })));
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    verificationLink: {
                        ...prevState.verificationLink,
                        isDataReady: true,
                    }
                })));
            }
        }
    }

    public static copyToClipboard(link: string) {
        return (dispatch, getState: () => AppState) => {  
            copy(link)
            dispatch(notificationCreate({ message: 'Copied to clipboard', level: 'info'}))
        }
    }

    public static setVerificationLinkProcessing() {
        return async (dispatch, getState: () => AppState) => {
            try {               
                
                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    verificationLink: {
                        ...prevState.verificationLink,
                        isProcessing: true,                  
                    }
                })));
            } catch (err) {
                console.error(err);
            }
        }
    }

    public static setVerificationLinkDataReady() {
        return async (dispatch, getState: () => AppState) => {
            try {             
                    dispatch(stateController.setState((prevState) => ({
                        ...prevState,
                        verificationLink: {
                            ...prevState.verificationLink,
                            isDataReady: true,                  
                        }
                    })));
            } catch (err) {
                console.error(err);
            }
        }
    }

    public static setSentStatus(invitationUid: string){
        return async (dispatch) => {
            try {            
                const result = await PlayerVerificationService.setStatusToSent(invitationUid);           
                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    verificationLink: {
                        ...prevState.verificationLink,
                        isProcessing: false,                  
                    }
                })));
            } catch(e) {
                console.error(e)
            }
        }
    }

    public static updateDataInGrid(invitationUid: string, field: string, data: any){
        return async (dispatch,  getState: () => AppState) => {
            try {          
                const {playerInvitations} = Selectors.selectState(getState());                           
                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    playerInvitations: playerInvitations.map(item => {
                        if (item.uid === invitationUid) return {
                            ...item,
                            [field]: data,                                                            
                        }
                        return item;
                    }),
                })));
            } catch(e) {                
                console.error(e)
            }
        }
    }
}

class Selectors {
    public static selectState = (state: AppState) => state.admin.playerVerification;
    public static getInvitationItems = (state: AppState) => Selectors.selectState(state).playerInvitations;
    public static isLoading = (state: AppState) => Selectors.selectState(state).isLoading;
    public static getFilterParameters = (state: AppState) => Selectors.selectState(state).filterParameters;
    public static getStatuses = (state: AppState) => Selectors.selectState(state).statuses;
    public static getPageNumber = (state: AppState) => Selectors.selectState(state).page;
    public static getRecordsPerPage = (state: AppState) => Selectors.selectState(state).recordsPerPage;
    public static getTotalRecords = (state: AppState) => Selectors.selectState(state).total;
    public static getNotes = (state: AppState) => Selectors.selectState(state).notes
    public static getRemoveInfo = (state: AppState) => Selectors.selectState(state).removeInfo;
    public static getEvidenceInfo = (state: AppState) => Selectors.selectState(state).evidence;
    public static getVerificationLinkInfo = (state: AppState) => Selectors.selectState(state).verificationLink;
    public static isFiltersSetToDefault(state: AppState) {
        return JSON.stringify(defaultState.filterParameters) === JSON.stringify(Selectors.getFilterParameters(state))
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    PlayerVerificationState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};
