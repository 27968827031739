import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import SubscriptionService from 'api/agency/agent/subscription/subscription'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { Subscription, SubscriptionPlansResponse } from 'api/agency/agent/subscription/subscription-models';
import { getAuth } from 'store/auth/authReducer';
import historyAccessor from 'history-accessor';
import { PageType } from 'constants/enums';

class State {
    isLoading: boolean;
    isProcessing: boolean;
    plans: SubscriptionPlansResponse;
    selectedPlanId: number;
    selectedAddonId: number;
    customer: any;
    firstName: string;
    lastName: string;
    creditCardError: boolean;
    expiryError: boolean;
    cvcError: boolean;
    firstNameError: boolean;
    lastNameError: boolean;
    generalError: boolean;
    postCode: string;
    isDone: boolean;
}

const defaultState: State = {
    isLoading: false,
    isProcessing: false,
    plans: null,
    selectedPlanId: null,
    selectedAddonId: null,
    customer: null,
    firstName: null,
    lastName: null,
    creditCardError: false,
    expiryError: false,
    cvcError: false,
    firstNameError: false,
    lastNameError: false,
    generalError: false,
    postCode: null,
    isDone: false
}

const stateController = new StateController<State>(
    "AGENCY/SUBSCRIPTIONS",
    defaultState
)

class Actions {

    public static dispose() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(
                defaultState
            ));
        }
    }

    public static getClosestPlan(preconnected: number, plans: Array<Subscription>): number {
        if (preconnected <= 20) return plans[2].id;
        var closest = plans.reduce(function (prev, curr) {
            return (Math.abs(curr.slotsAmount - preconnected) < Math.abs(prev.slotsAmount - preconnected) ? curr : prev);
        });
        return closest.id;
    }

    public static getPlans() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));
            var plans = await SubscriptionService.getAllPlans();
            dispatch(stateController.setState({
                plans: plans,
                selectedPlanId: !plans.isSubscribed ? this.getClosestPlan(plans.preconnectedPlayersCount, plans.relatedOptions) : null,
                selectedAddonId: plans.isSubscribed ? (plans.relatedOptions.find(x => x.slotsAmount == 10) || {}).id : null,
                isLoading: false
            }));

            const isAgencySubscribed = getAuth(getState()).isAgencySubscribed;
            dispatch(userActivityInsert({
                PageName: isAgencySubscribed ? 'Agency Home [Add-on Subscription]' : 'Agency Home [Subscription]',
                Message: `Opened`,
                PageType: PageType.AgencyHome,
            }))
        }
    }

    public static setPlan(id: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ selectedPlanId: id }));

            const state = getState().agency.agent.agencySubscription.agencySubscription
            const plan = state.plans.relatedOptions.find(i => i.id == id);
            if (plan) {
                dispatch(userActivityInsert({
                    PageName: 'Agency Home [Subscription]',
                    Message: `Selected ${plan.slotsAmount || 0} credits`,
                    PageType: PageType.AgencyHome,
                }))
            }
        }
    }

    public static setAddon(id: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ selectedAddonId: id }));

            const state = getState().agency.agent.agencySubscription.agencySubscription
            const plan = state.plans.relatedOptions.find(i => i.id == id);
            if (plan) {
                dispatch(userActivityInsert({
                    PageName: 'Agency Home [Add-on Subscription]',
                    Message: `Selected ${plan.slotsAmount || 0} credits`,
                    PageType: PageType.AgencyHome,
                }))
            }
        }
    }

    public static setGeneralError(error: boolean) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ generalError: error }));
        }
    }
    public static setCreditCardError(error: boolean) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ creditCardError: error }));
        }
    }
    public static setExpiryError(error: boolean) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ expiryError: error }));
        }
    }
    public static setCvcError(error: boolean) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ cvcError: error }));
        }
    }
    public static setFirstNameError(error: boolean) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ firstNameError: error }));
        }
    }
    public static setLastNameError(error: boolean) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ lastNameError: error }));
        }
    }

    public static setFirstName(val: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ firstName: val, firstNameError: false }));
        }
    }
    public static setLastName(val: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ lastName: val, lastNameError: false }));
        }
    }
    public static setPostCode(val: string) {
        return (dispatch, getState: () => AppState) => {
            if (val.length <= 12)
                dispatch(stateController.setState({ postCode: val }));
        }
    }

    public static setSubscription(selectedSubscriptionId: number, stripeCustomer: string, operationUid: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                await SubscriptionService.setSubscription(selectedSubscriptionId, stripeCustomer, operationUid);
                dispatch(stateController.setState({ isProcessing: false, isDone: true }));
            } catch (error) {
                dispatch(this.setGeneralError(true))
                throw error;
            }

            const state = getState().agency.agent.agencySubscription.agencySubscription
            const plan = state.plans.relatedOptions.find(i => i.id == selectedSubscriptionId);
            if (plan) {
                dispatch(userActivityInsert({
                    PageName: 'Agency Home [Subscription]',
                    Message: `Ordered ${plan.slotsAmount} credits via Credit Card`,
                    PageType: PageType.AgencyHome,
                }))
            }
        }
    }

    public static setAddonToSubscription(selectedAddonId: number, stripeCustomer: string, operationUid: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                await SubscriptionService.setSubscription(selectedAddonId, stripeCustomer, operationUid);
                dispatch(stateController.setState({ isProcessing: false, isDone: true }));
            } catch (error) {
                dispatch(this.setGeneralError(true))
                throw error;
            }

            const state = getState().agency.agent.agencySubscription.agencySubscription
            const plan = state.plans.relatedOptions.find(i => i.id == selectedAddonId);
            if (plan) {
                dispatch(userActivityInsert({
                    PageName: 'Agency Home [Add-on Subscription]',
                    Message: `Ordered ${plan.slotsAmount} credits via Credit Card`,
                    PageType: PageType.AgencyHome,
                }))
            }
        }
    }

    public static setIsLoading(isLoading: boolean) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: isLoading }));
        }
    }

    public static setIsProcessing(isProcessing: boolean) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isProcessing: isProcessing }));
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    stateController as Controller
};