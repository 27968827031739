import { StateController } from 'utils/action-declaration';

import { AppState } from 'root.reducer'
import VirtualSummitService from 'api/virtual-summit/virtual-summit.service'
import { CompetitionWithActiveSquadsModel } from 'api/virtual-summit/models/summit-invitationl'
import { Actions as StepActions } from 'pages/virtual-summit/redux/complete-details.controller';


class InviteClubsNonTrState {
    source: Array<CompetitionWithActiveSquadsModel>;
    originalSelected: Array<number>;
    isLoading: boolean;
    selected: Array<number>;
    isCompleting: boolean;
}

const defaultState: InviteClubsNonTrState = {
    isLoading: false,
    isCompleting: false,
    source: [],
    selected: [],
    originalSelected: []
}

const stateController = new StateController<InviteClubsNonTrState>(
    "VIRTUAL_SUMMIT/INVITE_CLUBS_NON_TR",
    defaultState
);

export const clubLimit = 100;

class Actions {
    public static clubsSelect(ids: Array<number>) {
        return async (dispatch, getState: () => AppState) => {
            let appState = getState();
            let currentSelected = appState.virtualSummit.inviteClubsNonTr.selected;

            let combined = [...currentSelected];

            for (let i = 0; i < ids.length; i++) {
                const id = ids[i];
                if (!combined.includes(id)) {
                    if (combined.length != clubLimit)
                        combined.push(id);
                }
                else combined.splice(combined.indexOf(id), 1);
            }

            // for (let id of ids) {
            //     if (!combined.includes(id) && combined.length != clubLimit) combined.push(id);
            //     else combined.splice(combined.indexOf(id), 1);
            // }

            dispatch(stateController.setState({ selected: combined }))
        }
    }

    public static continue(redirect: (referer: string) => void) {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let ids = state.virtualSummit.inviteClubsNonTr.selected;

            dispatch(stateController.setState({ isCompleting: true }))

            await VirtualSummitService.setSummitClubsNonTr(state.auth.userId, ids);
            
            dispatch(stateController.setState({ isCompleting: false }))
            dispatch(StepActions.goToNext(false, redirect));
        }
    }

    public static loadData() {
        return async (dispatch, getState: () => AppState) => {
            let appState = getState();

            dispatch(stateController.setState({ isLoading: true }))

            var result = await VirtualSummitService.getSummitClubsNonTr(appState.auth.userId)

            dispatch(stateController.setState({ isLoading: false, selected: result.selectedIds, originalSelected: result.selectedIds, source: result.competitions }))
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    InviteClubsNonTrState as State,
    Actions as Actions,
    stateController as Controller
};



