import { PlayerAdInitialInfoResponse } from 'api/player-ad/models/player-ad'


export const getPreferredPlayerAreas = (initialInfo: PlayerAdInitialInfoResponse) => {
    const info = initialInfo

    if (!info) {
        return null
    }

    return [
        {
            id: null,
            name: 'Global',
            shortName: null,
        },
        { ...info.preferredRegionArea },
        { ...info.preferredNationalityArea },
    ];
};

