export const americasSummit14_15thFebruaryAttendees = [
    { id: '174', name: 'Club Atlético Talleres', logo: 'logo_2588_Club_Atletico_Talleres.png' },
    { id: '496', name: 'HNK Rijeka', logo: 'logo_2164_HNK_Rijeka.png' },
    { id: '1559', name: 'FC Cincinnati', logo: '37892_Cincinnati.png' },
    { id: '2164', name: 'Colorado Rapids', logo: '7853_Colorado_Rapids.png' },
    { id: '2179', name: 'Club Tijuana', logo: '15459_Club_Tijuana.png' },
    { id: '3774', name: 'Club Necaxa', logo: 'logo_2283_Club_Necaxa.png' },
    { id: '5121', name: 'Minnesota United FC', logo: 'logo_2122_Minnesota_United_FC.png' },
    { id: '5924', name: 'CD Everton', logo: '12293_CD_Everton.png' },
    { id: '6031', name: 'Tampa Bay Rowdies', logo: '8096_Tampa_Bay_Rowdies.png' },
    { id: '6306', name: 'Hibernian FC', logo: 'logo_Hibernian_FC.png' },
    { id: '7845', name: 'CF Pachuca', logo: '15413_CF_Pachuca.png' },
    { id: '7853', name: 'Toronto FC', logo: 'logo_2102_Toronto_FC.png' },
    { id: '8110', name: 'Hertha BSC', logo: 'logo_1267_Hertha_BSC.png' },
    { id: '8934', name: 'FC Sion', logo: '6421_FC_Sion.png' },
    { id: '9385', name: 'Sporting Kansas City', logo: '7849_Sporting_Kansas_City.png' },
    { id: '9657', name: 'Los Angeles FC', logo: '62148_Los_Angeles_FC.png' },
    { id: '10026', name: 'Los Angeles Galaxy', logo: '7847_Los_Angeles_Galaxy.png' },
    { id: '10216', name: 'CA Fénix', logo: 'logo_2878_CA_Fenix.png' },
    { id: '10317', name: 'New York Red Bulls', logo: 'logo_2112_New_York_Red_Bulls.png' },
    { id: '10744', name: 'CD Cruz Azul', logo: '15408_Cruz_Azul.png' },
    { id: '12027', name: 'Club Atlético Boston River', logo: 'logo_2875_Club_Atletico_Boston_River.png' },
    { id: '12156', name: 'Charlton Athletic', logo: 'logo_Charlton_Athletic.png' },
    { id: '12173', name: 'Barnsley FC', logo: 'logo_1158_Barnsley_FC.png' },
    { id: '12470', name: 'VfL Wolfsburg', logo: 'logo_VfL_Wolfsburg.png' },
    { id: '13404', name: 'Houston Dynamo FC', logo: '7944_Houston_Dynamo_FC.png' },
    { id: '13923', name: 'CS Emelec', logo: 'logo_2708_CS_Emelec.png' },
    { id: '14291', name: 'FK Sarajevo', logo: '9918_FK_Sarajevo.png' },
    { id: '14930', name: 'Celaya FC', logo: '15426_Celaya_FC.png' },
    { id: '15821', name: 'Arminia Bielefeld', logo: 'logo_Arminia_Bielefeld.png' },
    { id: '17017', name: 'Philadelphia Union', logo: 'logo_2115_Philadelphia_Union.png' },
    { id: '19310', name: 'Academia Deportiva Cantolao', logo: '36885_Academia_Deportiva_Cantolao.png' },
    { id: '22011', name: 'Puebla FC', logo: 'logo_2282_Puebla_FC.png' },
    { id: '22514', name: 'CF América', logo: '15405_América.png' },
    { id: '22719', name: 'Club de Regatas Vasco da Gama', logo: '5657_Club_de_Regatas_Vasco_da_Gama.png' },
    { id: '23506', name: 'Atlas Guadalajara', logo: '15407_Atlas.png' },
    { id: '23647', name: 'Atlético de San Luis', logo: '25866_Atlético_de_San_Luis.png' },
    { id: '24432', name: 'Pafos FC', logo: 'logo_2178_Pafos_FC.png' },
    { id: '25090', name: 'Cimarrones de Sonora', logo: '26089_Cimarrones_de_Sonora.png' },
    { id: '25359', name: 'Celta de Vigo', logo: 'logo_1718_Celta_de_Vigo.png' },
    { id: '25911', name: 'Royal Antwerp FC', logo: 'logo_1583_Royal_Antwerp_FC.png' },
    { id: '26540', name: 'Orlando City SC', logo: '8068_Orlando_City_SC.png' },
    { id: '27251', name: 'Clube de Regatas do Flamengo', logo: '5654_Clube_de_Regatas_do_Flamengo.png' },
    { id: '28858', name: 'Borussia Mönchengladbach', logo: '2454_Borussia_Mönchengladbach.png' },
    { id: '29015', name: 'Lokomotiv Moscow', logo: '13887_Lokomotiv_Moscow.png' },
    { id: '29306', name: 'Mineros de Zacatecas', logo: '30286_Mineros_de_Zacatecas.png' },
    { id: '30372', name: 'Envigado FC', logo: '12405_Envigado_FC.png' },
    { id: '30849', name: 'Atlanta United FC', logo: 'logo_2103_Atlanta_United_FC.png' },
    { id: '31484', name: 'Deportivo Guadalajara', logo: '15409_Deportivo_Guadalajara.png' },
    { id: '33648', name: 'Atlético Nacional', logo: '12391_Atlético_Nacional.png' },
    { id: '33797', name: 'Spezia Calcio', logo: 'logo_1860_Spezia_Calcio.png' },
    { id: '36192', name: 'Tigres UANL', logo: '15417_Tigres_UANL.png' },
    { id: '38328', name: 'D.C. United', logo: 'logo_2118_D.C._United.png' },
    { id: '39176', name: 'Leeds United', logo: 'logo_1150_Leeds_United.png' },
    { id: '39292', name: 'Liverpool FC Montevideo', logo: '15632_Liverpool_FC_Montevideo.png' },
    { id: '39981', name: 'Clube Atlético Mineiro', logo: '5653_Atlético-MG.png' },
    { id: '41646', name: 'FC Dallas', logo: 'logo_2113_FC_Dallas.png' },
    { id: '41692', name: 'Seattle Sounders FC', logo: 'logo_2106_Seattle_Sounders_FC.png' },
    { id: '41956', name: 'Deportivo Cali', logo: 'logo_2667_Deportivo_Cali.png' },
    { id: '42798', name: 'Cavalry FC', logo: '64336_Cavalry_FC.png' },
    { id: '50113', name: 'Inter Miami CF', logo: '-69261_Inter_Miami_CF.png' },
    { id: '200001', name: 'Mazatlán FC', logo: '-82696_Mazatlán_FC.png' },
    { id: '200004', name: 'Charlotte FC', logo: '-78435_Charlotte_FC.png' },
    { id: '221000', name: 'Canadian Premier League', logo: 'Canadian_Premier_League.png' },
]

export const virtualSummit10thNovemberAttendees = [
    { id: '23168', name: '1. FC Köln', logo: 'logo_1266_1._FC_Koln.png' },
    { id: '49', name: 'Arsenal FC', logo: 'logo_1121_Arsenal_FC.png' },
    { id: '38553', name: 'Aston Villa', logo: 'logo_1138_Aston_Villa.png' },
    { id: '23506', name: 'Atlas Guadalajara', logo: '15407_Atlas.png' },
    { id: '40123', name: 'Besiktas JK', logo: 'logo_2002_Besiktas_JK.png' },
    { id: '8239', name: 'Brentford', logo: 'logo_1153_Brentford_FC.png' },
    { id: '70', name: 'Brighton & Hove Albion', logo: 'logo_1135_Brighton_And_Hove_Albion.png' },
    { id: '17417', name: 'BSC Young Boys', logo: 'logo_1934_BSC_Young_Boys.png' },
    { id: '22514', name: 'CF América', logo: '15405_América.png' },
    { id: '28943', name: 'Club Atlético Banfield', logo: '12056_Banfield.png' },
    { id: '39981', name: 'Clube Atlético Mineiro', logo: '5653_Atlético-MG.png' },
    { id: '64', name: 'Crystal Palace', logo: 'logo_1128_Crystal_Palace.png' },
    { id: '32753', name: 'Deportivo Toluca', logo: '15418_Deportivo_Toluca.png' },
    { id: '14821', name: 'FC Copenhagen', logo: 'logo_FC_Copenhagen.png' },
    { id: '17668', name: 'Famalicão', logo: 'logo_FC_Famalicao.png' },
    { id: '16232', name: 'FC Girondins Bordeaux', logo: '3772_FC_Girondins_Bordeaux.png' },
    { id: '10158', name: 'FC Toulouse', logo: '3780_FC_Toulouse.png' },
    { id: '13510', name: 'FC Utrecht', logo: '17_FC_Utrecht.png' },
    { id: '13058', name: 'Feyenoord Rotterdam', logo: 'logo_1533_Feyenoord_Rotterdam.png' },
    { id: '3', name: 'Juventus FC', logo: 'logo_1832_Juventus_FC.png' },
    { id: '39176', name: 'Leeds United', logo: 'logo_1150_Leeds_United.png' },
    { id: '46', name: 'Liverpool', logo: 'logo_1123_Liverpool_FC.png' },
    { id: '10026', name: 'Los Angeles Galaxy', logo: '7847_Los_Angeles_Galaxy.png' },
    { id: '5121', name: 'Minnesota United FC', logo: 'logo_2122_Minnesota_United_FC.png' },
    { id: '25436', name: 'Nashville SC', logo: '30149_Nashville.png' },
    { id: '17175', name: 'Norwich City', logo: 'logo_1144_Norwich_City.png' },
    { id: '16046', name: 'Olympique Lyon', logo: 'logo_1966_Olympique_Lyon.png' },
    { id: '4', name: 'Parma Calcio 1913', logo: '3160_Parma_Calcio_1913.png' },
    { id: '7898', name: 'Preston North End', logo: 'logo_1161_Preston_North_End.png' },
    { id: '5282', name: 'PSV Eindhoven', logo: 'logo_1534_PSV_Eindhoven.png' },
    { id: '31588', name: 'Rangers FC', logo: 'logo_2188_Rangers_FC.png', },
    { id: '31367', name: 'Sheffield United', logo: 'logo_1159_Sheffield_United.png' },
    { id: '33797', name: 'Spezia Calcio', logo: 'logo_1860_Spezia_Calcio.png' },
    { id: '850', name: 'Stoke City', logo: 'logo_1129_Stoke_City.png' },
    { id: '9150', name: 'Venezia FC', logo: '3191_Venezia_FC.png' },
    { id: '35045', name: 'Vitesse Arnhem', logo: '24_Vitesse_Arnhem.png' }
]

export const virtualDealDay11thAugustAttendees = [
    { id: '48', name: 'Manchester City', logo: 'logo_Manchester_City.png' },
    { id: '45', name: 'Manchester United', logo: null }, //'logo_1120_Manchester_United.png' 
    { id: '33906', name: 'FC Barcelona', logo: null }, //'logo_FC_Barcelona.png'
    { id: '3', name: 'Juventus', logo: 'logo_1832_Juventus_FC.png' },
    { id: '38553', name: 'Aston Villa', logo: 'logo_1138_Aston_Villa.png' },
    { id: '10088', name: 'RB Leipzig', logo: 'logo_1261_RB_Leipzig.png' },
    { id: '46', name: 'Liverpool', logo: 'logo_1123_Liverpool_FC.png' },
    { id: '16046', name: 'Olympique Lyon"', logo: 'logo_1966_Olympique_Lyon.png' },
    { id: '49', name: 'Arsenal FC', logo: 'logo_1121_Arsenal_FC.png' },
    { id: '18960', name: 'FC Porto', logo: 'logo_1672_FC_Porto.png' },
    { id: '63', name: 'Everton FC', logo: 'logo_1124_Everton_FC.png' },
    { id: '39176', name: 'Leeds United', logo: 'logo_1150_Leeds_United.png' },
    { id: '60', name: 'Newcastle United', logo: 'logo_Newcastle_United.png' },
    { id: '976', name: 'AS Monaco', logo: '19830_AS_Monaco.png' },
    { id: '28998', name: 'Real Betis Balompié', logo: '684_Real_Betis_Balompié.png' },
    { id: '28879', name: 'Athletic Bilbao', logo: '678_Athletic_Bilbao.png' },
    { id: '5282', name: 'PSV Eindhoven', logo: 'logo_1534_PSV_Eindhoven.png' },
    { id: '65', name: 'Wolverhampton Wanderers', logo: 'logo_Wolverhampton_Wanderers.png' },
    { id: '67', name: 'West Ham United', logo: 'logo_1127_West_Ham_United.png' },
    { id: '32429', name: 'Eintracht Frankfurt', logo: 'logo_1268_Eintracht_Frankfurt.png' },
    { id: '3476', name: 'Sporting CP', logo: '9608_Sporting_CP.png' },
    { id: '64', name: 'Crystal Palace', logo: 'logo_1128_Crystal_Palace.png' },
    { id: '70', name: 'Brighton & Hove Albion', logo: 'logo_1135_Brighton_And_Hove_Albion.png' },
    { id: '20594', name: 'FK Krasnodar', logo: 'logo_2149_FK_Krasnodar.png' },
    { id: '11863', name: 'Shakhtar Donetsk', logo: 'logo_2135_Shakhtar_Donetsk.png' },
    { id: '6160', name: 'CSKA Moscow', logo: '13886_CSKA_Moskva.png' },
    { id: '27251', name: 'Clube de Regatas do Flamengo', logo: '5654_Clube_de_Regatas_do_Flamengo.png' },
    { id: '69', name: 'Watford FC', logo: '1644_Watford_FC.png' },
    { id: '9276', name: 'CF Monterrey', logo: 'logo_2267_CF_Monterrey.png' },
    { id: '13058', name: 'Feyenoord Rotterdam', logo: 'logo_1533_Feyenoord_Rotterdam.png' },
    { id: '31588', name: 'Rangers FC', logo: 'logo_2188_Rangers_FC.png', },
    { id: '7421', name: 'Granada CF', logo: 'logo_1732_Granada_CF.png' },
    { id: '14651', name: 'Stade Brest 29"', logo: '3803_Brest.png' },
    { id: '2459', name: 'Fenerbahce SK', logo: 'logo_2004_Fenerbahce_SK.png' },
    { id: '23168', name: '1. FC Köln', logo: 'logo_1266_1._FC_Koln.png' },
    { id: '36192', name: 'Tigres UANL', logo: '15417_Tigres_UANL.png' }
]

export const virtualDealDay9thJuneAttendees = [
    
    { id: '48', name: 'Manchester City', logo: 'logo_Manchester_City.png' },
    { id: '46', name: 'Liverpool', logo: 'logo_1123_Liverpool_FC.png' },
    { id: '45', name: 'Manchester United', logo: null }, //'logo_1120_Manchester_United.png'
    { id: '49', name: 'Arsenal FC', logo: 'logo_1121_Arsenal_FC.png' },
    { id: '10088', name: 'RB Leipzig', logo: 'logo_1261_RB_Leipzig.png' },
    { id: '63', name: 'Everton', logo: 'logo_1124_Everton_FC.png' },
    { id: '3', name: 'Juventus', logo: 'logo_1832_Juventus_FC.png' },
    { id: '38553', name: 'Aston Villa', logo: 'logo_1138_Aston_Villa.png' },
    { id: '65', name: 'Wolverhampton Wanderers', logo: 'logo_Wolverhampton_Wanderers.png' },
    { id: '60', name: 'Newcastle United', logo: 'logo_Newcastle_United.png' },
    { id: '70', name: 'Brighton & Hove Albion', logo: 'logo_1135_Brighton_And_Hove_Albion.png' },
    { id: '39176', name: 'Leeds United', logo: 'logo_1150_Leeds_United.png' },
    { id: '32429', name: 'Eintracht Frankfurt', logo: 'logo_1268_Eintracht_Frankfurt.png' },
    { id: '5282', name: 'PSV Eindhoven', logo: 'logo_1534_PSV_Eindhoven.png' },
    { id: '11', name: 'Atalanta BC', logo: '3172_Atalanta.png' },
    { id: '3476', name: 'Sporting CP', logo: '9608_Sporting_CP.png' },
    { id: '31367', name: 'Sheffield United', logo: 'logo_1159_Sheffield_United.png' },
    { id: '6160', name: 'CSKA Moscow', logo: '13886_CSKA_Moskva.png' },
    { id: '11863', name: 'Shakhtar Donetsk', logo: 'logo_2135_Shakhtar_Donetsk.png' },
    { id: '4230', name: 'Stade Reims', logo: '3802_Stade_Reims.png' },
    { id: '8239', name: 'Brentford', logo: 'logo_1153_Brentford_FC.png' },
    { id: '20594', name: 'FK Krasnodar', logo: 'logo_2149_FK_Krasnodar.png' },
    { id: '30997', name: 'Red Bull Salzburg', logo: 'logo_1942_Red_Bull_Salzburg.png' },
    { id: '28851', name: 'West Bromwich Albion', logo: '1627_West_Bromwich_Albion.png' },
    { id: '40356', name: 'Werder Bremen', logo: 'logo_1269_SV_Werder_Bremen.png' },
    { id: '29469', name: 'Dinamo Zagreb', logo: 'logo_2163_GNK_Dinamo_Zagreb.png', },
    { id: '69', name: 'Watford', logo: 'logo_Watford_FC.png' },
    { id: '21242', name: 'RC Lens', logo: 'logo_1983_RC_Lens.png' },
    { id: '9657', name: 'Los Angeles FC', logo: '62148_Los_Angeles_FC.png' },
    { id: '23168', name: '1. FC Köln', logo: 'logo_1266_1._FC_Koln.png' },
    { id: '28212', name: 'RC Strasbourg Alsace', logo: 'logo_1981_RC_Strasbourg_Alsace.png' },
    { id: '31588', name: 'Rangers FC', logo: 'logo_2188_Rangers_FC.png', },
    { id: '2459', name: 'Fenerbahce', logo: 'logo_2004_Fenerbahce_SK.png' },
    { id: '28414', name: 'Dinamo Moscow', logo: '13889_Dinamo_Moscow.png' },
    { id: '22514', name: 'CF América', logo: '15405_América.png' },
    { id: '2956', name: 'KAA Gent', logo: 'logo_1571_KAA_Gent.png' },
    { id: '16775', name: 'Reading', logo: 'logo_1147_Reading_FC.png' },
    { id: '35103', name: 'Standard Liège', logo: 'logo_1574_Standard_Liege.png' },
    { id: '39981', name: 'Clube Atlético Mineiro', logo: '5653_Atlético-MG.png' },
    { id: '26439', name: 'Deportivo Alavés', logo: 'logo_CD_Alaves.png' },
    { id: '3695', name: 'SK Slavia Prague', logo: 'logo_2051_SK_Slavia_Prag.png' },
    { id: '31484', name: 'Deportivo Guadalajara', logo: '15409_Deportivo_Guadalajara.png' },
    { id: '10088', name: 'RB Leipzig', logo: 'logo_1261_RB_Leipzig.png' },
    { id: '63', name: 'Everton', logo: 'logo_1124_Everton_FC.png' },
]

export const virtualSummit24thMarchAttendees = [
    { id: '3', name: 'Juventus', logo: 'logo_1832_Juventus_FC.png' },
    { id: '2', name: 'AS Roma', logo: 'logo_1835_AS_Roma.png' },
    { id: '46', name: 'Liverpool', logo: 'logo_1123_Liverpool_FC.png' },
    { id: '48', name: 'Manchester City', logo: 'logo_Manchester_City.png' },
    { id: '49', name: 'Arsenal FC', logo: 'logo_1121_Arsenal_FC.png' },
    { id: '18960', name: 'FC Porto', logo: 'logo_1672_FC_Porto.png' },
    { id: '8665', name: 'New York FC', logo: 'logo_2109_New_York_City_FC.png' },
    { id: '13058', name: 'Feyenoord Rotterdam', logo: 'logo_1533_Feyenoord_Rotterdam.png' },
    { id: '64', name: 'Crystal Palace', logo: 'logo_1128_Crystal_Palace.png' },
    { id: '10026', name: 'Los Angeles Galaxy', logo: '7847_Los_Angeles_Galaxy.png' },
    { id: '63', name: 'Everton', logo: 'logo_1124_Everton_FC.png' },
    { id: '70', name: 'Brighton & Hove Albion', logo: 'logo_1135_Brighton_And_Hove_Albion.png' },
    { id: '11863', name: 'Shakhtar Donetsk', logo: 'logo_2135_Shakhtar_Donetsk.png' },
    { id: '6160', name: 'CSKA Moscow', logo: '13886_CSKA_Moskva.png' },
    { id: '10088', name: 'RB Leipzig', logo: 'logo_1261_RB_Leipzig.png' },
    { id: '41692', name: 'Seattle Sounders', logo: 'logo_2106_Seattle_Sounders_FC.png' },
    { id: '31588', name: 'Rangers FC', logo: 'logo_2188_Rangers_FC.png', },
    { id: '25629', name: 'Anderlecht', logo: 'logo_RSC_Anderlecht.png' },
    { id: '27085', name: 'Nurnberg', logo: 'logo_1278_1.FC_Nuremberg.png' },
    { id: '1740', name: 'Amiens SC', logo: '3789_SC_Amiens.png' },
    { id: '35103', name: 'Standard Liège', logo: 'logo_1574_Standard_Liege.png' },
    { id: '15549', name: 'FC Basel 1893', logo: 'logo_1932_FC_Basel_1893.png', },
    { id: '17417', name: 'BSC Young Boys', logo: 'logo_1934_BSC_Young_Boys.png' },
    { id: '23168', name: '1. FC Köln', logo: 'logo_1266_1._FC_Koln.png' },
    { id: '39599', name: 'FC Midtjylland', logo: 'logo_FC_Midtjylland.png' },
    { id: '976', name: 'AS Monaco', logo: '19830_AS_Monaco.png' },
    { id: '43723', name: 'SK Slavia Praha', logo: 'logo_2051_SK_Slavia_Prag.png' },
    { id: '30586', name: 'Sparta Prague', logo: 'logo_2050_AC_Sparta_Praha.png' },
    { id: '4059', name: 'Nice', logo: 'logo_1965_OGC_Nice.png' },
    { id: '5180', name: 'Istanbul Basaksehir FK', logo: 'logo_2005_Medipol_Basaksehir.png' },
    { id: '10422', name: 'Vancouver Whitecaps FC', logo: '15015_Vancouver_Whitecaps_FC.png' },
    { id: '25519', name: 'Rapid Wien', logo: 'logo_1943_Rapid_Vienna.png' },
    { id: '33794', name: 'Sport Club Internacional', logo: 'logo_2611_Sport_Club_Internacional.png' },
    { id: '2459', name: 'Fenerbahce', logo: 'logo_2004_Fenerbahce_SK.png' },
    { id: '29178', name: 'Melbourne City FC', logo: 'logo_2473_Melbourne_City_FC.png' },
    { id: '2956', name: 'KAA Gent', logo: 'logo_1571_KAA_Gent.png' },
    { id: '4230', name: 'Stade Reims', logo: '3802_Stade_Reims.png' },
    { id: '22514', name: 'CF América', logo: '15405_América.png' },
    { id: '26367', name: 'Santos Laguna', logo: 'logo_2268_Santos_Laguna.png' },
    { id: '31484', name: 'Deportivo Guadalajara', logo: '15409_Deportivo_Guadalajara.png' },
    { id: '6306', name: 'Hibernian', logo: 'logo_Hibernian_FC.png' },
    { id: '34162', name: 'Austria Vienna', logo: 'logo_1944_Austria_Vienna.png' }
]

export const secondWinterDealDayAttendees = [
    { id: '71', name: 'AFC Bournemouth', logo: 'logo_1134_AFC_Bournemouth.png', },
    { id: '63', name: 'Everton', logo: 'logo_1124_Everton_FC.png' },
    { id: '3', name: 'Juventus FC', logo: 'logo_1832_Juventus_FC.png' },
    { id: '17788', name: 'Paris Saint-Germain', logo: 'logo_Paris_Saint-Germain.png' },
    { id: '66', name: 'Leicester City', logo: 'logo_Leicester_City.png' },
    { id: '10317', name: 'New York Red Bulls', logo: 'logo_2112_New_York_Red_Bulls.png' },
    { id: '17417', name: 'BSC Young Boys', logo: 'logo_1934_BSC_Young_Boys.png' },
    { id: '24794', name: 'Genk', logo: 'logo_1573_KRC_Genk.png' },
    { id: '70', name: 'Brighton & Hove Albion', logo: 'logo_1135_Brighton_And_Hove_Albion.png' },
    { id: '25629', name: 'Anderlecht', logo: 'logo_RSC_Anderlecht.png' },
    { id: '6160', name: 'CSKA Moscow', logo: '13886_CSKA_Moskva.png' },
    { id: '13058', name: 'Feyenoord Rotterdam', logo: 'logo_1533_Feyenoord_Rotterdam.png' },
    { id: '46', name: 'Liverpool', logo: 'logo_1123_Liverpool_FC.png' },
    { id: '48', name: 'Manchester City', logo: 'logo_Manchester_City.png' },
    { id: '2956', name: 'KAA Gent', logo: 'logo_1571_KAA_Gent.png' },
    { id: '5282', name: 'PSV Eindhoven', logo: 'logo_1534_PSV_Eindhoven.png' },
    { id: '4230', name: 'Stade Reims', logo: '3802_Stade_Reims.png' },
    { id: '40508', name: 'LASK', logo: '8754_LASK.png' },
    { id: '18960', name: 'FC Porto', logo: 'logo_1672_FC_Porto.png' },
    { id: '11863', name: 'Shakhtar Donetsk', logo: 'logo_2135_Shakhtar_Donetsk.png' },
    { id: '10026', name: 'Los Angeles Galaxy', logo: '7847_Los_Angeles_Galaxy.png' },
    { id: '31588', name: 'Rangers FC', logo: 'logo_2188_Rangers_FC.png', },
    { id: '8665', name: 'New York FC', logo: 'logo_2109_New_York_City_FC.png' },
    { id: '31205', name: 'SG Dynamo Dresden', logo: 'logo_1281_SG_Dynamo_Dresden.png' },
    { id: '17043', name: 'FC Tokyo', logo: 'logo_2416_FC_Tokyo.png' },
    { id: '15549', name: 'FC Basel 1893', logo: 'logo_1932_FC_Basel_1893.png', },
    { id: '39176', name: 'Leeds United', logo: 'logo_1150_Leeds_United.png' },
    { id: '1740', name: 'Amiens SC', logo: '3789_SC_Amiens.png' },
    { id: '33794', name: 'Sport Club Internacional', logo: 'logo_2611_Sport_Club_Internacional.png' },
    { id: '12359', name: 'Fortuna Sittard', logo: '44_Fortuna_Sittard.png' },
    { id: '34162', name: 'Austria Vienna', logo: 'logo_1944_Austria_Vienna.png' },
    { id: '15581', name: 'Lech Poznań', logo: 'logo_1595_Lech_Poznan.png' },
    { id: '1559', name: 'FC Cincinnati', logo: '37892_Cincinnati.png' },
    { id: '38328', name: 'D.C United', logo: 'logo_2118_D.C._United.png' },
    { id: '39648', name: 'New England Revolution', logo: 'logo_2111_New_England_Revolution.png' },
    { id: '30586', name: 'Sparta Prague', logo: 'logo_2050_AC_Sparta_Praha.png' },
    { id: '10158', name: 'FC Toulouse', logo: '3780_FC_Toulouse.png' },
    { id: '35187', name: 'Virtus Entella', logo: '3404_Virtus_Entella.png' },
    { id: '41692', name: 'Seattle Sounders', logo: 'logo_2106_Seattle_Sounders_FC.png' },
    { id: '23588', name: 'SC Heerenveen', logo: 'logo_1537_SC_Heerenveen.png' },
    { id: '65', name: 'Wolverhampton Wanderers', logo: 'logo_Wolverhampton_Wanderers.png' },
    { id: '35103', name: 'Standard Liège', logo: 'logo_1574_Standard_Liege.png' }
]







// --------------
// Notice!: Items below was used to create "secondWinterDealDayAttendees" array. Could be used in future as data base.

export const attendeesOld = [
    { id: '46', name: 'Liverpool', logo: 'logo_1123_Liverpool_FC.png' },
    { id: '70', name: 'Brighton & Hove Albion', logo: 'logo_1135_Brighton_And_Hove_Albion.png' },
    { id: '2956', name: 'KAA Gent', logo: 'logo_1571_KAA_Gent.png' },
    { id: '65', name: 'Wolverhampton Wanderers', logo: 'logo_Wolverhampton_Wanderers.png' },
    { id: '30997', name: 'Red Bull Salzburg', logo: 'logo_1942_Red_Bull_Salzburg.png' },
    { id: '63', name: 'Everton FC', logo: 'logo_1124_Everton_FC.png' },
    { id: '10026', name: 'Los Angeles Galaxy', logo: '7847_Los_Angeles_Galaxy.png' },
    { id: '2459', name: 'Fenerbahce SK', logo: 'logo_2004_Fenerbahce_SK.png' },
    { id: '48', name: 'Manchester City', logo: 'logo_Manchester_City.png' },
    { id: '17417', name: 'BSC Young Boys', logo: 'logo_1934_BSC_Young_Boys.png' },
    { id: '18001', name: 'Fortuna Düsseldorf', logo: 'logo_Fortuna_Dusseldorf.png' },
    { id: '29469', name: 'Dinamo Zagreb', logo: 'logo_2163_GNK_Dinamo_Zagreb.png', },
    { id: '3', name: 'Juventus FC', logo: 'logo_1832_Juventus_FC.png' },
    { id: '13058', name: 'Feyenoord Rotterdam', logo: 'logo_1533_Feyenoord_Rotterdam.png' },
    { id: '26232', name: 'FC Schalke 04', logo: 'logo_FC_Schalke_04.png' },
    { id: '23168', name: '1. FC Köln', logo: 'logo_1266_1._FC_Koln.png' },
    { id: '29178', name: 'Melbourne City FC', logo: 'logo_2473_Melbourne_City_FC.png' },
    { id: '64', name: 'Crystal Palace', logo: 'logo_1128_Crystal_Palace.png' },
    { id: '23588', name: 'SC Heerenveen', logo: 'logo_1537_SC_Heerenveen.png' },
    { id: '5282', name: 'PSV Eindhoven', logo: 'logo_1534_PSV_Eindhoven.png' },
    { id: '28998', name: 'Real Betis', logo: 'logo_Real_Betis_Balompie.png' },
    { id: '1064', name: 'Nîmes Olympique', logo: '3813_Nîmes_Olympique.png' },
    { id: '14821', name: 'FC Copenhagen', logo: 'logo_FC_Copenhagen.png' },
    { id: '21139', name: 'Olympiacos', logo: '12509_Olympiacos_Piraeus.png' },
    { id: '35103', name: 'Standard Liège', logo: 'logo_1574_Standard_Liege.png' },
    { id: '6160', name: 'CSKA Moscow', logo: '13886_CSKA_Moskva.png' },
    { id: '33906', name: 'FC Barcelona', logo: null }, //'logo_FC_Barcelona.png'
    { id: '60', name: 'Newcastle United', logo: 'logo_Newcastle_United.png' },
    { id: '71', name: 'AFC Bournemouth', logo: 'logo_1134_AFC_Bournemouth.png', },
    { id: '27230', name: 'CD América de Cali', logo: 'logo_2664_CD_America_de_Cali.png', },
    { id: '31588', name: 'Rangers FC', logo: 'logo_2188_Rangers_FC.png', },
    { id: '24711', name: 'Urawa Red Diamonds', logo: 'logo_2407_Urawa_Red_Diamonds.png' },
    { id: '40508', name: 'LASK', logo: '8754_LASK.png' },
    { id: '34374', name: 'Levski Sofia', logo: '9218_Levski_Sofia.png' },
    { id: '39176', name: 'Leeds United', logo: 'logo_1150_Leeds_United.png' },
    { id: '20454', name: 'Legia Warszawa', logo: 'logo_1594_Legia_Warszawa.png', },
    { id: '33648', name: 'Atlético Nacional', logo: '12391_Atlético_Nacional.png', },
    { id: '11863', name: 'Shakhtar Donetsk', logo: 'logo_2135_Shakhtar_Donetsk.png' },
    { id: '28212', name: 'RC Strasbourg Alsace', logo: 'logo_1981_RC_Strasbourg_Alsace.png' },
    { id: '15549', name: 'FC Basel 1893', logo: 'logo_1932_FC_Basel_1893.png', },
    { id: '17017', name: 'Philadelphia Union', logo: 'logo_2115_Philadelphia_Union.png' },
    { id: '4059', name: 'Nice', logo: 'logo_1965_OGC_Nice.png' },
]

export const madridAttendes = [
    { id: '33906', name: 'Barcelona', logo: null }, //'logo_FC_Barcelona.png'
    { id: '48', name: 'Manchester City', logo: 'logo_Manchester_City.png' },
    { id: '46', name: 'Liverpool', logo: 'logo_1123_Liverpool_FC.png' },
    { id: '12768', name: 'Atlético Madrid', logo: '679_Atlético_Madrid.png' },
    { id: '38580', name: 'Valencia', logo: 'logo_1715_Valencia_CF.png' },
    { id: '18833', name: 'SL Benfica', logo: 'logo_1673_SL_Benfica.png' },
    { id: '3', name: 'Juventus', logo: 'logo_1832_Juventus_FC.png' },
    { id: '2', name: 'AS Roma', logo: 'logo_1835_AS_Roma.png' },
    { id: '2226', name: 'Sevilla', logo: 'logo_1713_Sevilla_FC.png' },
    { id: '16', name: 'Napoli', logo: 'logo_SSC_Napoli.png' },
    { id: '28998', name: 'Real Betis', logo: 'logo_Real_Betis_Balompie.png' },
    { id: '50', name: 'Chelsea', logo: 'logo_Chelsea_FC.png' },
    { id: '13440', name: 'Boca Juniors', logo: '12057_Boca_Juniors.png' },
    { id: '15349', name: 'River Plate', logo: 'logo_2577_Club_Atletico_River_Plate.png' },
    { id: '6160', name: 'CSKA Moscow',  logo: '13886_CSKA_Moskva.png' },
    { id: '773', name: 'Getafe', logo: 'logo_1728_Getafe_CF.png' },
    { id: '64', name: 'Crystal Palace', logo: 'logo_1128_Crystal_Palace.png' }, 
    { id: '18960', name: 'FC Porto', logo: 'logo_1672_FC_Porto.png' }, 
    { id: '40356', name: 'Werder Bremen', logo: 'logo_1269_SV_Werder_Bremen.png' },
    

    { id: '25359', name: 'Celta Vigo', logo: 'logo_1718_Celta_de_Vigo.png' },
    { id: '21139', name: 'Olympiacos', logo: '12509_Olympiakos_Piraeus.png' },
    { id: '26056', name: 'AEK Athens', logo: 'logo_2023_AEK_Athens.png' },
    { id: '65', name: 'Wolverhampton', logo: 'logo_Wolverhampton_Wanderers.png' },
    { id: '60', name: 'Newcastle', logo: 'logo_Newcastle_United.png' },
    { id: '67', name: 'West Ham', logo: '1633_West_Ham_United.png' },
    { id: '2459', name: 'Fenerbahce', logo: 'logo_2004_Fenerbahce_SK.png' },
    { id: '30586', name: 'Sparta Prague', logo: 'logo_2050_AC_Sparta_Praha.png' },
    { id: '20454', name: 'Legia Warsaw', logo: 'logo_1594_Legia_Warszawa.png' },
    { id: '7972', name: 'LOSC Lille', logo: 'logo_1974_LOSC_Lille.png' },
    { id: '4059', name: 'Nice', logo: 'logo_1965_OGC_Nice.png' },
    { id: '70', name: 'Brighton', logo: 'logo_1135_Brighton_And_Hove_Albion.png' },
    { id: '850', name: 'Stoke', logo: 'logo_1129_Stoke_City.png' },
    { id: '71', name: 'Bournemouth', logo: 'logo_1134_AFC_Bournemouth.png' },
    { id: '62', name: 'Southampton', logo: 'logo_1125_Southampton_FC.png' },
    { id: '12761', name: 'Espanyol', logo: 'logo_1722_RCD_Espanyol_Barcelona.png' },
    { id: '26439', name: 'Deportivo Alavés', logo: 'logo_CD_Alaves.png' },
    { id: '7421', name: 'Granada', logo: 'logo_1732_Granada_CF.png' },
    { id: '19254', name: 'Fluminense', logo: '59046_Fluminense_SC.png' },


    { id: '5', name: 'Internacinale', logo: 'logo_1836_Inter_Milan.png' },
    { id: '69', name: 'Watford', logo: 'logo_Watford_FC.png' },
    { id: '31367', name: 'Sheffield United', logo: 'logo_1159_Sheffield_United.png' },
    { id: '6051', name: 'Real Valladolid', logo: 'logo_1735_Real_Valladolid_CF.png' },
    { id: '10744', name: 'Cruz Azul', logo: '15408_Cruz_Azul.png' },
    { id: '26367', name: 'Santos Laguna', logo: 'logo_2268_Santos_Laguna.png' },
    { id: '5282', name: 'PSV Eindhoven', logo: 'logo_1534_PSV_Eindhoven.png' },
    { id: '8', name: 'Sampdoria', logo: 'logo_1842_UC_Sampdoria.png' },
    { id: '22827', name: 'Hajduk Split', logo: 'logo_2165_HNK_Hajduk_Split.png' },
    { id: '10', name: 'Bologna', logo: 'logo_1845_Bologna_FC_1909.png' },
    { id: '18', name: 'SPAL', logo: 'logo_1850_SPAL_2013.png' },
    { id: '17', name: 'Genoa', logo: 'logo_1843_Genoa_CFC.png' },
    { id: '14821', name: 'FC Copenhagen', logo: 'logo_FC_Copenhagen.png' },
    { id: '25629', name: 'Anderlecht', logo: 'logo_RSC_Anderlecht.png' },
    { id: '33452', name: 'Real Sociedad', logo: 'logo_Real_Sociedad.png' },
    { id: '13058', name: 'Feyenoord', logo: 'logo_1533_Feyenoord_Rotterdam.png' },
    { id: '41692', name: 'Seattle Sounders', logo: 'logo_2106_Seattle_Sounders_FC.png' },
    { id: '38328', name: 'DC United', logo: 'logo_2118_D.C._United.png' },
    { id: '8665', name: 'New York FC', logo: 'logo_2109_New_York_City_FC.png' },

// 4th row
    { id: '27085', name: 'Nurnberg', logo: 'logo_1278_1.FC_Nuremberg.png' },
    { id: '23948', name: 'Zenit St. Petersburg', logo: '13885_Zenit_St._Petersburg.png' },
    { id: '22011', name: 'Puebla FC', logo: 'logo_2282_Puebla_FC.png' },
    { id: '25285', name: 'Levante UD', logo: 'logo_Levante_UD.png' },
    { id: '31172', name: 'HSC Montpellier', logo: '3787_Montpellier.png' },
    { id: '26232', name: 'FC Schalke 04', logo: 'logo_FC_Schalke_04.png' },
    { id: '22196', name: 'SpVgg Greuther Fürth', logo: 'logo_Greuther_Furth.jpg' },
    { id: '23588', name: 'SC Heerenveen', logo: 'logo_1537_SC_Heerenveen.png' },
    { id: '12470', name: 'Wolfsburg', logo: 'logo_VfL_Wolfsburg.png' }, 
    { id: '20', name: 'US Sassuolo', logo: '3315_Sassuolo.png' }, 
    { id: '30997', name: 'Red Bull Salzburg', logo: 'logo_1942_Red_Bull_Salzburg.png' }, 
    { id: '63', name: 'Everton', logo: 'logo_1124_Everton_FC.png' },
    { id: '29015', name: 'Lokomotiv Moscow', logo: '13887_Lokomotiv_Moscow.png' },
    { id: '5635', name: 'Dynamo Kyiv', logo: 'logo_2136_Dynamo_Kyiv.png' },
    { id: '5626', name: 'Enosis Neon Paralimniou', logo: 'logo_2961_Enosis_Neon_Paralimni.png' },
    { id: '22240', name: 'PAOK Thessaloniki', logo: 'logo_2021_PAOK_Thessaloniki.png' },
    { id: '3695', name: 'SK Slavia Prague', logo: 'logo_2051_SK_Slavia_Prag.png' },
    { id: '10026', name: 'Los Angeles Galaxy', logo: 'logo_2101_Los_Angeles_Galaxy.png' },
    { id: '1559', name: 'FC Cincinnati', logo: '37892_Cincinnati.png' },


    { id: '36694', name: 'APOEL Nicosa', logo: 'logo_2173_APOEL_Nicosia.png' },
    { id: '13073', name: 'Club Brugge', logo: 'logo_1572_Club_Brugge_KV.png' },
    { id: '2956', name: 'Gent', logo: 'logo_1571_KAA_Gent.png' },
    { id: '24794', name: 'Genk', logo: 'logo_1573_KRC_Genk.png' },
    { id: '39176', name: 'Leeds United', logo: 'logo_1150_Leeds_United.png' },
    { id: '29455', name: 'Nottingham Forrest', logo: 'logo_1152_Nottingham_Forest.png' },
    { id: '20887', name: 'Millwall', logo: 'logo_1160_Millwall_FC.png' },
    { id: '8239', name: 'Brentford', logo: 'logo_1153_Brentford_FC.png' },
    { id: '16775', name: 'Reading', logo: 'logo_1147_Reading_FC.png' },
    { id: '72', name: 'Huddersfield', logo: 'logo_1137_Huddersfield_Town.png' },
    { id: '13193', name: 'Girona', logo: 'logo_Girona_FC.png' },
    { id: '32802', name: 'Tenerife', logo: 'logo_1743_CD_Tenerife.png' },
    { id: '39333', name: 'Sporting Gijon', logo: 'logo_1730_Sporting_Gijon.png' },
    { id: '26867', name: 'Peñarol', logo: '15633_Peñarol.png' },
    { id: '174', name: 'Talleres de Cordoba', logo: 'logo_2588_Club_Atletico_Talleres.png' },
    { id: '7526', name: 'Lugo', logo: 'logo_1740_CD_Lugo.png' },
    { id: '33927', name: 'Almeria', logo: 'logo_UD_Almeria.png' },
    { id: '27244', name: 'Huesca', logo: 'logo_SD_Huesca.png' },
    { id: '13114', name: 'Real Zaragoza', logo: 'logo_1742_Real_Zaragoza.png' },


    { id: '3228', name: 'Real Oviedo', logo: 'logo_1737_Real_Oviedo.png' },
    { id: '5109', name: 'PEC Zwolle', logo: 'logo_1543_PEC_Zwolle.png' },
    { id: '9385', name: 'Sporting Kansas City', logo: 'logo_2121_Sporting_Kansas_City.png' },
    { id: '19', name: 'Frosinone', logo: 'logo_Frosinone_Calcio.png' },
    { id: '18894', name: 'Brøndby', logo: 'logo_1407_Brondby_IF.png' },
    { id: '12686', name: 'FC Nordsjælland', logo: '7458_Nordsjelland.png' },
    { id: '39599', name: 'FC Midtjylland', logo: 'logo_FC_Midtjylland.png' },
    { id: '28158', name: 'Aalborg', logo: 'logo_Aalborg_BK.png' },
    { id: '23752', name: 'Haugesund', logo: 'logo_1474_FK_Haugesund.png' },
    { id: '26457', name: 'Stabæk', logo: 'logo_1473_Stabaek_IF.png' },
    { id: '14430', name: 'Lillestrøm', logo: 'logo_Lillestrom_SK.png' },
    { id: '7819', name: 'Sarpsborg', logo: 'logo_1472_Sarpsborg_08_FF.png' },
    { id: '11087', name: 'Brann', logo: 'logo_SK_Brann.png' },
    { id: '12612', name: 'Hammarby', logo: 'logo_1441_Hammarby_IF.png' },
    { id: '6677', name: 'Östersunds', logo: 'logo_1439_Ostersunds_FK.png' },
    { id: '11544', name: 'Grasshoppers Zurich', logo: 'logo_1936_Grasshopper_Club_Zurich.png' },
    { id: '25519', name: 'Rapid Wien', logo: 'logo_1943_Rapid_Vienna.png' },
    { id: '1177', name: 'OFI Crete', logo: 'logo_2747_OFI_Crete.png' },
    { id: '42063', name: 'Újpest FC', logo: 'logo_1633_Ujpest_FC.png' },
]

export const londonAttendes = [
    { id: '33906', name: 'Barcelona', logo: null }, //'logo_FC_Barcelona.png'
    { id: '48', name: 'Manchester City', logo: 'logo_Manchester_City.png' },
    { id: '46', name: 'Liverpool', logo: 'logo_1123_Liverpool_FC.png' },
    { id: '40356', name: 'Werder Bremen', logo: 'logo_1269_SV_Werder_Bremen.png' },
    { id: '38580', name: 'Valencia', logo: 'logo_1715_Valencia_CF.png' },
    { id: '41692', name: 'Seattle Sounders', logo: 'logo_2106_Seattle_Sounders_FC.png' },
    { id: '3', name: 'Juventus', logo: 'logo_1832_Juventus_FC.png' },
    { id: '2', name: 'AS Roma', logo: 'logo_1835_AS_Roma.png' },
    { id: '2226', name: 'Sevilla', logo: 'logo_1713_Sevilla_FC.png' },
    { id: '16', name: 'Napoli', logo: 'logo_SSC_Napoli.png' },
    { id: '28998', name: 'Real Betis', logo: 'logo_Real_Betis_Balompie.png' },
    { id: '50', name: 'Chelsea', logo: 'logo_Chelsea_FC.png' },
    { id: '13440', name: 'Boca Juniors', logo: '12057_Boca_Juniors.png' },
    { id: '15349', name: 'River Plate', logo: 'logo_2577_Club_Atletico_River_Plate.png' },
    { id: '6160', name: 'CSKA Moscow',  logo: '13886_CSKA_Moskva.png' },
    { id: '773', name: 'Getafe', logo: 'logo_1728_Getafe_CF.png' },
    { id: '64', name: 'Crystal Palace', logo: 'logo_1128_Crystal_Palace.png' }, 
    { id: '18960', name: 'FC Porto', logo: 'logo_1672_FC_Porto.png' }, 
    { id: '27085', name: 'Nurnberg', logo: 'logo_1278_1.FC_Nuremberg.png' },
    { id: '25359', name: 'Celta Vigo', logo: 'logo_1718_Celta_de_Vigo.png' },
    { id: '21139', name: 'Olympiacos', logo: '12509_Olympiacos_Piraeus.png' },
    { id: '26056', name: 'AEK Athens', logo: 'logo_2023_AEK_Athens.png' },
    { id: '65', name: 'Wolverhampton', logo: 'logo_Wolverhampton_Wanderers.png' },
    { id: '60', name: 'Newcastle', logo: 'logo_Newcastle_United.png' },
    { id: '67', name: 'West Ham', logo: 'logo_1127_West_Ham_United.png' },
    { id: '2459', name: 'Fenerbahce', logo: 'logo_2004_Fenerbahce_SK.png' },
    { id: '30586', name: 'Sparta Prague', logo: 'logo_2050_AC_Sparta_Praha.png' },
    { id: '20454', name: 'Legia Warsaw', logo: 'logo_1594_Legia_Warszawa.png' },
    { id: '7972', name: 'LOSC Lille', logo: 'logo_1974_LOSC_Lille.png' },
    { id: '4059', name: 'Nice', logo: 'logo_1965_OGC_Nice.png' },
    { id: '70', name: 'Brighton', logo: 'logo_1135_Brighton_And_Hove_Albion.png' },
    { id: '850', name: 'Stoke', logo: 'logo_1129_Stoke_City.png' },
    { id: '71', name: 'Bournemouth', logo: 'logo_1134_AFC_Bournemouth.png' },
    { id: '62', name: 'Southampton', logo: 'logo_1125_Southampton_FC.png' },
    { id: '12761', name: 'Espanyol', logo: 'logo_1722_RCD_Espanyol_Barcelona.png' },
    { id: '26439', name: 'Deportivo Alavés', logo: 'logo_CD_Alaves.png' },
    { id: '7421', name: 'Granada', logo: 'logo_1732_Granada_CF.png' },
    { id: '19254', name: 'Fluminense', logo: '59046_Fluminense_SC.png' },
    { id: '5', name: 'Internacinale', logo: 'logo_1836_Inter_Milan.png' },
    { id: '69', name: 'Watford', logo: 'logo_Watford_FC.png' },
    { id: '31367', name: 'Sheffield United', logo: 'logo_1159_Sheffield_United.png' },
    { id: '6051', name: 'Real Valladolid', logo: 'logo_1735_Real_Valladolid_CF.png' },
    { id: '10744', name: 'Cruz Azul', logo: '15408_Cruz_Azul.png' },
    { id: '26367', name: 'Santos Laguna', logo: 'logo_2268_Santos_Laguna.png' },
    { id: '5282', name: 'PSV Eindhoven', logo: 'logo_1534_PSV_Eindhoven.png' },
    { id: '8', name: 'Sampdoria', logo: 'logo_1842_UC_Sampdoria.png' },
    { id: '22827', name: 'Hajduk Split', logo: 'logo_2165_HNK_Hajduk_Split.png' },
    { id: '10', name: 'Bologna', logo: 'logo_1845_Bologna_FC_1909.png' },
    { id: '18', name: 'SPAL', logo: 'logo_1850_SPAL_2013.png' },
    { id: '17', name: 'Genoa', logo: 'logo_1843_Genoa_CFC.png' },
    { id: '14821', name: 'FC Copenhagen', logo: 'logo_FC_Copenhagen.png' },
    { id: '25629', name: 'Anderlecht', logo: 'logo_RSC_Anderlecht.png' },
    { id: '33452', name: 'Real Sociedad', logo: 'logo_Real_Sociedad.png' },
    { id: '13058', name: 'Feyenoord', logo: 'logo_1533_Feyenoord_Rotterdam.png' },
    { id: '45', name: 'Manchester United', logo: null }, //'logo_1120_Manchester_United.png'
    { id: '38328', name: 'DC United', logo: 'logo_2118_D.C._United.png' },
    { id: '8665', name: 'New York FC', logo: 'logo_2109_New_York_City_FC.png' },
    { id: '36694', name: 'APOEL Nicosa', logo: 'logo_2173_APOEL_Nicosia.png' },
    { id: '13073', name: 'Club Brugge', logo: 'logo_1572_Club_Brugge_KV.png' },
    { id: '2956', name: 'Gent', logo: 'logo_1571_KAA_Gent.png' },
    { id: '24794', name: 'Genk', logo: 'logo_1573_KRC_Genk.png' },
    { id: '39176', name: 'Leeds United', logo: 'logo_1150_Leeds_United.png' },
    { id: '29455', name: 'Nottingham Forrest', logo: 'logo_1152_Nottingham_Forest.png' },
    { id: '20887', name: 'Millwall', logo: 'logo_1160_Millwall_FC.png' },
    { id: '8239', name: 'Brentford', logo: 'logo_1153_Brentford_FC.png' },
    { id: '16775', name: 'Reading', logo: 'logo_1147_Reading_FC.png' },
    { id: '72', name: 'Huddersfield', logo: 'logo_1137_Huddersfield_Town.png' },
    { id: '13193', name: 'Girona', logo: 'logo_Girona_FC.png' },
    { id: '32802', name: 'Tenerife', logo: 'logo_1743_CD_Tenerife.png' },
    { id: '39333', name: 'Sporting Gijon', logo: 'logo_1730_Sporting_Gijon.png' },
    { id: '26867', name: 'Peñarol', logo: '15633_Peñarol.png' },
    { id: '174', name: 'Talleres de Cordoba', logo: 'logo_2588_Club_Atletico_Talleres.png' },
    { id: '7526', name: 'Lugo', logo: 'logo_1740_CD_Lugo.png' },
    { id: '33927', name: 'Almeria', logo: 'logo_UD_Almeria.png' },
    { id: '27244', name: 'Huesca', logo: 'logo_SD_Huesca.png' },
    { id: '13114', name: 'Real Zaragoza', logo: 'logo_1742_Real_Zaragoza.png' },
    { id: '3228', name: 'Real Oviedo', logo: 'logo_1737_Real_Oviedo.png' },
    { id: '5109', name: 'PEC Zwolle', logo: 'logo_1543_PEC_Zwolle.png' },
    { id: '5159', name: 'Groningen', logo: 'logo_FC_Groningen.png' },
    { id: '19', name: 'Frosinone', logo: 'logo_Frosinone_Calcio.png' },
    { id: '18894', name: 'Brøndby', logo: 'logo_1407_Brondby_IF.png' },
    { id: '12686', name: 'FC Nordsjælland', logo: '7458_Nordsjelland.png' },
    { id: '39599', name: 'FC Midtjylland', logo: 'logo_FC_Midtjylland.png' },
    { id: '28158', name: 'Aalborg', logo: 'logo_Aalborg_BK.png' },
    { id: '23752', name: 'Haugesund', logo: 'logo_1474_FK_Haugesund.png' },
    { id: '26457', name: 'Stabæk', logo: 'logo_1473_Stabaek_IF.png' },
    { id: '14430', name: 'Lillestrøm', logo: 'logo_Lillestrom_SK.png' },
    { id: '7819', name: 'Sarpsborg', logo: 'logo_1472_Sarpsborg_08_FF.png' },
    { id: '11087', name: 'Brann', logo: 'logo_SK_Brann.png' },
    { id: '12612', name: 'Hammarby', logo: 'logo_1441_Hammarby_IF.png' },
    { id: '6677', name: 'Östersunds', logo: 'logo_1439_Ostersunds_FK.png' },
    { id: '11544', name: 'Grasshoppers Zurich', logo: 'logo_1936_Grasshopper_Club_Zurich.png' },
    { id: '25519', name: 'Rapid Wien', logo: 'logo_1943_Rapid_Vienna.png' },
    { id: '1177', name: 'OFI Crete', logo: 'logo_2747_OFI_Crete.png' },
]


export const attendees_4 = [
    { id: '63', name: 'Everton', logo: 'logo_1124_Everton_FC.png' },
    {
        id: '65',
        name: 'Wolverhampton Wanderers',
        logo: 'logo_Wolverhampton_Wanderers.png',
    },
    { id: '24794', name: 'Genk', logo: 'logo_1573_KRC_Genk.png' },
    { id: '26439', name: 'Deportivo Alavés', logo: 'logo_CD_Alaves.png' },
    { id: '15581', name: 'Lech Poznań', logo: 'logo_1595_Lech_Poznan.png' },
    {
        id: '20454',
        name: 'Legia Warszawa',
        logo: 'logo_1594_Legia_Warszawa.png',
    },
    { id: '25629', name: 'Anderlecht', logo: 'logo_RSC_Anderlecht.png' },
    { id: '48', name: 'Manchester City', logo: 'logo_Manchester_City.png' },
    { id: '4059', name: 'Nice', logo: 'logo_1965_OGC_Nice.png' },
    {
        id: '11544',
        name: 'Grasshopper',
        logo: 'logo_1936_Grasshopper_Club_Zurich.png',
    },
    { id: '26457', name: 'Stabæk', logo: 'logo_1473_Stabaek_IF.png' },
    {
        id: '21425',
        name: 'St. Gallen',
        logo: 'logo_1935_FC_St._Gallen_1879.png',
    },
    { id: '64', name: 'Crystal Palace', logo: 'logo_1128_Crystal_Palace.png' },
    { id: '32802', name: 'Tenerife', logo: 'logo_1743_CD_Tenerife.png' },
    { id: '3228', name: 'Real Oviedo', logo: 'logo_1737_Real_Oviedo.png' },
    { id: '1177', name: 'OFI', logo: 'logo_2747_OFI_Crete.png' },
    { id: '16210', name: 'AEL', logo: 'logo_2179_AEL_Limassol.png' },
    { id: '51', name: 'Burnley', logo: 'logo_1136_Burnley_FC.png' },
    { id: '42094', name: 'Bochum', logo: 'logo_1284_VfL_Bochum.png' },
    { id: '27244', name: 'Huesca', logo: 'logo_SD_Huesca.png' },
    { id: '32443', name: 'Badalona', logo: 'logo_1802_CF_Badalona.png' },
    { id: '33452', name: 'Real Sociedad', logo: 'logo_Real_Sociedad.png' },
    {
        id: '39333',
        name: 'Sporting Gijón',
        logo: 'logo_1730_Sporting_Gijon.png',
    },
    {
        id: '6863',
        name: 'Rayo Vallecano',
        logo: 'logo_1734_Rayo_Vallecano.png',
    },
    { id: '4474', name: 'Burgos', logo: 'logo_1781_Burgos_CF.png' },
    { id: '39599', name: 'Midtjylland', logo: 'logo_FC_Midtjylland.png' },
    { id: '18894', name: 'Brøndby', logo: 'logo_1407_Brondby_IF.png' },
    {
        id: '12686',
        name: 'Nordsjælland',
        logo: 'logo_1413_FC_Nordsjaelland.png',
    },
    { id: '12470', name: 'Wolfsburg', logo: 'logo_VfL_Wolfsburg.png' },
    { id: '6306', name: 'Hibernian', logo: 'logo_Hibernian_FC.png' },
    { id: '7427', name: 'Osijek', logo: 'logo_2166_NK_Osijek.png' },
    { id: '496', name: 'Rijeka', logo: 'logo_2164_HNK_Rijeka.png' },
    { id: '17668', name: 'Famalicão', logo: 'logo_FC_Famalicao.png' },
    { id: '38234', name: 'Sabadell', logo: 'logo_1800_CE_Sabadell.png' },
    {
        id: '70',
        name: 'Brighton & Hove Albion',
        logo: 'logo_1135_Brighton_And_Hove_Albion.png',
    },
    { id: '14430', name: 'Lillestrøm', logo: 'logo_Lillestrom_SK.png' },
    { id: '19138', name: 'Mallorca', logo: 'logo_RCD_Mallorca.png' },
    { id: '39176', name: 'Leeds United', logo: 'logo_1150_Leeds_United.png' },
    { id: '42063', name: 'Újpest', logo: 'logo_1633_Ujpest_FC.png' },
    { id: '41761', name: 'Leyton Orient', logo: 'logo_Leyton_Orient.png' },
    { id: '39309', name: 'Valletta', logo: 'logo_3540_Valletta_FC.png' },
    { id: '15280', name: 'Córdoba', logo: 'logo_1745_Cordoba_CF.png' },
    {
        id: '37893',
        name: 'Skënderbeu Korçë',
        logo: 'logo_2451_KF_Skenderbeu.png',
    },
    { id: '16042', name: 'Kukësi', logo: 'logo_2454_FK_Kukesi.png' },
    {
        id: '174',
        name: 'Talleres Córdoba',
        logo: 'logo_2588_Club_Atletico_Talleres.png',
    },
    { id: '11640', name: 'Cornellà', logo: 'logo_1799_UE_Cornella.png' },
    { id: '11717', name: 'MOL Vidi FC', logo: 'logo_1629_Videoton_FC.png' },
    { id: '33927', name: 'Almería', logo: 'logo_UD_Almeria.png' },
    {
        id: '38623',
        name: 'Pogoń Szczecin',
        logo: 'logo_1599_Pogon_Szczecin.png',
    },
    { id: '427', name: 'SV Roeselare', logo: 'logo_1588_KSV_Roeselare.png' },
    {
        id: '9794',
        name: 'Zagłębie Sosnowiec',
        logo: 'logo_1612_Zaglebie_Sosnowiec.png',
    },
    {
        id: '6051',
        name: 'Real Valladolid',
        logo: 'logo_1735_Real_Valladolid_CF.png',
    },
    {
        id: '71',
        name: 'AFC Bournemouth',
        logo: 'logo_1134_AFC_Bournemouth.png',
    },
    { id: '26056', name: 'AEK Athens', logo: 'logo_2023_AEK_Athens.png' },
    { id: '2', name: 'Roma', logo: 'logo_1835_AS_Roma.png' },
    {
        id: '22827',
        name: 'Hajduk Split',
        logo: 'logo_2165_HNK_Hajduk_Split.png',
    },
];

export const attendees_3 = [
    { id: '39176', name: 'Leeds United', logo: 'logo_1150_Leeds_United.png' },
    { id: '48', name: 'Manchester City', logo: 'logo_Manchester_City.png' },
    { id: '3', name: 'Juventus', logo: 'logo_1832_Juventus_FC.png' },
    {
        id: '65',
        name: 'Wolverhampton Wanderers',
        logo: 'logo_Wolverhampton_Wanderers.png',
    },
    { id: '46', name: 'Liverpool', logo: 'logo_1123_Liverpool_FC.png' },
    {
        id: '67',
        name: 'West Ham United',
        logo: 'logo_1127_West_Ham_United.png',
    },

    { id: '2226', name: 'Sevilla', logo: 'logo_1713_Sevilla_FC.png' },
    { id: '2', name: 'Roma', logo: 'logo_1835_AS_Roma.png' },

    { id: '8239', name: 'Brentford', logo: 'logo_1153_Brentford_FC.png' },

    { id: '60', name: 'Newcastle United', logo: 'logo_Newcastle_United.png' },
    {
        id: '24698',
        name: 'Cambridge United',
        logo: 'logo_1201_Cambridge_United.png',
    },
    { id: '50', name: 'Chelsea', logo: 'logo_Chelsea_FC.png' },

    { id: '62', name: 'Southampton', logo: 'logo_1125_Southampton_FC.png' },

    { id: '26191', name: 'Bury', logo: 'logo_1168_Bury_FC.png' },
    { id: '15581', name: 'Lech Poznań', logo: 'logo_1595_Lech_Poznan.png' },
    {
        id: '20454',
        name: 'Legia Warszawa',
        logo: 'logo_1594_Legia_Warszawa.png',
    },
    { id: '32843', name: 'Shanghai SIPG', logo: 'logo_1640_Shanghai_SIPG.png' },
    {
        id: '11544',
        name: 'Grasshopper',
        logo: 'logo_1936_Grasshopper_Club_Zurich.png',
    },
    { id: '26457', name: 'Stabæk', logo: 'logo_1473_Stabaek_IF.png' },
    {
        id: '21425',
        name: 'St. Gallen',
        logo: 'logo_1935_FC_St._Gallen_1879.png',
    },
    { id: '33452', name: 'Real Sociedad', logo: 'logo_Real_Sociedad.png' },
    { id: '39599', name: 'Midtjylland', logo: 'logo_FC_Midtjylland.png' },
    {
        id: '12686',
        name: 'Nordsjælland',
        logo: 'logo_1413_FC_Nordsjaelland.png',
    },
    { id: '6306', name: 'Hibernian', logo: 'logo_Hibernian_FC.png' },
    { id: '7427', name: 'Osijek', logo: 'logo_2166_NK_Osijek.png' },
    { id: '496', name: 'Rijeka', logo: 'logo_2164_HNK_Rijeka.png' },
    {
        id: '70',
        name: 'Brighton & Hove Albion',
        logo: 'logo_1135_Brighton_And_Hove_Albion.png',
    },
    { id: '14430', name: 'Lillestrøm', logo: 'logo_Lillestrom_SK.png' },
    { id: '42063', name: 'Újpest', logo: 'logo_1633_Ujpest_FC.png' },

    { id: '41761', name: 'Leyton Orient', logo: 'logo_Leyton_Orient.png' },
    { id: '11717', name: 'MOL Vidi FC', logo: 'logo_1629_Videoton_FC.png' },
    {
        id: '32987',
        name: 'Bristol Rovers',
        logo: 'logo_1184_Bristol_Rovers.png',
    },
    {
        id: '71',
        name: 'AFC Bournemouth',
        logo: 'logo_1134_AFC_Bournemouth.png',
    },
    { id: '2956', name: 'Gent', logo: 'logo_1571_KAA_Gent.png' },
    { id: '16775', name: 'Reading', logo: 'logo_1147_Reading_FC.png' },
    {
        id: '6051',
        name: 'Real Valladolid',
        logo: 'logo_1735_Real_Valladolid_CF.png',
    },
    { id: '20887', name: 'Millwall', logo: 'logo_1160_Millwall_FC.png' },
    { id: '40409', name: 'Oxford United', logo: 'logo_1166_Oxford_United.png' },
    {
        id: '15872',
        name: 'Wigan Athletic',
        logo: 'logo_1163_Wigan_Athletic.png',
    },
    { id: '3740', name: 'Kortrijk', logo: 'logo_KV_Kortrijk.png' },
    { id: '14821', name: 'København', logo: 'logo_FC_Copenhagen.png' },
    {
        id: '7354',
        name: 'Forest Green Rovers',
        logo: 'logo_Forest_Green_Rovers.png',
    },
    {
        id: '29469',
        name: 'Dinamo Zagreb',
        logo: 'logo_2163_GNK_Dinamo_Zagreb.png',
    },
    {
        id: '38623',
        name: 'Pogoń Szczecin',
        logo: 'logo_1599_Pogon_Szczecin.png',
    },
    {
        id: '5306',
        name: 'Raków Częstochowa',
        logo: 'logo_1622_Rakow_Czestochowa.png',
    },
    { id: '31136', name: 'Heracles', logo: 'logo_1542_Heracles_Almelo.png' },
    {
        id: '29529',
        name: 'Cheltenham Town',
        logo: 'logo_1197_Cheltenham_Town.png',
    },
    {
        id: '30586',
        name: 'Sparta Praha',
        logo: 'logo_2050_AC_Sparta_Praha.png',
    },
    { id: '12758', name: 'Tubize', logo: 'logo_1593_AFC_Tubize.png' },
    {
        id: '6249',
        name: 'Bayer Leverkusen',
        logo: 'logo_1260_Bayer_04_Leverkusen.png',
    },
    { id: '12139', name: 'Honvéd', logo: 'logo_1632_Budapest_Honved_FC.png' },
    {
        id: '29529',
        name: 'Cheltenham Town',
        logo: 'logo_1197_Cheltenham_Town.png',
    },
    { id: '13073', name: 'Club Brugge', logo: 'logo_1572_Club_Brugge_KV.png' },
    { id: '14958', name: 'Coventry City', logo: 'logo_1188_Coventry_City.png' },
    { id: '38328', name: 'DC United', logo: 'logo_2118_D.C._United.png' },
    { id: '5626', name: 'Enosis', logo: 'logo_2961_Enosis_Neon_Paralimni.png' },
];

export const attendeesMadridNovember = [
    { id: '3', name: 'Juventus', logo: 'logo_1832_Juventus_FC.png' },
    { id: '48', name: 'Manchester City', logo: 'logo_Manchester_City.png' },
    { id: '18833', name: 'SL Benfica', logo: 'logo_1673_SL_Benfica.png' },
    { id: '46', name: 'Liverpool', logo: 'logo_1123_Liverpool_FC.png' },
    { id: '12768', name: 'Atlético Madrid', logo: '679_Atlético_Madrid.png' },
    { id: '26232', name: 'FC Schalke 04', logo: 'logo_FC_Schalke_04.png' },

    { id: '2226', name: 'Sevilla', logo: 'logo_1713_Sevilla_FC.png' },
    { id: '38580', name: 'Valencia', logo: 'logo_1715_Valencia_CF.png' },
    { id: '47', name: 'Tottenham Hotspur', logo: 'logo_1122_Tottenham_Hotspur.png' },
    { id: '18960', name: 'FC Porto', logo: 'logo_1672_FC_Porto.png' },
    { id: '65', name: 'Wolverhampton Wanderers', logo: 'logo_Wolverhampton_Wanderers.png' },
    { id: '23948', name: 'Zenit St. Petersburg', logo: '13885_Zenit_St._Petersburg.png' },

    { id: '11', name: 'Atalanta BC', logo: '3172_Atalanta.png' },
    { id: '12470', name: 'Wolfsburg', logo: 'logo_VfL_Wolfsburg.png' },
    { id: '5282', name: 'PSV Eindhoven', logo: 'logo_1534_PSV_Eindhoven.png' },
    { id: '63', name: 'Everton', logo: 'logo_1124_Everton_FC.png' },
    { id: '62', name: 'Southampton', logo: 'logo_1125_Southampton_FC.png' },
    { id: '8665', name: 'New York FC', logo: 'logo_2109_New_York_City_FC.png' },

    { id: '6160', name: 'CSKA Moscow', logo: '13886_CSKA_Moskva.png' },
    { id: '2459', name: 'Fenerbahce', logo: 'logo_2004_Fenerbahce_SK.png' },
    { id: '21139', name: 'Olympiacos', logo: '12509_Olympiacos_Piraeus.png' },
    { id: '20454', name: 'Legia Warsaw', logo: 'logo_1594_Legia_Warszawa.png' },
    { id: '10026', name: 'Los Angeles Galaxy', logo: 'logo_2101_Los_Angeles_Galaxy.png' },
    { id: '5635', name: 'Dynamo Kyiv', logo: 'logo_2136_Dynamo_Kyiv.png' },

    { id: '25629', name: 'Anderlecht', logo: 'logo_RSC_Anderlecht.png' },
    { id: '70', name: 'Brighton & Hove Albion', logo: 'logo_1135_Brighton_And_Hove_Albion.png' },
    { id: '18001', name: 'Fortuna Düsseldorf', logo: 'logo_Fortuna_Dusseldorf.png' },
    { id: '60', name: 'Newcastle United', logo: 'logo_Newcastle_United.png' },
    { id: '31367', name: 'Sheffield United', logo: 'logo_1159_Sheffield_United.png' },
    { id: '13058', name: 'Feyenoord', logo: 'logo_1533_Feyenoord_Rotterdam.png' },

    { id: '29015', name: 'Lokomotiv Moscow', logo: '13887_Lokomotiv_Moscow.png' },
    { id: '25285', name: 'Levante UD', logo: 'logo_Levante_UD.png' },
    { id: '28998', name: 'Real Betis', logo: 'logo_Real_Betis_Balompie.png' },
    { id: '38328', name: 'DC United', logo: 'logo_2118_D.C._United.png' },
    { id: '14821', name: 'FC Copenhagen', logo: 'logo_FC_Copenhagen.png' },
    { id: '21242', name: 'RC Lens', logo: 'logo_1983_RC_Lens.png' },

    { id: '20', name: 'US Sassuolo', logo: '3315_Sassuolo.png' },
    { id: '1559', name: 'FC Cincinnati', logo: '37892_Cincinnati.png' },
    { id: '22827', name: 'Hajduk Split', logo: 'logo_2165_HNK_Hajduk_Split.png' },
    { id: '38291', name: 'São Paulo Futebol Clube', logo: '5638_São_Paulo.png' },
    { id: '31172', name: 'HSC Montpellier', logo: '3787_Montpellier.png' },
    { id: '30586', name: 'Sparta Prague', logo: 'logo_2050_AC_Sparta_Praha.png' },

]

// Old and useless
export const attendeesMadridNovemberOld = [
    { id: '46', name: 'Liverpool', logo: 'logo_1123_Liverpool_FC.png' },
    { id: '3', name: 'Juventus', logo: 'logo_1832_Juventus_FC.png' },
    { id: '2226', name: 'Sevilla', logo: 'logo_1713_Sevilla_FC.png' },
    { id: '65', name: 'Wolverhampton Wanderers', logo: 'logo_Wolverhampton_Wanderers.png' },
    { id: '12470', name: 'Wolfsburg', logo: 'logo_VfL_Wolfsburg.png' },
    { id: '63', name: 'Everton', logo: 'logo_1124_Everton_FC.png' },
    { id: '39333', name: 'Sporting Gijón', logo: 'logo_1730_Sporting_Gijon.png' },
    { id: '38328', name: 'DC United', logo: 'logo_2118_D.C._United.png' },
    { id: '60', name: 'Newcastle United', logo: 'logo_Newcastle_United.png' },
    { id: '64', name: 'Crystal Palace', logo: 'logo_1128_Crystal_Palace.png' },
    { id: '70', name: 'Brighton & Hove Albion', logo: 'logo_1135_Brighton_And_Hove_Albion.png' },
    { id: '6863', name: 'Rayo Vallecano', logo: 'logo_1734_Rayo_Vallecano.png' },
    { id: '6160', name: 'CSKA Moscow', logo: '13886_CSKA_Moskva.png' },
    { id: '39176', name: 'Leeds United', logo: 'logo_1150_Leeds_United.png' },
    { id: '20454', name: 'Legia Warsaw', logo: 'logo_1594_Legia_Warszawa.png' },
    { id: '21139', name: 'Olympiacos', logo: '12509_Olympiacos_Piraeus.png' },
    { id: '13114', name: 'Real Zaragoza', logo: 'logo_1742_Real_Zaragoza.png' },
    { id: '12686', name: 'FC Nordsjælland', logo: '7458_Nordsjelland.png' },
    { id: '23752', name: 'Haugesund', logo: 'logo_1474_FK_Haugesund.png' },
    { id: '27085', name: 'Nurnberg', logo: 'logo_1278_1.FC_Nuremberg.png' },
    { id: '8665', name: 'New York FC', logo: 'logo_2109_New_York_City_FC.png' },
    { id: '8239', name: 'Brentford', logo: 'logo_1153_Brentford_FC.png' },
    { id: '4059', name: 'Nice', logo: 'logo_1965_OGC_Nice.png' },
    { id: '26056', name: 'AEK Athens', logo: 'logo_2023_AEK_Athens.png' },
    { id: '32802', name: 'Tenerife', logo: 'logo_1743_CD_Tenerife.png' },
    { id: '14430', name: 'Lillestrøm', logo: 'logo_Lillestrom_SK.png' },
    { id: '496', name: 'Rijeka', logo: 'logo_2164_HNK_Rijeka.png' },
    { id: '7819', name: 'Sarpsborg', logo: 'logo_1472_Sarpsborg_08_FF.png' },
    { id: '26457', name: 'Stabæk', logo: 'logo_1473_Stabaek_IF.png' },
    { id: '41761', name: 'Leyton Orient', logo: 'logo_Leyton_Orient.png' },
    { id: '16042', name: 'Kukësi', logo: 'logo_2454_FK_Kukesi.png' },
    { id: '32987', name: 'Bristol Rovers', logo: 'logo_1184_Bristol_Rovers.png' },
    { id: '11087', name: 'Brann', logo: 'logo_SK_Brann.png' },
    { id: '9794', name: 'Zagłębie Sosnowiec', logo: 'logo_1612_Zaglebie_Sosnowiec.png' },
    { id: '42063', name: 'Újpest', logo: 'logo_1633_Ujpest_FC.png' },
    { id: '21425', name: 'St. Gallen', logo: 'logo_1935_FC_St._Gallen_1879.png' },
    { id: '12139', name: 'Honvéd', logo: 'logo_1632_Budapest_Honved_FC.png' },
    { id: '72', name: 'Huddersfield', logo: 'logo_1137_Huddersfield_Town.png' },
    { id: '22827', name: 'Hajduk Split', logo: 'logo_2165_HNK_Hajduk_Split.png' },
    { id: '31367', name: 'Sheffield United', logo: 'logo_1159_Sheffield_United.png' },
    { id: '12612', name: 'Hammarby', logo: 'logo_1441_Hammarby_IF.png' },
    { id: '39599', name: 'Midtjylland', logo: 'logo_FC_Midtjylland.png' },
    { id: '7421', name: 'Granada', logo: 'logo_1732_Granada_CF.png' },
    { id: '6051', name: 'Real Valladolid', logo: 'logo_1735_Real_Valladolid_CF.png' },
    { id: '48', name: 'Manchester City', logo: 'logo_Manchester_City.png' },
    { id: '25629', name: 'Anderlecht', logo: 'logo_RSC_Anderlecht.png' },
    { id: '11544', name: 'Grasshopper', logo: 'logo_1936_Grasshopper_Club_Zurich.png' },
    { id: '5626', name: 'Enosis', logo: 'logo_2961_Enosis_Neon_Paralimni.png' },
    { id: '18894', name: 'Brøndby', logo: 'logo_1407_Brondby_IF.png' },
    { id: '3228', name: 'Real Oviedo', logo: 'logo_1737_Real_Oviedo.png' },
    { id: '38623', name: 'Pogoń Szczecin', logo: 'logo_1599_Pogon_Szczecin.png' },
    { id: '27244', name: 'Huesca', logo: 'logo_SD_Huesca.png' },
    { id: '5', name: 'Internacinale', logo: 'logo_1836_Inter_Milan.png' },
    { id: '62', name: 'Southampton', logo: 'logo_1125_Southampton_FC.png' },
    { id: '5282', name: 'PSV Eindhoven', logo: 'logo_1534_PSV_Eindhoven.png' },
    { id: '174', name: 'Talleres Córdoba', logo: 'logo_2588_Club_Atletico_Talleres.png' }
]


export const virtualSummitAttendees = [
    { id: '28998', name: 'Real Betis Balompié', logo: '684_Real_Betis_Balompié.png' },
    { id: '496', name: 'HNK Rijeka', logo: 'logo_2164_HNK_Rijeka.png' },
    { id: '7421', name: 'Granada CF', logo: 'logo_1732_Granada_CF.png' },
    { id: '23352', name: 'Stade Rennais FC', logo: 'logo_Stade_Rennes.png' },
    { id: '26457', name: 'Stabæk Fotball', logo: 'logo_1473_Stabaek_IF.png' },
    { id: '6863', name: 'Rayo Vallecano', logo: '713_Rayo_Vallecano.png' },
    { id: '29178', name: 'Melbourne City FC', logo: 'logo_2473_Melbourne_City_FC.png' },
    { id: '7927', name: 'Dinamo Minsk', logo: '10082_Dinamo_Minsk.png' },
    { id: '3209', name: 'FK Ufa', logo: '14095_FK_Ufa.png' },
    { id: '12686', name: 'FC Nordsjaelland', logo: '7458_Nordsjelland.png' },
    { id: '3695', name: 'SK Slavia Prague', logo: 'logo_2051_SK_Slavia_Prag.png' },
    { id: '8498', name: 'Odense Boldklub', logo: 'logo_1412_Odense_Boldklub.png' },
    { id: '9073', name: 'Krylya Sovetov Samara', logo: '13891_Krylya_Sovetov_Samara.png' },
    { id: '35103', name: 'Standard Liège', logo: 'logo_1574_Standard_Liege.png' },
    { id: '50', name: 'Chelsea FC', logo: 'logo_Chelsea_FC.png' },
    { id: '30849', name: 'Atlanta United FC', logo: 'logo_2103_Atlanta_United_FC.png' },
    { id: '30997', name: 'Red Bull Salzburg', logo: 'logo_1942_Red_Bull_Salzburg.png' },
    { id: '49', name: 'Arsenal FC', logo: 'logo_1121_Arsenal_FC.png' },
    { id: '13404', name: 'Houston Dynamo', logo: 'logo_2120_Houston_Dynamo.png' },
    { id: '40692', name: 'Columbus Crew SC', logo: 'logo_2116_Columbus_Crew_SC.png' },
    { id: '17017', name: 'Philadelphia Union', logo: 'logo_2115_Philadelphia_Union.png' },
    { id: '3', name: 'Juventus FC', logo: 'logo_1832_Juventus_FC.png' },
    { id: '21242', name: 'RC Lens', logo: 'logo_1983_RC_Lens.png' },
    { id: '5972', name: 'San Jose Earthquakes', logo: '7851_San_Jose_Earthquakes.png' },
    { id: '63', name: 'Everton FC', logo: 'logo_1124_Everton_FC.png' },
    { id: '12612', name: 'Hammarby IF', logo: '6710_Hammarby_IF.png' },
    { id: '41956', name: 'Deportivo Cali', logo: 'logo_2667_Deportivo_Cali.png' },
    { id: '5121', name: 'Minnesota United FC', logo: 'logo_2122_Minnesota_United_FC.png' },
    { id: '38580', name: 'Valencia CF', logo: 'logo_1715_Valencia_CF.png' },
    { id: '60', name: 'Newcastle United', logo: 'logo_Newcastle_United.png' },
    { id: '32987', name: 'Bristol Rovers', logo: 'logo_1184_Bristol_Rovers.png' },
    { id: '31367', name: 'Sheffield United', logo: 'logo_1159_Sheffield_United.png' },
    { id: '7845', name: 'CF Pachuca', logo: 'logo_2276_CF_Pachuca.png' },
    { id: '31138', name: 'Club León FC', logo: '15424_Club_León_FC.png' },
    { id: '8239', name: 'Brentford FC', logo: 'logo_1153_Brentford_FC.png' },
    { id: '70', name: 'Brighton & Hove Albion', logo: 'logo_Brighton_And_Hove_Albion_FC.png' },
    { id: '48', name: 'Manchester City', logo: '1625_Manchester_City.png' },
    { id: '11544', name: 'Grasshopper Club Zurich', logo: 'logo_1936_Grasshopper_Club_Zurich.png' },
    { id: '14821', name: 'FC Copenhagen', logo: 'logo_FC_Copenhagen.png' },
    { id: '18960', name: 'FC Porto', logo: 'logo_1672_FC_Porto.png' },
    { id: '26232', name: 'FC Schalke 04', logo: 'logo_FC_Schalke_04.png' },
    { id: '4673', name: 'Portsmouth FC', logo: 'logo_Portsmouth_FC.png' },
]


export const vsSummit = [
    { id: '49', name: 'Arsenal FC', logo: 'logo_1121_Arsenal_FC.png' },
    { id: '674', name: 'Valencia CF', logo: 'logo_1715_Valencia_CF.png' },
    { id: '6160', name: 'CSKA Moscow', logo: '13886_CSKA_Moskva.png' },
    { id: '41692', name: 'Seattle Sounders FC', logo: 'logo_2106_Seattle_Sounders_FC.png' },
    { id: '34388', name: 'Rubin Kazan', logo: '13895_Rubin_Kazan.png' },
    { id: '23948', name: 'Zenit St. Petersburg', logo: '13885_Zenit_St._Petersburg.png' },
    { id: '63', name: 'Everton FC', logo: 'logo_1124_Everton_FC.png' },
    { id: '39176', name: 'Leeds United', logo: 'logo_1150_Leeds_United.png' },
    { id: '28414', name: 'Dinamo Moscow', logo: '13889_Dinamo_Moscow.png' },
    { id: '27251', name: 'Clube de Regatas do Flamengo', logo: '5654_Clube_de_Regatas_do_Flamengo.png' },
    { id: '17668', name: 'FC Famalicao', logo: 'logo_FC_Famalicao.png' },
    { id: '23352', name: 'Stade Rennais FC', logo: 'logo_Stade_Rennes.png' },
    { id: '48', name: 'Manchester City', logo: '1625_Manchester_City.png' },
    { id: '50', name: 'Chelsea FC', logo: 'logo_Chelsea_FC.png' },
    { id: '43723', name: 'SK Slavia Praha', logo: 'logo_2051_SK_Slavia_Prag.png' },
    { id: '18960', name: 'FC Porto', logo: 'logo_1672_FC_Porto.png' },
    { id: '35103', name: 'Standard Liège', logo: 'logo_1574_Standard_Liege.png' },
    { id: '26232', name: 'FC Schalke 04', logo: 'logo_FC_Schalke_04.png' },
    { id: '27287', name: 'Vitória Guimarães SC', logo: 'logo_1676_Vitoria_Guimaraes_SC.png' },
    { id: '9276', name: 'CF Monterrey', logo: 'logo_2267_CF_Monterrey.png' },
    { id: '36192', name: 'Tigres UANL', logo: '15417_Tigres_UANL.png' },
    { id: '30849', name: 'Atlanta United FC', logo: 'logo_2103_Atlanta_United_FC.png' },
    { id: '40498', name: 'Swansea City', logo: 'logo_1131_Swansea_City.png' },
    { id: '14821', name: 'FC Copenhagen', logo: 'logo_FC_Copenhagen.png' },
    { id: '23168', name: '1. FC Koln', logo: 'logo_1266_1._FC_Koln.png' },
    { id: '13058', name: 'Feyenoord Rotterdam', logo: 'logo_1533_Feyenoord_Rotterdam.png' },
    { id: '15581', name: 'Lech Poznan', logo: 'logo_1595_Lech_Poznan.png' },
    { id: '20454', name: 'Legia Warszawa', logo: 'logo_1594_Legia_Warszawa.png' },
    { id: '13404', name: 'Houston Dynamo', logo: 'logo_2120_Houston_Dynamo.png' },
    { id: '17417', name: 'BSC Young Boys', logo: 'logo_1934_BSC_Young_Boys.png' },
    { id: '64', name: 'Crystal Palace', logo: 'logo_1128_Crystal_Palace.png' },
    { id: '41646', name: 'FC Dallas', logo: 'logo_2113_FC_Dallas.png' },
    { id: '2459', name: 'Fenerbahce SK', logo: 'logo_2004_Fenerbahce_SK.png' },
    { id: '7845', name: 'CF Pachuca', logo: 'logo_2276_CF_Pachuca.png' },
    { id: '31367', name: 'Sheffield United', logo: 'logo_1159_Sheffield_United.png' },
    { id: '15821', name: 'Arminia Bielefeld', logo: 'logo_Arminia_Bielefeld.png' },
    { id: '7927', name: 'Dinamo Minsk', logo: '10082_Dinamo_Minsk.png' },
    { id: '3', name: 'Juventus FC', logo: 'logo_1832_Juventus_FC.png' },
    { id: '41751', name: 'Maccabi Haifa', logo: 'logo_2234_Maccabi_Haifa.png' },
    { id: '60', name: 'Newcastle United', logo: 'logo_Newcastle_United.png' },
    { id: '67', name: 'West Ham United', logo: '1633_West_Ham_United.png' },
    { id: '22240', name: 'PAOK', logo: 'logo_2021_PAOK_Thessaloniki.png' },
    // { id: '10026', name: 'Los Angeles Galaxy', logo: '7847_Los_Angeles_Galaxy.png' },
    // { id: '38328', name: 'D.C United', logo: 'logo_2118_D.C._United.png' },
    // { id: '31484', name: 'Deportivo Guadalajara', logo: '15409_Deportivo_Guadalajara.deportivo_guadalajara' },
    // { id: '31172', name: 'HSC Montpellier', logo: '3787_Montpellier.png' },
    // { id: '4059', name: 'OGC Nice', logo: 'logo_1965_OGC_Nice.png' },
    // { id: '26540', name: 'Orlando City SC', logo: '8068_Orlando_City_SC.png' },
]

export const vsDealDay = [
    { id: '65', name: 'Wolverhampton Wanderers', logo: 'logo_Wolverhampton_Wanderers.png' },
    { id: '23948', name: 'Zenit St. Petersburg', logo: '13885_Zenit_St._Petersburg.png' },
    { id: '67', name: 'West Ham United', logo: '1633_West_Ham_United.png' },
    { id: '16', name: 'SSC Napoli', logo: 'logo_SSC_Napoli.png' },
    { id: '6', name: 'SS Lazio', logo: 'logo_1837_SS_Lazio.png' },
    { id: '35103', name: 'Standard Liège', logo: 'logo_1574_Standard_Liege.png' },
    { id: '11863', name: 'Shakhtar Donetsk', logo: 'logo_2135_Shakhtar_Donetsk.png' },
    { id: '2226', name: 'Sevilla', logo: 'logo_1713_Sevilla_FC.png' },
    { id: '30997', name: 'Red Bull Salzburg', logo: 'logo_1942_Red_Bull_Salzburg.png' },
    { id: '12761', name: 'RCD Espanyol Barcelona', logo: 'logo_1722_RCD_Espanyol_Barcelona.png' },
    { id: '5282', name: 'PSV Eindhoven', logo: 'logo_1534_PSV_Eindhoven.png' },
    { id: '17788', name: 'Paris Saint-Germain', logo: 'logo_Paris_Saint-Germain.png' },
    { id: '1064', name: 'Nîmes Olympique', logo: '3813_Nîmes_Olympique.png' },
    { id: '60', name: 'Newcastle United', logo: 'logo_Newcastle_United.png' },
    { id: '2956', name: 'KAA Gent', logo: 'logo_1571_KAA_Gent.png' },
    { id: '48', name: 'Manchester City', logo: '1625_Manchester_City.png' },
    { id: '10026', name: 'Los Angeles Galaxy', logo: '7847_Los_Angeles_Galaxy.png' },
    { id: '29015', name: 'Lokomotiv Moscow', logo: '13887_Lokomotiv_Moscow.png' },
    { id: '15581', name: 'Lech Poznan', logo: 'logo_1595_Lech_Poznan.png' },
    { id: '3', name: 'Juventus FC', logo: 'logo_1832_Juventus_FC.png' },
    { id: '18001', name: 'Fortuna Düsseldorf', logo: 'logo_Fortuna_Dusseldorf.png' },
    { id: '13058', name: 'Feyenoord Rotterdam', logo: 'logo_1533_Feyenoord_Rotterdam.png' },
    { id: '2459', name: 'Fenerbahce SK', logo: 'logo_2004_Fenerbahce_SK.png' },
    { id: '26232', name: 'FC Schalke 04', logo: 'logo_FC_Schalke_04.png' },
    { id: '18960', name: 'FC Porto', logo: 'logo_1672_FC_Porto.png' },
    { id: '12686', name: 'FC Nordsjælland', logo: '7458_Nordsjelland.png' },
    { id: '33906', name: 'FC Barcelona', logo: null }, //'logo_FC_Barcelona.png'
    { id: '63', name: 'Everton FC', logo: 'logo_1124_Everton_FC.png' },
    { id: '64', name: 'Crystal Palace', logo: 'logo_1128_Crystal_Palace.png' },
    { id: '50', name: 'Chelsea FC', logo: 'logo_Chelsea_FC.png' },
    { id: '3294', name: 'AS Saint-Étienne', logo: '3782_AS_Saint-Étienne.png' },
    { id: '976', name: 'AS Monaco', logo: '19830_AS_Monaco.png' },
    { id: '13073', name: 'Club Brugge KV', logo: 'logo_1572_Club_Brugge_KV.png' },
    { id: '38328', name: 'D.C United', logo: 'logo_2118_D.C._United.png' },
    { id: '5635', name: 'Dynamo Kyiv', logo: 'logo_2136_Dynamo_Kyiv.png' },
    { id: '32429', name: 'Eintracht Frankfurt', logo: 'logo_1268_Eintracht_Frankfurt.png' },
    { id: '17017', name: 'Philadelphia Union', logo: 'logo_2115_Philadelphia_Union.png' },
    { id: '850', name: 'Stoke City', logo: 'logo_1129_Stoke_City.png' },
    { id: '69', name: 'Watford FC', logo: '1644_Watford_FC.png' },
    { id: '24711', name: 'Urawa Red Diamonds', logo: 'logo_2407_Urawa_Red_Diamonds.png' },
    { id: '5180', name: 'Istanbul Basaksehir FK', logo: 'logo_2005_Medipol_Basaksehir.png' },
    { id: '1003', name: 'Hull City', logo: '1672_Hull_City.png' },
]