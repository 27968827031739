import { StateController } from "utils/action-declaration";
import { AppState } from 'root.reducer';
import newSquadsDownloadStateService from 'api/admin/new-squads-download/new-squads-download.service';
import { SquadDownloadsModel, SubsquadsFilterOptions } from 'api/admin/new-squads-download/models';
import { ClubModel } from 'api/admin/clubs/models';
import _ from "lodash";
import { getPlayerSearchFilterCriteria } from "store/playerSearch/reducers/playerSearchFilterReducer";
import { PlayerSearchService } from 'api/player/player-search';
import { notificationCreate } from 'app/notifications/notifications.actions';

export enum NewSquadsDownloadTabEnum {
  Squads = 'Squads',
  // Players = 'Players', // TODO: hide according to #16070
  Subsquads = 'Subsquads',
}

export enum MappedSquadSourceEnum {
  Transferroom = 'Transferroom',
  Wyscout = 'Wyscout',
}

export type SquadsTabState = {
  isLoading: boolean;
  currentPage: number;
  totalRecords: number;
  recordsPerPage: number;
  squadDownloads: SquadDownloadsModel[];
  transfermarktId?: number;
  wyscoutId?: number;
  mappedSquadSource: MappedSquadSourceEnum;
  transferroomSquad: string;
  transferroomSquadId: number | null;
  autosuggestProcessing: boolean;
  keyword: string;
  autosuggestionsOptions: any[];
  squadQueuedWasDownloaded: boolean;
}

export type SubsquadsTabState = {
  isLoading: boolean;
  subsquadAutosuggestProcessing: boolean;
  mainsquadAutosuggestProcessing: boolean;
  attachSubsquadProcessing: boolean;
  detachSubsquadProcessing: boolean;
  isSortOrderAscending: true,
  sortOrder: string;
  currentPage: number;
  totalRecords: number;
  recordsPerPage: number;
  subsquads: ClubModel[];
  filterOptions: SubsquadsFilterOptions;
  previousFilterSnapshot: SubsquadsFilterOptions;
  subsquadKeyword: string;
  mainsquadKeyword: string;
  subsquadAutosuggestionsOptions: any[];
  mainsquadAutosuggestionsOptions: any[];
  subsquadName: string;
  subsquadId: number | null;
  mainsquadName: string;
  mainsquadId: number | null;
}

class NewSquadsDownloadState {
  activeTab: NewSquadsDownloadTabEnum;
  squadsTabState: SquadsTabState;
  subsquadsTabState: SubsquadsTabState;
}

const defaultSquadsTabState: SquadsTabState = {
  isLoading: true,
  currentPage: 1,
  totalRecords: 0,
  recordsPerPage: 20,
  squadDownloads: [],
  transfermarktId: undefined,
  wyscoutId: undefined,
  mappedSquadSource: MappedSquadSourceEnum.Transferroom,
  transferroomSquad: '',
  transferroomSquadId: null,
  autosuggestProcessing: false,
  keyword: '',
  autosuggestionsOptions: [],
  squadQueuedWasDownloaded: false
}

const defaultSubsquadsFilterOptions: SubsquadsFilterOptions = {
  squadName: '',
  countryName: '',
  cityName: '',
  competitionName: ''
};

const defaultSubsquadsTabState: SubsquadsTabState = {
  isLoading: true,
  subsquadAutosuggestProcessing: false,
  mainsquadAutosuggestProcessing: false,
  attachSubsquadProcessing: false,
  detachSubsquadProcessing: false,
  isSortOrderAscending: true,
  sortOrder: 'date',
  currentPage: 1,
  totalRecords: 0,
  recordsPerPage: 20,
  subsquads: [],
  filterOptions: defaultSubsquadsFilterOptions,
  previousFilterSnapshot: defaultSubsquadsFilterOptions,
  subsquadKeyword: '',
  mainsquadKeyword: '',
  subsquadAutosuggestionsOptions: [],
  mainsquadAutosuggestionsOptions: [],
  subsquadName: '',
  subsquadId: null,
  mainsquadName: '',
  mainsquadId: null,
}

const defaultState: NewSquadsDownloadState = {
  activeTab: NewSquadsDownloadTabEnum.Squads,
  squadsTabState: defaultSquadsTabState,
  subsquadsTabState: defaultSubsquadsTabState,
}

const stateController = new StateController<NewSquadsDownloadState>(
  'ADMIN_V2/NEW_SQUAD_DOWNLOAD',
  defaultState
);

class Actions {
  public static dispose() {
    return (dispatch) => {
      dispatch(stateController.setState(defaultState))
    }
  }

  public static loadInitialData() {
    return async (dispatch) => {
      try {
        // await dispatch(Actions.getSquadsDownloads());
      } catch (err) {
        console.error(err);
      }
    };
  };

  public static getSquadsDownloads(silently?: boolean) {
    return async (dispatch,  getState: () => AppState) => {
      try {
        dispatch(stateController.setState(
          prevState => ({
            ...prevState,
            squadsTabState: {
              ...prevState.squadsTabState,
              isLoading: !silently,
            }
        })));
        const {
          currentPage,
          recordsPerPage
        } = Selectors.selectSquadsState(getState());

        const searchParameters = {
          pageIndex: currentPage,
          pageSize: recordsPerPage,
        }

        const data = await newSquadsDownloadStateService.getSquadDownloads(searchParameters);

        const squadDownloads = data.pageItems.map((squad, index) => ({
          ...squad,
          key: squad.id
        }));

        dispatch(stateController.setState(prevState => ({
          ...prevState,
          squadsTabState: {
            ...prevState.squadsTabState,
            squadDownloads,
            totalRecords: data.total,
          }
        })));

      } catch (err) {
        console.error(err);
      } finally {
        dispatch(stateController.setState(
          prevState => ({
            ...prevState,
            squadsTabState: {
              ...prevState.squadsTabState,
              isLoading: false
            }
        })));
      }
    }
  };

  public static getSubsquads() {
    const wasFiltersChanged = (filterOptions: SubsquadsFilterOptions, previousFilterSnapshot: SubsquadsFilterOptions) => {
      return JSON.stringify(filterOptions) !== JSON.stringify(previousFilterSnapshot);
    }

    return async (dispatch,  getState: () => AppState) => {
      const userId = getState().auth.userId;
      try {
        dispatch(stateController.setState(
          prevState => ({
            ...prevState,
            subsquadsTabState: {
              ...prevState.subsquadsTabState,
              isLoading: true,
            }
        })));
        const {
          isSortOrderAscending,
          sortOrder,
          currentPage,
          recordsPerPage,
          filterOptions,
          previousFilterSnapshot
        } = Selectors.selectSubsquadsState(getState());

        const shouldDisposePagination = wasFiltersChanged(filterOptions, previousFilterSnapshot);

        dispatch(stateController.setState(prevState => ({
          ...prevState,
          subsquadsTabState: {
            ...prevState.subsquadsTabState,
            previousFilterSnapshot: {
              ...prevState.subsquadsTabState.filterOptions,
            },
            currentPage: shouldDisposePagination ? 1 : currentPage
          }
        })));

        const searchParameters = {
          userId,
          isSortOrderAscending,
          sortOrder,
          recordsPerPage,
          page: currentPage,
          squadName: filterOptions.squadName ? filterOptions.squadName : undefined,
          countryName: filterOptions.countryName ? filterOptions.countryName : undefined,
          cityName: filterOptions.cityName ? filterOptions.cityName : undefined,
          competitionName: filterOptions.competitionName ? filterOptions.competitionName : undefined,
        }

        const data = await newSquadsDownloadStateService.getSquads(searchParameters);

        const subsquads = data.output.map((squad, index) => ({
          ...squad,
          key: squad.id
        }));

        dispatch(stateController.setState(prevState => ({
          ...prevState,
          subsquadsTabState: {
            ...prevState.subsquadsTabState,
            subsquads,
            totalRecords: data.rowCount,
          }
        })));

      } catch (err) {
        console.error(err);
      } finally {
        dispatch(stateController.setState(
          prevState => ({
            ...prevState,
            subsquadsTabState: {
              ...prevState.subsquadsTabState,
              isLoading: false
            }
        })));
      }
    }
  };

  public static onChangeTab(tabKey: NewSquadsDownloadTabEnum) {
    return dispatch => {
      dispatch(Actions.dispose());
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        activeTab: tabKey
      })))
    }
  }

  public static onSquadsPaginationChange(pageNumber: number, pageSize: number) {
    return (dispatch, getState: () => AppState) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        squadsTabState: {
          ...prevState.squadsTabState,
          currentPage: pageNumber,
          recordsPerPage: pageSize
        }
      })));

      dispatch(Actions.getSquadsDownloads());
    }
  }

  public static onSubsquadsPaginationChange(pageNumber: number, pageSize: number) {
    return (dispatch, getState: () => AppState) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        subsquadsTabState: {
          ...prevState.subsquadsTabState,
          currentPage: pageNumber,
          recordsPerPage: pageSize
        }
      })));

      dispatch(Actions.getSubsquads());
    }
  }

  public static applySearch() {
    return dispatch => dispatch(Actions.getSubsquads())
  }

  public static disposeFilters() {
    return (dispatch) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        subsquadsTabState: {
          ...prevState.subsquadsTabState,
          filterOptions: defaultSubsquadsFilterOptions
        }
      })))
      dispatch(Actions.applySearch());
    }
  }

  public static onChangeSquadName(value: string) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        subsquadsTabState: {
          ...prevState.subsquadsTabState,
          filterOptions: {
            ...prevState.subsquadsTabState.filterOptions,
            squadName: value
          }
        }
      })));
    }
  }

  public static onChangeCountryName(value: string) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        subsquadsTabState: {
          ...prevState.subsquadsTabState,
          filterOptions: {
            ...prevState.subsquadsTabState.filterOptions,
            countryName: value
          }
        }
      })));
    }
  }

  public static onChangeCityName(value: string) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        subsquadsTabState: {
          ...prevState.subsquadsTabState,
          filterOptions: {
            ...prevState.subsquadsTabState.filterOptions,
            cityName: value
          }
        }
      })));
    }
  }

  public static onChangeCompetitionName(value: string) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        subsquadsTabState: {
          ...prevState.subsquadsTabState,
          filterOptions: {
            ...prevState.subsquadsTabState.filterOptions,
            competitionName: value
          }
        }
      })));
    }
  }

  public static onTransfermarktIdChange(value: string) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        squadsTabState: {
          ...prevState.squadsTabState,
          transfermarktId: value,
          squadQueuedWasDownloaded: false
        }
      })));
    }
  }

  public static onChangeKeyword(value: string) {
    return async (dispatch) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        squadsTabState: {
          ...prevState.squadsTabState,
          keyword: value,
          squadQueuedWasDownloaded: false
        }
      })));

      if (value) {
        Actions.loadFirstClubAutosuggestionsDebounceFunc(dispatch, value)
      } else {
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          squadsTabState: {
            ...prevState.squadsTabState,
            autosuggestionsOptions: [],
          }
        })));
      }
    }
  };

  private static loadFirstClubAutosuggestionsDebounceFunc = _.debounce((dispatch, keyword) => dispatch(Actions.getClubAutosuggestions(keyword)), 1000)

  public static getClubAutosuggestions(keyword: string) {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          squadsTabState: {
            ...prevState.squadsTabState,
            autosuggestProcessing: true
          }
        })));
        const filter = getPlayerSearchFilterCriteria(getState());
        const autosuggestionsOptions = await PlayerSearchService.getPlayerAutosuggestions(0, 0, { ...filter, keyword }, true, true, true)
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          squadsTabState: {
            ...prevState.squadsTabState,
            autosuggestionsOptions: autosuggestionsOptions
          }
        })));
      } finally {
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          squadsTabState: {
            ...prevState.squadsTabState,
            autosuggestProcessing: false
          }
        })));
      }
    }
  };

  public static selectClub(clubName: string, squadId: number) {
    return (dispatch) => {
      dispatch(Actions.onChangeKeyword(clubName));
      dispatch(Actions.getClubAutosuggestions(clubName));

      dispatch(stateController.setState(prevState => ({
        ...prevState,
        squadsTabState: {
          ...prevState.squadsTabState,
          transferroomSquad: clubName,
          transferroomSquadId: squadId
        },
      })));
    };
  };

  public static onClearAutosuggestions() {
    return (dispatch) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        squadsTabState: {
          ...prevState.squadsTabState,
          autosuggestionsOptions: [],
          keyword: '',
          transferroomSquad: '',
          transferroomSquadId: null,
        },
      })));
    }
  }

  public static onRefreshDownloads() {
    return (dispatch) => {
      dispatch(Actions.getSquadsDownloads());
      dispatch(stateController.setState(
      prevState => ({
        ...prevState,
        squadsTabState: {
          ...prevState.squadsTabState,
          squadQueuedWasDownloaded: false
        }
      })));
    }
  }

  public static onDownloadSquad() {
    return async (dispatch , getState: () => AppState) => {
      try {
        dispatch(stateController.setState(
        prevState => ({
          ...prevState,
          squadsTabState: {
            ...prevState.squadsTabState,
            isLoading: true,
            squadQueuedWasDownloaded: false
          }
        })));
        const transfermarktId = Selectors.getTransfermarktId(getState());
        const wyscoutId = Selectors.getWyscoutId(getState());
        const squadId = Selectors.getTransferroomSquadId(getState());

        await newSquadsDownloadStateService.downloadSquad({
          transfermarktApiId: transfermarktId,
          wyscoutApiId: wyscoutId,
          squadId: squadId,
        });

        dispatch(notificationCreate({ message: 'Squad queued to download successfully', level: 'success'}));

        dispatch(stateController.setState(
        prevState => ({
          ...prevState,
          squadsTabState: {
            ...prevState.squadsTabState,
            squadQueuedWasDownloaded: true,
          }
        })));

        dispatch(Actions.getSquadsDownloads());
      } catch (err) {
        console.error(err);
        dispatch(notificationCreate({ message: 'Error in squad download', level: 'error'}));
      } finally {
        dispatch(stateController.setState(
          prevState => ({
            ...prevState,
            squadsTabState: {
              ...prevState.squadsTabState,
              isLoading: false,
            }
          })));
      }
    }
  }

  public static onChangeMappedSquadSource(value: MappedSquadSourceEnum) {
    return (dispatch) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        squadsTabState: {
          ...prevState.squadsTabState,
          mappedSquadSource: value
        },
      })));
    }
  }

  // subsquds tab

  public static onChangeSubsquadKeyword(value: string) {
    return async (dispatch) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        subsquadsTabState: {
          ...prevState.subsquadsTabState,
          subsquadKeyword: value,
        }
      })));

      if (value) {
        Actions.loadSubsquadAutosuggestionsDebounceFunc(dispatch, value)
      } else {
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          subsquadsTabState: {
            ...prevState.subsquadsTabState,
            subsquadAutosuggestionsOptions: []
          }
        })));
      }
    }
  };

  private static loadSubsquadAutosuggestionsDebounceFunc = _.debounce((dispatch, keyword) => dispatch(Actions.getSubsquadAutosuggestions(keyword)), 1000)

  public static getSubsquadAutosuggestions(keyword: string) {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          subsquadsTabState: {
            ...prevState.subsquadsTabState,
            subsquadAutosuggestProcessing: true
          }
        })));
        const filter = getPlayerSearchFilterCriteria(getState());
        const autosuggestionsOptions = await PlayerSearchService.getPlayerAutosuggestions(0, 0, { ...filter, keyword }, true)
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          subsquadsTabState: {
            ...prevState.subsquadsTabState,
            subsquadAutosuggestionsOptions: autosuggestionsOptions
          }
        })));
      } finally {
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          subsquadsTabState: {
            ...prevState.subsquadsTabState,
            subsquadAutosuggestProcessing: false
          }
        })));
      }
    }
  };

  public static selectSubsquad(clubName: string, squadId: number) {
    return (dispatch) => {
      dispatch(Actions.onChangeSubsquadKeyword(clubName));
      dispatch(Actions.getSubsquadAutosuggestions(clubName));

      dispatch(stateController.setState(prevState => ({
        ...prevState,
        subsquadsTabState: {
          ...prevState.subsquadsTabState,
          subsquadName: clubName,
          subsquadId: squadId
        },
      })));
    };
  };

  public static onClearSubsquadsAutosuggestions() {
    return (dispatch) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        subsquadsTabState: {
          ...prevState.subsquadsTabState,
          subsquadAutosuggestionsOptions: [],
          subsquadKeyword: '',
          subsquadName: '',
          subsquadId: null,
        },
      })));
    }
  }

  public static onChangeMainsquadKeyword(value: string) {
    return async (dispatch) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        subsquadsTabState: {
          ...prevState.subsquadsTabState,
          mainsquadKeyword: value,
        }
      })));

      if (value) {
        Actions.loadMainsquadAutosuggestionsDebounceFunc(dispatch, value)
      } else {
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          subsquadsTabState: {
            ...prevState.subsquadsTabState,
            mainsquadAutosuggestionsOptions: []
          }
        })));
      }
    }
  };

  private static loadMainsquadAutosuggestionsDebounceFunc = _.debounce((dispatch, keyword) => dispatch(Actions.getMainsquadAutosuggestions(keyword)), 1000)

  public static getMainsquadAutosuggestions(keyword: string) {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          subsquadsTabState: {
            ...prevState.subsquadsTabState,
            mainsquadAutosuggestProcessing: true
          }
        })));
        const filter = getPlayerSearchFilterCriteria(getState());
        const autosuggestionsOptions = await PlayerSearchService.getPlayerAutosuggestions(0, 0, { ...filter, keyword }, true)
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          subsquadsTabState: {
            ...prevState.subsquadsTabState,
            mainsquadAutosuggestionsOptions: autosuggestionsOptions
          }
        })));
      } finally {
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          subsquadsTabState: {
            ...prevState.subsquadsTabState,
            mainsquadAutosuggestProcessing: false
          }
        })));
      }
    }
  };

  public static selectMainsquad(clubName: string, squadId: number) {
    return (dispatch) => {
      dispatch(Actions.onChangeMainsquadKeyword(clubName));
      dispatch(Actions.getMainsquadAutosuggestions(clubName));

      dispatch(stateController.setState(prevState => ({
        ...prevState,
        subsquadsTabState: {
          ...prevState.subsquadsTabState,
          mainsquadName: clubName,
          mainsquadId: squadId
        },
      })));
    };
  };

  public static onClearMainsquadsAutosuggestions() {
    return (dispatch) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        subsquadsTabState: {
          ...prevState.subsquadsTabState,
          mainsquadAutosuggestionsOptions: [],
          mainsquadKeyword: '',
          mainsquadName: '',
          mainsquadId: null,
        },
      })));
    }
  }

  public static detachSubsquad() {
    return async (dispatch, getState: () => AppState) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        subsquadsTabState: {
          ...prevState.subsquadsTabState,
          detachSubsquadProcessing: true,
        }
      })));
      try {
        const subsquadId = Selectors.selectSubsquadsState(getState()).subsquadId;
        await newSquadsDownloadStateService.detachSubsquad(subsquadId);

        dispatch(Actions.onClearSubsquadsAutosuggestions());
        dispatch(Actions.onClearMainsquadsAutosuggestions());

        dispatch(notificationCreate({ message: 'Subsquad detached successfully', level: 'success'}));

        dispatch(Actions.getSubsquads());
      } catch (err) {
        console.error(err);
        dispatch(notificationCreate({ message: 'Error in detach subsquad', level: 'error'}));
      } finally {
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          subsquadsTabState: {
            ...prevState.subsquadsTabState,
            detachSubsquadProcessing: false,
          }
        })));
      }
    }
  }

  public static attachSubsquad() {
    return async (dispatch, getState: () => AppState) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        subsquadsTabState: {
          ...prevState.subsquadsTabState,
          attachSubsquadProcessing: true,
        }
      })));
      try {
        const subsquadId = Selectors.selectSubsquadsState(getState()).subsquadId;
        const mainsquadId = Selectors.selectSubsquadsState(getState()).mainsquadId;
        await newSquadsDownloadStateService.attachSubsquad(subsquadId, mainsquadId);

        dispatch(Actions.onClearSubsquadsAutosuggestions());
        dispatch(Actions.onClearMainsquadsAutosuggestions());

        dispatch(notificationCreate({ message: 'Subsquad attached successfully', level: 'success'}));

        dispatch(Actions.getSubsquads());
      } catch (err) {
        console.error(err);
        dispatch(notificationCreate({ message: 'Error in attach subsquad', level: 'error'}));
      } finally {
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          subsquadsTabState: {
            ...prevState.subsquadsTabState,
            attachSubsquadProcessing: false,
          }
        })));
      }
    }
  }

}

class Selectors {
  public static selectState = (state: AppState) => state.admin.newDownloadsSquads;
  public static selectSquadsState = (state: AppState) => Selectors.selectState(state).squadsTabState;
  public static selectSubsquadsState = (state: AppState) => Selectors.selectState(state).subsquadsTabState;
  public static activeTab = (state: AppState) => Selectors.selectState(state).activeTab;

  public static isSquadsLoading = (state: AppState): boolean => Selectors.selectSquadsState(state).isLoading;
  public static getSquadsDownloads = (state: AppState) => Selectors.selectSquadsState(state).squadDownloads;
  public static getSquadsTotalRecords = (state: AppState) => Selectors.selectSquadsState(state).totalRecords;
  public static getSquadsRecordsPerPage = (state: AppState) => Selectors.selectSquadsState(state).recordsPerPage;
  public static getSquadsCurrentPage = (state: AppState) => Selectors.selectSquadsState(state).currentPage;
  public static getAutosuggestProcessing = (state: AppState) => Selectors.selectSquadsState(state).autosuggestProcessing;
  public static getAutosuggestionsOptions = (state: AppState) => Selectors.selectSquadsState(state).autosuggestionsOptions;
  public static getSquadsKeyword = (state: AppState) => Selectors.selectSquadsState(state).keyword;
  public static getTransferroomSquad = (state: AppState) => Selectors.selectSquadsState(state).transferroomSquad;
  public static getTransferroomSquadId = (state: AppState) => Selectors.selectSquadsState(state).transferroomSquadId;
  public static getMappedSquadSource = (state: AppState) => Selectors.selectSquadsState(state).mappedSquadSource;
  public static getTransfermarktId = (state: AppState) => Selectors.selectSquadsState(state).transfermarktId;
  public static getWyscoutId = (state: AppState) => Selectors.selectSquadsState(state).wyscoutId;

  public static isSubsquadsLoading = (state: AppState): boolean => Selectors.selectSubsquadsState(state).isLoading;
  public static getSubsquads = (state: AppState) => Selectors.selectSubsquadsState(state).subsquads;
  public static getSubsquadsTotalRecords = (state: AppState) => Selectors.selectSubsquadsState(state).totalRecords;
  public static getSubsquadsRecordsPerPage = (state: AppState) => Selectors.selectSubsquadsState(state).recordsPerPage;
  public static getSubsquadsCurrentPage = (state: AppState) => Selectors.selectSubsquadsState(state).currentPage;
  public static getSubsquadsFilterOptions = (state: AppState) => Selectors.selectSubsquadsState(state).filterOptions;



  public static isSubsquadsFiltersSetToDefault(state: AppState) {
    return JSON.stringify(defaultState.subsquadsTabState.filterOptions) === JSON.stringify(Selectors.getSubsquadsFilterOptions(state))
  }
}

const reducer = stateController.getReducer();

export {
  reducer as Reducer,
  NewSquadsDownloadState as State,
  Actions as Actions,
  Selectors as Selectors,
  stateController as Controller
};
