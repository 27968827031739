import axios from 'axios-config';
import { FriendlySuccessStoryModel, StoryModel } from "./models";

export class FriendlySuccessStoryService {
    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    }

    public static async getAll(userId: number): Promise<FriendlySuccessStoryModel> {
        const { data } = await axios.get(
            `api/AdminSuccessStory/Friendly/GetAll/${userId}`,
            this.axiosConfig
        );
        return data;
    }

    public static async createStory(userId: number, storyModel: StoryModel) {
        const { data } = await axios.post(
            `api/AdminSuccessStory/Friendly/Create/${userId}`,
            storyModel,
            this.axiosConfig
        );
        return data;
    }

    public static async updateStory(userId: number, id: number, storyModel: StoryModel) {
        const { data } = await axios.put(
            `api/AdminSuccessStory/Friendly/Update/${userId}/${id}`,
            storyModel,
            this.axiosConfig
        );
        return data;
    }

    public static async deleteStory(userId: number, id: number) {
        const { data } = await axios.delete(
            `api/AdminSuccessStory/Friendly/Delete/${userId}/${id}`,
            this.axiosConfig
        );
        return data;
    }

    public static async loadSquadSuggestions(keyword: string, squadId: number, clubId: number, competitionId: []) {
        const { data } = await axios.post(
            `api/Suggestion/GetSquadGreedySuggestionByKeyword/${squadId}/${clubId}`,
            { keyword: keyword, pageSize: 100, currentPageNumber: 1, competitionIds:  competitionId ? [competitionId]: [] },
            this.axiosConfig
        );
        return data;
    }
}