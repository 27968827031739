import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer'
import { SearchItemForAgency, SearchItemType, SearchResult } from 'api/search/model/search-result'
import { getPageSize, setPageSize } from 'ts-components/pagination/helpers/paging-store'
import { SearchRequest } from 'api/search/model/search-request'
import { CancelTokenSource } from 'axios';
import { getCancelTokenSource } from 'axios-config';
import ClubSideSearchService from 'api/search/search.agencyside.service'
import AgencyFinderService from 'api/player-v2/agency-finder.service';
import { ShortlistService } from 'api/shortlist/shortlist-service';
import AgencyAgentOnBoardingService from 'api/agency/agent/onboarding/onboarding.service';
import { AgencyPlayerDisplayInfo, DisplayStatus, PlayerStatus, SearchPlayerItemForAgency } from 'api/search/model/search-player-item-for-agency';
import { GridActivityService } from './grid-activity.service'
import * as storedFilter from 'pages/PlayerSearch-v2/redux/stored-filter'
import { agencyPaths } from 'routes/paths';
import historyAccessor from 'history-accessor';
import insertUserActivity from "app/user-activity/actions/user-activity.actions";

type SearchResultAgency = SearchResult<SearchItemForAgency>;

class GridState {
    result: SearchResultAgency;
    resultLoading: boolean;
    accessRestricted: boolean;
    selectedItemId: number;
    gridState: GridToggleState;
    pageSize: number;
    processingItems: Array<number>;
    currentPage: number;
    playerVideoModal: PlayerVideoModalState
}
export type PlayerVideoModalState = {
    playerVideos: string[]
    playerId: number
    isModalOpen: boolean
    playerName: string
}

export enum GridToggleState {
    None = 0,
    Search = 1,
    ShortList = 2
}

export const tabKey = {
    common: 'common',
    shortlist: 'shortlist',
}

export const getGridState = (tab) => {
    let newState: GridToggleState = GridToggleState.None;
    if (tab === "common") newState = GridToggleState.Search;
    else if (tab === "shortlist") newState = GridToggleState.ShortList;
    else newState = GridToggleState.None;

    return newState
}

const defaultState: GridState = {
    result: null,
    gridState: GridToggleState.None,
    resultLoading: false,
    accessRestricted: false,
    selectedItemId: null,
    pageSize: getPageSize('search', 10),
    processingItems: [],
    currentPage: 1,
    playerVideoModal: {
        playerVideos: [],
        playerId: null,
        isModalOpen: false,
        playerName: '',
    }
}

const stateController = new StateController<GridState>(
    "AGENCY_SEARCH_SCREEN/GRID",
    defaultState
);

class Actions {
    public static token: CancelTokenSource = null;


    public static initSinglePlayer(displayInfo: AgencyPlayerDisplayInfo, playerId: number, parentSquadId: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ resultLoading: true }))

            let result: SearchResultAgency = {
                items: [
                    {
                        type: SearchItemType.Player,
                        player: {
                            agencyPlayerDisplayInfo: displayInfo,
                            parentSquad: { id: parentSquadId },
                            id: playerId
                        }
                    } as SearchItemForAgency
                ],
                totalResultCount: 0,
                totalPageCount: 0,
                currentPage: 0
            }

            dispatch(stateController.setState({ result: result, resultLoading: false }))
        }
    }

    public static onViewProfileCick(playerId: number) {
        return (dispatch, getState: () => AppState) => {
            let url = window.origin + `/profile/${playerId}`;
            window.open(url, '_blank').focus()
            let subState = Selectors.getRoot(getState());
            let players = subState.result.items.filter(x => x.type == SearchItemType.Player).map(x => x.player);
            let p: SearchPlayerItemForAgency = players.find(x => x.id === playerId);

            dispatch(GridActivityService.openPlayerProfile(
                p.id,
                p.parentSquad ? p.parentSquad.id : null,
                !p.parentSquad && p.agencyPlayerDisplayInfo.representedBy ? p.agencyPlayerDisplayInfo.representedBy.id : null,
                Selectors.getGridState(getState()),
            ))
        }
    }

    public static getPlayerVerified(playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            let url = window.origin + `/player-verification?player=${playerId}`;
            window.open(url, '_blank').focus();

            // let subState = Selectors.getRoot(getState());
            // let players = subState.result.items.filter(x => x.type == SearchItemType.Player).map(x => x.player);
            // let p = players.find(x => x.id === playerId);

            // dispatch(GridActivityService.verifyPlayer(
            //     p.id,
            //     p.parentSquad ? p.parentSquad.id : null,
            //     !p.parentSquad && p.agencyPlayerDisplayInfo.representedBy ? p.agencyPlayerDisplayInfo.representedBy.id : null,
            //     Selectors.getGridState(getState()),
            // ))
        }
    }

    public static onShortListClick(playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            let subState = Selectors.getRoot(getState());

            let players = subState.result.items.filter(x => x.type == SearchItemType.Player).map(x => x.player);
            let currentPlayer = players.find(x => x.id === playerId);

            dispatch(stateController.setState({ processingItems: [...subState.processingItems, playerId] }))
            if (currentPlayer.isShortListed) {
                await ShortlistService.agencyRemoveFromShortlist(playerId);
                currentPlayer = { ...currentPlayer, isShortListed: false };
            }
            else {
                await ShortlistService.agencyAddToShortlist(playerId);
                currentPlayer = { ...currentPlayer, isShortListed: true };
            }

            dispatch(GridActivityService.toggleShortlist(
                currentPlayer.id,
                currentPlayer.isShortListed,
                currentPlayer.parentSquad ? currentPlayer.parentSquad.id : null,
                !currentPlayer.parentSquad && currentPlayer.agencyPlayerDisplayInfo.representedBy ? currentPlayer.agencyPlayerDisplayInfo.representedBy.id : null,
                Selectors.getGridState(getState()),
            ))

            let newResult: SearchResultAgency = {
                ...subState.result,
                items: subState.result.items.map((item) => {
                    if (item.player !== null && item.player.id == playerId) {
                        return { ...item, player: currentPlayer }
                    }
                    return item;
                })
            }

            dispatch(stateController.setState({ result: newResult, processingItems: [...subState.processingItems.filter(x => x != playerId)] }))
            if (Selectors.isInShortList(getState())) {
                dispatch(Actions.refresh())
            }
        }
    }

    public static load(state: GridToggleState) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ gridState: state }));
            dispatch(Actions.refresh(
                () => {
                    if (state == GridToggleState.Search) {
                        dispatch(GridActivityService.openSearch())
                    } else if (state == GridToggleState.ShortList) {
                        dispatch(GridActivityService.openShortList())
                    }
                }
            ));
        }
    }

    public static setGridState(tab: String) {
        return async (dispatch, getState: () => AppState) => {

            let newState: GridToggleState = getGridState(tab)
            dispatch(stateController.setState({ gridState: newState }));
            dispatch(Actions.refresh(
                () => {
                    if (newState == GridToggleState.Search) {
                        dispatch(GridActivityService.openSearch())
                    } else if (newState == GridToggleState.ShortList) {
                        dispatch(GridActivityService.openShortList())
                    }
                }
            ));
        }
    }

    public static contactPlayer(playerId: number, parentSquadId: number, isProfilePage?: boolean) {
        return async (dispatch, getState: () => AppState) => {
            let subState = Selectors.getRoot(getState());
            let players = subState.result.items.filter(x => x.type == SearchItemType.Player).map(x => x.player);
            let currentPlayer = players.find(x => x.id === playerId);
            dispatch(stateController.setState({ processingItems: [...subState.processingItems, playerId] }))
            var chanelId = await AgencyFinderService.sendRepresentationRequestToPlayer(playerId);

            currentPlayer = {
                ...currentPlayer,
                agencyPlayerDisplayInfo: {
                    ...currentPlayer.agencyPlayerDisplayInfo,
                    displayStatus: DisplayStatus.ButtonContactPlayerMessageSent,
                    contactPlayerMessageChannel: chanelId
                }
            };

            let newResult: SearchResultAgency = {
                ...subState.result,
                items: subState.result.items.map((item) => {
                    if (item.player !== null && item.player.id == playerId) {
                        return { ...item, player: currentPlayer }
                    }
                    return item;
                })
            }
            // dispatch(insertUserActivity({
            //     Message: 'Sent Representation Message',
            //     PageName: isProfilePage ? 'Player Profile' : 'Search',
            //     PlayerId: playerId,
            //     ClubId: parentSquadId,
            // }));
            dispatch(stateController.setState({ result: newResult, processingItems: [...subState.processingItems.filter(x => x != playerId)] }));
        }
    }

    public static claimRepresentation(playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let subState = Selectors.getRoot(state);

            let players = subState.result.items.filter(x => x.type == SearchItemType.Player).map(x => x.player);
            let currentPlayer = players.find(x => x.id === playerId);

            dispatch(stateController.setState({ processingItems: [...subState.processingItems, playerId] }))
            await AgencyAgentOnBoardingService.addPlayers([{ playerId, isRegular: true }]);

            currentPlayer = {
                ...currentPlayer,
                agencyPlayerDisplayInfo: {
                    ...currentPlayer.agencyPlayerDisplayInfo,
                    displayStatus: DisplayStatus.ButtonGetPlayerVerified, status: PlayerStatus.ClaimedNotVerified
                }
            };

            let newResult: SearchResultAgency = {
                ...subState.result,
                items: subState.result.items.map((item) => {
                    if (item.player !== null && item.player.id == playerId) {
                        return { ...item, player: currentPlayer }
                    }
                    return item;
                })
            }

            // dispatch(GridActivityService.claimRepresentation(
            //     currentPlayer.id,
            //     currentPlayer.parentSquad ? currentPlayer.parentSquad.id : null,
            //     !currentPlayer.parentSquad && currentPlayer.agencyPlayerDisplayInfo.representedBy ? currentPlayer.agencyPlayerDisplayInfo.representedBy.id : null,
            //     Selectors.getGridState(getState()),
            // ))

            dispatch(stateController.setState({ result: newResult, processingItems: [...subState.processingItems.filter(x => x != playerId)] }))
        }
    }

    public static getPlayer = (state: AppState, id: number) => {
        return state.agency.playerSearch.grid.result.items.find(x => x.player != null && x.player.id == id).player;
    }

    public static updatePlayer(id: number, newItem: SearchPlayerItemForAgency) {
        return (dispatch, getState: () => AppState) => {
            const state = getState().agency.playerSearch.grid;

            const array: Array<SearchItemForAgency> = state.result.items
                .map(item => item.player.id == id ? { ...item, player: newItem } : item)

            dispatch(stateController.setState({
                result: {
                    ...state.result,
                    items: array,
                }
            }))
        }
    }

    public static refresh(action?: () => void) {
        return async (dispatch, getState: () => AppState) => {
            const state = getState();

            if (Actions.token) {
                Actions.token.cancel()
            }

            Actions.token = getCancelTokenSource();

            dispatch(stateController.setState({ resultLoading: true, result: null }));

            if (Selectors.getGridState(getState()) === GridToggleState.Search) {
                storedFilter.saveFilter(Selectors.getStoredFilter(state));
            }

            let req: SearchRequest = Selectors.getRequest(state);
            let result = await ClubSideSearchService.search(req, Actions.token.token);

            if (action && result) {
                action()
            }

            if (result) {
                dispatch(stateController.setState({ result: result, resultLoading: false }));
            }

        }
    }

    public static setPage = (page: number) => {
        return (dispatch) => {
            dispatch(stateController.setState({ currentPage: page }))
            dispatch(Actions.refresh())
        }
    }

    public static setPageSize = (page: number, pageSize: number) => {
        return (dispatch) => {
            dispatch(stateController.setState((draftState) => {
                return {
                    ...draftState,
                    currentPage: page,
                    pageSize,
                }
            }))
            setPageSize('search', pageSize)
            dispatch(Actions.refresh(
                () => dispatch(GridActivityService.setPageSize(pageSize))
            ))
        }
    }

    public static resetPage = () => {
        return (dispatch) => {
            dispatch(stateController.setState({ currentPage: 1 }))
        }
    }

    public static setAsProcessing = (id: number, isProcessing: boolean) => {
        return (dispatch, getState: () => AppState) => {
            let state = getState().newPlayerSearch.grid;

            if (isProcessing) {
                dispatch(stateController.setState(
                    { processingItems: [...state.processingItems, id] }
                ))
            }
            else {
                dispatch(stateController.setState(
                    { processingItems: [...state.processingItems.filter(x => x != id)] }
                ))
            }
        }
    }

    public static goToMessageChannel(channelId: string, isProfilePage: boolean, playerId: number, parentSquadId: number, isCustomUserActivity?: boolean) {
        return async (dispatch, getState: () => AppState) => {
            if (!isCustomUserActivity) {
                dispatch(insertUserActivity({
                    Message: 'Opened Representation Message',
                    PageName: isProfilePage ? 'Player Profile' : 'Search',
                    PlayerId: playerId,
                    ClubId: parentSquadId,
                }));
            }
            historyAccessor.push(`${agencyPaths.messaging}?channel=${channelId}`)
        }
    }

    public static openPlayerVideoModal(entity: SearchPlayerItemForAgency) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                playerVideoModal: {
                    playerId: entity.id,
                    isModalOpen: true,
                    playerName: entity.shortName,
                    playerVideos: entity.playerVideos,
                }
            })))
        }
    }

    public static closePlayerVideoModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                playerVideoModal: defaultState.playerVideoModal
            })))
        }
    }

    public static onPositionRoleTooltipHover = (playerId: number) => {
        return async (dispatch, getState: () => AppState) => {
            const player = Actions.getPlayer(getState(), playerId);
            const gridState = Selectors.getGridState(getState());
            const agencyId = !player.parentSquad && player?.agencyPlayerDisplayInfo?.representedBy ? player?.agencyPlayerDisplayInfo?.representedBy.id : null; 
            dispatch(GridActivityService.openPositionRoleTootip(
                playerId,
                player.parentSquad ? player.parentSquad.id : null,
                agencyId,
                gridState,
            ))
        }
    }
    
    public static onRatingTooltipHover = (playerId: number) => {
        return async (dispatch, getState: () => AppState) => {
            const player = Actions.getPlayer(getState(), playerId);
            const gridState = Selectors.getGridState(getState());
            const agencyId = !player.parentSquad && player?.agencyPlayerDisplayInfo?.representedBy ? player?.agencyPlayerDisplayInfo?.representedBy.id : null;  
            dispatch(GridActivityService.openRatingTootip(
                playerId,
                player.parentSquad ? player.parentSquad.id : null,
                agencyId,
                gridState,
            ))
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.agency.playerSearch.grid;
    public static getCurrentPage = (state: AppState) => (Selectors.getRoot(state).result || {}).currentPage || 1;
    public static getGridState = (state: AppState) => Selectors.getRoot(state).gridState;
    public static isInShortList = (state: AppState) => Selectors.getGridState(state) == GridToggleState.ShortList;
    public static getTotalRowCount = (state: AppState) => (Selectors.getRoot(state).result || {}).totalResultCount
    public static getTotalPageCount = (state: AppState) => (Selectors.getRoot(state).result || {}).totalPageCount;
    public static getPageSize = (state: AppState) => Selectors.getRoot(state).pageSize;
    public static isLoading = (state: AppState) => Selectors.getRoot(state).resultLoading;
    public static getItems = (state: AppState) => (Selectors.getRoot(state).result || {}).items || [];
    public static getRequest = (state: AppState) => {
        const subState = state.agency.playerSearch;
        const gridState = Selectors.getGridState(state);
        const structure = subState.filter.structure;
        const pageNumber = state.agency.playerSearch.grid.currentPage;

        const getRange = (criteria, structure) => {
            if (!criteria) return null;

            const value = { ...criteria };
            if (value.max === structure.max) { value.max = null; }
            if (value.min === structure.min) { value.min = null; }

            return value;
        }

        let req: SearchRequest = {
            clubsAndAgentsFilter: {
                showOnlyClubs: subState.filter.clubAgentsFilter.showOnlyClubs,
                showOnlyTrustedAgents: subState.filter.clubAgentsFilter.showOnlyTrustedAgents
            },
            pageNumber: pageNumber,
            pageSize: Selectors.getPageSize(state),
            playerFilter: {
                positionList: subState.filter.transferTypeFilter.positionList,
                showOnlyFreeAgentPlayers: subState.filter.transferTypeFilter.showOnlyFreeAgentPlayers,
                anuallGrossSalaryYearly: subState.filter.transferTypeFilter.anuallGrossSalaryYearly,
                loanFee: subState.filter.transferTypeFilter.loanFee,
                transferFee: subState.filter.transferTypeFilter.transferFee,
                marketValue: subState.filter.transferTypeFilter.marketValue,
                age: { min: subState.filter.moreFilter.age.min, max: subState.filter.moreFilter.age.max },
                showOnlyAvailablePlayers: subState.filter.transferTypeFilter.showOnlyAvailablePlayers,
                showOnlyPlayersOnReleaseList: subState.filter.transferTypeFilter.showOnlyPlayersOnReleaseList,
                showOnlyPlayersLookingForAgent: subState.filter.lookingForAgent,
                gbePass: subState.filter.moreFilter.gbePass,
                contractExpiryMonthesMax: subState.filter.moreFilter.contractExpiryMonthesMax,
                minHeight: subState.filter.moreFilter.minHeight,
                minutesPlayed: getRange(subState.filter.moreFilter.minutesPlayed, structure.minutesPlayed),
                leaguesList: [
                    ...subState.filter.moreFilter.leaguesList,
                    ...subState.filter.moreFilter.leaguesLists.map(i => i.competitionIds).flat()
                ],
                nationalityList: subState.filter.moreFilter.nationalityList,
                minRating: subState.filter.moreFilter.minRating
            },
            shortListOnly: gridState == GridToggleState.ShortList,
            sortByKey: subState.filter.sortBy,
            keyword: subState.search.keyword,
        };

        return req;
    }
    public static getStoredFilter = (state: AppState) => {
        const subState = state.agency.playerSearch;
        return {
            clubsAndAgentsFilter: {
                showOnlyClubs: subState.filter.clubAgentsFilter.showOnlyClubs,
                showOnlyTrustedAgents: subState.filter.clubAgentsFilter.showOnlyTrustedAgents
            },
            playerFilter: {
                positionList: subState.filter.transferTypeFilter.positionList,
                showOnlyFreeAgentPlayers: subState.filter.transferTypeFilter.showOnlyFreeAgentPlayers,
                anuallGrossSalaryYearly: subState.filter.transferTypeFilter.anuallGrossSalaryYearly,
                loanFee: subState.filter.transferTypeFilter.loanFee,
                transferFee: subState.filter.transferTypeFilter.transferFee,
                marketValue: subState.filter.transferTypeFilter.marketValue,
                age: { min: subState.filter.moreFilter.age.min, max: subState.filter.moreFilter.age.max },
                gbePass: subState.filter.moreFilter.gbePass,
                contractExpiryMonthesMax: subState.filter.moreFilter.contractExpiryMonthesMax,
                minHeight: subState.filter.moreFilter.minHeight,
                minutesPlayed: subState.filter.moreFilter.minutesPlayed,
                leaguesList: subState.filter.moreFilter.leaguesList,
                leaguesLists: subState.filter.moreFilter.leaguesLists,
                nationalityList: subState.filter.moreFilter.nationalityList,
                lookingForAgent: subState.filter.lookingForAgent,
                minRating: subState.filter.moreFilter.minRating
            },
            sortByKey: subState.filter.sortBy,
            keyword: subState.search.keyword,
        };
    }

    public static getDisplayInfoForSinglePlayer(appState: AppState, playerId: number) {
        let result = appState.agency.playerSearch.grid.result;
        if (result == null) {
            return {
                state: null,
                isProcessing: false
            }
        }
        return {
            state: result.items[0].player.agencyPlayerDisplayInfo,
            isProcessing: appState.agency.playerSearch.grid.processingItems.includes(playerId)
        }
    }

    public static getIsVerifiedPlayer(appState: AppState, playerId: number) {
        var state = Selectors.getDisplayInfoForSinglePlayer(appState, playerId);

        if (state.state == null) return false;
        else return state.state.isPlayerVerifiedByCurrentAgency;
    }

    public static getAgencyPlayerStatus(appState: AppState, playerId: number) {
        var state = Selectors.getDisplayInfoForSinglePlayer(appState, playerId);

        if (state.state == null) return PlayerStatus.None;
        else return state.state.status;
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    GridState as State,
    Actions as Actions,
    stateController as Controller,
    Selectors,
};
