import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import historyAccessor from 'history-accessor';
import { agencyPaths } from 'routes/paths';
import _ from 'lodash';
import { AGENCY_FORCE_REONBOARDING_POPUP_FALSE } from 'store/actionTypes';
import ReonboardingService from 'api/agency/agent/reonboarding/reonboarding.service';
import OnboardingService from 'api/agency/agent/onboarding/onboarding.service';
import { PreconnectedPlayerItem, AddPlayersModel, AddedPlayerItem } from 'api/agency/agent/reonboarding/reonboarding-models';
import { Actions as RequestCreditsModalActions } from 'pages/agency/common/request-credits-modal/request-credits-modal.controller';
import { Actions as ReonboardingActions, ReonboardingSubsteppers } from 'pages/agency/authorized-agent/reonboarding-flow/reonboarding.controller';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';

export enum SelectPlayersSteps {
    SelectPlayersStep = 1,
    AddPlayers = 2,
    CongratulationsStep = 3,
    HasNoPriorityCreditsStep = 4,
}

class State {
    isLoading: boolean;
    activeStep: SelectPlayersSteps;
    preconnectedPlayers: PreconnectedPlayerItem[];
    selectedPlayers: PreconnectedPlayerItem[];
    amountOfAvailablePlayers: number;
    availableRegularPlayerSlots: number;
    isUnlimitedRegularPlayerSlots: boolean;
    removePlayerLoading: boolean;
    isNotCreditsModalOpen: boolean;
    pendingToRemove: PreconnectedPlayerItem[];
    processedPlayers: PreconnectedPlayerItem[];
    playerCreditsAvailable: number;

    addPlayersState: AddPlayersModel;
}

const defaultState: State = {
    isLoading: false,
    activeStep: SelectPlayersSteps.SelectPlayersStep,
    preconnectedPlayers: [],
    selectedPlayers: [],
    amountOfAvailablePlayers: -1,
    availableRegularPlayerSlots: null,
    isUnlimitedRegularPlayerSlots: false,
    removePlayerLoading: false,
    isNotCreditsModalOpen: false,
    pendingToRemove: [],
    processedPlayers: [],
    playerCreditsAvailable: null,
    addPlayersState: {
        pendingPlayersList: [],
        suggestionPlayersList: [],
        isLoading: false,
        processing: false,
        keyword: '',
        showSuggestionsList: false,
        addedPlayersIds: []
    }
}

const stateController = new StateController<State>(
    'AGENCY/REONBOARDING-FLOW/SELECT-PLAYERS',
    defaultState
);

class Helpers {
    public static arrayContains(source: Array<PreconnectedPlayerItem>, item: PreconnectedPlayerItem) {
        return source.some(x => x.playerId == item.playerId &&
            x.playerName == item.playerName &&
            x.currentSquad?.name == item.currentSquad?.name &&
            x.opportunityAdsQty == item.opportunityAdsQty &&
            x.currentSquad?.logo == item.currentSquad?.logo
        )
    }
}

class Actions {
    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState({ ...defaultState }));
        }
    }

    public static setActiveStep(step: SelectPlayersSteps) {
        return (dispatch) => {
            if (step === SelectPlayersSteps.CongratulationsStep) {
                dispatch(ReonboardingActions.setIsCongratulationsScreen(true))
            }
            dispatch(stateController.setState({ activeStep: step }));
            dispatch(ReonboardingActions.disposeScrollingEffect());
        }
    }

    public static init() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isLoading: true }));

                await Promise.all(
                    [
                        dispatch(Actions.getPreconnectedPlayers()),
                        dispatch(Actions.getRegularPlayerSlotStats()),
                        dispatch(Actions.loadAmountOfAvailablePlayers())
                    ]
                );

            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        }
    }

    public static getPreconnectedPlayers() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isLoading: true }));

                const { players } = await ReonboardingService.getPreconnectedPlayers();
                dispatch(stateController.setState({ preconnectedPlayers: players }));

            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        }
    }

    public static getRegularPlayerSlotStats() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const { availableRegularPlayerSlots, isUnlimitedRegularPlayerSlots } = await OnboardingService.getRegularPlayerSlotStats();
                dispatch(stateController.setState({
                    availableRegularPlayerSlots: availableRegularPlayerSlots,
                    isUnlimitedRegularPlayerSlots: isUnlimitedRegularPlayerSlots,
                }));

            } catch (err) {
                console.error(err)
            }
        }
    }

    public static loadAmountOfAvailablePlayers = () => {
        return async (dispatch) => {
            try {
                const data = await OnboardingService.getSubscriptionStats();
                let amountOfAvailablePlayers = 0;
                const hasSubscription = !!data;
                if (hasSubscription) {
                    amountOfAvailablePlayers = data.totalSlots - data.usedSlots;
                }
                dispatch(stateController.setState(({ amountOfAvailablePlayers })));
            } catch (err) {
                console.error(err);
            }
        }
    }

    public static removePlayer(item: PreconnectedPlayerItem) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const substate = Selectors.getRoot(getState());
                dispatch(stateController.setState({ pendingToRemove: [...substate.pendingToRemove, item] }));
                const selectedPlayers = Selectors.getRoot(getState()).selectedPlayers;
                const isSelectedPlayers = selectedPlayers.find(player => player.playerId === item.playerId);

                await ReonboardingService.removePlayer(item.playerId);

                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    processedPlayers: [...prevState.processedPlayers, item]
                })));

                dispatch(userActivityInsert({
                    PageName: 'Verification Flow [Select Players]',
                    Message: 'Not Our Player',
                    PlayerId: item.playerId,
                }));

                if (isSelectedPlayers) {
                    dispatch(Actions.onSelectedPlayers(item));
                }
            } catch (err) {
                console.error(err)
            }
        }
    }

    public static onSelectedPlayers(player: PreconnectedPlayerItem) {
        return (dispatch, getState: () => AppState) => {
            const substate = Selectors.getRoot(getState());
            const selectedPlayersState = substate.selectedPlayers;
            let selectedPlayers = [...selectedPlayersState];
            const isSelectedPlayers = selectedPlayers.find(item => item.playerId === player.playerId)

            if (!isSelectedPlayers) {
                selectedPlayers.push(player);

                dispatch(stateController.setState({
                    selectedPlayers: selectedPlayers,
                }));

                dispatch(userActivityInsert({
                    PageName: 'Verification Flow [Select Players]',
                    Message: 'Selected Player',
                    PlayerId: player.playerId,
                }));
            } else {
                dispatch(stateController.setState({
                    selectedPlayers: selectedPlayers.filter(item => item.playerId !== player.playerId),
                }));

                dispatch(userActivityInsert({
                    PageName: 'Verification Flow [Select Players]',
                    Message: 'Deselected Player',
                    PlayerId: player.playerId,
                }));
            }
        }
    }

    public static openNotCreditsModal() {
        return (dispatch) => {
            dispatch(stateController.setState({ isNotCreditsModalOpen: true }));
        }
    }

    public static closeNotCreditsModal() {
        return (dispatch) => {
            dispatch(stateController.setState({ isNotCreditsModalOpen: false }));
        }
    }

    public static openRequestCreditsModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(userActivityInsert({
                PageName: 'Verification Flow [Select Players]',
                Message: 'Request More Credits',
            }));
            dispatch(RequestCreditsModalActions.openModal('Verification Flow [Select Players]'));
            dispatch(stateController.setState({ isNotCreditsModalOpen: false }));
        }
    }

    public static onContinueClick() {
        return (dispatch, getState: () => AppState) => {
            const amountOfAvailablePlayers = Selectors.getRoot(getState()).amountOfAvailablePlayers;
            const selectedPlayers = Selectors.getRoot(getState()).selectedPlayers;

            if (amountOfAvailablePlayers > 0) {
                dispatch(Actions.setActiveStep(SelectPlayersSteps.CongratulationsStep));
            } else {
                dispatch(Actions.setActiveStep(SelectPlayersSteps.HasNoPriorityCreditsStep));
            }

            dispatch(userActivityInsert({
                PageName: 'Verification Flow [Select Players]',
                Message: `Continued (${selectedPlayers.map(player => player.playerName).join(', ')})`,
            }));
        }
    }

    public static remindMeLater() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch({ type: AGENCY_FORCE_REONBOARDING_POPUP_FALSE });
                ReonboardingService.remindMeLater();

                dispatch(userActivityInsert({
                    PageName: 'Verification Flow [Select Players]',
                    Message: 'Remind Me Later',
                }));

                historyAccessor.push(agencyPaths.landingPage);

            } catch (err) {
                console.error(err)
            }
        }
    }

    public static selectPlayersToPitch() {
        return (dispatch) => {

            dispatch(userActivityInsert({
                PageName: 'Verification Flow [Select Players to Pitch]',
                Message: 'Opened Players List',
            }));

            dispatch(ReonboardingActions.setActiveStepper(ReonboardingSubsteppers.SelectPitchablePlayers));
            dispatch(ReonboardingActions.setIsCongratulationsScreen(false))
        }
    }

    public static verifyPlayersNowClick() {
        return (dispatch) => {
            dispatch(ReonboardingActions.setActiveStepper(ReonboardingSubsteppers.VerifyPlayers));
        }
    }

    public static onAddPlayers() {
        return (dispatch) => {
            dispatch(userActivityInsert({
                PageName: 'Verification Flow [Select Players]',
                Message: 'Add Players Here',
            }));
            dispatch(Actions.setActiveStep(SelectPlayersSteps.AddPlayers));
        }
    }

    //--------------------------AddPlayersStep---------------------------------------------------------------------

    public static disposeAddPlayersState() {
        return (dispatch) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                addPlayersState: {
                    ...prevState.addPlayersState,
                    pendingPlayersList: [],
                    suggestionPlayersList: [],
                    isLoading: false,
                    processing: false,
                    keyword: '',
                    showSuggestionsList: false,
                    addedPlayersIds: []
                }
            })))
        }
    }

    public static addPlayerToList(player: AddedPlayerItem) {

        return (dispatch, getState: () => AppState) => {
            dispatch(userActivityInsert({
                PageName: `Verification Flow [Select Players - Add Players]`,
                Message: `Added Player`,
                PlayerId: player.playerId,
            }));

            const pendingPlayersListState = Selectors.getRoot(getState()).addPlayersState.pendingPlayersList
            const addedPlayersIdsState = Selectors.getRoot(getState()).addPlayersState.addedPlayersIds

            const pendingPlayersList = [...pendingPlayersListState];
            const addedPlayersIds = [...addedPlayersIdsState];

            if (!addedPlayersIds.includes(player.playerId)) {
                pendingPlayersList.push(player);
                addedPlayersIds.push(player.playerId);
            } 
            // else {
                // pendingPlayersList.splice(pendingPlayersList.indexOf(player), 1)
                // addedPlayersIds.splice(addedPlayersIds.indexOf(player.playerId), 1)
            // }
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                addPlayersState: {
                    ...prevState.addPlayersState,
                    pendingPlayersList: pendingPlayersList,
                    addedPlayersIds: addedPlayersIds,
                }
            })))
        }
    }

    public static removePlayerFromList(playerId: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(userActivityInsert({
                PageName: `Verification Flow [Select Players - Add Players]`,
                Message: `Removed Player`,
                PlayerId: playerId,
            }));

            const substate = Selectors.getRoot(getState()).addPlayersState

            const players = substate.pendingPlayersList.filter(player => player.playerId !== playerId)
            const ids = substate.addedPlayersIds.filter(item => item !== playerId)

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                addPlayersState: {
                    ...prevState.addPlayersState,
                    pendingPlayersList: players,
                    addedPlayersIds: ids,
                }
            })))
        }
    }

    public static addAllPlayers() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    addPlayersState: {
                        ...prevState.addPlayersState,
                        processing: true,
                    }
                })))

                const substate = Selectors.getRoot(getState())
                const request = Selectors.getRoot(getState()).addPlayersState.addedPlayersIds

                const addedPlayersList = await ReonboardingService.addPreconnectedPlayers(request);

                const playerNamesList = addedPlayersList.players.map(item => item.playerName).join(', ');
                dispatch(userActivityInsert({
                    PageName: `Verification Flow [Select Players - Add Players]`,
                    Message: `Added Players (${playerNamesList})`,
                }));

                const addedPlayers = addedPlayersList.players
                const {
                    availableRegularPlayerSlots,
                    selectedPlayers,
                    preconnectedPlayers
                } = substate

                const playerCreditsAvailable = availableRegularPlayerSlots - selectedPlayers.length

                const newSelectedPlayers = addedPlayers.slice(0, playerCreditsAvailable)

                const preconnectedPlayersList = [...addedPlayers, ...preconnectedPlayers]
                const selectedPlayersList = [...newSelectedPlayers, ...selectedPlayers]

                dispatch(stateController.setState({
                    preconnectedPlayers: preconnectedPlayersList,
                    selectedPlayers: selectedPlayersList,
                }));

                dispatch(Actions.setActiveStep(SelectPlayersSteps.SelectPlayersStep));
                dispatch(Actions.disposeAddPlayersState())

            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    addPlayersState: {
                        ...prevState.addPlayersState,
                        processing: false,
                    }
                })))
            }
        }
    }

    public static cancellAddindPlayers() {
        return (dispatch, getState: () => AppState) => {
            dispatch(userActivityInsert({
                PageName: `Verification Flow [Select Players - Add Players]`,
                Message: `Cancelled`,
            }));
            dispatch(Actions.disposeAddPlayersState())
            dispatch(Actions.setActiveStep(SelectPlayersSteps.SelectPlayersStep));
        }
    }

    public static createDebounce = () => _.debounce((dispatch, keyword: string) => dispatch(Actions.loadPlayersToAdd(keyword)), 1000)

    public static fetchDebounced = Actions.createDebounce()

    public static onKeywordChange(keyword: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                addPlayersState: {
                    ...prevState.addPlayersState,
                    keyword: keyword,
                }
            })))

            if (keyword.length > 0) {
                if (keyword.length > 2) { // start searching after 3 letters typed
                    Actions.fetchDebounced(dispatch, keyword)
                }
            } else {
                dispatch(Actions.onKeywordClear())
            }
        }
    }

    public static setKeyword(keyword: string) {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                addPlayersState: {
                    ...prevState.addPlayersState,
                    keyword: keyword,
                }
            })))
        }
    }

    public static onKeywordClear() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                addPlayersState: {
                    ...prevState.addPlayersState,
                    keyword: '',
                    showSuggestionsList: false,
                    isLoading: false
                }
            })))
        }
    }

    public static loadPlayersToAdd(keyword: any) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    addPlayersState: {
                        ...prevState.addPlayersState,
                        isLoading: true,
                    }
                })))

                const data = await ReonboardingService.getSuggestedPlayersByKeyword(keyword);

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    addPlayersState: {
                        ...prevState.addPlayersState,
                        suggestionPlayersList: data.players,
                        showSuggestionsList: true
                    }
                })))
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    addPlayersState: {
                        ...prevState.addPlayersState,
                        isLoading: false,
                    }
                })))
            }
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState): State => state.agency.reonboarding.selectPlayers;
    public static isLoading = (state: AppState): boolean => Selectors.getRoot(state).isLoading;
    public static getCurrentStep = (state: AppState) => Selectors.getRoot(state).activeStep;
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    stateController as Controller,
    Selectors as Selectors,
    Helpers as Helpers,
};