import { combineReducers } from 'redux';
import * as HomePageController from './home-page/home-page.controller'
import AgencyFinderReducer, { AgencyFinderState } from './agency-finder/redux/root.controller'
import * as MyAgencyController from './my-agency/my-agency.controller'
import * as RepresentationScreenController from './representation-screen/representation-screen.controller'
import * as SettingsController from './settings-page/settings-page.controller'
import * as LatestActivityController from './components/latest-activity/latest-activity.controller'
import * as AgencyConfirmController from './agency-confirm/agency-confirm.controller'
import * as OnboardingController from './onboarding/onboarding.controller'
import * as OnboardingByEmailController from './onboarding-by-email/onboarding-by-email.controller'
import * as InviteAgencyController from 'pages/player-v2/invite-agency/invite-agency.controller';
import * as ConfirmEmailController from 'pages/player-v2/email-confimation/email-confirmation.controller';
import * as ConfirmDealController from 'pages/player-v2/deal-confirmation/deal-confirmation.controller';
import * as PlayerRemovedScreenController from 'pages/player-v2/player-removed-screen/player-removed-screen.controller';
import * as AgreeDocumentsModalController from 'pages/player-v2/agree-documents/agree-documents.controller';
import * as InvitePlayersController from 'pages/player-v2/invite-players/invite-players.controller';
import * as PlayerPreferencesController from 'pages/player-v2/player-preferences/player-preferences.controller';

export type PlayerState = {
    homePage: HomePageController.State
    agencyFinder: AgencyFinderState,
    myAgency: MyAgencyController.State,
    representationScreen: RepresentationScreenController.State,
    settings: SettingsController.State,
    latestActivity: LatestActivityController.State,
    agencyConfirm: AgencyConfirmController.State,
    onboarding: OnboardingController.State
    onboardingByEmail: OnboardingByEmailController.State
    inviteAgency: InviteAgencyController.State,
    confirmEmail: ConfirmEmailController.State,
    confirmDeal: ConfirmDealController.State,
    playerRemoved: PlayerRemovedScreenController.State,
    agreeDocumentsModal: AgreeDocumentsModalController.State,
    invitePlayers: InvitePlayersController.State,
    playerPreferences: PlayerPreferencesController.State,
}

export default combineReducers({
    homePage: HomePageController.Reducer,
    agencyFinder: AgencyFinderReducer,
    myAgency: MyAgencyController.Reducer,
    representationScreen: RepresentationScreenController.Reducer,
    settings: SettingsController.Reducer,
    latestActivity: LatestActivityController.Reducer,
    onboarding: OnboardingController.Reducer,
    onboardingByEmail: OnboardingByEmailController.Reducer,
    inviteAgency: InviteAgencyController.Reducer,
    agencyConfirm: AgencyConfirmController.Reducer,
    confirmEmail: ConfirmEmailController.Reducer,
    confirmDeal: ConfirmDealController.Reducer,
    playerRemoved: PlayerRemovedScreenController.Reducer,
    agreeDocumentsModal: AgreeDocumentsModalController.Reducer,
    invitePlayers: InvitePlayersController.Reducer,
    playerPreferences: PlayerPreferencesController.Reducer,
})