import { 
    CLUB_FORCE_MANUAL_REONBOARDING_POPUP_FALSE, 
    CLUB_FORCE_MANUAL_REONBOARDING_POPUP_TRUE, 
    CLUB_FORCE_REONBOARDING_POPUP_FALSE, 
    CLUB_FORCE_REONBOARDING_POPUP_TRUE 
} from "store/actionTypes";


export const getHideReonboardingPopupActionType = (isManual: boolean) => {
    return isManual 
       ? CLUB_FORCE_MANUAL_REONBOARDING_POPUP_FALSE 
       : CLUB_FORCE_REONBOARDING_POPUP_FALSE;
}

export const getOpenReonboardingPopupActionType = (isManual: boolean) => {
    return isManual 
       ? CLUB_FORCE_MANUAL_REONBOARDING_POPUP_TRUE 
       : CLUB_FORCE_REONBOARDING_POPUP_TRUE;
}

export const getUserActivityPageName = (isManual: boolean) => {
    return isManual 
        ? 'Manual Re-Onboarding [Select Flow]' 
        : 'Re-Onboarding [Select Flow]';
}