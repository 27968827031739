import * as redux from 'redux';
import * as notificationController from './notification.controller'
import * as bookSessionController from './book-session.controller'
import * as pitchInvitationsController from './pitch-invitation.controller'
import * as dashboardController from './dashboard.controller'
import * as commonController from './common.controller'
import * as homePagePlayersController from './home-page-players.controller'
import * as connectionController from './connections.controller'
import * as agencyUsersController from './agency-users.controller'
import * as pitchOpportunitiesVerirfiedController from './pitch-opportunities-verified.controller'
import * as alertController from './alert.controller'
import * as playerRequestController from './player-request.controller'
import * as playerMarketBannerController from './player-market-banner-controller'
import * as pendingVerification from 'pages/agency/authorized-agent/home-page/redux/pending-verification.controller'
import * as representationRequestController from './representation-request.controller'
import * as headCoachesController from './head-coaches.controller'

export class AgencyLandingPageState {
    notification: notificationController.State
    bookSession: bookSessionController.State
    pitchInvitations: pitchInvitationsController.State
    dashboard: dashboardController.State
    common: commonController.State
    homePagePlayers: homePagePlayersController.State
    connections: connectionController.State
    agencyUsers: agencyUsersController.State
    alert: alertController.State
    pitchOpportunitiesVerirfied: pitchOpportunitiesVerirfiedController.State
    playerRequest: playerRequestController.State
    pendingVerification: pendingVerification.State
    playerMarketBanner: playerMarketBannerController.State
    representationRequest: representationRequestController.State
    headCoaches: headCoachesController.State
}

export function combineReducers() {

    let landingPageReducer = {
        notification: notificationController.Reducer,
        bookSession: bookSessionController.Reducer,
        pitchInvitations: pitchInvitationsController.Reducer,
        dashboard: dashboardController.Reducer,
        common: commonController.Reducer,
        homePagePlayers: homePagePlayersController.Reducer,
        connections: connectionController.Reducer,
        agencyUsers: agencyUsersController.Reducer,
        alert: alertController.Reducer,
        pitchOpportunitiesVerirfied: pitchOpportunitiesVerirfiedController.Reducer,
        playerRequest: playerRequestController.Reducer,
        playerMarketBanner: playerMarketBannerController.Reducer,
        pendingVerification: pendingVerification.Reducer,
        representationRequest: representationRequestController.Reducer,
        headCoaches: headCoachesController.Reducer,
    }

    return redux.combineReducers(landingPageReducer)
} 