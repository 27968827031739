import _ from 'lodash'
import { copyToClipboard as copy } from 'utils/copy-to-clipboard';
import { AppState } from 'root.reducer'
import { StateController } from 'utils/action-declaration'
import { notificationCreate } from 'app/notifications/notifications.actions'
import { AgencyOnboardingService } from 'api/admin/onboard-agency/agency-onboarding.service'
import { AgentModel, AgencyDataModel, AdminCreateAgencyState, AddAgentModalState, AreaItem, PhoneCodeModel } from 'api/admin/onboard-agency/models'
import validator from 'services/validator';
import {SuggestionModel} from 'api/admin/search-agency-suggestions/models';
import { AgenciesSubscriptionEnum, AgenciesSubscriptionModel } from 'admin-v2/pages/agency-permissions/models';
import { AgencyPermissions as AgencyPermissionsService } from 'admin-v2/pages/agency-permissions/agency-permissions.service';

export interface OnboardAgencyState {
    agencyId: number;
    agentToOnboard: Array<AgentModel>;
    subscriptionPlanId: AgenciesSubscriptionEnum;
}

class AdminOnboardAgencyState {
    agents: Array<AgentModel>;
    suggestionItems: Array<SuggestionModel>;
    step: number;
    agencyId: number;
    regularPlayersSlotsAmount?: number;
    processing: boolean;
    isModalOpen: boolean;
    isDataReceived: boolean;
    didTryToProcess: boolean;
    isOnboardingToProcess: boolean;
    isOnboardingProcessDone: boolean;
    keyword: string;
    subscriptionPlanName: string;
    agencyData: AgencyDataModel;
    onboardAgency: OnboardAgencyState;
    isUnlimitedRegularPlayerSlots: boolean;

    areasList: Array<AreaItem>;
    phoneCodes: Array<PhoneCodeModel>;
    createAgencyModal: AdminCreateAgencyState;
    addAgentModal: AddAgentModalState;
    agenciesSubscriptions: AgenciesSubscriptionModel[];
}

const defaultState: AdminOnboardAgencyState = {
    agents: [],
    suggestionItems: [],
    step: 1,
    agencyId: null,
    regularPlayersSlotsAmount: null, // TODO: maybe delate
    processing: false,
    isModalOpen: false,
    isDataReceived: false,
    didTryToProcess: false,
    isOnboardingToProcess: false,
    isOnboardingProcessDone: false,
    isUnlimitedRegularPlayerSlots: false,
    keyword: '',
    subscriptionPlanName: '',
    agencyData: {
        agencyId: null,
        agencyName: '',
        isVerified: false,
        onboardingLink: '',
        totalPlayerSlots: 0,
        regularPlayerSlots: null,
        isUnlimitedRegularPlayerSlots: false,
        selectedAgent: null,
        subscriptionPlanId: AgenciesSubscriptionEnum.None
    },
    onboardAgency: {
        agencyId: null,
        agentToOnboard: [],
        subscriptionPlanId: AgenciesSubscriptionEnum.None
    },

    areasList: [],
    phoneCodes: [],
    createAgencyModal: {
        agencyName: '',
        isModalOpen: false,
        isNewAgency: true,
        agencyAreaName: '',
        areaId: null,
        websiteUrl: '',
        processing: false,
        agencyNameError: false,
    },
    addAgentModal: {
        isModalOpen: false,
        agentFirstName: '',
        agentLastName: '',
        jobTitle: '',
        phoneCode: '',
        phoneNumber: '',
        phoneCodeAreaId: null,
        agentEmail: '',
        processing: false,
        isEmailValid: true,
        isMasterUser: false,
        isFirstUser: false,
        emailError: false,
        firstNameError: false,
        lastNameError: false,
        jobTitleError: false,
    },
    agenciesSubscriptions: []
}

const stateController = new StateController<AdminOnboardAgencyState>(
    'ADMIN_V2/ONBOARD_AGENCY_MODAL',
    defaultState
)

class Actions {

    public static loadInitialData() {
        return async (dispatch, getState: () => AppState) => {

            const phoneCodes = await AgencyOnboardingService.getPhoneCodes();
            const areas = await AgencyOnboardingService.getAreas();
            const agenciesSubscriptions = await AgencyPermissionsService.getAllAgenciesSubscriptions();

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                areasList: areas,
                phoneCodes: phoneCodes,
                agenciesSubscriptions: agenciesSubscriptions,
            })))
        }
    }

    public static openModal() {
        return (dispatch) => {
            dispatch(Actions.loadInitialData())
            dispatch(stateController.setState({ isModalOpen: true }))

        }
    }
    public static closeModal() {
        return (dispatch) => {
            dispatch(stateController.setState({ ...defaultState }))
        }
    }

    public static onKeywordChange(value: string) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ keyword: value }))
            if (value.length > 2) {
                Actions.loadFirstSuggestionsDebounceFunc(dispatch, value)
            } else {
                dispatch(stateController.setState({ suggestionItems: [] }))
            }
        }
    }
    private static loadFirstSuggestionsDebounceFunc = _.debounce((dispatch, keyword) => dispatch(Actions.loadAgencySuggestions(keyword)), 1000)

    public static loadAgencySuggestions(keyword: string) {
        return async (dispatch, getState: () => AppState) => {
            const state = getState().admin.onboardAgencyModal
            if (state.step === 2 && !state.isDataReceived) {
                return
            }
            try {
                dispatch(stateController.setState({ processing: true }))
                const data = await AgencyOnboardingService.getAgencyOnboardingSuggestions(keyword)
                dispatch(stateController.setState({ suggestionItems: data }))
            } finally {
                dispatch(stateController.setState({ processing: false }))
            }
        }
    }

    public static copyToClipboard() {
        return (dispatch, getState: () => AppState) => {
            copy(getState().admin.onboardAgencyModal.agencyData.onboardingLink)
            dispatch(notificationCreate({ message: 'Copied to clipboard', level: 'info' }))
        }
    }
    public static loadData(agencyId: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                isDataReceived: false,
                step: 2,
                didTryToProcess: false,
                onboardAgency: {
                    ...prevState.onboardAgency,
                    agencyId: null,
                    agentToOnboard: [],
                    subscriptionPlanId: AgenciesSubscriptionEnum.None
                }
            })))

            await dispatch(Actions.loadAgencyData(agencyId))
            await dispatch(Actions.loadAgents(agencyId))
            await dispatch(Actions.setDefaultSubscriptionPlan(getState().admin.onboardAgencyModal.agencyData))

            dispatch(stateController.setState({ isDataReceived: true, isOnboardingProcessDone: false }))
        }
    }
    private static loadAgencyData(agencyId: number) {
        return async (dispatch, getState: () => AppState) => {
            const agencyData = await AgencyOnboardingService.loadAgencyData(agencyId);

            // set initial value to empty for unverified agencies
            const regularPlayersSlotsAmount = !agencyData.regularPlayerSlots && !agencyData.isVerified ? null : agencyData.regularPlayerSlots;
            dispatch(stateController.setState({ agencyData: agencyData }))
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                onboardAgency: {
                    ...prevState.onboardAgency,
                    agencyId: agencyData.agencyId
                },
                regularPlayersSlotsAmount,
                isUnlimitedRegularPlayerSlots: agencyData.isVerified && agencyData.isUnlimitedRegularPlayerSlots
            })))
        }
    }
    public static loadAgents(agencyId: number) {
        return async (dispatch, getState: () => AppState) => {
            const data = await AgencyOnboardingService.loadAgents(agencyId)
            const agents = data.map((item, index) => ({
                ...item,
                key: index
            }))

            agents && await dispatch(Actions.setDefaultSelectedAgent(agents))

            dispatch(stateController.setState({ agents: agents }))
        }
    }

    private static setDefaultSelectedAgent(agents: any) {
        return async (dispatch, getState: () => AppState) => {

            const onboardAgents = agents.filter(x => x.onboardingLink === null)

            if (onboardAgents.length > 0) {
                const agentsIds = onboardAgents.map(x => x.agentId)
                const agentId = Math.max.apply(null, agentsIds) /* finding the last added agentId */
                const onboardAgent = agents.filter(x => x.agentId === agentId)

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    agencyData: {
                        ...prevState.agencyData,
                        selectedAgent: onboardAgent,
                        onboardingLink: onboardAgent.onboardingLink
                    },
                    onboardAgency: {
                        ...prevState.onboardAgency,
                        agentToOnboard: onboardAgent
                    }

                })))
            } else {
                const defaultAgent = agents[0];
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    agencyData: {
                        ...prevState.agencyData,
                        selectedAgent: defaultAgent,
                        onboardingLink: defaultAgent?.onboardingLink
                    }
                })))
            }

        }
    }
    private static setDefaultSubscriptionPlan(agencyData: AgencyDataModel) {
        return async (dispatch, getState: () => AppState) => {
            const subscriptionPlans = getState().admin.onboardAgencyModal.agenciesSubscriptions;
            const subscription = subscriptionPlans.find(item => item.id === agencyData.subscriptionPlanId);
            if (subscription) {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    subscriptionPlanName: subscription.name,
                    onboardAgency: {
                        ...prevState.onboardAgency,
                        subscriptionPlanId: subscription.id
                    }
                })))
            } else {
                dispatch(stateController.setState({ subscriptionPlanName: 'none' }))
            }

        }
    }
    public static setSubscriptionPlan(value, subscriptionPlanId) {
        return async (dispatch, getState: () => AppState) => {
            const { agenciesSubscriptions } = getState().admin.onboardAgencyModal;
            const subscription = agenciesSubscriptions.find(item => item.id === subscriptionPlanId);

            await dispatch(stateController.setState(prevState => ({
                ...prevState,
                subscriptionPlanName: value,
                onboardAgency: {
                    ...prevState.onboardAgency,
                    subscriptionPlanId: subscriptionPlanId,
                },
                agencyData: {
                    ...prevState.agencyData,
                    totalPlayerSlots: subscription.priorityCreditAmount,
                    regularPlayerSlots: subscription.regularCreditAmount,
                }
            })));
        }
    }

    public static selectAgentToOnboard(agent: AgentModel) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                agencyData: {
                    ...prevState.agencyData,
                    selectedAgent: agent,
                    onboardingLink: agent.onboardingLink
                }
            })))
            if (agent.onboardingLink === null) {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    onboardAgency: {
                        ...prevState.onboardAgency,
                        agentToOnboard: [agent]
                    }
                })))
            }
        }
    }

    public static onboardAgency() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ didTryToProcess: true }));

            if (Selectors.isSubscriptionValidateError(getState()) || Selectors.isAgentValidateError(getState())) {
                return;
            }
            try {
                dispatch(stateController.setState({ isOnboardingToProcess: true }));

                const {
                    onboardAgency,
                } = getState().admin.onboardAgencyModal;

                const data = await AgencyOnboardingService.onboardAgency(
                    onboardAgency.agencyId,
                    onboardAgency.agentToOnboard,
                    onboardAgency.subscriptionPlanId
                );

                dispatch(stateController.setState(prevState => {
                    const selectedAgent = prevState.agents.find(x => x.agentId === onboardAgency.agentToOnboard[0].agentId);
                    selectedAgent.onboardingLink = data;
                    selectedAgent.isLinkCanBeGenerated = false;

                    return {
                        ...prevState,
                        agents: [...prevState.agents],
                        agencyData: {
                            ...prevState.agencyData,
                            onboardingLink: data,
                            isVerified: true,
                        }
                    }
                }));

                dispatch(notificationCreate({ message: 'Link generated', level: 'success' }))
            } finally {
                dispatch(stateController.setState({ isOnboardingToProcess: false, isOnboardingProcessDone: true }))
            }
        }
    }
    public static onboardAgent() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ didTryToProcess: true }))

            try {
                dispatch(stateController.setState({ isOnboardingToProcess: true }))
                const { onboardAgency, agencyData } = getState().admin.onboardAgencyModal
                const selectedAgentId = agencyData.selectedAgent.agentId;
                const data = await AgencyOnboardingService.onboardAgent(
                    selectedAgentId,
                    onboardAgency.agencyId
                )
                dispatch(stateController.setState(prevState => {
                    const selectedAgent = prevState.agents.find(x => x.agentId === selectedAgentId);
                    selectedAgent.onboardingLink = data;
                    selectedAgent.isLinkCanBeGenerated = false;
                    return {
                        ...prevState,
                        agents: [...prevState.agents],
                        agencyData: {
                            ...prevState.agencyData,
                            onboardingLink: data
                        }
                    }
                }))
                dispatch(Actions.loadAgents(onboardAgency.agencyId))
                dispatch(notificationCreate({ message: 'Link generated', level: 'success' }))
            } finally {
                dispatch(stateController.setState({ isOnboardingToProcess: false, isOnboardingProcessDone: true }))
            }
        }
    }

    //====== Create Agency Modal =======================================================================================================

    public static openCreateAgencyModal() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                step: 3,
                createAgencyModal: {
                    ...prevState.createAgencyModal,
                    isModalOpen: true
                }
            })))
        }
    }
    public static closeCreateAgencyModal() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                step: 1,
                keyword: '',
                createAgencyModal: {
                    ...prevState.createAgencyModal,
                    isModalOpen: false,
                    isNewAgency: true,
                    agencyAreaName: '',
                    areaId: null,
                    websiteUrl: '',
                    agencyNameError: false,
                }
            })))
        }
    }
    public static saveAgencyCard(agencyId: number) {
        return async (dispatch, getState: () => AppState) => {
            await dispatch(Actions.loadData(agencyId))
            const agencyName = getState().admin.onboardAgencyModal.agencyData.agencyName
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                step: 2,
                createAgencyModal: defaultState.createAgencyModal,
                keyword: agencyName
            })))
            dispatch(Actions.loadAgencySuggestions(agencyName));
        }
    }
    public static setAgencyName(value: string) {
        return (dispatch) => {
            dispatch(Actions.validateAgencyName(value))
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                createAgencyModal: {
                    ...prevState.createAgencyModal,
                    agencyName: value,
                    isNewAgency: true
                }
            })))
        }
    }
    public static selectCountry(value: string, areaId: number) {
        return (dispatch) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                createAgencyModal: {
                    ...prevState.createAgencyModal,
                    areaId: areaId,
                    agencyAreaName: value,
                }
            })))
        }
    }
    public static setAgencyWebsiteUrl(url: string) {
        return (dispatch) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                createAgencyModal: {
                    ...prevState.createAgencyModal,
                    websiteUrl: url
                }
            })))
        }
    }

    public static validateAgencyName(value: string) {
        return (dispatch) => {
            const isAgencyNameError = !value.trim();	        
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                createAgencyModal: {
                    ...prevState.createAgencyModal,
                    agencyNameError: isAgencyNameError
                }
            })))
        }
    }
    public static createAgency() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                createAgencyModal: {
                    ...prevState.createAgencyModal,
                    processing: true
                }
            })))
            try {
                const substate = getState().admin.onboardAgencyModal.createAgencyModal

                const request = {
                    agencyName: substate.agencyName,
                    areaId: substate.areaId,
                    websiteUrl: substate.websiteUrl,
                }

                const data = await AgencyOnboardingService.createNewAgency(request)

                if (data.isSuccess && !data.isAgencyExist) {
                    await dispatch(Actions.saveAgencyCard(data.agencyId))
                } else {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        createAgencyModal: {
                            ...prevState.createAgencyModal,
                            isNewAgency: !data.isAgencyExist
                        }
                    })))
                }

            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    createAgencyModal: {
                        ...prevState.createAgencyModal,
                        processing: false
                    }
                })))
            }
        }
    }

    //====== Add Agent Modal ============================================================================================================

    public static openAddAgentModal(value: boolean) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                step: 4,
                addAgentModal: {
                    ...prevState.addAgentModal,
                    isModalOpen: true,
                    isFirstUser: value,
                    isMasterUser: value ? value : false
                }
            })))
        }
    }
    public static closeAddAgentModal() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                step: 2,
                addAgentModal: {
                    ...prevState.addAgentModal,
                    isModalOpen: false,
                    agentFirstName: '',
                    agentLastName: '',
                    jobTitle: '',
                    phoneCode: '',
                    phoneNumber: '',
                    phoneCodeAreaId: null,
                    agentEmail: '',
                    processing: false,
                    isEmailValid: true,
                    isMasterUser: false,
                    isFirstUser: false,
                    emailError: false,
                    firstNameError: false,
                    lastNameError: false,
                    jobTitleError: false,
                }
            })))
        }
    }

    public static onChangeAddAgentModalInput(value: { [T in keyof AddAgentModalState]?: AddAgentModalState[T] }) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                addAgentModal: {
                    ...prevState.addAgentModal,
                    ...value,
                    isEmailValid: true,
                }
            })))
        }
    }

    public static onChangeFirstNameInput(value: string) {
        return async (dispatch, getState: () => AppState) => {
            const firstNameValid = !!value.trim()
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                addAgentModal: {
                    ...prevState.addAgentModal,
                    agentFirstName: value,
                    firstNameError: !firstNameValid,
                }
            })))
        }
    }
    public static onChangeLastNameInput(value: string) {
        return async (dispatch, getState: () => AppState) => {
            const lastNameValid = !!value.trim()
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                addAgentModal: {
                    ...prevState.addAgentModal,
                    agentLastName: value,
                    lastNameError: !lastNameValid,
                }
            })))
        }
    }
    public static onChangeJobTitleInput(value: string) {
        return async (dispatch, getState: () => AppState) => {
            const jobValid = !!value.trim()
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                addAgentModal: {
                    ...prevState.addAgentModal,
                    jobTitle: value,
                    jobTitleError: !jobValid,
                }
            })))
        }
    }

    public static onChangeEmailInput(value: string) {
        return async (dispatch, getState: () => AppState) => {
            const isEmailValid = validator.isValidEmail(value)
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                addAgentModal: {
                    ...prevState.addAgentModal,
                    agentEmail: value,
                    emailError: !isEmailValid
                }
            })))
        }
    }
    public static onChangePhoneInput(value: string) {
        return async (dispatch, getState: () => AppState) => {
            const phone = value.replace(/[^0-9]/g, '');
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                addAgentModal: {
                    ...prevState.addAgentModal,
                    phoneNumber: phone,
                }
            })))
        }
    }
    public static onChangeUserType(value: boolean) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                addAgentModal: {
                    ...prevState.addAgentModal,
                    isMasterUser: !value
                }
            })))
        }
    }
    public static saveAgentCard() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                addAgentModal: {
                    ...prevState.addAgentModal,
                    processing: true
                }
            })))
            try {
                const substate = getState().admin.onboardAgencyModal.addAgentModal
                const id = getState().admin.onboardAgencyModal.agencyData.agencyId
                const request = {
                    agencyId: id,
                    firstName: substate.agentFirstName,
                    lastName: substate.agentLastName,
                    email: substate.agentEmail,
                    jobTitle: substate.jobTitle,
                    phoneCodeAreaId: substate.phoneCodeAreaId,
                    phone: substate.phoneNumber,
                    isMasterUser: substate.isMasterUser
                }

                const data = await AgencyOnboardingService.createNewAgent(request)

                if (data.isSuccess && !data.isEmailExist) {
                    dispatch(Actions.loadAgents(id))
                    dispatch(Actions.closeAddAgentModal())
                } else {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        addAgentModal: {
                            ...prevState.addAgentModal,
                            isEmailValid: !data.isEmailExist
                        }
                    })))
                }
            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    addAgentModal: {
                        ...prevState.addAgentModal,
                        processing: false
                    }
                })))
            }
        }
    }

}

class Selectors {

    public static isSubscriptionValidateError(state: AppState) {
        const onboardAgency = state.admin.onboardAgencyModal.onboardAgency;
        if (state.admin.onboardAgencyModal.didTryToProcess === false) {
            return false;
        } else {
            return !(state.admin.onboardAgencyModal.didTryToProcess === true && !!onboardAgency.subscriptionPlanId);
        }
    }
    public static isAgentValidateError(state: AppState) {
        const onboardAgency = state.admin.onboardAgencyModal.onboardAgency;
        if (state.admin.onboardAgencyModal.didTryToProcess === false) {
            return false;
        } else {
            return !(state.admin.onboardAgencyModal.didTryToProcess === true && onboardAgency.agentToOnboard.length > 0);
        }
    }
    public static isSpinerOn(state: AppState) {
        const onboardAgencyModal = state.admin.onboardAgencyModal
        return onboardAgencyModal.processing;

    }
}

const reducer = stateController.getReducer()

export {
    reducer as Reducer,
    AdminOnboardAgencyState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
}