import * as redux from 'redux';
import * as competitionList from './competition-list.controller';
import * as searchAutosuggest from './search-autosuggest.controller';
import * as gridController from './grid.controller';
import * as FilterContorller from './filter.controller';
import * as pitchOpportunityModal from './pitch-opportunity-modal.controller'
import * as UpsellBannerContorller from './upsell-banner.controller';

export class State {
    competitionList: competitionList.State;
    search: searchAutosuggest.State;
    grid: gridController.State;
    filter: FilterContorller.State;
    pitchOpportunityModal: pitchOpportunityModal.State;
    upsellBanner: UpsellBannerContorller.State;
}

export function combineReducers() {
    let reducers = {
        competitionList: competitionList.Reducer,
        grid: gridController.Reducer,
        search: searchAutosuggest.Reducer,
        filter: FilterContorller.Reducer,
        pitchOpportunityModal: pitchOpportunityModal.Reducer,
        upsellBanner: UpsellBannerContorller.Reducer
    };

    return redux.combineReducers(reducers);
}
