import { PageType } from 'constants/enums';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions'
import { GridToggleState } from './grid.controller';
import { OnlyAgentsAndClubsFilter, TransferTypeFilter, SearchMoreFilter, Structure } from './filter.controller'
import { currencyById } from 'constants/currency';
import MarketValueSelector from 'components/Forms/MarketValueSelector/MarketValueSelector';
import { AppState } from 'root.reducer';
import { getAuth } from 'store/auth/authReducer';
import ContractExpireSelector from 'components/Forms/ContractExpirySelector/ContractExpirySelectorV2';
import { FiltersSet } from 'api/search/model/suggestion-subsets';

export class FilterActivityService {
    private static page: string = 'Search';
    private static containers = {
        filter: 'Filter',
        sortBy: 'Sort by',
    }

    private static buildCommand(container: string, action: string, playerId?: number, clubId?: number, agencyId?: number) {
        return {
            Message: action,
            PageType: PageType.Search,
            PlayerId: playerId,
            ClubId: clubId,
            AgencyId: agencyId,
            PageName: `${this.page}${container ? ` [${container}]` : ''}`
        }
    }

    public static sortBy(message: string, gridState: GridToggleState) {
        return (dispatch) => {
            const state = FilterActivityService.getStatePage(gridState)
            const command = this.buildCommand(state ? `${state}-${this.containers.sortBy}` : this.containers.sortBy, message)
            dispatch(userActivityInsert(command))
        }
    }

    public static filterClubAgents(filter: OnlyAgentsAndClubsFilter) {
        return (dispatch) => {
            const message = filter.showOnlyClubs ? 'Only Clubs' : filter.showOnlyTrustedAgents ? 'Only Trusted Agents' : ''

            if (message) {
                const command = this.buildCommand(this.containers.filter, message)
                dispatch(userActivityInsert(command))
            }
        }
    }

    public static suggestionsShowMore() {
        return (dispatch) => {
            dispatch(userActivityInsert({
                Message: "Show more",
                PageType: PageType.Search,
                PageName: `Search [Suggestion]`
            }))
        }
    }

    public static selectHighlight(optionName: string) {
        return (dispatch) => {
            dispatch(userActivityInsert({
                Message: `Selected: ${optionName}`,
                PageType: PageType.Search,
                PageName: `Search [Highlight]`
            }))
        }
    }

    public static filterTransferTypeAndPosition(filter: TransferTypeFilter, gridState: GridToggleState) {
        return (dispatch, getState: () => AppState) => {
            const auth = getAuth(getState())
            const message = FilterActivityService.getTranferTypeMessage(filter, currencyById[auth.currency.id])
            const state = FilterActivityService.getStatePage(gridState)

            if (message) {
                const command = this.buildCommand(state ? `${state} ${this.containers.filter}` : this.containers.filter, message)
                dispatch(userActivityInsert(command))
            }
        }
    }

    public static filterMore(filter: SearchMoreFilter, structure: Structure, gridState: GridToggleState) {
        return (dispatch) => {
            const message = FilterActivityService.getMoreFilterMessage(filter, structure)
            const state = FilterActivityService.getStatePage(gridState)

            if (message) {
                const command = this.buildCommand(state ? `${state} ${this.containers.filter}` : this.containers.filter, message)
                dispatch(userActivityInsert(command))
            }
        }
    }

    private static getMoreFilterMessage(filter: SearchMoreFilter, structure: Structure) {
        const action = []
        if (filter.minRating !== null) { action.push(`Min Player Rating: ${filter.minRating}`) }
        if (filter.age && (filter.age.min !== structure.age.min || filter.age.max !== structure.age.max)) {
            action.push(`Age: ${filter.age.min === filter.age.max ? filter.age.max : filter.age.min + '-' + filter.age.max}`)
        }
        if (filter.gbePass) { action.push(`GBE: Pass`) }
        if (filter.minHeight !== null) { action.push(`Min Height: ${filter.minHeight}`) }
        if (filter.minutesPlayed && (filter.minutesPlayed.min !== structure.minutesPlayed.min || filter.minutesPlayed.max !== structure.minutesPlayed.max)) {
            action.push(`Min Played: ${filter.minutesPlayed.min === filter.minutesPlayed.max ? filter.minutesPlayed.max : filter.minutesPlayed.min + '-' + filter.minutesPlayed.max}`)
        }
        if ((filter.nationalityList || {})[0]) {
            const nationalityListNames = filter.nationalityList.map(id => structure.nationalityList.find(item => item.id === id).name)
            action.push(`Nationality: ${nationalityListNames.join(', ')}`)
        }
        if ((filter.leaguesList || {})[0]) {
            action.push(`League: ${filter.leaguesList
                .map(item => {
                    const name = structure.leaguesList.find(el => el.id === item).name
                    const i = name.split(',')
                    return `${i[0]}(${i[1] ? i[1].trim() : ''})`;
                })
                .join(', ')}`)
        }
        if (filter.leaguesLists && filter.leaguesLists[0]) {
            action.push(`League List: ${filter.leaguesLists.map(item => {
                const customLeagueList = filter.leaguesLists.find(el => el.id === item.id)
                const leaguesInCustomLeagueList = customLeagueList.competitionIds.map(i => structure.leaguesList.find(e => e.id === i).name.split(',')[0]).join(', ')
                return `${item.name}(${leaguesInCustomLeagueList})`;
            })
                .join(', ')}`)
        }
        if (filter.contractExpiryMonthesMax) {
            action.push(`Contract Expiry: ${ContractExpireSelector.toYrOrMonth(filter.contractExpiryMonthesMax)}`)
        }
        return action.join('; ')
    }

    private static getTranferTypeMessage(filter: TransferTypeFilter, currencyCode: string) {
        let action = []

        if (filter.showOnlyFreeAgentPlayers) {
            action.push('Only Free Agents')
        }
        if (filter.showOnlyPlayersWithSuspendedContract) {
            action.push('Only Suspended')
        }
        if (filter.showOnlyAvailablePlayers) {
            action.push('Only Marked Available');
        }

        const fee = []
        const transferFee = FilterActivityService.getFeeRange(filter.transferFee, currencyCode)
        const grossSalary = FilterActivityService.getFeeRange(filter.anuallGrossSalaryYearly, currencyCode, '/yr')
        const loanFee = FilterActivityService.getFeeRange(filter.loanFee, currencyCode, '/mo')
        // const marketValue = FilterActivityService.getFeeRange(filter.marketValue, currencyCode)

        if (transferFee) { fee.push(`Transfer Fee: ${transferFee}`) }
        if (grossSalary) { fee.push(`Gross Salary: ${transferFee}`) }
        if (transferFee || grossSalary) { action.push(`To Buy (${fee.join('; ')})`) }
        if (loanFee) { action.push(`To Loan (Loan fee: ${loanFee})`) }
        // if (marketValue) { action.push(`xTV: ${marketValue}`) }

        if (filter.showOnlyPlayersOnReleaseList) {
            action.push('Only on Release List');
        }

        if (filter.positionList && filter.positionList[0]) { action.push(`Position: ${filter.positionList.join(', ')}`) }

        return action.join(', ');
    }

    private static displayWithUnts = (value: number) => MarketValueSelector.displayMarketValue(value) + MarketValueSelector.valueUnts(value)

    private static getFeeRange(range: { min?: number, max?: number }, currencyCode, unts = '') {
        if (!range) return '';

        if (range.min === null && range.max === null) return '';

        if (range.min === null) {
            return `max ${currencyCode}${FilterActivityService.displayWithUnts(range.max)}${unts}`;
        }

        if (range.max === null) {
            return `min ${currencyCode}${FilterActivityService.displayWithUnts(range.min)}${unts}`;
        }

        return `${currencyCode}${FilterActivityService.displayWithUnts(range.min)}-${FilterActivityService.displayWithUnts(range.max)}${unts}`;
    }

    private static getStatePage(state: GridToggleState) {
        let action = ''
        if (state === GridToggleState.ShortList) { action = 'Shortlist' }
        else if (state === GridToggleState.Similarity) { action = 'Similar to' }
        return action;
    }

    public static getLatestSearchMessage(
        filters: FiltersSet,
        structure: Structure,
        currencyCode: string
    ) {
        const { clubAgentsFilter, transferTypeFilter, moreFilter } = filters

        const clubAgentsMessage = clubAgentsFilter.showOnlyClubs ? 'Only Clubs' : clubAgentsFilter.showOnlyTrustedAgents ? 'Only Trusted Agents' : ''
        if (clubAgentsMessage) return clubAgentsMessage;

        const transferTypeMessage = this.getTranferTypeMessage(transferTypeFilter, currencyCode)
        const moreFilterMessage = this.getMoreFilterMessage(moreFilter, structure)
        const message = [transferTypeMessage, moreFilterMessage].filter(item => item).join('; ')
        return message
    }

}