import { STORAGE_KEY } from 'services/custom-columns';

const stateKey = 'state';
export const loadState = () => {
    try {
        const serializedState = localStorage.getItem(stateKey);
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};

export const saveState = state => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem(stateKey, serializedState);
    } catch {
        // ignore write errors
    }
};

export const clearState = () => {
    try {
        localStorage.removeItem(stateKey);
        localStorage.removeItem(STORAGE_KEY);
    } catch {
        // ignore write errors
    }
};
