import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer'
import { LandingPageService } from 'api/landing-page/landing-page-service'
import { UserNotification } from 'api/landing-page/models/user-notification'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';

class NotificationState {
    notification: UserNotification;
}

const defaultState: NotificationState = {
    notification: null
}

const stateController = new StateController<NotificationState>(
    "AGENCY/LANDING_PAGE/NOTIFICATION",
    defaultState
);

class Actions {

    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState))
        }
    }

    public static loadNotification() {
        return async (dispatch) => {
            const currentNotification = await LandingPageService.getUnreadNotification();
            dispatch(stateController.setState({ notification: currentNotification }))
        }
    }

    public static markAsRead() {
        return async (dispatch, getState: () => AppState) => {
            const appState = getState();
            const notification = appState.agency.landingPage.notification.notification;
            notification.readAt = new Date();
            dispatch(stateController.setState({ notification: { ...notification } }))
            await LandingPageService.markNotificationAsRead();
            dispatch(
                userActivityInsert({
                    Message: 'Closed Info Box',
                    PageName: 'Home',
                    PageType: PageType.Home
                }),
            );
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    NotificationState as State,
    Actions as Actions,
    stateController as Controller
};



