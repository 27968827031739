import { StateController } from 'utils/action-declaration' 
import { MODAL_OPEN, MODAL_CLOSE } from 'store/actionTypes';
import { BOOK_EXPERT_SESSION_MODAL } from 'constants/modals';
import { PageType } from 'constants/enums';

export class AddUserState {
    isLoading: boolean
}

const defaultState: AddUserState = {
    isLoading: false,
}

const stateController = new StateController<AddUserState>(
    "LANDING_PAGE/BOOK_EXPERT_SESSION_MODAL",
    defaultState
);

class Actions {

    

    public static openBookSessionModal = () => {
        return (dispatch, getState) => {
        
            const state = getState();
            const salesExpert = state.landingPage.notification.notification.successSalesExpert;



            let command = {
                type: MODAL_OPEN,
                payload: { 
                    id: BOOK_EXPERT_SESSION_MODAL, 
                    className: 'hp-book-session-modal',
                    content: {
                        pageName: 'Home [Success manager]',
                        pageType: PageType.Home,
                        salesExpert: salesExpert
                    }
                },
            };
            dispatch(command);
        }
    }

    public static openBookSessionAdvisorModal = () => {
        return (dispatch, getState) => {        
                      
            const state = getState();
            const salesExpert = state.landingPage.notification.notification.defaultAdvisor;

            let command = {
                type: MODAL_OPEN,
                payload: { 
                    id: BOOK_EXPERT_SESSION_MODAL, 
                    className: 'hp-book-session-modal',
                    content: {
                        pageName: 'Home [Success manager]',
                        pageType: PageType.Home,
                        salesExpert: salesExpert
                    }
                },
            };
            dispatch(command);
        }
    }

    public static closeBookSessionModal = () => {
        return dispatch => {
            let command = {
                type: MODAL_CLOSE,
                payload: BOOK_EXPERT_SESSION_MODAL,
            };
            dispatch(command);
        }
    }

}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    AddUserState as State,
    Actions as Actions,
    stateController as Controller
};


