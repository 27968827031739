import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import SuggestedDealsService from 'api/agency/agent/suggested-deals/suggested-deals.service'
import { AgencyTransferSuggestion, SaveAgencyTransfer, SuggestedDealRequest } from 'api/agency/agent/suggested-deals/models'
import historyAccessor from 'history-accessor';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';
import AgencyPublicProfileService from 'api/agency/public-profile/public-profile.service';
import { IdName } from 'api/core/id-name';
import { AGENCY_FORCE_NOTIFICATION_LAYOUT_FALSE } from 'store/actionTypes';
import { notificationCreate } from 'app/notifications/notifications.actions';
import { isLowerTierAgency } from 'store/auth/authReducer';
import { Actions as LtaModalActions, LtaUpgradeModalType, LTA_PROFILE_TRANSFERS_LIMIT } from 'pages/agency/common/low-tier-agency-upgrade-modal/lta-upgrade-modal.controller';

export enum SuggestionScreen {
    Suggestions = 1,
    AddManual = 2,
    AddingForm = 3
}
class State {
    isLoading: boolean;
    isPlayersFetching: boolean;
    profileTransfersQty: number;
    dealsAddedManuallyQty: number;
    suggestions: Array<AgencyTransferSuggestion>;
    playerSuggestions: Array<AgencyTransferSuggestion>;
    screen: SuggestionScreen;
    players: Array<IdName>;
    selectedPlayer: IdName;
    pendingToAdd: Array<AgencyTransferSuggestion>;
    pendingToRemove: Array<AgencyTransferSuggestion>;
    processedDeals: Array<AgencyTransferSuggestion>;
    isAddDealManuallyProcessing: boolean;
    dealAddedManually: boolean;
    reachDealLimitModal: boolean;
    confirmationModal: boolean;
    dealProcessedModal: boolean;
    playerAutocompleteKeyword: string;
}

const defaultState: State = {
    isLoading: false,
    isPlayersFetching: false,
    profileTransfersQty: 0,
    dealsAddedManuallyQty: 0,
    suggestions: null,
    playerSuggestions: [],
    players: [],
    screen: SuggestionScreen.Suggestions,
    selectedPlayer: null,
    pendingToAdd: [],
    pendingToRemove: [],
    processedDeals: [],
    isAddDealManuallyProcessing: false,
    dealAddedManually: false,
    reachDealLimitModal: false,
    confirmationModal: false,
    dealProcessedModal: false,
    playerAutocompleteKeyword: ''
}

const stateController = new StateController<State>(
    "AGENCY/SUGGESTED-DEALS",
    defaultState
)

class Helpers {
    public static arrayContains(source: Array<AgencyTransferSuggestion>, item: AgencyTransferSuggestion) {
        return source.some(x => x.player.id == item.player.id &&
            x.squadFrom.id == item.squadFrom.id &&
            x.squadTo.id == item.squadTo.id &&
            x.year == item.year &&
            x.transferTypeId == item.transferTypeId
        )
    }
}

class Actions {
    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState({ ...defaultState }));
        }
    }

    public static setScreen(screen: SuggestionScreen) {
        return (dispatch) => {
            dispatch(stateController.setState({ screen: screen }));
        }
    }

    public static init() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));
            const { suggestions, profileTransfersQty } = await SuggestedDealsService.getPendingSuggestions();
            dispatch(stateController.setState({ isLoading: false, suggestions, profileTransfersQty }));
            dispatch(userActivityInsert({
                Message: `Opened`,
                PageName: `Suggested Deals`,
                PageType: PageType.Agency,
            }));
        }
    }

    public static loadPlayers() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isPlayersFetching: true }));
            const data = await AgencyPublicProfileService.getPlayers();
            dispatch(stateController.setState({ players: data, isPlayersFetching: false }));
        }
    }

    public static selectPlayer(player: IdName) {
        return async (dispatch, getState: () => AppState) => {
            const { suggestions, processedDeals } = Selectors.getRoot(getState());
            const playerSuggestions = suggestions.filter(x => x.player.id === player.id && !Helpers.arrayContains(processedDeals, x));

            if (playerSuggestions.length) {//if suggested player exist
                dispatch(stateController.setState({
                    selectedPlayer: player
                }))
                dispatch(Actions.setPlayerAutocompleteKeyword(player.name));
            }
            else {
                dispatch(Actions.openAddDealForm(player));
                dispatch(Actions.setPlayerAutocompleteKeyword(''));
            }

            dispatch(stateController.setState({
                playerSuggestions
            }));
            dispatch(userActivityInsert({
                Message: `Selected: ${player.name}`,
                PageName: `Suggested Deals`,
                PageType: PageType.Agency,
                PlayerId: player.id
            }));
        }
    }

    public static openAddDealForm(player: IdName) {
        return async (dispatch, getState: () => AppState) => {
            if (Selectors.isLimitReached(getState())) {
                dispatch(Actions.openReachDealLimitModal());
                return;
            }

            dispatch(stateController.setState({
                selectedPlayer: player,
                screen: SuggestionScreen.AddingForm
            }))
        }
    }

    public static openReachDealLimitModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ reachDealLimitModal: true }));
        }
    }

    public static closeReachDealLimitModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ reachDealLimitModal: false }));
            dispatch(userActivityInsert({
                Message: `Closed`,
                PageName: `Suggested Deals [LTA Restriction]`,
                PageType: PageType.Agency,
            }));
        }
    }

    public static upgradeLta() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ reachDealLimitModal: false }));
            dispatch(LtaModalActions.open(LtaUpgradeModalType.InstantUpgrade, 'Agency Profile [Track Record]'));
            dispatch(userActivityInsert({
                Message: `Clicked Upgrade`,
                PageName: `Suggested Deals [LTA Restriction]`,
                PageType: PageType.Agency,
            }));
        }
    }

    public static addDeal(item: AgencyTransferSuggestion) {
        return async (dispatch, getState: () => AppState) => {
            if (Selectors.isLimitReached(getState())) {
                dispatch(Actions.openReachDealLimitModal());
                return;
            }

            const substate = Selectors.getRoot(getState());
            dispatch(stateController.setState({ pendingToAdd: [...substate.pendingToAdd, item] }))

            const requestArgs: SuggestedDealRequest = {
                fromSquadId: item.squadFrom.id,
                playerId: item.player.id,
                toSquadId: item.squadTo.id,
                transferTypeId: item.transferTypeId,
                year: item.year
            };
            const { isAlreadyProcessed, isLimitReached } = await SuggestedDealsService.addDeal(requestArgs);

            if (isLimitReached) {
                dispatch(Actions.openReachDealLimitModal());
                dispatch(stateController.setState({ pendingToAdd: [...substate.pendingToAdd.filter(x => x != item)] }))
                return;
            }

            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                processedDeals: [...prevState.processedDeals, item]
            })));

            if (isAlreadyProcessed) {
                dispatch(Actions.openDealProcessedModal());
                return;
            }

            dispatch(userActivityInsert({
                Message: `Confirmed Suggested ${item.isContractExtension ? 'Extension' : 'Deal'}: ${item.year}; ${item.player.name}; ${item.isContractExtension ? `${item.squadFrom.name}` : `${item.squadFrom.name} - ${item.squadTo.name}`}`,
                PageName: `Suggested Deals`,
                PageType: PageType.Agency,
                PlayerId: item.player.id
            }));

            if (substate.suggestions && substate.suggestions.length != 0 && substate.suggestions.length == Selectors.getRoot(getState()).processedDeals.length) {
                dispatch(userActivityInsert({
                    Message: `Cleared All Suggestions`,
                    PageName: `Suggested Deals`,
                    PageType: PageType.Agency,
                }));
            }
        }
    }

    public static rejectDeal(item: AgencyTransferSuggestion) {
        return async (dispatch, getState: () => AppState) => {
            const substate = Selectors.getRoot(getState());
            dispatch(stateController.setState({ pendingToRemove: [...substate.pendingToRemove, item] }));

            const requestArgs = {
                fromSquadId: item.squadFrom.id,
                playerId: item.player.id,
                toSquadId: item.squadTo.id,
                transferTypeId: item.transferTypeId,
                year: item.year
            };
            const { isAlreadyProcessed } = await SuggestedDealsService.rejectDeal(requestArgs);
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                processedDeals: [...prevState.processedDeals, item]
            })));

            if (isAlreadyProcessed) {
                dispatch(Actions.openDealProcessedModal());
                return;
            }

            dispatch(userActivityInsert({
                Message: `Declined Suggested ${item.isContractExtension ? 'Extension' : 'Deal'}: ${item.year}; ${item.player.name}; ${item.isContractExtension ? `${item.squadFrom.name}` : `${item.squadFrom.name} - ${item.squadTo.name}`}`,
                PageName: `Suggested Deals`,
                PageType: PageType.Agency,
                PlayerId: item.player.id
            }));

            if (substate.suggestions && substate.suggestions.length != 0 && substate.suggestions.length == Selectors.getRoot(getState()).processedDeals.length) {
                dispatch(userActivityInsert({
                    Message: `Cleared All Suggestions`,
                    PageName: `Suggested Deals`,
                    PageType: PageType.Agency,
                }));
            }
        }
    }

    public static goToAddDealsManuallyStep() {
        return (dispatch, getState: () => AppState) => {
            if (Selectors.isLimitReached(getState())) {
                dispatch(Actions.openReachDealLimitModal());
                return;
            }

            dispatch(Actions.setScreen(SuggestionScreen.AddManual));
            dispatch(userActivityInsert({
                Message: `Add Deals Manually`,
                PageName: `Suggested Deals`,
                PageType: PageType.Agency,
            }));
        }
    }

    public static remindMeLater(trackRemind: boolean, path: string) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));
            const data = await SuggestedDealsService.remindMeLater();
            dispatch({ type: AGENCY_FORCE_NOTIFICATION_LAYOUT_FALSE });
            historyAccessor.push(path);

            if (trackRemind) {
                dispatch(userActivityInsert({
                    Message: `Remind Me Later`,
                    PageName: `Suggested Deals`,
                    PageType: PageType.Agency,
                }));
            }
        }
    }

    public static saveDeal(transfer: SaveAgencyTransfer, isAddNew: boolean, transferFromName: string, transferToName: string) {
        return async (dispatch, getState: () => AppState) => {
            if (Selectors.isLimitReached(getState())) {
                dispatch(Actions.openReachDealLimitModal());
                return;
            }

            const { selectedPlayer } = Selectors.getRoot(getState());

            dispatch(stateController.setState({
                dealAddedManually: false,
                isAddDealManuallyProcessing: true
            }));
            try {
                const { isLimitReached } = await SuggestedDealsService.addDealManually(transfer);
                if (isLimitReached) {
                    dispatch(Actions.openReachDealLimitModal());
                    return;
                }

                if (!isAddNew) {
                    dispatch(Actions.setScreen(SuggestionScreen.Suggestions));
                }                     
                dispatch(stateController.setState({
                    playerSuggestions: [],
                    playerAutocompleteKeyword: '',
                }))
                dispatch(notificationCreate({
                    level: 'success',
                    message: `Your deal for <b>${selectedPlayer.name}</b> has been added to your track record`
                }))
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    dealAddedManually: true,
                    dealsAddedManuallyQty: prevState.dealsAddedManuallyQty + 1
                })));
                dispatch(userActivityInsert({
                    Message: `Added ${transfer.isContractExtension ? 'Extension' : 'a Deal'}: ${transfer.year}; ${selectedPlayer.name}; ${transfer.isContractExtension ? `${transferFromName}; ${transfer.durationYears} yr` : `${transferFromName} - ${transferToName}`}`,
                    PageName: `Suggested Deals`,
                    PageType: PageType.Agency,
                    PlayerId: transfer.playerId
                }));
            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState({ isAddDealManuallyProcessing: false }));
            }
        }
    }

    public static goBackToSuggestions() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.setScreen(SuggestionScreen.Suggestions));
            dispatch(stateController.setState({
                selectedPlayer: null,
                playerSuggestions: [],
                playerAutocompleteKeyword: ''
            }));
            dispatch(userActivityInsert({
                Message: `Moved Back to Deal Suggestions`,
                PageName: `Suggested Deals`,
                PageType: PageType.Agency,
            }));
        }
    }

    public static goBackToPlayerSearch() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.setScreen(SuggestionScreen.AddManual));
            dispatch(userActivityInsert({
                Message: `Moved Back to Player Search`,
                PageName: `Suggested Deals`,
                PageType: PageType.Agency,
            }));
        }
    }

    public static openConfimationModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ confirmationModal: true }));
        }
    }

    public static confirmGoBack() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.setScreen(SuggestionScreen.AddManual));
            dispatch(Actions.closeConfirmationModal());
            dispatch(userActivityInsert({
                Message: `Cancelled Manual Deal`,
                PageName: `Suggested Deals`,
                PageType: PageType.Agency,
                PlayerId: getState().agency.agent.suggestedDeals.selectedPlayer.id
            }));
        }
    }

    public static closeConfirmationModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ confirmationModal: false }));
        }
    }

    public static setPlayerAutocompleteKeyword(keyword: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ playerAutocompleteKeyword: keyword }));
        }
    }

    public static openDealProcessedModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ dealProcessedModal: true }));
        }
    }

    public static closeDealProcessedModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ dealProcessedModal: false }));
        }
    }

}

class Selectors {
    public static getRoot = (state: AppState): State => state.agency.agent.suggestedDeals;

    public static isLimitReached = (state: AppState): boolean => {
        const { profileTransfersQty, pendingToAdd, dealsAddedManuallyQty } = Selectors.getRoot(state);

        if (!isLowerTierAgency(state)) {
            return false;
        }
        return profileTransfersQty + pendingToAdd.length + dealsAddedManuallyQty >= LTA_PROFILE_TRANSFERS_LIMIT;
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    stateController as Controller,
    Helpers as Helpers,
    Selectors as Selectors
};