import axios from 'axios-config';

// 0 - Empty, 1 - Player, 2 - Squad
export enum SuggestionType {
    Empty = 0,
    Player = 1,
    Squad = 2,
    League = 3,
    Agency = 4,
}
export class SuggestionModel {
    id: number;
    shortName: string;
    firstName: string;
    middleName: string;
    lastName: string;
    photo: string;
    parentSquadId?: number;
    parentSquadName: string;
    currentSquadId?: number;
    currentSquadName: string;
    englishFirstName: string;
    englishLastName: string;
    englishShortName: string;
    suggestionType: SuggestionType
    firstPositionName: string;
    secondPositionName: string;
    firstPositionCode: string;
    secondPositionCode: string;
    competitionId?: number;
    competitionName: string;
    competitionCountry: string;
    countryId: string;
    countryName: string;
    isRestricted: boolean;
    available: boolean;
}

export class PlayerSearchService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async getPlayerAutosuggestions(squadId: number, clubId: number, filterCriteria: any, clubOnly: boolean = false, clubWithoutPlayers: boolean = false, clubWithoutCompetitions: boolean = false): Promise<Array<SuggestionModel>> {
        const { data } = await axios.post(
            `api/Suggestion/GetPlayerSearchFilterSuggestion/${squadId}/${clubId}?clubOnly=${clubOnly}&clubWithoutPlayers=${clubWithoutPlayers}&clubWithoutCompetitions=${clubWithoutCompetitions}`,
            { ...filterCriteria },
            { ...this.axiosConfig }
        )
        return data
    }

    public static async getAgencyPlayerAutosuggestions(filterCriteria: any, onlyShortList: boolean = false): Promise<Array<SuggestionModel>> {
        const { data } = await axios.post(
            `api/Suggestion/GetAgencyPlayerSearchFilterSuggestion?onlyShortList=${onlyShortList}`,
            { ...filterCriteria },
            { ...this.axiosConfig }
        )
        return data
    }

    public static async getAgencySearchFilterSuggestion(filterCriteria: any): Promise<Array<SuggestionModel>> {
        const { data } = await axios.post(
            `api/Suggestion/GetAgencySearchFilterSuggestion`,
            { ...filterCriteria },
            { ...this.axiosConfig }
        )
        return data
    }
}
