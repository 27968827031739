export const benefits = [
    'Access to a global network of decision makers ',
    '18 x 15 min meetings with selected clubs of choice',
    'Strengthen relationships and get down to business fast'
]
   

export const agentBenefits = [
    'Access to a global network of decision makers ',
    '18 x 15 min meetings with selected clubs of choice',
    'Strengthen relationships and get down to business fast'
]