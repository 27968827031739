import * as redux from 'redux'
import { State as SearchBarState, Reducer as SearchBarReducer } from './search-bar.controller'
import { State as StatisticsCommonState, Reducer as StatisticsCommonReducer } from './statisctics-common.controller'
import * as playerPointController from './player-point.controller'
export class PlayerPointsState {
    searchBar: SearchBarState;
    statisticsCommon: StatisticsCommonState;
    player: playerPointController.State;
}

export function combineReducers() {
    return redux.combineReducers({
        searchBar: SearchBarReducer,
        statisticsCommon: StatisticsCommonReducer,
        player: playerPointController.Reducer,
    })
}



