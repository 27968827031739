import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import { SearchByKey } from 'api/search/model/search-request'
import getSections from 'services/filterSections';
import SearchService from 'api/search/search.agencyside.service';
import Range from 'api/core/range';
import { SearchItemType } from 'api/search/model/suggestion-item'
import * as GridActions from './grid.controller'
import * as Suggestions from './search-autosuggest.controller'
import { IdName } from 'api/core/id-name';
import { CompetitionListModel } from 'api/agency/agency-competition-list/models';
import { SuggestionActivityService } from './autosuggest.activity.service'
import { FilterActivityService } from './filter.activity.service'
import * as storedFilter from 'pages/PlayerSearch-v2/redux/stored-filter'
import { getGridState } from './grid.controller'
import PlayerMarketBannerService from 'api/agency/agent/landing/player-market-banner.service';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';

const sections = getSections();

class SortItem {
    id: SearchByKey;
    title: string;
}

export const sortList: Array<SortItem> = [
    { id: SearchByKey.RatingHightToLow, title: 'Rating: High to Low' },
    { id: SearchByKey.RatingLowToHigh, title: 'Rating: Low to High' },
    { id: SearchByKey.XtvHightToLow, title: 'xTV: High to Low' },
    { id: SearchByKey.XtvLowToHigh, title: 'xTV: Low to High' },
    { id: SearchByKey.AgeHigtToLow, title: 'Age: High to Low', },
    { id: SearchByKey.AgeLowHigh, title: 'Age: Low to High' },
    { id: SearchByKey.ContractExpiry, title: 'Contract Expiry' },
    { id: SearchByKey.DateWhenAddedToShortList, title: 'Date when added' },
];

export const clubSortList: Array<SortItem> = [
    // { id: SearchByKey.Recommended, title: 'Recommended' },
    { id: SearchByKey.XtvHightToLow, title: 'xTV: High to Low' },
    { id: SearchByKey.XtvLowToHigh, title: 'xTV: Low to High' },
];

export class Structure {
    age: Range<number>;
    height: Range<number>;
    contractExpiryMonthes: Range<number>;
    leaguesList: Array<IdName>;
    nationalityList: Array<IdName>;
    gbePass: boolean;
    showOnlyFreeAgentPlayers: boolean;
    positionList: Array<string>;
    transferFee: Range<number>;
    anuallGrossSalaryYearly: Range<number>;
    loanFee: Range<number>;
    minutesPlayed: Range<number>;
    lookingForAgent: boolean;
    playerMarketPermissionRequested: boolean;
    rating: Range<number>;
}

const getStructure = (): Structure => {
    return {
        age: {
            min: sections.age.default.minAge,
            max: sections.age.default.maxAge,
        },
        height: {
            min: 150,
            max: 210,
        },
        contractExpiryMonthes: {
            min: sections.contractExpiry.default.contractExpiryMin,
            max: sections.contractExpiry.default.contractExpiryMax,
        },
        leaguesList: [],
        nationalityList: [],
        gbePass: sections.faPlayerPoints.default.faPointsPassResult,
        showOnlyFreeAgentPlayers: false,
        positionList: [],
        transferFee: {
            min: sections.transferFee.default.minTransferFee,
            max: sections.transferFee.default.maxTransferFee,
        },
        anuallGrossSalaryYearly: {
            min: sections.transferFee.default.minTransferFee,
            max: sections.transferFee.default.maxTransferFee,
        },
        loanFee: {
            min: sections.loanFee.default.minTransferFee,
            max: sections.loanFee.default.maxTransferFee,
        },
        minutesPlayed: {
            min: 0,
            max: null,
        },
        lookingForAgent: false,
        playerMarketPermissionRequested: false,
        rating: {
            min: 0,
            max: 100,
        },
    }
}

export class SearchMoreFilter {
    gbePass: boolean;
    minHeight: number;
    contractExpiryMonthesMax: number;
    leaguesList: Array<number>;
    leaguesLists: Array<CompetitionListModel & { competitions: Array<IdName> }>;
    nationalityList: Array<number>;
    age: Range<number>;
    minutesPlayed: Range<number>;
    minRating: number;
}

export class TransferTypeFilter {
    positionList: Array<string>;
    showOnlyFreeAgentPlayers: boolean;
    transferFee: Range<number>;
    anuallGrossSalaryYearly: Range<number>;
    loanFee: Range<number>;
    marketValue: Range<number>;
    showOnlyAvailablePlayers: boolean;
    showOnlyPlayersOnReleaseList: boolean;
}

export class PositionFilter {
    positionList: Array<string>;
}

export class OnlyAgentsAndClubsFilter {
    showOnlyClubs: boolean;
    showOnlyTrustedAgents: boolean;
}

const getTransferTypeCriteria = (): TransferTypeFilter => {
    return {
        positionList: [],
        transferFee: null,
        anuallGrossSalaryYearly: null,
        loanFee: null,
        showOnlyFreeAgentPlayers: false,
        marketValue: null,
        showOnlyAvailablePlayers: false,
        showOnlyPlayersOnReleaseList: false
    };
};

const getInitialCriteria = (): SearchMoreFilter => {
    return {
        gbePass: sections.faPlayerPoints.default.faPointsPassResult,
        minHeight: null,
        contractExpiryMonthesMax: null,
        leaguesList: [],
        leaguesLists: [],
        nationalityList: [],
        age: {
            min: sections.age.default.minAge,
            max: sections.age.default.maxAge,
        },
        minutesPlayed: {
            min: sections.minutesPlayed.default.mpMin,
            max: sections.minutesPlayed.default.mpMax,
        },
        minRating: null
    };
};

export class SearchState {
    moreFilter: SearchMoreFilter;
    clubAgentsFilter: OnlyAgentsAndClubsFilter;
    transferTypeFilter: TransferTypeFilter;
    transferTypeFilterModal: any;
    clubAgentsFilterModal: any;
    moreFilterModal: any;
    positionFilterModal: any;
    structure: Structure;
    sortBy?: SearchByKey;
    filterPanel: any;
    structureLoading: boolean;
    mpMaxUpdatedFromServer: boolean;
    lookingForAgent: boolean;
    playerMarketModal: PlayerMarketModalState;
}

type PlayerMarketModalState =
    {
        isOpen: boolean;
        isProcessing: boolean;
        step: number;
    }

const defaultState: SearchState = {
    transferTypeFilterModal: null,
    clubAgentsFilterModal: null,
    moreFilterModal: null,
    positionFilterModal: null,
    moreFilter: getInitialCriteria(),
    transferTypeFilter: getTransferTypeCriteria(),
    clubAgentsFilter: {
        showOnlyClubs: false,
        showOnlyTrustedAgents: false,
    },
    structure: getStructure(),
    sortBy: SearchByKey.XtvHightToLow,
    filterPanel: null,
    structureLoading: false,
    mpMaxUpdatedFromServer: false,
    lookingForAgent: false,
    playerMarketModal: null
};

const stateController = new StateController<SearchState>(
    'SEARCH_SCREEN/FILTER',
    defaultState,
);

class Actions {
    public static dispose = () => {
        return (dispatch) => {
            dispatch(stateController.setState({ ...defaultState }))
        }
    }

    public static loadCriteria = () => {
        return async (dispatch) => {
            try {
                dispatch(stateController.setState({ structureLoading: true }))
                const criteria = await SearchService.getCriteria();
                dispatch(stateController.setState((draftState: SearchState) => ({
                    ...draftState,
                    structure: {
                        ...draftState.structure,
                        minutesPlayed: { ...draftState.structure.minutesPlayed, max: criteria.maxMinutesPlayed },
                        leaguesList: criteria.availableLeagues,
                        nationalityList: criteria.availableNationalitites,
                        transferFee: { ...draftState.structure.transferFee, max: criteria.maxTransferFee },
                        playerMarketPermissionRequested: criteria.playerMarketPermissionRequested
                    },
                    moreFilter: {
                        ...draftState.moreFilter,
                        minutesPlayed: draftState.mpMaxUpdatedFromServer ?
                            draftState.moreFilter.minutesPlayed :
                            { ...draftState.moreFilter.minutesPlayed, max: criteria.maxMinutesPlayed }
                    },
                    mpMaxUpdatedFromServer: true,
                    structureLoading: false,
                })))
            } catch (error) {
                dispatch(stateController.setState({ structureLoading: false }))
            }
        }
    }

    private static isAgentClubFilterActiveOrDisabled = (firtsClubAgentsFilter, secondClubAgentsFilter) => {
        return !firtsClubAgentsFilter.showOnlyClubs && !firtsClubAgentsFilter.showOnlyTrustedAgents && (
            secondClubAgentsFilter.showOnlyClubs || secondClubAgentsFilter.showOnlyTrustedAgents
        )
    }

    public static toogleClubAgentsFilter(clubAgentsFilterModal?: boolean) {
        return dispatch => {
            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                clubAgentsFilterModal: clubAgentsFilterModal ? draftState.clubAgentsFilter : null,
            })))
        }
    }

    public static setClubsAgentsFilterAction(clubAgentsFilter: OnlyAgentsAndClubsFilter, withActivity: boolean = true) {
        return (dispatch, getState: () => AppState) => {
            const oldclubAgentsFilter = { ...Selectors.getClubAgentsFilter(getState()) }
            dispatch(stateController.setState({ clubAgentsFilter: clubAgentsFilter }));

            if (
                Actions.isAgentClubFilterActiveOrDisabled(clubAgentsFilter, oldclubAgentsFilter) ||
                Actions.isAgentClubFilterActiveOrDisabled(oldclubAgentsFilter, clubAgentsFilter)
            ) {
                dispatch(Actions.initSorting());
                dispatch(GridActions.Actions.resetPage());
            }

            dispatch(Suggestions.Actions.setDefaultTab())
            dispatch(Suggestions.Actions.fetchSuggestionsSilentAndNoRefresh())
            dispatch(GridActions.Actions.refresh(
                () => { if (withActivity) dispatch(FilterActivityService.filterClubAgents(clubAgentsFilter)) }
            ));
        }
    }

    public static setClubsAgentsFilter(clubAgentsFilter: OnlyAgentsAndClubsFilter) {
        return (dispatch) => {
            dispatch(Actions.setClubsAgentsFilterAction(clubAgentsFilter, true))
        }
    }

    public static setDefaultClubsAgentsFilter(clubAgentsFilter: OnlyAgentsAndClubsFilter) {
        return (dispatch) => {
            dispatch(Actions.setClubsAgentsFilterAction(clubAgentsFilter, false))
        }
    }

    public static clearClubsAgentsFilter() {
        return (dispatch, getState: () => AppState) => {
            const oldclubAgentsFilter = { ...Selectors.getClubAgentsFilter(getState()) }
            dispatch(stateController.setState({ clubAgentsFilter: { showOnlyTrustedAgents: false, showOnlyClubs: false }, }))

            if (oldclubAgentsFilter.showOnlyClubs || oldclubAgentsFilter.showOnlyTrustedAgents) {
                dispatch(Actions.initSorting());
                dispatch(GridActions.Actions.resetPage());
            }
            dispatch(Suggestions.Actions.setDefaultTab())
        }
    }

    public static clearClubsAgentsFilterAsync() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.clearClubsAgentsFilter())
            dispatch(Suggestions.Actions.fetchSuggestionsSilentAndNoRefresh());
            dispatch(GridActions.Actions.refresh());
        }
    }

    public static onClubSelect(name: string, clubId: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.resetFilter())
            dispatch(GridActions.Actions.resetPage());
            dispatch(Suggestions.Actions.setKeywordWithGridRefresh(name))
            dispatch(Suggestions.Actions.fetchSuggestionsSilentAndNoRefresh())
            dispatch(FilterActivityService.filterClubPlayers(clubId))
        }
    }

    public static onAgencySelect(name: string, agencyId: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.resetFilter())
            dispatch(GridActions.Actions.resetPage());
            dispatch(Suggestions.Actions.setKeywordWithGridRefresh(name))
            dispatch(Suggestions.Actions.fetchSuggestionsSilentAndNoRefresh())
            dispatch(FilterActivityService.filterAgencyPlayers(agencyId))
        }
    }

    public static onAgencySuggestionSelected(agencyId: number) {
        return (dispatch, getState: () => AppState) => {
            const agency = Suggestions.Selectors.getSuggestionItem(getState())(agencyId, SearchItemType.Agency)

            dispatch(Actions.resetFilter());
            dispatch(Suggestions.Actions.setKeywordWithGridRefresh(agency.title));
            dispatch(Suggestions.Actions.fetchSuggestionsSilentAndNoRefresh())
            dispatch(SuggestionActivityService.selectAgency(agencyId, agency.title))
        }
    }

    public static onClubSuggestionSelected(clubId: number) {
        return (dispatch, getState: () => AppState) => {
            const club = Suggestions.Selectors.getSuggestionItem(getState())(clubId, SearchItemType.Club)
            dispatch(Actions.resetFilter())
            dispatch(Suggestions.Actions.setKeywordWithGridRefresh(club.title));
            dispatch(Suggestions.Actions.fetchSuggestionsSilentAndNoRefresh())
            dispatch(SuggestionActivityService.selectClub(clubId, club.title));
        }
    }

    public static toglePositionFilter(open: boolean) {
        return (dispatch, getState: () => AppState) => {
            const filter = Selectors.getPositionFilter(getState());
            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                positionFilterModal: open ? { positionList: filter } : null,
            })))
        }
    }

    public static setPositionFilter(positionList: Array<string>) {
        return (dispatch, getState: () => AppState) => {
            const gridState = GridActions.Selectors.getGridState(getState())
            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                transferTypeFilter: { ...draftState.transferTypeFilter, positionList },
            })));
            dispatch(GridActions.Actions.resetPage());
            dispatch(GridActions.Actions.refresh(
                () => dispatch(FilterActivityService.filterPosition({ positionList }, gridState))
            ));
        }
    }

    public static toggleTransferTypeFilterModal(transferTypeFilterModal: boolean) {
        return (dispatch, getState: () => AppState) => {
            const filter = Selectors.getTranferTypeFilter(getState())
            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                transferTypeFilterModal: transferTypeFilterModal ? { ...filter } : null,
            })))
        }
    }

    public static setTranferTypeFilter(filter: TransferTypeFilter & { positionList: Array<string> }) {
        return (dispatch, getState: () => AppState) => {
            const gridState = GridActions.Selectors.getGridState(getState())
            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                transferTypeFilter: { ...draftState.transferTypeFilter, ...filter },
            })));
            dispatch(GridActions.Actions.resetPage());
            dispatch(GridActions.Actions.refresh(
                () => dispatch(FilterActivityService.filterMarketValue(filter, gridState))
            ));
        }
    }

    public static setDefaultTranferTypeFilter(filter: TransferTypeFilter & { positionList: Array<string> }) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                transferTypeFilter: { ...draftState.transferTypeFilter, ...filter },
            })));
            dispatch(GridActions.Actions.resetPage());
            dispatch(GridActions.Actions.refresh());
        }
    }

    public static clearTranferTypeFilter() {
        return dispatch => {
            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                transferTypeFilter: { ...getTransferTypeCriteria() },
            })));
        }
    }

    public static clearTranferTypeFilterAsync() {
        return dispatch => {
            dispatch(Actions.clearTranferTypeFilter());
            dispatch(GridActions.Actions.resetPage());
            dispatch(GridActions.Actions.refresh());
        }
    }

    public static clearLookingForAgentFilter() {
        return dispatch => {
            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                lookingForAgent: false
            })));
        }
    }

    public static toggleMoreFilterModal(moreFilter: boolean) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                moreFilterModal: moreFilter ? { ...draftState.moreFilter } : null,
            })));
        }
    }

    public static setMoreFilter(filter: SearchMoreFilter) {
        return (dispatch, getState: () => AppState) => {
            const gridState = GridActions.Selectors.getGridState(getState())
            const structure = Selectors.getStructure(getState())

            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                moreFilter: { ...draftState.moreFilter, ...filter },
            })))
            dispatch(GridActions.Actions.resetPage());
            dispatch(GridActions.Actions.refresh(
                () => dispatch(FilterActivityService.filterMore(filter, structure, gridState))
            ));
        }
    }

    public static setDefaultMoreFilter(filter: SearchMoreFilter) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                moreFilter: { ...draftState.moreFilter, ...filter },
            })))
            dispatch(GridActions.Actions.resetPage());
            dispatch(GridActions.Actions.refresh());
        }
    }

    public static clearMoreFilter() {
        return dispatch => {
            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                moreFilter: { ...getInitialCriteria(), minutesPlayed: draftState.structure.minutesPlayed },
            })));
        }
    }

    public static clearMoreFilterAsync() {
        return dispatch => {
            dispatch(Actions.clearMoreFilter());
            dispatch(GridActions.Actions.resetPage());
            dispatch(GridActions.Actions.refresh());
        }
    }

    public static toggleLookingForAgent() {
        return (dispatch, getState: () => AppState) => {
            if (getState().auth.agencyPermission.agencyPlayerMarket) {
                const isLookingForAgentFilterActive = Selectors.isLookingForAgentFilterActive(getState());
                dispatch(stateController.setState((draftState) => ({
                    ...draftState,
                    lookingForAgent: !isLookingForAgentFilterActive
                })));
                dispatch(GridActions.Actions.resetPage());
                dispatch(GridActions.Actions.refresh(
                    () => dispatch(FilterActivityService.filterPlayersLookingForAgent(!isLookingForAgentFilterActive))
                ));
            }
            else {
                dispatch(Actions.openPlayerMarketModal())
            }
        }
    }

    public static toggleAllFilterModal(open: boolean) {
        return (dispatch, getState: () => AppState) => {
            const transferTypeFilter = Selectors.getTranferTypeFilter(getState());
            const clubAgentsFilter = Selectors.getClubAgentsFilter(getState());
            const moreFilter = Selectors.getMoreFilter(getState());
            const isLookingForAgentActive = Selectors.isLookingForAgentFilterActive(getState())

            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                filterPanel: open ? { transferTypeFilter, clubAgentsFilter, moreFilter, lookingForAgentFilter: { showOnlyLookingForAgent: isLookingForAgentActive } } : null,
            })))
        }
    }

    public static applyFilter() {
        return (dispatch, getState: () => AppState) => {
            const filterPanel = Selectors.getFilterPanel(getState());
            const structure = Selectors.getStructure(getState())
            const gridState = GridActions.Selectors.getGridState(getState())
            dispatch(stateController.setState({
                moreFilter: filterPanel.moreFilter,
                transferTypeFilter: filterPanel.transferTypeFilter,
                lookingForAgent: filterPanel.lookingForAgentFilter?.showOnlyLookingForAgent
            }));
            dispatch(Actions.setClubsAgentsFilter(filterPanel.clubAgentsFilter))
            dispatch(GridActions.Actions.resetPage());
            const action = () => {
                if (!filterPanel.clubAgentsFilter.showOnlyClubs && !filterPanel.clubAgentsFilter.showOnlyTrustedAgents) {
                    dispatch(FilterActivityService.filterMore(filterPanel.moreFilter, structure, gridState))
                    dispatch(FilterActivityService.filterMarketValue(filterPanel.transferTypeFilter, gridState))
                    dispatch(FilterActivityService.filterPosition(filterPanel.transferTypeFilter.positionList, gridState))
                }

                dispatch(FilterActivityService.filterClubAgents(filterPanel.clubAgentsFilter))
            }
            dispatch(GridActions.Actions.refresh(action));
            dispatch(Actions.toggleAllFilterModal(false))
        }
    }

    public static setFilterPanel(data: any) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ filterPanel: { ...data } }));
        }
    }

    public static resetFilter() {
        return dispatch => {
            dispatch(Actions.clearClubsAgentsFilter());
            dispatch(Actions.clearMoreFilter());
            dispatch(Actions.clearTranferTypeFilter());
            dispatch(Actions.clearLookingForAgentFilter());
        }
    }

    public static init(tab: string) {
        return dispatch => {
            const gridState = getGridState(tab)
            dispatch(Actions.restoredFilter(gridState))
            dispatch(GridActions.Actions.resetPage())
            dispatch(Suggestions.Actions.onKeywordClearWithoutRefresh())
            dispatch(GridActions.Actions.setGridState(tab))
        }
    }

    public static restoredFilter(gridState: GridActions.GridToggleState) {
        return (dispatch, getState: () => AppState) => {
            const filter: any = storedFilter.restoredFilter();
            const state = getState()
            const subState = Selectors.getRoot(state)
            const sortBy = Selectors.getSortBy(state)
            const structure = Selectors.getStructure(state)
            const isLookingForAgentEnabled = getState().auth.agencyPermission.agencyPlayerMarket;
            if (filter && gridState === GridActions.GridToggleState.Search) {
                const playerFilter = filter.playerFilter || {}

                let playerMinutesMin = playerFilter.minutesPlayed ? playerFilter.minutesPlayed.min : structure.minutesPlayed.min;
                let playerMinutesMax = playerFilter.minutesPlayed ? playerFilter.minutesPlayed.max : structure.minutesPlayed.max;

                let mpMaxUpdatedFromServer = true;
                if (playerMinutesMax == null && structure.minutesPlayed) {
                    playerMinutesMax = structure.minutesPlayed.max;
                    mpMaxUpdatedFromServer = false;
                }

                dispatch(stateController.setState({
                    mpMaxUpdatedFromServer,
                    moreFilter: {
                        ...getInitialCriteria(),
                        gbePass: playerFilter.gbePass,
                        minHeight: playerFilter.minHeight,
                        contractExpiryMonthesMax: playerFilter.contractExpiryMonthesMax,
                        leaguesList: playerFilter.leaguesList,
                        leaguesLists: playerFilter.leaguesLists,
                        nationalityList: playerFilter.nationalityList,
                        age: playerFilter.age,
                        minutesPlayed: {
                            min: playerMinutesMin,
                            max: playerMinutesMax,
                        },
                        minRating: playerFilter.minRating
                    },
                    transferTypeFilter: {
                        ...getTransferTypeCriteria(),
                        showOnlyFreeAgentPlayers: playerFilter.showOnlyFreeAgentPlayers,
                        positionList: playerFilter.positionList,
                        transferFee: playerFilter.transferFee,
                        anuallGrossSalaryYearly: playerFilter.anuallGrossSalaryYearly,
                        loanFee: playerFilter.loanFee,
                        marketValue: playerFilter.marketValue,
                    },
                    sortBy: filter.sortByKey || sortBy,
                    clubAgentsFilter: filter.clubsAndAgentsFilter,
                    lookingForAgent: isLookingForAgentEnabled ? playerFilter.lookingForAgent : false
                }));
                dispatch(Suggestions.Actions.setKeyword(filter.keyword))
            } else {
                dispatch(Actions.resetFilter())
                dispatch(Actions.initSorting())
            }
        }
    }

    public static resetFilterAsync() {
        return dispatch => {
            dispatch(Actions.resetFilter());
            dispatch(GridActions.Actions.resetPage());
            dispatch(GridActions.Actions.refresh());
        }
    }

    public static initFilter() {
        return dispatch => {
            dispatch(Actions.resetFilter());
            dispatch(Actions.initSorting())
            dispatch(GridActions.Actions.resetPage());
            dispatch(Suggestions.Actions.onKeywordClearWithoutRefresh())
        }
    }

    public static applySorting(sortBy: SearchByKey, column: string) {
        return (dispatch, getState: () => AppState) => {
            const gridState = GridActions.Selectors.getGridState(getState())

            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                sortBy,
            })));
            dispatch(GridActions.Actions.resetPage());
            dispatch(GridActions.Actions.refresh(
                () => dispatch(FilterActivityService.sortBy(column || 'Availability', gridState))
            ));
        }
    }

    public static initSorting() {
        return (dispatch, getState: () => AppState) => {
            const state = getState();
            const sortBy = Selectors.isActiveClubsAgentsFilter(state)
                ? SearchByKey.XtvHightToLow
                : SearchByKey.XtvHightToLow;

            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                sortBy,
            })));
        }
    }

    public static deleteCompetitionList(listId: number) {
        return (dispatch, getState) => {
            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                moreFilter: {
                    ...draftState.moreFilter,
                    leaguesLists: draftState.moreFilter.leaguesLists.filter(item => item.id !== listId)
                },
            })))
        }
    }

    public static openPlayerMarketModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                playerMarketModal: { isOpen: true, isProcessing: false, step: draftState.structure.playerMarketPermissionRequested ? 3 : 1 }
            })));
        }
    }
    public static closePlayerMarketModal() {
        return (dispatch, getState: () => AppState) => {
            const currentStep = Selectors.getRoot(getState()).playerMarketModal.step;
            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                playerMarketModal: null
            })));

            if (currentStep === 1) {
                dispatch(userActivityInsert({
                    PageName: 'Search [Player Market Restriction]',
                    Message: 'Closed',
                    PageType: PageType.Search,
                }))
            }
            if (currentStep === 3) {
                dispatch(userActivityInsert({
                    PageName: 'Search [Player Market Restriction]',
                    Message: 'Closed (Already Interested)',
                    PageType: PageType.Search,
                }))
            }

        }
    }

    public static interestedPlayerMarketModal() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                playerMarketModal: { ...draftState.playerMarketModal, isProcessing: true }
            })));

            await PlayerMarketBannerService.interest();

            dispatch(stateController.setState((draftState) => ({
                ...draftState,
                playerMarketModal: { ...draftState.playerMarketModal, isProcessing: false, step: 2 },
                structure: { ...draftState.structure, playerMarketPermissionRequested: true }
            })));

            dispatch(userActivityInsert({
                PageName: 'Search [Player Market Restriction]',
                Message: 'Interested',
                PageType: PageType.Search,
            }))
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState): SearchState => state.agency.playerSearch.filter;
    public static getClubAgentsFilterModal = (state: AppState) => Selectors.getRoot(state).clubAgentsFilterModal;
    public static getClubAgentsFilter = (state: AppState) => Selectors.getRoot(state).clubAgentsFilter;
    public static isActiveClubsAgentsFilter = (state: AppState) => Selectors.getClubAgentsFilter(state).showOnlyClubs
        || Selectors.getClubAgentsFilter(state).showOnlyTrustedAgents;
    public static isLookingForAgentFilterActive = (state: AppState) => Selectors.getRoot(state).lookingForAgent;
    public static getPositionFilterModal = (state: AppState): PositionFilter => Selectors.getRoot(state).positionFilterModal;

    public static getTransferTypeFilterModal = (state: AppState): TransferTypeFilter => Selectors.getRoot(state).transferTypeFilterModal;
    public static getTranferTypeFilter = (state: AppState) => Selectors.getRoot(state).transferTypeFilter;
    public static isActiveTransferTypeFilter = (state: AppState) => {
        return JSON.stringify({ ...Selectors.getTranferTypeFilter(state), positionList: [] }) !== JSON.stringify(getTransferTypeCriteria())
    }
    public static getPositionFilter = (state: AppState) => Selectors.getRoot(state).transferTypeFilter.positionList;

    public static getMoreFilterModal = (state: AppState) => Selectors.getRoot(state).moreFilterModal;
    public static getMoreFilter = (state: AppState) => Selectors.getRoot(state).moreFilter;
    public static isActiveMoreFilter = (state: AppState) => JSON.stringify(Selectors.getMoreFilter(state)) !== JSON.stringify({ ...getInitialCriteria(), minutesPlayed: Selectors.getStructure(state).minutesPlayed })

    public static getFilterPanel = (state: AppState) => Selectors.getRoot(state).filterPanel;

    public static getStructure = (state: AppState) => Selectors.getRoot(state).structure;
    public static getSortBy = (state: AppState) => Selectors.getRoot(state).sortBy;
    public static isStructureLoading = (state: AppState) => Selectors.getRoot(state).structureLoading
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    SearchState as State,
    Actions,
    Selectors,
    stateController as Controller,
};