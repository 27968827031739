import { StateController } from 'utils/action-declaration';

class AgentsState {
    connectedByClubs: Array<any>
    connectedByLeagueAndCounties: Array<any>
}

const defaultState: AgentsState = {
    connectedByClubs: [],
    connectedByLeagueAndCounties: []
}

const stateController = new StateController<AgentsState>(
    'AGENCY_PROFILE/CLUB_CONNECTIONS',
    defaultState
)

class Actions {
    public static saveDataToState(data: AgentsState) {
        return dispatch => {
            dispatch(stateController.setState({ ...data }))
        }
    }
}

class Selectors {
    
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    AgentsState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};