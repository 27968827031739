import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer'
import VirtualSummitService from 'api/virtual-summit/virtual-summit.service';
import { MeetingPreparationModel, MeetingModel, AgendaModel } from 'api/virtual-summit/models/meeting-preparation'

class MeetingPreparationData {
    info: Array<MeetingPreparationModel>;
    isLoading: boolean
}

const defaultState: MeetingPreparationData = {
    info: [],
    isLoading: false
}

const stateController = new StateController<MeetingPreparationData>(
    "EVENT/MEETING-PREPARATION",
    defaultState
);

class Actions {

    public static loadMeetingPreparationData = (redirect, needToRedirect, defaultPath = false) => {
        return async (dispatch, getState: () => AppState) => {
            let userId = getState().auth.userId;
            dispatch(stateController.setState({ isLoading: true }));

            let data = await VirtualSummitService.getMeetingPraparationDataV2(userId);

            /*
            if (defaultPath) { // When only one tab is awailable
                redirect(defaultPath)
            } else 
            */
            if (needToRedirect) {
                if (data[0].tables.length > 0) {
                    redirect('/events/agenda-builder')
                } else {
                    redirect('/events/attendance')
                }
            } else if (data[0].tables[0].meetings.length > 0) {
                redirect('/events/agenda-builder')
            } else if (window.location.pathname === '/events' || window.location.pathname === '/events/') {
                redirect('/events/attendance')
            }

            dispatch(stateController.setState({
                info: data,
                isLoading: false
            }));
        }
    }
}

class Selectors {
    public static selectIsLoading(state: any) {
        return state.events.virtualSummit.meetingPreparation.isLoading
    }
    // public static selectPracticalInfo(state: AppState) {
    //     const tablesInfo = state.events.virtualSummit.meetingPreparation.info.tables.map(i => {
    //         return {
    //             ...i.userSummitTable,
    //             hosterName: i.hosterName
    //         }
    //     })
    //     return tablesInfo
    // }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    MeetingPreparationData as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};



