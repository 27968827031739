import React from 'react'
import styled from 'styled-components'

import Panel from 'components/Panel';
import { EventTitle } from '../past-event-title'
import { PastEventStats } from '../past-event-stats'
import { PastEventClubLogos } from '../past-event-club-logos'
import { americasSummit14_15thFebruaryAttendees } from 'app/events/components/shared/data/past-events-clubs';
import AndreZanotta from 'media/images/events/orlando/andre_zanotta.jpg';
import Tanner from 'media/images/events/orlando/tanner.jpg';
import Convey from 'media/images/events/orlando/convay.jpg';
import Platek from 'media/images/events/orlando/phil_platek.png';

const AmericasSummit14_15thFebruary = () => {
    return (
        <div className="row">
            <div className="col-md-8 col-12">
                <Panel className="content-wrapper" style={{ paddingBottom: 30 }}>
                    <EventTitle
                        number="1"
                        bgImageNumber={4}
                        th="st"
                        title1="Americas Summit:"
                        title2="Special Edition"
                        date="14-15th Feb 2022, Orlando, Florida"
                        vs={true}
                    />
                    <SpeakersSection>
                        <h2>Speakers</h2>
                        <div className="speaker-card-container">
                            <div className="speaker-card">
                                <div className="speaker-image">
                                    <div style={{ backgroundImage: `url('${Platek}')`}}></div>
                                </div>
                                <div className="speaker-info">
                                    <div className="speaker-info__name">Philip Platek</div>
                                    <div className="speaker-info__position">Spezia Calcio and more</div>
                                </div>
                            </div>
                            <div className="speaker-card">
                                <div className="speaker-image">
                                    <div style={{ backgroundImage: `url('${Convey}')` }}></div>
                                </div>
                                <div className="speaker-info">
                                    <div className="speaker-info__name">Paul Conway</div>
                                    <div className="speaker-info__position">KV Oostende and more</div>
                                </div>
                            </div>
                            <div className="speaker-card">
                                <div className="speaker-image">
                                    <div style={{ backgroundImage: `url('${AndreZanotta}')` }}></div>
                                </div>
                                <div className="speaker-info">
                                    <div className="speaker-info__name">Andre Zanotta</div>
                                    <div className="speaker-info__position">FC Dallas</div>
                                </div>
                            </div>
                            <div className="speaker-card">
                                <div className="speaker-image">
                                    <div style={{ backgroundImage: `url('${Tanner}')` }}></div>
                                </div>
                                <div className="speaker-info">
                                    <div className="speaker-info__name">Ernst Tanner</div>
                                    <div className="speaker-info__position">Philadelphia Union</div>
                                </div>
                            </div>
                        </div>
                    </SpeakersSection>
                </Panel>
            </div>
            <div className="col-md-4 col-12 row no-gutters">
                {/* <div className="col-md-12 col-sm-6 col-12" style={{height: "148px"}}>
                </div> */}
                <div className="col-12">
                    <PastEventClubLogos
                        attendees={americasSummit14_15thFebruaryAttendees}
                        countlimit={60}
                    />
                </div>
            </div>
        </div>
    )
}

const SpeakersSection = styled.div`
    h2 {
        font-size: 21px;
        line-height: 34px;
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .speaker-card-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .speaker-card {
            width: 128px;
            margin-bottom: 10px;

            .speaker-image {
                display: flex;
                justify-content: center;
        
                & > div{
                    width: 128px;
                    height: 128px;
                    border-radius: 100%;
                    background-position: center;
                    background-size: cover;
                }
            }
        
            .speaker-info {
                margin-top: 15px;
                font-size: 16px;
                line-height: 19px;
                text-align: center;
        
                &__name {
                    font-weight: bold;
                }
        
                &__position {
                }
            }

        }
    }
`;

export default AmericasSummit14_15thFebruary