import { ToastContainer } from 'react-toastify'
import { Zoom } from 'react-toastify'



export default function Notifications() {
    return (
        <ToastContainer
            limit={3}
            draggable
            rtl={false}
            closeOnClick
            pauseOnHover
            pauseOnFocusLoss
            autoClose={3000}
            transition={Zoom}
            newestOnTop={false}
            position="top-right"
            hideProgressBar={true}
        />
    )
}