import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import userActivityInsert from "app/user-activity/actions/user-activity.actions";
import SearchService from 'api/search/search.agencyside.service';
import { UpsellBannerModel } from 'api/search/model/upsell-banner'

class UpsellBannerState {
    upsellBanner: UpsellBannerModel;
}

const defaultState: UpsellBannerState = {
    upsellBanner: {
        processing: false,
        isShowUpsellBanner: false,
        isSuccessMessageModalOpen: false,
        requestInfo: {
            showBanner: false,
            playerMarketSubscriptionValue: null,
            playerMarketSubscriptionValueFormatted: '',
        },
    }
}

const stateController = new StateController<UpsellBannerState>(
    'SEARCH/UPSELL_BANNER',
    defaultState
)

class Actions {

    public static dispose = () => {
        return async (dispatch) => {
            dispatch(stateController.setState({ ...defaultState }));
        }
    }

    public static init = () => {
        return async (dispatch) => {

            const data = await SearchService.getUpsellBannerData();

            if (data.showBanner) {
                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    upsellBanner: {
                        ...prevState.upsellBanner,
                        requestInfo: {
                            ...prevState.upsellBanner.requestInfo,
                            showBanner: data.showBanner,
                            playerMarketSubscriptionValue: data.playerMarketSubscriptionValue,
                            playerMarketSubscriptionValueFormatted: data.playerMarketSubscriptionValueFormatted,
                        }
                    }
                })))
                dispatch(Actions.showBanner())
            }
        }
    }

    public static showBanner = () => {
        return (dispatch) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                upsellBanner: {
                    ...prevState.upsellBanner,
                    isShowUpsellBanner: true
                }
            })))
        }
    }

    public static closeBanner = () => {
        return async (dispatch, getState: () => AppState) => {

            const subscription = Selectors.getSubscriptionValueToString(getState());
            dispatch(Actions.sendUserActivity(`Closed Upgrade from ${subscription} Banner`, 'Upgrade'));

            await SearchService.closeUpsellBanner();

            dispatch(Actions.hideBanner());
        }
    }

    public static hideBanner = () => {
        return async (dispatch) => {

            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                upsellBanner: {
                    ...prevState.upsellBanner,
                    isShowUpsellBanner: false
                }
            })))
        }
    }

    public static getInTouch = () => {
        return async (dispatch, getState: () => AppState) => {
            try {
                const subscription = Selectors.getSubscriptionValueToString(getState());

                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    upsellBanner: {
                        ...prevState.upsellBanner,
                        processing: true
                    }
                })))

                dispatch(Actions.sendUserActivity(`Clicked Get in Touch for Upgrade from ${subscription}`, 'Upgrade'));

                await SearchService.getInTouch();

                dispatch(Actions.openSuccessMessageModal())

            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    upsellBanner: {
                        ...prevState.upsellBanner,
                        processing: false
                    }
                })))
            }
        }
    }

    public static openSuccessMessageModal() {
        return (dispatch, getState: () => AppState) => {
            const subscription = Selectors.getSubscriptionValueToString(getState());
            dispatch(Actions.sendUserActivity(`Upgrade from ${subscription} Request Confirmed`, 'Upgrade'));

            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                upsellBanner: {
                    ...prevState.upsellBanner,
                    isSuccessMessageModalOpen: true
                }
            })))
        }
    }

    public static closeSuccessMessageModal() {
        return (dispatch) => {
            dispatch(Actions.sendUserActivity(`Clicked Close Upgrade Request Confirmation`, 'Upgrade'));
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                upsellBanner: {
                    ...prevState.upsellBanner,
                    isSuccessMessageModalOpen: false,
                    isShowUpsellBanner: false
                }
            })))
        }
    }

    public static sendUserActivity(message: string, pageName: string) {
        return (dispatch) => {
            dispatch(userActivityInsert({
                Message: message,
                PageName: `Search [${pageName}]`,
            }))
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState): UpsellBannerModel => state.agency.playerSearch.upsellBanner.upsellBanner;
    public static isBannerVisible = (state: AppState): boolean => Selectors.getRoot(state).isShowUpsellBanner;
    public static getSubscriptionValue = (state: AppState): number => Selectors.getRoot(state).requestInfo?.playerMarketSubscriptionValue;
    public static getSubscriptionValueFormatted = (state: AppState): string => Selectors.getRoot(state).requestInfo?.playerMarketSubscriptionValueFormatted;
    public static getSubscriptionValueToString = (state: AppState): string => {
        const subscription = Selectors.getSubscriptionValue(state);
        if (subscription === null) {
            return
        }
        if (subscription === 150000) {
            return '150K'
        }
        if (subscription === 300000) {
            return '300K'
        }
        if (subscription === 500000) {
            return '500K';
        }
    }
}
const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    UpsellBannerState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};