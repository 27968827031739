import { EventInfoModel } from 'app/events/components/authorized-event-page/events-map/models';
import ClubComponent from './club-root/events.switch'
import AgencyComponent from './agency-root/events.switch'

const eventSettings: EventInfoModel = {
    shortTitle: "TransferRoom Virtual Deal Day",
    title: "TransferRoom Virtual Deal Day",
    shortEventDates: "1st August 2022",
    eventDates: "1st August 2022",
    location: "Virtual",
    componentForClubs: ClubComponent,
    componentForAgents: AgencyComponent,
    speakerCard: () => null,
    countOfEvents: 0,
    agencyCountOfEvents: 0,
    timeZone: 'CET',
    hprInfo: {
        showForClub: false,
        showForAgency: false,
        userActivityPageName: "Home [TransferRoom Virtual Deal Day]",
        description: "Join key decision makers from 180+ clubs across 45+ countries on August 1st and engage in fast-paced transfer talks."
    },
    registration: {
        activateAgencyFeature: true,
        hasDateSelectorStep: false
    },
    visibility: {
        isEventFinished: true,
        canShowAttendeesComponent: true,
        shouldShowConfirmedAttendees: false,
        shouldShowConfirmedAttendeesForAgencies: false,
        canShowAgendaBuilder: true
    }
};

export default eventSettings;