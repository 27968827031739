import { createSelector } from 'reselect'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { StateController } from 'utils/action-declaration';
import AgencyHomePageService from 'api/agency/agent/landing/agency-homepage.service'
import { Dashboard, PlayerVerificationItemStatusEnum } from 'api/agency/agent/landing/agency-dashboard'
import { AgentProfileModel } from 'api/agency/agent/agent-profile/agent-profile'
import { PlayerTransparencyItem } from 'api/agency/agent/agency-transparency/models';
import historyAccessor from 'history-accessor';
import { agencyPaths } from 'routes/paths';

import AgentProfileService from 'api/agency/agent/agent-profile/agent-profile.service'
import AgencyAdService from 'api/agency/agent/landing/agency-ads-permission.service'
import AgencyTransparencyService from 'api/agency/agent/agency-transparency/agency-transparency.service';
import AgencyHeadCoachesService from 'api/agency/agent/head-coaches/head-coaches.service';

import { Actions as PitchActions } from 'pages/agency/authorized-agent/home-page/redux/pitch-opportunities-verified.controller'
import { AppState } from 'root.reducer';
import { PageType } from 'constants/enums';
import { AGENCY_FORCE_TRANSPARENCY_LAYOUT_TRUE } from "store/actionTypes";
import { isLowerTierAgency } from 'store/auth/authReducer';
import { AgentClubStaffInvitationStatusEnum } from 'api/agency/player/shared/shared-models';

class DashBoardState {
    isLoading: boolean
    dashboard: Dashboard
    currentAgent: AgentProfileModel
    showEvent: boolean;
    clubsConnectedModalOpen: boolean;
    showAlert: boolean;
    showAlertIsLoading: boolean;
    priorityPlayerItems: PlayerTransparencyItem[];
    isAgencyTransparent: boolean;
}

const defaultState: DashBoardState = {
    dashboard: {
        playerVerifications: [],
        clubStaffVerifications: [],
        playerPitchesCount: 0,
        totalPlayersCount: 0,
        totalClubStaffCount: 0,
        countOfVerifiedPlayers: 0,
        countOfPremiumVerifiedPlayers: 0,
        playerCreditAvailable: 0,
        regularPlayerCreditAvailable: 0,
        recommendationFlags: {
            addPlayers: false,
            chooseYourSubscription: false,
            connectWithClubs: false,
            getVerified: false,
            inviteAllYourColleagues: false,
            eventRegister: false
        },
        invitedClubs: [],
        connectedToAgencyClubs: [],
        countOfPitchOpportunities: 0,
        playersWithOpportunities: [],
        countOfAllActiveSquads: 0,
        totalOpportunitiesWithDeclined: 0,
        countOfPreconnectedPlayers: 0,
        playerMarketRequested: false,
        showReonboardingBanner: false,
        revokedAccessClubs: [],
        totalPlayersWithSharedInsights: 0,
        totalPremiumPlayersCount: 0
    },
    isLoading: false,
    showEvent: false,
    currentAgent: null,
    clubsConnectedModalOpen: false,
    showAlert: false,
    showAlertIsLoading: false,
    priorityPlayerItems: [],
    isAgencyTransparent: false,
}

const stateController = new StateController<DashBoardState>(
    "AGENCY/LANDING_PAGE/DASHBOARD",
    defaultState
);

class Actions {

    public static openClubsConnectedModal() {
        return (dispatch) => {
            dispatch(stateController.setState({ clubsConnectedModalOpen: true }))
        }
    }

    public static closeClubConnectionModal() {
        return (dispatch) => {
            dispatch(stateController.setState({ clubsConnectedModalOpen: false }))
        }
    }

    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState))
        }
    }

    public static applyAccess() {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.landingPage.dashboard;
            await AgencyAdService.activatePitchAccess()

            dispatch(userActivityInsert({
                PageName: 'Agency Home [New club connection]',
                Message: 'Closed',
                PageType: PageType.AgencyHome,
            }))
            dispatch(stateController.setState({ dashboard: { ...subState.dashboard, invitedClubs: [] } }))
            //dispatch(this.loadLandingPageData())
        }
    }

    public static dismissInvitedColleagues() {
        return async (dispatch, getState: () => AppState) => {
            await AgencyHomePageService.dismissInvitedColleagues()
            dispatch(stateController.setState((prevState) => {
                return ({ ...prevState, dashboard: { ...prevState.dashboard, recommendationFlags: { ...prevState.dashboard.recommendationFlags, inviteAllYourColleagues: false } } })
            }))
            dispatch(userActivityInsert({
                PageName: 'Agency Home [Invite Colleagues]',
                Message: 'Dismissed',
                PageType: PageType.AgencyHome,
            }))
        }
    }

    public static setIsAgencyTransparent() {
        return async (dispatch, getState: () => AppState) => {
            const countOfPriorityPlayers = getState().agency.landingPage.dashboard.priorityPlayerItems.length;
            const countOfSharedPlayers = Selectors.getIsInsightsSharedItems(getState()).length;

            const percentagePriorityPlayers = countOfPriorityPlayers === 0 ? 0 : Math.floor(countOfSharedPlayers / countOfPriorityPlayers * 100);
            const isAgencyTransparent = percentagePriorityPlayers >= 50;

            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                isAgencyTransparent: isAgencyTransparent,
            })));
        }
    }

    public static inviteColleagues() {
        return async (dispatch) => {
            historyAccessor.push(agencyPaths.inviteAgents);
            dispatch(userActivityInsert({
                PageName: 'Agency Home [Invite Colleagues]',
                Message: 'Invite Now',
                PageType: PageType.AgencyHome,
            }))
        }
    }

    public static loadLandingPageData() {
        return async (dispatch, getState: () => AppState) => {
            const isLta = isLowerTierAgency(getState());
            dispatch(stateController.setState({ isLoading: true }))

            const dashboard: Dashboard = await AgencyHomePageService.getDashboard();
            const currentAgent = await AgentProfileService.getProfile();
            const showEvent = await AgencyHomePageService.showEvent();

            if (!isLta) {
                await dispatch(Actions.loadTransparencyData());
            }

            dispatch(stateController.setState({
                isLoading: false,
                dashboard: { ...dashboard },
                currentAgent: currentAgent,
                showEvent: showEvent,
            }));

            dispatch(PitchActions.setPlayers(dashboard.playersWithOpportunities));
        }
    }

    public static loadTransparencyData() {
        return async (dispatch) => {
            try {
                dispatch(stateController.setState({ isLoading: true }))
                const { showAlert, playerItems } = await AgencyTransparencyService.getTransparencyInfo();
                await dispatch(stateController.setState({
                    showAlert,
                    priorityPlayerItems: playerItems,
                }));
                dispatch(Actions.setIsAgencyTransparent());
            } finally {
                dispatch(stateController.setState({ isLoading: false }))
            }
        }
    }

    public static refreshCounters() {
        return async (dispatch, getState: () => AppState) => {
            const liteCounters = await AgencyHomePageService.getLiteCounters();
            const dashboard = getState().agency.landingPage.dashboard.dashboard;

            dispatch(stateController.setState({
                isLoading: false,
                dashboard: { ...dashboard, totalOpportunitiesWithDeclined: liteCounters.opportunityPitchCountIncludeDeclined, countOfPitchOpportunities: liteCounters.opportunityPitchCountExcludeDeclined },
            }));
        }
    }

    public static removePlayer(id: number) {
        return async (dispatch, getState: () => AppState) => {
            const dashboard = getState().agency.landingPage.dashboard.dashboard;

            await AgencyHomePageService.removePlayer(id);
            const deletedPlayer = dashboard.playerVerifications.find(x => x.playerId == id)

            dashboard.playerVerifications = [...dashboard.playerVerifications.filter(x => x.playerId != id)];
            dashboard.totalPlayersCount = dashboard.totalPlayersCount - 1;
            if (!deletedPlayer.isRegular) {
                dashboard.playerCreditAvailable = dashboard.playerCreditAvailable + 1;
                dashboard.regularPlayerCreditAvailable = dashboard.regularPlayerCreditAvailable + 1;
            } else if (deletedPlayer.verifiedStatus === PlayerVerificationItemStatusEnum.SentToPlayer) {
                dashboard.regularPlayerCreditAvailable = dashboard.regularPlayerCreditAvailable + 1;
            }

            dispatch(stateController.setState(
                {
                    dashboard: { ...dashboard }
                }
            ))

            if (deletedPlayer) {
                dispatch(userActivityInsert({
                    PageName: 'Agency Home [Unverified Players]',
                    Message: 'Removed a Player',
                    PlayerId: id,
                    ClubId: deletedPlayer.squadId,
                    PageType: PageType.AgencyHome,
                }))
            }
        }
    }

    public static removeHeadCoach(id: number) {
        return async (dispatch, getState: () => AppState) => {
            const dashboard = getState().agency.landingPage.dashboard.dashboard;

            await AgencyHeadCoachesService.removeCoach(id);
            const deletedHeadCoach = dashboard.clubStaffVerifications.find(x => x.clubStaffId == id)

            dashboard.clubStaffVerifications = [...dashboard.clubStaffVerifications.filter(x => x.clubStaffId != id)];

            dashboard.totalClubStaffCount = dashboard.totalClubStaffCount - 1;
            if (!deletedHeadCoach.isRegular) {
                dashboard.playerCreditAvailable = dashboard.playerCreditAvailable + 1;
                dashboard.regularPlayerCreditAvailable = dashboard.regularPlayerCreditAvailable + 1;
            } else if (deletedHeadCoach.verifiedStatus === AgentClubStaffInvitationStatusEnum.SentToStaff) {
                dashboard.regularPlayerCreditAvailable = dashboard.regularPlayerCreditAvailable + 1;
            }

            dispatch(stateController.setState(
                {
                    dashboard: { ...dashboard }
                }
            ))

            if (deletedHeadCoach) {
                dispatch(userActivityInsert({
                    PageName: 'Agency Home [Unverified Head Coaches]',
                    Message: 'Removed Head Coach',
                    CoachId: id,
                    ClubId: deletedHeadCoach.squadId,
                    PageType: PageType.AgencyHome,
                }))
            }
        }
    }

    public static decreasePlayerCreditAndIncreaseVerifiedPlayers(isRegular: boolean) {
        return async (dispatch, getState: () => AppState) => {
            const dashboard = getState().agency.landingPage.dashboard.dashboard;

            const newState = isRegular ?
                {
                    ...dashboard,
                    regularPlayerCreditAvailable: dashboard.regularPlayerCreditAvailable - 1,
                    countOfVerifiedPlayers: dashboard.countOfVerifiedPlayers + 1,
                } :
                {
                    ...dashboard,
                    playerCreditAvailable: dashboard.playerCreditAvailable - 1,
                    countOfVerifiedPlayers: dashboard.countOfVerifiedPlayers,
                    countOfPremiumVerifiedPlayers: dashboard.countOfPremiumVerifiedPlayers + 1
                }

            dispatch(stateController.setState({
                isLoading: false,
                dashboard: newState,
            }));
        }
    }

    public static decreaseTotalPlayerCounter() {
        return async (dispatch, getState: () => AppState) => {
            const dashboard = getState().agency.landingPage.dashboard.dashboard;

            dispatch(stateController.setState({
                isLoading: false,
                dashboard: { ...dashboard, totalPlayersCount: dashboard.totalPlayersCount - 1 },
            }));
        }
    }

    public static setUpTransparencyInsights(isIndicator = false) {
        return async (dispatch, getState: () => AppState) => {
            dispatch({ type: AGENCY_FORCE_TRANSPARENCY_LAYOUT_TRUE });
            dispatch(Actions.sendUserActivity(isIndicator ? 'Agency Home [Transparency Indicator]' : 'Agency Home [Transparency Setup Alert]', 'Setup Now'))
        }
    }

    public static setInsightsRemindMeLater() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ showAlertIsLoading: true }));
            await AgencyTransparencyService.setRemindMeLater();
            dispatch(stateController.setState({
                showAlert: false,
                showAlertIsLoading: false
            }));
            dispatch(Actions.sendUserActivity('Agency Home [Transparency Setup Alert]', 'Closed'))
        }
    }
    public static sendUserActivity(pageName, message) {
        return async (dispatch) => {
            dispatch(userActivityInsert({
                PageName: pageName,
                Message: message,
            }))
        }
    }

    public static hideReonboardingBanner() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                dashboard: {
                    ...prevState.dashboard,
                    showReonboardingBanner: false
                },
            })
            ));
        }
    }
}

class Selectors {
    public static getLandingPage = (state: AppState) => state.agency.landingPage
    public static getDashboard = (state: AppState) => Selectors.getLandingPage(state).dashboard.dashboard;
    public static getPitchOpportunitiesVerirfied = (state: AppState) => Selectors.getLandingPage(state).pitchOpportunitiesVerirfied;
    public static getUnverifiedPlayers = createSelector([Selectors.getDashboard], (dashboard) =>
        dashboard.playerVerifications.filter((item) => item.verifiedStatus != PlayerVerificationItemStatusEnum.VerifiedByAdmin))
    public static getUnverifiedCoaches = (state: AppState) => Selectors.getDashboard(state).clubStaffVerifications.filter((item) => item.verifiedStatus === AgentClubStaffInvitationStatusEnum.PreconnectedToAgent ||
        item.verifiedStatus === AgentClubStaffInvitationStatusEnum.SentToStaff)
    public static getRecommentaionFlags = (state: AppState) => Selectors.getDashboard(state).recommendationFlags
    public static getIsInsightsSharedItems = (state: AppState) => state.agency.landingPage.dashboard.priorityPlayerItems.filter(item => item.isInsightsShared)
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    DashBoardState as State,
    Actions as Actions,
    stateController as Controller,
    Selectors as Selectors
};
