import * as types from 'store/actionTypes';
import { getRequests } from 'store/requests/requestsReducer';
import { createAction } from 'redux-actions';

const initialState = {
    result: null,
};

export default (s = initialState, action) => {
    switch (action.type) {
        case types.USER_RESET_PASSWORD_POST.SUCCESS:
            return {
                ...s,
                result: action.payload.data,
            };

        case types.USER_CLEAR_SET_PASSWORD_REQUEST_SESSION.SUCCESS:
            return {
                ...s,
                result: null,
            };

        case types.USER_RESET_PASSWORD_RESET_ERROR: {
            return {
                ...s,
                result: null,
            }
        }
        default:
            return s;
    }
};

export const resetPasswordRequest = createAction(types.USER_RESET_PASSWORD_RESET_ERROR);

export const resetPasswordResult = state => {
    return state.userresetpassword.result;
};

export const isResetPasswordProcessing = state => {
    return getRequests(state).some(r => r.type === types.USER_RESET_PASSWORD_POST);
}
