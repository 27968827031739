import {
    AVAILABLE_FRIENDLY_MATCHES_FETCH,
    FRIENDLY_MATCH_DECLARE_INTEREST,
    FRIENDLY_MATCH_DECLARE_INTEREST_BY_MESSAGE,
    FRIENDLY_MATCHES_INVITATIONS_FETCH,
    FRIENDLY_MATCH_SET_UNAVAILABLE_STATUS,
    INVITE_TO_MATCH_ID,
    MY_FRIENDLY_MATCHES_FILTER_CHANGE
} from 'store/actionTypes';
import createApiThunk, { createApiWithCorrelationID } from 'services/createApiThunk';
import { createAction } from 'redux-actions';
import { getAuthSquadId } from 'store/auth/authReducer';
import { getRequests } from 'store/requests/requestsReducer';
import { v4 } from 'uuid';
import { ActionType, PageType, UserActivityType } from 'constants/enums';

export const getFetchActivityLoadingStatus = state =>
    getRequests(state).some(
        r => r.type === AVAILABLE_FRIENDLY_MATCHES_FETCH
    );

export const changeFilter = createAction(MY_FRIENDLY_MATCHES_FILTER_CHANGE);

export const inviteToMatchId = (id, mode) => (dispatch, getState) => {
    return dispatch(createAction(INVITE_TO_MATCH_ID)(id, mode));
};

let lastMyPitchesFetchCorrelationId = null;

export const availableMatchesFetchFilter = (refresh = false) => async (
    dispatch,
    getState,
) => {

    lastMyPitchesFetchCorrelationId = v4();
    const state = getState();
    const currentSquadId = getAuthSquadId(state);

    const criteria = state.friendlyMatches.activities.filterOptions;

    const request = {
        type: AVAILABLE_FRIENDLY_MATCHES_FETCH,
        method: 'post',
        url: `FriendlyMatches/GetAvailableFriendlyMatchesFilter?squadId=${currentSquadId}`,
        payload: criteria,
        refresh: refresh,
        correlationId: lastMyPitchesFetchCorrelationId
    };

    dispatch(createAction(AVAILABLE_FRIENDLY_MATCHES_FETCH.REQUEST)(request));

    let response = await createApiWithCorrelationID(request);
    if (response.correlationID === lastMyPitchesFetchCorrelationId) {

        let action = createAction(AVAILABLE_FRIENDLY_MATCHES_FETCH.SUCCESS)({
            request: request,
            data: response.data,
        });

        dispatch(action);
    }
};



export const friendlyMatchInvitationsFetch = (type, payload = {}) => (
    dispatch,
    getState,
) => {
    const currentSquadId = getAuthSquadId(getState());

    const request = {
        type: FRIENDLY_MATCHES_INVITATIONS_FETCH,
        method: 'get',
        url: `FriendlyMatches/GetInvitations?squadId=${currentSquadId}`,
        payload,
    };
    return dispatch(createApiThunk(request));
};

export const friendlyMatchDeclareInterest = createAction(
    FRIENDLY_MATCH_DECLARE_INTEREST.AUTH,
    (ad, x, isInvitation, isFriendliesRecommender) => {
        return { ad, x, isInvitation, isFriendliesRecommender }
    },
    () => ({
        actionWithAuth: friendlyMatchDeclareInterestAuth,
    }),
);

const friendlyMatchDeclareInterestAuth = ({ payload: { ad, x, isInvitation, isFriendliesRecommender}, aspId }) => {
    const request = {
        type: FRIENDLY_MATCH_DECLARE_INTEREST,
        method: 'post',
        url: `FriendlyMatches/DeclareInterest/${aspId}`,
        payload: ad.id,
        ad: ad
    };

    const pageName = getPageName(isInvitation, isFriendliesRecommender);

    const trackingObj = {
        Message: 'Declared Interest',
        PageName: pageName,
        ClubId: ad.squadId,
        ActionType: ActionType.DeclaredInterest,
        PageType: PageType.Friendlies,
        UserActivityType: UserActivityType.Action
    }
    return createApiThunk(request, trackingObj)
};

export const friendlyMatchDeclareInterestByMessage = createAction(
    FRIENDLY_MATCH_DECLARE_INTEREST_BY_MESSAGE.AUTH,
    payload => payload,
    () => ({
        actionWithAuth: friendlyMatchDeclareInterestByMessageAuth,
    }),
);

const friendlyMatchDeclareInterestByMessageAuth = ({ payload: ad, aspId }) => {
    const request = {
        type: FRIENDLY_MATCH_DECLARE_INTEREST_BY_MESSAGE,
        method: 'post',
        url: `FriendlyMatches/DeclareInterestByMessage/${aspId}`,
        payload: ad.id,
        ad: ad
    };
    return createApiThunk(request)
};

export const setUnavailableStatusForMatch = createAction(
    FRIENDLY_MATCH_SET_UNAVAILABLE_STATUS.AUTH,
    payload => payload,
    () => ({
        actionWithAuth: setUnavailableStatusForMatchAuth,
    }),
);

const getPageName = (isInvitation, isFrendliesRecommender) => {
    const itemType = isFrendliesRecommender ? "Friendlies recommender" : 
        isInvitation ? "Invitation" : "Suggestion";
    return `Friendlies [${itemType}]`;
}

const setUnavailableStatusForMatchAuth = ({ payload: ad, aspId }) => {
    const request = {
        type: FRIENDLY_MATCH_SET_UNAVAILABLE_STATUS,
        method: 'post',
        url: `FriendlyMatches/SetUnavailableStatusForMatch/${aspId}`,
        payload: ad.id,
        ad,
    };
    const trackingObj = {
        Message: 'Not Available',
        PageName: 'Friendlies [Invitation]',
        ClubId: ad.squadId,
        PageType: PageType.Friendlies
    }
    return createApiThunk(request, trackingObj);
};
