import {
    SET_PITCHPLUS_STEP1,
    SET_PITCHPLUS_STEP2,
    SET_PITCHPLUS_STEP3,
    ALL_STEPS_WAS_DONE,
    CLEAR_PITCHPLUS_CREATE,
    SUPER_PITCH_CREATE_DISPOSE
} from "./pitch-plus.actions"
import { 
    SUPER_PITCH_FETCH_COMPETITONS_WITH_SQUAD, 
    SUPER_PITCH_CREATE_SUPER_PITCH,
    GET_RECOMMENDED_SQUADS_BY_PLAYER_ID_AND_PRICE,
    SUPER_PITCH_FETCH_SQUAD_PLAYERS 
} from 'store/actionTypes';

const initialState = {
    step1: { player: null },
    step2: { forSale: null, forLoan: null, sellOn: null },
    step3: [],
    squads: [],
    players: [],
    recommendedSquads:[],
    atLeasOnceAllStepsWasDone: false,
    sessionCompleted: false,
    allowedPitchesCount: 3
};


export default (superPitchCreateState = initialState, action) => {
    switch (action.type) {
        case SET_PITCHPLUS_STEP1: {
            return {
                ...superPitchCreateState,
                step1: action.payload,
            };
        }
        case SET_PITCHPLUS_STEP2: {
            return {
                ...superPitchCreateState,
                step2: action.payload,
            };
        }

        case SET_PITCHPLUS_STEP3: {
            return {
                ...superPitchCreateState,
                step3: action.payload,
            };
        }

        case ALL_STEPS_WAS_DONE: {
            return {
                ...superPitchCreateState,
                atLeasOnceAllStepsWasDone: true
            }
        }

        case CLEAR_PITCHPLUS_CREATE: {
            return {
                ...superPitchCreateState,
                step1: initialState.step1,
                step2: initialState.step2,
                step3: initialState.step3
            };
        }

        case SUPER_PITCH_FETCH_COMPETITONS_WITH_SQUAD.SUCCESS: {
            return {
                ...superPitchCreateState,
                squads: action.payload.data,
            };
        }

        case GET_RECOMMENDED_SQUADS_BY_PLAYER_ID_AND_PRICE.SUCCESS: {
            let allowedSquadIds = []
            superPitchCreateState.squads.filter(x => allowedSquadIds.push(...x.squads.map(_ => _.id)));
            //recommended and squads should be same, but we should check
            let recommendedWithAllowedIds = action.payload.data.filter(x => allowedSquadIds.includes(x.id))
                .map(x => {
                    return {
                        ...x,
                        isRecommended: true
                    }
                } );

            let recommender = [{
                areaId:-1,
                areaName:"",
                divisionLevel: -1,
                id:-1,
                name:"",
                nameWithArea:"Recommended Clubs",
                squads: recommendedWithAllowedIds
            }];
            
            let recommendedSquads =[...recommender,...superPitchCreateState.squads];

            return {
                ...superPitchCreateState,
                recommendedSquads: recommendedSquads,
            };
        }

        case SUPER_PITCH_CREATE_SUPER_PITCH.SUCCESS: {
            return {
                ...superPitchCreateState,
                sessionCompleted: true,
            };
        }

        case SUPER_PITCH_CREATE_DISPOSE: {
            return { superPitchCreateState, ...initialState, squads: superPitchCreateState.squads, players: superPitchCreateState.players };
        }

        case SUPER_PITCH_FETCH_SQUAD_PLAYERS.SUCCESS: {
            return {
                ...superPitchCreateState,
                players: action.payload.data.output,
            };
        }

        default:
            return superPitchCreateState;
    }
};
