import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer'
import VirtualSummitService from 'api/virtual-summit/virtual-summit.service';
import { userPaths } from 'routes/paths';
import { Actions as StepActions, RegistrationState } from './confirm.controller';
import { Actions as Step2Actions } from './step-2.controller';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';

export class User {
    firstName: string;
    lastName: string;
    club: string;
    title: string;
    email: string;
    phoneCodeAreaId: number | null;
    mobilePhone: string;
    order?: number;
}

export class Validation {
    firstNameValid: boolean;
    lastNameValid: boolean;
    titleValid: boolean;
    emailValid: boolean;
    phoneCodeAreaIdValid: boolean;
    phoneValid: boolean;
}

class AttendeesStep2_1State {
    user: User;
    validation: Validation;
    order: number;
    isBusy: boolean;
    usersToCreate: Array<User>;
}

const defaultState: AttendeesStep2_1State = {
    user: { club: "", email: "", firstName: "", lastName: "", phoneCodeAreaId: null, mobilePhone: "", title: "", order: -1 },
    validation: { phoneCodeAreaIdValid: true, phoneValid: true, titleValid: true, emailValid: true, lastNameValid: true, firstNameValid: true },
    order: null,
    isBusy: false,
    usersToCreate: []
}

const stateController = new StateController<AttendeesStep2_1State>(
    "VIRTUAL_SUMMIT/CONFIRM_STEP2_1",
    defaultState
);

class Actions {

    public static dispose = () => {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ ...defaultState }, "dispose"));
        }
    }

    public static init = (order: number, club: string) => {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().virtualSummit.confirmStep2_1;

            dispatch(stateController.setState({ ...defaultState, order: order, user: { ...defaultState.user, club: club }, usersToCreate: substate.usersToCreate }));
        }
    }

    public static createUser = (redirect: () => void) => {
        return async (dispatch, getState: () => AppState) => {

            let state = getState();
            let substate = getState().virtualSummit.confirmStep2_1;
            const { eventId } = state.auth;

            let current = substate.usersToCreate;
            dispatch(stateController.setState({ usersToCreate: [...current, { ...substate.user, order: substate.order }] }))

            await dispatch(Step2Actions.attendeesRemoveFirst());

            let toRegister = getState().virtualSummit.confirmStep2.usersToRegister;
            if (toRegister.length > 0) {
                dispatch(Actions.init(toRegister[0], getState().virtualSummit.confirmStep1.user.club));
            }
            else {
                dispatch(stateController.setState({ isBusy: true }));
                var userToSave = getState().virtualSummit.confirmStep2_1.usersToCreate;
                for (var usver of userToSave) {
                    let createdId = await VirtualSummitService.registerUser(
                        {
                            club: usver.club,
                            firstName: usver.firstName,
                            email: usver.email,
                            lastName: usver.lastName,
                            phoneCodeAreaId: usver.phoneCodeAreaId,
                            mobilePhone: usver.mobilePhone,
                            title: usver.title,
                        },
                        state.auth.squadId,
                        eventId
                    )
                    await dispatch(Step2Actions.addCreatedUser({ id: createdId, order: substate.order }))
                }

                await dispatch(Step2Actions.setAttendees());

                for (var usver of userToSave) {
                    dispatch(
                        userActivityInsert({
                            Message: `Continued Custom Extra Attendees. Selected: "${usver.firstName} ${usver.lastName}", "${usver.title}"`,
                            PageName: 'Event [Details]',
                            CurrentClubId: state.auth.squadId,
                            PageType: PageType.Events,
                            EventId: eventId
                        }),
                    );
                }

                var substateSelected = getState().virtualSummit.confirmStep2;
                let attendees: Array<number> = [];
                if (substateSelected.selectedNo2 && substateSelected.selectedNo2.id != -1) attendees.push(substateSelected.selectedNo2.id);
                if (substateSelected.selectedNo3 && substateSelected.selectedNo3.id != -1) attendees.push(substateSelected.selectedNo3.id);
                if (substateSelected.selectedNo4 && substateSelected.selectedNo4.id != -1) attendees.push(substateSelected.selectedNo4.id);

                for (var att of attendees) {

                    var userGet = state.virtualSummit.confirmStep2.options.find(item => item.id == att)
                    const isUserRegistrationWasAlreadyTracked = userGet.disabled
                    
                    if (!isUserRegistrationWasAlreadyTracked) {
                        dispatch(
                            userActivityInsert({
                                Message: `Continued Extra Attendees. Selected: ${userGet.name}, ${userGet.positionTitle}`,
                                PageName: 'Event [Details]',
                                CurrentClubId: state.auth.squadId,
                                PageType: PageType.Events,
                                EventId: eventId
                            }),
                        );
                    }
                }


                dispatch(stateController.setState({ isBusy: false }));

                dispatch(Actions.dispose());
                if (redirect != null && state.virtualSummit.confirmPage.fromEvent)
                    redirect();
                else
                    dispatch(StepActions.setStep(RegistrationState.Done));
            }
        }
    }

    public static setTitle = (title: string) => {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().virtualSummit.confirmStep2_1;
            dispatch(stateController.setState({ user: { ...substate.user, title: title } }));
        }
    }

    public static setPhone = (phone: string) => {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().virtualSummit.confirmStep2_1;
            dispatch(stateController.setState({ user: { ...substate.user, mobilePhone: phone } }));
        }
    }
    public static setPhoneAreaId = (areaId: number) => {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().virtualSummit.confirmStep2_1;
            dispatch(stateController.setState({ user: { ...substate.user, phoneCodeAreaId: areaId } }));
        }
    }

    public static setFirstName = (name: string) => {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().virtualSummit.confirmStep2_1;
            dispatch(stateController.setState({ user: { ...substate.user, firstName: name } }));
        }
    }


    public static setLastName = (name: string) => {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().virtualSummit.confirmStep2_1;
            dispatch(stateController.setState({ user: { ...substate.user, lastName: name } }));
        }
    }

    public static setEmail = (email: string) => {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().virtualSummit.confirmStep2_1;
            dispatch(stateController.setState({ user: { ...substate.user, email: email } }));
        }
    }

    public static setValidation = (fields: Validation) => {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ validation: fields }));
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    AttendeesStep2_1State as State,
    Actions as Actions,
    stateController as Controller
};



