import { AgentPlayerAgreementTypeEnum, IdName, ParentSquadInfo, AgentPlayerInvitationStatusEnum } from 'api/agency/player/shared/shared-models';

export interface MarketAreaModel {
    areaId: number
    areaName: string
    flagPath: string
    numberOfAgencies: number
}

export interface AgencyModel {
    agencyId: number
    agencyName: string
    playersQty: number
    isTrusted: boolean
    isHighTransparency: boolean
    primaryMarketArea: AreaModel
    secondaryMarketArea: AreaModel
    playerRepresentationStatus: PlayerRepresentationStatus
    expertInAreas: ExpertModel[]
    hasAgencyProfile?: boolean
}

export interface ExpertModel {
    flagPath: string
    id: number
    name: string
}
export interface AreaModel {
    id: number
    name: string
    flagPath: string
}

export enum PlayerRepresentationStatus {
    Sent = 1,
    DeclaredInterest = 2,
    Rejected = 3,
    Removed = 4
}

export interface AgencyInfoModel {
    agentPlayerRepresentationList: AgentPlayerRepresentationModel[] | null;
    generalPlayerInfos: GeneralAgencyInfoModel;
}

export interface GeneralAgencyInfoModel {
    playerId: number;
    invitationUid: string;
    playerName: string;
    playerPhone: string;
    emailConfirmed: boolean;
    agent: IdName;
    agentToPlayerConfirmed: boolean;
    agentToPlayerPending: boolean;
    playerToAgentPending: boolean;
    playerDisconnectedFromAgency: boolean;
    agreementPassed: boolean;
    wasEmailConfirmed: boolean;
    expiryDate: string | Date;
    agreementType: AgentPlayerAgreementTypeEnum;
    messagesCount: number;
    parentSquad: ParentSquadInfo;
    isTrusted: boolean;
    isHighTransparency: boolean;
    isActive: boolean;
    invitationStatusId: AgentPlayerInvitationStatusEnum;
    agencyLogo: string;
    playerJoinedTheAgencyDate: string | null;
    agencyPublicProfileUrl: string | null;
}

export interface AgentPlayerRepresentationModel {
    id: number;
    messageRequestId: number;
    agencyId: number;
    playerId: number;
    agentPlayerRepStatusId: number;
    agentPlayerRepName: string;
    updatedByUserId: number;
    updatedAt: string | Date;
    insertedAt: string | Date;
}

export interface AgentPlayerRepresentationRequestModel {
    id: number;
    messageRequestId: number;
    agencyId: number;
    agencyName: string;
    playerId: number;
    agentPlayerRepStatusId: number;
    agentPlayerRepName: string;
    updatedByUserId: number;
    updatedAt: string | Date;
    messageRequestTypeName: string;
    messageRequestTypeId: number;
    messageId: number;
    insertedAt: string | Date;
    messageResponseId: number;
    answerId: number;
    answerValue: string;
    messageResponse: string;
    isTrusted: boolean;
    isHighTransparency: boolean;
    hasAgencyProfile: boolean;
    agencyLogo: string;
    requestAge: string;
}

export interface RecommendationModel {
    areaId: number;
    countryName: string;
    flagPath: string;
    leagues: RecommendationLeagueModel[];
}

export interface RecommendationLeagueModel {
    id: number;
    name: string;
}

export enum PlayerMarkedInterestAreaSourceEnum {
    Suggestion = 1,
    Recommendation = 2,
    Country = 3,
}
