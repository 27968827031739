import { StateController } from 'utils/action-declaration';
import { ReceiverType } from 'api/messaging/models/get-channel-response'
import { AppState } from 'root.reducer'

export class MessageSendSession {
    recipientType?: ReceiverType;
    recipientId?: number;

    mesageTypeId: number;
    subject: string;

    toClub: boolean;
    toAgency: boolean;

    agencyId?: number;
    agencyName?: string;
    agencyCity?: string;
    agencyCountry?: string;
    isAgentPlayerVerified?: boolean;
    isTrustedAgent?: boolean;

    squadId?: number;
    squadLogo?: string;
    squadName?: string;
    squadLeagueName?: string;
    squadLeagueCountry?: string;

    checkBoxTitle?: string;
}

class SendMessageModalState {
    isSending: boolean;
    currentSession: MessageSendSession;
    specifcation: IMessageSendSpecificaion;
}

const defaultState: SendMessageModalState = {
    isSending: false,
    currentSession: null,
    specifcation: null
}

const stateController = new StateController<SendMessageModalState>(
    "SEND_MESSAGE_MODAL/MULTI_RECIPIENT",
    defaultState
);

export interface IMessageSendSpecificaion {
    sendMessage: (session: MessageSendSession, message: string, checked: boolean) => Promise<any>;
    cancelMessage: (session: MessageSendSession) => any;
}

class Actions {

    private static specifcation: IMessageSendSpecificaion;

    public static openSession(session: MessageSendSession, specification: IMessageSendSpecificaion) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ currentSession: session }));
            Actions.specifcation = specification;
        }
    }

    public static sendMessage(message: string, checked: boolean,) {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().sendMessageMultiRecipientsModal;
            dispatch(stateController.setState({ isSending: true }));
            await Actions.specifcation.sendMessage(substate.currentSession, message, checked)
            Actions.specifcation = null;
            dispatch(stateController.setState({ isSending: false, currentSession: null }));
        }
    }

    public static selectRecipient(recipientType: ReceiverType, id: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                currentSession: {
                    ...prevState.currentSession,
                    recipientId: id,
                    recipientType: recipientType
                }
            })))
        }
    }

    public static close() {
        return (dispatch, getState: () => AppState) => {
            const session = getState().sendMessageMultiRecipientsModal.currentSession
            Actions.specifcation.cancelMessage(session);
            Actions.specifcation = null;
            dispatch(stateController.setState({ ...defaultState }));
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    SendMessageModalState as State,
    Actions as Actions,
    stateController as Controller
};



