import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer'
import { FriendlyMatchService } from 'api/friendly/friendly-match.service'
import { AreaModel } from 'api/friendly/area-model'
import { ShortSquadModel } from 'api/friendly/short-squad-model'
import { closeFriendlyAdsModal, openFriendlyAdsModal } from 'pages/landing-page/redux/landing-page.actions'
import { FriendlyMatchModel } from 'api/friendly/friendly-invitation'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions'
import * as Recommendation from './performance-data.controller'
import { LandingPageService } from 'api/landing-page/landing-page-service';
import { ActionType, PageType, UserActivityType } from 'constants/enums';
import { convertToUtcDate } from 'utils/date';

const friendlyTeamTypes: Array<FriendlyTeam> = [
    { name: 'First Team', id: 1 },
    { name: 'First Team / U23', id: 5 },
    { name: 'B', id: 2 },
    { name: 'U23', id: 4 },
    { name: 'U21', id: 3 },
    { name: 'U19', id: 7 },
    { name: 'U18', id: 6 },
    { name: 'U16', id: 8 },
    { name: 'Development Squad', id: 9 },
];

const friendlyVenueTypes: Array<FriendlyVenue> = [
    { name: 'Home', id: 1 },
    { name: 'Away', id: 2 },
    { name: 'Home/Away', id: 23 },
];

class FriendlyAdModalState {
    currentStep: number;

    friendlyTeamTypes: Array<FriendlyTeam>;
    friendlyVenueTypes: Array<FriendlyVenue>;
    availableAreas: Array<AreaModel>;

    selectedFriendlyTeam: number;
    selectedFriendlyVenue: number;
    selectedArea: number;

    startDate: Date;
    endDate: Date;
    busyDates: { [id: number]: Array<Date>; }
    busyDatesLoading: boolean;
    loading: boolean;

    leagueSquadsCachedSource: { [id: number]: Array<ShortSquadModel>; }
    clubsLoading: boolean;
    clubSelectedArea: number;
    clubSelectedLeague: number;
    clubSelelection: Array<{ id: number, name: string }>;

    squads: Array<any>;
    selectedSquads: Array<number>;

    step1Processing: boolean;
    step2Processing: boolean;

    suggestions: Array<FriendlyMatchModel>;
    processingSuggestionIds: Array<number>;
    declareInterestProcessingIds: Array<number>;
}

export enum Step {
    Info = 1,
    Clubs = 2,
    Suggestions = 3
}

export class FriendlyTeam {
    id: number;
    name: string;
}

export class FriendlyVenue {
    id: number;
    name: string;
}

const defaultState: FriendlyAdModalState = {
    currentStep: Step.Info,

    friendlyTeamTypes: friendlyTeamTypes,
    friendlyVenueTypes: friendlyVenueTypes,
    availableAreas: [],

    selectedArea: null,
    selectedFriendlyTeam: 1,
    selectedFriendlyVenue: 1,
    startDate: null,
    endDate: null,

    busyDates: {},
    busyDatesLoading: false,
    loading: false,

    leagueSquadsCachedSource: {},
    clubsLoading: false,
    clubSelectedArea: null,
    clubSelectedLeague: null,
    clubSelelection: [],

    squads: [],
    selectedSquads: [],

    step1Processing: false,
    step2Processing: false,
    suggestions: null,
    processingSuggestionIds: [],
    declareInterestProcessingIds: []
}

const stateController = new StateController<FriendlyAdModalState>(
    "LANDING_PAGE/FRIENDLY_AD_MODAL",
    defaultState
);

class Actions {

    public static setSelectedClubs = (ids: Array<number>, callback: () => void) => {
        return async (dispatch, getState: () => AppState) => {
            let subState = getState().landingPage.friendlyAdModal;
            let allSquads = [];
            subState.squads.map(x => allSquads = [...allSquads, ...x.squads]);
            let squadInfos = ids.map(id => { return { id: id, name: allSquads.find(i => i.id == id).name } })
            dispatch(stateController.setState({ clubSelelection: squadInfos }));
            dispatch(Actions.goToSuggestions(callback));
        };
    };

    public static initSquads = () => {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ loading: true }))
            const data = await LandingPageService.activeSquadsFetch(true)
            dispatch(stateController.setState({ squads: data, loading: false }));
        };
    };

    public static declareInterestOfFriendlySuggestion(id: number) {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let subState = state.landingPage.friendlyAdModal;

            try {
                dispatch(stateController.setState({ 
                    processingSuggestionIds: [...subState.processingSuggestionIds, id],
                    declareInterestProcessingIds: [...subState.declareInterestProcessingIds, id]
                }))
                
                await FriendlyMatchService.declareInterestForFriendly(id);
                
                subState.suggestions.find(item => item.id === id).interestWasDeclared = true;
                let updatedItems = [...subState.suggestions];
                let selectedItem = subState.suggestions.filter(x => x.id == id);
                dispatch(stateController.setState(prevState => ({ 
                    ...prevState,
                    suggestions: updatedItems
                })))

                if (selectedItem.length > 0) {
                    dispatch(userActivityInsert({
                        PageName: 'Home [Friendlies recommender]',
                        Message: `Declared Interest`,
                        ClubId: selectedItem[0].squadId,
                        ActionType: ActionType.DeclaredInterest,
                        PageType: PageType.Home,
                        UserActivityType: UserActivityType.Action
                    }));
                }
            } catch(e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState(prevState => ({ 
                    ...prevState, 
                    processingSuggestionIds: [...prevState.processingSuggestionIds.filter(x => x !== id)], 
                    declareInterestProcessingIds: [...prevState.declareInterestProcessingIds.filter(x => x !== id)]
                })))
            }
        }
    }


    public static createAnotherOne() {
        return (dispatch) => {
            dispatch(closeFriendlyAdsModal());
            dispatch(openFriendlyAdsModal());
        }
    }

    public static goBack() {
        return (dispatch) => {
            dispatch(stateController.setState({ currentStep: Step.Info }))
        }
    }

    public static closeModal() {
        return (dispatch) => {
            dispatch(closeFriendlyAdsModal());
        }
    }

    public static removeAllSelection() {
        return (dispatch) => {
            dispatch(stateController.setState({ clubSelelection: [] }))
        }
    }

    public static dispose() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(defaultState));
        }
    }

    public static onLeagueToggle(prevVal: boolean) {
        return (dispatch, getState: () => AppState) => {
            let state = getState();
            let subState = state.landingPage.friendlyAdModal;

            let currentSelection = [...subState.clubSelelection];
            let currentSquadList = subState.leagueSquadsCachedSource[subState.clubSelectedLeague];

            let newSelectionWithout = [...currentSelection.filter(x => !currentSquadList.map(x => x.id).includes(x.id))]

            if (prevVal) {
            }
            else {
                newSelectionWithout = [...newSelectionWithout, ...currentSquadList.map(x => ({ id: x.id, name: x.name }))];
            }

            dispatch(stateController.setState({ clubSelelection: newSelectionWithout }))
        }
    }

    public static onSquadToggle(squadId: number, name: string) {
        return (dispatch, getState: () => AppState) => {
            let state = getState();
            let subState = state.landingPage.friendlyAdModal;
            let clubSelection = [...subState.clubSelelection];

            let squadInfo = { id: squadId, name: name };
            let newClubSelection = [...clubSelection]
            if (clubSelection.map(x => x.id).includes(squadId)) {
                newClubSelection = newClubSelection.filter(x => x.id != squadId);
            }
            else {
                newClubSelection = [...newClubSelection, squadInfo];
            }

            dispatch(stateController.setState({ clubSelelection: newClubSelection }))
        }
    }

    public static onClubsLeagueChange(id: number) {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let subState = state.landingPage.friendlyAdModal;
            dispatch(stateController.setState({ clubsLoading: true, clubSelectedLeague: id }))

            let currentClubs: any = subState.leagueSquadsCachedSource[id];
            let source = { ...subState.leagueSquadsCachedSource }
            if (currentClubs == null) {
                let squads = await FriendlyMatchService.getSquadsByCompetitionId(id, state.auth.squadId);
                source[id] = squads;
            }

            dispatch(stateController.setState({ leagueSquadsCachedSource: { ...source } }))
            dispatch(stateController.setState({ clubsLoading: false }))

        }
    }

    public static onClubsCountryChange(id: number) {
        return (dispatch, getState: () => AppState) => {
            let state = getState();
            let subState = state.landingPage.friendlyAdModal;
            let competition = subState.availableAreas.find(x => x.id == id);
            let firstLeagueInList = competition.competitions[0].id;
            dispatch(stateController.setState({ clubSelectedArea: id }))
            dispatch(this.onClubsLeagueChange(firstLeagueInList))
        }
    }

    public static setTeam(id: number) {
        return async (dispatch, getState) => {
            let state = getState();
            let landingPageState = state.landingPage.friendlyAdModal;

            dispatch(stateController.setState({ selectedFriendlyTeam: id, busyDatesLoading: true, startDate: null, endDate: null }))

            let currentArray: {} = landingPageState.busyDates[id]
            if (currentArray == null) {
                currentArray = await FriendlyMatchService.getAvailableDates(state.auth.squadId, id);
                let currentBusyDatesDict: {} = { ...landingPageState.busyDates }
                currentBusyDatesDict[id] = currentArray;
                dispatch(stateController.setState({ busyDates: currentBusyDatesDict }));
            }

            dispatch(stateController.setState({ busyDatesLoading: false }));
        }
    }

    public static setVenue(id: number) {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            if (id == 1) {
                dispatch(stateController.setState({ selectedFriendlyVenue: id, selectedArea: state.auth.areaId }));
            }
            else {
                dispatch(stateController.setState({ selectedFriendlyVenue: id }));
            }
        }
    }

    public static setArea(id: number) {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            if (id == state.auth.areaId) {
                dispatch(stateController.setState({ selectedArea: id, selectedFriendlyVenue: 1 }));
            }
            else {
                dispatch(stateController.setState({ selectedArea: id, selectedFriendlyVenue: 2 }));
            }
        }
    }

    public static init() {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            dispatch(stateController.setState({ loading: true }))

            let areas = await FriendlyMatchService.getActiveAreasWithCompetitions(true);
            let currentArray = await FriendlyMatchService.getAvailableDates(state.auth.squadId, state.landingPage.friendlyAdModal.selectedFriendlyTeam);
            let obj = {};
            obj[state.landingPage.friendlyAdModal.selectedFriendlyTeam] = currentArray;

            let naArea: AreaModel = {
                id: null,
                name: 'N/A',
                code: null,
                phoneCode: null,
                mask: null,
                competitions: []
            }

            dispatch(stateController.setState({
                availableAreas: [naArea, ...areas],
                busyDates: obj,
                loading: false,
                clubSelectedArea: state.auth.areaId,
                clubSelectedLeague: state.auth.competitionId,
                selectedArea: state.auth.areaId
            }))
        }
    }


    public static goToSuggestions = (callback: () => void | null = null) => {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let subState = state.landingPage.friendlyAdModal;

            let invitedClubs = '';
            subState.clubSelelection.map(item => {
                invitedClubs += item.name + ', ';
            });

            dispatch(stateController.setState({ step2Processing: true }))

            try {

                let iusVenueType: boolean = null;
                if (subState.selectedFriendlyVenue == 1) iusVenueType = true;
                else if (subState.selectedFriendlyVenue == 2) iusVenueType = false;

                let createdId = await FriendlyMatchService.createFriendlyMatch({
                    squadTypeId: subState.selectedFriendlyTeam,
                    startAt: convertToUtcDate(subState.startDate)?.toDate(),
                    endAt: convertToUtcDate(subState.endDate)?.toDate(),
                    isHomeVenue: iusVenueType,
                    areaId: subState.selectedArea
                })

                dispatch(userActivityInsert({
                    PageName: 'Home [Launch friendly ads]',
                    Message: 'Launched Friendly Ad',
                    PageType: PageType.Home
                }))

                if (callback) {
                    callback()
                }

                await FriendlyMatchService.inviteClubs({
                    squadIds: subState.clubSelelection.map(x => x.id),
                    friendlyMatchId: createdId
                })

                dispatch(userActivityInsert({
                    PageName: 'Home [Launch friendly ads]',
                    Message: `Invited Clubs: ${invitedClubs}`,
                    PageType: PageType.Home
                }));



                let result = await FriendlyMatchService.getSuggestions(createdId);
                dispatch(Recommendation.Actions.completedFriendlyAdsRecommendation())

                dispatch(stateController.setState({ currentStep: Step.Suggestions, suggestions: result }))


            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState({ step2Processing: false }))
            }
        }
    }

    public static goToClubsSelection = () => {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let subState = state.landingPage.friendlyAdModal;
            dispatch(stateController.setState({ clubsLoading: true, step1Processing: true }))

            dispatch(stateController.setState({ currentStep: Step.Clubs, step1Processing: false }))

            let currentClubs: any = subState.leagueSquadsCachedSource[subState.clubSelectedLeague];
            let source = { ...subState.leagueSquadsCachedSource }
            if (currentClubs == null) {
                let squads = await FriendlyMatchService.getSquadsByCompetitionId(subState.clubSelectedLeague, state.auth.squadId);
                source[subState.clubSelectedLeague] = squads;
            }

            dispatch(stateController.setState({ leagueSquadsCachedSource: source, clubsLoading: false }))
        }
    }

    public static setDatesAndCheckAvailability = (startDate: Date, endDate) => {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ startDate: startDate, endDate: endDate }))
            let state = getState();
            let landingPageState = state.landingPage.friendlyAdModal;
        }
    }

    public static friendlyAdDone = () => {
        return async (dispatch) => {

            dispatch(userActivityInsert({
                PageName: 'Home [Friendlies recommender]',
                Message: `Done`,
                PageType: PageType.Home
            }));

            dispatch(Actions.dispose());
            dispatch(Actions.closeModal());
        }
    }

    public static goToCreateAnotherFriendlyAd = () => {
        return async (dispatch) => {
            dispatch(userActivityInsert({
                PageName: 'Home [Launch friendly ads]',
                Message: `Create Another Friendly Ad`,
                PageType: PageType.Home
            }));

            dispatch(Actions.dispose());
            dispatch(Actions.init());
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    FriendlyAdModalState as State,
    Actions as Actions,
    stateController as Controller
};



