import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer'
import CustomSignInService from 'api/custom-sign-in/custom-sign-in.service';
import { PlayerProposalsGroupedByPlayerAdModel } from 'api/custom-sign-in/models/player-proposals.model';
import { cloneDeep } from 'lodash';
import { ShortlistService } from 'api/shortlist/shortlist-service';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { modalClose } from 'store/modals/modalsActions'
import { MODAL_OPEN } from 'store/actionTypes'
import { EMAIL_LANDING_PLAYER_PROPOSALS_DONE } from 'constants/modals'
import { ActionType, UserActivityType } from 'constants/enums';

class BusyPath {
    adId: number;
    playerId: number;
}

class PlayerProposalsState {
    isLoading: boolean;
    data: Array<PlayerProposalsGroupedByPlayerAdModel>;
    viewData: Array<PlayerProposalsGroupedByPlayerAdModel>;
    uncollapsedGroups: Array<number>;
    inProgressPlayers: Array<BusyPath>;
}

const defaultState: PlayerProposalsState = {
    isLoading: false,
    data: null,
    viewData: null,
    uncollapsedGroups: [],
    inProgressPlayers: []
}

const stateController = new StateController<PlayerProposalsState>(
    "LANDING/PLAYER_PROPOSALS",
    defaultState
);


class Actions {
    public static showMore(playerAdId: number) {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().landing.playerProposals;

            let originalCopy: PlayerProposalsGroupedByPlayerAdModel[] = cloneDeep(substate.data);
            let viewCopy: PlayerProposalsGroupedByPlayerAdModel[] = cloneDeep(substate.viewData);

            let originalGroup = originalCopy.find(i => i.playerAdId == playerAdId);
            let viewGroup = viewCopy.find(i => i.playerAdId == playerAdId);

            viewGroup.playerProposals = originalGroup.playerProposals;
            dispatch(stateController.setState({ viewData: viewCopy, uncollapsedGroups: [...substate.uncollapsedGroups, playerAdId] }));
            dispatch(userActivityInsert({
                Message: `Clicked Show more, ${viewGroup.positionCode}, ${viewGroup.isGroupForLoan ? 'Loan' : 'Sale'}`,
                PageName: 'Mail [Player Proposals]',
            }))
        }
    }

    public static showLess(playerAdId: number) {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().landing.playerProposals;

            let originalCopy = cloneDeep(substate.data);
            let viewCopy = cloneDeep(substate.viewData);

            let originalGroup = originalCopy.find(i => i.playerAdId == playerAdId);
            let viewGroup = viewCopy.find(i => i.playerAdId == playerAdId);

            viewGroup.playerProposals = originalGroup.playerProposals.slice(0, 3);
            let uncollapsed = substate.uncollapsedGroups.filter(i => i != playerAdId);
            dispatch(stateController.setState({ viewData: viewCopy, uncollapsedGroups: uncollapsed }));
        }
    }

    public static loadData() {
        return async (dispatch, getState: () => AppState) => {
            let appState = getState();
            dispatch(stateController.setState({ isLoading: true, }));
            var result = await CustomSignInService.getAdsRecommendationByUserId(appState.auth.userId);
            var resultCopy: PlayerProposalsGroupedByPlayerAdModel[] = cloneDeep(result);
            resultCopy.map(i => {
                i.playerProposals = i.playerProposals.slice(0, 3);
            })
            dispatch(stateController.setState({ isLoading: false, data: result, viewData: resultCopy }));
        }
    }

    public static notInterested(adId: number, playerId: number, squadId: number) {
        return async (dispatch, getState: () => AppState) => {
            let appState = getState();
            dispatch(stateController.setState({ inProgressPlayers: [...appState.landing.playerProposals.inProgressPlayers, { adId: adId, playerId: playerId }] }));
            await CustomSignInService.notInterested(playerId,adId);

            let newData: PlayerProposalsGroupedByPlayerAdModel[] = cloneDeep(appState.landing.playerProposals.data);
            let newViewData: PlayerProposalsGroupedByPlayerAdModel[] = cloneDeep(appState.landing.playerProposals.viewData);

            let group = newData.find(i => i.playerAdId == adId);
            if (group != null) {
                // let player = group.playerProposals.find(i => i.playerId == playerId);
                // if (player != null) {
                //     player.isNotInterested = true;
                // }
                group.playerProposals = group.playerProposals.filter(i => i.playerId != playerId);
            }
            let groupView = newViewData.find(i => i.playerAdId == adId);
            if (groupView != null) {
                // let player = groupView.playerProposals.find(i => i.playerId == playerId);
                // if (player != null) {
                //     player.isNotInterested = true;
                // }
                groupView.playerProposals = groupView.playerProposals.filter(i => i.playerId != playerId);
            }

            dispatch(stateController.setState({
                inProgressPlayers: appState.landing.playerProposals.inProgressPlayers.filter(i => i.adId != adId && i.playerId != playerId),
                data: newData,
                viewData: newViewData,
            }));
            dispatch(userActivityInsert({
                PageName: `Mail [Player Proposals]`,
                Message: `Not Interested, ${groupView.positionCode}, ${groupView.isGroupForLoan ? 'loan' : 'sale'}`,
                PlayerId: playerId,
                ClubId: squadId
            }));
        }
    }

    public static addToShortList(playerAdId: number, playerId: number, squadId: number) {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            await ShortlistService.addToShortlist(playerId);

            let newData: PlayerProposalsGroupedByPlayerAdModel[] = cloneDeep(state.landing.playerProposals.data);
            let newViewData: PlayerProposalsGroupedByPlayerAdModel[] = cloneDeep(state.landing.playerProposals.viewData);

            let group = newData.find(i => i.playerAdId == playerAdId);
            if (group != null) {
                let player = group.playerProposals.find(i => i.playerId == playerId);
                if (player != null) {
                    player.isInShortList = !player.isInShortList;
                }
            }
            let groupView = newViewData.find(i => i.playerAdId == playerAdId);
            if (groupView != null) {
                let player = groupView.playerProposals.find(i => i.playerId == playerId);
                if (player != null) {
                    player.isInShortList = !player.isInShortList;
                }
            }

            dispatch(stateController.setState({ data: newData, viewData: newViewData }));
            // dispatch(userActivityInsert({
            //     PageName: 'Player Profile [Scatter chart]',
            //     Message: `Added to Shortlist`,
            //     PlayerId: playerId,
            //     ClubId: squadId,
            //     PageType: PageType.PlayerProfile
            // }));
        }
    }

    public static declareInterest(playerAdId: number, playerId: number, squadId: number) {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();

            dispatch(stateController.setState({ inProgressPlayers: [...state.landing.playerProposals.inProgressPlayers, { adId: playerAdId, playerId: playerId }] }));
            await ShortlistService.declareInterest(playerId, state.auth.squadId, state.auth.userId);

            let newData: PlayerProposalsGroupedByPlayerAdModel[] = cloneDeep(getState().landing.playerProposals.data);
            let newViewData: PlayerProposalsGroupedByPlayerAdModel[] = cloneDeep(getState().landing.playerProposals.viewData);

            let group = newData.find(i => i.playerAdId == playerAdId);
            if (group != null) {
                let player = group.playerProposals.find(i => i.playerId == playerId);
                if (player != null) {
                    player.isInterestDeclared = true;
                }
            }
            let groupView = newViewData.find(i => i.playerAdId == playerAdId);
            if (groupView != null) {
                let player = groupView.playerProposals.find(i => i.playerId == playerId);
                if (player != null) {
                    player.isInterestDeclared = true;
                }
            }

            dispatch(stateController.setState({
                data: newData,
                viewData: newViewData,
                inProgressPlayers: state.landing.playerProposals.inProgressPlayers.filter(i => i.adId != playerAdId && i.playerId != playerId)
            }));
            dispatch(userActivityInsert({
                PageName: `Mail [Player Proposals]`,
                Message: `Declared Interest, ${groupView.positionCode}, ${groupView.isGroupForLoan ? 'loan' : 'sale'}`,
                PlayerId: playerId,
                ClubId: squadId,
                UserActivityType: UserActivityType.Action,
                ActionType: ActionType.DeclaredInterest
            }));
        }
    }

    public static doneAndRedirect(redirect) {
        return dispatch => {
            dispatch(modalClose(EMAIL_LANDING_PLAYER_PROPOSALS_DONE))
            redirect()
        }
    }

    public static openDoneModal(redirect) {
        return dispatch => {
            const command = {
                type: MODAL_OPEN,
                payload: {
                    id: EMAIL_LANDING_PLAYER_PROPOSALS_DONE,
                    customToggle: () => Actions.doneAndRedirect(redirect),
                    content: {
                        onDone: () => dispatch(Actions.doneAndRedirect(redirect)),
                        text: 'You have cleared all proposals',
                    }
                }
            };
            dispatch(command);
            dispatch(userActivityInsert({
                PageName: `Mail [Player Proposals]`,
                Message: `Updated all player proposals`,
            }));
        }
    }
}

class Selectors {
    public static selectIsAllItemsReacted(state: AppState): boolean {
        const groups = (state.landing.playerProposals.data || [])
        if (groups.length === 0) return false
    
        return groups.every(item => {
            const isEveryItemInGroupWasReacted = item.playerProposals.every(item => item.isInterestDeclared || item.isNotInterested)
            return isEveryItemInGroupWasReacted === true
        });
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    PlayerProposalsState as State,
    Actions as Actions,
    stateController as Controller,
    Selectors as Selectors
};



