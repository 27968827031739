import { StateController } from 'utils/action-declaration';

import { AppState } from 'root.reducer'
import VirtualSummitService from 'api/virtual-summit/virtual-summit.service'
import { CompetitionWithActiveSquadsModel } from 'api/virtual-summit/models/summit-invitationl'
import { Actions as StepActions } from 'pages/virtual-summit/redux/complete-details.controller';
import { Actions as ConfirmFlowStepActions, RegistrationState } from 'pages/virtual-summit/redux/confirm-page-steps/confirm.controller';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions'
import { PageType } from 'constants/enums';
import { union } from 'lodash';

class InviteClubsState {
    source: Array<CompetitionWithActiveSquadsModel>;
    originalSelected: Array<number>;
    isLoading: boolean;
    selected: Array<number>;
    disabled: Array<number>;
    isCompleting: boolean;
}

const defaultState: InviteClubsState = {
    isLoading: false,
    isCompleting: false,
    source: [],
    selected: [],
    disabled:[],
    originalSelected: []
}

const stateController = new StateController<InviteClubsState>(
    "VIRTUAL_SUMMIT/INVITE_CLUBS",
    defaultState
);

export const clubLimit = 100;

class Actions {
    public static clubsSelect(ids: Array<number>) {
        return async (dispatch, getState: () => AppState) => {
            let appState = getState();
            let currentSelected = appState.virtualSummit.inviteClubs.selected;

            let combined = [...currentSelected];

            for (let i = 0; i < ids.length; i++) {
                const id = ids[i];
                if (!combined.includes(id)) {
                    if (combined.length != clubLimit)
                        combined.push(id);
                }
                else combined.splice(combined.indexOf(id), 1);
            }

            // for (let id of ids) {
            //     if (!combined.includes(id) && combined.length != clubLimit) combined.push(id);
            //     else combined.splice(combined.indexOf(id), 1);
            // }

            dispatch(stateController.setState({ selected: combined }))
        }
    }

    public static continue(redirect: (referer: string) => void) {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let ids = state.virtualSummit.inviteClubs.selected;
            const { eventId  } = state.auth;

            dispatch(stateController.setState({ isCompleting: true }))

            await VirtualSummitService.setSummitClubs(state.auth.userId, ids);
            await VirtualSummitService.setGeneralNetworingIsCompleted(state.auth.userId);
            dispatch(StepActions.setNetworkingCompleted());
            
            dispatch(stateController.setState({ isCompleting: false }))
            dispatch(StepActions.goToNext(false, redirect));

            let source = state.virtualSummit.inviteClubs.source
            var allClubs = [];
            source.forEach(l => l.squads.forEach(s => allClubs.push(s)));

            let message = 'Invited Clubs: ';
            if(ids.length) {
                ids.forEach((e, i) => {
                    var squad = allClubs.find(x => x.id === e);
                    if(squad != null){
                        message += (i > 0 ? ', ' : '') + squad.englishName;    
                    }                        
                });
            } else {
                message = 'Skipped Clubs Invitation';
            }
            
            dispatch(userActivityInsert({ 
                Message: message, 
                PageName: 'Event [Details]',
                PageType: PageType.Events,
                EventId: eventId
            }))
        }
    }

    public static continueForConfirmFlow(redirect: (referer: string) => void, nextState: RegistrationState) {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let ids = state.virtualSummit.inviteClubs.selected;
            const { eventId  } = state.auth;

            dispatch(stateController.setState({ isCompleting: true }))

            await VirtualSummitService.setSummitClubs(state.auth.userId, ids);
            await VirtualSummitService.setGeneralNetworingIsCompleted(state.auth.userId);
            dispatch(StepActions.setNetworkingCompleted());

            dispatch(stateController.setState({ isCompleting: false }));
            dispatch(ConfirmFlowStepActions.setStep(nextState));

            let source = state.virtualSummit.inviteClubs.source
            var allClubs = [];
            source.forEach(l => l.squads.forEach(s => allClubs.push(s)));

            
            let message = 'Invited Clubs: ';
            if(ids.length) {
                ids.forEach((e, i) => {
                    var squad = allClubs.find(x => x.id === e);
                    if(squad != null){
                        message += (i > 0 ? ', ' : '') + squad.englishName;
                    }
                });
            } else {
                message = 'Skipped Clubs Invitation';
            }
            
            dispatch(userActivityInsert({ 
                Message: message, 
                PageName: 'Event [Registration]',
                PageType: PageType.Events,
                EventId: eventId
            }))
        }
    }

    public static skip(redirect: (referer: string) => void) {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            const { eventId  } = state.auth;

            dispatch(StepActions.goToNext(true, redirect)) 
            dispatch(userActivityInsert({ 
                Message: 'Skipped Clubs Invitation', 
                PageName: 'Event [Details]',
                PageType: PageType.Events,
                EventId: eventId
            }))
        }
    }

    public static skipForConfirmFlow(nextState: RegistrationState) {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            const { eventId  } = state.auth;

            dispatch(ConfirmFlowStepActions.setStep(nextState));
            dispatch(userActivityInsert({ 
                Message: 'Skipped Clubs Invitation', 
                PageName: 'Event [Registration]',
                PageType: PageType.Events,
                EventId: eventId
            }))
        }
    }

    public static loadData() {
        return async (dispatch, getState: () => AppState) => {
            let appState = getState();

            dispatch(stateController.setState({ isLoading: true }))

            var result = await VirtualSummitService.getSummitClubs(appState.auth.userId)

            dispatch(stateController.setState({ 
                isLoading: false, 
                disabled: result.disabledIds, 
                selected: union(result.selectedIds, result.recommendedSquadIds),
                originalSelected: result.selectedIds,
                source: result.competitions 
            }))
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    InviteClubsState as State,
    Actions as Actions,
    stateController as Controller
};



