import { createAction } from 'redux-actions';
import { PAGINATION_TURN_PAGE } from 'store/actionTypes';
import {
    getClubAdsFetchRequest,
} from 'pages/Pitch/store/reducers/club-ads.reducer';
import {
    clubAdsFetch,
} from 'pages/Pitch/store/actions/club-ads.actions';
import { Selectors as GridSelectors, PaginationClickState } from 'pages/PlayerSearch-v2/redux/grid.controller';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';

export const clubAdsTurnSetPage = createAction(
    PAGINATION_TURN_PAGE,
    (page, appPage) => ({
        page,
        appPage,
    }),
);

export const turnPage = scope => page => (dispatch, getState) => {
    switch (scope) {
        case 'clubAds': {
            const fetchRequest = getClubAdsFetchRequest(getState());
            const paginationState = GridSelectors.getRoot(getState()).paginationState;

            if (paginationState === PaginationClickState.Number) {
                dispatch(userActivityInsert({
                    PageName: 'Pitch [Pagination]',
                    Message: `Moved to Page: ${page}`,
                }))
            } else if (paginationState === PaginationClickState.Left) {
                dispatch(userActivityInsert({
                    PageName: 'Pitch [Pagination]',
                    Message: `Moved to Previous Page: ${page}`,
                }));
            } else if (paginationState === PaginationClickState.Right) {
                dispatch(userActivityInsert({
                    PageName: 'Pitch [Pagination]',
                    Message: `Moved to Next Page: ${page}`,
                }));
            }

            dispatch(clubAdsTurnSetPage(page, 'clubAds'));
            return dispatch(
                clubAdsFetch({ ...fetchRequest, currentPageNumber: page }),
            );
        }
        default: {
        }
    }
};
