import * as redux from 'redux';
import * as agencySubscription from 'pages/subscription-flow/redux/agency-subscription.controller';
import * as ltaCheckout from 'pages/lta-checkout/lta-checkout.controller';

export class AgencyAgentState {
    agencySubscription: agencySubscription.State;
    ltaCheckout: ltaCheckout.State;
}

export function combineReducers() {

    let agencySubscriptionReducer = {
        agencySubscription: agencySubscription.Reducer,
        ltaCheckout: ltaCheckout.Reducer
    };

    return redux.combineReducers(agencySubscriptionReducer)
} 