import React from 'react'
import styled from 'styled-components'

class Props {
    description: string;
}

const HprContent = () => {
    return <StyledDiv>
        Only a few spaces left for our next event. <br/>
        Register your interest to avoid disappointment.
    </StyledDiv>
}

const StyledDiv = styled.div`
    color: #D92D20;
    font-weight: 700;
`
export default HprContent