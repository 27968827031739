import { RefObject } from 'react'
import { AppState } from "root.reducer";
import history from "history-accessor";
import { StateController } from "utils/action-declaration";
import { AddUserModalService } from "api/admin/add-user-modal/add-user-modal.service";
import { RequestService } from "api/admin/user-requests/user-requests";
import { NewUserData } from "api/admin/add-user-modal/models";
import { isValid } from "utils/validation";

class AdminAddNewUserModal {
    isModalOpen: boolean;
    newUserData: NewUserData;
    allowedAreas: Array<any>;
    contacts: Array<any>;
    keyword: string;
    errorMessage: string;
    isSaving: boolean;
    loadingContacts: boolean;
    isFormValid: boolean;
}

const defaultNewUserData = {
    id: 0,
    squadName: "",
    firstName: "",
    lastName: "",
    clubId: 0,
    email: "",
    jobTitle: "",
    userPositionId: 16,
    userPositionName: "",
    phone: "",
    phoneCodeAreaId: 0,
    squadId: 0,
    areaId: 0,
    userTypeId: 0,
    contactId: 0,
    requestedByUserId: 0,
    registrationSource: "Add new user",
    status: 1,
};

const defaultState: AdminAddNewUserModal = {
    isModalOpen: false,
    newUserData: defaultNewUserData,
    allowedAreas: [],
    contacts: [],
    keyword: "",
    errorMessage: "",
    isSaving: false,
    loadingContacts: false,
    isFormValid: false,
};

const stateController = new StateController<AdminAddNewUserModal>(
    "ADMIN/ADD-NEW-USER-MODAL",
    defaultState
);

class Actions {
    public static openModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isModalOpen: true }));
        };
    }

    public static closeModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isModalOpen: false }));
            dispatch(Actions.resetForm());
        };
    }

    public static onUserDataChange(userData: { [K in keyof NewUserData]?: NewUserData[K] }) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                newUserData: {
                    ...prevState.newUserData,
                    ...userData,
                },
            })));

            dispatch(Actions.validateForm())
        };
    }

    public static onKeywordChange(keyword: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ keyword: keyword }));
        };
    }

    public static addUserProfile() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isSaving: true, errorMessage: null }));

                const userData = getState().admin.addNewUserModal.newUserData

                const data = await AddUserModalService.addNewUser(userData);

                if (data.output) {
                    dispatch(stateController.setState({ isModalOpen: false }));
                    dispatch(Actions.disposeComponent());

                    history.push("/admin");
                } else {
                    dispatch(stateController.setState({
                        errorMessage: data.humanReadableErrorMessage || "An error has occurred.",
                        isModalOpen: true,
                    }));
                }
            } finally {
                dispatch(stateController.setState({ isSaving: false }));
            }
        };
    }

    public static onContactSelect(contact) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                keyword: contact.companySquadId ? contact.companyName : "",
                newUserData: {
                    ...prevState.newUserData,
                    ...{
                        contactId: contact.id,
                        squadId: contact.companySquadId,
                        squadName: contact.companySquadId ? contact.companyName : "",
                        clubId: contact.companySquadId,
                        clubName: contact.companySquadId ? contact.companyName : "",
                        userPositionName: contact.jobTitle,
                        email: contact.email,
                        firstName: contact.firstName,
                        lastName: contact.lastName,
                      },
                },
            })));
        };
    }

    public static searchSimilarContacts() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const newUserData = getState().admin.addNewUserModal.newUserData;
                const { firstName, lastName, email, jobTitle } = newUserData;

                dispatch(stateController.setState({ loadingContacts: true }));

                const contacts = await RequestService.getSimilarContacts({
                    id: 0,
                    fullName: (firstName || "") + " " + (lastName || ""),
                    email,
                    userPositionId: 0,
                    phone: "",
                    userPositionName: jobTitle,
                    userTypeId: 0,
                });

                dispatch(stateController.setState({ contacts: contacts }));
            } finally {
                dispatch(
                    stateController.setState({
                        loadingContacts: false,
                    })
                );
            }
        };
    }

    public static validateForm() {
        return async (dispatch, getState: () => AppState) => {
            const newUserData = getState().admin.addNewUserModal.newUserData;
            const {
                squadName,
                firstName,
                lastName,
                email,
                userPositionName,
                phoneCodeAreaId,
                phone,
            } = newUserData;

            let squadNameValid = squadName ? true : false;
            let firstNameValid = isValid.name(firstName);
            let lastNameValid = isValid.name(lastName);
            let emailValid = isValid.email(email);
            let userPositionNameValid = isValid.name(userPositionName);
            let phoneValid = phone ? true : false;
            let phoneCodeAreaIdValid = phoneCodeAreaId ? true : false;
            let isFormValid =
                squadNameValid &&
                firstNameValid &&
                lastNameValid &&
                emailValid &&
                userPositionNameValid &&
                phoneValid &&
                phoneCodeAreaIdValid;

            dispatch(stateController.setState({ isFormValid: isFormValid }));
        };
    }

    public static onChangeKeyword() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isModalOpen: false }));
        };
    }

    public static loadAllowdAreas(userId: number) {
        return async (dispatch, getState: () => AppState) => {
            const data = await AddUserModalService.loadAllowedAreas(userId);

            dispatch(stateController.setState({ allowedAreas: data }));
        };
    }

    public static resetForm() {
        return (dispatch) => {
            dispatch(Actions.setDefaultViewState());
            dispatch(Actions.disposeComponent());
        };
    }

    public static setDefaultViewState() {
        return (dispatch) => {
            dispatch(stateController.setState({ newUserData: defaultNewUserData }));
        };
    }

    public static disposeComponent() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState));
        };
    }
}

class Selectors { }

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    AdminAddNewUserModal as State,
    Actions,
    Selectors,
    stateController as Controller,
};
