export const benefits = [
    'Connect directly with decision makers in the peak of the transfer window',
    'Real-time intelligence to find the best transfer opportunities',
    'Supercharge transfer talks'
]

export const extraTextForAgency = 'Accelerate your transfer success in the heat of the summer window by meeting one-to-one with decision makers at 15 clubs of your choice. Reasons why you and your agency should join us:'

export const agentBenefits = [
    'Reach decision makers directly in the peak of the window',
    'Accelerate transfer talks and get down to business fast',
    'Gain the best contacts in world football and grow your network'
]