import {
    PAGINATION_TURN_PAGE,
    PAGINATION_RESET,
    PLAYERS_FETCH,
    SHORTLIST_FETCH,
    PLAYERSEARCH_FILTER_CHANGED,
} from 'store/actionTypes';
import { DEFAULT_PAGE_SIZE } from 'constants/table';
import * as pagingStore from 'ts-components/pagination/helpers/paging-store';

const initialPaginationState = {
    currentPage: 1,
    rowCount: 0,
    pageSize: pagingStore.getPageSize('playerSearch', DEFAULT_PAGE_SIZE),
    pageCount: 0,
};

const initialState = {
    playerSearch: initialPaginationState,
    shortList: initialPaginationState,
};

export default (pagination = initialState, action) => {
    switch (action.type) {
        case PAGINATION_TURN_PAGE: {
            const { appPage, page, pageSize = pagination.pageSize } = action.payload;
            return {
                ...pagination,
                [appPage]: { ...pagination[appPage], currentPage: page, pageSize },
            };
        }

        case PAGINATION_RESET: {
            const { appPage } = action.payload;
            let r = {
                ...pagination,
                [appPage]: {
                    ...pagination[appPage],
                    currentPage: 1,
                },
            };
            return r;
        }

        case PLAYERSEARCH_FILTER_CHANGED: {
            return {
                ...pagination,
                playerSearch: {
                    ...pagination.playerSearch,
                    currentPage: 1,
                },
                shortList: {
                    ...pagination.shortList,
                    currentPage: 1,
                },
            };
        }

        case SHORTLIST_FETCH.SUCCESS:
        case PLAYERS_FETCH.SUCCESS: {
            // cancelled request
            if(!action.payload.data){
                return {
                    ...pagination,                    
                };
            }

            const { scope } = action.payload.request;
            const {
                rowCount,
                pageCount,
                currentPageNumber,
            } = action.payload.data;

            return {
                ...pagination,
                [scope]: {
                    ...pagination[scope],
                    ...{
                        rowCount,
                        pageCount,
                        currentPage: currentPageNumber,
                    },
                },
            };
        }

        default:
            return pagination;
    }
};

export const getPagination = scope => state => state.pagination[scope];

export const getCurrentPage = scope => state =>
    state.pagination[scope].currentPage;

export const getRowCount = scope => state => state.pagination[scope].rowCount;
export const getPageSize = scope => state => state.pagination[scope].pageSize;
export const getPageCount = scope => state => state.pagination[scope].pageCount;
