import { createAction } from 'redux-actions';

export const SET_FILTER_VISIBILITY__ACTION =
    'RESPONSIVE_SUPPORT_SET_IS_FILTER_VISIBILITY';
export const SET_FILTER_VISIBILITY__CREATOR = createAction(
    SET_FILTER_VISIBILITY__ACTION,
    visibility => visibility,
);

const initialState = {
    advancedFitlerVisibility: false,
};

export default (responsiveState = initialState, action) => {
    switch (action.type) {
        case SET_FILTER_VISIBILITY__ACTION: {
            return {
                ...responsiveState,
                advancedFitlerVisibility: action.payload,
            };
        }
        default:
            return responsiveState;
    }
};
