import axios from 'axios-config';

export class AgencyLandingService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async activateAgencyFreeTrial() {
        const { data } = await axios.post(
            `api/v2/AgencyFeature/ActivateAgentFeatureTrial`,
            {},
            this.axiosConfig
        );
        return data;
    }

    public static async deactivateAgencyFreeTrial() {
        const { data } = await axios.post(
            `api/v2/AgencyFeature/DeactivateAgentFeatureTrial`,
            {},
            this.axiosConfig
        );
        return data;
    }

    public static async getIsAgencyFreeTrialActive(): Promise<boolean> {
        const { data } = await axios.get(
            `api/v2/AgencyFeature/GetAgentFeatureTrial`,
            this.axiosConfig
        );
        return data;
    }

}