import axios from 'axios-config';
import {
    CompetitionListModel,
    CreateCompetitionListRequest,
    UpdateCompetitionListRequest,
} from './models';
import { CustomLeagueList } from 'api/search/model/search-filter-criteria';

export default class AgencyCompetitionListService {
    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async getAll(): Promise<Array<CompetitionListModel>> {
        const { data } = await axios.get(
            `api/v2/AgencyCompetitionList/GetAll`,
            { ...this.axiosConfig }
        );
        return data;
    }

    public static async create(request: CreateCompetitionListRequest): Promise<CustomLeagueList> {
        const { data } = await axios.post(
            `api/v2/AgencyCompetitionList/Create`,
            request,
            { ...this.axiosConfig }
        );
        return data;
    }

    public static async update(request: UpdateCompetitionListRequest): Promise<CustomLeagueList> {
        const { data } = await axios.post(
            `api/v2/AgencyCompetitionList/Update`,
            request,
            { ...this.axiosConfig }
        );
        return data;
    }

    public static async delete(id: number): Promise<any> {
        const { data } = await axios.post(
            `api/v2/AgencyCompetitionList/Delete`,
            id,
            { ...this.axiosConfig }
        );
        return data;
    }
}