import axios from 'axios-config';
import { AgencyPublicProfileResponse } from './models';

export default class AgencyPublicProfileService {
    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async getAgencyPublicProfile(agencyName: number | string): Promise<AgencyPublicProfileResponse> {
        const { data } = await axios.get(
            `api/AgencyPublicProfile/GetProfile/${encodeURIComponent(agencyName)}`,
            this.axiosConfig
        );
        return data;
    }
}