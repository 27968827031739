import { AppState } from 'root.reducer'
import { StateController } from 'utils/action-declaration'
import { AgecnyAccessService } from 'api/agency/club/agency-access/agency-access.service'
import { ShortlistService } from 'api/shortlist/shortlist-service';
import { PlayerService } from 'api/player/player-service';


export interface LetUsKnowModalState {
  isModalOpen: boolean
}
export interface GetInTouchModalState {
  isModalOpen: boolean
}
export interface ShareProfileModalState {
  isModalOpen: boolean
}
export interface ClaimThisProfileModalState {
  isModalOpen: boolean
}
export interface RepresenationGridState {
  isLoading: boolean;
  shortlistedPlayers: Array<number>;
  players: Array<any>;
  indicators: {
    strength: number;
    verifiedPlayersAmount: number;
    playersAmount: number;
  };
  declareInterestProcessingIds: Array<number>;
}


class AgencyProfileState {
  isAgencyTrusted: boolean
  isPitchAccessGranted: boolean
  isPitchAccessProcessing: boolean

  letUsKnowModal: LetUsKnowModalState
  getInTouchModal: GetInTouchModalState
  shareProfileModal: ShareProfileModalState
  claimThisProfileModal: ClaimThisProfileModalState

  represenationGrid: RepresenationGridState
}

const defaultState: AgencyProfileState = {
  isAgencyTrusted: true,
  isPitchAccessGranted: true,
  isPitchAccessProcessing: false,

  letUsKnowModal: {
    isModalOpen: false,
  },
  getInTouchModal: {
    isModalOpen: false,
  },
  shareProfileModal: {
    isModalOpen: false,
  },
  claimThisProfileModal: {
    isModalOpen: false,
  },

  represenationGrid: {
    isLoading: false,
    shortlistedPlayers: [],
    players: [],
    indicators: {
      strength: 0,
      verifiedPlayersAmount: 0,
      playersAmount: 0,
    },
    declareInterestProcessingIds: []
  }
}

const stateController = new StateController<AgencyProfileState>(
  'AGENCY/PUBLIC_PROFILE/AGENCY_PROFILE',
  defaultState
)

class Actions {

  //====== Agency Profile Page =========================================================================================

  public static setAgencyTrusted() {
    return (dispatch, getState: () => AppState) => {
      const isAgencyTrusted = getState().agency.publicProfile.agencyPublicProfile.isAgencyTrusted
      dispatch(stateController.setState({ isAgencyTrusted: !isAgencyTrusted }))
    }
  }

  private static setPitchAccess(value: boolean) {    // temporary action
    return (dispatch, getState: () => AppState) => {
      dispatch(stateController.setState({ isPitchAccessGranted: value }))
    }
  }

  public static givePitchAccess(externalAgencyId?: number) {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState({ isPitchAccessProcessing: true }))
        dispatch(Actions.setPitchAccess(true))
        //await AgecnyAccessService.giveAccess(externalAgencyId)
      } finally {
        dispatch(stateController.setState({ isPitchAccessProcessing: false }))
      }
    }
  }

  public static revokePitchAccess(externalAgencyId?: number) {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState({ isPitchAccessProcessing: true }))
        dispatch(Actions.setPitchAccess(false))
        //await AgecnyAccessService.revokeAccess(externalAgencyId)
      } finally {
        dispatch(stateController.setState({ isPitchAccessProcessing: false }))
      }
    }
  }

  //====== Let us know Modal ===========================================================================================

  // bla bla bla

  //====== Get in touch Modal ==========================================================================================

  // bla bla bla

  //====== Share profile Modal =========================================================================================

  // bla bla bla

  //====== Claim this profile Modal ====================================================================================

  // bla bla bla

  //====== Represenation ===============================================================================================
  public static loadShortlistedPlayers() {
    return async dispatch => {
      const shortlistedPlayers = await ShortlistService.loadShortListedPlayers()
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        represenationGrid: {
            ...prevState.represenationGrid,
            shortlistedPlayers: shortlistedPlayers,
        }
    })))
    }
  }

  public static shortlistToggle(playerId: number, isInShortlist: boolean) {
    return async (dispatch, getState: () => AppState) => {
      // const isPreview = getState().agencyProfile.common.isPreview;
      // if (isPreview) {
      //   return
      // }
      try {

        // const player = getState().agencyProfile.playerPortfolio.players.find(item => item.id === playerId)

        if (!isInShortlist) {
          dispatch(stateController.setState(prevState => ({ ...prevState, represenationGrid: [...prevState.represenationGrid.shortlistedPlayers, playerId] })))
          await ShortlistService.addToShortlist(playerId);
        } else {
          dispatch(stateController.setState(prevState => ({ ...prevState, represenationGrid: prevState.represenationGrid.shortlistedPlayers.filter(item => item !== playerId) })))
          await ShortlistService.removeFromShortlist(playerId);
        }

        // dispatch(userActivityInsert({
        //   Message: !isInShortlist ? 'Added to Shortlist' : 'Removed from Shortlist',
        //   PageName: 'Club Agents [Agency Profile]',
        //   PlayerId: playerId,
        //   ClubId: (player.parentSquad && player.parentSquad.id) || (player.currentSquad && player.currentSquad.id) || null,
        //   CurrentClubId: (player.currentSquad && player.currentSquad.id) || null,
        //   ActionType: !isInShortlist ? ActionType.AddToShortlist : null,
        //   PageType: PageType.Agents
        // }))
      } catch (e) {
        console.error(e)

        // Discard change if request not succeeded
        if (!isInShortlist) {
          dispatch(stateController.setState(prevState => ({ ...prevState, represenationGrid: prevState.represenationGrid.shortlistedPlayers.filter(item => item !== playerId) })))
        } else {
          dispatch(stateController.setState(prevState => ({ ...prevState, represenationGrid: [...prevState.represenationGrid.shortlistedPlayers, playerId] })))
        }
      }
    }
  }


  // public static showMorePlayers() {
  //   return async (dispatch, getState: () => AppState) => {
  //     const agent = getState().agencyProfile.agencyInfo
  //     const isPreview = getState().agencyProfile.common.isPreview

  //     if (!isPreview) {
  //       dispatch(userActivityInsert({
  //         PageName: 'Club Agents [Agency profile]',
  //         Message: `View more players`,
  //         AgencyId: agent.agencyId,
  //         PageType: PageType.Agents
  //       }))
  //     }
  //   }
  // }

  // public static openPlayerProfile(playerId: number) {
  //   return async (dispatch, getState: () => AppState) => {
  //     const substate = getState().agency.publicProfile.agencyPublicProfile
  //     const player = substate.players.find(({ id }) => playerId === id);

  //     window.open(`profile/${playerId}`, '_blank');

  //   }
  // }

  // public static openClubsPlayers(playerId: number) {
  //   return async (dispatch, getState: () => AppState) => {
  //     const substate = getState().agencyProfile.playerPortfolio
  //     const player = substate.players.find(({ id }) => playerId === id);

  //     window.open(`/playersearch?q=${player.parentSquad.name}`, '_blank')

  //     dispatch(userActivityInsert({
  //       PageName: 'Club Agents [Agency profile]',
  //       Message: `Opened Clubs Players`,
  //       PlayerId: playerId,
  //       ClubId: player.parentSquad.id,
  //       PageType: PageType.Agents
  //     }))
  //   }
  // }

  public static declareInterest(playerId: number, agencyId: number) {
    return async (dispatch, getState: () => AppState) => {
      // const isPreview = getState().agencyProfile.common.isPreview;
      // if (isPreview) {
      //   return
      // }
      try {
        const substate = getState().agency.publicProfile.agencyPublicProfile;
        // const agent = getState().agencyProfile.agencyInfo

        const isPlayerInShortlist = substate.represenationGrid.shortlistedPlayers.includes(playerId)
        if (!isPlayerInShortlist) {
          dispatch(Actions.shortlistToggle(playerId, isPlayerInShortlist))
        }

        dispatch(stateController.setState(prevState => ({ ...prevState, represenationGrid: [...substate.represenationGrid.declareInterestProcessingIds, playerId] })))
        await PlayerService.declareInterestToAgent(playerId)

        // const player = substate.players.find(({ id }) => playerId == id);

        // if (player != null) {
        //   dispatch(userActivityInsert({
        //     PageName: 'Club Agents [Agency profile]',
        //     Message: `Declared Interest`,
        //     PlayerId: playerId,
        //     // ClubId: player.parentSquad.id,
        //     AgencyId: agent.agencyId,
        //     ActionType: ActionType.DeclaredInterest,
        //     PageType: PageType.Agents,
        //     UserActivityType: UserActivityType.Action
        //   }));
        // }

        // let players = substate.players
        // players.find(item => item.id === playerId).isInterestWasDeclared = true

        // dispatch(stateController.setState({
        //   players: [...players]
        // }))

      } catch (e) {
        console.error(e)
      } finally {
        dispatch(stateController.setState(prevState => ({ ...prevState, represenationGrid: prevState.represenationGrid.declareInterestProcessingIds.filter(item => item !== playerId) })))
      }
    }
  }
}

class Selectors { }

const reducer = stateController.getReducer()

export {
  reducer as Reducer,
  AgencyProfileState as State,
  Actions as Actions,
  Selectors as Selectors,
  stateController as Controller,
}