import styled, { css } from 'styled-components'

export default styled.div`
margin: 0 auto;
/* margin-bottom: 60px; */
padding-left: 5px;
/* margin-top: 74px; */

${props => !props.hideBottomLine && css`
    border-bottom: solid 1px #d4d4d4;
    padding-bottom: 50px;
`}

h2 {
    font-size: 40px;
    font-weight: bold;
}

.extra-text {
    font-size: 18px;
    margin-bottom: 20px;
}

.list-container {
    padding: 0;
    margin-top: 15px;

    @media (max-width: 768px) {
        padding: 0 15px;
    }

    ul {
        font-size: 18px;
        padding: 0;

        .mobile-li {
            margin-bottom: 7px;
            font-weight: 500;
        }
        li {
            display: flex;
        }
        span {
            color: #0E854C;
            font-size: 21px;
            margin-right: 15px;
            margin-top: 4px;
        }
    }

    @media (min-width: 768px) {
        padding-left: 15px;
    }
}
`