import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer'
import CustomSignInService from 'api/custom-sign-in/custom-sign-in.service'
import { PlayerAdModel, PlayerAdTypeEnum } from 'api/custom-sign-in/models/otdated-ads.model';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';

import { modalClose } from 'store/modals/modalsActions'
import { MODAL_OPEN } from 'store/actionTypes'
import { EMAIL_LANDING_OUTDATEDADS_DONE } from 'constants/modals'

class OutdatedAdsState {
    isLoading: boolean;
    data: Array<PlayerAdModel>;
    updatedAds: Array<number>;
    removedAds: Array<number>;
    adIdsInprogress: Array<number>;
}

const defaultState: OutdatedAdsState = {
    isLoading: false,
    data: null,
    updatedAds: [],
    removedAds: [],
    adIdsInprogress: []
}

const stateController = new StateController<OutdatedAdsState>(
    "LANDING/OUTDATED_ADS",
    defaultState
);


class Actions {
    public static removeAd(adId: number) {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().landing.outdatedAds;
            dispatch(stateController.setState({ adIdsInprogress: [...substate.adIdsInprogress, adId] }));
            await CustomSignInService.removePlayerAd(getState().auth.userId, adId);
            dispatch(stateController.setState({
                removedAds: [...substate.removedAds, adId],
                adIdsInprogress: substate.adIdsInprogress.filter(i => i != adId),
            }));
            let ad = substate.data.find(i => i.id == adId);
            if (ad) {
                dispatch(userActivityInsert({
                    Message: `Removed Ad - ${ad.positionCode}, ${ad.type == PlayerAdTypeEnum.Buy ? 'Sale' : 'Loan'}`,
                    PageName: 'Mail [Outdated Player Ads]',
                }))
            }
        }
    }

    public static updateAd(adId: number) {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().landing.outdatedAds;
            dispatch(stateController.setState({ adIdsInprogress: [...substate.adIdsInprogress, adId] }));
            await CustomSignInService.updatePlayerAd(getState().auth.userId, adId);
            dispatch(stateController.setState({
                updatedAds: [...substate.updatedAds, adId],
                adIdsInprogress: substate.adIdsInprogress.filter(i => i != adId),
            }));
            let ad = substate.data.find(i => i.id == adId);
            if (ad) {
                dispatch(userActivityInsert({
                    Message: `Updated Ad - ${ad.positionCode}, ${ad.type == PlayerAdTypeEnum.Buy ? 'Sale' : 'Loan'}`,
                    PageName: 'Mail [Outdated Player Ads]',
                }))
            }
        }
    }

    public static doneAndRedirect(redirect) {
        return dispatch => {
            dispatch(modalClose(EMAIL_LANDING_OUTDATEDADS_DONE))
            redirect()
        }
    }

    public static openModalIfAllItemsDone(redirect) {
        return dispatch => {
            const command = {
                type: MODAL_OPEN,
                payload: {
                    id: EMAIL_LANDING_OUTDATEDADS_DONE,
                    customToggle: () => Actions.doneAndRedirect(redirect),
                    content: {
                        onDone: () => dispatch(Actions.doneAndRedirect(redirect)),
                        text: 'You have updated all ads'
                    }
                }
            };
            dispatch(command);
            dispatch(userActivityInsert({
                Message: `Updated All Ads`,
                PageName: 'Mail [Outdated Player Ads]',
            }))
        }
    }

    public static loadData(updateAdId, removeAdId) {
        return async (dispatch, getState: () => AppState) => {
            let appState = getState();
            let substate = getState().landing.outdatedAds;

            dispatch(stateController.setState({ isLoading: true, }))
            var result = await CustomSignInService.getOutdatedAdsWithAction(appState.auth.userId, updateAdId, removeAdId);
            dispatch(stateController.setState({ isLoading: false, data: result }));
            if (updateAdId != null) {
                dispatch(stateController.setState({ updatedAds: [...substate.updatedAds, updateAdId] }));
            }
            if (removeAdId != null) {
                dispatch(stateController.setState({ removedAds: [...substate.removedAds, removeAdId] }));
            }
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    OutdatedAdsState as State,
    Actions as Actions,
    stateController as Controller
};



