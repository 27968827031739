import { AppState } from 'root.reducer'
import validator from 'services/validator'
import { StateController } from 'utils/action-declaration'
import { copyToClipboard as copy } from 'utils/copy-to-clipboard'
import { notificationCreate } from 'app/notifications/notifications.actions'
import { getAreaPhoneCodes } from 'app/phone-codes/actions/phone-codes.actions'
import AgencyPlayerService from 'api/agency/player/shared/agency-player-service'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions'
import AgencyHomePageService from 'api/agency/agent/landing/agency-homepage.service'
import ReonboardingServise from 'api/agency/agent/reonboarding/reonboarding.service'
import { PendingVerificationPlayer } from 'api/agency/agent/reonboarding/reonboarding-models'
import { Actions as SharedModalActions, ModalState } from 'components/modals/shared-modal-controller'
import { Actions as HomePagePlayersActions } from 'pages/agency/authorized-agent/home-page/redux/home-page-players.controller';
import { isTransparencyPermitted } from 'store/auth/authReducer'

export interface ShareLinkModalState {
    email: string
    emailValid: boolean
    phoneNumber: string
    playerFullName: string
    verificationLink: string
    playerId: number
    phoneCodeAreaId: number
    isPriority: boolean
    isModalOpen: boolean
    isLinkGenerating: boolean
    isSendEmailDisabled: boolean
    isSendPhoneDisabled: boolean
    isSendEmailProcessing: boolean
    isSendPhoneProcessing: boolean
    isEmailSent: boolean
    isPhoneSent: boolean
    verificationMessage: string
}
export interface UpgradePlayerModalState {
    playerFullName: string
    playerId: number
    processing: boolean
    isModalOpen: boolean
    shareToggle: boolean
}
export interface RestrictedPlayerCreditsModalState {
    isRegular: boolean
    isModalOpen: boolean
    requestSent: boolean

}

class State {
    players: PendingVerificationPlayer[]
    shareLinkModal: ShareLinkModalState
    upgradePlayerModal: UpgradePlayerModalState
    restrictedPlayerCreditsModal: RestrictedPlayerCreditsModalState
}

const defaultState: State = {
    players: [],

    shareLinkModal: {
        email: '',
        emailValid: true,
        phoneNumber: '',
        playerFullName: '',
        verificationLink: '',
        playerId: null,
        phoneCodeAreaId: null,
        isPriority: false,
        isModalOpen: false,
        isLinkGenerating: false,
        isSendEmailDisabled: false,
        isSendPhoneDisabled: false,
        isSendEmailProcessing: false,
        isSendPhoneProcessing: false,
        isEmailSent: false,
        isPhoneSent: false,
        verificationMessage: null
    },
    upgradePlayerModal: {
        playerFullName: '',
        playerId: null,
        processing: false,
        isModalOpen: false,
        shareToggle: false,
    },
    restrictedPlayerCreditsModal: {
        isRegular: false,
        isModalOpen: false,
        requestSent: false,
    }
}

const stateController = new StateController<State>(
    'AGENCY/AGENCY-TRANSPARENCY',
    defaultState
)


class Actions {

    //====== Pending verification block ====================================================================================================

    public static getPendingVerificationPlayers() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const data = await ReonboardingServise.getPendingVerificationPlayers()
                dispatch(stateController.setState({ 
                    players: data.players
                }))

                if(data.players.length){
                    dispatch(getAreaPhoneCodes());
                }
            } catch (err) {
                console.error(err)
            }
        }
    }

    //====== Share Link Modal ==============================================================================================================

    public static openShareLinkModal(playerItem: PendingVerificationPlayer) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                shareLinkModal: {
                    ...prevState.shareLinkModal,
                    playerId: playerItem.playerId,
                    phoneCodeAreaId: playerItem.phoneCodeAreaId,
                    phoneNumber: playerItem.playerPhone,
                    email: playerItem.playerEmail || '',
                    playerFullName: playerItem.playerName,
                    isSendEmailDisabled: !!playerItem.playerEmail,
                    isSendPhoneDisabled: !!playerItem.playerPhone,
                    isModalOpen: true,
                    isPriority: playerItem.isPriority,
                    verificationMessage: playerItem.verificationMessage
                }
            })))
            dispatch(userActivityInsert({
                PageName: 'Agency Players [Pending Verification]',
                Message: `Share Verification Link`,
                PlayerId: playerItem.playerId,
            }))
        }
    }
    public static closeShareLinkModal() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                shareLinkModal: {
                    ...defaultState.shareLinkModal
                }
            })))
        }
    }
    public static sendWhatsapp() {
        return async (dispatch, getState: () => AppState) => {
            const { playerId, isPriority } = Selectors.getShareLinkModal(getState());
            // var result = await ReonboardingServise.getVerificationMessages([playerId]);
            // window.open(`https://wa.me/?text=${result[0].message}`);
            const link = await ReonboardingServise.generateLink({ playerId: playerId, isRegular: !isPriority });
            dispatch(userActivityInsert({
                PageName: 'Agency Players [Verification Link]',
                Message: `Sent by WhatsApp`,
                PlayerId: playerId,
            }))
        }
    }
    public static sendEmail() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    shareLinkModal: {
                        ...prevState.shareLinkModal,
                        isSendEmailProcessing: true,
                    }
                })))
                const { email, playerId, isPriority } = Selectors.getShareLinkModal(getState());

                await ReonboardingServise.sendVerificationEmail({ email: email, playerId: playerId, isRegular: !isPriority })

                dispatch(userActivityInsert({
                    PageName: 'Agency Players [Verification Link]',
                    Message: `Sent by Email`,
                    PlayerId: playerId,
                }));

                let playersCopy = [...Selectors.getRoot(getState()).players];
                let find = playersCopy.find(x => x.playerId == playerId);
                if (find) {
                    find.playerEmail = email;
                    dispatch(stateController.setState(prev => ({ ...prev, players: playersCopy })))
                }
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    shareLinkModal: {
                        ...prevState.shareLinkModal,
                        isSendEmailProcessing: false,
                        isEmailSent: true
                    }
                })))
            }
        }
    }
    public static sendPhone() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    shareLinkModal: {
                        ...prevState.shareLinkModal,
                        isSendPhoneProcessing: true,
                    }
                })))
                const { phoneCodeAreaId, playerId, phoneNumber, isPriority } = Selectors.getShareLinkModal(getState());

                await ReonboardingServise.sendVerificationSms({
                    isInsightsShared: false,
                    isRegular: !isPriority,
                    phone: phoneNumber,
                    phoneCodeAreaId: phoneCodeAreaId,
                    playerId: playerId,
                })

                dispatch(userActivityInsert({
                    PageName: 'Agency Players [Verification Link]',
                    Message: `Sent by Phone Number`,
                    PlayerId: playerId,
                }));

                let playersCopy = [...Selectors.getRoot(getState()).players];
                let find = playersCopy.find(x => x.playerId == playerId);
                if (find) {
                    find.phoneCodeAreaId = phoneCodeAreaId;
                    find.playerPhone = phoneNumber;
                    dispatch(stateController.setState(prev => ({ ...prev, players: playersCopy })));
                }
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    shareLinkModal: {
                        ...prevState.shareLinkModal,
                        isSendPhoneProcessing: false,
                        isPhoneSent: true
                    }
                })))
            }
        }
    }
    public static generateLink() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const { playerId, isPriority, verificationLink } = Selectors.getShareLinkModal(getState())
                let link = verificationLink
                if (link === '') {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        shareLinkModal: {
                            ...prevState.shareLinkModal,
                            isLinkGenerating: true,
                        }
                    })))

                    const link = await ReonboardingServise.generateLink({ playerId: playerId, isRegular: !isPriority })

                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        shareLinkModal: {
                            ...prevState.shareLinkModal,
                            verificationLink: link.verificationLink,
                        }
                    })))
                    dispatch(userActivityInsert({
                        PageName: 'Agency Players [Verification Link]',
                        Message: `Generated a Link`,
                        PlayerId: playerId,
                    }))
                }
                link = Selectors.getShareLinkModal(getState()).verificationLink
                if (link !== '') {
                    await copy(link);
                    dispatch(notificationCreate({ message: 'Copied to clipboard', level: 'info' }));
                    dispatch(userActivityInsert({
                        PageName: 'Agency Players [Verification Link]',
                        Message: `Copied a Link`,
                        PlayerId: playerId,
                    }))
                }
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    shareLinkModal: {
                        ...prevState.shareLinkModal,
                        isLinkGenerating: false,
                    }
                })))
            }
        }
    }
    public static onChangeEmail(value: string) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                shareLinkModal: {
                    ...prevState.shareLinkModal,
                    email: value,
                    emailValid: validator.isValidEmail(value)
                }
            })))
        }
    }
    public static onChangePhoneCode(areaId: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                shareLinkModal: {
                    ...prevState.shareLinkModal,
                    phoneCodeAreaId: areaId,
                }
            })))
        }
    }
    public static onChangePhoneNumber(value: string) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                shareLinkModal: {
                    ...prevState.shareLinkModal,
                    phoneNumber: value,
                }
            })))
        }
    }

    //====== Upgrade Player Modal ===========================================================================================================

    public static openUpgradePlayerModal(playerItem: PendingVerificationPlayer) {
        return async (dispatch, getState: () => AppState) => {
            const { priorityCreditsRemaining } = getState().agency.landingPage.homePagePlayers;
            if (priorityCreditsRemaining > 0) {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    upgradePlayerModal: {
                        ...prevState.upgradePlayerModal,
                        isModalOpen: true,
                        shareToggle: true,
                        playerId: playerItem.playerId,
                        playerFullName: playerItem.playerName,
                    }
                })))
                dispatch(userActivityInsert({
                    PageName: 'Agency Players [Pending Verification]',
                    Message: `Upgrade Player`,
                    PlayerId: playerItem.playerId,
                }))
            } else {
                dispatch(Actions.openRestrictedPlayerCreditsModal())
            }
        }
    }
    public static closeUpgradePlayerModal() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                upgradePlayerModal: {
                    ...defaultState.upgradePlayerModal
                }
            })))
        }
    }
    public static onToggleShare() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                upgradePlayerModal: {
                    ...prevState.upgradePlayerModal,
                    shareToggle: !prevState.upgradePlayerModal.shareToggle,
                }
            })))
        }
    }
    public static openSharedInsightsInfoModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(SharedModalActions.onOpen(ModalState.UpgradePlayerToPriority))
        }
    }
    public static upgradePlayer(id: number, shared: boolean) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const subState = Selectors.getRoot(getState());
                const transparencyPermitted = isTransparencyPermitted(getState());

                if (!transparencyPermitted) shared = false

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    upgradePlayerModal: {
                        ...prevState.upgradePlayerModal,
                        processing: true,
                    }
                })));

                await AgencyHomePageService.upgradePlayer(id, shared);

                const playersCopy = [...subState.players];
                const upgradedPlayer = playersCopy.find(i => i.playerId == id);
                upgradedPlayer.isPriority = true;

                dispatch(stateController.setState({ players: playersCopy }));

                const uaMessage = transparencyPermitted
                    ? `Upgraded Player to Priority; ${shared ? 'Shared Insights with Player' : 'Unshared Insights with Player'}`
                    : `Upgraded Player to Priority`
                dispatch(userActivityInsert({
                    PageName: 'Agency Players [Upgrade]',
                    Message: uaMessage,
                    PlayerId: upgradedPlayer.playerId,
                    ClubId: upgradedPlayer.currentSquad?.id,
                }))

                dispatch(HomePagePlayersActions.decreaseRemainingPriorityCredits())
                dispatch(Actions.closeUpgradePlayerModal())
            } catch (err) {
                console.error(err)
            }
        }
    }

    //====== Restricted Player Credits Modal ================================================================================================

    public static openRestrictedPlayerCreditsModal() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                restrictedPlayerCreditsModal: {
                    ...prevState.restrictedPlayerCreditsModal,
                    isModalOpen: true,
                    isRegular: false,
                    requestSent: false,
                }
            })))
        }
    }
    public static closeRestrictedPlayerCreditsModal() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                restrictedPlayerCreditsModal: {
                    ...defaultState.restrictedPlayerCreditsModal
                }
            })))
        }
    }
    public static requestMoreCredits() {
        return async (dispatch, getState: () => AppState) => {
            const modalData = Selectors.getRoot(getState()).restrictedPlayerCreditsModal
            await AgencyPlayerService.requestPlayerCreditsEmail({
                isRegular: modalData.isRegular,
                isPriority: !modalData.isRegular
            })
            dispatch(stateController.setState({
                restrictedPlayerCreditsModal: {
                    ...modalData,
                    requestSent: true
                }
            }))
        }
    }

}


class Selectors {
    public static getRoot = (state: AppState): State => state.agency.landingPage.pendingVerification;
    public static getShareLinkModal = (state: AppState) => Selectors.getRoot(state).shareLinkModal;
 }

const reducer = stateController.getReducer()

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    stateController as Controller,
    Selectors as Selectors,
}