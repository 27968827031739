
/* eslint-disable */
export const isValid = {
    name: value => value.trim().length > 0,
    password: value => value.trim().length > 0,
    areaId: value => typeof value === "number" && value !== null && value > 0,
    numbers: value => !isNaN(parseInt(value.replace(" ", ""))),
    phone: value => {
        const val = value.trim();
        return val.match(
            /(^(\+?\-?\s*[0-9()\s]+)([0-9\s-]*)([0-9]\s])*$)|(^\s*$)/,
        )
    },
    email: value => {
        const val = value.trim();
        return val.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,);
    },
};
