import axios from 'axios-config';
import { UserRequestModel, SquadModel, ContactModel, ContactRequest } from './models'
import { Result } from 'api/core/result'

export class RequestService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async getUsersWaitingConfirmation(): Promise<Array<UserRequestModel>> {
        const { data } = await axios.get<Array<UserRequestModel>>(
            `api/Users/GetUsersWaitingConfirmation`,
            this.axiosConfig,
        );
        return data;
    }

    public static async activateUserFromRequest(userRequestId: number): Promise<Result<boolean>> {
        const { data } = await axios.post(
            `api/Users/ActivateUserFromRequest/${userRequestId}`,
            null,
            this.axiosConfig,
        );
        return data;
    }

    public static async deleteUser(userRequestId: number): Promise<boolean> {
        const { data } = await axios.delete(
            `api/Users/UserRequest/${userRequestId}`,
            this.axiosConfig,
        );
        return data;
    }

    public static async getSquadsSuggestion(squadId: number, clubId: number, keyword: string = ''): Promise<Array<SquadModel>> {
        const { data } = await axios.get<Array<SquadModel>>(
            `api/Squad/GetSquadSuggestionByKeyword/${squadId}/${clubId}/${keyword}`,
            this.axiosConfig,
        );
        return data;
    }

    public static async updateUserSquad(userId: number, newSquadId: number, oldSquadId: number, contactId: number): Promise<boolean> {
        const { data } = await axios.post(
            `api/Users/UpdateSquad`,
            {
                userId: userId,
                newSquadId: newSquadId,
                oldSquadId: oldSquadId,
                contactId: contactId
            },
            this.axiosConfig,
        );
        return data;
    }

    public static async updateUserExternalAgency(userId, value, oldValue, agencyName): Promise<boolean> {
        const { data } = await axios.post(
            `api/Users/UpdateExternalAgency`,
            {
                userId: userId,
                newAgencyExternalId: value,
                oldAgencyExternalId: oldValue,
                agencyName: agencyName
            },
            this.axiosConfig,
        );
        return data;
    }

    public static async getAgentsWaitingConfirmation(): Promise<Array<UserRequestModel>> {
        const { data } = await axios.get<Array<UserRequestModel>>(
            `api/Users/GetAgentsWaitingConfirmation`,
            this.axiosConfig,
        );
        return data;
    }

    public static async getSimilarContacts(res: ContactRequest): Promise<Array<ContactModel>> {
        const { data } = await axios.post(
            `api/Users/GetSimilarContacts`,
            res,
            this.axiosConfig,
        );
        return data;
    }
}