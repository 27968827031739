import axios from 'axios-config'
import { GetContactsDataResponse, Contact } from './models'
import { CancelToken } from 'axios';

export class AdminContactsService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    }

    public static async getContactsData(lastTimestamp: number): Promise<GetContactsDataResponse> {
        const { data } = await axios.get(
            `api/AdminContacts/Changes?lastTimestamp=${lastTimestamp}`
        )
        return data
    }

    public static async unmapUser(item: Contact):Promise<Contact> {
        const { data } = await axios.post(
            `api/AdminContacts/RemoveAssociation`,
            {
                contactId: item.id,
                userId: item.userId,
            },
            {...AdminContactsService.axiosConfig}
        )
        return data
    }

    public static async getUsersData(keyword: string, cancelToken: CancelToken) {
        const { data } = await axios.get(
            `api/AdminContacts/UsersForMapping`,
            {
                params: {
                    keyword: keyword,
                },
                cancelToken: cancelToken,
            }
        )
        return data
    }
    
    public static async selectedUser(mapContactId, userId) {     
        const { data } = await axios.post(
            `api/AdminContacts/AddAssociation`,
            {
                contactId: mapContactId,
                userId: userId ,
            },            
            {...AdminContactsService.axiosConfig}
        )
        return data
    }
}