export const testimonials = [
    {
        fullName: 'Dan Ashworth',
        position: 'Technical Director',
        club: 'Brighton & Hove Albion',
        img: require('media/images/events/why-attend-testimonials/1-dan-ashworth.png'),
        text: 'It’s a great way of improving your contact base and being really time efficient.'
    },
    {
        fullName: 'Les Ferdinand',
        position: 'Director of Football',
        club: 'Queens Park Rangers',
        img: require('media/images/events/why-attend-testimonials/2-les-ferdinand.jpg'),
        text: 'There is nobody in between claiming one person is saying this and another person is saying that.'
    },
    {
        fullName: 'Bruno Carotti',
        position: 'Sporting Director',
        club: 'HSC Montpellier',
        img: require('media/images/events/why-attend-testimonials/3-bruno-carotti.png'),
        text: 'TransferRoom Virtual Summit gives us the opportunity to meet the world of football in our office.'
    },
    {
        fullName: 'Dougie Freedman',
        position: 'Sporting Director',
        club: 'Crystal Palace',
        img: require('media/images/events/why-attend-testimonials/4-dougie-freedman.png'),
        text: 'It’s a way of strengthening relationships. It’s speed-training, it’s 15 minutes, the buzzer goes, and you get down to business very quickly.'
    },

    // To display without image and name
    // {
    //     text: 'Having the ability to virtually meet with decision makers all around the world at the palm of your hand is critical.'
    // },
]