import axios from 'axios-config';
import { PlayerTransfer } from './models';

export default class DealConfirmationService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async getDealsToConfirm(): Promise<Array<PlayerTransfer>> {
        const { data } = await axios.get(`api/v2/PlayerPreference/DealsToConfirm`, this.axiosConfig)
        return data
    }

    public static async setDealAsConfirmed(transferId: number) {
        await axios.post(`api/v2/PlayerPreference/ConfirmDeal`, {
            transferId
        }, this.axiosConfig)
    }

    public static async setDealAsRejected(transferId: number) {
        await axios.post(`api/v2/PlayerPreference/RejectDeal`, {
            transferId
        }, this.axiosConfig)
    }
}