import { StateController } from 'utils/action-declaration'
import { AppState } from 'root.reducer'
import { playerPathsV2 } from 'routes/paths'
import DealConfirmationService from 'api/player-v2/deal-confirmation/deal-confirmation.service'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions'
import { MessageType } from 'api/messaging/models/message'
import history from 'history-accessor'
import { PlayerTransfer } from 'api/player-v2/deal-confirmation/models'

class DealConfirmationState {
    currentPage: number
    isLoading: boolean
    deals: Array<PlayerTransfer>
}

const defaultState: DealConfirmationState = {
    currentPage: 1,
    isLoading: false,
    deals: null
}

const stateController = new StateController<DealConfirmationState>(
    'PLAYER_DEAL_CONFIRMATION',
    defaultState
)

class Actions {

    public static setCurrentPage(pageNumber: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ currentPage: pageNumber }))
        }
    }

    public static init(trackMesagesOpened: boolean) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));
            var data = await DealConfirmationService.getDealsToConfirm();
            dispatch(stateController.setState({ isLoading: false, deals: data }));

            if (data.length === 0)
                history.push(playerPathsV2.homePage)

            if (trackMesagesOpened && data.length > 0) {
                dispatch(userActivityInsert({
                    Message: 'Opened',
                    PageName: 'My Page [Messaging]',                // need to raname ??
                    PlayerId: data[0].playerId,
                    AgencyId: data[0].agencyId,
                }))
            }
        }
    }

    public static done() {
        return async (dispatch, getState: () => AppState) => {
            var substate = getState().playerV2.confirmDeal;
            var shifted = substate.deals.slice(1);

            if (shifted.length === 0) {
                dispatch(stateController.setState({ currentPage: 1 }))
                history.push(playerPathsV2.homePage)
            }
            else {
                dispatch(stateController.setState({ deals: shifted, currentPage: 1 }));
            }

        }
    }

    public static skip(transferMessage: PlayerTransfer) {
        return async (dispatch, getState: () => AppState) => {
            var substate = getState().playerV2.confirmDeal;
            var shifted = substate.deals.slice(1);

            if (shifted.length === 0) {
                dispatch(stateController.setState({ currentPage: 1 }));
                history.push(playerPathsV2.homePage)                
            }
            else {
                dispatch(stateController.setState({ deals: shifted, currentPage: 1 }));
            }
            dispatch(userActivityInsert({
                Message: 'Cancelled Deal Rejection',
                PageName: 'My Page [Messaging]',           //need to rename??
                PlayerId: transferMessage.playerId,
                AgencyId: transferMessage.agencyId,
            }))
        }
    }

    public static confirm(transferMessage: PlayerTransfer) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));
            await DealConfirmationService.setDealAsConfirmed(transferMessage.id);
            dispatch(stateController.setState({ isLoading: false, currentPage: 2 }));
            dispatch(userActivityInsert({
                Message: 'Confirmed Deal',
                PageName: 'My Page [Messaging]',
                PlayerId: transferMessage.playerId,
                AgencyId: transferMessage.agencyId,
            }))
        }
    }

    public static reject(transferMessage: PlayerTransfer) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));
            await DealConfirmationService.setDealAsRejected(transferMessage.id);
            dispatch(stateController.setState({ isLoading: false, currentPage: 4 }));
            dispatch(userActivityInsert({
                Message: 'Rejected Deal',
                PageName: 'My Page [Messaging]',
                PlayerId: transferMessage.playerId,
                AgencyId: transferMessage.agencyId,
            }))
        }
    }

    public static trackUserActivityForConfirmationLink(queryString?: string) {
        return async (dispatch, getState: () => AppState) => {
            if (queryString) {
                const playerId = new URLSearchParams(queryString).get('playerId')
                const agencyId = new URLSearchParams(queryString).get('agencyId')
                const source = new URLSearchParams(queryString).get('source')
                const messageType = new URLSearchParams(queryString).get('mt')

                if (parseInt(messageType) == MessageType.AgencyToPlayerTransferConfirmation) {
                    dispatch(userActivityInsert({
                        Message: 'Opened Deal Verification Link', //need to rename
                        PageName: source == "sms" ? 'Deal Confirmation SMS' : 'Deal Confirmation Email',
                        PlayerId: parseInt(playerId),
                        AgencyId: parseInt(agencyId),
                    }))
                }

                if (parseInt(messageType) == MessageType.ProfileSharingStart || parseInt(messageType) == MessageType.ProfileSharingStop) {
                    dispatch(userActivityInsert({
                        Message: 'Opened Activity Page Link',
                        PageName: source == "sms" ? 'Activity Sharing SMS' : 'Activity Sharing Email',
                        PlayerId: parseInt(playerId),
                        AgencyId: parseInt(agencyId),
                    }))
                }

            } else return
        }
    }
}


const reducer = stateController.getReducer()

export {
    reducer as Reducer,
    DealConfirmationState as State,
    Actions as Actions,
    stateController as Controller
}