import React from 'react'
import Panel from 'components/Panel';
import { EventTitle } from '../past-event-title'
import { PastEventStats } from '../past-event-stats'
import { PastEventClubLogos } from '../past-event-club-logos'
import styled from 'styled-components'
import CancunSummitImage from 'media/images/events/2024-june-cancun/cancun-summit-june-2024.jpg';

const CancunSummitJune2024 = () => {
    return (
        <div className="row">
            <div className="col-md-8 col-12">
                <Panel className="content-wrapper">
                    <EventTitle
                        number="14"
                        bgImageNumber={4}
                        th="th"
                        title1="TransferRoom"
                        title2="Summit"
                        date="3rd-4th June 2024, Cancún, Mexico"
                        vs={true}
                    />
                    <div className="photo-container row" style={{ padding: '0' }}>
                        <img style={{ width: '100%', borderRadius: '10px' }} src={CancunSummitImage} alt="" />
                    </div>
                </Panel>
            </div>
            <div className="col-md-4 col-12 row no-gutters">
                <div className="col-md-12 col-sm-6 col-12">
                    <PastEventStats
                        participants="112"
                        countries="24"
                        leagues="33"
                        agents="14"
                    />
                </div>
                <div className="col-md-12 col-sm-6 col-12">
                    <PastEventClubLogos
                        attendees={previousEventAttendees}
                        countlimit={42}
                    />
                </div>
            </div>
        </div>
    )
}

const SpeakersSection = styled.div`
    padding: 15px 0px 45px 0px;
    @media (max-width: 768px) {
        padding: unset;   
    }
    h2 {
        font-size: 21px;
        line-height: 34px;
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .speaker-card-container {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

        .speaker-card {
            width: 250px;
            margin-bottom: 10px;

            .speaker-image {
                display: flex;
                justify-content: center;
        
                & > div{
                    width: 128px;
                    height: 128px;
                    border-radius: 100%;
                    background-position: center;
                    background-size: cover;
                }
            }
        
            .speaker-info {
                margin-top: 15px;
                font-size: 16px;
                line-height: 19px;
                text-align: center;
        
                &__name {
                    font-weight: bold;
                }
        
                &__position {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                &__club {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }

        }
    }
`;

export const previousEventAttendees = [
    { id: 48, name: "Manchester City", logo: "1625_Manchester_City.png" },
    { id: 62, name: "Southampton FC", logo: "1619_Southampton_FC.png" },
    { id: 174, name: "CA Talleres", logo: "logo_2588_Club_Atletico_Talleres.png" },
    { id: 1065, name: "SC Braga", logo: "logo_Sporting_Braga.png" },
    { id: 1559, name: "FC Cincinnati", logo: "37892_Cincinnati.png" },
    { id: 1949, name: "Independiente Medellin", logo: "12399_Independiente_Medellín.png" },
    { id: 2226, name: "Sevilla FC", logo: "680_Sevilla_FC.png" },
    { id: 5121, name: "Minnesota United FC", logo: "logo_2122_Minnesota_United_FC.png" },
    { id: 5359, name: "Caracas FC", logo: "15680_Caracas_FC.png" },
    { id: 6031, name: "Tampa Bay Rowdies", logo: "8096_Tampa_Bay_Rowdies.png" },
    { id: 6249, name: "Bayer 04 Leverkusen", logo: "logo_1260_Bayer_04_Leverkusen.png" },
    { id: 7400, name: "Botafogo de Futebol e Regatas", logo: "logo_7400_Botafogo_de_Futebol_e_Regatas.png" },
    { id: 8064, name: "Kawasaki Frontale", logo: "logo_2410_Kawasaki_Frontale.png" },
    { id: 8839, name: "Ferroviaria", logo: "logo_8839_Associacao_Ferroviaria_de_Esportes_(SP).png" },
    { id: 10026, name: "Los Angeles Galaxy", logo: "7847_Los_Angeles_Galaxy.png" },
    { id: 10317, name: "New York Red Bulls", logo: "logo_2112_New_York_Red_Bulls.png" },
    { id: 13404, name: "Houston Dynamo FC", logo: "7944_Houston_Dynamo_FC.png" },
    { id: 15024, name: "Yverdon Sport FC", logo: "6422_Yverdon_Sport_FC.png" },
    { id: 15326, name: "Albion FC", logo: "logo_15326_Albion_FC.png" },
    { id: 15741, name: "US Triestina", logo: "logo_1905_US_Triestina.png" },
    { id: 16727, name: "Fortaleza CEIF", logo: "12427_Fortaleza_CEIF_FC.png" },
    { id: 22719, name: "Clube de Regatas Vasco da Gama", logo: "5657_Club_de_Regatas_Vasco_da_Gama.png" },
    { id: 24432, name: "Pafos FC", logo: "34760_Pafos_FC.png" },
    { id: 25436, name: "Nashville SC", logo: "30149_Nashville.png" },
    { id: 25911, name: "Royal Antwerp FC", logo: "logo_1583_Royal_Antwerp_FC.png" },
    { id: 26540, name: "Orlando City SC", logo: "8068_Orlando_City_SC.png" },
    { id: 29065, name: "Club Sporting Cristal", logo: "15532_Club_Sporting_Cristal.png" },
    { id: 30849, name: "Atlanta United FC", logo: "logo_2103_Atlanta_United_FC.png" },
    { id: 31536, name: "Aris Limassol", logo: "logo_31536_Aris_Limassol.png" },
    { id: 31588, name: "Rangers FC", logo: "logo_2188_Rangers_FC.png" },
    { id: 32865, name: "Portland Timbers", logo: "8113_Portland_Timbers.png" },
    { id: 33217, name: "Real CD Espana", logo: "17385_Real_CD_España.png" },
    { id: 33615, name: "Zalaegerszegi TE FC", logo: "logo_33615_Zalaegerszegi_TE_FC.png" },
    { id: 33648, name: "Atletico Nacional", logo: "12391_Atlético_Nacional.png" },
    { id: 35839, name: "Sociedade Esportiva Palmeiras", logo: "5646_Sociedade_Esportiva_Palmeiras.png" },
    { id: 37217, name: "Deportes Tolima", logo: "12396_Deportes_Tolima.png" },
    { id: 38328, name: "D.C. United", logo: "logo_2118_D.C._United.png" },
    { id: 41692, name: "Seattle Sounders FC", logo: "8067_Seattle_Sounders_FC.png" },
    { id: 43347, name: "Dynamo Puerto FC", logo: "64966_Dynamo_Puerto_FC.png" },
    { id: 50113, name: "Inter Miami CF", logo: "-69261_Inter_Miami_CF.png" },
    { id: 200004, name: "Charlotte FC", logo: "-78435_Charlotte_FC.png" },
    { id: 225041, name: "Karpaty Lviv", logo: null },
];

export default CancunSummitJune2024