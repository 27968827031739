// import { asyncActionType } from 'store/actionTypes'

export const SAVE_LANDING_PAGE_RESTRICTIONS = 'app/landing-page/SAVE_LANDING_PAGERESTRICTIONS'

export class RestrictionsState {
    showPitchClubName: boolean
}

const initialState: RestrictionsState = {
    showPitchClubName: false
}

export default (state: RestrictionsState = initialState, action): RestrictionsState => {
    switch (action.type) {
        case SAVE_LANDING_PAGE_RESTRICTIONS:
            return {
                ...state,
                ...action.payload.data
            }
        default:
            return state
    }
}