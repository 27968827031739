import {
    CLUB_ADS_FETCH,
    CLUB_ADS_MY_PITCHES_FETCH,
    CLUB_ADS_SELECT_CLUB_AD,
    CLUB_ADS_MAKE_AVAILABLE,
    CLUB_ADS_CLEAR_SELECTED_CLUB_AD,
    CLUB_ADS_SET_SHORT_LIST,
    CLUB_ADS_SET_PLUS_PITCH_TAB,
    CLUB_ADS_CLEAR,
    CLUB_ADS_FILTER_STRUCTURE_FETCH,
    CLUB_ADS_MY_PITCHES_FILTER_STRUCTURE_FETCH,
    CLUB_AD_PITCH_PLAYER,
    CLUB_AD_MAKE_AVAILABLE_REQUEST
} from 'store/actionTypes';
import { combineReducers } from 'redux';
import { arrayToObject } from 'services/utils';
import { getRequests } from 'store/requests/requestsReducer';
import compose from 'lodash/fp/compose';
import { createSelector } from 'reselect';

import pagination, {
    getCurrentPage,
    getPageSize,
} from 'pages/Pitch/tab-ads/components/pagination/reducers/pagination.reducer';
import { Selectors as FilterByPlayerSelectors } from 'pages/Pitch/tab-ads/store/filter-by-player.controller';

import myPitches from './my-pitches.reducer';
import filter, { getClubAdsFilterCriteria } from './filter-reducer';
import pitchInsight from './pitch-insight.reducer'
const byId = (clubAdsById = {}, action) => {
    switch (action.type) {
        case CLUB_ADS_FETCH.SUCCESS: {
            const ads = action.payload.data.items.output;
            return arrayToObject(ads);
        }
        case CLUB_ADS_CLEAR: {
            return {};
        }
        default:
            return clubAdsById;
    }
};

const allIds = (ids = [], action) => {
    switch (action.type) {
        case CLUB_ADS_FETCH.SUCCESS: {
            const ads = action.payload.data.items.output;
            return ads.map(ad => ad.id);
        }
        case CLUB_ADS_CLEAR: {
            return [];
        }
        default:
            return ids;
    }
};

const selectedId = (selectedIdState = false, action) => {
    switch (action.type) {
        case CLUB_ADS_SELECT_CLUB_AD: {
            const clubAdId = action.payload.id;
            return selectedIdState === clubAdId ? null : clubAdId;
        }
        case CLUB_ADS_CLEAR_SELECTED_CLUB_AD: {
            return null;
        }
        default:
            return selectedIdState;
    }
};

const makeAvailable = (makeAvailableState = false, action) => {
    switch (action.type) {
        case CLUB_ADS_MAKE_AVAILABLE: {
            return action.payload;
        }
        case CLUB_ADS_SELECT_CLUB_AD:
        case CLUB_ADS_CLEAR_SELECTED_CLUB_AD: {
            return false
        }
        default:
            return makeAvailableState;
    }
};

const playersWithChangedAvailability = (playersChangedAvailabilityState = [], action) => {
    switch (action.type) {
        case CLUB_AD_MAKE_AVAILABLE_REQUEST.SUCCESS: {
            return [...playersChangedAvailabilityState, action.payload.request.payload.playerId];
        }
        case CLUB_ADS_CLEAR_SELECTED_CLUB_AD: {
            return []
        }
        default:
            return playersChangedAvailabilityState;
    }
}

const shortList = (shortList = false, action) => {
    switch (action.type) {
        case CLUB_ADS_SET_SHORT_LIST: {
            return action.payload;
        }
        default:
            return shortList;
    }
};

const plusPitch = (plusPitch = false, action) => {
    switch (action.type) {
        case CLUB_ADS_SET_PLUS_PITCH_TAB: {
            return action.payload;
        }
        default: {
            return plusPitch
        }
    }
}

const processingPitchIds = (processingIds = [], action) => {
    switch (action.type) {
        case CLUB_AD_PITCH_PLAYER.REQUEST: {
            return [...processingIds, action.payload.payload.clubAdId];
        }
        case CLUB_AD_PITCH_PLAYER.SUCCESS: {
            return processingIds.filter(item => item !== action.payload.request.payload.clubAdId)
        }
        case CLUB_AD_PITCH_PLAYER.ERROR: {
            return processingIds.filter(item => item !== action.payload.payload.clubAdId)
        }
        default: {
            return processingIds
        }
    }
}

export default combineReducers({
    byId,
    allIds,
    selectedId,
    makeAvailable,
    pagination,
    filter,
    myPitches,
    shortList,
    plusPitch,
    pitchInsight,
    processingPitchIds,
    playersWithChangedAvailability
});








export const getClubAds = state => state.clubAds;
export const getMyPitches = state => getClubAds(state).myPitches;
export const isShortList = state => getClubAds(state).shortList;
export const isPlusPitchOpen = state => getClubAds(state).plusPitch;
export const getProcessingIds = state => getClubAds(state).processingPitchIds
export const getClubAdsFilter = compose(
    clubAds => clubAds.filter,
    getClubAds,
);
export const getMyPitchesFilter = compose(
    myPitches => myPitches.filter,
    getMyPitches,
);
export const getClubAd = compose(
    clubAds => id => clubAds.byId[id],
    getClubAds,
);
const getSelectedClubAdId = compose(
    clubAds => clubAds.selectedId,
    getClubAds,
);

export const getIsMakeAvailableOpen = compose(
    clubAds => clubAds.makeAvailable,
    getClubAds,
);

export const getPlayersChangedAvailability = compose(
    clubAds => clubAds.playersWithChangedAvailability,
    getClubAds
)

export const getSelectedClubAd = createSelector(
    getClubAd,
    getSelectedClubAdId,
    (getClubAd, id) => getClubAd(id),
);
export const getClubAdsLoadingStatus = state =>
    getRequests(state).some(
        r =>
            r.type === CLUB_ADS_FETCH || r.type === CLUB_ADS_FILTER_STRUCTURE_FETCH,
    );
export const getMyPitchesLoadingStatus = state =>
    getRequests(state).some(
        r => r.type === CLUB_ADS_MY_PITCHES_FETCH || r.type === CLUB_ADS_MY_PITCHES_FILTER_STRUCTURE_FETCH
    );
export const getClubAdsFetchRequest = createSelector(
    getClubAdsFilterCriteria,
    getCurrentPage('clubAds'),
    getPageSize('clubAds'),
    FilterByPlayerSelectors.getSelectedPlayerId,
    (criteria, currentPage, pageSize, selectedPlayerId) => {
        return {
            ...criteria,
            playerId: selectedPlayerId,
            currentPageNumber: currentPage,
            pageSize: pageSize,
        };
    },
);
export const getMyPitchesFetchRequest = createSelector(
    getClubAdsFilterCriteria,
    getCurrentPage('myPitches'),
    getPageSize('myPitches'),
    FilterByPlayerSelectors.getSelectedPlayerId,
    (criteria, currentPage, pageSize, selectedPlayerId) => {
        return {
            ...criteria,
            playerId: selectedPlayerId,
            currentPageNumber: currentPage,
            pageSize: pageSize,
        };
    },
);
