import Range from 'api/core/range'

export class SearchRequest {
    /// <summary>
    /// In case only agents and club turned on it is not affected on result.
    /// </summary>
    public playerFilter: SearchPlayerFilter;

    /// <summary>
    /// In case clubs and agents filter turned on, PlayerFilter is disabled.
    /// </summary>
    public clubsAndAgentsFilter: OnlyAgentsAndClubsFilter;

    public shortListOnly: boolean;

    public sortByKey: SearchByKey;

    public keyword: string;

    public playerSet?: number[];

    public pageNumber: number;

    public pageSize: number;
}

export class SearchPlayerFilter {
    public age: Range<number>;

    public gbePass?: boolean;

    public minHeight?: number;

    public contractExpiryMonthesMax?: number;

    public leaguesList: Array<number>;

    public nationalityList: Array<number>;

    public positionList: Array<string>;

    public showOnlyFreeAgentPlayers: boolean;

    public showOnlyAvailablePlayers: boolean;

    public showOnlyPlayersWithSuspendedContract?: boolean;

    public showOnlyPlayersOnReleaseList: boolean;

    public showOnlyPlayersLookingForAgent?: boolean;

    public transferFee?: Range<number>;

    public anuallGrossSalaryYearly?: Range<number>;

    public loanFee?: Range<number>;

    public minutesPlayed?: Range<number>;
    
    public marketValue?: Range<number>;

    public minRating?: number;

}

export class OnlyAgentsAndClubsFilter {
    public showOnlyClubs: boolean;
    public showOnlyTrustedAgents: boolean;
}

export enum SearchByKey {
    Availability = 0,
    XtvHightToLow = 1,
    XtvLowToHigh = 2,
    AgeHigtToLow = 3,
    AgeLowHigh = 4,
    ContractExpiry = 5,
    ByRelevant = 6,
    DateWhenAddedToShortList = 7,
    Similarity = 8,
    RatingHightToLow = 9,
    RatingLowToHigh = 10,
}