import React from 'react'
import { connect } from 'react-redux';
import Panel from 'components/Panel/Panel'
import Button from 'components/Buttons/Button/Button'
import userActivityInsertSingle, { UserActivity } from 'app/user-activity/actions/user-activity.actions';
import { userPaths } from 'routes/paths'
import { MIN_COUNT_INVITED_AGENT } from 'pages/virtual-summit/redux/confirm-page-steps/invite-agents.controller';
import { PageType } from 'constants/enums';
import AuthState from 'store/auth/auth-state';
import { getAuth } from 'store/auth/authReducer';
import Section from './attendance-details.styled';
import historyAccessor from 'history-accessor';

export interface StateProps {
    auth: AuthState;
}
interface DispatchProps {
    userActivityInsert: (data: UserActivity) => void;
}
interface OwnProps {
    details: {
        isTransferIn: boolean;
        isTransferOut: boolean;
        isGeneralNetworking: boolean;
        transferInStepCompleted: boolean;
        transferOutStepCompleted: boolean;
        generalNetworkingStepCompleted: boolean;
        generalNetworkingClubs: string[];
        transferOutPlayers: string[];
        positionNames: string[];
        // isRewardComplete: boolean,
        trustedAgents: Array<{ id: number, name: string }>,
        invitedAgencySuggestionCount: number;
    }
}

type Props = StateProps & DispatchProps & OwnProps;

const AttendanceDetails = (props: Props) => {

    const {
        isTransferIn,
        isTransferOut,
        isGeneralNetworking,
        transferInStepCompleted,
        transferOutStepCompleted,
        generalNetworkingStepCompleted,
        trustedAgents,
        invitedAgencySuggestionCount,
        positionNames,
        transferOutPlayers,
        generalNetworkingClubs
    } = props.details;

    const concatWithTitle = (arrayOfValues: Array<string>, limit: number) => {
        var short = arrayOfValues.slice(0, limit);
        var dataLong = arrayOfValues.join(', ');
        var dataShort = short.join(', ');

        if (short.length < arrayOfValues.length) {
            dataShort += "...";
        }

        return <span style={{ cursor: 'pointer' }} title={dataLong}>{dataShort}</span>
    }

    const isObjectivesDone = () => isTransferIn || isTransferOut || isGeneralNetworking;
    // const rewardDone = () => props.details.isRewardComplete;
    // const renderRewardLabel = () => {
    //     return <div>Get up to 6 free plus pitches and help the community to grow by inviting colleagues from clubs that are not yet on TransferRoom</div>
    // }

    const renderObjectivesLabel = () => {
        if (!isObjectivesDone()) {
            return <div>Choose your objectives and set up details</div>
        } else {
            let texts = [];
            if (isTransferIn) texts.push('Transfers In');
            if (isTransferOut) texts.push('Transfers Out');
            if (isGeneralNetworking) texts.push('General Networking');

            let string = texts.join(', ')
            return <div>
                {string}
            </div>
        }
    }

    const isTransferInDone = () => {
        if (transferInStepCompleted) {
            return Object.keys(positionNames).length > 0;
        }
        return false;
    }

    const renderTransferInLabel = () => {
        if (isTransferInDone()) {
            let positions = Object.keys(positionNames);
            return <div>
                You set requirements for {positions.length} position{positions.length == 1 ? '' : 's'}:
                <br />
                {concatWithTitle(positions, 5)}
            </div>
        } else {
            return 'Set Up Your Requirements to help clubs prepare'
        }
    }

    const isTransferOutDone = () => {
        if (transferOutStepCompleted) {
            return transferOutPlayers.length > 0;
        }
        return false;
    }

    const renderTransferOutLabel = () => {
        if (isTransferOutDone()) {
            let count = transferOutPlayers.length;
            return <div>
                You have made {count} player{count == 1 ? '' : 's'}  available:{' '}
                {concatWithTitle(transferOutPlayers, 15)}
            </div>
        } else {
            return 'Which players are you looking to sell or loan out at the event?'
        }
    }

    const isTransferInviteClubDone = () => {
        if (generalNetworkingStepCompleted) {
            return generalNetworkingClubs.length > 0;
        }
        return false;
    }

    const renderInviteInLabel = () => {

        if (isTransferInviteClubDone()) {
            return <div>
                Invited:{' '}
                {concatWithTitle(generalNetworkingClubs, 16)}
            </div>
        } else {
            return 'Supercharge your event potential by inviting clubs that you personally would like to meet.'
        }
    }

    const renderTrustedAgentsInLabel = () => {

        if (trustedAgents.length > 0) {
            return <div>
                Invited:{' '}
                {concatWithTitle(trustedAgents.map(x => x.name), 16)}
            </div>
        } else {
            return 'Invite Trusted Agents you would like to see or meet at the event'
        }
    }

    const transferInviteClubDone = isTransferInviteClubDone();

    const flags = [
        (isTransferIn || isTransferOut || isGeneralNetworking),
        transferInStepCompleted,
        transferOutStepCompleted,
        transferInviteClubDone,
        //this.rewardDone()
    ]

    if (invitedAgencySuggestionCount >= MIN_COUNT_INVITED_AGENT) {
        flags.push(trustedAgents.length > 0)
    }

    const getDoneItemsCount = () => {
        return flags.filter(x => x === true).length
    }

    const totalItemsCount = flags.length
    const doneItemsCount = getDoneItemsCount()

    const inviteTrustedPassed = invitedAgencySuggestionCount >= MIN_COUNT_INVITED_AGENT && trustedAgents.length == invitedAgencySuggestionCount;

    const getUrlToStep = (step: number) => {
        return `${userPaths.eventCompleteDetails}?step=${step}&referer=/events`;
    }

    const { auth } = props;

    return (
        <Section>
            <div className="ep-attendance-details">
                <h2 className="ep-attendance-details__heading">
                    Your Attendance Details {doneItemsCount != totalItemsCount && <span>({doneItemsCount} of {totalItemsCount} items done)</span>}
                </h2>

                {/* Early access text */}
                {
                    // doneItemsCount < 2 ? (
                    //     <p>If you complete 2 of 4 items below before 8th March you will get early access to club-to-club meeting booking.</p>
                    // ) :
                    // (
                    //     <p><b>You are eligible for early access to the club-to-club meeting booking.</b></p>
                    // )
                }

                <div style={{ marginTop: 50 }} className="ep-attendance-details__items-container">
                    <SuggestionPanel
                        titleText={isObjectivesDone() ? "Your Primary Objectives" : "Set Up Your Primary Objectives"}
                        completed={isObjectivesDone()}
                        descriptionText={renderObjectivesLabel()}
                        updateButtonText={"UPDATE"}
                        setUpButtonText={"SET UP NOW"}
                        onClick={(actionType) => {
                            if (actionType === 'update') {
                                props.userActivityInsert({
                                    Message: 'Update Primary Objectives',
                                    PageName: 'Event',
                                    PageType: PageType.Events,
                                    EventId: auth.eventId,
                                })
                            } else {
                                props.userActivityInsert({
                                    Message: 'Set Up Your Primary Objectives',
                                    PageName: 'Event',
                                    PageType: PageType.Events,
                                    EventId: auth.eventId,
                                })
                            }
                            historyAccessor.push(`${userPaths.eventCompleteDetails}?step=1`);
                        }}
                    />
                    <SuggestionPanel
                        titleText={isTransferInDone() ? "Transfers In" : "Transfers In"}
                        completed={isTransferInDone()}
                        descriptionText={renderTransferInLabel()}
                        updateButtonText={"UPDATE"}
                        setUpButtonText={"SET UP NOW"}
                        onClick={(actionType) => {
                            if (actionType === 'update') {
                                props.userActivityInsert({
                                    Message: 'Update Transfers In',
                                    PageName: 'Event',
                                    PageType: PageType.Events,
                                    EventId: auth.eventId,
                                })
                            } else {
                                props.userActivityInsert({
                                    Message: 'Set Up Transfers In',
                                    PageName: 'Event',
                                    PageType: PageType.Events,
                                    EventId: auth.eventId,
                                })
                            }

                            historyAccessor.push(getUrlToStep(2));

                        }}
                    />
                    <SuggestionPanel
                        titleText="Transfers Out"
                        completed={isTransferOutDone()}
                        descriptionText={renderTransferOutLabel()}
                        updateButtonText={"UPDATE"}
                        setUpButtonText={"SET UP NOW"}
                        onClick={(actionType) => {

                            if (actionType === 'update') {
                                props.userActivityInsert({
                                    Message: 'Update Transfers Out',
                                    PageName: 'Event',
                                    PageType: PageType.Events,
                                    EventId: auth.eventId,
                                })
                            } else {
                                props.userActivityInsert({
                                    Message: 'Set Up Transfers Out',
                                    PageName: 'Event',
                                    PageType: PageType.Events,
                                    EventId: auth.eventId,
                                })
                            }

                            historyAccessor.push(getUrlToStep(3));
                        }}
                    />
                    <SuggestionPanel
                        titleText="Invite Clubs"
                        completed={transferInviteClubDone}
                        descriptionText={renderInviteInLabel()}
                        updateButtonText={"INVITE MORE"}
                        setUpButtonText={"INVITE NOW"}
                        onClick={(actionType) => {

                            if (actionType === 'update') {
                                props.userActivityInsert({
                                    Message: 'Invite More Clubs',
                                    PageName: 'Event',
                                    PageType: PageType.Events,
                                    EventId: auth.eventId,
                                })
                            } else {
                                props.userActivityInsert({
                                    Message: 'Invite Clubs',
                                    PageName: 'Event',
                                    PageType: PageType.Events,
                                    EventId: auth.eventId,
                                })
                            }

                            historyAccessor.push(getUrlToStep(4));
                        }}
                    />
                    {invitedAgencySuggestionCount >= MIN_COUNT_INVITED_AGENT && <SuggestionPanel
                        titleText="Invite Trusted Agents"
                        completed={props.details.trustedAgents.length > 0}
                        descriptionText={renderTrustedAgentsInLabel()}
                        updateButtonText={!inviteTrustedPassed ? "INVITE MORE" : null}
                        setUpButtonText={!inviteTrustedPassed ? "INVITE NOW" : null}
                        onClick={(actionType) => {

                            if (actionType === 'update') {
                                props.userActivityInsert({
                                    Message: 'Invite More Trusted Agents',
                                    PageName: 'Event',
                                    PageType: PageType.Events,
                                    EventId: auth.eventId,
                                })
                            } else {
                                props.userActivityInsert({
                                    Message: 'Invite Trusted Agents',
                                    PageName: 'Event',
                                    PageType: PageType.Events,
                                    EventId: auth.eventId,
                                })
                            }

                            historyAccessor.push(getUrlToStep(5));
                        }}
                    />}
                    {/* 
                    hidden
                    <SuggestionPanel
                        titleText="Rewards Programme"
                        completed={this.rewardDone()}
                        descriptionText={this.renderRewardLabel()}
                        updateButtonText={"INVITE MORE"}
                        setUpButtonText={"INVITE NOW"}
                        onClick={(actionType) => {
                            if (actionType === 'update') {
                                this.props.userActivityInsert({ 
                                    Message: 'Update Rewards Programme', 
                                    PageName: 'Event',
                                    PageType: PageType.Events,
                                    EventId: auth.eventId,
                                })
                            } else {
                                this.props.userActivityInsert({ 
                                    Message: 'Set Up Reward Programme', 
                                    PageName: 'Event',
                                    PageType: PageType.Events,
                                    EventId: auth.eventId,
                                })
                            }
                            this.props.history.push(getUrlToStep(5));
                        }}
                    />
                    
                    */}

                </div>
            </div>
        </Section>
    );
}

interface SuggestionPanelProps {
    titleText: string,
    descriptionText: JSX.Element | string;
    completed: boolean,
    onClick: (string) => void
    updateButtonText: string,
    setUpButtonText: string
}
const SuggestionPanel = (props: SuggestionPanelProps): JSX.Element => {
    return (
        <Panel compact className={`ep-attendance-details__suggestion-item suggestion-item ${props.completed ? 'completed' : ''}`}>
            <div className="suggestion-item__number">
                {props.completed ? (
                    <i className="fas fa-check"></i>
                ) : (
                    <span>TO DO</span>
                )}
            </div>
            <div className="suggestion-item__text">
                <div>{props.titleText}</div>
                <div>{props.descriptionText}</div>
            </div>
            {
                props.descriptionText != null && props.setUpButtonText != null
                &&
                <div className="suggestion-item__btn">
                    {props.completed ? (
                        <Button
                            large
                            type="primary"
                            effect="outline"
                            onClick={() => props.onClick('update')}
                            style={{ textTransform: 'uppercase' }}
                        >
                            {props.updateButtonText}
                        </Button>
                    ) : (
                        <Button
                            large
                            type="primary"
                            effect="no"
                            onClick={() => props.onClick('setup')}
                            style={{ textTransform: 'uppercase' }}
                        >
                            {`${props.setUpButtonText} >`}
                        </Button>
                    )}
                </div>
            }
        </Panel >
    )
}



const mapStateToProps = (state): StateProps => ({
    // details: state.events.upcommingSummitData,
    auth: getAuth(state),
})

const mapDispatchToProps: DispatchProps = {
    userActivityInsert: userActivityInsertSingle
}

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceDetails)