import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import historyAccessor from 'history-accessor';
import { playerPathsV2 } from 'routes/paths';
import {
  PLAYER_FORCE_PLAYER_REMOVED_SCREEN_LAYOUT_FALSE,
  PLAYER_FORCE_REPRESENTATION_SCREEN_LAYOUT_TRUE
} from 'store/actionTypes';
import AgencyFinderService from 'api/player-v2/agency-finder.service';
import { getPlayerUserPermissions } from "store/userPreference/userPreference.reducer";
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';

class State {
  processing: boolean;
}

const defaultState: State = {
  processing: false,
}

const stateController = new StateController<State>(
  'PLAYERV2/PLAYER-REMOVED-SCREEN',
  defaultState
)

class Actions {
  public static dispose() {
    return (dispatch) => {
      dispatch(stateController.setState({ ...defaultState }));
    }
  }

  public static onRequestAgentRepresentation() {
    return async (dispatch, getState: () => AppState) => {
      try {
        const playerId = getState().auth.playerId;

        dispatch(stateController.setState({ processing: true }));

        await AgencyFinderService.RemoveDisconnectedFromAgentInvitation();
        dispatch({ type: PLAYER_FORCE_REPRESENTATION_SCREEN_LAYOUT_TRUE });
        dispatch({ type: PLAYER_FORCE_PLAYER_REMOVED_SCREEN_LAYOUT_FALSE });

        dispatch(userActivityInsert({
          PageName: 'Agent Removed',
          Message: 'Opened Representation Request',
          PlayerId: playerId,
        }));
      } catch (err) {
        console.error(err)
      } finally {
        dispatch(stateController.setState({ processing: false }));
      }
    }
  }

  public static onClose() {
    return async (dispatch, getState: () => AppState) => {
      try {
        const playerId = getState().auth.playerId;

        dispatch(stateController.setState({ processing: true }));

        await AgencyFinderService.RemoveDisconnectedFromAgentInvitation();
        dispatch({ type: PLAYER_FORCE_PLAYER_REMOVED_SCREEN_LAYOUT_FALSE });

        dispatch(userActivityInsert({
          PageName: 'Agent Removed',
          Message: 'Closed Notification',
          PlayerId: playerId,
        }));

        historyAccessor.push(playerPathsV2.homePage);
      } catch (err) {
        console.error(err)
      } finally {
        dispatch(stateController.setState({ processing: false }));
      }
    }
  }
}

class Selectors {
  public static getRoot = (state: AppState): State => state.playerV2.playerRemoved;
  public static isProcessing = (state: AppState) => Selectors.getRoot(state).processing;
  public static isAvailableRepresentationRequest = (state: AppState): boolean => {
    const permissions = getPlayerUserPermissions(state)
    const isAvalible = permissions?.isPlayerReferralsAvailable || permissions?.isAgentFinderAvailable ||
      permissions?.isMessagesAvailable || permissions?.isPlayerSettingsAvailable;
    return isAvalible;
  }
}

const reducer = stateController.getReducer();

export {
  reducer as Reducer,
  State as State,
  Actions as Actions,
  stateController as Controller,
  Selectors as Selectors
};
