const attendees = [
    { id: '46', name: 'Liverpool', logo: 'logo_1123_Liverpool_FC.png' },
    { id: '70', name: 'Brighton & Hove Albion', logo: 'logo_1135_Brighton_And_Hove_Albion.png' },
    { id: '2956', name: 'KAA Gent', logo: 'logo_1571_KAA_Gent.png' },
    { id: '65', name: 'Wolverhampton Wanderers', logo: 'logo_Wolverhampton_Wanderers.png' },
    { id: '30997', name: 'Red Bull Salzburg', logo: 'logo_1942_Red_Bull_Salzburg.png' },
    { id: '63', name: 'Everton FC', logo: 'logo_1124_Everton_FC.png' },
    { id: '10026', name: 'Los Angeles Galaxy', logo: '7847_Los_Angeles_Galaxy.png' },
    { id: '2459', name: 'Fenerbahce SK', logo: 'logo_2004_Fenerbahce_SK.png' },
    { id: '48', name: 'Manchester City', logo: 'logo_Manchester_City.png' },
    { id: '17417', name: 'BSC Young Boys', logo: 'logo_1934_BSC_Young_Boys.png' },
    { id: '18001', name: 'Fortuna Düsseldorf', logo: 'logo_Fortuna_Dusseldorf.png' },
    { id: '29469', name: 'Dinamo Zagreb', logo: 'logo_2163_GNK_Dinamo_Zagreb.png', },
    { id: '3', name: 'Juventus FC', logo: 'logo_1832_Juventus_FC.png' },
    { id: '13058', name: 'Feyenoord Rotterdam', logo: 'logo_1533_Feyenoord_Rotterdam.png' },
    { id: '26232', name: 'FC Schalke 04', logo: 'logo_FC_Schalke_04.png' },
    { id: '23168', name: '1. FC Köln', logo: 'logo_1266_1._FC_Koln.png' },
    { id: '29178', name: 'Melbourne City FC', logo: 'logo_2473_Melbourne_City_FC.png' },
    { id: '64', name: 'Crystal Palace', logo: 'logo_1128_Crystal_Palace.png' },
    { id: '23588', name: 'SC Heerenveen', logo: 'logo_1537_SC_Heerenveen.png' },
    { id: '5282', name: 'PSV Eindhoven', logo: 'logo_1534_PSV_Eindhoven.png' },
    { id: '28998', name: 'Real Betis', logo: 'logo_Real_Betis_Balompie.png' },
    { id: '1064', name: 'Nîmes Olympique', logo: '3813_Nîmes_Olympique.png' },
    { id: '14821', name: 'FC Copenhagen', logo: 'logo_FC_Copenhagen.png' },
    { id: '21139', name: 'Olympiacos', logo: '12509_Olympiacos_Piraeus.png' },
    { id: '35103', name: 'Standard Liège', logo: 'logo_1574_Standard_Liege.png' },
    { id: '6160', name: 'CSKA Moscow', logo: '13886_CSKA_Moskva.png' },
    { id: '33906', name: 'FC Barcelona', logo: null }, //'logo_FC_Barcelona.png'
    { id: '60', name: 'Newcastle United', logo: 'logo_Newcastle_United.png' },
    { id: '71', name: 'AFC Bournemouth', logo: 'logo_1134_AFC_Bournemouth.png', },
    { id: '27230', name: 'CD América de Cali', logo: 'logo_2664_CD_America_de_Cali.png', },
    { id: '31588', name: 'Rangers FC', logo: 'logo_2188_Rangers_FC.png', },
    { id: '24711', name: 'Urawa Red Diamonds', logo: 'logo_2407_Urawa_Red_Diamonds.png' },
    { id: '40508', name: 'LASK', logo: '8754_LASK.png' },
    { id: '34374', name: 'Levski Sofia', logo: '9218_Levski_Sofia.png' },
    { id: '39176', name: 'Leeds United', logo: 'logo_1150_Leeds_United.png' },
    { id: '20454', name: 'Legia Warszawa', logo: 'logo_1594_Legia_Warszawa.png', },
    { id: '33648', name: 'Atlético Nacional', logo: '12391_Atlético_Nacional.png', },
    { id: '11863', name: 'Shakhtar Donetsk', logo: 'logo_2135_Shakhtar_Donetsk.png' },
    { id: '28212', name: 'RC Strasbourg Alsace', logo: 'logo_1981_RC_Strasbourg_Alsace.png' },
    { id: '15549', name: 'FC Basel 1893', logo: 'logo_1932_FC_Basel_1893.png', },
    { id: '17017', name: 'Philadelphia Union', logo: 'logo_2115_Philadelphia_Union.png' },
    { id: '4059', name: 'Nice', logo: 'logo_1965_OGC_Nice.png' },
]

export default attendees