import { StateController } from 'utils/action-declaration'
import { AppState } from 'root.reducer';
import { PlayerAdService } from 'api/player-ad/player-ad-service'
import { CreateplayerAd, CreatePlayerAdRequestV2 } from 'api/player-ad/models/create-player-ad'
import { Actions as StepActions } from 'pages/virtual-summit/redux/complete-details.controller';
import VirtualSummitService from 'api/virtual-summit/virtual-summit.service'

import { PlayerAd } from 'api/player-ad/models/player-ad';
import { playerAdUpdateEvent } from "app/player-ads/store/actions/player-ads.actions"
import { removeAdVirtualSummit } from "store/adForceUpdate/ad-force-update.actions"
import moment from 'moment'
import { getPositionCodeById, getPositionIdByCode } from "utils/position-helper";
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { hasAgentFeature } from 'store/auth/authReducer';
import { v4 } from 'uuid';
import { PageType, ActionType } from 'constants/enums';
import { PlayerAdInitialInfoResponse} from 'api/player-ad/models/player-ad'
import { getPreferredPlayerAreas} from 'pages/player-ads/create-new-ad-modal/helpers/preferred-player-area';
import { getPreferredPlayingStyles} from 'pages/player-ads/create-new-ad-modal/helpers/preferred-player-playing-style';
import { IdName } from "api/core/id-name";

export const ageRule = { min: 16, max: 40 };
export const heightRule = { min: 150, max: 210 };

export class CreateAdModel {
    id: string
    finished: boolean;
    toLoan: boolean;
    toBuy: boolean;

    loan?: number;
    buy?: number;
    grossSalary?: number;

    age: { min: number, max: number }
    height: number;

    isAgencyRecommended: boolean;

    preferredPlayerArea: IdName;
    preferredPlayingStyle: IdName;
    isAnonymiseRequirement: boolean;
}

class CreatePlayerAdState {
    positions: {
        [key: string]: Array<CreateAdModel>
    }
    currentPositionIndex: number;
    currentStep: number;
    adsProcessing: boolean;
    playerAds: Array<PlayerAd>;
    isLoading: boolean;
    outdatedAdsRemoved: Array<PositionIdPair>;
    outdatedAdsApprovedV2: Array<PlayerAd>

    playerAdInitialInfo: PlayerAdInitialInfoResponse;

    loadingPlayerAds: boolean
}

export class PositionIdPair {
    position: string;
    id: number;
    type: number;
}

const defaultState: CreatePlayerAdState = {
    positions: {},
    currentPositionIndex: 0,
    currentStep: 1,
    adsProcessing: false,
    playerAds: null,
    isLoading: false,
    outdatedAdsRemoved: [],
    outdatedAdsApprovedV2: [],

    playerAdInitialInfo: null,

    loadingPlayerAds: false,
}

const stateController = new StateController<CreatePlayerAdState>(
    "VIRTUAL-SUMMIT/CREATE_PLAYER_AD_MODAL",
    defaultState
);

class Actions {
    static deleteFromState(id: any): any {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ playerAds: getState().virtualSummit.createPlayerAd.playerAds.filter(x => (x as any).id != id) }))
        }
    }

    static addRemovedAd(id: number) {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().virtualSummit.createPlayerAd;
            let item = substate.playerAds.find(x => x.id == id);

            await dispatch(removeAdVirtualSummit(id))
            await dispatch(stateController.setState({ outdatedAdsRemoved: [...substate.outdatedAdsRemoved, { id: item.id, position: item.positionCode, type: item.type }] }))
            await dispatch(this.deleteFromState(id));
        }
    }

    static addUpdatedAd(ad: PlayerAd) {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().virtualSummit.createPlayerAd;
            let item = substate.playerAds.find(x => x.id == ad.id);

            let duration = moment.duration(moment.utc().diff(ad.lastUpdated))
            if (duration.asHours() > 24) {
                await dispatch(playerAdUpdateEvent(ad));
            }

            await dispatch(stateController.setState({
                outdatedAdsApprovedV2: [...substate.outdatedAdsApprovedV2, item],
                playerAds: substate.playerAds.filter(x => x.id != item.id)
            }))
        }
    }

    public static nextStep(amountOfSteps: number = 1) {
        return (dispatch, getState: () => AppState) => {
            let state = getState();
            let step = getState().virtualSummit.createPlayerAd.currentStep;

            if (step === 1) {
                const positions = getState().virtualSummit.createPlayerAd.positions;
                for (let key in positions) {
                    if (positions.hasOwnProperty(key)) {
                        let ads = positions[key];
                        ads.forEach(ad => {
                            ad.isAgencyRecommended = state.auth.isAgentsVisible;
                        });
                    }
                }
            }
            dispatch(stateController.setState({ currentStep: getState().virtualSummit.createPlayerAd.currentStep + amountOfSteps }))
        }
    }
    public static prevStep(amountOfSteps: number = 1) {
        return (dispatch, getState: () => AppState) => {
            let substate = getState().virtualSummit.createPlayerAd;
            var currentPositionCode = Object.keys(substate.positions)[substate.currentPositionIndex];
            substate.positions[currentPositionCode] = substate.positions[currentPositionCode].filter(x => x.finished || x.id == null)

            dispatch(stateController.setState({
                currentStep: getState().virtualSummit.createPlayerAd.currentStep - amountOfSteps,
                positions: { ...getState().virtualSummit.createPlayerAd.positions }
            }))
        }
    }

    public static createNewAd() {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState().virtualSummit.createPlayerAd;
            let defaultAdState: CreateAdModel = {
                id: v4(),
                finished: false,
                toLoan: false,
                toBuy: false,
                age: null,//{ min: 16, max: 40 },
                height: null,//150
                isAgencyRecommended: true,
                preferredPlayerArea: null,
                preferredPlayingStyle: null,
                isAnonymiseRequirement: false,
            }

            var currentPositionCode = Object.keys(substate.positions)[substate.currentPositionIndex];
            substate.positions[currentPositionCode].push(defaultAdState);
            dispatch(Actions.loadPlayerAdInitialInfo())
            dispatch(stateController.setState({ positions: { ...getState().virtualSummit.createPlayerAd.positions } }));
        }
    }

    public static removeCreatedAd(id: string) {
        return (dispatch, getState: () => AppState) => {
            let substate = getState().virtualSummit.createPlayerAd;

            var currentPositionCode = Object.keys(substate.positions)[substate.currentPositionIndex];
            substate.positions[currentPositionCode] = substate.positions[currentPositionCode].filter(x => x.id != id);

            dispatch(stateController.setState({ positions: { ...getState().virtualSummit.createPlayerAd.positions } }));
        }
    }

    public static changePositionsSelection(positionCode: string) {

        let defaultAdState: CreateAdModel = {
            id: null,
            finished: false,
            toLoan: false,
            toBuy: false,
            age: null,//{ min: 16, max: 40 },
            height: null,//150
            isAgencyRecommended: true,
            preferredPlayerArea: null,
            preferredPlayingStyle: null,
            isAnonymiseRequirement: false,
        }

        return (dispatch, getState: () => AppState) => {
            let state = getState();

            defaultAdState.isAgencyRecommended = state.auth.isAgentsVisible && (hasAgentFeature(state)
                || state.auth.isFreemiumSubscription);

            const positions = getState().virtualSummit.createPlayerAd.positions

            if (Object.keys(positions).includes(positionCode)) {
                delete positions[positionCode]
            } else {
                positions[positionCode] = [defaultAdState]
            }

            dispatch(stateController.setState({
                positions: { ...positions }
            }))
        }
    }

    public static loadPlayerAds = () => async (dispatch, getState) => {
        dispatch(stateController.setState({ loadingPlayerAds: true }));
        let appState = getState();
        const aspId = appState.auth.aspNetUserId;
        let result = await PlayerAdService.getMyPlayerAds(aspId);
        let ads = result.filter(x => x.active);
        dispatch(stateController.setState({ playerAds: ads }));
        dispatch(stateController.setState({ loadingPlayerAds: false }));
    }

    public static goToPrevPosition() {
        return (dispatch, getState: () => AppState) => {
            let substate = getState().virtualSummit.createPlayerAd;
            let currentPositionIndex = substate.currentPositionIndex;
            let position = Object.keys(substate.positions)[substate.currentPositionIndex - 1];
            var currentPositionCode = Object.keys(substate.positions)[substate.currentPositionIndex];

            substate.positions[currentPositionCode] = substate.positions[currentPositionCode].filter(x => x.finished || x.id == null)

            let outdatedAds = substate.playerAds;
            let stillLooking = substate.outdatedAdsApprovedV2;

            let adsToRevert = stillLooking.filter(x => x.positionCode == position);
            let adsToKeep = stillLooking.filter(x => x.positionCode != position);
            dispatch(stateController.setState({
                currentPositionIndex: currentPositionIndex - 1,
                playerAds: [...outdatedAds, ...adsToRevert],
                outdatedAdsApprovedV2: adsToKeep,
                positions: { ...getState().virtualSummit.createPlayerAd.positions }
            }))
        }
    }

    public static goToNextPosition(redirect: (referer: string) => void) {
        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let currencyId = state.auth.currency.id;
            let currencyName = state.auth.currency.name;
            let { eventId } = state.auth;
            let subState = state.virtualSummit.createPlayerAd;
            let currentPositionIndex = subState.currentPositionIndex;
            var currentPositionCode = Object.keys(subState.positions)[subState.currentPositionIndex];

            if (state.auth.isAgentsVisible && !hasAgentFeature(state)) {
                dispatch(stateController.setState({ adsProcessing: true }))
                for (let key in subState.positions) {
                    if (subState.positions.hasOwnProperty(key)) {
                        let ads = subState.positions[key];
                        ads.forEach(ad => {
                            if (ad.isAgencyRecommended)
                                ad.isAgencyRecommended = true;
                        });
                    }
                }
                dispatch(stateController.setState({ positions: { ...subState.positions } }))
                // await AgencyOnboardingService.setUpAgencyFeatures();
                // dispatch(setupAgencyFeature());
                dispatch(stateController.setState({ adsProcessing: false }))
            }

            if (currentPositionIndex === Object.keys(subState.positions).length - 1) {
                let request: Array<CreatePlayerAdRequestV2> = new Array<CreatePlayerAdRequestV2>();
                for (var key of Object.keys(subState.positions)) {
                    let currentAds = subState.positions[key];
                    currentAds.forEach(current => {
                        if (current.toBuy && current.id != null && current.finished) {
                            request.push({
                                toAge: current.age ? current.age.max : null,
                                fromAge: current.age ? current.age.min : null,
                                grossSalary: current.grossSalary,
                                minHeight: current.height,
                                type: 1,
                                amount: current.buy,
                                positionId: getPositionIdByCode(key),
                                currencyId: currencyId,
                                isAgencyRecommendation: current.isAgencyRecommended,
                                preferredNationalityAreaId: current.preferredPlayerArea?.id,
                                playingStyleId: current.preferredPlayingStyle?.id,
                                isAnonymised: current.isAnonymiseRequirement,
                                isLeftFooted: false,
                            })
                        }

                        if (current.toLoan && current.id != null && current.finished) {
                            request.push({
                                toAge: current.age ? current.age.max : null,
                                fromAge: current.age ? current.age.min : null,
                                minHeight: current.height,
                                type: 2,
                                amount: current.loan,
                                positionId: getPositionIdByCode(key),
                                currencyId: currencyId,
                                isAgencyRecommendation: current.isAgencyRecommended,
                                preferredNationalityAreaId: current.preferredPlayerArea?.id, 
                                playingStyleId: current.preferredPlayingStyle?.id,
                                isAnonymised: false,
                                isLeftFooted: false,
                            })
                        }
                    });
                }

                dispatch(stateController.setState({ adsProcessing: true }))

                await PlayerAdService.createPlayerAds(request);
                await VirtualSummitService.setTransferInIsCompleted(state.auth.userId);

                const positions = request;
                let positionsText = '';

                positions.map((item, index) => {

                    let positionValueText = '';
                    let nationality = ''
                    let playingStyle = ''
                    if (item.type === 1) {
                        positionValueText += 'To buy';
                    }
                    if (item.type === 2) {
                        if (positionValueText.length > 0) {
                            positionValueText += ', '
                        }
                        positionValueText += 'To loan';
                    }
                    if (item.preferredNationalityAreaId) {
                        
                        const area = Selectors.getPreferredPlayerAreasList(getState()).find(x => x.id === item.preferredNationalityAreaId).name
                        nationality = `, ${area}`
                    }

                    if (item.playingStyleId) {

                        const stylesList = subState.playerAdInitialInfo.positionStyles.find(x =>x.positionId === item.positionId).playingStyles
                        const style = stylesList.find(s => s.id === item.playingStyleId).name
                        
                        playingStyle = `, ${style}`
                    }

                    let finalText = `${getPositionCodeById(item.positionId)}(${positionValueText}${nationality}${playingStyle}${item.isAgencyRecommendation ? ', Invited agents' : ''}, ${item.amount} ${currencyName}, ${item.isAnonymised ? ',  Anonymised Requirement' : ''})`;
                    positionsText += `${finalText}`;

                    const isLastPosition = Object.keys(positions).length - 1 === index
                    positionsText += isLastPosition ? '.' : ', '
                });
                if (request.length > 0) {
                    dispatch(userActivityInsert({
                        Message: `Launched Player Ad: ${positionsText}`,
                        PageName: 'Event [Details] ',
                        PageType: PageType.Events,
                        EventId: eventId,
                        ActionType: ActionType.LaunchedPlayerAd,
                    }));

                    const auth = state.auth
                    const isAgentsFreeTrialActive = hasAgentFeature(state)
                    const i = request[request.length - 1]

                    if (auth.isAgentsVisible) {
                        let message = '';
                        if (isAgentsFreeTrialActive && !i.isAgencyRecommendation) {
                            message = 'Transfers In Set Up. Unchecked Agents Invitation'
                        } else if (!isAgentsFreeTrialActive && i.isAgencyRecommendation) {
                            message = 'Transfers In Set Up. Activated Agency Trial'
                        }
                        else if (!isAgentsFreeTrialActive && !i.isAgencyRecommendation) {
                            message = 'Transfers In Set Up. Unchecked Agency Trial activation'
                        }

                        if (message) {
                            dispatch(userActivityInsert({
                                Message: message,
                                PageName: 'Event [Details]',
                                PageType: PageType.Events,
                                EventId: eventId
                            }));
                        }
                    }
                }

                dispatch(StepActions.setInCompleted());
                dispatch(stateController.setState({ adsProcessing: false }))
                dispatch(StepActions.goToNext(false, redirect));
            }
            else {
                dispatch(stateController.setState({ currentPositionIndex: currentPositionIndex + 1 }))
            }
        }
    }

    public static markLastAdAsFinished() {
        return (dispatch, getState: () => AppState) => {
            let currentSubState = getState().virtualSummit.createPlayerAd;
            let position = Object.keys(currentSubState.positions)[currentSubState.currentPositionIndex];
            currentSubState.positions[position][currentSubState.positions[position].length - 1].finished = true;

            dispatch(stateController.setState({ positions: { ...getState().virtualSummit.createPlayerAd.positions } }))
        }
    }

    public static onAdPropertiesChange(id: string, position: string, toBuy: boolean, toLoan: boolean, loan?: number, buy?: number, grossSalary?: number, isAgencyRecommended?: boolean) {
        return (dispatch, getState: () => AppState) => {
            let positions = getState().virtualSummit.createPlayerAd.positions;
            let ad = positions[position].find(x => x.id == id);
            ad.toBuy = toBuy;
            ad.toLoan = toLoan;
            ad.loan = loan;
            ad.buy = buy;
            ad.grossSalary = grossSalary;
            ad.isAgencyRecommended = isAgencyRecommended;

            let changedPositions = { ...getState().virtualSummit.createPlayerAd.positions }
            dispatch(stateController.setState({
                positions: changedPositions
            }))
            dispatch(Actions.validateAnonymiseRequirement(id, position, toBuy, buy, grossSalary))



        }
    }

    public static validateAnonymiseRequirement(id: string, position: string, toBuy: boolean, buy?: number, grossSalary?: number) {
        return (dispatch, getState: () => AppState) => {
            if (!toBuy) {
                dispatch(Actions.onAnonymiseRequirementChange(id, position, false))
            }
                        
            if (!!buy || !!grossSalary){                
                const criteria = Selectors.getPlayerAdAnonymisationCriteria(getState())          
                                
                if ((buy < criteria.transferFeeThreshold.price && grossSalary < criteria.grossSalaryThreshold.price) || 
                    (!buy && grossSalary < criteria.grossSalaryThreshold.price) ||
                    (!grossSalary && buy < criteria.transferFeeThreshold.price)
                ) {
                    dispatch(Actions.onAnonymiseRequirementChange(id, position, false))
                }
            } 
        }
    }

    public static onAgePropertyChanged(id: string, position: string, min: number, max: number) {
        return (dispatch, getState: () => AppState) => {
            let positions = getState().virtualSummit.createPlayerAd.positions;
            let ad = positions[position].find(x => x.id == id);

            let upd = (min == ageRule.min && max == ageRule.max) ? null : { min: min, max: max }
            ad.age = upd;

            let changedPositions = { ...getState().virtualSummit.createPlayerAd.positions }
            dispatch(stateController.setState({
                positions: changedPositions
            }))
        }
    }

    public static onHeightPropertyChanged(id: string, position: string, val: number) {
        return (dispatch, getState: () => AppState) => {
            let positions = getState().virtualSummit.createPlayerAd.positions;
            let ad = positions[position].find(x => x.id == id);
            ad.height = val == heightRule.min ? null : val;

            let changedPositions = { ...getState().virtualSummit.createPlayerAd.positions };
            dispatch(stateController.setState({
                positions: changedPositions
            }))
        }
    }

    public static onAnonymiseRequirementChange(id: string, position: string, value: boolean) {
        return (dispatch, getState: () => AppState) => {
            let positions = getState().virtualSummit.createPlayerAd.positions;
            let ad = positions[position].find(x => x.id == id);
            ad.isAnonymiseRequirement = value;

            let changedPositions = { ...getState().virtualSummit.createPlayerAd.positions };
            dispatch(stateController.setState({
                positions: changedPositions
            }))
        }
    }

    public static onPreferredPlayerAreaChanged(id: string, position: string, area: IdName) {
        return (dispatch, getState: () => AppState) => {
            let positions = getState().virtualSummit.createPlayerAd.positions;
            let ad = positions[position].find(x => x.id == id);
            ad.preferredPlayerArea = area;

            let changedPositions = { ...getState().virtualSummit.createPlayerAd.positions };
            dispatch(stateController.setState({
                positions: changedPositions
            }))
        }
    }

    public static onPreferredPlayingStyleChanged(id: string, position: string, playingStyle: IdName) {
        return (dispatch, getState: () => AppState) => {
            let positions = getState().virtualSummit.createPlayerAd.positions;
            let ad = positions[position].find(x => x.id == id);
            ad.preferredPlayingStyle = playingStyle;

            let changedPositions = { ...getState().virtualSummit.createPlayerAd.positions };
            dispatch(stateController.setState({
                positions: changedPositions
            }))
        }
    }

    public static disposeModalState() {
        return dispatch => {
            dispatch(stateController.setState({
                positions: {},
                currentPositionIndex: 0,
                currentStep: 1
            }))
        }
    }

    public static changeLoader() {
        return (dispatch, getState: () => AppState) => {
            let state = getState();
            let subState = state.virtualSummit.createPlayerAd;

            dispatch(stateController.setState({
                isLoading: !subState.isLoading
            }))
        }

    }

    public static loadPlayerAdInitialInfo() {
        return async (dispatch) => {
            const initialInfo = await PlayerAdService.getPlayerAdInitialInfo();
            
            if (!initialInfo) {
                return null
            }

            dispatch(stateController.setState({ playerAdInitialInfo: initialInfo}))
        }
    }

    public static onFindOutMoreClick() {
        return (dispatch) => {
            dispatch(userActivityInsert({
                Message: `Clicked Playing Style`,
                PageName: 'Create Player Ad',
                PageType: PageType.Events
            }))
            window.open('https://blog.transferroom.com/transferrooms-playing-styles-explained', '_blank');
        }
    }

    public static onPlayingStyleTooltipHover() {
        return async (dispatch) => {
            dispatch(userActivityInsert({
                Message: `Viewed Playing Style Tooltip`,
                PageName: 'Create Player Ad',
                PageType: PageType.Events
            }))
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.virtualSummit.createPlayerAd;
    public static getPlayerAds = (state: AppState) => Selectors.getRoot(state).playerAds
    public static getPlayerAdInitialInfo = (state: AppState) => Selectors.getRoot(state).playerAdInitialInfo;
    public static getPlayerAdAnonymisationCriteria = (state: AppState) => Selectors.getPlayerAdInitialInfo(state).anonymisationCriteria;
    public static getCurrentPositionIndex = (state: AppState) => Selectors.getRoot(state).currentPositionIndex;
    public static getCurrentPositionId = (state: AppState) => {
        const positionIndex = Selectors.getCurrentPositionIndex(state)
        const positionCode = Object.keys(Selectors.getRoot(state).positions)[positionIndex]

        return getPositionIdByCode(positionCode)

    }
    public static getPreferredPlayerAreasList = (state: AppState) => {
        const info = Selectors.getPlayerAdInitialInfo(state)
        if (!info) {
            return null
        }
        const areas = getPreferredPlayerAreas(info)
    
        return areas
    };
    public static getPreferredPlayingStylesList = (state: AppState) => {
        const info = Selectors.getPlayerAdInitialInfo(state)
        const positionId = Selectors.getCurrentPositionId(state)

        if (!info) {
            return null
        }
        
        const styles = getPreferredPlayingStyles(info, positionId)

        return styles
    };
    public static getPLayerAdsPositions = (state: AppState) => {
        const subState = Selectors.getPlayerAds(state)

        let playerAdsPositions = [];

        if (subState !== null) {
            playerAdsPositions = subState.map(x => x.positionCode);
        }

        let uniquePlayerAdsPositions = playerAdsPositions.filter((element, index) => {
            return playerAdsPositions.indexOf(element) === index;
        });

        return uniquePlayerAdsPositions
    }
}
const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    CreatePlayerAdState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};


