import axios from 'axios-config';
import {
  PlayerInterestReportListModel,
  PlayerInterestSearchParameters,
  FriendlyInterestReportListModel,
  FriendlyInterestSearchParameters
} from "./models";

export default class declareInterestReportService {
  private static axiosConfig = {
    headers: { 'Content-Type': 'application/json' },
  }

  public static async getPlayerInterestReport(searchParameters: PlayerInterestSearchParameters): Promise<PlayerInterestReportListModel> {
    const { data } = await axios.post(
      `api/AdminReports/GetPlayerInterestReport`,
      searchParameters,
      this.axiosConfig
    );
    return data;
  }

  public static async getFriendlyInterestReport(searchParameters: FriendlyInterestSearchParameters): Promise<FriendlyInterestReportListModel> {
    const { data } = await axios.post(
      `api/AdminReports/GetFriendlyInterestReport`,
      searchParameters,
      this.axiosConfig
    );
    return data;
  }
}
