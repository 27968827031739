import { BehaviorSubject } from 'rxjs';
import getColumns from './player-grid-columns';

const STORAGE_KEY_VERSION = 2;
export const STORAGE_KEY = 'customColumnsConfig' + STORAGE_KEY_VERSION;

var allPlayerColumns = getColumns();

/* Column presets names */
const PLAYER_COLUMNS_PRESET = 'PLAYER_COLUMNS_PRESET';

/* ColumnPresets sources */
const getColumnPresetsSource = () => {
    return {
        [PLAYER_COLUMNS_PRESET]: allPlayerColumns,
    };
};

var defaultPresetCollection = {};

/* Default Column presets collections */
const getDefaultPresetCollection = () => {
    const columnPresetsSource = getColumnPresetsSource();
    return {
        [PLAYER_COLUMNS_PRESET]: [
            { id: 'star' },
            // { id: allPlayerColumns.similarityPercentage.id },
            { id: 'name' },
            { id: 'club', checked: true },
            { id: 'position', checked: true },
            { id: 'age', checked: true },
            { id: 'height' },
            { id: 'foot' },
            { id: 'contractExpiry', checked: true },
            // { id: allPlayerColumns.marketValue.id },
            // { id: allPlayerColumns.gsnPerformance.id },
            // { id: allPlayerColumns.gsnPotential.id },
            { id: allPlayerColumns.faPlayerPoint.id },
            { id: allPlayerColumns.minutesPlayed.id },
            { id: allPlayerColumns.scoringContribution.id },
            { id: allPlayerColumns.goals.id },
            { id: allPlayerColumns.assists.id },
            { id: allPlayerColumns.xG.id },
            { id: allPlayerColumns.xA.id },
            { id: allPlayerColumns.successfulDribbles.id },
            { id: allPlayerColumns.shots.id },
            { id: allPlayerColumns.shotsOnTarget.id },
            { id: allPlayerColumns.passesAccuracy.id },
            { id: allPlayerColumns.forwardPassesAccuracy.id },
            { id: allPlayerColumns.passesToFinalThirdAccuracy.id },
            { id: allPlayerColumns.longPassesAccuracy.id },
            { id: allPlayerColumns.interceptions.id },
            { id: allPlayerColumns.aerialDuelsWon.id },
            { id: allPlayerColumns.defensiveDuelsWon.id },
            { id: allPlayerColumns.shotsAgainst.id },
            { id: allPlayerColumns.saves.id },
            { id: allPlayerColumns.concededGoals.id },
            { id: allPlayerColumns.xCG.id },

            { id: 'transferStatus' },
            { id: 'declareInterest' },
            { id: 'chevron' },
        ].map(({ id, checked }) => ({
            ...columnPresetsSource[PLAYER_COLUMNS_PRESET][id],
            checked,
        })),
    };
};

class CustomColumnsService {
    static instance = null;
    allPresets = {};
    columnsConfig = {};
    columnsChange = {};

    constructor() {
        this.refresh();
    }

    refresh() {
        allPlayerColumns = getColumns();
        defaultPresetCollection = {};
        defaultPresetCollection = getDefaultPresetCollection();
        let initialPresetCollection =
            this._getStoredColumnsConfig() || defaultPresetCollection;
        if (
            initialPresetCollection[PLAYER_COLUMNS_PRESET].length !==
            defaultPresetCollection[PLAYER_COLUMNS_PRESET].length
        ) {
            const arr = [...initialPresetCollection[PLAYER_COLUMNS_PRESET]];
            for (let i = 0; i < arr.length; i++) {
                const iA = arr[i];
                var dA = defaultPresetCollection[PLAYER_COLUMNS_PRESET].find(
                    x => x.id === iA.id,
                );
                if (!dA) {
                    initialPresetCollection[PLAYER_COLUMNS_PRESET].splice(i, 1);
                }
            }

            for (
                let i = 0;
                i < defaultPresetCollection[PLAYER_COLUMNS_PRESET].length;
                i++
            ) {
                const dA = defaultPresetCollection[PLAYER_COLUMNS_PRESET][i];
                var iA = initialPresetCollection[PLAYER_COLUMNS_PRESET].find(
                    x => x.id === dA.id,
                );
                if (!iA) {
                    initialPresetCollection[PLAYER_COLUMNS_PRESET].splice(
                        i,
                        0,
                        dA,
                    );
                }
            }
        }
        const ia = initialPresetCollection[PLAYER_COLUMNS_PRESET];
        initialPresetCollection[PLAYER_COLUMNS_PRESET] = ia.sort((a, b) => {
            var oA = defaultPresetCollection[PLAYER_COLUMNS_PRESET].find(
                x => x.id === a.id,
            );
            var oB = defaultPresetCollection[PLAYER_COLUMNS_PRESET].find(
                x => x.id === b.id,
            );
            var iA = oA
                ? defaultPresetCollection[PLAYER_COLUMNS_PRESET].indexOf(oA)
                : -1;
            var iB = oA
                ? defaultPresetCollection[PLAYER_COLUMNS_PRESET].indexOf(oB)
                : -1;
            return iA < iB ? -1 : iA > iB ? 1 : 0;
        });

        // AD: commented, so that it was possible to reload columns with other translations
        // on fly, but not to keep initial translation
        // if (!CustomColumnsService.instance) {
        [PLAYER_COLUMNS_PRESET].forEach(presetName => {
            const initialCurrentPresetCollection =
                initialPresetCollection[presetName] ||
                defaultPresetCollection[presetName];

            this.columnsConfig[presetName] = [];
            this.columnsChange[presetName] = new BehaviorSubject(
                this.allPresets[presetName],
            );
            this.allPresets[presetName] = [...initialCurrentPresetCollection];
            this.updateColumnPreset(
                presetName,
                this.allPresets[presetName].filter(
                    ({ checked, mandatory }) => checked || mandatory,
                ),
            );
        });
        CustomColumnsService.instance = this;
        //}

        return CustomColumnsService.instance;
    }

    updateColumnPreset(presetName, columnsSettings) {
        if (this.columnsConfig[presetName]) {
            this.columnsConfig[presetName] = columnsSettings.map(
                columnConfig => ({ ...columnConfig }),
            );
            this._storeColumnsConfig();
            this.columnsChange[presetName].next(this.columnsConfig[presetName]);
        }
    }

    getAllColumns(presetName) {
        if (this.allPresets[presetName]) {
            return this.allPresets[presetName];
        } else {
            return [];
        }
    }

    getAllColumnsUI(presetName) {
        allPlayerColumns = getColumns();
        defaultPresetCollection = getDefaultPresetCollection();

        const initialPresetCollection =
            this._getStoredColumnsConfig() || defaultPresetCollection;
        const initialCurrentPresetCollection =
            initialPresetCollection[presetName] ||
            defaultPresetCollection[presetName];
        this.allPresets[presetName] = [...initialCurrentPresetCollection];

        if (this.allPresets[presetName]) {
            return this.allPresets[presetName];
        } else {
            return [];
        }
    }

    _storeColumnsConfig() {
        const objectToStore = Object.keys(this.allPresets).reduce(
            (result, key) =>
                Object.assign(result, {
                    [key]: this.allPresets[key]
                        .map(el => {
                            let part = this.columnsConfig[key].find(
                                ({ id }) => id === el.id,
                            );
                            let intermediate = {
                                ...el,
                                ...part,
                            };
                            return {
                                id: intermediate.id,
                                sortBy: intermediate.sortBy,
                                checked:
                                    part !== undefined || intermediate.defaultUK
                                        ? intermediate.checked
                                        : false,
                            };
                        })
                        .filter(x => x.id),
                }),
            {},
        );

        window.localStorage.setItem(STORAGE_KEY, JSON.stringify(objectToStore));
    }

    _getStoredColumnsConfig() {
        const storedConfigString = window.localStorage.getItem(STORAGE_KEY);
        const columnPresetsSource = getColumnPresetsSource();

        if (storedConfigString) {
            const storedConfigObject = {
                PLAYER_COLUMNS_PRESET: JSON.parse(storedConfigString)[
                    PLAYER_COLUMNS_PRESET
                ].filter(x => x.id),
            };
            return Object.keys(storedConfigObject).reduce(
                (result, el) =>
                    Object.assign(result, {
                        [el]: storedConfigObject[el].map(
                            ({ id, checked, sortBy }) => ({
                                ...columnPresetsSource[el][id],
                                checked,
                                sortBy,
                            }),
                        ),
                    }),
                {},
            );
        } else {
            return null;
        }
    }
}

const customColumnsService = new CustomColumnsService();

export {
    // Preset names
    PLAYER_COLUMNS_PRESET,
    // Service instance
    customColumnsService,
};
