import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import PitchService from "api/agency/agent/pitch/pitch.service";
import { PlayerAdTypeEnumModel, PlayerAdModel, PlayerPitchOpportunity } from 'api/agency/agent/landing/agency-dashboard';
import { createSelector } from 'reselect';
import { validateFee} from 'utils/number-utils';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { Actions as DashboardActions } from './dashboard.controller'
import historyAccessor from 'history-accessor';
import { agencyPaths } from 'routes/paths'
import { PageType } from 'constants/enums';

class State {
    isLoading: boolean
    isMirrorModalOpen: boolean;
    isModalOpen: boolean
    isPitchModalOpen: boolean;
    players: Array<PlayerPitchOpportunity>;
    selectedPlayerIds: Array<number>;
    modalState: ModalState;
    pitchModalState: PitchModalState;
}

class ModalState {
    currentIndex: number;
    sentPitches: Array<number>;
    createdPitchesCount?:number;
    processingNotInterested: Array<number>;
}

class PitchModalState {
    ad: PlayerAdModel;
    grossSalary: string;
    message: string;
    playerName: string;
    clubName: string;
    processing: boolean;
    playerId: number;
}

const defaultState: State = {
    isLoading: false,
    isMirrorModalOpen: false,
    isModalOpen: false,
    isPitchModalOpen: false,
    players: [],
    selectedPlayerIds: [],
    modalState: null,
    pitchModalState: null
}

const stateController = new StateController<State>(
    "AGENCY/LANDING_PAGE/PITCH_OPPORTUNITIES_VERIFIED",
    defaultState
);

class Actions {

    public static openMirrorModal() {
        return (dispatch, getState: () => AppState) => {
            const subState = getState().agency.landingPage.pitchOpportunitiesVerirfied.players;
            if (subState.length == 0) {
                historyAccessor.push(agencyPaths.pitchPage)
            }
            else {
                dispatch(stateController.setState({ isMirrorModalOpen: true }))
            }
        }
    }

    public static closeMirrorModal() {
        return (dispatch) => {
            dispatch(stateController.setState({ isMirrorModalOpen: false }))
        }
    }

    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState))
        }
    }

    public static openModal() {
        return (dispatch, getState: () => AppState) => {
            const subState = getState().agency.landingPage.pitchOpportunitiesVerirfied;
            const selectedNew = subState.selectedPlayerIds.length > 0 ? [...subState.selectedPlayerIds] : subState.players.map(x => x.playerId);
            dispatch(stateController.setState({
                isModalOpen: true,
                modalState: { ...subState.modalState, currentIndex: 0, createdPitchesCount:0, sentPitches: [], processingNotInterested: [] },
                selectedPlayerIds: selectedNew
            }))

            const player = subState.players.find(item => item.playerId == selectedNew[0])

            if (player) {
                dispatch(userActivityInsert({
                    PageName: 'Agency Home [Pitch opportunities]',
                    Message: 'Opened Pitch List',
                    ClubId: player.playerSquad.id,
                    PlayerId: player.playerId,
                    PageType: PageType.AgencyHome,
                }))
            }
        }
    }

    public static closeModal() {
        return (dispatch, getState: () => AppState) => {
            const subState = getState().agency.landingPage.pitchOpportunitiesVerirfied;
            let playersNew = [...subState.players];
            playersNew.forEach(element => {
                element.opportunityAds = element.opportunityAds.filter(ad => !subState.modalState.sentPitches.some(x => x == ad.id));
            });

            playersNew = playersNew.filter(x => x.opportunityAds.length > 0)

            const index = subState.modalState.currentIndex;

            dispatch(Actions.trackByIndex(index, 'Done'))
            dispatch(stateController.setState({ isModalOpen: false, selectedPlayerIds: [], players: playersNew }))
        }
    }

    public static trackByIndex(index, message) {
        return (dispatch, getState: () => AppState) => {
            const subState = getState().agency.landingPage.pitchOpportunitiesVerirfied;
            const selectedPlayerIds = subState.selectedPlayerIds;
            const player = subState.players.find((item) => item.playerId == selectedPlayerIds[index])

            if (player) {
                dispatch(userActivityInsert({
                    PageName: 'Agency Home [Pitch opportunities]',
                    Message: message,
                    PlayerId: player.playerId,
                    ClubId: player.playerSquad.id,
                    PageType: PageType.AgencyHome,
                }))
            }
        }
    }

    public static openPitchModal(ad: PlayerAdModel, playerId: number, playerName: string, clubName: string) {
        return (dispatch, getState: () => AppState) => {
            const subState = getState().agency.landingPage.pitchOpportunitiesVerirfied;
            let fee = 0;
            const player = subState.players.find(x => x.playerId == playerId);
            if (player)
                fee = player.recomendedAdFee[ad.id];
            dispatch(stateController.setState({
                isPitchModalOpen: true, pitchModalState: {
                    ...subState.pitchModalState,
                    ad: ad, grossSalary: fee.toString(), message: "", playerName: playerName, clubName: clubName, playerId: playerId
                }
            }))

            if (player) {
                dispatch(userActivityInsert({
                    PageName: 'Agency Home [Pitch opportunities]',
                    PlayerId: player.playerId,
                    Message: `Make a pitch: ${Actions.getPitchMessage(player, ad, fee.toString())}`,
                    ClubId: player.playerSquad.id,
                    PageType: PageType.AgencyHome,
                }))
            }

        }
    }

    public static setFee(fee: any) {
        return (dispatch, getState: () => AppState) => {
            const subState = getState().agency.landingPage.pitchOpportunitiesVerirfied;
            const formatted = validateFee(fee);

            dispatch(stateController.setState({ isModalOpen: true, pitchModalState: { ...subState.pitchModalState, grossSalary: formatted } }))
        }
    }

    public static setMessage(message: string) {
        return (dispatch, getState: () => AppState) => {
            const subState = getState().agency.landingPage.pitchOpportunitiesVerirfied;
            dispatch(stateController.setState({ isModalOpen: true, pitchModalState: { ...subState.pitchModalState, message: message } }))
        }
    }

    public static sendPitch() {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.landingPage.pitchOpportunitiesVerirfied;

            try {
                dispatch(stateController.setState({ pitchModalState: { ...subState.pitchModalState, processing: true } }));
                const ad = subState.pitchModalState.ad;
                const playerId = subState.pitchModalState.playerId;
                const player = subState.players.find(item => item.playerId === playerId)
                const grossSalary = parseInt(subState.pitchModalState.grossSalary.replace(/\D/g, ''))

                await PitchService.pitch(
                    subState.pitchModalState.ad.id,
                    subState.pitchModalState.playerId,
                    grossSalary,
                    subState.pitchModalState.message
                );

                dispatch(DashboardActions.refreshCounters())
                dispatch(stateController.setState({
                    modalState: { ...subState.modalState, sentPitches: [...subState.modalState.sentPitches, subState.pitchModalState.ad.id], createdPitchesCount: subState.modalState.sentPitches.length + 1},
                    isPitchModalOpen: false,
                    pitchModalState: null
                }));

                if (player && ad) {
                    dispatch(userActivityInsert({
                        PageName: 'Agency Home [Pitch opportunities]',
                        PlayerId: player.playerId,
                        Message: `Pitched Player: ${Actions.getPitchMessage(player, ad, grossSalary)}`,
                        ClubId: player.playerSquad.id,
                        PageType: PageType.AgencyHome,
                    }))
                }
            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState(prevState => ({ ...prevState, pitchModalState: { ...prevState.pitchModalState, processing: false } })));
            }
        }
    }

    public static closeOrGoNextDependOnCurrentCount() {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.landingPage.pitchOpportunitiesVerirfied;
            if (subState.selectedPlayerIds.length == 0) {
                dispatch(this.closeModal());
                return;
            }
            else {
                const currentIndexNew = subState.modalState.currentIndex;
                if (subState.selectedPlayerIds[currentIndexNew] == null) {
                    dispatch(stateController.setState({ modalState: { ...subState.modalState, currentIndex: currentIndexNew - 1 } }))
                }
            }
        }
    }

    public static notInterested(playerId: number, adId: number) {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.landingPage.pitchOpportunitiesVerirfied;

            try {
                dispatch(stateController.setState({ modalState: { ...subState.modalState, processingNotInterested: [...subState.modalState.processingNotInterested, adId] } }));
                let playersNew = [...subState.players];
                let selectedNew = [...subState.selectedPlayerIds];
                const player = playersNew.find(x => x.playerId == playerId);
                const ads = player.opportunityAds.find(x => x.id == adId)

                let perfomNext = false;

                if (player) {
                    player.opportunityAds = player.opportunityAds.filter(x => x.id != adId);
                    if (player.opportunityAds.length == 0) {
                        playersNew = playersNew.filter(x => x.playerId != playerId);
                        selectedNew = selectedNew.filter(x => x != playerId);
                        perfomNext = true;
                    }

                    await PitchService.notInterestedv2(adId, playerId);

                    dispatch(userActivityInsert({
                        PageName: 'Agency Home [Pitch opportunities]',
                        PlayerId: player.playerId,
                        Message: `Dismissed: ${Actions.getPitchMessage(player, ads, player.recomendedAdFee[ads.id])}`,
                        ClubId: player.playerSquad.id,
                        PageType: PageType.AgencyHome,
                    }))
                }

                dispatch(stateController.setState({
                    players: playersNew,
                    selectedPlayerIds: selectedNew,
                }));
                if (perfomNext) {
                    dispatch(Actions.closeOrGoNextDependOnCurrentCount())
                }
                dispatch(DashboardActions.refreshCounters())
            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    modalState: {
                        ...prevState.modalState,
                        processingNotInterested: subState.modalState.processingNotInterested.filter(x => x != adId),
                    },
                })));
            }

        }
    }

    public static getPitchMessage(player: PlayerPitchOpportunity, ads: PlayerAdModel, fee: string | number) {
        fee = fee || 0;
        return `${ads.createdBySquad.name}, ${player.positionCode}, ${ads.type == PlayerAdTypeEnumModel.Buy ? 'To Buy' : 'To Loan'}, ${ads.currency.sign}${fee}${ads.type == PlayerAdTypeEnumModel.Buy ? '/yr' : '/mo'}`
    }

    public static closePitchModal() {
        return (dispatch, getState: () => AppState) => {
            const subState = getState().agency.landingPage.pitchOpportunitiesVerirfied;

            const ad = subState.pitchModalState.ad;
            const playerId = subState.pitchModalState.playerId;
            const player = subState.players.find(item => item.playerId === playerId)

            if (player && ad) {
                const grossSalary = parseInt(subState.pitchModalState.grossSalary.replace(/\D/g, ''))
                dispatch(userActivityInsert({
                    PageName: 'Agency Home [Pitch opportunities]',
                    PlayerId: player.playerId,
                    Message: `Cancelled pitch: ${Actions.getPitchMessage(player, ad, grossSalary)}`,
                    ClubId: player.playerSquad.id,
                    PageType: PageType.AgencyHome,
                }))
            }

            dispatch(stateController.setState({ isPitchModalOpen: false }))
        }
    }

    public static setPlayers(players: Array<PlayerPitchOpportunity>) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ players: players }))
        }
    }

    public static togglePlayerSelection(playerId: number) {
        return (dispatch, getState: () => AppState) => {
            const subState = getState().agency.landingPage.pitchOpportunitiesVerirfied;
            let newSelected = [...subState.selectedPlayerIds];
            const player = subState.players.find((item) => item.playerId === playerId)
            let message: string;

            if (newSelected.some(x => x == playerId)) {
                newSelected = newSelected.filter(x => x != playerId)
                message = 'Unselected Player'
            }
            else {
                newSelected = [...newSelected, playerId];
                message = 'Selected Player'
            }

            dispatch(stateController.setState({ selectedPlayerIds: newSelected }))

            dispatch(userActivityInsert({
                PageName: 'Agency Home [Pitch opportunities]',
                PlayerId: playerId,
                Message: message,
                ClubId: player.playerSquad.id,
                PageType: PageType.AgencyHome,
            }))
        }
    }

    public static nextIndex() {
        return (dispatch, getState: () => AppState) => {
            const subState = getState().agency.landingPage.pitchOpportunitiesVerirfied;
            if (subState.modalState.currentIndex + 1 < subState.selectedPlayerIds.length) {
                const nextIndex = ++subState.modalState.currentIndex

                dispatch(stateController.setState({ modalState: { ...subState.modalState, currentIndex: nextIndex } }))
                dispatch(Actions.trackByIndex(nextIndex, 'Opened Next Pitch List'))
            }
        }
    }

    public static prevIndex() {
        return (dispatch, getState: () => AppState) => {
            const subState = getState().agency.landingPage.pitchOpportunitiesVerirfied;
            if (subState.modalState.currentIndex > 0) {
                const prevIndex = --subState.modalState.currentIndex;

                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    modalState: { ...prevState.modalState, currentIndex: prevIndex }
                })));

                dispatch(Actions.trackByIndex(prevIndex, 'Opened Previous Pitch List'))
            }
        }
    }

    public static load() {
        return async (dispatch) => {
            dispatch(stateController.setState({ isLoading: true }));
            dispatch(stateController.setState({ isLoading: false, }));
        }
    }
}

export class Selectors {
    public static getLandingPage = (state: AppState) => state.agency.landingPage
    public static getPitchOpportunitiesVerified = (state: AppState) => Selectors.getLandingPage(state).pitchOpportunitiesVerirfied;
    public static getVerifiedPlayersSelectedToPitchCount = (state: AppState) => Selectors.getLandingPage(state).pitchOpportunitiesVerirfied.selectedPlayerIds.length;
    public static getVerifiedPlayersSelectedToPitch = (state: AppState) => Selectors.getLandingPage(state).pitchOpportunitiesVerirfied.selectedPlayerIds;
    public static getVerifiedPlayersPitchesCount = (state: AppState) => Selectors.getLandingPage(state).dashboard.dashboard.countOfPitchOpportunities;
    public static getVerifiedPlayers = createSelector([Selectors.getPitchOpportunitiesVerified], (i) => i.players);
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    stateController as Controller
};
