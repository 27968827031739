import _ from 'lodash'
import { AppState } from 'root.reducer';
import config from 'config'
import { StateController } from 'utils/action-declaration';
import { SearchItemType, SuggestionItem } from 'api/search/model/suggestion-item'
import { getCancelTokenSource } from 'axios-config'
import SearchService from 'api/search/search.clubside.service'
import { Actions as GridController, GridToggleState, Selectors as GridSelectors } from './grid.controller'
import { SuggestionActivityService } from './autosuggest.activity.service'
import { PlayerActivityService } from './player-activity.service';
import { Actions as FilterActions, SearchMode, Selectors as FilterSelectors } from './filter.controller'

export enum Tab {
    All = 1,
    Players = 2,
    Agents = 3,
    Clubs = 4
}
class SearchAutosuggestState {
    keyword: string;
    isLoading: boolean;
    wasLoaded: boolean;
    items: SuggestionItem[];
    activeTab: Tab;
}

const defaultState: SearchAutosuggestState = {
    keyword: '',
    items: [],
    isLoading: false,
    wasLoaded: false,
    activeTab: Tab.All
}

const stateController = new StateController<SearchAutosuggestState>(
    'PLAYER_SEARCH_V2/AUTOSUGGEST',
    defaultState
)

class Actions {

    public static createDebounce = () => _.debounce(
        (dispatch, getState: () => AppState) => {
            const tabKey = GridSelectors.getGridState(getState());
            if(tabKey == GridToggleState.Search)
                dispatch(FilterActions.setSearchModeToFiltered())
            dispatch(Actions.fetchSuggestions());
        },
        1000)

    public static cancelToken = null
    public static fetchDebounced = Actions.createDebounce()

    public static onKeywordChange(keyword: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ keyword }))

            if (keyword.length > 0) {
                Actions.fetchDebounced(dispatch, getState)
            } else {
                dispatch(Actions.onKeywordClear())
            }
        }
    }

    public static setKeywordWithGridRefresh(keyword: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ keyword }))
            dispatch(GridController.resetPage())
            dispatch(GridController.refresh())
        }
    }

    public static setKeyword(keyword: string) {
        return dispatch => {
            dispatch(stateController.setState({ keyword }))
        }
    }

    public static onKeywordClearWithoutRefresh() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ keyword: '', wasLoaded: false, isLoading: false, items: [] }))
            Actions.fetchDebounced.cancel()
            if (Actions.cancelToken) { Actions.cancelToken.cancel() }
            Actions.cancelToken = null;
            Actions.fetchDebounced = Actions.createDebounce()
        }
    }

    public static onKeywordClear() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.onKeywordClearWithoutRefresh())
            dispatch(GridController.resetPage())
            dispatch(Actions.setDefaultTab())
            dispatch(GridController.refresh())
            dispatch(FilterActions.loadSearchSuggestionSubsets())

            function processSearchModeReaction() {
                // Need to handle scenario when user clear search keyword while in In Filtered State
                // See: https://transferroom.visualstudio.com/TransferRoom/_wiki/wikis/TransferRoom.wiki/994/Player-Search-V3
                if (FilterSelectors.isSearchModeInFilteredState(getState())) {
                    // If user have any other filters active, then need to reSave latest filterSet to DB
                    if (FilterSelectors.isFiltersInActiveState(getState())) {
                        dispatch(FilterActions.saveLatestFilterSet())
                    }
                    // Else if there are no other active filters and no search subsets selected except keyword, than just move to Default SearchMode state (NOT Filtered)
                    const isSubsetSelected = FilterSelectors.getRoot(getState()).searchSubset
                    const isFiltersInActiveState = FilterSelectors.isFiltersInActiveState(getState())
                    if (!isSubsetSelected && !isFiltersInActiveState) {
                        dispatch(FilterActions.setSearchModeToDefault())
                    }
                }
            }
            processSearchModeReaction()
        }
    }



    public static fetchSuggestions() {
        return async (dispatch, getState: () => AppState) => {
            const state = getState();
            const keyword = state.newPlayerSearch.search.keyword
            const showOnlyClubs = FilterSelectors.getClubAgentsFilter(state).showOnlyClubs
            const showOnlyAgencies = FilterSelectors.getClubAgentsFilter(state).showOnlyTrustedAgents
            const isSimilarity = GridSelectors.isSimilarity(state)
            const isInShortList = GridSelectors.isInShortList(state)
            const playerSet = (FilterSelectors.getRoot(state).searchSubset || {}).playerSet || null

            if (Actions.cancelToken) {
                Actions.cancelToken.cancel()
            }

            Actions.cancelToken = getCancelTokenSource();

            try {
                dispatch(stateController.setState({ isLoading: true }))
                dispatch(GridController.resetPage());
                dispatch(GridController.refresh());

                // if (FilterSelectors.getRoot(getState()).searchMode === SearchMode.Filtered) {
                //     dispatch(FilterActions.saveLatestFilterSet())
                // }

                let data = await SearchService.getSuggestions(keyword, showOnlyClubs, showOnlyAgencies, isSimilarity, isInShortList, playerSet, Actions.cancelToken.token)

                if (data) {
                    dispatch(stateController.setState({ wasLoaded: true, items: data }));

                    if (!isSimilarity) {
                        dispatch(SuggestionActivityService.searchValue(keyword, isInShortList))
                    }
                }

            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState({ isLoading: false }))
            }
        }
    }

    public static fetchSuggestionsSilentAndNoRefresh() {
        return async (dispatch, getState: () => AppState) => {
            const state = getState();
            const keyword = state.newPlayerSearch.search.keyword
            const showOnlyClubs = FilterSelectors.getClubAgentsFilter(state).showOnlyClubs
            const showOnlyAgencies = FilterSelectors.getClubAgentsFilter(state).showOnlyTrustedAgents
            const isSimilarity = GridSelectors.isSimilarity(state)
            const isInShortList = GridSelectors.isInShortList(state)
            const playerSet = (FilterSelectors.getRoot(state).searchSubset || {}).playerSet || null

            if (!keyword.trim()) return;

            if (Actions.cancelToken)
                Actions.cancelToken.cancel()

            Actions.cancelToken = getCancelTokenSource();

            try {
                const data = await SearchService.getSuggestions(keyword, showOnlyClubs, showOnlyAgencies, isSimilarity, isInShortList, playerSet, Actions.cancelToken.token)

                if (data) {
                    dispatch(stateController.setState({ wasLoaded: true, items: data }))
                }
            } catch (e) {
                console.error(e)
            }
        }
    }

    public static disposeState() {
        return dispatch => {
            dispatch(stateController.setState({ isLoading: false, wasLoaded: false, items: [], activeTab: Tab.All }))
        }
    }

    public static onPlayerSelected(playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            const player = Selectors.getSuggestionItem(getState())(playerId, SearchItemType.Player)

            if (GridSelectors.isSimilarity(getState())) {
                dispatch(GridController.initSimilarity(playerId, player.currentClubId ? player.currentClubId : null));
                dispatch(Actions.onKeywordClearWithoutRefresh())
            } else {
                window.open('/profile/' + playerId, '_blank');
                await dispatch(FilterActions.saveLatestFilterSet(player.title));
                dispatch(Actions.onKeywordClear())
                const { currentClubId, agencyId } = player;
                dispatch(SuggestionActivityService.selectPlayer(player.id, player.title, currentClubId, currentClubId ? null : agencyId));
            }
        }
    }

    public static onAgencySelected(agencyId: number) {
        return async (dispatch, getState: () => AppState) => {
            const agency = Selectors.getSuggestionItem(getState())(agencyId, SearchItemType.Agency);

            // window.open('/agency-profile/' + agencyId, '_blank');
            // window.open(`${config.profileWebAppUrl}/agency-profile/${encodeURIComponent(agency.title)}`);
            window.open(`/agency-profile/${encodeURIComponent(agency.title)}`, '_blank');
            await dispatch(FilterActions.saveLatestFilterSet(agency.title));
            dispatch(Actions.onKeywordClear());
            dispatch(SuggestionActivityService.selectAgency(agency.id, agency.title))
        }
    }

    public static setActiveTab = (activeTab: Tab) => {
        return (dispatch) => {
            dispatch(stateController.setState({ activeTab }))
            dispatch(SuggestionActivityService.selectTab(activeTab))
        }
    }

    public static setDefaultTab = () => {
        return (dispatch, getState: () => AppState) => {
            const clubAgentsFilter = FilterSelectors.getClubAgentsFilter(getState())
            const activeTab = clubAgentsFilter.showOnlyClubs ? Tab.Clubs : clubAgentsFilter.showOnlyTrustedAgents ? Tab.Agents : Tab.All;
            dispatch(stateController.setState({ activeTab }))
        }
    }

    public static onReleaseListTooltipHover = (playerId: number) => {
        return async (dispatch, getState: () => AppState) => {
            const item = Selectors.getSuggestionItem(getState())(playerId, SearchItemType.Player);
            const gridState = GridSelectors.getGridState(getState());
            dispatch(PlayerActivityService.openReleaseListTootip(
                playerId,
                item.currentClubId,
                item.agencyId,
                gridState,
            ))
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.newPlayerSearch.search
    public static getSuggestionItem = (state: AppState) => (id, type: SearchItemType) =>
        state.newPlayerSearch.search.items.find(item => item.id === id && item.searchItemType === type)
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    SearchAutosuggestState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};