import { AppState } from "root.reducer";
import { StateController } from "utils/action-declaration";
import SoldPlayersService from "api/admin/sold-players/sold-players.service";
import { ReportModel, SoldPlayerModel, FilterOptions } from "api/admin/sold-players/models";

class SoldPlayersState {
  isLoading: boolean;
  reports: Array<ReportModel>;
  players: Array<SoldPlayerModel>;
  filterOptions: FilterOptions;
}

const defaultFilterOptions = {
  report: null,
  playerName: ''
}

const defaultState: SoldPlayersState = {
  isLoading: false,
  reports: [],
  players: [],
  filterOptions: defaultFilterOptions
}

const stateController = new StateController<SoldPlayersState>(
  'ADMIN_V2/SOLD_PLAYERS',
  defaultState
);

class Actions {

  public static dispose() {
    return (dispatch) => {
      dispatch(stateController.setState(defaultState))
    }
  }

  public static loadInitialData = () => {
    return async (dispatch) => {
      try {
        dispatch(stateController.setState({ isLoading: true }));
        const reports = await SoldPlayersService.getSoldPlayerReportsList();

        if (reports.length) {
          dispatch(stateController.setState({ reports: reports }));
          await dispatch(Actions.onChangeReport(reports[0].value));
        }

      } catch (err) {
        console.error(err);
      } finally {
        dispatch(stateController.setState({ isLoading: false }));
      }
    };
  };

  public static GetSoldPlayerReport = (reportDateStamp: string) => {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState({ isLoading: true }));
        const { rows } = await SoldPlayersService.getSoldPlayersReport(reportDateStamp);
        const playersExtended = !rows ? [] : rows.map((row, index) => ({...row, key: `${index}_${row.playerName}`}));

        dispatch(stateController.setState({ players: playersExtended }));
      } catch (err) {
        console.error(err);
      } finally {
        dispatch(stateController.setState({ isLoading: false }));
      }
    };
  };

  public static onChangeReport(value: string | null) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        filterOptions: {
          ...prevState.filterOptions,
          report: value,
          playerName: ''
        }
      })));

      dispatch(Actions.GetSoldPlayerReport(value));
    }
  }
  public static onChangePlayerName(value: string) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        filterOptions: {
          ...prevState.filterOptions,
          playerName: value
        }
      })));
    }
  }
}

class Selectors {
  public static selectState = (state: AppState) => state.admin.soldPlayers;
  public static isLoading = (state: AppState): boolean => Selectors.selectState(state).isLoading;
  public static getReports = (state: AppState) => state.admin.soldPlayers.reports;
  public static getPlayers = (state: AppState) => state.admin.soldPlayers.players;
  public static getFilterOptions = (state: AppState) => state.admin.soldPlayers.filterOptions;
  public static selectFilteredPlayers = (state: AppState) => {
    const { playerName } = Selectors.getFilterOptions(state);
    return Selectors.getPlayers(state)
      .filter(item => !playerName.length ||
        (item.playerName && item.playerName.toLowerCase() || "").toLowerCase().includes(playerName.toLowerCase()));
  };
}

const reducer = stateController.getReducer();

export {
  reducer as Reducer,
  SoldPlayersState as State,
  Actions as Actions,
  Selectors as Selectors,
  stateController as Controller
};
