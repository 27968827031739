import * as redux from 'redux';
import * as clubController from './club/root.controller';
import * as agentController from './agent/root.controller';
import * as landingPage from './authorized-agent/home-page/redux/root.controller';
import * as pitchInsight from './authorized-agent/pitch-page/redux/agency-pith-insight.controller'
import * as commonPitch from './authorized-agent/pitch-page/redux/common.controller'
import * as plusPitch from './authorized-agent/pitch-page/redux/agency-plus-pitch.controller';
import * as customPitch from './authorized-agent/pitch-page/redux/custom-pitch/root.reducer';
import * as playerSearch from './authorized-agent/player-search/redux/root.controller';
import * as publicProfile from './authorized-agent/public-profile/redux/root.reducer';
import * as ltaUpgradeModal from './common/low-tier-agency-upgrade-modal/lta-upgrade-modal.controller';
import * as swapPlayer from './authorized-agent/swap-player/swap-player.controller';
import * as reonboarding from './authorized-agent/reonboarding-flow/root.controller';
import * as reonboardingPopup from 'pages/agency/authorized-agent/reonboarding-popup/reonboarding-popup.controller'
import * as requestCreditsModal from './common/request-credits-modal/request-credits-modal.controller';
import * as plusPitchTabSearchState from './authorized-agent/pitch-page/redux/agency-plus-pitch-search.controller';


export class AgencyState {
    club: clubController.AgencyClubState;
    agent: agentController.AgencyAgentState;
    landingPage: landingPage.AgencyLandingPageState;
    pitchInsight: pitchInsight.State;
    plusPitch: plusPitch.State;
    customPitch: customPitch.CustomPitchState;
    commonPitch: commonPitch.State;
    playerSearch: playerSearch.State;
    publicProfile: publicProfile.AgencyPublicProfileState;
    ltaUpgradeModal: ltaUpgradeModal.State;
    swapPlayer: swapPlayer.State;
    reonboarding: reonboarding.ReonboardingRootState;
    reonboardingPopup: reonboardingPopup.State
    requestCreditsModal: requestCreditsModal.State;
    plusPitchSearch: plusPitchTabSearchState.State;

}

export function combineReducers() {

    let agencyReducer = {
        club: clubController.combineReducers(),
        agent: agentController.combineReducers(),
        landingPage: landingPage.combineReducers(),
        pitchInsight: pitchInsight.Reducer,
        plusPitch: plusPitch.Reducer,
        customPitch: customPitch.combineReducers(),
        commonPitch: commonPitch.Reducer,
        playerSearch: playerSearch.combineReducers(),
        publicProfile: publicProfile.combineReducers(),
        ltaUpgradeModal: ltaUpgradeModal.Reducer,
        swapPlayer: swapPlayer.Reducer,
        reonboarding: reonboarding.combineReducers(),
        reonboardingPopup: reonboardingPopup.Reducer,
        requestCreditsModal: requestCreditsModal.Reducer,
        plusPitchSearch: plusPitchTabSearchState.Reducer,
    };

    return redux.combineReducers(agencyReducer)
}