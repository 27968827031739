import { StateController } from 'utils/action-declaration';
import CompetitionService from 'api/agency/agency-competition-list/competition-list.service';
import { CompetitionListModel } from 'api/agency/agency-competition-list/models';
import { AppState } from 'root.reducer';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';
import { Actions as SearchActions, Selectors as SearchSelectors } from './filter.controller';

class CommonState {
    competitionList: Array<CompetitionListModel>;
    modalData?: any;
    processing: boolean;
    processingIds: Array<number>;
}

const defaultState: CommonState = {
    competitionList: [],
    modalData: null,
    processing: false,
    processingIds: [],
}

const stateController = new StateController<CommonState>(
    'SEARCH/COMPETITION_LIST',
    defaultState
)

class CompetitionsRequest {
    name: string;
    competitionIds: Array<number>;
}

class UpdateCompetitionsRequest {
    id: number;
    name: string;
    competitionIds: Array<number>;
}

class Actions {
    public static getCompetitionList = () => {
        return async (dispatch) => {
            try {
                dispatch(stateController.setState({ processing: true }))
                const competitionList = await CompetitionService.getAll();

                competitionList.forEach(element => {
                    element.competitionIds = element.competitions.map(x => x.id)
                });

                dispatch(stateController.setState({ competitionList, processing: false }))
            } catch (error) {
                dispatch(stateController.setState({ processing: false }))
            }
        }
    }

    public static toggleModal = (modalData?: any) => {
        return (dispatch) => {
            dispatch(stateController.setState({ modalData }))
        }
    }

    public static toggleCompetitionListModal = (modalData?: any) => {
        return (dispatch) => {
            dispatch(Actions.toggleModal(modalData))
            if (modalData && modalData.id) {
                dispatch(userActivityInsert({
                    PageName: 'Search [Filter - League list]',
                    Message: `Edit list: ${modalData.name}`,
                    PageType: PageType.Search,
                }))
            } else if (modalData) {
                dispatch(userActivityInsert({
                    PageName: 'Search [Filter - League list]',
                    Message: `Create League List`,
                    PageType: PageType.Search,
                }))
            } else {
                dispatch(userActivityInsert({
                    PageName: 'Search [Filter - League list]',
                    Message: `Cancelled`,
                    PageType: PageType.Search,
                }))
            }
        }
    }

    public static competitionListCreate = (data: CompetitionsRequest) => {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ processing: true }))
                await CompetitionService.create({ name: data.name, competitions: data.competitionIds });
                await dispatch(Actions.getCompetitionList())
                dispatch(Actions.toggleModal(null))
                dispatch(stateController.setState({ processing: false }))

                const competitions: any = SearchSelectors.getStructure(getState()).leaguesList;
                const selectedCompetitions = competitions.filter(item => data.competitionIds.includes(item.id))

                dispatch(userActivityInsert({
                    PageName: 'Search [Filter - League list]',
                    Message: `Save changes: ${data.name} - ${selectedCompetitions
                        .map(item => {
                            const i = item.name.split(',')
                            return `${i[0]}(${i[1] ? i[1].trim() : ''})`;
                        })
                        .join(', ')}`,
                    PageType: PageType.Search,
                }))
            } catch (error) {
                dispatch(stateController.setState({ processing: false }))
            }
        }
    }
    public static competitionListUpdate = (data: UpdateCompetitionsRequest) => {
        return async (dispatch, getState: () => AppState) => {
            try {
                const processingIds = Selectors.getProcessingIds(getState());
                dispatch(stateController.setState({ processing: true, processingIds: [...processingIds, data.id] }))
                await CompetitionService.update({ competitionListId: data.id, name: data.name, competitionIds: data.competitionIds });
                await dispatch(Actions.getCompetitionList())

                const competitions: any = SearchSelectors.getStructure(getState()).leaguesList;
                const selectedCompetitions = competitions.filter(item => data.competitionIds.includes(item.id))

                dispatch(userActivityInsert({
                    PageName: 'Search [Filter - League list]',
                    Message: `Save changes: ${data.name} - ${selectedCompetitions
                        .map(item => {
                            const i = item.name.split(',')
                            return `${i[0]}(${i[1] ? i[1].trim() : ''})`;
                        })
                        .join(', ')}`,
                    PageType: PageType.Search,
                }))
                dispatch(Actions.toggleModal(null))

                dispatch(stateController.setState((draftState) => ({
                    ...draftState,
                    processing: false,
                    processingIds: draftState.processingIds.filter(i => i !== data.id)
                })))
            } catch (error) {
                dispatch(stateController.setState((draftState) => ({
                    ...draftState,
                    processing: false,
                    processingIds: draftState.processingIds.filter(i => i !== data.id)
                })))
            }
        }
    }
    public static competitionListDelete = (id: number) => {
        return async (dispatch, getState: () => AppState) => {
            try {
                const processingIds = Selectors.getProcessingIds(getState());
                dispatch(stateController.setState({ processing: true, processingIds: [...processingIds, id] }))

                const competitions: any = Selectors.getCompetitionList(getState());
                const deleted = competitions.find(item => item.id === id)
                await CompetitionService.delete(id);
                await dispatch(Actions.getCompetitionList())
                dispatch(Actions.toggleModal(null))
                dispatch(stateController.setState((draftState) => ({
                    ...draftState,
                    processing: false,
                    processingIds: draftState.processingIds.filter(i => i !== id)
                })))

                dispatch(SearchActions.deleteCompetitionList(id))
                dispatch(userActivityInsert({
                    PageName: 'Search [Filter - League list]',
                    Message: `Delete list: ${deleted.name}`,
                    PageType: PageType.Search,
                }))
            } catch (error) {
                dispatch(stateController.setState((draftState) => ({
                    ...draftState,
                    processing: false,
                    processingIds: draftState.processingIds.filter(i => i !== id)
                })))
            }
        }
    }
    public static selectLeague = (list: any) => {
        return async (dispatch, getState: () => AppState) => {
            dispatch(userActivityInsert({
                Message: `Select list: ${list.name
                    } - ${list.competitions.map(i => i.name).join(',')}`,
                PageName: 'Search [Filter - League list]',
                PageType: PageType.Search
            }))
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.agency.playerSearch.competitionList;
    public static isProcessing = (state: AppState) => Selectors.getRoot(state).processing;
    public static getProcessingIds = (state: AppState) => Selectors.getRoot(state).processingIds;
    public static getCompetitionNames = (state: AppState): Array<string> => {
        const competitionList = Selectors.getCompetitionList(state);
        return competitionList.map(item => item.name)
    };
    public static getCompetitionListModal = (state: AppState) => Selectors.getRoot(state).modalData;
    public static getCompetitionList = (state: AppState) => Selectors.getRoot(state).competitionList;
}


const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    CommonState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};