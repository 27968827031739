import * as types from 'store/actionTypes';
import { getRequests } from 'store/requests/requestsReducer';
import PasswordValidation from 'utils/validate-password';

const initialState = {
    result: null,
    isLoading: false,
};

export default (s = initialState, action) => {
    switch (action.type) {
        case types.USER_SET_NEW_PASSWORD_POST.SUCCESS:
            return {
                ...s,
                result: action.payload.data,
                isLoading: false,
            };
        case types.USER_CLEAR_SET_PASSWORD_REQUEST_SESSION:
            return {
                ...s,
                result: null,
                isLoading: false,
            };
        case types.SET_PASSWORD_ERROR: {
            const result = s.result || {};
            const validation = PasswordValidation.validate(action.payload);
            return {
                ...s,
                result: {
                    ...result,
                    data: {
                        isSuccess: validation.isSuccess,
                        rules: validation.rules,
                    },
                },
            };
        }
        case types.USER_RETURN_TO_SET_PASSWORD:
            return {
                ...s,
                result: null,
                isLoading: false,
            };
        case types.USER_SET_NEW_PASSWORD_POST.REQUEST:
            return {
                ...s,
                result: null,
                isLoading: true,
            };
        default:
            return s;
    }
};

export const setNewPasswordResult = state => {
    return state.usersetnewpassword.result;
};

export const getIsPasswordSettingProccessing = (state) => {
    return state.usersetnewpassword.isLoading|| getRequests(state).some(r => r.type === types.AUTH_LOGIN);
}