import axios from 'axios-config';
import { PlayerVerificationExtended, } from 'api/agency/agent/landing/agency-dashboard'

export default class AgentPlayerService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async removePlayer(id: number): Promise<void> {
        await axios.delete(`api/v2/AgencyPlayer/Players/${id}/Remove`, this.axiosConfig)
    }

    public static async saveVideos(request: { playerId: number, urls: Array<string> }): Promise<boolean> {
        const { data } = await axios.post<boolean>(`api/v2/AgencyPlayer/SaveVideos`, request, this.axiosConfig)
        return data;
    }

    public static async savePromoText(playerId: number, promoText: string) {
        const { data } = await axios.post<boolean>(`api/v2/AgencyPlayer/SetPromoText`, { promoText: promoText, playerId: playerId }, this.axiosConfig)
        return data;
    }

    public static async saveContractExpiration(playerId: number, contractExpiry: Date, additionalYears) {
        const { data } = await axios.post<boolean>(
            `api/v2/AgencyPlayer/SetContractExpiration`,
            { 
                contractExpiration: contractExpiry, 
                playerId: playerId ,
                yearsPlus: additionalYears
            },
            this.axiosConfig
        )
        return data;
    }
}