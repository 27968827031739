import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import userActivityInsert, { userActivityInsertSequence } from "app/user-activity/actions/user-activity.actions";
import AgencyPlayerOnboardingService from 'api/agency/player/shared/agency-player-service';



class RequestCreditsModalState {
    isRequestLoading: boolean;
    isModalOpen: boolean;
    isMorePriorityPlayerCredits: boolean;
    isMorePlayerCredits: boolean;
    isRequestSent: boolean;
    pageName?: string;
    pageType?: number;
}

const defaultState: RequestCreditsModalState = {
    isRequestLoading: false,
    isModalOpen: false,
    isMorePriorityPlayerCredits: true,
    isMorePlayerCredits: true,
    isRequestSent: false,
    pageName: '',
    pageType: null,
}

const stateController = new StateController<RequestCreditsModalState>(
    "AGENCY/REQUEST_CREDITS_MODAL",
    defaultState
);

class Actions {
    public static openModal(pageName?: string, pageType?: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({
                isModalOpen: true,
                isMorePriorityPlayerCredits: true,
                isMorePlayerCredits: true,
                isRequestSent: false,
            }))
            if (pageName) {
                dispatch(stateController.setState({
                    pageName: pageName,
                    pageType: pageType,
                }));

                dispatch(userActivityInsertSequence([
                    {
                        Message: `Clicked Request Credits`,
                        PageName: pageName,
                        PageType: pageType
                    },
                    {
                        Message: `Viewed Request More Credits`,
                        PageName: pageName,
                        PageType: pageType,
                    },
                    {
                        Message: `Prechecked Request More Priority Player Credits`,
                        PageName: pageName,
                        PageType: pageType,
                    },
                    {
                        Message: `Prechecked Request More Player Credits`,
                        PageName: pageName,
                        PageType: pageType,
                    }
                ]));
            }
        }
    }

    public static closeModal() {
        return (dispatch, getState: () => AppState) => {
            const { pageName, pageType } = getState().agency.requestCreditsModal;

            dispatch(stateController.setState({ isModalOpen: false }))

            if (pageName) {
                dispatch(userActivityInsert({
                    Message: `Clicked Cancel`,
                    PageName: pageName,
                    PageType: pageType,
                }));
            }
        }
    }

    public static setMorePriorityPlayerCredits = (value: boolean) => {
        return (dispatch, getState: () => AppState) => {
            const { pageName, pageType } = getState().agency.requestCreditsModal;

            dispatch(stateController.setState({ isMorePriorityPlayerCredits: value }))

            if (value) {
                pageName && dispatch(userActivityInsert({
                    Message: `Checked Request More Priority Player Credits`,
                    PageName: pageName,
                    PageType: pageType
                }));
            } else {
                pageName && dispatch(userActivityInsert({
                    Message: `Unchecked Request More Priority Player Credits`,
                    PageName: pageName,
                    PageType: pageType
                }));
            }
        }
    }

    public static setMorePlayerCredits = (value: boolean) => {
        return (dispatch, getState: () => AppState) => {
            const { pageName, pageType } = getState().agency.requestCreditsModal;

            dispatch(stateController.setState({ isMorePlayerCredits: value }))

            if (value) {
                pageName && dispatch(userActivityInsert({
                    Message: `Checked Request More Player Credits`,
                    PageName: pageName,
                    PageType: pageType
                }));
            } else {
                pageName && dispatch(userActivityInsert({
                    Message: `Unchecked Request More Player Credits`,
                    PageName: pageName,
                    PageType: pageType
                }));
            }
        }
    }

    public static sendRequestMoreCredits() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const { pageName, pageType } = getState().agency.requestCreditsModal;

                dispatch(stateController.setState({ isRequestLoading: true }))

                pageName && dispatch(userActivityInsert({
                    Message: `Clicked Send Request`,
                    PageName: pageName,
                    PageType: pageType
                }));

                const substate = Selectors.getRoot(getState());
                const isRegular = substate.isMorePlayerCredits;
                const isPriority = substate.isMorePriorityPlayerCredits;

                await AgencyPlayerOnboardingService.requestPlayerCreditsEmail({
                    isRegular,
                    isPriority,
                });

                dispatch(stateController.setState({ isRequestSent: true }))

                pageName && dispatch(userActivityInsert({
                    Message: `Request Sent Successfully`,
                    PageName: pageName,
                    PageType: pageType
                }));
            } finally {
                dispatch(stateController.setState({ isRequestLoading: false }))
            }
        }
    }

    public static closeModalAfterRequest() {
        return (dispatch, getState: () => AppState) => {
            const { pageName, pageType } = getState().agency.requestCreditsModal;

            pageName && dispatch(stateController.setState({
                isModalOpen: false,
            }))

            pageName && dispatch(userActivityInsert({
                Message: `Clicked Close`,
                PageName: pageName,
                PageType: pageType
            }));
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.agency.requestCreditsModal;
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    RequestCreditsModalState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};



