import axios from 'axios-config';

interface ShowTermsAndConditions {
    showTos: boolean;
    showAcceptTerms: boolean;
}

export default class CommonService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async showTermsAndConditions(): Promise<ShowTermsAndConditions> {
        const { data } = await axios.get(
            `api/Common/ShowTermsAndConditions`,
            this.axiosConfig
        )
        return data;
    }

    public static async markVisibleTermsAndConditions(): Promise<any> {
        await axios.post(
            `api/Common/MarkVisibleTermsAndConditions`,
            {},
            this.axiosConfig
        );
    }

    public static async markVisibleAgencyNewIndicator(): Promise<any> {
        await axios.post(
            `api/Common/MarkVisibleAgencyNewIndicator`,
            {},
            this.axiosConfig
        );
    }

    public static async insertUserActivityPoison(ua): Promise<any> {
        await axios.post(
            `api/Common/InsertUserActivityPoison`,
            { data: ua },
            this.axiosConfig
        );
    }

    public static async acceptTermsAndConditions(userType: number): Promise<any> {
        await axios.post(
            `api/v2/TermsAndConditions/Accept`,
            { type: userType},
            this.axiosConfig
        );
    }

    public static async skipTermsAndConditions(userType: number): Promise<any> {
        await axios.post(
            `api/v2/TermsAndConditions/Skip`,
            { type: userType},
            this.axiosConfig
        );
    }

    public static async setTermsAndConditionsAsShown(userType: number): Promise<any> {
        await axios.post(
            `api/v2/TermsAndConditions/SetAsShown`,
            { type: userType},
            this.axiosConfig
        );
    }
}