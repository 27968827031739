import axios from 'axios-config';
import {Player} from './model/player'

export class PlayerStatisticService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async getPlayerStatisticBatchByPlayerIds(playerIds: Array<number>): Promise<Array<any>> {
        const { data } = await axios.post(
            `api/Statistic/GetPlayerComparisonBatch`,
            { items: playerIds.map(item => ({ key: item, type: 1 })) },
            this.axiosConfig
        );
        return data;
    }

    public static async getRecomendedPlayersByPlayerAdIds(playerAdIds: Array<number>, clubId: number, squadId: number, userId: number): Promise<Array<Player>> {
        const { data } = await axios.post(
            `api/Recommend/GetClubAdsRecommendedPlayers/${clubId}/${squadId}/${userId}`,
            {
                SearchFilterViewModel: {
                    ContractExpiryMax: 60,
                    ContractExpiryMin: 0,
                    MaxAge: 40,
                    MinAge: 16,
                    PageSize: 10000
                },
                PlayerAdId: playerAdIds
            },
            this.axiosConfig
        );
        return data.output;
    }
}