const RuleLength = "At least 8 characters";
const RuleAtLeastOneNumber = "At least one number";
const RuleAtLeastOneUppercase = "At least one uppercase letter";
const RuleAtLeastOneSpecialLetter = "At least one special character";
const RuleConfirmedPassword = "Passwords do not match";

export default class PasswordValidation {
    private static validateRuleLength(password: string): boolean {
        return password.length >= 8;
    }
    private static validateRuleAtLeastOneNumber(password: string): boolean {
        return /.*\d.*/.test(password);
    }
    private static validateRuleAtLeastOneUppercase(password: string): boolean {
        return /.*[A-Z].*/.test(password);
    }

    private static validateRuleAtLeastOneSpecialLetter(password: string): boolean {
        return new RegExp("[!@#$%&*()\\-_+=^]").test(password);
    }

    public static isValid = (rules) => {
        return !rules.some(x => !x.passed)
    }

    public static validateConfirmPassword = (password, confirmPassword) => {
        return confirmPassword && password && (password !== confirmPassword) ? RuleConfirmedPassword : '';
    }

    public static validate(password: string): { rules: Array<UserPasswordRule>, isSuccess: boolean } {
        const rules: Array<UserPasswordRule> = [];
        rules.push({ ruleCode: RuleLength, passed: this.validateRuleLength(password) });
        rules.push({ ruleCode: RuleAtLeastOneNumber, passed: this.validateRuleAtLeastOneNumber(password) });
        rules.push({ ruleCode: RuleAtLeastOneUppercase, passed: this.validateRuleAtLeastOneUppercase(password) });
        rules.push({ ruleCode: RuleAtLeastOneSpecialLetter, passed: this.validateRuleAtLeastOneSpecialLetter(password) });

        return {
            rules,
            isSuccess: this.isValid(rules)
        };
    }
}

 export interface UserPasswordRule {
    ruleCode: string;
    passed: boolean;
}
