import { AppState } from 'root.reducer';
import { StateController } from 'utils/action-declaration';
import { AgencySearchService } from 'api/agency/club/agency-search/service';
import { AgencySuggestionEnum, AllAgencySuggestionItem, AgencyItem } from 'api/agency/club/agency-search/models'
import { AreaService } from 'api/area/area.service';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { AgecnyAccessService } from 'api/agency/club/agency-access/agency-access.service'
import { AreaItem } from 'api/area/model';
import _ from 'lodash';
import { PageType } from 'constants/enums';
import config from 'config';

const DEFAULT_PAGE_SIZE = 20;

export enum Tabs {
    All = 1,
    MyNetwork = 2,
}
export class AgentsState {
    currentTab?: Tabs;
    isInitialLoading: boolean;
    isLoading: boolean;
    pagination: AgentsPaginationState;
    filterCriteria: FilterCriteria;
    initialFilterCriteria: FilterCriteria;
    items: Array<AgencyItem>;
    processingItems: Array<number>;
    suggestions: Array<AllAgencySuggestionItem>;
    areas: Array<AreaItem>;
    hasAtLeastOneTrustedAgency: boolean;
}

export class AgentsPaginationState {
    currentPage: number;
    pageCount: number;
    rowCount: number;
    pageSize: number;
    totalLength: number;
}

export class FilterCriteria {
    onlyVerifiedAgents: boolean;
    playersInPortfolio: { min: number; max: number };
    portfolioMarketValue: { min: number; max: number };
    selectedCountries: number[];
    keyword: string;

    selectedSuggestionType?: AgencySuggestionEnum;
    SelectedSuggestionId?: number;

    myNetworkOnly?: boolean
}
const defaultFilterCriteria: FilterCriteria = {
    onlyVerifiedAgents: false,
    playersInPortfolio: null,
    portfolioMarketValue: null,
    selectedCountries: [],
    keyword: "",

    selectedSuggestionType: null,
    SelectedSuggestionId: null,
}

const defaultPagination: AgentsPaginationState = {
    currentPage: 1,
    pageCount: 0,
    rowCount: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    totalLength: 0
};

const defaultState: AgentsState = {
    currentTab: null,
    isInitialLoading: true,
    isLoading: false,
    pagination: defaultPagination,
    filterCriteria: defaultFilterCriteria,
    initialFilterCriteria: defaultFilterCriteria,
    items: null,
    processingItems: [],
    suggestions: null,
    areas: [],
    hasAtLeastOneTrustedAgency: false,
}

const stateController = new StateController<AgentsState>(
    'AGENCY/CLUB_MAIN_PAGE',
    defaultState
)

class Actions {

    public static setTabOnMount(tab: Tabs) {
        return dispatch => {
            dispatch(stateController.setState({ currentTab: tab }))
            dispatch(Actions.disposeGridItems())
            if (tab === Tabs.All) {
                dispatch(Actions.loadAgencies(true, false))
            } else if (tab === Tabs.MyNetwork) {
                dispatch(Actions.loadAgencies(true, true))
            }
        }
    }

    public static changeTab(tab: Tabs) {
        return dispatch => {
            dispatch(stateController.setState({ currentTab: tab }))
            dispatch(Actions.disposeGridItems())
            dispatch(Actions.disposeSearch())
            dispatch(Actions.disposePagination())
            if (tab === Tabs.All) {
                dispatch(Actions.loadAgencies(false, false))
            } else if (tab === Tabs.MyNetwork) {
                dispatch(Actions.loadAgencies(false, true))
            }
        }
    }


    public static loadAgencies(isInitialRequest: boolean = false, isMyNetworkTab: boolean = false) {
        return async (dispatch, getState: () => AppState) => {

            try {
                dispatch(stateController.setState({ isInitialLoading: isInitialRequest, isLoading: true }))

                const substate = getState().agency.club.agencySearch
                const data = await AgencySearchService.getAllAgencies({
                    filter: isInitialRequest ? {
                        ...defaultFilterCriteria,
                        myNetworkOnly: isMyNetworkTab
                    } : {
                            ...substate.filterCriteria,
                            myNetworkOnly: isMyNetworkTab
                        },
                    pageIndex: substate.pagination.currentPage - 1,
                    pageSize: DEFAULT_PAGE_SIZE,
                })

                if (isInitialRequest) {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        initialFilterCriteria: {
                            ...prevState.initialFilterCriteria,
                            playersInPortfolio: data.filterRanges.playersInPortfolio,
                            portfolioMarketValue: data.filterRanges.portfolioMarketValue
                        },
                        filterCriteria: {
                            ...prevState.filterCriteria,
                            playersInPortfolio: data.filterRanges.playersInPortfolio,
                            portfolioMarketValue: data.filterRanges.portfolioMarketValue
                        },
                        hasAtLeastOneTrustedAgency: data.hasAtLeastOneTrusted
                    })))
                }

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    items: data.items.pageItems,
                    suggestions: data.suggestions,
                    pagination: {
                        ...prevState.pagination,
                        rowCount: data.items.total,
                        pageSize: data.items.pageSize,
                        pageCount: data.items.pageCount,
                    }
                })))
            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState({ isInitialLoading: false, isLoading: false }))
            }
        }
    }

    public static loadAreas() {
        return async dispatch => {
            const areas = await AreaService.getAreas()
            dispatch(stateController.setState({ areas: areas.items }))
        }
    }

    public static giveAccess(agencyId: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({ ...prevState, processingItems: [...prevState.processingItems, agencyId] })))
                await AgecnyAccessService.giveAccess(agencyId)

                dispatch(userActivityInsert({
                    Message: `Granted Access`,
                    PageName: 'Club Agents [All Agencies]',
                    AgencyId: agencyId,
                    PageType: PageType.Agents
                }));

                const objective = getState().agency.club.agencySearch.items.find(item => item.agencyId === agencyId)
                objective.accessGranted = true
                dispatch(stateController.setState(prevState => ({ ...prevState, items: [...prevState.items] })))
            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState(prevState => ({ ...prevState, processingItems: prevState.processingItems.filter(item => item !== agencyId) })))
            }
        }
    }

    public static giveAccessAndUpdateSuggestions(agencyId: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({ ...prevState, processingItems: [...prevState.processingItems, agencyId] })))
                await AgecnyAccessService.giveAccess(agencyId)

                dispatch(userActivityInsert({
                    Message: `Granted Access`,
                    PageName: 'Club Agents [All Agencies - Search]',
                    AgencyId: agencyId,
                    PageType: PageType.Agents
                }));

                const objective = getState().agency.club.agencySearch.items.find(item => item.agencyId === agencyId)
                objective.accessGranted = true

                const suggestion = getState().agency.club.agencySearch.suggestions.find(item => item.agencyId === agencyId)
                suggestion.accessGranted = true

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    items: [...prevState.items],
                    suggestions: [...prevState.suggestions]
                })))

            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState(prevState => ({ ...prevState, processingItems: prevState.processingItems.filter(item => item !== agencyId) })))
            }
        }
    }

    public static revokeAccess(agencyId: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({ ...prevState, processingItems: [...prevState.processingItems, agencyId] })))
                await AgecnyAccessService.revokeAccess(agencyId)

                dispatch(userActivityInsert({
                    Message: `Revoked Access`,
                    PageName: 'Club Agents [My Network]',
                    AgencyId: agencyId,
                    PageType: PageType.Agents
                }));

                dispatch(stateController.setState(prevState => ({ 
                    ...prevState,
                    items: prevState.items.filter(item => item.agencyId !== agencyId),
                })))

                const state = getState().agency.club.agencySearch;

                if(!state.items[0] && state.pagination.currentPage > 1) {
                    dispatch(Actions.onPageTurn(state.pagination.currentPage - 1));
                }
            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState(prevState => ({ ...prevState, processingItems: prevState.processingItems.filter(item => item !== agencyId) })))
            }
        }
    }



    public static onSearchChange(keyword: string) {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterCriteria: {
                    ...prevState.filterCriteria,
                    keyword: keyword
                }
            })))
            Actions.loadAgenciesOnFilterChangeDebounced(dispatch)
        }
    }

    public static selectSuggestedItem(suggestionsId: number, keyword: string) {
        return async dispatch => {
            await dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterCriteria: {
                    ...prevState.filterCriteria,
                   // SelectedSuggestionId: suggestionsId,
                    keyword: keyword.trim().toLocaleLowerCase(),
                },
            })))
            dispatch(Actions.disposeAutosuggest())
            dispatch(Actions.loadAgenciesOnFilterChange())
        }
    }

    public static onFilterChange(criteria: FilterCriteria) {
        return dispatch => {
            dispatch(stateController.setState({
                filterCriteria: criteria
            }))
            Actions.loadAgenciesOnFilterChangeDebounced(dispatch)
        }
    }

    private static loadAgenciesOnFilterChange() {
        return (dispatch, getState: () => AppState) => {
            const currentTab = getState().agency.club.agencySearch.currentTab
            if (currentTab === Tabs.All) {
                dispatch(Actions.loadAgencies(false, false))
            } else if (currentTab === Tabs.MyNetwork) {
                dispatch(Actions.loadAgencies(false, true))
            }
        }
    }

    private static loadAgenciesOnFilterChangeDebounced = _.debounce((dispatch) => dispatch(Actions.loadAgenciesOnFilterChange()), 1000)

    public static disposeAutosuggest() {
        return dispatch => {
            dispatch(stateController.setState({ suggestions: null }))
        }
    }

    public static disposeSearch() {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterCriteria: {
                    ...prevState.filterCriteria,
                    keyword: "",
                }
            })))
        }
    }

    public static disposeGridItems() {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                items: null
            })))
        }
    }

    public static disposePagination() {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                pagination: defaultPagination
            })))
        }
    }

    public static dispose() {
        return dispatch => {
            dispatch(stateController.setState(defaultState))
        }
    }


    public static onPageTurn(paginationIndex) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                pagination: {
                    ...prevState.pagination,
                    currentPage: paginationIndex
                }
            })))
            const isMyNetworkTab = getState().agency.club.agencySearch.currentTab === Tabs.MyNetwork
            dispatch(Actions.loadAgencies(false, isMyNetworkTab))
        }
    }

    public static openAgencyProfile(agencyId: number, squadId?: number, playerId?: number) {
        return async (dispatch, getState: () => AppState) => {
            const selectedAgency = getState().agency.club.agencySearch.items.find(item => item.agencyId === agencyId).name
            // window.open(`/agency-profile/${agencyId}`, '_blank')
            // window.open(`${config.profileWebAppUrl}/agency-profile/${encodeURIComponent(selectedAgency)}`);
            window.open(`/agency-profile/${encodeURIComponent(selectedAgency)}`, '_blank');
            dispatch(userActivityInsert({
                Message: playerId ? 'View Agent' : `Opened Agency profile`,
                PageName: 'Club Agents [All Agencies - Search]',
                AgencyId: agencyId,
                ClubId: squadId,
                PlayerId: playerId,
                PageType: PageType.Agents
            }));
        }
    }


    public static openPlayerProfile(clubId: number, playerId: number,) {
        return async (dispatch, getState: () => AppState) => {
            window.open(`/profile/${playerId}`, '_blank')
            dispatch(userActivityInsert({
                Message: `Opened Player Profile (unknown agency)`,
                PageName: 'Club Agents [All Agencies - Search]',
                PlayerId: playerId,
                ClubId: clubId,
                PageType: PageType.Agents
            }));
        }
    }
}
class Selectors {

}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    AgentsState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};