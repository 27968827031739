import axios from 'axios-config';

export default class AgencyResetPreconnectedPlayersService{

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async loadPlayers(agencyId) {
        const { data } = await axios.get(
            `api/v2/AdminAgencyReconnectPlayers/GetPlayersForAgency/${agencyId}`,
            this.axiosConfig
        );
        return data;
    }

    public static async resetPlayerConnections(playersToReconnect, agencyId){
        const { data } = await axios.post(
            `api/v2/AdminAgencyReconnectPlayers/ResetPlayerConnections`,
            {
                agencyId: agencyId,
                playersToReconnect: playersToReconnect,
            },
            this.axiosConfig
        );
        return data;
    }
}