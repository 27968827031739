import { rows, isReversedField } from '../const';
import { camalize } from '../../../utils/camel';
import { formatPlayerSuggestion, formatPlayer } from './format-player';
import { translate } from '../../../services/localization';
import { PlayerComparisonModel } from '../models/player-comparison.model';
import { SuggestionModel } from '../models/suggestion.model';
import { ComparedPlayerModel } from '../models/compared-player.model';

type OwnProps = {
    firstPlayer: ComparedPlayerModel;
    secondPlayer: SuggestionModel;
    thirdPlayer: SuggestionModel;
    comparison: PlayerComparisonModel;
}

/*
    Here "position" defines where the point will be placed on the chart axis 
    given that chart axis domain is [0,100]  
*/
const getDataForChart = ({
    firstPlayer,
    secondPlayer,
    thirdPlayer,
    comparison
}: OwnProps) => {

    const result = [];
    if (!canCalculate()) {
        return [];
    }

    rows[firstPlayer.firstPositionCode].forEach((lable, index) => {
        if (index > 1 && index !== 6) {
            let obj: any = {};
            const labelCamalized: string = lable.length > 3 ? camalize(lable) : lable;

            obj.subject = lable;
            obj.axisValue = comparison.average[labelCamalized].maximumAxisValue;

            obj.firstPlayerName = formatPlayer(firstPlayer);
            obj.firstPlayerValue = comparison.firstPlayer[labelCamalized];
            obj.firstPlayerPosition = getInProcents(
                comparison,
                labelCamalized,
                'firstPlayer',
            );

            if (secondPlayer && comparison.secondPlayer) {
                obj.secondPlayerName = formatPlayerSuggestion(secondPlayer);
                obj.secondPlayerValue = comparison.secondPlayer[labelCamalized];
                obj.secondPlayerPosition = getInProcents(
                    comparison,
                    labelCamalized,
                    'secondPlayer',
                );
            }

            if (thirdPlayer && comparison.thirdPlayer) {
                obj.thirdPlayerName = formatPlayerSuggestion(thirdPlayer);
                obj.thirdPlayerValue = comparison.thirdPlayer[labelCamalized];
                obj.thirdPlayerPosition = getInProcents(
                    comparison,
                    labelCamalized,
                    'thirdPlayer',
                );
            }

            obj.averageName = translate(
                'playerComparison.formatPlayer.average',
            );
            obj.averageValue = comparison.average[labelCamalized].average;
            obj.averagePosition = getInProcents(
                comparison,
                labelCamalized,
                'average',
            );

            obj.average = comparison.average[labelCamalized];

            result.push(obj);
        }
    });

    return result;

    function canCalculate() {
        return (
            comparison && firstPlayer.hasStatistics && firstPlayer.firstPositionCode && comparison.average
        );
    }
};

export default getDataForChart;

function getInProcents(comparison: PlayerComparisonModel, label: string, player: string) {
    if (player === 'average') {
    }
    if (!comparison[player] || !comparison[player][label]) {
        if (isReversedField(label)) {
            return 100;
        }
        return 0;
    }

    let score = comparison[player][label];
    if (typeof score.average === 'number') {
        score = score.average;
    }
    const domain = {
        min: comparison.average[label].minimumAxisValue,
        max: comparison.average[label].maximumAxisValue,
    };
    const scoreProportion =
        (score - Math.min(domain.min, score)) / (domain.max - domain.min);

    let result = scoreProportion * 100;
    result = Math.round(result);
    result = Math.min(100, result);
    if (isReversedField(label)) {
        result = 100 - result;
    }
    return result;
}
