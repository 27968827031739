import { GeneralPositionEnum } from 'api/core/general-position.model';
import { FootTypeEnum } from 'api/core/foot-type';
import { PlayerPosition } from 'pages/agency/authorized-agent/add-players/add-players.controller';

export interface InvitationReactionResponse {
    playerName: string;
    agentName: string;
    agencyName: string;
    agentPosition: string;
    contractExpiration: Date | string | null;
}

export interface UserPlayerResponse {
    agentName: string;
    agencyName: string;
    contractExpiration: Date | string | null;
    phoneAreaId: number | null;
    phone: string;
    playerHasAgent: boolean;
}

export interface GeneralPlayerInfoModel {
    playerId: number;
    invitationUid: string;
    playerName: string;
    playerPhone: string;
    emailConfirmed: boolean;
    agent: IdName;
    agentToPlayerConfirmed: boolean;
    agentToPlayerPending: boolean;
    playerToAgentPending: boolean;
    playerDisconnectedFromAgency: boolean;
    agreementPassed: boolean;
    wasEmailConfirmed: boolean;
    expiryDate: string;
    agreementType: AgentPlayerAgreementTypeEnum;
    messagesCount: number;
    parentSquad: ParentSquadInfo;
    showLatestActivity: boolean;
}

export interface ParentSquadInfo {
    country: string;
    id: number;
    league: string;
    logo: string;
    name: string;
}

export interface IdName {
    id: number;
    name: string;
    shortName: string;
}

export enum AgentPlayerInvitationStatusEnum {
    PreconnectedToAgent = 1,
    DisconnectedFromAgent = 2,
    SentToPlayer = 3,
    VerifiedByPlayer = 4,
    VerifiedAndConfirmed = 5,
    RejectedByAdmin = 6,
    SentToAgency = 7,
    RejectedByAgency = 8,
    RejectedByPlayer = 9,
    RequireAdminConfirmation = 10,
    PreconnectedToNotTrusted = 11
}

export enum AgentPlayerAgreementTypeEnum {
    None = 0,
    ContractualAgreement = 1,
    VerbalAgreement = 2
}

export interface PlayerMessage {
    type: PlayerMessageType;
    playerTransfer: PlayerTransferMessage;
}

export enum PlayerMessageType {
    Default = 0,
    PlayerTransfer = 1,
}

export interface PlayerTransferMessage {
    id: number;
    agencyId: number;
    agencyName: string;
    year: number;
    playerId: number;
    playerName: string;
    fromSquadId: number;
    fromSquadName: string;
    fromSquadLogo: string;
    toSquadId: number;
    toSquadName: string;
    toSquadLogo: string;
    isContractExtension: boolean;
    durationYears: number;
}

export interface PlayerCreditsRequest {
    isRegular: boolean;
    isPriority: boolean;
}
export interface NationalitiesRequest {
    id: string;
    name: string;
    shortName: string;
}

export interface PlayerModalForm {
    source: string | null;
    firstName: string | null;
    lastName: string | null;
    dateOfBirth: string | null;
    height: number | null;
    nationality: number | null;
    secondNationality: number | null;
    isFreeAgent: boolean;
    foot: FootTypeEnum | null;
    contractExpiry: string | Date | null;
    club: string | null;
    squadId: number | null;
    primaryPosition: PlayerPosition | null;
    secondaryPosition: PlayerPosition | null;
}

export interface CreatePlayerRequest {
    source: string | null;
    firstName: string | null;
    lastName: string | null;
    dateOfBirth: string | null;
    height: number | null;
    nationality: number | null;
    secondNationality: number | null;
    isFreeAgent: boolean;
    foot: FootTypeEnum | null;
    contractExpiry: string | Date | null;
    club: string | null;
    squadId: number | null;
    primaryPosition: GeneralPositionEnum | null;
    secondaryPosition: GeneralPositionEnum | null;
}

export enum AgentClubStaffInvitationStatusEnum {
    PreconnectedToAgent = 1,
    DisconnectedFromAgent = 2,
    SentToStaff = 3,
    VerifiedByStaff = 4,
    VerifiedAndConfirmed = 5,
    RejectedByAdmin = 6,
    SentToAgency = 7,
    RejectedByAgency = 8,
    RejectedByStaff = 9,
    RequireAdminConfirmation = 10,
    PreconnectedToNotTrusted = 11
}
