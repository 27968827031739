import React from 'react'
import styled from 'styled-components'

import Panel from 'components/Panel';
import { EventTitle } from '../past-event-title'
import { PastEventStats } from '../past-event-stats'
import { PastEventClubLogos } from '../past-event-club-logos'
import thirdDealDayImage2 from 'media/images/events/third-virtual-deal-day-2.png';
import { previousEventAttendees as previousMadridSummit21_22nd_march } from './madrid-summit-21-22nd-march'

const MadridSummit16thJune = () => {
    return (
        <div className="row">
            <div className="col-md-8 col-12">
                <Panel className="content-wrapper">
                    <EventTitle
                        number="3"
                        bgImageNumber={4}
                        th="rd"
                        title1="TransferRoom"
                        title2="Deal Day"
                        date="16th Jun 2022, Madrid, Spain"
                        vs={true}
                    />
                    <div className="photo-container row" style={{ padding: '0 0%' }}>
                        <img style={{ width: '100%' }} src={thirdDealDayImage2} alt="" />
                    </div>
                </Panel>
            </div>
            <div className="col-md-4 col-12 row no-gutters">
                <div className="col-md-12 col-sm-6 col-12">
                    <PastEventStats
                        participants="247"
                        countries="40"
                        leagues="53"
                        agents="21"
                    />
                </div>
                <div className="col-md-12 col-sm-6 col-12">
                    <PastEventClubLogos
                        attendees={previousMadridSummit21_22nd_march}
                        countlimit={36}
                    />
                </div>
            </div>
        </div>
    )
}

export default MadridSummit16thJune