import {translate} from './localization';

export function mapSelectors(path, selectors) {
    return Object.keys(selectors).reduce(
        (obj, key) => ({ ...obj, [key]: state => selectors[key](path(state)) }),
        {},
    );
}

export function monthDiff(d1, d2) {
    let months;
    months = Math.abs(d1.getFullYear() - d2.getFullYear()) * 12;
    months -= d2.getMonth();
    months += d1.getMonth();
    return Math.abs(months);
}

// if d1 > d2 then result is positive
export function monthDiffSigned(d1, d2) {
    var months;
    months = (d1.getFullYear() - d2.getFullYear()) * 12;
    months -= d2.getMonth();
    months += d1.getMonth();
    return months;
}

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

export function dateDiffInDaysSigned(a, b) {
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc1 - utc2) / _MS_PER_DAY);
}

export function dateDiffInDays(a, b) {
    return Math.abs(dateDiffInDaysSigned(a, b));
}

export function dateDiffInWeeks(a, b) {
    return Math.floor(dateDiffInDays(a, b) / 7);
}

export function dateDiffInYears(a, b) {
    return Math.floor(monthDiff(a, b) / 12);
}

export function arrayToObject(array, key = 'id') {
    return array.reduce((obj, item) => {
        obj[item[key]] = item;
        return obj;
    }, {})
}

export function formatDate(fromDate, separator = '/', shortYear = false) {
    const date = new Date(fromDate);
    let dd: any = date.getDate();
    let mm: any = date.getMonth() + 1; //January is 0!

    let yyyy: any = date.getFullYear();

    if (shortYear) {
        yyyy = yyyy.toString().slice(2,4)
    } 

    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    return dd + separator + mm + separator + yyyy;
}

export function isEquivalent(a, b) {
    // Create arrays of property names
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
        return false;
    }

    for (let i = 0; i < aProps.length; i++) {
        const propName = aProps[i];

        // If values of same property are not equal,
        // objects are not equivalent
        if (a[propName] !== b[propName]) {
            return false;
        }
    }

    // If we made it this far, objects
    // are considered equivalent
    return true;
}

export function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

export function formatWithCommas(x) {
    if (x === null || x === undefined) return '';
    if (!['string', 'number'].includes(typeof x)) return '';

    const parts = x.toString().split('.');
    for (let i = 0; i < parts.length; i++) {
        parts[i] = parts[i].trim();
    }
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
}

export function displayPlayerName({ shortName }) {
    return `${shortName}`;
}

export function toggle(arr, item, key) {
    if (arr.map(getItem).includes(getItem(item))) {
        return arr.filter(i => getItem(i) !== getItem(item));
    }
    return [...arr, item];

    function getItem(x) {
        return key ? x[key] : x;
    }
}

export function isElementInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
            (window.innerWidth || document.documentElement.clientWidth)
    );
}

export function ensureInView(container, element) {

    let cTop = container.scrollTop;
    let cBottom = cTop + container.clientHeight;

    let eTop = element.offsetTop;
    let eBottom = eTop + element.clientHeight;

    if (eTop < cTop) {
      container.scrollTop -= (cTop - eTop);
    }
    else if (eBottom > cBottom) {
      container.scrollTop += (eBottom - cBottom);
    }
}

export function deepCopy(x) {
    return JSON.parse(JSON.stringify(x));
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function capitalizeEachFirstLetter(string) {
    const parts = string.split(' ');
    return parts
        .map(item => item.charAt(0).toUpperCase() + item.slice(1))
        .join(' ');
}

export function toFeet(n, format: any = {}) {
    const realFeet = (n * 0.3937) / 12;
    const feet = Math.floor(realFeet);
    const inches = Math.floor((realFeet - feet) * 12);
    return (
        feet +
        (format.feets ? format.feets : "'") +
        inches +
        (format.inches ? format.inches : '"')
    );
}

export function displayPlayerHeight(player) {

    if(!player || player.height === 0){
        return '-';
    }

    return `${player.height}${translate('utils.cm')}`;
}

export function displayPlayerFoot(player) {
    switch (player.footName) {
        case 'Right':
            return translate('apiTexts.foot.right');
        case 'Left':
            return translate('apiTexts.foot.left');
        default:
            return player.footName;
    }
}

export function getPageName() {
    const url = window.location.pathname;

    if (url.indexOf('/mysquad') === 0) {
        return 'My Squad';
    }

    return url;
}

export function simulateMouseClick(targetNode) {
    function triggerMouseEvent(targetNode, eventType) {
        const clickEvent = document.createEvent('MouseEvents');
        clickEvent.initEvent(eventType, true, true);
        targetNode.dispatchEvent(clickEvent);
    }
    ["mouseover", "mousedown", "mouseup", "click"].forEach(function (eventType) {
        triggerMouseEvent(targetNode, eventType);
    });
}

export const normalizeUrl = (url) => {
    if (!url.startsWith('https://') && !url.startsWith('http://')) {
        url = `https://${url}`
      }

      return url;
}
