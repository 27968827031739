import React from 'react'
import './authorized-agenda.scss'

class Props {
    forAgency?: boolean
}

const Agenda = (props: Props) => {
    return (
        <div className="ep-auth-agenda">
            <h2 className="ep-auth-agenda__heading">
                Agenda
            </h2>
            <div className="ep-auth-agenda__date">
                WEDNESDAY 10th NOVEMBER
             </div>
            <div className="ep-auth-agenda__timeline">
                <div className="ep-auth-agenda__timeline-tip">
                    All times are CET
                </div>

                <div className="ep-auth-agenda__timeline-subheader">Part 1: Speaker Sessions</div>
                <div className="timeline-row">
                    <div className="time">13.15 - 13.30</div>
                    <div className="description">Welcome from TransferRoom</div>
                </div>
                <div className="timeline-row">
                    <div className="time">13.30 - 14.00</div>
                    <div className="description">Q&A with Arne Friedrich, Sporting Director, Hertha BSC</div>
                </div>

                <div style={{ marginTop: 55 }} className="ep-auth-agenda__timeline-subheader">Part 2: Networking</div>
                <div className="timeline-row">
                    <div className="time">14.00 - 15.00</div>
                    <div className="description">{props.forAgency ? 'Agency-to-club' : 'One-on-One'} meetings (5) - Round 1</div>
                </div>
                <div className="timeline-row">
                    <div className="time">15.00 - 15.15</div>
                    <div className="description">Break</div>
                </div>
                <div className="timeline-row">
                    <div className="time">15.15 - 16.15</div>
                    <div className="description">{props.forAgency ? 'Agency-to-club' : 'One-on-One'} meetings (5) - Round 2</div>
                </div>
                <div className="timeline-row">
                    <div className="time">16.15 - 16.30</div>
                    <div className="description">Break</div>
                </div>
                <div className="timeline-row">
                    <div className="time">16.30 - 17.30</div>
                    <div className="description">{props.forAgency ? 'Agency-to-club' : 'One-on-One'} meetings (5) - Round 3</div>
                </div>
            </div>
        </div>
    )
}

export default Agenda