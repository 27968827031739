import { EventInfoModel } from 'app/events/components/authorized-event-page/events-map/models';
import ClubComponent from './club-root/events.switch'
import AgencyComponent from './agency-root/events.switch'

const eventSettings: EventInfoModel = {
    shortTitle: "TransferRoom Summit",
    title: "TransferRoom Summit - Deal Day 2022",
    shortEventDates: "16th June 2022",
    eventDates: "16th June 2022",
    location: "Madrid, Spain",
    componentForClubs: ClubComponent,
    componentForAgents: AgencyComponent,
    speakerCard: () => null,
    countOfEvents: 0,
    agencyCountOfEvents: 0,
    timeZone: 'CET',
    hprInfo: {
        showForClub: false,
        showForAgency: false,
        userActivityPageName: "Home [TransferRoom Summit]",
        description: "Join key decision makers from 180+ clubs across 45+ countries in Madrid on June 16th and engage in fast-paced transfer talks."
    },
    registration: {
        activateAgencyFeature: true,
        hasDateSelectorStep: false
    },
    visibility: {
        isEventFinished: true,
        canShowAttendeesComponent: true,
        shouldShowConfirmedAttendees: false,
        shouldShowConfirmedAttendeesForAgencies: false,
        canShowAgendaBuilder: true
    }
};

export default eventSettings;