import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import historyAccessor from 'history-accessor'
import { agencyPaths } from 'routes/paths';
import { copyToClipboard as copy } from 'utils/copy-to-clipboard';
import { notificationCreate } from 'app/notifications/notifications.actions';
import ReonboardingServise from 'api/agency/agent/reonboarding/reonboarding.service';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import validator from 'services/validator';
import { Selectors as CreatePitchesSelectors } from "../create-pitches-stepper/create-pitches-stepper.controller";
import { Actions as ReonboardingActions } from 'pages/agency/authorized-agent/reonboarding-flow/reonboarding.controller';


export enum VerifyPlayersSteps {
    VerifyPlayers = 1,
    Done = 2,
}

interface PlayerVerificationData {
    id: number;
    invitationId: string;
    name: string;
    email: string;
    emailSent: boolean;
    emailSending: boolean;
    emailPredefinded: boolean;
    emailValid: boolean;
    phoneCodeAreaId: number;
    phoneNumber: string;
    phoneSent: boolean;
    phoneSending: boolean;
    phonePredefined: boolean;
    link: string;
    linkGenerating: boolean;
    isRegular: boolean;
    verificationMessage: string;
}

class State {
    isLoading: boolean;
    activeStep: VerifyPlayersSteps
    players: Array<PlayerVerificationData>
    activePlayerIndex: number
    anyPlayersVerified: boolean
}

const defaultState: State = {
    isLoading: false,
    activeStep: VerifyPlayersSteps.VerifyPlayers,
    players: null,
    activePlayerIndex: null,
    anyPlayersVerified: false
}

const stateController = new StateController<State>(
    'AGENCY/REONBOARDING-FLOW/VERIFY-PLAYERS',
    defaultState
);

class Actions {

    public static init() {
        return async (dispatch, getState: () => AppState) => {
            let selectedPlayers = getState().agency.reonboarding.selectPlayers.selectedPlayers;
            let selectedPlayersToPitch = getState().agency.reonboarding.selectPitchablePlayers.pitchablePlayers;
            let pitchablePlayerIds = selectedPlayersToPitch.map(x => x.playerId);
            var result = await ReonboardingServise.getVerificationMessages(selectedPlayers.map(x => x.playerId));

            let mappedPlayers: Array<PlayerVerificationData> = selectedPlayers.map(x => {
                return {
                    email: x.playerEmail,
                    emailSending: false,
                    emailSent: false,
                    emailPredefinded: x.playerEmail != null && x.playerEmail != '',
                    emailValid: validator.isValidEmail(x.playerEmail),
                    id: x.playerId,
                    invitationId: x.invitationUid,
                    link: null,
                    linkGenerating: false,
                    name: x.playerName,
                    phoneCodeAreaId: x.phoneCodeAreaId,
                    phoneNumber: x.playerPhone,
                    phonePredefined: x.phoneCodeAreaId != null && x.playerPhone != null && x.playerPhone.length >= 5,
                    phoneSending: false,
                    phoneSent: false,
                    isRegular: !pitchablePlayerIds.includes(x.playerId),
                    verificationMessage: result.find(y=>y.playerId == x.playerId)?.message
                }
            })
            dispatch(stateController.setState({
                activePlayerIndex: 0,
                players: mappedPlayers
            }));
        }
    }

    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState({ ...defaultState }));
        }
    }

    public static setActiveStep(step: VerifyPlayersSteps) {
        return (dispatch) => {
            dispatch(stateController.setState({ activeStep: step }));
        }
    }

    public static onPlayerDataChange(email: string, areaId: number, phone: string) {
        return (dispatch, getState: () => AppState) => {
            const substate = getState().agency.reonboarding.verifyPlayers;

            let playersCopy = [...substate.players]
            let activePlayer = playersCopy[substate.activePlayerIndex];
            activePlayer.email = email;
            activePlayer.phoneCodeAreaId = areaId;
            activePlayer.phoneNumber = phone;
            activePlayer.emailValid = validator.isValidEmail(email);

            dispatch(stateController.setState({ players: playersCopy }));
        }
    }

    public static generateLink() {
        return async (dispatch, getState: () => AppState) => {
            const substate = getState().agency.reonboarding.verifyPlayers;
            let playersCopy = [...substate.players]
            let activePlayer = playersCopy[substate.activePlayerIndex];
            activePlayer.linkGenerating = true;
            dispatch(stateController.setState({ players: playersCopy }));
            let data = await ReonboardingServise.generateLink({ playerId: activePlayer.id, isRegular: activePlayer.isRegular });
            let link = data.verificationLink;
            copy(link)
            dispatch(notificationCreate({ message: 'Copied to clipboard', level: 'info' }))

            activePlayer.linkGenerating = false;
            activePlayer.link = link;
            dispatch(stateController.setState({ players: [...playersCopy] }));
            dispatch(userActivityInsert({
                Message: `Generated a Link`,
                PageName: 'Verification Flow [Verify Players]',
                PlayerId: activePlayer.id,
            }));
            dispatch(userActivityInsert({
                Message: `Copied a Link`,
                PageName: 'Verification Flow [Verify Players]'
            }));
        }
    }

    public static skip() {
        return async (dispatch, getState: () => AppState) => {
            const substate = getState().agency.reonboarding.verifyPlayers;
            const totalPreparedPitches = CreatePitchesSelectors.getTotalPreparedPitches(getState());

            let activePlayer = substate.players[substate.activePlayerIndex];
            dispatch(stateController.setState({ isLoading: true }));
            await ReonboardingServise.removePlayer(activePlayer.id);
            dispatch(stateController.setState({ activePlayerIndex: substate.activePlayerIndex + 1, isLoading: false }));
            dispatch(userActivityInsert({
                Message: `Skipped and Removed Player`,
                PageName: 'Verification Flow [Verify Players]',
                PlayerId: activePlayer.id,
            }));

            if (substate.activePlayerIndex + 1 >= substate.players.length) {
                if (substate.anyPlayersVerified && totalPreparedPitches > 0)
                    dispatch(stateController.setState({ activeStep: VerifyPlayersSteps.Done }))
                else
                    historyAccessor.push(agencyPaths.landingPagePlayers);
            }
            dispatch(ReonboardingActions.disposeScrollingEffect());
        }
    }

    public static next(linkSent: boolean) {
        return async (dispatch, getState: () => AppState) => {
            const substate = getState().agency.reonboarding.verifyPlayers;
            const totalPreparedPitches = CreatePitchesSelectors.getTotalPreparedPitches(getState());

            let activePlayer = substate.players[substate.activePlayerIndex];
            dispatch(stateController.setState({ isLoading: true }));

            if (linkSent) {
                let timeoutId = setTimeout(() => {
                    dispatch(stateController.setState({ activePlayerIndex: substate.activePlayerIndex + 1, isLoading: false }));
                    clearTimeout(timeoutId);
                }, 1000);
            } else {
                let data = await ReonboardingServise.generateLink({ playerId: activePlayer.id, isRegular: activePlayer.isRegular });
                dispatch(stateController.setState({ activePlayerIndex: substate.activePlayerIndex + 1, isLoading: false, anyPlayersVerified: true }));
            }

            dispatch(userActivityInsert({
                Message: `Shared - Go to Next`,
                PageName: 'Verification Flow [Verify Players]',
                PlayerId: activePlayer.id,
            }));

            if (substate.activePlayerIndex + 1 >= substate.players.length) {
                if (totalPreparedPitches > 0) {
                    dispatch(stateController.setState({ activeStep: VerifyPlayersSteps.Done }))
                } else {
                    historyAccessor.push(agencyPaths.landingPagePlayers);
                }
            }
            dispatch(ReonboardingActions.disposeScrollingEffect());
        }
    }

    public static close() {
        return (dispatch, getState: () => AppState) => {
            const substate = getState().agency.reonboarding.verifyPlayers;
            historyAccessor.push(agencyPaths.landingPagePlayers);
            dispatch(userActivityInsert({
                Message: `Closed Info Screen`,
                PageName: 'Verification Flow [Verify Players]'
            }));
        }
    }

    public static sendEmail() {
        return async (dispatch, getState: () => AppState) => {
            const substate = getState().agency.reonboarding.verifyPlayers;
            let playersCopy = [...substate.players]
            let activePlayer = playersCopy[substate.activePlayerIndex];
            activePlayer.emailSending = true;
            dispatch(stateController.setState({ players: playersCopy }));
            await ReonboardingServise.sendVerificationEmail({ email: activePlayer.email, playerId: activePlayer.id, isRegular: activePlayer.isRegular });
            activePlayer.emailSending = false;
            activePlayer.emailSent = true;
            dispatch(stateController.setState({ players: [...playersCopy] }));
            dispatch(userActivityInsert({
                Message: `Sent by Email`,
                PageName: 'Verification Flow [Verify Players]',
                PlayerId: activePlayer.id,
            }));
        }
    }
    public static sendPhone() {
        return async (dispatch, getState: () => AppState) => {
            const substate = getState().agency.reonboarding.verifyPlayers;
            let playersCopy = [...substate.players]
            let activePlayer = playersCopy[substate.activePlayerIndex];
            activePlayer.phoneSending = true;
            dispatch(stateController.setState({ players: playersCopy }));
            await ReonboardingServise.sendVerificationSms({
                isInsightsShared: false, isRegular: activePlayer.isRegular, phone: activePlayer.phoneNumber, phoneCodeAreaId: activePlayer.phoneCodeAreaId, playerId: activePlayer.id
            });
            activePlayer.phoneSending = false;
            activePlayer.phoneSent = true;
            dispatch(stateController.setState({ players: [...playersCopy] }));
            dispatch(userActivityInsert({
                Message: `Sent by Phone Number`,
                PageName: 'Verification Flow [Verify Players]',
                PlayerId: activePlayer.id,
            }));
        }
    }

    public static sendWhatsapp() {
        return async (dispatch, getState: () => AppState) => {
            const substate = getState().agency.reonboarding.verifyPlayers;
            let activePlayer = substate.players[substate.activePlayerIndex];
            // window.open("https://wa.me/1234/?text=Hello%20world")
            //https://wa.me/?text=I'm%20inquiring%20about%20the%20apartment%20listing
            // dispatch(stateController.setState({ isLoading: true }));
            // var result = await ReonboardingServise.getVerificationMessages([activePlayer.id]);
            // dispatch(stateController.setState({ isLoading: false }));
            // window.open(`https://wa.me/?text=${result[0].message}`);
            let data = await ReonboardingServise.generateLink({ playerId: activePlayer.id, isRegular: activePlayer.isRegular });

            dispatch(userActivityInsert({
                Message: `Sent by WhatsApp`,
                PageName: 'Verification Flow [Verify Players]',
                PlayerId: activePlayer.id,
            }));
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState): State => state.agency.reonboarding.verifyPlayers;
    public static isLoading = (state: AppState): boolean => this.getRoot(state).isLoading || this.getRoot(state).activePlayerIndex == null;
    public static getCurrentStep = (state: AppState): VerifyPlayersSteps => this.getRoot(state).activeStep;
    public static getCurrentPlayerData = (state: AppState): PlayerVerificationData => {
        const substate = this.getRoot(state);
        if (substate.activePlayerIndex == null) return null;

        const player = substate.players[substate.activePlayerIndex];
        return player;
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    stateController as Controller,
    Selectors as Selectors
};