import axios from 'axios-config';

export enum InterestToPlayerType {
    RequestDeclareInterest = 0,
    RequestTransferAvailabily = 1,
    RequestLoanAvailabily = 2,
    RequestLoanContribution = 3,
    RequestAskingPrice = 4
}

export class PlayerService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async declareInterestSave(playerId: number, userId: number, squadId: number): Promise<any> {
        const { data } = await axios.post(
            `api/Players/DeclareInterestForPlayer/${playerId}/${userId}/${squadId}`,
            null,
            this.axiosConfig
        )
        InterestToPlayerType.RequestAskingPrice
        return data
    }

    public static async declareInterestToAgent(playerId: number): Promise<any> {
        const { data } = await axios.post(
            `api/v2/SquadAgentInterest/DeclareInterest/${playerId}`,
            null,
            this.axiosConfig
        )
        return data
    }

    public static async interestSave(playerId: number, userId: number, squadId: number, requestType: InterestToPlayerType) {
        return await axios.post(
            `api/Players/InterestToPlayerRequest/${playerId}/${userId}/${squadId}/${requestType}`,
            null,
            this.axiosConfig
        )
    }

    public static async addToShortList(playerId: any) {
        return await axios.put(
            `api/ShortList/1/${playerId}`,
            this.axiosConfig
        )
    }

    public static async deleteFromShortList(playerId: any) {
        return await axios.delete(
            `api/ShortList/1/${playerId}`,
            this.axiosConfig
        )
    }

    public static async trackProfileLoad(playerId: number){
        return await axios.post(`api/v2/Players/SaveProfileView/${playerId}`, {}, this.axiosConfig)
    }

    public static async profileRepresentationRequestLoad(playerId: number): Promise<number>{
        const { data } = await axios.get<number>(
            `api/Players/GetRepresentationRequestMessage/${playerId}`,
            this.axiosConfig,
        );
        return data;
    }
    
    public static async inviteToTrialRequest(playerId: number, message){
        return await axios.post(`api/v2/Messaging/Player/InviteToTrialRequest/${playerId}`,
            message,
            this.axiosConfig)
    }
}









