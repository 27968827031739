import {
    CLUB_ADS_FILTER_STRUCTURE_FETCH,
    CLUB_ADS_FILTER_SECTION_CLEAR,
    CLUB_ADS_FILTER_CRITERIA_CLEAR,
    CLUB_ADS_FILTER_KEYWORD_RESET,
    CLUB_ADS_FILTER_CHANGED,
    CLUB_ADS_FILTER_COLLAPSE_TOGGLE,
} from 'store/actionTypes';
import createApiThunk from 'services/createApiThunk';
import { createAction } from 'redux-actions';
import { getAuthAspId } from 'store/auth/authReducer';

export const clubAdsFilterChanged = createAction(CLUB_ADS_FILTER_CHANGED); 

export const clubAdsFilterStructureFetch = () => async (dispatch, getState) => {
    const aspId = getAuthAspId(getState());
    const request = {
        type: CLUB_ADS_FILTER_STRUCTURE_FETCH,
        scope: 'clubAds',
        method: 'post',
        url: `ClubAds/GetClubAdsFilterInitData?aspId=${aspId}`,
        success: CLUB_ADS_FILTER_STRUCTURE_FETCH.SUCCESS,
    };

    return await dispatch(createApiThunk(request));
};

export const clubAdsFilterSectionClear = createAction(
    CLUB_ADS_FILTER_SECTION_CLEAR,
);

export const clubAdsFilterCriteriaClear = createAction(
    CLUB_ADS_FILTER_CRITERIA_CLEAR,
);

export const clubAdsFilterKeywordReset = createAction(
    CLUB_ADS_FILTER_KEYWORD_RESET,
);

export const clubAdsFilterCollapseToggle = createAction(
    CLUB_ADS_FILTER_COLLAPSE_TOGGLE,
);
