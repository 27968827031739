import * as redux from 'redux';
import * as editPublicProfile from './edit-public-profile.controller';
import * as agencyPublicProfile from 'pages/agency/authorized-agent/public-profile/agency-profile/agency-profile.controller';
import * as squadSuggestion from 'pages/agency/authorized-agent/public-profile/edit-public-profile/track-record/squad-suggestion/squad-suggestion.controller';

export class AgencyPublicProfileState {
    editPublicProfile: editPublicProfile.State;
    agencyPublicProfile: agencyPublicProfile.State;
    squadSuggestion: squadSuggestion.State;
}

export function combineReducers() {

    let publicProfileReducer = {
        editPublicProfile: editPublicProfile.Reducer,
        agencyPublicProfile: agencyPublicProfile.Reducer,
        squadSuggestion: squadSuggestion.Reducer,
    };

    return redux.combineReducers(publicProfileReducer)
}