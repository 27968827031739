import { ROUTE_CHANGED } from 'store/actionTypes';
import { combineReducers } from 'redux';
import take from 'lodash/take';

export default combineReducers({ history });

function history(history = [], action) {
    switch (action.type) {
        case ROUTE_CHANGED: {
            return take([action.payload.to, ...history], 5);
        }
        default:
            return history;
    }
}

export const getRoutesHistory = state => state.routes.history;
