import { StateController } from 'utils/action-declaration';
import { LandingPageService } from 'api/landing-page/landing-page-service';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';

class PlayerTrendingState {
    canShowComponent: boolean;
    isDismissing: boolean;
    isLoading: boolean;
    players: Array<any>;
}

const defaultState: PlayerTrendingState = {
    canShowComponent: false,
    isDismissing: false,
    isLoading: false,
    players: []
}

const stateController = new StateController<PlayerTrendingState>(
    "LANDING_POAGE/PLAYERS_TRENDING",
    defaultState
);

class Actions {

    public static processPlayersTrendingComponentMounting() {
        return async (dispatch, getState) => {
            const searchParams = new URLSearchParams(window.location.search);
            let fromEmail = searchParams.get('fromPlayersTrendingEmail');
            if (fromEmail === 'true') {
                try {
                    const players = await LandingPageService.getTrendingPlayers()
                    if (players.length === 0) {
                        dispatch(stateController.setState({ canShowComponent: false }))
                    } else {
                        dispatch(stateController.setState({ players: players, canShowComponent: true }))
                    }
                } catch (e) {
                    console.error(e);
                }
            } else {
                const result = await LandingPageService.getIsPlayerTrendingDismissed();

                if (result.panelWasDismissed === false) {
                    try {
                        const players = await LandingPageService.getTrendingPlayers()
                        if (players.length === 0) {
                            dispatch(stateController.setState({ canShowComponent: false }))
                        } else {
                            dispatch(stateController.setState({ players: players, canShowComponent: true }))
                        }
                    } catch (e) {
                        console.error(e);
                    }
                }
            }
        }
    }

    public static dismiss(redirect) {
        return async (dispatch) => {
            try {
                dispatch(stateController.setState({ isDismissing: true }));
                await LandingPageService.dismissPlayersTrending();
                dispatch(stateController.setState({ canShowComponent: false }));
                redirect();
                dispatch(userActivityInsert({
                    PageName: "Home [Players trending]",
                    Message: "Dismissed",
                    PageType: PageType.Home
                }))
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(stateController.setState({ isDismissing: false }));
            }
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    PlayerTrendingState as State,
    Actions as Actions,
    stateController as Controller
};



