export const validatePriceInput = (val, type) => {
    let v = val.replace(/[^0-9]+/g, '').trim();
    if (v === '') {
        v = null;
        return v;
    }
    if (v[0] === '0' && v[1]) v = v[1];
    if (type === 'buy' && v.length >= 10) v = v.slice(0, 9);
    if (type === 'buy' && v >= 500000000) v = 500000000;
    if (type === 'loan' && v.length >= 8) v = v.slice(0, 7);
    if (type === 'loan' && v >= 5000000) v = 5000000;
    return v;
};

export const blockInvalidChar = (e) => ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault();