import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import VirtualSummitService from 'api/virtual-summit/virtual-summit.service';
import { Actions as ObjActions, State as ObjState } from './objectivities.controller';
import { Actions as InActions, State as InState } from './transfers-in.controller';
import { Actions as OutActions, State as OutState } from './transfers-out.controller';
import { AgendaModel, MeetingModel } from 'api/virtual-summit/models/meeting-preparation';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { Actions as PreparationActions } from 'app/events/redux/virtual-summit/meeting-preparation.controller';
import { PageType } from 'constants/enums';

export enum AgendaState {
    Objectivities = 1,
    TransfersIn = 3,
    TransfersOut = 2,
    Done = 4
}

class B2MatchState {
    isLoading: boolean;
    step: AgendaState;
    meetingClubName: string;
    meetingId: number;
    agendaId: number;
    scheduledTime: Date;
    nextMeetingId: number;
}

const defaultState: B2MatchState = {
    isLoading: false,
    step: AgendaState.Objectivities,
    meetingClubName: null,
    meetingId: null,
    agendaId: null,
    scheduledTime: null,
    nextMeetingId: null
}

const stateController = new StateController<B2MatchState>(
    "VIRTUAL_SUMMIT/B2MATCH",
    defaultState
);

class Actions {
    public static showDoneStep(): any {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ step: AgendaState.Done }));
        }
    }


    public static getData = (id: number) => {
        return async (dispatch, getState: () => AppState) => {
            let appState = getState();
            let { userId, eventId } = appState.auth;
            dispatch(stateController.setState({ isLoading: true }));

            const data = await VirtualSummitService.getPreparationByMeetingId(userId, id);
            var all = await VirtualSummitService.getMeetingPraparationData(userId);

            var relatedMeetingTable = all.tables.filter(x => x.meetings.find(s => s.id == id));
            var relatedMeetings = relatedMeetingTable[0].meetings;

            let isNotPassed = (meeting: MeetingModel) => {
                if (meeting.id == id) return false;
                let myAgendaModel: AgendaModel = meeting.host;
                let opponentAgendaModel: AgendaModel = meeting.guest;

                if (!meeting.amIHost) {
                    myAgendaModel = meeting.guest;
                    opponentAgendaModel = meeting.host;
                }

                if(opponentAgendaModel.isAgency) return false;

                return myAgendaModel.players.length === 0;
            }

            var filteredMeetings = relatedMeetings.filter(isNotPassed).map(item => item.id);
            var nextMeeting = filteredMeetings[0];

            let agendaModel: AgendaModel = data.amIHost ? data.host : data.guest;
            let opponentAgendaModel: AgendaModel = data.amIHost ? data.guest : data.host;

            dispatch(ObjActions.setInitialData({
                isLoading: false,
                moreAccessRequested: false,
                transferIn: agendaModel.agendaTransferIn,
                transferOut: agendaModel.agendaTransferOut,
                transferOutBlocked: !agendaModel.allowChangeAvailability,
                transferOutDisabled: !agendaModel.canTransferOut,
            }));

            dispatch(stateController.setState({
                isLoading: false,
                meetingId: id,
                agendaId: agendaModel.id,
                meetingClubName: opponentAgendaModel.squadName,
                scheduledTime: data.schedulledTime,
                nextMeetingId: nextMeeting
            }));

            dispatch(InActions.setSelectedPlayers(agendaModel.playersIn.map(i => i.id)));
            dispatch(OutActions.setSelectedPlayers(agendaModel.playersOut.map(i => i.id)));
            
            let { scheduledTime, meetingClubName } = getState().events.virtualSummit.b2match;
            dispatch(userActivityInsert({
                PageName: 'Event [Agenda Builder]',
                Message: `Opened meeting set up (${meetingClubName} at ${scheduledTime})`,
                PageType: PageType.Events,
                EventId: eventId
            }))
        }
    }

    public static saveObjectivities = () => {
        return async (dispatch, getState: () => AppState) => {
            let appState = getState();
            let { userId, eventId } = appState.auth;
            let substate: ObjState = appState.events.virtualSummit.b2matchObjectivities;
            let substateMain: B2MatchState = getState().events.virtualSummit.b2match;
            dispatch(stateController.setState({ isLoading: true }));
            const data = await VirtualSummitService.saveAgendaObjectivities(substateMain.agendaId, substate.transferIn, substate.transferOut);
            dispatch(stateController.setState({ isLoading: false }));

            let b2matchObjectivities = getState().events.virtualSummit.b2matchObjectivities
            let canShowTransferOut = b2matchObjectivities.transferOut
            if (canShowTransferOut) {
                dispatch(Actions.goNext())
            } else {
                dispatch(Actions.skipOneStep())
            }


            let { scheduledTime, meetingClubName } = getState().events.virtualSummit.b2match
            if (substate.transferIn) {
                
                dispatch(userActivityInsert({
                    PageName: 'Event [Agenda Builder]',
                    Message: `Selected Transfers In (${meetingClubName} at ${scheduledTime})`,
                    PageType: PageType.Events,
                    EventId: eventId
                }))
            }
            if (substate.transferOut) {
                dispatch(userActivityInsert({
                    PageName: 'Event [Agenda Builder]',
                    Message: `Selected Transfers Out (${meetingClubName} at ${scheduledTime})`,
                    PageType: PageType.Events,
                    EventId: eventId
                }))
            }

        }
    }

    public static goNext = () => {
        return async (dispatch, getState) => {
            let substate: B2MatchState = getState().events.virtualSummit.b2match;
            let nextStep = substate.step + 1;
            dispatch(stateController.setState({ step: nextStep }));
        }
    }

    public static skipOneStep = () => {
        return async (dispatch, getState) => {
            let substate: B2MatchState = getState().events.virtualSummit.b2match;
            let nextStep = substate.step + 2;
            dispatch(stateController.setState({ step: nextStep }));
        }
    }

    public static goBack = () => {
        return async (dispatch, getState) => {
            let substate: B2MatchState = getState().events.virtualSummit.b2match;
            let nextStep = substate.step - 1;
            dispatch(stateController.setState({ step: nextStep }));
        }
    }

    public static goBackFromTrIn = () => {
        return async (dispatch, getState) => {
            let appState = getState();
            let substate: B2MatchState = appState.events.virtualSummit.b2match;
            let substate1: ObjState = appState.events.virtualSummit.b2matchObjectivities;

            let nextStep;
            if (substate1.transferOut)
                nextStep = substate.step - 1;
            else
                nextStep = substate.step - 2;

            dispatch(stateController.setState({ step: nextStep }));
        }
    }

    public static goBackFromTrOut = () => {
        return async (dispatch, getState) => {
            let appState = getState();
            let substate: B2MatchState = appState.events.virtualSummit.b2match;
            let substate1: ObjState = appState.events.virtualSummit.b2matchObjectivities;

            let nextStep;
            if (substate1.transferIn)
                nextStep = substate.step - 1;
            else
                nextStep = substate.step - 2;

            dispatch(stateController.setState({ step: nextStep }));
        }
    }

    public static dispose = () => {
        return async (dispatch, getState: () => AppState) => {
            let appState = getState();
            dispatch(stateController.setState(defaultState));
        }
    }

    public static disposeAll = () => {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(defaultState));
            dispatch(ObjActions.dispose());
            dispatch(InActions.dispose());
            dispatch(OutActions.dispose());
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    B2MatchState as State,
    Actions as Actions,
    stateController as Controller
};



