import { getRequests } from 'store/requests/requestsReducer';
import { asyncActionType } from 'store/actionTypes';
import { createAction } from 'redux-actions';

const initialState = {
    items: [],
    currentClub: null,
    currentCurrency: null,
    selectedCountryId: null,
    selectedCompetitionId: null,
};

export default (searchBarState = initialState, action) => {
    switch (action.type) {
        case SEARCH_BAR_FETCH_CLUBS.SUCCESS: {
            return {
                ...searchBarState,
                items: action.payload.data,
            };
        }

        case SEARCH_BAR_SET_CURRENT_CLUB: {
            return {
                ...searchBarState,
                currentClub: action.payload,
                currentCurrency:
                    searchBarState.currentCurrency || action.payload.currency,
            };
        }

        case SEARCH_BAR_SET_CURRENT_CURRENCY: {
            return {
                ...searchBarState,
                currentCurrency: action.payload,
            };
        }

        case SEARCH_BAR_SET_CURRENT_COUNTRY: {
            return {
                ...searchBarState,
                selectedCountryId: action.payload,
            };
        }

        case SEARCH_BAR_SET_CURRENT_COMPETITION: {
            return {
                ...searchBarState,
                selectedCompetitionId: action.payload,
            };
        }

        default:
            return searchBarState;
    }
};

export const SEARCH_BAR_FETCH_CLUBS = asyncActionType(
    'app/financial-insights/SEARCH_BAR_FETCH_CLUBS',
);

export const SEARCH_BAR_FETCH_CURRENT_CLUB = asyncActionType(
    'app/financial-insights/SEARCH_BAR_FETCH_CURRENT_CLUB',
);

export const SEARCH_BAR_SET_CURRENT_CLUB =
    'app/financial-insights/SEARCH_BAR_SET_CURRENT_CLUB';
export const SEARCH_BAR_SET_CURRENT_CURRENCY =
    'app/financial-insights/SEARCH_BAR_SET_CURRENT_CURRENCY';
export const SEARCH_BAR_SET_CURRENT_COUNTRY =
    'app/financial-insights/SEARCH_BAR_SET_CURRENT_COUNTRY';
export const SEARCH_BAR_SET_CURRENT_COMPETITION =
    'app/financial-insights/SEARCH_BAR_SET_CURRENT_COMPETITION';

export const setCurrentClub = createAction(SEARCH_BAR_SET_CURRENT_CLUB);
export const getCurrentClub = state =>
    state.financialInsights.searchClub.currentClub;
export const setCurrentCurrency = createAction(SEARCH_BAR_SET_CURRENT_CURRENCY);
export const getCurrentCurrency = state =>
    state.financialInsights.searchClub.currentCurrency;

export const setCountry = createAction(SEARCH_BAR_SET_CURRENT_COUNTRY);
export const getCountry = state =>
    state.financialInsights.searchClub.selectedCountryId;

export const setCompetition = createAction(SEARCH_BAR_SET_CURRENT_COMPETITION);
export const getCompetition = state =>
    state.financialInsights.searchClub.selectedCompetitionId;

export const getCountries = state => {
    let items = state.financialInsights.searchClub.items;
    let countries = [];
    for (var item of items) {
        if (countries.map(item => item.key).indexOf(item.areaId) === -1) {
            countries.push({ key: item.areaId, name: item.areaName });
        }
    }

    return countries;
};

export const getCompetitions = state => {
    let subState = state.financialInsights.searchClub;
    let items = subState.items;
    let selectedCountryId = subState.selectedCountryId;
    if (selectedCountryId === null) return [];
    let competitions = [];
    for (var item of items.filter(item => item.areaId === selectedCountryId)) {
        if (competitions.map(item => item.key).indexOf(item.id) === -1) {
            competitions.push({ key: item.id, name: item.name });
        }
    }
    return competitions;
};

export const getSquads = state => {
    let subState = state.financialInsights.searchClub;
    let items = subState.items;
    let selectedCompetitionId = subState.selectedCompetitionId;
    if (selectedCompetitionId === undefined) return [];

    let competition = items.find(item => item.id === selectedCompetitionId);
    if (competition === undefined) return [];
    return competition.squads;
};

export const getSearchIsLoading = state =>
    getRequests(state).some(r => r.type === SEARCH_BAR_FETCH_CLUBS|| r.type===SEARCH_BAR_FETCH_CURRENT_CLUB);
