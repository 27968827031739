import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import AgencyPlayerService from 'api/agency/player/shared/agency-player-service';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';

export class ModalData {
    requestSent: boolean;
    isRegular: boolean;
    modalType: RestrictedCoachCreditsModalType;
    coachId?: number;
    squadId?: number;
}

export enum RestrictedCoachCreditsModalType {
    UpgradeHeadCoach = 1,
    AddHeadCoach = 2,
    // CreditRequest = 3,
}

class State {
    modalData: ModalData;
}

const defaultState: State = {
    modalData: null,
}

const stateController = new StateController<State>(
    "AGENCY/RESTRICTED_HEAD_COACH_CREDITS_MODAL",
    defaultState
)

class Actions {

    public static openRestrictedPriorityCreditsModal(modalType: RestrictedCoachCreditsModalType, coachId: number = null, squadId: number = null) {
        return (dispatch) => {
            dispatch(stateController.setState({
                modalData: {
                    requestSent: false,
                    isRegular: false,
                    modalType: modalType,
                    coachId: coachId,
                    squadId: squadId
                }
            }))
        }
    }

    public static openRestrictedRegularCreditsModal(modalType: RestrictedCoachCreditsModalType) {
        return (dispatch) => {
            dispatch(stateController.setState({
                modalData: {
                    requestSent: false,
                    isRegular: true,
                    modalType: modalType
                }
            }))
        }
    }

    public static closeRestrictedCreditsModal(isSentSuccessfully?: boolean) {
        return async (dispatch, getState: () => AppState) => {
            const { modalData } = getState().agency.agent.restrictedCoachCreditsModal;

            dispatch(stateController.setState({ modalData: null }));

            if (isSentSuccessfully) {
                dispatch(userActivityInsert({
                    PageName: Actions.getPageName(modalData.modalType),
                    Message: `Clicked Close`,
                    CoachId: modalData.coachId,
                    ClubId: modalData.squadId,
                    PageType: Actions.getPageType(modalData.modalType)
                }))
            } else {
                dispatch(userActivityInsert({
                    PageName: Actions.getPageName(modalData.modalType),
                    Message: `Closed${modalData.isRegular ? '' : ' Priority'} Credits Request`,
                    CoachId: modalData.coachId,
                    ClubId: modalData.squadId,
                    PageType: Actions.getPageType(modalData.modalType)
                }))
            }

        }
    }

    public static collapsedRestrictedCreditsModal(isSentSuccessfully?: boolean) {
        return async (dispatch, getState: () => AppState) => {
            const { modalData } = getState().agency.agent.restrictedCoachCreditsModal;

            dispatch(stateController.setState({ modalData: null }));

            if (isSentSuccessfully) {
                dispatch(userActivityInsert({
                    PageName: Actions.getPageName(modalData.modalType),
                    Message: `Collapsed Credit Request Sent Successfully Pop-up`,
                    CoachId: modalData.coachId,
                    ClubId: modalData.squadId,
                    PageType: Actions.getPageType(modalData.modalType)
                }))
            } else {
                dispatch(userActivityInsert({
                    PageName: Actions.getPageName(modalData.modalType),
                    Message: `Collapsed Request Credits Pop-up`,
                    CoachId: modalData.coachId,
                    ClubId: modalData.squadId,
                    PageType: Actions.getPageType(modalData.modalType)
                }))
            }

        }
    }

    public static requestMoreCredits() {
        return async (dispatch, getState: () => AppState) => {
            const { modalData } = Selectors.getRoot(getState());
            try {
                await AgencyPlayerService.requestPlayerCreditsEmail({
                    isRegular: modalData.isRegular,
                    isPriority: !modalData.isRegular
                });
                dispatch(userActivityInsert({
                    PageName: Actions.getPageName(modalData.modalType),
                    Message: `Sent${modalData.isRegular ? '' : ' Priority'} Credits Request`,
                    CoachId: modalData.coachId,
                    ClubId: modalData.squadId,
                    PageType: Actions.getPageType(modalData.modalType)
                }))

                dispatch(stateController.setState({
                    modalData: {
                        ...modalData,
                        requestSent: true
                    }
                }))

                dispatch(userActivityInsert({
                    PageName: Actions.getPageName(modalData.modalType),
                    Message: `Opened Credit Request Sent Successfully Pop-up`,
                    CoachId: modalData.coachId,
                    ClubId: modalData.squadId,
                    PageType: Actions.getPageType(modalData.modalType)
                }))
            } catch (err) {
                console.error(err)
            }
        }
    }


    private static getPageName(modalType: RestrictedCoachCreditsModalType) {
        let pageName = '';
        switch (modalType) {
            case RestrictedCoachCreditsModalType.UpgradeHeadCoach:
                pageName = 'Agency Head Coaches [Upgrade Head Coach]';
                break;
            case RestrictedCoachCreditsModalType.AddHeadCoach:
                pageName = 'Agency Home [Coach Verification]'
                break;
        }
        return pageName;
    }

    private static getPageType(modalType: RestrictedCoachCreditsModalType) {
        // if (modalType === RestrictedCoachCreditsModalType.UpgradeHeadCoach) {
        //     return PageType.;
        // }
        return PageType.AgencyCoaches;
    }
}

class Selectors {
    public static getRoot = (state: AppState): State => state.agency.agent.restrictedCoachCreditsModal;
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};