import { getRequests } from 'store/requests/requestsReducer';
import { asyncActionType } from 'store/actionTypes';

const initialState = {
    avaragePlayerAgeFormatted: null,
    avaragePlayerValuationFormatted: null,
    squadCountry: null,
    squadLeague: null,
    squadLogo: null,
    squadName: null,
    squadSize: null,
};

export default (playersClubState = initialState, action) => {
    switch (action.type) {
        case PLAYERS_CLUB_INFO_FETCH.SUCCESS: {
            return {
                ...playersClubState, ...action.payload.data,
            };
        }
        case PLAYERS_CLUB_INFO_FETCH.REQUEST:
            return { ...playersClubState };

        default:
            return playersClubState;
    }
};


export const PLAYERS_CLUB_INFO_FETCH = asyncActionType('app/financial-insights/PLAYERS_CLUB_INFO_FETCH');

export const getPlayersClubInfoLoadingStatus = state =>
    getRequests(state).some(r => r.type === PLAYERS_CLUB_INFO_FETCH);


