import { AppState } from 'root.reducer';
import historyAccessor from 'history-accessor';
import { StateController } from 'utils/action-declaration';
import AgencyTransparencyService from "api/agency/agent/agency-transparency/agency-transparency.service";
import { PlayerSharedActivityHistory, ActivityListModel } from "api/agency/agent/agency-transparency/models";
import { getActivityListText, getImageSrc, getFormattedDate } from "./helpers/latest-activity.helpers";
import userActivityInsert from "../../../app/user-activity/actions/user-activity.actions";
import { PageType } from "../../../constants/enums";
import { playerPaths } from 'routes/paths'

class State {
  isLoading: boolean;
  activityList: ActivityListModel[] | null;
}

const defaultState: State = {
  isLoading: false,
  activityList: null
}

const stateController = new StateController<State>(
  "PLAYER/LATEST-ACTIVITY",
  defaultState
);

class Actions {
  public static dispose() {
    return (dispatch) => {
      dispatch(stateController.setState({ ...defaultState }));
    }
  }

  public static init() {
    return async (dispatch, getState: () => AppState) => {
    try {
        dispatch(stateController.setState({ isLoading: true }));
        const data = await AgencyTransparencyService.getPlayerActivityHistory();
        const convertedActivityList = data.items.map((item: PlayerSharedActivityHistory) => ({
          id: `${item.actionType}-${item.actionDate}`,
          isSharedByAgent: item.isSharedByAgent,
          date: item.actionDate,
          dateFormatted: getFormattedDate(item.actionDate),
          imageSrc: getImageSrc(item),
          text: getActivityListText(item)
        }));

        dispatch(stateController.setState({ activityList: convertedActivityList }));
      } catch (err) {
        console.error(err)
      } finally {
        dispatch(stateController.setState({ isLoading: false}));
      }
    }
  }

  public static onBackBtnClick() {
    return (dispatch) => {

      if(historyAccessor.length > 0 && historyAccessor.location.pathname !== playerPaths.latestActivity && historyAccessor.location.pathname !== playerPaths.latestActivity + '/'){        
        historyAccessor.goBack();
      }
      else{
        historyAccessor.push(playerPaths.homePage);
      }

      dispatch(userActivityInsert({
        PageName: 'My Page [Latest Activity]',
        Message: 'Moved Back',
        PageType: PageType.PlayerPage,
      }))
    }
  }
}

class Selectors {
  public static getRoot = (state: AppState): State => state.player.latestActivity;
  public static isLoading = (state: AppState) => Selectors.getRoot(state).isLoading;
  public static getActivityList = (state: AppState) => Selectors.getRoot(state).activityList;
}

const reducer = stateController.getReducer();

export {
  reducer as Reducer,
  State as State,
  Actions as Actions,
  stateController as Controller,
  Selectors as Selectors
};