import { StateController } from 'utils/action-declaration';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { Actions as DashboardActions } from './dashboard.controller';
import { Actions as PitchInvitations } from './pitch-invitation.controller';
import { Actions as NotificationsActions } from './notification.controller';
import { Actions as BookSessionController } from 'pages/agency/authorized-agent/home-page/redux/book-session.controller'
import { Actions as PlayerMarketBannerController} from 'pages/agency/authorized-agent/home-page/redux/player-market-banner-controller'
import {Actions as RepresentationRequestController} from 'pages/agency/authorized-agent/home-page/redux/representation-request.controller'
import * as PlayerRequestController from 'pages/agency/authorized-agent/home-page/redux/player-request.controller';
import { PageType } from 'constants/enums';

class CommonState {
    isLoading: boolean;
}

const defaultState: CommonState = {
    isLoading: false,
}

const stateController = new StateController<CommonState>(
    "AGENCY/LANDING_PAGE/COMMON",
    defaultState
);

class Actions {

    public static loadAllActivityTabData(adId: number) {
        return dispatch => {
            dispatch(stateController.setState({ isLoading: true }))

            return Promise.allSettled([
                dispatch(NotificationsActions.loadNotification()),
                dispatch(PitchInvitations.loadAds(adId)),
                dispatch(DashboardActions.loadLandingPageData()),
                dispatch(PlayerRequestController.Actions.loadPlayerRequest()),
                dispatch(RepresentationRequestController.loadRepresentationRequests())
            ]).then(() => {
                dispatch(stateController.setState({ isLoading: false }))
                dispatch(userActivityInsert({
                    PageName: 'Agency Home [Activity]',
                    Message: 'Opened Activity',
                    PageType: PageType.AgencyHome,
                }))
                dispatch(BookSessionController.processAutoOpenModalFromEmail())
                dispatch(PlayerMarketBannerController.init())
            }).catch((e) => console.error(e))
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    CommonState as State,
    Actions as Actions,
    stateController as Controller
};
