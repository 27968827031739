export const benefits = [
    'Exclusively for decision makers',
    'Boost your global network',
    '20 x 12 min meetings with other clubs',
    'Get down to business fast'
]

export const agentBenefits = [
    'Exclusively for decision makers',
    'Boost your global network',
    '20 x 12 min meetings with other clubs',
    'Get down to business fast'
]