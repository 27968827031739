import {StateController} from 'utils/action-declaration'
import {AppState} from "root.reducer";
import history from "../../history-accessor";
import {anonymousPaths} from "../../routes/paths";
import {resetPassword} from "../../app/reset-password/actions/reset-password.action";
import AgencyProfileService from "../../api/user-password/user-password";

export enum ResetPasswordSteps {
	ResetPassword,
	ThankYouModal,
}

class State {
	step: ResetPasswordSteps;
	isThankYouModalOpen: boolean;
	isTokenValid: boolean;
	init: boolean;
	token: string;
	isLoading: boolean;
}

const defaultState = {
	step: ResetPasswordSteps.ResetPassword,
	isThankYouModalOpen: false,
	token: '',
	isTokenValid: true,
	isLoading: false,
	init: false,
}

const stateController = new StateController<State>(
	'PLAYER/RESET_PASSWORD',
	defaultState
)

class Actions {
	public static dispose() {
		return (dispatch, getState: () => AppState) => {
			dispatch(stateController.setState({ step: ResetPasswordSteps.ResetPassword }));
		}
	}
	
	public static init(token) {
		return async (dispatch, getState: () => AppState) => {
			try {
				dispatch(stateController.setState(prevState => (
					{
						...prevState,
						isLoading: true,
					})));
				
				const resp = await AgencyProfileService.checkResetPassword(token);
				dispatch(stateController.setState(prevState => (
					{
						...prevState,
						isLoading: false,
					})));
			
				if (!resp) {
					dispatch(stateController.setState(prevState => (
						{ ...prevState,
							isTokenValid: false,
							init: true,
							token,
						})));
				} else {
					dispatch(stateController.setState(prevState => (
						{ ...prevState,
							isTokenValid: true,
							email: resp,
							init: true,
							token,
						})));
					
				}
			} catch (e) {
				console.log(e)
			}
		}
	}
	
	public static setStep(step: ResetPasswordSteps) {
		return (dispatch) => {
			dispatch(stateController.setState({ step: step }));
		}
	}
	
	public static closeThankYouModal() {
		return (dispatch) => {
			history.push(anonymousPaths.welcomeBackLogin);
		}
	}
	
	public static changePassword(email, pwd, token) {
		return async (dispatch, getState) => {
			try {
				await dispatch(resetPassword(email, pwd, token));
			} catch (err) {
				console.error(err);
			} finally {
				dispatch(stateController.setState((prevState) => ({
					step: ResetPasswordSteps.ThankYouModal,
				})));
			}
		}
	}
	
	public static sendUserActivity(message: string, agencyId: number) {
		return (dispatch, getState: () => AppState) => {
			// const { playerId } = getAuth(getState());
			//
			// dispatch(userActivityInsert({
			// 	PageName: 'Agent Confirmation',
			// 	Message: message,
			// 	PlayerId: playerId,
			// 	AgencyId: agencyId,
			// }))
		}
	}
}

class Selectors {
	public static getRoot = (state: AppState): State => state.resetPassword;
	public static getCurrentStep = (state: AppState) => Selectors.getRoot(state).step;
}

const reducer = stateController.getReducer()

export {
	reducer as Reducer,
	State as State,
	Actions as Actions,
	Selectors as Selectors,
	stateController as Controller
}