import moment from 'moment'
import { AppState } from 'root.reducer'
import { getAuth } from 'store/auth/authReducer'
import { StateController } from 'utils/action-declaration'
import PlayerMarketBannerService from 'api/agency/agent/landing/player-market-banner.service'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';

const LOCAL_STORAGE_KEY = 'playerMarketBanner'
class State {
    isLoading: boolean
    isShowBanner: boolean
    isThankYouModalOpen: boolean
}

const defaultState: State = {
    isLoading: false,
    isShowBanner: false,
    isThankYouModalOpen: false,
}

const stateController = new StateController<State>(
    "AGENCY/LANDING_PAGE/PLAYER_MARKET_BANNER",
    defaultState
)

class Actions {

    //======= Player market banner ===================================================================================================
    public static init() {
        return async (dispatch, getState: () => AppState) => {
            const userId = getAuth(getState()).userId
            const localStorageInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) ?? {}
            const oldDate = localStorageInfo[userId]
            const agencyPlayerMarket = getAuth(getState()).agencyPermission.agencyPlayerMarket
            const playerMarketRequested = getState().agency.landingPage.dashboard.dashboard.playerMarketRequested;
            const currentDate = moment()
            const difference = currentDate.diff(oldDate, 'day')  // oldDate, set 'minute' for testing

            if (playerMarketRequested || agencyPlayerMarket === true) {
                dispatch(stateController.setState({ isShowBanner: false }))
                return
            }
            if (!oldDate) {
                dispatch(stateController.setState({ isShowBanner: true }))
                return
            } else if (difference >= 7) {
                dispatch(stateController.setState({ isShowBanner: true }))
                return
            } else {
                dispatch(stateController.setState({ isShowBanner: false }))
                return
            }
        }
    }
    public static remindMeLater() {
        return (dispatch, getState: () => AppState) => {
            const userId = getAuth(getState()).userId
            const localStorageInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) ?? {}
            const dateObject = {
                ...localStorageInfo,
                [userId]: `${moment()}` 
            }

            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(dateObject))
            dispatch(Actions.init())

            dispatch(userActivityInsert({
                PageName: 'Agency Home [Player Market Banner]',
                Message: 'Remind Me Later',
                PageType: PageType.AgencyHome,
            }));
        }
    }
    public static interested() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isLoading: true }))

                // const localStorageInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) ?? {}
                // const userId = getAuth(getState()).userId
                // const dateObject = {
                //     ...localStorageInfo,
                //     [userId]: 'Never again!' 
                // }
                // localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(dateObject))

                await PlayerMarketBannerService.interest()

                dispatch(stateController.setState({ isThankYouModalOpen: true }))
                dispatch(stateController.setState({ isShowBanner: false }));

                dispatch(userActivityInsert({
                    PageName: 'Agency Home [Player Market Banner]',
                    Message: 'Interested',
                    PageType: PageType.AgencyHome,
                }));
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState({ isLoading: false }))
            }
        }
    }

    //======= Thank You modal ========================================================================================================

    public static toggleOfThankYouModal(value?: boolean) {
        return (dispatch, getState: () => AppState) => {
            if (value !== undefined) {
                dispatch(stateController.setState({ isThankYouModalOpen: value }))
                return
            }
            dispatch(stateController.setState({ isThankYouModalOpen: !getState().agency.landingPage.playerMarketBanner.isThankYouModalOpen }))
        }
    }
}


const reducer = stateController.getReducer()

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    stateController as Controller,
}