import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer'
import VirtualSummitService from 'api/virtual-summit/virtual-summit.service';


export class Attendee {
    squadId: number;
    squadName: string;
    squadLogo: string;
    transferInStepCompleted: boolean;
    transferOutStepCompleted: boolean;
    generalNetworkingStepCompleted: boolean;
    adPositionNames: Array<string>;
    countOfAvailablePlayers: number;
}
export class ConfirmedAttendees {
    attendees: Array<Attendee>;
    isLoading: boolean
}

const defaultState: ConfirmedAttendees = {
    attendees: [],
    isLoading: false
}

const stateController = new StateController<ConfirmedAttendees>(
    "EVENT/CONFIRMED-ATTENDEES",
    defaultState
);

class Actions {
    
    public static loadConfirmedAttendeesData = () => {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));
            let data = await VirtualSummitService.getConfirmedAttendeesData();
            if (data) {
                dispatch(stateController.setState({ 
                    attendees: data,
                    isLoading: false 
                }));
            } else {
                dispatch(stateController.setState({ isLoading: false }));
            }
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    ConfirmedAttendees as State,
    Actions as Actions,
    stateController as Controller
};



