import { PLUS_PITCH_REACTIONS_FETCH } from './indexReducer';

const initialState = {
};

export default (reactionsState = initialState, action) => {
    switch (action.type) {

        case PLUS_PITCH_REACTIONS_FETCH.REQUEST: {
            return {
                ...reactionsState,
                [action.payload.payload.pitchId]: {
                    isLoading: true
                }
            }
        }

        case PLUS_PITCH_REACTIONS_FETCH.SUCCESS: {
            return {
                ...reactionsState,
                [action.payload.request.payload.pitchId]: {
                    isLoading: false,
                    reactions: [...action.payload.data]
                }
            }
        }
        default:
            return reactionsState;
    }
};