import * as redux from 'redux';
import * as playerProposalsController from './player-proposals.controller';
import * as outdatedAdsController from './outdated-ads';
import * as friendlyProposalsController from './friendly-proposals.controller';
import * as clubAdsProposalsController from './club-ads-proposals.controller';

export class LandingState {
    playerProposals: playerProposalsController.State;
    outdatedAds: outdatedAdsController.State;
    friendlyProposals: friendlyProposalsController.State;
    clubAdsProposals: clubAdsProposalsController.State;
}

export function combineReducers() {

    let landingReducer = {
        playerProposals: playerProposalsController.Reducer,
        outdatedAds: outdatedAdsController.Reducer,
        friendlyProposals: friendlyProposalsController.Reducer,
        clubAdsProposals: clubAdsProposalsController.Reducer
    };

    return redux.combineReducers(landingReducer)
} 