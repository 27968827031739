import React, { Component } from 'react'
import { connect } from 'react-redux'
import EventPageNotRegistered from './content/events.entry'
import FeatureCoordinator from 'utils/featureCoordinator'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { Actions as MiscEventController, Selectors as MiscEventSelectors } from 'app/events/redux/misc.controller'
import { Actions as AgencyEventController } from 'app/events/redux/agency-event.controller'
import Spinner from 'components/Spinner/Spinner';
import { PageType } from 'constants/enums';
import { getAuth } from 'store/auth/authReducer';
import AuthState from 'store/auth/auth-state';

class StateProps {
    isLoading: boolean;
    auth: AuthState;
}
class DispatchProps {
    getRegistrationState: () => void;
    userActivityInsert: (obj: any) => void;
}

class EventsSwitch extends Component<StateProps & DispatchProps, {}> {

    componentDidMount() {

        document.title = 'Events';

        const { eventId } = this.props.auth;

        this.props.userActivityInsert({
            Message: 'Opened Page',
            PageName: 'Event',
            PageType: PageType.AgencyEvents,
            EventId: eventId
        });

        this.props.getRegistrationState()

        // this is temporary, until we need to define red icon in Event menu
        // by user's visit to Event page
        FeatureCoordinator.setIsDisplayed(FeatureCoordinator.eventsNewFeature);
    }

    render() {
        const { isLoading } = this.props;

        if (isLoading) return <Spinner />;
        return <EventPageNotRegistered />
    }
}

const mapStateToProps = state => ({
    isLoading: MiscEventSelectors.selectIsLoading(state),
    upcommingEventData: state.events.misc.upcommingSummitData,
    auth: getAuth(state),
});

const mapDispatchToProps = {
    getRegistrationState: AgencyEventController.getRegistrationState,
    userActivityInsert,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsSwitch)
