import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer'
import VirtualSummitService from 'api/virtual-summit/virtual-summit.service'
import { PreparationTransferIn } from 'api/virtual-summit/models/preparation-transfer-in'
import { Actions as MainActions } from './b2match.controller'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';

class B2MatchTransfersInState {
    isLoading: boolean;
    players: PreparationTransferIn;
    selectedIds: Array<number>;
    processing: boolean
}

const defaultState: B2MatchTransfersInState = {
    isLoading: false,
    players: [],
    selectedIds: [],
    processing: false
}

const stateController = new StateController<B2MatchTransfersInState>(
    "VIRTUAL_SUMMIT/B2MATCH_TRANSFERS-IN",
    defaultState
);

class Actions {
    public static getData = () => {

        return async (dispatch, getState: () => AppState) => {
            let state = getState();
            let { userId, eventId } = state.auth;
            let meetingId = state.events.virtualSummit.b2match.agendaId;

            dispatch(stateController.setState({ isLoading: true }));
            const data = await VirtualSummitService.getPreparationTransferIn(userId, meetingId)

            dispatch(stateController.setState({
                players: data,
                isLoading: false
            }));

            let { scheduledTime, meetingClubName } = getState().events.virtualSummit.b2match;
            dispatch(userActivityInsert({
                PageName: 'Event [Agenda Builder]',
                Message: `Opened Transfers In set up (${meetingClubName} at ${scheduledTime})`,
                PageType: PageType.Events,
                EventId: eventId
            }));
        }
    }

    public static openTooltip = (playerId: number, clubId) => {
        return async (dispatch, getState: () => AppState) => {
            const appState =  getState();
            let { scheduledTime, meetingClubName } = appState.events.virtualSummit.b2match;
            let { userId, eventId } = appState.auth;

            dispatch(userActivityInsert({
                Message: `Opened Recommender Tooltip in Transfers In Set Up (${meetingClubName} at ${scheduledTime})`,
                PageName: 'Event [Agenda Builder] ',
                PageType: PageType.Events,
                PlayerId: playerId,
                ClubId: clubId,
                EventId: eventId
            }));
        }
    }

    public static continue = () => {
        return async (dispatch, getState: () => AppState) => {

            let state = getState();
            let { userId, eventId } = state.auth;
            let meetingId = state.events.virtualSummit.b2match.agendaId;

            dispatch(stateController.setState({ processing: true }));

            await VirtualSummitService.SetTransferInPlayers(userId, meetingId, state.events.virtualSummit.b2matchTransfersIn.selectedIds);

            dispatch(stateController.setState({ processing: false }));

            let { scheduledTime, meetingClubName } = getState().events.virtualSummit.b2match
            let { players, selectedIds } = getState().events.virtualSummit.b2matchTransfersIn
            let playersNames = []
            selectedIds.forEach(i => {
                let player = players.find(item => item.id === i)
                playersNames.push(player.name)
            })

            dispatch(userActivityInsert({
                PageName: 'Event [Agenda Builder]',
                Message: `Selected Players for Transfers In: ${playersNames.join(', ')} (${meetingClubName} at ${scheduledTime})`,
                PageType: PageType.Events,
                EventId: eventId
            }));

            await VirtualSummitService.CompleteStepper(userId, meetingId);
            dispatch(MainActions.showDoneStep())
        }
    }

    public static onChange = (playerId: number) => {
        return (dispatch, getState: () => AppState) => {
            let selectedIds = getState().events.virtualSummit.b2matchTransfersIn.selectedIds
            let newSelection;
            if (selectedIds.includes(playerId)) {
                newSelection = selectedIds.filter(i => i !== playerId)
                dispatch(stateController.setState({
                    selectedIds: newSelection
                }))
            } else {
                newSelection = selectedIds.concat(playerId)
                dispatch(stateController.setState({
                    selectedIds: newSelection
                }))
            }
        }
    }

    public static dispose = () => {
        return async (dispatch, getState: () => AppState) => {
            let appState = getState();
            dispatch(stateController.setState(defaultState));
        }
    }

    public static setSelectedPlayers = (playerIds: Array<number>) => {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ selectedIds: playerIds }));
        }
    }
}

class Selectors {
    public static selectPlayers(state: AppState) {
        return state.events.virtualSummit.b2matchTransfersIn.players
    }
    public static selectRecommendedPlayers(state: AppState) {
        return this.selectPlayers(state).filter(i => i.isAvailable || i.isRecomended)
    }
    public static selectOtherPlayers(state: AppState) {
        return this.selectPlayers(state).filter(i => !i.isAvailable && !i.isRecomended)
    }
    public static selectSelectedIds(state: AppState) {
        return state.events.virtualSummit.b2matchTransfersIn.selectedIds
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    B2MatchTransfersInState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};



