import { ConversationTypeEnum } from './message'

export class ChannelItem {
    id: string;
    subject: string;
    unreadMessagesCount: number;
    sender: Endpoint;
    receiver: Endpoint;
    conversationType?: ConversationTypeEnum;
    lastMessageDateTime?: Date | string;
    sentDate: string;
    isReadonly?: boolean;
    isPlayerVerified?: boolean;
    isPlayerWaitingForAcceptance?: boolean;
    playerName?: string;
    staffInfo: ClubStaff;
}

export class Endpoint {
    id: number;
    type: ReceiverType;
    name: string;
    shortName: string;
    areaName?: string;
    league?: string;
}

export class ClubStaff {
    staffId: number;
    staffName: string;
    isVerified: boolean;
}

export enum ReceiverType {
    Squad = 0,
    Agency = 1,
    Player = 2,
}