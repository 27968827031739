import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import PlayersService from 'api/admin/players/players.service';
import { ContractExpirationData, PlayerModel, FilterOptions, PositionModel } from "api/admin/players/models";
import _ from 'lodash';
import { getPlayerSearchFilterCriteria } from 'store/playerSearch/reducers/playerSearchFilterReducer';
import { PlayerSearchService } from 'api/player/player-search';
import { RcFile } from 'antd/lib/upload';
import { notificationCreate } from 'app/notifications/notifications.actions';
import { getContractExpirationDate } from './utils';
import AdminPermissionsService from "api/admin/admin-permissions/admin-permissions.service";
import permissionsConstants from "constants/permissions";
import { getAuth } from "store/auth/authReducer";

export interface UpdatePlayerModalState {
  isOpen: boolean;
  isParent: boolean;
  processing: boolean;
  isFetching: boolean;
  modalTitle: string;
  squadName: string;
  keyword: string;
  newSquad: string;
  newSquadId: number | null;
  autosuggestionsOptions: Array<any>;
}

export interface AddImageModalState {
  isOpen: boolean;
  modalTitle: string;
  currentImage: string | null;
}

export interface ContractExpirationModalState {
  isApplying: boolean;
  isDeleting: boolean;
  isOpen: boolean;
  modalTitle: string;
  contractData: ContractExpirationData[];
}

export class ProcessingState {
  activeStateProcessingIds: Array<number>;
  hiddenForCurrentSquadProcessingIds: Array<number>;
  hiddenForParentSquadProcessingIds: Array<number>;
}

export interface UpdatePlayerPositionModalState {
  isOpen: boolean;
  isFetching: boolean;
  isFirstPosition: boolean;
  modalTitle: string;
  currentPositionCode: string;
  currentPositionName: null | string;
  newPositionCode: string;
  newPositionName: null | string;
  firstGeneralPositionCode: null | string;
  secondGeneralPositionCode: null | string;
}

class PlayersState {
  isLoading: boolean;
  isAllowedChangePlayerSquad: boolean;
  playerId: number;
  pageSize: number;
  currentPageNumber: number;
  totalRecords: number;
  players: PlayerModel[];
  positions: PositionModel[];
  filterOptions: FilterOptions;
  previousFilterSnapshot: FilterOptions;
  processing: ProcessingState;
  updatePlayerModalState: UpdatePlayerModalState;
  addImageModalState: AddImageModalState;
  contractExpirationModalState: ContractExpirationModalState;
  updatePlayerPositionModalState: UpdatePlayerPositionModalState;
}

const defaultFilterOptions: FilterOptions = {
  firstName: '',
  lastName: '',
  currentSquadName: '',
  parentSquadName: '',
  contractExpiration: 1,
  isActive: null,
  hiddenForCurrentSquad: null,
  hiddenForParentSquad: null,
  firstPosition: null,
  secondPosition: null,
}

const defaultState: PlayersState = {
  isLoading: false,
  isAllowedChangePlayerSquad: false,
  playerId: 0,
  pageSize: 10,
  currentPageNumber: 1,
  totalRecords: 0,
  players: [],
  positions: [],
  filterOptions: defaultFilterOptions,
  previousFilterSnapshot: defaultFilterOptions,
  processing: {
    activeStateProcessingIds: [],
    hiddenForCurrentSquadProcessingIds: [],
    hiddenForParentSquadProcessingIds: [],
  },
  updatePlayerModalState: {
    isOpen: false,
    isParent: false,
    isFetching: false,
    processing: false,
    modalTitle: '',
    squadName: '',
    keyword: '',
    newSquad: '',
    newSquadId: null,
    autosuggestionsOptions: []
  },
  addImageModalState: {
    isOpen: false,
    modalTitle: '',
    currentImage: null,
  },
  contractExpirationModalState: {
    isApplying: false,
    isDeleting: false,
    isOpen: false,
    modalTitle: '',
    contractData: []
  },
  updatePlayerPositionModalState: {
    isOpen: false,
    isFetching: false,
    isFirstPosition: true,
    modalTitle: '',
    currentPositionCode: '',
    currentPositionName: null,
    newPositionCode: '',
    newPositionName: null,
    firstGeneralPositionCode: '',
    secondGeneralPositionCode: '',
  },
}

const stateController = new StateController<PlayersState>(
  'ADMIN_V2/PLAYERS',
  defaultState
);

class Actions {
  public static dispose() {
    return (dispatch) => {
      dispatch(stateController.setState(defaultState))
    }
  }

  public static loadInitialData = () => {
    return async (dispatch) => {
      try {
        await Promise.allSettled([
          dispatch(Actions.hasUserPermissionByCode()),
          dispatch(Actions.getPlayersPositions()),
          dispatch(Actions.getPlayers()),
        ]);
      } catch (err) {
        console.error(err);
      }
    };
  };

  public static hasUserPermissionByCode = () => {
    return async (dispatch, getState: () => AppState) => {
      try {
        const data = await AdminPermissionsService.hasUserPermissionByCode(permissionsConstants.changePlayerSquad);
        dispatch(stateController.setState({ isAllowedChangePlayerSquad: data }));
      } catch (err) {
        console.error(err);
      }
    }
  }

  public static getPlayersPositions = () => {
    return async (dispatch, getState: () => AppState) => {
      try {
        const { data } = await PlayersService.getPositions();
        dispatch(stateController.setState({ positions: data ? data : [] }));
      } catch (err) {
        console.error(err);
      }
    }
  }

  public static getPlayers() {
    const wasFiltersChanged = (filterOptions: FilterOptions, previousFilterSnapshot: FilterOptions) => {
      return JSON.stringify(filterOptions) !== JSON.stringify(previousFilterSnapshot);
    }

    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState({ isLoading: true }));
        const aspId = getState().auth.aspNetUserId;
        const {
          pageSize,
          currentPageNumber,
          filterOptions,
          previousFilterSnapshot
        } = Selectors.selectState(getState());

        const shouldDisposePagination = wasFiltersChanged(filterOptions, previousFilterSnapshot);

        dispatch(stateController.setState(prevState => ({
          ...prevState,
          previousFilterSnapshot: {
            ...prevState.filterOptions,
          },
          currentPageNumber: shouldDisposePagination ? 1 : currentPageNumber
        })));

        const searchParameters = {
          aspId,
          pageSize,
          contractFilterOption: filterOptions.contractExpiration,
          currentPageNumber: shouldDisposePagination ? 1 : currentPageNumber,
          englishFirstName: filterOptions.firstName ? filterOptions.firstName : undefined,
          englishLastName: filterOptions.lastName ? filterOptions.lastName : undefined,
          currentSquadName: filterOptions.currentSquadName ? filterOptions.currentSquadName : undefined,
          parentSquadName: filterOptions.parentSquadName ? filterOptions.parentSquadName : undefined,
          active: filterOptions.isActive !== null ? filterOptions.isActive : undefined,
          hiddenForCurrentSquad: filterOptions.hiddenForCurrentSquad !== null ? filterOptions.hiddenForCurrentSquad : undefined,
          hiddenForParentSquad: filterOptions.hiddenForParentSquad !== null ? filterOptions.hiddenForParentSquad : undefined,
          firstGeneralPosition: filterOptions.firstPosition !== null ? filterOptions.firstPosition : undefined,
          secondGeneralPosition: filterOptions.secondPosition !== null ? filterOptions.secondPosition : undefined,
        }

        const data = await PlayersService.getPlayersNew(searchParameters);

        const players = !!data ? data.output.map((player: PlayerModel) => ({
          ...player,
          key: player.id
        })) : [];

        dispatch(stateController.setState(prevState => ({
          ...prevState,
          players,
          totalRecords: data.rowCount,
        })));
      } catch (err) {
        console.error(err);
      } finally {
        dispatch(stateController.setState({ isLoading: false }));
      }
    }
  }

  public static onPaginationChange(pageNumber: number, pageSize: number) {
    return (dispatch, getState: () => AppState) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        currentPageNumber: pageNumber,
        pageSize: pageSize
      })))

      dispatch(Actions.getPlayers());
    }
  }

  public static toggleActivatePlayer(playerId: number, value: boolean) {
    return async (dispatch, getState: () => AppState) => new Promise(async (resolve, reject) => {
      try {
        const aspId = getState().auth.aspNetUserId;
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          processing: {
            ...prevState.processing,
            activeStateProcessingIds: [...prevState.processing.activeStateProcessingIds, playerId]
          }
        })));
        const substate = Selectors.selectState(getState());

        const result = await PlayersService.toggleActivatePlayer(aspId, playerId, value);

        if (result) {
          const updatedPlayers = [...substate.players]
          updatedPlayers.find(item => item.id === playerId).active = value
          dispatch(stateController.setState(prevState => ({
            ...prevState,
            players: updatedPlayers
          })))
        }
        resolve(true);

      } catch (e) {
        console.error(e)
        reject(e)
      } finally {
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          processing: {
            ...prevState.processing,
            activeStateProcessingIds: prevState.processing.activeStateProcessingIds.filter(item => item !== playerId)
          }
        })))
      }
    })
  }

  public static toggleHidePlayer(playerId: number, value: boolean, isParent: boolean) {
    return async (dispatch, getState: () => AppState) => new Promise(async (resolve, reject) => {
      try {
        const aspId = getState().auth.aspNetUserId;
        if (isParent) {
          dispatch(stateController.setState(prevState => ({
            ...prevState,
            processing: {
              ...prevState.processing,
              hiddenForParentSquadProcessingIds: [...prevState.processing.hiddenForParentSquadProcessingIds, playerId]
            }
          })));
        } else {
          dispatch(stateController.setState(prevState => ({
            ...prevState,
            processing: {
              ...prevState.processing,
              hiddenForCurrentSquadProcessingIds: [...prevState.processing.hiddenForCurrentSquadProcessingIds, playerId]
            }
          })));
        }

        const substate = Selectors.selectState(getState());

        const result = value
          ? await PlayersService.hidePlayer(aspId, playerId, isParent)
          : await PlayersService.showPlayer(aspId, playerId, isParent);

        if (result) {
          const updatedPlayers = [...substate.players];
          if (isParent) {
            updatedPlayers.find(item => item.id === playerId).hiddenForParentSquad = value;
          } else {
            updatedPlayers.find(item => item.id === playerId).hiddenForCurrentSquad = value;
          }

          dispatch(stateController.setState(prevState => ({
            ...prevState,
            players: updatedPlayers
          })));
        }
        resolve(true);

      } catch (e) {
        console.error(e)
        reject(e)
      } finally {
        if (isParent) {
          dispatch(stateController.setState(prevState => ({
            ...prevState,
            processing: {
              ...prevState.processing,
              hiddenForParentSquadProcessingIds: prevState.processing.hiddenForParentSquadProcessingIds.filter(item => item !== playerId)
            }
          })));
        } else {
          dispatch(stateController.setState(prevState => ({
            ...prevState,
            processing: {
              ...prevState.processing,
              hiddenForCurrentSquadProcessingIds: prevState.processing.hiddenForCurrentSquadProcessingIds.filter(item => item !== playerId)
            }
          })));
        }
      }
    })
  }

  public static openUpdatePlayerSquadModal(player: PlayerModel, isParent: boolean) {
    return (dispatch) => {
      const parentSquadName = player.parentSquadName ? player.parentSquadName : 'none';
      const currentSquadName = player.currentSquadName ? player.currentSquadName : 'none';

      dispatch(stateController.setState(prevState => ({
        ...prevState,
        playerId: player.id,
        updatePlayerModalState: {
          ...prevState.updatePlayerModalState,
          isParent,
          isOpen: true,
          modalTitle: `${player.englishFirstName} ${player.englishLastName}`,
          squadName: isParent ? parentSquadName : currentSquadName
        },
      })));
    }
  }

  public static closeUpdatePlayerSquadModal() {
    return (dispatch) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        playerId: 0,
        updatePlayerModalState: {
          ...defaultState.updatePlayerModalState
        }
      })));
    }
  };

  public static onChangeKeyword(value: string) {
    return async (dispatch, getState: () => AppState) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        updatePlayerModalState: {
          ...prevState.updatePlayerModalState,
          keyword: value,
        }
      })));

      if (value) {
        Actions.loadFirstClubAutosuggestionsDebounceFunc(dispatch, value)
      } else {
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          updatePlayerModalState: {
            ...prevState.updatePlayerModalState,
            autosuggestionsOptions: [],
            newSquad: '',
            newSquadId: null
          }
        })));
      }
    }
  };

  private static loadFirstClubAutosuggestionsDebounceFunc = _.debounce((dispatch, keyword) => dispatch(Actions.getClubAutosuggestions(keyword)), 1000)

  public static getClubAutosuggestions(keyword: string) {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          updatePlayerModalState: {
            ...prevState.updatePlayerModalState,
            processing: true
          }
        })));
        const filter = getPlayerSearchFilterCriteria(getState());
        const autosuggestionsOptions = await PlayerSearchService.getPlayerAutosuggestions(0, 0, { ...filter, keyword }, true)
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          updatePlayerModalState: {
            ...prevState.updatePlayerModalState,
            autosuggestionsOptions: autosuggestionsOptions
          }
        })));
      } finally {
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          updatePlayerModalState: {
            ...prevState.updatePlayerModalState,
            processing: false
          }
        })));
      }
    }
  };

  public static selectClub(clubName: string, squadId: number) {
    return (dispatch) => {
      dispatch(Actions.onChangeKeyword(clubName));
      dispatch(Actions.getClubAutosuggestions(clubName));

      dispatch(stateController.setState(prevState => ({
        ...prevState,
        updatePlayerModalState: {
          ...prevState.updatePlayerModalState,
          newSquad: clubName,
          newSquadId: squadId
        }
      })));
    };
  };

  public static assignSquad(playerId: number, squadId: number, squadName: string, isParent: boolean) {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          updatePlayerModalState: {
            ...prevState.updatePlayerModalState,
            isFetching: true
          }
        })));
        await PlayersService.assignSquad(playerId, squadId, squadName, isParent);

        const newItems = [...Selectors.getPlayers(getState())];

        if (isParent) {
          newItems.find((item) => item.id === playerId).parentSquadName = squadName;
        } else {
          newItems.find((item) => item.id === playerId).currentSquadName = squadName;
        }

        dispatch(stateController.setState(prevState => ({
          ...prevState,
          players: newItems,
        })));

        dispatch(Actions.closeUpdatePlayerSquadModal());
      } catch (err) {
        console.error(err);
      } finally {
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          updatePlayerModalState: {
            ...prevState.updatePlayerModalState,
            isFetching: false
          }
        })));
      }
    }
  };

  public static openAddImageModal(player: PlayerModel, ) {
    return (dispatch) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        playerId: player.id,
        addImageModalState: {
          ...prevState.addImageModalState,
          isOpen: true,
          modalTitle: `${player.englishFirstName} ${player.englishLastName}`,
          currentImage: player.photo ? player.photo : null
        },
      })));
    }
  }

  public static closeAddImageModal() {
    return (dispatch) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        playerId: 0,
        addImageModalState: {
          ...defaultState.addImageModalState
        },
      })));
    }
  }

  public static uploadImage(playerId: number, file: Blob | RcFile | string) {
    return async (dispatch, getState: () => AppState) => {
      const { photo } = await PlayersService.uploadPlayerImage(playerId, file);
      const newPhotoName = photo + `?${new Date().getTime()}`;
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        players: prevState.players.map(item => {
          if (item.id === playerId) {
            return {
              ...item,
              photo: newPhotoName
            }
          } else {
            return item
          }
        }),
        addImageModalState: {
          ...prevState.addImageModalState,
          currentImage: newPhotoName
        }
      })));
      dispatch(notificationCreate({
        message: "Image successfully changed",
        level: 'success'
      }))
    }
  }

  public static deleteImage(playerId: number) {
    return async (dispatch) => {
      await PlayersService.deletePlayerImage(playerId)
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        players: prevState.players.map(item => {
          if (item.id === playerId) {
            return {
              ...item,
              photo: null
            }
          } else {
            return item
          }
        }),
        addImageModalState: {
          ...prevState.addImageModalState,
          currentImage: null,
        }
      })))
    }
  }

  public static openContractExpirationModal(player: PlayerModel, ) {
    return (dispatch) => {
      let expirationDate = getContractExpirationDate(player);

      dispatch(stateController.setState(prevState => ({
        ...prevState,
        playerId: player.id,
        contractExpirationModalState: {
          ...prevState.contractExpirationModalState,
          isOpen: true,
          modalTitle: `${player.englishFirstName} ${player.englishLastName}`,
          contractData: [{
            key: player.id,
            contractExpire: expirationDate,
            contractExpirationApply: player.contractExpirationApply,
            requestedBy: player.contractExpirationApply && player.contractExpirationApply.requestedByAgency && player.contractExpirationApply.requestedByAgency.name,
            appliedBy: player.contractExpirationApply && (
                player.contractExpirationApply.autoApply ? 'Auto' : !!player.contractExpirationApply.appliedByAdmin ? player.contractExpirationApply.appliedByAdmin.shortName : ''
            )
          }]
        },
      })));
    }
  }

  public static closeContractExpirationModal() {
    return (dispatch) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        playerId: 0,
        contractExpirationModalState: {
          ...defaultState.contractExpirationModalState
        },
      })));
    }
  }

  public static applyContractExpire(playerId: number) {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          contractExpirationModalState: {
            ...prevState.contractExpirationModalState,
            isApplying: true,
          }
        })));
        const auth = getAuth(getState())

        await PlayersService.applyContractExpire(playerId);

        const newItems = [...Selectors.getPlayers(getState())];
        let player = newItems.find((item) => item.id === playerId);

        if(!player.contractExpirationApply.appliedByAdmin) {
          player.contractExpirationApply.appliedByAdmin = {
            id: 0,
            name: '',
            shortName: ''
          }
        }
        player.contractExpirationApply.appliedByAdmin.shortName = auth.userFullName;

        dispatch(stateController.setState(prevState => ({
          ...prevState,
          players: newItems,
        })));

        dispatch(Actions.closeContractExpirationModal());
      } catch (err) {
        console.log(err)
      } finally {
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          contractExpirationModalState: {
            ...prevState.contractExpirationModalState,
            isApplying: false,
          }
        })));
      }
    }
  }

  public static deleteContractExpire(playerId: number) {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          contractExpirationModalState: {
            ...prevState.contractExpirationModalState,
            isDeleting: true,
          }
        })));

        await PlayersService.deleteContractExpire(playerId);

        const newItems = [...Selectors.getPlayers(getState())];
        let player = newItems.find((item) => item.id === playerId);
        player.contractExpirationApply = null;
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          players: newItems,
        })));

        dispatch(Actions.closeContractExpirationModal());
      } catch (err) {
        console.log(err)
      } finally {
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          contractExpirationModalState: {
            ...prevState.contractExpirationModalState,
            isDeleting: false
          }
        })));
      }
    }
  }

  public static applySearch() {
    return dispatch => dispatch(Actions.getPlayers())
  }

  public static disposeFilters() {
    return (dispatch) => {
      dispatch(stateController.setState(prevState => ({ ...prevState, filterOptions: defaultState.filterOptions })))
      dispatch(Actions.applySearch());
    }
  }

  public static onChangeFirstName(value: string) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        filterOptions: {
          ...prevState.filterOptions,
          firstName: value
        }
      })));
    }
  }

  public static onChangeLastName(value: string) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        filterOptions: {
          ...prevState.filterOptions,
          lastName: value
        }
      })));
    }
  }

  public static onChangeCurrentSquadName(value: string) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        filterOptions: {
          ...prevState.filterOptions,
          currentSquadName: value
        }
      })));
    }
  }

  public static onChangeParentSquadName(value: string) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        filterOptions: {
          ...prevState.filterOptions,
          parentSquadName: value
        }
      })));
    }
  }

  public static onChangeContractExpiration(value: number) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        filterOptions: {
          ...prevState.filterOptions,
          contractExpiration: value
        }
      })));
    };
  }

  public static onChangeIsActive(value: boolean | null) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        filterOptions: {
          ...prevState.filterOptions,
          isActive: value
        }
      })));
    };
  }

  public static onChangeHiddenForCurrentSquad(value: boolean | null) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        filterOptions: {
          ...prevState.filterOptions,
          hiddenForCurrentSquad: value
        }
      })));
    };
  }

  public static onChangeHiddenForParentSquad(value: boolean | null) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        filterOptions: {
          ...prevState.filterOptions,
          hiddenForParentSquad: value
        }
      })));
    };
  }

  public static onChangeFirstPosition(value: string | null) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        filterOptions: {
          ...prevState.filterOptions,
          firstPosition: value
        }
      })));
    };
  }

  public static onChangeSecondPosition(value: string | null) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        filterOptions: {
          ...prevState.filterOptions,
          secondPosition: value
        }
      })));
    };
  }

  public static openUpdatePlayerPositionModal(player: PlayerModel, isFirstPosition: boolean) {
    return (dispatch) => {
      let positionText = '';
      if (isFirstPosition) {
        positionText = 'first position'
      } else {
        positionText = 'secondary position'
      }

      const playerName = player.englishLastName ? `${player.englishFirstName} ${player.englishLastName}` : player.englishFirstName;

      dispatch(stateController.setState(prevState => ({
        ...prevState,
        playerId: player.id,
        updatePlayerPositionModalState: {
          ...prevState.updatePlayerPositionModalState,
          isOpen: true,
          isFirstPosition: isFirstPosition,
          firstGeneralPositionCode: player.firstGeneralPositionCode,
          secondGeneralPositionCode: player.secondGeneralPositionCode,
          currentPositionCode: isFirstPosition ? player.firstGeneralPositionCode : player.secondGeneralPositionCode,
          currentPositionName: isFirstPosition ? player.firstGeneralPositionName : player.secondGeneralPositionName,
          modalTitle: `Change ${playerName}'s ${positionText}`,
        },
      })));
    }
  }

  public static closeUpdatePlayerPositionModal() {
    return (dispatch) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        playerId: 0,
        updatePlayerPositionModalState: {
          ...defaultState.updatePlayerPositionModalState
        }
      })))
    }
  };

  public static updatePosition(playerId: number, positionCode: string, isFirstPosition: boolean) {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          updatePlayerPositionModalState: {
            ...prevState.updatePlayerPositionModalState,
            isFetching: true
          }
        })));
        const code = positionCode !== 'none' ? positionCode : null;
        await PlayersService.updatePlayerPosition(playerId, code, isFirstPosition);

        const positions = Selectors.getPositions(getState());
        const positionName = code ? positions.find(position => position.key === code).value : null;
        const newItems = [...Selectors.getPlayers(getState())];

        if (isFirstPosition) {
          newItems.find((item) => item.id === playerId).firstGeneralPositionCode = code;
          newItems.find((item) => item.id === playerId).firstGeneralPositionName = positionName;
        } else {
          newItems.find((item) => item.id === playerId).secondGeneralPositionCode = code;
          newItems.find((item) => item.id === playerId).secondGeneralPositionName = positionName;
        }

        dispatch(stateController.setState(prevState => ({
          ...prevState,
          players: newItems,
        })));

        dispatch(Actions.closeUpdatePlayerPositionModal());
      } catch (err) {
        console.error(err);
      } finally {
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          updatePlayerPositionModalState: {
            ...prevState.updatePlayerPositionModalState,
            isFetching: false
          }
        })));
      }
    }
  };

  public static onChangePosition(value: string) {
    return (dispatch, getState) => {
      if (value !== 'none') {
        const positions = Selectors.getPositions(getState());
        const positionName = positions.find(el => el.key === value).value
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          updatePlayerPositionModalState: {
            ...prevState.updatePlayerPositionModalState,
            newPositionCode: value,
            newPositionName: positionName,
          }
        })));
      } else {
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          updatePlayerPositionModalState: {
            ...prevState.updatePlayerPositionModalState,
            newPositionCode: 'none',
            newPositionName: null,
          }
        })));
      }
    };
  };

}

class Selectors {
  public static selectState = (state: AppState) => state.admin.players;
  public static isLoading = (state: AppState): boolean => Selectors.selectState(state).isLoading;
  public static isAllowedChangePlayerSquad = (state: AppState): boolean => Selectors.selectState(state).isAllowedChangePlayerSquad;
  public static getPlayers = (state: AppState) => Selectors.selectState(state).players;
  public static getTotalRecords = (state: AppState) => Selectors.selectState(state).totalRecords;
  public static getPageSize = (state: AppState) => Selectors.selectState(state).pageSize;
  public static getCurrentPage = (state: AppState) => Selectors.selectState(state).currentPageNumber;
  public static getProcessing = (state: AppState) => Selectors.selectState(state).processing;
  public static getPlayerId = (state: AppState) => Selectors.selectState(state).playerId;
  public static getFilterOptions = (state: AppState) => Selectors.selectState(state).filterOptions;
  public static getPositions = (state: AppState) => Selectors.selectState(state).positions;

  public static getUpdatePlayerSquadModalState = (state: AppState) => Selectors.selectState(state).updatePlayerModalState;
  public static isOpenUpdatePlayerSquadModal = (state: AppState) => Selectors.getUpdatePlayerSquadModalState(state).isOpen;
  public static getTitleUpdatePlayerSquadModal = (state: AppState) => Selectors.getUpdatePlayerSquadModalState(state).modalTitle;
  public static isParentUpdatePlayerSquadModal = (state: AppState) => Selectors.getUpdatePlayerSquadModalState(state).isParent;
  public static getSquadNameUpdatePlayerSquadModal = (state: AppState) => Selectors.getUpdatePlayerSquadModalState(state).squadName;
  public static getIsFetchingUpdatePlayerSquadModal = (state: AppState) => Selectors.getUpdatePlayerSquadModalState(state).isFetching;
  public static getNewSquadIdUpdatePlayerSquadModal = (state: AppState) => Selectors.getUpdatePlayerSquadModalState(state).newSquadId;
  public static getNewSquadNameUpdatePlayerSquadModal = (state: AppState) => Selectors.getUpdatePlayerSquadModalState(state).newSquad;

  public static getAutosuggestionsProcessing = (state: AppState) => Selectors.selectState(state).updatePlayerModalState.processing;
  public static getAutosuggestionsOptions = (state: AppState) => Selectors.selectState(state).updatePlayerModalState.autosuggestionsOptions;
  public static getAutosuggestionsKeyword = (state: AppState) => Selectors.selectState(state).updatePlayerModalState.keyword;

  public static getAddImageModalState = (state: AppState) => Selectors.selectState(state).addImageModalState;
  public static isOpenAddImageModal = (state: AppState) => Selectors.getAddImageModalState(state).isOpen;
  public static getTitleAddImageModal = (state: AppState) => Selectors.getAddImageModalState(state).modalTitle;
  public static getCurrentImageAddImageModal = (state: AppState) => Selectors.getAddImageModalState(state).currentImage;

  public static getContractExpirationModalState = (state: AppState) => Selectors.selectState(state).contractExpirationModalState;
  public static isOpenContractExpirationModal = (state: AppState) => Selectors.getContractExpirationModalState(state).isOpen;
  public static getTitleContractExpirationModal = (state: AppState) => Selectors.getContractExpirationModalState(state).modalTitle;
  public static getDataContractExpirationModal = (state: AppState) => Selectors.getContractExpirationModalState(state).contractData;

  public static getUpdatePlayerPositionSquadModalState = (state: AppState) => Selectors.selectState(state).updatePlayerPositionModalState;

  public static isFiltersSetToDefault(state: AppState) {
    return JSON.stringify(defaultState.filterOptions) === JSON.stringify(Selectors.getFilterOptions(state))
  }
}

const reducer = stateController.getReducer();

export {
  reducer as Reducer,
  PlayersState as State,
  Actions as Actions,
  Selectors as Selectors,
  stateController as Controller
};
