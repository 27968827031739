import axios from 'axios-config';
import {PlayerActivityHistoryResponse, SavePlayerTransparencyItem, TransparencyInfo} from "./models";

export default class AgencyTransparencyService {

  private static axiosConfig = {
    headers: { 'Content-Type': 'application/json' },
  };

  public static async turnOnSharingNow(): Promise<any> {
    const { data } = await axios.post<any>(
      `api/AgencyTransparency/TurnOn`,
      null,
      this.axiosConfig
    );

    return data;
  }

  public static async close(): Promise<any> {
    const { data } = await axios.post<any>(
      `api/AgencyTransparency/Close`,
      null,
      this.axiosConfig
    );

    return data;
  }

  public static async getTransparencyInfo(): Promise<TransparencyInfo> {
      let { data } = await axios.get<any>(`api/AgencyTransparency/GetInfo`, this.axiosConfig);
      return data;
  }

  public static async setRemindMeLater() {
      await axios.post<any>(`api/AgencyTransparency/RemindMeLater`);
  }

  public static async updateSharingSettings(items: SavePlayerTransparencyItem[]){
      await axios.post<any>(`api/AgencyTransparency/UpdateSharing`, { playerItems: items });
  }

  public static async getPlayerActivityHistory(): Promise<PlayerActivityHistoryResponse> {
    const { data } = await axios.get(`api/AgencyTransparency/GetPlayerActivityHistory`, this.axiosConfig);
    return data;
  }

}