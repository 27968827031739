import { TEST_INTEREST } from "./indexReducer"

const initialState = {
    testingInterest: []
};

export default (pageState = initialState, action) => {
    switch (action.type) {
        case TEST_INTEREST.REQUEST: {
            return { ...pageState, testingInterest: [...pageState.testingInterest, action.payload.payload.pitchSquadId] };
        }
        case TEST_INTEREST.SUCCESS:{
            return { ...pageState, testingInterest: pageState.testingInterest.filter(i => i !== action.payload.request.payload.pitchSquadId) };
        }
        case TEST_INTEREST.ERROR: {
            return { ...pageState, testingInterest: pageState.testingInterest.filter(i => i !== action.payload.payload.pitchSquadId) };
        }
        default:
            return pageState;
    }
};