import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import { Selectors as SelectPlayersSelectors } from '../select-players-stepper/select-players-stepper.controller';
import { Actions as ReonboardingActions, ReonboardingSubsteppers } from '../reonboarding.controller';
import { Actions as RequestCreditsModalActions } from 'pages/agency/common/request-credits-modal/request-credits-modal.controller';
import {
    PitchSuggestionsModel,
    PitchSuggestionTransferTypeEnum,
    PreconnectedPlayerItem
} from 'api/agency/agent/reonboarding/reonboarding-models';
import ReonboardingService from 'api/agency/agent/reonboarding/reonboarding.service';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';

export interface PitchablePlayerItem extends PreconnectedPlayerItem {
    transferType: PitchSuggestionTransferTypeEnum;
    options: PitchablePlayerOption[];
}

interface PitchablePlayerOption {
    label: string;
    value: PitchSuggestionTransferTypeEnum
}

export enum SelectPitchablePlayersSteps {
    SelectPlayersToPitch = 1,
    SelectPitchOptions = 2,
    SelectPitchablePlayersDone = 3,
}
class State {
    isLoading: boolean;
    processing: boolean;
    activeStep: SelectPitchablePlayersSteps;
    amountOfAvailablePlayers: number;
    totalPitches: number;
    players: PreconnectedPlayerItem[];
    pitchablePlayers: PitchablePlayerItem[];
    pitchOpportunities: number;
    pitchSuggestions: PitchSuggestionsModel[];
}

const defaultState: State = {
    isLoading: false,
    processing: false,
    activeStep: SelectPitchablePlayersSteps.SelectPlayersToPitch,
    amountOfAvailablePlayers: -1,
    totalPitches: 0,
    players: [],
    pitchablePlayers: [],
    pitchOpportunities: 0,
    pitchSuggestions: []
}

const stateController = new StateController<State>(
    'AGENCY/REONBOARDING-FLOW/SELECT-PITCHABLE-PLAYERS',
    defaultState
);

class Actions {
    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState({ ...defaultState }));
        }
    }

    public static init() {
        return (dispatch, getState: () => AppState) => {
            const selectedPlayersState = SelectPlayersSelectors.getRoot(getState()).selectedPlayers;
            const amountOfAvailablePlayers = SelectPlayersSelectors.getRoot(getState()).amountOfAvailablePlayers;

            const totalPitches = selectedPlayersState.reduce((acc, player) => {
                return acc + player.opportunityAdsQty
            }, 0);

            const selectedPlayersSorted = selectedPlayersState.sort((a, b) => (b.opportunityAdsQty - a.opportunityAdsQty));

            dispatch(stateController.setState({
                amountOfAvailablePlayers,
                players: selectedPlayersSorted,
                totalPitches: totalPitches
            }));
        }
    }

    public static setActiveStep(step: SelectPitchablePlayersSteps) {
        return (dispatch) => {
            if (step === SelectPitchablePlayersSteps.SelectPitchablePlayersDone) {
                dispatch(ReonboardingActions.setIsCongratulationsScreen(true))
            }
            dispatch(stateController.setState({ activeStep: step }));
            dispatch(ReonboardingActions.disposeScrollingEffect());
        }
    }

    public static onBackHandler () {
        return (dispatch) => {

            dispatch(userActivityInsert({
                PageName: 'Verification Flow [Pitch Options]',
                Message: 'Moved Back to Players List',
            }));

            dispatch(stateController.setState({ activeStep: SelectPitchablePlayersSteps.SelectPlayersToPitch }));
        }
    }

    public static goToSelectPitchOptionsStep() {
        return (dispatch, getState: () => AppState) => {
            const pitchablePlayers = Selectors.getPitchablePlayers(getState());

            dispatch(userActivityInsert({
                PageName: 'Verification Flow [Select Players to Pitch]',
                Message: `Continued (${ pitchablePlayers.map(player => player.playerName).join(', ') })`,
            }));

            dispatch(stateController.setState({ activeStep: SelectPitchablePlayersSteps.SelectPitchOptions }));
        }
    }

    public static goToCreatePitchesStepper() {
        return (dispatch) => {

            dispatch(userActivityInsert({
                PageName: 'Verification Flow [Pitch Opportunities]',
                Message: 'See Your Pitch Opportunities',
            }));

            dispatch(ReonboardingActions.setActiveStepper(ReonboardingSubsteppers.CreatePitches));
            dispatch(ReonboardingActions.setIsCongratulationsScreen(false))
        }
    }

    public static skip() {
        return (dispatch) => {

            dispatch(userActivityInsert({
                PageName: 'Verification Flow [Select Players to Pitch]',
                Message: 'Skip and Don’t Pitch',
            }));

            dispatch(ReonboardingActions.setActiveStepper(ReonboardingSubsteppers.VerifyPlayers));
        }
    }

    public static openRequestCreditsModal() {
        return (dispatch) => {
            dispatch(RequestCreditsModalActions.openModal('Verification Flow [Select Players to Pitch]'))

            dispatch(userActivityInsert({
                PageName: 'Verification Flow [Select Players to Pitch]',
                Message: 'Request More Credits',
            }));
        }
    }

    public static onSelectPlayer(player: PreconnectedPlayerItem) {
        return (dispatch, getState: () => AppState) => {
            const pitchablePlayersState = Selectors.getPitchablePlayers(getState());
            const selectedPlayer = pitchablePlayersState.find(pitchable => pitchable.playerId === player.playerId);
            const availablePlayerCredits = selectedPlayer
                ? Selectors.getAvailablePlayerCredits(getState()) + 1
                : Selectors.getAvailablePlayerCredits(getState());

            if (availablePlayerCredits <= 0) {
                dispatch(Actions.openRequestCreditsModal());
                return;
            }

            if (selectedPlayer) {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    pitchablePlayers: prevState.pitchablePlayers.filter(pitchable => pitchable.playerId !== player.playerId)
                })));

                dispatch(userActivityInsert({
                    PageName: 'Verification Flow [Select Players to Pitch]',
                    Message: 'Deselected Player',
                    PlayerId: player.playerId,
                }));
            } else {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    pitchablePlayers: [
                        ...prevState.pitchablePlayers,
                        {
                            ...player,
                            transferType: PitchSuggestionTransferTypeEnum.Both,
                            options: [
                                {
                                    label: 'Transfer',
                                    value: PitchSuggestionTransferTypeEnum.Transfer
                                },
                                {
                                    label: 'Loan',
                                    value: PitchSuggestionTransferTypeEnum.Loan
                                },
                                {
                                    label: 'Transfer & Loan',
                                    value: PitchSuggestionTransferTypeEnum.Both
                                }
                            ]
                        }],
                })));

                dispatch(userActivityInsert({
                    PageName: 'Verification Flow [Select Players to Pitch]',
                    Message: 'Selected Player',
                    PlayerId: player.playerId,
                }));
            }
        }
    }

    public static onSelectPitchOptions(value: PitchSuggestionTransferTypeEnum, playerId: number) {
        return (dispatch, getState: () => AppState) => {
            const pitchablePlayersState = Selectors.getPitchablePlayers(getState());
            const pitchablePlayers = [ ...pitchablePlayersState ];

            pitchablePlayers.find(pitchable => pitchable.playerId === playerId).transferType = value;

            dispatch(stateController.setState({
                pitchablePlayers: [ ...pitchablePlayers ]
            }));

            const getUserActivityMessage = () => {
                switch (value) {
                    case PitchSuggestionTransferTypeEnum.Transfer:
                        return 'Selected Transfer';
                    case PitchSuggestionTransferTypeEnum.Loan:
                        return 'Selected Loan';
                    case PitchSuggestionTransferTypeEnum.Both:
                        return 'Selected Transfer and Loan';

                    default:
                        return ''
                }
            }

            dispatch(userActivityInsert({
                PageName: 'Verification Flow [Pitch Options]',
                Message: getUserActivityMessage(),
                PlayerId: playerId,
            }));
        }
    }

    public static onSubmitPitchOptions() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ processing: true }));
                const pitchablePlayersState = Selectors.getPitchablePlayers(getState());

                const prepareData = pitchablePlayersState.map(pitchable => ({
                    playerId: pitchable.playerId,
                    transferType: pitchable.transferType
                }));

                const data = await ReonboardingService.getPitchSuggestions(prepareData);

                const pitchOpportunitiesTotal = data.players.reduce((acc, suggestion) => {
                    return acc + suggestion.pitchSuggestions.length
                }, 0);

                dispatch(stateController.setState({
                    pitchOpportunities: pitchOpportunitiesTotal,
                    pitchSuggestions: data.players
                }));

                dispatch(userActivityInsert({
                    PageName: 'Verification Flow [Pitch Options]',
                    Message: 'Continued',
                }));

                if (pitchOpportunitiesTotal > 0) {
                    dispatch(Actions.setActiveStep(SelectPitchablePlayersSteps.SelectPitchablePlayersDone));
                } else {
                    dispatch(ReonboardingActions.setActiveStepper(ReonboardingSubsteppers.CreatePitches));
                }
            } catch(err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState({ processing: false }));
            }
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState): State => state.agency.reonboarding.selectPitchablePlayers;
    public static isLoading = (state: AppState): boolean => Selectors.getRoot(state).isLoading;
    public static isProcessing = (state: AppState): boolean => Selectors.getRoot(state).processing;
    public static getCurrentStep = (state: AppState) => Selectors.getRoot(state).activeStep;
    public static getPitchablePlayersQty = (state: AppState) => Selectors.getRoot(state).pitchablePlayers.length;
    public static getAvailablePlayerCredits = (state: AppState) => {
        return Selectors.getRoot(state).amountOfAvailablePlayers - Selectors.getPitchablePlayersQty(state)
    };
    public static getTotalPitches = (state: AppState) => Selectors.getRoot(state).totalPitches;
    public static getPlayers = (state: AppState) => Selectors.getRoot(state).players;
    public static getPitchablePlayers = (state: AppState) => Selectors.getRoot(state).pitchablePlayers;
    public static getPitchOpportunities = (state: AppState) => Selectors.getRoot(state).pitchOpportunities;
    public static getPitchSuggestions = (state: AppState) => Selectors.getRoot(state).pitchSuggestions;
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    stateController as Controller,
    Selectors as Selectors
};
