import React from 'react'
import styled from 'styled-components'

export const EventPhotosSidebar = ({ images, numberOfPhotos, galleryLink = "" }) => (
    <Section>
        <ul>
            <li>
                <img src={images[0].src} alt="" />
            </li>
            <li>
                <img src={images[1].src} alt="" />
            </li>
            <li>
                <img src={images[2].src} alt="" />
                {galleryLink && numberOfPhotos > 0 &&
                    galleryLink !== undefined &&
                    galleryLink !== null && (
                        <a
                            href={galleryLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="events__video-last"
                        >
                            <span className="events__video-last_wrap">
                                <span className="events__video-last_sumblen">
                                    {numberOfPhotos}
                                </span>
                                Photos
                            </span>
                            <i className="layer" />
                        </a>
                    )}
            </li>
        </ul>
    </Section>
);

const Section = styled.div`
    width: 20%;
    margin-left: 20px;
    flex-grow: 1;
    ul {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;

        li {
            list-style-type: none;
            display: block;
            position: relative;
            margin: 10px 0;
            border-radius: 10px;
            overflow: hidden;
            box-shadow: 0 8px 30px -5px rgba(34, 42, 60, 0.4);
            img {
                max-width: 100%;
                height: auto;
            }
        }
    }
`