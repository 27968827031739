import axios from 'axios-config';
import { EventAgendaDayModel, EventAgendaModel } from './models/event-agenda';
import { UserSummitModel } from './models/summit-invitationl';
import { EventModel } from './models/event-data'

export default class MiscEventService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async loadEventData(squadId: number): Promise<EventModel> {
        const { data } = await axios.get(
            `api/Event/GetEventData?squadId=${squadId}`,
            this.axiosConfig
        );
        return data
    }
    public static async loadUserSummitData(userId: number): Promise<any> {
        const { data } = await axios.get(
            `api/UserSummits/Attendance/${userId}`,
            this.axiosConfig
        )
        return data
    }
    public static async loadEventPreRegistrationStatus(userId: number): Promise<any> {
        const { data } = await axios.get(
            `api/UserSummits/PreRegistrations/${userId}`,
            this.axiosConfig
        )
        return data
    }

    public static async preRegisterEvent(userId: number): Promise<any> {
        await axios.post(
            `api/UserSummits/PreRegistrations/${userId}`,
            this.axiosConfig
        );        
    }

    public static async loadAgencyRegistrationState(): Promise<UserSummitModel> {
        const { data } = await axios.get(
            `api/UserSummits/AttendanceAgency`,
            this.axiosConfig
        )
        return data
    }
    
    public static async loadEventAgenda(eventId: number): Promise<EventAgendaModel> {
        const { data } = await axios.get(
            `api/Event/GetEventAgenda/${eventId}`,
            this.axiosConfig
        );
        return data
    }
}