import { AMOUNT_OF_CLUBS as amountOfClubs } from 'constants/variable-amounts';

export const promotionPlans = [
    {
        id: 0,
        name: ''
    },
    {
        id: 1,
        name: 'Free trials',
        textMain: 'Your free trial expires in',
        textAdditional: `Don't lose your access to football's marketplace - trusted by decision makers from ${amountOfClubs} clubs`
    },
    {
        id: 2,
        name: 'Restricted access',
        textMain: 'Restricted access',
        textAdditional: 'Your access to the transfer market is highly restricted. Get access to all features.'
    },
    {
        id: 3,
        name: 'ECA',
        textMain: 'Eca member offer - expires in',
        textAdditional: 'Get a TransferRoom VIP subscription with 20% off! Offer valid until'
    },
    {
        id: 4,
        name: 'VIP',
        textMain: 'Missing out on new features?',
        textAdditional: 'Get access to all the new features and maximise your results on TransferRoom. Upgrade to VIP.'
    },
    {
        id: 5,
        name: 'VIP (green)',
        textMain: 'Want to sell more players?',
        textAdditional: 'Get access to new features and maximise your results on TransferRoom. Upgrade to VIP.'
    }
];

export const promotionPlansEnum = {
    None: 0,
    FreeTrials: 1,
    RestrictedAccess: 2,
    ECA: 3,
    VIP: 4,
    VIPgreem: 5,
};

export const promotionReactions = {
    unknown: 0,
    upgradeNow: 1,
    done: 2,
    remove: 3
}