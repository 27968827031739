import axios from 'axios-config';
import { PitchSuggestionsItem, PitchSuggestionsResponse, PendingVerificationResponse, RecommendedPitchesRequest, PreconnectedPlayersResponse, VerificationPlayerMessage } from "./reonboarding-models";
import { PendingEmailVerification, PendingGenerateLinkVerification, PendingVerification } from '../verify-players/models';

export default class ReonboardingService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async getPreconnectedPlayers(): Promise<PreconnectedPlayersResponse> {
        const { data } = await axios.get(`api/AgencyReonboarding/GetPreconnectedPlayers`, this.axiosConfig)
        return data;
    }
    public static async addPreconnectedPlayers(playerIds: number[]): Promise<PreconnectedPlayersResponse> {        
        const { data } = await axios.post(`api/AgencyReonboarding/AddPlayers`, 
        {playerIds},
        this.axiosConfig)
        return data;
    }

    public static async removePlayer(id: number): Promise<void> {
        await axios.delete(`api/v2/AgencyPlayer/Players/${id}/Remove`, this.axiosConfig)
    }

    public static async remindMeLater(): Promise<any> {
        const { data } = await axios.post(`api/AgencyReonboarding/RemindMeLater`, this.axiosConfig)
        return data
    }

    public static async logGetStarted(): Promise<any> {
        const { data } = await axios.post(`api/AgencyReonboarding/GetStarted`, this.axiosConfig)
        return data
    }

    public static async sendVerificationEmail(request: PendingEmailVerification): Promise<any> {
        const { data } = await axios.post<any>(`api/v2/AgentPlayerInvitation/SendToVerifyEmails`, { players: [request] }, this.axiosConfig)
        return data;
    }

    public static async sendVerificationSms(request: PendingVerification): Promise<any> {
        const { data } = await axios.post<any>(`api/v2/AgentPlayerInvitation/SendToVerifyPhone`, { players: [request] }, this.axiosConfig)
        return data;
    }

    public static async getVerificationMessages(playerIds: Array<number>): Promise<Array<VerificationPlayerMessage>> {
        const { data } = await axios.post<any>(`api/v2/AgentPlayerInvitation/GetVerificationMessages`, playerIds, this.axiosConfig)
        return data;
    }

    public static async generateLink(request: PendingGenerateLinkVerification): Promise<any> {
        const { data } = await axios.post<any>(`api/v2/AgentPlayerInvitation/GenerateLinks`, { players: [request] }, this.axiosConfig)
        return data[0];
    }

    public static async getPitchSuggestions(request: PitchSuggestionsItem[]): Promise<PitchSuggestionsResponse> {
        const { data } = await axios.post(
            `api/AgencyReonboarding/GetPitchSuggestions`,
            { items: [ ...request ] },
            this.axiosConfig
        )
        return data;
    }

    public static async getSuggestedPlayersByKeyword(keyword: string): Promise<any> {
        const { data } = await axios.get(`api/AgencyReonboarding/SearchPlayers/${keyword}`, this.axiosConfig)               
        return data;
    }

    public static async getPendingVerificationPlayers(): Promise<PendingVerificationResponse> {
        const { data } = await axios.get(`api/AgencyReonboarding/PendingVerification`, this.axiosConfig)
        return data
    }

    public static async createConditionalPitches(request: RecommendedPitchesRequest): Promise<any> {
        const { data } = await axios.post(
            `api/AgencyReonboarding/CreateConditionalPitches`,
            request,
            this.axiosConfig
        )
        return data;
    }
}