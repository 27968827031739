import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer'
import historyAccessor from 'history-accessor';
import { agencyPaths } from 'routes/paths';
import AgencyHomePageService from 'api/agency/agent/landing/agency-homepage.service'
import * as DashboardController from 'pages/agency/authorized-agent/home-page/redux/dashboard.controller'
import { createSelector } from 'reselect';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';
import AgencyPlayerService from 'api/agency/player/shared/agency-player-service'
import * as authReducer from 'store/auth/authReducer';

export enum PlayerRepresentationStatus {
    Sent = 0,
    AddedToPortfolio = 1,
    Reject = 2
}

export enum PlayerStateEnum {
    Default = 0,
    Regular = 1,
    Premium = 2,
    RequestRegularCredits = 3,
    RequestPremiumCredits = 4,
    RequestSent = 5
}

export class ModalData { playerId: number; playerName: string; invitationUid: string; playerState: PlayerStateEnum }

export class GetPlayerRepresentationItemModel {
    playerId: number;
    playerName: string;
    invitationGuid: string;
    squadId?: number;
    squadName?: string;
    representationStatus?: PlayerRepresentationStatus
}


class PlayerRequestState {
    playersRequest: Array<GetPlayerRepresentationItemModel>;
    processingIds: Array<number>;
    acceptModal: ModalData;
    denyModal: ModalData;
    restrictedCostModal: ModalData;
    shareInsightsToggled: boolean;
}

const defaultState: PlayerRequestState = {
    playersRequest: [],
    processingIds: [],
    acceptModal: null,
    denyModal: null,
    restrictedCostModal: null,
    shareInsightsToggled: true
}

const stateController = new StateController<PlayerRequestState>(
    "AGENCY/LANDING_PAGE/PLAYER_REQUEST",
    defaultState
);

class Actions {
    public static dispose() {
        return (dispatch) => { dispatch(stateController.setState(defaultState)) }
    }

    public static toggleShareInsights() {
        return (dispatch, getState: () => AppState) => {
            let currentState = getState().agency.landingPage.playerRequest.shareInsightsToggled;
            dispatch(stateController.setState({ shareInsightsToggled: !currentState }))
        }
    }

    public static loadPlayerRequest() {
        return async (dispatch) => {
            const data = await AgencyHomePageService.getPlayerPerRequest();
            dispatch(stateController.setState({ playersRequest: data }))
        }
    }

    public static openRestrictedModal(playerId: number, playerName: string, uid: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ restrictedCostModal: { playerId, playerName, invitationUid: uid, playerState: PlayerStateEnum.Default } }))
            const player = Selectors.getPlayersRequest(getState()).find(i => i.invitationGuid === uid)

            dispatch(userActivityInsert({
                PageName: 'Agency Home [Representation request]',
                Message: 'Clicked Add',
                PlayerId: playerId,
                ClubId: player.squadId,
                PageType: PageType.AgencyHome
            }))
        }
    }
    public static closeRestrictedModal() {
        return (dispatch, getState: () => AppState) => {
            const modal = Selectors.restrictedCostModalOpen(getState())
            const player = Selectors.getPlayersRequest(getState()).find(i => i.invitationGuid === modal.invitationUid)
            dispatch(stateController.setState({ restrictedCostModal: null }))

            dispatch(userActivityInsert({
                PageName: 'Agency Home [Representation request]',
                Message: 'Cancelled',
                PlayerId: player.playerId,
                ClubId: player.squadId,
                PageType: PageType.AgencyHome
            }))
        }
    }
    public static openSubscriptionPage() {
        return (dispatch, getState: () => AppState) => {
            const modal = Selectors.restrictedCostModalOpen(getState())
            const player = Selectors.getPlayersRequest(getState()).find(i => i.invitationGuid === modal.invitationUid)
            historyAccessor.push(agencyPaths.subscriptionFlow)
            dispatch(stateController.setState({ restrictedCostModal: null }))

            dispatch(userActivityInsert({
                PageName: 'Agency Home [Representation request]',
                Message: 'Opened Add-on Subscription',
                PlayerId: player.playerId,
                ClubId: player.squadId,
                PageType: PageType.AgencyHome
            }))
        }
    }

    public static openDenyModal(playerId: number, playerName: string, uid: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ denyModal: { playerId, playerName, invitationUid: uid, playerState: PlayerStateEnum.Default } }));

            const player = Selectors.getPlayersRequest(getState()).find(i => i.invitationGuid === uid)

            dispatch(userActivityInsert({
                PageName: 'Agency Home [Representation request]',
                Message: 'Clicked Deny',
                PlayerId: playerId,
                ClubId: player.squadId,
                PageType: PageType.AgencyHome
            }))
        }
    }
    public static closeDenyModal() {
        return (dispatch, getState: () => AppState) => {
            const modal = Selectors.denyModalOpen(getState())
            const player = Selectors.getPlayersRequest(getState()).find(i => i.invitationGuid === modal.invitationUid)
            dispatch(stateController.setState({ denyModal: null }))

            dispatch(userActivityInsert({
                PageName: 'Agency Home [Representation request]',
                Message: 'Cancelled',
                PlayerId: player.playerId,
                ClubId: player.squadId,
                PageType: PageType.AgencyHome
            }))
        }
    }
    public static denyPlayer() {
        return async (dispatch, getState: () => AppState) => {
            const playersRequest = Selectors.getPlayersRequest(getState())
            const modalData = Selectors.denyModalOpen(getState())
            const processingIds = Selectors.processingIds(getState())

            dispatch(stateController.setState({ processingIds: [...processingIds, modalData.playerId] }));

            await AgencyHomePageService.denyPlayerRequest(modalData.invitationUid);

            const player = playersRequest.find(i => i.invitationGuid === modalData.invitationUid)

            dispatch(userActivityInsert({
                PageName: 'Agency Home [Representation request]',
                Message: 'Denied Request',
                PlayerId: player.playerId,
                ClubId: player.squadId,
                PageType: PageType.AgencyHome
            }))

            dispatch(stateController.setState({
                playersRequest: playersRequest.map(i => i.invitationGuid === modalData.invitationUid ? { ...i, representationStatus: PlayerRepresentationStatus.Reject } : i),
                denyModal: null,
                processingIds: processingIds.filter(i => i === modalData.playerId)
            }))
            dispatch(DashboardController.Actions.decreaseTotalPlayerCounter());
        }
    }

    public static openAcceptModal(playerId: number, playerName: string, uid: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ acceptModal: { playerId, playerName, invitationUid: uid, playerState: PlayerStateEnum.Regular }, shareInsightsToggled: true }))

            const player = Selectors.getPlayersRequest(getState()).find(i => i.invitationGuid === uid)

            dispatch(userActivityInsert({
                PageName: 'Agency Home [Representation request]',
                Message: 'Clicked Add',
                PlayerId: playerId,
                ClubId: player.squadId,
                PageType: PageType.AgencyHome
            }))
        }
    }
    public static closeAcceptModal(playerState?: PlayerStateEnum) {
        return (dispatch, getState: () => AppState) => {
            const modal = Selectors.acceptModalOpen(getState())
            const player = Selectors.getPlayersRequest(getState()).find(i => i.invitationGuid === modal.invitationUid)
            dispatch(stateController.setState({ acceptModal: null }))

            let message;
            if (playerState == PlayerStateEnum.RequestPremiumCredits) {
                message = 'Closed Priority Credits Request';
            } else if (playerState == PlayerStateEnum.RequestRegularCredits) {
                message = 'Closed Credits Request';
            } else {
                message = 'Cancelled';
            }

            dispatch(userActivityInsert({
                PageName: 'Agency Home [Representation request]',
                Message: message,
                PlayerId: player.playerId,
                ClubId: player.squadId,
                PageType: PageType.AgencyHome
            }))
        }
    }

    public static requestCreditsState() {
        return (dispatch, getState: () => AppState) => {
            const modal = Selectors.acceptModalOpen(getState())
            dispatch(stateController.setState({ acceptModal: { ...modal, playerState: PlayerStateEnum.RequestRegularCredits } }));
        }
    }

    public static requestPremiumCreditsState() {
        return (dispatch, getState: () => AppState) => {
            const modal = Selectors.acceptModalOpen(getState())
            dispatch(stateController.setState({ acceptModal: { ...modal, playerState: PlayerStateEnum.RequestPremiumCredits } }));
        }
    }

    public static requestRegularCredits() {
        return async (dispatch, getState: () => AppState) => {
            const modalData = Selectors.acceptModalOpen(getState())
            const processingIds = Selectors.processingIds(getState())
            const playersRequest = Selectors.getPlayersRequest(getState())
            const player = playersRequest.find(i => i.playerId === modalData.playerId)

            dispatch(stateController.setState({ processingIds: [...processingIds, modalData.playerId] }));

            await AgencyPlayerService.requestPlayerCreditsEmail({
                isRegular: true,
                isPriority: false
            });

            dispatch(stateController.setState({
                acceptModal: { ...modalData, playerState: PlayerStateEnum.RequestSent },
                processingIds: processingIds.filter(i => i === modalData.playerId)
            }));

            dispatch(userActivityInsert({
                PageName: 'Agency Home [Representation request]',
                Message: 'Sent Credits Request',
                PlayerId: player.playerId,
                ClubId: player.squadId,
                PageType: PageType.AgencyHome,
            }))
        }
    }
    public static requestPremiumCredits() {
        return async (dispatch, getState: () => AppState) => {
            const modalData = Selectors.acceptModalOpen(getState())
            const processingIds = Selectors.processingIds(getState())
            const playersRequest = Selectors.getPlayersRequest(getState())
            const player = playersRequest.find(i => i.playerId === modalData.playerId)

            dispatch(stateController.setState({ processingIds: [...processingIds, modalData.playerId] }));

            await AgencyPlayerService.requestPlayerCreditsEmail({
                isRegular: false,
                isPriority: true
            });

            dispatch(stateController.setState({
                acceptModal: { ...modalData, playerState: PlayerStateEnum.RequestSent },
                processingIds: processingIds.filter(i => i === modalData.playerId)
            }));
            dispatch(userActivityInsert({
                PageName: 'Agency Home [Representation request]',
                Message: 'Sent Priority Credits Request',
                PlayerId: player.playerId,
                ClubId: player.squadId,
                PageType: PageType.AgencyHome
            }))
        }
    }

    public static acceptPlayer(isRegular: boolean, sharedInsights: boolean) {
        return async (dispatch, getState: () => AppState) => {
            const playersRequest = Selectors.getPlayersRequest(getState())
            const modalData = Selectors.acceptModalOpen(getState())
            const processingIds = Selectors.processingIds(getState())
            const isLowerTierAgency = authReducer.isLowerTierAgency(getState());
            const transparencyPermitted = authReducer.isTransparencyPermitted(getState());

            dispatch(stateController.setState({ processingIds: [...processingIds, modalData.playerId] }));

            await AgencyHomePageService.acceptPlayerRequest(modalData.invitationUid, isRegular, transparencyPermitted ? sharedInsights : false);
            const player = playersRequest.find(i => i.invitationGuid === modalData.invitationUid)

            if (isRegular) {
                dispatch(userActivityInsert({
                    PageName: 'Agency Home [Representation request]',
                    Message: 'Added Player',
                    PlayerId: player.playerId,
                    ClubId: player.squadId,
                    PageType: PageType.AgencyHome
                }))
            }
            else {
                dispatch(userActivityInsert({
                    PageName: 'Agency Home [Representation request]',
                    Message: transparencyPermitted ? 
                    `Upgraded Player to Priority; ${sharedInsights ? 'Shared Insights with Player' : 'Unshared Insights with Player'}` :
                    `Upgraded Player to Priority`,
                    PlayerId: player.playerId,
                    ClubId: player.squadId,
                    PageType: PageType.AgencyHome
                }))
            }

            dispatch(stateController.setState({
                playersRequest: playersRequest.map(i => i.invitationGuid === modalData.invitationUid ? { ...i, representationStatus: PlayerRepresentationStatus.AddedToPortfolio } : i),
                acceptModal: isRegular && !isLowerTierAgency ? { ...modalData, playerState: PlayerStateEnum.Premium } : null,
                processingIds: processingIds.filter(i => i === modalData.playerId)
            }))

            dispatch(DashboardController.Actions.decreasePlayerCreditAndIncreaseVerifiedPlayers(isRegular))
            if(!isLowerTierAgency){
                dispatch(DashboardController.Actions.loadTransparencyData())
            }
        }
    }

}

class Selectors {
    public static getRoot = (state: AppState) => state.agency.landingPage.playerRequest
    public static getPlayersRequest = (state: AppState) => Selectors.getRoot(state).playersRequest
    public static processingIds = (state: AppState) => Selectors.getRoot(state).processingIds
    public static acceptModalOpen = (state: AppState) => Selectors.getRoot(state).acceptModal
    public static denyModalOpen = (state: AppState) => Selectors.getRoot(state).denyModal
    public static restrictedCostModalOpen = (state: AppState) => Selectors.getRoot(state).restrictedCostModal
    public static acceptProcessing = createSelector([Selectors.acceptModalOpen, Selectors.processingIds],
        (modalData: ModalData, processingIds: Array<number>) => modalData && processingIds.includes(modalData.playerId)
    )
    public static denyProcessing = createSelector([Selectors.denyModalOpen, Selectors.processingIds],
        (modalData: ModalData, processingIds: Array<number>) => modalData && processingIds.includes(modalData.playerId)
    )
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    PlayerRequestState as State,
    Actions as Actions,
    stateController as Controller,
    Selectors as Selectors,
};



