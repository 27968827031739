import { StateController } from 'utils/action-declaration';
import { SUPER_PITCH_TEST_INTEREST_MODAL } from 'constants/modals';
import { MODAL_OPEN, MODAL_CLOSE } from 'store/actionTypes';
import { AppState } from 'root.reducer'
import { SuperPitchService } from 'api/super-pitch/super-pitch'
import * as SuperPitchController from './common.controller'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';

class TestInterestModalState {
    isLoading: boolean;
}

const defaultState: TestInterestModalState = {
    isLoading: false
}

const stateController = new StateController<TestInterestModalState>(
    "SUPER_PITCH/TEST_INTEREST",
    defaultState
)

class Actions {

    public static testInterestBatch = (pitchId: number, pitchSquadIds: Array<number>) => async (dispatch, getState: () => AppState) => {
        try {
            dispatch(stateController.setState({ isLoading: true }))
            const squadId = getState().auth.squadId
            await SuperPitchService.testInterestBatch(squadId, pitchId, pitchSquadIds)
            dispatch(Actions.closeTestInterestModal())
            dispatch(SuperPitchController.Actions.superPitchesFetch())

            const data = getState().superPitch.common.pitches.byId[pitchId]
            const { recipientsSquadList } = data
            let selectedSquadNames = [];
            pitchSquadIds.forEach(id => {
                let selected = recipientsSquadList.filter(k => k.id === id)[0];
                if (selected != null) selectedSquadNames.push(selected.squad.name);
            });
            dispatch(userActivityInsert({
                Message: `Tested Interest: ${selectedSquadNames.join()}`,
                PageName: 'Super pitch [Test list]',
                PlayerId: data.playerInfo.id,
                PageType: PageType.Pitch
            }));
        } catch (e) {
            console.error(e)
        } finally {
            dispatch(stateController.setState({ isLoading: false }))
        }
    }

    public static openTestInterestModal = () => {
        return dispatch => {
            let command = { type: MODAL_OPEN, payload: { id: SUPER_PITCH_TEST_INTEREST_MODAL, content: {} } };
            dispatch(command);
        };
    };

    public static closeTestInterestModal = () => {
        return dispatch => {
            let command = { type: MODAL_CLOSE, payload: SUPER_PITCH_TEST_INTEREST_MODAL };
            dispatch(command);
        };
    };
}

class Selectors {
    public static getIsTestInterestFetching = (state: AppState) => {
        return state.superPitch.testInterestModal.isLoading
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    TestInterestModalState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};