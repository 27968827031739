import axios from 'axios-config';
import { ClubsListModel, SearchParameters, SupervisorsResponse, CompetitionsResponse, Country, UserModel } from './models';

export default class ClubsService {
  private static axiosConfig = {
    headers: { 'Content-Type': 'application/json' },
  }

  private static axiosConfigWithFile = {
    headers: { 'Content-Type': 'multipart/form-data' },
  }

  public static async getSquads(searchParameters: SearchParameters): Promise<ClubsListModel> {
    const { data } = await axios.post(
      `api/AdminSquads/GetSquads/${searchParameters.userId}`,
      searchParameters,
      this.axiosConfig
    );
    return data;
  }

  public static async getSupervisors(): Promise<SupervisorsResponse> {
    const { data } = await axios.get(
      `api/AdminSquads/GetSalesSupervisorUsers`,
      this.axiosConfig
    )
    return data
  }

  public static async getAllCompetitions(): Promise<CompetitionsResponse> {
    const { data } = await axios.get(
      `api/Competition/GetAll`,
      this.axiosConfig
    )
    return data
  }

  public static async assignSupervisor(squadId: number, supervisorUserId: number): Promise<any> {
    const url = supervisorUserId > 0
      ? `api/AdminSquads/AssignSupervisorToSquad/${squadId}/${supervisorUserId}`
      : `api/AdminSquads/AssignSupervisorToSquad/${squadId}`;

    const { data } = await axios.post(
      url,
      {
        id: squadId,
        supervisorId: supervisorUserId > 0 ? supervisorUserId  : undefined
      },
      this.axiosConfig
    );
    return data;
  }

  public static async uploadSquadLogo(squadId: number, file): Promise<any>{
    let bodyFormData = new FormData();
    bodyFormData.append('file', file);

    const { data } = await axios.post(
      `api/AdminSquads/UploadImage/${squadId}`,
      bodyFormData,
      this.axiosConfigWithFile
    )
    return data;
  }

  public static async deleteSquadLogo(squadId) {
    const { data } = await axios.post(
      `api/AdminSquads/DeleteImage/${squadId}`,
      null,
      this.axiosConfig
    )
    return true;
  }

  public static async loadAreas(): Promise<Array<Country>> {
    const { data } = await axios.get(
      `api/Area/GetAreasList`,
      this.axiosConfig
    );
    return data;
  }

  public static async updateArea(squadId: number, areaId: number, areaName: string) {
    const { data } = await axios.post(
      `api/Area/UpdateSquadArea/${squadId}/${areaId}`,
      {
        squadId: squadId,
        areaId: areaId,
        areaName: areaName
      },
      this.axiosConfig
    )
    return data;
  }

  public static async updateCompetition(squadId: number, competitionId: number, competitionName: string) {
    const { data } = await axios.post(
      `api/Competition/UpdateCompetition/${squadId}/${competitionId}`,
      {
        squadId: squadId,
        competitionId: competitionId,
        leagueName: competitionName
      },
      this.axiosConfig
    )
    return data;
  }

  public static async setFriendlyOnly(userId: number, isFriendlyOnly: boolean) {
    const { data } = await axios.post(
      `api/Users/SetFriendlyOnlyUser/${userId}/${isFriendlyOnly}`,
      null,
      this.axiosConfig
    )
    return data;
  }

  public static async getSquadUsersToImpersonate(squadId: number): Promise<Array<UserModel>> {
    const { data } = await axios.get(
      `api/AdminImpersonation/GetSquadUsersToImpersonate?squadId=${squadId}`,
      this.axiosConfig
    );
    return data;
  }

  public static async getSession(squadId: number, userId: number) {
    const { data } = await axios.get(
      `api/AdminImpersonation/RequestSession?squadId=${squadId}&type=squad&userId=${userId}`,
      this.axiosConfig
    );
    return data;
  }

}
