import { AppState } from "root.reducer";
import {
    AllUsersCriteria,
    FilterOptions,
    SupervisorItem,
    UserModel,
    AccountAccessTabState,
    MultiAccountAccessItemModel,
    EditUserTabKeyEnum
} from 'api/admin/users/models'
import { StateController } from "utils/action-declaration";
import { AdminAllUsersService } from 'api/admin/users/users.service'
import { createSelector } from "reselect";
import { getAreaPhoneCodes } from "app/phone-codes/actions/phone-codes.actions";
import _ from 'lodash';
import { notificationCreate } from 'app/notifications/notifications.actions'
import { getAuthSquadId, getAuthClubId, getAuth } from 'store/auth/authReducer';
import { getPlayerSearchFilterCriteria } from "store/playerSearch/reducers/playerSearchFilterReducer";
import AdminPermissionsService from "api/admin/admin-permissions/admin-permissions.service";

class AllUsersState {
    items: UserModel[];
    isLoading: boolean;
    editingUserId: number;
    filterOptions: FilterOptions;
    previousFilterSnapshot?: FilterOptions;
    supervisors: Array<SupervisorItem>;
    pagination: {
        rowCount: number,
        pageCount: number
    };
    processing: ProcessingState;
    criteria: AllUsersCriteria;
    accountAccessTabState: AccountAccessTabState;
    tabKey: EditUserTabKeyEnum;
    allowedColumns: string[];
}

export class ProcessingState {
    masterColumnProcessingIds: Array<number>;
    eventColumnProcessingIds: Array<number>;
    dmColumnProcessingIds: Array<number>;
    friendlyTagProcessingIds: Array<number>;
    activeStateProcessingIds: Array<number>;
    deleteUserProcessingIds: Array<number>;
    deleteAgentProcessingIds: Array<number>;
}
const defaultFilterOptions = {
    sortOrder: 'date',
    isSortOrderAscending: false,

    userName: '',
    clubName: '',
    areaName: '',
    positionName: '',
    userTypeName: 'all',
    isEvent: null,
    isDM: null,
    email: '',
    phone: '',
    isRegistrationFinished: null,
    isTermsAgreed: null,
    isFriendlyRelated: null,
    isActive: null,
    mobileDevice: null,
    supervisorId: null,
    date: '',

    userId: null,

    page: 1,
    recordsPerPage: 20,
}
const defaultState: AllUsersState = {
    items: [],
    isLoading: false,
    editingUserId: null,
    previousFilterSnapshot: defaultFilterOptions,
    filterOptions: defaultFilterOptions,
    supervisors: [],
    pagination: {
        rowCount: 1,
        pageCount: 1
    },
    processing: {
        masterColumnProcessingIds: [],
        eventColumnProcessingIds: [],
        dmColumnProcessingIds: [],
        friendlyTagProcessingIds: [],
        activeStateProcessingIds: [],
        deleteUserProcessingIds: [],
        deleteAgentProcessingIds: []
    },
    criteria: null,

    accountAccessTabState: {
        isLoading: false,
        keyword: '',
        autosuggestProcessing: false,
        clubsList: [],
        suggestions: [],
        selectedClubsList: [],
        isRemoveClubModalOpen: false,
        removingClubId: null
    },
    tabKey: EditUserTabKeyEnum.Profile,
    allowedColumns: []
}

const stateController = new StateController<AllUsersState>(
    'ADMIN_V2/ALL_USERS',
    defaultState
)

class Actions {

    public static initData() {
        return (dispatch, getState: () => AppState) => {
            try {
                Promise.allSettled([
                    dispatch(Actions.loadCriteria()),
                    dispatch(Actions.loadAllUsersData()),
                    dispatch(Actions.loadSupervisors()),
                    dispatch(getAreaPhoneCodes()),
                    dispatch(Actions.loadAllowedColumns())
                ])
            } catch (e) {
                console.error(e)
            }
        }
    }

    public static fetchItems(filterOptions): ReturnType<typeof AdminAllUsersService.getAllUser> {
        return new Promise(async (resolve, reject) => {
            try {
                const data = await AdminAllUsersService.getAllUser(filterOptions)
                resolve(data)
            } catch (e) {
                reject(e)
            }
        })
    }

    public static loadAllUsersData() {

        function wasFiltersChanged(state: AppState) {
            // we should ignore pagination changes
            const filterOptionsWithoutPagination = {
                ...state.admin.allUsers.filterOptions,
                page: defaultState.filterOptions.page,
                recordsPerPage: defaultState.filterOptions.recordsPerPage,
            }
            const defaultFilterOptionsWithoutPagination = {
                ...state.admin.allUsers.previousFilterSnapshot,
                page: defaultState.filterOptions.page,
                recordsPerPage: defaultState.filterOptions.recordsPerPage,
            }
            const wasFiltersChanged = JSON.stringify(filterOptionsWithoutPagination) !== JSON.stringify(defaultFilterOptionsWithoutPagination)
            return wasFiltersChanged
        }

        return async (dispatch, getState: () => AppState) => {
            try {

                const shouldDisposePagination = wasFiltersChanged(getState())
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    previousFilterSnapshot: {
                        ...prevState.filterOptions,
                    },
                    filterOptions: {
                        ...prevState.filterOptions,
                        page: shouldDisposePagination ? 1 : prevState.filterOptions.page
                    },
                })))

                const substate = getState().admin.allUsers
                const userId = getState().auth.userId
                const filterOptions = {
                    ...substate.filterOptions,
                    userId: userId
                }

                dispatch(stateController.setState({ isLoading: true }))
                const data = await Actions.fetchItems(filterOptions)

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    items: data.output,
                    pagination: {
                        pageCount: data.pageCount,
                        rowCount: data.rowCount
                    }
                })))
            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState({ isLoading: false }))
            }
        }

    }

    public static silentUpdateAllUsersData() {
        return async (dispatch, getState) => new Promise(async (resolve, reject) => {
            try {
                const substate = getState().admin.allUsers
                const userId = getState().auth.userId
                const filterOptions = {
                    ...substate.filterOptions,
                    userId: userId
                }

                const data = await Actions.fetchItems(filterOptions)

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    items: data.output,
                    filterOptions: {
                        ...prevState.filterOptions,
                        page: data.currentPageNumber
                    },
                    pagination: {
                        pageCount: data.pageCount,
                        rowCount: data.rowCount
                    }
                })))
                resolve(true)
            } catch (e) {
                console.error(e)
                reject(e)
            }
        })
    }

    public static loadSupervisors() {
        return async (dispatch, getState: () => AppState) => new Promise(async (resolve, reject) => {
            try {
                const users = await AdminAllUsersService.getSupervisors()
                const supervisors = users.output.map(item => ({
                    value: item.id.toString(),
                    name: item.name
                }))
                dispatch(stateController.setState({ supervisors: supervisors }))
                resolve(true)
            } catch (e) {
                console.error(e)
                reject(false)
            }
        })
    }

    public static paginationChange(pageNumber: number, pageSize: number) {
        return (dispatch, getState: () => AppState) => {
            const { filterOptions } = Selectors.selectState(getState());

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterOptions: {
                    ...prevState.filterOptions,
                    page: pageNumber,
                    recordsPerPage: pageSize
                }
            })))

            if (pageNumber !== filterOptions.page || pageSize !== filterOptions.recordsPerPage) {
                dispatch(Actions.loadAllUsersData());
            }
        }
    }

    public static applySearch() {
        return dispatch => dispatch(Actions.loadAllUsersData())
    }
    public static disposeFilters() {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({ ...prevState, filterOptions: defaultState.filterOptions })))
            dispatch(Actions.applySearch())
        }
    }

    public static onSort(key: string = 'date', order: 'ascend' | 'descend' | undefined) {
        return (dispatch, getState: () => AppState) => {
            const { filterOptions } = Selectors.selectState(getState());
            const isSortOrderAscending = order === 'ascend';

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                filterOptions: {
                    ...prevState.filterOptions,
                    sortOrder: key,
                    isSortOrderAscending: order === 'ascend' ? true : false
                }
            })))

            if (key !== filterOptions.sortOrder || isSortOrderAscending !== filterOptions.isSortOrderAscending) {
                dispatch(Actions.loadAllUsersData());
            }
        }
    }

    public static onChangeName(value: string) {
        return dispatch => dispatch(stateController.setState(prevState => ({
            ...prevState,
            filterOptions: {
                ...prevState.filterOptions,
                userName: value
            }
        })))
    }
    public static onChangeClubName(value: string) {
        return dispatch => dispatch(stateController.setState(prevState => ({
            ...prevState,
            filterOptions: {
                ...prevState.filterOptions,
                clubName: value
            }
        })))
    }
    public static onChangeCountry(value: string) {
        return dispatch => dispatch(stateController.setState(prevState => ({
            ...prevState,
            filterOptions: {
                ...prevState.filterOptions,
                areaName: value
            }
        })))
    }
    public static onChangePosition(value: string) {
        return dispatch => dispatch(stateController.setState(prevState => ({
            ...prevState,
            filterOptions: {
                ...prevState.filterOptions,
                positionName: value
            }
        })))
    }
    public static onChangeUserType(value: string) {
        return dispatch => dispatch(stateController.setState(prevState => ({
            ...prevState,
            filterOptions: {
                ...prevState.filterOptions,
                userTypeName: value
            }
        })))
    }
    public static onChangeIsEvent(value: boolean) {
        return dispatch => dispatch(stateController.setState(prevState => ({
            ...prevState,
            filterOptions: {
                ...prevState.filterOptions,
                isEvent: value
            }
        })))
    }
    public static onChangeIsDM(value: boolean) {
        return dispatch => dispatch(stateController.setState(prevState => ({
            ...prevState,
            filterOptions: {
                ...prevState.filterOptions,
                isDM: value
            }
        })))
    }
    public static onChangeEmail(value: string) {
        return dispatch => dispatch(stateController.setState(prevState => ({
            ...prevState,
            filterOptions: {
                ...prevState.filterOptions,
                email: value
            }
        })))
    }
    public static onChangePhone(value: string) {
        return dispatch => dispatch(stateController.setState(prevState => ({
            ...prevState,
            filterOptions: {
                ...prevState.filterOptions,
                phone: value
            }
        })))
    }
    public static onChangeIsRegistrationFinished(value: boolean) {
        return dispatch => dispatch(stateController.setState(prevState => ({
            ...prevState,
            filterOptions: {
                ...prevState.filterOptions,
                isRegistrationFinished: value
            }
        })))
    }
    public static onChangeIsTermsAgreed(value: boolean) {
        return dispatch => dispatch(stateController.setState(prevState => ({
            ...prevState,
            filterOptions: {
                ...prevState.filterOptions,
                isTermsAgreed: value
            }
        })))
    }
    public static onChangeIsFriendlyTag(value: boolean) {
        return dispatch => dispatch(stateController.setState(prevState => ({
            ...prevState,
            filterOptions: {
                ...prevState.filterOptions,
                isFriendlyRelated: value
            }
        })))
    }
    public static onChangeIsActive(value: string) {
        return dispatch => dispatch(stateController.setState(prevState => ({
            ...prevState,
            filterOptions: {
                ...prevState.filterOptions,
                isActive: value
            }
        })))
    }
    public static onChangeMobileDevice(value: string) {
        return dispatch => dispatch(stateController.setState(prevState => ({
            ...prevState,
            filterOptions: {
                ...prevState.filterOptions,
                mobileDevice: value
            }
        })))
    }
    public static onChangeSupervisor(value: string) {
        return dispatch => dispatch(stateController.setState(prevState => ({
            ...prevState,
            filterOptions: {
                ...prevState.filterOptions,
                supervisorId: value
            }
        })))
    }
    public static onChangeDate(value: string) {
        return dispatch => dispatch(stateController.setState(prevState => ({
            ...prevState,
            filterOptions: {
                ...prevState.filterOptions,
                date: value
            }
        })))
    }

    public static setIsMasterUser(userId: number, value: boolean) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        masterColumnProcessingIds: [...prevState.processing.masterColumnProcessingIds, userId]
                    }
                })))
                const result = await AdminAllUsersService.setIsMasterUser(userId, value)


                if (result) {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        items: [...prevState.items.map(x => x.id === userId ? { ...x, isMasterUser: value } : x)]
                    })))
                }

                await dispatch(Actions.silentUpdateAllUsersData()) // Required to update userType

            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        masterColumnProcessingIds: prevState.processing.masterColumnProcessingIds.filter(item => item !== userId)
                    }
                })))
            }
        }
    }

    public static setIsEvent(userId: number, value: boolean) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        eventColumnProcessingIds: [...prevState.processing.eventColumnProcessingIds, userId]
                    }
                })))
                const result = await AdminAllUsersService.setIsEvent(userId, value)
                if (result) {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        items: [...prevState.items.map(x => x.id === userId ? { ...x, isEvent: value } : x)]
                    })))
                }

            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        eventColumnProcessingIds: prevState.processing.eventColumnProcessingIds.filter(item => item !== userId)
                    }
                })))
            }
        }
    }

    public static setIsDM(userId: number, value: boolean) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        dmColumnProcessingIds: [...prevState.processing.dmColumnProcessingIds, userId]
                    }
                })))
                const result = await AdminAllUsersService.setIsDM(userId, value)
                if (result) {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        items: [...prevState.items.map(x => x.id === userId ? { ...x, isDM: value } : x)]
                    })))
                }

            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        dmColumnProcessingIds: prevState.processing.dmColumnProcessingIds.filter(item => item !== userId)
                    }
                })))
            }
        }
    }

    public static setIsFriendlyRelated(userId: number, value: boolean) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const substate = getState().admin.allUsers
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        friendlyTagProcessingIds: [...prevState.processing.friendlyTagProcessingIds, userId]
                    }
                })))
                const result = await AdminAllUsersService.setIsDM(userId, value)
                if (result) {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        items: [...prevState.items.map(x => x.id === userId ? { ...x, isFriendlyRelated: value } : x)]
                    })))
                }

            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        friendlyTagProcessingIds: prevState.processing.friendlyTagProcessingIds.filter(item => item !== userId)
                    }
                })))
            }
        }
    }

    public static setIsActive(userId: number, value: boolean) {
        return async (dispatch, getState: () => AppState) => new Promise(async (resolve, reject) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        activeStateProcessingIds: [...prevState.processing.activeStateProcessingIds, userId]
                    }
                })))
                const result = await AdminAllUsersService.setIsActive(userId, value)
                if (result) {
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        items: [...prevState.items.map(x => x.id === userId ? { ...x, active: value } : x)]
                    })))
                }
                resolve(true)

            } catch (e) {
                console.error(e)
                reject(e)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: {
                        ...prevState.processing,
                        activeStateProcessingIds: prevState.processing.activeStateProcessingIds.filter(item => item !== userId)
                    }
                })))
            }
        })
    }

    public static unlockUser(userId: number, shouldSendSeretPasswordLink: boolean) {
        return async (dispatch, getState: () => AppState) => new Promise(async (resolve, reject) => {
            try {
                const result = await AdminAllUsersService.unlockUser(userId, shouldSendSeretPasswordLink)
                if (result) {
                    const updatedItems = getState().admin.allUsers.items
                    updatedItems.find(item => item.id === userId).lockTime = null
                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        items: updatedItems
                    })))
                    resolve(true)
                } else {
                    reject("Failed")
                }
            } catch (error) {
                console.error(error)
                reject(error)
            }
        })
    }

    public static getResetPasswordLink(userId: number) {
        return async (dispatch, getState: () => AppState): Promise<string> => new Promise(async (resolve, reject) => {
            try {
                const url = await AdminAllUsersService.getResetPasswordLink(userId)
                if (url) {
                    resolve(url)
                } else {
                    reject("Failed")
                }
            } catch (error) {
                console.error(error)
                reject(error)
            }
        })
    }

    public static deleteUser(userId: number, isAgent: boolean) {
        return async (dispatch, getState: () => AppState): Promise<boolean> => new Promise(async (resolve, reject) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: isAgent ?
                        {
                            ...prevState.processing,
                            deleteAgentProcessingIds: [...prevState.processing.deleteAgentProcessingIds, userId]
                        }
                        :
                        {
                            ...prevState.processing,
                            deleteUserProcessingIds: [...prevState.processing.deleteUserProcessingIds, userId]
                        }
                })))


                const result = isAgent ?
                    await AdminAllUsersService.resetAgentInvitation(userId)
                    :
                    await AdminAllUsersService.deleteUser(userId);

                if (result) {
                    await dispatch(Actions.silentUpdateAllUsersData())
                    resolve(result)
                } else {
                    reject("Failed")
                }
            } catch (error) {
                console.error(error)
                reject(error)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processing: isAgent ?
                        {
                            ...prevState.processing,
                            deleteAgentProcessingIds: [...prevState.processing.deleteAgentProcessingIds].filter(item => item !== userId)
                        }
                        :
                        {
                            ...prevState.processing,
                            deleteUserProcessingIds: [...prevState.processing.deleteUserProcessingIds].filter(item => item !== userId)
                        }
                })))
            }
        })
    }

    public static closeModal() {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                editingUserId: null,
                tabKey: EditUserTabKeyEnum.Profile
            })))
            dispatch(Actions.disposeAccountAccess())
        }
    }

    public static toggleEditUser(userId: number, key?: EditUserTabKeyEnum) {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                editingUserId: userId,
            })))

            if (key) {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    tabKey: key
                })))
            }
        }
    }

    public static onChangeTab(tabKey: EditUserTabKeyEnum) {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                activeTab: tabKey
            })))
        }
    }

    public static loadCriteria() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const data = await AdminAllUsersService.getCriteria()

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    criteria: data,
                })))
            } catch (e) {
                console.error(e)
            }
        }
    }

    public static loadAllowedColumns() {
        return async (dispatch, getState: () => AppState) => {
            try {
                //21 is permission Id for "All Users"
                const { userId } = getAuth(getState());
                const data = await AdminPermissionsService.getAllowedColumns(userId, 21);

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    allowedColumns: data,
                })))
            } catch (e) {
                console.error(e)
            }
        }
    }

    //--------------------------- ACCOUNT ACCESS TAB ----------------------------------
    
    public static disposeAccountAccess = () => {
        return async dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                accountAccessTabState: {
                    ...defaultState.accountAccessTabState
                },
            })));
        };
    };

    public static loadMultiAccountAccessClubs(squds: MultiAccountAccessItemModel[]) {
        return async (dispatch, getState: () => AppState) => {

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                accountAccessTabState: {
                    ...prevState.accountAccessTabState,
                    isLoading: true
                }
            })));
            try {

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    accountAccessTabState: {
                        ...prevState.accountAccessTabState,
                        selectedClubsList: squds,
                    }
                })));

            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    accountAccessTabState: {
                        ...prevState.accountAccessTabState,
                        isLoading: false
                    }
                })));
            }
        }
    }

    public static onKeywordChange(value: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                accountAccessTabState: {
                    ...prevState.accountAccessTabState,
                    keyword: value,
                    autosuggestProcessing: true
                }
            })));

            if (value !== '') {
                Actions.loadFirstSuggestionsDebounceFunc(dispatch, value)
            } else {
                dispatch(Actions.onClearKeyword());
            }
        }
    }

    private static loadFirstSuggestionsDebounceFunc = _.debounce((dispatch, keyword) => dispatch(Actions.getClubAutosuggestions(keyword)), 1000)

    public static getClubAutosuggestions(keyword: string) {
        return async (dispatch, getState: () => AppState) => {

            try {
                const squadId = getAuthSquadId(getState());
                const clubId = getAuthClubId(getState());
                const clubOnly = true;
                const filter = getPlayerSearchFilterCriteria(getState());

                const suggestionClubs = await AdminAllUsersService.getMultiAccessAutosuggestionSquads(squadId, clubId, { ...filter, keyword }, clubOnly, true, true)

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    accountAccessTabState: {
                        ...prevState.accountAccessTabState,
                        suggestions: suggestionClubs
                    }
                })));

            } finally {

                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    accountAccessTabState: {
                        ...prevState.accountAccessTabState,
                        autosuggestProcessing: false
                    }
                })));
            }
        }
    }

    public static onClearKeyword() {
        return (dispatch, getState: () => AppState) => {
            const clubs = Selectors.getUserMultiAccessClubs(getState())
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                accountAccessTabState: {
                    ...prevState.accountAccessTabState,
                    keyword: '',
                    autosuggestProcessing: false,
                }
            })));
        }
    }

    public static addClub(squad: any) {

        return async (dispatch, getState: () => AppState) => {

            const clubs = Selectors.getUserMultiAccessSelectedClubs(getState())

            const newClub = {
                squad: {
                    id: squad.id,
                    clubId: null,
                    shortName: squad.value,
                    name: squad.value,
                    league: squad.league,
                    country: squad.countryName,
                    logo: squad.logo,
                    isLogoRestricted: false,
                },
                isMainSquad: false
            }

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                accountAccessTabState: {
                    ...prevState.accountAccessTabState,
                    selectedClubsList: [...clubs, newClub],
                }
            })));

            dispatch(notificationCreate({ message: 'Club is chosen!', level: 'success' }))
        };
    };

    public static openRemoveClubModal(clubId: number) {
        return (dispatch, getState: () => AppState) => {

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                accountAccessTabState: {
                    ...prevState.accountAccessTabState,
                    isRemoveClubModalOpen: true,
                    removingClubId: clubId,
                }
            })));
        }
    }
    public static closeRemoveClubModal() {
        return (dispatch) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                accountAccessTabState: {
                    ...prevState.accountAccessTabState,
                    isRemoveClubModalOpen: false,
                    removingClubId: null,
                }
            })));
            // dispatch(notificationCreate({ message: 'Club is removed!', level: 'warn' }))
        }
    }

    public static deleteClub() {
        return (dispatch, getState: () => AppState) => {
            const removingClubId = Selectors.getAccountAccessTabState(getState()).removingClubId
            const clubs = Selectors.getUserMultiAccessSelectedClubs(getState())
            const list = clubs.filter(item => item.squad.id !== removingClubId)

            dispatch(Actions.closeRemoveClubModal())
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                accountAccessTabState: {
                    ...prevState.accountAccessTabState,
                    selectedClubsList: list,
                }
            })));
        }
    }
}

class Selectors {
    public static selectState = (state: AppState) => state.admin.allUsers
    public static selectItems = createSelector(
        Selectors.selectState,
        state => state.items
    )
    public static selectFilterOptions = createSelector(
        Selectors.selectState,
        state => state.filterOptions
    )
    public static selectPagination = createSelector(
        Selectors.selectState,
        state => state.pagination
    )
    public static selectIsLoading = createSelector(
        Selectors.selectState,
        state => state.isLoading
    )
    public static selectCurrentPage = createSelector(
        Selectors.selectFilterOptions,
        filterOptions => filterOptions.page
    )
    public static selectRecordsPerPage = createSelector(
        Selectors.selectFilterOptions,
        filterOptions => filterOptions.recordsPerPage
    )

    public static selectIsAllFiltersSetToDefault = createSelector(
        Selectors.selectFilterOptions,
        filterOptions => JSON.stringify(defaultState.filterOptions) === JSON.stringify(filterOptions)
    )
    public static selectSupervisors = createSelector(
        Selectors.selectState,
        state => state.supervisors
    )
    public static selectProcessingsState = createSelector(
        Selectors.selectState,
        state => state.processing
    )
    public static selectCriteria = createSelector(
        Selectors.selectState,
        state => state.criteria
    )

    public static getInitialTabKey = createSelector(
        Selectors.selectState,
        state => state.tabKey
    )

    public static getAccountAccessTabState = (state: AppState) => Selectors.selectState(state).accountAccessTabState
    public static getUserMultiAccessClubs = (state: AppState) => Selectors.getAccountAccessTabState(state).clubsList
    public static getUserMultiAccessSelectedClubs = (state: AppState) => Selectors.getAccountAccessTabState(state).selectedClubsList
    public static getUserMultiAccessSelectedClubsIds = (state: AppState) => Selectors.getUserMultiAccessSelectedClubs(state).map(item => item.squad.id)
    public static getEditingUserId = (state: AppState) => Selectors.selectState(state).editingUserId
    public static getEditingUser = (state: AppState) => {
        const userId = Selectors.getEditingUserId(state)
        return Selectors.selectState(state).items.find(item => item.id === userId)
    }
    public static getMultiAccessSelectedClubIdsToSave = (state: AppState) => Selectors.getUserMultiAccessSelectedClubs(state)
        .filter(item => item.isMainSquad === false)
        .map(item => item.squad.id)

}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    AllUsersState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};