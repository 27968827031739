import { StateController } from "utils/action-declaration";
import { AppState } from "root.reducer";
import { PlayersFilterOptions, PlayersTabState, FriendliesFilterOptions, FriendliesTabState, PlayerInterestSearchParameters } from "api/admin/declare-interest-report/models";
import declareInterestReportService from "api/admin/declare-interest-report/declare-interest-report.service";

export enum DeclareInterestReportTabEnum {
  Players = 'players',
  Friendlies = 'friendlies',
}

class DeclareInterestReportState {
  activeTab: DeclareInterestReportTabEnum;
  playersTabState: PlayersTabState;
  friendliesTabState: FriendliesTabState;
}

const defaultPlayersFilterOptions: PlayersFilterOptions = {
  playerOrStaffName: '',
  fromClubName: '',
  toClubName: '',
  agencyOnly: false,
  clubOnly: false,
};

const defaultFriendliesFilterOptions: FriendliesFilterOptions = {
  fromClubName: '',
  toClubName: '',
};

const defaultPlayersTabState: PlayersTabState = {
  isLoading: false,
  isSortOrderAscending: false,
  sortOrder: 'date',
  currentPage: 1,
  totalReports: 0,
  recordsPerPage: 20,
  playersReports: [],
  filterOptions: defaultPlayersFilterOptions,
  previousFilterSnapshot: defaultPlayersFilterOptions,
}

const defaultFriendliesTabState: FriendliesTabState = {
  isLoading: false,
  isSortOrderAscending: false,
  sortOrder: 'date',
  currentPage: 1,
  totalReports: 0,
  recordsPerPage: 20,
  friendliesReports: [],
  filterOptions: defaultFriendliesFilterOptions,
  previousFilterSnapshot: defaultFriendliesFilterOptions,
}

const defaultState: DeclareInterestReportState = {
  activeTab: DeclareInterestReportTabEnum.Players,
  playersTabState: defaultPlayersTabState,
  friendliesTabState: defaultFriendliesTabState
}

const stateController = new StateController<DeclareInterestReportState>(
  'ADMIN_V2/DECLARE_INTEREST_REPORT',
  defaultState
);

class Actions {
  public static dispose() {
    return (dispatch) => {
      dispatch(stateController.setState(defaultState))
    }
  }

  public static loadInitialData = () => {
    return async (dispatch) => {
      try {
        await dispatch(Actions.getPlayerInterestReport());
      } catch (err) {
        console.error(err);
      }
    };
  };

  public static getPlayerInterestReport() {
    const wasFiltersChanged = (filterOptions: PlayersFilterOptions, previousFilterSnapshot: PlayersFilterOptions) => {
      return JSON.stringify(filterOptions) !== JSON.stringify(previousFilterSnapshot);
    }

    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState(
          prevState => ({
            ...prevState,
            playersTabState: {
              ...prevState.playersTabState,
              isLoading: true,
            }
        })));
        const {
          isSortOrderAscending,
          recordsPerPage,
          sortOrder,
          currentPage,
          filterOptions,
          previousFilterSnapshot
        } = Selectors.selectPlayersState(getState());

        const shouldDisposePagination = wasFiltersChanged(filterOptions, previousFilterSnapshot);

        dispatch(stateController.setState(prevState => ({
          ...prevState,
          playersTabState: {
            ...prevState.playersTabState,
            previousFilterSnapshot: {
              ...prevState.playersTabState.filterOptions,
            },
            currentPage: shouldDisposePagination ? 1 : currentPage
          }
        })));

        const searchParameters : PlayerInterestSearchParameters = {
          sortOrder,
          recordsPerPage,
          isSortOrderAscending,
          agencyOnly: filterOptions.agencyOnly,
          clubOnly: filterOptions.clubOnly,
          page: shouldDisposePagination ? 1 : currentPage,
          playerOrStaffName: filterOptions.playerOrStaffName ? filterOptions.playerOrStaffName : undefined,
          fromClubName: filterOptions.fromClubName ? filterOptions.fromClubName : undefined,
          toClubName: filterOptions.toClubName ? filterOptions.toClubName : undefined,
        }

        const data = await declareInterestReportService.getPlayerInterestReport(searchParameters);
        const playersReports = data.output.map((report) => ({
          ...report,
          key: `${report.id}_${report.dateCreated}_${report.playerOrStaffName}`
        }));

        dispatch(stateController.setState(prevState => ({
          ...prevState,
          playersTabState: {
            ...prevState.playersTabState,
            playersReports,
            totalReports: data.rowCount,
          }
        })));
      } catch (err) {
        console.error(err);
      } finally {
        dispatch(stateController.setState(
          prevState => ({
            ...prevState,
            playersTabState: {
              ...prevState.playersTabState,
              isLoading: false
            }
        })));
      }
    }
  }

  public static getFriendlyInterestReport() {
    const wasFiltersChanged = (filterOptions: FriendliesFilterOptions, previousFilterSnapshot: FriendliesFilterOptions) => {
      return JSON.stringify(filterOptions) !== JSON.stringify(previousFilterSnapshot);
    }

    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState(
          prevState => ({
            ...prevState,
            friendliesTabState: {
              ...prevState.friendliesTabState,
              isLoading: true
            }
        })));
        const {
          isSortOrderAscending,
          recordsPerPage,
          sortOrder,
          currentPage,
          filterOptions,
          previousFilterSnapshot
        } = Selectors.selectFriendliesState(getState());

        const shouldDisposePagination = wasFiltersChanged(filterOptions, previousFilterSnapshot);

        dispatch(stateController.setState(prevState => ({
          ...prevState,
          friendliesTabState: {
            ...prevState.friendliesTabState,
            previousFilterSnapshot: {
              ...prevState.friendliesTabState.filterOptions,
            },
            currentPage: shouldDisposePagination ? 1 : currentPage,
          }

        })));

        const searchParameters = {
          sortOrder,
          recordsPerPage,
          isSortOrderAscending,
          page: shouldDisposePagination ? 1 : currentPage,
          fromClubName: filterOptions.fromClubName ? filterOptions.fromClubName : undefined,
          toClubName: filterOptions.toClubName ? filterOptions.toClubName : undefined,
        }

        const data = await declareInterestReportService.getFriendlyInterestReport(searchParameters);
        const friendliesReports = data.output.map((report) => ({
          ...report,
          key: `${report.uid}`
        }));

        dispatch(stateController.setState(prevState => ({
          ...prevState,
          friendliesTabState: {
            ...prevState.friendliesTabState,
            friendliesReports,
            totalReports: data.rowCount,
          }
        })));
      } catch (err) {
        console.error(err);
      } finally {
        dispatch(stateController.setState(
          prevState => ({
          ...prevState,
          friendliesTabState: {
            ...prevState.friendliesTabState,
            isLoading: false
          }
        })));
      }
    }
  }

  public static onChangeTab(tabKey: DeclareInterestReportTabEnum) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        activeTab: tabKey
      })))
    }
  }

  public static onPlayersPaginationChange(pageNumber: number, pageSize: number) {
    return (dispatch, getState: () => AppState) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        playersTabState: {
          ...prevState.playersTabState,
          currentPage: pageNumber,
          recordsPerPage: pageSize
        }
      })));

      dispatch(Actions.getPlayerInterestReport());
    }
  }

  public static onFriendliesPaginationChange(pageNumber: number, pageSize: number) {
    return (dispatch, getState: () => AppState) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        friendliesTabState: {
          ...prevState.friendliesTabState,
          currentPage: pageNumber,
          recordsPerPage: pageSize
        }
      })));

      dispatch(Actions.getFriendlyInterestReport());
    }
  }

  public static onChangePlayersTabPlayerOrStaffName(value: string) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        playersTabState: {
          ...prevState.playersTabState,
          filterOptions: {
            ...prevState.playersTabState.filterOptions,
            playerOrStaffName: value
          }
        }
      })));
    }
  }

  public static onChangePlayersTabFromClubName(value: string) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        playersTabState: {
          ...prevState.playersTabState,
          filterOptions: {
            ...prevState.playersTabState.filterOptions,
            fromClubName: value
          }
        }
      })));
    }
  }

  public static onChangePlayersTabToClubName(value: string) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        playersTabState: {
          ...prevState.playersTabState,
          filterOptions: {
            ...prevState.playersTabState.filterOptions,
            toClubName: value
          }
        }
      })));
    }
  }

  public static onToggleAgencyOnly(checked) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        playersTabState: {
          ...prevState.playersTabState,
          filterOptions: {
            ...prevState.playersTabState.filterOptions,
            agencyOnly: checked
          }
        }
      })));
      dispatch(Actions.getPlayerInterestReport());
    };
  };

  public static onToggleClubOnly(checked) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        playersTabState: {
          ...prevState.playersTabState,
          filterOptions: {
            ...prevState.playersTabState.filterOptions,
            clubOnly: checked
          }
        }
      })));
      dispatch(Actions.getPlayerInterestReport());
    };
  };

  public static onChangeFriendliesTabFromClubName(value: string) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        friendliesTabState: {
          ...prevState.friendliesTabState,
          filterOptions: {
            ...prevState.friendliesTabState.filterOptions,
            fromClubName: value
          }
        }
      })));
    }
  }

  public static onChangeFriendliesTabToClubName(value: string) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        friendliesTabState: {
          ...prevState.friendliesTabState,
          filterOptions: {
            ...prevState.friendliesTabState.filterOptions,
            toClubName: value
          }
        }
      })));
    }
  }

  public static applySearch() {
    return (dispatch, getState: () => AppState) => {
      const activeTab = Selectors.activeTab(getState());
      if (activeTab === DeclareInterestReportTabEnum.Players) {
        dispatch(Actions.getPlayerInterestReport());
      }
      if (activeTab === DeclareInterestReportTabEnum.Friendlies) {
        dispatch(Actions.getFriendlyInterestReport());
      }
    }
  }

  public static disposeFilters(withApply?: boolean) {
    return (dispatch, getState: () => AppState) => {
      const activeTab = Selectors.activeTab(getState());
      if (activeTab === DeclareInterestReportTabEnum.Players) {
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          playersTabState:{
            ...prevState.playersTabState,
            filterOptions: defaultPlayersFilterOptions
          }
        })));
      }

      if (activeTab === DeclareInterestReportTabEnum.Friendlies) {
        dispatch(stateController.setState(prevState => ({
          ...prevState,
          friendliesTabState: {
            ...prevState.friendliesTabState,
            filterOptions: defaultFriendliesFilterOptions
          }
        })));
      }

      if (withApply) {
        dispatch(Actions.applySearch());
      }
    }
  }

}

class Selectors {
  public static selectState = (state: AppState) => state.admin.declareInterestReport;
  public static selectPlayersState = (state: AppState) => state.admin.declareInterestReport.playersTabState;
  public static selectFriendliesState = (state: AppState) => state.admin.declareInterestReport.friendliesTabState;
  public static activeTab = (state: AppState) => Selectors.selectState(state).activeTab;
  public static isPlayersLoading = (state: AppState): boolean => Selectors.selectPlayersState(state).isLoading;
  public static isFriendliesLoading = (state: AppState): boolean => Selectors.selectFriendliesState(state).isLoading;
  public static getPlayersReports = (state: AppState) => Selectors.selectState(state).playersTabState.playersReports;
  public static getPlayersTotalReports = (state: AppState) => Selectors.selectState(state).playersTabState.totalReports;
  public static getPlayersRecordsPerPage = (state: AppState) => Selectors.selectState(state).playersTabState.recordsPerPage;
  public static getPlayersCurrentPage = (state: AppState) => Selectors.selectState(state).playersTabState.currentPage;
  public static getPlayersFilterOptions = (state: AppState) => Selectors.selectState(state).playersTabState.filterOptions;
  public static getFriendliesReports = (state: AppState) => Selectors.selectState(state).friendliesTabState.friendliesReports;
  public static getFriendliesTotalReports = (state: AppState) => Selectors.selectState(state).friendliesTabState.totalReports;
  public static getFriendliesRecordsPerPage = (state: AppState) => Selectors.selectState(state).friendliesTabState.recordsPerPage;
  public static getFriendliesCurrentPage = (state: AppState) => Selectors.selectState(state).friendliesTabState.currentPage;
  public static getFriendliesFilterOptions = (state: AppState) => Selectors.selectState(state).friendliesTabState.filterOptions;

  public static isPlayersFiltersSetToDefault(state: AppState) {
    return JSON.stringify(defaultState.playersTabState.filterOptions) === JSON.stringify(Selectors.getPlayersFilterOptions(state))
  }
  public static isFriendliesFiltersSetToDefault(state: AppState) {
    return JSON.stringify(defaultState.friendliesTabState.filterOptions) === JSON.stringify(Selectors.getFriendliesFilterOptions(state))
  }
}

const reducer = stateController.getReducer();

export {
  reducer as Reducer,
  DeclareInterestReportState as State,
  Actions as Actions,
  Selectors as Selectors,
  stateController as Controller
};
