import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAuth } from 'store/auth/authReducer';
import historyAccessor from 'history-accessor'

import { Selectors as MiscEventSelectors } from 'app/events/redux/misc.controller'
import Spinner from 'components/Spinner/Spinner';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import {
    Switch,
    Route,
    Redirect
} from 'react-router-dom';

import { userPaths } from 'routes/paths'
import * as MeetingPreparationController from 'app/events/redux/virtual-summit/meeting-preparation.controller'
import BannerRegistered from 'app/events/components/authorized-event-page/common/banners/banner-registered'
import Tabs from 'app/events/components/authorized-event-page/common/tabs-for-registered-state/tabs'
import TabAttendance from './tab-attendance/tab-attendance.entry'
import TabMeetings from 'app/events/components/authorized-event-page/common/tab-meetings/tab-meetings.entry'
// import TabInfo from './tab-info/tab-info.entry'
import { PageType } from 'constants/enums';
import BlogIframeModalCTA from 'app/events/components/authorized-event-page/common/blog-iframe-modal/cta'

class Events extends Component {
    state = {
        activeIndex: 0,
        attendees: null,
        modalVideo: false,
        modalMap: false,
    };

    toggle = modal => {
        this.setState({
            [modal]: !this.state[modal],
        });
    };

    componentDidMount() {

        const { auth } = this.props;

        let search = new URLSearchParams(historyAccessor.location.search)

        let from = search.get("from");
        let type = search.get("type");
        let club = search.get("club");
        let redirect = search.get("redirect");

        if (from === "email" && type === "preparation" && club !== null) {
            this.props.userActivityInsert({
                Message: `Opened Link from ${club}`,
                PageName: 'Event [Agenda Email] ',
                PageType: PageType.Events,
                EventId: auth.eventId
            });
        }
        if (from === "email" && type === "agenda") {
            this.props.userActivityInsert({
                Message: 'Clicked Prepare Agenda Now',
                PageName: 'Event [Agenda Email] ',
                PageType: PageType.Events,
                EventId: auth.eventId
            });
        }

        this.props.loadMeetingPreparationData(historyAccessor.push, redirect === 'true')

    }

    render() {
        const { isLoading, upcommingEventData, auth } = this.props;

        const {
            isTransferIn,
            isTransferOut,
            isGeneralNetworking,
            transferInStepCompleted,
            transferOutStepCompleted,
            generalNetworkingStepCompleted,
            isRewardComplete,
            registerNowVisible,
            user,
            isUserRegistered,
            extraAttendees,
            // isShowPracticalInformation
        } = upcommingEventData

        const isObjectivesDefined = isTransferIn || isTransferOut || isGeneralNetworking
        const isUserCompletedAnyStep = transferInStepCompleted || transferOutStepCompleted || generalNetworkingStepCompleted || isRewardComplete
        //for successfully npm build 
        if (isUserCompletedAnyStep) { }

        const registeredAttendees = [];
        if (extraAttendees) {
            if (isUserRegistered) {
                registeredAttendees.push(user.name)
            }
            extraAttendees.filter(i => i.isRegistered && i.name !== null).forEach(i => {
                registeredAttendees.push(i.name)
            })
        }

        return (
            <div className="authorized-event-page">
                {isLoading && <Spinner />}

                { <BannerRegistered
                    isLoading={isLoading}
                    isCompleted={isObjectivesDefined}
                    onCompleteAttendanceClick={() => {
                        this.props.userActivityInsert({ 
                            Message: 'Complete Details', 
                            PageName: 'Event',
                            PageType: PageType.Events,
                            EventId: auth.eventId
                        })
                        historyAccessor.push(userPaths.eventCompleteDetails + "?first=true");
                    }}
                    eventAttendeess={registeredAttendees}
                    onAddExtraAttendeeClick={() => {
                        historyAccessor.push(userPaths.eventRegistration + "?fromEvent=true")
                    }}
                />}
                {!isLoading && (
                    <div className="authorized-event-page__navigation-container">
                        <Tabs headerHeight={this.props.headerHeight} isRegistered={!registerNowVisible} isShowPracticalInfo={false} />
                        <BlogIframeModalCTA />
                    </div>
                )}
                {/* isShowPracticalInfo={isShowPracticalInfo} */}
                
                {!isLoading &&
                    <>
                        <Switch>
                            <Route exact path="/events/attendance">
                                <div className="events-page-wrapper">
                                    <TabAttendance />
                                </div>
                            </Route>
                            {!registerNowVisible && <Route exact path="/events/agenda-builder">
                                <div className="events-page-wrapper">
                                    <TabMeetings />
                                </div>
                            </Route>}
                            {/* {!registerNowVisible && isShowPracticalInformation && <Route exact path="/events/info">
                                <div className="events-page-wrapper">
                                    <TabInfo />
                                </div>
                            </Route>} */}
                            {<Redirect to="/events/attendance" />}
                        </Switch>
                    </>
                } 

                {/* {!isLoading &&
                    <div className="events-page-wrapper">
                        <TabAttendance />
                    </div>
                } */}

            </div>
        );
    }
}

const mapStateToProps = state => ({
    isLoading: MiscEventSelectors.selectIsLoading(state),
    upcommingEventData: state.events.misc.upcommingSummitData,
    isRegistrationShown: state.events.misc.isRegistrationShown,
    auth: getAuth(state),
    headerHeight: state.commonStyles.header.headerHeight
});

const mapDispatchToProps = {
    userActivityInsert,
    loadMeetingPreparationData: MeetingPreparationController.Actions.loadMeetingPreparationData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Events);
