import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import VerifyService from "api/agency/agent/verify-players/verify-players.service";
import { PendingVerification, PlayerVerification } from "api/agency/agent/verify-players/models";
import OnboardingService from 'api/agency/agent/onboarding/onboarding.service';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import historyAccessor from 'history-accessor';
import { PageType } from 'constants/enums';
import * as RestrictedCreditsModalController from 'pages/agency/authorized-agent/home-page/tabs/activity/restricted-player-credits-modal/restricted-player-credits.controller';
import { RestrictedPlayerCreditsModalType } from 'pages/agency/authorized-agent/home-page/tabs/activity/restricted-player-credits-modal/restricted-player-credits.controller';
import { Actions as Lta, LtaUpgradeModalType } from 'pages/agency/common/low-tier-agency-upgrade-modal/lta-upgrade-modal.controller'
import { Actions as SharedModalActions, ModalState } from 'components/modals/shared-modal-controller';
import { isTransparencyPermitted } from 'store/auth/authReducer';
import {agencyPaths} from "routes/paths";
import {Actions as VerificationController} from 'pages/agency/authorized-agent/player-verification/verification.controller'

export class PlayerPhone { playerId: number; phone: string; phoneCodeAreaId: number }
class State {
    isLoading: boolean;
    serverData: Array<PlayerVerification>;
    uiData: Array<PlayerVerification>;
    toSend: Array<number>;
    done: boolean;
    successPremiumCount: number;
    successRegularCount: number;
    failedPremiumCount: number;
    failedRegularCount: number;
    amountOfAvailablePlayers: number;
    amountOfAvailableRegularPlayers: number;
    isUnlimitedRegularPlayerSlots: boolean;
    hasSubscription: boolean;
    verifiedPriorityPlayersQty: number;
    insightsSharedWithVerifiedPlayersQty: number;
    isRedirectToPitchPage: boolean;
}

const defaultState: State = {
    isLoading: true,
    serverData: null,
    uiData: null,
    toSend: [],
    done: false,
    successPremiumCount: 0,
    successRegularCount: 0,
    failedPremiumCount: 0,
    failedRegularCount: 0,
    amountOfAvailablePlayers: 0,
    amountOfAvailableRegularPlayers: 0,
    isUnlimitedRegularPlayerSlots: false,
    hasSubscription: false,
    verifiedPriorityPlayersQty: 0,
    insightsSharedWithVerifiedPlayersQty: 0,
    isRedirectToPitchPage: false,
}

const stateController = new StateController<State>(
    "AGENCY/VERIFY-PLAYERS",
    defaultState
)

class Actions {
    public static dispose() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(defaultState));
        }
    }

    public static init(playerId?: number) {
        return async (dispatch, getState: () => AppState) => {
            await Promise.all(
                [
                    dispatch(Actions.loadPlayers(playerId)),
                    dispatch(Actions.loadAmountOfAvailablePlayers()),
                    dispatch(Actions.loadAmountOfAvailableRegularPlayers())
                ]
            );
        }
    }

    public static loadPlayers(playerId?: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({ ...prevState, isLoading: true })));

            const res = await VerifyService.getPlayers();
            const { verifiedPriorityPlayersQty, insightsSharedWithVerifiedPlayersQty } = res;
            const sent = res.players.filter(x => x.isImmutable);
            const specific = res.players.filter(x => x.playerId == playerId);
            for (const obj of specific) {
                obj.isRegular = true;
                obj.isInsightsShared = false;
            }
            const rest = res.players.filter(x => !x.isImmutable && x.playerId != playerId);
            for (const obj of rest) {
                obj.isRegular = true;
                obj.isInsightsShared = false;
            }
            rest.sort(function (a, b) {
                const isRegularComparison = Number(a.isRegular) - Number(b.isRegular);
                if (isRegularComparison)
                    return isRegularComparison;

                if (a.playerName < b.playerName) { return -1; }
                if (a.playerName > b.playerName) { return 1; }
                return 0;
            });
            let result = [];
            if (specific.length > 0)
                result = [...sent, ...specific, ...rest];
            else
                result = [...sent, ...rest];

            const toSend = [];
            res.players.map(x => {
                if (x.phoneCodeAreaId != null && x.phone != null && !x.isImmutable) {
                    toSend.push(x.playerId);
                }
            })
            dispatch(stateController.setState(prevState =>
            ({
                ...prevState,
                isLoading: false,
                serverData: res.players,
                uiData: result,
                toSend: toSend,
                verifiedPriorityPlayersQty,
                insightsSharedWithVerifiedPlayersQty
            })));

        }
    }
    public static send() {
        return async (dispatch, getState: () => AppState) => {
            const state = getState();
            const subState = state.agency.agent.verifyPlayers;

            const { hasSubscription } = subState
            if (!hasSubscription) {
                dispatch(RestrictedCreditsModalController.Actions.openRestrictedPremiumCreditsModal(RestrictedPlayerCreditsModalType.AddPlayers));
                return;
            }

            dispatch(stateController.setState({ isLoading: true }));
            const allPlayers: PendingVerification[] = [];
            const uaPlayers: PlayerVerification[] = [];
            subState.toSend.map(id => {
                const find = subState.uiData.find(x => x.playerId == id);
                allPlayers.push({ playerId: id, phone: find.phone, phoneCodeAreaId: find.phoneCodeAreaId, isRegular: find.isRegular, isInsightsShared: find.isInsightsShared })
                uaPlayers.push(find)
            });
            const data = await VerifyService.sendToVerify({ players: allPlayers });

            const uaRegularPlayers = uaPlayers.map(x => x.playerName).join('; ');
            const uaRegularPlayersWithoutPriorityPlayers = uaPlayers.filter(x => x.isRegular).map(x => x.playerName)
            const uaPriorityPlayers = uaPlayers.filter(x => !x.isRegular).map(x => x.playerName);
            const uaSharedPlayers = uaPlayers.filter(x => x.isInsightsShared).map(x => x.playerName);
            const uaSharedPlayersCount = uaSharedPlayers.length;

            // for (let index = 0; index < uaPlayers.length; index++) {
            //     dispatch(userActivityInsert({
            //         Message: `Sent Request`,
            //         PageName: 'Agency Home [Player Verification]',
            //         PlayerId: uaPlayers[index].playerId,
            //         ClubId: uaPlayers[index].clubId,
            //         PageType: PageType.AgencyHome,
            //     }));
            // }

            if (uaSharedPlayers.length) {
                uaSharedPlayers.forEach(item => dispatch(userActivityInsert({
                    PageName: 'Agency Home [Player Verification]',
                    Message: `Shared Transparency (${item})`,
                    PageType: PageType.AgencyHome,
                })))
            }

            if (uaPriorityPlayers.length) {
                uaPriorityPlayers.forEach(item => dispatch(userActivityInsert({
                    PageName: 'Agency Home [Player Verification]',
                    Message: `Priority Request Sent (${item})`,
                    PageType: PageType.AgencyHome,
                })))
            }

            if (uaRegularPlayersWithoutPriorityPlayers.length) {
                uaRegularPlayersWithoutPriorityPlayers.forEach(item => dispatch(userActivityInsert({
                    PageName: 'Agency Home [Player Verification]',
                    Message: `Regular Request Sent (${item})`,
                    PageType: PageType.AgencyHome,
                })))
            }

            dispatch(userActivityInsert({
                Message: `${uaPlayers.length} Requests Sent; Regular (${uaRegularPlayers}), Priority (${uaPriorityPlayers.join('; ')})${uaSharedPlayersCount > 0 ? `, Shared (${uaSharedPlayers.join('; ')})` : ''}`,
                PageName: 'Agency Home [Player Verification]',
                PageType: PageType.AgencyHome,
            }))


            const successPremiumCount = data.filter(x => x.isSuccess && !x.isRegular).length;
            const failedPremiumCount = data.filter(x => !x.isSuccess && !x.isRegular).length;
            const successRegularCount = data.filter(x => x.isSuccess && x.isRegular).length;
            const failedRegularCount = data.filter(x => !x.isSuccess && x.isRegular).length;

            dispatch(stateController.setState({ isLoading: false, done: true, successPremiumCount, failedPremiumCount, successRegularCount, failedRegularCount }));
        }
    }

    public static toggleIsPremium(id: number) {
        return async (dispatch, getState: () => AppState) => {
            const state = getState();
            const subState = state.agency.agent.verifyPlayers;
            const selectedRegular = (subState.uiData || []).reduce((acc, obj) => {
                if (subState.toSend.some(x => x == obj.playerId) && obj.isRegular)
                    return acc + 1;
                else
                    return acc;
            }, 0);
            const selectedPremium = (subState.uiData || []).reduce((acc, obj) => {
                if (subState.toSend.some(x => x == obj.playerId) && !obj.isRegular)
                    return acc + 1;
                else
                    return acc;
            }, 0);


            const uiDataCopy = [...subState.uiData];
            let toSendCopy = [...subState.toSend];

            const player = uiDataCopy.find(x => x.playerId == id);

            const totalPremiumAvailable = subState.amountOfAvailablePlayers - (selectedPremium);
            const totalRegularAvailable = subState.amountOfAvailableRegularPlayers - (selectedRegular + selectedPremium);
            if (totalPremiumAvailable <= 0 && !subState.toSend.some(x => x == id)) {
                dispatch(RestrictedCreditsModalController.Actions.openRestrictedPremiumCreditsModal(RestrictedPlayerCreditsModalType.AddPlayers));
                return;
            }
            if (totalPremiumAvailable <= 0 && subState.toSend.some(x => x == id) && player.isRegular) {
                dispatch(RestrictedCreditsModalController.Actions.openRestrictedPremiumCreditsModal(RestrictedPlayerCreditsModalType.AddPlayers));
                return;
            }
            if (totalRegularAvailable <= 0 && !subState.isUnlimitedRegularPlayerSlots && !subState.toSend.some(x => x == id)) {
                dispatch(RestrictedCreditsModalController.Actions.openRestrictedRegularCreditsModal(RestrictedPlayerCreditsModalType.AddPlayers));
                return;
            }

            dispatch(userActivityInsert({
                Message: !player.isRegular ? `Unmarked Player as Priority` : 'Marked Player as Priority',
                PageName: 'Agency Home [Player Verification]',
                PlayerId: player.playerId,
                ClubId: player.clubId,
                PageType: PageType.AgencyHome,
            }));

            if (player.isRegular && !subState.toSend.some(x => x == id)) {
                dispatch(userActivityInsert({
                    Message: `Selected Player`,
                    PageName: 'Agency Home [Player Verification]',
                    PlayerId: player.playerId,
                    ClubId: player.clubId,
                    PageType: PageType.AgencyHome,
                }));
            }

            player.isRegular = !player.isRegular;

            if (!player.isRegular) {
                const find = subState.toSend.find(x => x == id);
                if (!find) {
                    toSendCopy = [...toSendCopy, id];
                }
            }
            if (isTransparencyPermitted(state))
                dispatch(Actions.toggleIsInsightsShared(id));

            dispatch(stateController.setState({ uiData: uiDataCopy, toSend: toSendCopy }))
        }
    }

    public static toggleIsInsightsShared(id: number) {
        return async (dispatch, getState: () => AppState) => {
            const state = getState();
            const subState = state.agency.agent.verifyPlayers;
            const uiDataCopy = [...subState.uiData];
            const player = uiDataCopy.find(x => x.playerId == id);
            const currentInsightsSharedStatus = player.isInsightsShared;

            player.isInsightsShared = !player.isRegular && !player.isInsightsShared;

            if (currentInsightsSharedStatus !== player.isInsightsShared) {
                dispatch(userActivityInsert({
                    Message: player.isInsightsShared ? 'Shared Insights with Player' : 'Unshared Insights with Player',
                    PageName: 'Agency Home [Player Verification]',
                    PlayerId: player.playerId,
                    ClubId: player.clubId,
                    PageType: PageType.AgencyHome,
                }));
            }

            dispatch(stateController.setState({ uiData: uiDataCopy }))
        }
    }

    public static togglePlayerSelected(id: number) {
        return async (dispatch, getState: () => AppState) => {
            const state = getState();
            const isLta = state.auth.agencyPermission.isLowerTier;
            const subState = state.agency.agent.verifyPlayers;
            const find = subState.toSend.find(x => x == id);
            const uiDataCopy = [...subState.uiData];
            const player = uiDataCopy.find(x => x.playerId == id);
            const selectedRegular = (subState.uiData || []).reduce((acc, obj) => {
                if (subState.toSend.some(x => x == obj.playerId) && obj.isRegular)
                    return acc + 1;
                else
                    return acc;
            }, 0);
            const selectedPremium = (subState.uiData || []).reduce((acc, obj) => {
                if (subState.toSend.some(x => x == obj.playerId) && !obj.isRegular)
                    return acc + 1;
                else
                    return acc;
            }, 0);
            const totalRegularAvailable = subState.amountOfAvailableRegularPlayers - (selectedRegular + selectedPremium)

            if (find) {
                player.isRegular = true;
                player.isInsightsShared = false;
                dispatch(stateController.setState({ toSend: subState.toSend.filter(x => x != id), uiData: uiDataCopy }))
                dispatch(userActivityInsert({
                    Message: `Unselected Player`,
                    PageName: 'Agency Home [Player Verification]',
                    PlayerId: player.playerId,
                    ClubId: player.clubId,
                    PageType: PageType.AgencyHome,
                }));
            }
            else {
                if (totalRegularAvailable <= 0 && !subState.isUnlimitedRegularPlayerSlots) {
                    if (isLta) {
                        dispatch(userActivityInsert({
                            Message: `Opened Upgrade Request`,
                            PageName: 'Agency Home [Player Verification]',
                        }));
                        dispatch(Lta.open(LtaUpgradeModalType.ReachLimitUpgrade, 'Agency Home [Player Verification]'));
                        return;
                    }
                    else {
                        dispatch(RestrictedCreditsModalController.Actions.openRestrictedRegularCreditsModal(RestrictedPlayerCreditsModalType.AddPlayers));
                        return;
                    }
                }
                dispatch(stateController.setState({ toSend: [...subState.toSend, id] }))
                dispatch(userActivityInsert({
                    Message: `Selected Player`,
                    PageName: 'Agency Home [Player Verification]',
                    PlayerId: player.playerId,
                    ClubId: player.clubId,
                    PageType: PageType.AgencyHome,
                }));
            }
        }
    }

    public static setPlayerPhone(id: number, phone: string) {
        return async (dispatch, getState: () => AppState) => {
            const state = getState();
            const subState = state.agency.agent.verifyPlayers;

            const stateNew = [...subState.uiData];

            const find = stateNew.find(x => x.playerId == id);
            find.phone = phone;

            // const toSendNew = [...subState.toSend]
            // const toSendFind = subState.toSend.find(x => x == id);
            // if (!toSendFind && find.phone != null && find.phoneCodeAreaId != null && find.phone.length > 5) {
            //     toSendNew.push(id);
            // } else if(find.phone.length <= 5) {
            //     toSendNew = toSendNew.filter(x => x != id)
            // }

            dispatch(stateController.setState({
                uiData: stateNew,
                //toSend: toSendNew 
            }));
        }
    }

    public static setPlayerPhoneCodeAreaId(id: number, areaId: number) {
        return async (dispatch, getState: () => AppState) => {
            const state = getState();
            const subState = state.agency.agent.verifyPlayers;

            const stateNew = [...subState.uiData];

            const find = stateNew.find(x => x.playerId == id);
            find.phoneCodeAreaId = areaId;

            // const toSendNew = [...subState.toSend]
            // const toSendFind = subState.toSend.find(x => x == id);
            // if (!toSendFind && find.phone != null && find.phoneCodeAreaId != null && find.phone.length > 5) {
            //     toSendNew.push(id);
            // } else if(find.phoneCodeAreaId == null) {
            //     toSendNew = toSendNew.filter(x => x != id)
            // }

            dispatch(stateController.setState({
                uiData: stateNew,
                //toSend: toSendNew 
            }));
        }
    }

    public static loadAmountOfAvailablePlayers = () => {
        return async (dispatch, getState: () => AppState) => {
            const data = await OnboardingService.getSubscriptionStats();
            let amountOfAvailablePlayers = 0;
            const hasSubscription = !!data;
            if (hasSubscription) {
                amountOfAvailablePlayers = data.totalSlots - data.usedSlots;
            }
            dispatch(stateController.setState(({ amountOfAvailablePlayers, hasSubscription })));
        }
    }

    public static loadAmountOfAvailableRegularPlayers = () => {
        return async (dispatch, getState: () => AppState) => {
            const data = await OnboardingService.getRegularPlayerSlotStats();
            const { availableRegularPlayerSlots, isUnlimitedRegularPlayerSlots } = data;
            dispatch(stateController.setState(({ 
                amountOfAvailableRegularPlayers: availableRegularPlayerSlots,
                isUnlimitedRegularPlayerSlots
             })));
        }
    }

    public static closed() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(userActivityInsert({
                Message: `Closed`,
                PageName: `Agency Home [Player Verification]`,
                PageType: PageType.AgencyHome,
            }));
           
            if(getState().agency.agent.verifyPlayers.isRedirectToPitchPage) {
                historyAccessor.push(agencyPaths.pitchPage);
                dispatch(VerificationController.redirectToPitchPage());
            } else {
                historyAccessor.push('/')
            }
        }
    }
    
    public static redirectToPitchPage() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                isRedirectToPitchPage: !prevState.isRedirectToPitchPage,
            })));
        }
    }
    
    public static done() {
        return async (dispatch, getState: () => AppState) => {
            const subState = getState().agency.agent.verifyPlayers;
            const regularQty = (subState.successRegularCount || 0) + (subState.failedRegularCount || 0);
            const premiumQty = (subState.successPremiumCount || 0) + (subState.failedPremiumCount || 0);
            dispatch(userActivityInsert({
                Message: `Done. ${regularQty + premiumQty} regular and ${premiumQty} priority requests sent`,
                PageName: `Agency Home [Player Verification]`,
                PageType: PageType.AgencyHome,
            }));
            if(getState().agency.agent.verifyPlayers.isRedirectToPitchPage) {
                historyAccessor.push(agencyPaths.pitchPage);
                dispatch(Actions.redirectToPitchPage());
            } else {
                    historyAccessor.push('/')
            }
            
        }
    }

    public static openSharedInsightsInfoModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(SharedModalActions.onOpen(ModalState.PlayerVerification));
            dispatch(userActivityInsert({
                PageName: 'Agency Home [Player Verification]',
                Message: 'Opened Info about Sharing',
                PageType: PageType.AgencyHome
            }));
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    stateController as Controller
};