import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import {StateController} from 'utils/action-declaration'
import {AppState} from "root.reducer";
import historyAccessor from "history-accessor";
import {coachPaths} from "routes/paths";
import { getAuth } from "store/auth/authReducer";
import { AgencyModel } from "api/agency/coach/onboarding/models";
import { getCoachUserPermissions } from "store/userPreference/userPreference.reducer";
import { CoachService } from "api/coach/coach.service";
import { ConfirmAgentRequest } from "api/coach/models";
import { COACH_IS_AGENCY_CONFIRMATION_NEEDED_FALSE } from "store/actionTypes";
import { openPublicProfile } from 'utils/open-link';

export enum ConfirmSteps {
    ConfirmAgency,
    ConfirmedScreen,
    RepresentationScreen,
}

class State {
    step: ConfirmSteps;
    isAgencyConfirmed: boolean;
    isConfirmProcessing: boolean;
    isDeclineProcessing: boolean;
    isTransparent: boolean;
    isLoading: boolean;
    agencyInfo: AgencyModel;
    invitationUid: string;
}

const defaultState = {
    step: ConfirmSteps.ConfirmAgency,
    isAgencyConfirmed: false,
    isConfirmProcessing: false,
    isDeclineProcessing: false,
    isTransparent: false,
    isLoading: false,
    agencyInfo: null,
    invitationUid: null,
}

const stateController = new StateController<State>(
    'COACH/AGENCY_CONFIRM',
    defaultState
)

class Actions {
    public static init() {
        return async (dispatch, getState: () => AppState) => {
            const state = getState();
            const { isAgencyConfirmationNeeded } = getCoachUserPermissions(state)

            if(!isAgencyConfirmationNeeded) {
                historyAccessor.push(coachPaths.myProfile);
                return;
            }

            dispatch(stateController.setState({ isLoading: true }));
            try {
                const { isValid, agency, invitationUid } = await CoachService.getConfirmAgencyAccess();
                if (!isValid) {
                    historyAccessor.push(coachPaths.myProfile);
                }

                dispatch(stateController.setState({ 
                    invitationUid: invitationUid,
                    agencyInfo: agency
                }));
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        }
    }

    public static setStep(step: ConfirmSteps) {
        return (dispatch) => {
            dispatch(stateController.setState({ step: step }));
        }
    }

    public static initConfirmAgencyPage() {
        return (dispatch, getState: () => AppState) => {
            const { coachId } = getAuth(getState());

            dispatch(userActivityInsert({
                PageName: 'Confirm Agency',
                Message: 'Opened Agency Confirmation Page',
                CoachId: coachId
            }));
        }
    }

    public static onOpenPublicProfile() {
        return (dispatch, getState: () => AppState) => {
            const { coachId } = getAuth(getState());
            const agencyInfo = Selectors.getRoot(getState()).agencyInfo;

            dispatch(userActivityInsert({
                PageName: 'Confirm Agency',
                Message: 'Clicked Agency Name',
                CoachId: coachId,
                AgencyId: agencyInfo?.id
            }));

            openPublicProfile(agencyInfo?.name)
        }
    }

    public static confirmAgency(confirmStatus: boolean) {
        return async (dispatch, getState: () => AppState) => {
            const state = getState();
            const { invitationUid } = Selectors.getRoot(state);
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                isConfirmProcessing: confirmStatus,
                isDeclineProcessing: !confirmStatus,
            })));
            const confirmRequest : ConfirmAgentRequest = {
                invitationUid: invitationUid,
                isAgentConfirmed: confirmStatus,
            }
            await CoachService.confirmAgent(confirmRequest);
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                isAgencyConfirmed: confirmStatus,
                isConfirmProcessing: false,
                isDeclineProcessing: false
            })));

            dispatch({ type: COACH_IS_AGENCY_CONFIRMATION_NEEDED_FALSE });

            dispatch(Actions.setStep(ConfirmSteps.ConfirmedScreen));
        }
    }

    public static redirectAfterConfirmAgency() {
        return (dispatch) => {
            dispatch(Actions.setStep(ConfirmSteps.ConfirmAgency));
            historyAccessor.push(coachPaths.myProfile);
        }
    }
    
    public static sendUserActivity(message: string, agencyId: number) {
        return (dispatch, getState: () => AppState) => {
            const { coachId } = getAuth(getState());

            dispatch(userActivityInsert({
                PageName: 'Agent Confirmation',
                Message: message,
                CoachId: coachId,
                AgencyId: agencyId,
            }))
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState): State => state.coach.agencyConfirm;
    public static getCurrentStep = (state: AppState) => Selectors.getRoot(state).step;
    public static isAgencyConfirmed = (state: AppState) => Selectors.getRoot(state).isAgencyConfirmed;
}

const reducer = stateController.getReducer()

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
}