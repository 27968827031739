export const getOpponentIndexPrefix = (opponentIndex: number) => {
  let opponentIndexPrefix = '';
  switch (opponentIndex) {
    case 1:
      opponentIndexPrefix = 'first';
      break;
    case 2:
      opponentIndexPrefix = 'second';
      break;
    case 3:
      opponentIndexPrefix = 'third';
      break;
    default:
      opponentIndexPrefix = '';
      break;
  }

  return opponentIndexPrefix;
}
