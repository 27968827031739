import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer'
import { getAuth } from 'store/auth/authReducer';
import { getEventInfo } from 'app/events/components/authorized-event-page/events-map/eventsMap';

export enum RegistrationState {
    Complete = 1,
    Attendees = 2,
    Tables = 3,
    Done = 4,
    DateSelector = 5,
    AttendeesDetails = 21,
    InviteClubs = 22,
    InviteAgents = 23,
    ActivateAgentFeature = 24
}

class ConfirmState {
    step: RegistrationState;
    fromEvent: boolean;
    agentFeaturePermission: boolean;
    agentFeatureActive: boolean;
}

const defaultState: ConfirmState = {
    step: RegistrationState.Complete,
    fromEvent: null,
    agentFeaturePermission: null,
    agentFeatureActive: null
}

const stateController = new StateController<ConfirmState>(
    "VIRTUAL_SUMMIT/CONFIRM",
    defaultState
);

class Actions {
    public static init = () => {
        return async (dispatch, getState: () => AppState) => {
            const appState = getState();
            const { eventId } = getAuth(appState);
            const { registration } = getEventInfo(eventId);
            const { hasDateSelectorStep } = registration;

            if(hasDateSelectorStep){
                dispatch(stateController.setState({ step: RegistrationState.DateSelector }));
            }
        }
    }

    public static setStep = (step: RegistrationState) => {
        return async (dispatch, getState: () => AppState) => {
            let appState = getState();
            dispatch(stateController.setState({ step: step }));
        }
    }

    public static setFromEvent = () => {
        return async (dispatch, getState: () => AppState) => {
            let appState = getState();
            await dispatch(stateController.setState({ fromEvent: true }));
        }
    }

    public static setAgentFeatureFromState = () => {
        return async (dispatch, getState: () => AppState) => {
            let appState = getState();
            await dispatch(stateController.setState({ 
                agentFeaturePermission: appState.auth.isAgentsVisible, 
                agentFeatureActive: appState.auth.isAgentsFreeTrialActive 
            }));
        }
    }

    public static dispose = () => {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ ...defaultState }));
        }
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    ConfirmState as State,
    Actions as Actions,
    stateController as Controller
};



