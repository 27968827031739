import React from 'react'
import styled from 'styled-components'

import Panel from 'components/Panel';
import { EventTitle } from '../past-event-title'
import { PastEventStats } from '../past-event-stats'
import { PastEventClubLogos } from '../past-event-club-logos'
import virtualDealDayImage from 'media/images/events/2023-1-august-virtual/virtual-deal-day-thumb.png'

const VirtualDealDayAugust2023 = () => {
    return (
        <div className="row">
            <div className="col-md-8 col-12">
                <Panel className="content-wrapper">
                    <EventTitle
                        number="10"
                        bgImageNumber={8}
                        th="th"
                        title1="TransferRoom"
                        title2="Virtual Deal Day"
                        date="1st Aug 2023, Online"
                        vs={true}
                    />
                    <div className="photo-container row" style={{ padding: '0 10%' }}>
                        <img style={{ width: '100%' }} src={virtualDealDayImage} alt="" />
                    </div>
                </Panel>
            </div>
            <div className="col-md-4 col-12 row no-gutters">
                <div className="col-md-12 col-sm-6 col-12">
                    <PastEventStats
                        participants="460"
                        countries="53"
                        leagues="82"
                        agents="55"
                    />
                </div>
                <div className="col-md-12 col-sm-6 col-12">
                    <PastEventClubLogos
                        attendees={previousEventAttendees}
                        countlimit={42}
                    />
                </div>
            </div>
        </div>
    )
}

export const previousEventAttendees = [
    { id: 200004, name: "Charlotte FC", logo: "-78435_Charlotte_FC.png" },
    { id: 50113, name: "Inter Miami CF", logo: "-69261_Inter_Miami_CF.png" },
    { id: 5282, name: "PSV Eindhoven", logo: "11_PSV_Eindhoven.png" },
    { id: 2226, name: "Sevilla FC", logo: "680_Sevilla_FC.png" },
    { id: 7083, name: "Elche CF", logo: "logo_1793_Elche_CF.png" },
    { id: 49, name: "Arsenal FC", logo: "logo_1121_Arsenal_FC.png" },
    { id: 60, name: "Newcastle United", logo: "logo_Newcastle_United.png" },
    { id: 47, name: "Tottenham Hotspur", logo: "logo_1122_Tottenham_Hotspur.png" },
    { id: 29455, name: "Nottingham Forest", logo: "logo_1152_Nottingham_Forest.png" },
    { id: 30723, name: "Borussia Dortmund", logo: "logo_1259_Borussia_Dortmund.png" },
    { id: 6571, name: "US Salernitana 1919", logo: "logo_1865_US_Salernitana_1919.png" },
    { id: 7972, name: "LOSC Lille", logo: "logo_1974_LOSC_Lille.png" },
    { id: 10158, name: "FC Toulouse", logo: "3780_FC_Toulouse.png" },
    { id: 26329, name: "Trabzonspor", logo: "logo_2006_Trabzonspor.png" },
    { id: 2956, name: "KAA Gent", logo: "logo_1571_KAA_Gent.png" },
    { id: 1421, name: "Gremio Foot-Ball Porto Alegrense", logo: "logo_2605_Gremio_Foot-Ball_Porto_Alegrense.png" },
    { id: 17417, name: "BSC Young Boys", logo: "logo_1934_BSC_Young_Boys.png" },
    { id: 14821, name: "FC Copenhagen", logo: "logo_FC_Copenhagen.png" },
    { id: 25519, name: "Rapid Vienna", logo: "logo_1943_Rapid_Vienna.png" },
    { id: 12098, name: "CD Santa Clara", logo: "9634_CD_Santa_Clara.png" },
    { id: 32586, name: "Omonia Nicosia", logo: "logo_2175_Omonia_Nicosia.png" },
    { id: 3695, name: "SK Slavia Prague", logo: "11242_SK_Slavia_Prague.png" },
    { id: 7390, name: "Club Atletico Lanus", logo: "12063_Club_Atlético_Lanús.png" },
    { id: 174, name: "Club Atletico Talleres", logo: "logo_2588_Club_Atletico_Talleres.png" },
    { id: 8076, name: "CD Universidad Catolica", logo: "12295_CD_Universidad_Católica.png" },
    { id: 1949, name: "Independiente Medellin", logo: "12399_Independiente_Medellín.png" },
    { id: 18582, name: "Panathinaikos Athens", logo: "logo_2022_Panathinaikos_Athens.png" },
    { id: 5306, name: "Rakow Czestochowa", logo: "logo_1622_Rakow_Czestochowa.png" },
    { id: 3774, name: "Club Necaxa", logo: "logo_2283_Club_Necaxa.png" },
    { id: 36736, name: "Olimpia Asuncion", logo: "15486_Olimpia.png" },
    { id: 12027, name: "CA Boston River", logo: "logo_2875_Club_Atletico_Boston_River.png" },
    { id: 37475, name: "Mamelodi Sundowns FC", logo: "16344_Mamelodi_Sundowns_FC.png" },
    { id: 416, name: "Al-Fateh SC", logo: "16483_Al-Fateh_SC.png" },
    { id: 976, name: "AS Monaco", logo: "19830_AS_Monaco.png" },
    { id: 30849, name: "Atlanta United FC", logo: "logo_2103_Atlanta_United_FC.png" },
    { id: 39337, name: "SK Dnipro-1", logo: "59603_SK_Dnipro-1.png" },
]

export default VirtualDealDayAugust2023