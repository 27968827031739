import _ from 'lodash'
import { AppState } from 'root.reducer';
import { StateController } from 'utils/action-declaration';
import * as AgencyPlusPitchController from './agency-plus-pitch.controller';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';
import { PlusPitchSend } from 'api/agency/plus-pitch/models';


class PlusPitchTabSearchState {
    keyword: string;
    isLoading: boolean;
    wasLoaded: boolean;
    items: Array<PlusPitchSend>;
}

const defaultState: PlusPitchTabSearchState = {
    keyword: '',
    items: [],
    isLoading: false,
    wasLoaded: false,
}

const stateController = new StateController<PlusPitchTabSearchState>(
    'AGENCY/PLUS_PITCH/AUTOSUGGEST',
    defaultState
)

class Actions {

    public static createDebounce = () => _.debounce((dispatch) => dispatch(Actions.fetchSuggestions()), 1000)

        public static fetchDebounced = Actions.createDebounce()

    public static initGrid() {
        return (dispatch, getState: () => AppState) => {
            const pitchesList = Selectors.getGridList(getState())
            dispatch(stateController.setState({ items: pitchesList }))
        }
    }

    public static onKeywordChange(keyword: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }))
            dispatch(Actions.setKeyword(keyword))
            if (keyword.length > 0) {
                Actions.fetchDebounced(dispatch)
            } else {
                dispatch(Actions.onKeywordClear())
            }
        }
    }

    public static setKeyword(keyword: string) {
        return dispatch => {
            dispatch(stateController.setState({
                keyword,
            }))
        }
    }

    public static onKeywordClear() {
        return (dispatch, getState: () => AppState) => {
            const list = Selectors.getGridList(getState())
            dispatch(stateController.setState({ 
                items: list,
                keyword: '',
                isLoading: false
            }))
        }
    }

    public static fetchSuggestions() {
        return (dispatch, getState: () => AppState) => {
            const list = Selectors.getGridList(getState())
            const keyword = Selectors.getKeyword(getState());
            
            dispatch(stateController.setState({ 
                items: [...list.filter(item => 
                    item.pitchedPlayer && item.pitchedPlayer.englishShortName.toUpperCase().indexOf(keyword.toUpperCase()) !== -1 || 
                    item.pitchedClubStaff && item.pitchedClubStaff.name.toUpperCase().indexOf(keyword.toUpperCase()) !== -1
                )] 
            }))
            dispatch(stateController.setState({ isLoading: false }))
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.agency.plusPitchSearch
    public static isLoading = (state: AppState): boolean => Selectors.getRoot(state).isLoading
    public static getKeyword = (state: AppState): string => Selectors.getRoot(state).keyword
    public static getGridList = (state: AppState): Array<PlusPitchSend> => AgencyPlusPitchController.Selectors.getPlusPitchList(state)
    public static getFilteredGridList = (state: AppState): Array<PlusPitchSend> => Selectors.getRoot(state).items
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    PlusPitchTabSearchState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};