import { AppState } from 'root.reducer';
import { StateController } from 'utils/action-declaration';
import { CoachProfileService } from 'api/coach-profile/coach-profile.service';
import {
    CoachProfileModel,
    CoachAdditionalInfoModel,
    ComparedCoachItem,
    RadarDataItem,
    ComparisonSeasonData,
    ComparisonCoachData
} from "api/coach-profile/model";
import { notificationCreate } from 'app/notifications/notifications.actions';
import { copyToClipboard as copy } from 'utils/copy-to-clipboard';
import { getInPercents } from 'utils/convertors'
import { openCoachProfileById } from "utils/open-link";
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import historyAccessor from 'history-accessor'
import { userPaths } from 'routes/paths';
import { removeAccents } from 'utils/remove-accents';
import { getCancelTokenSource } from 'axios-config';
import { ActionType, CoachAccessSubscriptionEnum, PageType, UserActivityType } from 'constants/enums';
import { isClubUser, isHeadCoachUser, getCoachAccess, isAgencyUser } from 'store/auth/authReducer';
import { ReceiverRole } from 'api/search-v3/model/declare-interest-definition';
import { ReceiverType } from 'api/messaging/models/get-channel-response';
import { Actions as SendMessageActions } from 'pages/coach-profile/redux/send-message.controller';
import {
    DisplayCoachStatus,
    CoachStatus,
    SearchCoachItemForClub
} from "api/search-v3/model/search-coach-item-for-club";
import CoachOnboardingService from "api/agency/agent/onboarding/coaches/coach-onboarding.service";
import { Actions as CoachRedirectActions } from 'pages/coach/home-page/coach-redirect.controller';
import insertUserActivity from "../../../app/user-activity/actions/user-activity.actions";

export interface AvailableSectionData {
    [key: string]: boolean;
}

export interface AvailableSections {
    summary: boolean;
    career: boolean;
    performance: AvailableSectionData | null;
    coachingStyle: AvailableSectionData | null;
    suitability: AvailableSectionData | null;
    youthDevelopment: AvailableSectionData | null;
    references: AvailableSectionData | null;
    alternatives: boolean;
}

export interface HiddenSections {
    suitability: boolean;
    alternatives: boolean;
}

export interface ComparisonData {
    processing: boolean;
    currentCoach: ComparedCoachItem;
    selectedCoach: ComparedCoachItem;
    radarData: RadarDataItem[];
    sentence: string | null;
    availableSeasons: ComparisonSeasonData[];
    selectedSeason: ComparisonSeasonData | null;
    availableCoaches: ComparisonCoachData[];
    defaultSeasonId: number | null;
}

class State {
    isLoading: boolean;
    accessDenied: boolean;
    profile: CoachProfileModel;
    additionalInfo: CoachAdditionalInfoModel;
    isShareProfileModalOpen: boolean;
    processing: boolean;
    autoSelectValue: string;
    autoSelectOptionSelected: ComparisonCoachData;
    comparison: ComparisonData;
    availableSections: AvailableSections;
    hiddenSections: HiddenSections;
    declareInterestToAgentProcessing: boolean;
    agencyActionsProcessing: boolean;
}

const defaultState: State = {
    isLoading: false,
    accessDenied: false,
    processing: false,
    profile: null,
    additionalInfo: null,
    isShareProfileModalOpen: false,
    autoSelectValue: '',
    autoSelectOptionSelected: null,
    comparison: {
        processing: false,
        currentCoach: null,
        selectedCoach: null,
        radarData: null,
        sentence: null,
        availableSeasons: [],
        selectedSeason: null,
        availableCoaches: [],
        defaultSeasonId: null
    },
    availableSections: {
        coachingStyle: null,
        suitability: null,
        youthDevelopment: null,
        summary: false,
        career: false,
        performance: null,
        references: null,
        alternatives: false
    },
    hiddenSections: {
        suitability: false,
        alternatives: false,
    },
    declareInterestToAgentProcessing: false,
    agencyActionsProcessing: false
}

const stateController = new StateController<State>("COACH_PROFILE/MAIN_INFO", defaultState);

class Actions {
    public static cancelToken = null;

    public static dispose() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ ...defaultState }))
        }
    }

    public static disposeComparison() {
        return (dispatch, getState: () => AppState) => {
            const additionalInfoState = Selectors.getAdditionalInfo(getState());
            const defaultSeasons =
                additionalInfoState?.defaultComparisonSeasonList.length > 0 ? additionalInfoState.defaultComparisonSeasonList : [];
            const selectedSeason = defaultSeasons.find(season => season.id === additionalInfoState?.defaultComparisonSeasonId);

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                autoSelectOptionSelected: null,
                autoSelectValue: '',
                comparison: {
                    ...prevState.comparison,
                    availableSeasons: additionalInfoState?.defaultComparisonSeasonList ? additionalInfoState.defaultComparisonSeasonList : [],
                    availableCoaches: additionalInfoState?.defaultComparisonCoachList ? additionalInfoState.defaultComparisonCoachList : [],
                    sentence: null,
                    selectedCoach: null,
                    radarData: null,
                    selectedSeason: selectedSeason
                }
            })));
        }
    }

    public static init(coachId: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));

            const { isValidProfile, hasAccess } = await CoachProfileService.checkProfileAccess(coachId);
            if (!isValidProfile) {
                historyAccessor.push(userPaths.home);
                return;
            }

            if (hasAccess) {
                dispatch(Actions.sendUserActivity('Viewed Coach Profile', 'Coach Profile Main', coachId));

                await Promise.all([
                    dispatch(Actions.getCoachProfile(coachId)),
                    dispatch(Actions.getAdditionalInfo(coachId)),
                ]);

                dispatch(Actions.getAvailableSections());
                dispatch(Actions.getHiddenSections());

                dispatch(stateController.setState({ isLoading: false }));
            } else {
                dispatch(stateController.setState({ accessDenied: true }));
                dispatch(stateController.setState({ isLoading: false }));
                return; // TODO: some additional logic here
            }
        };
    };

    public static getCoachProfile(coachId: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const data = await CoachProfileService.getCoachProfile(coachId);

                CoachProfileService.saveProfileView(coachId);
                dispatch(stateController.setState({
                    profile: {
                        ...data,
                        // coachingStyle: {
                        //     ...data.coachingStyle,
                        //     alternatives: []
                        // }
                    },
                }))
            } catch (err) {
                console.error(err);
            }
        }
    }

    public static getAdditionalInfo(coachId: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const data = await CoachProfileService.getAdditionalInfo(coachId);
                const availableSeasons = data?.defaultComparisonSeasonList && data?.defaultComparisonSeasonList.length > 0 ? data.defaultComparisonSeasonList : []
                const availableCoaches = data?.defaultComparisonCoachList && data?.defaultComparisonCoachList.length > 0 ? data.defaultComparisonCoachList : []
                const selectedSeason = availableSeasons.find(season => season.id === data?.defaultComparisonSeasonId);

                dispatch(stateController.setState({
                    additionalInfo: {
                        ...data,
                        career: data.career.map((item, idx) => ({ ...item, key: `${idx}-${item.squad.id}` })),
                        playingCareer: data.playingCareer.map((item, idx) => ({ ...item, key: `${idx}-${item.squad.id}` })),
                        headCoachReferencesResponse: data.headCoachReferencesResponse,
                    },
                }));
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    comparison: {
                        ...prevState.comparison,
                        selectedCoach: null,
                        availableSeasons: availableSeasons,
                        availableCoaches: availableCoaches,
                        defaultSeasonId: data.defaultComparisonSeasonId,
                        selectedSeason: selectedSeason,
                    }
                })));
            } catch (err) {
                console.error(err);
            }
        }
    }

    public static initCurrent() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));

            dispatch(CoachRedirectActions.checkIfRedirectNeeded());

            await Promise.all([
                dispatch(Actions.getCurrentCoachProfile()),
                dispatch(Actions.getCurrentAdditionalInfo()),
            ]);

            dispatch(Actions.getAvailableSections());
            dispatch(Actions.getHiddenSections());

            dispatch(Actions.sendUserActivity('Viewed Head Coach Profile'));

            dispatch(stateController.setState({ isLoading: false }));
        };
    }

    public static getCurrentCoachProfile() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const data = await CoachProfileService.getCurrentCoachProfile();

                CoachProfileService.saveProfileView(data.coachId); 
                dispatch(stateController.setState({
                    profile: data,
                }))
            } catch (err) {
                console.error(err);
            }
        }
    }

    public static getCurrentAdditionalInfo() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const data = await CoachProfileService.getCurrentAdditionalInfo();
                const availableSeasons = data?.defaultComparisonSeasonList && data?.defaultComparisonSeasonList.length > 0 ? data.defaultComparisonSeasonList : []
                const availableCoaches = data?.defaultComparisonCoachList && data?.defaultComparisonCoachList.length > 0 ? data.defaultComparisonCoachList : []
                const selectedSeason = availableSeasons.find(season => season.id === data?.defaultComparisonSeasonId);

                dispatch(stateController.setState({
                    additionalInfo: {
                        ...data,
                        career: data.career.map((item, idx) => ({
                                ...item,
                                key: `${idx}-${item.squad.id}`,
                                isRelevantExperience: false
                            })),
                        playingCareer: data.playingCareer.map((item, idx) => ({ ...item, key: `${idx}-${item.squad.id}` })),
                    },
                }));
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    comparison: {
                        ...prevState.comparison,
                        selectedCoach: null,
                        availableSeasons: availableSeasons,
                        availableCoaches: availableCoaches,
                        defaultSeasonId: data.defaultComparisonSeasonId,
                        selectedSeason: selectedSeason,
                    }
                })));
            } catch (err) {
                console.error(err);
            }
        }
    }
    
    public static getAvailableSections() {
        return (dispatch, getState: () => AppState) => {
            const isUserClub = isClubUser(getState());
            const isCoachUser = isHeadCoachUser(getState());
            const profile = Selectors.getProfile(getState());
            const info = Selectors.getAdditionalInfo(getState());
            const comparison = Selectors.getComparison(getState());
            const performance = profile?.performance
            const youthDevelopment = profile?.youthDevelopment;
            const coachAccess = getCoachAccess(getState());
            const isAgencyCoachAccessFull = coachAccess === CoachAccessSubscriptionEnum.Full;

            const isPerformanceExists = performance?.teamRatingData?.length > 0 || performance?.squadValueData?.length > 0 || (
                performance?.xgImpactDefense?.length > 0 ||
                performance?.xgImpactOffense?.length > 0 ||
                performance?.xgImpactTotal?.length > 0
            );

            const isCoachingStyleExists = comparison?.availableSeasons?.length > 0 ||
                profile?.coachingStyle?.formations?.length > 0 ||
                Object.values(profile?.coachingStyle?.playingStyle).some(x => x !== null) ||
                profile?.coachingStyle?.squadRotation?.length > 0
            ;

            const isYouthDevelopmentExists = youthDevelopment?.youthDevelopmentSummary?.length > 0 ||
                youthDevelopment?.youthDevelopmentMinutes?.length > 0 ||
                youthDevelopment?.youthDevelopmentStartingXI?.length > 0
            ;
            
            const isSuitabilityExists = info?.suitabilityScore != null && isUserClub && (info?.suitablePlayers?.length > 0 ||
                profile.suitability.seasonSpend?.length > 0 ||
                info?.shoppingList?.length > 0);

            const isAlternativesExists = (isAgencyCoachAccessFull || isUserClub || isCoachUser) &&
                profile?.coachingStyle?.alternatives?.length > 0;

            const isReferencesExists = info?.headCoachReferencesResponse?.coachReferencesWorkedWithItems?.length > 0 ||
                info?.headCoachReferencesResponse?.coachReferencesWorkedUnderItems?.length > 0 ||
                info?.headCoachReferencesResponse?.coachReferencesWorkedUnderAsPlayerItems?.length > 0;

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                availableSections: {
                    ...prevState.availableSections,
                    summary: !!profile?.executiveSummary,
                    career: info?.career?.length > 0,
                    performance: isPerformanceExists ? {
                        teamRating: performance?.teamRatingData?.length > 0,
                        xgImpact: performance?.xgImpactDefense?.length > 0 || performance?.xgImpactOffense?.length > 0 || performance?.xgImpactTotal?.length > 0,
                        squadValue: performance?.squadValueData?.length > 0,
                    } : null,
                    coachingStyle: isCoachingStyleExists ? {
                        comparison: comparison?.availableSeasons?.length > 0,
                        formation: profile?.coachingStyle?.formations?.length > 0,
                        playingStyle: Object.values(profile?.coachingStyle?.playingStyle).some(x => x !== null),
                        squadRotation: profile?.coachingStyle?.squadRotation.length > 0,
                        substitutions: profile?.coachingStyle?.substitutionsDistribution?.length > 0
                    } : null,
                    youthDevelopment: isYouthDevelopmentExists ? {
                        summary: youthDevelopment?.youthDevelopmentSummary?.length > 0,
                        minutes: youthDevelopment?.youthDevelopmentMinutes?.length > 0,
                        starting: youthDevelopment?.youthDevelopmentStartingXI?.length > 0,
                    } : null,
                    suitability: isSuitabilityExists ? {
                        playerSuitability: info?.suitablePlayers?.length > 0,
                        transferSpend: profile?.suitability?.seasonSpend?.length > 0,
                        shoppingList: info?.shoppingList?.length > 0,
                    } : null,
                    references: isReferencesExists,
                    alternatives: isAlternativesExists
                }
            })));
        }
    }

    public static getHiddenSections() {
        return (dispatch, getState: () => AppState) => {
            const isUserClub = isClubUser(getState());
            const isUserAgency = isAgencyUser(getState());
            const coachAccess = getCoachAccess(getState());

            const isAgencyCoachAccessFull = coachAccess === CoachAccessSubscriptionEnum.Full;

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                hiddenSections: {
                    ...prevState.hiddenSections,
                    suitability: !isUserClub,
                    alternatives: (isUserAgency && !isAgencyCoachAccessFull),
                }
            })));
        }
    }

    public static shareProfileModalOpen() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isShareProfileModalOpen: true }));
        }
    }

    public static shareProfileModalClose() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isShareProfileModalOpen: false }));
        }
    }

    public static copyLink() {
        return (dispatch, getState: () => AppState) => {
            const url = new URL(window.location.href);
            copy(url.toString());
            dispatch(notificationCreate({ message: 'Copied to clipboard', level: 'info' }))
        }
    }

    public static onSelectComparisonSeason(season: ComparisonSeasonData) {
        return (dispatch, getState: () => AppState) => {
            const { autoSelectOptionSelected } = Selectors.getRoot(getState());
            const selectedCoachId = autoSelectOptionSelected ? autoSelectOptionSelected.id : undefined;

            dispatch(stateController.setState(prevState => ({
                ...prevState,
                comparison: {
                    ...prevState.comparison,
                    selectedSeason: season
                },
            })));
            if (!selectedCoachId) {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    autoSelectOptionSelected: null,
                    autoSelectValue: ''
                })));
            }
            dispatch(Actions.getCoachComparison(season.id, selectedCoachId));
            dispatch(Actions.sendUserActivity('Coach Profile Coaching Style Comparison Season Changed'));
        }
    }

    public static getCoachComparison(seasonId: number, selectedCoachId?: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                comparison: {
                    ...prevState.comparison,
                    processing: true
                }
            })));
            try {
                const profileState = Selectors.getProfile(getState());
                const currentCoachId = profileState?.coachId;
                const additionalInfoState = Selectors.getAdditionalInfo(getState());
                const defaultComparisonSeasonList = additionalInfoState.defaultComparisonSeasonList;
                const defaultComparisonCoachList = additionalInfoState.defaultComparisonCoachList;

                if (Actions.cancelToken) {
                    Actions.cancelToken.cancel();
                }
                Actions.cancelToken = getCancelTokenSource();

                const data = await CoachProfileService.getCoachComparison(currentCoachId, seasonId, Actions.cancelToken.token, selectedCoachId);

                if (data) {
                    const radarData = [
                        {
                            subject: 'Attack',
                            firstCoach: data.firstCoach ? getInPercents(data, 'totalGoals', 'firstCoach') : 0,
                            firstCoachFormatted: data.firstCoach ? data.firstCoach.totalGoalsFormatted : null,
                            secondCoach: data.secondCoach ? getInPercents(data, 'totalGoals', 'secondCoach') : 0,
                            secondCoachFormatted: data.secondCoach ? data.secondCoach.totalGoalsFormatted : null,
                        },
                        {
                            subject: 'Possession',
                            firstCoach: data.firstCoach ? getInPercents(data, 'posession', 'firstCoach') : 0,
                            firstCoachFormatted: data.firstCoach ? data.firstCoach.posessionFormatted : null,
                            secondCoach: data.secondCoach ? getInPercents(data, 'posession', 'secondCoach') : 0,
                            secondCoachFormatted: data.secondCoach ? data.secondCoach.posessionFormatted : null,
                        },
                        {
                            subject: 'Shot Distance',
                            firstCoach: data.firstCoach ? getInPercents(data, 'xG_Shot', 'firstCoach') : 0,
                            firstCoachFormatted: data.firstCoach ? data.firstCoach.xG_ShotFormatted : null,
                            secondCoach: data.secondCoach ? getInPercents(data, 'xG_Shot', 'secondCoach') : 0,
                            secondCoachFormatted: data.secondCoach ? data.secondCoach.xG_ShotFormatted : null,
                        },
                        {
                            subject: 'Directness',
                            firstCoach: data.firstCoach ? getInPercents(data, 'longBall', 'firstCoach') : 0,
                            firstCoachFormatted: data.firstCoach ? data.firstCoach.longBallFormatted : null,
                            secondCoach: data.secondCoach ? getInPercents(data, 'longBall', 'secondCoach') : 0,
                            secondCoachFormatted: data.secondCoach ? data.secondCoach.longBallFormatted : null,
                        },
                        {
                            subject: 'Width',
                            firstCoach: data.firstCoach ? getInPercents(data, 'crosses', 'firstCoach') : 0,
                            firstCoachFormatted: data.firstCoach ? data.firstCoach.crossesFormatted : null,
                            secondCoach: data.secondCoach ? getInPercents(data, 'crosses', 'secondCoach') : 0,
                            secondCoachFormatted: data.secondCoach ? data.secondCoach.crossesFormatted : null,
                        },
                        {
                            subject: 'Pressing',
                            firstCoach: data.firstCoach ? getInPercents(data, 'ppda', 'firstCoach') : 0,
                            firstCoachFormatted: data.firstCoach ? data.firstCoach.ppdaFormatted : null,
                            secondCoach: data.secondCoach ? getInPercents(data, 'ppda', 'secondCoach') : 0,
                            secondCoachFormatted: data.secondCoach ? data.secondCoach.ppdaFormatted : null,
                        },
                    ];

                    const availableSeasons =
                        data.secondCoach && data.seasonIntersection
                            ? data.seasonIntersection
                            : defaultComparisonSeasonList;
                    const availableCoaches = data.coachListIntersection
                        ? data.coachListIntersection
                        : defaultComparisonCoachList;

                    const selectedSeason = availableSeasons.find(season => season.id === seasonId)

                    dispatch(stateController.setState(prevState => ({
                        ...prevState,
                        comparison: {
                            ...prevState.comparison,
                            currentCoach: data.firstCoach,
                            selectedCoach: data.secondCoach,
                            sentence: data.sentence,
                            radarData: radarData,
                            availableSeasons: availableSeasons,
                            availableCoaches: availableCoaches,
                            selectedSeason: selectedSeason,
                        }
                    })));
                }
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    comparison: {
                        ...prevState.comparison,
                        processing: false
                    }
                })));
            }
        }
    }

    public static openCoachProfileById(coachId: number) {
        return (dispatch, getState: () => AppState) => {
            openCoachProfileById(coachId);
        }
    }

    public static openPlayerProfileById(playerId: number,) {
        return async (dispatch, getState: () => AppState) => {
            window.open(`/profile/${playerId}`, '_blank')
        }
    }

    public static sendUserActivity(message: string, pageName?: string, coachId?: number) {
        return (dispatch, getState: () => AppState) => {
            const isCoachUser = isHeadCoachUser(getState());
            const coachIdState = getState().coachProfile.profile?.coachId;
            const getPageName = () => {
                if (isCoachUser) {
                    return 'Head Coach Profile';
                }
                return pageName ? pageName : 'Coach Profile Main';
            }

            dispatch(userActivityInsert({
                Message: message,
                PageName: getPageName(),
                PageType: PageType.CoachProfile,
                CoachId: coachIdState ? coachIdState : coachId,
            }));
        }
    }

    // coach autoSelect
    public static onChangeAutoSelect(value: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ autoSelectValue: value }));
        }
    }

    public static onClearAutoSelect() {
        return (dispatch, getState: () => AppState) => {
            const additionalInfoState = Selectors.getAdditionalInfo(getState());
            const comparisonState = Selectors.getComparison(getState());
            const radarData = [...comparisonState.radarData];

            const radarDataUpdated = radarData.map(x => ({
                ...x,
                secondCoach: 0,
                secondCoachFormatted: null
            }));

            if (!comparisonState.selectedCoach) {
                dispatch(stateController.setState({
                    autoSelectValue: '',
                    autoSelectOptionSelected: null
                }));
            } else {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    autoSelectOptionSelected: null,
                    autoSelectValue: '',
                    comparison: {
                        ...prevState.comparison,
                        availableSeasons: additionalInfoState.defaultComparisonSeasonList,
                        sentence: null,
                        selectedCoach: null,
                        radarData: radarDataUpdated
                    }
                })));
            }
        }
    }

    public static onSelectAutoSelectItem(coach: ComparisonCoachData) {
        return (dispatch, getState: () => AppState) => {
            const coaches = Selectors.getRoot(getState()).comparison.availableCoaches;
            const selectedCoach = coaches.find(x => x.id === coach.id);
            const { selectedSeason } = Selectors.getComparison(getState());
            const selectedSeasonId = selectedSeason ? selectedSeason.id : undefined;
            dispatch(Actions.onChangeAutoSelect(selectedCoach.name));
            dispatch(Actions.getCoachComparison(selectedSeasonId, coach.id));
            dispatch(stateController.setState({ autoSelectOptionSelected: coach }));
            dispatch(Actions.sendUserActivity('Coach Profile Coaching Style Comparison Head Coach Selected'));
        }
    }

    public static openAgencyProfile = () => {
        return (dispatch, getState: () => AppState) => {
            const substate = Selectors.getProfile(getState());
            const coachId = substate?.coachId;
            const agencyName = substate?.agencyRelatedInfo?.agencyName;
            const agencyId = substate?.agencyRelatedInfo?.agencyId;

            dispatch(userActivityInsert({
                Message: `Clicked ${agencyName} Profile Link`,
                PageName: `Coach Profile [Top of Profile]`,
                PageType: PageType.CoachProfile,
                CoachId: coachId ? coachId : null,
                AgencyId: agencyId ? agencyId : null,
            }));
            window.open(`/agency-profile/${encodeURIComponent(agencyName)}`, '_blank');
        }
    }

    public static declareInterestToAgentForCoach = (coachId: number, agencyId: number) => {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ declareInterestToAgentProcessing: true }));
            try {
                const profile = Selectors.getProfile(getState());

                await CoachProfileService.declareInterestToAgentForCoach(coachId);

                const declareInterestToRole = { ...profile.declareInterest.declareInterestToRole };
                if (declareInterestToRole.toRole == ReceiverRole.Agent) {
                    declareInterestToRole.interestDeclared = true;
                }

                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    profile: {
                        ...prevState.profile,
                        declareInterest: {
                            ...prevState.profile.declareInterest,
                            interestDeclaredToAgent: true,
                            declareInterestToRole,
                        }
                    }
                })));

                dispatch(userActivityInsert({
                    Message: `Declared Interest to Agent`,
                    PageName: `Coach Profile [Top of Profile]`,
                    PageType: PageType.PlayerProfile,
                    CoachId: coachId ? coachId : null,
                    AgencyId: agencyId ? agencyId : null,
                    ActionType: ActionType.DeclaredInterest,
                    UserActivityType: UserActivityType.Action
                }));
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ declareInterestToAgentProcessing: false }));
            }
        }
    }

    public static onNewMessageClick = (messageReceiverType: ReceiverType, agencyId?: number) => {
        return async (dispatch, getState: () => AppState) => {
            const isAgencyMessageReceiver = messageReceiverType === ReceiverType.Agency;
            if (isAgencyMessageReceiver) {
                const substate = Selectors.getProfile(getState());
                const coachIdState = substate?.coachId;

                dispatch(userActivityInsert({
                    Message: `Clicked Send Message to Agent`,
                    PageName: `Coach Profile [Top of Profile]`,
                    PageType: PageType.CoachProfile,
                    PlayerId: coachIdState ? coachIdState : null,
                    AgencyId: agencyId ? agencyId : null,
                }));
            } else {
                // TODO: message sendont to Club not used now for Coach
                // dispatch(Actions.sendUserActivity('Clicked Send Message to Club', 'Top of Profile'));
            }
            dispatch(SendMessageActions.openModal(messageReceiverType));

        }
    }

    public static onClaimRepresentationClick(staffId: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ agencyActionsProcessing: true }));
            try {
                await CoachOnboardingService.addCoaches([{ staffId: staffId, isRegular: true }]);

                dispatch(Actions.sendUserActivity('Clicked Claim Representation on TransferRoom', 'Top of Profile', staffId));

                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    profile: {
                        ...prevState.profile,
                        agencyRelatedInfo: {
                            ...prevState.profile.agencyRelatedInfo,
                            agencyCoachDisplayInfo: {
                                ...prevState.profile.agencyRelatedInfo.agencyCoachDisplayInfo,
                                displayStatus: DisplayCoachStatus.ButtonGetCoachVerified,
                                status: CoachStatus.ClaimedNotVerified,
                            }
                        }
                    }
                })));
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ agencyActionsProcessing: false }));
            }
        }
    }

    public static getCoachVerified(staffId: number) {
        return (dispatch, getState: () => AppState) => {
            const currentCoach = Selectors.getProfile(getState());
            const squadId = currentCoach?.shortInfo?.squad?.id;
            const isFreeAgent = currentCoach?.shortInfo?.isFreeAgent;
            const agencyId = currentCoach?.agencyRelatedInfo?.agencyId;

            dispatch(insertUserActivity({
                Message: 'Verify Head Coach',
                PageName: `Top of Profile`,
                CoachId: staffId,
                ClubId: squadId ? squadId : null,
                AgencyId: isFreeAgent ? agencyId : null,
            }));

            let url = window.origin + `/coach-verification?coach=${staffId}`;
            window.open(url, '_blank').focus();
        }
    }

    public static onClickBannerPrevArrow() {
        return (dispatch, getState: () => AppState) => {
            const substate = Selectors.getProfile(getState());
            const coachId = substate?.coachId;

            dispatch(userActivityInsert({
                PageName: 'Head Coach Profile',
                Message: 'Coach Profile Alternatives Navigated Back',
                PageType: PageType.CoachProfile,
                CoachId: coachId
            }));
        }
    }

    public static onClickBannerNextArrow() {
        return (dispatch, getState: () => AppState) => {
            const substate = Selectors.getProfile(getState());
            const coachId = substate?.coachId;

            dispatch(userActivityInsert({
                PageName: 'Head Coach Profile',
                Message: 'Coach Profile Alternatives Navigated Forward',
                PageType: PageType.CoachProfile,
                CoachId: coachId
            }));
        }
    }

}

class Selectors {
    public static getRoot = (state: AppState): State => state.coachProfile;
    public static getProfile = (state: AppState): CoachProfileModel => Selectors.getRoot(state).profile;
    public static getAdditionalInfo = (state: AppState): CoachAdditionalInfoModel => Selectors.getRoot(state).additionalInfo;
    public static getCurrencySign = (state: AppState) => Selectors.getRoot(state).additionalInfo?.currency?.sign;
    public static isLoading = (state: AppState) => Selectors.getRoot(state).isLoading;
    public static isAccessDenied = (state: AppState) => Selectors.getRoot(state).accessDenied;
    public static hasPlayingCareer = (state: AppState) => Selectors.getRoot(state).additionalInfo?.playingCareer?.length > 0;
    public static getCoachLastName = (state: AppState) => Selectors.getRoot(state).profile.shortInfo.lastName || Selectors.getRoot(state).profile.shortInfo.shortName;
    public static getSquadName = (state: AppState) => Selectors.getRoot(state).additionalInfo?.squad?.name;
    public static getCoachName = (state: AppState) => Selectors.getRoot(state).profile?.shortInfo.shortName;
    public static getComparison = (state: AppState) => Selectors.getRoot(state).comparison;
    public static getAutoSelectValue = (state: AppState) => Selectors.getRoot(state).autoSelectValue;
    public static getAutoSelectOption = (state: AppState) => Selectors.getRoot(state).autoSelectOptionSelected;

    public static getAvailableCoachesFiltered = (state: AppState) => {
        const coaches = Selectors.getRoot(state).comparison.availableCoaches;
        const keyword = Selectors.getAutoSelectValue(state);

        return coaches.filter((coach) =>
            (coach.name && removeAccents(coach.name).toLowerCase() || '').indexOf(removeAccents(keyword).toLowerCase()) > -1
        );
    }

    public static getAvailableSections = (state: AppState) => Selectors.getRoot(state).availableSections;
    public static getHiddenSections = (state: AppState) => Selectors.getRoot(state).hiddenSections;
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};
