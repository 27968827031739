import React from 'react'
import { connect } from 'react-redux';
import { getTumbnailUrl } from 'utils/video-links';
import styled from 'styled-components'
import ReactPlayer from 'react-player';
import Thumb from './bg-madrid.png';
import PlayVimeoIcon from './play-btn.png';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import DividerLine from '../divider-line/divider-line';

class VimeoPlayerProps {
    content: Content;
    trackUserActivity: () => void
}

type Content = {
    videoUrl: string;
    backgroundThumb: string;
}

class ReplayComponent extends React.Component<VimeoPlayerProps, any> {
    state = {
        isPlaying: false,
        isUserActivityTracked: false,
    }


    render() {

        const {
            videoUrl,
            backgroundThumb
        } = this.props.content
        const { isPlaying } = this.state

        return (
            <StyledSection>
                <div className="iframe-container">
                    <div>
                        <div className={`video`}>
                            {!isPlaying && (
                                <div onClick={() => {
                                    this.setState({ isPlaying: !this.state.isPlaying }, () => {
                                        if (!this.state.isUserActivityTracked && this.props.trackUserActivity) {
                                            this.props.trackUserActivity()
                                            this.setState({ isUserActivityTracked: true })
                                        }
                                    })
                                }}>
                                    <img className="tumbnail" src={backgroundThumb} alt="video tumbnail" />

                                    <div className="overlay-container">
                                        <img className="play-btn" src={PlayVimeoIcon} ></img>
                                    </div>

                                </div>
                            )}

                            <ReactPlayer
                                playing={isPlaying}
                                onPause={() => this.setState({ isPlaying: false })}
                                controls={true}
                                style={isPlaying ? { width: '100%', height: '100%' } : { display: 'none', width: '100%', height: '100%' }}
                                url={videoUrl}
                            />
                        </div>
                    </div>
                </div>
                {/* <div className="line"></div> */}
            </StyledSection>
        )
    }
}

export default connect(null, { userActivityInsert: userActivityInsert })(ReplayComponent)

const StyledSection = styled.div`

    .line {
        height: 1px;
        width: 100%;
        margin-top: 85px;
        background: #D4D4D4;
    }

    .iframe-container {
        overflow: hidden;
        padding-top: 57.25%;
        position: relative;
        border-radius: 15px;

        > div {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            .video {
                position: relative;
                width: 100%;
                height: 100%;
                > div:first-child {
                    width: 100% !important;
                    height: 100% !important;
                }
                .tumbnail {
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                }
                .overlay-container {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    padding: 50px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    @media (max-width: 650px) {
                        padding: 25px;
                    }
                    @media (max-width: 550px) {
                        flex-direction: column-reverse;
                    }
                    
                    .play-btn {
                        display: block;
                        position: relative;
                        margin: 0 auto;
                        height: 87px;
                        width: 87px;
                        transition: all 100ms ease-in-out;
                        cursor: pointer;
                        &:hover {
                            opacity: 0.8;
                        }
                        @media (max-width: 1200px) {
                            height: 60px;
                            width: 60px;
                        }
                        @media (max-width: 650px) {
                            height: 40px;
                            width: 40px;
                        }
                        @media (max-width: 550px) {
                            margin-top: 0;
                            margin-bottom: 10px;
                            height: 50px;
                            width: 50px;
                        }
                    }
                    
                    &:hover {
                        cursor: pointer;
                        .play-btn {
                            opacity: 0.8;
                        }
                    }
                }
            }
        }   
    }
`