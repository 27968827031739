import { createAction } from 'redux-actions';
import { MODAL_CLOSE, MODAL_OPEN } from 'store/actionTypes';
import {
    MATCH_MAKER_CLUB_ADS_MODAL_V2
} from 'constants/modals';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';

export const closeMatchMakerClubAdsdModalWithTracking = () => {
    return (dispatch, getState) => {
        dispatch(userActivityInsert({ 
            Message: 'Closed Recommender', 
            PageName: 'Squad [Recommender]',
            PageType: PageType.Squad
        }));
        dispatch(closeMatchMakerClubAdsdModal())
    };
};

export const closeMatchMakerClubAdsdModal = createAction(
    MODAL_CLOSE,
    () => MATCH_MAKER_CLUB_ADS_MODAL_V2, //MATCH_MAKER_CLUB_ADS_MODAL
);

export const openMatchMakerClubAdsdModal = player => {
    return dispatch => {
        let command = {
            type: MODAL_OPEN,
            payload: {
                id: MATCH_MAKER_CLUB_ADS_MODAL_V2, //MATCH_MAKER_CLUB_ADS_MODAL
                content: { player: player },
                customToggle: closeMatchMakerClubAdsdModalWithTracking
            },
        };
        dispatch(command);
    };
};