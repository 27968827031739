import { StateController } from 'utils/action-declaration';
import validator from 'services/validator';
import { AppState } from 'root.reducer';
import historyAccessor from 'history-accessor'
import { RegistrationService } from 'api/registration/registration.service';
import { anonymousPaths } from 'routes/paths';

export enum RegistrationState {
    DefaultView = 1,
    BookDemo = 2,
    BookDemoDone = 3,
    RequestInvitation = 4,
    RequestInvitationDone = 5,
    AgencyIsAlreadyExist = 6,
}

export type AgentSignUpForm = {
    email: string;
    firstName: string;
    surname: string;
    agencyName: string;
    phoneCodeAreaId: number;
    phoneNumber: string;
}

export type ValidationState = {
    isEmailValid: boolean,
    isFirstNameValid: boolean;
    isSurnameValid: boolean;
    isPhoneCodeAreaIdValid: boolean;
    isAgencyNameValid: boolean;
    isPhoneNumberValid: boolean;
    didTryToProcess: boolean,
    errorMessage: string | Array<string>;
    isFormValid: boolean;
};

class ClubRegistration {
    isProcessing: boolean;
    currentState: RegistrationState;
    form: AgentSignUpForm;
    validation: ValidationState;
    agencyIsAlreadyExist: boolean;
}

const defaultState: ClubRegistration = {
    isProcessing: false,
    currentState: RegistrationState.RequestInvitationDone,
    form: {
        email: '',
        firstName: '',
        surname: '',
        agencyName: '',
        phoneCodeAreaId: null,
        phoneNumber: ''
    },
    agencyIsAlreadyExist: false,
    validation: {
        isEmailValid: null,
        isFirstNameValid: null,
        isSurnameValid: null,
        isPhoneCodeAreaIdValid: null,
        isAgencyNameValid: null,
        isPhoneNumberValid: null,

        didTryToProcess: false,
        errorMessage: '',
        isFormValid: true,
    },
}

const stateController = new StateController<ClubRegistration>(
    "REGISTRATION_NEW/AGENT",
    defaultState
);

class Actions {
    private static validationRules = {
        email: {
            method: validator.isValidEmail,
            message: 'Insert user email!',
        },
        firstName: {
            method: validator.isNotEmpty,
            message: 'Insert your first name.',
        },
        surname: {
            method: validator.isNotEmpty,
            message: 'Insert your last name.',
        },
        agencyName: {
            method: validator.isNotEmpty,
            message: 'Insert agency name.',
        },
        phoneCodeAreaId: {
            method: validator.isNotEmpty,
            message: 'Select your phone code.',
        },
        phoneNumber: {
            method: validator.isNotEmpty,
            message: 'Insert yout phone number.',
        },
    };

 

    public static setDefaultViewState() {
        return dispatch => {
            dispatch(stateController.setState({ currentState: RegistrationState.DefaultView }))
        }
    }

    
 

    public static setSignUpState() {
        return async (dispatch, getState) => {
            await dispatch(Actions.validateEmail());

            const isFormValid = getState().registration.agent.validation.isEmailValid
            if (isFormValid) {
                dispatch(stateController.setState({ isProcessing: true }))
                const email = getState().registration.agent.form.email
                const result = await RegistrationService.checkUser(email)
                dispatch(stateController.setState({ isProcessing: false }))
                dispatch(stateController.setState(prevState => ({ ...prevState, validation: { errorMessage: '', didTryToProcess: false } })))
                if (result) {
                    historyAccessor.push('/login')
                } else {
                    dispatch(stateController.setState({ currentState: RegistrationState.RequestInvitation }))
                }
            }
        }
    }
    public static setBookDemoState() {
        return async dispatch => {
            dispatch(stateController.setState({ currentState: RegistrationState.BookDemo }))
        }
    }
    public static setBookDemoDoneState() {
        return dispatch => {
            dispatch(stateController.setState({ currentState: RegistrationState.BookDemoDone }))
        }
    }
    public static setRequestInvitationState() {
        return dispatch => {
            dispatch(stateController.setState({ currentState: RegistrationState.RequestInvitation }))
        }
    }

    public static setRequestInvitationDoneState() {
        return dispatch => {
            dispatch(stateController.setState({ currentState: RegistrationState.RequestInvitationDone }))
        }
    }

    public static setRequestInvitationAgencyIsAlreadyExistState() {
        return dispatch => {
            dispatch(stateController.setState({ currentState: RegistrationState.AgencyIsAlreadyExist }))
        }
    }

    public static onEmailChange(value: string) {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState, form: { ...prevState.form, email: value }
            })))
        }
    }
    public static onFirstNameChange(value: string) {
        return (dispatch, getState: () => AppState) => {


            let state = getState().registration.agent;
            let changed: ClubRegistration = { ...state, form: { ...state.form, firstName: value } };


            dispatch(stateController.setState(changed));
        }
    }
    public static onSurnameChange(value: string) {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState, form: { ...prevState.form, surname: value }
            })))
        }
    }
    public static onAgencyNameChange(value: string) {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState, form: { ...prevState.form, agencyName: value }
            })))
        }
    }
    public static onPhoneCodeChange(value: string) {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState, form: { ...prevState.form, phoneCodeAreaId: value }
            })))
        }
    }
    public static onPhoneNumberChange(value: string) {
        return dispatch => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                form: {
                    ...prevState.form,
                    phoneNumber: value
                }
            })))
        }
    }

    public static processBookDemo() {
        return async (dispatch, getState: () => AppState) => {
            await dispatch(Actions.validateExtendedForm())
            const isFormValid = getState().registration.agent.validation.isFormValid

            if (isFormValid) {
                const {
                    firstName,
                    surname,
                    email,
                    agencyName,
                    phoneCodeAreaId,
                    phoneNumber,
                } = getState().registration.agent.form

                dispatch(stateController.setState({ isProcessing: true }))

                const m = {
                    firstName: firstName,
                    lastName: surname,
                    email: email,
                    agencyName: agencyName,
                    phoneCodeAreaId: phoneCodeAreaId,
                    phone: phoneNumber,
                };
                await RegistrationService.requestAgencyDemo(m)
                dispatch(stateController.setState({ isProcessing: false }))
                dispatch(Actions.disposeComponent())
                dispatch(Actions.setBookDemoDoneState())
            }
        }
    }
    public static processRequestInvitation() {
        return async (dispatch, getState: () => AppState) => {
            await dispatch(Actions.validateExtendedForm())
            const isFormValid = getState().registration.agent.validation.isFormValid

            if (isFormValid) {
                const {
                    firstName,
                    surname,
                    email,
                    agencyName,
                    phoneCodeAreaId,
                    phoneNumber,
                } = getState().registration.agent.form
                const m = {
                    firstName: firstName,
                    lastName: surname,
                    email: email,
                    agencyName: agencyName,
                    phoneCodeAreaId: phoneCodeAreaId,
                    phone: phoneNumber,
                };
                
                dispatch(stateController.setState({ isProcessing: true }))
                const result = await RegistrationService.registerAgency(m)
                dispatch(stateController.setState({ isProcessing: false }))
                
                if (result.value.emailIsRegistered) {
                   
                    dispatch(Actions.disposeComponent())

                    historyAccessor.push(anonymousPaths.login);
                }
                else if(result.value.agencyMatch){
                  
                    dispatch(Actions.setRequestInvitationAgencyIsAlreadyExistState())
                }
                else {
                    dispatch(Actions.disposeComponent())
                    dispatch(Actions.setRequestInvitationDoneState())
                }
            }
        }
    }

    public static disposeComponent() {
        return dispatch => {
            dispatch(stateController.setState(defaultState))
        }
    }

    public static disposeError() {
        return async dispatch => {
            dispatch(stateController.setState(prevState => ({ ...prevState, validation: { errorMessage: '', didTryToProcess: false } })))
        }
    }

    public static validateEmail() {
        return async (dispatch, getState: () => AppState) => {
            const email = getState().registration.agent.form.email
            const result = validator.validate({ email: email }, this.validationRules) as any
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                validation: {
                    ...prevState.validation,
                    didTryToProcess: true,
                    errorMessage: result.email.message,
                    isEmailValid: result.email.isValid,
                }
            })))
        }
    }

    public static prefill(email: string, firstName: string, lastName: string) {
        return dispatch => {
            if (email != null && firstName != null) {
                dispatch(Actions.setRequestInvitationState());
                dispatch(Actions.onEmailChange(email));
                dispatch(Actions.onFirstNameChange(firstName));
                if (lastName) {
                    dispatch(Actions.onSurnameChange(lastName));
                }
            }
        }
    };

       public static resetForm (){
        return dispatch =>{
            dispatch(Actions.setDefaultViewState());
            dispatch(Actions.disposeComponent());
        }
    }

    public static validateExtendedForm() {
        return async (dispatch, getState: () => AppState) => {
            const form = getState().registration.agent.form
            const result = validator.validate({
                email: form.email,
                firstName: form.firstName,
                surname: form.surname,
                agencyName: form.agencyName,
                phoneCodeAreaId: form.phoneCodeAreaId,
                phoneNumber: form.phoneNumber
            }, this.validationRules) as any
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                validation: {
                    ...prevState.validation,
                    isFormValid:
                        result.email.isValid
                        && result.email.isValid
                        && result.firstName.isValid
                        && result.surname.isValid
                        && result.agencyName.isValid
                        && result.phoneCodeAreaId.isValid
                        && result.phoneNumber.isValid,
                    didTryToProcess: true,
                    errorMessage: result.email.message,

                    isEmailValid: result.email.isValid,
                    isFirstNameValid: result.firstName.isValid,
                    isSurnameValid: result.surname.isValid,
                    isAgencyNameValid: result.agencyName.isValid,
                    isPhoneCodeAreaIdValid: result.phoneCodeAreaId.isValid,
                    isPhoneNumberValid: result.phoneNumber.isValid,
                }
            })))
        }
    }
}


const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    ClubRegistration as State,
    Actions as Actions,
    stateController as Controller
};



