import axios from 'axios-config';
import { CompleteLtaSubsriptionRequest, SubscriptionPlansResponse } from './subscription-models';

export default class AgencySubscriptionService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async getAllPlans(): Promise<SubscriptionPlansResponse> {
        const { data } = await axios.get(
            `api/v2/AgencySubscription/GetPlans`, this.axiosConfig)
        return data;
    }

    public static async getAllPlansForAgencyToSubscribe(agencyId): Promise<SubscriptionPlansResponse> {
        const { data } = await axios.get(
            `api/v2/AgencySubscription/GetPlansForAgency/${(agencyId || '')}`, this.axiosConfig)
        return data;
    }

    public static async getAllPlansForAgencyToSubscribeAdmin(agencyId): Promise<SubscriptionPlansResponse> {
        const { data } = await axios.get(
            `api/v2/AgencySubscription/GetAdminPlansForAgency/${(agencyId || '')}`, this.axiosConfig)
        return data;
    }

    public static async getFutureSubscription(agencyId): Promise<SubscriptionPlansResponse> {
        const { data } = await axios.get(
            `api/v2/AdminAgencySubscription/GetFutureSubscription/${(agencyId || '')}`, this.axiosConfig)
        return data;
    }

    public static async setSubscription(selectedSubscriptionId: number, stripeCustomer: string, operationUid: string): Promise<any> {
        const { data } = await axios.post(
            `api/v2/AgencySubscription/SetSubscription`, { selectedPricePerPlayerPlanId: selectedSubscriptionId, stripeCustomer: stripeCustomer, operationUid: operationUid }, this.axiosConfig)
        return data;
    }

    public static async getStripeCustomer(email: string, name: string): Promise<any> {
        const { data } = await axios.post(
            `api/v2/Stripe/GetStripeCustomer`, { email: email, name: name }, this.axiosConfig)
        return data;
    }

    public static async log(operationUid: string, isSuccess: boolean, message: string, metadata: string): Promise<any> {
        const { data } = await axios.post(
            `api/v2/Stripe/Log`, { OperationUid: operationUid, IsSuccess: isSuccess, Message: message, Metadata: metadata }, this.axiosConfig)
        return data;
    }

    public static async createPayment(req: { customerId: string, paymentMethodId: string, priceId: string, quantity: number, vatIncluded: boolean, description: string, operationUid: string }): Promise<any> {
        const { data } = await axios.post(
            `api/v2/Stripe/CreatePayment`,
            { PaymentMethod: req.paymentMethodId, Customer: req.customerId, Price: req.priceId, Quantity: req.quantity, VatIncluded: req.vatIncluded, Description: req.description, OperationUid: req.operationUid },
            this.axiosConfig)
        return data;
    }

    public static async completeLtaSubscription(invitationGuid: string, paidAmount: string, currency: string, eventData: string): Promise<any> {
        const args: CompleteLtaSubsriptionRequest = {
            invitationGuid,
            paidAmount,
            currency,
            eventData
        };
        const { data } = await axios.post(`api/v2/AgencySubscription/CompleteLtaSubscription`, args, this.axiosConfig);
        return data;
    }

    public static async logPaymentDetails(invitationGuid: string, eventData: string): Promise<any> {
        const { data } = await axios.post(
            `api/v2/AgencySubscription/LogPaymentDetails`, { invitationGuid, eventData }, this.axiosConfig)
        return data;
    }
}