import React, { Component } from 'react';
import PropTypes from 'prop-types';

const stylesMap = {
    niceblue: {
        no: 'tr-btn--niceblue',
        outline: 'tr-btn--niceblue',
        glow: 'tr-btn--niceblue',
        text: 'tr-btn--niceblue',
        disabled: 'tr-btn--niceblue tr-btn--disabled',
    },
    primary: {
        no: 'tr-btn--primary',
        outline: 'tr-btn--primaryoutline',
        glow: 'tr-btn--primaryglow',
        text: 'tr-btn--primarytext',
        disabled: 'tr-btn--primary tr-btn--disabled',
    },
    secondary: {
        no: 'tr-btn--secondary',
        outline: 'tr-btn--secondaryoutline',
        glow: 'tr-btn--secondaryglow',
        text: 'tr-btn--secondarytext',
        disabled: 'tr-btn--secondary tr-btn--disabled',
    },
    primaryinverse: {
        no: 'tr-btn--primaryinverse',
        outline: 'tr-btn--primaryoutline',
        glow: 'tr-btn--primaryglow',
        text: 'tr-btn--primarytext',
        disabled: 'tr-btn--primary tr-btn--disabled',
    },
    secondaryinverse: {
        no: 'tr-btn--secondaryinverse',
        outline: 'tr-btn--secondaryoutline',
        glow: 'tr-btn--secondaryglow',
        text: 'tr-btn--secondarytext',
        disabled: 'tr-btn--secondary tr-btn--disabled',
    },
    grey: {
        no: 'tr-btn--grey',
        outline: 'tr-btn--greyoutline',
        glow: 'tr-btn--greyglow',
        text: 'tr-btn--greytext',
        disabled: 'tr-btn--greytext tr-btn--disabled',
        disabledLight: 'tr-btn--greydisabledLight tr-btn--disabled',
    },
    greysecondary: {
        no: 'tr-btn--grey-secondary',
        outline: 'tr-btn--greyoutline-light',
        glow: 'tr-btn--greyglow',
        text: 'tr-btn--greytext',
        disabled: 'tr-btn--greytext tr-btn--disabled',
        disabledLight: 'tr-btn--greydisabledLight tr-btn--disabled',
    },
    successinverse: {
        no: 'tr-btn--successinverse',
        outline: 'tr-btn--successoutline',
        glow: 'tr-btn--successglow',
        text: 'tr-btn--successtext',
        disabled: 'tr-btn--success tr-btn--disabled',
    },
    success: {
        no: 'tr-btn--success',
        outline: 'tr-btn--successoutline',
        glow: 'tr-btn--successglow',
        text: 'tr-btn--successtext',
        disabled: 'tr-btn--success tr-btn--disabled',
    },
    danger: {
        no: 'tr-btn--danger',
        outline: 'tr-btn--dangeroutline',
        glow: 'tr-btn--dangerglow',
        text: 'tr-btn--dangertext',
        disabled: 'tr-btn--dangertext tr-btn--disabled',
    },
    declare: {
        no: 'tr-btn--declare',
        outline: 'tr-btn--declare',
        glow: 'tr-btn--declare',
        text: 'tr-btn--declare',
        disabled: 'tr-btn--declare tr-btn--disabled',
    },
    small: {
        no: 'tr-btn--small',
        outline: 'tr-btn--small',
        glow: 'tr-btn--small',
        text: 'tr-btn--small',
        disabled: 'tr-btn--small tr-btn--disabled',
    },
    fired: {
        no: 'tr-btn--fired',
        outline: 'tr-btn--fired',
        glow: 'tr-btn--fired',
        text: 'tr-btn--fired',
        disabled: 'tr-btn--fired tr-btn--disabled',
    },
    transparent: {
        no: 'tr-btn--transparent',
        outline: 'tr-btn--transparent',
        glow: 'tr-btn--transparent',
        text: 'tr-btn--transparent',
        disabled: 'tr-btn--transparent tr-btn--disabled',
    },
    transparentniceblue:{
        no: 'tr-btn--transparentniceblue',
        outline: 'tr-btn--transparentniceblue',
        glow: 'tr-btn--transparentniceblue',
        text: 'tr-btn--transparentniceblue',
        disabled: 'tr-btn--transparentniceblue tr-btn--disabled',
    }
};


class Props {
    type?: 'primary' | 'secondary' | 'primaryinverse' | 'secondaryinverse' | 'success' | 'successinverse' | 'danger' | 'grey' | 'declare' | 'small' | 'fired' | 'transparent' | 'small' | "niceblue" | "transparentniceblue";
    effect?: 'no' | 'outline' | 'glow' | 'text' | 'disabled' | 'disabledLight';
    large?: boolean;
    className?: string;
    block?: boolean;

    Icon?: any;
    defaultProps?: any;
    propTypes?: any;
    [key: string]: any;

    dataTestId?: string;
}

class Button extends Component<Props> {
    static Icon: ({ children, ...otherProps }: { [x: string]: any; children: any; }) => JSX.Element;
  
    render() {
        const {
            type = 'primary',
            effect = 'no',
            large = false,
            className,
            block,
            dataTestId,
            ...otherProps
        } = this.props;

        let map = stylesMap[type];

        const colorClassName = map !== undefined ? map[effect] : '';

        const classes = ['btn', 'tr-btn', colorClassName]
            .concat(large ? ['tr-btn--large'] : [])
            .concat(block ? ['btn-block'] : [])
            .concat(className ? [className] : [])
            .join(' ');

        return (
            <button type="button" className={classes} data-test-id={dataTestId} {...otherProps}>
                {this.props.children}
            </button>
        );
    }
}

Button.Icon = ({ children, ...otherProps }) => (
    <span className="tr-btn__icon-container" {...otherProps}>
        {children}
    </span>
);

export default Button;
