import axios from 'axios-config'
import { getConfirmationLinkData } from 'api/admin/confirmation-link/models'


export class AdminConfirmationLinkService {

    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    }

    public static async getConfirmationLinkAsync(email: string, linkType: number): Promise<getConfirmationLinkData>  {
        const { data } = await axios.post(
            `api/AdminConfirmationLink/GetConfirmationLink/`,
            {
                Email: email,
                LinkType: linkType,
            },
            {...AdminConfirmationLinkService.axiosConfig}
        )
        return data
    }
}