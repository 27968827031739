import { MODAL_OPEN, MODAL_CLOSE } from 'store/actionTypes';
import { ACCESS_RESTRICTED_MODAL, MAKE_PLAYERS_AVAILABLE, CREATE_PLAYER_ADS, ADD_USERS, LANDING_PAGE_MODAL_FRIENDLY_ADS } from 'constants/modals';
import { LANDING_PITCH_PLAYERS } from 'constants/modals';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';

import { LANDING_PAGE_LOADING } from './common.reducer'

import { Actions as PitchPlayersActions } from './pitch-players.controller';
import { Actions as PerformanceActions } from './performance-data.controller';
import { Actions as PlayerStatisticsActions } from './player-statistic.controller';
import { Actions as SuperPitchesActions } from './super-pitches-all.controller';
import { Actions as OutdatedAcsActions } from './outdated-ads.controller';
import { Actions as NotificationsActions } from './notification.controller';
import { Actions as FriendlyInvitationsActions } from './friendly-invitations.controller';
import { Actions as PlusPitchesV2Actions } from './plus-pitch.v2.controller'
import { Actions as PitchV2Actions } from './pitch.v2.controller'
import { Actions as AgencyCardAlertActions } from './agency-card-alert.controller'
import { PageType, SubscriptionPlanEnum } from 'constants/enums';
import { AppState } from 'root.reducer';


export const loadLandingPageData = () => async (dispatch, getState: () => AppState) => {

    dispatch({ type: LANDING_PAGE_LOADING, payload: true })
    

    const state = getState();

    const promises = [
        dispatch(NotificationsActions.loadNotification()),
        dispatch(PerformanceActions.loadPerformanceData()),
        dispatch(PlayerStatisticsActions.loadShortListedPlayers()),
        dispatch(SuperPitchesActions.loadAllsuperPitches()),
        dispatch(OutdatedAcsActions.loadOutdatedPlayerAds()),
        dispatch(PlusPitchesV2Actions.loadPlusPitches()),
        dispatch(PitchV2Actions.loadAllPitches()),
        dispatch(AgencyCardAlertActions.getAgencyCards()),
    ]

    if (state.auth.subscriptionPlan !== SubscriptionPlanEnum.EntryPackage) {
        promises.push(dispatch(FriendlyInvitationsActions.init()))
    }

    return Promise.allSettled(promises).then(() => {
        return new Promise(async (resolve) => {
            await dispatch(PlayerStatisticsActions.ensurePreloadStatistic())
            resolve(true)
        })
    }).then(() => {
        dispatch({ type: LANDING_PAGE_LOADING, payload: false })
    }).catch((error) => {
        console.error(error)
        dispatch({ type: LANDING_PAGE_LOADING, payload: false })
    })
}

export const openMakePlayersAvailable = (source) => {
    return dispatch => {
        let command = {
            type: MODAL_OPEN,
            payload: { id: MAKE_PLAYERS_AVAILABLE, },
        };
        dispatch(command);

        let message = "Fix Now";
        let pageName = "Home [Make player available]";

        switch (source) {
            case "home":
                message = "Sell Now";
                pageName = "Home [Make player available]";
                break;
            case "performance":
                message = "Players Made Available";
                pageName = "Home [Performance indicators]";
                break;
            default:
                break;
        }

        dispatch(
            userActivityInsert({
                Message: message,
                PageName: pageName,
                PageType: PageType.Home
            }),
        );
    };
}

export const openPitchPlayers = (source) => {
    return dispatch => {
        let command = {
            type: MODAL_OPEN,
            payload: { id: LANDING_PITCH_PLAYERS, customToggle: cancelPitchPlayers },
        };
        dispatch(command);

        let message = "Fix Now";
        let pageName = "Home [Pitch your players]";

        switch (source) {
            case "home":
                message = "Pitch Now";
                pageName = "Home [Pitch your players]";
                break;
            case "performance":
                message = "Player Pitches";
                pageName = "Home [Performance indicators]";
                break;
            default:
                break;
        }

        dispatch(
            userActivityInsert({
                Message: message,
                PageName: pageName,
                PageType: PageType.Home
            }),
        );
    };
}

export const cancelMakePlayersAvailable = () => {
    return dispatch => {
        dispatch(
            userActivityInsert({
                Message: 'Cancelled Changes',
                PageName: 'Home [Make player available]',
                PageType: PageType.Home
            }),
        );
        dispatch(closeMakePlayersAvailable());
    };
}

export const closeMakePlayersAvailable = () => {
    return dispatch => {
        let command = {
            type: MODAL_CLOSE,
            payload: MAKE_PLAYERS_AVAILABLE,
        };
        dispatch(command);
    };
}

export const cancelPitchPlayers = () => {
    return dispatch => {
        dispatch(
            userActivityInsert({
                Message: 'Cancelled Changes',
                PageName: 'Home [Pitch your players]',
                PageType: PageType.Home
            }),
        );
        dispatch(closePitchPlayers());
        dispatch(PitchPlayersActions.dispose());
        dispatch(PerformanceActions.refreshCounters());
    };
}

export const donePitchPlayers = () => {
    return dispatch => {
        dispatch(
            userActivityInsert({
                Message: 'Done',
                PageName: 'Home [Pitch your players]',
                PageType: PageType.Home
            }),
        );
        dispatch(closePitchPlayers());
        dispatch(PitchPlayersActions.dispose());
    };
}

export const closePitchPlayers = () => {
    return dispatch => {
        let command = {
            type: MODAL_CLOSE,
            payload: LANDING_PITCH_PLAYERS,
        };
        dispatch(command);
    };
}

// export const openCreatePlayerAds = (source) => {
//     return dispatch => {
//         let command = {
//             type: MODAL_OPEN,
//             // payload: { id: CREATE_PLAYER_ADS, },
//             payload: { id: PLAYER_AD_CREATOR_MODAL }
//         };

//         let message = "Fix Now";
//         let pageName = "Home [Make player available]";

//         switch (source) {
//             case "home":
//                 message = "Create Ad";
//                 pageName = "Home [Create Player Ads]";
//                 break;
//             case "performance":
//                 message = "Create Ad";
//                 pageName = "Home [Performance Indicators]";
//                 break;
//             case "playerAds":
//                 message = "Create Ad";
//                 pageName = "Player Ads";
//                 break;
//             default:
//                 break;
//         }

//         dispatch(
//             userActivityInsert({
//                 Message: message,
//                 PageName: pageName,
//                 PageType: PageType.Home
//             }),
//         );
//         dispatch(command);
//     };
// }

export const cancelCreatePlayerAds = () => {
    return dispatch => {
        dispatch(
            userActivityInsert({
                Message: 'Cancelled',
                PageName: 'Home [Create player ads]',
                PageType: PageType.Home
            }),
        );
        dispatch(closeCreatePlayerAds());
    };
}

export const doneCreatePlayerAds = () => {
    return dispatch => {
        dispatch(
            userActivityInsert({
                Message: 'Done',
                PageName: 'Home [Player recommender]',
                PageType: PageType.Home
            }),
        );
        dispatch(closeCreatePlayerAds());
    };
}

export const closeCreatePlayerAds = () => {
    return dispatch => {
        let command = {
            type: MODAL_CLOSE,
            payload: CREATE_PLAYER_ADS,
        };
        dispatch(command);
    };
}
export const openRestrictionModal = () => {
    return dispatch => {
        let command = {
            type: MODAL_OPEN,
            payload: { id: ACCESS_RESTRICTED_MODAL, },
        };
        dispatch(command);
    };
}

export const openFriendlyAdsModal = (source) => {
    return dispatch => {
        let command = {
            type: MODAL_OPEN,
            payload: { id: LANDING_PAGE_MODAL_FRIENDLY_ADS, },
        };

        let message = "Fix Now";
        let pageName = "Home [Launch friendly ads]";

        switch (source) {
            case "home":
                message = "Find friendly >";
                pageName = "Home [Launch friendly ads]";
                break;
            case "performance":
                message = "Friendly Ads";
                pageName = "Home [Performance indicators]";
                break;
            default:
                break;
        }

        dispatch(
            userActivityInsert({
                Message: message,
                PageName: pageName,
                PageType: PageType.Home
            }),
        );
        dispatch(command);
    };
}

export const cancelFriendlyAdsModal = () => {
    return async dispatch => {
        dispatch(
            userActivityInsert({
                Message: 'Cancelled',
                PageName: 'Home [Launch friendly ads]',
                PageType: PageType.Home
            }),
        );
        dispatch(closeFriendlyAdsModal());
    };
}

export const closeFriendlyAdsModal = () => {
    return async dispatch => {
        let command = {
            type: MODAL_CLOSE,
            payload: LANDING_PAGE_MODAL_FRIENDLY_ADS,
        };
        dispatch(command);
    };
}

export const openAddUsersModal = () => {
    return async dispatch => {
        let command = {
            type: MODAL_OPEN,
            payload: { id: ADD_USERS, },
        };
        dispatch(command);
    };
}

export const cancelAddUsersModal = () => {
    return async dispatch => {
        dispatch(
            userActivityInsert({
                Message: 'Closed',
                PageName: 'Home [Add more users]',
                PageType: PageType.Home
            }),
        );
        dispatch(closeAddUsersModal());
    };
}

export const closeAddUsersModal = () => {
    return async dispatch => {
        let command = {
            type: MODAL_CLOSE,
            payload: ADD_USERS,
        };
        dispatch(command);
    };
}

