import { StateController } from "utils/action-declaration";
import { AppState } from "root.reducer";
import upgradeBannerReportService from "api/admin/upgrade-banner-report/upgrade-banner-report.service";
import { UpgradeBannerReportModel, FilterOptions } from "api/admin/upgrade-banner-report/models";

class UpgradeBannerReportState {
  isLoading: boolean;
  isSortOrderAscending: boolean;
  sortOrder: string;
  currentPage: number;
  totalReports: number;
  recordsPerPage: number;
  reports: Array<UpgradeBannerReportModel>;
  filterOptions: FilterOptions;
  previousFilterSnapshot: FilterOptions;
}

const defaultFilterOptions: FilterOptions = {
  date: '',
  squadName: '',
  userName: '',
  userJobTitle: '',
  event: '',
  pageName: ''
};

const defaultState: UpgradeBannerReportState = {
  isLoading: false,
  isSortOrderAscending: false,
  sortOrder: 'date',
  currentPage: 1,
  totalReports: 0,
  recordsPerPage: 20,
  reports: [],
  filterOptions: defaultFilterOptions,
  previousFilterSnapshot: defaultFilterOptions,
}

const stateController = new StateController<UpgradeBannerReportState>(
  'ADMIN_V2/UPGRADE_BANNER_REPORT',
  defaultState
);

class Actions {
  public static dispose() {
    return (dispatch) => {
      dispatch(stateController.setState(defaultState))
    }
  }

  public static loadInitialData = () => {
    return async (dispatch) => {
      try {
        await dispatch(Actions.getUpgradeBannerReport());
      } catch (err) {
        console.error(err);
      }
    };
  };

  public static getUpgradeBannerReport() {
    const wasFiltersChanged = (filterOptions: FilterOptions, previousFilterSnapshot: FilterOptions) => {
      return JSON.stringify(filterOptions) !== JSON.stringify(previousFilterSnapshot);
    }

    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState({ isLoading: true }));
        const {
          isSortOrderAscending,
          recordsPerPage,
          sortOrder,
          currentPage,
          filterOptions,
          previousFilterSnapshot
        } = Selectors.selectState(getState());

        const shouldDisposePagination = wasFiltersChanged(filterOptions, previousFilterSnapshot);

        dispatch(stateController.setState(prevState => ({
          ...prevState,
          previousFilterSnapshot: {
            ...prevState.filterOptions,
          },
          currentPage: shouldDisposePagination ? 1 : currentPage
        })));

        const searchParameters = {
          ...filterOptions,
          sortOrder,
          recordsPerPage,
          isSortOrderAscending,
          page: shouldDisposePagination ? 1 : currentPage,
          date: filterOptions.date ? new Date(filterOptions.date) : undefined,
          squadName: filterOptions.squadName ? filterOptions.squadName : undefined,
          userName: filterOptions.userName ? filterOptions.userName : undefined,
          userJobTitle: filterOptions.userJobTitle ? filterOptions.userJobTitle : undefined,
          event: filterOptions.event ? filterOptions.event : undefined,
          pageName: filterOptions.pageName ? filterOptions.pageName : undefined,
        }

        const data = await upgradeBannerReportService.getUpgradeBannerReport(searchParameters);
        const reports = data.output.map((report, index) => ({
          ...report,
          key: `${report.date}`
        }));

        dispatch(stateController.setState(prevState => ({
          ...prevState,
          reports,
          totalReports: data.rowCount,
        })));
      } catch (err) {
        console.error(err);
      } finally {
        dispatch(stateController.setState({ isLoading: false }));
      }
    }
  }

  public static onPaginationChange(pageNumber: number, pageSize: number) {
    return (dispatch, getState: () => AppState) => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        currentPage: pageNumber,
        recordsPerPage: pageSize
      })))

      dispatch(Actions.getUpgradeBannerReport());
    }
  }

  public static onChangeDate(value: string) {
    return dispatch => dispatch(stateController.setState(prevState => ({
      ...prevState,
      filterOptions: {
        ...prevState.filterOptions,
        date: value
      }
    })));
  }

  public static onChangeSquadName(value: string) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        filterOptions: {
          ...prevState.filterOptions,
          squadName: value
        }
      })));
    }
  }

  public static onChangeUserName(value: string) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        filterOptions: {
          ...prevState.filterOptions,
          userName: value
        }
      })));
    }
  }

  public static onChangeUserJobTitle(value: string) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        filterOptions: {
          ...prevState.filterOptions,
          userJobTitle: value
        }
      })));
    }
  }

  public static onChangeEvent(value: string) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        filterOptions: {
          ...prevState.filterOptions,
          event: value
        }
      })));
    }
  }

  public static onChangePageName(value: string) {
    return dispatch => {
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        filterOptions: {
          ...prevState.filterOptions,
          pageName: value
        }
      })));
    }
  }

  public static applySearch() {
    return dispatch => dispatch(Actions.getUpgradeBannerReport())
  }

  public static disposeFilters() {
    return (dispatch) => {
      dispatch(stateController.setState(prevState => ({ ...prevState, filterOptions: defaultState.filterOptions })));
      dispatch(Actions.applySearch());
    }
  }
}

class Selectors {
  public static selectState = (state: AppState) => state.admin.upgradeBannerReport;
  public static isLoading = (state: AppState): boolean => Selectors.selectState(state).isLoading;
  public static getReports = (state: AppState) => Selectors.selectState(state).reports;
  public static getTotalReports = (state: AppState) => Selectors.selectState(state).totalReports;
  public static getRecordsPerPage = (state: AppState) => Selectors.selectState(state).recordsPerPage;
  public static getCurrentPage = (state: AppState) => Selectors.selectState(state).currentPage;
  public static getFilterOptions = (state: AppState) => Selectors.selectState(state).filterOptions;
  public static isFiltersSetToDefault(state: AppState) {
    return JSON.stringify(defaultState.filterOptions) === JSON.stringify(Selectors.getFilterOptions(state))
  }
}

const reducer = stateController.getReducer();

export {
  reducer as Reducer,
  UpgradeBannerReportState as State,
  Actions as Actions,
  Selectors as Selectors,
  stateController as Controller
};
