import _ from 'lodash'
import { AppState } from 'root.reducer';
import { StateController } from 'utils/action-declaration';
import { getCancelTokenSource } from 'axios-config'
import CustomPitchService from 'api/agency/custom-pitch/custom-pitch.service';
import { SuggestionItem } from 'api/agency/custom-pitch/models';
import * as CreatePitchController from './create-pitch.controller';
import { formatAverage } from 'app/players-comparison/helpers/format-player';
import AgencyCustomPitchService from 'api/agency/custom-pitch/custom-pitch.service'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';

class HeadToHeadAutosuggestState {
    keyword: string;
    isLoading: boolean;
    wasLoaded: boolean;
    items: SuggestionItem[];
    selectedPlayerId?: number;
    chartDataLoading: boolean;
}

const defaultState: HeadToHeadAutosuggestState = {
    keyword: '',
    items: [],
    isLoading: false,
    wasLoaded: false,
    selectedPlayerId: null,
    chartDataLoading: false
}

const stateController = new StateController<HeadToHeadAutosuggestState>(
    'CUSTOM_PITCH/HEAD_TO_HEAD/AUTOSUGGEST',
    defaultState
)

class Actions {

    public static createDebounce = () => _.debounce((dispatch) => dispatch(Actions.fetchSuggestions()), 1000)

    public static cancelToken = null
    public static fetchDebounced = Actions.createDebounce()

    public static onKeywordChange(keyword: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ keyword }))

            if (keyword.length > 0) {
                Actions.fetchDebounced(dispatch)
            } else {
                dispatch(Actions.onKeywordClear())
            }
        }
    }

    public static setKeyword(keyword: string, playerId: number) {
        return dispatch => {
            dispatch(stateController.setState({ 
                keyword,
                selectedPlayerId: playerId
             }))
        }
    }

    public static onKeywordClearWithoutRefresh() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ keyword: '', wasLoaded: false, isLoading: false, items: [] }))
            Actions.fetchDebounced.cancel()
            if (Actions.cancelToken) { Actions.cancelToken.cancel() }
            Actions.cancelToken = null;
            Actions.fetchDebounced = Actions.createDebounce()
        }
    }

    public static onKeywordClear() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.onKeywordClearWithoutRefresh())
        }
    }

    public static fetchSuggestions() {
        return async (dispatch, getState: () => AppState) => {
            const state = getState();
            const { keyword } = Selectors.getRoot(state);
            const { playerId } = CreatePitchController.Selectors.getSession(getState())

            if (Actions.cancelToken)
                Actions.cancelToken.cancel()

            Actions.cancelToken = getCancelTokenSource();

            try {
                dispatch(stateController.setState({ isLoading: true }))

                let data = await CustomPitchService.getSuggestions(playerId, keyword, Actions.cancelToken.token)

                if (data) {
                    dispatch(stateController.setState({ wasLoaded: true, items: data }));
                }

            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState({ isLoading: false }))
            }
        }
    }

    public static onPlayerSelected(item: SuggestionItem) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ 
                keyword: item.name, 
                selectedPlayerId: item.id,
                wasLoaded: false, 
                isLoading: false, 
                items: [],
                chartDataLoading: true
            }))

            await dispatch(CreatePitchController.Actions.loadHeadToHeadInfo(item.id, true))

            const { playerId, recipientsSquadId } = getState().agency.customPitch.createPitch.entity;
            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Creation]',
                Message: `Head-to-head: compared with ${item.name}`,
                PlayerId: playerId,
                ClubId: recipientsSquadId,
                PageType: PageType.Pitch,
            }))

            dispatch(stateController.setState({ 
                chartDataLoading: false
            }))
        }
    }

    public static autoSuggestPlayer() {
        return async (dispatch, getState: () => AppState) => {
            const { playerId, recipientsSquadId } = CreatePitchController.Selectors.getSession(getState())

            dispatch(stateController.setState({ 
                chartDataLoading: true
            }))

            const headToHeadPlayerId = await AgencyCustomPitchService.getSuggestedHeadToHeadPlayer(playerId, recipientsSquadId);
            await dispatch(CreatePitchController.Actions.loadHeadToHeadInfo(headToHeadPlayerId, true));

            const { draft } = CreatePitchController.Selectors.getHeadToHeadData(getState())
            const { firstPlayer, secondPlayer} = draft.info;
            dispatch(stateController.setState({ 
                keyword: secondPlayer?.englishShortName ?? formatAverage(firstPlayer, draft.info) ?? "", 
                selectedPlayerId: secondPlayer?.playerId,
                wasLoaded: false,
                isLoading: false, 
                chartDataLoading: false,
                items: [],
            }))

            dispatch(userActivityInsert({
                PageName: 'Custom Pitch [Creation]',
                Message: `Head-to-head: compared with ${secondPlayer?.englishShortName} (autosuggest)`,
                PlayerId: playerId,
                ClubId: recipientsSquadId,
                PageType: PageType.Pitch,
            }))
        }
    }

    public static dispose() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ 
                keyword: '', 
                selectedPlayerId: null,
                wasLoaded: false, 
                isLoading: false, 
                items: [],
            }))
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.agency.customPitch.headToHeadAutosuggest
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    HeadToHeadAutosuggestState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};