import * as redux from 'redux';
import * as Grid from './grid.controller'
import * as FilterContorller from './filter.controller';
import * as SearchAutosuggestController from './autosuggest.controller'
import * as SendMessageController from './send-message.controller'
import * as competitionList from './competition-list.controller';

export class NewSearchRootState {
    search: SearchAutosuggestController.State;
    grid: Grid.State;
    filter: FilterContorller.State;
    message: SendMessageController.State;
    competitionList: competitionList.State;
}

export function combineReducers() {

    const newSearchReducers = {
        search: SearchAutosuggestController.Reducer,
        filter: FilterContorller.Reducer,
        grid: Grid.Reducer,
        message: SendMessageController.Reducer,
    };

    return redux.combineReducers(newSearchReducers);
}
