import { combineReducers } from 'redux';
import * as landingPage from 'pages/landing-page/redux/root.reducer';
import playerSearch from 'store/playerSearch/reducers/playerSearchReducer';
//import matchMakerAds from './app/match-maker-filter/reducers/match-maker-filter.reducer'
import playerProfile, { PlayerProfileState } from 'store/playerProfile/profileReducer';
import * as playerProfileV2 from 'pages/player-profile/redux/player-profile.controller';
import * as PlayerProfileCommonController from 'store/playerProfile/profile-common.controller'
import pagination from './app/pagination/reducers/pagination.reducer';
import modals from 'store/modals/modalsReducer';
import requests from 'store/requests/requestsReducer';
import auth from 'store/auth/authReducer';
import playersInfo from './app/player-info/reducer/player-info.reducer';
import performance from './app/performance/reducer/performance.reducer';
// import positionsSelector from './app/positions-selector/reducers/positions-selector.reducer';
import playersComparison, { PlayersComparisonRootState } from './app/players-comparison/reducer/players-comparison.reducer';
import shortList from './app/shortlist/reducers/shortlist.reducer';
import leagueListEditor from './app/competition-lists/reducers/competition-lists-editor.reducer';
//import login from './app/login/reducers/login.reducer'; TODO:MG

import users from './app/users/reducers/users.reducer';
import positions from './app/positions/reducers/positions.reducer';
import phoneCodes from './app/phone-codes/reducers/phone-codes.reducer';
import * as MessagesController from './app/messages/store/messages.controller';
import fullStat from './app/full-stat/reducer/full-stat.reducer';
import playerAds from './app/player-ads/store/reducers';
import * as playerAdsV2 from './pages/player-ads/redux/root.controller'

import friendlyMatches from './app/friendly-matches/store/reducers';
import matchedFriendlyAds from './app/friendly-matches/store/reducers/matched-ads.reducer';
import clubAds from './pages/Pitch/store/reducers/club-ads.reducer';
// import playerAdsOld from './app/player-ads/reducers/my-ads.reducer';
// import playerPitches from './app/player-ads/reducers/player-pitches.reducer';
import editprofile from './app/edit-profile/reducer/edit-profile.reducer';
import expiredAds from './app/expired-ads/reducer/expired-ads.reducer';
import competitionLists from './app/competition-lists/reducers/competition-lists.reducer';
import competitions from './app/competitions/reducers/competitions.reducer';
import areas from './app/areas/reducers/areas.reducer';
import userActivity from './app/user-activity/reducers/user-activity.reducer';
import events from 'app/events/redux/root.reducer';
import routes from './app/routes/reducers/routes.reducer';
import userregistration from './admin/user-registration/reducer/user-registration.reducer';
import * as registration from 'pages/anonymous-pages/registration/redux/root.reducer';
import * as requestAgencyInvitation from 'pages/anonymous-pages/request-agency-invitation/request-agency-invitation.controller';
import userresetpassword from './app/password-recovery/reducer/password-recovery.reducer';
import usersetnewpassword from './app/reset-password/reducer/reset-password.reducer';
import userupdatepassword from './app/update-password/reducer/update-password.reducer';

import * as adminMessaging from './admin/messaging/reducer/admin-messaging.controller';
import * as adminAgencyOnboarding from './admin/agency-onboarding/redux/agency-onboarding.controller';
import confirmAccount from './pages/ConfirmAccount/reducer/ConfirmAccount.reducer';
import playerProposals from './admin/player-proposals/reducer/player-proposals.reducer';
import friendlyProposals from './admin/friendly-proposals/reducer/friendly-proposals.reducer';
import clubAdsNewsletter from './admin/newsletter/reducer/newsletter-reducer';
import responsiveSupport from 'store/responsiveSupport/responsiveSupport.reducer';
import userPreference, { UserPreferenceState } from 'store/userPreference/userPreference.reducer';
import pitchPlusCreate from './app/pitch-plus/create-flow/store/pitchPlusCreate.reducer';
import adminBlockedReceivers from './admin/components/blocked-receivers-selector/reducer/blocked-receivers-selector.reducer';
import financialInsights from 'pages/financial-insight/redux/financial-insights.reducer';
import * as mobileAppPromoModal from 'components/mobile-app-promotion-modal/mobile-app-promotion.controller'
import liveData, { State as LiveDataState } from 'store/liveData/liveData.reducer';
import { i18nReducer } from 'react-redux-i18n';
import playerRanking from 'store/playerProfile/playerRanking.reducer';
import plusPitch from 'pages/Pitch/tab-plus-pitch/store/indexReducer';
import adForceUpdate from 'store/adForceUpdate/ad-force-update.reducer';
import playerProfileMarketValue from 'store/playerProfile/market-value/market-value.reducer.js'
import subscriptionPromotion, { SubscriptionPromotionState } from 'app/subscription-cta/subscription-cta.reducer';
import * as Shortlist from 'store/shortlist/shortlist.controller';

// Interfaces
import AuthState from 'store/auth/auth-state'
import * as adminV2 from 'admin-v2/admin-root.controller'
import * as virtualSummit from 'pages/virtual-summit/redux/virtual-summit.reducer'
import * as landing from 'pages/custom-sing-in/redux/landing-page.reducer'
import { EventsPageState } from 'app/events/redux/root.reducer'
import * as instatIndex from 'pages/Profile/components/instat-index/instat-index.controller'
import * as scatterChart from 'pages/Profile/components/scatter-chart/redux/scatter-chart.controller'
import * as loanInsights from 'pages/loan-insights/redux/root-controller'
import * as superPitch from 'pages/Pitch/tab-super-pitch/store/root.reducer'
import * as ads from 'pages/Pitch/tab-ads/store/root.reducer'
import * as playerAdPage from 'pages/player-ads/redux/root.reducer'
import * as agency from 'pages/agency/agency-root.controller'
import * as agencyProfile from 'pages/agency/common/agency-profile/redux/root.reducer';
import * as sendMessageModal from 'components/send-message-modal/send-message-modal.controller';
import * as sendMessageMultiRecipientsModal from 'components/send-message-multi-addressee-modal/send-message-multi-recipients-modal.controller';
import * as playerPoints from 'pages/player-points/redux/root.reducer'
import * as agencyFeatureLanding from 'pages/agency/club/main-page/feature-landing/feature-landing.controller'
import commonStyles, { CommonStylesState } from 'store/styles/styles.reducer' // Styles
import * as GbeIframeController from 'pages/iframes/gbe-hubspot-frame/redux/gbe-iframe.controller'
import * as FeedbackController from 'app/feedback-collector/feedback-collector.controller'
import * as MySquad from 'pages/my-squad-v2/redux/root.controller';
//import * as VideoController from 'pages/video-room/redux/video.root.reducer'
import * as UpdatePasswordMandatory from 'pages/update-password-mandatory/update-password.controller';
import * as PlayerReducer from 'pages/player/root.reducer'
import * as PlayerController from 'pages/player-v2/root.controller';
import * as EventRegistrationModalController from 'app/event-registration-modal/event-registration-modal.controller';
import * as SharedModalController from "./components/modals/shared-modal-controller";
import * as VideoModalController from "./components/modals/video/video-modal.controller";
import * as AccessRestrictedModalController from './ts-components/access-restricted-modal-v2/access-restricted-modal.controller';
import * as NewSearchState from 'pages/PlayerSearch-v2/redux/root.reducer';
import * as SquadSearchReducer from 'pages/PlayerSearch-v3/squad/redux/root.reducer';
import * as AgencySearchReducer from 'pages/PlayerSearch-v3/agency/redux/root.reducer';
import * as CoachProfile from 'pages/coach-profile/redux/coach-profile.controller';
import * as club from 'pages/club/club-root.controller';
import * as AgencyProfileController from 'pages/AgencyProfile/redux/agency-profile.controller';
import * as CoachController from 'pages/coach/root.controller';

import * as UpdatedDocumentsModalController from 'components/updated-documents-modal-V2/updated-documents.controller';
import * as UpdateEmailController from 'app/change-email/change-email.controller'
import * as ResetPasswordController from 'pages/reset-password/reset-password.controller'


export class AppState {
    agency: agency.AgencyState;
    player: PlayerReducer.PlayerState;
    playerV2: PlayerController.PlayerState;
    club: club.ClubState;
    coach: CoachController.CoachState;
    coachProfile: CoachProfile.State;
    agencyProfile: agencyProfile.AgencyProfileState;
    agencyProfileV2: AgencyProfileController.State;
    auth: AuthState;
    admin: adminV2.AdminState;
    adminMessaging: adminMessaging.State;
    adminAgencyOnboarding: adminAgencyOnboarding.State;
    commonStyles: CommonStylesState;
    mySquadV2: MySquad.MySquadV2RootState;
    mobileAppPromoModal: mobileAppPromoModal.State
    agencyFeatureLanding: agencyFeatureLanding.State;
    virtualSummit: virtualSummit.VirtualSummitState;
    events: EventsPageState;
    gbeIframe: GbeIframeController.State;
    messages: MessagesController.State;
    profileInstatIndex: instatIndex.State;
    profileScatterChart: scatterChart.State;
    loanInsights: loanInsights.LoanInsightsState;
    superPitch: superPitch.SuperPitchState;
    shortlisted: Shortlist.State;
    playerProfile: PlayerProfileState;
    playerProfileV2: playerProfileV2.State;
    playerProfileCommon: PlayerProfileCommonController.State;
    landingPage: landingPage.LandingPageState
    landing: landing.LandingState;
    playerAdPage: playerAdPage.PlayerAdPageState
    playerAdsV2: playerAdsV2.PlayerAdsState;
    registration: registration.RegistrationState;
    requestAgencyInvitation: requestAgencyInvitation.State;
    sendMessageModal: sendMessageModal.State;
    sendMessageMultiRecipientsModal: sendMessageMultiRecipientsModal.State;
    liveData: LiveDataState;
    playerPoints: playerPoints.PlayerPointsState;
    playersComparison: PlayersComparisonRootState;
    feedback: FeedbackController.State;
    //video: VideoController.VideoState;
    updatePasswordMandatory: UpdatePasswordMandatory.State;
    newPlayerSearch: NewSearchState.NewSearchRootState;
    squadSearch: SquadSearchReducer.SquadSearchState;
    agencySearch: AgencySearchReducer.AgencySearchState;
    eventRegistrationModal: EventRegistrationModalController.State;
    ads: ads.AdsState;
    userPreference: UserPreferenceState;
    editprofile: any;
    sharedModal: SharedModalController.State;
    videoModal: VideoModalController.State;
    accessRestrictedModal: AccessRestrictedModalController.State;
    i18n: any;
    updatedDocumentsModal: UpdatedDocumentsModalController.State
    subscriptionPromotion: SubscriptionPromotionState;
    updateEmail: UpdateEmailController.State;
    resetPassword: ResetPasswordController.State;
    playerProfileMarketValue: any;
}

const entities = combineReducers({
    users,
    positions,
    phoneCodes,
    // playerAdsOld,
    // playerPitches,
    competitionLists,
    competitions,
    areas,
});

const rootReducer = combineReducers({
    // positionsSelector,
    //auth: login, TODO:MG
    //sorry
    auth,
    admin: adminV2.combineReducers(),
    agency: agency.combineReducers(),
    player: PlayerReducer.default,
    playerV2: PlayerController.default,
    club: club.combineReducers(),
    coach: CoachController.combineReducers(),
    coachProfile: CoachProfile.Reducer,
    adForceUpdate,
    adminBlockedReceivers,
    adminMessaging: adminMessaging.Reducer,
    agencyProfile: agencyProfile.combineReducers(),
    agencyProfileV2: AgencyProfileController.Reducer,
    agencyFeatureLanding: agencyFeatureLanding.Reducer,
    clubAds,
    clubAdsNewsletter,
    commonStyles,
    confirmAccount,
    editprofile,
    entities,
    events,
    expiredAds,
    financialInsights,
    friendlyMatches,
    friendlyProposals,
    fullStat,
    gbeIframe: GbeIframeController.Reducer,
    i18n: i18nReducer,
    landingPage: landingPage.combineReducers(),
    landing: landing.combineReducers(),
    leagueListEditor,
    liveData,
    loanInsights: loanInsights.combineReducers(),
    matchedFriendlyAds,
    // matchMakerAds,
    messages: MessagesController.Reducer,
    mySquadV2: MySquad.combineReducers(),
    mobileAppPromoModal: mobileAppPromoModal.Reducer,
    modals,
    newPlayerSearch: NewSearchState.combineReducers(),
    squadSearch: SquadSearchReducer.combineReducers(),
    agencySearch: AgencySearchReducer.combineReducers(),
    pagination,
    performance,
    pitchPlusCreate,
    playerAds,
    playerAdPage: playerAdPage.combineReducers(),
    playerAdsV2: playerAdsV2.combineReducers(),
    playerProfileV2: playerProfileV2.Reducer,
    playerProfile,
    playerProfileCommon: PlayerProfileCommonController.Reducer,
    playerProposals,
    playerPoints: playerPoints.combineReducers(),
    playerRanking,
    playerSearch,
    playersComparison,
    playersInfo,
    playerProfileMarketValue,
    plusPitch,
    profileInstatIndex: instatIndex.Reducer,
    profileScatterChart: scatterChart.Reducer,
    requests,
    registration: registration.combineReducers(),
    responsiveSupport,
    requestAgencyInvitation: requestAgencyInvitation.Reducer,
    routes,
    sendMessageModal: sendMessageModal.Reducer,
    sendMessageMultiRecipientsModal: sendMessageMultiRecipientsModal.Reducer,
    shortList,
    shortlisted: Shortlist.Reducer,
    superPitch: superPitch.Reducers,
    subscriptionPromotion,
    userActivity: userActivity,
    userPreference,
    userregistration,

    userresetpassword,
    usersetnewpassword,
    userupdatepassword,
    virtualSummit: virtualSummit.combineReducers(),
    adminAgencyOnboarding: adminAgencyOnboarding.Reducer,
    feedback: FeedbackController.Reducer,
    //video: VideoController.combineReducers(),
    updatePasswordMandatory: UpdatePasswordMandatory.Reducer,
    eventRegistrationModal: EventRegistrationModalController.Reducer,
    ads: ads.Reducers,
    sharedModal: SharedModalController.Reducer,
    videoModal: VideoModalController.Reducer,
    accessRestrictedModal: AccessRestrictedModalController.Reducer,
    updatedDocumentsModal: UpdatedDocumentsModalController.Reducer,
    updateEmail: UpdateEmailController.Reducer,
    resetPassword: ResetPasswordController.Reducer,
} as any);

export default rootReducer;
