import { StateController } from 'utils/action-declaration' 
import { AppState } from 'root.reducer';
import { AgencyItem } from 'api/landing-page/models/agency-card-alert';
import { Actions as PlayerStatisticActions } from 'pages/landing-page/redux/player-statistic.controller';
import { LandingPageService } from 'api/landing-page/landing-page-service';
import { PlayerService } from 'api/player/player-service';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { ActionType, PageType, UserActivityType } from 'constants/enums';

export class AgencyCardAlertState {
    currentIndex: number;
    agencies: AgencyItem[];
    declareInterestProcessingIds: Array<number>;
    welcomeMessageProcessingIds: Array<number>;
}

const defaultState: AgencyCardAlertState = {
    currentIndex: 0,
    agencies:[],
    declareInterestProcessingIds:[],
    welcomeMessageProcessingIds:[]
}

const stateController = new StateController<AgencyCardAlertState>(
    "LANDING_PAGE/AGENCY_CARD_ALERT",
    defaultState
);

class Actions {
    public static getAgencyCards(){
        return async (dispatch, getState: () => AppState) => {
            const agencies = await LandingPageService.getAgencyCards();
            dispatch(stateController.setState({ agencies }));
        }
    }

    public static togglePlayerInShortList(playerId: number, agencyId: number, isInShortlist: boolean){
        return async (dispatch, getState: () => AppState) => {
            try {
                await dispatch(PlayerStatisticActions.togglePlayerInShortList(playerId, isInShortlist));
                dispatch(userActivityInsert({
                    Message: !isInShortlist ? 'Added to Shortlist' : 'Removed from Shortlist',
                    PageName: 'Home [Agency joined]',
                    PlayerId: playerId,
                    AgencyId: agencyId,
                    ActionType: !isInShortlist ? ActionType.AddToShortlist : null,
                    PageType: PageType.Home
                }));
            } catch (e) {
                console.error(e)
            }
        }
    }

    public static declareInterest(playerId: number, agencyId: number) {
        return async (dispatch, getState: () => AppState) => {
             try {
                
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    declareInterestProcessingIds: [...prevState.declareInterestProcessingIds, playerId],
                })))

                const shortlistedPlayers = getState().landingPage.playerStatistic.shortListedPlayers;
                if (!shortlistedPlayers.includes(playerId)) {
                    dispatch(Actions.togglePlayerInShortList(playerId, agencyId, false));
                }

                await PlayerService.declareInterestToAgent(playerId);

                dispatch(userActivityInsert({
                    Message: `Declared Interest`,
                    PageName: 'Home [Agency joined]',
                    AgencyId: agencyId,
                    PlayerId: playerId,
                    ActionType: ActionType.DeclaredInterest,
                    PageType: PageType.Home,
                    UserActivityType: UserActivityType.Action
                }));

                const substate = getState().landingPage.agencyCardAlert;
                const agencies = substate.agencies;
                const agency = agencies.find(item => item.agencyId === agencyId);
                agency.players.find(item => item.id === playerId).isInterestWasDeclared = true;

                dispatch(stateController.setState({
                    agencies: [...agencies]
                }))
            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    declareInterestProcessingIds: prevState.declareInterestProcessingIds.filter(item => item !== playerId),
                })))
            }
        }
    }

    public static dismissAgencyCard(agencyToClubNotificationId: number){
        return async (dispatch, getState: () => AppState) => {
            try {
                let substate = getState().landingPage.agencyCardAlert;

                await LandingPageService.dismissAgencyCard(agencyToClubNotificationId);

                const agencyToDismiss = substate.agencies.find(x => x.agencyToClubNotificationId == agencyToClubNotificationId);
                dispatch(userActivityInsert({
                    Message: 'Dismissed',
                    PageName: 'Home [Agency joined]',
                    AgencyId: agencyToDismiss.agencyId,
                    PageType: PageType.Home,
                    //UserActivityType: UserActivityType.Action
                }));

                let newCurrentIndex = substate.currentIndex;
                if(newCurrentIndex === substate.agencies.length - 1 && newCurrentIndex > 0){
                    newCurrentIndex--;
                }
                const agencies = substate.agencies.filter(x => x.agencyToClubNotificationId !== agencyToClubNotificationId);
                dispatch(stateController.setState({
                    currentIndex: newCurrentIndex,
                    agencies: [...agencies]
                }))
            } catch (e) {
                console.error(e)
            }
        }
    }

    public static nextIndex() {
        return (dispatch, getState: () => AppState) => {
            let substate = getState().landingPage.agencyCardAlert;
            if (substate.currentIndex + 1 < substate.agencies.length) {
                const nextIndex = ++substate.currentIndex

                dispatch(stateController.setState((prevState) => ({ 
                    ...prevState, 
                    currentIndex: nextIndex 
                })));

                const nextAgency = substate.agencies[nextIndex];
                dispatch(userActivityInsert({
                    Message: 'Next Agency',
                    PageName: 'Home [Agency joined]',
                    AgencyId: nextAgency.agencyId,
                    PageType: PageType.Home
                }));
            }
        }
    }

    public static prevIndex() {
        return (dispatch, getState: () => AppState) => {
            let substate = getState().landingPage.agencyCardAlert;
            if (substate.currentIndex > 0) {
                const prevIndex = --substate.currentIndex;

                dispatch(stateController.setState((prevState) => ({
                    ...prevState,
                    currentIndex: prevIndex
                })));

                const prevAgency = substate.agencies[prevIndex];
                dispatch(userActivityInsert({
                    Message: 'Previous Agency',
                    PageName: 'Home [Agency joined]',
                    AgencyId: prevAgency.agencyId,
                    PageType: PageType.Home
                }));
            }
        }
    }

    public static sendWelcomeMessage(agencyId: number){
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState(prevState => ({
                ...prevState,
                welcomeMessageProcessingIds: [...prevState.declareInterestProcessingIds, agencyId],
            })))

            try {
                await LandingPageService.sendWelcomeMessageToAgency(agencyId);

                dispatch(userActivityInsert({
                    Message: 'Welcomed Agency',
                    PageName: 'Home [Agency joined]',
                    AgencyId: agencyId,
                    PageType: PageType.Home,
                    //UserActivityType: UserActivityType.Action
                }));

                const substate = getState().landingPage.agencyCardAlert;
                const agencies = substate.agencies;
                const agency = agencies.find(item => item.agencyId === agencyId);
                agency.isWelcomeMessageWasSent = true;

                dispatch(stateController.setState({
                    agencies: [...agencies]
                }))
            } catch (e) {
                console.error(e)
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    welcomeMessageProcessingIds: prevState.welcomeMessageProcessingIds.filter(item => item !== agencyId),
                })))
            }
        };
    }
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    AgencyCardAlertState as State,
    Actions as Actions,
    stateController as Controller
};


