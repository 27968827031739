import createApiThunk from '../../../services/createApiThunk';
import { createAction } from 'redux-actions';
import * as types from 'store/actionTypes';
import { logInV2 } from 'store/auth/auth.actions';
import historyAccessor from 'history-accessor';

export const returnToResetPassword = createAction(types.USER_RETURN_TO_SET_PASSWORD);
export const clearRequestSession = createAction(types.USER_CLEAR_SET_PASSWORD_REQUEST_SESSION);
export const validatePassword = createAction(types.SET_PASSWORD_ERROR);

export const resetPassword = (email, pwd, token) => {
    return async (dispatch, getState) => {
        const request = {
            type: types.USER_SET_NEW_PASSWORD_POST,
            method: 'post',
            scope: 'usersetnewpassword',
            url: `/Users/SetNewPassword`,
            payload: {
                email: email,
                password: pwd,
                token: token,
            },
            success: types.USER_SET_NEW_PASSWORD_POST.SUCCESS,
        };
    
        return dispatch(createApiThunk(request));
    }
}

export const setNewPassword = (email, pwd, token) => {
    return async (dispatch, getState) => {
        let response = await dispatch(resetPassword(email, pwd, token));
        if (response.success) {
            await dispatch(logInV2({ userLogin: email, password: pwd }))
            historyAccessor.push('/')
        }
    }
};
