import { StateController } from 'utils/action-declaration'
import { AppState } from 'root.reducer'
import { PlayerPreferenceService } from 'api/player-side/player-preference'
import historyAccessor from 'history-accessor';
import { AgentPlayerAgreementTypeEnum } from 'api/agency/player/shared/shared-models';
import { MyAgentActivityService } from './my-agent.activity'
import { Actions as MyPageActions } from '../my-page/my-page.controller';
import moment from 'moment';

export enum MyAgentStep {
    Unknown = 0,
    Info = 1,
    Agreement = 2,
    Remove = 3
}

class MyAgentState {
    currentStep: MyAgentStep;
    expireDate: string | Date;
    agreementType: AgentPlayerAgreementTypeEnum;
    processing: boolean;
    noSpecificExpiryDate: boolean;
}

const defaultState: MyAgentState = {
    currentStep: MyAgentStep.Info,
    expireDate: null,
    agreementType: AgentPlayerAgreementTypeEnum.None,
    noSpecificExpiryDate: false,
    processing: false,
}

const stateController = new StateController<MyAgentState>(
    'PLAYER_MY_AGENT',
    defaultState
)

class Actions {
    // public static init() {
    //     return async (dispatch, getState: () => AppState) => {
    //         const playerEmail = getState().auth.userEmail
    //         try {
    //             dispatch(stateController.setState({ isInitialyLoading: true }))
    //             await dispatch(Actions.sendActivationLink(playerEmail, playerEmail))
    //         } catch (err) {
    //             console.error(err)
    //         } finally {
    //             dispatch(stateController.setState({ isInitialyLoading: false, firstVisit: false }))
    //         }
    //     }
    // }

    // public static openChangeEmail() {
    //     return async (dispatch, getState: () => AppState) => {
    //         dispatch(EmailConfirmationActivityService.openedEmailUpdate(getState().auth.playerId))
    //         dispatch(Actions.goToStep(EmailConfirmationStep.ChangeEmail))
    //     }
    // }

    public static init() {
        return (dispatch, getState: () => AppState) => {
            let substate = getState();
            if (substate.player.myPage.serverData)
                dispatch(MyAgentActivityService.openedMyAgent(substate.auth.playerId, substate.player.myPage.serverData.agent.id));
        }
    }

    public static initContractDetails() {
        return (dispatch, getState: () => AppState) => {
            let substate = getState();
            dispatch(stateController.setState(
                {
                    expireDate: substate.player.myPage.serverData.expiryDate,
                    noSpecificExpiryDate: substate.player.myPage.serverData.expiryDate == null,
                    agreementType : substate.player.myPage.serverData.agreementType
                }
            ))
            dispatch(MyAgentActivityService.openedContractDetails(substate.auth.playerId, substate.player.myPage.serverData.agent.id));
        }
    }

    public static removeAgentClick() {
        return (dispatch, getState: () => AppState) => {
            let substate = getState();
            dispatch(MyAgentActivityService.removeMyAgent(substate.auth.playerId, substate.player.myPage.serverData.agent.id));
            dispatch(Actions.goToStep(MyAgentStep.Remove));
        }
    }

    public static removeAgentCancel() {
        return (dispatch, getState: () => AppState) => {
            let substate = getState();
            dispatch(MyAgentActivityService.removeMyAgentCancelled(substate.auth.playerId, substate.player.myPage.serverData.agent.id));
            dispatch(Actions.goToStep(MyAgentStep.Info));
        }
    }

    public static goToStep(step: MyAgentStep) {
        return (dispatch) => {
            dispatch(stateController.setState({ currentStep: step }))
        }
    }

    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState({ ...defaultState }))
        }
    }

    public static setExpireDate(date: string | Date) {
        return (dispatch) => {
            if (date !== null) {
                dispatch(stateController.setState({ expireDate: date, noSpecificExpiryDate: false, }))
            } else {
                dispatch(stateController.setState({ expireDate: null, noSpecificExpiryDate: true }))
            }
        }
    }

    public static setAgreementType(type: AgentPlayerAgreementTypeEnum) {
        return (dispatch) => {
            dispatch(stateController.setState({ agreementType: type }))
        }
    }

    public static saveAgentDetails() {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState();

            dispatch(stateController.setState({ processing: true }))
            const agent = Selectors.getRoot(getState())
            await PlayerPreferenceService.changeAgentDetails({
                expiryDate: moment(agent.expireDate).toISOString(),
                agreementType: agent.agreementType,
            })
            dispatch(stateController.setState({ processing: false }))

            if (agent.expireDate) {
                dispatch(MyAgentActivityService.expiryDate(substate.auth.playerId, substate.player.myPage.serverData.agent.id, moment(agent.expireDate).format("MMM D YYYY")));
            }
            else {
                dispatch(MyAgentActivityService.noSpecificExpiryDate(substate.auth.playerId, substate.player.myPage.serverData.agent.id));
            }

            dispatch(MyAgentActivityService.agreementType(substate.auth.playerId, substate.player.myPage.serverData.agent.id,
                agent.agreementType == AgentPlayerAgreementTypeEnum.ContractualAgreement ? 'Contractual Agreement' : 'Verbal Agreement'));

            await dispatch(MyPageActions.setAgreement(agent.expireDate));
            dispatch(Actions.goToStep(MyAgentStep.Info))
        }
    }

    public static removeAgent() {
        return async (dispatch, getState: () => AppState) => {
            let substate = getState();

            dispatch(stateController.setState({ processing: true }))
            await PlayerPreferenceService.removeAgency()
            dispatch(stateController.setState({ processing: false }))

            dispatch(MyAgentActivityService.removeMyAgentConfirmed(substate.auth.playerId, substate.player.myPage.serverData.agent.id));
            historyAccessor.push('')
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.player.myAgentFlow
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    MyAgentState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};